import React from 'react'
import {
  Container,
  Header,
  Content
} from './styles'
import CourseButton from '../CourseButton/CourseButton'
import {
  getGuardianPermissionKeys,
  getPermissionGrantedTexts
} from './GuardianPermission.utils'
import { useStudentDataContext } from '../Context/Context'
import { PROF_CERT_GUARDIAN_PERMISSION_REQUESTED } from '../../Constants'

const PermissionGranted = () => {
  const {
    gguParentCertificationFormSubmitted
  } = useStudentDataContext()

  const isProfCertGrantedPage = !!(localStorage.getItem(
    PROF_CERT_GUARDIAN_PERMISSION_REQUESTED
  ) && gguParentCertificationFormSubmitted)

  const {
    guardianPermissionRequestedKey
  } = getGuardianPermissionKeys(isProfCertGrantedPage)

  const continueCourse = () => {
    localStorage.removeItem(guardianPermissionRequestedKey)
    window.location.reload()
  }

  const {
    description,
    buttonText
  } = getPermissionGrantedTexts(isProfCertGrantedPage)

  return (
    <Container>
      <div className='content' style={{ alignItems: 'center' }}>
        <Header marginBottom='16px'>You’re good to go!</Header>
        <Content marginBottom='48px' style={{ textAlign: 'center' }}>
          {description}
        </Content>
        <CourseButton
          className='btn btn-primary'
          onClick={continueCourse}
        >
          {buttonText}
        </CourseButton>
      </div>
    </Container>
  )
}

export default PermissionGranted
