import React, { useEffect, useRef, useContext } from 'react'
import DOMPurify from 'dompurify'
import isEmpty from 'lodash/isEmpty'
import Context from '../Context/Context'
import './MathJax.css'
import { canFreezeTable } from '../../utilities/questions'

export default function MathJax (props) {
  configureDOMPurify()

  const {
    math: rawMath,
    isExam,
    className,
    onMathJaxWillRender,
    onMathJaxDidRender,
    datoCardType,
    isQuestionOption
  } = props

  const { isALRedesign } = useContext(Context)
  const previewRef = useRef()
  const freezeOptions = { datoCardType, isQuestionOption }
  const math = updateMathString(rawMath, isALRedesign, freezeOptions)

  useEffect(() => {
    // Temporary fix for the test env
    // We will fix this later by using dynamic script load
    if (process.env.NODE_ENV === 'test') return

    // Call onMathJaxWillRender
    const { Hub } = window.MathJax
    onMathJaxWillRender && Hub.Queue([onMathJaxWillRender])

    // Modify the HTML of the element
    // Then MathJax will manipulate the DOM directly
    const { current } = previewRef
    current.innerHTML = DOMPurify.sanitize(math, {
      ADD_TAGS: ['iframe', 'semantics', 'annotation'],
      ADD_ATTR: ['allow', 'autoplay', 'gyroscope', 'allowFullScreen',
        'frameborder', 'scrolling', 'encoding']
    })

    // Render MathJax content inside the element asynchronously, using Queue
    // https://docs.mathjax.org/en/v2.7-latest/advanced/queues.html#the-mathjax-processing-queue
    Hub.Queue(['Typeset', Hub, current])

    // Call onMathJaxDidRender
    onMathJaxDidRender && Hub.Queue([onMathJaxDidRender])
  }, [math, onMathJaxDidRender, onMathJaxWillRender, previewRef])

  const handlePaste = (event) => {
    if (!isExam) return
    event.preventDefault()
    return false
  }

  return <div id='react-mathjax-preview' className={className} onPaste={handlePaste}>
    <div id='react-mathjax-preview-result' ref={previewRef} />
  </div>
}

let alreadyConfiguredDOMPurify = false
function configureDOMPurify () {
  if (alreadyConfiguredDOMPurify) return

  DOMPurify.addHook('afterSanitizeAttributes', (node, data) => {
    // Set all anchor elements target to target=_blank
    const { nodeName } = node
    // Check if tag is anchor, A
    if (nodeName.toLowerCase() === 'a') {
      if (!node.getAttribute('href')) node.style.cssText = 'cursor: auto !important;'
      node.setAttribute('target', '_blank')
      // Prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
      node.setAttribute('rel', 'noopener noreferrer')
    }
  })

  alreadyConfiguredDOMPurify = true
}

function updateMathString (rawMath = '', isALRedesign, freezeOptions) {
  if (isEmpty(rawMath)) return rawMath

  const { datoCardType, isQuestionOption } = freezeOptions
  const shouldFreezeTable = canFreezeTable(datoCardType, isQuestionOption)

  // questions coming from backend were wrapped in p tags
  // Replacing them with div, to allow nested divs
  // <p> might contain attributes which must be preserved
  // <p> might contain a class, we need to merge our class with it.
  let math = rawMath.replace(/<p(.*?)>/g, (match, p1 = '') => {
    const [matchString, className] = (p1.match(/class=['|"](.*?)['|"]/i) || ['', ''])
    return `<div class='paragraph ${className}' ${p1.replace(matchString, '')}>`
  })
  math = math.replace(/<\/p>/g, '</div>')
  // Wrapping the equation with another div to add inline-block style.
  math = math.replace(/(<\/math>)?<math/g, (match, p1) => {
    return p1 ? match : '<div class=\'single_equation\'><math'
  })
  math = math.replace(/(<\/em>)?<em/g, (match, p1) => {
    return p1 ? match : '<span><em'
  })
  math = math.replace(/<\/em>(\s*(\.|\?|,|;|:|!))?(<em)?/g, (match, p1 = '', p2, p3) => {
    return p3 ? match : `</em>${p1}</span>`
  })
  math = math.replace(/<\/math>(\s*(\.|\?|,|;|:|!))?(<math)?/g, (match, p1 = '', p2, p3) => {
    return p3 ? match : `</math>${p1}</div>`
  })
  isALRedesign && !shouldFreezeTable && (math = math.replace(/(<\/table>)?<table/g, (match, p1) => {
    return p1 ? match : `
    <div class='tableWrapper'>
      <div class='tableContainer'>
      <table `
  }))
  isALRedesign && !shouldFreezeTable &&
    (math = math.replace(/<\/table>(\s*(\.|\?|,|;|:|!))?(<table)?/g, (match, p1) => {
      return p1 ? match : `
      </table>
      </div>
    </div>`
    }).replace(/<th/g, '<td'))

  // Updated table
  isALRedesign && shouldFreezeTable && (math = math.replace(/(<\/table>)?<table/g, (match, p1) => {
    return p1 ? match : `
    <div class='tableWrapperContainer'>
      <div class='tableScrollableButtonsWrapper'></div>
      <div class='tableWrapperScrollable'>
        <div class='tableContainerScrollable'>
        <table `
  }))
  isALRedesign && shouldFreezeTable &&
    (math = math.replace(/<\/table>(\s*(\.|\?|,|;|:|!))?(<table)?/g, (match, p1) => {
      return p1 ? match : `
        </table>
        </div>
      </div>
      </div>
    </div>`
    }).replace(/<th/g, '<td'))
  return math
}
