export { getPlural }

function getPlural (word, amount) {
  if (amount === 1) return word
  // add more possibilities if needed
  const plurals = {
    '(quiz)$': '$1zes',
    '([^aeiouy]|qu)y$': '$1ies',
    '([^s]+)$': '$1s'
  }
  // add function for irregular words
  for (const reg in plurals) {
    const pattern = new RegExp(reg, 'i')
    if (pattern.test(word)) {
      return word.replace(pattern, plurals[reg])
    }
  }
}
