import styled from 'styled-components'
import mediaqueries, { BREAKPOINTS } from '../../../mediaQueries'

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 31px;
  flex-wrap: wrap;

  .btn {
    width: 150px;
    margin: 6px 8px;
    margin-right: 8px;
    height: 40px;
    padding: 0px 8px;
  }

  .btn-secondary:disabled {
    background-color: #161618;
    border-color: #77b1af;
    color: #77b1af;
  }

  ${({ isAnsweringDisabled }) => `
    justify-content: flex-start;
    margin-top: ${isAnsweringDisabled ? '43px' : ''};

    .btn-secondary:disabled {
      background-color: #161618;
      border-color: #77b1af;
      color: #5FC4B8;
    }

    .btn-primary {
      background-color: #5FC4B8;
    }

    .btn {
      width: 64px;
      border: #5FC4B8 1px solid;
    }
  `}

  ${mediaqueries.mobile`
    .btn {
        width: 100%;
    }
    
    ${({ reviewMode }) => `
        .btn {
          width: ${reviewMode ? '157px' : '100%'};

          :first-child {
            margin-left: 0px !important;
            margin-right: ${reviewMode ? '12px !important' : '0px'};
          }

          :nth-child(2) {
            margin-left: 0px !important;
            margin-right: 0px !important;
          }
        }
      `}
  `}

  @media (max-width: ${BREAKPOINTS.mobile - 1}px) {
    .btn {
      width: 100% !important;
    }    
  }
`

export const TextWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 48px;
  display: flex;
  height: 19px;
  justify-content: space-between;
`

export const QuestionIconWrapper = styled.div`
  display: inline-flex;
`

export const Icon = styled.img`
  width: inherit;
  height: inherit;
  vertical-align: middle;
  border-style: none;
  margin-bottom: 18px;
`

export const QuestionText = styled.span`
  line-height: 19.2px;
  font-size: 16px;
  margin-left: 12px;
`

export const PinWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const PinText = styled.span`
  max-width: 93px;
  font-size: 16px;
  line-height: 19.2px;
  color: #98a4ae;
  text-align: right;
`

export const PinButtonWrapper = styled.span`
  width: 36px;
  height: 36px;
  margin-top: -6px;

`

export const PinButton = styled.img`
  margin-left: 12px;
  margin-bottom: 9px;
  width: 14px;
  height: 14px;
`

export const QuestionCard = styled.div`
  background-color: rgba(0, 0, 0, 0);
  padding: 0px !important;
`

export const LessonText = styled.div`
  ${mediaqueries.mobile`
    img {
      max-width: 100%;
    }
  `}
`

export const OffsetContainer = styled.div`
  width: 615px;
  margin-left: -58px;
`

export const JDoodleContainer = styled.div`
  height: 500px;
  overflow: hidden;
  & > iframe {
    height: 500px;
  }
`

export const CodingAnswersScoreBreakdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  margin-bottom: 24px;
`

export const TotalScoreContainer = styled.div`
  display: flex;
  gap: 4px;
  cursor: pointer;
  align-items: center;
`

export const TotalScore = styled.span`
  color: var(--B1BFC5---Cool-Grey, #B1BFC5);

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const TotalScorePercent = styled(TotalScore)`
  color: #FFF;
`

export const DisplayedScoreDetailsWrapper = styled.div`
  min-height: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const GroupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 16px;
  background: var(--232327---Gray-5, #232327);
  border-radius: 5px 5px 0 0;
`

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StepText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  padding: 8px 16px;
  background: var(--1D1D1F---Gray-6, #1D1D1F);
  ${GroupWrapper}:last-child & {
    border-radius: 0 0 5px 5px;
  }
`
