/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/

import React, { Component } from 'react'
import ViewSDKClient from './ViewSDKClient'
import { PdfContainer } from '../styled'

class FullWindow extends Component {
  componentDidMount () {
    const viewSDKClient = new ViewSDKClient()
    viewSDKClient.ready().then(async () => {
      const { pdf, callBack, title, readingUUID, getPagesInCb } = this.props
      const adobeViewer = await viewSDKClient.previewFile(
        `pdf-div-${readingUUID}`,
        { pdf, title, readingUUID },
        { defaultViewMode: 'FIT_WIDTH',
          showAnnotationTools: false,
          showDownloadPDF: false })
      if (!getPagesInCb) return callBack()
      const apis = await adobeViewer.getAPIs()
      const metaData = await apis.getPDFMetadata()
      callBack(metaData.numPages)
    })
  }

  render () {
    const { getPagesInCb, readingUUID } = this.props
    return <PdfContainer
      id={`pdf-div-${readingUUID}`}
      style={{ display: getPagesInCb ? 'none' : 'block' }}
    />
  }
}

export default FullWindow
