import React from 'react'
import { MainGridViewContainer } from '../FormComponent/style'

function GridView ({ progressBubbleButtons }) {
  return (
    <MainGridViewContainer> {progressBubbleButtons }</MainGridViewContainer>
  )
}

export default GridView
