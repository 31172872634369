import React from 'react'
import PropTypes from 'prop-types'
import {
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'

const CustomTooltip = ({ id, placement = 'top', text, children }) => {
  if (!text) return children

  return (
    <OverlayTrigger
      data-testid='overlay-trigger'
      key={text + placement}
      placement={placement}
      overlay={
        <Tooltip id={id} className='show'>
          {text}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

CustomTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  placement: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node
}

export default CustomTooltip
