import React, { useEffect, useRef, useContext } from 'react'
import { trackCopyScheduleEvent } from '../../utilities/chapterUtils'
import Context from '../Context/Context'
import { BorderLine, CalendarListContainer, CalendarListText } from './styles'

export default function CalendarList ({ handleCalendarDropdown, origin }) {
  const calendarRef = useRef()
  const {
    cohortData: {
      name,
      calendarEvents,
      calendarKey,
      officialCourseName
    }
  } = useContext(Context)

  const stopPropagation = (e) => {
    e.stopPropagation()
  }

  const loadScript = () => {
    const script = document.createElement('script')
    script.id = 'addEventScript'
    script.async = true
    script.type = 'text/javascript'
    script.defer = true
    script.src = 'https://cdn.addevent.com/libs/stc/1.0.2/stc.min.js'
    document.body.appendChild(script)
  }

  const checkIfClickedOutside = e => {
    // If the clicked target is not within the dropdown,
    // then close the dropdown
    const shouldHideDropdown = calendarRef.current &&
     !calendarRef.current.contains(e.target)
    if (shouldHideDropdown) {
      handleCalendarDropdown()
    }
  }

  const handleCalendarDownload = () => {
    calendarEvents.download('course-calendar.ics')
    trackCopyScheduleEvent({
      courseName: officialCourseName,
      cohort: name,
      pageName: origin
    })
  }

  useEffect(() => {
    const { addeventstc } = window
    addeventstc ? addeventstc.refresh() : loadScript()

    // adding event listener to listening the dropdown outside clicks
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <CalendarListContainer
      onClick={stopPropagation}
      ref={calendarRef}
    >
      {calendarKey &&
      <>
        <CalendarListText
          data-styling='none'
          title='Add to Calendar'
          data-direct='outlook'
          className='addeventstc'
          data-id={calendarKey}
        >
        Outlook Calendar
        </CalendarListText>

        <BorderLine />

        <CalendarListText
          title='Add to Calendar'
          className='addeventstc'
          data-direct='google'
          data-id={calendarKey}
        >
        Google Calendar
        </CalendarListText>

        <BorderLine />

        <CalendarListText
          title='Add to Calendar'
          className='addeventstc'
          data-direct='apple'
          data-id={calendarKey}
        >
        Apple Calendar
        </CalendarListText>

        <BorderLine />
      </>
      }

      <CalendarListText
        onClick={handleCalendarDownload}
        data-testid='calendarEvents'
        title='Download Calendar'
      >
        Download Calendar
      </CalendarListText>
    </CalendarListContainer>
  )
}

CalendarList.displayName = 'CalendarList'
