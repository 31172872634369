import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import api from '../api'
import config from '../config'
import { getStudentAnswersKey } from './contextKeys'
import { QUIZ, EXAM } from '../Constants/sectionType'
import { COURSE_STARTED } from '../Constants/eventTypes'

export {
  hasProgress,
  getLatestProgress,
  isGuessworkCompleteForSection,
  getEventStatus,
  getStudentAnswers,
  getButtonClass,
  hasCourseProgress,
  restructureTrialsArray
}

function isGuessworkCompleteForSection (studentProgress, sectionId) {
  const guessWorkComplete = studentProgress['guesswork-complete']
  return !!guessWorkComplete && !!guessWorkComplete[sectionId]
}

function isEmptyProgressObject (studentProgress) {
  const progress = cloneDeep(studentProgress)

  const isEmptyValue = Object.values(progress || {}).every(progressValue => {
    if (typeof progressValue !== 'object') return !progressValue

    const isEmptyProgress = isEmpty(progressValue)
    if (isEmptyProgress) return true

    return isEmptyProgressObject(progressValue)
  })

  return isEmptyValue
}

function hasCourseProgress (studentProgress) {
  if (!studentProgress) return false

  return !isEmptyProgressObject(studentProgress)
}

/**
 *
 * @param {Array} trialsArray an array of student trials in practice exercises question-type,
 *  its path is 'studentProgress.studentAnswers.trials'
 * @description Converts object-element trials array to string-element trials array
 * @example [{ trial: 0, result: 'correct' }, { trial: 1, result: 'incorrect' }]
 *  is converted to ['correct', 'incorrect], where  the index of each
 * element is the number of the question trial.
 * @returns {Array} string-element array
 */
function restructureTrialsArray (trialsArray) {
  if (!trialsArray) return
  if (!isObjectElementArray(trialsArray)) return trialsArray

  const trials = []
  trialsArray
    .filter(trial => typeof trial === 'object')
    .forEach(trialObject => (trials[trialObject.trial] = trialObject.result))

  return trials
}

/**
 *
 * @param {Array} trialsArray an array of student trials in practice exercises question-type,
 *  its path is 'studentProgress.studentAnswers.trials'
 * @description checks whether the trials array is an array of objects or strings
 * @returns {Boolean} returns 'True' is the trialsArray element is an object
 */
function isObjectElementArray (trialsArray) {
  if (!trialsArray) return
  return trialsArray.some(
    trial => typeof trial === 'object'
  )
}

async function getEventStatus (cohortID) {
  const data = {
    eventName: COURSE_STARTED
  }
  const { courseId } = config

  if (!cohortID) return false

  try {
    const { canTrackEvent } = await api
      .checkEventEligibility({ data, courseId, cohortID })
    return canTrackEvent
  } catch (e) {
    console.error(e)
  }
}

function getStudentAnswers (type, studentData) {
  const studentAnswersKey = getStudentAnswersKey(type)
  return studentData[studentAnswersKey]
}

function getButtonClass ({
  label, sectionType, reviewMode, type, studentData
}) {
  const studentAnswers = getStudentAnswers(type, studentData)
  let btnClass
  const foundAnswer = studentAnswers.find(
    answer => answer.uuid === label.uuid
  )
  const isPendingResponseAnswer = !reviewMode &&
  [QUIZ, EXAM].includes(sectionType)
  const isAnsweRevealed = reviewMode &&
  [QUIZ, EXAM].includes(sectionType)
  if (foundAnswer?.answer ||
      foundAnswer?.answer === 0 ||
      foundAnswer?.correct) {
    if (isPendingResponseAnswer) btnClass = 'response-submitted'
    else {
      const { correct } = foundAnswer
      btnClass = correct ? 'completed' : 'incorrect'
    }
  } else btnClass = !isAnsweRevealed ? 'empty' : 'incorrect'
  return btnClass
}

function hasProgress (params) {
  const { progressKey, uuid, studentProgress } = params || {}
  if (!studentProgress || !progressKey || !uuid) return false

  return !!studentProgress[progressKey]?.[uuid]
}

async function getLatestProgress () {
  const studentProgress = await api.getStudentProgress()
  return studentProgress
}
