import React from 'react'
import { DownArrow, ViewAllWrapper } from './styled'

const ShowOrHideAll = ({ text, showAll, setShowAll, paddingTop }) => {
  return (
    <ViewAllWrapper
      data-cy='viewInstructorButton'
      paddingTop={paddingTop}
      onClick={() => setShowAll(!showAll)}
    >
      <DownArrow isIconClicked={showAll} />
      <span>
        {showAll ? 'Hide' : 'Show'} {text}
      </span>
    </ViewAllWrapper>
  )
}

export default ShowOrHideAll
