import { measureText } from '../../utilities/domUtils'

export {
  replaceBlanksWithInputElem
}

function replaceBlanksWithInputElem (
  questionText,
  answers,
  measureFunc = measureText
) {
  let replacedQuestion

  // Replaces any series of 2 or more underscores (__) with an input element.
  // Note the %{answerLen}% string, which will be replaced after this step.
  replacedQuestion = questionText.replace(
    /_{2,}/g,
    '<input type="text" autocomplete="off" role="presentation" class="active__learning-blank" name="blank[]" ' +
    'style="width: %{answerLen}%px;">'
  )

  // Calculate the layout width of the answer text and change each input
  // element style to the max width of the answer.
  let maxWidth = 0
  answers.forEach((answer) => {
    const { width } = measureFunc(
      answer,
      'active-learning active__learning-block active__learning-blank'
    )
    if (width > maxWidth) maxWidth = width
  })

  // Replace all inputs answerLen temporary width holder to the max width
  replacedQuestion = replacedQuestion.replace(/%{answerLen}%/g, maxWidth)

  return replacedQuestion
}
