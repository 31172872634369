import isEqual from 'lodash/isEqual'
import {
  addHttpsIfSchemeNotExist,
  replaceHTMLWhiteSpaceWithSpace
} from '.'
import {
  GUESSWORK,
  QUIZ,
  EXAM,
  ACTIVE_LEARNING
} from '../Constants/sectionType'
import { emitter } from '../Components/Emitter/Emitter'
import { ON_SUBMIT_ANSWER } from '../Constants/emitterKeys'
import { AL_TEXT_BLANKS } from '../Constants/questionType'

const defaultStructuredTextValue = {
  schema: 'dast',
  document: {
    type: 'root',
    children: [
      {
        type: 'paragraph',
        children: [
          {
            type: 'span',
            value: ''
          }
        ]
      }
    ]
  }
}

export const getQuestionTitle = (question, index, sectionType) => {
  if (sectionType === ACTIVE_LEARNING) {
    return `Card ${index + 1}`
  } else if ([GUESSWORK, QUIZ, EXAM].includes(sectionType)) {
    return `Question ${index + 1}`
  }

  return question.title
}

export function getQuestionType (sectionsArray, index) {
  if (!sectionsArray || (!index && index !== 0) || !sectionsArray[index]) return

  return sectionsArray[index].quest_type
}

export const snapSelectionToWord = () => {
  let selection

  // Check for existence of window.getSelection() and that it has a
  // modify() method. IE 9 has both selection APIs but no modify() method.
  if (window.getSelection && (selection = window.getSelection()).modify) {
    selection = window.getSelection()
    if (!selection.isCollapsed) {
      // Detect if selection is backwards
      const range = document.createRange()
      range.setStart(selection.anchorNode, selection.anchorOffset)
      range.setEnd(selection.focusNode, selection.focusOffset)
      const backwards = range.collapsed
      range.detach()

      // modify() works on the focus of the selection
      const endNode = selection.focusNode; var endOffset = selection.focusOffset
      selection.collapse(selection.anchorNode, selection.anchorOffset)

      let direction = []
      if (backwards) {
        direction = ['backward', 'forward']
      } else {
        direction = ['forward', 'backward']
      }
      selection.modify('move', direction[0], 'character')

      selection.modify('move', direction[1], 'word')
      selection.extend(endNode, endOffset)

      selection.modify('extend', direction[1], 'character')

      selection.modify('extend', direction[0], 'word')
    }
  } else if ((selection = document.selection) && selection.type !== 'Control') {
    const textRange = selection.createRange()
    if (textRange.text) {
      textRange.expand('word')
      // Move the end back to not include the word's trailing space(s),
      // if necessary
      while (/\s$/.test(textRange.text)) {
        textRange.moveEnd('character', -1)
      }
      textRange.select()
    }
  }
}

export const addTextEvents = ({
  currentQuestionUUID,
  typeOfQuestion,
  isPinned,
  context
}) => {
  const highlightedDivs = Object.values(document.getElementsByClassName('highlighted'))
  if (highlightedDivs.length) {
    highlightedDivs.forEach((highlightedDiv) => {
      highlightedDiv.onclick = function () {
        const parent = highlightedDiv.parentNode
        while (highlightedDiv.firstChild) {
          parent.insertBefore(highlightedDiv.firstChild, highlightedDiv)
        }
        highlightedDiv.remove()
      }
    })
  }
  const mathJaxDiv = document.getElementById('question_text')
  if (!mathJaxDiv) return

  if (!mathJaxDiv.getElementsByClassName('single_equation').length) {
    addListeners({
      currentQuestionUUID,
      typeOfQuestion,
      isPinned,
      context,
      mathJaxDiv,
      questionDiv: true
    })
  }
  const mathJaxDivLesson = document.getElementById('lesson_text')
  if (!mathJaxDivLesson) return

  if (!mathJaxDivLesson.getElementsByClassName('single_equation').length) {
    addListeners({
      currentQuestionUUID,
      typeOfQuestion,
      isPinned,
      context,
      mathJaxDiv: mathJaxDivLesson
    })
  }
}

export const addListeners = ({
  currentQuestionUUID,
  typeOfQuestion,
  isPinned,
  context,
  mathJaxDiv,
  questionDiv }) => {
  mathJaxDiv.onmousedown = function () {
    const divId = questionDiv ? 'lesson_text' : 'question_text'
    const textDiv = document.getElementById(divId)
    textDiv && textDiv.classList.add('unSelectable')
  }
  mathJaxDiv.onmouseup = function () {
    eventsHandler({ currentQuestionUUID,
      typeOfQuestion,
      isPinned,
      context,
      mathJaxDiv,
      questionDiv })
  }

  mathJaxDiv.oncontextmenu = function () {
    eventsHandler({ currentQuestionUUID,
      typeOfQuestion,
      isPinned,
      context,
      mathJaxDiv,
      questionDiv })
  }
}

export const eventsHandler = ({
  currentQuestionUUID,
  typeOfQuestion,
  isPinned,
  context,
  mathJaxDiv,
  questionDiv
}) => {
  const { updateContext, studentData } = context
  const { studentAnswers } = studentData
  const divId = questionDiv ? 'lesson_text' : 'question_text'
  const textDiv = document.getElementById(divId)
  textDiv && textDiv.classList.remove('unSelectable')
  const div = document.createElement('div')
  div.setAttribute('style', 'background: #B1BFC5;color: #000000;width: fit-content;display: inline-block;')
  div.setAttribute('class', 'highlighted')
  if (!window.getSelection) return

  const selectedText = window.getSelection()
  const focusNode = selectedText.focusNode
  const isMultiDivSelection = (selectedText.anchorNode.parentElement !==
   selectedText.focusNode.parentElement) &&
    (focusNode.length > 1 || focusNode.length === undefined)
  if (isMultiDivSelection) return

  // converts current selection from characters to word
  snapSelectionToWord()
  if (!selectedText.rangeCount) return

  const range = selectedText.getRangeAt(0).cloneRange()
  const content = range.extractContents()
  div.appendChild(content)
  if (!div.innerText) return

  range.insertNode(div)
  const isLessonText = mathJaxDiv.classList.contains('lesson_text')
  const textKey = isLessonText ? 'lessonText' : 'questionText'

  const answer = {
    uuid: currentQuestionUUID,
    type: typeOfQuestion.toLocaleLowerCase(),
    questionActivity: {
      pinned: isPinned,
      highlighted: {
        [textKey]: mathJaxDiv.innerHTML.toString()
      }
    }
  }
  const foundAnswer = findAnswer(currentQuestionUUID, studentAnswers)
  if (!foundAnswer || !foundAnswer?.questionActivity?.highlighted) {
    studentData.studentAnswers = [...studentAnswers, answer]
    emitter.emit(ON_SUBMIT_ANSWER, answer)
    updateContext({ studentData: studentData })
    return
  }

  const restAnswers = studentAnswers.filter(answer => answer.uuid !== currentQuestionUUID)
  foundAnswer.questionActivity.highlighted[textKey] = mathJaxDiv.innerHTML.toString()
  studentData.studentAnswers = [...restAnswers, foundAnswer]
  emitter.emit(ON_SUBMIT_ANSWER, foundAnswer)
  updateContext({ studentData })
}

export const getQuestionIndexByTheme = (question, questionCounterByTheme) => {
  const instructorKey = question.instructor.instructor_uuid
  questionCounterByTheme[instructorKey] =
    instructorKey in questionCounterByTheme ? questionCounterByTheme[instructorKey] + 1 : 0
  return questionCounterByTheme[instructorKey]
}

export const findAnswer = (questionUuid, studAns) => {
  if (!studAns || !studAns.length || !questionUuid) return
  return studAns.find(q => q.uuid === questionUuid)
}

export const isMultiAnswerSingleString = (answer) => {
  const isAnswerConcatenatedString =
  answer.length === 1 && answer[0].length > 1
  if (isAnswerConcatenatedString) {
    answer = answer[0].split(',')
  }
  return answer
}

export const highlightedText = (questionUUID, studentAnswers) => {
  const fAns = findAnswer(questionUUID, studentAnswers)
  const highlighted = fAns?.questionActivity?.highlighted
  return highlighted
}

export const getHyperlinkedTitle = (title, fields) => {
  if (!title) return ''
  if (!fields) return title

  Object.keys(fields).forEach((key) => {
    const regex = new RegExp(key, 'gi')

    title = title.replace(
      regex,
      str => `<a target='_blank' rel='noreferrer' href='${addHttpsIfSchemeNotExist(fields[key])}'>${str}</a>`
    )
  })

  return title
}

export const getGMExpressionsStartAndGoal = gmExpressions => {
  if (!gmExpressions) return null

  const PARAGRAPH_REGEX = /<p>\s*(.*?)\s*<\/p>/g
  const DELIMITER = ';'

  const spaceRemovedExpressions = replaceHTMLWhiteSpaceWithSpace(gmExpressions)
  const startAndGoals = []
  let match

  while ((match = PARAGRAPH_REGEX.exec(spaceRemovedExpressions))) {
    const [, expression] = match
    if (!expression) continue

    const [start, goal = null] = expression.split(DELIMITER)
    startAndGoals.push({
      start: formatLatexExpression(start),
      goal: formatLatexExpression(goal)
    })
  }

  if (!startAndGoals.length) return null

  return startAndGoals
}

export const formatLatexExpression = expression => {
  if (!expression) return expression

  // remove mathrm from pi
  expression = expression.replace('\\mathrm\\pi', '\\pi')

  return expression
}

export const getIllustrationAlt = question => {
  if (!question) return null

  const {
    illustration_alt: illustrationAlt,
    illustrationProps
  } = question

  if (illustrationAlt) return illustrationAlt

  if (!illustrationProps) return null
  const { alt } = illustrationProps

  return alt
}

export const hasNpercentCardsCompleted = percent => (cards, studentAnswers) => {
  if (!cards || !cards.length || !studentAnswers) return false

  const numberOfCompletedCards = getNumberOfCompletedCardsFromSet(
    cards, studentAnswers
  )
  const totalCards = cards.length

  const completedPercent = (numberOfCompletedCards * 100) / totalCards
  return completedPercent >= percent
}

export const getNumberOfCompletedCardsFromSet = (cards, studentAnswers) => {
  if (!cards || !studentAnswers) return 0

  return cards.reduce((total, card) => {
    const isCardCompleted = studentAnswers.some(answer => {
      const { uuid, correct } = answer
      return (card.Question_uuid === uuid) && correct
    })

    return isCardCompleted ? total + 1 : total
  }, 0)
}

export const has70PercentCardsCompleted = hasNpercentCardsCompleted(70)

export const formatQuestionExplanation = explanation => {
  if (!explanation) return ''

  // Since We want to use the color set in Dato we should remove black texts,
  // (Outlier background is also black) this way we can use the default color.
  explanation = explanation.replace(/color\s*:\s*#000(?:000)?/gi, '')
  // Replace the default Arial font to Lato, and use the other fonts
  // as they're set in Dato(This way content users can control the font of texts).
  explanation = explanation.replace(/font-family\s*:\s*Arial/gi, 'font-family:Lato')

  return explanation
}

/**
 * This function checks if the currentQuestion is last question in the set.
 * @param {Array} questions array of all the questions in a set
 * @param {string} currentQuestionId id of the current active question
 * @returns {Boolean} if the question is last question in set
 */
export const isLastQuestionInSet = (questions = [], currentQuestionId = '', unAnsweredCount) =>
  questions.length ? questions.findIndex(
    question => question.Question_uuid === currentQuestionId
  ) === questions.length - 1 && unAnsweredCount !== 1 : false

export const filterOutStructuredTextBlockType = (structuredValue) => {
  if (!structuredValue?.document?.children) return structuredValue

  return {
    ...structuredValue,
    document: {
      ...structuredValue.document,
      children: structuredValue.document.children.filter(item => item.type !== 'block')
    }
  }
}

export const filterStructuredTextBlockImages = (structuredBlocks) => {
  if (!Array.isArray(structuredBlocks)) return null

  return structuredBlocks.filter(item => !!item.image)
}

export const isEmptyStructuredTextValue = (structuredValue) => {
  if (!structuredValue?.document?.children) return true

  const { children } = structuredValue.document
  if (children?.length === 0) return true

  return isEqual(structuredValue, defaultStructuredTextValue)
}

export const canFreezeTable = (cardType, isQuestionOption) => {
  if (isQuestionOption) return false

  return cardType !== AL_TEXT_BLANKS
}
