import React, { useMemo, memo } from 'react'
import {
  StyledBody,
  StyledCardWrapper,
  StyledContentWrapper,
  StyledFooter,
  StyledHeader
} from './StandardOverlayCard.styled'
import CourseButton from '../../CourseButton/CourseButton'
import classNames from 'classnames'
import Loader from '../../Loader/Loader'

const StandardOverlayCard = ({
  title,
  headerIcon,
  children,
  isLoading,
  buttons = []
}) => {
  const isMobile = window.matchMedia('(max-width: 575px)').matches

  const _buttons = useMemo(() => {
    return buttons.map((button, index) => {
      return (
        <CourseButton
          key={index}
          onClick={button.onClick}
          disabled={button.disabled}
          className={classNames(button.className, 'btn btn-primary')}
          style={isMobile ? { height: '48px', width: '100%' } : {}}
          dataTestId={button.dataTestId}
        >
          {button.text}
        </CourseButton>
      )
    })
  }, [buttons, isMobile])

  return (
    <StyledCardWrapper>
      <StyledContentWrapper>
        <StyledHeader>
          {headerIcon}
          {title}
        </StyledHeader>
        <StyledBody>
          {children}
          <StyledFooter>{_buttons}</StyledFooter>
        </StyledBody>
      </StyledContentWrapper>
      {isLoading && <Loader />}
    </StyledCardWrapper>
  )
}

export default memo(StandardOverlayCard)
