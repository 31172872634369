import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: flex;
  gap: 21px;
  margin-bottom: 16px;
  flex-wrap: wrap;
`

export const StyledFreezeItemWrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const StyledFreezeItem = styled.div`
  display: flex;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${props => props.active ? '#FFFFFF' : '#5FC4B8'};
  cursor: pointer;
  ${({ disabled }) => disabled && `
    pointer-events: none;
    color: #5B6067;

    .icon {
      background-color: #5B6067;
    }
  `}

  &:hover {
    color: #C7F6F0;

    .icon {
      background-color: #C7F6F0;
    }
  }

  &:active, &:focus {
    color: #5B6067;

    .icon {
      background-color: #5B6067;
    }
  }
`

export const StyledFreezeItemContainer = styled.div`
  display: flex;
  gap: 8px;
`

export const Icon = styled.span`
  mask: url(/images/icons/${props => props.iconName}.svg) no-repeat 50% 50%;
  background-color: ${props => props.bgColor ? props.bgColor : props.active ? '#FFFFFF' : '#5FC4B8'};
  width: ${props => props.width || 24}px;
  height: ${props => props.height || 18}px;
`
