import styled from 'styled-components'
import { BREAKPOINTS } from '../../../mediaQueries'

export const MainWrapper = styled.div`
  margin-top: 116px;
  @media (max-width: ${BREAKPOINTS.mobile - 1}px) {
    margin-top: ${props => props.isAssessmentQuizExam && '92px'}
  }
  @media (max-width: ${BREAKPOINTS.small}px) {
    margin-top: ${props => props.isAssessmentQuizExam && '154.2px'}
  }
`

export const ScorePageUI = styled.div`
  max-width: 500px;
  height: 199px;
  margin: auto;
  font-weight: 300;
  font-size: 18px;
  line-height: 21.6px;
  @media (max-width: ${BREAKPOINTS.mobile - 1}px) {
    max-width: ${props => props.isAssessmentQuizExam && '327px'};
    height: ${props => props.isAssessmentQuizExam && '221px'}
  }
`

export const Score = styled.div`
  font-size: 36px;
  line-height: 43.2px;
  width: max-content;
  height: 43px;
  margin-bottom: 24px;
  @media (max-width: ${BREAKPOINTS.mobile - 1}px) {
    width: ${props => props.isAssessmentQuizExam && '327px'}
  }
`
export const ButtonDiv = styled.div`
  display: flex;
  margin-top: ${props => props.isExam ? '24px' : '48px'};
  button {
    width: 157px;
    height: 40px;
    }
  .btn-primary {
    background-color: #5FC4B8;
    margin-right: 12px;
  }
  .btn-secondary {
    border: 1px solid #5FC4B8;
    color: #5FC4B8
  }
}
`
