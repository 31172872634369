import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const ToggleButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: ${props => props.isLocked ? '15px' : '0px'};
    width: 32px;
    height: 32px;
    border: 1px solid #4b4b4c;
    border-radius: 5px;
    cursor: pointer;

    i {
        color: #4b4b4c;
        font-size: 20px;
        font-weight: bold;
    }
`

export const Time = styled.p`
    margin: 0px;
    font-size: 14px;
    font-family: "Lato", sans-serif;
    margin-right: 10px;
    margin-top: 10px;
    color: #B1BFC5;
    font-weight: 400;
    line-height: 17px;
`

export const TimeComplete = styled(Time)`
    color: #77b1af;
`

export const VideosWrapper = styled.div`
  padding: ${({ isPracticeDrawer }) => isPracticeDrawer ? 0 : '0px 12px'};
  background-color: #0F0F0F;
`

export const VideoWrapper = styled.div`
    background-color: #0F0F0F;
    display: flex;
    justify-content: space-between;
    align-items: ${props => props.isLocked ? 'center' : 'flex-start'};
    padding: 24px 0px;
    & p {
        margin: 0px;
    }
`

export const Counter = styled.p`
    font-size: 13pt;
    margin-right: 10px !important;
    line-height: 19px;
`

export const Left = styled.div`
    flex-grow: 1;
    margin-right: 80px;
    overflow: hidden;
    flex: 1;
    ${mediaqueries.mobile`margin-right: 10px;`}
`

export const Right = styled.div`
    flex: 0 0 1
  `
export const Content = styled.div``

export const Title = styled.p`
    font-size: 16px;
    font-family: "Lato", sans-serif;
    font-weight: bold;
    line-height: 19px;
    margin-bottom: 5px !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    text-overflow: ellipsis;
`

export const SubTitle = styled.p`
    font-size: 16px;
    line-height: 19.2px;
    font-family: "Lato", sans-serif;
    margin-top: 12px !important;
`
