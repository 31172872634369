import React from 'react'

export default function FreeformAuthoringDisplay () {
  return (
    <>
      <div id='tabs' style={{ display: 'none' }}>
        <ul>
          <li>
            <button id='tabauthoring' className='tabactive'>
              Teacher | Authoring
            </button>
          </li>
          <li>
            <button id='tabdelivery'>Student | Delivery</button>
          </li>
        </ul>
        <div id='tabindicator' className='authoringindicator' />
      </div>

      <div id='authoring'>
        {/* This textarea will be replaced with
        a CKEditor enchanced with MathType Plugin */}
        <textarea name='text' id='htmleditor' rows='5' cols='10' />
        <h2>Correct answer</h2>
        <div id='correctAnswer'>
          <span />
        </div>
        <h2>Feedback text</h2>

        {/* This textarea will be replaced with
        a CKEditor enchanced with MathType Plugin */}
        <textarea id='feedbackText' rows='5' cols='10' />

        {/* Put here the desired language
        for Wiris Quizzes Studio and MathType. Available languages are
           "en", "es", "ca", "pt", "pt_br",
            "it", "fr", "de", "da", no", "nn", "el" */}
        <input type='hidden' className='wirislang' value='en' />
      </div>
    </>
  )
}
