let calculator = null

export default function desmos (expr) {
  var elt = document.getElementById('calculator')
  if (elt) {
    calculator = window.Desmos?.GraphingCalculator(elt)
    if (!calculator) return
    calculator.updateSettings(expr.settings)
    calculator.setState(expr.state)
    calculator.updateSettings({
      settingsMenu: false,
      zoomButtons: false,
      keypad: false,
      expressions: false,
      backgroundColor: '#4f515a'
      // invertedColors:true,
      // fontSize: 12,
    })
  }

  return calculator
}
