import React, { useContext } from 'react'
import LockedMarker from '../LockedMarker/LockedMarker'
import CourseButton from '../CourseButton/CourseButton'
import Context from '../Context/Context'
import { ReadingButtonWrapper } from './styled'
const ReadingItemButton = props => {
  const { isLocked, isReadingOpened, handleClick } = props
  const {
    isCohortEndedForStudent
  } = useContext(Context)

  if (isLocked) return <LockedMarker />

  return (
    <ReadingButtonWrapper>
      <CourseButton
        onClick={handleClick}
        style={{ minWidth: '119px', lineHeight: '22px' }}
        className={isReadingOpened ||
        isCohortEndedForStudent ? { 'btn-secondary': true } : { 'btn-primary': true }}
        dataCypress={isReadingOpened ? 'reviewReadingButton' : 'openReadingButton'}>
        {isCohortEndedForStudent
          ? <span>View</span> : isReadingOpened
            ? <span>Review</span> : <span>Open</span>}
      </CourseButton>
    </ReadingButtonWrapper>
  )
}

export default ReadingItemButton
