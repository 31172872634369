import React, { useMemo, useEffect } from 'react'
import config from '../../config'
import { QuestionWrapper } from './styles'
import MathJax from '../MathJax/MathJax'
import { TEXT_BLANK, CARD } from '../../Constants/questionType'
import DesmosgraphComponent from '../Desmonsgraph/DesmosgraphComponent'
import AttributedImage from '../AttributedImage/AttributedImage'
import { formatBoldTags, replaceBlockquoteDoubleQuotation } from '../../utilities'
import { setDatoUrl } from '../../utilities/dato'
import {
  getHyperlinkedTitle,
  getIllustrationAlt } from '../../utilities/questions'
import { replaceBlanksWithInputElem } from '../TextBlanksQuestion/TextBlanksQuestionShared'
import { useStudentDataContext } from '../Context/Context'

export default function Question ({
  questionSet,
  activeQuestionIndex
}) {
  const {
    id: datoRecordId,
    dato_card_type: cardType,
    lesson_text: lessonText,
    question_type: questionType,
    question_text: questionText,
    desmos_graph: desmosGraph,
    illustration,
    illustration_title: illustrationTitle,
    illustrationProps,
    answer: answers
  } = useMemo(() => {
    return questionSet?.[activeQuestionIndex] || {}
  }, [questionSet, activeQuestionIndex])

  const { updateContext } = useStudentDataContext()

  useEffect(() => {
    setDatoUrl({
      datoRecordId,
      cardType,
      questionType
    }, updateContext)

    return () => {
      updateContext({ activeLearningDatoLink: '' })
    }
    // eslint-disable-next-line
  }, [activeQuestionIndex])

  function showLessonText () {
    const isCardType = questionType === CARD
    let text = lessonText
    const regex = /&quot;/
    const quoteLength = (lessonText?.match(new RegExp(regex, 'g')))?.length
    for (let index = 0; index < quoteLength; index++) {
      const isEven = index % 2 === 0
      if (isEven) {
        text = text.replace(regex, '“')
      } else {
        text = text.replace(regex, '”')
      }
    }

    if (config.course.showRegularQuotationInsideDoubleQuoteElement) {
      text = replaceBlockquoteDoubleQuotation(text)
    }

    const newLessonText = isCardType
      ? `<div id='card-lesson-text'>${text}</div>`
      : text

    const formattedLessonText = formatBoldTags(newLessonText)

    return lessonText
      ? <MathJax math={formattedLessonText} /> : ''
  }

  function showQuestionText () {
    if (!questionText) return ''
    if (questionType !== TEXT_BLANK) {
      const whiteQuestionText = questionText &&
        questionText.replace(/<span style="font-size:11pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Arial"><span style="color:#000000"><span style="font-weight:400">/g,
          '<span style="font-size:18px; font-variant:normal; white-space:pre-wrap"><span style="font-family:Lato"><span style="color:#ffffff"><span style="font-weight:300">')
      return <MathJax math={formatBoldTags(whiteQuestionText)} />
    }

    return (
      <MathJax math={replaceBlanksWithInputElem(
        formatBoldTags(questionText),
        answers
      )} />
    )
  }

  function showDesmosGraph () {
    if (!desmosGraph) return null

    return (
      <DesmosgraphComponent
        expression={desmosGraph}
        illustration={illustration}
        questionType={questionType}
        noteMargin={'0px'}
      />
    )
  }

  function displayIllustration () {
    if (!illustration || !illustrationProps) return

    const { fields } = illustrationProps
    const illustrationStr = typeof illustration === 'object'
      ? illustration.url?.toString()
      : illustration.toString()

    if (!illustrationStr) return

    const illustrationAlt = getIllustrationAlt(
      questionSet[activeQuestionIndex])
    const title = illustrationProps?.title || illustrationTitle
    const illustrationTitleStr = title
      ? getHyperlinkedTitle(title, fields) : ''

    return (
      <AttributedImage
        alt={illustrationAlt}
        src={illustrationStr}
        title={illustrationTitleStr}
      />
    )
  }

  if (!questionSet?.length || (!activeQuestionIndex && activeQuestionIndex !== 0)) return null

  return (
    <QuestionWrapper>
      {showLessonText()}
      {displayIllustration()}
      <div style={{ fontWeight: '300' }}>
        {showQuestionText()}
      </div>
      {showDesmosGraph()}
    </QuestionWrapper>
  )
}
