import {
  PERMISSION_REQUESTED_KEY as GUARDIAN_PERMISSION_REQUESTED,
  PERMISSION_GRANTED_KEY as GUARDIAN_PERMISSION_GRANTED,
  PROF_CERT_GUARDIAN_PERMISSION_REQUESTED,
  PROF_CERT_GUARDIAN_PERMISSION_GRANTED,
  GUARDIAN_PERMISSION_PATH,
  PROF_CERT_GUARDIAN_PERMISSION_PATH
} from '../../Constants'

export const isGuardianPermissionPath = (pathName) => {
  return [
    GUARDIAN_PERMISSION_PATH,
    PROF_CERT_GUARDIAN_PERMISSION_PATH
  ].includes(pathName)
}

export const getGuardianPermissionKeys = (isProfCertGuardianPermission) => {
  if (isProfCertGuardianPermission) {
    return {
      guardianPermissionRequestedKey: PROF_CERT_GUARDIAN_PERMISSION_REQUESTED,
      guardianPermissionGrantedKey: PROF_CERT_GUARDIAN_PERMISSION_GRANTED
    }
  }

  return {
    guardianPermissionRequestedKey: GUARDIAN_PERMISSION_REQUESTED,
    guardianPermissionGrantedKey: GUARDIAN_PERMISSION_GRANTED
  }
}

export const getPermissionRequiredTexts = (isProfCertGuardianPermission) => {
  if (isProfCertGuardianPermission) {
    return {
      description: `Because you’re under 18 years old, Golden Gate University
      requires your parent or guardian to agree to three statements on
      your behalf. Please provide us with their email address below to
      get started.
      `
    }
  }

  return {
    description: `It’s awesome you’re getting an early start on college-level
    courses, but because you are under 18 years old, first your
    parent or guardian will need to agree to our Terms of Use and
    Privacy Policy for you.
    Please provide us with their email address below to get started.
    `
  }
}

export const getPermissionGrantedTexts = (isProfCertGuardianPermission) => {
  if (isProfCertGuardianPermission) {
    return {
      buttonText: 'Let’s go',
      description: 'You now have permission to take our Professional Certificate courses.'
    }
  }

  return {
    buttonText: 'Continue',
    description: `Your parent/guardian gave us the thumbs up for you take this course.
    Thanks for helping us reach out to them! Continue below to get
    started with your coursework.`
  }
}
