import React, {
  useState,
  memo,
  useEffect,
  useMemo,
  useCallback,
  Fragment
} from 'react'
import {
  StyledFreezeItem,
  StyledWrapper,
  StyledFreezeItemContainer,
  Icon
} from './TableColumnRowFreezeComponent.styled'
import './TableColumnRowFreezeComponent.css'
import TooltipOverlayCard from '../../Tooltip/Card/TooltipOverlayCard'
import { FREEZE_TABLE_VIEWED } from '../../../Constants/studentContext'
import api from '../../../api'

const renderOverlayContent = () => (
  <span>
    By freezing the top row and/or the first column, you'll be
    able to scroll through content while continuing to view the
    frozen cells.
  </span>
)

const adminFreezeTableViewedLocalStorageKey = 'OUTLIER_FREEZE_TABLE_VIEWED_'

const TableColumnRowFreezeComponent = ({
  disableColumnFreeze,
  disableRowFreeze,
  tableElement,
  freezeTableViewed,
  isAdmin,
  userEmail,
  updateFreezeTableViewed
}) => {
  const [isColumnFreezed, setIsColumnFreezed] = useState(false)
  const [isRowFreezed, setIsRowFreezed] = useState(false)
  const [isSavingFreezeTableViewed, setIsSavingFreezeTableViewed] = useState(false)
  const [isFreezeTableViewed, setIsFreezeTableViewed] = useState(freezeTableViewed)

  const isMobile = window.matchMedia('(max-width: 575px)').matches

  useEffect(() => {
    setIsFreezeTableViewed(freezeTableViewed)
  }, [freezeTableViewed])

  // Since we don't have student-data for admins, we store it on localStorage
  useEffect(() => {
    if (!isAdmin) return

    const isFreezeTableViewed = localStorage.getItem(
      adminFreezeTableViewedLocalStorageKey + userEmail
    )
    if (!isFreezeTableViewed) return

    setIsFreezeTableViewed(true)
  }, [userEmail, isAdmin])

  const toggleColumnFreeze = () => {
    if (disableColumnFreeze) return
    setIsColumnFreezed(!isColumnFreezed)
  }

  const toggleRowFreeze = () => {
    if (disableRowFreeze) return
    setIsRowFreezed(!isRowFreezed)
  }

  useEffect(() => {
    if (isColumnFreezed) {
      tableElement.classList.add('column-freeze')
      return
    }

    tableElement.classList.remove('column-freeze')
  }, [isColumnFreezed, tableElement])

  useEffect(() => {
    if (isRowFreezed) {
      tableElement.classList.add('row-freeze')
      return
    }

    tableElement.classList.remove('row-freeze')
  }, [isRowFreezed, tableElement])

  const renderIcon = (isRow) => {
    const iconName = isRow ? 'icon-frozen-row-table' : 'icon-frozen-column-table'
    const isActive = isRow ? isRowFreezed : isColumnFreezed
    const isDisabled = isRow ? disableRowFreeze : disableColumnFreeze

    return (
      <Icon
        className='icon'
        iconName={iconName}
        active={isActive}
        disabled={isDisabled}
      />
    )
  }

  const setFreezeTableViewed = useCallback(async () => {
    // For admins we would like to store it on localStorage
    if (isAdmin) {
      localStorage.setItem(
        adminFreezeTableViewedLocalStorageKey + userEmail, true
      )
      setIsFreezeTableViewed(true)
      return
    }

    setIsSavingFreezeTableViewed(true)
    try {
      const response = await api.setStudentData(
        FREEZE_TABLE_VIEWED, { [FREEZE_TABLE_VIEWED]: true }
      )
      if (response.data?.success) {
        updateFreezeTableViewed()
      }
    } finally {
      setIsSavingFreezeTableViewed(false)
    }
  }, [isAdmin, updateFreezeTableViewed, userEmail])

  const overlayButtons = useMemo(() => {
    return [
      {
        text: "Let's Try!",
        dataTestId: 'btn-lets-try',
        onClick: (e) => {
          e.stopPropagation()
          setFreezeTableViewed()
        }
      }
    ]
  }, [setFreezeTableViewed])

  const getFreezeTopRowItem = () => {
    if (disableRowFreeze) return null

    return (
      <StyledFreezeItem
        onClick={toggleRowFreeze}
        active={isRowFreezed}
        disabled={disableRowFreeze}
      >
        <StyledFreezeItemContainer>
          {renderIcon(true)}
          <span>Freeze Top Row</span>
        </StyledFreezeItemContainer>
      </StyledFreezeItem>
    )
  }

  const getFreezeFirstColumnItem = () => {
    if (disableColumnFreeze) return null

    return (
      <StyledFreezeItem
        onClick={toggleColumnFreeze}
        active={isColumnFreezed}
        disabled={disableColumnFreeze}
      >
        <StyledFreezeItemContainer>
          {renderIcon(false)}
          <span>Freeze First Column</span>
        </StyledFreezeItemContainer>
      </StyledFreezeItem>
    )
  }

  const freezeItems = [getFreezeTopRowItem(), getFreezeFirstColumnItem()]

  const getFirstValidFreezeItemIndex = () => {
    return freezeItems.findIndex(Boolean)
  }

  const getLastValidFreezeItemIndex = () => {
    return freezeItems.length - 1 - freezeItems.slice().reverse().findIndex(Boolean)
  }

  const getFreezeItemTooltipIndex = () => {
    const firstValidFreezeItemIndex = getFirstValidFreezeItemIndex()
    // If it's not mobile, we would like to display the tooltip on the first item
    if (!isMobile) return firstValidFreezeItemIndex

    // If it's mobile, we would like to display the tooltip on the last item
    const lastValidFreezeItemIndex = getLastValidFreezeItemIndex()
    return lastValidFreezeItemIndex
  }

  const renderFreezeItemWithTooltip = (item, index) => {
    if (!item) return null

    if (index !== getFreezeItemTooltipIndex()) return item

    return (
      <TooltipOverlayCard
        title='New feature: Freeze Row and Column!'
        arrowPlacement={isMobile ? 'center' : 'start'}
        headerIcon={isMobile ? null : <Icon
          className='icon'
          iconName='sparkle'
          width={18}
          height={24}
          bgColor='#969BFF'
        />}
        renderOverlayContent={renderOverlayContent}
        buttons={overlayButtons}
        show={!isFreezeTableViewed}
        isLoading={isSavingFreezeTableViewed}
      >
        {item}
      </TooltipOverlayCard>
    )
  }

  return (
    <StyledWrapper>
      {freezeItems.map((item, index) => (
        <Fragment key={index}>
          {renderFreezeItemWithTooltip(item, index)}
        </Fragment>
      ))}
    </StyledWrapper>
  )
}

TableColumnRowFreezeComponent.displayName = 'TableColumnRowFreezeComponent'

export default memo(TableColumnRowFreezeComponent)
