import styled from 'styled-components'

export const TooltipIcon = styled.span`
  margin-left: 11px;
  margin-right: 11px;
  width: ${props => props.width ? props.width : 20.13}px;
  height: ${props => props.height ? props.height : 20.13}px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  background: ${props => props.bgColor ? props.bgColor : 'rgba(255, 255, 255, 0.227)'};
`
