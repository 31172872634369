import styled, { css } from 'styled-components'
import media, { BREAKPOINTS } from '../../../../src/mediaQueries'
import { TRUE_FALSE, MULTIPLE_CHOICE } from '../../../Constants/questionType'

export const ProgressButton = styled.img`
  margin-right: 16px;
  cursor: pointer;
  width: 22px;
  height: 22px;

  @media only screen and (max-width: 576px){
    ${props => !props.isAssessmentQuizExam && `
      width: 32px;
      height: 32px;
    `}
  }

  ${({ isTouchDevice }) => isTouchDevice && `
      width: 32px;
      height: 32px;
  `}

  ${({ isAssessmentQuizExam, isActiveLearning }) => isAssessmentQuizExam && `
      width: 16px;
      height: 16px;
      margin-bottom: ${!isActiveLearning && '8px'};
      margin-right: 12px;
  `}

  ${({ isActiveIcon, isActiveLearning }) => isActiveIcon && `
    width: 20px;
    height: 20px;
    position: absolute;
    left: 22px;
    ${isActiveLearning && 'margin-bottom: 8px'};
  `}
`
export const ButtonWrapper = styled.div`
  ${({ isPinned }) => isPinned && `
    padding-right: 12px !important;
  `}

  ${({ isActiveLearning }) => isActiveLearning && `
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    height: auto !important;
    line-height: 1 !important;
  `}
`

export const PinnedIcon = styled.img`
    width: 14px;
    height: 14px;
    margin-bottom: 7px;
    margin-left: 12px;
    @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
      position: absolute;
      right: 24px;
    }
`
export const GridPinnedIcon = styled.img`
  top: ${props => props.isCurrentQuestion ? '56px' : '29px'};
`

export const MainGridViewContainer = styled.div`
  padding: 0 !important;
  display: grid;
  grid-template-columns: repeat(auto-fill,48px);

  @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
    grid-column-gap: 15px;
    width:auto !important;
  }
  @media (max-width: ${BREAKPOINTS.mobile - 1}px) {
    grid-column-gap: 12px;
  }

  div {
    display: flex;
    flex-direction: column-reverse;
    width: 48px !important;
    height: 74px !important;
    padding: 0px 16px 15px 16px !important;
    text-align: center;
    margin-bottom: 0 !important;

    @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
      height:64px !important;
      margin-bottom: 12px !important;
    }
    .listPinnedIcon {
      display: none;
    }
    .girdPinnedIcon {
      display: block !important;
      width: 4px;
      height: 4px;
      position: relative;
      left: 12px;
    }
    span {
      width: auto !important;
      margin-bottom: 6px;
    }
    span:before {
      content: '' !important
    }
    .active {
      position: relative;
      top: 26px;
      left: -2px;

    }
    .active + span {
      position: relative;
      top: 28px;
    }
  }

  .card-title {
    display: none !important;
  }
`

export const QuestionText = styled.span`
 ${props => props.isAssessmentQuizExam && `
    position: relative;
    bottom: 2px;
  `}
`
export const OptionsWrapper = styled.div`
  position: relative;
  margin-bottom: ${props => (props.isLastIndex ? '0' : '12px')} !important;
  ${({ isALRedesign, isAssessmentQuizExam, hideCorrectIndicator }) =>
    isAssessmentQuizExam
      ? 'width: 94%;'
      : isALRedesign
        ? 'width: 94.4%;'
        : hideCorrectIndicator && 'width: 100%;'
}
  ${({ hideCorrectIndicator }) =>
    hideCorrectIndicator &&
    `width: 100% !important;
   
  `}

  @media (max-width: ${BREAKPOINTS.desktop - 1}px) {
    ${({ isAssessmentQuizExam, isSidePanelOpen }) => isAssessmentQuizExam &&
      isSidePanelOpen && 'width: 90.7%'
}
    ${({ isALRedesign, isSidePanelOpen }) => isALRedesign && `
      ${isSidePanelOpen ? 'width: 90%;' : 'width: 94.4%'}
    `}
  }
  ${media.tablet`
    ${({ isALRedesign, isSidePanelOpen }) => isALRedesign && `
      ${isSidePanelOpen ? 'width: 90%;' : 'width: 94.2%'}
    `}
  `}

  ${media.mobile`
    ${({ isAssessmentQuizExam }) => isAssessmentQuizExam && 'width: 90.7%;'}
  `}

  @media (max-width: ${BREAKPOINTS.mobile - 1}px) {
    ${({ isALRedesign }) => isALRedesign && 'width: 90%;'}
  }

`

const incorrect = css`
  &:before {
    height: 34px;
    width: 28px;
    mask: url("/images/icons/close.png") no-repeat 0 0;
    right: 10px;
    position: absolute;
    content: "";
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
    line-height: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: #fff;
    pointer-events: none;
    cursor: pointer !important;
  }
`

export const Label = styled.label`
  pointer-events: all;
  display: flex;
  align-items: center;
  width: 100%;
  line-height: 1.5;
  padding: 10px;
  letter-spacing: 0;
  border-radius: 0.35rem;
  white-space: normal;
  cursor: pointer !important;
  margin-bottom: 0 !important;
  background: #212c2e;

  ${({
    isAssessmentQuizExam,
    isALRedesign,
    isAnsweringDisabled,
    isPendingResponseAnswer,
    isIncorrectOption,
    isEyeOpen,
    reviewMode,
    isMultipleSelection,
    checked,
    isCurrentOptionCorrect,
    type,
    isCorrect
  }) =>
    (isAssessmentQuizExam || isALRedesign) && `
      padding: 24px 12px;

      ${(isEyeOpen || reviewMode) ? `
        border: 1px solid rgba(255, 255, 255, 0.1);
      ` : ''}

      ${(reviewMode && checked) ? `
        border: 1px solid ${isCorrect ? '#5FC4B8' : '#FFA463'} !important;
      ` : ''}
      ${(reviewMode && !checked &&
        isCurrentOptionCorrect && type === MULTIPLE_CHOICE) ? `
        border: 1px solid #FFFFFF;
      ` : ''}
      ${(reviewMode && !checked && isCurrentOptionCorrect &&
          type === TRUE_FALSE) ? `
        border: 1px solid #FFFFFF;
      ` : ''}
      ${({ isALRedesign }) => isALRedesign && `
        font-size: 16px !important;
        font-weight: regular !important;
      `}

      background: ${(!isAnsweringDisabled && !isEyeOpen && !reviewMode)
    ? '#0A2A2D' : 'none'};

    ${(isALRedesign && checked) && `border: 1px solid ${
    isCorrect
      ? '#5FC4B8' : isIncorrectOption
        ? '#FFA463' : '#FFFFFF'} !important;
      ${!isMultipleSelection && `background: none;`}
    `};

    ${isIncorrectOption && !isPendingResponseAnswer && !isALRedesign ? incorrect : ''}
  `}

  ${({ hideCorrectIndicator }) => hideCorrectIndicator && `
    background: #25272B;
  `}

  &:focus {
    background: #445052;
    outline: none;
    border: 1px solid white;
  }
  &:active {
    background: #445052;
  }
`

export const Input = styled.input`
  box-sizing: border-box;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer !important;
  z-index: 1;

  ${({ hideCorrectIndicator }) => hideCorrectIndicator ? `
    &:hover + ${Label}{
      background: #37393F;
    }
  ` : `
    &:not(:disabled):hover + ${Label} {
      background: #445052;
    }
  `}

  &:disabled {
    cursor: default !important;
  }
`

export const TextWrapper = styled.div`
  font-weight: bold;
  width: 100%;
  display: flex;
  ${({ isAssessmentQuizExam, isALRedesign }) =>
    (isAssessmentQuizExam || isALRedesign) && `
      line-height: 19.2px;
      font-size: 16px;
      flex-direction: column;
      align-items: flex-start !important;
  `}
  ${({ isAssessmentQuizExam, isALRedesign, isEyeOpen }) =>
    (isEyeOpen && (isAssessmentQuizExam || isALRedesign)) && `
      color: #B1BFC5;
      text-decoration: line-through;

      & * .MathJax_SVG::after  {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        border-bottom: solid 3px #B1BFC5;
        transform: translateY(-50%);
      }
  `}

  align-items: center;
  margin-right: 30px;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  width: 24px;
  height: 24px;

  ${({ isAssessmentQuizExam, reviewMode, isALRedesign }) =>
    (isAssessmentQuizExam || isALRedesign)
      ? `
    right: -28px;
    width: 16px;
    height: 16px;
    ${reviewMode && `
      pointer-events: none;
    `}
  ` : `
    margin-right: 14px;
    border-radius: 50%;
    border: 2px solid #fff;
  `}
`

export const Icon = styled.img`
  width: inherit;
  height: inherit;
  vertical-align: middle;
  border-style: none;
`
export const CorrectAnswerText = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 4px;
  margin-bottom: 6px;
  ${({ isALRedesign }) => isALRedesign && `
    margin-left: 0px;
    letter-spacing: 1px;
  `}
`
export const CardTitle = styled.span`
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  height: 18px !important;
  max-width: 218px;
  overflow: hidden;
  margin-bottom: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 11px;
  margin-top: 5px;
  color: #98A4AE;
`
