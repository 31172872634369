import React, { Component, useContext } from 'react'
import { FullStoryAPI } from 'react-fullstory'
import jwtDecode from 'jwt-decode'

import createClient from './client'
import history from '../../utilities/history'
import config from '../../config'
import api from '../../api'
import utils from '../../utilities'
import { isUserAdmin } from '../../utilities/userUtils'

const { isPreviewCourse } = config

export const Auth0Context = React.createContext()
export const useAuth0Context = () => useContext(Auth0Context)

export class Auth0Provider extends Component {
  constructor (props) {
    super(props)
    this.initAuth0 = this.initAuth0.bind(this)
    this.onRedirectCallback = this.onRedirectCallback.bind(this)
    this.loginWithPopup = this.loginWithPopup.bind(this)
    this.loginWithRedirect = this.loginWithRedirect.bind(this)
    this.handleRedirectCallback = this.handleRedirectCallback.bind(this)

    this.state = {
      isFirstLogin: false,
      isAuthenticated: false,
      user: {},
      studentId: null,
      auth0Client: null,
      loading: true,
      popupOpen: false,
      redirectURI: '',
      redirect: false
    }
  }

  componentDidMount () {
    this.initAuth0()
  }

  setAuth0token = () => {
    const locationHref = window.location.href
    const URLtoken = locationHref.split('#/')[0].split('token=')[1]

    if (URLtoken) {
      localStorage.setItem('auth0token', URLtoken)
      return URLtoken
    }

    const auth0token = localStorage.getItem('auth0token')
    return auth0token
  }

  async initAuth0 () {
    if (isPreviewCourse) {
      return this.setState({ loading: false, redirect: true })
    }

    const auth0token = this.setAuth0token()

    if (auth0token) {
      const user = jwtDecode(auth0token)
      return this.setState({
        loading: false,
        redirect: true,
        isAuthenticated: true,
        user
      })
    }

    const { storedToken } = config
    if (storedToken) return this.initAuth0FromToken(storedToken)

    const auth0FromHook = await createClient()
    this.setState({ auth0Client: auth0FromHook })
    if (window.location.search.includes('code=')) {
      let appState = ''
      try {
        const res = await auth0FromHook.handleRedirectCallback()
        appState = res.appState
        this.setState({ isFirstLogin: true })
      } catch (e) {
      }
      this.onRedirectCallback(appState)
    }

    const isAuthenticated = await auth0FromHook.isAuthenticated()
    this.setState({ isAuthenticated })
    if (isAuthenticated) {
      const user = await auth0FromHook.getUser()
      const isAdmin = await isUserAdmin()
      const shouldAutoVerifyUser = isAdmin && !user?.['email_verified']

      if (shouldAutoVerifyUser) {
        const response = await api.updateAuth0User({ email_verified: true })
        const { email_verified: emailVerified } = response || {}
        if (emailVerified) window.location.reload()
      }
      // Full Story identify Begin
      // Use SHA512 because it's currently widely accepted.
      // See https://stackoverflow.com/questions/2117732/reasons-why-sha512-is-superior-to-md5
      // The secret key of `outlier` cannot really be secret because this is
      // frontend code. If this were something we really needed to be secure
      // (instead of just an id for Fullstory, we would need to do something
      // on the backend.
      let userId
      try {
        userId = await api.getStudentId()
      } catch (error) {
        console.error(error)
      }
      FullStoryAPI('identify', userId, {
        // real displayName is not passed to FS in order to limit exposure of PII. As FS needs either displayName or email, first one is passed with the user id
        displayName: userId,
        studentId_str: userId
      })
      // Full Story identify End

      const identityData = {
        traits: {
          name: user.nickname || userId,
          studentId: userId
        }
      }
      api.setStudentIdentity(identityData)

      this.setState({ user, studentId: userId })
    } else {
      FullStoryAPI('identify', false)
    }
    this.setState({ loading: false, redirect: true })
  }

  initAuth0FromToken = async token => {
    let userId, user
    try {
      userId = await api.getStudentId()
      user = jwtDecode(token)
    } catch (e) {
      // Since Token is not valid remove it and back to
      // normal authentication flow
      const { remove } = config.authToken()
      remove()
      return (window.location.href = config.courseBaseUrl)
    }

    FullStoryAPI('identify', userId, {
      // real displayName is not passed to FS in order to limit exposure of PII. As FS needs either displayName or email, first one is passed with the user id
      displayName: userId,
      studentId_str: userId
    })

    const identityData = {
      traits: {
        name: user.nickname || userId,
        studentId: userId
      }
    }
    api.setStudentIdentity(identityData)

    this.setState({
      user,
      studentId: userId,
      loading: false,
      redirect: true,
      isAuthenticated: true
    })
  }

  onRedirectCallback (appState) {
    if (appState?.targetUrl) {
      this.setState({ redirectURI: appState.targetUrl.slice(1) })
    }
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }

  async handleRedirectCallback () {
    if (isPreviewCourse) return

    this.setState({ loading: true })
    const { auth0Client } = this.state
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    this.setState({ loading: false, isAuthenticated: true, user })
  }

  async loginWithPopup (params = {}) {
    if (isPreviewCourse) return

    const { auth0Client } = this.state
    this.setState({ popupOpen: true })
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      this.setState({ popupOpen: false })
    }
    const user = await auth0Client.getUser()
    this.setState({ user, isAuthenticated: true })
  }

  async loginWithRedirect (params = {}) {
    if (isPreviewCourse) return

    const { auth0Client } = this.state
    try {
      await auth0Client.loginWithRedirect(params)
    } catch (error) {
      console.error(error)
    }
  }

  render () {
    const {
      isFirstLogin,
      redirect,
      redirectURI,
      auth0Client,
      isAuthenticated,
      user,
      studentId,
      loading,
      popupOpen
    } = this.state

    // we should add a specifc height in px to get around
    // white screen issue in proctorio
    if (!redirect) return <div style={{ height: '1000px' }} />

    const { remove } = utils.loginTimestamp()
    const { children } = this.props
    return (
      <Auth0Context.Provider
        value={{
          isFirstLogin,
          isAuthenticated,
          user,
          studentId,
          loading,
          popupOpen,
          loginWithPopup: this.loginWithPopup,
          handleRedirectCallback: this.handleRedirectCallback,
          getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
          loginWithRedirect: this.loginWithRedirect,
          getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
          getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
          logout: () => {
            if (isPreviewCourse || !auth0Client) return

            const { courseBaseUrl } = config
            auth0Client.logout({
              returnTo: courseBaseUrl
            })
            remove()
          }
        }}
      >
        {React.cloneElement(children, { isAuthenticated, user, studentId, redirectURI })}
      </Auth0Context.Provider>
    )
  }
}
