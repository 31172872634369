import React from 'react'
import './css/SectionListExcerciseItem.css'
import {
  Content, Counter,
  Left,
  Right,
  SubTitle,
  Time,
  Title
} from '../LecturesExerciseItem/styled'
import { Collapse } from 'reactstrap'
import PracticeExercisesButton from '../PracticeExercisesButton/PracticeExercisesButton'
import { ActivitiesWrapper, ActivityWrapper } from './styled'

const COMPLETED = 'Completed'
const CHALLENGE_QUESTION = '1 challenge question'

function PracticeSetSectionItem ({
  exercice,
  sectionUUID,
  questionSetLock,
  isAccordionOpen
}) {
  const { isPracticeDrawer } = exercice?.[0] || {}
  const showIndex = !isPracticeDrawer

  return (
    <>
      <Collapse isOpen={isAccordionOpen || isPracticeDrawer}>
        <ActivitiesWrapper
          isPracticeDrawer={isPracticeDrawer}
          data-cy='activitiesWrapper'
        >
          {
            exercice?.map(
              (question, index) => {
                const {
                  title, question_set_uuid: problemBankQuestionUUID,
                  Question_uuid: questionUUID,
                  description,
                  isCompleted
                } = question
                return (
                  <ActivityWrapper isLocked={questionSetLock} key={title}>
                    <Left className='d-flex align-items-baseline'>
                      {showIndex && <Counter>{ index + 1}.</Counter>}
                      <Content>
                        <Title>
                          {title}
                        </Title>
                        <Time data-testid='questionProgress'>{isCompleted ? COMPLETED : CHALLENGE_QUESTION}</Time>
                        <SubTitle>{description}</SubTitle>
                      </Content>
                    </Left>
                    <Right>
                      <PracticeExercisesButton
                        sectionUUID={sectionUUID}
                        questionUUID={problemBankQuestionUUID || questionUUID}
                        isPracticeCompleted={!!isCompleted}
                        isUnlocked={!questionSetLock}
                      />
                    </Right>
                  </ActivityWrapper>)
              }
            )
          }
        </ActivitiesWrapper>
      </Collapse>
    </>
  )
}

export default PracticeSetSectionItem
