
import React, { Component } from 'react'
import classNames from 'classnames'
import Radiobutton from '../FormComponent/Radiobutton'
import ShowSolution from '../ShowSolution/ShowSolution'
import QuestionComponent from '../QuestionComponent/QuestionComponent'
import FeedbackToast from '../FeedbackToast/FeedbackToast'
import CourseButton from '../CourseButton/CourseButton'
import { emitter } from '../Emitter/Emitter'
import Context from '../Context/Context'
import QuestionNextButton from '../QuestionComponent/QuestionNextButton'
import QuestionPreviousButton from '../QuestionComponent/QuestionPreviousButton'
import { ACTIVE_LEARNING,
  ACTIVITY_TYPES } from '../../Constants/sectionType'
import {
  ON_STUDENT_ANSWER,
  ON_SUBMIT_ANSWER
} from '../../Constants/emitterKeys'
import MathJax from '../MathJax/MathJax'
import { getExplanation } from '../../utilities/courseUtils'
import { findAnswer } from '../../utilities/questions'
import ShowFeedback from '../ShowFeedBack/ShowFeedBack'
import { CORRECT, INCORRECT } from '../../Constants/result'
import { getStudentAnswersKey } from '../../utilities/contextKeys'
import {
  AdaptationStatement,
  LessonSmallTextAL,
  LessonTextAL,
  PaginationWrapper, PinButton, PinButtonWrapper, PinText, PinWrapper,
  QuestionText, TextWrapper, TrueFalseFirstSection, TrueFalseSecondSection
} from './styles'
import { PINNED_ICON_WHITE, PIN_ICON } from '../../Constants/progressBarIconSource'
import { PINNED_TEXT, PIN_TEXT } from '../../Constants'
import JDoodleCompiler from '../JDoodleCompiler/JDoodleCompiler'

class TrueFalseQuestion extends Component {
  constructor (props) {
    super(props)
    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.onShowSolution = this.onShowSolution.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.CheckSolution = this.CheckSolution.bind(this)
    this.createRadiobutton = this.createRadiobutton.bind(this)
    this.createRadiobuttons = this.createRadiobuttons.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleRadioClick = this.handleRadioClick.bind(this)

    this.state = {
      selectedOption: '',
      try_attempt: 0,
      show_attempt: false,
      shouldShowSolution: false,
      isNextDisabled: true,
      isPinned: false,
      correctIncorrectClass: '',
      showToast: false,
      studentAnswerRes: {}
    }
  }

  componentDidMount () {
    const { question: { Question_uuid: questionUUID } } = this.props
    const { studentData: { pinnedQuestions } } = this.context
    this.setState({ isPinned: pinnedQuestions.includes(questionUUID) })
    const studentAnswers = this.getStudentAnswers()

    const studentAnswered = findAnswer(questionUUID, studentAnswers)
    if (studentAnswered) this.updateButtonStyle(studentAnswered)
  }

  componentDidUpdate (prevProps) {
    const {
      isRevealed,
      make_reveal_false: turnOffRevealAnswerDialog,
      question: {
        answer,
        Question_uuid: questionUUID
      }
    } = this.props
    const { studentData: { pinnedQuestions } } = this.context
    const {
      isRevealed: previousIsRevealed,
      question: {
        Question_uuid: previousQuestionUUID
      }
    } = prevProps
    const studentAnswers = this.getStudentAnswers()

    const studentAnswered = findAnswer(questionUUID, studentAnswers)

    if (previousQuestionUUID !== questionUUID) {
      this.setState({ isPinned: pinnedQuestions.includes(questionUUID) })
    }
    if (previousIsRevealed !== isRevealed && isRevealed) {
      const correctAnswer = parseInt(answer[0]) - 1
      this.setState({ selectedOption: correctAnswer })

      const fnToCallInTimeout = () => {
        this.CheckSolution()
        turnOffRevealAnswerDialog()
      }
      setTimeout(fnToCallInTimeout, 200)
    } else if (previousQuestionUUID !== questionUUID) {
      this.updateButtonStyle(studentAnswered)
      this.setState({ result: '' })
    }
  }

  getStudentAnswers = () => {
    const {
      currentChapter: { type },
      studentData
    } = this.context
    const studentAnswersKey = getStudentAnswersKey(type)
    return studentData[studentAnswersKey]
  }

  updateButtonStyle (studentAnswered) {
    this.setState({
      selectedOption: !studentAnswered ? '' : studentAnswered.answer,
      result: '',
      isNextDisabled: !studentAnswered ? true : studentAnswered.correct !== true,
      correctIncorrectClass: !studentAnswered ? '' : studentAnswered.correct === true
        ? 'ans-correct'
        : 'incorrect ans-incorrect'
    })
  }

  handleOptionChange (index) {
    /* ------- Clear TIme out on Reveal Answer Click ----- */
    clearTimeout(this.timeOutFuncID)
    /* ------- Clear TIme out on Reveal Answer Click ----- */

    this.setState({
      selectedOption: index,
      isNextDisabled: true,
      showToast: false,
      correctIncorrectClass: ''
    })

    /* ------- Start : Call to the CheckSolution function on every button change ----- */
    setTimeout(() => {
      this.CheckSolution()
      this.setState({ showToast: true })
    }, 200)
    /* ------- End : Call to the CheckSolution function on every button change ----- */

    this.props.make_reveal_false()
  }

  onShowSolution () {
    const {
      question: {
        answer: [correctAnswerIndex]
      }
    } = this.props

    if (!correctAnswerIndex) return

    const { selectedOption: studentSelectedOption } = this.state
    const correctAnswer = correctAnswerIndex === 0 ? 'True' : 'False'
    const selectedAnswer = studentSelectedOption !== ''
      ? (studentSelectedOption === 0 ? 'True' : 'False')
      : ''

    this.setState({
      show_solution: true,
      show_attempt: false,
      solution: {
        answer: correctAnswer,
        selectedValues: selectedAnswer
      }
    })
  }

  handleFormSubmit (formSubmitEvent) {
    /* ------- Clear TIme out on Reveal Answer Click ----- */
    clearTimeout(this.timeOutFuncID)
    /* ------- Clear TIme out on Reveal Answer Click ----- */
    formSubmitEvent.preventDefault()
    this.CheckSolution()
  }

  CheckSolution () {
    const {
      selectedOption: answerSelectedByStudent,
      result: stateResult,
      isPinned: pinned,
      try_attempt: noOfTimeAttempted
    } = this.state
    const {
      question: {
        Question_uuid: questionUUID,
        answer: correctAnswerIndex
      },
      saveProgress
    } = this.props
    const correctAnswer = parseInt(correctAnswerIndex[0]) - 1
    const selectedAnswerValue = answerSelectedByStudent !== ''
      ? (answerSelectedByStudent === 0 ? 'True' : 'False')
      : ''
    const isCorrectAns =
      JSON.stringify(answerSelectedByStudent) === JSON.stringify(correctAnswer)
    const currentAttempt = noOfTimeAttempted + 1
    const className = isCorrectAns ? 'ans-correct' : 'incorrect ans-incorrect'
    // change context data using event
    const studentAnswerObject = {
      uuid: questionUUID,
      answer: answerSelectedByStudent,
      type: ACTIVITY_TYPES[ACTIVE_LEARNING],
      questionActivity: { pinned },
      correct: isCorrectAns
    }

    emitter.emit(ON_STUDENT_ANSWER, studentAnswerObject)
    // change context data
    const result = isCorrectAns ? CORRECT : INCORRECT
    if (stateResult !== result) {
      const text = isCorrectAns ? 'Correct Answer' : 'Incorrect Answer'
      window.outlierLog(text, selectedAnswerValue)
    }

    this.setState({
      result,
      try_attempt: isCorrectAns ? 0 : currentAttempt,
      isNextDisabled: !isCorrectAns,
      correctIncorrectClass: className,
      studentAnswerRes: studentAnswerObject
    })
    saveProgress(studentAnswerObject)
    if (isCorrectAns) return
    // change context data
    this.setState({
      show_attempt: true,
      show_solution: false
    })
  }

  createRadiobutton (label, index) {
    const { show_solution: showSolution,
      selectedOption, correctIncorrectClass } = this.state
    const { question: { options } } = this.props
    const { isSidePanelOpen } = this.context
    const optionsLength = options.length ? options.length - 1 : 1
    return (
      <Radiobutton
        label={label}
        isLastIndex={optionsLength === index && showSolution}
        key={index}
        handleCheckboxChange={this.handleOptionChange}
        value={index}
        dataCypress='TrueFalseButton'
        isRadioChecked={selectedOption === index}
        correctIncorrect={correctIncorrectClass}
        isALRedesign
        isSidePanelOpen={isSidePanelOpen}
      />
    )
  }

  createRadiobuttons (items) {
    return (
      items.map(this.createRadiobutton)
    )
  }

  // Should return false if the action was not allowed.
  handleKeyPress (type) {
    const {
      showPrevious,
      onPreviousButtonClick,
      onNextButtonClick
    } = this.props

    // Typically from pressing the left arrow
    if (type && type === 'previous') {
      if (!showPrevious) return false
      onPreviousButtonClick()
      return true
    }

    // Everything else is interpreted as next or advance forward
    const {
      isNextDisabled,
      studentAnswerRes: studentAnswerResponse
    } = this.state

    if (isNextDisabled) return false

    onNextButtonClick(studentAnswerResponse)
    return true
  }

  handleRadioClick (event) {
    const { target } = event
    // Blur the element so the arrow keys do not change the answer. Only click
    // should change the answer.
    target.blur()
  }

  onPinClick (currentQuestionuuID) {
    const { isPinned } = this.state
    const { studentData, updateContext } = this.context
    const { pinnedQuestions } = studentData
    const index = pinnedQuestions.indexOf(currentQuestionuuID)
    const studentAnswers = this.getStudentAnswers()
    const fAnswer = findAnswer(currentQuestionuuID, studentAnswers)
    const answer = {
      type: ACTIVITY_TYPES[ACTIVE_LEARNING],
      uuid: currentQuestionuuID,
      questionActivity: { pinned: false },
      ...(fAnswer?.correct && { answer: fAnswer.answer }),
      ...(fAnswer?.correct && { correct: fAnswer.correct })
    }
    if (isPinned) {
      pinnedQuestions.splice(index, 1)
    } else {
      pinnedQuestions.push(currentQuestionuuID)
      answer.questionActivity.pinned = true
    }
    emitter.emit(ON_SUBMIT_ANSWER, answer)
    studentData.pinnedQuestions = pinnedQuestions
    updateContext({ studentData })
    this.setState({ isPinned: !isPinned })
  }

  render () {
    const {
      question,
      question: {
        Question_uuid: questionUUID,
        jdoodleEditor,
        starterCode,
        text_when_stuck: textWhenStuck,
        general_explanation: generalExplanation,
        specific_explanation: specificExplanation
      },
      lesson_text: lessonText,
      structuredText,
      display_illustration: displayIllustration,
      desmosgraph: desmosGraph,
      gMath,
      question_text: questionText,
      displayReveal,
      filteredActvQuestInd,
      correctProps,
      currentQuestionUUID,
      incorrectProps
    } = this.props
    const {
      shouldShowSolution,
      isNextDisabled,
      show_attempt: showAttempt,
      try_attempt: tryAttempt,
      show_solution: showSolution,
      solution,
      result,
      showToast,
      isPinned,
      studentAnswerRes
    } = this.state
    const { options, adaptationStatement } = question
    const { isLastQuestionAnswered } = this.context
    const radioOptions = options.length
      ? this.createRadiobuttons(options)
      : this.createRadiobuttons(['', ''])
    const queTextClass = classNames({
      'que-text': false,
      'active-que-text': false,
      col: true
    })
    const isCorrect = result === CORRECT
    return (
      <>
        <>
          {showToast && isCorrect &&
            <FeedbackToast
              showToast={showToast}
              isCorrect={isCorrect}
            />}
          <TextWrapper isALRedesign >
            <QuestionText isALRedesign >
              Card {filteredActvQuestInd + 1}
            </QuestionText>
            <PinWrapper isALRedesign
              onClick={() => this.onPinClick(currentQuestionUUID)}>
              <PinText>{isPinned ? PINNED_TEXT : PIN_TEXT}</PinText>
              <PinButtonWrapper
                isALRedesign
              >
                <PinButton
                  src={isPinned ? PINNED_ICON_WHITE : PIN_ICON}
                  data-testid='pinButton'
                />
              </PinButtonWrapper>
            </PinWrapper>
          </TextWrapper>
        </>
        <TrueFalseFirstSection
          isALRedesign >
          {structuredText}
          {lessonText && <>
            <LessonTextAL className='font__md'
              isALRedesign >
              {lessonText}</LessonTextAL>
          </>}
          <div className='row align-items-start pt-4'>
            {displayIllustration}
            {/* --- Added Desmos Here --- */}
            {desmosGraph}
            {questionText && <div className={queTextClass}>
              <LessonSmallTextAL className='font__md'
                isALRedesign >
                {questionText}
              </LessonSmallTextAL>
            </div>}
          </div>
          {gMath}
        </TrueFalseFirstSection>
        <TrueFalseSecondSection
          isALRedesign >
          {jdoodleEditor &&
            <div className='mb-2'>
              <JDoodleCompiler
                key={questionUUID}
                questionUUID={questionUUID}
                isALRedesign
                starterCode={starterCode}
              />
            </div>}
          <div
            className='active__learning-options mx-auto row no-gutters'
            id='multiple_radio_auto'
            onClick={this.handleRadioClick}
          >
            {radioOptions}
          </div>
          {showSolution && <ShowSolution solutionData={solution}
            explanation={getExplanation(specificExplanation, generalExplanation)}
          />}
          {!showSolution && result &&
          <ShowFeedback
            textWhenStuck={textWhenStuck}
            showStuck={showAttempt && tryAttempt >= 3}
            studentAnswer={question?.options[studentAnswerRes?.answer]}
            sectionType={ACTIVE_LEARNING} result={result}
            correctProps={correctProps}
            incorrectProps={incorrectProps}
            showSolutionHandler={displayReveal}
            isALRedesign
          />}
        </TrueFalseSecondSection>

        <PaginationWrapper
          isALRedesign
        >
          <QuestionPreviousButton
            isALRedesign
            {...this.props}
          />
          {shouldShowSolution && <CourseButton
            className={{
              'btn-primary': true,
              'mx-1': true
            }}
            onClick={this.onShowSolution}
          >
            Show Solution
          </CourseButton>}

          {!isLastQuestionAnswered &&
            <QuestionNextButton
              {...this.props}
              isNextDisabled={isNextDisabled}
              studentAnswerRes={studentAnswerRes}
              isALRedesign
            />}
        </PaginationWrapper>
        {
          adaptationStatement &&
            <AdaptationStatement
              isALRedesign
              className='active__learning-pagination adaptation
                bottom-fixed text-center'>
              <div className='adaptation-statement'>
                <MathJax math={adaptationStatement} />
              </div>
            </AdaptationStatement>
        }
      </>
    )
  }
}

TrueFalseQuestion.contextType = Context
export default QuestionComponent(TrueFalseQuestion)
