import styled from 'styled-components'

const StyledCardWrapper = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  position: relative;
`

const StyledContentWrapper = styled.div`
  background-color: #25272B;
`

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 24px;
  border-bottom: 1px solid rgba(196, 916, 196, 0.1);
  font-weight: 700;
`

const StyledBody = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
`

const StyledFooter = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`

export {
  StyledCardWrapper,
  StyledContentWrapper,
  StyledHeader,
  StyledBody,
  StyledFooter
}
