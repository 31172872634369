export const KALTURA_CALCULUS_ID = '1_3chw23oq'
export const KALTURA_PHILOSOPHY_ID = '1_dkbtpv0n'
export const KALTURA_OTHER_COURSES_ID = '1_32txhinf'
export const KALTURA_HIGHSCHOOL_COURSES_ID = '1_1gvecrqb'
export const CALCULUS_CHAPTERS = [
  {
    time: '00:00',
    chapter: 'Introduction'
  },
  {
    time: '01:03',
    chapter: '1. Tech Requirements + Frequently Used Sites'
  },
  {
    time: '01:40',
    chapter: '2. Science Based Learning'
  },
  {
    time: '03:37',
    chapter: '3. Syllabus + Schedule'
  },
  {
    time: '04:26',
    chapter: '4. Website Tour'
  },
  {
    time: '07:22',
    chapter: '5. Academic Integrity'
  },
  {
    time: '08:55',
    chapter: '6. Quizzes, Midterm and Final Exams'
  },
  {
    time: '09:39',
    chapter: '7. Community and Class Participation'
  },
  {
    time: '10:57',
    chapter: '8. Outlier Netiquette'
  },
  {
    time: '13:03',
    chapter: '9. Transferring Credits'
  },
  {
    time: '13:38',
    chapter: '10. Contacting Outlier'
  }
]
export const EVERGREEN_CHAPTERS = [
  {
    time: '00:00',
    chapter: 'Introduction'
  },
  {
    time: '01:03',
    chapter: '1. Tech Requirements + Frequently Used Sites'
  },
  {
    time: '02:17',
    chapter: '2. Science Based Learning'
  },
  {
    time: '03:25',
    chapter: '3. Syllabus + Schedule'
  },
  {
    time: '04:13',
    chapter: '4. Website Tour'
  },
  {
    time: '06:52',
    chapter: '5. Academic Integrity'
  },
  {
    time: '08:24',
    chapter: '6. Quizzes, Midterm and Final Exams'
  },
  {
    time: '08:51',
    chapter: '7. Community and Class Participation'
  },
  {
    time: '10:10',
    chapter: '8. Outlier Netiquette'
  },
  {
    time: '11:39',
    chapter: '9. Transferring Credits'
  },
  {
    time: '12:13',
    chapter: '10. Contacting Outlier'
  }
]
