import React, { Component } from 'react'
import $ from 'jquery'

import ShowSolution from '../ShowSolution/ShowSolution'
import QuestionComponent from '../QuestionComponent/QuestionComponent'
import { emitter } from '../Emitter/Emitter'
import Context from '../Context/Context'
import QuestionNextButton from '../QuestionComponent/QuestionNextButton'
import QuestionPreviousButton from '../QuestionComponent/QuestionPreviousButton'
import ReviewNextButton from '../QuestionComponent/ReviewNextButton'
import FreeformAuthoringDisplay from './FreeformAuthoringDisplay'
import MathType from '../MathType/MathType'
import StepsSolutionModal from '../StepsSolutionModal/StepsSolutionModal'
import { EXAM_STATUSES, PIN_TEXT, PINNED_TEXT } from '../../Constants/index'
import MobileAppConnectionStatus from '../MobileAppConnectionStatus/MobileAppConnectionStatus'

import { display, getCorrectAnswer } from './utils/wiris'
import { isPopupEditor, cleanupPopupForDisplaying, isEmptyOrWhiteSpace } from '../MathType/utils'
import {
  QUIZ,
  EXAM,
  RETAKE,
  ACTIVITY_TYPES
} from '../../Constants/sectionType'
import {
  ON_SECTION_DATA_PROGRESS,
  ON_STUDENT_ANSWER,
  ON_SUBMIT_ANSWER
} from '../../Constants/emitterKeys'
import { findAnswer, getQuestionTitle } from '../../utilities/questions'
import { getExplanation } from '../../utilities/courseUtils'
import { myScriptHandler } from '../../utilities/mobileAppUtils'
import ShowFeedback from '../ShowFeedBack/ShowFeedBack'
import {
  PaginationWrapper,
  TextWrapper,
  Icon,
  QuestionText,
  QuestionCard,
  LessonText,
  MathEditor,
  PinWrapper,
  PinText,
  PinButtonWrapper,
  PinButton,
  QuestionIconWrapper
} from './styles'
import {
  NEW_GUESSWORK_ICON,
  NEW_EMPTY_ICON,
  NEW_COMPLETED_ICON,
  NEW_INCORRECT_ICON,
  PIN_ICON,
  PINNED_ICON_WHITE
} from '../../Constants/progressBarIconSource'
import { CORRECT, INCORRECT } from '../../Constants/result'
import { trackStudentEvent } from '../../utilities/chapterUtils'
import api from '../../api'
import { BREAKPOINTS } from '../../mediaQueries'
import {
  saveAnswersToLocalStorage,
  isExamRetakeType
} from '../../utilities/examUtils'
import ProctorioExamResources
  from '../ProctorioExamResources/ProctorioExamResources'
import { isEqual } from 'lodash'
import JDoodleCompiler from '../JDoodleCompiler/JDoodleCompiler'
import { EXAM_STATUS } from '../../Constants/studentContext'

class FreeformEquationQuestionQuizExam extends Component {
  constructor (props) {
    super(props)
    this.onShowSolution = this.onShowSolution.bind(this)
    this.showResult = this.showResult.bind(this)
    this.initializeWiris = this.initializeWiris.bind(this)
    this.onEditorChange = this.onEditorChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.getTriesPerQuestion = this.getTriesPerQuestion.bind(this)
    this.setModalContent = this.setModalContent.bind(this)
    this.isExam = this.isExam.bind(this)

    this.state = {
      tryAttempt: 0,
      result: '',
      solution: {},
      isNextDisabled: true,
      isEditorDisabled: false,
      isButtonLoading: false,
      displayNext: 'none',
      displayCheck: 'block',
      triesPerQuestion: this.getTriesPerQuestion(),
      triesResult: [],
      quizzesEditor: null,
      isExam: this.isExam(),
      isQuiz: this.isQuiz(),
      studentAnswerRes: {},
      modalContent: '',
      isPinned: false,
      myScriptTimestamp: null
    }

    this.prevAnswer = null
    this.mathTypeRef = React.createRef()
    this.myScriptHandler = myScriptHandler.bind(this)
    this.intervalId = null
  }

  async componentDidMount () {
    this.myScriptHandler()
    this.initialLastSectionQuiz = this.getLastSectionQuiz()
    this.lastSectionQuiz = this.initialLastSectionQuiz

    const filterQuestion = this.getFilteredQuestions()
    const {
      question: { Question_uuid: questionUUID },
      activeSectionUUID,
      completeScreenData,
      activeChildrenIndex,
      isFirstQuestion,
      type_of_question: questionType
    } = this.props
    if (filterQuestion && filterQuestion.Question_uuid !== questionUUID) return

    const {
      studentData: { studentAnswers, pinnedQuestions },
      cohortData: { duration, name: cohort },
      reviewMode
    } = this.context
    if (!pinnedQuestions.length) {
      studentAnswers
        .forEach(studentAnswer => studentAnswer?.questionActivity?.pinned &&
          pinnedQuestions.push(studentAnswer.uuid))
    }
    this.setState({ isPinned: pinnedQuestions.includes(questionUUID) })
    const fAns = findAnswer(questionUUID, studentAnswers)
    const ans = fAns ? fAns.answer : ''
    if (fAns) this.setState({ result: '' }, () => this.setQuestionStates(fAns))

    await this.initializeWiris()

    if (reviewMode) this.onShowSolution()

    const { current: mathType } = this.mathTypeRef || {}
    mathType && mathType.setMathML(ans)

    const isQuestionTypeExam = questionType === EXAM
    const isTrackEvent = isQuestionTypeExam && isFirstQuestion && !reviewMode
    if (!isTrackEvent) return

    trackStudentEvent({
      questionType,
      duration,
      cohort,
      activeChildrenIndex,
      completeScreenData
    })
    emitter.emit(
      ON_SECTION_DATA_PROGRESS,
      {
        key: EXAM_STATUS,
        sectionUUID: activeSectionUUID,
        value: EXAM_STATUSES.IN_PROGRESS
      }
    )
  }

  isExam () {
    const { type_of_question: typeOfQuestion } = this.props
    return typeOfQuestion === EXAM
  }

  isQuiz () {
    const { type_of_question: typeOfQuestion } = this.props
    return typeOfQuestion === QUIZ
  }

  async componentDidUpdate (prevProps, prevState) {
    this.lastSectionQuiz = this.getLastSectionQuiz()

    const {
      question: { Question_uuid: prevQuestionUUID },
      review_mode: prevReviewMode
    } = prevProps
    const {
      question: { Question_uuid: questionUUID },
      review_mode: reviewMode
    } = this.props
    const { current: mathType } = this.mathTypeRef || {}
    const { isExam, isQuiz } = this.state
    const { studentData: { studentAnswers } } = this.context

    const fAns = findAnswer(questionUUID, studentAnswers)
    const ans = fAns?.answer || ''

    const updateEditorValue = () =>
      (questionUUID !== prevQuestionUUID || (ans && !(isExam ||
         isQuiz)) || reviewMode) &&
        mathType.setMathML(ans)

    updateEditorValue()

    if (prevQuestionUUID !== questionUUID) {
      await this.initializeWiris()
      clearInterval(this.intervalId)
      this.myScriptHandler()
    }

    if (reviewMode && (!prevReviewMode || prevQuestionUUID !== questionUUID)) {
      this.onShowSolution()
    }

    if (fAns && (prevQuestionUUID !== questionUUID)) {
      this.setQuestionStates(fAns)
    } else if (this.lastSectionQuiz !== this.initialLastSectionQuiz) {
      window.location.reload()
    } else if (prevQuestionUUID !== questionUUID && !fAns) {
      this.setQuestionStates(fAns)
    }
    // Need to call this again to set after initialize wiris
    // The fields in wiris.js could be replaced while initializing
    updateEditorValue()

    const { isEditorDisabled } = this.state
    $('.wrs_focusElement, #studentAnswer input').attr('disabled', isEditorDisabled)
    if (prevQuestionUUID !== questionUUID) {
      const { studentData: { pinnedQuestions } } = this.context
      this.setState({ isPinned: pinnedQuestions.includes(questionUUID) })
    }
  }

  async componentWillUnmount () {
    clearInterval(this.intervalId)
    await api.setMyScriptData('nodata')
  }

  getLastSectionQuiz () {
    const { studentData: { 'quiz-section': quizSection } } = this.context
    return Object.keys(quizSection).sort().pop()
  }

  getFilteredQuestions () {
    const { currentQuestionSet, activeSectionUUID, question } = this.props
    const { studentData, course, navigateToActiveIndex } = this.context
    if (!navigateToActiveIndex) return question
    const lastActiveQuestionUUID = studentData['last-active-learning-uuid']
    const isActiveSectionUUID = lastActiveQuestionUUID[activeSectionUUID]
    const filterQuestion = isActiveSectionUUID &&
    currentQuestionSet.find(q => q.Question_uuid ===
      lastActiveQuestionUUID[activeSectionUUID][course['currentInstructor']])
    return filterQuestion
  }

  setQuestionStates (fAns) {
    const result = fAns?.correct ? CORRECT : INCORRECT
    this.setState({
      isNextDisabled: !fAns,
      result: fAns ? result : '',
      tryAttempt: 0,
      displayNext: fAns ? 'block' : 'none',
      displayCheck: fAns ? 'none' : 'block',
      triesPerQuestion: this.getTriesPerQuestion(),
      triesResult: fAns?.answer ? fAns.tries : []
    })
  }

  getTriesPerQuestion () {
    const {
      question: { tries_per_question: triesPerQuestion }
    } = this.props
    return triesPerQuestion
      ? parseInt(triesPerQuestion)
      : 3
  }

  async initializeWiris () {
    const {
      question: { Question_uuid: questionUUID, configuration }
    } = this.props
    const tabDeliveryElem = document.getElementById('tabdelivery')
    if (tabDeliveryElem) {
      tabDeliveryElem.className = ''
    }
    const { studentData: { studentAnswers } } = this.context

    const fAns = findAnswer(questionUUID, studentAnswers)
    const contentJSON = {
      text: '',
      feedback: '',
      question: configuration,
      gievnAnswer: fAns?.answer || ''
    }
    await display(contentJSON, (editor) => {
      this.setState({ quizzesEditor: editor || questionUUID })
    })
    this.handleSubmitButtonDisabled(false)
    if (window.innerWidth > BREAKPOINTS.mobile) {
      $('.wrs_focusElement').focus()
    }
  }

  onShowSolution () {
    const {
      review_mode: reviewMode,
      question: {
        Question_uuid: questionUUID,
        question_view_lesson: questionViewLessson, configuration }
    } = this.props

    const correctAnswer = getCorrectAnswer()
    const isPopup = isPopupEditor(configuration)
    const { studentData: { studentAnswers } } = this.context

    const fAns = findAnswer(questionUUID, studentAnswers)
    const studentAnswer = fAns?.answer || ''
    const questionResult = !!fAns?.correct

    this.setState({
      solution: {
        questionUUID,
        correctAnswer: isPopup
          ? cleanupPopupForDisplaying(correctAnswer) : correctAnswer,
        studentAnswer: isPopup
          ? cleanupPopupForDisplaying(studentAnswer) : studentAnswer,
        studentAnswerClass: questionResult
          ? 'radio__badge-correct' : 'radio__badge-incorrect',
        view_lesson: reviewMode
          ? questionViewLessson !== null
            ? questionViewLessson : null
          : null
      },
      showSolution: true
    })
  }

  getCorrectAnswer = () => {
    const { configuration } = this.props.question
    const correctAnswer = getCorrectAnswer()
    const isPopup = isPopupEditor(configuration)
    return isPopup ? cleanupPopupForDisplaying(correctAnswer) : correctAnswer
  }

  async showResult (studentAnswer) {
    const {
      tryAttempt,
      isButtonLoading
    } = this.state
    const { current: mathType } = this.mathTypeRef
    const notValidAnswer = isEmptyOrWhiteSpace(mathType.getMathML())
    const answer = studentAnswer || mathType.getMathML()

    if (notValidAnswer) {
      isButtonLoading && this.setState({ isButtonLoading: false })
      this.handleSubmitButtonDisabled(false)
      return
    }

    this.setState({ isNextDisabled: false })
    window.outlierLog('Check Answer', mathType.getMathML())

    const { setGuessworkCorrectAnswer } = this.props
    setGuessworkCorrectAnswer(this.getCorrectAnswer())

    const timestamp = Date.now()
    const questionResult = await mathType.asyncCheckAnswer(answer)
    this.handleSubmitButtonDisabled(false)
    const finalTryAttempt = tryAttempt + 1
    const tryEl = document.getElementById('tries-' + (finalTryAttempt - 1))

    if (questionResult) {
      if (tryEl) tryEl.className = 'radio__badge radio__badge-correct'
      this.setQuestionResult({ answer, questionResult, timestamp })
    } else {
      this.setQuestionResult({ answer, questionResult, timestamp })
      if (tryEl) tryEl.className = 'radio__badge radio__badge-incorrect'
    }
  }

  async setQuestionResult ({ answer, questionResult, timestamp }) {
    const {
      completeScreenData,
      question: { Question_uuid: questionUUID },
      type_of_question: typeOfQuestion,
      review_mode: reviewMode
    } = this.props
    const { examRetake, cohortData } = this.context
    const { triesResult, tryAttempt, isExam } = this.state
    const isRetakeType = isExamRetakeType({
      examRetake,
      cohortId: cohortData.cohortID,
      chapter: completeScreenData?.chapter
    }) && isExam
    const testResult = {}

    triesResult.push({ answer, questionResult, timestamp })

    const { answer: latestAnswer, questionResult: latestResult } =
      triesResult.reduce((result, current) => {
        return current?.timestamp > result?.timestamp ? current : result
      }, { timestamp: 0 })

    testResult.uuid = questionUUID
    testResult.answer = latestAnswer
    testResult.correct = latestResult
    testResult.tries = triesResult
    testResult.type = ACTIVITY_TYPES[isRetakeType ? RETAKE : typeOfQuestion]

    this.setState({
      result: latestResult ? CORRECT : INCORRECT,
      isEditorDisabled: !!reviewMode,
      tryAttempt: latestResult ? 0 : tryAttempt + 1,
      isNextDisabled: false,
      displayCheck: 'none',
      displayNext: 'block'
    })
    if (!reviewMode) {
      const { studentData: { pinnedQuestions } } = this.context
      if (pinnedQuestions.includes(testResult.uuid)) {
        testResult.questionActivity = {
          pinned: true
        }
      }
      emitter.emit(ON_STUDENT_ANSWER, { ...testResult, isExam })
      this.setState({ studentAnswerRes: testResult })
      const { studentData: { studentAnswers } } = this.context
      const { localStorageKey, currentQuestionSet } = this.props
      console.log({ triesResult })
      saveAnswersToLocalStorage(localStorageKey, currentQuestionSet, studentAnswers)
      this.setState({ isButtonLoading: false })
      return
    }
    window.outlierLog(
      latestResult ? 'Correct Answer' : 'Incorrect Answer', latestAnswer
    )
  }

  // Should return false if the action was not allowed.
  // Component uses Wiris entry, so we must ignore arrow keys which are used to
  // move around within that input field.
  handleKeyPress (type) {
    const {
      isNextDisabled,
      displayNext,
      displayCheck,
      studentAnswerRes
    } = this.state
    const {
      onNextButtonClick,
      onReviewClickNext,
      review_mode: reviewMode
    } = this.props
    // Typically from pressing the left arrow, allow it and ignore it.
    if (type && type === 'previous') return true

    // Typically from pressing the right arrow, allow it and ignore it.
    if (type && type === 'advance') return true

    // Everything else is interpreted as next or advance forward
    if (reviewMode) {
      onReviewClickNext()
      return true
    } else {
      if (!isNextDisabled) {
        if (displayNext === 'block') {
          onNextButtonClick(studentAnswerRes)
          return true
        } else if (displayCheck === 'block') {
          this.showResult()
          return true
        }
      }
    }

    // Unrecognized, default is don't allow it.
    return false
  }

  setModalContent (modalContent) {
    this.setState({ modalContent })
  }

  onEditorChange (answer) {
    if (answer === this.prevAnswer) return
    this.prevAnswer = answer

    const {
      question: { Question_uuid: questionUUID },
      review_mode: reviewMode
    } = this.props
    const { isButtonLoading } = this.state
    const { studentData: { studentAnswers } } = this.context
    const fAns = findAnswer(questionUUID, studentAnswers)
    const isEqualAnswer = isEqual(fAns?.answer, answer)
    const isEditorDisabled = reviewMode

    this.setState({
      isEditorDisabled,
      isNextDisabled: !isEditorDisabled && !answer,
      displayNext: isEditorDisabled ? 'block' : 'none',
      displayCheck: isEditorDisabled ? 'none' : 'block'
    })

    if (!answer || isEqualAnswer) return

    !isButtonLoading && this.setState({ isButtonLoading: true })
    this.handleSubmitButtonDisabled(true)
    this.showResult(answer)
  }

  handleSubmitButtonDisabled = value => {
    const { isModalSubmitDisabled, updateContext } = this.context
    if (isModalSubmitDisabled === value) return
    updateContext({ isModalSubmitDisabled: value })
  }

  onPinClick (currentQuestionUUID) {
    const {
      review_mode: reviewMode,
      completeScreenData,
      type_of_question: typeOfQuestion
    } = this.props

    const {
      studentData,
      updateContext,
      cohortData,
      examRetake
    } = this.context
    const { pinnedQuestions, studentAnswers } = studentData
    const { isPinned, isExam } = this.state
    const currentIndex = pinnedQuestions.indexOf(currentQuestionUUID)
    const isRetakeType = isExamRetakeType({
      examRetake,
      cohortId: cohortData.cohortID,
      chapter: completeScreenData?.chapter
    }) && isExam
    const fAnswer = findAnswer(currentQuestionUUID, studentAnswers)
    // condition to check answer exists in review mode before pinning
    const isAttemptedQuestion = typeof (fAnswer?.correct) === 'boolean'
    const answer = {
      uuid: currentQuestionUUID,
      type: isRetakeType
        ? ACTIVITY_TYPES[RETAKE]
        : typeOfQuestion.toLocaleLowerCase(),
      ...(reviewMode && isAttemptedQuestion && {
        answer: fAnswer.answer,
        correct: fAnswer.correct,
        tries: fAnswer.tries
      }),
      questionActivity: { pinned: false }
    }
    if (isPinned) {
      pinnedQuestions.splice(currentIndex, 1)
      emitter.emit(ON_SUBMIT_ANSWER, answer)
    } else {
      answer.questionActivity.pinned = true
      pinnedQuestions.push(currentQuestionUUID)
      emitter.emit(ON_SUBMIT_ANSWER, answer)
    }
    studentData.pinnedQuestions = pinnedQuestions
    updateContext({ studentData })
    this.setState({ isPinned: !isPinned })
  }

  render () {
    const { question } = this.props
    if (!question) return <div className='text-center'>Loading...</div>

    const {
      question: {
        general_explanation: generalExplanation,
        specific_explanation: specificExplanation,
        examResources,
        resourceIcons,
        additionalConfigurations,
        configuration
      },
      type_of_question: typeOfQuestion,
      lesson_text: lessonText,
      structuredText,
      question_text: questionText,
      display_illustration: displayIllustration,
      review_mode: reviewMode,
      desmosgraph,
      gMath,
      showPrevious,
      filterMessage,
      currentQuestionUUID,
      correctProps,
      showfinish,
      isFirstQuestion,
      currentQuestionSet,
      incorrectProps
    } = this.props
    const {
      quizzesEditor,
      isNextDisabled,
      isEditorDisabled,
      showSolution,
      solution,
      result,
      studentAnswerRes,
      isPinned,
      isButtonLoading,
      isQuiz,
      modalContent
    } = this.state
    const {
      isSidePanelOpen
    } = this.context
    const isExam = typeOfQuestion === EXAM
    const {
      Question_uuid: questionUUID,
      jdoodleEditor,
      starterCode
    } = question
    const questionIndex = currentQuestionSet?.findIndex(
      (q) => q.Question_uuid === questionUUID
    )
    const { questionUUID: solutionQuestionUUID } = solution
    return (
      <React.Fragment>
        <StepsSolutionModal modalContent={modalContent} setModalContent={this.setModalContent} />
        <TextWrapper>
          <QuestionIconWrapper>
            <span>
              <Icon
                src={result
                  ? (reviewMode
                    ? (result === 'Correct'
                      ? NEW_COMPLETED_ICON : NEW_INCORRECT_ICON)
                    : NEW_GUESSWORK_ICON)
                  : reviewMode ? NEW_INCORRECT_ICON : NEW_EMPTY_ICON}
                alt='Feedback'
              />
            </span>
            <QuestionText>
              {getQuestionTitle(question, questionIndex, typeOfQuestion)}
            </QuestionText>
          </QuestionIconWrapper>
          <PinWrapper onClick={() => this.onPinClick(currentQuestionUUID)}>
            <PinText>{isPinned ? PINNED_TEXT : PIN_TEXT}</PinText>
            <PinButtonWrapper>
              <PinButton
                src={isPinned ? PINNED_ICON_WHITE : PIN_ICON}
              />
            </PinButtonWrapper>
          </PinWrapper>
        </TextWrapper>
        <FreeformAuthoringDisplay />
        <div id='delivery' style={{ display: 'none' }}>
          <QuestionCard
            id='questiontext'
            className='active__learning-block component__backdrop mb-2'
            isAssessmentQuizExam
            reviewMode={reviewMode}
          >
            {structuredText}
            {lessonText ? (
              <React.Fragment>
                <LessonText
                  className='pb-3 font__md'
                  isAssessmentQuizExam
                  isSidePanelOpen={isSidePanelOpen}
                >
                  {lessonText}
                </LessonText>
                <hr className='active__learning-hr' />
              </React.Fragment>
            ) : null}
            <div className='row align-items-start pt-4'>
              {displayIllustration}
              {/* --- Added Desmos Here --- */}
              {desmosgraph}
              {questionText ? (
                <div className='col que-text'>
                  <div className='font__md'>{questionText}</div>
                </div>
              ) : null}
            </div>
            {gMath}
            {jdoodleEditor &&
            <div className='mb-2'>
              <JDoodleCompiler
                key={questionUUID}
                questionUUID={questionUUID}
                starterCode={starterCode}
                isPartOfQuestion
              />
            </div>}
            <div>
              <MathEditor
                className='mx-auto row no-gutters position-relative'
                isAssessmentQuizExam
                isSidePanelOpen={isSidePanelOpen}
              >
                <MathType
                  ref={this.mathTypeRef}
                  onChange={this.onEditorChange}
                  questionConfiguration={configuration}
                  quizzesEditor={quizzesEditor}
                  additionalConfigurations={additionalConfigurations}
                />
                {isEditorDisabled && (
                  <div
                    id='studentAnswerDisabled'
                    className='studentAnswerOverlay'
                  />
                )}
              </MathEditor>
              <MobileAppConnectionStatus />
            </div>
            <ProctorioExamResources
              reviewMode={reviewMode}
              examResources={examResources}
              resourceIcons={resourceIcons}
            />
            {showSolution && questionUUID === solutionQuestionUUID && (
              <ShowSolution solutionData={solution}
                filterMessage={filterMessage} correctProps={correctProps}
                incorrectProps={incorrectProps} isExamReviewMode={reviewMode && isExam}
                explanation={getExplanation(specificExplanation, generalExplanation)}
                isExamOrQuizReviewMode={reviewMode} />
            )}
            {!isQuiz && !showSolution && result &&
            quizzesEditor && (!isExam || reviewMode) &&
            <ShowFeedback
              guessWorkCorrectAnswer={this.getCorrectAnswer()}
              sectionType={typeOfQuestion}
              result={result}
              studentAnswer={studentAnswerRes?.answer}
              correctProps={correctProps}
              incorrectProps={incorrectProps} />}
          </QuestionCard>
          <PaginationWrapper
            isAssessmentQuizExam
            isFirstQuestion={isFirstQuestion}
            showPrevious={showPrevious}
            reviewMode={reviewMode}
            showfinish={showfinish}
          >
            {!isFirstQuestion &&
            <QuestionPreviousButton {...this.props}
              isLoading={isButtonLoading}
              isAssessmentQuizExam />}
            {!showfinish &&
              !reviewMode && (
              <QuestionNextButton
                {...this.props}
                isLoading={isButtonLoading}
                studentAnswerRes={studentAnswerRes}
                isAssessmentQuizExam
                isNextDisabled={isNextDisabled}
                isEditorDisabled={isEditorDisabled}
              />
            )}
            {reviewMode && (
              <ReviewNextButton {...this.props} />
            )}

            <strong style={{ display: 'none' }}>
              <span id='feedback' />
            </strong>
          </PaginationWrapper>

          {/* <!-- The custom feedback will be inserted here in place of the empty span element. --> */}
          <div id='customfeedback' style={{ display: 'none' }}>
            <span />
          </div>
          {/* <!-- The answer feedback component will be inserted here in place of the empty span element. --> */}
          <div id='answerfeedback' style={{ display: 'none' }}>
            <span />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

FreeformEquationQuestionQuizExam.contextType = Context
FreeformEquationQuestionQuizExam.displayName = 'FreeformEquationQuestionQuizExam'
export default QuestionComponent(FreeformEquationQuestionQuizExam)
