import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Context from '../../Context/Context'
import config from '../../../config'
import { emitter } from '../../Emitter/Emitter'
import { secondsToFormattedDateShort } from '../../../utilities/dateTimeUtils'
import { ON_NAVIGATE_TO } from '../../../Constants/emitterKeys'

import {
  Wrapper,
  BreadcrumbWrapper,
  BackArrow,
  BreadcrumbText,
  ContentWrapper,
  Title,
  CourseName,
  Right,
  HeaderText
} from './styled'
import { getCourseToolkitHeaderName, getIsVIPCohort } from '../../../utilities/courseUtils'

const Header = props => {
  const { title, breadcrumb: { text, link } } = props
  const {
    cohortData: {
      courseDisplayName,
      name: cohortName,
      startDate,
      endDate,
      duration
    },
    isAuditor,
    isAdmin
  } = useContext(Context)

  const handleNavigateButton = () => {
    emitter.emit(ON_NAVIGATE_TO, '/' + link)
  }

  const formattedStartDate = secondsToFormattedDateShort(startDate)
  const formattedEndDate = endDate ? secondsToFormattedDateShort(endDate) : ''

  const courseToolkitParams = {
    courseName: courseDisplayName,
    cohortName,
    duration,
    isAuditor
  }

  const hideCohortDate = config.isCollegeSuccessCourse()
  const isAdminAndVipCohort = isAdmin && getIsVIPCohort(cohortName)

  return (
    <Wrapper className='d-flex flex-column'>
      <BreadcrumbWrapper
        onClick={handleNavigateButton}
        className='d-inline-flex align-items-center'
      >
        <BackArrow />
        <BreadcrumbText>{text}</BreadcrumbText>
      </BreadcrumbWrapper>
      <ContentWrapper
        className='d-flex justify-content-between'
      >
        <Title data-cy='pageTitle'>{title}</Title>
        <Right className='d-flex flex-column'>
          <CourseName data-cy='courseName'>
            {getCourseToolkitHeaderName(courseToolkitParams)}
          </CourseName>

          {!isAdminAndVipCohort && !hideCohortDate && (
            <HeaderText data-cy='courseDate'>
              {formattedStartDate} - {formattedEndDate}
            </HeaderText>
          )}
        </Right>
      </ContentWrapper>
    </Wrapper>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumb: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  })
}

export default Header
