import React, { Component, Fragment } from 'react'
import { Table } from 'react-bootstrap'
import Pagination from 'react-js-pagination'
import './StudentGrades.css'
import gradeList from './StudentGradesData'
import config from '../../config'

class StudentGrades extends Component {
  constructor (props) {
    super(props)

    this.handleTextInput = this.handleTextInput.bind(this)
    this.handleRecordsPerPage = this.handleRecordsPerPage.bind(this)
    this.filterStudentList = this.filterStudentList.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.paginateStudentList = this.paginateStudentList.bind(this)

    this.state = {
      gradeList,
      searchText: '',
      recordsPerPage: 10,
      activePage: 1
    }
  }

  componentDidMount () {
    this.filterGradeListByCourse()
  }

  filterGradeListByCourse () {
    Object.entries(gradeList).forEach(([studentId, grades]) => {
      gradeList[studentId] = grades.filter(
        grade => grade.courseName.toLowerCase().includes(config.courseName))
      !gradeList[studentId].length && delete gradeList[studentId]
    })
    this.setState({ gradeList })
  }

  handleRecordsPerPage (e) {
    this.setState({
      recordsPerPage: e.target.value,
      activePage: 1
    })
  }

  handleTextInput (e) {
    this.setState({
      searchText: e.target.value,
      activePage: 1
    })
  }

  filterStudentList () {
    const { gradeList, searchText } = this.state

    if (!searchText) return Object.keys(gradeList)

    const filteredStudentIds = Object.keys(gradeList).filter(
      studentId => studentId.toLowerCase().includes(searchText.toLowerCase()))

    return filteredStudentIds
  }

  paginateStudentList (filteredStudentIds) {
    const { recordsPerPage, activePage } = this.state
    const startingStudentIdIndex = (activePage - 1) * recordsPerPage
    return filteredStudentIds.splice(startingStudentIdIndex, recordsPerPage)
  }

  handlePageChange (pageClicked) {
    this.setState({ activePage: pageClicked })
  }

  render () {
    const { gradeList, searchText, recordsPerPage, activePage } = this.state
    let filteredStudentIds = this.filterStudentList()
    const filteredStudentIdsCount = Object.keys(filteredStudentIds).length

    filteredStudentIds = this.paginateStudentList(filteredStudentIds)

    const noStudentIdToShow = filteredStudentIds.length === 0

    return (
      <div className='container'>
        <div >
          <h1 className='gradesHeading'>FINAL GRADES</h1>
        </div>
        <div className='gradeSearchRightAligned'>
          <input
            type='text'
            placeholder='Search your ID'
            id='studentID'
            className='gradeSearchBox'
            onChange={this.handleTextInput}
            value={searchText}
          />
        </div>
        <Table bordered className='gradesTableMain'>
          <thead>
            <tr className='panel-title gradeTableHeaders'>
              <th className='gradesTableTHLeft'>Student ID</th>
              <th>Course</th>
              <th className='gradesTableTHRight'>Grade</th>
            </tr>
          </thead>
          <tbody>
            {
              noStudentIdToShow
                ? <tr >
                  <td colSpan='3' className='studentIdRow'>
                        ID not found.
                  </td>
                </tr>
                : filteredStudentIds.map(studentId => (
                  <Fragment key={studentId}>
                    <tr >
                      <td colSpan='3' className='studentIdRow panel-title mb-2'>
                        { gradeList[studentId] && `${studentId}`}
                      </td>
                    </tr>
                    {
                      gradeList[studentId] &&
                     gradeList[studentId].map((examResult, index) => (
                       <tr key={index} >
                         <td />
                         <td className='gradesRow'>{examResult.courseName}</td>
                         <td className='gradesRow'>{examResult.grade}</td>
                       </tr>
                     ))
                    }
                  </Fragment>
                ))
            }
          </tbody>
        </Table>
        <div
          className={`gradesFooter ${noStudentIdToShow && 'hideGradesFooter'}`}
        >
          <select value={recordsPerPage} className='gradesRecordsPerPage'
            onChange={this.handleRecordsPerPage}>
            <option value='10'>10</option>
            <option value='20'>20</option>
            <option value='30'>30</option>
            <option value='40'>40</option>
          </select>
          <Pagination
            itemClass='gradesPagination'
            itemClassFirst='gradesPaginationFirstElement'
            itemClassLast='gradesPaginationLastElement'
            itemClassPrev='gradesPaginationPrev'
            itemClassNext='gradesPaginationNext'
            disabledClass=' disabled gradeDisablePagiPrevNext'
            prevPageText='<'
            nextPageText='>'
            activeLinkClass='gradesPaginationActiveLinkClass'
            activeClass='active activePagination'
            activePage={activePage}
            itemsCountPerPage={recordsPerPage}
            totalItemsCount={filteredStudentIdsCount}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange}
          />
        </div>
      </div>
    )
  }
}

export default StudentGrades
