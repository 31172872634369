import React from 'react'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import {
  MainWrapper,
  LinksWrapper,
  Icon,
  ResourcesText,
  LinkText,
  Dot,
  LinkWrapper
} from './styles'
import utils from '../../utilities'

const areEqual = (prevProps, nextProps) => {
  const { children, ...prevRest } = prevProps
  const { children: nextChildren, ...nextRest } = nextProps

  return isEqual(prevRest, nextRest)
}

const ProctorioExamResources = React.memo(props => {
  const { reviewMode, examResources } = props
  if (reviewMode || !examResources) return null

  const { getLinkTextAndUrlFromMarkdown } = utils
  const resourcesArray = getLinkTextAndUrlFromMarkdown(examResources)

  const getResourceIconUrl = index => {
    const { resourceIcons = [] } = props
    const icon = resourceIcons[index]
    if (!icon) return null

    return icon.url
  }

  const resourcesLengthIndex = resourcesArray.length - 1
  return (
    <MainWrapper>
      <ResourcesText>Resources</ResourcesText>
      <LinksWrapper>
        {
          resourcesArray.map((resource, i) => {
            const isLastItem = i === resourcesLengthIndex
            const { title, url } = resource
            const iconUrl = getResourceIconUrl(i)
            return (
              <LinkWrapper key={i}>
                <Icon src={iconUrl} />
                <LinkText href={url} target='_blank' rel='noopener noreferrer'>
                  {title}
                </LinkText>
                {!isLastItem && <Dot />}
              </LinkWrapper>
            )
          })
        }
      </LinksWrapper>
    </MainWrapper>
  )
}, areEqual)

ProctorioExamResources.propType = {
  examResources: PropTypes.string,
  resourceIcons: PropTypes.array,
  reviewMode: PropTypes.bool
}

export default ProctorioExamResources
