import React, { Component } from 'react'
import { getCurrentInstructorDetails } from '../../utilities/chapterUtils'
import InstructorListItem from '../InstructorListItem/InstructorListItem'
import LoadingSpinner from '../LoadingSpinner/LoadingAnimation'
import './css/instructor-picker.css'

class InstructorList extends Component {
  render () {
    const {
      Instructors,
      currentInstructor: currentInstructorID,
      onInstructorSwitch,
      isChapterOrientation
    } = this.props
    const instructor = getCurrentInstructorDetails({
      currentInstructorID,
      lectureVideos: Instructors
    })
    if (!instructor) return <LoadingSpinner />

    return (
      <InstructorListItem
        Instructor={instructor}
        onInstructorSwitch={onInstructorSwitch}
        currentInstructor={instructor.instructor_uuid}
        Instructors={Instructors}
        isChapterOrientation={isChapterOrientation}
      />
    )
  }
}

export default InstructorList
