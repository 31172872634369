import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

export default class ColoredScrollbars extends Component {
  constructor (props, ...rest) {
    super(props, ...rest)
    this.state = { top: 0 }
    this.handleUpdate = this.handleUpdate.bind(this)
    this.renderView = this.renderView.bind(this)
    this.renderThumb = this.renderThumb.bind(this)
  }

  handleUpdate (values) {
    const { top } = values
    this.setState({ top })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.children !== this.props.children) {
      if (this.props.srlBottom === true) {
        this.customRef.scrollToBottom()
      }
    }
  }

  componentDidMount () {
    this.customRef.scrollToBottom()
  }

  renderView ({ style, ...props }) {
    // const { top } = this.state
    const viewStyle = {
      // padding: 15,
      // backgroundColor: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(top * 255)}, ${Math.round(255)})`,
      // color: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))})`
      // maxHeight : 350
    }
    return (
      <div
        className='box p-0'
        style={{ ...style, ...viewStyle }}
        {...props} />
    )
  }

  renderThumb ({ style, ...props }) {
    // const { top } = this.state
    const thumbStyle = {
      // backgroundColor: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))})`
      backgroundColor: `#3B5D5B`
    }
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props} />
    )
  }

  render () {
    return (
      <Scrollbars
        renderView={this.renderView}
        renderThumbHorizontal={this.renderThumb}
        renderThumbVertical={this.renderThumb}
        onUpdate={this.handleUpdate}
        ref={c => { this.customRef = c }}
        {...this.props} />
    )
  }
}
