import React, { Component } from 'react'
import ChapterListItem from '../ChapterListItem/ChapterListItem'
import ExamListItem from '../ExamListItem/ExamListItem'
import Context from '../Context/Context'
import {
  addPartsToChapters,
  getChaptersLockText,
  getChapterPrevExam,
  getPreviousChapter,
  isChapterAfterExam as isChapterAfterExamFunction,
  isOrientationChapter,
  isChapter
} from '../../utilities/chapterUtils'
import { secondsSinceEpoch } from '../../utilities/dateTimeUtils'
import { ASSIGNMENT_PROGRESS, EXAM_COMPLETE } from '../../Constants/studentContext'
import AssignmentListItem from '../AssignmentListItem/AssignmentListItem'
import config from '../../config'

import {
  SpecialTopic,
  StyledPartWrapper
} from './styled'
import { history } from '../HistoryManager/HistoryManager'
import { CODING_ASSIGNMENT, EXAM, WRITING_ASSIGNMENT } from '../../Constants/chapterType'
import { MAX_INTENSIVE_COHORT_DURATION } from '../../Constants/cohortDuration'
import { cloneDeep } from 'lodash'

class ChapterList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentDate: secondsSinceEpoch(),
      normalChapters: [],
      specialChapters: []
    }
    this.specialSectRef = React.createRef()
    this.fetchLockReasons = this.fetchLockReasons.bind(this)
  }

  processSpecialChapters () {
    const { hasWhatsNextUpdateFlag } = config
    const { chapters } = this.props
    const clonedChapters = cloneDeep(chapters)
    const specialChapters = []
    clonedChapters.forEach((chapter, index) => {
      if (hasWhatsNextUpdateFlag && chapter.title.includes('Special Topic')) {
        specialChapters.push(chapter)
        clonedChapters.splice(index, 1)
      }
    })
    this.setState(prevState => {
      return {
        ...prevState,
        normalChapters: clonedChapters,
        specialChapters
      }
    })
  }

  fetchLockReasons () {
    const {
      chapters,
      courseUnits,
      courseUnlockDate,
      cohortModifier,
      cohortSpecialDays,
      cohortExamDates
    } = this.props
    const { currentDate } = this.state

    const {
      isNoAssessments,
      studentData,
      isAuditor,
      isStudioCohort,
      isAdmin,
      isCohortEndedForStudent,
      isVIPGradedContent,
      cohortData: {
        courseResourcesSchedule,
        cohortID,
        studentStatus,
        cohortMilestones,
        name: cohortName
      },
      latestCohort,
      examRetake,
      isVIP
    } = this.context
    const { auditContentLock } = latestCohort || {}

    const examsCompleted = studentData[EXAM_COMPLETE]
    const assignmentsProgress = studentData[ASSIGNMENT_PROGRESS]
    return getChaptersLockText(
      {
        examRetake,
        currentDate,
        courseUnlockDate,
        chapters,
        courseUnits,
        cohortMilestones,
        assignmentsProgress,
        cohortModifier,
        cohortSpecialDays,
        cohortExamDates,
        examsCompleted,
        isAuditor,
        isStudioCohort,
        isAdmin,
        isVIP,
        isCohortEndedForStudent,
        isVIPGradedContent,
        isPreviewCourse: config.isPreviewCourse,
        cohortID,
        studentStatus,
        auditContentLock,
        isNoAssessments,
        courseResourcesSchedule,
        cohortName
      }
    )
  }

  scrollToSection = () => {
    const {
      location: { hash }
    } = history
    const hasScrollHash = hash.slice(1) === 'special-chapters-section'
    const specialSectRef = this.specialSectRef.current
    if (hasScrollHash && specialSectRef) {
      specialSectRef.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }

  componentDidMount () {
    this.processSpecialChapters()
  }

  componentDidUpdate () {
    const { hasWhatsNextUpdateFlag } = config
    if (hasWhatsNextUpdateFlag) {
      this.scrollToSection()
    }
  }

  collegeAlgebraUnlock = ({
    cohortDuration,
    chapterIndex,
    chapters,
    courseUnits,
    lockReasons
  }) => {
    const collegeAlgebraIntensive =
      ['collegealgebra', 'collegealgebra.plus'].includes(config.courseName) &&
      cohortDuration <= MAX_INTENSIVE_COHORT_DURATION
    if (!collegeAlgebraIntensive) return false

    if (chapters[chapterIndex]?.type === EXAM) return false

    const previousExam = getChapterPrevExam({ chapterIndex, chapters, courseUnits })
    return previousExam && !lockReasons[previousExam.chapter_uuid]
  }

  sociologyUnlock = ({
    cohortDuration,
    chapter,
    chapters,
    lockReasons
  }) => {
    const sociologyIntensive =
      ['sociology', 'sociology.plus'].includes(config.courseName) &&
      cohortDuration <= MAX_INTENSIVE_COHORT_DURATION
    if (!sociologyIntensive) return false

    const isChapterAfterExam = isChapterAfterExamFunction(chapter, chapters)
    if (!isChapterAfterExam) return false

    const chapterBeforeExam = getPreviousChapter(chapter, chapters)
    if (!chapterBeforeExam) return false

    return !lockReasons[chapterBeforeExam.chapter_uuid]
  }

  render () {
    const { lastProgressedChapterUUID, cohortMilestones,
      courseUnlockDate, cohortModifier, cohortSpecialDays,
      cohortExamDates, cohortData,
      courseUnits
    } = this.props
    const { normalChapters } = this.state

    const isOpen = (chapter) =>
      lastProgressedChapterUUID === chapter.chapter_uuid
    const {
      cohortData: { studentStatus },
      cohortDuration,
      isAuditor,
      isStudioCohort
    } = this.context

    const chaptersWithParts = addPartsToChapters(normalChapters, courseUnits)
    const displayedChapters = []

    chaptersWithParts.forEach((chapter, index) => {
      const { type, title } = chapter
      if (type === 'part') {
        displayedChapters.push(
          <StyledPartWrapper
            key={index}>{title}
          </StyledPartWrapper>
        )
        return
      }

      const lockReasons = this.fetchLockReasons()

      const sociologyUnlock = this.sociologyUnlock({
        cohortDuration,
        chapter,
        chapters: chaptersWithParts,
        lockReasons
      })

      const collegeAlgebraUnlock = this.collegeAlgebraUnlock({
        cohortDuration,
        chapterIndex: index,
        chapters: chaptersWithParts,
        courseUnits,
        lockReasons
      })

      const lockReason =
        !sociologyUnlock &&
        !collegeAlgebraUnlock &&
        lockReasons[chapter.chapter_uuid]

      const withCourseLockText = Array.isArray(lockReason)
      if (
        [CODING_ASSIGNMENT, WRITING_ASSIGNMENT].includes(chapter.type) &&
        cohortMilestones &&
        !isStudioCohort
      ) {
        const { chapter_uuid: assignmentUUID } = chapter
        const milestone = cohortMilestones.find(
          assignment => assignment?.datoAssignmentUUID === assignmentUUID
        )
        if (!milestone) return null
        displayedChapters.push(
          <AssignmentListItem
            key={chapter.chapter_uuid}
            chapter={chapter}
            cohortData={cohortData}
            lockReason={withCourseLockText
              ? lockReason[0]
              : lockReason}
            deadlineData={milestone}
          />
        )
      }

      const hasAccessToExam = chapter && chapter.type === 'exam' && !isAuditor
      if (hasAccessToExam) {
        displayedChapters.push(
          <ExamListItem
            chapter={chapter}
            chapters={normalChapters}
            key={chapter.chapter_uuid}
            lockReason={withCourseLockText
              ? lockReason[0]
              : lockReason}
            cohortStartDate={courseUnlockDate}
            cohortModifier={cohortModifier}
            cohortSpecialDays={cohortSpecialDays}
            cohortExamDates={cohortExamDates}
            cohortData={cohortData}
            studentStatus={studentStatus}
          />
        )
      }
      if (!chapter || !isChapter(chapter)) return null
      if (isStudioCohort && isOrientationChapter(chapter)) return null
      displayedChapters.push(
        <ChapterListItem
          isOpen={isOpen(chapter)}
          chapter={chapter}
          chapters={normalChapters}
          key={chapter.chapter_uuid}
          chapterLoopId={index}
          lockReason={withCourseLockText
            ? lockReason[0]
            : lockReason}
          cohortStartDate={courseUnlockDate}
          courseLockText={withCourseLockText && lockReason[1]}
          cohortModifier={cohortModifier}
          cohortSpecialDays={cohortSpecialDays}
          cohortExamDates={cohortExamDates}
          studentStatus={studentStatus}
        />
      )
    })
    const { specialChapters } = this.state
    if (specialChapters.length > 0 && config.hasWhatsNextUpdateFlag) {
      const displayedSpecialChapters = specialChapters.map((chapter, index) => {
        return (<ChapterListItem key={index}
          chapter={chapter}
        />
        )
      })
      const specialChaptersSection =
        <div
          ref={this.specialSectRef}
          key={displayedSpecialChapters.length}
          data-testid='special-chapters-section'
        >
          <SpecialTopic>Special Topics</SpecialTopic>
          {displayedSpecialChapters}
        </div>
      return [displayedChapters, specialChaptersSection]
    }
    return displayedChapters
  }
}

ChapterList.contextType = Context
ChapterList.displayName = 'ChapterList'
export default ChapterList
