// grading criteria of quizzes, participation, midterms,
// and final exam in percentage (%)

import {
  CALCULUS,
  CALCULUS_FP,
  COLLEGE_WRITING_I,
  PSYCHOLOGY,
  PSYCHOLOGY_FP,
  STATISTICS,
  ASTRONOMY,
  MICROECONOMICS,
  SOCIOLOGY,
  FINANCIAL_ACCOUNTING,
  PRECALCULUS,
  PHILOSOPHY,
  PRINCIPLES_OF_ECONOMICS,
  COLLEGE_ALGEBRA,
  BUSINESS
} from '../../../Constants/courseNames'

export const GRADING_CRITERIA = {
  [CALCULUS]: {
    quizzes: 50,
    participation: 5,
    midterms: 20,
    finalExams: 25,
    quizzesCount: 40,
    midtermCount: 2
  },
  [CALCULUS_FP]: {
    quizzes: 50,
    participation: 5,
    midterms: 20,
    finalExams: 25,
    quizzesCount: 32,
    midtermCount: 2
  },
  [COLLEGE_WRITING_I]: {
    quizzes: 14,
    preWritingAssignments: 20,
    cloneReadingEssay: 16,
    lensEssay: 20,
    multiSourceResearchEssay: 25,
    participation: 5
  },
  [PSYCHOLOGY]: {
    quizzes: 30,
    participation: 5,
    midterms: 40,
    finalExams: 25,
    quizzesCount: 21,
    midtermCount: 2
  },
  [PSYCHOLOGY_FP]: {
    quizzes: 30,
    participation: 5,
    midterms: 40,
    finalExams: 25,
    quizzesCount: 19,
    midtermCount: 2
  },
  [STATISTICS]: {
    quizzes: 50,
    participation: 5,
    midterms: 20,
    finalExams: 25,
    quizzesCount: 12,
    midtermCount: 2
  },
  [ASTRONOMY]: {
    quizzes: 50,
    participation: 5,
    midterms: 20,
    finalExams: 25,
    quizzesCount: 19,
    midtermCount: 2
  },
  [MICROECONOMICS]: {
    quizzes: 50,
    participation: 5,
    midterms: 20,
    finalExams: 25,
    quizzesCount: 13,
    midtermCount: 2
  },
  [SOCIOLOGY]: {
    quizzes: 30,
    participation: 5,
    midterms: 20,
    finalExams: 25,
    quizzesCount: 13,
    otherWritingAssignments: 20,
    allWritingAssignments: 20,
    writingAssignmentsCount: 6,
    midtermCount: 2
  },
  [FINANCIAL_ACCOUNTING]: {
    quizzes: 50,
    participation: 5,
    midterms: 20,
    finalExams: 25,
    quizzesCount: 13,
    midtermCount: 2
  },
  [PRECALCULUS]: {
    quizzes: 50,
    participation: 5,
    midterms: 20,
    finalExams: 25,
    quizzesCount: 41,
    midtermCount: 2
  },
  [PHILOSOPHY]: {
    quizzes: 14,
    participation: 6,
    midterms: 20,
    finalEssay: 30,
    otherWritingAssignments: 30,
    allWritingAssignments: 60,
    quizzesCount: 7,
    midtermCount: 1
  },
  [PRINCIPLES_OF_ECONOMICS]: {
    quizzes: 50,
    participation: 5,
    midterms: 20,
    finalExams: 25,
    quizzesCount: 13,
    midtermCount: 2
  },
  [COLLEGE_ALGEBRA]: {
    quizzes: 50,
    participation: 5,
    midterms: 20,
    finalExams: 25,
    quizzesCount: 31,
    midtermCount: 2
  },
  [BUSINESS]: {
    quizzes: 40,
    participation: 5,
    midterms: 30,
    finalExams: 20,
    quizzesCount: 11,
    midtermCount: 2,
    otherWritingAssignments: 5,
    allWritingAssignments: 5,
    writingAssignmentsCount: 1
  }
}
