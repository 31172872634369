import styled, { createGlobalStyle, keyframes } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
    height: 100vh;
  }
  footer {
    visibility: hidden;
  }
`

const expand = keyframes`
  from {
    width: 0;
  }

  to {
    transform: 375px;
  }
`

const fade = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const ChecklistContainer = styled.div`
  height: 100%;
  position: fixed;
  width: ${({ isOpen }) => (isOpen ? '375px' : '0')};
  z-index: 9;
  top: 0;
  right: 0;
  background-color: #25272b;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  animation: ${expand} 0.2s linear;
`

export const ChecklistWrapper = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #161618ad;
  z-index: 99999;
  position: absolute;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`

export const ChecklistBody = styled.div`
  animation: ${fade} 1.5s linear;
  flex-direction: column;
  display: flex;
`
