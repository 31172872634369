import React, { useContext } from 'react'
import { getDateStringWithYear } from '../../utilities/dateTimeUtils'
import Context from '../Context/Context'
import { BannerContainer, Message } from './styles'
import { getCurrentProspect } from '../../utilities/gguDegreeUtils'

const DegreeRegistrationBanner = () => {
  const {
    currentSemester,
    degreeRegistrationIncomplete,
    prospects
  } = useContext(Context) || {}

  const { goodFinancialStanding, prospectStatus } = getCurrentProspect(prospects) || {}

  const isOfferNotAccepted = prospectStatus === 'Offer not accepted'

  if (goodFinancialStanding === 'No') return null
  if (!degreeRegistrationIncomplete || isOfferNotAccepted) return null

  const closeEnrollmentDate = currentSemester?.closeEnrollment
    ? getDateStringWithYear(currentSemester?.closeEnrollment)
    : ''

  const continueEnrollment = () => {
    window.open('https://dashboard.outlier.org', '_self')
  }

  return (
    <BannerContainer>
      <Message>
          ACTION REQUIRED! You must enroll in courses for next term by {closeEnrollmentDate}.&nbsp;
        <span onClick={continueEnrollment}>enroll now</span>
      </Message>
    </BannerContainer>
  )
}

DegreeRegistrationBanner.displayName = 'DegreeRegistrationBanner'

export default DegreeRegistrationBanner
