import { Tooltip } from 'react-bootstrap'
import styled, { createGlobalStyle } from 'styled-components'
import mediaqueries from '../../../mediaQueries'

const GlobalStyle = createGlobalStyle`
  .tooltip {
    opacity: 1 !important;
    z-index: 1000;
  }

  .arrow {
    display: none;
  }
`

const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    max-width: ${props => props.maxwidth || '280'}px;
    text-align: start;
    border-radius: 0px;
    padding: 0px;
    background: transparent;
    ${mediaqueries.mobile`
    margin-left: 21px;
    margin-right: 25px;
    `}
  }
`

const StyledArrowWrapper = styled.div`
  height: 24px;
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  background: transparent;
  justify-content: ${
  props => props.placement === 'start'
    ? ' flex-start'
    : props.placement === 'end'
      ? 'flex-end'
      : 'center'
};
`

const StyledTriangle = styled.div`
  height: 0px;
  width: 0px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 24px solid #25272B;
`

export {
  GlobalStyle,
  StyledTooltip,
  StyledArrowWrapper,
  StyledTriangle
}
