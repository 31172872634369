import React from 'react'
import {
  CloseDropDownIcon,
  DropDownMenu,
  DropDownMenuWrapper
} from './style'

const DropDownMenuComponent = ({
  iconSrc,
  iconWidth,
  iconHeight,
  dropDownOffset,
  maxHeight,
  showDropDownIcon,
  children
}) => {
  return (
    <DropDownMenuWrapper
      className='dropdown d-flex'
      id='nav_icon_hambars'
    >
      <a
        href='#id'
        className='dropdown-toggle ml-1 d-flex'
        id='dropdownMenuOffset'
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
        data-offset='10,20'
      >
        {showDropDownIcon && (
          <div className='nav-notification d-flex'>
            <img
              className='d-inline-block icon-bars'
              src={iconSrc}
              width={iconWidth}
              height={iconHeight}
              alt='Menu Icons'
            />
          </div>
        )}
      </a>
      <DropDownMenu
        className='dropdown-menu dropdown-menu-right dropdown-profile pb-2'
        aria-labelledby='dropdownMenuOffset'
        dropDownOffset={dropDownOffset}
        maxHeight={maxHeight}
      >
        <CloseDropDownIcon
          src='images/icons/icon-close-dropdown-menu.svg'
          alt='Close menu icon'
        />
        {children}
      </DropDownMenu>
    </DropDownMenuWrapper>
  )
}

export default DropDownMenuComponent
