import {
  EXAM_1,
  EXAM_2,
  EXAM_3,
  EXAM_4,
  EXAM_5,
  FINAL_EXAM,
  FINAL_EXAM_PART_I,
  FINAL_EXAM_PART_II,
  MIDTERM_EXAM_1,
  MIDTERM_EXAM_2
} from './examType'

export const EXAM_RETAKE_KEYS = {
  // standard cohorts
  [MIDTERM_EXAM_1]: { start: 'midterm1RetakeStart', end: 'midterm1Deadline' },
  [MIDTERM_EXAM_2]: { start: 'midterm2RetakeStart', end: 'midterm2Deadline' },
  [FINAL_EXAM]: { start: 'finalRetakeStart', end: 'finalDeadline' },

  // 39 week cohorts
  [EXAM_1]: { start: 'midterm1RetakeStart', end: 'midterm1Deadline' },
  [EXAM_2]: { start: 'midterm2RetakeStart', end: 'midterm2Deadline' },
  [EXAM_3]: { start: 'exam3RetakeStart', end: 'exam3RetakeDeadline' },
  [EXAM_4]: { start: 'exam4RetakeStart', end: 'exam4RetakeDeadline' },
  [EXAM_5]: { start: 'exam5RetakeStart', end: 'exam5RetakeDeadline' },
  [FINAL_EXAM_PART_I]: { start: 'exam5RetakeStart', end: 'exam5RetakeDeadline' },
  [FINAL_EXAM_PART_II]: { start: 'finalRetakeStart', end: 'finalDeadline' }
}
