import styled from 'styled-components'

export const ModalContainer = styled.div`
 width: 517px;
 position: fixed;
 top: 40%;
 left: 50%;
 transform: translate(-50%, -50%);
 text-align: left;
 padding: 24px;
 background-color: #25272B;
 border-radius: 5px;
 font-size: 16px;
 z-index: 100;
 button {
  margin-right: 10px;
  width: auto;
 }
 h1 {
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
 }
`
