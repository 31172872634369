import styled, { css } from 'styled-components'
import media from '../../../mediaQueries'

export const MainWrapper = styled.div`
  display: flex;
  padding-top: 24px;
  padding-bottom: 12px;

  ${media.desktop`
    flex-direction: column;
    padding-bottom: 36px;
  `}
`

export const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 12px;

  &:disabled {
    color: #5fc4b8;
    opacity: 0.3;
  }
  &:not(:disabled) {
    &:active {
      color: #b8fff7;
      opacity: 0.3;
    }
    &:hover {
      filter: brightness(1.5);
    }
  }
`

const sharedText = css`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const ResourcesText = styled.span`
  ${sharedText}
  color: #98A4AE;
  margin-right: 24px;

  ${media.desktop`
    marign-right: 0px;
    margin-bottom: 12px;
  `}
`

export const LinkText = styled.a`
  ${sharedText}
  color: #5FC4B8;

  :hover {
    text-decoration: none;
    color: #5FC4B8;
  }
`

export const Dot = styled.div`
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: #98A4AE;
  margin-left: 12px;
  margin-right: 12px;
`

export const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 6px;
`
