import styled, { css, createGlobalStyle } from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const PageWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 360px;
  width: 100%;
  height: ${props => props.isQuestionSetLoading
    ? 'calc(100vh - 220px)' : '100%'};
  padding-bottom: ${props => props.isCompletionModalOpen
    ? '88px!important'
    : '48.15px'};
  font-family: Lato;
  max-width: 1920px;
  margin: 0 auto;

  ${mediaqueries.tablet`
    padding-bottom: ${props => props.isCompletionModalOpen && props.isTwoModalBtns
    ? '131px!important'
    : '48.15px'};
  `}

  ${mediaqueries.mobile`
    padding-bottom: ${props => props.isCompletionModalOpen
    ? '131px!important'
    : '48.15px'};
  `}
`

export const ContentWrapper = styled.div`
  width: 500px;
  margin-top: ${props => props.isProblemBank ? '72px' : '97px'};

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    text-align: justify;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 48px;
  }


  ${mediaqueries.mobile`
    width: 100%;
    padding: 0px 24px;
    margin-top: ${props => props.isProblemBank ? '93px' : '120px'};
  `}
`
export const BreadCrumb = styled.div`
  color: #5FC4B8;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: absolute;
  top: 32px;
  left: 24px;
  cursor: pointer;
  user-select: none;

  span {
    width: 9px;
    height: 9px;
    border: 2px solid #5fc4b8;
    border-top-width: 0;
    border-right-width: 0;
    margin-right: 12px;
    transform: rotate(45deg);
  }

  ${mediaqueries.mobile`
    top: 48px;
  `}
`
export const SectionTitle = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: rgba(255, 255, 255, 0.9);
`
export const ExerciseTitle = styled.h2`
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 43px;
  margin-bottom: 24px;
  width: 500px;
  color: rgba(255, 255, 255, 0.9);
  overflow-wrap: break-word;

  ${mediaqueries.mobile`
    width: 100%;
  `}
`
export const NextButton = styled.button`
  height: 40px;
  width: 150px;
  border-radius: 5px;
  border: none;
  background: #5fc4b8;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  outline: none !important;

  &:disabled {
    background-color: #5fc4b8;
    opacity: 0.3;
  }
  &:not(:disabled) {
    &:active {
      background-color: #b8fff7;
      opacity: 0.3;
    }
    &:hover {
      background-color: #b8fff7;
    }
  }

  ::after {
    display: inline-block;
    content: 'Next';

    ${mediaqueries.desktop`
      content: '';
      width: 15.29px;
      height: 10.29px;
      background-image: url('/images/icons/icon-right-arrow.svg');
    `}
  }

  ${mediaqueries.desktop`
      width: 64px;
    `}

  ${mediaqueries.mobile`
      width: 157px;
    `}
`
export const ExerciseWrapper = styled.div`
  width: 472px;
  margin: 0 auto;

  ${mediaqueries.mobile`
    width: 100%;
    min-width: 299px;
  `} 
`
export const Nav = styled.div`
  position: relative;
  width: fit-content;
  min-width: 20px;
  padding: 16px 0;
  margin: 0 auto 48px auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;

  ::before {
    display: block;
    content: '';
    position: absolute;
    z-index: 0;
    top: 25.5px;
    left: 22px;
    width: calc(100% - 44px);
    height: 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`
export const NavItem = styled.div`
  position: relative;
  z-index: 1;
  background-image: url(${props => props.src});
  width: 20px;
  height: 20px;
  ${props => props.selected
    ? css`
    background-size: contain;
  ` : css`
     background-size: 16px 16px;
  `}

  margin-left: 22px;
  margin-right: 22px;
  background-color: #161618;
  background-repeat: no-repeat;
  background-position: center; 
  transition: all 0.4s;
`
export const IncompleteSelectedItem = styled(NavItem)`
  width: 20px;
  height: 20px;
  background-image: url('/images/icons/icon-selected-question-incomplete.svg');
`
export const QuestionWrapper = styled.div`
  width: 472px;
  display: flex;
  text-align: left;
  margin:0 auto;
  flex-flow: column nowrap;
  font-size: calc(12px + var(--font-increase-px));
  line-height: calc(16px + var(--font-increase-px));

  & > div {
    width: 100% !important;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 12px !important;
    padding: 0px !important;

    p {
      background-color: transparent !important;
      text-align: center !important;
    }
  }

  ${mediaqueries.mobile`
    width: 100%;
  `}
`
export const AnswersWrapper = styled.div`
  position: relative;
  width: 500px;
  margin: 0 auto;
  ${props => props.isTextBlank && 'margin-top: -37px;'}
  ${mediaqueries.mobile`
    width: 100%;
  `} 
`
export const MathTypeWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  } 

  .mathTypeWrapper {
    width: 100%!important;
  }
`
export const Option = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`
export const OptionWrapper = styled.label`
  box-sizing: border-box;
  width: 472px;
  min-height: 67px;
  height: fit-content;
  background-color: #0A2A2D;
  padding: 24px 12px;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 16px !important;
  line-height: 19px !important;
  margin-bottom: 12px;
  border: 1px solid transparent;;
  cursor: pointer;
  user-select: none;
  ${props => props.checked && css`
    border: 1px solid white;
  `}
  transition: all 0.7s;

  ${({ isHidden }) =>
    isHidden && `
      text-decoration: line-through;
      color: #B1BFC5;

      & * .MathJax_SVG::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        border-bottom: solid 3px #B1BFC5;
        transform: translateY(-50%);
      }
  `}

  .paragraph {
    font-weight: 400 !important;
  }

  div {
    margin: 0px !important;
    padding: 0px !important;
  }

  input {
    position: absolute;
    width: 0px;
    height: 0px;
  }

  ${mediaqueries.mobile`
    width: 100%;
  `}
`
export const CorrectAnswerWrapper = styled(OptionWrapper)`
  height: fit-content;
  min-height: 90px;
  background-color: transparent;
  border: 1px solid #5FC4B8;
  flex-direction: column;
  align-items: flex-start;
  cursor: default;

  & > p {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 6px;
  }
`
export const UnselectedCorrectAnswerWrapper = styled(CorrectAnswerWrapper)`
  border: 1px solid #FFFFFF;
`
export const IncorrectAnswerWrapper = styled(CorrectAnswerWrapper)`
  border: 1px solid #FFA463;
`
export const UnselectedIncorrectAnswerWrapper = styled(OptionWrapper)`
  color: #B1BFC5;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: default;
  min-height: 69px;
`
export const CircleComponent = styled.div`
  width: 16px;
  height: 16px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.src})`};
  margin-left: 12px;
  margin-bottom: 12px;
  cursor: pointer;
`
export const ShowAnswer = styled.div`
  width: 118px;
  height: 17px;
  margin-top: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5FC4B8;
  cursor: pointer;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-flow: row now;
  width: 302px;
  margin-top: 35px;

  button {
    display: block;
    margin-top: 12px;
    margin-right: 12px;

    ${mediaqueries.mobile`
      margin-right: 0px;
    `}
  }

  ${mediaqueries.mobile`
    flex-flow: row wrap;
    width: 100%;
    justify-content: space-between;
  `}
`
export const CheckButton = styled.button`
  width: 150px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  line-height: 17px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  ${props => props.primary
    ? css`
        border: none;
        background-color: 
        ${props.active ? '#5FC4B8' : 'rgba(95, 196, 184, 0.3)'};
        color: black!important;
        &:disabled {
            background-color: #5fc4b8;
            opacity: 0.3;
          }
          &:not(:disabled) {
            &:active {
              background-color: #b8fff7;
              opacity: 0.3;
            }
            &:hover {
              background-color: #b8fff7;
            }
          }
        `
    : css`
       background-color: transparent;
       border: ${props => `1px solid 
        ${props.active ? '#5FC4B8' : 'rgba(95, 196, 184, 0.3)'}`};
       color:  ${props.active ? '#5FC4B8' : 'rgba(95, 196, 184, 0.3)'};
       &:disabled {
            color: #5fc4b8;
            border-color: #5fc4b8;
            opacity: 0.3;
          }
          &:not(:disabled) {
            &:active {
              color: #b8fff7;
              border-color: #b8fff7;
              opacity: 0.3;
            }
            &:hover {
              color: #b8fff7;
              border-color: #b8fff7;
            }
          }
  `}

  ${mediaqueries.mobile`
    width: 100%;
  `}
`
export const ArrowButtunsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;

  ${mediaqueries.mobile`
    width: 100%;
  `}
`
export const ArrowButtons = styled.button`
  height: 40px;
  width: 64px;
  border-radius: 5px;
  background-size: 15.29px 10.29px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  ${props => !props.active
    ? 'border: 1px solid rgba(95, 196, 184, 0.3)'
    : 'border: 1px solid #5FC4B8'};
  box-sizing: border-box;
  border-radius: 5px;
  background-image: ${props => props.src && `url(${props.src})`};
  &:disabled {
    color: #5fc4b8;
    border-color: #5fc4b8;
    opacity: 0.3;
  }
  &:not(:disabled) {
    &:active {
      color: #b8fff7;
      opacity: 0.3;
    }
    &:hover {
      filter: brightness(1.5);
    }
  }
  ${mediaqueries.mobile`
    width: 100%;
  `}
`
export const AuthoringWrapper = styled.div`
  display: none!important;
  width: 0px!important;
  height: 0px!important;
  overflow: hidden!important;
`

export const NextButtonV2 = styled(ArrowButtons)`
  ${props => props.active && css`
      background-color: #5FC4B8;
      border: none;
      &:not(:disabled) {
        &:active {
          opacity: 0.3;
        }
      }
    `}

  ${mediaqueries.mobile`
    margin-left: ${props => props.fullWidth ? '0px' : '12px'};
  `}
`
export const AnswerExplanationWrapper = styled.div`
  width: 100%;
  font-size: calc(12px + var(--font-increase-px));
  line-height: calc(16px + var(--font-increase-px));
`
export const TitleWrapper = styled.p`
  margin-top: 25px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px !important;
  font-style: normal;
  font-weight: 700!important;
  font-size: 14px!important;;
  line-height: 17px!important;
  letter-spacing: 1px!important;
  text-transform: uppercase!important;
  color: #B1BFC5!important;
`
export const CorrectAnswer = styled.div`
  margin-top: 12px;
`
export const ExplanationWrapper = styled.div`
  margin-top: 12px!important;
`
export const OverrideStyles = createGlobalStyle`
  .lect-video-sec {
    margin-top: -30px!important;
  }
`
export const CustomStepByStepSolnStyles = createGlobalStyle`
    #step-by-step-input {
      width: 275px;
      height: 40px;
      background: #000000;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 5px;
    }

    .stepByStepWrapper {
      margin-top: 24px;
      margin-bottom: -7.68px;
    }

    .stepInputBtn {
      width: 40px;
      height: 40px;
      border: 1px solid #5FC4B8;
      border-radius: 5px;
      background-color: transparent;
      color: #5FC4B8;
    }

    .linkWrapper {
      a {
        width: 327px;
        height: 22px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-decoration: none !important;
      }
    }
`
export const ToastComponentsWrapper = styled.div`
  position: fixed;
  top: ${props => props.showToast ? '76px' : '-76px'};
  opacity: ${props => props.showToast ? '1' : '0'};
  left: 0;
  width: 100%;
  height: fit-content;
  z-index: 8;
  transition: all 0.2s ease-in-out;

  ${mediaqueries.mobile`
    top: ${props => props.showToast ? '97px' : '-97px'};
  `}
`
export const ToastComponent = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  margin: 0 auto;
  background: #25272B;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 24px 12px;

  p {
    margin: 0px;
    padding: 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #FFFFFF;
  }
`
export const BBConfetti = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(/images/icons/icon-bb-confetti.svg);
`

export const CorrectCode = styled.pre`
  background-color: #000000;
  color: #ffffff;
  font-size: 12px;
  border-radius: 5px;
  padding: 12px;
  max-height: 500px;
`

export const CompilationResult = styled.div`
  & > p {
    margin-bottom: 0;
    
    &.result {
      font-size: 18px;
      font-weight: 300;
      margin-top: 12px;
    }
  }

  & > div {
    &#react-mathjax-preview {
      font-size: 18px;
      font-weight: 300;
      margin-top: 12px;

      & .paragraph {
        margin-bottom: 0;
      }
    }
  }
`
