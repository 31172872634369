export const COURSE_STARTED = 'Course Started'
export const QUIZ_COMPLETED = 'Quiz Completed'
export const ACTIVE_LEARNING_COMPLETED = 'Active Learning Completed'
export const SYLLABUS_VIEWED = 'Syllabus Viewed'
export const LECTURE_STARTED = 'Lecture Started'
export const LECTURE_WATCHED = 'Lecture Watched'
export const MIDTERM_EXAM_STARTED = 'Midterm Exam Started'
export const MIDTERM_EXAM_COMPLETED = 'Midterm Exam Completed'
export const FINAL_EXAM_COMPLETED = 'Final Exam Completed'
export const COPY_SCHEDULE = 'Copy Schedule'
export const CSAT_COURSE_START = 'CSAT Course Start'
export const CSAT_COURSE_START_39_WEEK = 'CSAT Course Start 39 Week'
export const NPS_COURSE_END = 'NPS Course End'
export const NPS_COURSE_END_V2 = 'NPS Course End V2'
export const WRITING_ASSIGNMENT_STARTED_GGU = 'Writing Assignment Started GGU'
export const ASSIGNMENT_COMPLETED = 'Assignment Completed'
export const SKOOLI_AUDIT_STUDENTS = 'Skooli Audit Students'
export const COURSERA_LOGIN = 'Coursera Login'

// College success pwa
export const COLLEGE_SUCCESS_ONBOARDING_STARTED = 'College success PWA Onboarding Started'
export const COLLEGE_SUCCESS_SCREEN2_VIEWED = 'College success PWA Intro Screen 2 viewed'
export const COLLEGE_SUCCESS_SCREEN3_VIEWED = 'College success PWA Intro Screen 3 viewed'
export const COLLEGE_SUCCESS_SCREEN4_VIEWED = 'College success PWA Intro Screen 4 viewed'
export const COLLEGE_SUCCESS_GOAL_QUESTION_VIEWED = 'College success PWA Goal Question viewed'
