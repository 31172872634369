import React, { useState } from 'react'
import ModalComponent from '../ModalComponent/ModalComponent'

const QuizNavigationModal = () => {
  const [modalShow, setModalShow] = useState(true)

  const handleModalShow = (modalShow) => {
    setModalShow(modalShow)
    window.location.href = '/'
  }

  return (
    <>
      <ModalComponent
        className='quiz-navigation-modal'
        show={modalShow}
        handleClose={() => handleModalShow(false)}
      >
        <span className='p-3' data-testid='notification'>
          The assessment you tried to access has not yet been unlocked.
        </span>
      </ModalComponent>
    </>
  )
}

QuizNavigationModal.displayName = 'QuizNavigationModal'

export default QuizNavigationModal
