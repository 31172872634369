import { sum } from 'lodash'
import styled from 'styled-components'
import media from '../../../mediaQueries'

export const GradeChartCircleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

export const Wrapper = styled.div`
  margin-top: 46.24px;
`

export const GradeCriteriaChartWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 12px;
  ${media.desktop`
    grid-template-columns: auto auto;
  `}
  ${media.small`
    grid-template-columns: 100%;
  `}
`

export const getConicGradientData = (degrees, colors) => {
  const gradientDataArr = degrees.map((degree, index) => {
    const firstDegree = sum(degrees.slice(0, index))
    const secondDegree = sum(degrees.slice(0, index + 1))

    return `${colors[index]} ${firstDegree}deg ${secondDegree}deg`
  })

  return gradientDataArr.join(',')
}

export const GradeChartCircle = styled.div`
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  margin-right: 12px;
  justify-content: center;
  align-items: center;
  ${({ degrees, colors }) => {
    const gradientData = getConicGradientData(degrees, colors)
    return `
      background-image: conic-gradient(${gradientData})
    `
  }}
`

export const GradeCircleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const GradeCircleTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
`

export const GradeDetailsText = styled(GradeCircleTitle)`
  font-weight: 300;
  margin-bottom: 24px;
`

export const GradeCircleSubTitle = styled.div`
  font-size: 14px;
  line-height: 17px;
  margin-top: 6px;
  color: #b1bfc5;
`

export const InnerGradeCircle = styled.div`
  width: 45px;
  height: 45px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  background: #171719;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
