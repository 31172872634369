import React from 'react'
import Typeform from '../TypeForm'
import { emitter } from '../../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../../Constants/emitterKeys'
import { useStudentDataContext } from '../../Context/Context'
import config from '../../../config'

const ZoomConsentForm = () => {
  const { updateContext, needsGuardianPermission } = useStudentDataContext()

  const { under18ZoomConsentFormId, zoomConsentFormId } = config.course
  const formId = needsGuardianPermission
    ? under18ZoomConsentFormId : zoomConsentFormId

  const handleSubmit = () => {
    setTimeout(() => {
      updateContext({ zoomConsent: true })
      emitter.emit(ON_NAVIGATE_TO, '/')
    }, 2000)
  }

  return (
    <Typeform
      formId={formId}
      sendAttemptId
      handleSubmit={handleSubmit}
      showContentAfterSubmission={false}
      formStyle={{ height: '100vh', width: '100vw', margin: 0 }}
    />
  )
}

ZoomConsentForm.displayName = 'ZoomConsentForm'
export default ZoomConsentForm
