import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { EditorWrapper } from './styles'

const noToolbar = {
  toolbar: null
}

export default function RichTextEditor (props) {
  const { value, isDisabled } = props

  return (
    <EditorWrapper>
      <ReactQuill
        value={value}
        theme='snow'
        modules={noToolbar}
        readOnly={isDisabled}
      />
    </EditorWrapper>
  )
}
