import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const CourseMaterialWrapper = styled.ul`
  padding-left: 0px;
  list-style: none;
  margin-bottom: 19px;
  li {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 26px;
  }
  span {
    width: 40px;
    font-size: 14px;
    color: #5FC4B8;
    letter-spacing: 1px;
    margin-left: 10px;
    margin-right: 15px;
    font-weight: bold;
    line-height: 17px;

    &:disabled {
      color: #5fc4b8;
      opacity: 0.3;
    }
    &:not(:disabled) {
      &:active {
        color: #b8fff7;
        opacity: 0.3;
      }
      &:hover {
        color: #b8fff7;
      }
    }
  }
  i {
    font-size: 18px;
    font-weight: 300;
    color: #5e636b;
    margin-left: 0px;
  }
  ${mediaqueries.tablet`
    display: contents;
    margin-top: 50px;
    li {
      float: left;
      z-index: 1;
      position: relative;
      .ml-2 {
        margin-left: 0px !important;
      }
    }
  `}
`
