export const selectInputStyles = {
  indicatorSeparator: (base, state) => ({
    ...base,
    margin: '4px 0',
    backgroundColor: state.isDisabled
      ? 'rgba(255, 255, 255, 0.1)'
      : 'rgba(255, 255, 255, 0.3)'
  }),
  control: (base, state) => ({
    ...base,
    minHeight: '40px',
    background: state.isDisabled ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.3)',
    color: 'var(--text-primary)',
    border: `1px solid ${
      state.selectProps.hasError
        ? 'var(--error-color)'
        : state.isDisabled
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(255, 255, 255, 0.3)'
    }`,
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    '&:hover': {
      border: '1px solid rgba(255, 255, 255, 0.3)'
    }
  }),
  placeholder: (base, state) => ({
    ...base,
    color: state.isDisabled ? '#495057' : '#6c757d',
    marginLeft: '5px',
    fontSize: '15px'
  }),
  menu: (base) => ({
    ...base,
    background: ' #060606',
    color: 'var(--text-primary)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px var(--shadow-primary)',
    borderRadius: '5px',
    marginTop: '15px'
  }),
  input: (base) => ({
    ...base,
    color: 'var(--text-primary)'
  }),
  menuList: (base) => ({
    ...base,
    padding: 0
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: !state.isSelected ? '#060606' : 'rgba(255, 255, 255, 0.1)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
    cursor: 'pointer',
    '&:last-child': {
      borderBottom: 'none',
      borderRadius: '0 0 5px 5px'
    },
    '&:first-of-type': {
      borderRadius: '5px 5px 0 0'
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    }
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isDisabled && 'rgba(255, 255, 255, 0.3)'
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: 'var(--text-primary)',
    display: 'flex',
    fontSize: '15px',
    lineHeight: '19px'
  }),
  multiValue: (base) => ({
    ...base,
    background: 'var(--black-secondary)',
    color: 'var(--text-primary)'
  }),
  singleValue: (base) => ({
    ...base,
    color: 'var(--text-primary)'
  })
}
