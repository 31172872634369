import styled from 'styled-components'
import media from '../../../mediaQueries'

export const AssignmentSection = styled.section`
  ${media.mobile`
    padding: 0px 12px;
  `}
`
export const AssignmentContainer = styled.div`
  position: relative;
  max-width: 722px;
`
export const GradeHeading = styled.p`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  margin: 0 0 16px 0;
`
export const AssignmentDescription = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  margin: 25px 0;
  width: 100%;
  p {
    font-size: inherit;
    line-height: inherit;
  }

  a {
    display: inline;
  }
`
export const BreakLine = styled.div`
  width: 100%;
  margin-top: 17px;
  margin-bottom: 24px;
  height: 0px;
  border: 1px solid rgba(255, 255, 255, 0.3);
`
export const SubmissionHeading = styled.h2`
  font-weight: 700;
  font-size: 18px;
  line-height: 17px;
  letter-spacing: 1px;
  margin-top: 13px;
  margin-bottom: 0;
`

export const TextAreaWrap = styled.div`
  margin-bottom: 50px;
  width: 100%;
`

export const SubmissionParagraph = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  font-style: normal;
  margin: ${props =>
    `${props.marginTop || '12px'} 0 ${props.marginBottom || '24px'} 0`};
`

export const Link = styled.a`
  color: #5FC4B8 !important;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`
export const ExternalLink = styled(Link)`
  text-transform: none;
  color: #5fc4b8;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-right: 24px;
  letter-spacing: 1px;

  :hover {
    color: #5fc4b8;
  }
`

export const ExternalLinkText = styled.span`
  vertical-align: top;
`

const Icon = styled.span`
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-color: #5FC4B8;
  margin-right: 8px;
`

export const RubricIcon = styled(Icon)`
  mask: url(/images/icons/card-checklist.svg) no-repeat 50% 50%;
`

export const JSTorIcon = styled(Icon)`
  mask: url(/images/icons/box-arrow-up-right.svg) no-repeat 50% 50%;
`

export const DownloadIcon = styled(Icon)`
  mask: url(/images/icons/icon-download.svg);
  width: 20.11px;
  height: 16.25px;
  margin-right: 12px;
`

export const SubmitButton = styled.button`
  margin: 24px auto;
  width: 120px;
  height: 40px;
`
export const ReviewContainer = styled.div`
  width: 100%;
  margin-top: 21px;
`
export const ReviewItem = styled.div`
  margin-bottom: ${({ marginBottom }) => marginBottom || '25'}px;
  font-family: Lato;
  font-style: normal;
  text-align: left;
  line-height: 17px;

  h4 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 1px;
    margin-bottom: 9px;
  }

  P {
    margin:0;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
  }
`
export const ButtonsContainer = styled.div`
  margin-top: 24px;
  text-align: center;
  width: 100%;

  &.top-page{
    margin-top: 12.5px;
    margin-bottom: -5px;
  }

  button {
    margin: 0 15px;
  }
`

export const ReviewItemsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;

  div {
    margin-right: 77px;

    ${media.mobile`
      flex: 50%;
      margin-right: 0;
    `}
  }

  div:last-child {
    margin-right: 0;
  }
`

export const AnnotatedFeedbackArea = styled.div`
  width: 100%;
  height: 684px;
  background: #000;
  padding: 20px 21px 25px;
  border-radius: 5px;

  p {
    margin-bottom: 14px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.183px;
    color: #FFFFFF;
  }

  ${media.mobile`
    padding: 22px 15px 22px;
  `}
`
export const DownloadText = styled.span`
  text-transform: uppercase;
  color: #5fc4b8;
  vertical-align: top;
`

export const SubmissionClosedMessage = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 21.6px;
  margin-bottom: 24px;
`

export const PreviewTextMessage = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  margin-top: 18px;
  color: #FFFFFF;
`

export const StarterFile = styled.div`
  font-size: 18px;
  width: 100%;
  line-height: 22px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  p {
    font-weight: 400;
    color: #FFFFFF;
  }
`
export const AssignmentFileLink = styled.a`
  color: #5FC4B8 !important;
  font-weight: 300;
  font-size: 18px;
  text-transform: none;
`

export const BreakdownContainer = styled.div`
  margin-top: 0;
  font-size: 18px;
  line-height: 24px;

  & div {
    &.suite {
      margin-bottom: 12px;
    }

    &.header {
      display: flex;
      flex-direction: row;
      background-color: #232327;
      font-weight: 400;
      border-radius: 5px 5px 0 0;
      padding: 8px 16px;

      & span {
        &:first-child {
          flex: 1;
        }
      }
    }

    &.step {
      display: flex;
      flex-direction: row;
      background-color: #1D1D1F;
      font-weight: 300;
      padding: 8px 16px;

      & span {
        &:first-child {
          flex: 1;
        }
      }

      &:last-child {
        border-radius: 0 0 5px 5px;
      }
    }
  }
`
export const CheckboxWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 24px;
  margin-bottom: 26px;
`
