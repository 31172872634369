import React, { Component } from 'react'
import ModalComponent from '../ModalComponent/ModalComponent'

class StepsSolutionModal extends Component {
  constructor (props) {
    super(props)
    this.setModalShow = this.setModalShow.bind(this)
    this.state = {
      modalShow: false,
      modalContent: ''
    }
  }

  static getDerivedStateFromProps (props) {
    const { modalContent } = props
    if (modalContent) return { modalShow: true, modalContent }
    return null
  }

  setModalShow (modalShow, modalContent) {
    const { setModalContent } = this.props
    this.setState({ modalShow, modalContent })
    setModalContent('')
  }

  render () {
    const { modalShow, modalContent } = this.state
    return (
      <ModalComponent
        className='mobile-modal'
        show={modalShow}
        handleClose={() => this.setModalShow(false, '')}
      >
        {modalContent}
      </ModalComponent>
    )
  }
}

export default StepsSolutionModal
