import React from 'react'
import {
  InstructorItemWrapper,
  InstructorTitle,
  InstructorDetailsWrapper,
  InstructorTopics,
  InstructorReadBioBtn
} from './styled'

const SingleInstructor = ({
  instructor,
  getInstructor,
  setBioInstructor,
  setShowInstructorBio
}) => {
  // cr means courseResource here
  const { name, crTopicTheme, crHeadshot: image } = instructor

  const handleReadBio = () => {
    const bioInstructor = getInstructor(name)
    if (!bioInstructor) return

    setBioInstructor(bioInstructor)
    setShowInstructorBio(true)
  }

  return (
    <InstructorItemWrapper image={image}>
      <InstructorDetailsWrapper data-cy='instructorDetails'>
        <InstructorTitle>{name}</InstructorTitle>
        {crTopicTheme && <InstructorTopics>{crTopicTheme}</InstructorTopics>}
        <InstructorReadBioBtn onClick={handleReadBio}>
          read bio
        </InstructorReadBioBtn>
      </InstructorDetailsWrapper>
    </InstructorItemWrapper>
  )
}

export default SingleInstructor
