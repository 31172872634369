import debounce from 'lodash/debounce'
import React, { Component } from 'react'
import $ from 'jquery'
import classNames from 'classnames'

import QuestionComponent from '../QuestionComponent/QuestionComponent'
import Context from '../Context/Context'
import QuestionNextButton from '../QuestionComponent/QuestionNextButton'
import QuestionPreviousButton from '../QuestionComponent/QuestionPreviousButton'
import FeedbackToast from '../FeedbackToast/FeedbackToast'
import FreeformAuthoringDisplay from './FreeformAuthoringDisplay'
import MathType from '../MathType/MathType'
import CourseButton from '../CourseButton/CourseButton'
import ShowFeedback from '../ShowFeedBack/ShowFeedBack'
import { PIN_TEXT, PINNED_TEXT } from '../../Constants/index'
import MobileAppConnectionStatus from '../MobileAppConnectionStatus/MobileAppConnectionStatus'

import {
  PIN_ICON,
  PINNED_ICON_WHITE
} from '../../Constants/progressBarIconSource'
import { emitter } from '../Emitter/Emitter'
import { display, getCorrectAnswer } from './utils/wiris'
import { ACTIVE_LEARNING,
  ACTIVITY_TYPES } from '../../Constants/sectionType'
import {
  ON_STUDENT_ANSWER,
  ON_SUBMIT_ANSWER
} from '../../Constants/emitterKeys'
import MathJax from '../MathJax/MathJax'
import { findAnswer } from '../../utilities/questions'
import { myScriptHandler } from '../../utilities/mobileAppUtils'
import {
  PaginationWrapper,
  TextWrapper,
  QuestionText,
  QuestionCard,
  LessonText,
  MathEditor,
  PinWrapper,
  PinText,
  PinButtonWrapper,
  QuestionTextAL,
  AdaptationWrapper,
  PinButton
} from './styles'
import { getStudentAnswersKey } from '../../utilities/contextKeys'
import { BREAKPOINTS } from '../../mediaQueries'
import api from '../../api'
import JDoodleCompiler from '../JDoodleCompiler/JDoodleCompiler'

class FreeformEquationQuestion extends Component {
  constructor (props) {
    super(props)
    this.showResult = this.showResult.bind(this)
    this.showResultDebounced = debounce(this.showResult, 500)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.initializeWiris = this.initializeWiris.bind(this)
    this.onEditorChange = this.onEditorChange.bind(this)

    this.state = {
      tryAttempt: 0,
      showAttempt: false,
      result: '',
      ans: '',
      isCheckAnswerDisabled: true,
      isNextDisabled: true,
      isCheckLoading: false,
      quizzesEditor: null,
      myScriptTimestamp: null,
      isPinned: false,
      showToast: false,
      studentAnswerRes: {}
    }
    this.prevAnswerFound = false
    this.mathTypeRef = React.createRef()
    this.myScriptHandler = myScriptHandler.bind(this)
    this.intervalId = null
  }

  async componentDidMount () {
    const { question: { Question_uuid: questionUuid } } = this.props
    const { studentData: { pinnedQuestions } } = this.context
    this.setState({ isPinned: pinnedQuestions.includes(questionUuid) })
    const studentAnswers = this.getStudentAnswers()
    const fAns = findAnswer(questionUuid, studentAnswers)
    this.correct = fAns ? fAns.correct : null
    if (fAns && this.correct) {
      this.setState({
        result: '',
        isNextDisabled: false
      })
      const { current: mathType } = this.mathTypeRef
      mathType.setMathML(fAns ? fAns.answer : '')
    }

    await this.initializeWiris()
    this.myScriptHandler()
    if (window.innerWidth > BREAKPOINTS.mobile) {
      $('.wrs_focusElement').focus()
    }
  }

  async componentDidUpdate (prevProps, prevState) {
    const studentAnswers = this.getStudentAnswers()
    const {
      question: { Question_uuid: questionUuid },
      make_reveal_false: makeRevealFalse,
      isRevealed
    } = this.props
    const { studentData: { pinnedQuestions } } = this.context
    const {
      question: { Question_uuid: prevQuestionUuid },
      isRevealed: prevIsRevealed
    } = prevProps
    if (prevQuestionUuid !== questionUuid) {
      this.setState({ isPinned: pinnedQuestions.includes(questionUuid) })
    }
    const { current: mathType } = this.mathTypeRef
    const currentQuestionUuid = prevQuestionUuid !== questionUuid
    const fAns = findAnswer(questionUuid, studentAnswers)
    this.correct = fAns ? fAns.correct : null
    if (prevIsRevealed !== isRevealed && isRevealed) {
      const correctAnswer = getCorrectAnswer()
      mathType.setMathML(correctAnswer)
      this.showResultDebounced('clicked')
      makeRevealFalse()
    } else if (currentQuestionUuid || (fAns && !this.prevAnswerFound)) {
      this.prevAnswerFound = true
      mathType.setMathML(fAns ? fAns.answer : '')
      await this.initializeWiris()
      clearInterval(this.intervalId)
      this.myScriptHandler()
      if (window.innerWidth > BREAKPOINTS.mobile) {
        $('.wrs_focusElement').focus()
      }
      this.setState({
        result: '',
        tryAttempt: 0,
        isNextDisabled: currentQuestionUuid || !(fAns && fAns.answer !== prevState.ans)
      })
    }
  }

  async componentWillUnmount () {
    clearInterval(this.intervalId)
    await api.setMyScriptData('nodata')
  }

  async initializeWiris () {
    document.getElementById('tabdelivery').className = ''
    const { question:
      {
        Question_uuid: questionUuid,
        configuration
      }
    } = this.props
    const studentAnswers = this.getStudentAnswers()
    const fAns = findAnswer(questionUuid, studentAnswers)
    this.correct = fAns ? fAns.correct : null
    const questionConfig = configuration
    const contentJSON = {
      text: '',
      feedback: '',
      question: questionConfig,
      gievnAnswer: fAns?.answer || ''
    }
    await display(contentJSON, editor => {
      this.setState({ quizzesEditor: editor || questionUuid })
    })
    this.setState({ ans: '' })
  }

  getStudentAnswers = () => {
    const {
      currentChapter: { type },
      studentData
    } = this.context
    const studentAnswersKey = getStudentAnswersKey(type)
    return studentData[studentAnswersKey]
  }

  async showResult (showSolutionClicked) {
    this.prevAnswerFound = true
    this.setState({ isCheckLoading: true, showToast: false })

    const {
      question: { Question_uuid: questionUuid },
      saveProgress
    } = this.props
    const { isPinned: pinned } = this.state
    const { current: mathType } = this.mathTypeRef
    const ansString = mathType.getMathML()
    const qResult = await Promise.all([
      mathType.asyncCheckAnswer(),
      // Fix the issue loading flash because the promise resolve too soon
      new Promise(resolve => setTimeout(resolve, 300))
    ]).then(r => r[0])

    const testRes = {}
    testRes.uuid = questionUuid
    testRes.answer = ansString
    testRes.correct = qResult
    testRes.type = ACTIVITY_TYPES[ACTIVE_LEARNING]
    testRes.questionActivity = { pinned }

    window.outlierLog('Check Answer', testRes.answer)
    if (qResult) {
      this.setState({
        result: 'correct',
        tryAttempt: 0,
        showToast: !showSolutionClicked && true,
        isNextDisabled: false
      })
      window.outlierLog('Correct Answer', testRes.answer)
    } else {
      const finalTryAttempt = this.state.tryAttempt + 1
      this.setState({
        result: 'incorrect',
        tryAttempt: finalTryAttempt,
        showAttempt: true,
        showToast: finalTryAttempt >= 3,
        isNextDisabled: true
      })
      window.outlierLog('Incorrect Answer', testRes.answer)
    }
    emitter.emit(ON_STUDENT_ANSWER, testRes)
    this.setState({
      studentAnswerRes: testRes,
      isCheckLoading: false
    })
    saveProgress(testRes)
  }

  // Should return false if the action was not allowed.
  // Component uses Wiris entry, so we must ignore arrow keys which are used to
  // move around within that input field.
  handleKeyPress (type) {
    const {
      isNextDisabled,
      isCheckAnswerDisabled,
      studentAnswerRes
    } = this.state

    // Typically from pressing the left arrow, allow it and ignore it.
    if (type && type === 'previous') return true

    // Typically from pressing the right arrow, allow it and ignore it.
    if (type && type === 'advance') return true

    // Everything else is interpreted as check or advance forward
    if (!isNextDisabled) {
      this.props.onNextButtonClick(studentAnswerRes)
      return true
    } else if (!isCheckAnswerDisabled) {
      this.showResult()
      return true
    }

    // Unrecognized, default is don't allow it.
    return false
  }

  onEditorChange (ans) {
    const {
      state: { ans: prevAns, isCheckAnswerDisabled: prevIsCheckAnswerDisabled }
    } = this
    const isCheckAnswerDisabled = !ans

    if (ans !== prevAns) this.setState({ ans, isNextDisabled: true })

    if (isCheckAnswerDisabled !== prevIsCheckAnswerDisabled) {
      this.setState({ isCheckAnswerDisabled })
    }
  }

  onPinClick (currentQuestionUUID) {
    const { isPinned } = this.state
    const { studentData, updateContext } = this.context
    const { pinnedQuestions } = studentData
    const studentAnswers = this.getStudentAnswers()
    const fAnswer = findAnswer(currentQuestionUUID, studentAnswers)
    const currentQuestionIndex = pinnedQuestions.indexOf(currentQuestionUUID)
    const answer = {
      type: ACTIVITY_TYPES[ACTIVE_LEARNING],
      uuid: currentQuestionUUID,
      questionActivity: { pinned: false },
      ...(fAnswer?.correct && {
        answer: fAnswer.answer,
        correct: fAnswer.correct
      })
    }
    if (isPinned) {
      pinnedQuestions.splice(currentQuestionIndex, 1)
    } else {
      answer.questionActivity.pinned = true
      pinnedQuestions.push(currentQuestionUUID)
    }
    emitter.emit(ON_SUBMIT_ANSWER, answer)
    studentData.pinnedQuestions = pinnedQuestions
    updateContext({ studentData })
    this.setState({ isPinned: !isPinned })
  }

  render () {
    const {
      question,
      question: {
        Question_uuid: questionUUID,
        jdoodleEditor,
        starterCode,
        text_when_stuck: textWhenStuck,
        additionalConfigurations,
        configuration, adaptationStatement
      },
      lesson_text: lessonText,
      structuredText,
      display_illustration: displayIllustration,
      desmosgraph: desmosGraph,
      gMath,
      question_text: questionText,
      displayReveal,
      filteredActvQuestInd,
      correctProps,
      currentQuestionUUID,
      incorrectProps
    } = this.props
    const {
      isCheckLoading,
      quizzesEditor,
      isNextDisabled,
      isCheckAnswerDisabled,
      showAttempt,
      tryAttempt,
      isPinned,
      result,
      showToast,
      studentAnswerRes
    } = this.state
    const { isSidePanelOpen, isLastQuestionAnswered } = this.context
    const queTextClass = classNames({
      'que-text': false,
      'active-que-text': false,
      col: true
    })
    const checkAnswer = 'Check'
    if (!question) return <div className='text-center'>Loading...</div>
    return (
      <>
        <FreeformAuthoringDisplay />
        <>
          {showToast && result &&
          <FeedbackToast
            showToast={showToast}
            isCorrect={result === 'correct'}
          />}
          <TextWrapper isALRedesign>
            <QuestionText isALRedesign>
            Card {filteredActvQuestInd + 1}
            </QuestionText>
            <PinWrapper
              isALRedesign
              onClick={() => this.onPinClick(currentQuestionUUID)}
            >
              <PinText>{isPinned ? PINNED_TEXT : PIN_TEXT}</PinText>
              <PinButtonWrapper
                isALRedesign
              >
                <PinButton
                  src={isPinned ? PINNED_ICON_WHITE : PIN_ICON}
                  data-testid='pinButton'
                />
              </PinButtonWrapper>
            </PinWrapper>
          </TextWrapper></>

        <div id='delivery' style={{ display: 'none' }}>
          <QuestionCard
            id='questiontext'
            isALRedesign
            className='active__learning-block component__backdrop mb-2'
          >
            {structuredText}
            {lessonText && (
              <>
                <LessonText
                  className='pb-3 font__md'
                  isALRedesign
                  isSidePanelOpen={isSidePanelOpen}
                >
                  {lessonText}
                </LessonText>
              </>
            )}
            <QuestionTextAL
              className='row align-items-start pt-4'
              isALRedesign
              isSidePanelOpen={isSidePanelOpen}
            >
              {displayIllustration}
              {/* --- Added Desmos Here --- */}
              {desmosGraph}
              {questionText && (
                <div className={queTextClass}>
                  <div data-testid='questionText' className='font__md'>
                    {questionText}
                  </div>
                </div>
              )}
            </QuestionTextAL>
            {gMath}
            <div>
              {jdoodleEditor &&
              <div className='mb-2'>
                <JDoodleCompiler
                  key={questionUUID}
                  questionUUID={questionUUID}
                  isALRedesign
                  starterCode={starterCode}
                />
              </div>}
              <MathEditor
                className='mx-auto row no-gutters'
                isALRedesign
                isSidePanelOpen={isSidePanelOpen}
              >
                <MathType ref={this.mathTypeRef} onChange={this.onEditorChange}
                  questionConfiguration={configuration} quizzesEditor={quizzesEditor}
                  additionalConfigurations={additionalConfigurations}
                />
                <MobileAppConnectionStatus />
              </MathEditor>
            </div>
            {result &&
            <ShowFeedback
              textWhenStuck={textWhenStuck}
              showStuck={showAttempt && tryAttempt >= 3}
              showSolutionHandler={displayReveal}
              studentAnswer={studentAnswerRes?.answer}
              sectionType={ACTIVE_LEARNING} result={result}
              correctProps={correctProps}
              isALRedesign
              incorrectProps={incorrectProps} />}
          </QuestionCard>

          <PaginationWrapper isALRedesign >
            <QuestionPreviousButton
              isALRedesign
              {...this.props} />

            {isNextDisabled ? (
              <CourseButton
                id='test'
                name='test'
                className={{
                  'btn-primary': true,
                  'mx-1': true,
                  check_answer: true
                }}
                onClick={this.showResult}
                dataCypress='checkAnswerButton'
                disabled={isCheckAnswerDisabled || isCheckLoading}
              >
                {isCheckLoading ? <div>
                  <i className='fa fa-spinner fa-spin' /></div>
                  : checkAnswer}
              </CourseButton>
            ) : (
              (!isLastQuestionAnswered &&
                <QuestionNextButton
                  {...this.props}
                  isALRedesign
                  isNextDisabled={isNextDisabled}
                  studentAnswerRes={studentAnswerRes}
                />)
            )}

            <strong style={{ display: 'none' }}>
              <span id='feedback' />
            </strong>
          </PaginationWrapper>
          {
            adaptationStatement &&
              <AdaptationWrapper
                isALRedesign
                className='active__learning-pagination
                adaptation bottom-fixed text-center'
              >
                <div className='adaptation-statement'>
                  <MathJax math={adaptationStatement} />
                </div>
              </AdaptationWrapper>
          }

          {/* <!-- The custom feedback will be inserted here
            in place of the empty span element. --> */}
          <div id='customfeedback' style={{ display: 'none' }}>
            <span />
          </div>
          {/* <!-- The answer feedback component will be inserted here
            in place of the empty span element. --> */}
          <div id='answerfeedback' style={{ display: 'none' }}>
            <span />
          </div>
        </div>
      </>
    )
  }
}

FreeformEquationQuestion.contextType = Context
export default QuestionComponent(FreeformEquationQuestion)
