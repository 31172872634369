import React from 'react'
import {
  ChecklistIcon,
  ChecklistIconContainer,
  Card,
  ChecklistIconContainerChildWrapper,
  ChecklistIconWrapper,
  FlexWrapper,
  IsCaughtUp,
  CourseStartDate,
  CardWrapper,
  ChecklistContainer
} from './styles'
import { Progress, Background, Container } from '../../ProgressTimeLine/styles'
import { getProgressBarColor } from '../../../utilities/courseUtils'
import { GREY, YELLOW, RED, VIOLET } from '../checklistColors'
import { ASSIGNMENT, EXAM } from '../../../Constants/sectionType'

const CourseCard = ({
  displayName,
  courseResourcesSchedule,
  activityCount,
  isAudit,
  progress,
  hasCohortStarted,
  isWorkingAhead,
  isCaughtUp,
  onClick,
  activeAssessments = []
}) => {
  const progressBarIndicator = getProgressBarColor(
    progress,
    false,
    courseResourcesSchedule,
    isAudit
  )

  const activeExams = activeAssessments.filter(({ type }) => type === EXAM)
  const hasActiveExam = activeAssessments.some(({ type }) => type === EXAM)
  const hasActiveAssignment = activeAssessments.some(
    ({ type }) => type === ASSIGNMENT
  )
  const activeAssignments = activeAssessments.filter(
    ({ type }) => type === ASSIGNMENT
  )

  const getAssessmentLabel = () => {
    if (hasActiveExam) {
      if (hasActiveAssignment) {
        return `${activeAssessments.length} Assessments`
      }
      if (activeExams.length > 1) {
        return `${activeExams.length} Assessments`
      }
      return EXAM
    }
    if (hasActiveAssignment) {
      if (activeAssignments.length > 1) {
        return `${activeAssignments.length} Assignments`
      }
      return ASSIGNMENT
    }

    return ''
  }

  const getAssessmentWarningColor = () => {
    const hasActiveAssessmentRedColor = activeAssessments.some(
      ({ assessmentColor }) => assessmentColor === RED
    )
    if (hasActiveAssessmentRedColor) return RED

    const hasActiveAssessmentYellowColor = activeAssessments.some(
      ({ assessmentColor }) => assessmentColor === YELLOW
    )
    if (hasActiveAssessmentYellowColor) return YELLOW

    const hasActiveAssessmentGreyColor = activeAssessments.some(
      ({ assessmentColor }) => assessmentColor === GREY
    )
    if (hasActiveAssessmentGreyColor) return GREY

    return ''
  }

  const { startDate } = courseResourcesSchedule?.[0] || {}
  const assessmentLabel = getAssessmentLabel()

  const renderActivities = () => {
    const assessmentWarningColor = getAssessmentWarningColor()
    const showAssessmentLabel = assessmentLabel && !isAudit

    return (
      <>
        <ChecklistIconContainerChildWrapper data-testid='activitiesContainer'>
          <ChecklistContainer>
            <ChecklistIconWrapper color={progressBarIndicator}>
              <ChecklistIcon
                width='14px'
                height='10.5px'
                color={progressBarIndicator}
                marginRight='6px'
                src='images/icons/checklist.svg'
              />
              {!!activityCount &&
              <ChecklistContainer className='activityCount'>
                {activityCount}
              </ChecklistContainer>
              }
            </ChecklistIconWrapper>

            {showAssessmentLabel && (
              <ChecklistIconWrapper
                data-testid='assignment-icon'
                color={assessmentWarningColor}
              >
                <ChecklistIcon
                  height='16px'
                  width='16px'
                  color={assessmentWarningColor}
                  marginRight='4px'
                  src='images/icons/calendar.svg'
                />
                {assessmentLabel}
              </ChecklistIconWrapper>
            )}
          </ChecklistContainer>
          <ChecklistIcon
            width='13px'
            height='7px'
            src='images/icons/caret-right.svg'
          />
        </ChecklistIconContainerChildWrapper>
        <div>
          <Container noMargin>
            <Background />
            <Progress percent={progress} color={progressBarIndicator} />
          </Container>
        </div>
      </>
    )
  }

  const renderCohortMessage = () => {
    const showCaughtUpMessage = isCaughtUp && !isAudit

    return (
      <FlexWrapper>
        {showCaughtUpMessage && (
          <>
            <ChecklistIcon
              width='12px'
              height='9.55px'
              color={VIOLET}
              src='images/icons/icon-check-basic.svg'
            />
            <IsCaughtUp data-testid='caughtUpText'>
              Caught up {isWorkingAhead ? <span>+{isWorkingAhead}</span> : null}
            </IsCaughtUp>
          </>
        )}
        {!hasCohortStarted && (
          <CourseStartDate data-testid='courseStartDate'>
            Begins {startDate}
          </CourseStartDate>
        )}
        <ChecklistIcon
          width='13px'
          height='7px'
          flex
          src='images/icons/caret-right.svg'
        />
      </FlexWrapper>
    )
  }

  const showActivities = !isCaughtUp && hasCohortStarted
  return (
    <CardWrapper>
      <Card hasCaughtUp={isCaughtUp || !hasCohortStarted} onClick={onClick}>
        <h6 data-testid='courseName'>
          {isAudit ? `${displayName} (Audit)` : displayName}
        </h6>
        <ChecklistIconContainer hasAssessment={assessmentLabel}>
          {showActivities ? renderActivities() : renderCohortMessage()}
        </ChecklistIconContainer>
      </Card>
    </CardWrapper>
  )
}

export default CourseCard
