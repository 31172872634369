export const PRACTICE_EXAM = 'Practice Exam'
export const SINGLE_MIDTERM_EXAM = 'Midterm Exam'
export const MIDTERM_EXAM_1 = 'Midterm Exam 1'
export const MIDTERM_EXAM_2 = 'Midterm Exam 2'
export const EXAM_1 = 'Exam 1'
export const EXAM_2 = 'Exam 2'
export const EXAM_3 = 'Exam 3'
export const EXAM_4 = 'Exam 4'
export const EXAM_5 = 'Exam 5'
export const MIDTERM_EXAM_2_PART_1 = 'Midterm Exam 2 Part 1'
export const MIDTERM_EXAM_2_PART_2 = 'Midterm Exam 2 Part 2'
export const FINAL_EXAM_PART_I = 'Final Exam Part I'
export const FINAL_EXAM_PART_II = 'Final Exam Part II'
export const FINAL_EXAM = 'Final Exam'
export const SET_UP_YOUR_EXAM = 'Set Up Your Exam'
