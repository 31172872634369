export const ACTIVE_LEARNING = 'Active Learning'
export const DISCUSSION = 'Discussion'
export const PRACTICE_EXERCISES = 'Practice Exercises'
export const PRACTICE_TERMS = 'Practice Terms'
export const PROBLEM_BANK = 'Problem Bank'
export const QUIZ = 'Quiz'
export const EXAM = 'Exam'
export const ASSIGNMENT = 'Assignment'
export const GUESSWORK = 'Guesswork'
export const LECTURE = 'Lecture'
export const READINGS = 'Readings'
export const PRACTICE = 'Practice'
export const CONCEPT_MAP = 'Concept Map'
export const RETAKE = 'Retake'
export const PRACTICE_SETS = 'Practice Sets'
export const MULTI_LECTURE_VIDEOS = 'multi_lecture_videos'
export const PRACTICE_TERM = 'practice_terms'
export const FINAL_ESSAY = 'Final Essay'
export const SUBSECTION = 'Subsection'

// studentAnswers types
export const ACTIVITY_TYPES = {
  [ACTIVE_LEARNING]: 'active_learning',
  [GUESSWORK]: 'guesswork',
  [PRACTICE_EXERCISES]: 'practice_exercises',
  [PROBLEM_BANK]: 'problem_banks',
  [QUIZ]: 'quiz',
  [RETAKE]: 'retake',
  [EXAM]: 'exam',
  [PRACTICE_TERMS]: 'practice_terms',
  [CONCEPT_MAP]: 'conceptMap'
}

export const SECTION_TYPE = {
  ACTIVE_LEARNING: 'active_learning',
  GUESSWORK: 'guesswork',
  CALCULUS_LECTURE_VIDEOS: 'lecture',
  LECTURE_VIDEOS: 'multi_lecture_videos',
  PRACTICE_EXERCISES: 'practice_exercises',
  PRACTICE_TERMS: 'practice_terms',
  QUIZ: 'quiz',
  READINGS: 'readings',
  CONCEPT_MAP: 'conceptMap',
  PROBLEM_BANK: 'problem_bank'
}
