/**
 * Extracts rubric headers and associated steps from the provided rubric data.
 * @param {Object} rubricData - The rubric data containing headers, sets, and suites.
 * @returns {Object} - An object where the keys are rubric headers and the values are steps.
 */
export const extractRubricHeadersWithSteps = (rubricData) => {
  return rubricData?.sets?.reduce((result, set) => {
    set.suites.forEach(suite => {
      if (suite.rubric_row) {
        const rubricHeader = suite.rubric_row.header
        result[rubricHeader] = {
          rubric_row: suite.rubric_row,
          steps: suite.steps.map(step => ({
            id: step.id,
            name: step.name,
            weight: step.weight,
            description: step.description
          }))
        }
      }
    })
    return result
  }, {})
}

/**
 * Maps inputs with achieved points for all steps in the auto grade submissionResult.
 * @param {Object} submissionResult - The submission result containing step results.
 * @returns {Array} - An array of steps with mapped inputs and achieved points.
 */
export const mapInputsWithAchievedPointsForAllSteps = (submissionResult) => {
  return submissionResult?.step_results?.map(step => {
    if (step.auto_test_step.data?.inputs) {
      const inputs = step.auto_test_step.data.inputs
      const logSteps = step.log.steps

      const mappedInputs = inputs.map((input, index) => ({
        ...input,
        achievedPoints: logSteps[index]?.achieved_points ?? 0
      }))

      return {
        ...step,
        auto_test_step: {
          ...step.auto_test_step,
          data: {
            ...step.auto_test_step.data,
            inputs: mappedInputs
          }
        }
      }
    }

    return step
  })
}

/**
 * Groups mappedSubmissionResults by rubric header, calculating total achieved points and weight.
 * @param {Object} rubricHeadersWithSteps - The rubric data containing headers and steps.
 * @param {Array} mappedSubmissionResults - The mapped submission results to group by header.
 * @returns {Array} - An array of grouped results, including header, results, total achieved points, and weight.
 */
export const groupTestResultsByHeader = (rubricHeadersWithSteps, mappedSubmissionResults) => {
  if (!rubricHeadersWithSteps || !mappedSubmissionResults) { return [] }
  const rubricMap = Object.values(rubricHeadersWithSteps).reduce((acc, { rubric_row, steps }) => {
    steps.forEach(step => {
      acc[step.id] = rubric_row.header
    })
    return acc
  }, {})

  const orderedHeaders = Object.values(rubricHeadersWithSteps).flatMap(({ rubric_row }) => rubric_row.header)

  const groupedResults = orderedHeaders.map(header => ({
    header,
    results: [],
    totalAchievedPoints: 0,
    totalWeight: 0
  }))

  mappedSubmissionResults.forEach(testResult => {
    const stepId = testResult.auto_test_step.id
    const header = rubricMap[stepId]

    if (header) {
      const group = groupedResults.find(gr => gr.header === header)
      if (group) {
        group.results.push(testResult)
        group.totalAchievedPoints += testResult.achieved_points
        group.totalWeight += testResult.auto_test_step.weight
      }
    }
  })

  return groupedResults
}

/**
 * This function processes the provided submission results and auto-test details to:
 * - Map the achieved points for each step in the submission.
 * - Extract rubric headers and associated steps from the auto-test details.
 * - Group test results by rubric header, aggregating achieved points and weights.
 * - Compute the total achieved points and total weight across all grouped results.
 *
 * Returns an object containing:
 * - `groupedStepsByRubricHeader`: An array of grouped steps with their headers, results, total achieved points, and total weight.
 * - `totalAchievedPoints`: The sum of achieved points across all grouped steps.
 * - `totalWeight`: The sum of weights across all grouped steps.
 *
 * @param {Object} submissionResult - The result of the auto grade submission with achieved points for each step.
 * @param {Object} autoTestDetails - Details of the auto-tests with rubric headers for each step.
 * @returns {Object} - An object containing the grouped steps, total achieved points, and total weight.
 */
export const calculateScoreDetails = (submissionResult, autoTestDetails) => {
  const mappingResult = mapInputsWithAchievedPointsForAllSteps(submissionResult)
  const extractedRubricHeadersWithSteps = extractRubricHeadersWithSteps(autoTestDetails)
  const groupedStepsByRubricHeader = groupTestResultsByHeader(
    extractedRubricHeadersWithSteps,
    mappingResult
  )

  const totalAchievedPoints = groupedStepsByRubricHeader
    .reduce((sum, group) => sum + group.totalAchievedPoints, 0)

  const totalWeight = groupedStepsByRubricHeader
    .reduce((sum, group) => sum + group.totalWeight, 0)

  return {
    groupedStepsByRubricHeader,
    totalAchievedPoints,
    totalWeight
  }
}
