import { GUESSWORK, EXAM, QUIZ, ACTIVE_LEARNING } from '../Constants/sectionType'
import {
  LAST_GUESSWORK_UUID, LAST_EXAM_UUID, LAST_QUIZ_UUID
} from '../Constants/studentContext'
import { getLastActiveLearningUUIDKey } from './contextKeys'

export default sectionTypeToLastContextKey

function sectionTypeToLastContextKey (sectionType, chapterType) {
  const lastActiveLearningUUIDKey = getLastActiveLearningUUIDKey(chapterType)
  const MAPPING = {
    [GUESSWORK]: LAST_GUESSWORK_UUID,
    [EXAM]: LAST_EXAM_UUID,
    [QUIZ]: LAST_QUIZ_UUID,
    [ACTIVE_LEARNING]: lastActiveLearningUUIDKey
  }

  return MAPPING[sectionType]
}
