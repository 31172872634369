import axios from 'axios'
import axiosRetry from 'axios-retry'
import { canUseStudentEmailOverride } from './utilities/userUtils'
import createClient from './Components/Auth0Provider/client'
import config, { PRODUCTION_API_HOST } from './config'
import { VOUCHER_TYPE } from './Constants/voucher'
import {
  notificationsData
} from './Components/StudentHeader/notificationMockData'
import $ from 'jquery'
import { getUTMParametersFromUrl, getIdFromUrl } from './utilities'
import { addExamNumbersToCohortSyllabus, addExamNumbersToCourseData } from './utilities/courseUtils'
const qs = require('qs')
let version
let useStudentEmailOverride = false
let studentEmailQueryParam = ''

const {
  getApiHost,
  isPreviewCourse,
  studentEmailOverride
} = config
const apiHost = getApiHost()
const defaultHeaders = {
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  'X-React-App-Git-Commit': process?.env?.REACT_APP_GIT_COMMIT,
  Pragma: 'no-cache', // required for iOS Safari to behave correctly
  Expires: 0
}
axios.defaults.headers = defaultHeaders

let apiResponseCache = {}

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => retryCount * 1000, // In milliseconds
  shouldResetTimeout: true,
  // Retry every failing api requests. We will see the status codes of failed api requests
  retryCondition: error => {
    const isCodeGradeRequest =
        error?.request?.responseURL?.includes('/codegrade/')
    const is404Response = error?.response?.status === 404

    return !isCodeGradeRequest && !is404Response
  },
  onRetry: (retryCount, error, requestConfig) => {
    const { status } = error?.response || {}
    const { url } = requestConfig || {}

    console.error(
      'ON Error retry: ', JSON.stringify({ retryCount, statusCode: status, url })
    )
  }
})

export default {
  updateAuth0User,
  clearApiResponseCache,
  loginToCodegrade,
  submitUserCode,
  uploadCodingAssignment,
  getAutotestDetails,
  getSubmissionResult,
  getStudentTokens,
  incrementStudyGuideClicks,
  sendMetrics,
  logError,
  getCourseraActivityLink,
  courseraProgramMembership,
  getYellowdigUrl,
  getSkooliUrl,
  setStudentIdentity,
  getStudentProgress,
  getProgressKeyValue,
  getExamRetakes,
  setStudentSectionProgress,
  enrollToCollegeSuccessCourse,
  generateKalturaSession,
  setStudentExamProgress,
  isStudentRegisteredForCourse,
  getStudentCourses,
  getStudentAttempts,
  setCourseFeatures,
  getCourseFeatures,
  getCohortInfo,
  getCohortDeadlines,
  getProctorioUrls,
  trackProctorioExams,
  getSyllabusDetails,
  getStudentId,
  getToken,
  addLogEvent,
  changePassword,
  getStepByStepAnswer,
  fetchSectionData,
  getAllSectionsData,
  getChecklistSectionsData,
  setLectureProgressOnNavigate,
  getCourseData,
  getContentCreatorPermission,
  getSectionCountData,
  setStudentData,
  updateStudentData,
  generateUploadVideoSignedUrl,
  uploadVideoToGCS,
  saveUploadVideoMetadata,
  getVideoFromGCS,
  saveWritingAssignmentFile,
  saveWritingAssignmentText,
  getWritingAssignment,
  getAssignmentFeedbackFile,
  submitTrackedEvent,
  getProjectedGrade,
  getSectionModificationsForStudent,
  getAssignmentModificationsForStudent,
  submitCollegeSuccessPWAEvents,
  checkEventEligibility,
  getAllNotifications,
  getStandardCohort,
  requestGuardianPermission,
  setFirstLoginDate,
  getAmazonVouchers,
  getStudentData,
  setMyScriptData,
  getMyScriptData,
  createKamiViewSession,
  createUserConvertFlow,
  getExtensions,
  getUPittOverideStatus,
  fetchAsset,
  getProspectsData,
  getAllGGUSemesters,
  updateProspectData,
  getStudentCurrentGrade,
  getStudyBlocks,
  checkServerStatus,
  saveStudyBlocksAttendance,
  verifyExamKey,
  getExamOverrideKeys,
  alertExamViolation,
  verifyStudentAdminAccess
}

function clearApiResponseCache () {
  apiResponseCache = {}
}

async function getToken (isPreview = isPreviewCourse) {
  if (isPreview) return ''

  const auth0token = localStorage.getItem('auth0token')
  if (auth0token) return auth0token

  // Use locally stored token, if it exists and if there is proctorio=true query param.
  const { storedToken } = config
  if (storedToken) return storedToken

  const client = await createClient()
  const token = await client.getIdToken()
  return token
}

async function sendMetrics (data, isPreview = isPreviewCourse) {
  if (isPreview) return {}

  const token = await getToken()
  const url = `${apiHost}/monitor/site-performance`

  try {
    console.info(`API start sendMetrics: ${JSON.stringify(data)}`)
  } catch (error) {
    console.error('Error in JSON stringify: ', error.message)
  }

  try {
    const res = await axios.put(url, data, {
      headers: { Authorization: `Bearer ${token}` }
    })

    console.info(`API done sendMetrics`)

    return res
  } catch (error) {
    console.error('Error when sending sendMetrics: ', error.message)
    return null
  }
}

async function logError (data) {
  const token = await getToken()
  const url = `${apiHost}/monitor/log-frontend-error`

  try {
    const res = await axios.post(url, data, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return res
  } catch (error) {
    console.error('Error when sending error logs: ', error.message)
    return null
  }
}

async function getStudentId () {
  const token = await getToken()
  const url = `${apiHost}/student/student-id`

  const { data: { studentId } } = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return studentId
}

async function changePassword (email) {
  const { REACT_APP_CLIENT_ID } = process.env

  try {
    const url = `${config.auth0BaseURL}/dbconnections/change_password`
    const response = await axios.post(url, {
      client_id: REACT_APP_CLIENT_ID,
      email,
      connection: 'Username-Password-Authentication'
    })
    alert(response.data)
  } catch (e) {
    alert('Something went wrong! Try again later.')
  }
}

async function setLectureProgressOnNavigate (key, sectionData) {
  if (useStudentEmailOverride) {
    return {
      success: true,
      data: sectionData
    }
  }

  const token = await getToken()
  const url = new URL(`${apiHost}/student/progress/${key}/${config.courseId}`)

  console.info(`API start setStudentSectionProgress with intent ${key}`)

  const res = await fetch(url, {
    method: 'PUT',
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8'
    },
    body: JSON.stringify(sectionData),
    keepalive: true
  })
  console.info(`API done setStudentSectionProgress with intent ${key}`)

  return res
}

async function getStudentProgress (isPreview = isPreviewCourse, courseId = config.courseId) {
  if (isPreview) return {}

  const token = await getToken()
  const suffix = useStudentEmailOverride ? `/${studentEmailOverride}` : ''
  const url = `${apiHost}/student/progress/${courseId}${suffix}`

  console.info('API start getStudentProgress')

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    version = (data && data.version) || 0

    console.info(`API done getStudentProgress version ${version}`)

    return data
  } catch (error) {
    console.error('Error in getStudentProgress: ', error.message)
    return null
  }
}

async function getProgressKeyValue (key) {
  const token = await getToken()
  const url = `${apiHost}/student/exam-locked-status/${config.courseId}`
  console.info('API start getProgressKeyValue')

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.info('API done getProgressKeyValue')

    return data?.Data
  } catch (error) {
    console.error('Error in getProgressKeyValue: ', error.message)
    return null
  }
}

async function setStudentSectionProgress (key, sectionData) {
  if (useStudentEmailOverride) {
    return {
      success: true,
      data: sectionData
    }
  }

  const token = await getToken()
  const url = `${apiHost}/student/progress/${key}/${config.courseId}`

  console.info(`API start setStudentSectionProgress with intent ${key}`)

  const res = await axios.put(url, sectionData, {
    headers: { Authorization: `Bearer ${token}` }
  })

  console.info(`API done setStudentSectionProgress with intent ${key}`)

  return res
}

async function generateKalturaSession () {
  const url = `${apiHost}/generate-kaltura-session`
  const token = await getToken()
  const { data: { sessionString } } = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return sessionString
}

async function setStudentExamProgress (key, sectionData) {
  if (useStudentEmailOverride) {
    return {
      success: true,
      data: sectionData
    }
  }

  const token = await getToken()
  const url = `${apiHost}/student/progress/multiple/${key}/${config.courseId}`

  console.info(`API start setStudentExamProgress with intent ${key}`)

  const res = await axios.put(url, sectionData, {
    headers: { Authorization: `Bearer ${token}` }
  })

  console.info(`API done setStudentExamProgress with intent ${key}`)

  return res
}

async function isStudentRegisteredForCourse (isAdmin) {
  const token = await getToken()
  // All courses return true for outlier admin,
  // we need to make sure admin always see the latest course.
  // Latest course would be the one which isnt present in the config.

  const courseIdForAdmin = isAdmin ? config.courseId : null

  const additionalCourseIds = courseIdForAdmin
    ? [courseIdForAdmin]
    : config.course.additionalCourseIds?.length
      ? config.course.additionalCourseIds
      : [config.courseId]

  try {
    const response = await Promise.all(additionalCourseIds.map(async (additionalCourseId) =>
      axios.get(`${apiHost}/course/${additionalCourseId}`, {
        headers: { Authorization: `Bearer ${token}` },
        validateStatus: (status) => status
      })))

    const activeCourseResponse = response.find(({ status }) => status === 200)
    if (activeCourseResponse) {
      const courseId = getIdFromUrl(activeCourseResponse?.config?.url)
      config.courseId = courseId
      return courseId
    }
    return false
  } catch (e) {
    console.error('Student Registration Status Fetching Issue - ContextProvider', e.message)
    return false
  }
}

async function getStudentCourses (
  addAccessParam,
  isPreview = isPreviewCourse,
  fetchAllCourses = false) {
  if (isPreview) return { courses: [] }

  const token = await getToken()
  useStudentEmailOverride = await canUseStudentEmailOverride()

  const queryParams = new URLSearchParams({
    ...((addAccessParam || config.getUserAssignedCourses()) && { access: true }),
    ...(useStudentEmailOverride && { studentEmail: encodeURIComponent(studentEmailOverride) }),
    ...(!fetchAllCourses && { courseId: config.courseId })
  })

  const url = `${apiHost}/student/courses?${queryParams}`

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    if (data?.error) throw new Error(data.error)
    return data
  } catch (error) {
    console.error('Error when fetching courses: ', error.message)
    return null
  }
}

async function getStudentAttempts () {
  const token = await getToken()

  try {
    const { data } = await axios.get(`${apiHost}/student/attempts`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    if (data?.error) throw new Error(data.error)
    return data
  } catch (error) {
    console.error('Error when fetching student attempts: ', error.message)
    return null
  }
}

async function enrollToCollegeSuccessCourse () {
  const token = await getToken()
  const url = `${apiHost}/student/college-success/enroll`
  try {
    const { data } = await axios.put(url, {}, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    console.error('Error in enrollCollegeSuccess', e.message)
    return null
  }
}

async function getCohortInfo (cohortId) {
  const token = await getToken()
  const url = `${apiHost}/cohort/info/${cohortId}`

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    if (data && data.error) throw new Error(data.error)
    return data
  } catch (error) {
    console.error('API error in getCohortInfo', error.message)
    return null
  }
}

async function getCohortDeadlines (cohortId) {
  const token = await getToken()
  const url = `${apiHost}/cohort/deadlines/${cohortId}`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    if (data && data.error) throw new Error(data.error)
    return data
  } catch (error) {
    console.error(`API error in getCohortDeadlines: ${error}`)
    return { error }
  }
}

async function getExtensions () {
  const token = await getToken()
  const url = `${apiHost}/student/extension`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    if (data && data.error) throw new Error(data.error)
    return data
  } catch (error) {
    console.error(`API error in getExtensions: ${error}`)
    return { error }
  }
}

async function getSyllabusDetails (cohortID) {
  const token = await getToken()
  const url = `${apiHost}/cohort-syllabus/${cohortID}`

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    if (data?.error) throw new Error(data.error)

    return addExamNumbersToCohortSyllabus(data)
  } catch (error) {
    console.error('Error in getSyllabusDetails: ', error.message)
    return null
  }
}

async function getStepByStepAnswer (query) {
  const token = await getToken()
  const url = `${apiHost}/step-by-step-answer/type/image/${query}`

  const { data } = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return data
}

async function fetchSectionData ({ activeCourseUUID, uuid }) {
  const token = await getToken()
  const url = getUrl(activeCourseUUID, uuid)

  if (apiResponseCache[url]) return apiResponseCache[url]

  const { data } = await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

  if (data && data.error) throw new Error(data.error)
  apiResponseCache[url] = data
  return data
}

async function getAllSectionsData (activeCourseUUID) {
  const token = await getToken()
  const url = `${apiHost}/course-data/sections/${activeCourseUUID}`

  try {
    const { data } = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` }
      })

    if (data && data.error) throw new Error(data.error)
    return data
  } catch (error) {
    console.error('Error in getAllSectionsData', error.message)
    return null
  }
}

async function getChecklistSectionsData (activeCourseUUID) {
  const token = await getToken()
  const url = `${apiHost}/checklist/${activeCourseUUID}`

  try {
    const { data } = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` }
      })

    if (data && data.error) throw new Error(data.error)
    return data
  } catch (error) {
    console.error('Error in getChecklistSectionsData', error.message)
    return null
  }
}

async function getCourseData (courseUuid) {
  const token = await getToken()
  const url = getUrl(courseUuid)

  try {
    const { data } = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` }
      })

    if (data?.error) throw new Error(data.error)
    return addExamNumbersToCourseData(data)
  } catch (error) {
    console.error('Error in getCourseData', error.message)
    return null
  }
}
async function getSectionCountData (courseUuid) {
  const token = await getToken()

  const url = `${config.datoApiHost}/dato/meta/${courseUuid}`

  try {
    const { data } = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` }
      })

    if (data?.error) throw new Error(data.error)
    return data
  } catch (error) {
    console.error('Error in getSectionCountData: ', error.message)
    return null
  }
}

async function getExamRetakes () {
  const token = await getToken()

  const url = `${apiHost}/student/exam-retakes`
  const { data } = await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

  if (data && data.error) throw new Error(data.error)
  return data
}

async function setStudentData (key, sectionData) {
  if (useStudentEmailOverride) {
    return {
      status: 200,
      data: 'true' }
  }

  const token = await getToken()
  const url = `${apiHost}/student/data/${key}`

  const res = await axios.put(url, sectionData, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return res
}

async function updateStudentData (data) {
  const token = await getToken()
  const url = `${apiHost}/student/update`

  const res = await axios.put(url, data, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return res
}

async function getStudentData () {
  const token = await getToken()
  const url = `${apiHost}/student/student-data${studentEmailQueryParam}`

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data || {}
  } catch (e) {
    console.error('Error in getStudentData', e.message)
    return {}
  }
}

async function setCourseFeatures (key, data) {
  if (useStudentEmailOverride) {
    return {
      status: 200,
      data: 'true'
    }
  }

  const token = await getToken()
  const url = `${apiHost}/student/course-feature/${config.courseId}/${key}`
  try {
    const res = await axios.put(url, {
      [key]: data
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return { data: res.data, error: null }
  } catch (e) {
    console.error('Error in getCourseFeatures', e.message)
    return { error: e, data: null }
  }
}

async function getCourseFeatures () {
  const token = await getToken()
  const url = `${apiHost}/student/course-features/${config.courseId}/${studentEmailQueryParam}`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data || {}
  } catch (e) {
    console.error('Error in getCourseFeatures', e.message)
    return {}
  }
}

async function getWritingAssignment ({
  cohortID,
  assignmentUUID,
  fileName,
  gcsVideo,
  multiPartAssignment
} = {}) {
  const token = await getToken()
  studentEmailQueryParam = useStudentEmailOverride
    ? `?studentEmail=${encodeURIComponent(studentEmailOverride)}` : ''
  const queryString = new URLSearchParams({
    ...(multiPartAssignment && { multiPartAssignment: true }),
    ...(gcsVideo && { gcsVideo: true }),
    ...(useStudentEmailOverride && {
      studentEmail: encodeURIComponent(studentEmailOverride)
    })
  }).toString()

  const url = `${apiHost}/student/writing-assignment/${config.courseId}/${cohortID}/${assignmentUUID}${fileName ? `/${fileName}` : ''}?${queryString}`

  console.info(`API start getWritingAssignment`)

  try {
    const response = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'arraybuffer'
      })

    console.info(`API done getWritingAssignment`)

    return response
  } catch (error) {
    console.error(`API error in getWritingAssignment: ${error.message}`)
    return { error: error.message }
  }
}

async function getCourseraActivityLink (title, assignmentId) {
  const token = await getToken()
  const [, courseraCourseTitle] = title?.split(' | ') || []
  const url = `${apiHost}/coursera/get-coursera-activity-url/${config.courseId}/${courseraCourseTitle}/${assignmentId}`

  console.info(`API start getCourseraActivityLink`, title, url)

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    console.info(`API done getCourseraActivityLink`, data)

    return data
  } catch (error) {
    console.error(`API error in getCourseraActivityLink ${title}: ${error.message}`)
    return { message: error.message }
  }
}

async function courseraProgramMembership (courseId) {
  const token = await getToken()
  const url = `${apiHost}/coursera/program/invitations/${courseId}`

  console.info(`API start courseraProgramMembership`, courseId)

  try {
    const { data } = await axios.post(url, {}, {
      headers: { Authorization: `Bearer ${token}` }
    })

    console.info(`API done courseraProgramMembership`, data)
    return { success: true, data }
  } catch (error) {
    console.error(`API error in courseraProgramMembership: ${error.message}`)
    return { success: false, message: error.message }
  }
}

async function getYellowdigUrl (courseId, cohortId) {
  const token = await getToken()
  const queryParam = cohortId ? `cohortId=${cohortId}` : ''
  const url = `${apiHost}/yellowdig/get-url/${courseId}?${queryParam}`

  console.info(`API start getYellowdigUrl`)

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    console.info(`API done getYellowdigUrl`)

    return data
  } catch (error) {
    console.error(`API error in getYellowdigUrl ${courseId}: ${error.message}`)
    return { message: error.message }
  }
}

async function getSkooliUrl (cohortId) {
  const token = await getToken()
  const url = `${apiHost}/skooli/get-url/${cohortId}`

  console.info(`API start getSkooliUrl`)

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    console.info(`API done getSkooliUrl`)

    return { data }
  } catch (error) {
    console.error(`API error in getSkooliUrl ${cohortId}: ${error.message}`)
    return { error }
  }
}

async function setStudentIdentity (data) {
  const token = await getToken()
  const url = `${apiHost}/student/analytics/identify-student`
  console.info(`API start setStudentIdentity`)
  try {
    const res = await axios.post(url, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.info('API done setStudentIdentity')
    return res
  } catch (error) {
    console.error('API error in setStudentIdentity')
    return { message: error.message }
  }
}

async function submitCollegeSuccessPWAEvents (data) {
  const token = await getToken()
  const url = `${apiHost}/student/college-success-pwa-onboarding-events`

  console.info(`API start submitCollegeSuccessPWAEvents`)
  try {
    const res = await axios.post(url, data, {
      headers: { Authorization: `Bearer ${token}` }
    })

    console.info('API done submitCollegeSuccessPWAEvents')
    return res
  } catch (error) {
    console.error('API error in submitCollegeSuccessPWAEvents')
    return { message: error.message }
  }
}

async function submitTrackedEvent ({ data: rawData, courseId, cohortID }) {
  if (isPreviewCourse) return {}
  const token = await getToken()
  const url = `${apiHost}/student/analytics/track-event/${courseId}/${cohortID}`

  const utmProperties = getUTMParametersFromUrl()

  const properties = { ...rawData.properties, ...utmProperties }
  const data = { ...rawData, properties }

  console.info(`API start submitTrackedEvent`)
  try {
    const res = await axios.post(url, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.info('API done submitTrackedEvent')
    return res
  } catch (error) {
    console.error('API error in submitTrackedEvent')
    return { message: error.message }
  }
}

async function checkEventEligibility ({ data, courseId, cohortID }) {
  const token = await getToken()
  const url = `${apiHost}/student/analytics/check-event-eligibility/${courseId}/${cohortID}`
  console.info(`API start checkEventEligibility`)
  try {
    const res = await axios.post(url, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.info('API done checkEventEligibility')
    return res.data
  } catch (error) {
    console.error('API error in checkEventEligibility')
    return { message: error.message }
  }
}

async function generateUploadVideoSignedUrl (data) {
  const token = await getToken()
  const url = `${apiHost}/student/generate-upload-signed-url`

  console.info(`API start generateUploadVideoSignedUrl`)
  try {
    const res = await axios.post(url, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.info('API done generateUploadVideoSignedUrl')
    return res
  } catch (error) {
    console.error('API error in generateUploadVideoSignedUrl')
    return { message: error.message }
  }
}

async function uploadVideoToGCS (signedUrl, file) {
  console.info(`API start uploadVideoToGCS`)
  const formData = new Blob([file], { type: 'video/mp4' })
  const config = {
    headers: {
      'Content-Type': 'video/mp4'
    }
  }
  try {
    const res = await axios.put(
      signedUrl,
      formData,
      config
    )
    console.info('API done uploadVideoToGCS')
    return res
  } catch (error) {
    console.error('API error in uploadVideoToGCS')
    return { message: error.message }
  }
}

async function saveUploadVideoMetadata (data) {
  const { courseId, cohortId, assignmentId, filename } = data
  const token = await getToken()
  const url = `${apiHost}/student/assignment-file-metadata/${courseId}/${cohortId}/${assignmentId}`

  console.info(`API start saveUploadVideoMetadata`)
  try {
    const res = await axios.post(url, {
      originalFileName: filename
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.info('API done saveUploadVideoMetadata')
    return res
  } catch (error) {
    console.error('API error in saveUploadVideoMetadata')
    return { message: error.message }
  }
}

async function getVideoFromGCS (signedUrl) {
  console.info(`API start getVideoFromGCS`)
  try {
    const res = await axios.get(signedUrl, {
      responseType: 'blob',
      headers: { 'Content-Type': 'video/mp4' }
    })
    console.info('API done getVideoFromGCS')
    return res
  } catch (error) {
    console.error('API error in getVideoFromGCS')
    return { message: error.message }
  }
}

async function saveWritingAssignmentFile ({
  assignmentUUID,
  cohortID,
  formData,
  fileName,
  multiPartAssignment,
  configuration
}) {
  if (useStudentEmailOverride) {
    return {
      status: 200,
      statusText: '',
      data: { success: 'file uploaded' },
      headers: { 'content-type': 'application/pdf' } }
  }

  const token = await getToken()
  const queryParam = multiPartAssignment ? '?multiPartAssignment=true' : ''
  const url = `${apiHost}/student/writing-assignment/${config.courseId}/${cohortID}/${assignmentUUID}${fileName ? `/${fileName}` : ''}${queryParam}`
  console.info(`API start saveWritingAssignmentFile ${assignmentUUID}`)
  try {
    const res = await axios.put(url, formData, {
      headers: { Authorization: `Bearer ${token}`,
        'Content-type': 'multipart/form-data' },
      ...(configuration && configuration)
    })
    const { data: { success, message } = {} } = res || {}
    if (!success) {
      console.error(`API error in saveWritingAssignmentFile ${assignmentUUID}: ${message}`)
      return null
    }

    console.info(`API done saveWritingAssignmentFile ${assignmentUUID}`)
    return success
  } catch (error) {
    console.error(`API error in saveWritingAssignmentFile ${assignmentUUID}: ${error.message}`)
    return null
  }
}

async function saveWritingAssignmentText (assignmentUUID, cohortID, text) {
  if (useStudentEmailOverride) {
    return {
      status: 200,
      statusText: '',
      data: { success: 'file uploaded' },
      headers: { 'content-type': 'application/json; charset=utf-8' } }
  }

  const token = await getToken()
  const url = `${apiHost}/student/writing-assignment/${config.courseId}/${cohortID}/${assignmentUUID}`
  console.info(`API start saveWritingAssignmentText ${assignmentUUID}`)
  try {
    const res = await axios.put(url, text, {
      headers: { Authorization: `Bearer ${token}` }
    })
    const { data: { success, message } = {} } = res || {}
    if (!success) {
      console.error(`API error in saveWritingAssignmentText ${assignmentUUID}: ${message}`)
      return null
    }

    console.info(`API done saveWritingAssignmentText ${assignmentUUID}`)
    return success
  } catch (error) {
    console.error(`API error in saveWritingAssignmentText ${assignmentUUID}: ${error.message}`)
    return null
  }
}

async function getProctorioUrls (examTake, examTag) {
  const token = await getToken()
  const url = `${PRODUCTION_API_HOST}/student/proctorio/get-urls`

  const parameters = {
    launchUrl: examTake,
    examStart: examTake,
    examTake,
    examTag,
    examEnd: config.courseBaseUrl,
    examSettings: config.proctorioExamSettings
  }

  console.info(`API start getProctorioUrls: ${JSON.stringify(parameters)}`)
  try {
    const { data } = await axios.post(url, parameters, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.info(`API done getProctorioUrls: ${JSON.stringify(data)}`)

    return data
  } catch (err) {
    console.error(`API error in getProctorioUrls: ${err.message}`)
    return null
  }
}

async function trackProctorioExams (examName) {
  const token = await getToken()
  const url = `${apiHost}/student/proctorio/track`

  const body = {
    examName
  }

  console.info(`API start trackProctorioExams`)
  try {
    const { data } = await axios.put(url, body, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.info(`API done trackProctorioExams`)

    return data
  } catch (err) {
    console.error(`API error in trackProctorioExams: ${err.message}`)
    return null
  }
}

async function getStudentGradeReport (courseID, cohortID, studentEmail) {
  const token = await getToken()
  const url = `${apiHost}/student/grades/${courseID}/${cohortID}/${encodeURIComponent(studentEmail)}`

  const { data } = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return data
}

async function getSectionModificationsForStudent () {
  const token = await getToken()
  const url = `${apiHost}/student/section-grade-modification-logs/${config.courseId}`

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (error) {
    console.error(`API error in getSectionModificationsForStudent: ${error}`)
    return { error }
  }
}

async function getAssignmentModificationsForStudent () {
  const token = await getToken()
  const url = `${apiHost}/student/assignment-grade-modification-logs/${config.courseId}`

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (error) {
    console.error(`API error in getAssignmentModificationsForStudent: ${error}`)
    return { error }
  }
}

async function getAssignmentFeedbackFile (courseID, cohortID, assignmentId) {
  const token = await getToken()
  const url = `${apiHost}/student/assignment-feedback-file/${courseID}/${cohortID}/${assignmentId}`

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob'
    })

    return data
  } catch (e) {
    console.error(`API error in getAssignmentFeedbackFile: ${e.message}`)
    return { message: e.message }
  }
}

async function getContentCreatorPermission () {
  const token = await getToken()
  const url = `${apiHost}/student/get-content-creator-permission`

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    if (data?.error) throw new Error(data.error)
    return data
  } catch (error) {
    console.error('Error in getContentCreatorPermission: ', error.message)
    return null
  }
}

async function getStandardCohort (cohortId) {
  const token = await getToken()
  const url = `${apiHost}/find-standard-cohort/${cohortId}`

  console.info(`API start getStandardCohort`)

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    console.info(`API done getStandardCohort`)

    return data
  } catch (e) {
    console.error(`API error in getStandardCohort: ${e.message}`)
    return null
  }
}

async function requestGuardianPermission (guardianEmail, isProfCertCourse) {
  const token = await getToken()
  const url = `${apiHost}/student/guardian-permission/${guardianEmail}`
  const requestBody = {
    courseURL: window.location.origin,
    isProfCertCourse
  }

  console.info(`API start requestGuardianPermission`)

  try {
    const { data } = await axios.post(url, requestBody,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    console.info(`API done requestGuardianPermission`)
    return data
  } catch (e) {
    console.error(`API error in requestGuardianPermission: ${e.message}`)
    return { message: e.message }
  }
}

async function getTuitionVouchers (relationship) {
  const token = await getToken()
  const url = `${apiHost}/student/get-tuition-vouchers/${relationship}`

  console.info('API start getTuitionVouchers: ', relationship)
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    console.info('API done getTuitionVouchers: ', relationship)

    return data
  } catch (err) {
    console.error(`API error in getTuitionVouchers ${relationship}: ${err.message}`)
    return null
  }
}

async function getAmazonVouchers () {
  const vouchers = await getTuitionVouchers(VOUCHER_TYPE.AMAZON)

  return vouchers
}

async function setFirstLoginDate (attemptId) {
  const token = await getToken()
  const url = `${apiHost}/student/set-first-login-date`

  const body = { attemptId }

  console.info('API start setFirstLoginDate')
  try {
    const { data } = await axios.post(url, body, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.info('API done setFirstLoginDate')

    return data
  } catch (err) {
    console.error(`API error in setFirstLoginDate: ${err.message}`)
    return null
  }
}

async function getAllNotifications (cohortID) {
  // const token = await getToken()
  // const url = `${Url}/student/notifications/${cohortID}`

  // let { data } = await axios.get(url, {
  //   headers: { Authorization: `Bearer ${token}` }
  // })
  const { data } = notificationsData
  if (data && data.error) throw new Error(data.error)
  return data
}

async function getProjectedGrade (courseID, cohortID, studentEmail) {
  try {
    const { projectedGrade } = await getStudentGradeReport(courseID, cohortID, studentEmail)
    return projectedGrade
  } catch (error) {
    console.error(`API error in getProjectedGrade: ${error.message}`)
    return null
  }
}

async function setMyScriptData (id, data) {
  if (!config.isMobileFlag) return

  const token = await getToken()
  const url = `${apiHost}/my-script/${id}`

  const res = await axios.post(url, data, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return res
}

async function getMyScriptData () {
  const token = await getToken()
  const url = `${apiHost}/my-script`

  const res = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return res
}

function getUrl (courseUUID, sectionUUID = courseUUID) {
  return `${config.datoApiHost}/dato/files/${courseUUID}/${sectionUUID}`
}

// ConvertFlow accepts jsonp requests. Making a call with axios was giving CORS issue.
// Axios doesnt support jsonp requests. Used the same method as used by ConvertFlow.
async function createUserConvertFlow (contact) {
  const query = qs.stringify({ contact })
  return $.ajax(({
    type: 'GET',
    url: `${config.getConvertFlowUrl(contact.website_id)}`,
    data: query,
    contentType: 'application/json; charset=utf-8',
    dataType: 'JSONP',
    success: function (data, status, xhr) { return data }
  }))
}

async function createKamiViewSession ({
  courseID, cohortID, assignmentUUID, documentIdentifier, nickname
}) {
  const token = await getToken()
  const url = `${apiHost}/student/kami/create/view-session/${courseID}/${cohortID}/${assignmentUUID}`

  const body = {
    document_identifier: documentIdentifier,
    user: {
      name: nickname,
      user_id: nickname
    },
    viewer_options: {
      theme: 'dark',
      zoom: 70,
      show_save: true,
      show_print: true,
      show_help: false,
      show_menu: false
    },
    editable: false
  }

  try {
    const { data } = await axios.post(url, body, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return { data }
  } catch (error) {
    console.error('Error when creating Kami view session', error.message)
    return { message: error.message }
  }
}

async function incrementStudyGuideClicks (chapterId) {
  try {
    const token = await getToken()
    const courseId = config.courseId
    const url = `${apiHost}/student/study-guide-view/${courseId}/${chapterId}`

    const res = await axios.put(url, {}, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return res
  } catch (error) {
    return null
  }
}

async function addLogEvent (event, data) {
  const token = await getToken()
  const url = `${apiHost}/student/analytics/add-log-event`
  console.info(`API start addLogEvent for ${event}`)
  try {
    const res = await axios.put(url, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.info(`API done addLogEvent for ${event}`)
    return res
  } catch (error) {
    console.error('API error in addLogEvent')
    return null
  }
}

async function getUPittOverideStatus () {
  const token = await getToken()
  const url = `${apiHost}/student/upittoverride`

  console.info(`API start getUPittOverideStatus`)

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    console.info(`API done getUPittOverideStatus`)

    return data
  } catch (e) {
    console.error(`API error in getUPittOverideStatus: ${e.message}`)
    return 'Error'
  }
}

async function getStudentTokens (isPreview = isPreviewCourse) {
  if (isPreview) return []

  const token = await getToken()
  useStudentEmailOverride = await canUseStudentEmailOverride()
  studentEmailQueryParam = useStudentEmailOverride
    ? `?studentEmail=${encodeURIComponent(studentEmailOverride)}`
    : ''
  const url = `${apiHost}/student/tokens${studentEmailQueryParam}`

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    if (data?.error) throw new Error(data.error)
    return data
  } catch (error) {
    console.error('Error when fetching tokens: ', error.message)
    return null
  }
}

async function loginToCodegrade () {
  const token = await getToken()
  const url = `${apiHost}/codegrade/login${isPreviewCourse ? '-preview' : ''}`

  console.info(`API start loginToCodegrade`)

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    console.info(`API done loginToCodegrade`)

    return data
  } catch (e) {
    console.error(`API error in loginToCodegrade: ${e.message}`)
    return null
  }
}

async function submitUserCode (assignmentId, formData, accessToken) {
  console.info(`API start submitUserCode`, formData)
  const token = await getToken()
  try {
    const { data } = await axios.post(
      `${apiHost}/codegrade/submit-assignment/${assignmentId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'multipart/form-data',
          'x-codegrade-token': accessToken
        }
      })
    console.info(`API done submitUserCode`)
    return data
  } catch (error) {
    console.error('Error when submitting user code: ', error.message)
    return error
  }
}

async function uploadCodingAssignment ({ courseId, cohortId, assignmentUUID, formData }) {
  console.info(`API start uploadCodingAssignment`, formData)
  const token = await getToken()
  try {
    const { data } = await axios.put(
      `${apiHost}/student/coding-assignment/${courseId}/${cohortId}/${assignmentUUID}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'multipart/form-data'
        }
      })
    console.info(`API done uploadCodingAssignment`)
    return data
  } catch (error) {
    console.error('Error when uploading coding assignment: ', error.message)
    return error
  }
}

async function getAutotestDetails (assignmentId, accessToken) {
  console.info(`API start getAutotestDetails`, assignmentId)
  const token = await getToken()
  try {
    const { data } = await axios.get(
      `${apiHost}/codegrade/auto-test/${assignmentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-codegrade-token': accessToken
        }
      }
    )
    console.info(`API done getAutotestDetails`)
    return data
  } catch (error) {
    console.error(`API error in getAutotestDetails: ${error.message}`)
    return error
  }
}

async function getSubmissionResult (params, accessToken) {
  console.info(`API start getSubmissionResult`, params)
  const { autotestId, runId, submissionId } = params || {}
  const token = await getToken()
  try {
    const { data } = await axios.get(
      `${apiHost}/codegrade/auto-test-result/${autotestId}/${runId}/${submissionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-codegrade-token': accessToken
        }
      }
    )
    console.info(`API done getSubmissionResult`)
    return data
  } catch (error) {
    console.error(`API error in getSubmissionResult: ${error.message}`)
    return error
  }
}

async function fetchAsset (asset) {
  const token = await getToken()
  const url = `${apiHost}/asset-file/${asset}`

  console.info(`API start loginToCodegrade`)

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (e) {
    console.error(`API error in fetchAsset: ${e.message}`)
    return null
  }
}

async function updateAuth0User (body) {
  if (useStudentEmailOverride) return body

  const token = await getToken()
  const url = `${apiHost}/update/auth0/user`
  console.log('API start updateAuth0User', body)

  try {
    const result = await axios.put(url, body, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.log('API done updateAuth0User')
    return result?.data
  } catch (e) {
    console.error('Error in updateAuth0User', e.message)
    return null
  }
}

async function getProspectsData () {
  const token = await getToken()
  const url = `${apiHost}/prospects/prospect-data`
  console.log('API Start getProspectsData')

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.log('API done getProspectsData')
    return data
  } catch (e) {
    console.error('Error in getProspectsData', e.message)
    return null
  }
}

async function getAllGGUSemesters () {
  const token = await getToken()
  const url = `${apiHost}/ggu/semesters`
  console.log('API Start getAllGGUSemesters')

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.log('API done getAllGGUSemesters')
    return data
  } catch (e) {
    console.error('Error in getAllGGUSemesters', e.message)
    return null
  }
}

async function updateProspectData (prospectId, prospectData) {
  const token = await getToken()
  const url = `${apiHost}/prospects/prospect-data/${prospectId}`
  console.log('API Start updateProspectData')

  try {
    const { data } = await axios.put(url, prospectData, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    console.error('Error in updateProspectData', e.message)
    return {}
  }
}

async function getStudentCurrentGrade (courseId, cohortName, studentEmail) {
  const token = await getToken()
  const url = `${apiHost}/student/current-grade/${courseId}/${encodeURIComponent(cohortName)}/${encodeURIComponent(studentEmail)}`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return {}
  }
}

async function getStudyBlocks () {
  const token = await getToken()
  const url = `${apiHost}/student/study-block`

  try {
    const { data: { data: { studyBlocks, metadata } = {} } } = await axios.get(
      url, { headers: { Authorization: `Bearer ${token}` } }
    )
    return { success: true, studyBlocks, metadata }
  } catch (e) {
    console.error('Error while getting the study blocks', e.message)
    return { success: false, message: e.message }
  }
}

async function saveStudyBlocksAttendance (studyBlockId) {
  const token = await getToken()
  const url = `${apiHost}/student/study-block/update-attendance/${studyBlockId}`

  try {
    const { data: { success, studyBlock } = {} } = await axios.put(url, {}, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return { success, studyBlock }
  } catch (e) {
    console.error('Error while updating the study block attendance', e.message)
    return { success: false, message: e.message }
  }
}

async function checkServerStatus () {
  const cacheBuster = (url) => `${url}?cb=${Date.now()}`
  const url = cacheBuster(`${apiHost}/dato/files/test-course/test-course`)
  try {
    const response = await axios.get(url)
    return response?.status === 200
  } catch (error) {
    return false
  }
}

async function verifyExamKey (cohortId, examKeyData) {
  const token = await getToken()
  const url = `${apiHost}/student/validate/exam-keys/${cohortId}`
  try {
    const { data } = await axios.put(url, examKeyData, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    console.error('Error while verifying exam key', e.message)
    return { success: false, message: e.message }
  }
}

async function getExamOverrideKeys (cohortId) {
  const token = await getToken()
  const url = `${apiHost}/student/override-keys/${cohortId}`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return {}
  }
}

async function alertExamViolation (payload) {
  const token = await getToken()
  const url = `${apiHost}/student/alert/exam-violation`

  const cacheKey = url + payload.type
  if (apiResponseCache[cacheKey]) return

  try {
    const { data } = await axios.post(url, payload, {
      headers: { Authorization: `Bearer ${token}` }
    })
    apiResponseCache[cacheKey] = data
    return data
  } catch (e) {
    console.error('Error while alerting exam violation', e.message)
    return { success: false, message: e.message }
  }
}

async function verifyStudentAdminAccess () {
  const token = await getToken()
  const url = `${apiHost}/student/check-admin-permission?permissionsV2=true`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return {}
  }
}
