import config from '../../../config'
import { shouldHideBreadCrumbSectionNumber } from '../../../utilities/chapterUtils'

export {
  getBreadCrumbText
}

function getBreadCrumbText (breadCrumb, props, chapters) {
  const childIndex = props?.childIndex ?? -1
  if (childIndex === -1) return breadCrumb

  const { screen } = props
  const { course: { use0BasedIndexing: isComputerScience } } = config
  const breadCrumbLength = screen?.breadcrumbArr?.length ?? 0
  const baseTitle = screen.breadcrumbArr[breadCrumbLength - 1][0]
  const hideSectionNumber = shouldHideBreadCrumbSectionNumber(screen.chapter)
  if (baseTitle !== breadCrumb || hideSectionNumber) return breadCrumb

  let chapterNumber
  let sectionNumber
  const chapterId = screen?.chapter?.uuid
  const sectionId = screen?.uuid
  chapters.forEach(chapter => {
    if (chapter.chapter_uuid === chapterId) {
      chapterNumber = isComputerScience ? chapter.chapterNumber - 1
        : chapter.chapterNumber
      chapter.sections.forEach((section, index) => {
        if (section.section_uuid === sectionId) {
          sectionNumber = isComputerScience ? index : index + 1
        }
      })
    }
  })
  return `section ${chapterNumber}.${sectionNumber}`
}
