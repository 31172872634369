import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const TextWrapper = styled.div`
  height: 70px;
  margin-top: 0px;
  margin-bottom: 1px;
  padding: 25px 24px;
  display: flex;
  justify-content: space-between;
  background: #121212;
  border-radius: 5px 5px 0px 0px;
`

export const QuestionText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-left: 0px;
  color: #FFFFFF;
`

export const PinWrapper = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`

export const PinText = styled.span`
    max-width: 93px;
    font-size: 16px;
    line-height: 19.2px;
    color: #98A4AE;
    text-align: right;
`

export const PinButtonWrapper = styled.span`
  margin-top: -2px;
`

export const PinButton = styled.img`
  margin-left: 12px;
  margin-bottom: 2px;
  width: 14px;
  height: 14px;
`

export const CardWrapper = styled.div`
  padding: 24px;
  margin-bottom: .5rem;
  position: relative;
  font-weight: 300;
  font-size: calc(12px + var(--font-increase-px));
  line-height: calc(16px + var(--font-increase-px));
  background: #121212;
  border-radius: 0px 0px 5px 5px;

  @media (min-width: 1660px) {
    padding: 30px;
  }
`

export const QuestionWrapper = styled.div`
  padding-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const RevealButton = styled.button`
  width: 100%;
  padding: 24px 12px;
  display: flex;
  align-items: center;
  text-align: left;

  color: #fff;
  background: #0A2A2D;
  border: none;
  border-radius: 5px;
  line-height: 1.5;
  letter-spacing: 0;
  white-space: normal;

  &:hover, &:focus {
    background: #445052;
  }

  ${({ textRevealed }) => textRevealed && `background: #445052;`}

  .paragraph {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0;
  }
`

export const RevealedTextWrapper = styled.div`
  margin-top: 24px;
  .paragraph {
    margin-bottom: 0px;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }
`

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 42px;

  .btn {
    margin: 6px 0px !important;
    margin-right: 8px !important;
    height: 40px;
    padding: 0px 8px;
    width: 64px;
    border: #5FC4B8 1px solid;
  }

  .btn-primary {
    background-color: #5FC4B8;
  }

  .btn-primary:disabled {
    background-color: #161618;
    border-color: #77b1af;
    color: #5FC4B8;
    opacity: 50%;
  }

  .btn-secondary:disabled {
    background-color: #161618;
    border-color: #77b1af;
    color: #5FC4B8;
  }

  ${mediaqueries.mobile`
    .btn {
      width: 100%;
      flex-grow: 1 !important;
      max-width: 157px !important;

      :first-child {
        margin-left: 0px !important;
        margin-right: 12px !important;
      }

      :nth-child(2) {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
    }
  `}
`

export const QuestionTextAL = styled.div`
  margin-bottom: 8px;
  .paragraph {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }
`

export const LessonTextAL = styled.div`
  padding-bottom: 0px !important;
  .paragraph {
    margin-bottom: 0px;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }
`

export const AdaptationStatementWrapper = styled.div`
  background: none;
  padding-top: 0;
  margin-top: 42px;
  .paragraph {
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #98a4ae;
  }

  a {
    text-decoration: none;
    color: #77b1af;
  }
`
