import React from 'react'
import {
  getTimezoneShort,
  secondsToFormattedDateTimeShort
} from '../../utilities/dateTimeUtils'
import {
  Description,
  DeadlineSection,
  OriginalDeadline,
  UnrequestedExtensionDeadline
} from './styled'

function UnrequestablePage (props) {
  const { lockDate, originalLockDate } = props
  const timezone = getTimezoneShort(lockDate)
  const originalDeadline =
    secondsToFormattedDateTimeShort(originalLockDate).replace(', ', ' at ')
  const extendedDeadline =
    secondsToFormattedDateTimeShort(lockDate).replace(', ', ' at ')
  return (
    <>
      <Description>
        This assessment has already been granted an automatic extension and cannot be extended further.
      </Description>
      <DeadlineSection>
        <OriginalDeadline>
          Original deadline: {originalDeadline} {timezone}
        </OriginalDeadline>
        <UnrequestedExtensionDeadline>
          Extended deadline: {extendedDeadline} {timezone}
        </UnrequestedExtensionDeadline>
      </DeadlineSection>
    </>
  )
}

UnrequestablePage.displayName = 'UnrequestablePage'
export default UnrequestablePage
