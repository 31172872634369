import React from 'react'
import PropTypes from 'prop-types'
import PracticeTermText from './PracticeTermText'
import PracticeExerciseItemButton from '../PracticeExerciseItemButton'
import {
  VideoWrapper,
  Content,
  Left,
  Right,
  Title
} from '../../LecturesExerciseItem/styled'
import { emitter } from '../../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../../Constants/emitterKeys'

const PracticeTermItem = props => {
  const {
    isCompleted,
    isCohortEnded,
    sectionUUID,
    isLocked
  } = props

  const handleClick = () => {
    emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/Quizlet`)
  }

  return (
    <VideoWrapper isLocked={isLocked}>
      <Left className='d-flex'>
        <Content>
          <Title>Practice Terms</Title>
          <PracticeTermText
            isCompleted={isCompleted}
          />
        </Content>
      </Left>
      <Right>
        <PracticeExerciseItemButton
          isLocked={isLocked}
          handleClick={handleClick}
          isCompleted={isCompleted}
          isCohortEnded={isCohortEnded}
        />
      </Right>
    </VideoWrapper>
  )
}

PracticeTermItem.propTypes = {
  isCompleted: PropTypes.bool.isRequired,
  isLocked: PropTypes.bool.isRequired,
  sectionUUID: PropTypes.string.isRequired,
  isCohortEnded: PropTypes.bool.isRequired
}

export default PracticeTermItem
