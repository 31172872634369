import React, { useContext } from 'react'
import api from '../../api'
import Context from '../Context/Context'
import TypeForm from '../TypeForm/TypeForm'
import { GGU } from '../../Constants/creditGrantingInstitutions'
import {
  H1, Container, P, Div, H3, GlobalStyle, ItalicText, Separator
} from './styled'

export default function AcademicIntegrityForm () {
  const {
    cohortData: {
      creditGrantingInstitution,
      officialCourseName,
      isGGUCohort
    },
    prospects
  } = useContext(Context)
  const currentProspect = prospects?.[0]
  const {
    prospectId
  } = currentProspect || {}
  const isGGUVersion = creditGrantingInstitution === GGU || isGGUCohort ||
    officialCourseName?.toLowerCase()?.includes('ggu')

  const handleSubmit = async () => {
    if (!prospectId) return
    return api.updateProspectData(prospectId, { hasGGUAIF: true })
  }

  const universityPolicy = (isGGUVersion) => {
    if (isGGUVersion) {
      return (
        <P>
          Students in this course will be expected to agree to and comply with
          the <a href='https://catalog.ggu.edu/content.php?catoid=11&navoid=559#academic-integrity-policy'>
            Golden Gate University Academic Integrity Policy</a>.
          Any student suspected of violating this obligation for any reason during
          the semester will be required to participate in the procedural process
          as outlined in the Golden Gate University Academic Integrity Policy.
        </P>
      )
    }

    return (
      <P>
        Students in Outlier courses will be expected
        to comply with the <a href='http://www.cfo.pitt.edu/policies/policy/02/02-03-02.html'> University
        of Pittsburgh's Policy on Academic Integrity</a>.
        Any student suspected of violating this obligation for any reason during the
        term will be required to participate in the procedural process, initiated by Outlier,
        as outlined in the University of Pittsburgh Guidelines on Academic Integrity.</P>
    )
  }

  const policyOverview = (isGGUVersion) => {
    if (isGGUVersion) {
      return (
        <P>
          Outlier is committed to excellence. Upon entering a course on the Outlier platform,
          every student must sign a pledge to not cheat or plagiarize.
          We expect students to hold themselves and other students to the highest standard of academic integrity.
          Please review the Code of Conduct, Academic Integrity Code,
          and Terms of Agreement before pledging below:
        </P>
      )
    }

    return (
      <P> Outlier is committed to excellence. Upon entering an Outlier course,
        every student must sign a pledge to not cheat or plagiarize.
        We expect Outliers to hold themselves and other Outliers to the highest standard of academic integrity.
        Please review the Code of Conduct, Academic Integrity Code,
        and Terms of Agreement before pledging below:
      </P>
    )
  }

  const codeOfConduct = (isGGUVersion) => {
    if (isGGUVersion) {
      return (
        <>
          <P>
            Outlier is dedicated to creating a safe and constructive learning community.
          </P>
          <P>
            Students are to be respectful in online forums, chat, and other channels.
            Offensive language—or language that could be construed as offensive should be avoided.
            The academic discussion communities are intended for class discussion.
          </P>
          <P>
            Outlier encourages students to learn collaboratively, but graded assessments
            such as quizzes, essays, capstones, or exams may not be discussed or shared.
          </P>
        </>
      )
    }

    return (
      <>
        <P>
          Outlier is dedicated to creating a safe and constructive learning community
          for all of its students (known affectionately around here as “Outliers”).
        </P>
        <P>
          Outliers are to be respectful in online forums, chats, and other channels.
          Offensive language—or language that could be construed as offensive—should be avoided.
          The Outlier Slack channels are intended for class discussion.
        </P>
        <P>
          Outlier encourages students to learn collaboratively, but graded assessments
          such as quizzes or exams may not be discussed or shared.
        </P>
      </>
    )
  }

  const academicIntegrityCode = (isGGUVersion) => {
    if (isGGUVersion) {
      return (
        <P>
          Outlier has a zero-tolerance policy for cheating, and learning resources
          and staff are available to prepare students for all graded assessments.
          Outlier wants to make sure students succeed! If at any time during the course,
          you feel you’re in need of support or are in danger of falling behind,
          your GGU instructors and our dedicated <a href='mailto:success@outlier.org'>Student Success team</a>{' '}
          is standing by to help. Please reach out to us!
        </P>
      )
    }

    return (
      <P>
        Outlier has a zero-tolerance policy for cheating; learning resources
        and staff are available to prepare students for all graded assessments.
        Outlier wants to make sure its students succeed! If, at any time during the course,
        you feel you’re in need of support or are in danger of falling behind,
        our dedicated <a href='mailto:success@outlier.org'>Student Success team</a>{' '}
        is standing by to help. Please reach out to us!
      </P>
    )
  }

  return (
    <TypeForm formId='iWQIyKGs'
      renderHeaderContent={() => (
        <>
          <GlobalStyle />
          <Container className='container'>
            <Div marginBottom={100}>
              <H1> Academic Integrity Policy </H1>
            </Div>
            <Div marginBottom={65}>
              {policyOverview(isGGUVersion)}
            </Div>
            <H3>The Outlier Code Of Conduct</H3>
            {codeOfConduct(isGGUVersion)}
            <ItalicText>
              Failure to act in accordance with Outlier’s Code of Conduct could result in disciplinary sanctions.
            </ItalicText>
            <H3>The Outlier Academic Integrity Code</H3>
            {academicIntegrityCode(isGGUVersion)}
            {universityPolicy(isGGUVersion)}
            <Div marginBottom={70} />

            <H3>Exam Rules</H3>
            <P>
              We’re proud to be pioneering a new, evidence-based approach to online college courses,
              but our approach only works if everyone participates in good faith.
              Here are the rules in place to make sure our exams are fair for all involved:
            </P>
            <P>
              We use online proctoring software to detect cheating and ensure test integrity.
              Misrepresentation of coursework or use of unapproved materials while
              taking graded assessments is considered academic dishonesty.
            </P>
            <P style={{ fontWeight: 'bold' }}>Steps for success</P>
            <P>
              To maintain academic integrity, test-takers must follow the steps below:
            </P>
            <ol>
              <li><P>Provide clear identification to the proctoring service at the time of exams.</P></li>
              <li><P>When taking exams, students must remain visible, stationary,
                and undisturbed by others in a clear workspace for the duration of exams.</P>
              </li>
              <li><P>Test takers must have their test-taking device plugged into a power source
                and have their webcam, speakers, and microphone on and unobstructed for the duration of the exam.</P>
              </li>
            </ol>
            <P style={{ fontWeight: 'bold' }}>When taking exams, test-takers must NOT:</P>
            <ol>
              <li><P>Use headphones, phones, or smart devices</P></li>
              <li><P>Use dual monitors</P></li>
              <li><P>Talk or look around the room</P></li>
            </ol>
            <Div marginBottom={70} />

            <H3>Plagiarism</H3>
            <P>
              Plagiarism occurs when you use another’s words, ideas, assertions, data,
              or figures, and do not acknowledge that you have done so.
            </P>
            <P>
              For our writing assignments, we expect your original work.
              If you reference the words, ideas, or phrasing of another person or
              from published material, you must always acknowledge your sources by citing them.
              Note that using AI content generators when assignments do not explicitly
              call or allow for them is considered plagiarism.
            </P>
            {isGGUVersion
              ? <P>
                If plagiarism or breaches of academic integrity occur, penalties may include participation
                in the procedural process as outlined in the <a href='https://catalog.ggu.edu/content.php?catoid=11&navoid=559#academic-integrity-policy'>
                Golden Gate University Academic Integrity Policy</a>, a failing grade, removal from the course,
                and a ban from taking future Outlier courses. Refunds will not be provided.
              </P>
              : <P>
                If plagiarism or breaches of academic integrity occur, penalties may include a failing grade,
                removal from the course, and a ban from taking future Outlier courses. Refunds will not be provided.
              </P>
            }

            <Div marginBottom={70} />
            {!isGGUVersion && <>
              <H3>Sanctions</H3>
              <P>
              Outlier is invested in our students’ success—it’s why we’re doing what we do to begin with!
              That said, failure to act in accordance with Outlier’s Academic Integrity Code could result in
              disciplinary sanctions, which are designed with your success in mind.
              Sanctions that may be imposed by Outlier Administration include, but are not limited to:
              </P>
              <Div marginBottom={70}>
                <ul>
                  <li><P>reduced or failing grade</P></li>
                  <li><P>removal from the course</P></li>
                  <li><P>notation of academic integrity penalty on the student’s official transcript</P></li>
                  <li><P>a lifetime ban from enrollment with Outlier</P></li>
                  <li><P>any combination of the sanctions above</P></li>
                </ul>
                <P>
                Refunds will not be issued in the case of any corrective action for violations of
                the Code of Conduct, Academic Integrity Code, or Terms of Agreement.
                </P>
              </Div>
              <H3>Appeals Process</H3>
              <P>The appeals process is established as a formal opportunity to review whether
             a violation of academic integrity occurred and/or whether the imposed sanction
              was consistent with the program and other students for similar violations.
               To file an appeal, the following process should be followed:</P>
              <Div marginBottom={70}>
                <ol>
                  <li>
                    <P>The student should write an email to the academic integrity committee{' '}
                      <a href='mailto:success@outlier.org'>(success@outlier.org)</a>{' '}
                    within 10 days' notice of the academic integrity violation.
                    The email must include and address:</P>
                    <ol type='a'>
                      <li><P>The details of what is being appealed</P></li>
                      <li><P>An explanation as to the reason or purpose for which the student is filing the appeal</P></li>
                      <li><P>Supporting documents (emails, course materials) the student wishes the committee to review</P></li>
                    </ol>
                  </li>
                  <li><P>Upon receipt of the email, the academic integrity committee
                  will make a determination to sustain, reverse, or modify the sanction. </P>
                  </li>
                  <li><P>Within two weeks of the appeal submission, a member of the academic integrity committee
                  will notify the individual via email of the committee’s decision.</P>
                  </li>
                </ol>
                <P style={{ fontWeight: 'bold' }}>If a student escalates the concern:</P>
                <ul>
                  <li><P>The appeals committee will schedule a meeting with the individual.</P></li>
                  <li>
                    <P>At the meeting, the student must attend alone, where they will be granted the opportunity
                      to present their case. The individual will be required to sign a document to permit the
                      academic integrity committee to record the hearing.</P>
                  </li>
                  <li><P>The academic integrity committee will make a determination to sustain, reverse, or modify the sanction. </P></li>
                  <li>
                    <P>Within two weeks of the hearing, a member of the academic integrity committee
                      will notify the individual via email of the committee’s decision.</P>
                  </li>
                </ul>
              </Div></>}
            <Separator />
            <Div>
              <H1>TERMS OF AGREEMENT</H1>
              <P style={{ marginTop: '26px' }}>By enrolling in an Outlier course, I agree that:</P>
              <ul>
                <li>
                  <P>I will not share information about the content of graded assessments
                    or exams with others, nor will I use such information shared by others.</P>
                </li>
                <li><P>I will use my original work and will acknowledge the work of others by citing them.</P></li>
                <li><P>I will complete all graded assessments and exams independently.</P></li>
                <li>
                  {isGGUVersion
                    ? <P>I will not communicate with others while completing graded assessments or exams
                    (with the exception of my instructor, Outlier Student Success staff, or the proctoring service
                    in the case of remote proctoring issues).</P>
                    : <P>I will not communicate with others while completing graded assessments or exams
                    (with the exception of Outlier Student Success staff or the proctoring service
                    in the case of remote proctoring issues).</P>
                  }
                </li>
                <li>
                  <P>I will not use unauthorized materials or tools when completing graded assessments or exams.
                    Examples of unauthorized materials and tools while taking an assessment or exams include:</P>
                  <ul>
                    <li><P>Search engines or digital assistants</P></li>
                    <li><P>Headphones, phones, tablets, dual monitors, smartwatches, and other smart devices</P></li>
                    <li><P>Assistive materials such as textbooks, pre-written notes, formula lists, etc.</P></li>
                  </ul>
                </li>
                <li><P>I will keep my webcam, speakers, and microphone on and unobstructed for the duration of my exams.</P></li>
                <li>
                  {isGGUVersion
                    ? <P>I will prepare for my exams as prescribed by my instructors and follow the
                    requirements for completing remote assessments or exams:</P>
                    : <P>I will prepare for my exams as prescribed by Outlier and follow Outlier’s
                    rules for completing remote assessments or exams:</P>
                  }
                  <ul>
                    <li><P>Provide a clear view of your ID at the beginning of the exam</P></li>
                    <li><P>Be fully visible within the webcam frame for the duration of the exam</P></li>
                    <li><P>Be alone and stay put (ensure no one will be entering the room)</P></li>
                    <li><P>Have a clear desk and workspace (only a calculator and/or one sheet of blank paper is allowed)</P></li>
                    <li><P>Be connected to a power source for the duration of the exam</P></li>
                  </ul>
                </li>
              </ul>
              <P>I have read and understood the Outlier Code of Conduct, Outlier Academic Integrity Code,
                    and the Terms of Agreement outlined above.</P>
            </Div>
          </Container>
        </>
      )}
      handleSubmit={handleSubmit}
      afterSubmissionText='Move onto the next form to continue with your Outlier onboarding.' />
  )
}
