import styled from 'styled-components'
import media, { BREAKPOINTS } from '../../../../mediaQueries'

export const GradingScaleWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 48px;
  padding-bottom: 2px;
  ${media.desktop`margin-top: 29px;`}
`

export const GradingCriteriaText = styled.div`
  margin-top: 12px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 24px;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;

  ${media.tablet`
    grid-template-columns: auto;
    grid-gap: 0;
  `}
`

export const InstructorsWrapper = styled.div`
  .instructor-wrapper {
    margin-top: 32px;

    ${media.mobile` margin-top: 40px;`}
  }
`

export const CourseDescAndMaterialWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% auto;
  ${media.tablet`
    grid-template-columns: auto;
    grid-gap: 32px;
    margin-bottom: 29px;
  `}
  grid-gap: 24px;
  padding-top: 2px;
  margin-bottom: 32px;
`

export const CourseDescAndMaterialText = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
`

export const DownloadSyllabusButton = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5fc4b8;
  margin-top: -4px;
  cursor: pointer;

  &:disabled {
    color: #5fc4b8;
    opacity: 0.3;
  }
  &:not(:disabled) {
    &:active {
      color: #b8fff7;
      opacity: 0.3;
    }
    &:hover {
      color: #b8fff7;
    }
  }

  ${media.desktop`
    margin-top: -2px;
  `}
  ${media.tablet`
    margin-top: -4px;
    margin-bottom: 35px;
  `}
  ${media.mobile`
    margin-bottom: 29px;
  `}
`

export const LearningOutcomesWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
    grid-template-columns: auto;
    grid-gap: 0;
  }
  ${media.desktop`padding-bottom: 48px;`}
  ${media.mobile`padding-bottom: 29px;`}
  padding-bottom: 53px;
  grid-gap: 24px;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;

  > ul {
    padding-inline-start: 17px;
  }

  ${media.tablet`
    > ul {
      padding-inline-start: 18px;
    }
  `}

  ${media.mobile`
    > ul {
      padding-inline-start: 20px;
    }
  `}
`

export const LearningOutcomesSubTitle = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 24px;
`

export const LearningOutcomeItem = styled.li.attrs(() => ({
  'data-cy': 'outcomeListItem'
}))`
  margin-bottom: 12px;
`

export const ScheduleSectionWrapper = styled.div`
  margin-top: 48px;
`
