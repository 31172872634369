import React, { useContext, useState } from 'react'
import config from '../../../config'
import Header from '../Header'
import ScheduleSection from '../ScheduleSection'
import FAQ from '../FAQ'
import GradeCriteriaChart from '../GradeCriteriaChart'
import GradingScale from '../GradingScale'
import Links from '../Links/Links'
import Lecturers from '../Instructors' // Note: should be renamed to Lecturers
import Instructors from './Instructors'
import ScrollToTop from '../../ScrollToTop'
import { emitter } from '../../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../../Constants/emitterKeys'
import { Wrapper, Container, GlobalStyle, SubHeader } from '../styled'
import Context from '../../Context/Context'
import { shouldHideGrading } from '../Utils'

const isCollegeSuccessCourse = config.isCollegeSuccessCourse()

const Resources = () => {
  const [isLecturersLoading, setIsLecturersLoading] = useState(true)

  const {
    courseData = {}, cohortData = {}
  } = useContext(Context)
  const { isGGUCohort } = cohortData

  if (config.isPreviewCourse) {
    emitter.emit(ON_NAVIGATE_TO, '/')
    return null
  }

  const hideScheduleSection = isCollegeSuccessCourse
  const hideGrading = shouldHideGrading({
    courseData, cohortData, isCollegeSuccessCourse
  })

  return (
    <>
      <GlobalStyle />
      <ScrollToTop />
      <Wrapper>
        <Container>
          <Header
            title='Course Toolkit'
            breadcrumb={{
              text: 'Course Home',
              link: config.courseId
            }}
          />
          {!hideScheduleSection && <ScheduleSection subHeader='schedule' />}
          <Links />
          {isGGUCohort && <Instructors />}
          <Lecturers setIsLecturersLoading={setIsLecturersLoading} />
          {
            !hideGrading &&
              (config.hasInTimeGradeFlag ? !isLecturersLoading : true) && (
              <>
                <SubHeader>grading</SubHeader>
                <GradeCriteriaChart />
                <GradingScale />
              </>
            )}
          <FAQ />
        </Container>
      </Wrapper>
    </>
  )
}

export default Resources
