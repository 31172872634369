import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import {
  GlobalStyle,
  ModalHeader,
  Separator,
  OptionsLink,
  ButtonsContainer,
  PrimaryButton
} from '../style'
import { STUDENT_ACCEPTED_NON_CERTIFICATE_WARNING } from '../../../Constants'

const NonCertificateWarningModal = ({
  showModal,
  certificateName,
  courseName
}) => {
  const [show, setShow] = useState(showModal)
  const closeModal = () => {
    setShow(false)
  }

  return (
    <Modal show={show} centered>
      <GlobalStyle />
      <ModalHeader>
        <p>This course isn't part of your Certificate.</p>
      </ModalHeader>
      <Separator />
      <Modal.Body>
        <p>
          You can still take it, but it won't count towards your
          {certificateName} Certificate. To keep making Certificate progress,
          exit this course and enroll in a Certificate course via our Catalog.
        </p>
        <OptionsLink
          data-testid='link-exit-options'
          onClick={() => {
            window.open(
              `https://dashboard.outlier.org/#/account/manage-courses/${courseName}`,
              '_self'
            )
          }}
        >
          view exit options
        </OptionsLink>
        <ButtonsContainer>
          <PrimaryButton
            data-testid='btn-stay'
            onClick={() => {
              localStorage.setItem(
                STUDENT_ACCEPTED_NON_CERTIFICATE_WARNING,
                'true'
              )
              closeModal()
            }}
            height='auto'
          >
            stay in this course
          </PrimaryButton>
        </ButtonsContainer>
      </Modal.Body>
    </Modal>
  )
}

NonCertificateWarningModal.diaplayName = 'ExitModal'

export default NonCertificateWarningModal
