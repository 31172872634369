import React, { useState, useEffect, useContext } from 'react'
import { Collapse } from 'reactstrap'
import LockedMarker from '../LockedMarker/LockedMarker'
import util, { capitalizeFirstLetter } from '../../utilities'
import { SectionListExerciseItemStyle, ToggleIcon } from '../SectionListExcerciseItem/styled'
import CustomTooltip from '../Tooltip'
import { TooltipIcon } from '../Tooltip/styled'
import Context from '../Context/Context'
import PracticeTermItem from './PracticeTermItem'
import { VideosWrapper } from '../LecturesExerciseItem/styled'
import { CONCEPT_MAP, LECTURE, PRACTICE_TERMS, PROBLEM_BANK } from '../../Constants/sectionType'
import {
  CONCEPT_MAP_COMPLETE, LECTURE_COMPLETE, PRACTICE_TERM_COMPLETE
} from '../../Constants/studentContext'
import PracticeExerciseItemText from './PracticeExerciseItemText'
import config from '../../config'
import ConceptMapItem from './ConceptMapItem'
import MultipleVideos from '../LecturesExerciseItem/MultipleVideos'
import PracticeSetSectionItem from '../SectionListExcerciseItem/PracticeSetSectionItem'

const PracticeExerciseItem = props => {
  const [numberOfCompletedActivities, setNumberOfCompletedActivities] = useState(-1)
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const {
    sectionUUID,
    isLastItem,
    lockReason,
    excercise
  } = props
  const {
    isCohortEndedForStudent,
    studentData: {
      studentAnswers = [],
      [CONCEPT_MAP_COMPLETE]: conceptMapComplete,
      [LECTURE_COMPLETE]: lectureComplete,
      [PRACTICE_TERM_COMPLETE]: practiceTermComplete
    }
  } = useContext(Context)

  useEffect(() => {
    setNumberOfCompletedActivities(getNumberOfCompletedActivities())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const { activities, type } = excercise

    const { get } = config.sectionItemAccordionState(type, sectionUUID)
    const storedState = get()
    if (storedState) return setIsAccordionOpen(storedState === 'true')

    // if all activities completed, collapse the accordion.
    const isAllCompleted = activities.length === numberOfCompletedActivities
    setIsAccordionOpen(!isAllCompleted)
    // eslint-disable-next-line
  }, [numberOfCompletedActivities])

  const handleToggle = () => {
    const { type } = excercise
    const { set } = config.sectionItemAccordionState(type, sectionUUID)
    set(!isAccordionOpen)
    setIsAccordionOpen(!isAccordionOpen)
  }

  const getComponentByType = type => {
    const mapping = {
      [CONCEPT_MAP]: ConceptMapItem,
      [LECTURE]: MultipleVideos,
      [PROBLEM_BANK]: PracticeSetSectionItem,
      [PRACTICE_TERMS]: PracticeTermItem
    }

    return mapping[type] || null
  }

  const getNumberOfCompletedActivities = () => {
    const { activities } = excercise

    return activities.filter(activity => isActivityCompleted(activity)).length
  }

  const isActivityCompleted = activity => {
    const { type } = activity

    if (type === PROBLEM_BANK) {
      const problemSetUUID = activity?.exercice?.[0]?.['question_set_uuid']
      const isPracticeSetCompleted = getIsPracticeSetCompleted(problemSetUUID)
      activity.exercice[0].isCompleted = isPracticeSetCompleted
      return isPracticeSetCompleted
    }

    if (type === LECTURE) {
      const kalturaEmbedCode = activity?.multipleVideos?.[0]?.kalturaEmbedCode
      return !!lectureComplete[kalturaEmbedCode]
    }

    const completeData = getActivityCompleteData(type)

    if (!completeData) return false
    return !!completeData[sectionUUID]
  }

  const getActivityCompleteData = activityType => {
    const mapping = {
      [CONCEPT_MAP]: conceptMapComplete,
      [PRACTICE_TERMS]: practiceTermComplete
    }

    return mapping[activityType]
  }

  const getIsPracticeSetCompleted = (problemSetUUID) => {
    return !!studentAnswers?.find(answer =>
      answer?.uuid === problemSetUUID && answer?.isPracticeCompleted)
  }

  const fieldTooltip = 'Use this to practice what you\'ve learned in the Lectures and Active Learning.'
  const { type, activities } = excercise

  const numberOfActivities = activities.length
  const isLocked = !!lockReason

  return (
    <>
      <SectionListExerciseItemStyle
        isLastItem={isLastItem}
        className={`section-panel-active d-flex
        justify-content-between align-items-center`}
        onClick={handleToggle}
        data-cy='sectionListExerciseItem'
        onKeyPress={(e) => util.handleKeyPress(e, handleToggle)}
        tabIndex='0'
      >
        <div className='d-flex flex-column'>
          <div className='main-panels--left'>
            <h3 className='panel-title d-inline-block section-title'>
              {capitalizeFirstLetter(type)}
            </h3>
            <CustomTooltip
              id='practice-tooltip'
              text={fieldTooltip}
            >
              <TooltipIcon>?</TooltipIcon>
            </CustomTooltip>
          </div>
          <PracticeExerciseItemText
            numberOfCompleted={numberOfCompletedActivities}
            numberOfActivities={numberOfActivities}
          />
        </div>
        <div className='main-panels--right d-flex align-items-center'>
          {isLocked && <LockedMarker />}
          <ToggleIcon
            isAccordionOpen={isAccordionOpen}
            src={'images/icons/icon-angle-arrow-light.svg'}
            isLocked={isLocked}
          />
        </div>
      </SectionListExerciseItemStyle>
      <Collapse isOpen={isAccordionOpen}>
        <VideosWrapper>
          {
            activities.map(activity => {
              const { type } = activity
              const Comp = getComponentByType(type)
              if (!Comp) return null

              return <Comp
                key={type}
                isLocked={isLocked}
                isCohortEnded={isCohortEndedForStudent}
                isCompleted={isActivityCompleted(activity)}
                sectionUUID={sectionUUID}
                {...activity}
              />
            })
          }
        </VideosWrapper>
      </Collapse>
    </>
  )
}

PracticeExerciseItem.displayName = 'PracticeExerciseItem'

export default PracticeExerciseItem
