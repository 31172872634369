import React, { useContext, useState, useRef, useEffect } from 'react'
import { history } from '../../HistoryManager/HistoryManager'
import { SubHeader } from '../styled'
import ScheduleItem from './ScheduleItem'
import Context from '../../Context/Context'
import {
  getActiveSchedules,
  getDynamicSchedules,
  todayAtTwelveAmInSecs
} from '../Utils'
import ShowOrHideAll from '../ShowOrHideAll'
import {
  ArchivedScheduleNote,
  SyllabusChangeNote,
  RightMargin,
  TextLink
} from './styled'
import { emitter } from '../../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../../Constants/emitterKeys'
import { ADD_TO_CALENDAR_LABEL } from '../../../Constants'
import { COURSE_TOOLKIT_PAGE } from '../../../Constants/pageType'
import CalendarList from '../../CalendarList/CalendarList'
import { ImageStyler } from '../../CourseHome/styles'
import { getIsVIPCohort } from '../../../utilities/courseUtils'

const ScheduleSection = ({ subHeader, isSyllabusPage }) => {
  const {
    cohortData: {
      courseResourcesSchedule,
      startDate,
      name: cohortName
    },
    isAdmin
  } = useContext(Context)
  const [viewAllSchedule, setViewAllSchedule] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [showCalendarDropdown, setShowCalendarDropdown] = useState(false)
  const [activeScheduleCount, setActiveScheduleCount] = useState(null)
  const [cohortSchedule, setCohortSchedule] = useState([])
  const hasArchivedSchedule = todayAtTwelveAmInSecs > startDate
  const isAdminAndVipCohort = isAdmin && getIsVIPCohort(cohortName)
  const isGGUCohort = cohortName?.toLowerCase().includes('ggu')

  useEffect(() => {
    if (!courseResourcesSchedule?.length) return

    const getCohortSchedule = async () => {
      setIsLoading(false)
      if (isSyllabusPage) {
        setCohortSchedule(courseResourcesSchedule)
        return
      }

      const dynamicCohortSchedule = getDynamicSchedules(
        courseResourcesSchedule
      )
      if (!dynamicCohortSchedule?.length) return

      setCohortSchedule(dynamicCohortSchedule)
      const activeSchedules = getActiveSchedules(dynamicCohortSchedule)
      setActiveScheduleCount(activeSchedules)
    }

    getCohortSchedule()
  }, [courseResourcesSchedule, isSyllabusPage])

  const scheduleRef = useRef()
  const {
    location: { hash }
  } = history

  useEffect(() => {
    // to scroll schedule section into view if the url has a hash —> #schedule
    if (hash !== '#schedule') return
    scheduleRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [cohortSchedule, hash])

  const handleSyllabusNavigation = () => {
    emitter.emit(ON_NAVIGATE_TO, '/resources/syllabus/#schedule')
  }

  const handleScheduleBtnClick = () => {
    setShowCalendarDropdown(!showCalendarDropdown)
  }

  const renderArchivedNote = () => {
    return (
      isAdminAndVipCohort
        ? <ArchivedScheduleNote>
          You currently have the admin view of the course.
          To view the dynamic schedule for a specific cohort, please contact the product team.
        </ArchivedScheduleNote>
        : !isGGUCohort
          ? <ArchivedScheduleNote>
            We’ve archived past dates in{' '}
            <span data-cy='syllabusLink' onClick={handleSyllabusNavigation}>The Syllabus</span>.
          </ArchivedScheduleNote>
          : null
    )
  }

  const scheduleShowingInDefaultCollapsedView =
    activeScheduleCount && activeScheduleCount > 3 ? activeScheduleCount : 3

  return (
    <>
      <SubHeader
        data-cy='scheduleSubHeader'
        ref={scheduleRef}
      >
        <RightMargin>
          {subHeader}
        </RightMargin>
        {isAdminAndVipCohort ? null
          : <>
            { isLoading ? (
              <i className='fa fa-spinner fa-spin ml-2' />
            ) : (
              <TextLink
                hasPointer
                data-testid='addToCalendar'
                onClick={handleScheduleBtnClick}
              >
                <span>
                  <ImageStyler
                    src='images/icons/calendar-icon.png'
                    alt='Toolkit Icon' />
                  { ADD_TO_CALENDAR_LABEL }
                </span>
                { showCalendarDropdown &&
                  <CalendarList
                    origin={COURSE_TOOLKIT_PAGE}
                    handleCalendarDropdown={handleScheduleBtnClick}
                  /> }
              </TextLink>
            ) }
          </>
        }
      </SubHeader>
      {isAdminAndVipCohort && isSyllabusPage ? (
        <SyllabusChangeNote>
          You currently have the admin view of the course. To view the dynamic
          schedule for a specific cohort, please contact the product team.
        </SyllabusChangeNote>
      ) : isAdminAndVipCohort ? (
        <SyllabusChangeNote>
          View the master{' '}
          <span data-cy='syllabusLink' onClick={handleSyllabusNavigation}>
            syllabus here
          </span>
          . You currently have the admin view of the course. To view the dynamic
          schedule for a specific cohort, please contact the product team.
        </SyllabusChangeNote>
      ) : isSyllabusPage && (
        <SyllabusChangeNote>
            Section assignments and assessments are subject to change and will
            be announced within a reasonable time frame.
        </SyllabusChangeNote>
      )
      }
      {(viewAllSchedule
        ? cohortSchedule
        : cohortSchedule.slice(0, scheduleShowingInDefaultCollapsedView)
      ).map(schedule => (
        <ScheduleItem
          todayAtTwelveAmInSecs={todayAtTwelveAmInSecs}
          isAdminAndVipCohort={isAdminAndVipCohort}
          isSyllabusPage={isSyllabusPage}
          schedule={schedule}
          key={`${schedule.title}-${schedule.type}`}
        />
      ))}
      {!isLoading && !isSyllabusPage && hasArchivedSchedule &&
        cohortSchedule.length <= scheduleShowingInDefaultCollapsedView && !isAdminAndVipCohort &&
        renderArchivedNote()}
      {cohortSchedule.length > scheduleShowingInDefaultCollapsedView && (
        <>
          {!isSyllabusPage &&
            hasArchivedSchedule &&
            viewAllSchedule &&
            renderArchivedNote()}
          <ShowOrHideAll
            text='Future Dates'
            showAll={viewAllSchedule}
            setShowAll={setViewAllSchedule}
            paddingTop='12'
          />
        </>
      )}
    </>
  )
}

export default ScheduleSection
