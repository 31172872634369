import styled from 'styled-components'

export const TextDisabled = styled.div`
  color: #455456;
  font-size: 18px;
`

export const TextEnabled = styled.div`
  color: #77b1af;
  font-size: 18px;
`
