import React, { useState, useEffect } from 'react'
import { TranscriptPluginWrapper, OverrideCSSProperties } from './styles'

const TranscriptPlugin = React.forwardRef((props, ref) => {
  const getTranscriptPluginSize = () => {
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight
    return Math.floor(windowHeight - 0.34 * windowWidth - 182)
  }

  const [size, setSize] = useState(getTranscriptPluginSize())

  useEffect(() => {
    window.onresize = () => {
      setSize(getTranscriptPluginSize())
    }

    return () => {
      window.onresize = null
    }
  }, [])

  return (
    <>
      <OverrideCSSProperties transcriptHeight={size} />
      <TranscriptPluginWrapper
        id='transcript-player-plugin'
        ref={ref}
      />
    </>
  )
})

TranscriptPlugin.displayName = 'TranscriptPlugin'
export default TranscriptPlugin
