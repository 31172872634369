import React, { useEffect, useState } from 'react'
import {
  canvasSmallToolbarSettings,
  flexibleComparisonOptions,
  strictComparisonOptions
} from './configuration'
import { useFrame } from 'react-frame-component'

function GMath ({ eqs, matchCommuted, matchAnyEq, setGMathHeight }) {
  const [canvas, setCanvas] = useState(null)
  const [paths, setPaths] = useState([])
  const [isSolved, setIsSolved] = useState(false)
  const { document, window } = useFrame()
  const toggleBtn = document.querySelector('.expansion-toggle')

  useEffect(() => {
    !window.gmath && loadGMScript()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!toggleBtn) return

    toggleBtn.addEventListener('click', handleGMathHeight)

    return () => {
      toggleBtn.removeEventListener('click', handleGMathHeight)
    }
    // eslint-disable-next-line
  }, [canvas])

  const handleGMathHeight = () => {
    const isExpanded = toggleBtn.classList.contains('expansion-toggle-expanded')
    const COLLAPSE_HEIGHT = '400px'
    const EXPANDED_HEIGHT = '700px'
    const GMHeight = isExpanded ? EXPANDED_HEIGHT : COLLAPSE_HEIGHT
    setGMathHeight(GMHeight)
  }

  const loadGMScript = () => {
    const script = document.createElement('script')
    script.id = 'gmScript'
    script.src = 'https://graspablemath.com/shared/libs/gmath/gm-inject.js'
    script.onload = () => onGMLoad()
    document.body.appendChild(script)

    const sheet = document.createElement('style')
    sheet.innerHTML = `
      #gm-div {border: 1px solid gray; margin: 11px;}
      #gm-div.solved{box-shadow: lightgreen 0 0 8px 3px;}
      .gm-formula-panel-container { font-family: Lato, sans-serif; }
    `
    document.body.appendChild(sheet)
  }

  const onGMLoad = () => {
    const { loadGM } = window
    loadGM(() => initCanvas())
  }

  useEffect(() => {
    setupDerivations()
    // eslint-disable-next-line
  }, [canvas, JSON.stringify(eqs)])

  const initCanvas = () => {
    const { gmath } = window
    gmath.setDarkTheme(true)
    gmath.SettingsType.get('Derivation').defaults.setAll({
      pos: 'auto',
      font_size: 30,
      cloning_on: false
    })
    gmath.SettingsType.get('Math').doc.set('trig_in_degrees', false)
    if (canvas) {
      canvas.controller.reset()
      document
        .querySelectorAll('.divider-line')
        .forEach((e) => e.parentNode.removeChild(e))
    } else {
      const canvas = new gmath.Canvas('#gm-div', canvasSmallToolbarSettings)
      // Set up auto-layout.
      gmath.autoLayout.autoLayoutCanvasForOutlier(canvas, {
        mayAdjustFontSize: false,
        maxFontSize: 80,
        adjustViewportHeight: 'dynamic',
        minViewportHeight: '296px',
        maxViewportHeight: '300px',
        maxFormulaPanelHeight: '100vh'
      })
      setCanvas(canvas)
      setPaths([])
    }
  }

  const setupDerivations = () => {
    if (!canvas) return

    const width = canvas.model.viewport().width
    const col = width / eqs.length
    canvas.controller.reset()
    const newDerivations = eqs.map(({ start }, i) => {
      if (i) addLine(col * i)
      return canvas.model.createElement(
        'derivation',
        { eq: start, pos: { x: col * i + col / 2, y: 20 } },
        null,
        (der) => der.moveElement({ x: -der.size.width / 2, y: 0 })
      )
    })
    canvas.controller.on('timelineChange.outlier', () => {
      checkIfSolved(newDerivations)
      setTimeout(() => adjustColumns(), 0)
    })
    canvas.setResetState()
    checkIfSolved(newDerivations)
  }

  const checkIfSolved = (derivations) => {
    const result = eqs.every(({ goal }, i) => {
      if (!derivations) return null
      if (!goal) return false // ignore empty goals
      if (matchAnyEq) {
        return derivations.some((der) => isEqual(der, goal))
      } else {
        return isEqual(derivations[i], goal)
      }
    })
    setIsSolved(result)
  }
  const isEqual = (der, eq) => {
    const { gmath } = window
    const opts = matchCommuted
      ? flexibleComparisonOptions
      : strictComparisonOptions
    return gmath.AlgebraModel.algExpressionsAreEqual(
      opts,
      der.getLastModel(),
      eq
    )
  }

  const adjustColumns = () => {
    const h = canvas.model.size().height
    paths.forEach((path) => {
      path.points = path.points.map((p) => [p[0], p[1] ? h : 0])
      path.update()
    })
    handleGMathHeight()
  }

  const addLine = (x) => {
    const h = canvas.model.viewport().height
    const newPath = canvas.model.createPath({
      points: [
        [x, 0],
        [x, h]
      ]
    })
    newPath.color = 'gray'
    newPath.element.style('stroke', 'gray')
    newPath.element.style('stroke-dasharray', 4)
    const tempPath = [...paths]
    tempPath.push(newPath)
    setPaths(tempPath)
  }

  return (
    <div
      id='gm-div'
      className={isSolved ? 'solved' : ''}
    />
  )
}

export default GMath
