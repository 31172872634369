import React from 'react'
import { useAuth0Context } from '../Components/Auth0Provider/Auth0Provider'

const withAuthContext = Component => {
  const WithAuthContext = props => {
    const context = useAuth0Context()

    return (
      <Component {...props} authData={{ ...context }} />
    )
  }

  return WithAuthContext
}

export default withAuthContext
