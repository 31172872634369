import api from '../../../api'
import config from '../../../config'

export async function handleCourseraProgramMembership (e) {
  document.body.style.cursor = 'wait'
  e.target.style.cursor = 'wait'

  const courseraProgramInvitations =
    JSON.parse(localStorage.getItem('courseraProgramInvitations')) || {}

  if (courseraProgramInvitations[config.courseId]) {
    document.body.style.cursor = 'default'
    e.target.style.cursor = ''
    return
  }

  const { success } = await api.courseraProgramMembership(config.courseId)

  if (success) {
    courseraProgramInvitations[config.courseId] = true
    localStorage.setItem('courseraProgramInvitations',
      JSON.stringify(courseraProgramInvitations))
  }

  document.body.style.cursor = 'default'
  e.target.style.cursor = ''
}
