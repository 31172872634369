import styled from 'styled-components'

export const ModalDiv = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`
export const ModalCTA = styled.div`
  font-family: Lato;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  margin-top: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5FC4B8;
  margin-bottom: -24px;
  cursor: pointer;
`
export const Icon = styled.img`
  width: inherit;
  height: inherit;
  vertical-align: middle;
  border-style: none;
  margin-left: 13px;
`
