import React, { useContext, useState } from 'react'
import AlertModal from '../AlertModal/AlertModal'
import Context from '../Context/Context'
import api from '../../api'
import {
  CONTACT_STUDENT_SUCCESS_LINK,
  COURSE_FEATURE_KEY,
  GGU_CONTACT_INSTRUCTOR_PATH
} from '../../Constants'
import { Icon, ModalCTA, ModalDiv } from './styled'
import { PRACTICE_EXAM } from '../../Constants/examType'
import {
  addDaysToDateInSecs,
  dateToSecondsSinceEpoch,
  secondsSinceEpoch
} from '../../utilities/dateTimeUtils'
import { NEXT_ICON_ENABLED } from '../../Constants/progressBarIconSource'
import isEmpty from 'lodash/isEmpty'
import {
  ASSIGNMENT_PROGRESS, EXAM_COMPLETE
} from '../../Constants/studentContext'
import { NO_EXTENSION_MODAL_REQUEST } from '../../Constants/studentStatus'

const MissedDeadlineModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(true)

  const {
    courseData: { chapters = [] } = {},
    courseFeatures: {
      [COURSE_FEATURE_KEY]: closedIds = []
    } = {},
    latestCohort = {},
    originalCohortDeadlines = {},
    studentData = {},
    setCourseFeatures,
    cohortData: { studentStatus, isGGUCohort } = {}
  } = useContext(Context) || {}

  const {
    relationship: { fields: { liveProctoring } = {} } = {}
  } = latestCohort || {}

  const hideExtensionModal = NO_EXTENSION_MODAL_REQUEST.includes(studentStatus)

  if (!chapters?.length || hideExtensionModal) return null

  const currentDateInSeconds = secondsSinceEpoch()
  const {
    finalExamEndTime,
    cohortMilestones = []
  } = originalCohortDeadlines || {}

  // extensions can be created no later than 1wk after the final exam
  if (finalExamEndTime) {
    const finalExamEndTimeInSeconds =
      dateToSecondsSinceEpoch(new Date(finalExamEndTime))
    const oneWeekAfterFinalExam =
      addDaysToDateInSecs(finalExamEndTimeInSeconds, 7)
    if (oneWeekAfterFinalExam < currentDateInSeconds) return null
  }

  // extensions can be created no later than 1wk after the final assignment
  if (latestCohort?.milestones?.length) {
    const finalAssignment = (latestCohort?.milestones || [])
      .find(cohort => cohort.finalAssignment === true)

    const { lockTime } = finalAssignment || {}
    const finalAssignmentLockDate = dateToSecondsSinceEpoch(new Date(lockTime))
    const oneWeekAfterFinalAssignment =
      addDaysToDateInSecs(finalAssignmentLockDate, 7)
    if (oneWeekAfterFinalAssignment < currentDateInSeconds) return null
  }

  const examChapters = (chapters || []).filter(
    chapter => chapter.type === 'exam' && chapter.title !== PRACTICE_EXAM
  )
  const [
    { chapter_uuid: midterm1ExamId, title: midterm1Title } = {},
    { chapter_uuid: midterm2ExamId, title: midterm2Title } = {},
    { chapter_uuid: finalExamId, title: finalExamTitle } = {}
  ] = examChapters

  let data

  const showDeadline = (endTime, id, title, isExam = true) => {
    if (!(endTime && id)) return

    const endTimeInSeconds = dateToSecondsSinceEpoch(new Date(endTime))
    if (currentDateInSeconds < endTimeInSeconds) return

    // Do not show deadline modal if there is an extended toast with the same assessment
    const extendedAssessment = JSON.parse(
      localStorage.getItem('extensionToast') || '{}'
    )
    if (id === extendedAssessment.assessmentID) return

    const isExamModalClosed = closedIds.includes(id)
    if (isExamModalClosed) return

    const isExamCompleted = isExam
      ? studentData?.[EXAM_COMPLETE]?.[id] === true
      : studentData?.[ASSIGNMENT_PROGRESS]?.[id]?.status === 'submitted'
    if (isExamCompleted) return

    return { id, title, isExam }
  }

  /* Process midterm 1 */
  data = showDeadline(latestCohort.midTerm1EndTime, midterm1ExamId, midterm1Title)
  /* Process midterm 2 */
  const midTerm2Data = showDeadline(latestCohort.midTerm2EndTime, midterm2ExamId, midterm2Title)
  if (midTerm2Data) data = midTerm2Data
  /* Process Final Exam */
  const finalExamData = showDeadline(latestCohort.finalExamEndTime, finalExamId, finalExamTitle)
  if (finalExamData) data = finalExamData

  // Assignments
  if (!data) {
    cohortMilestones.forEach((cohortMilestone, index, arr) => {
      const {
        id: milestoneId
      } = cohortMilestone

      const currentCohortMilestone = (latestCohort?.milestones || [])
        .find(cohort => cohort.id === milestoneId)

      const { lockTime, datoAssignmentUUID } = currentCohortMilestone || {}
      const assignmentObj = currentCohortMilestone?.assignmentList?.[0] || {}
      if (isEmpty(assignmentObj)) return

      const assignmentName = assignmentObj?.name || ''
      const title = `${assignmentName.split('|').pop()}`.trim()

      const assignmentData =
        showDeadline(lockTime, datoAssignmentUUID, title, false)

      if (assignmentData) data = assignmentData
    })
  }

  const closeModal = async (id, goToContact = false) => {
    const idList = [...closedIds, id]
    // // Update local state of closedIds
    setCourseFeatures(COURSE_FEATURE_KEY, idList)
    // hide modal
    setIsModalVisible(false)
    // set this value in BE
    const response = await api.setCourseFeatures(COURSE_FEATURE_KEY, idList)
    if (response.error) return

    if (goToContact) {
      const contactLink = isGGUCohort
        ? GGU_CONTACT_INSTRUCTOR_PATH
        : CONTACT_STUDENT_SUCCESS_LINK

      window.open(contactLink, '_blank')
    }
  }

  const modalCTAText = isGGUCohort ? 'Contact instructor' : 'Contact student success'
  const modalBodyText = isGGUCohort ? 'your instructor.' : 'the Student Success team!'

  if (!data) return null
  const { id, title = '', isExam } = data

  const wrapperStyles = {
    backgroundColor: '#25272B', borderRadius: '5px', height: 'auto'
  }

  const hideModal = isExam && liveProctoring

  if (hideModal) return null

  return (
    <>
      {isModalVisible && <AlertModal
        data-testid='missed-deadline-modal'
        show={isModalVisible}
        title={`You missed ${title}`}
        headerShow
        closeButtonText=''
        closeButtonClickHandler={() => closeModal(id)}
        wrapperWidth={'400px'}
        wrapperStyles={wrapperStyles}
      >
        <ModalDiv data-testid='missed-deadline-text'>
          The deadline for {title} just passed.
          If you need an extension, please contact {modalBodyText}
        </ModalDiv>
        <ModalCTA
          data-testid='contact-button'
          onClick={() => closeModal(id, true)}>
          {modalCTAText}<Icon src={NEXT_ICON_ENABLED} />
        </ModalCTA>
      </AlertModal>}
    </>)
}

MissedDeadlineModal.displayName = 'MissedDeadlineModal'
export default MissedDeadlineModal
