import React from 'react'

function InstructorVideoListItem (props) {
  const { instructordata } = props
  return (
    <>
      <div style={{ flex: '1.3344' }}>
        <img
          src={instructordata.image}
          style={{ maxWidth: '100%', height: 'auto' }}
          alt={instructordata.name}
        />
        <div className='mt-2'>
          <h5>{instructordata.name}</h5>
          <p
            className='text-justify'
            dangerouslySetInnerHTML={{ __html: (instructordata.bio) }}
          />
        </div>
      </div>
    </>
  )
}
export default InstructorVideoListItem
