import React from 'react'
import {
  StyledText
} from './styled'

const PracticeTermText = props => {
  const { isCompleted } = props

  if (
    isCompleted
  ) return <StyledText>Completed</StyledText>

  return <StyledText>Unlimited Practice</StyledText>
}

export default PracticeTermText
