import debounce from 'lodash/debounce'
import React, { Component } from 'react'
import { getAnswerField } from '../FreeformEquationQuestion/utils/wiris'
import {
  isPopupEditor,
  cleanupPopup,
  asyncCheckStudentAnswer
} from './utils'

export default class Quizzes extends Component {
  constructor (props) {
    super(props)
    this.alreadyAddQuizzesListener = false
    this._removeOnInputListener = null
    this._valueAtInit = ''
  }

  componentDidMount () {
    this.addQuizzesFieldListener()
    this.onChange()
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      questionConfiguration: prevConfig,
      quizzesEditor: prevEditor,
      practiceExerciseConfig: prevPractice
    } = prevProps
    const {
      questionConfiguration: nextConfig,
      quizzesEditor: nextEditor,
      practiceExerciseConfig: nextPractice
    } = this.props

    const prevRandomSeed = prevPractice && prevPractice.questionRandomSeed
    const nextRandomSeed = nextPractice && nextPractice.questionRandomSeed

    if (!this.alreadyAddQuizzesListener || prevConfig !== nextConfig ||
      prevEditor !== nextEditor || prevRandomSeed !== nextRandomSeed) {
      this.addQuizzesFieldListener()
    }

    if (prevEditor !== nextEditor) {
      this.onChange()
      closeEditor(prevEditor)
    }
  }

  componentWillUnmount () {
    closeEditor(this.props.quizzesEditor)
    this.removeOnInputListener()
  }

  // PUBLIC METHODS:

  getMathML = () => {
    const f = getAnswerField()
    return f ? f.getValue() : ''
  }

  setMathML = mathML => {
    const f = getAnswerField()
    const { questionConfiguration: q } = this.props
    if (!f || (!mathML && isPopupEditor(q))) return

    f.setValue(mathML)
  }

  asyncCheckAnswer = async () => {
    const answerField = getAnswerField()
    if (!answerField) return

    const { questionConfiguration, additionalConfigurations } = this.props
    const studentAnswer = answerField.getValue()

    return asyncCheckStudentAnswer({
      questionConfiguration, studentAnswer, additionalConfigurations
    })
  }

  // PRIVATE METHODS:

  addQuizzesFieldListener = () => {
    const f = getAnswerField()
    if (!f) return

    f.addQuizzesFieldListener({
      contentChanged: this.onChange
    })
    this.addOnInputListener()
    this.alreadyAddQuizzesListener = true
    this._valueAtInit = f.getValue()
  }

  addOnInputListener = () => {
    this.removeOnInputListener()

    const intervalId = setInterval(() => {
      const nodes = document.querySelectorAll('#studentAnswer input')
      if (!nodes || !nodes.length) return

      this.removeOnInputListener()
      Array.prototype.forEach.call(nodes, e => {
        e.addEventListener('input', this.onChange)
        e.addEventListener('change', this.onChange)
      })
      this._removeOnInputListener = () =>
        Array.prototype.forEach.call(nodes, e => {
          e.removeEventListener('input', this.onChange)
          e.removeEventListener('change', this.onChange)
        })
    }, 100)

    this._removeOnInputListener = () => clearInterval(intervalId)
  }

  removeOnInputListener = () => {
    if (!this._removeOnInputListener) return
    this._removeOnInputListener()
    this._removeOnInputListener = null
  }

  onChange = debounce(() => {
    const { props: { onChange } } = this
    if (!onChange) return

    let v = this.getMathML()
    const { questionConfiguration: q } = this.props
    if (isPopupEditor(q) && cleanupPopup(v) === this._valueAtInit) v = ''

    onChange(v)
  }, 100, { maxWait: 300 })

  render () {
    return <div id='studentAnswer' className='studentAnswerReady'>
      <span />
    </div>
  }
}

function closeEditor (editor) {
  if (!editor || typeof editor.close !== 'function') return
  editor.close()
}
