import React from 'react'
import PropTypes from 'prop-types'
import { Time } from '../LecturesExerciseItem/styled'
import { getTimeFromSeconds } from '../../utilities/dateTimeUtils'

const LectureItemTime = props => {
  // remainingTime = duration - progress on this video, in seconds
  const { duration, remainingTime, optionalVideo } = props
  const formattedTime = getTimeFromSeconds(remainingTime)
  // lecture completed
  if (
    remainingTime === 0
  ) return <Time>Completed</Time>
  // lecture is optional label
  const optional = optionalVideo ? ' · Optional' : null
  // lecture doesn't start
  if (remainingTime === duration) {
    return <Time>{formattedTime} mins{optional}</Time>
  }
  // lecture start but not complete
  return <Time>{formattedTime} mins remaining{optional}</Time>
}

LectureItemTime.propTypes = {
  duration: PropTypes.number.isRequired,
  remainingTime: PropTypes.number
}

export default LectureItemTime
