import { useEffect } from 'react'

export function useDetectOutsideRefEvent (ref, eventHandler) {
  useEffect(() => {
    function onClickOutsideRef (event) {
      if (ref.current && !ref.current.contains(event.target)) {
        eventHandler()
      }
    }
    document.addEventListener('mousedown', onClickOutsideRef)
    return () => {
      document.removeEventListener('mousedown', onClickOutsideRef)
    }
    // eslint-disable-next-line
  }, [ref])
}
