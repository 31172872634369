import React, { useCallback, useContext } from 'react'
import Context from '../Context/Context'
import {
  NotificationCard,
  NotificationCardHeader,
  NotificationCardTitle,
  NotificationCardUnseenIcon,
  NotificationCardBody,
  Link,
  NotificationCardFooter,
  NotificationFooterText,
  Dot
} from './styles'
import { emitter } from '../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'

const Notification = React.memo(({
  announcement,
  setShowModal
}) => {
  const { id, title, seen, body, date, name, course, link } = announcement
  const { updateContext } = useContext(Context)

  const getAnnouncementBody = (body) => {
    if (body.length > 100) return `${body.slice(0, 100)}...`
    return body
  }

  const handleOnClick = useCallback(() => {
    emitter.emit(ON_NAVIGATE_TO, '/announcements')
    updateContext({ selectedAnnouncementId: id })
    setShowModal(false)
  }, [id, updateContext, setShowModal])

  return (
    <NotificationCard
      id={`announcement-${id}`}
      onClick={handleOnClick}
    >
      <NotificationCardHeader>
        <NotificationCardTitle>{title}</NotificationCardTitle>
        {!seen && <NotificationCardUnseenIcon />}
      </NotificationCardHeader>
      <NotificationCardBody>
        {getAnnouncementBody(body)}
        {link && (
          <Link
            onClick={e => e.stopPropagation()}
            href={link?.href} target='_blank'
          >
            {link?.text}
          </Link>
        )}
      </NotificationCardBody>
      <NotificationCardFooter>
        <NotificationFooterText>
          {date}
          <Dot />
          {name}
        </NotificationFooterText>
        <NotificationFooterText>{course}</NotificationFooterText>
      </NotificationCardFooter>
    </NotificationCard>
  )
})

Notification.displayName = 'Notification'
export default Notification
