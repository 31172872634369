import { GGU_ORIENTATION_LINK } from '../Constants'
import { isOrientationChapter } from './chapterUtils'

export const getSkooliTutoringTexts = courseName => {
  if ([
    'philosophy',
    'philosophy.plus',
    'collegewriting-i',
    'collegewriting-i.plus',
    'collegewriting-ii'
  ].includes(courseName)) {
    return {
      title: 'Writing Support',
      text: 'Submit a draft and get expert feedback.'
    }
  }

  return {
    title: 'Tutoring',
    text: 'Access free tutoring help, available 24/7.'
  }
}

export const getOrientationUrl = ({ courseChapters, isGGUCohort, profCert }) => {
  if (profCert) {
    const orientationChapter = courseChapters?.find(chapter =>
      isOrientationChapter(chapter)
    )?.sections[0]
    const { section_uuid: orientationChapterUUID } = orientationChapter || {}
    return `#/${orientationChapterUUID}`
  }

  if (isGGUCohort) {
    return GGU_ORIENTATION_LINK
  }

  return '#/resources/orientation'
}
