import React from 'react'
import { Collapse } from 'reactstrap'

import QuizSectionExcerciseList from '../QuizSectionExcerciseList/QuizSectionExcerciseList'
import { ActivitiesWrapper } from './styled'

function QuizSectionItem (props) {
  return (
    <>
      <Collapse isOpen={props.isAccordionOpen}>
        <ActivitiesWrapper>
          <QuizSectionExcerciseList {...props} />
        </ActivitiesWrapper>
      </Collapse>
    </>
  )
}

export default QuizSectionItem
