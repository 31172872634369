import styled from 'styled-components'
import mediaqueries, { BREAKPOINTS } from '../../../mediaQueries'

export const MainWrapper = styled.div`
  width: ${props => props.isSidePanelOpen && 'calc(100% - 264px)'};
  height: 265px;
  margin-top: ${({ isALRedesign }) => isALRedesign ? '54px' : '86px'};

  ${mediaqueries.tablet`
    ${({ isALRedesign }) => isALRedesign && `
      padding : 0px 38px;
    `}
  `}

  ${mediaqueries.mobile`
    ${({ isALRedesign }) => isALRedesign && `
      padding : 0px 24px;
    `}
  `}
  
`

export const QuestionIntroUI = styled.div`
  max-width: 500px;
  margin: auto;
  font-weight: 300;
  font-size: 18px;
  line-height: 21.6px;
  @media (max-width: ${BREAKPOINTS.mobile - 1}px) {
    max-width: ${props => props.isAssessment && '327px'}
  }

  ${mediaqueries.tablet`
    ${({ isALRedesign }) => isALRedesign && `
      margin : 0px;
      max-width: 100%;
    `}
  `}
  
  @media (max-width: ${BREAKPOINTS.desktop - 1}px) {
    max-width: ${props => props.isSidePanelOpen && '327px'}
  }
`

export const PageTitle = styled.div`
  font-size: 36px;
  line-height: 43.2px;
  min-height: 43px;
  margin-bottom: 24px;
  ${({ isActiveLearning }) => isActiveLearning && `
    width : 100%;
    height: auto;
  `}
`
export const ButtonDiv = styled.div`
  margin-top: 48px;
  button {
    width: 150px;
    height: 40px;
    ${({ isActiveLearning }) => isActiveLearning && `
      width: 64px;
    `}

    ${mediaqueries.mobile`
      ${({ isActiveLearning }) => isActiveLearning && `
        width: 150px;
      `}
    `}
}
img {
  margin-bottom: 2px;
}
`
export const ALSetIndicator = styled.div`
  text-transform: uppercase;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  letter-spacing: 1px;
`

export const ReviewActionLink = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5FC4B8;
  padding-bottom: 24px;
  cursor: pointer;

  &:disabled {
    color: #b8fff7;
    opacity: 0.3;
  }
  &:not(:disabled) {
    &:active {
      color: #b8fff7;
      opacity: 0.3;
    }
    &:hover {
      color: #b8fff7;
    }
  }
`
