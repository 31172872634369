import ModalComponent from '../ModalComponent/ModalComponent'
import { ButtonContainer, ModalText, ModalTitleText } from './style'
import { PrimaryButton } from '../Modals/style'
import React, { useEffect, useState } from 'react'

const AIViolationModal = ({
  showAIViolationModal,
  setShowAIViolationModal,
  showKickMessage,
  showOutlierTabsMessage
}) => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    setShow(showAIViolationModal)
  }, [showAIViolationModal])
  const closeModal = () => {
    if (setShowAIViolationModal) {
      setShowAIViolationModal(false)
    } else {
      setShow(false)
      window.open('about:blank', '_self')
    }
  }

  const modalText = showOutlierTabsMessage
    ? 'You are not allowed to reference Outlier course materials or access any ' +
      'outside resources during exams. Continuing away from the exam window will' +
      ' result in you receiving a 0% on this exam.'
    : !showKickMessage
      ? 'You are not allowed to reference Outlier course materials or access any ' +
      'outside resources during exams. Continuing away from the exam window ' +
      'will result in you receiving a 0% on this exam.'
      : 'You have violated Outlier’s Academic Integrity Policy by referencing ' +
      'Outlier course materials or accessing outside resources during this exam.' +
      ' The exam will now close and the Academic Integrity committee will review' +
      ' your exam session.'

  return (
    <ModalComponent
      show={show}
      disableHideOnClick
      hideClose
    >
      <ModalTitleText>Academic Integrity Violation</ModalTitleText>
      <ModalText>
        {modalText}
      </ModalText>
      <ButtonContainer>
        <PrimaryButton
          onClick={() => {
            closeModal()
          }}
        >I UNDERSTAND</PrimaryButton>
      </ButtonContainer>
    </ModalComponent>
  )
}

export default AIViolationModal
AIViolationModal.displayName = 'AIViolationModal'
