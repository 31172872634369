import styled from 'styled-components'

export const DownArrow = styled.span`
  width: 6px;
  height: 12px;
  transform: ${props =>
    props.isIconClicked ? 'rotate(90deg)' : 'rotate(270deg)'};
  background-color: #ffffff;
  mask: url(../images/icons/icon-angle-left-arrow.svg);
`

export const ViewAllWrapper = styled.div`
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  padding-top: ${props => props.paddingTop}px;
  display: inline-block;

  span {
    margin-left: 12px;
  }
`
