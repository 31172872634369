import React, { Component } from 'react'
import config from '../../config'
import { InstructorNameWrapper, LinkWrapper, Hr, Title } from './styles'
import InstructorBioModal from '../InstructorBioModal/InstructorBioModal'
import ChangeInstructorModal from '../ChangeInstructorModal/ChangeInstructorModal'
import { getCurrentInstructorDetails } from '../../utilities/chapterUtils'

class InstructorListItem extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showReadBioModal: false,
      showChangeInstructorModal: false,
      instructorDetail: ''
    }
  }

  setInstructorReadBioModalShow = showReadBioModal => {
    this.setState({ showReadBioModal })
  }

  setChangeInstructorModalShow = showChangeInstructorModal => {
    this.setState({ showChangeInstructorModal })
  }

  setInstructorDetail = InstructorId => {
    const { Instructors } = this.props
    const instructorDetail = getCurrentInstructorDetails({
      currentInstructorID: InstructorId,
      lectureVideos: Instructors
    })
    this.setState({ instructorDetail })
  }

  showReadBioModal = (selectedInstructorId) => {
    this.setInstructorDetail(selectedInstructorId)
    this.setInstructorReadBioModalShow(true)
  }

  onChangeInstructor = selectedInstructorId => {
    this.props.onInstructorSwitch(selectedInstructorId)
    this.setChangeInstructorModalShow(false)
    this.state.showReadBioModal && this.setInstructorReadBioModalShow(false)
  }

  render () {
    const {
      instructorDetail,
      showChangeInstructorModal,
      showReadBioModal
    } = this.state

    const {
      Instructor,
      Instructors,
      isChapterOrientation,
      currentInstructor
    } = this.props
    const { isCalculusCourse } = config
    const isCalculus = isCalculusCourse()

    return (
      <>
        {showChangeInstructorModal &&
          <ChangeInstructorModal
            show={showChangeInstructorModal}
            handleClose={() => this.setChangeInstructorModalShow(false)}
            headerContent='Select your lecturer'
            footerShow={isCalculus}
            onChangeInstructor={this.onChangeInstructor}
            calculusInstructorsList={Instructors}
            currentInstructorID={currentInstructor}
            showReadBioModal={this.showReadBioModal}
          />
        }
        {showReadBioModal &&
          <InstructorBioModal
            show={showReadBioModal}
            handleClose={() => this.setInstructorReadBioModalShow(false)}
            content={instructorDetail}
            headerContent={isCalculus ? 'BACK' : 'Meet your lecturer'}
            footerShow={isCalculus}
            onChangeInstructor={this.onChangeInstructor}
            currentInstructorID={currentInstructor}
          />
        }
        <InstructorNameWrapper data-cy='InstructorName'>
          <span>
            {Instructor.name}
          </span>
          {isChapterOrientation ? '' : isCalculus
            ? <>
              <Title>"{Instructor.theme_name}"</Title>
              <LinkWrapper
                onClick={() => this.setChangeInstructorModalShow(true)}
                data-cy='changeInstructorModalLink'
              >
                CHANGE LECTURER
              </LinkWrapper>
            </>
            : <LinkWrapper onClick={() => this.showReadBioModal(Instructor.instructor_uuid)}>
              READ BIO
            </LinkWrapper>
          }
        </InstructorNameWrapper>
        <Hr />
      </>
    )
  }
}

export default InstructorListItem
