import styled from 'styled-components'
import mediaqueries, { BREAKPOINTS } from '../../../mediaQueries'

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ isALRedesign }) => !isALRedesign ? '31px' : '42px'};
  flex-wrap: wrap;

  .btn {
    width: 150px;
    margin: 6px 4px;
    margin-right: 8px;
    height: 40px;
    padding: 0px 8px;
  }

  .btn-secondary:disabled {
    background-color: #161618;
    border-color: #77b1af;
    color: #77b1af;
  }

  ${({ isAssessmentQuizExam, isALRedesign, isAnsweringDisabled }) =>
    (isAssessmentQuizExam || isALRedesign) && `
    justify-content: flex-start;
    margin-top: ${isAnsweringDisabled ? '43px' : ''};

    .btn-secondary:disabled {
      background-color: #161618;
      border-color: #77b1af;
      color: #5FC4B8;
    }

    ${isALRedesign && `
      .btn-primary:disabled {
        background-color: #161618;
        border-color: #77b1af;
        color: #5FC4B8;
        opacity: 50%;
      }
    `}

    .btn-primary {
      background-color: #5FC4B8;
    }

    .btn {
      width: 64px;
      height: 48px;
      border: #5FC4B8 1px solid;
    }

    .btn.text-btn {
      width: unset;
      line-height: 16px;
      padding: 16px 32px;
    }
  `}

  ${mediaqueries.mobile`
    ${({ isALRedesign }) => !isALRedesign && `
      .btn {
        width: 100%;
      }
    `}
    
    ${({ isAssessmentQuizExam, reviewMode, isFirstQuestion, showfinish }) =>
    isAssessmentQuizExam && `
        .btn {
          width: ${reviewMode ? '157px' : '100%'};

          :first-child {
            margin-left: 0px !important;
            margin-right: ${reviewMode ? '12px !important' : '0px'};
          }

          :nth-child(2) {
            margin-left: 0px !important;
            margin-right: 0px !important;
          }
        }
      `}
  `}

  @media (max-width: ${BREAKPOINTS.mobile - 1}px) {
    .btn {
      width: 100% !important;
    }

    ${({ isALRedesign }) => isALRedesign && `
      .btn {
        flex-grow: 1 !important;
        max-width: 157px !important;

        :first-child {
          margin-left: 0px !important;
          margin-right: 12px !important;
        }

        :nth-child(2) {
          margin-left: 0px !important;
          margin-right: 0px !important;
        }  
      }      
    `}
  }
`

export const TextWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 48px;
  display: flex;
  height: 19px;
  justify-content: space-between;
  ${({ isALRedesign }) => isALRedesign && `
    align-items: center;
    height: 70px; 
    padding: 0px 24px; 
    margin-top: 0px;
    margin-bottom: 1px;
    border-radius: 5px 5px 0px 0px;
    background: #121212;
  `}
`
export const QuestionIconWrapper = styled.div`
  display: inline-flex;
`

export const Icon = styled.img`
  width: inherit;
  height: inherit;
  vertical-align: middle;
  border-style: none;
  margin-bottom: 18px;
`

export const QuestionText = styled.span`
  line-height: 19.2px;
  font-size: 16px;
  margin-left: ${({ isALRedesign }) => !isALRedesign ? '12px' : '0px'};
`
export const PinWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  ${({ isALRedesign }) => isALRedesign && 'align-items: center;'};
`
export const PinText = styled.span`
  max-width: 93px;
  font-size: 16px;
  line-height: 19.2px;
  color: #98a4ae;
  text-align: right;
`
export const AdaptationStatement = styled.div`
  ${({ isALRedesign }) => isALRedesign && `
    text-align: left !important;
    .paragraph {
      margin-top: 42px !important;
      line-height: 16.8px !important;
    }
  `}
`
export const PinButtonWrapper = styled.span`
  width: 36px;
  height: 36px;
  margin-top: -6px;
  ${({ isALRedesign }) => isALRedesign && `
    display: flex;
    align-items: center;
    width: 26px;
    height: 19.95px;
    margin-top: 0px;
  `}

`
export const PinButton = styled.img`
  margin-left: 12px;
  margin-bottom: 2px;
  width: 14px;
  height: 14px;
`

export const QuestionCard = styled.div`
  ${props => props.isAssessmentQuizExam && `
    background-color: rgba(0, 0, 0, 0);
    padding: 0px !important;
  `};
  ${({ isALRedesign }) => isALRedesign && `
    background-color: #121212;
    padding: 24px 24px 12px 24px !important;
    border-radius: 0px 0px 5px 5px !important;
  `}
`
export const LessonText = styled.div`
  
  ${({ isALRedesign }) => isALRedesign && `  
    padding-bottom: 16px !important;
    h3 {
      font-size: 24px !important;
      font-weight: 300;
      line-height: 29px;
    }
  `}
  ${mediaqueries.mobile`
    img {
      max-width: 100%;
    }
  `}
`
export const QuestionTextAL = styled.div`
  ${({ isALRedesign }) => isALRedesign && `  
    padding-top: 0px !important;
    .paragraph{
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 24px !important;
    }
  `}
`
export const FadeIn = styled.div`
${({ showFeedback, hideCorrectIndicator }) => hideCorrectIndicator && `
  opacity: ${showFeedback ? '1' : '0'};
  transition: opacity .6s ease-in;
`}
`
