import React, { useState, useEffect, useContext } from 'react'
import { useDebounce } from '../../utilities'
import { words } from 'lodash'
import {
  TextEditorContainer,
  TextEditorLabel,
  SyncState,
  WordCount,
  Editor
} from './styles'
import Context from '../Context/Context'
import { syncStates } from '../../Constants/textEditor'

export default function TextEditor ({
  isDisabled = false,
  setActiveInput,
  setText,
  onSave,
  textContent,
  onEmpty,
  isResubmitPage
}) {
  const context = useContext(Context)
  const [value, setValue] = useState('')
  const syncState = context.textEntryAssignmentState
  const [wordsCount, setWordsCount] = useState(0)

  useEffect(() => {
    if (!textContent) return
    setValue(textContent)
    setActiveInput('TEXTENTRY')
    setWordsCount(words(textContent).length)
    // eslint-disable-next-line
  }, [textContent])

  useEffect(() => {
    return () => {
      /* to prevent 'status: draft' from being saved
      after Submit button is clicked */
      syncBackend.cancel()
    }
    // eslint-disable-next-line
  }, [])

  const setSyncState = state => {
    context.updateContext({ textEntryAssignmentState: state })
  }

  const handleEditorChange = (e) => {
    setActiveInput('TEXTENTRY')
    const content = e.target.value
    if (!isResubmitPage) {
      setSyncState(content ? syncStates.SAVING : syncStates.SAVED)
      syncBackend(content)
    }
    setValue(content)
    setText(content)
    setWordsCount(words(content).length)
    if (!content.trim().length) {
      return onEmpty()
    }
  }

  const syncBackend = useDebounce(async (content) => {
    if (!content) return
    const textSaved = await onSave(content)
    if (!textSaved) return setSyncState(syncStates.NOT_SAVED)

    setSyncState(syncStates.SAVED)
  }, 1000)

  return (
    <TextEditorContainer isDisabled={isDisabled}>
      <TextEditorLabel>
        <p>Text Entry</p>
        {isDisabled && (<p>*To submit a text entry, remove the file above.</p>)}
      </TextEditorLabel>
      {!isDisabled && (
        <SyncState>
          {syncState === syncStates.SAVED && <span>Draft Saved</span>}
          {syncState === syncStates.SAVING && <span>Saving....</span>}
        </SyncState>
      )}
      <Editor
        value={value}
        onChange={handleEditorChange}
        data-testid='assignmentTextBox'
      />
      <WordCount data-testid='words-count'>{wordsCount} words</WordCount>
      <div id='text-editor-overlay' />
    </TextEditorContainer>
  )
}
