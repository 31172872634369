import styled from 'styled-components'

export const ChecklistIcon = styled.div`
  background-color: ${({ color }) => (color ? `${color}` : '#ffffff')};
  -webkit-mask-image: url("${({ src }) => src}");
  -webkit-mask-position: right;
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  ${({ flex }) => flex && 'flex: 1;'}
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  mask-image: url("${({ src }) => src}");
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`};
`

export const ChecklistContainer = styled.div`
  display: inherit;
  align-items: center;
`

export const ChecklistIconWrapper = styled.div`
  display: inherit;
  align-items: center;
  color: ${({ color }) => color};
`

export const ChecklistIconContainer = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #b1bfc5;
  display: block;

  & span {
    display: flex;
    align-items: center;
  }
  & .activityCount::after {
    content: "";
    width: 2px;
    display: ${({ hasAssessment }) => (hasAssessment ? 'block' : 'none')};
    height: 2px;
    background: #98a4ae;
    border-radius: 100%;
    margin-right: 8px;
    margin-left: 8px;
  }
`

export const CardWrapper = styled.div`
  :first-child {
    margin-top: 28px;
  }
`

export const Card = styled.div`
  background: #33373e;
  width: 327px;
  height: ${({ hasCaughtUp }) => (hasCaughtUp ? '94px' : '112px')};
  border-radius: 5px;
  margin: auto;
  margin-bottom: 18px;
  padding: 24px;
  cursor: pointer;

  & > h6 {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
    line-height: 19px;
    color: #ffffff;
  }
`

export const ChecklistIconContainerChildWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const IsCaughtUp = styled.p`
  display: flex;
  gap: 8px;
  font-size: 16px;
  font-weight: normal;
  margin: 0px;
  line-height: 19px;
  color: #969bff;
`

export const CourseStartDate = styled.p`
  color: #b1bfc5;
  font-weight: normal;
  margin: 0px;
  line-height: 19px;
  font-size: 16px;
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`
