import React, { useEffect, useState } from 'react'
import api from '../../../api'
import { NotificationHeader, NotificationDetailsWrapper } from '../style'
import NotificationList from './NotificationList'

const Notifications = ({ courseContext }) => {
  const [notifications, setNotifications] = useState({})
  const {
    studentCourses,
    cohortData: { cohortID }
  } = courseContext

  useEffect(() => {
    const getAllNotifications = async () => {
      if (!cohortID) return

      const allNotifications = await api.getAllNotifications(cohortID)
      if (!allNotifications) return

      setNotifications(allNotifications)
    }

    getAllNotifications()
  }, [cohortID])

  return (
    <>
      <NotificationHeader>Notifications</NotificationHeader>
      <NotificationDetailsWrapper>
        <NotificationList
          notifications={notifications}
          studentCourses={studentCourses}
        />
      </NotificationDetailsWrapper>
    </>
  )
}

export default Notifications
