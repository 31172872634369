export const notificationsData = {
  data: [
    {
      id: 1,
      headline: 'You have a new cohort starting',
      additionalDetails:
        'Notification text. This is how long they generally will be.',
      actions: [
        {
          websiteUrl: 'https://action.com',
          actionText: 'Action'
        }
      ],
      createdAt: 1623668400000,
      scheduleAt: 'Time the notification was created',
      courses: ['Introduction to Psychology', 'Calculus I'],
      recipients: ['cohortId1', 'cohortId2'],
      statuses: ['Enrolled', 'Completed'],
      students: ['studentId1', 'studentId2']
    },
    {
      id: 2,
      headline: 'Short headline',
      additionalDetails:
        'Notification text. This is how long they generally will be.',
      actions: [
        {
          websiteUrl: 'https://action.com',
          actionText: 'Action 1'
        },
        {
          websiteUrl: 'https://action.com',
          actionText: 'Action 2'
        }
      ],
      createdAt: 1623729600000,
      scheduleAt: 'Time the notification was created',
      courses: ['Introduction to Philosophy', 'Introduction to Psychology'],
      recipients: ['cohortId1', 'cohortId2'],
      statuses: ['Enrolled', 'Completed'],
      students: ['studentId1', 'studentId2']
    },
    {
      id: 3,
      headline: 'Short headline',
      additionalDetails:
        'Notification text. This is how long they generally will be.',
      actions: [
        {
          websiteUrl: 'https://action.com',
          actionText: 'Longer Action 1'
        },
        {
          websiteUrl: 'https://action.com',
          actionText: 'Longer Action 2'
        }
      ],
      createdAt: 1623556800000,
      scheduleAt: 'Time the notification was created',
      courses: ['Calculus I - FP', 'Introduction to Psychology'],
      recipients: ['cohortId1', 'cohortId2'],
      statuses: ['Enrolled', 'Completed'],
      students: ['studentId1', 'studentId2']
    },
    {
      id: 4,
      headline: 'Another headline',
      additionalDetails:
        'Notification text. This is how long they generally will be.',
      actions: [
        {
          websiteUrl: 'https://action.com',
          actionText: 'Longer Action 1'
        },
        {
          websiteUrl: 'https://action.com',
          actionText: 'Action 2'
        },
        {
          websiteUrl: 'https://action.com',
          actionText: 'Longer Action 3'
        }
      ],
      createdAt: 1623582000000,
      scheduleAt: 'Time the notification was created',
      courses: ['Calculus I - FP', 'Introduction to Psychology'],
      recipients: ['cohortId1', 'cohortId2'],
      statuses: ['Enrolled', 'Completed'],
      students: ['studentId1', 'studentId2']
    },
    {
      id: 5,
      headline: 'Another headline',
      additionalDetails:
        'Notification text. This is how long they generally will be.',
      actions: [
        {
          websiteUrl: 'https://action.com',
          actionText: 'Longer Action 1'
        },
        {
          websiteUrl: 'https://action.com',
          actionText: 'Action 2'
        },
        {
          websiteUrl: 'https://action.com',
          actionText: 'Longer Action 3'
        }
      ],
      createdAt: 1623582000000,
      scheduleAt: 'Time the notification was created',
      courses: ['Calculus I - FP', 'Introduction to Psychology'],
      recipients: ['cohortId1', 'cohortId2'],
      statuses: ['Enrolled', 'Completed'],
      students: ['studentId1', 'studentId2']
    },
    {
      id: 6,
      headline: 'Another headline',
      additionalDetails:
        'Notification text. This is how long they generally will be.',
      actions: [
        {
          websiteUrl: 'https://action.com',
          actionText: 'Longer Action 1'
        },
        {
          websiteUrl: 'https://action.com',
          actionText: 'Action 2'
        },
        {
          websiteUrl: 'https://action.com',
          actionText: 'Longer Action 3'
        }
      ],
      createdAt: 1623582000000,
      scheduleAt: 'Time the notification was created',
      courses: ['Calculus I - FP', 'Introduction to Psychology'],
      recipients: ['cohortId1', 'cohortId2'],
      statuses: ['Enrolled', 'Completed'],
      students: ['studentId1', 'studentId2']
    },
    {
      id: 7,
      headline: 'Another headline',
      additionalDetails:
        'Notification text. This is how long they generally will be.',
      actions: [
        {
          websiteUrl: 'https://action.com',
          actionText: 'Longer Action 1'
        },
        {
          websiteUrl: 'https://action.com',
          actionText: 'Action 2'
        },
        {
          websiteUrl: 'https://action.com',
          actionText: 'Longer Action 3'
        }
      ],
      createdAt: 1623582000000,
      scheduleAt: 'Time the notification was created',
      courses: ['Calculus I - FP', 'Introduction to Psychology'],
      recipients: ['cohortId1', 'cohortId2'],
      statuses: ['Enrolled', 'Completed'],
      students: ['studentId1', 'studentId2']
    },
    {
      id: 8,
      headline: 'Another headline',
      additionalDetails:
        'Notification text. This is how long they generally will be.',
      actions: [
        {
          websiteUrl: 'https://action.com',
          actionText: 'Longer Action 1'
        },
        {
          websiteUrl: 'https://action.com',
          actionText: 'Action 2'
        },
        {
          websiteUrl: 'https://action.com',
          actionText: 'Longer Action 3'
        }
      ],
      createdAt: 1623582000000,
      scheduleAt: 'Time the notification was created',
      courses: ['Calculus I - FP', 'Introduction to Psychology'],
      recipients: ['cohortId1', 'cohortId2'],
      statuses: ['Enrolled', 'Completed'],
      students: ['studentId1', 'studentId2']
    },
    {
      id: 9,
      headline: 'Another headline',
      additionalDetails:
        'Notification text. This is how long they generally will be.',
      actions: [
        {
          websiteUrl: 'https://action.com',
          actionText: 'Longer Action 1'
        },
        {
          websiteUrl: 'https://action.com',
          actionText: 'Action 2'
        },
        {
          websiteUrl: 'https://action.com',
          actionText: 'Longer Action 3'
        }
      ],
      createdAt: 1623582000000,
      scheduleAt: 'Time the notification was created',
      courses: ['Calculus I - FP', 'Introduction to Psychology'],
      recipients: ['cohortId1', 'cohortId2'],
      statuses: ['Enrolled', 'Completed'],
      students: ['studentId1', 'studentId2']
    }
  ]
}
