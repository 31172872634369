import React, { Component } from 'react'
import { ProgressButton } from './style'
import {
  POPUP_ICON,
  POPUP_ACTIVE_ICON,
  POPUP_INCORRECT_ICON,
  POPUP_COMPLETED_ICON,
  COMPLETED_ICON,
  ACTIVE_ICON,
  INCORRECT_ICON,
  CHALLENGE_ICON,
  EMPTY_ICON
} from '../../Constants/progressBarIconSource'

class ButtonPractice extends Component {
  render () {
    const {
      btnClass,
      keyId
    } = this.props

    let icon
    if (btnClass.includes('bubble_border')) {
      if (btnClass.includes('completed')) {
        icon = POPUP_COMPLETED_ICON
      } else if (btnClass.includes('active')) {
        icon = POPUP_ACTIVE_ICON
      } else if (btnClass.includes('incorrect')) {
        icon = POPUP_INCORRECT_ICON
      } else {
        icon = POPUP_ICON
      }
    } else if (btnClass.includes('completed')) {
      icon = COMPLETED_ICON
    } else if (btnClass.includes('incorrect')) {
      icon = INCORRECT_ICON
    } else if (btnClass.includes('active')) {
      icon = ACTIVE_ICON
    } else if (btnClass.includes('bubble_star')) {
      icon = CHALLENGE_ICON
    } else {
      icon = EMPTY_ICON
    }

    return <ProgressButton
      id={keyId}
      src={icon}
      data-cy='practiceProgressBar'
    />
  }
}

export default ButtonPractice
