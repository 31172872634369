import React, { Component } from 'react'
import './css/head-foot.css'
import Context from '../Context/Context'
import { emitter } from '../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'
import { getBreadCrumbText } from './Utils/BreadCrumbUtils'

import {
  BreadCrumbContainer,
  BreadCrumbWrapper,
  Caret,
  Pagetitle
} from './styles'
import { ACTIVE_LEARNING, EXAM, QUIZ } from '../../Constants/sectionType'
import {
  setChapterNumber
} from '../../utilities/courseUtils'
import { CODING_ASSIGNMENT_PATH, WRITING_ASSIGNMENT_PATH } from '../../Constants'

class BreadCrumbComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  getBreadCrumbAndPageTitle = () => {
    const { screen, isAnnouncements } = this.props
    const reviewMode = this.context.reviewMode

    // Announcements page
    if (isAnnouncements) {
      return ({
        breadcrumb: 'Back To [Activity/Page]'
      })
    }

    // Homepage
    if (!screen?.children) {
      return ({
        breadcrumb: ''
      })
    }

    const childIndex = this.props?.childIndex ?? -1
    // Section Landing Page
    if (childIndex === -1) {
      return ({
        breadcrumb: 'Course Home',
        url: `/${screen.breadcrumbArr[0][1]}`
      })
    }

    // Extension Page
    if (screen.type === 'extension-request') {
      return ({
        marginBottom: { mobile: 1, desktop: 1, small: 1 },
        breadcrumb: 'Course Home',
        url: `/${screen.breadcrumbArr[0][1]}`
      })
    }

    const {
      breadcrumbArr,
      quest_type: questType,
      data,
      type
    } = screen.children[childIndex]
    const breadCrumbLength = screen?.breadcrumbArr?.length ?? 0
    const baseTitle = screen.breadcrumbArr[breadCrumbLength - 1][0]
    const baseURL = `/${screen.breadcrumbArr[breadCrumbLength - 1][1]}`
    const workType = data?.worktype
    let breadcrumb = ''
    let url = ''
    let pageTitle = ''
    let marginBottom
    let promptBeforeLeaving = false

    switch (type) {
      // Landing Page - Guesswork, Active Learning, Practice Exercise, Quiz
      case 'intertitial':
        breadcrumb = baseTitle
        url = baseURL
        pageTitle = breadcrumbArr[0][0]
        marginBottom = { mobile: 28, desktop: 28 }
        break
      case 'question': // Questions Page - Guesswork, Active Learning, Practice Exercise
      case 'question_problem_bank': // Questions Page - Practice Exercise (Statistics)
        if (questType === 'Practice Exercises') {
          // Practice Exercise
          pageTitle = breadcrumbArr[0][0]
          breadcrumb = baseTitle
          url = baseURL
        } else {
          // Guesswork, Active Learning
          pageTitle = breadcrumbArr[0][0]
          breadcrumb = baseTitle
          url = baseURL
        }
        marginBottom = { mobile: 36, desktop: 24 }
        break
      // Questions Page - Quiz
      case 'question_quiz':
        breadcrumb = baseTitle
        url = baseURL
        pageTitle = breadcrumbArr[0][0]
        marginBottom = { mobile: 36, desktop: 24 }
        promptBeforeLeaving = !reviewMode
        break
      // Completion Page - Guesswork, Active Learning, Quiz
      case 'end_content_intertitial_section':
        marginBottom = { mobile: 98, desktop: 98 }
        break
      // Practice Exercise Listings page
      case 'problem_set':
        pageTitle = breadcrumbArr[0][0]
        breadcrumb = baseTitle
        url = baseURL
        marginBottom = { mobile: 43, desktop: 31 }
        break
      // Exams
      case 'question_exam':
        pageTitle = breadcrumbArr[0][0]
        breadcrumb = reviewMode ? 'Course Home' : ''
        url = `/${screen.breadcrumbArr[0][1]}`
        marginBottom = { mobile: 36, desktop: 24 }
        break
      case WRITING_ASSIGNMENT_PATH:
      case CODING_ASSIGNMENT_PATH:
        pageTitle = breadcrumbArr[0][0]
        breadcrumb = 'Course Home'
        url = `/${screen.breadcrumbArr[0][1]}`
        marginBottom = { mobile: 36, desktop: 24 }
        break
      case 'reading':
        breadcrumb = baseTitle
        url = baseURL
        break
      default:
        // Lecture
        pageTitle = breadcrumbArr[0][0]
        breadcrumb = baseTitle
        url = baseURL
    }

    return ({
      breadcrumb,
      url,
      marginBottom,
      pageTitle,
      questType,
      type,
      workType,
      promptBeforeLeaving
    })
  }

  render () {
    const { screen, wrapperMarginTop, isAnnouncements, className } = this.props
    const { courseData, reviewMode } = this.context
    const courseWithChapterNumbers = setChapterNumber(courseData)
    const { chapters } = courseWithChapterNumbers
    if (screen?.hideBreadcrumb) return null

    const {
      breadcrumb,
      marginBottom,
      pageTitle = '',
      promptBeforeLeaving,
      type,
      questType,
      workType,
      url
    } = this.getBreadCrumbAndPageTitle()
    const isAssessmentQuizExam = !reviewMode &&
    (pageTitle === QUIZ || pageTitle === EXAM)
    const isActiveLearning = pageTitle === ACTIVE_LEARNING
    const isQuizExamOrAL = isAssessmentQuizExam || isActiveLearning
    const isPendingResponseAnswer = reviewMode &&
    [QUIZ, EXAM].includes(questType || workType)

    const link = () => {
      if (isAnnouncements) return window.history.back()
      if (!url) return

      if (promptBeforeLeaving && pageTitle === QUIZ) {
        const action = window.confirm('Changes that you made may not be saved.')
        return action ? emitter.emit(ON_NAVIGATE_TO, url) : null
      }
      return emitter.emit(ON_NAVIGATE_TO, url)
    }

    return (
      <BreadCrumbContainer
        className={`container${className ? ` ${className}` : ''}`}
        id='breadcrumb-container'
        marginBottom={marginBottom}
        isActiveLearning={isActiveLearning}
        isAssessment={isAssessmentQuizExam || isPendingResponseAnswer}
        isAnnouncements={isAnnouncements}
      >
        {breadcrumb && (
          <BreadCrumbWrapper
            id='breadcrumb-wrapper'
            tabIndex={0}
            onClick={link}
            marginTop={wrapperMarginTop}
            isActiveLearning={isActiveLearning}
            isAssessment={isAssessmentQuizExam || isPendingResponseAnswer}
            data-cy='BreadCrumb' data-uuid={url}>
            <Caret />
            {isQuizExamOrAL ||
            (isPendingResponseAnswer && type === 'intertitial')
              ? getBreadCrumbText(breadcrumb, this.props, chapters)
              : (isPendingResponseAnswer && type !== 'intertitial')
                ? 'COURSE HOME' : breadcrumb}
          </BreadCrumbWrapper>
        )}
        {pageTitle && !(isQuizExamOrAL || isPendingResponseAnswer) && (
          <Pagetitle
            id='page-title'
            marginTop={
              breadcrumb
                ? { mobile: 32, desktop: 24 }
                : { mobile: 114, desktop: 128 }
            }>
            {pageTitle}
          </Pagetitle>
        )}
      </BreadCrumbContainer>
    )
  }
}

BreadCrumbComponent.contextType = Context
export default BreadCrumbComponent
