import isEmpty from 'lodash/isEmpty'
import config from '../../../config'
import {
  GUESSWORK,
  LECTURE,
  ACTIVE_LEARNING,
  PRACTICE_EXERCISES,
  READINGS,
  PRACTICE,
  CONCEPT_MAP,
  PROBLEM_BANK,
  QUIZ,
  PRACTICE_TERMS
} from '../../../Constants/sectionType'
import { getQuestionTitle, getQuestionIndexByTheme } from '../../../utilities/questions'
import { getMultipleVideos, getUrl } from '../../../utilities/sectionUtils'
import {
  MULTIPLE_CHOICE,
  TRUE_FALSE,
  TEXT_BLANK,
  AL_MULTIPLE_CHOICE,
  AL_MULTIPLE_CHOICE_WITH_MATH,
  AL_TRUE_FALSE,
  AL_TRUE_FALSE_WITH_MATH,
  AL_CARD,
  AL_CARD_WITH_MATH,
  AL_TEXT_BLANKS,
  CARD
} from '../../../Constants/questionType'
import { getQuizNumberUptoPreviousSection } from '../../../utilities/chapterUtils'

function loadEachSection ({ courseMetadata, studentAnswers, section, courseSections }) {
  return [
    ...loadSectionGuesswork(section),
    ...loadSectionReadings(section),
    ...loadSectionLecture(section),
    ...loadSectionActiveLearning(section),
    ...loadSectionPracticeExercises(section, courseSections),
    ...loadSectionConceptMap(section),
    ...loadSectionPracticeTerms(section),
    ...loadSectionPracticeDrawer(section, courseSections),
    ...loadSectionQuizzes(studentAnswers, section, courseMetadata),
    ...loadSectionEndContentIntertitial(section)
  ]
}

function loadSectionGuesswork (section) {
  const children = []

  const { section_exe: { guesswork } } = section

  if (!guesswork) return children

  const {
    title,
    Question: questions,
    question_set_uuid: questionSetUUID,
    intro_text: introText,
    intro_image: introImage,
    question_set_ending_uuid: questionSetEndingUUID,
    question_set_ending_text: endingText,
    question_set_ending_image: questionSetEndingImage
  } = guesswork

  const questionSetEndingText = endingText || getDefaultQuestionSetEndingText(GUESSWORK)

  const isEndingQuestionSet = questionSetEndingUUID && questionSetEndingText
  children.push({
    uuid: questionSetUUID,
    type: 'intertitial',
    data: {
      intro_text: introText,
      intro_image: introImage,
      worktype: GUESSWORK,
      questions: questions
    },
    breadcrumb: title,
    breadcrumbArr: [[GUESSWORK, questionSetUUID]]
  })

  questions.forEach((q, index) => {
    const questionTitle = getQuestionTitle(q, index, GUESSWORK)
    children.push({
      uuid: q.Question_uuid,
      type: 'question',
      section_ending_uuid: isEndingQuestionSet ? questionSetEndingUUID : null,
      quest_type: GUESSWORK,
      data: questions,
      breadcrumb: title + ' > ' + questionTitle,
      breadcrumbArr: [
        [GUESSWORK, questionSetUUID],
        [questionTitle, q.Question_uuid]
      ]
    })
  })

  if (!isEndingQuestionSet) return children

  children.push({
    uuid: questionSetEndingUUID,
    type: 'end_content_intertitial_section',
    typeSection: 'end_content_intertitial_guesswork',
    data: {
      intro_text: questionSetEndingText,
      section_ending_uuid: questionSetEndingUUID,
      intro_image: questionSetEndingImage
    },
    breadcrumb: title,
    breadcrumbArr: [[GUESSWORK, questionSetEndingUUID]],
    sectionkUUID: questionSetUUID
  })

  return children
}

function loadSectionLecture (section) {
  const children = []

  const { section_exe: sectionTypes } = section
  const { lecture, multi_lecture_videos: multiLectureVideos } = sectionTypes

  if (!lecture && !multiLectureVideos) return children

  // the lecture where we get drupal data like course materials, instructor
  // for the multiple videos
  const mainLecture = getMainLectureInfo(sectionTypes)
  const multipleVideos = getMultipleVideos(sectionTypes)

  if (multipleVideos) {
    return loadMultipleLectureVideos(multipleVideos, mainLecture)
  }

  const { lecturevideos, question_set_uuid: questionSetUUID } = lecture || {}
  if (!lecturevideos?.length) return children

  children.push({
    uuid: questionSetUUID,
    type: 'lecture',
    data: lecture,
    breadcrumb: LECTURE,
    breadcrumbArr: [[LECTURE, questionSetUUID]]
  })

  return children
}

function loadSectionActiveLearning (section) {
  const children = []

  const {
    section_exe: { active_learning: activeLearning }
  } = section

  if (!activeLearning) return children

  const {
    question_set_uuid: questionSetUUID,
    intro_text: introText,
    intro_image: introImage,
    Question: datoCards,
    title,
    question_set_ending_uuid: questionSetEndingUUID,
    question_set_ending_text: endingText,
    adaptationStatement: questionSetAdaptationStatement,
    question_set_ending_image: questionSetEndingImage
  } = activeLearning

  const questionSetEndingText = endingText ||
    getDefaultQuestionSetEndingText(ACTIVE_LEARNING)

  const cards = normalizeALCards(datoCards)
  const isEndingQuestionSet = questionSetEndingUUID && questionSetEndingText

  const questions = addAdaptationStatementForALCards(
    removeIntructorFromALCards(cards), questionSetAdaptationStatement)

  children.push({
    uuid: questionSetUUID,
    type: 'intertitial',
    data: {
      intro_text: introText,
      intro_image: introImage,
      worktype: ACTIVE_LEARNING,
      questions
    },
    breadcrumb: title,
    breadcrumbArr: [[ACTIVE_LEARNING, questionSetUUID]]
  })

  const questionCounterByTheme = {}
  questions.forEach((q, index) => {
    const questionIndexByTheme = getQuestionIndexByTheme(q, questionCounterByTheme)
    const questionTitle = getQuestionTitle(q, questionIndexByTheme, ACTIVE_LEARNING)
    children.push({
      uuid: q.Question_uuid,
      type: 'question',
      section_ending_uuid: isEndingQuestionSet ? questionSetEndingUUID : null,
      quest_type: ACTIVE_LEARNING,
      data: questions,
      breadcrumb: title + ' > ' + questionTitle,
      breadcrumbArr: [
        [ACTIVE_LEARNING, questionSetUUID],
        [questionTitle, q.Question_uuid]
      ]
    })
  })

  if (!isEndingQuestionSet) return children

  children.push({
    uuid: questionSetEndingUUID,
    type: 'end_content_intertitial_section',
    typeSection: 'end_content_intertitial_active',
    data: {
      intro_text: questionSetEndingText,
      section_ending_uuid: questionSetEndingUUID,
      intro_image: questionSetEndingImage
    },
    breadcrumb: title,
    breadcrumbArr: [[ACTIVE_LEARNING, questionSetEndingUUID]],
    sectionkUUID: questionSetUUID
  })

  return children
}

function normalizeALCards (cards) {
  if (!cards) return

  return cards.map(card => {
    const {
      question_type: questionType,
      desmos_graph: desmos } = card

    if (questionType === AL_TRUE_FALSE ||
        questionType === AL_TRUE_FALSE_WITH_MATH) {
      return getTrueFalseCard(card)
    }

    if (questionType === AL_MULTIPLE_CHOICE ||
        questionType === AL_MULTIPLE_CHOICE_WITH_MATH) {
      return getMultipleChoiceCard(card)
    }

    if (questionType === AL_TEXT_BLANKS) return getTextBlanksCard(card)

    const isCardQuestion = questionType === AL_CARD ||
      questionType === AL_CARD_WITH_MATH
    return {
      ...card,
      dato_card_type: questionType,
      question_type: isCardQuestion ? CARD : questionType,
      desmos_graph: desmos || null }
  })
}

function getTrueFalseCard (card) {
  const {
    options,
    answer,
    desmos_graph: desmos,
    question_type: questionType,
    trueFalseOption1Feedback,
    trueFalseOption2Feedback } = card

  const optionsFeedback = [
    trueFalseOption1Feedback,
    trueFalseOption2Feedback]
  const correctAnswer = answer.map(Number)

  const {
    textWhenCorrect,
    textWhenIncorrect } = getFeedbackTexts(
    { options, optionsFeedback },
    correctAnswer,
    false)

  delete card.trueFalseOption1Feedback
  delete card.trueFalseOption2Feedback

  return {
    ...card,
    desmos_graph: desmos || null,
    options: addParagraphTags(options),
    dato_card_type: questionType,
    question_type: TRUE_FALSE,
    text_when_correct: textWhenCorrect,
    text_when_incorrect: textWhenIncorrect
  }
}

function getMultipleChoiceCard (card) {
  const {
    options,
    answer,
    desmos_graph: desmos,
    question_type: questionType,
    mcqOption1Feedback,
    mcqOption2Feedback,
    mcqOption3Feedback,
    mcqOption4Feedback } = card

  const optionsFeedback = [
    mcqOption1Feedback,
    mcqOption2Feedback,
    mcqOption3Feedback,
    mcqOption4Feedback]
  const correctAnswer = answer.map(Number)

  const {
    textWhenCorrect,
    textWhenIncorrect } = getFeedbackTexts(
    { options, optionsFeedback },
    correctAnswer,
    true)

  delete card.mcqOption1Feedback
  delete card.mcqOption2Feedback
  delete card.mcqOption3Feedback
  delete card.mcqOption4Feedback

  return {
    ...card,
    desmos_graph: desmos || null,
    options: addParagraphTags(options),
    dato_card_type: questionType,
    question_type: MULTIPLE_CHOICE,
    text_when_correct: textWhenCorrect,
    text_when_incorrect: textWhenIncorrect
  }
}

function addParagraphTags (options) {
  return options.map(option => {
    return option.includes('<p>')
      ? option : `<p>${option}</p>`
  })
}

function getTextBlanksCard (card) {
  const {
    question_type: questionType,
    desmos_graph: desmos } = card
  return {
    ...card,
    desmos_graph: desmos || null,
    dato_card_type: questionType,
    question_type: TEXT_BLANK
  }
}

function getFeedbackTexts (
  { options, optionsFeedback },
  correctAnswer,
  addEmptyFeedbackForCorrectAnswer) {
  let textWhenCorrect = null
  const textWhenIncorrect = []
  let feedbackText = ''

  options.forEach((option, index) => {
    if (optionsFeedback[index]) {
      feedbackText = `<p>${optionsFeedback[index]}</p>\n`
      if (correctAnswer.includes(index + 1)) {
        textWhenCorrect = feedbackText
        addEmptyFeedbackForCorrectAnswer &&
          textWhenIncorrect.push(
            '<p>You\'re on the right track. Try Again!</p>\r\n')
      } else {
        textWhenIncorrect.push(feedbackText)
      }
    }
  })

  return { textWhenCorrect, textWhenIncorrect }
}

function loadSectionReadings (section) {
  const children = []
  const { section_exe: { readings } } = section

  if (!readings) return children

  readings.forEach((reading, index) => {
    const {
      question_set_uuid: readingUUID, title, author, estimatedReadingTime,
      pdfFile, mobiFile, epubFile, field_tooltip: tooltip,
      infographic, optionalReading
    } = reading

    children.push({
      uuid: readingUUID,
      type: 'reading',
      data: {
        allReadings: readings,
        readingUUID,
        readingNumber: index + 1,
        title,
        author,
        estimatedReadingTime,
        infographic,
        optionalReading,
        tooltip: tooltip || '',
        pdf: getUrl(pdfFile),
        mobi: getUrl(mobiFile),
        epub: getUrl(epubFile)
      },
      breadcrumb: title,
      breadcrumbArr: [[READINGS, readingUUID]]
    })
  })

  return children
}

function loadSectionPracticeExercises (section, courseSections) {
  const children = []

  const {
    section_exe: { practice_exercises: practiceExercises }
  } = section
  if (!practiceExercises) return children

  return loadSectionProblemBank(practiceExercises, courseSections)
}

function loadSectionConceptMap (section) {
  const children = []
  const {
    section_exe: { conceptMap }
  } = section

  if (!conceptMap) return children

  const {
    uuid: readingUUID,
    pdfFile,
    answerKeyPdfFile
  } = conceptMap

  children.push({
    uuid: readingUUID,
    type: 'reading',
    data: {
      allReadings: [{ ...conceptMap, question_set_uuid: readingUUID }],
      isConceptMap: true,
      readingUUID,
      readingNumber: 1,
      title: CONCEPT_MAP,
      author: '',
      estimatedReadingTime: null,
      tooltip: '',
      pdf: getUrl(pdfFile),
      answerKeyPdf: getUrl(answerKeyPdfFile)
    },
    breadcrumb: CONCEPT_MAP,
    breadcrumbArr: [[PRACTICE, readingUUID]]
  })

  return children
}

function loadSectionPracticeTerms (section) {
  const children = []
  const { section_exe: { practice_terms: practiceTerms }, section_uuid: sectionUUID } = section

  if (!practiceTerms) return children

  children.push({
    uuid: 'Quizlet',
    sectionUUID,
    url: practiceTerms.url,
    type: 'practice_term',
    breadcrumb: 'Practice Term',
    breadcrumbArr: [['Practice Term']]
  })
  return children
}

function getPracticeDrawerActivityType (activity = {}) {
  if (!activity) return ''

  switch (activity?.type) {
    case 'LectureVideoRecord':
    case 'MultiLectureVideoRecord':
      return LECTURE

    case PROBLEM_BANK:
      return PROBLEM_BANK

    case 'PracticeTermRecord':
      return PRACTICE_TERMS

    default:
      return ''
  }
}

function loadSectionPracticeDrawer (section, courseSections) {
  const children = []
  const {
    section_exe: { practiceDrawer }, section_uuid: sectionUUID
  } = section

  if (!practiceDrawer?.length) return children

  practiceDrawer.forEach((activity, index) => {
    const isLecture = activity?.type === 'LectureVideoRecord'
    const isMultiLecture = activity?.type === 'MultiLectureVideoRecord'
    const nextActivity = practiceDrawer?.[index + 1]
    const nextActivityType = getPracticeDrawerActivityType(nextActivity)

    if (isLecture || isMultiLecture) {
      const {
        lecturevideos_uuid: uuid, title, type,
        kaltura_embed_code: kalturaEmbedCode, ...rest
      } = activity
      const breadcrumb = title || LECTURE

      children.push({
        uuid,
        type: 'lecture',
        isPracticeDrawer: true,
        data: {
          lecturevideos: [{
            ...activity
          }],
          videos: [{
            id: uuid,
            title,
            displayTitle: title,
            kalturaEmbedCode,
            isPracticeDrawer: true,
            nextActivityType,
            ...rest
          }]
        },
        breadcrumb: LECTURE,
        breadcrumbArr: [[breadcrumb, uuid]],
        isLastVideo: true
      })
    }

    const isProblemBank = activity?.type === PROBLEM_BANK
    if (isProblemBank) {
      const {
        question_set_uuid: questionSetUUID,
        intro_text: introText,
        intro_image: introImageSource,
        question: practiceQuestions,
        general_explanation: generalExplanation,
        title,
        question_set_ending_text: questionSetEndingText,
        question_set_ending_uuid: questionSetEndingUUID,
        question_set_ending_image: questionSetEndingImage
      } = activity

      addGeneralExplanationToEachQuestion(practiceQuestions, generalExplanation)
      const introImage = introImageSource?.url?.toString() || introImageSource || ''
      const { question: Question, intro_image: introImageObj, ...rest } = activity
      const problemBank = [{ Question, intro_image: introImage, ...rest }]

      children.push({
        uuid: questionSetUUID,
        type: 'intertitial',
        data: {
          intro_text: introText,
          intro_image: introImage,
          worktype: PRACTICE_EXERCISES,
          problemBank,
          currentProblemSetUUID: questionSetUUID,
          courseSections
        },
        breadcrumb: PRACTICE_EXERCISES,
        breadcrumbArr: [[PRACTICE_EXERCISES, questionSetUUID]]
      })

      practiceQuestions.forEach((question, index) => {
        children.push({
          uuid: questionSetUUID + '_' + question.Question_uuid,
          type: 'question_problem_bank',
          section_ending_uuid: questionSetUUID + '_end_section',
          quest_type: PRACTICE_EXERCISES,
          data: {
            currentProblemSet: practiceQuestions,
            problemBank,
            currentProblemSetUUID: questionSetUUID,
            courseSections
          },
          breadcrumb: PRACTICE_EXERCISES + ' > ' + title,
          breadcrumbArr: [
            [PRACTICE_EXERCISES, 'practice_exercises'],
            [title, questionSetUUID]
          ],
          questionUUID: question.Question_uuid,
          practiceUUID: questionSetUUID
        })
      })

      children.push({
        uuid: questionSetUUID + '/practice',
        type: 'end_content_intertitial_section',
        typeSection: 'end_content_intertitial_practices',
        data: {
          intro_text: questionSetEndingText,
          section_ending_uuid: questionSetEndingUUID,
          intro_image: questionSetEndingImage
        },
        breadcrumb: title,
        breadcrumbArr: [[PRACTICE_EXERCISES, questionSetUUID + '/practice']],
        sectionUUID: questionSetUUID
      })
    }

    const isPracticeTerms =
      activity?.url?.includes('quizlet') || activity?.type === 'PracticeTermRecord'
    if (isPracticeTerms) {
      children.push({
        uuid: 'Quizlet',
        sectionUUID,
        url: activity.url,
        type: 'practice_term',
        breadcrumb: 'Practice Term',
        breadcrumbArr: [['Practice Term']]
      })
    }
  })

  return children
}

function loadSectionQuizzes (studentAnswers, section, courseMetadata) {
  const children = []

  let {
    section_uuid: currentSectionUUID,
    section_exe: { quiz: quizzes }
  } = section
  const courseQuizNumber = getQuizNumberUptoPreviousSection({
    courseMetadata,
    currentSectionUUID
  })

  if (!quizzes) return children

  quizzes = quizzes.map(q => (
    { ...q, Question: trimQuestionsAnswers(q.Question) })
  )

  quizzes = quizzes.map(quiz => {
    const { quizResources, resourceIcons } = quiz
    return {
      ...quiz,
      Question: addExamResourcesInQuestions(
        quiz.Question,
        quizResources,
        resourceIcons
      )
    }
  })

  let quizNumber = 0
  quizzes.forEach(
    ({
      question_set_uuid: questionSetUUID,
      intro_text: introText,
      intro_image: introImage,
      Question: questions,
      title,
      general_explanation: generalExplanation,
      view_lesson: viewLesson,
      question_set_ending_text: questionSetEndingText,
      question_set_ending_image: questionSetEndingImage
    }) => {
      quizNumber++
      addGeneralExplanationToEachQuestion(questions, generalExplanation)
      children.push({
        uuid: questionSetUUID,
        course_quiz_number: courseQuizNumber + quizNumber,
        section_quiz_number: quizNumber,
        type: 'intertitial',
        data: {
          intro_text: introText,
          intro_image: introImage,
          title,
          worktype: QUIZ,
          questions
        },
        breadcrumb: title,
        breadcrumbArr: [[QUIZ, questionSetUUID]]
      })

      questions.forEach((q, index) => {
        const questionTitle = getQuestionTitle(q, index, QUIZ)
        children.push({
          uuid: q.Question_uuid,
          type: 'question_quiz',
          typeSection: 'end_content_intertitial_practices',
          section_ending_uuid: questionSetUUID + '_end_section',
          general_explanation: generalExplanation,
          quest_type: QUIZ,
          data: questions,
          breadcrumb: title + ' > ' + questionTitle,
          breadcrumbArr: [
            [title, questionSetUUID],
            [questionTitle, q.Question_uuid]
          ],
          practiceUUID: questionSetUUID,
          view_lesson: viewLesson
        })
      })

      children.push({
        uuid: questionSetUUID + '_end_section',
        type: 'end_content_intertitial_section',
        typeSection: 'end_content_intertitial_practices',
        data: {
          intro_text: questionSetEndingText,
          section_ending_uuid: questionSetUUID + '_end_section',
          intro_image: questionSetEndingImage,
          first_quiz_question: questions[0].Question_uuid,
          questions
        },
        course_quiz_number: courseQuizNumber + quizNumber,
        section_quiz_number: quizNumber,
        breadcrumb: title,
        breadcrumbArr: [[QUIZ, questionSetUUID + '_end_section']],
        sectionUUID: questionSetUUID
      })
    }
  )

  return children
}

function loadSectionEndContentIntertitial (section) {
  const children = []

  const {
    title,
    section_ending_uuid: sectionEndingUUID,
    section_ending_text: sectionEndingText,
    section_ending_image: sectionEndingImage
  } = section

  if (!sectionEndingUUID || !sectionEndingText) return children

  children.push({
    uuid: sectionEndingUUID,
    type: 'end_content_intertitial',
    data: {
      intro_text: sectionEndingText,
      intro_image: sectionEndingImage,
      section_ending_uuid: sectionEndingUUID
    },
    breadcrumb: title,
    breadcrumbArr: [[title, sectionEndingUUID]]
  })
  return children
}

function loadMultipleLectureVideos (
  multipleVideos,
  mainLecture = { instructor: {} }
) {
  const {
    course_download: mainCourseDownload,
    instructor: mainInstructor,
    title,
    type
  } = mainLecture
  return multipleVideos.map((video, index) => {
    const {
      id, displayTitle, embedCode, kalturaEmbedCode,
      instructor: videoInstructor,
      course_download: videoCourseDownload,
      codingExamples
    } = video
    const breadcrumb = displayTitle || LECTURE
    const instructor = isEmpty(videoInstructor)
      ? mainInstructor
      : videoInstructor
    const courseDownload = videoCourseDownload?.length
      ? [...mainCourseDownload, ...videoCourseDownload]
      : mainCourseDownload

    return {
      uuid: id,
      type: 'lecture',
      data: {
        lecturevideos: [{
          course_download: courseDownload,
          ...(codingExamples && { codingExamples }),
          embed_code: embedCode,
          kaltura_embed_code: kalturaEmbedCode,
          instructor,
          lecturevideos_uuid: id,
          title,
          type
        }]
      },
      breadcrumb: LECTURE,
      breadcrumbArr: [[breadcrumb, id]],
      isLastVideo: index === multipleVideos.length - 1
    }
  }
  )
}

function loadSectionProblemBank (problemBank, courseSections) {
  const children = []
  children.push({
    uuid: 'practice_exercises',
    type: 'problem_set',
    data: problemBank,
    breadcrumb: PRACTICE_EXERCISES,
    breadcrumbArr: [[PRACTICE_EXERCISES, 'practice_exercises']]
  })

  problemBank.forEach(problemSet => {
    const {
      question_set_uuid: questionSetUUID,
      intro_text: introText,
      intro_image: introImageSource,
      Question: practiceQuestions,
      general_explanation: generalExplanation,
      title,
      question_set_ending_text: questionSetEndingText,
      question_set_ending_uuid: questionSetEndingUUID,
      question_set_ending_image: questionSetEndingImage
    } = problemSet
    addGeneralExplanationToEachQuestion(practiceQuestions, generalExplanation)
    const introImage = introImageSource?.url?.toString() || introImageSource || ''
    children.push({
      uuid: questionSetUUID,
      type: 'intertitial',
      data: {
        intro_text: introText,
        intro_image: introImage,
        worktype: PRACTICE_EXERCISES,
        problemBank,
        currentProblemSetUUID: questionSetUUID,
        courseSections
      },
      breadcrumb: PRACTICE_EXERCISES,
      breadcrumbArr: [[PRACTICE_EXERCISES, questionSetUUID]]
    })

    practiceQuestions.forEach((q, index) => {
      children.push({
        uuid: questionSetUUID + '_' + q.Question_uuid,
        type: 'question_problem_bank',
        section_ending_uuid: questionSetUUID + '_end_section',
        quest_type: PRACTICE_EXERCISES,
        data: {
          currentProblemSet: practiceQuestions,
          problemBank,
          currentProblemSetUUID: questionSetUUID,
          courseSections
        },
        breadcrumb: PRACTICE_EXERCISES + ' > ' + title,
        breadcrumbArr: [
          [PRACTICE_EXERCISES, 'practice_exercises'],
          [title, questionSetUUID]
        ],
        questionUUID: q.Question_uuid,
        practiceUUID: questionSetUUID
      })
    })

    children.push({
      uuid: questionSetUUID + '/practice',
      type: 'end_content_intertitial_section',
      typeSection: 'end_content_intertitial_practices',
      data: {
        intro_text: questionSetEndingText,
        section_ending_uuid: questionSetEndingUUID,
        intro_image: questionSetEndingImage
      },
      breadcrumb: title,
      breadcrumbArr: [[PRACTICE_EXERCISES, questionSetUUID + '/practice']],
      sectionUUID: questionSetUUID
    })
  })

  return children
}

function addGeneralExplanationToEachQuestion (questionSet, generalExplanation) {
  questionSet.forEach(question => {
    question.general_explanation = generalExplanation
    return question.general_explanation
  })
}

function trimQuestionsAnswers (questions) {
  if (!questions) return

  return questions.map(q => ({ ...q, answer: trimAnswers(q?.answer) }))
}

function trimAnswers (answers) {
  if (!answers) return
  return answers.map(answer => answer.trim())
}

function addExamResourcesInQuestions (questions, examResources, resourceIcons) {
  if (!questions) return

  return questions.map(q => ({ ...q, examResources, resourceIcons }))
}

function removeIntructorFromALCards (cards) {
  if (!cards) return

  if (!config.course?.hasActiveLearningTheme) {
    cards.forEach(card => { card.instructor = [] })
  }

  return cards
}

function addAdaptationStatementForALCards (cards, questionSetAdaptationStatement) {
  if (!cards) return
  cards.forEach(card => {
    const cardAdaptationStatement = card.adaptationStatement
    // We want to hide adpatation statement for cards with `none` adpatation statement.
    if (
      isNoneAdaptationStatement(cardAdaptationStatement)
    ) return (card.adaptationStatement = '')
    card.adaptationStatement = cardAdaptationStatement ||
    questionSetAdaptationStatement
  })

  return cards
}

function isNoneAdaptationStatement (adaptationStatement) {
  if (!adaptationStatement) return false
  adaptationStatement = adaptationStatement.replace(/&nbsp;/g, '')
  return /<p>\s*none\s*<\/p>/.test(adaptationStatement.toLowerCase())
}

/**
 * @param {object} sectionTypes contains lecture and multi_lecture_videos
 * @returns {object} contains course_download & instructor info at least
 */
function getMainLectureInfo (sectionTypes) {
  const {
    lecture, multi_lecture_videos: multiLectureVideos
  } = sectionTypes || {}

  const mainLecture = lecture?.lecturevideos?.[0]
  const {
    title, type,
    course_download: courseDownloadLecture,
    instructor: instructorLecture
  } = mainLecture || {}

  const {
    course_download: courseDownloadMulti,
    instructor: instructorMulti
  } = multiLectureVideos || {}

  return {
    type,
    title,
    course_download: courseDownloadLecture || courseDownloadMulti || [],
    instructor: instructorLecture || instructorMulti || {}
  }
}

function getDefaultQuestionSetEndingText (setType) {
  const mapping = {
    [GUESSWORK]: `<p>Guesswork complete! Let's keep going...</p>\r\n`,
    [ACTIVE_LEARNING]: `<p>Active Learning complete! Let's keep going...</p>\r\n`
  }

  return mapping[setType]
}

export {
  normalizeALCards,
  getDefaultQuestionSetEndingText,
  addExamResourcesInQuestions,
  trimQuestionsAnswers,
  isNoneAdaptationStatement,
  addAdaptationStatementForALCards,
  removeIntructorFromALCards,
  loadEachSection,
  loadSectionReadings,
  loadSectionActiveLearning,
  loadSectionGuesswork,
  loadSectionLecture,
  loadSectionPracticeExercises,
  loadSectionConceptMap,
  loadSectionPracticeTerms,
  loadSectionQuizzes,
  loadSectionEndContentIntertitial,
  loadMultipleLectureVideos,
  addGeneralExplanationToEachQuestion,
  getMainLectureInfo,
  loadSectionProblemBank
}
