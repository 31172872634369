import styled from 'styled-components'
import media, { BREAKPOINTS } from '../../../mediaQueries'
import { ContentTitle } from '../../styles/text'

const LIGHT_DARK = '#1D1D1F'

export const ChapterTitle = styled(ContentTitle)`
    margin-bottom: 4px;
    flex-grow: 1;
    margin-right: 18px;
`

export const ChapterHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ChapterExamWrapper = styled.div`
  background: #161618;
  border-radius: 5px;
  padding: 24px 12px 32px;
  display: grid;
  grid-template-columns: auto 132px;
  row-gap: 24px;
  overflow: visible;

  ${media.mobile`
    grid-template-columns: auto;
  `}
`

export const ChapterWrapper = styled.div`
  background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(46, 46, 47, 0));
  padding: 1px;
  border-radius: 5px;
`

export const ChapterExamWrapperInner = styled.div`
  @media (min-width: ${BREAKPOINTS.tablet}px) {
    display: flex;
    position: relative;
  }
`

export const ChapterExamContentWrapper = styled.div`
  p {
    margin-bottom: 12px;
    color: #D6E7E7;
    font-weight: normal;
  }
`

export const ErrorMessage = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #E1774F;
  line-height: 19px;
  margin-bottom: 8px;
  margin-left: 10px;
`

export const ExamStudyGuideButton = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  margin-left: 12px;
  margin-bottom: 12px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5FC4B8;
  &:hover {
    text-decoration: underline;
  }
  ${media.mobile`
    margin-left: 12px;
    margin-bottom: 12px;
  `}
`
export const StudyGuideWrapper = styled.div`
  display: flex !important;
  align-items: baseline;
  ${media.mobile`
    flex-direction: ${(({ column }) => column ? 'column' : 'row')};
    display: flex !important;
  `}
`

export const StudyGuideUnlockText = styled.div`
  font-size: 16px;
  font-weight: normal;
  margin-left: 12px;
  line-height: 19px;
  color: #FFFFFF;
  ${media.mobile`
    margin-left: 0px;
    margin-bottom: 12px;
  `}
`

export const StudioCohortSignWrapper = styled.div`
  width: 100%;
  padding: 16px;
  grid-column: 1/3;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: ${LIGHT_DARK};

  ${media.mobile`
    grid-column: 1/2;
  `}
`

export const StudioCohortText = styled.p`
  margin-left: 16px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
`

export const LockText = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 8px;
  margin-bottom: 0px;
  display: block;
`

export const TealText = styled.span`
  color: #5fc4b8;
  cursor: pointer;
`

export const ScoreText = styled(LockText)`
  span {
    margin-top: 4px;
  }
`

export const UnlockText = styled(LockText)`
  color: ${(({ unlockReasonColor }) => unlockReasonColor)} !important;
`
