import React, { useEffect, useState } from 'react'
import { ToastComponent, ToastComponentsWrapper } from './styles'

function RequestErrorToast ({ error, isRequestError }) {
  const [isToastVisible, setIsToastVisible] = useState(false)

  useEffect(() => {
    const id1 = setTimeout(() => {
      setIsToastVisible(true)
    }, '10')

    const id2 = setTimeout(() => {
      setIsToastVisible(false)
    }, '2500')

    return () => {
      clearTimeout(id1)
      clearTimeout(id2)
    }
  }, [isRequestError])

  return (
    <ToastComponentsWrapper showToast={isToastVisible}>
      <ToastComponent>
        <p>{error}</p>
      </ToastComponent>
    </ToastComponentsWrapper>
  )
}

RequestErrorToast.displayName = 'RequestErrorToast'
export default RequestErrorToast
