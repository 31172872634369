import React, { useState } from 'react'
import Navigation from './Navigation'
import Question from './Question'
import AnswerComponent from './AnswerComponent'
import { ExerciseWrapper } from './styles'

export default function Excercises ({
  questionSet,
  problemBank,
  courseSections,
  currentProblemSetResponse
}) {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0)
  return (
    <ExerciseWrapper>
      <Navigation
        questionSet={questionSet}
        activeQuestionIndex={activeQuestionIndex}
        currentProblemSetResponse={currentProblemSetResponse}
      />
      <Question
        questionSet={questionSet}
        activeQuestionIndex={activeQuestionIndex}
      />
      <AnswerComponent
        activeQuestionIndex={activeQuestionIndex}
        questionSet={questionSet}
        setActiveQuestionIndex={setActiveQuestionIndex}
        problemBank={problemBank}
        currentProblemSetResponse={currentProblemSetResponse}
        courseSections={courseSections}
      />
    </ExerciseWrapper>
  )
}
