import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const ModalHeader = styled.div`
  border-bottom: ${({ showHeaderBorder }) => showHeaderBorder
    ? '1px solid rgba(255, 255, 255, 0.1)'
    : '0px'};
  height: 70px;
  display: flex;
  align-items: center;
  align-content: space-between;
  background: ${({ headerBgColor }) => headerBgColor || '#25272B'};
  justify-content: space-between;
  padding: 24px;
  position: sticky;
  top: 0;
  i {
    cursor: pointer;
  }
  span {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
   }
   .back {
    color: #5FC4B8;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;

    &:disabled {
      color: #5fc4b8;
      opacity: 0.3;
    }
    &:not(:disabled) {
      &:active {
        opacity: 0.3;
      }
      &:hover {
        filter: brightness(1.5);
      }
    }
  }

  img {
    transform: rotate(180deg);
    margin-top: -3px;
    margin-right: 12px;
  }
`

export const Footer = styled.div`
  width: 100%;
  height: 88px;
  padding: 22px 24px 24px;
  display: flex;
  border-top: 2px solid #343a40;
  justify-content: space-between;
  button {
    width: 150px;
    max-height: 40px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 9px;
    height: 19px;
    width: 290px;
    text-align: left;
    ${mediaqueries.mobile`
      width: 141px;
      margin-top: 0px;
    `}
  }
`
