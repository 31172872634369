import React, { useState, useEffect, useContext } from 'react'
import { emitter } from '../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'
import {
  VideoWrapper,
  Left,
  Right,
  Counter,
  Content
} from '../LecturesExerciseItem/styled'
import Context from '../Context/Context'
import { ReadingsSubText, ReadingTitle, Author } from './styled'
import ReadingItemButton from './ReadingItemButton'
import { getUrl } from '../../utilities/sectionUtils'
import FullWindow from './adobe/FullWindow'
import { findNumberOfPages } from '../../utilities/file'
import { READINGS_PROGRESS } from '../../Constants/studentContext'
import config from '../../config'

export default function ReadingItem (props) {
  const {
    studentData: {
      [READINGS_PROGRESS]: readingsProgress }
  } = useContext(Context)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [getPagesFromAdobe, setGetPagesFromAdobe] = useState(false)
  const [loadingPagesCount, setLoadingPagesCount] = useState(true)
  const {
    isLocked,
    index,
    sectionUUID,
    estimatedReadingTime,
    title,
    infographic,
    optionalReading,
    author,
    question_set_uuid: readingUUID,
    pdfFile } = props
  const pdfUrl = getUrl(pdfFile)
  const isReadingOpened = !!readingsProgress[readingUUID]

  useEffect(() => {
    const fetchNumberOfPages = async () => {
      try {
        if (infographic) {
          setNumberOfPages(1)
          setLoadingPagesCount(false)
          return
        }

        const count = await findNumberOfPages(pdfUrl)
        if (count) setLoadingPagesCount(false)

        setNumberOfPages(count)
        setGetPagesFromAdobe(true)
      } catch (error) {
        console.error(error.message)
      }
    }

    fetchNumberOfPages()
  }, [pdfUrl, infographic])

  const handleClick = () => {
    emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/${readingUUID}`)
  }

  const getReadingSubTitle = () => {
    if (loadingPagesCount) return <i className='fa fa-spinner fa-spin' />

    const plural = numberOfPages !== 1 ? 's' : ''
    const optional = optionalReading ? ' · Optional' : ''
    if (!isReadingOpened) return `${numberOfPages} Page${plural}${optional}`

    if (config.course.shouldAddReadingsInSectionProgress) return 'Completed'
    return 'Viewed'
  }

  return (
    <VideoWrapper isLocked={isLocked}>
      <Left className='d-flex'>
        <Counter>{index + 1}.</Counter>
        <Content>
          <div
            className='p-0 d-flex flex-wrap'
            data-test='reading-title-author'>
            <ReadingTitle className='mr-2' >
              {title}{author && ','}
            </ReadingTitle>
            <Author>{author}</Author>
          </div>
          <div>
            <ReadingsSubText mt='12' data-test='sub-text'>
              {getReadingSubTitle()}
            </ReadingsSubText>
            {(estimatedReadingTime && !isReadingOpened) &&
              <>
                <ReadingsSubText dot className='mx-2'>.</ReadingsSubText>
                <ReadingsSubText>{`${estimatedReadingTime} minute read`}</ReadingsSubText>
              </>
            }
          </div>
        </Content>
      </Left>
      <Right>
        <ReadingItemButton
          isReadingOpened={isReadingOpened}
          isLocked={isLocked}
          handleClick={handleClick}
        />
      </Right>
      {getPagesFromAdobe && <FullWindow
        getPagesInCb
        callBack={pages => {
          const shouldUsePageNumberFromAdobe = !numberOfPages || numberOfPages !== pages
          if (shouldUsePageNumberFromAdobe) {
            setNumberOfPages(pages)
            setLoadingPagesCount(false)
          }
        }}
        pdf={pdfUrl}
        title={title}
        readingUUID={readingUUID} />}
    </VideoWrapper>
  )
}
