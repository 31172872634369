import React from 'react'
import ModalComponent from '../../ModalComponent/ModalComponent'
import {
  OverrideCSSProperties,
  ModalCloseIcon,
  ModalBody,
  ModalActions,
  PrimaryButton,
  SecondaryButton
} from './styles'

const Modal = ({
  show,
  headerShow,
  showHeaderBorder,
  headerBgColor,
  handleClose,
  handleConfirm,
  handleDecline,
  height,
  title,
  text1,
  text2,
  primaryButtonText,
  secondaryButtonText
}) => {
  return (
    <>
      <OverrideCSSProperties height={height} />
      <ModalComponent
        show={show}
        handleClose={handleClose}
        headerShow={headerShow}
        showHeaderBorder={showHeaderBorder}
        title={title}
        headerBgColor={headerBgColor}
      >
        <ModalBody>
          {!title && <ModalCloseIcon onClick={handleClose} />}
          {text1 && <p>{text1}</p>}
          {text2 && <p>{text2}</p>}
        </ModalBody>
        <ModalActions>
          <PrimaryButton
            data-testid='primary-btn'
            onClick={handleConfirm}
          >
            {primaryButtonText || 'Load new file'}
          </PrimaryButton>
          <SecondaryButton ml='12' onClick={handleDecline}>
            {secondaryButtonText || 'Keep current code'}
          </SecondaryButton>
        </ModalActions>
      </ModalComponent>
    </>
  )
}

Modal.displayName = 'Modal'
export default Modal
