import React, { useContext, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import Context from '../../../Components/Context/Context'

import ActivitiesDetailHeader from '../ActivitiesDetailHeader/ActivitiesDetailHeader'

import {
  CourseDetailWrap,
  Divider,
  MessageCard,
  ActivityListWrap,
  SectionHeader,
  ActivityList,
  ActivityListItem,
  StyledP,
  MoreButton
} from './styles'
import { ChecklistIcon } from '../CourseCard/styles'
import { WHITE, YELLOW, RED } from '../checklistColors'
import {
  completedSection,
  createSectionList,
  filterClickedSection,
  getActiveLearningInstructors,
  getFormattedSections,
  getIntensiveSchedules,
  getLectureInstructors,
  getNextWeekSection,
  getPreviousLastWeekSection,
  getRecommendedProgressWeek,
  getSectionTypeSchedules,
  previousSections,
  removeAlreadyAnimatedActivity
} from './CourseActivitiesUtils'
import api from '../../../api'
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingAnimation'
import { emitter } from '../../Emitter/Emitter'
import {
  ON_INSTRUCTOR_SWITCH,
  ON_NAVIGATE_TO
} from '../../../Constants/emitterKeys'
import {
  getFormattedSchedule,
  isInProgressBeforeCutOffDate,
  getLatestCohort,
  getRecommendedProgress,
  isStudioCohort
} from '../../../utilities/courseUtils'
import { INTENSIVE_COHORT_DURATION } from '../../../Constants/cohortDuration'
import { ASSIGNMENT, BREAK, ESSAY, SECTION } from '../../../Constants/scheduleType'
import {
  ACTIVE_LEARNING,
  GUESSWORK,
  LECTURE,
  QUIZ
} from '../../../Constants/sectionType'
import config from '../../../config'
import ActivityDropdown from './ActivityDropdown'
import { CourseFactory } from '../../../configuration'
import { EXAM_COMPLETE, STUDENT_ANSWERS } from '../../../Constants/studentContext'
import { EXAM } from '../../../Constants/chapterType'
import { getExamHashLink } from '../../ResourcesSection/Utils'
import { Auth0Context } from '../../Auth0Provider/Auth0Provider'
import { removeFieldFromAllSections } from '../../../utilities/chapterUtils'
import { isUserAuditor } from '../../../utilities/userUtils'

const renderCard = () => (
  <MessageCard data-testid='lockCard' marginBottom='24px'>
    <div style={{ flex: 1 }}>
      <h4>Start with Guesswork first.</h4>
      <p>
        Completing Guesswork will unlock the other activities in the section.
      </p>
    </div>
    <ChecklistIcon
      width='32px'
      height='32px'
      color={'#B1BFC5'}
      marginRight='0px'
      src='images/icons/lock-icon.svg'
    />
  </MessageCard>
)

const assessmentEndDate = (endDate, isActiveAssessment, assessmentColor) => {
  const warningLabel = assessmentColor === WHITE ? 'Open now!' : 'Closing soon!'
  return (
    <>
      {endDate && (
        <p>
          {endDate}{isActiveAssessment && <span>{warningLabel}</span>}
        </p>
      )}
    </>
  )
}

const RenderActivities = ({
  examRetake,
  courseUnits,
  isVIP,
  isVIPGradedContent,
  isStudioCohort,
  dynamicCohortSchedule,
  chapters,
  context,
  toggleCheckList,
  allSectionsData,
  filteredSchedules,
  isIntensive,
  course,
  courseId,
  courseTitle,
  studentData = {},
  hasCohortStarted
}) => {
  const [isShowAll, setIsShowAll] = useState(false)
  const [toggleState, setToggleState] = useState(false)
  const [textAnimation, setTextAnimation] = useState(false)
  const [activityTitle, setActivityTitle] = useState('')
  const latestCohort = getLatestCohort(course)
  const sectionList = createSectionList({
    latestCohort,
    courseUnits,
    isVIP,
    isVIPGradedContent,
    isStudioCohort,
    examRetake,
    sectionsData: allSectionsData,
    dynamicCohortSchedule,
    context,
    chapters,
    filteredSchedules,
    isIntensive,
    studentData,
    courseId,
    courseTitle
  })

  const isAudit = isUserAuditor(latestCohort)

  useEffect(() => {
    const showTimeout = setTimeout(() => {
      setTextAnimation(true)
      setToggleState(true)
    }, 1000)

    return () => {
      clearTimeout(showTimeout)
    }
  }, [])

  const { startDate, endDate, title, warningColor } = dynamicCohortSchedule
  if (!sectionList?.length) { return null }
  const hasGuesswork = sectionList[0]?.title === '1.1 Guesswork'
  const showGuessworkCard = hasGuesswork && !sectionList[0].isCompleted && !isAudit
  const lastClickedActivity = JSON.parse(
    localStorage.getItem('lastClickedActivity')
  )
  const sectionUrl = filteredSchedules
    ? config.getCourseBaseUrlById(courseId)
    : null
  const activeCourse = config.courseId === courseId
  const sectionCompleted = completedSection(sectionList, lastClickedActivity)
  const sectionLists = sectionList
    ?.filter(section => filterClickedSection(section, lastClickedActivity))
    ?.slice(0, isShowAll ? sectionList?.length : 8)
  if (!sectionLists?.length) { return null }
  const onActivityClick = (
    lockText,
    title,
    type,
    sectionUUID,
    exerciseId,
    handleClick,
    currentActivity,
    isActiveLearning,
    isLecture
  ) => {
    if (lockText || type === BREAK) return

    localStorage.setItem(
      'lastClickedActivity',
      JSON.stringify(currentActivity)
    )
    if (isActiveLearning || isLecture) {
      setActivityTitle(title)
      return
    }
    if (title?.includes(QUIZ)) {
      if (activeCourse) {
        emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}?quiz`)
      } else {
        window.location.href = `${sectionUrl}/#${sectionUUID}?quiz`
      }
      toggleCheckList(false)
      return
    }
    if (type !== SECTION) {
      if (type === ASSIGNMENT && !activeCourse) {
        window.location.href =
          `${sectionUrl}/#${sectionUUID}/writing_assignment/`
      } else if (type === EXAM && !activeCourse) {
        const hashLink = getExamHashLink(title)
        window.location.href = `${sectionUrl}/#/#${hashLink}`
      } else {
        handleClick()
      }
    } else if (activeCourse) {
      if (title?.includes(GUESSWORK)) {
        window.location.href = `${window.location.origin}/#${sectionUUID}/${exerciseId}/`
      } else {
        setTimeout(() => { emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/${exerciseId}`) }, 2000)
        emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/${exerciseId}`)
      }
    } else {
      window.location.href = `${sectionUrl}/#${sectionUUID}/${exerciseId}/`
    }
    toggleCheckList(false)
  }

  const onInstructorActivityClick = (
    isActiveLearning,
    themeName,
    instructorUUID,
    sectionUUID,
    exerciseId,
    lecturevideosUUID
  ) => {
    if (activeCourse) {
      if (isActiveLearning) {
        context.updateContext({
          currentActiveLearningTheme: themeName,
          isActiveLearningButtonClicked: true
        })
        localStorage.setItem('last_viewed_theme', themeName)
        emitter.emit(ON_INSTRUCTOR_SWITCH, instructorUUID)
        setTimeout(() => {
          emitter.emit(ON_NAVIGATE_TO,
            `/${sectionUUID}/${lecturevideosUUID || exerciseId}`)
        }, 200)
        emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/${lecturevideosUUID || exerciseId}`)
        toggleCheckList(false)
        return
      }
      emitter.emit(ON_INSTRUCTOR_SWITCH, instructorUUID)
      setTimeout(() => {
        emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/${exerciseId}`)
      }, 2000)
      emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/${exerciseId}`)
    } else {
      setTimeout(() => {
        window.location.href = `
        ${`${sectionUrl}/#/${sectionUUID}/${isActiveLearning
    ? (lecturevideosUUID || exerciseId) : exerciseId}/` +
          `?instructor=${instructorUUID}${isActiveLearning
            ? `&theme_name=${themeName}` : ''}`}`
      }, 200)
      window.location.href = `
        ${`${sectionUrl}/#/${sectionUUID}/${isActiveLearning
    ? (lecturevideosUUID || exerciseId) : exerciseId}/` +
        `?instructor=${instructorUUID}${isActiveLearning
          ? `&theme_name=${themeName}` : ''}`}`
    }
    toggleCheckList(false)
  }
  const dateAndSectionRange = () => (
    <SectionHeader
      data-testid='sectionListHeader'
      completed={sectionCompleted && toggleState}
      warningColor={warningColor}
    >
      <h5>
        {startDate} {endDate && ' - '}
        {endDate}
      </h5>
      <span />
      <p>{title}</p>
      {warningColor &&
        <ChecklistIcon
          width='16px'
          height='16px'
          color={warningColor}
          src='images/icons/exclamation-circle-1.svg'
        />}
    </SectionHeader>
  )
  return (
    <>
      <ActivityListWrap data-testid='listWrap'>
        {showGuessworkCard ? renderCard() : null}
        {hasCohortStarted ? dateAndSectionRange() : null}
        <ActivityList>
          {sectionLists?.map(
            (
              {
                lockText,
                section_uuid: sectionUUID,
                endDate,
                assessmentColor,
                isActiveAssessment,
                title,
                description,
                handleClick,
                exerciseId,
                type,
                isCompleted,
                lectureVideos,
                activeLearningQuestions
              },
              index
            ) => {
              const currentActivity = {
                exerciseId: exerciseId || '',
                section_uuid: sectionUUID,
                title,
                description,
                type,
                isCompleted
              }
              const {
                isSameActivityTitleUUID,
                isSameDescription,
                isAnimated
              } = removeAlreadyAnimatedActivity(
                currentActivity,
                lastClickedActivity,
                toggleState,
                textAnimation
              )

              const {
                showChecklistDropdownAL,
                showChecklistDropdownLectures
              } = CourseFactory(courseId)
              const isActiveLearning =
                showChecklistDropdownAL && title?.includes(ACTIVE_LEARNING)
              const isLecture =
                showChecklistDropdownLectures && title?.includes(LECTURE)
              const instructors = getLectureInstructors(
                lectureVideos,
                isLecture
              )
              const activeLearningInstructors =
                getActiveLearningInstructors(
                  activeLearningQuestions,
                  isActiveLearning,
                  studentData[STUDENT_ANSWERS]
                )
              const isExam = [EXAM, ESSAY].includes(type)
              const isQuiz = title?.includes(QUIZ)

              const options = isActiveLearning
                ? activeLearningInstructors
                : instructors
              const isExerciseActive = isExam ? isActiveAssessment
                : lockText
                  ? title?.includes(GUESSWORK) && !lockText
                  : !lockText

              const shouldHideActivity = isAudit && isQuiz

              if (shouldHideActivity) return null
              const isTermBreak = type === BREAK

              return (
                <ActivityListItem
                  active={isExerciseActive}
                  isTermBreak={isTermBreak}
                  completed={isAnimated && toggleState}
                  assessmentColor={assessmentColor}
                  warningColor={warningColor}
                  textAnimation={
                    textAnimation &&
                    !isSameDescription &&
                    isSameActivityTitleUUID
                  }
                  key={index}
                  onClick={() =>
                    onActivityClick(
                      lockText,
                      title,
                      type,
                      sectionUUID,
                      exerciseId,
                      handleClick,
                      currentActivity,
                      isActiveLearning,
                      isLecture
                    )
                  }
                >
                  <div className='checkMark draw' />
                  <ActivityDropdown
                    option={options}
                    show={activityTitle === title}
                    toggle={(e) => {
                      e.stopPropagation()
                      setActivityTitle('')
                    }}
                    isActiveLearning={isActiveLearning}
                    onClick={(instructorUUID, themeName, lecturevideosUUID) =>
                      onInstructorActivityClick(
                        isActiveLearning,
                        themeName,
                        instructorUUID,
                        sectionUUID,
                        exerciseId,
                        lecturevideosUUID
                      )
                    }
                  />
                  {!isTermBreak && <h5>{title}</h5>}
                  <p>
                    {isSameActivityTitleUUID
                      ? lastClickedActivity?.description
                      : description}
                  </p>
                  {isSameActivityTitleUUID ? <h4>{description}</h4> : null}
                  {!isTermBreak && assessmentEndDate(endDate, isActiveAssessment, assessmentColor)}
                </ActivityListItem>
              )
            }
          )}
        </ActivityList>
      </ActivityListWrap>
      {sectionList?.length > 8 ? (
        <MoreButton
          data-testid='moreButton'
          onClick={() => setIsShowAll(!isShowAll)}
        >
          <img
            src={
              isShowAll
                ? '/images/icons/arrow-up.svg'
                : '/images/icons/arrow-down.svg'
            }
            alt='arrow-down'
          />
          <p>{isShowAll ? 'Show fewer' : 'Show all'}</p>
        </MoreButton>
      ) : null}
    </>
  )
}

const getCourseActivitiesLocalStorageKey = ({ courseId, activityType, email }) => {
  return `courseActivities_${email}_${courseId}_${activityType}`
}

const CourseActivities = ({
  course,
  toggleCheckList,
  hasCohortStarted,
  isFromCourseDetail,
  isGGUCohort,
  isOpen
}) => {
  const context = useContext(Context)
  const { user } = useContext(Auth0Context)
  const { email } = user || {}
  const [loading, setLoading] = useState(true)
  const [allSectionsData, setAllSectionsData] = useState([])

  const latestCohort = getLatestCohort(course)
  const isAudit = isUserAuditor(latestCohort)

  useEffect(() => {
    const getSectionData = async () => {
      try {
        setLoading(true)

        const checklistData = await api.getChecklistSectionsData(course.id)
        let sectionData = checklistData
        const latestCohort = getLatestCohort(course)
        const isAuditor = isUserAuditor(latestCohort)

        if (isAuditor) {
          const { dateStart, finalExamEndTime, cohortEndTime, duration } = latestCohort || {}
          const isCohortInProgress = isInProgressBeforeCutOffDate({
            dateStart,
            finalExamEndTime,
            cohortEndTime,
            duration,
            officialCourseName: course?.name
          })
          if (!isCohortInProgress) {
            sectionData = removeFieldFromAllSections(checklistData, 'quiz')
          }
        }

        const formattedSections = getFormattedSections(sectionData)
        setAllSectionsData(formattedSections)
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }
    getSectionData()
    // eslint-disable-next-line
  }, [])

  const getActivitiesLocalStorageKey = (activityType) => {
    return getCourseActivitiesLocalStorageKey({
      courseId: course.id,
      activityType,
      email
    })
  }

  // localStorage keys
  const moreThanTwoWeeksLocalStorageKey = course.id
    ? getActivitiesLocalStorageKey('moreThanTwoWeeks')
    : null

  const moreThanOneWeekLocalStorageKey = course.id
    ? getActivitiesLocalStorageKey('moreThanOneWeek')
    : null

  const allPastActivitiesClearedLocalStorageKey = course.id
    ? getActivitiesLocalStorageKey('allPastActivitiesCleared')
    : null

  useEffect(() => () => {
    if (!isFromCourseDetail) return

    const allPastActivitiesCleared = localStorage.getItem(
      allPastActivitiesClearedLocalStorageKey
    )

    if (allPastActivitiesCleared) {
      localStorage.removeItem(
        moreThanTwoWeeksLocalStorageKey
      )
      localStorage.removeItem(
        moreThanOneWeekLocalStorageKey
      )
      localStorage.removeItem(
        allPastActivitiesClearedLocalStorageKey
      )
    }
    // eslint-disable-next-line
  }, [])

  const chapters = course?.chapters
  const isIntensive = course?.cohort?.duration <= INTENSIVE_COHORT_DURATION
  const {
    lastTwoWeeksRP,
    lastWeekRP
  } = getRecommendedProgress(
    context?.isCohortEnded,
    course?.courseResourcesSchedule
  )
  let formattedSchedules = getFormattedSchedule(
    course?.courseResourcesSchedule,
    isIntensive
  )
  const previousLastWeekSection = isIntensive ? getPreviousLastWeekSection(
    course?.courseResourcesSchedule,
    isIntensive
  ) : lastWeekRP
  const currentWeekSection = getRecommendedProgressWeek(
    course?.courseResourcesSchedule,
    hasCohortStarted,
    isIntensive,
    previousLastWeekSection
  )
  let previousWeekSection = previousLastWeekSection ? [previousLastWeekSection] : []
  let currentWeekSections = currentWeekSection
  if (isIntensive) {
    formattedSchedules = getIntensiveSchedules(formattedSchedules)
    previousWeekSection = getIntensiveSchedules(previousWeekSection)
    currentWeekSections = getIntensiveSchedules(currentWeekSections)
  }
  let previousSection =
      previousSections(lastTwoWeeksRP, formattedSchedules, isIntensive)
  let allSections = []
  const { isCaughtUp, isWorkingAhead, workingAheadWeekToDisplay } = course

  if (isWorkingAhead) {
    const formattedSchedules = getFormattedSchedule(
      course?.courseResourcesSchedule,
      isIntensive
    )
    allSections = [formattedSchedules[workingAheadWeekToDisplay]]
  } else if (isCaughtUp) {
    const nextWeekSection =
        getNextWeekSection(course?.courseResourcesSchedule, isIntensive)
    allSections = Array.isArray(nextWeekSection) ? nextWeekSection : [nextWeekSection]
  } else {
    previousSection = isAudit ? previousSection
      : previousSection.map(section => (
        section?.assignmentType === BREAK ? section : { ...section, warningColor: RED }
      ))
    previousWeekSection = isAudit ? previousWeekSection
      : previousWeekSection.map(section => (
        section?.assignmentType === BREAK ? section : { ...section, warningColor: YELLOW }
      ))
    allSections = [
      ...previousSection,
      ...previousWeekSection,
      ...currentWeekSections
    ]
  }
  const filteredSchedules = getSectionTypeSchedules(allSections)

  const { id: courseId, name: courseName, studentData } = course || {}

  const hasPastTwoWeeksActivities = useMemo(() => {
    if (loading) return false

    // All past two weeks activities
    const previousTwoWeeksActivities = filteredSchedules.find(schedule => {
      const { warningColor, assignmentType } = schedule || {}
      return warningColor === RED && assignmentType !== BREAK
    })
    if (!previousTwoWeeksActivities) return false

    const sectionList = createSectionList({
      sectionsData: allSectionsData,
      dynamicCohortSchedule: previousTwoWeeksActivities,
      context,
      chapters,
      filteredSchedules,
      isIntensive,
      studentData,
      courseId,
      courseName
    })
    if (!sectionList?.length) return false

    const lastClickedActivity = JSON.parse(
      localStorage.getItem('lastClickedActivity')
    )
    const activeSectionList = sectionList.filter(
      section => filterClickedSection(section, lastClickedActivity)
    )

    if (!activeSectionList?.length) return false

    return true
  }, [
    allSectionsData,
    chapters,
    context,
    courseId,
    courseName,
    filteredSchedules,
    isIntensive,
    loading,
    studentData
  ])

  const hasPastOneWeekActivities = useMemo(() => {
    if (loading) return false

    // All past two weeks activities
    const prevWeekActivities = filteredSchedules.find(schedule => {
      const { warningColor, assignmentType } = schedule || {}
      return warningColor === YELLOW && assignmentType !== BREAK
    })
    if (!prevWeekActivities) return false

    const sectionList = createSectionList({
      sectionsData: allSectionsData,
      dynamicCohortSchedule: prevWeekActivities,
      context,
      chapters,
      filteredSchedules,
      isIntensive,
      studentData,
      courseId,
      courseName
    })
    if (!sectionList?.length) return false

    const lastClickedActivity = JSON.parse(
      localStorage.getItem('lastClickedActivity')
    )
    const activeSectionList = sectionList.filter(
      section => filterClickedSection(section, lastClickedActivity)
    )

    if (!activeSectionList?.length) return false

    return true
  }, [
    allSectionsData,
    chapters,
    context,
    courseId,
    courseName,
    filteredSchedules,
    isIntensive,
    loading,
    studentData
  ])

  useMemo(() => {
    if (hasPastTwoWeeksActivities) {
      localStorage.setItem(moreThanTwoWeeksLocalStorageKey, true)
    }
  }, [moreThanTwoWeeksLocalStorageKey, hasPastTwoWeeksActivities])

  useMemo(() => {
    if (hasPastOneWeekActivities) {
      localStorage.setItem(moreThanOneWeekLocalStorageKey, true)
    }
  }, [moreThanOneWeekLocalStorageKey, hasPastOneWeekActivities])

  // This state is when the user has cleared past two weeks activities but not one week
  const twoWeeksActivitiesClearedButNotOneWeek = useMemo(() => {
    if (loading) return false

    if (!moreThanTwoWeeksLocalStorageKey) return

    if (hasPastTwoWeeksActivities) return false

    const hadMoreThanTwoWeeksActivities = localStorage.getItem(
      moreThanTwoWeeksLocalStorageKey
    )
    if (!hadMoreThanTwoWeeksActivities) return false

    return hasPastOneWeekActivities
  }, [
    moreThanTwoWeeksLocalStorageKey,
    hasPastTwoWeeksActivities,
    hasPastOneWeekActivities,
    loading
  ])

  // This state is when the user has cleared all past activities
  const allPastActivitiesCleared = useMemo(() => {
    if (loading) return false

    if (!moreThanOneWeekLocalStorageKey) return

    if (hasPastOneWeekActivities || hasPastTwoWeeksActivities) return false

    const hadMoreThanTwoWeeksActivities = localStorage.getItem(
      moreThanTwoWeeksLocalStorageKey
    )
    const hadMoreThanOneWeekActivities = localStorage.getItem(
      moreThanOneWeekLocalStorageKey
    )

    if (!hadMoreThanTwoWeeksActivities && !hadMoreThanOneWeekActivities) return false

    localStorage.setItem(
      allPastActivitiesClearedLocalStorageKey,
      true
    )

    return true
  }, [
    allPastActivitiesClearedLocalStorageKey,
    moreThanOneWeekLocalStorageKey,
    moreThanTwoWeeksLocalStorageKey,
    hasPastTwoWeeksActivities,
    hasPastOneWeekActivities,
    loading
  ])

  const examsArray = chapters?.filter(chapter => chapter.type === EXAM)
  const { chapter_uuid: finalExamUUID } =
  examsArray?.[examsArray.length - 1] || {}
  const isFinalExamCompleted = studentData[EXAM_COMPLETE][finalExamUUID]

  const showActivities = useMemo(() => {
    if (loading) return false

    if (isGGUCohort) return filteredSchedules?.length && hasCohortStarted

    return !!filteredSchedules?.length
  }, [filteredSchedules, hasCohortStarted, isGGUCohort, loading])

  if (!isOpen) return null

  return (
    <CourseDetailWrap>
      <ActivitiesDetailHeader
        course={course}
        previousSection={previousSection}
        hasPastOneWeekActivities={hasPastOneWeekActivities}
        hasPastTwoWeeksActivities={hasPastTwoWeeksActivities}
        twoWeeksActivitiesClearedButNotOneWeek={twoWeeksActivitiesClearedButNotOneWeek}
        allPastActivitiesCleared={allPastActivitiesCleared}
        hasCohortStarted={hasCohortStarted}
        scheduleSection1={filteredSchedules[0]}
        studentData={studentData}
        isIntensive={isIntensive}
        isFinalExamCompleted={isFinalExamCompleted}
        isGGUCohort={isGGUCohort}
      />
      {showActivities ? (<Divider />) : null}
      {loading ? (
        <LoadingSpinner />
      ) : showActivities ? (
        filteredSchedules?.map((dynamicCohortSchedule, index) => (
          <RenderActivities
            dynamicCohortSchedule={dynamicCohortSchedule}
            chapters={chapters}
            toggleCheckList={toggleCheckList}
            context={context}
            allSectionsData={
              allSectionsData?.length
                ? allSectionsData
                : course?.allSectionsData
            }
            filteredSchedules={course?.courseResourcesSchedule}
            key={index}
            courseId={course?.id}
            examRetake={context?.examRetake}
            courseUnits={course?.courseUnits}
            isVIP={course?.vip || latestCohort?.name?.includes('VIP')}
            isVIPGradedContent={course?.vipGradedContent}
            isStudioCohort={isStudioCohort(course)}
            latestCohort={latestCohort}
            isIntensive={isIntensive}
            course={course}
            courseTitle={course.name}
            studentData={studentData}
            hasCohortStarted={hasCohortStarted}
          />
        ))
      ) : (
        <StyledP>
          The course opens on {course?.courseResourcesSchedule?.[0]?.startDate || ''}.
          Check back then to get started on your coursework!
        </StyledP>
      )}
    </CourseDetailWrap>
  )
}

CourseActivities.displayName = 'CourseActivities'
CourseActivities.propTypes = {
  course: PropTypes.object.isRequired
}

export default CourseActivities
