import { DISCUSSION } from './sectionType'

const SECTION_TOOLTIP = {
  Guesswork: 'Take your best guess at questions from this section. Don’t worry—these aren’t graded.',
  Lecture: 'Video lessons from our lecturers. Class is always in session!',
  'Active Learning': 'Our interactive textbook with readings guided by activities and practice problems.',
  [DISCUSSION]: 'Join the weekly discussion with your classmates. Ask questions or share your ideas!',
  'Practice Exercises': 'Practice key problems from this section until you become a master.',
  'Practice Terms': 'Study chapter key terms with flashcards, a matching game, quizzes, and more.',
  Quiz: 'Quiz time. These graded quizzes contribute to your final score. Only your highest score counts.'
}

export const STUDIO_TOOLTIP = {
  Guesswork: 'Take your best guess at these questions as an introduction to this material!',
  Quiz: 'Quiz time! Take these quizzes to test your concepts.'
}

export const DEFAULT_DIFFICULTY_WARNING_LEVEL_1 = 'Some students find that this section takes more time to complete.'

export default SECTION_TOOLTIP
