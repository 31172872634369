import styled from 'styled-components'

export const Icon = styled.img`
  width: 15.29px;
  height: 10.29px;
`

export const StyledStructuredText = styled.div`
  code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word;
  }

  blockquote {
    border-left: 5px solid #34363a !important;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.1em;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    position: relative;
    background-color: unset;
    border-radius: unset;

    footer {
      color: #848484;
      margin-top: 1.5em;
      line-height: 1.3em;
      font-style: italic;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    all: revert;
  }
`
