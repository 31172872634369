import React, { Component } from 'react'
import InstructorVideoListItem from '../InstructorVideoListItem/InstructorVideoListItem'

class InstructorVideoList extends Component {
  render () {
    var { instructor } = this.props
    if (!instructor) return null
    return instructor.map((instructordata, id) => (
      <div className='col-md-4' key={instructordata.instructor_uuid} >
        <InstructorVideoListItem instructordata={instructordata} />
      </div>
    ))
  }
}

export default InstructorVideoList
