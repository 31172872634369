import React, { useRef, useState } from 'react'
import {
  InputWrapper,
  InputWarningText,
  Input,
  InputFooterText
} from './styled'

const CustomInput = ({
  warningIconSrc,
  warningText,
  placeholder,
  footerText,
  onChange,
  showClear,
  handleEnterKeyPress,
  error,
  ...rest
}) => {
  const inputRef = useRef()
  const [value, setValue] = useState('')

  const clearInput = () => {
    if (!value) return
    inputRef.current.value = ''
    inputRef.current.focus()
    setValue('')
    onChange && onChange('')
  }

  const onEnterKeyPress = (event) => {
    if (!value) return
    if (event.key === 'Enter') {
      handleEnterKeyPress && handleEnterKeyPress()
      event.preventDefault()
    }
  }

  return (
    <InputWrapper>
      {error &&
        <InputWarningText data-testid='warning-text'>
          {warningIconSrc && <img src={warningIconSrc} alt='warning-icon' />}
          {warningText}
        </InputWarningText>}
      <div>
        <Input
          ref={inputRef}
          onChange={(e) => {
            setValue(e.target.value)
            onChange && onChange(e.target.value)
          }}
          type='password' placeholder={placeholder}
          error={error}
          {...rest}
          onKeyDown={onEnterKeyPress}
        />
        {value && showClear && !rest.disabled &&
          <img
            onClick={clearInput}
            src='images/icons/icon-cross-circled.svg'
            alt='clear-icon'
          />}
      </div>
      {footerText && (
        <InputFooterText data-testid='input-footer-text'>
          {footerText}
        </InputFooterText>
      )}
    </InputWrapper>
  )
}

CustomInput.displayName = 'CustomInput'
export default CustomInput
