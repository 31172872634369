import React, { useEffect, useRef, useState } from 'react'
import { Paragraph, ReadMoreWrapper, Caret } from './styled/index'
import { BREAKPOINTS } from '../../mediaQueries'

export default function ReadMoreToggler ({ children }) {
  const [readMore, setReadMore] = useState(false)
  const [isParagraphExceed, setIsParagraphExceed] = useState(false)
  const paragraphRef = useRef()
  const isOverflow = isParagraphExceed && !readMore
  const [paragraphHeight, setParagraphHeight] = useState()

  const toggleHandler = () => {
    setReadMore(!readMore)
  }

  const calculateHeight = () => {
    const lineHeight = 22
    const isMobileBreakpoint = window.innerWidth < BREAKPOINTS.mobile
    const calculatedParagraphHeight = (isMobileBreakpoint ? 5 : 3) * lineHeight
    setParagraphHeight(calculatedParagraphHeight)
    const scrollHeight = paragraphRef.current?.scrollHeight
    const isParagraphHeightGreater = (calculatedParagraphHeight < scrollHeight)
    setIsParagraphExceed(isParagraphHeightGreater)
  }

  useEffect(() => {
    window.addEventListener('resize', calculateHeight)
    calculateHeight()

    return () => {
      window.removeEventListener('resize', calculateHeight)
    }
    // eslint-disable-next-line
  }, [])

  const ReadMoreTextToggler = () => (isParagraphExceed &&
    <ReadMoreWrapper
      onClick={toggleHandler}
    >
      <Caret collapse={isOverflow} />
      {readMore ? 'READ LESS' : 'READ MORE'}
    </ReadMoreWrapper>
  )

  return (
    <>
      <Paragraph
        collapse={isOverflow}
        paragraphHeight={readMore ? '30em' : `${paragraphHeight}px`}
        ref={paragraphRef}
      >
        {children}
      </Paragraph>
      <ReadMoreTextToggler />
    </>
  )
}
