import React, { useContext } from 'react'
import CourseButton from '../CourseButton/CourseButton'
import LockedMarker from '../LockedMarker/LockedMarker'
import Context from '../Context/Context'
import { capitalizeFirstLetter } from '../../utilities'
import {
  PRACTICE_TERM_COMPLETE
} from '../../Constants/studentContext'
import { checkIfSectionStarted } from '../../utilities/sectionUtils'
import { emitter } from '../Emitter/Emitter'
import {
  ON_NAVIGATE_TO
} from '../../Constants/emitterKeys'
import { ReadingButtonWrapper } from '../Readings/styled'

export default function SectionHomepagePracticeButton ({
  exercise,
  isCompleted,
  isUnlocked,
  sectionUUID
}) {
  const { studentData: {
    studentAnswers,
    [PRACTICE_TERM_COMPLETE]: PracticeTermCompleted
  },
  updateContext,
  isCohortEndedForStudent
  } = useContext(Context)
  if (!isUnlocked) return <LockedMarker />
  const { type, question_set_uuid: questionSetUuid, Question: questionList } = exercise
  const isPracticeStarted = checkIfSectionStarted(questionList, studentAnswers)
  const capitalizedType = capitalizeFirstLetter(type)
  const isPracticeTerm = capitalizedType === 'Practice Terms'
  const handleClick = () => {
    if (sectionUUID && PracticeTermCompleted[sectionUUID]) {
      updateContext({ reviewMode: true })
    }
    const navigateTo = isPracticeTerm ? 'Quizlet' : questionSetUuid
    emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/${navigateTo}`)
    window.outlierLog('Section start', capitalizedType)
  }
  const getButtonText = () => {
    if (isCohortEndedForStudent) return 'Practice'
    if (isCompleted) return 'Practice'
    if (isPracticeStarted) return 'Continue'
    return 'Start'
  }
  const dataUuid =
    isCompleted ? sectionUUID + '/' + questionSetUuid : sectionUUID + '/Quizlet'

  return (
    <ReadingButtonWrapper>
      <CourseButton
        className={{
          'btn-primary': !isCompleted && !isCohortEndedForStudent,
          'btn-secondary': !!isCompleted || isCohortEndedForStudent
        }}
        onClick={handleClick}
        dataUuid={dataUuid}
      >
        {getButtonText()}
      </CourseButton>
    </ReadingButtonWrapper>
  )
}
