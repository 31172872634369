import React, { useContext } from 'react'
import LockedMarker from '../LockedMarker/LockedMarker'
import CourseButton from '../CourseButton/CourseButton'
import Context from '../Context/Context'

const LectureItemButton = props => {
  const {
    isCohortEndedForStudent
  } = useContext(Context)
  const { isLocked, remainingTime, duration, handleClick } = props
  const buttonStyle = { minWidth: '119px', lineHeight: '22px' }

  if (isLocked) return <LockedMarker />

  if (isCohortEndedForStudent) {
    return (
      <CourseButton
        data-testid='text-locked'
        onClick={handleClick}
        style={buttonStyle}
        className={{ 'btn-secondary': true }}
        dataCypress='viewLectureButton'
      >
        View
      </CourseButton>
    )
  }
  if (remainingTime === 0) {
    return (
      <CourseButton
        onClick={handleClick}
        style={{ minWidth: '119px', lineHeight: '22px' }}
        className={{ 'btn-secondary': true }}
        dataCypress='replayLectureButton'
      >
        Replay
      </CourseButton>
    )
  }
  if (remainingTime === duration) {
    return (
      <CourseButton
        onClick={handleClick}
        style={{ minWidth: '119px', lineHeight: '22px' }}
        className={{ 'btn-primary': true }}
        dataCypress='playLectureButton'
      >
        Play
      </CourseButton>
    )
  }
  return (
    <CourseButton
      onClick={handleClick}
      style={{ minWidth: '119px', lineHeight: '22px' }}
      className={{ 'btn-primary': true }}
      dataCypress='continueLectureButton'
    >
      Continue
    </CourseButton>
  )
}

export default LectureItemButton
