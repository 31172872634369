import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { isGuessworkCompleteForSection } from './studentProgressUtils'
import { secondsToFormattedDateShort, getSecondsFromTime } from './dateTimeUtils'
import config from '../config'
import {
  QUIZ,
  GUESSWORK,
  CONCEPT_MAP,
  PRACTICE_TERMS,
  PROBLEM_BANK,
  LECTURE,
  ACTIVE_LEARNING,
  DISCUSSION,
  SUBSECTION
} from '../Constants/sectionType'
import { has70PercentCardsCompleted } from './questions'
import {
  LECTURE_COMPLETE,
  LECTURE_VIDEO_PROGRESS,
  QUIZ_SECTION,
  PRACTICE_EXERCISES_COMPLETE,
  QUIZ_COMPLETE,
  EXAM_COMPLETE,
  GUESSWORK_COMPLETE,
  ACTIVE_LEARNING_COMPLETE,
  ASSIGNMENT_PROGRESS,
  READINGS_COMPLETE,
  PRACTICE_TERM_COMPLETE,
  CONCEPT_MAP_COMPLETE,
  LAST_GUESSWORK_UUID,
  LAST_ACTIVE_LEARNING_UUID,
  LAST_QUIZ_UUID,
  STUDENT_ANSWERS
} from '../Constants/studentContext'
import { CHAPTER, ORIENTATION } from '../Constants/chapterType'
import { getChapterLockCode, isPracticeExam, removeQuizzesFromSection } from './chapterUtils'
import {
  getCohortExamDates,
  getCohortModifier,
  getCohortSpecialDays,
  getCohortStartSecondsSinceEpoch,
  isInProgressBeforeCutOffDate
} from './courseUtils'
import { SECTION } from '../Constants'
import api from '../api'
import { PREV_EXAM_DUE_DATE, PREV_EXAM_START_DATE } from '../Constants/chapterLockCodes'

export {
  getUrl,
  getSectionType,
  getSectionExerciseLockCode,
  getSectionExerciseLockText,
  hasBlankAnswers,
  getUnansweredQuestions,
  getAnswerForUnasweredQuestions,
  getMaxPercent,
  hasSectionActiveLearning,
  checkIfSectionStarted,
  getMultipleVideos,
  getVideoByID,
  getVideosRemainingTime,
  getKalturaVideosRemainingTime,
  getVideosTime,
  areAllSiblingVideosCompleted,
  areAllSiblingKalturaVideosCompleted,
  areAllKalturaEmbedCodesValid,
  getTotalSectionVideosTime,
  getNumberOfSectionTypes,
  getCourseSections,
  doesLastCardExistInQuestionSet,
  getAnswerForExamQuestions
}

function getGuessworkLockCode (opts) {
  const {
    isAuditor,
    isVIP,
    isVIPGradedContent,
    isStudioCohort,
    studentProgress,
    chapters,
    sectionUUID,
    latestCohort,
    examRetake,
    courseUnits,
    courseResourcesSchedule,
    courseId
  } = opts || {}
  const chapterIndex = chapters?.findIndex(chapter => {
    return chapter?.sections?.some(
      currentSection => sectionUUID === currentSection.section_uuid
    )
  }) || -1
  if (chapterIndex < 0) return false
  const {
    id,
    auditContentLock,
    milestones
  } = latestCohort || {}
  const lockCodeOptions = {
    ...opts,
    examRetake,
    chapters,
    courseUnits,
    cohortModifier: getCohortModifier(chapters, latestCohort?.duration),
    cohortMilestones: milestones,
    cohortSpecialDays: getCohortSpecialDays(latestCohort),
    cohortExamDates: getCohortExamDates(latestCohort),
    assignmentsProgress: studentProgress?.[ASSIGNMENT_PROGRESS],
    examsCompleted: studentProgress?.[EXAM_COMPLETE],
    isAuditor,
    isVIP,
    isVIPGradedContent,
    isStudioCohort,
    cohortID: id,
    courseId,
    auditContentLock,
    courseResourcesSchedule
  }
  return getChapterLockCode(chapterIndex, lockCodeOptions)
}

function getSectionExerciseLockCode (exercise, opts) {
  const {
    isAdmin,
    isStudioCohort,
    isVIP,
    isVIPGradedContent
  } = opts

  if (
    isAdmin ||
    isVIPGradedContent ||
    isStudioCohort ||
    config.isPreviewCourse
  ) return false

  const {
    studentProgress,
    isAuditor,
    guessworkSectionUUID,
    isCohortEndedForStudent
  } = opts
  const exercizeType = typeof exercise === 'string' ? exercise : getExercizeType(exercise)

  // Unlock all exercises but Quiz for VIP accounts
  if (isVIP && exercizeType !== QUIZ) return false

  if (isAuditor && exercizeType === QUIZ) {
    return (
      isCohortEndedForStudent && getSectionExerciseLockCode.reason.COURSE_ENDED
    )
  }

  // no guessworkSectionUUID means section has no guesswork and there is
  // also no sibling section(sections in the same chapter) with guesswork before it.
  // so we unlock all section items.
  if (isAuditor || !guessworkSectionUUID) return false

  if (exercizeType === GUESSWORK) return getGuessworkLockCode(opts)

  if (exercizeType === QUIZ && opts.isNoAssessments) return getSectionExerciseLockCode.reason.NO_ASSESSMENTS

  if (exercizeType === QUIZ && ((opts.finalExamLockDate !== null) && (opts.currentDate >= opts.finalExamLockDate))) return getSectionExerciseLockCode.reason.AFTER_FINAL_EXAM_DUE_DATE

  if (config.course.unlockExercises) return false

  if (
    isGuessworkCompleteForSection(studentProgress, guessworkSectionUUID)
  ) return false

  return getSectionExerciseLockCode.reason.GUESSWORK_NOT_COMPLETED
}

getSectionExerciseLockCode.reason = {
  GUESSWORK_NOT_COMPLETED: 1,
  AFTER_FINAL_EXAM_DUE_DATE: 2,
  NO_ASSESSMENTS: 3,
  COURSE_ENDED: 4
}

function getSectionExerciseLockText (exercise, opts) {
  const lockCode = getSectionExerciseLockCode(exercise, opts)

  const {
    isVIP
  } = opts

  if (!lockCode) return lockCode

  if ([PREV_EXAM_DUE_DATE, PREV_EXAM_START_DATE].includes(lockCode)) {
    return 'Previous assessment must be completed'
  }

  if (lockCode === getSectionExerciseLockCode.reason.GUESSWORK_NOT_COMPLETED) {
    return 'Complete Guesswork to unlock'
  }

  if (lockCode === getSectionExerciseLockCode.reason.NO_ASSESSMENTS) {
    if (isVIP) return 'Assessment unavailable due to VIP account'
    return 'Assessment unavailable due to enrollment status'
  }

  if (lockCode === getSectionExerciseLockCode.reason.AFTER_FINAL_EXAM_DUE_DATE) {
    const formattedDate = secondsToFormattedDateShort(opts.finalExamLockDate)
    return `Final Exam Past Due (${formattedDate})`
  }

  if (lockCode === getSectionExerciseLockCode.reason.COURSE_ENDED) {
    return 'Course is Closed'
  }
}

// This should have tests, but I have an open question with the Jest team about
// how to test module private functions. I tried using the babel-plugin-rewire
// package (which looks great) but it does not play well with create-react-app
// since we cannot configure babel without ejecting. Moving on for now.
function getExercizeType (exercise) {
  if (Array.isArray(exercise)) {
    return exercise[0].type
  }

  return exercise.type
}

function getMatchedAnswerSet (studentAnswers, question) {
  return studentAnswers.some(answer =>
    question.Question_uuid === answer.uuid
  )
}

function hasBlankAnswers (studentAnswers, questionSet) {
  if (!questionSet.length) { return false }
  return questionSet.some(question => {
    const matchedAnswerSet = getMatchedAnswerSet(studentAnswers, question)
    return !matchedAnswerSet
  })
}

function getUnansweredQuestions (studentAnswers, questionSet) {
  const questionList = []

  if (!questionSet.length) { return [] }
  if (!studentAnswers.length) { return questionSet }
  questionSet.forEach((question) => {
    const matchedAnswerSet = getMatchedAnswerSet(studentAnswers, question)
    if (!matchedAnswerSet) questionList.push(question)
  })
  return questionList
}

function getAnswerForExamQuestions (studentAnswers, questionSet) {
  const answerList = []
  if (!questionSet.length || !studentAnswers.length) { return [] }
  questionSet.forEach((question) => {
    const matchedAnswerSet = studentAnswers.find(answer =>
      question.Question_uuid === answer.uuid
    )
    if (matchedAnswerSet && !matchedAnswerSet.question) {
      answerList.push({
        ...matchedAnswerSet,
        ...(question.codegradeAssignmentId
          ? {
            codegradeAssignmentId: question.codegradeAssignmentId,
            questionType: question.question_type
          } : {})
      })
    }
  })
  return answerList
}

function getAnswerForUnasweredQuestions (questionSet) {
  const answerList = []

  if (!questionSet.length) { return [] }
  questionSet.forEach((question) => {
    answerList.push({
      uuid: question.Question_uuid,
      question: question.Question_uuid,
      answer: null,
      correct: false
    })
  })
  return answerList
}

function getMaxPercent (what, find, per) {
  let found = false
  what = what.map(val => {
    if (val.activeSectionUUID === find) {
      found = true
      if (per > val.percentage) val.percentage = per
    }
    return val
  })

  !found && what.push({
    activeSectionUUID: find,
    percentage: per
  })
  return what
}

/**
 * @param {Object} sectionData activities in a section like guesswork, AL...
 * @returns {Boolean} check if section has active learning
 */
function hasSectionActiveLearning (sectionData) {
  if (!sectionData) return false
  return !!sectionData['active_learning']
}

function checkIfSectionStarted (questionList, studentAnswers) {
  if (!questionList) return false
  for (let questionIndex = 0;
    questionIndex < questionList.length; questionIndex++) {
    const answerExist = studentAnswers.find(answer => answer.uuid ===
      questionList[questionIndex].Question_uuid)
    if (answerExist) return true
  }
  return false
}

/**
 * @param {Object} sectionTypes the section's section_exe
 * @returns {Array|undefined} multi_lecture_videos with video order
 *  if the section has multi_lecture_videos, otherwise undefined
 *  the videos array coming from the api is assumed to be ordered
 *  the displayTitle is the actual title of the video
 */
function getMultipleVideos (sectionTypes) {
  const { multi_lecture_videos: multipleVideos } = sectionTypes
  if (!multipleVideos) return

  const { videos } = multipleVideos
  if (!videos || !videos.length) return

  return videos.map(({ uuid, ...rest }, index) => ({
    id: uuid,
    ...rest
  }))
}

function getVideoByID (id, multipleVideos) {
  if (!multipleVideos || !multipleVideos.length) return
  return multipleVideos.find(video => video.id === id)
}

function getVideosRemainingTime (sectionVideos, videoProgress, completedLectures) {
  const remainingTimes = {}
  sectionVideos.forEach(({ duration, embedCode }) => {
    const isVideoWatched = completedLectures[embedCode]
    if (isVideoWatched) return (remainingTimes[embedCode] = 0)

    const durationInSecond = getSecondsFromTime(duration)
    const progressInSecond = videoProgress[embedCode] || 0
    const remainingSecond = durationInSecond - progressInSecond
    remainingTimes[embedCode] = remainingSecond
  })
  return remainingTimes
}

/**
 * @param {Array} sectionVideos lectureVideos or multipleLectures
 * @param {boolean} isMultipleVideos if the lectureVideos is multipleLectures
 * @returns {boolean} if all lectureVideos have kalturaEmbedCode
 */
function areAllKalturaEmbedCodesValid (sectionVideos, isMultipleVideos) {
  if (isMultipleVideos) {
    return sectionVideos.every(({ kalturaEmbedCode }) => kalturaEmbedCode)
  }

  return sectionVideos.every(
    ({ kaltura_embed_code: kalturaEmbedCode }) => kalturaEmbedCode
  )
}

function getKalturaVideosRemainingTime (sectionVideos, videoProgress, completedLectures) {
  const remainingTimes = {}
  sectionVideos.forEach(({ duration, kalturaEmbedCode }) => {
    const isVideoWatched = completedLectures[kalturaEmbedCode]
    if (isVideoWatched) return (remainingTimes[kalturaEmbedCode] = 0)

    const durationInSecond = getSecondsFromTime(duration)
    const progressInSecond = videoProgress[kalturaEmbedCode] || 0
    const remainingSecond = durationInSecond - progressInSecond
    remainingTimes[kalturaEmbedCode] = remainingSecond
  })
  return remainingTimes
}

function getVideosTime (sectionVideos, isKalturaVideo) {
  const times = {}
  sectionVideos.forEach(({ duration, ...rest }) => {
    const embedCode = rest[isKalturaVideo ? 'kalturaEmbedCode' : 'embedCode']
    const durationInSecond = getSecondsFromTime(duration)
    times[embedCode] = durationInSecond
  })
  return times
}

function getTotalSectionVideosTime (sectionVideos) {
  return sectionVideos.reduce((total, { duration }) => {
    return total + getSecondsFromTime(duration)
  }, 0)
}

function areAllSiblingVideosCompleted (sectionVideos, currentEmbedCode, completedLectures) {
  const siblingVideos = sectionVideos.filter(
    video => video.embedCode !== currentEmbedCode
  )
  return siblingVideos.every(video => completedLectures[video.embedCode])
}

function areAllSiblingKalturaVideosCompleted (sectionVideos, currentEmbedCode, completedLectures) {
  const siblingVideos = sectionVideos.filter(
    video => video.kalturaEmbedCode !== currentEmbedCode
  )
  return siblingVideos.every(video => completedLectures[video.kalturaEmbedCode])
}

function getNumberOfSectionTypes (section) {
  if (!section.data || !section.data.section_exe) return 0
  const sections = section.data.section_exe
  const sectionKeys = Object.keys(sections)
    .filter(key => isValidSection(sections[key]))
  if (sectionKeys.includes('multi_lecture_videos') &&
    sectionKeys.includes('lecture')) return sectionKeys.length - 1
  return sectionKeys.length
}

function isValidSection (section) {
  if (!section) return false
  return Array.isArray(section) ? !!section.length
    : !!Object.keys(section).length
}

function getUrl (urlObject) {
  if (!urlObject) return ''
  if (typeof urlObject === 'string') return urlObject
  const { url } = urlObject
  return typeof url === 'string' ? url : ''
}

function getCourseSections (courseData, includeNonChapters = true) {
  const { chapters } = courseData
  const courseSections = []
  chapters.forEach(chapter => {
    const { type, sections, chapter_uuid: chapterUuid } = chapter
    if (type === 'chapter') {
      return courseSections.push(...sections.map(section => section.section_uuid))
    }
    includeNonChapters &&
    !isPracticeExam(chapterUuid) && courseSections.push(chapterUuid)
  })
  return courseSections
}

function doesLastCardExistInQuestionSet (lastCardUUID, questionSet) {
  if (!lastCardUUID || !questionSet) return false

  return questionSet.some(obj =>
    obj.Question_uuid === lastCardUUID
  )
}

export const isContentGatingUnlocked = (
  { section, studentData, isContentGatingEnabled } = {}
) => {
  if (!isContentGatingEnabled) return true

  if (!section || !section.section_exe) return false

  const {
    [LECTURE_COMPLETE]: completedLectures,
    [LECTURE_VIDEO_PROGRESS]: videoProgress,
    [PRACTICE_EXERCISES_COMPLETE]: completedPracticeExercises,
    studentAnswers
  } = studentData

  const { section_exe: sectionData } = section

  const activeLearningUnlocked = isContentGatingActiveLearningUnlocked(
    sectionData, studentAnswers
  )

  const lectureUnlocked = isContentGatingLectureUnlocked({
    sectionData,
    completedLectures,
    videoProgress
  })

  const practiceExerciseUnlocked = isContentGatingPracticeExerciseUnlocked(
    section, completedPracticeExercises
  )

  return !!(activeLearningUnlocked && lectureUnlocked && practiceExerciseUnlocked)
}

export const isContentGatingActiveLearningUnlocked = (
  sectionData, studentAnswers
) => {
  const { active_learning: activeLearning } = sectionData

  // If section does not have active learning, consider active learning state as unlocked.
  if (!activeLearning?.Question) return true

  if (config.course.hasActiveLearningTheme) {
    return has70PercentCardsCompletedForThemes(activeLearning, studentAnswers)
  }

  return has70PercentCardsCompleted(
    activeLearning.Question,
    studentAnswers
  )
}

export const has70PercentCardsCompletedForThemes = (
  activeLearning, studentAnswers
) => {
  const { Question } = activeLearning
  const groupedCardsByTheme = groupBy(
    Question, card => card.instructor?.['theme_name'] || ''
  )

  return Object.values(groupedCardsByTheme).some(cards => {
    return has70PercentCardsCompleted(cards, studentAnswers)
  })
}

export const isContentGatingLectureUnlocked = (
  { sectionData, completedLectures, videoProgress }
) => {
  const {
    lecture,
    multi_lecture_videos: multipleVideos
  } = sectionData

  // If section do not have lecture or multiple videos, we should consider
  // the lecture unlocked state as true
  if (!lecture && !multipleVideos) return true

  const isSingleVideo = lecture && !multipleVideos
  if (isSingleVideo) {
    return isContentGatingSingleVideoLectureUnlocked({
      lecture,
      completedLectures,
      videoProgress
    })
  }

  return shouldUnlockContentGatingLecture({
    sectionData,
    completedLectures,
    videoProgress
  })
}

export const isContentGatingSingleVideoLectureUnlocked = ({
  lecture,
  completedLectures,
  videoProgress
}) => {
  const { lecturevideos } = lecture
  if (!lecturevideos?.length) return true

  return lecturevideos.some(video => {
    const sectionData = {
      lecture: {
        lecturevideos: [video]
      }
    }

    return shouldUnlockContentGatingLecture({
      sectionData,
      completedLectures,
      videoProgress
    })
  })
}

export const shouldUnlockContentGatingLecture = ({
  sectionData,
  completedLectures,
  videoProgress
}) => {
  const lecturesCompleted = has70PercentLecturesCompleted(
    sectionData, completedLectures
  )
  const lecturesWatched = has70PercentSectionLecturesWatched(
    sectionData, videoProgress
  )
  return lecturesCompleted || lecturesWatched
}

export const isContentGatingPracticeExerciseUnlocked = (
  section, completedPracticeExercises
) => {
  const {
    section_uuid: sectionUUID,
    section_exe: {
      practice_exercises: practiceExercises
    }
  } = section

  // If section do no have practice exercises, unlock practice exercise state.
  if (!practiceExercises) return true

  return !!completedPracticeExercises[sectionUUID]
}

export const hasNPercentSectionLecturesWatched = percent => {
  return (sectionObject, videoProgress) => {
    const lectureVideos = getSectionLectureVideos(sectionObject)

    if (!lectureVideos || !lectureVideos.length) return false

    const totalSectionVideosTime = getTotalSectionVideosTime(lectureVideos)
    if (!totalSectionVideosTime) return false

    const videosWatchedTime = getSectionVideosWatchedTime(
      lectureVideos, videoProgress
    )

    const watchedPercent = (videosWatchedTime * 100) / totalSectionVideosTime
    return watchedPercent >= percent
  }
}

export const has70PercentSectionLecturesWatched = hasNPercentSectionLecturesWatched(70)

export const getSectionVideosWatchedTime = (lectureVideos, videoProgress) => {
  if (!lectureVideos || !lectureVideos.length || !videoProgress) return 0

  return lectureVideos.reduce((total, video) => {
    const progress = videoProgress[video.kalturaEmbedCode]

    return progress ? total + progress : total
  }, 0)
}

export const hasNPercentLecturesCompleted = percent => (sectionObject, completedLectures) => {
  const lectureVideos = getSectionLectureVideos(sectionObject)

  if (!lectureVideos || !lectureVideos.length) return false

  const totalVideos = lectureVideos.length
  const numberOfComplectedLectures = getNumberOfCompletedLectures(
    lectureVideos, completedLectures
  )

  const completedPercent = (numberOfComplectedLectures * 100) / totalVideos
  return completedPercent >= percent
}

export const getNumberOfCompletedLectures = (lectureVideos, completedLectures) => {
  if (!lectureVideos || !lectureVideos.length || !completedLectures) return 0

  return lectureVideos.reduce((total, video) => {
    const isVideoCompleted = completedLectures[video.kalturaEmbedCode]

    return isVideoCompleted ? total + 1 : total
  }, 0)
}

export const getSectionLectureVideos = sectionObject => {
  if (!sectionObject) return null

  const {
    multi_lecture_videos: multipleVideos,
    lecture
  } = sectionObject

  // prefer multi lecture videos over lecture videos
  if (multipleVideos) return multipleVideos.videos

  if (lecture) return addKalturaEmbedCodeToVideos(lecture.lecturevideos)

  return null
}

export const addKalturaEmbedCodeToVideos = lectureVideos => {
  if (!lectureVideos) return null

  return lectureVideos.map(video => {
    if (video.kalturaEmbedCode) return video

    return {
      ...video,
      kalturaEmbedCode: video.kaltura_embed_code
    }
  })
}

export const has70PercentLecturesCompleted = hasNPercentLecturesCompleted(70)

export const getPreviousSectionWithQuiz = (sectionUUID, chapters) => {
  const previousSection = getPreviousSection(sectionUUID, chapters)

  if (!previousSection) return null
  const { quizUUIDs, section_uuid: prevSectionUUID } = previousSection
  if (quizUUIDs?.length) return previousSection

  return getPreviousSectionWithQuiz(prevSectionUUID, chapters)
}

export const getSectionGuessworkUUID = (section, chapters) => {
  const {
    section_exe: sectionTypes,
    section_uuid: sectionUUID
  } = section

  const hasGuesswork = !!sectionTypes.guesswork
  if (hasGuesswork) return sectionUUID

  const previousSectionWithGuesswork = getPreviousSectionWithGuesswork(
    sectionUUID, chapters
  )

  if (!previousSectionWithGuesswork) return null
  const {
    section_uuid: prevSectionUUID
  } = previousSectionWithGuesswork

  return prevSectionUUID
}

export const getPreviousSectionWithGuesswork = (sectionUUID, chapters) => {
  const previousSection = getPreviousSection(sectionUUID, chapters)

  if (!previousSection) return null
  const { guessworkUUID, section_uuid: prevSectionUUID } = previousSection
  if (guessworkUUID) return previousSection

  return getPreviousSectionWithGuesswork(prevSectionUUID, chapters)
}

export const getPreviousSection = (sectionUUID, chapters) => {
  const currentChapter = getChapterOfASection(sectionUUID, chapters)

  if (!currentChapter) return null

  const { sections } = currentChapter

  const currentSectionIndex = sections.findIndex(section => section.section_uuid === sectionUUID)
  const isFirstSection = currentSectionIndex === 0

  if (!isFirstSection) return sections[currentSectionIndex - 1]

  const currentChapterIndex = chapters.findIndex(
    chapter => chapter.chapter_uuid === currentChapter.chapter_uuid
  )

  return getLastSectionFromPrevChapters(currentChapterIndex, chapters)
}

export const getLastSectionFromPrevChapters = (chapterIndex, chapters) => {
  const previousChapters = chapters.slice(0, chapterIndex)
  let previousChapter = null

  for (let i = previousChapters.length - 1; i >= 0; i--) {
    const chapter = previousChapters[i]

    if (chapter.type !== CHAPTER) continue
    const { sections } = chapter

    if (!sections || !sections.length) continue

    previousChapter = chapter
    break
  }

  if (!previousChapter) return null

  const { sections } = previousChapter
  return sections[sections.length - 1]
}

export const getChapterOfASection = (sectionUUID, chapters) => {
  if (!sectionUUID || !chapters || !chapters.length) return null

  const currentChapter = chapters.find(chapter => {
    const isChapterOrOrientation =
      chapter.type === CHAPTER || chapter.type === ORIENTATION
    if (!isChapterOrOrientation) return false

    const { sections } = chapter

    return sections.some(section => section.section_uuid === sectionUUID)
  })

  return currentChapter || null
}

export const hasSectionACompletedQuiz = (quizUUIDs, studentProgress) => {
  if (!quizUUIDs?.length || !studentProgress) return false

  const quizSection = studentProgress[QUIZ_SECTION]
  return quizUUIDs.some(uuid => uuid in quizSection)
}

export const shouldLockSection = ({
  isVIP,
  isAdmin,
  isAuditor,
  lockReason,
  studentData,
  sectionItem,
  chapterLoopId,
  isStudioCohort,
  isNoAssessments,
  sectionListLoopId,
  isVIPGradedContent,
  isContentGatingEnabled
}) => {
  const isFirstSection = (chapterLoopId === 1) && (sectionListLoopId === 0)
  if (isFirstSection) return false

  if (!isContentGatingEnabled) return lockReason

  const { previousSectionWithQuiz } = sectionItem
  if (!previousSectionWithQuiz?.quizUUIDs) return lockReason

  const { quizUUIDs } = previousSectionWithQuiz
  const hasCompletedQuiz = hasSectionACompletedQuiz(quizUUIDs, studentData)

  const unlockAllSections = isAdmin ||
    isAuditor ||
    isVIP ||
    isStudioCohort ||
    isVIPGradedContent ||
    config.isPreviewCourse

  const lockSection = !unlockAllSections && !isNoAssessments && !hasCompletedQuiz

  return lockReason || lockSection
}

export const getTotalQuestionSetForSectionProgress = (section, totalQuestionSet) => {
  if (
    !section || config.course.shouldAddReadingsInSectionProgress
  ) return totalQuestionSet

  const hasReadings = !!section.readings

  if (!hasReadings) return totalQuestionSet
  return totalQuestionSet - 1
}

export const getSectionProgressCompleteKeys = () => {
  return [
    QUIZ_COMPLETE,
    EXAM_COMPLETE,
    LECTURE_COMPLETE,
    GUESSWORK_COMPLETE,
    ACTIVE_LEARNING_COMPLETE,
    ASSIGNMENT_PROGRESS,
    ...(config.course.shouldAddReadingsInSectionProgress
      ? [READINGS_COMPLETE] : []
    ),
    PRACTICE_TERM_COMPLETE,
    CONCEPT_MAP_COMPLETE,
    PRACTICE_EXERCISES_COMPLETE
  ]
}

function getSectionType (sectionsArray, index) {
  if (!sectionsArray || (!index && index !== 0) || !sectionsArray[index]) return

  return sectionsArray[index].data?.worktype
}

export const hasActiveLearningOpened = (activeLearningOpened, sectionUUID) => {
  return activeLearningOpened[sectionUUID]
}

export const isOutlierOrVipAccount = (options) => {
  const {
    isAdmin,
    isStudioCohort,
    isVIPGradedContent
  } = options

  if (
    isAdmin ||
    isStudioCohort ||
    isVIPGradedContent ||
    config.isPreviewCourse
  ) return true

  return false
}

export const getActiveLearningLockText = () => {
  return 'Locked until you engage with Active Learning'
}

export const getRubric = ({
  rubric,
  updatedRubric,
  useUpdatedRubric,
  rubrics,
  cohortStartDate
}) => {
  if (rubrics?.length) {
    const rubricsWithStartDate = rubrics.map((_rubric) => ({
      ..._rubric,
      startDate: getCohortStartSecondsSinceEpoch({ dateStart: _rubric.cohortStartDate })
    })).sort((a, b) => b.startDate - a.startDate)

    const cohortRubric = rubricsWithStartDate.find(_rubric => cohortStartDate >= _rubric.startDate)
    if (cohortRubric) return cohortRubric.url
  }

  if (useUpdatedRubric) return updatedRubric || rubric
  return rubric
}

export const getScheduleWithSectionUUIDs = (schedule, chapters) => {
  if (!schedule || !chapters) return schedule

  const { syllabusData } = schedule
  if (!Array.isArray(syllabusData)) return schedule

  const sectionsNumberMap = getSectionsNumberMap(chapters)

  const scheduleWithUUIDs = syllabusData.map(_schedule => {
    const sectionsWithUUIDs = _schedule.sections.map(section => {
      if (section.assignmentType !== SECTION) return section

      return {
        ...section,
        uuid: sectionsNumberMap.get(getSectionNumberFromScheduleTitle(section.title))
      }
    })

    return {
      ..._schedule,
      sections: sectionsWithUUIDs
    }
  })

  return {
    ...schedule,
    syllabusData: scheduleWithUUIDs
  }
}

const defaultKeyMapper = ({ chapterIndex, sectionIndex }) => {
  return `${chapterIndex + 1}.${sectionIndex + 1}`
}

export const getSectionsNumberMap = (courseChapters, keyMapper = defaultKeyMapper) => {
  const sectionsMap = new Map()
  const chapters = courseChapters.filter(chapter => chapter.type === CHAPTER)

  chapters.forEach(({ sections }, chapterIndex) => {
    sections.forEach((section, sectionIndex) => {
      sectionsMap.set(
        keyMapper({ chapterIndex, sectionIndex, section }),
        section.section_uuid
      )
    })
  })

  return sectionsMap
}

export const isSectionProgressed = async (sectionUUID, studentProgress) => {
  if (!sectionUUID) return false

  const {
    [LAST_GUESSWORK_UUID]: lastGuessworkUUID,
    [LAST_ACTIVE_LEARNING_UUID]: lastActiveLearningUUID,
    [LAST_QUIZ_UUID]: lastQuizUUID,
    [LECTURE_COMPLETE]: lectureComplete,
    [LECTURE_VIDEO_PROGRESS]: lectureVideoProgress,
    [STUDENT_ANSWERS]: studentAnswers
  } = studentProgress

  const lastGuessworkUuids = Object.keys(lastGuessworkUUID)
  const lastActiveLearningUuids = Object.keys(lastActiveLearningUUID)
  const lastQuizUuids = Object.keys(lastQuizUUID)

  const isGuessworkVisited = lastGuessworkUuids.includes(sectionUUID)
  if (isGuessworkVisited) return true

  const isActiveLearningVisited = lastActiveLearningUuids.includes(sectionUUID)
  if (isActiveLearningVisited) return true

  const isQuizVisited = lastQuizUuids.includes(sectionUUID)
  if (isQuizVisited) return true

  const { section_exe: sectionExe } = await loadSectionData({
    activeCourseUUID: config.courseId, uuid: sectionUUID
  })

  if (!sectionExe) return false

  const {
    lecture,
    multi_lecture_videos: multipleVideos,
    practice_exercises: practiceExercises
  } = sectionExe

  const isLectureVisited = isSectionLectureVisited({
    sectionUUID,
    lecture,
    multipleVideos,
    lectureComplete,
    lectureVideoProgress
  })

  if (isLectureVisited) return true
  if (isSectionPracticeExerciseVisited(practiceExercises, studentAnswers)) return true

  return false
}

export const isSectionPracticeExerciseVisited = (practiceExercises, studentAnswers) => {
  if (!Array.isArray(practiceExercises) || !Array.isArray(studentAnswers)) return false

  const problemBanksMap = new Map(studentAnswers.map(answer => {
    if (answer.type !== 'problem_banks') return null

    return [answer.uuid, answer]
  }).filter(Boolean))

  return practiceExercises.some(practice => problemBanksMap.has(practice.question_set_uuid))
}

export const isSectionLectureVisited = ({
  sectionUUID,
  lecture,
  multipleVideos,
  lectureComplete,
  lectureVideoProgress
}) => {
  // If no video watched, return false
  if (isEmpty(lectureVideoProgress)) return false

  // If section video is completed, return true
  if (lectureComplete[sectionUUID]) return true

  if (isEmpty(multipleVideos)) {
    // check for instructor videos
    const lectureVideos = get(lecture, 'lecturevideos', [])

    return lectureVideos.some(({ kaltura_embed_code: kalturaEmbedCode }) => {
      return kalturaEmbedCode in lectureVideoProgress
    })
  }

  // multiple videos
  const videos = get(multipleVideos, 'videos', [])

  return videos.some(({ kalturaEmbedCode }) => {
    return kalturaEmbedCode in lectureVideoProgress
  })
}

export const getSectionNumberFromScheduleTitle = scheduleTitle => {
  if (!scheduleTitle) return scheduleTitle

  return scheduleTitle.split('|')[1]?.trim()
}

export const getActivityType = (activity = {}) => {
  if (!activity) return {}

  const isLecture = activity?.type === 'LectureVideoRecord'
  const isMultiLecture = activity?.type === 'MultiLectureVideoRecord'

  const isPracticeTerms =
    activity?.url?.includes('quizlet') || activity?.type === 'PracticeTermRecord'

  const isProblemBank = activity?.type === PROBLEM_BANK

  return {
    isLecture, isMultiLecture, isPracticeTerms, isProblemBank
  }
}

export const getPracticeDrawerActivities = (practiceDrawer = []) => {
  if (!practiceDrawer) return []

  const activities = []

  practiceDrawer.forEach(activity => {
    const {
      isLecture, isMultiLecture, isPracticeTerms, isProblemBank
    } = getActivityType(activity)

    if (isLecture || isMultiLecture) {
      const {
        type, title, kaltura_embed_code: kalturaEmbedCode,
        lecturevideos_uuid: uuid, ...rest
      } = activity

      return activities.push({
        type: LECTURE,
        isPracticeDrawer: true,
        multipleVideos: [{
          id: uuid,
          title,
          displayTitle: title,
          kalturaEmbedCode,
          isPracticeDrawer: true,
          ...rest
        }]
      })
    }

    if (isProblemBank) {
      const {
        intro_image: introImage = {}, question, ...rest
      } = activity

      return activities.push({
        type: PROBLEM_BANK,
        exercice: [{
          intro_image: introImage?.url,
          Question: question,
          isPracticeDrawer: true,
          ...rest
        }]
      })
    }

    if (isPracticeTerms) {
      const { type, ...rest } = activity
      return activities.push({
        type: PRACTICE_TERMS,
        ...rest
      })
    }
  })

  return activities
}

export const getPracticeActivities = exerciseList => {
  const {
    conceptMap,
    practiceDrawer = [],
    practice_terms: practiceTerms
  } = exerciseList

  const activities = []
  const practiceDrawerActivities = getPracticeDrawerActivities(practiceDrawer)
  const havePracticeTerms = practiceDrawerActivities?.find(
    activity => activity?.type === PRACTICE_TERMS)

  if (conceptMap) {
    activities.push({
      type: CONCEPT_MAP,
      ...conceptMap
    })
  }

  if (practiceTerms && !havePracticeTerms) {
    activities.push({
      type: PRACTICE_TERMS,
      ...practiceTerms
    })
  }

  return [...activities, ...practiceDrawerActivities]
}

const addItemsToExerciseListArray = (items, tag, type, exerciseListArr) => {
  const itemsWithTag = items.filter((item) => item[tag])
  const itemsWithoutTag = items.filter((item) =>
    !(tag in item) || item[tag] === ''
  )
  const getActivities = (item) => {
    switch (type) {
      case LECTURE: return ({
        type: LECTURE,
        multipleVideos: [{ ...item }]
      })
      case ACTIVE_LEARNING: return ({
        type: ACTIVE_LEARNING,
        exercise: item
      })
      case PROBLEM_BANK: return ({
        type: PROBLEM_BANK,
        exercise: [{ ...item }]
      })
      default: return []
    }
  }

  if (itemsWithTag?.length) {
    itemsWithTag.forEach((item) => {
      const tagName = item[tag]
      let foundMatchingTag = false

      for (const exercise of exerciseListArr) {
        const hasSameSubsectionTag = exercise?.type === SUBSECTION &&
          exercise?.tag === tagName

        if (hasSameSubsectionTag) {
          const activity = getActivities(item)
          exercise.activities.push(activity)
          foundMatchingTag = true
          break
        }
      }

      if (!foundMatchingTag) {
        exerciseListArr.push({
          type: SUBSECTION,
          tag: tagName,
          activities: [{ ...getActivities(item) }]
        })
      }
    })
  }

  if (itemsWithoutTag?.length) {
    if (type === LECTURE) {
      exerciseListArr.push({
        type: 'lecture',
        videos: itemsWithoutTag
      })
    } else {
      exerciseListArr.push(itemsWithoutTag)
    }
  }
}

export const getExerciseListArray = (section) => {
  const {
    guesswork,
    lecture,
    readings,
    multi_lecture_videos: multiLectureVideos,
    active_learning: activeLearning,
    includeDiscussionLink,
    practice_exercises: practiceExercises,
    practice_terms: practiceTerms,
    practiceDrawer,
    conceptMap,
    quiz
  } = section
  const exerciseListArr = []

  const hasPracticeTerms = practiceTerms && Object.keys(practiceTerms)?.length
  const hasConceptMap = conceptMap && Object.keys(conceptMap)?.length
  const hasOnlyPracticeTerms = hasPracticeTerms && !hasConceptMap && !practiceDrawer?.length

  const {
    course: {
      showReadingsAfterActiveLearning
    }
  } = config

  if (guesswork) exerciseListArr.push(guesswork)
  if (!showReadingsAfterActiveLearning && readings?.length) {
    exerciseListArr.push({ type: 'readings', readings: [...readings] })
  }
  if (multiLectureVideos) {
    const lectureVideos = multiLectureVideos?.videos || []
    const tag = 'subsectionDrawerTag'
    addItemsToExerciseListArray(
      lectureVideos,
      tag,
      LECTURE,
      exerciseListArr
    )
  }
  if (lecture && !multiLectureVideos) exerciseListArr.push(lecture)
  if (activeLearning) exerciseListArr.push(activeLearning)
  if (includeDiscussionLink) exerciseListArr.push({ type: DISCUSSION })
  if (showReadingsAfterActiveLearning && readings?.length) {
    exerciseListArr.push({ type: 'readings', readings: [...readings] })
  }
  if (practiceExercises) exerciseListArr.push(practiceExercises)
  if (hasOnlyPracticeTerms) {
    exerciseListArr.push({ type: 'Practice Terms', ...practiceTerms })
  }
  if (!hasOnlyPracticeTerms) {
    const activities = getPracticeActivities(section)
    if (activities.length) {
      exerciseListArr.push({ type: 'practice', activities })
    }
  }
  if (quiz) exerciseListArr.push(quiz)

  return exerciseListArr
}

export const transformActivityType = (activityType) => {
  switch (activityType) {
    case 'lecture':
      return 'Lectures'
    case 'Problem Bank':
      return 'Practice Exercises'
    case 'Quiz':
      return 'Quizzes'
    case 'readings':
      return 'Readings'
    case 'practice':
      return 'Practice'
    case 'practice_term':
      return 'Practice Terms'
    default:
      return activityType
  }
}

export const getActivityTypeName = (activityType) => {
  switch (activityType) {
    case 'practice_term':
      return 'Practice Terms'
    case 'reading':
      return 'readings'
    case 'Practice Exercises':
      return 'Problem Bank'
    default:
      return activityType
  }
}

export const getPracticeActivity = (activity) => {
  let type, uuid
  if (activity.type === 'Lecture') {
    type = 'Lecture'
    uuid = activity.multipleVideos[0].id
  } else if (activity.type === 'Practice Terms') {
    type = 'Practice Terms'
    uuid = 'Quizlet'
  } else if (activity.type === 'Problem Bank') {
    type = 'Problem Bank'
    uuid = activity.exercice[0].question_set_uuid
  } else {
    type = activity?.type
    uuid = activity?.uuid
  }
  return { type, uuid }
}

export const getNextActivity = (activityList, currentActivityType, id) => {
  const getNextActivityType = (nextActivity) => {
    if (Array.isArray(nextActivity)) {
      nextActivity = nextActivity[0]
    }
    let { type } = nextActivity
    let uuid
    if (type === 'practice') {
      type = getPracticeActivity(nextActivity.activities[0]).type
      uuid = getPracticeActivity(nextActivity.activities[0]).uuid
    } else {
      if (type === PRACTICE_TERMS) {
        uuid = 'Quizlet'
      } else if (nextActivity.question_set_uuid) {
        uuid = nextActivity.question_set_uuid
      } else if (nextActivity.Question) {
        uuid = nextActivity.Question[0].Question_uuid
      } else if (nextActivity.readings) {
        uuid = nextActivity.readings[0].question_set_uuid
      } else if (nextActivity.videos) {
        uuid = nextActivity.videos[0].uuid
      }
    }
    return { type, uuid }
  }

  const getCurrentActvityIndex = (activityList, currentActivityType) => {
    return activityList?.findIndex(activity => {
      if (Array.isArray(activity)) {
        return activity.some(subActivity =>
          [
            getActivityTypeName(currentActivityType),
            currentActivityType
          ].includes(subActivity.type)
        )
      } else {
        return activity.type === getActivityTypeName(currentActivityType)
      }
    })
  }

  const currentIndex = getCurrentActvityIndex(activityList, currentActivityType)
  const hasPractice = activityList.some(function (activity) {
    return activity.type === 'practice'
  })
  if (hasPractice) {
    const getIndexByUuid = (activities, uuid) => {
      if (uuid?.includes('_')) {
        uuid = uuid.split('_')[0]
      }
      for (let i = 0; i < activities.length; i++) {
        const activity = activities[i]
        const { uuid: activityUuid } = getPracticeActivity(activity)
        if (activityUuid === uuid) {
          return i
        }
      }
      return -1
    }
    const practiceList = activityList?.filter(activity => activity.type === 'practice')[0].activities
    const practiceIndex = getIndexByUuid(practiceList, id)
    const currentIndex = getCurrentActvityIndex(activityList, 'practice')
    if (practiceIndex === practiceList?.length - 1 && currentIndex < activityList.length - 1) {
      return getNextActivityType(activityList?.[currentIndex + 1])
    } else if (practiceIndex !== -1 && practiceIndex < practiceList.length - 1) {
      const { type, uuid } = getPracticeActivity(practiceList[practiceIndex + 1])
      return { type, uuid }
    }
  }
  if (currentIndex === -1 || currentIndex === activityList?.length - 1) {
    return { type: 'Next Section' }
  }
  const nextActivity = activityList?.[currentIndex + 1]
  if (typeof nextActivity === 'object' || Array.isArray(nextActivity)) {
    return getNextActivityType(nextActivity)
  }
  return null
}

export const loadSectionData = async ({
  activeCourseUUID, uuid, isAuditor, latestCohort, officialCourseName
}) => {
  const data = await api.fetchSectionData({ activeCourseUUID, uuid })
  let sectionData = data

  if (isAuditor) {
    const { dateStart, finalExamEndTime, cohortEndTime, duration } = latestCohort || {}
    const isCohortInProgress = isInProgressBeforeCutOffDate({
      dateStart, finalExamEndTime, cohortEndTime, duration, officialCourseName
    })
    sectionData = isCohortInProgress
      ? data
      : removeQuizzesFromSection(data)
  }
  return sectionData
}
