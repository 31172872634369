import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 9px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: #161618;
  border-radius: 5px;
  padding: 16px 18px 16px 19px;
  font-size: 16px;
  line-height: 19px;
  color: #B1BFC5;
  display: flex;
  flex: 1;
  font-weight: 400;
`

export const Icon = styled.img`
`

export const CrossIcon = styled.img`
  cursor: pointer;
`

export const StatusText = styled.div`
  margin-left: 11px;
`

export const RightSection = styled.div`
  margin-left: auto;
`

export const Link = styled.a`
  color: #5FC4B8;
  border-bottom: 1px solid #5FC4B8;

  &:hover {
    color: #5FC4B8;
    text-decoration: none;
  }
`
