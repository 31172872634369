import React, { useMemo, memo, useContext } from 'react'
import {
  FIRST_EDITION_SUBDOMAIN,
  DISCORD,
  YELLOWDIG
} from '../../../../Constants'
import config from '../../../../config'
import {
  getValidDiscussionLinkType,
  getIsStudentWithdrawn,
  getIsVIPCohort,
  getYellowdigUrl
} from '../../../../utilities/courseUtils'
import {
  isStudentAllowedToViewDiscussionLink
} from '../../../../utilities/studentPermissionUtils'
import { LinksHeader, LinksSection } from '../styled'
import LinkItem from '../LinkItem/LinkItem'
import api from '../../../../api'
import {
  getOrientationUrl,
  getSkooliTutoringTexts
} from '../../../../utilities/courseToolkit'
import { skooliTutoringCourses } from '../../../../Constants/courseToolkit'
import { emitter } from '../../../Emitter/Emitter'
import { ON_TRACK_STUDENT_EVENT } from '../../../../Constants/emitterKeys'
import { SKOOLI_AUDIT_STUDENTS } from '../../../../Constants/eventTypes'
import { ONCE } from '../../../../Constants/frequency'
import Context from '../../../Context/Context'

const showJSTORIn = ['collegewriting-i', 'collegewriting-i.plus']

const removeSpace = text => text.replace(/\s/g, '').trim()

const LinksContent = ({
  cohortData,
  studentCourses,
  studentId,
  isAuditor,
  isAdmin
}) => {
  const {
    studentStatus,
    attemptID,
    name,
    startDate,
    cohortID,
    isGGUCohort,
    creditGrantingInstitution
  } = cohortData

  const {
    courseData: {
      advising: showAdvising,
      chapters: courseChapters = []
    } = {},
    activeCourse: { profCert } = {}
  } = useContext(Context) || {}

  const skooliHandler = async (cohortID) => {
    const { data, error } = await api.getSkooliUrl(cohortID)
    if (error || !data) return

    if (isAuditor) {
      emitter.emit(
        ON_TRACK_STUDENT_EVENT,
        {
          event: SKOOLI_AUDIT_STUDENTS,
          properties: {
            attempt: attemptID,
            studentId: studentId
          },
          frequency: ONCE
        }
      )
    }

    window.open(data.launchUrl)
  }
  const {
    courseData: {
      desmosCalculator: showDesmosCalculator,
      tutoring: showTutoring
    } = {}
  } = useContext(Context) || {}

  // eslint-disable-next-line
  const isGGU1_11_23Cohort =  useMemo(() => {
    // eslint-disable-next-line
    return isGGUCohort && startDate === config.ggu1_11_23StartDate;
  }, [isGGUCohort, startDate])

  const useSkooliTutoringForCourse = config.shouldUseSkooliTutoringUsernsap({
    courseName: config.courseName,
    isGGUCohort,
    cohortStartDate: startDate,
    creditGrantingInstitution
  })

  const isStudentWithdrawnOrVipCohort =
    getIsStudentWithdrawn(studentStatus) || getIsVIPCohort(name)
  const isAdminAndVipCohort = isAdmin && getIsVIPCohort(name)

  // Discussion
  const discussionItem = useMemo(() => {
    const hasPermissionToViewDiscussionLink = isStudentAllowedToViewDiscussionLink(
      studentId
    )

    const hideDiscussionLink = !hasPermissionToViewDiscussionLink ||
      isStudentWithdrawnOrVipCohort || isAdminAndVipCohort

    if (hideDiscussionLink) return null

    const discussionLinkType = getValidDiscussionLinkType(studentCourses)
    const discussion = {
      title: 'Discussion',
      text: 'Connect with your classmates.'
    }

    if (discussionLinkType === DISCORD) {
      return {
        ...discussion,
        link: {
          url: 'https://discord.gg/eTTBdaUkP7',
          target: '_blank'
        }
      }
    }

    if (discussionLinkType !== YELLOWDIG) return null

    return {
      ...discussion,
      // eslint-disable-next-line
      title: isGGU1_11_23Cohort ? 'Discussion via YellowDig' : discussion.title,
      onClick: async () => {
        const launchUrl = await getYellowdigUrl(cohortData)
        window.open(launchUrl)
      }
    }
  }, [
    studentId,
    studentCourses,
    cohortData,
    isStudentWithdrawnOrVipCohort,
    isAdminAndVipCohort,
    // eslint-disable-next-line
    isGGU1_11_23Cohort
  ])

  // Syllabus
  const syllabusItem = useMemo(() => {
    return {
      title: 'Syllabus',
      text: 'Read the full outline of your course.',
      link: {
        url: '#/resources/syllabus'
      }
    }
  }, [])

  // Orientation
  const orientationItem = useMemo(() => {
    const url = getOrientationUrl({ courseChapters, isGGUCohort, profCert })
    return {
      title: 'Orientation',
      text: 'Watch the course introduction.',
      link: {
        url
      }
    }
  }, [isGGUCohort, courseChapters, profCert])

  // Tutoring
  const tutoringItem = useMemo(() => {
    if (isAdminAndVipCohort) return null

    const tutoring = {
      title: 'Tutoring',
      text: 'Receive private help from an expert.'
    }

    const shouldUseSkooli = startDate >= config.skooliUpdateDate
    const isPhilosophy = ['philosophy', 'philosophy.plus'].includes(config.courseName)
    // This code is just to give access to tutoring for philosophy course
    // with cohort start date greater or equal to skooli update date

    if (isPhilosophy && shouldUseSkooli) {
      return {
        ...getSkooliTutoringTexts(config.courseName),
        onClick: () => skooliHandler(cohortID)
      }
    }

    // Do not show tutoring if course has no access to it
    if (!showTutoring) return null

    // Only show skooli link for skooli courses and cohort start date greater
    // or equal to Oct-12-22
    // or if course is financialaccounting and cohort start date is greater or equal to Jun-14-23
    const shouldShowSkooliTutoringLink =
      skooliTutoringCourses.includes(config.courseName) &&
      startDate >= config.skooliUpdateDate &&
      useSkooliTutoringForCourse

    if (shouldShowSkooliTutoringLink) {
      return {
        ...getSkooliTutoringTexts(config.courseName),
        onClick: () => skooliHandler(cohortID)
      }
    }

    return {
      ...tutoring,
      link: { url: 'https://www.brainfuse.com/login/' }
    }
    // eslint-disable-next-line
  }, [startDate, isAdminAndVipCohort, cohortID])

  // Desmos
  const desmosItem = useMemo(() => {
    if (!showDesmosCalculator) return null

    return {
      title: 'Desmos Calculator',
      text: 'Use a digital graphing calculator.',
      link: { url: 'https://www.desmos.com/calculator' }
    }
    // eslint-disable-next-line
  }, [])

  // JSTOR
  const JSTORItem = useMemo(() => {
    if (!showJSTORIn.includes(config.courseName)) return null

    return {
      title: 'Digital Library (JSTOR)',
      text: 'Explore sources for your writing assignments.',
      link: {
        url: 'https://www.jstor.org',
        target: '_blank'
      }
    }
  }, [])

  // Time Management
  const timeManagement = useMemo(() => {
    return {
      title: 'Time Management',
      text: 'Master time management and beat procrastination.',
      link: {
        url: `https://collegesuccess.${FIRST_EDITION_SUBDOMAIN}.outlier.org/#/ckyt50tz000003e5zgngo24g8`,
        ...(config.course.openTimeManagementLinkInNewTab ? { target: '_blank' } : {})
      }
    }
  }, [])

  const advising = useMemo(() => {
    if (!showAdvising) return null

    return {
      title: 'Advising',
      text: 'Schedule time to talk to an advisor.',
      link: {
        url: 'https://info.outlier.org/meetings/outlier-brandon/procert-advising',
        target: '_blank'
      }
    }
  }, [showAdvising])

  const links = useMemo(() => {
    return [
      discussionItem,
      syllabusItem,
      orientationItem,
      tutoringItem,
      advising,
      desmosItem,
      JSTORItem,
      timeManagement
    ].filter(Boolean)
  }, [JSTORItem, desmosItem, discussionItem, orientationItem, advising, syllabusItem, timeManagement, tutoringItem])

  return (
    <div>
      <LinksHeader>
        Links
      </LinksHeader>
      <LinksSection data-cy='linksList' count={links.length}>
        {
          links.map(link => (
            <LinkItem
              id={`link-item-${removeSpace(link.title)}`}
              key={link.title}
              item={link}
            />)
          )
        }
      </LinksSection>
    </div>
  )
}

export default memo(LinksContent)
