import styled from 'styled-components'

export const Section = styled.main`
  display: flex;
  flex-direction: column;
  background: #172D31 !important;
  padding: 30px 30px 100px;
`

export const Heading = styled.h3`
  color: #77bbb5;
  font-family: Helvetica !important;
  font-weight: bold;
`

export const Date = styled.p`
  font-weight: bold;
  font-size: calc(12px + var(--font-increase-px));
  border-left: 1px solid;
  margin-left: -10px;
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 67px;
`

export const Subtitle = styled.p`
  font-weight: bold;
  font-size: calc(15px + var(--font-increase-px));
`

export const Paragraph = styled.p`
  font-size: calc(12px + var(--font-increase-px));
`

export const CenteredSubTitle = styled.p`
  font-size: calc(12px + var(--font-increase-px));
  text-align: center;
  font-weight: bold;
`

export const ListItem = styled.li`
  font-size: calc(12px + var(--font-increase-px));
  font-weight: 300;
`
