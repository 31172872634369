import React, { Component } from 'react'
import { CourseMaterialWrapper } from './styles'
class CourseMaterialDownload extends Component {
  constructor (props) {
    super(props)
    this.fileDownload = this.fileDownload.bind(this)
    this.state = {
    }
  }

  fileDownload (fileName) {
    window.outlierLog('Course Material Download', fileName)
  }

  render () {
    // const { course_download } = this.props
    const courseDownload = this.props.course_download
    var courseLink = ''
    if (courseDownload && courseDownload.length > 0) {
      courseLink = courseDownload.map((courseLinks, i) => {
        var url = courseLinks.file
        var splitedUrl = url.split('/')
        var decodedUrl = decodeURI(splitedUrl[splitedUrl.length - 1])
        var withoutExtensionUrl = decodedUrl.split('.').slice(0, -1).join('.')
        var fileName = (courseLinks.title) ? courseLinks.title : withoutExtensionUrl

        return (
          <li key={i + 1} className='d-inline-block'>
            <i className='far fa-file ml-2 pr-2' aria-hidden='true' />
            {fileName}
            <a
              href={courseLinks.file}
              onClick={() => this.fileDownload(fileName)}
              download
              rel='noopener noreferrer'
              target='_blank'
              data-cy='courseMaterialDownloadLink'
            >
              <span>VIEW</span>
            </a>
          </li>
        )
      })
      return (
        <CourseMaterialWrapper>
          {courseLink}
        </CourseMaterialWrapper>
      )
    } else {
      return (
        <div style={{ height: '44px' }} data-testid='empty-div' />
      )
    }
  }
}
export default CourseMaterialDownload
