import {
  PSYCHOLOGY,
  CALCULUS,
  CALCULUS_FP,
  STATISTICS,
  ASTRONOMY,
  MICROECONOMICS,
  PHILOSOPHY
} from '../../../Constants/courseNames'

export const LEARNING_OUTCOMES = {
  [PSYCHOLOGY]: [
    'Discuss foundational concepts and research findings in psychology',
    'Summarize the key concepts of major subdisciplines of psychology',
    'Describe psychological research methods',
    'Evaluate psychological research findings',
    'Describe the ethical considerations and principles used in psychological research and clinical practice'
  ],
  [CALCULUS]: [
    'Find limits of functions presented as graphs, tables, or algebraic expressions',
    'Use the concept of limit to define the derivative of functions',
    'Differentiate functions involving polynomials, exponentials, logarithms and trigonometric terms',
    'Apply the concepts of differentiation to solve optimization problems',
    'Apply the concepts of differentiation to solve related rates problems',
    'Use the derivative to draw the graphs of functions involving polynomials, exponentials, logarithms and trigonometric terms',
    'Find indefinite integrals involving polynomials, exponentials, logarithms, and trigonometric functions',
    'Find definite integrals involving polynomials, exponentials, logarithms and trigonometric functions',
    'Apply the definite integral to compute work, distance, and volumes',
    'Find net area, area under a curve, and area between curves'
  ],
  [CALCULUS_FP]: [
    'Find limits of functions presented as graphs, tables, or algebraic expressions',
    'Use the concept of limit to define the derivative of functions',
    'Differentiate functions involving polynomials, exponentials, logarithms and trigonometric terms',
    'Apply the concepts of differentiation to solve optimization problems',
    'Apply the concepts of differentiation to solve related rates problems',
    'Use the derivative to draw the graphs of functions involving polynomials, exponentials, logarithms and trigonometric terms',
    'Find definite integrals involving polynomials, exponentials, logarithms and trigonometric functions',
    'Find net area and area under a curve'
  ],
  [STATISTICS]: [
    'Explain basic concepts of statistics and probability',
    'Solve basic statistical problems using R',
    'Understand, analyze, and criticize quantitative arguments using principles of statistics'
  ],
  [ASTRONOMY]: [
    'Compare and contrast the relative sizes of planets, stars, galaxies, and the observable universe',
    'Relate the motions of the earth, moon, and sun to seasons, moon phases, eclipses, and the apparent motion of the stars',
    'Explain the properties of light',
    'Describe the properties of telescopes used to observe the sky',
    'Characterize the gravitational interplay of the Sun and the planets in our solar system',
    'Describe the techniques used to study distant stars and how they were first used to measure the distance to our Sun',
    'Describe the life cycle of stars and black holes',
    'Describe the structural components of the galaxy',
    'Describe the formation and evolution of galaxies',
    'Relate the existence of dark matter to the expansion of the universe',
    'Describe theories of the end and beginning of the universe'
  ],
  [MICROECONOMICS]: [
    'Understand foundational principles of the economic way of thinking.',
    'Describe important economic concepts such as supply and demand, elasticity, consumer behavior, production and costs, monopoly, oligopoly, labor markets, poverty, inequality, and discrimination.',
    'Compare and contrast major economic systems.',
    'Summarize major concepts related to international trade'
  ],
  [PHILOSOPHY]: [
    'Differentiate between important philosophical terms such as epistemic, metaethical, numerical identity, compatibilist, necessary and sufficient conditions, Epicurean, and deontological.',
    'Summarize key questions of philosophy.',
    'Describe the general development of philosophical traditions over time.',
    'Discuss key philosophers and their philosophical works.',
    'Analyze key philosophical viewpoints from differing points of view.',
    'Evaluate a philosophical position.',
    'Develop a thoughtful analytical essay to argue a position.'
  ]
}
