import {
  AMAZON_RELATIONSHIP, DISMISS_DAYS, POPUP_TYPE, VOUCHER_STATUS
} from '../Constants/voucher'
import { getLatestCohort } from './courseUtils'
import {
  addDaysToDateInSecs,
  dateToSecondsSinceEpoch,
  getDateMidNight,
  secondsSinceEpoch
} from './dateTimeUtils'

export const getPopupType = vouchers => {
  if (!vouchers?.length) return null

  const hasIrremovableVoucher = !!findIrremovableVoucher(vouchers)
  if (hasIrremovableVoucher) return POPUP_TYPE.IRREMOVABLE

  const hasRemovableVoucher = !!findRemovableVoucher(vouchers)
  if (hasRemovableVoucher) return POPUP_TYPE.REMOVABLE

  return null
}

export const findIrremovableVoucher = vouchers => {
  return vouchers.find(voucher => isIrremovableVoucher(voucher))
}

export const findRemovableVoucher = vouchers => {
  return vouchers.find(voucher => isRemovableVoucher(voucher))
}

export const isIrremovableVoucher = voucher => {
  const voucherDates = getARequiredVoucherDates(voucher)

  if (!voucherDates) return false

  const {
    nowAfterDismissDays,
    dropDeadlineMidNight
  } = voucherDates

  return nowAfterDismissDays >= dropDeadlineMidNight
}

export const isRemovableVoucher = voucher => {
  const voucherDates = getARequiredVoucherDates(voucher)

  if (!voucherDates) return false

  const {
    nowAfterDismissDays,
    dropDeadlineMidNight
  } = voucherDates

  return nowAfterDismissDays < dropDeadlineMidNight
}

export const getARequiredVoucherDates = voucher => {
  if (!voucher?.dropDeadline) return null

  const { dropDeadline, voucherStatus } = voucher

  const isNotRequired = voucherStatus !== VOUCHER_STATUS.OUTSTANDING_VOUCHER
  if (isNotRequired) return null

  const now = secondsSinceEpoch()
  const dropDeadlineMidNight = dateToSecondsSinceEpoch(
    getDateMidNight(dropDeadline)
  )

  const nowAfterDismissDays = addDaysToDateInSecs(now, DISMISS_DAYS)

  return {
    nowAfterDismissDays,
    dropDeadlineMidNight
  }
}

export const isAmazonStudent = studentCourses => {
  if (!studentCourses?.length) return false

  return studentCourses.some(course => {
    const latestCohort = getLatestCohort(course)

    if (!latestCohort) return false

    return (
      latestCohort.relationship?.fields?.relationshipName === AMAZON_RELATIONSHIP
    )
  })
}
