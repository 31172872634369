import React, { Component } from 'react'
import Context from '../Context/Context'
import QuizSectionListExcerciseItem from '../QuizSectionListExcerciseItem/QuizSectionListExcerciseItem'
import { capitalizeFirstLetter } from '../../utilities'
import { isGuessworkCompleteForSection } from '../../utilities/studentProgressUtils'
import shouldUnlockQuiz from '../../utilities/quizLockLogic'
import LockedMarker from '../LockedMarker/LockedMarker'
import { secondsToFormattedDateShort } from '../../utilities/dateTimeUtils'
import { LockedStatus } from './styles'
import { isContentGatingUnlocked } from '../../utilities/sectionUtils'

class QuizSectionExcerciseList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentDate: Date.now() / 1000 | 0,
      isUnlockedList: undefined,
      isLoading: true
    }
  }

  getSectionComplete (questionSetUUID) {
    const sections = this.context.studentData['quiz-section']
    return sections[questionSetUUID]
  }

  isSectionComplete (questionSetUUID) {
    const sectionComplete = this.getSectionComplete(questionSetUUID)
    return sectionComplete > -1
  }

  getScore (questionSetUUID) {
    const score = this.getSectionComplete(questionSetUUID)
    return score > -1 ? score : ''
  }

  fetchUnlockedList () {
    const { excerciseList, chapterExamUnlockDate, finalExamUnlockDate,
      finalExamLockDate, guessworkSectionUUID,
      lockReason,
      completeScreenData: { data: section }
    } = this.props
    const {
      isAuditor,
      isStudioCohort,
      isAdmin,
      isVIPGradedContent,
      isNoAssessments,
      studentData,
      cohortData,
      isContentGatingEnabled,
      isCohortEndedForStudent
    } = this.context

    const {
      is39WeekCohort,
      cohortExamDates: {
        exam5StartDate
      } = {}
    } = cohortData || {}

    const isContentUnlocked = isContentGatingUnlocked({
      section,
      studentData,
      isContentGatingEnabled
    })

    const shouldUnlockItem = quizNumber => shouldUnlockQuiz({
      isAuditor,
      isStudioCohort,
      isAdmin,
      isVIPGradedContent,
      is39WeekCohort,
      isCohortEndedForStudent,
      quizNumber,
      isGuessworkComplete: !guessworkSectionUUID ||
        isGuessworkCompleteForSection(this.context.studentData, guessworkSectionUUID),
      chapterExamUnlockDate,
      exam5StartDate,
      finalExamUnlockDate,
      finalExamLockDate,
      isNoAssessments,
      isContentUnlocked,
      currentDate: this.state.currentDate
    })

    if (excerciseList) {
      const isUnlockedList = excerciseList.map((quiz, index) =>
        shouldUnlockItem(index) && !lockReason)
      this.setState({ isUnlockedList, isLoading: false })
    }
  }

  componentDidMount () {
    this.fetchUnlockedList()
  }

  componentDidUpdate (prevProps) {
    const { excerciseList, chapterExamUnlockDate, finalExamUnlockDate, finalExamLockDate, activeSectionUUID } = this.props

    if ((excerciseList === prevProps.excerciseList) &&
    (chapterExamUnlockDate === prevProps.chapterExamUnlockDate) &&
    (finalExamUnlockDate === prevProps.finalExamUnlockDate) &&
    (finalExamLockDate === prevProps.finalExamLockDate) &&
    (activeSectionUUID === prevProps.activeSectionUUID)) return

    this.fetchUnlockedList()
  }

  render () {
    const {
      excerciseList,
      activeSectionUUID,
      guessworkSectionUUID,
      chapterExamUnlockDate,
      finalExamUnlockDate
    } = this.props
    const {
      isAuditor,
      cohortData,
      isStudioCohort,
      studentData,
      isNoAssessments,
      isCohortEndedForStudent
    } = this.context

    const {
      is39WeekCohort,
      cohortExamDates: {
        exam5StartDate
      } = {}
    } = cohortData || {}

    if (this.state.isLoading || !excerciseList) return <div>Loading...</div>

    const isGuessworkComplete = !guessworkSectionUUID ||
      isGuessworkCompleteForSection(studentData, guessworkSectionUUID)

    var excerciseListarr = Array.from(Object.keys(excerciseList), k => excerciseList[k])

    const quiz5UnlockDate = is39WeekCohort ? exam5StartDate : finalExamUnlockDate

    return excerciseListarr.map((excercise, index) => {
      return (
        <QuizSectionListExcerciseItem
          sectionUuid={activeSectionUUID}
          questionSetUuid={excercise.question_set_uuid}
          title={capitalizeFirstLetter(excercise.title)}
          key={index}
          isAuditor={isAuditor}
          isStudioCohort={isStudioCohort}
          isCompleted={this.isSectionComplete(excercise.question_set_uuid)}
          isUnlocked={this.state.isUnlockedList[index]}
          lockedTooltipText={!this.state.isUnlockedList[index] &&
            getLockedToolTipText(this.context, index)}
          score={this.getScore(excercise.question_set_uuid)}
          questionCount={excercise?.Question?.length || 0}
          renderLockedStatus={() => renderLockedStatus({
            showLockedMarker: isNoAssessments ||
            !isGuessworkComplete || isCohortEndedForStudent,
            quizNumber: index,
            chapterExamUnlockDate,
            finalExamUnlockDate: quiz5UnlockDate
          })}
        />
      )
    })
  }
}

const renderQuizAvailability = date => (
  <LockedStatus>
    Available {date}
  </LockedStatus>
)

const renderLockedStatus = ({
  showLockedMarker,
  quizNumber,
  chapterExamUnlockDate,
  finalExamUnlockDate
}) => {
  if (showLockedMarker) return <LockedMarker />

  const quizNumberHuman = quizNumber + 1
  if (quizNumberHuman === 4) {
    return renderQuizAvailability(
      secondsToFormattedDateShort(chapterExamUnlockDate)
    )
  }

  if (quizNumberHuman === 5) {
    return renderQuizAvailability(
      secondsToFormattedDateShort(finalExamUnlockDate)
    )
  }

  return <LockedMarker />
}

const getLockedToolTipText = (context, quizNumber) => {
  const {
    isAuditor,
    isCohortEndedForStudent
  } = context

  if (isAuditor && isCohortEndedForStudent) return 'Course is Closed'

  const quizNumberHuman = quizNumber + 1

  if (quizNumberHuman <= 3) return 'Complete guesswork to unlock'

  if (quizNumberHuman === 4) return 'Unlocks when next exam unlocks'

  if (quizNumberHuman === 5) return 'Unlocks when Final Exam unlocks'

  return undefined
}

QuizSectionExcerciseList.contextType = Context
export default QuizSectionExcerciseList
