import { inRange } from 'lodash'
import React, { useState, useContext, useEffect } from 'react'
import { BREAK, EXAM, SECTION } from '../../../Constants/scheduleType'
import {
  getSectionNumberFromScheduleTitle,
  isSectionProgressed
} from '../../../utilities/sectionUtils'
import StudentDataContex from '../../Context/Context'
import { history } from '../../HistoryManager/HistoryManager'

import {
  ScheduleDate,
  ScheduleItemBox,
  ScheduleItemWrapper,
  ScheduleTitle,
  ScheduleDetails,
  ScheduleDetailsWrapper,
  ScheduleTitleWrapper,
  DownArrow,
  TextLink,
  RightAlign,
  NoBreak,
  ActiveMarker,
  HiddenSpan,
  ScheduleLastProgressTitle,
  ScheduleLastProgressLink
} from './styled'
import { isMobile } from '../../../utilities/domUtils'

const ScheduleItem = ({ schedule, isSyllabusPage, todayAtTwelveAmInSecs, isAdminAndVipCohort }) => {
  const [lastProgressedSection, setLastProgressedSection] = useState(null)
  const {
    startDate,
    startDateInSecs,
    title,
    textLink,
    materialCovered,
    assignmentType,
    endDateInSecs,
    description,
    endDate
  } = schedule
  const { studentData, updateContext } = useContext(StudentDataContex)
  const [isIconClicked, setIsIconClicked] = useState(false)
  const isExam = assignmentType === EXAM
  const isTermBreak = assignmentType === BREAK
  const showDetailsWithLink = isTermBreak || isExam
  const isFinalGrade = assignmentType === 'finalGrade'
  const isActive = inRange(
    todayAtTwelveAmInSecs,
    startDateInSecs,
    endDateInSecs + 1
  )

  useEffect(() => {
    if (!isActive || schedule.assignmentType !== SECTION) return

    fetchLastProgressedSection(schedule.schedules, studentData)
  // eslint-disable-next-line
  }, [schedule.title])

  const fetchLastProgressedSection = async (schedules = [], studentProgress) => {
    const reversedSectionSchedules = schedules.slice().reverse().filter(
      schedule => schedule.assignmentType === SECTION
    )

    for (const schedule of reversedSectionSchedules) {
      const isProgressed = await isSectionProgressed(schedule.uuid, studentProgress)

      if (isProgressed) {
        setLastProgressedSection({ uuid: schedule.uuid, title: schedule.title })
        break
      }
    }
  }

  const handleSectionNavigate = (e) => {
    // We don't want to collapse/expand the accordion on section link click
    e.stopPropagation()
    history.push('/' + lastProgressedSection.uuid)
  }

  const isSmallScreen = isMobile()
  const getTextLink = () => {
    if (!textLink || isSyllabusPage) return null

    return (
      <TextLink
        top={isSmallScreen ? '12px' : '3px'}
        className='schedule-section-link'
        onClick={() => {
          const { handleClick } = textLink || {}
          if (!handleClick) return
          handleClick(updateContext)
        }}
      >
        {textLink.text}
      </TextLink>
    )
  }

  return (
    <ScheduleItemWrapper
      onClick={() => (!textLink || description) &&
        setIsIconClicked(!isIconClicked)}
      textLink={textLink}
    >
      {!isSyllabusPage && isActive && <ActiveMarker />}
      <ScheduleItemBox textLink={textLink} isExam={isExam}>
        <ScheduleDate>
          { isAdminAndVipCohort ? null : <div>
            <NoBreak>{ startDate }</NoBreak>
            { endDate && ' - ' }
            { endDate && <NoBreak>{ endDate }</NoBreak> }
          </div> }
        </ScheduleDate>
        <ScheduleTitleWrapper>
          <ScheduleTitle>{title}</ScheduleTitle>
          {
            lastProgressedSection && (
              <ScheduleLastProgressTitle>You last did coursework in {' '}
                <ScheduleLastProgressLink onClick={handleSectionNavigate}>
                  Section {getSectionNumberFromScheduleTitle(lastProgressedSection.title)}
                </ScheduleLastProgressLink>.
              </ScheduleLastProgressTitle>
            )
          }
        </ScheduleTitleWrapper>
        <HiddenSpan textLink={textLink} />
        <RightAlign textLink={textLink}>
          {isSmallScreen ? null : getTextLink()}
          {(!textLink || description) && (!isFinalGrade) &&
          <DownArrow textLink={textLink} isIconClicked={isIconClicked} />}
        </RightAlign>
      </ScheduleItemBox>
      {((materialCovered && materialCovered.length) || description) &&
      isIconClicked && (
        <ScheduleDetailsWrapper isExam={isExam}>
          <span />
          <ScheduleDetails>
            {materialCovered && <><b>Topics:</b> {materialCovered}</>}
            {description && description}
          </ScheduleDetails>
        </ScheduleDetailsWrapper>
      )}
      {showDetailsWithLink && (
        <ScheduleDetailsWrapper>
          <span />
          <ScheduleDetails>
            {isTermBreak && `${materialCovered}${textLink?.text
              ? 'Open the checklist to view your progress.' : ''}`}
            {isExam && (!materialCovered
              ? 'Make sure you’re set up with our proctoring software.'
              : `Take additional quizzes from ${materialCovered} to prepare for the
            exam.`)}
          </ScheduleDetails>
          <span />
        </ScheduleDetailsWrapper>
      )}
      {isSmallScreen ? getTextLink() : null}
    </ScheduleItemWrapper>
  )
}

export default ScheduleItem
