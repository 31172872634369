import {
  LECTURE_COMPLETE,
  LECTURE_VIDEO_PROGRESS,
  STUDENT_ANSWERS,
  LAST_ACTIVE_LEARNING_UUID,
  ACTIVE_LEARNING_COMPLETE,
  SECTION_PROGRESS,
  CONCEPT_MAP_COMPLETE,
  READINGS_COMPLETE,
  CONCEPT_MAP_PROGRESS,
  READINGS_PROGRESS,
  ORIENTATION_LECTURE_COMPLETE,
  ORIENTATION_LECTURE_VIDEO_PROGRESS,
  ORIENTATION_SECTION_PROGRESS,
  ORIENTATION_STUDENT_ANSWERS,
  ORIENTATION_LAST_ACTIVE_LEARNING_UUID,
  REVIEW_LECTURE_COMPLETE,
  REVIEW_LECTURE_VIDEO_PROGRESS,
  REVIEW_SECTION_PROGRESS,
  REVIEW_ACTIVE_LEARNING_COMPLETE,
  REVIEW_LAST_ACTIVE_LEARNING_UUID,
  REVIEW_STUDENT_ANSWERS,
  ORIENTATION_ACTIVE_LEARNING_COMPLETE
} from '../Constants/studentContext'
import { isOrientationChapter, isReviewChapter } from './chapterUtils'

export const getLectureCompleteKey = chapterType => isOrientation(chapterType)
  ? ORIENTATION_LECTURE_COMPLETE
  : isReview(chapterType) ? REVIEW_LECTURE_COMPLETE : LECTURE_COMPLETE

export const getLectureVideoProgressKey = chapterType => isOrientation(chapterType)
  ? ORIENTATION_LECTURE_VIDEO_PROGRESS
  : isReview(chapterType) ? REVIEW_LECTURE_VIDEO_PROGRESS : LECTURE_VIDEO_PROGRESS

export const getSectionProgressKey = chapterType => isOrientation(chapterType)
  ? ORIENTATION_SECTION_PROGRESS
  : isReview(chapterType) ? REVIEW_SECTION_PROGRESS : SECTION_PROGRESS

export const getStudentAnswersKey = chapterType => isOrientation(chapterType)
  ? ORIENTATION_STUDENT_ANSWERS
  : isReview(chapterType) ? REVIEW_STUDENT_ANSWERS : STUDENT_ANSWERS

export const getActiveLearningCompleteKey = chapterType => isOrientation(chapterType)
  ? ORIENTATION_ACTIVE_LEARNING_COMPLETE
  : isReview(chapterType) ? REVIEW_ACTIVE_LEARNING_COMPLETE : ACTIVE_LEARNING_COMPLETE

export const getLastActiveLearningUUIDKey = chapterType => isOrientation(chapterType)
  ? ORIENTATION_LAST_ACTIVE_LEARNING_UUID
  : isReview(chapterType) ? REVIEW_LAST_ACTIVE_LEARNING_UUID : LAST_ACTIVE_LEARNING_UUID

export const getReadingCompleteKey = isConceptMap => isConceptMap
  ? CONCEPT_MAP_COMPLETE : READINGS_COMPLETE

export const getReadingProgressKey = isConceptMap => isConceptMap
  ? CONCEPT_MAP_PROGRESS : READINGS_PROGRESS

const isOrientation = type => isOrientationChapter({ type })
const isReview = type => isReviewChapter({ type })
