import styled, { createGlobalStyle } from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const OverrideStyles = createGlobalStyle`
  .Page-layout-main-wrapper {
    padding-bottom: 0px!important;
  }
`

export const PageWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 112.95px);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  & > div {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    ${mediaqueries.mobile`
      padding-left: 24px;
      padding-right: 24px;
      align-items: flex-start;
    `}

    &.content {
      & > p {
        &.description {
          font-size: 16px;
          font-weight: 300;
          line-height: 32px;
          margin-bottom: 16px;
        }
      }
    }
  }

  ${mediaqueries.mobile`
    height: calc(100vh - 133.95px);
  `}
`
export const LockIcon = styled.img`
  height: 48px;
  width: 48px;
  margin-bottom: 16px;
`
export const Title = styled.h1`
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 48px;
  margin-bottom: 16px;
`

export const KeysInputWrapper = styled.div`
  width: 405px;
  ${mediaqueries.mobile`
    width: 100%;
  `}
`

export const Button = styled.button`
  width: 160px;
  height: 48px;
  padding: 16px 24px;
  color: #161618;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 5px;
  background: #5FC4B8;
  border: none!important;
  outline: none!important;
  margin-top: 16px;

  ${mediaqueries.mobile`
    width: 100%;
  `}

  :disabled {
    opacity: 0.3;
  }

  :hover:not(:disabled) {
    filter: brightness(1.2);
  }

  :active {
    opacity: 0.3;
  }
`

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 113px);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: #161618;
  position: fixed;
  top: 64px;
  z-index: 9999;
  ${mediaqueries.mobile`
    padding-left: 24px;
    padding-right: 24px;
    align-items: flex-start;
  `}
`

export const GoBackLink = styled.a`
  position: absolute;
  top: 24px;
  left: 24px;
  color: #5fc4b8 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  :hover {
    color: #b8fff7 !important;
    & > div {
      background-color: #b8fff7;
    }
  }
  :active {
    color: #b8fff74d !important;
    & > div {
      background-color: #b8fff74d;
    }
  }
`

export const BoxContainer = styled.div`
  width: 528px;
  height: 636px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > p {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
  }
`

export const Heading = styled.h1`
  color: #ffffff;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  margin-bottom: 16px;
`

export const GoBackIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  mask: url(/images/icons/icon-back-symbol.svg) no-repeat center;
  mask-size: contain;
  background-color: #5fc4b8;
`
