import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { GradeToolTipText, Link, TooltipIcon } from './styles'
import React, { useRef, useState } from 'react'

const GradeTooltip = ({ finalGradeDate }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const overlayRef = useRef(null)
  const iconRef = useRef(null)

  const handleMouseEnter = () => {
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }
  const isMobile = window.matchMedia('(max-width: 575px)').matches
  const currentFinalGradeDate = finalGradeDate || ''
  const formattedFinalGradeDate =
      new Date(currentFinalGradeDate.replace(/\//g, '-'))
        .toLocaleString('en-us', { month: 'short', day: 'numeric' })

  return (
    <OverlayTrigger
      key='top'
      placement={!isMobile ? 'right' : 'bottom'}
      show={showTooltip}
      overlay={
        <Tooltip
          id='projected-grade-tooltip'
          className={`show`}
          ref={overlayRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <GradeToolTipText>
            {`This is determined by the `}
            <Link
              href='#/resources?gradingCriteria'
              target='_blank' rel='noopener noreferrer'
            >
              graded activities
            </Link>
            {` you've completed and the recommended schedule.`}
            {`\n\nFor tips about improving your grade or more about this calculation, see our `}
            <Link
              href=' https://help.outlier.org/hc/en-us/articles/360046689752'
              target='_blank'
              rel='noopener noreferrer'
            >Course Credits & Grades Help Center article
            </Link>{`.`}

            {`\n\nYour final grade will be available on `}<b>{formattedFinalGradeDate}</b>{`.`}
          </GradeToolTipText>
        </Tooltip>
      }
    >
      <TooltipIcon
        color='#CCD4D8'
        ref={iconRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </OverlayTrigger>
  )
}

export default GradeTooltip
GradeTooltip.displayName = 'GradeTooltip'
