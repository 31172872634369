import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
   body {
    font-family: Lato;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    letter-spacing: 0em;
    line-height: 1.5em;
    text-transform: none;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    font-family: Lato, Sans-Serif;
   }

   li p {
     margin-bottom: 9px !important;
   }
`

export const Container = styled.div`
    margin-top: 100px;
    padding-left: 40px;
    padding-right: 40px;
`

export const H1 = styled.h1`
    font-weight: 300;
    font-size: 38px;
    letter-spacing: .03em;
    line-height: 1em;
    text-transform: uppercase;
    text-align: center;
    -webkit-font-smoothing: antialiased;
`

export const P = styled.p`
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0em;
    line-height: 1.5em;
    text-transform: none;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 20px;
`

export const ItalicText = styled(P)`
  font-style: italic;
`

export const Separator = styled.hr`
  margin-bottom: 70px;
  border-radius: 5px;
  border: .1px solid rgba(255, 255, 255, 0.1);
`

export const Div = styled.div`
  margin-bottom: ${props => props.marginBottom}px
`

export const H3 = styled.h3`
    color: #77bbb5;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: .05em;
    line-height: 1.5em;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 18px;
`
