import React from 'react'
import Typeform from '../TypeForm'

const ProfessionalCertificationTypeform = () => {
  return (
    <Typeform
      formId='ZN58Mxnj'
      headerText='GGU Certifications'
      buttonText='Return To Course Home'
      scrollToFooterAfterSubmission
    />
  )
}

export default ProfessionalCertificationTypeform
