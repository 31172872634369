import React, { Component } from 'react'

import CourseHome from './Components/CourseHome/CourseHome'
import Lecture from './Components/Lecture/Lecture'
import SectionLanding from './Components/SectionLanding/SectionLanding'
import QuestionSetInterstitial from './Components/QuestionSetInterstitial/QuestionSetInterstitial'
import QuestionSetInterstitialPractices from './Components/QuestionSetInterstitial/QuestionSetInterstitialPractices'
import GlobalInstructorSwitch from './Components/GlobalInstructorSwitch/GlobalInstructorSwitch'
import ProblemSet from './Components/ProblemSet/ProblemSet'
import CourseInfo from './Components/CourseInfo/CourseInfo'
import QuizLetComponent from './Components/QuizletComponent/QuizletComponent'
import LoadingSpinner from './Components/LoadingSpinner/LoadingAnimation'
import TermsOfUseComponent from './Components/TermsOfUse/TermsOfUse'
import PrivacyPolicyComponent from './Components/PrivacyPolicy/PrivacyPolicy'
import StudentGrades from './Components/StudentGrades/StudentGrades'
import ExtensionRequestPage
  from './Components/ExtensionRequestPage/ExtensionRequestPage'
import AnnouncementsPage from './Components/Announcements/AnnouncementsPage'
import Context from './Components/Context/Context'
import { emitter } from './Components/Emitter/Emitter'
import { getUnansweredQuestions,
  getAnswerForUnasweredQuestions,
  hasSectionActiveLearning,
  getMultipleVideos,
  getAnswerForExamQuestions,
  getExerciseListArray,
  getNextActivity } from './utilities/sectionUtils'
import { showNonCertificateWarning } from './utilities/courseUtils'
import config from './config'
import { FlagsProvider } from 'flagged'
import {
  ON_STUDENT_ANSWER,
  ON_EXAM_COMPLETE,
  ON_NAVIGATE_TO,
  ON_SECTION_DATA_PROGRESS,
  ON_SECTION_PROGRESS,
  ON_MINIMUM_SECTION_PROGRESS
} from './Constants/emitterKeys'
import { EXAM_COMPLETE, EXAM_STATUS, QUIZ_COMPLETE, QUIZ_SECTION } from './Constants/studentContext'
import utils, { setAsyncTimeout } from './utilities'
import AssignmentPage from './Components/AssignmentPage/AssignmentPage'
import { secondsSinceEpoch } from './utilities/dateTimeUtils'
import Readings from './Components/Readings/Readings'
import {
  Resources,
  Syllabus,
  Orientation
} from './Components/ResourcesSection'
import PracticeExamCompletionPage from './Components/CompletionPage/PracticeExamCompletionPage'
import StudyGuide from './Components/StudyGuide/StudyGuide'
import JDoodleStandAloneCompiler from './Components/JDoodleCompiler/JDoodleStandAloneCompiler'
import MissedDeadlineModal from './Components/MissedDeadlineModal/MissedDeadlineModal'
import { isMidExam, isPracticeExam } from './utilities/chapterUtils'
import VoucherUploadModal from './Components/VoucherUploadModal'
import StudyBlocks from './Components/StudyBlocks'
import NonCertificateWarningModal from './Components/Modals/NonCertificateWarning/NonCertificateWarningModal'
import { getLatestProgress, hasProgress } from './utilities/studentProgressUtils'
import { CODING_QUESTION } from './Constants/questionType'
import {
  getCodegradeToken,
  getSubmissionGrades,
  getSubmissionsAndAutotests
} from './utilities/codegradeUtils'
import { isExamRetakeType } from './utilities/examUtils'
import { EXAM_STATUSES } from './Constants'
import AIViolationModal
  from './Components/GlobalInstructorSwitch/AIViolationModal'
import { EXAM } from './Constants/chapterType'

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      globalState: ''
    }

    this.setAnswersAndFinish = this.setAnswersAndFinish.bind(this)
  }

  componentDidMount () {
    const { updateContext } = this.context
    updateContext({ setAnswersAndFinish: this.setAnswersAndFinish })
    this.setLoginTimeStamp()
  }

  setLoginTimeStamp = () => {
    const { isAdmin, isVIP, isVIPGradedContent, isFirstLogin } = this.context
    const { set } = utils.loginTimestamp()
    const shouldSaveLoginTimestamp = (
      isFirstLogin && (isAdmin || isVIP || isVIPGradedContent)
    )
    if (shouldSaveLoginTimestamp) set(secondsSinceEpoch())
  }

  shouldLogout = () => {
    const { isSessionExpired } = utils.loginTimestamp()
    if (isSessionExpired(secondsSinceEpoch())) return true
    return false
  }

  goToHomePage = () => {
    emitter.emit(ON_NAVIGATE_TO, '/')
  }

  async setAnswersAndFinish ({ activeSectionUUID, questionSet, localStorageKey = null, isQuiz }) {
    const {
      questionSetUUID,
      questions,
      section_ending_uuid: sectionEndingUUID,
      title
    } = questionSet || {}
    const { updateContext } = this.context
    const { studentAnswers } = this.context.studentData
    const unAnsweredQuestions = getUnansweredQuestions(studentAnswers, questions)
    const answerList = getAnswerForUnasweredQuestions(unAnsweredQuestions)
    answerList.forEach(answer => {
      emitter.emit(ON_STUDENT_ANSWER, answer)
    })
    if (localStorageKey) {
      const latestProgress = await getLatestProgress()
      this.context.setStudentProgress(latestProgress)
      const isAlreadyComplete = hasProgress({
        uuid: isQuiz ? questionSetUUID : activeSectionUUID,
        progressKey: isQuiz ? QUIZ_SECTION : EXAM_COMPLETE,
        studentProgress: latestProgress
      })
      const { examRetake, cohortData, currentChapter, currentQuestionSet } = this.context
      const isRetakeAllowed = isExamRetakeType({
        examRetake, chapter: currentChapter, cohortId: cohortData?.cohortID
      })
      const isRetakeComplete = latestProgress?.[EXAM_COMPLETE]?.[currentQuestionSet?.practiceUUID]
      const considerExamComplete = isRetakeAllowed ? isRetakeComplete : isAlreadyComplete
      if (considerExamComplete) {
        localStorage.removeItem(localStorageKey)
        emitter.emit(ON_NAVIGATE_TO, '/' + activeSectionUUID + '/' + sectionEndingUUID)
        return
      }

      const examAnswers = getAnswerForExamQuestions(studentAnswers, questions)
      updateContext({ isSubmittingAnswers: true })
      const hasCodingQuestion = questions.some(question => {
        return question.question_type === CODING_QUESTION
      })
      if (hasCodingQuestion) await getCodegradeToken()
      this.setState({ isSavingAnswers: true })
      const submissions = await getSubmissionsAndAutotests(examAnswers || [])
      if (!submissions?.length) return this.setState({ isSavingAnswers: false })

      await setAsyncTimeout(async () => {
        const examAnswerList = await getSubmissionGrades(submissions || [])
        this.setState({ isSavingAnswers: false })

        emitter.emit(ON_EXAM_COMPLETE, { examAnswerList })

        const sectionQuizzes = this.props?.screen?.data?.['section_exe']?.quiz
        const completedQuizzes = this.context.studentData[QUIZ_SECTION]
        const isSectionQuizzesSolved = sectionQuizzes?.every(quiz =>
          quiz['question_set_uuid'] in completedQuizzes)
        emitter.emit(
          ON_SECTION_DATA_PROGRESS,
          {
            key: isQuiz ? QUIZ_COMPLETE : EXAM_COMPLETE,
            sectionUUID: activeSectionUUID,
            value: isQuiz ? isSectionQuizzesSolved : true,
            isMidExam: isMidExam(currentChapter),
            title
          }
        )

        if (!isQuiz) {
          emitter.emit(
            ON_SECTION_DATA_PROGRESS,
            {
              key: EXAM_STATUS,
              sectionUUID: activeSectionUUID,
              value: EXAM_STATUSES.COMPLETE
            }
          )
          const { examLockEvent, updateContext } = this.context
          examLockEvent && examLockEvent.close()
          updateContext({ examLockEvent: null })
        }

        emitter.emit(ON_SECTION_PROGRESS, activeSectionUUID)
        emitter.emit(ON_MINIMUM_SECTION_PROGRESS, activeSectionUUID)
        emitter.emit(ON_NAVIGATE_TO, '/' + activeSectionUUID + '/' + sectionEndingUUID)
      }, hasCodingQuestion ? 30000 : 0)
    } else {
      emitter.emit(ON_NAVIGATE_TO, '/' + activeSectionUUID + '/' + sectionEndingUUID)
    }
  }

  selectComponent (screenObj) {
    const { activeChildrenIndex, screen: { children } } = this.props
    const {
      isVIP,
      isStudioCohort
    } = this.context
    const screenData =
      screenObj?.children?.[this.props.activeChildrenIndex + 1]?.data || ''

    if (this.props.activeChildrenIndex < 0 && this.context.showFinish) {
      this.context.updateContext({ showFinish: false, reviewMode: false })
    }
    if (
      screenObj.children &&
      this.props.activeChildrenIndex > -1 &&
      screenObj.type !== 'extension-request' &&
      screenObj.type !== 'exam'
    ) {
      const section = screenObj?.data?.['section_exe'] || {}
      const { type, uuid, quest_type: questType } =
      screenObj?.children?.[this.props.activeChildrenIndex] || {}
      const exerciseListArr = getExerciseListArray(section)
      const nextActivity = getNextActivity(exerciseListArr, questType ?? type, uuid)
      if (nextActivity?.uuid !== this.context.nextActivity?.uuid ||
         nextActivity?.type !== this.context.nextActivity?.type) {
        this.context.updateContext({ nextActivity })
      }
      switch (screenObj.children[this.props.activeChildrenIndex].type) {
        case 'lecture':
          const { data: { section_exe: sectionTypes } = {} } = screenObj
          const currentChild = screenObj.children[this.props.activeChildrenIndex]
          const {
            isPracticeDrawer, data: { videos = [] } = {}
          } = currentChild

          // check if section has active learning activity
          const hasActiveLearning = hasSectionActiveLearning(sectionTypes)
          const multipleVideos = isPracticeDrawer
            ? videos : getMultipleVideos(sectionTypes)
          const nextChildScreen = this.props.screen.children[
            this.props.activeChildrenIndex + 1]
          return (
            <Lecture
              key={screenObj.children[this.props.activeChildrenIndex].uuid}
              breadcrumbArr={screenObj.breadcrumbArr}
              LectureVideos={
                screenObj.children[this.props.activeChildrenIndex].data
              }
              sectionUUID={screenObj.children[this.props.activeChildrenIndex]}
              sectionCompleteUUID={screenObj.uuid}
              nextChildUUID={nextChildScreen && nextChildScreen.uuid}
              sectionTitle={screenObj.data.section_title}
              multipleVideos={multipleVideos}
              hasActiveLearning={hasActiveLearning}
              isLastVideo={
                screenObj.children[this.props.activeChildrenIndex].isLastVideo
              }
              shouldSplitLecturePage={this.props.shouldSplitLecturePage}
            />
          )
        case 'reading':
          const readingObject = screenObj.children[this.props.activeChildrenIndex]
          return (
            <Readings
              reading={readingObject.data}
              activeSectionUUID={screenObj.uuid}
              completeScreenData={screenObj}
            />
          )
        case 'intertitial':
          return (
            <QuestionSetInterstitial
              QuestionSetdata={
                screenObj.children[this.props.activeChildrenIndex].data
              }
              activeChildrenIndex={activeChildrenIndex}
              completeScreenData={screenObj}
              activeSectionUUID={screenObj.uuid}
              nextChildUUID={
                this.props.screen.children[this.props.activeChildrenIndex + 1]
                  .uuid
              }
              activeQuizUUID={children[activeChildrenIndex]?.uuid}
              screenData={screenData}
            />
          )

        case 'problem_set':
          return (
            <ProblemSet
              questionSet={
                screenObj.children[this.props.activeChildrenIndex].data
              }
              currentQuestion={
                screenObj.children[this.props.activeChildrenIndex]
              }
              sectionUUID={screenObj.children[this.props.activeChildrenIndex]}
              activeSectionUUID={screenObj.uuid}
            />
          )

        case 'end_content_intertitial':
          return (
            <QuestionSetInterstitial
              QuestionSetdata={
                screenObj.children[this.props.activeChildrenIndex].data
              }
              activeSectionUUID={screenObj.uuid}
              nextChildUUID={this.props.screen.children[2]}
              screenData={screenObj.children[2]}
              intertitial_type='end_content_intertitial'
            />
          )
        case 'end_content_intertitial_section':
          return (
            <QuestionSetInterstitialPractices
              activeChildrenIndex={this.props.activeChildrenIndex}
              QuestionSetdata={
                screenObj.children[this.props.activeChildrenIndex].data
              }
              activeSectionUUID={screenObj.uuid}
              screenData={screenData}
              intertitial_type={
                screenObj.children[this.props.activeChildrenIndex].typeSection
              }
              sectionUUID={
                screenObj.children[this.props.activeChildrenIndex].sectionUUID
              }
              completeScreenData={screenObj}
            />
          )

        case 'question':
          return (
            <GlobalInstructorSwitch
              questionSet={
                screenObj.children[this.props.activeChildrenIndex].data
              }
              completeScreenData={screenObj}
              currentQuestion={
                screenObj.children[this.props.activeChildrenIndex]
              }
              activeSectionUUID={screenObj.uuid}
              key={screenObj.uuid}
            />
          )

        case 'question_quiz':
          return (
            <GlobalInstructorSwitch
              questionSet={
                screenObj.children[this.props.activeChildrenIndex].data
              }
              currentQuestion={
                screenObj.children[this.props.activeChildrenIndex]
              }
              activeSectionUUID={screenObj.uuid}
              key={screenObj.uuid}
            />
          )

        case 'question_problem_bank':
          const { currentProblemSet } = this.context
          const questionData = screenObj.children[this.props.activeChildrenIndex]
          const { data } = questionData || {}
          const { problemBank, currentProblemSetUUID } = data || {}
          return (
            <GlobalInstructorSwitch
              questionSet={currentProblemSet}
              currentProblemSetUUID={currentProblemSetUUID}
              breadCrumb={screenObj.breadcrumb}
              problemBank={problemBank}
              currentQuestion={questionData}
              activeSectionUUID={screenObj.uuid}
              key={screenObj.uuid}
            />
          )

        case 'practice_term':
          return (
            <QuizLetComponent
              data={screenObj.children[this.props.activeChildrenIndex]}
              context={this.context}
            />
          )
        case 'coding_assignment':
        case 'writing_assignment':
          if (isStudioCohort) this.goToHomePage()
          return (
            <AssignmentPage data={screenObj.children[this.props.activeChildrenIndex]} />
          )
        case 'syllabus':
          return <Syllabus />
        case 'orientation':
          return <Orientation />

        default:
          return true
      }
    } else if (
      screenObj.type === 'exam' &&
      screenObj.children[this.props.activeChildrenIndex] &&
      screenObj.children[this.props.activeChildrenIndex].type ===
        'end_content_intertitial_section'
    ) {
      if (isPracticeExam(screenObj.uuid)) {
        return <PracticeExamCompletionPage />
      }
      return (
        <QuestionSetInterstitialPractices
          QuestionSetdata={
            screenObj.children[this.props.activeChildrenIndex].data
          }
          activeSectionUUID={screenObj.uuid}
          screenData={screenData}
          intertitial_type={
            screenObj.children[this.props.activeChildrenIndex].typeSection
          }
          sectionUUID={
            screenObj.children[this.props.activeChildrenIndex].sectionUUID
          }
          completeScreenData={screenObj}
        />
      )
    } else {
      switch (screenObj.type) {
        case 'course':
          return (
            <CourseHome
              course={screenObj.data}
              courseUnlockDate={screenObj.courseUnlockDate}
              cohortModifier={screenObj.cohortModifier}
              cohortSpecialDays={screenObj.cohortSpecialDays}
              cohortMilestones={screenObj.cohortMilestones}
              cohortCourseInfoUrl={screenObj.cohortCourseInfoUrl}
              cohortExamDates={screenObj.cohortExamDates}
              cohortData={screenObj.cohortData}
            />
          )
        case 'course_info':
          return <CourseInfo course={screenObj.data} />
        case 'section':
          return (
            <SectionLanding
              completeScreenData={screenObj}
              SectionSetdata={screenObj.data}
              finalExamLockDate={screenObj.finalExamLockDate}
              chapterExamUnlockDate={screenObj.chapterExamUnlockDate}
              finalExamUnlockDate={screenObj.finalExamUnlockDate}
            />
          )
        case 'exam':
          const cindex = this.props.activeChildrenIndex === -1
            ? 0
            : this.props.activeChildrenIndex
          return (
            <GlobalInstructorSwitch
              completeScreenData={screenObj}
              questionSet={screenObj.data.Question}
              activeChildrenIndex={this.props.activeChildrenIndex}
              currentQuestion={screenObj.children[cindex]}
              activeSectionUUID={screenObj.uuid}
              key={screenObj.uuid}
            />
          )
        case 'terms_of_use':
          return <TermsOfUseComponent />
        case 'privacy_policy':
          return <PrivacyPolicyComponent />
        case 'grades':
          return <StudentGrades />
        case 'java_compiler':
          return <JDoodleStandAloneCompiler />
        case 'study_guide':
          return <StudyGuide />
        case 'resources':
          if (isStudioCohort) this.goToHomePage()
          return <Resources />
        case 'extension-request':
          if (isVIP) this.goToHomePage()
          return <ExtensionRequestPage
            courseTitle={screenObj.courseTitle}
            assessments={screenObj.children}
            cohortData={screenObj.cohortData}
          />
        case 'announcements':
          return <AnnouncementsPage />
        default:
          return true
      }
    }
  }

  render () {
    if (this.state.isSavingAnswers) return <LoadingSpinner />

    const { screen, logout } = this.props
    const {
      isSavvasAndHasAdminAccess,
      isAdmin,
      isVIP,
      isVIPCourse,
      activeCourse: { name: courseName } = {},
      latestCohort
    } = this.context
    const excludeFromAIViolations = isSavvasAndHasAdminAccess ||
        isAdmin || isVIPCourse

    const { certificateName } = latestCohort || {}
    const showCertificateWarning = showNonCertificateWarning(latestCohort)
    // Consider exam inactive if there's no activity for more than 15 seconds
    const INACTIVITY_LIMIT = 15 * 1000
    let isOngoingExam = localStorage.getItem('ongoing-exam') === 'true'
    const examActivityTimestamp = localStorage.getItem('examActivityTimestamp')
    const noExamActivity = examActivityTimestamp &&
        (Date.now() - parseInt(examActivityTimestamp, 10)) > INACTIVITY_LIMIT
    if (isOngoingExam && noExamActivity) {
      isOngoingExam = false
      localStorage.removeItem('ongoing-exam')
    }
    const isExamPage = screen?.type === EXAM
    const isGoingExamAndMultipleOutlierTabs = !isExamPage && isOngoingExam

    if (!config.isPreviewCourse && this.shouldLogout()) {
      logout()
      return null
    }
    if (
      screen &&
      screen.type === 'section' &&
      screen.children === null
    ) {
      // We don't want to show 'Something went wrong' when the screen children are empty for the moment.
      // Since the children array arrives asynchoronusly we should show the Loading spinner until the data  gets loaded
      return <LoadingSpinner />
    } else {
      return (
        <FlagsProvider features={config.flags}>
          <>{this.selectComponent(screen)}</>
          {!isAdmin && !isVIP && <MissedDeadlineModal />}
          {<VoucherUploadModal />}
          <StudyBlocks />
          {showCertificateWarning && (
            <NonCertificateWarningModal
              showModal={showCertificateWarning}
              certificateName={certificateName}
              courseName={courseName}
            />
          )}
          {(isGoingExamAndMultipleOutlierTabs && !excludeFromAIViolations) &&
          <AIViolationModal
            showAIViolationModal
            showOutlierTabsMessage
          />}
        </FlagsProvider>
      )
    }
  }
}

App.contextType = Context
App.displayName = 'App'
export default App
