import React from 'react'
import {
  secondsToFormattedDateTimeShort,
  getTimezoneShort
} from '../../utilities/dateTimeUtils'
import {
  ReviewContainer,
  ReviewItemsArea,
  ReviewItem,
  SubmissionClosedMessage,
  GradeHeading,
  BreakdownContainer
} from './styles'

const CodingReviewPage = (props) => {
  const {
    lockTimeInSeconds,
    progressData,
    studentAnswer
  } = props
  const { grade, status, submissionTime } = progressData || {}
  const {
    totalScore,
    totalWeight,
    step_results: stepResults
  } = studentAnswer?.meta || {}

  const hasNotSubmittedAssignment = status === 'draft' || !status
  const isPastLockTime = Date.now() > lockTimeInSeconds * 1000
  const isPassLockTimeWithoutSubmit = isPastLockTime && hasNotSubmittedAssignment

  const assignmentLockDate =
    secondsToFormattedDateTimeShort(lockTimeInSeconds, 'short')
  const assignmentLockTimeZone = getTimezoneShort(lockTimeInSeconds)

  const formattedSubmissionTime =
    secondsToFormattedDateTimeShort(submissionTime, 'short')
  const submissionTimeZone = getTimezoneShort(submissionTime)

  const score = grade || 0
  const totalPoint = isPassLockTimeWithoutSubmit ? 'N/A' : `${totalScore}/${totalWeight}`
  const submissionTimeText = !submissionTime || isPassLockTimeWithoutSubmit
    ? 'N/A'
    : `${formattedSubmissionTime} ${submissionTimeZone}`

  return (
    <ReviewContainer>
      {!submissionTime &&
      <SubmissionClosedMessage>
        Assignment submission closed on {assignmentLockDate} {assignmentLockTimeZone}.
        Please reach out to <a href='mailto:success@outlier.org'>success@outlier.org</a> with any questions.
      </SubmissionClosedMessage>}
      <GradeHeading>Grade</GradeHeading>
      <ReviewItemsArea>
        <ReviewItem>
          <h4>score:</h4>
          <p>{score}%</p>
        </ReviewItem>
        <ReviewItem>
          <h4>total points:</h4>
          <p>{totalPoint}</p>
        </ReviewItem>
        <ReviewItem>
          <h4>submission time:</h4>
          <p>{submissionTimeText}</p>
        </ReviewItem>
      </ReviewItemsArea>
      <ReviewItem marginBottom='12'>
        <h4>breakdown</h4>
      </ReviewItem>
      <BreakdownContainer>
        {stepResults?.map((result, index) => {
          const { header, stepScore, steps } = result || {}
          return (
            <div className='suite' key={`${header.replace(/\s/g, '')}_${index}`}>
              <div className='header'>
                <span>{header}</span>
                <span>{stepScore}%</span>
              </div>
              {steps?.map((step, stepIndex) => {
                const { name, weight, achievedPoints } = step || {}
                return (
                  <div className='step' key={`${name.replace(/\s/g, '')}_${stepIndex}`}>
                    <span>{name}</span>
                    <span>{achievedPoints}/{weight}</span>
                  </div>
                )
              })}
            </div>
          )
        })}
      </BreakdownContainer>
    </ReviewContainer>
  )
}

CodingReviewPage.displayName = 'CodingReviewPage'

export default CodingReviewPage
