import React, { useState } from 'react'
import LoadingSpinner from '../LoadingSpinner/LoadingAnimation'
import {
  Container,
  Header,
  Content
} from './styles'
import CourseButton from '../CourseButton/CourseButton'
import api from '../../api'
import { isLatestTermsAccepted } from '../../utilities/userUtils'
import { useStudentDataContext } from '../Context/Context'
import {
  PERMISSION_AWAITED,
  PERMISSION_GRANTED,
  PERMISSION_REQUIRED
} from './permissionStates'

const PermissionRequested = ({
  guardianEmail,
  setEmail,
  setPermissionState
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { showProfCertGuardianPermission } = useStudentDataContext()

  const isPermissionGranted = (studentData) => {
    if (showProfCertGuardianPermission) {
      const {
        gguParentCertification1,
        gguParentCertification2,
        gguParentCertification3
      } = studentData

      return gguParentCertification1 &&
        gguParentCertification2 &&
        gguParentCertification3
    }

    const { guardianTOSDate } = studentData
    return isLatestTermsAccepted(guardianTOSDate)
  }

  const checkStatus = async () => {
    setIsLoading(true)
    const studentData = await api.getStudentData()
    setIsLoading(false)

    const permissionGranted = isPermissionGranted(studentData)
    if (permissionGranted) return setPermissionState(PERMISSION_GRANTED)

    setPermissionState(PERMISSION_AWAITED)
  }

  const resendEmail = () => {
    setEmail('')
    setPermissionState(PERMISSION_REQUIRED)
  }

  if (isLoading) return <LoadingSpinner />

  return (
    <Container>
      <div className='content'>
        <Header>We’ve reached out</Header>
        <Content>
          We have sent an email to <span>{guardianEmail}</span> with instructions
          on how to {showProfCertGuardianPermission
            ? 'grant permission for you to take Golden Gate University courses.'
            : 'agree to our Terms of Service and Privacy Policy.'}
        </Content>
        <Content marginBottom='24px'>
          Please give them a heads up to ensure it’s not lost in the shuffle of
          their inbox. Check to see if they’ve given their permission below
          or resend the email.
        </Content>
        <div className='buttons-container'>
          <CourseButton
            className='btn btn-primary'
            onClick={checkStatus}
          >
            check status
          </CourseButton>
          <CourseButton
            className='btn btn-secondary'
            onClick={resendEmail}
          >
            resend
          </CourseButton>
        </div>
      </div>
    </Container>
  )
}

export default PermissionRequested
