import React, { Component } from 'react'
import CourseButton from '../CourseButton/CourseButton'
import Context from '../Context/Context'
import { emitter } from '../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'
import LockedMarker from '../LockedMarker/LockedMarker'

class PracticeExercisesButton extends Component {
  constructor (props) {
    super(props)
    this.isPracticeCompleted = this.isPracticeCompleted.bind(this)
    this.getButtonAttributes = this.getButtonAttributes.bind(this)
    this.hasAttemptedQuestions = this.hasAttemptedQuestions.bind(this)
  }

  isPracticeCompleted () {
    const { questionUUID } = this.props
    const { studentAnswers } = this.context.studentData
    const studentAnswer = studentAnswers.find(answer =>
      answer.uuid === questionUUID && answer.isPracticeCompleted)
    return studentAnswer
  }

  hasAttemptedQuestions () {
    const { questionUUID } = this.props
    const { studentAnswers } = this.context.studentData
    return studentAnswers.find(answer =>
      answer.uuid === questionUUID && answer.trials !== undefined)
  }

  getButtonAttributes () {
    let text = 'Start'
    let isPrimaryBtn = true
    const { isPracticeCompleted } = this.props
    const hasAttemptedQuestions = this.hasAttemptedQuestions()
    const {
      isCohortEndedForStudent
    } = this.context
    if (hasAttemptedQuestions) {
      text = 'Continue'
    }
    if (isPracticeCompleted || isCohortEndedForStudent) {
      isPrimaryBtn = false
      text = 'Practice'
    }
    const className = {
      'btn-primary': isPrimaryBtn,
      'btn-secondary': !isPrimaryBtn
    }
    return {
      text,
      className
    }
  }

  render () {
    const { sectionUUID, questionUUID, isUnlocked } = this.props
    const { text, className } = this.getButtonAttributes()
    const dataUuid = `${sectionUUID}/${questionUUID}`

    if (!isUnlocked) return <LockedMarker />
    return (
      <CourseButton
        dataUuid={dataUuid}
        className={className}
        dataCypress='practiceButton'
        style={{ width: '120px' }}
        onClick={() => {
          emitter.emit(ON_NAVIGATE_TO, '/' + dataUuid)
        }}
      >
        {text}
      </CourseButton>
    )
  }
}

PracticeExercisesButton.contextType = Context
export default PracticeExercisesButton
