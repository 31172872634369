import React, { useContext } from 'react'
import Context from '../../Context/Context'
import LinksContent from './LinksContent/LinksContent'
import { Auth0Context } from '../../Auth0Provider/Auth0Provider'

export default function Links () {
  const {
    studentCourses,
    isAdmin,
    isAuditor,
    cohortData
  } = useContext(Context)
  const { studentId } = useContext(Auth0Context)

  const { startDate } = cohortData

  if (!studentCourses?.length || !startDate) return null

  return (
    <LinksContent
      isAdmin={isAdmin}
      isAuditor={isAuditor}
      studentId={studentId}
      studentCourses={studentCourses}
      cohortData={cohortData}
    />
  )
}
