import config from '../config'
export default shouldUnlockQuiz

function shouldUnlockQuiz (options) {
  const {
    isAdmin,
    isStudioCohort,
    isAuditor,
    isVIPGradedContent,
    isNoAssessments,
    isContentUnlocked,
    isCohortEndedForStudent
  } = options
  if (isNoAssessments) return false
  if (
    isAdmin ||
    isStudioCohort ||
    isVIPGradedContent ||
    config.isPreviewCourse ||
    config.course.unlockExercises
  ) return true
  if (isAuditor) return !isCohortEndedForStudent

  const quizNumberHuman = options.quizNumber + 1
  const {
    is39WeekCohort,
    currentDate,
    exam5StartDate,
    finalExamUnlockDate,
    finalExamLockDate,
    chapterExamUnlockDate,
    isGuessworkComplete } = options

  // If the final exam lock date has passed, all quizzes are locked. But a final
  // exam lock date of null means do not relock, in which case we skip this.
  if (finalExamLockDate !== null && (currentDate >= finalExamLockDate)) return false

  // Quizzes n thru 3 are unlocked when guesswork is completed and
  // when content gating is unlocked if isContentGatingEnabled is checked.
  const quiz1To3LockLogic = isGuessworkComplete && !!isContentUnlocked
  if (quizNumberHuman <= 3) {
    return quiz1To3LockLogic
  }

  // quiz 4 should unlock if the current date is less than the chapter exam
  // unlock date and when quizzes 1-3 are available
  if (quizNumberHuman === 4) {
    return quiz1To3LockLogic && currentDate >= chapterExamUnlockDate
  }

  // quiz 5 should unlock when final exam I unlocks for 39 week cohorts
  if (quizNumberHuman === 5 && is39WeekCohort) {
    return currentDate >= exam5StartDate && isGuessworkComplete
  }

  // Default is to unlock if the current date is greater than or equal to the
  // final exam unlock date and the guesswork is complete
  return currentDate >= finalExamUnlockDate && isGuessworkComplete
}
