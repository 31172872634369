import styled from 'styled-components'

export const ConceptMapTitle = styled.span`
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
`

export const StyledText = styled.div`
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: #B1BFC5;
    margin-top: 12px;
    ${props => props.dot && `
    -webkit-text-stroke-width: medium;
    line-height: 12px;
   `}
`
