import React, { useState, useEffect } from 'react'
import KalturaPlayer from '../../KalturaEmbed/KalturaPlayer'
import config from '../../../config'
import { VideoWrapper } from './styled'

export default function OrientationVideo (props) {
  const isActive = true
  const isLectureVideo = false
  const initialProgress = 0
  const { videoId } = props

  const [isKalturaLoading, setIsKalturaLoading] = useState(true)

  useEffect(() => {
    if (document.getElementById('kaltura_script')) {
      setIsKalturaLoading(false)
      return
    }
    const { scriptUrl } = config.kalturaSetting
    const kalturaScript = document.createElement('script')
    kalturaScript.id = 'kaltura_script'
    kalturaScript.type = 'text/javascript'
    kalturaScript.src = scriptUrl
    kalturaScript.onload = () => {
      setIsKalturaLoading(false)
    }
    document.body.appendChild(kalturaScript)
  }, [])

  if (isKalturaLoading) return null

  return (
    <VideoWrapper>
      <div className='embed-responsive embed-responsive-16by9'>
        <KalturaPlayer
          hashedId={videoId}
          isActive={isActive}
          isLectureVideo={isLectureVideo}
          initialProgress={initialProgress}
          onPlayEnd={() => {}}
          onTimeChange={() => {}}
        />
      </div>
    </VideoWrapper>
  )
}
