import api from '../api'

const POLL_INTERVAL = 60000

export const checkExamLockedStatus = async ({
  examUUID, handleExamLock
}) => {
  try {
    const intervalId = setInterval(async () => {
      const examLockedProgress = await api.getProgressKeyValue()
      const examLockedValue = examLockedProgress?.[examUUID]
      if (examLockedValue !== true) return

      handleExamLock()
      examLockEvent.close()
    }, POLL_INTERVAL)

    const examLockEvent = {
      close: () => {
        clearInterval(intervalId)
      }
    }

    return examLockEvent
  } catch (error) {
    console.error(error)
    return null
  }
}
