import React from 'react'
import ModalComponent from '../ModalComponent/ModalComponent'
import './css/tooltip-text-modal.css'

const TooltipTextModal = (props) => {
  const { tooltipText, clickedText, resetText } = props
  if (!tooltipText || !clickedText) {
    return null
  }

  return (
    <ModalComponent show={tooltipText !== ''} handleClose={resetText}>
      <div className='content-tooltip-text-modal'>
        <h2>{clickedText}</h2>
        <p>{tooltipText}</p>
      </div>
    </ModalComponent>
  )
}

export default TooltipTextModal
