import React, { useState } from 'react'
import { getCoursePolicies } from './policyContent'
import config from '../../../config'
import Accordion from './Accordion'
import {
  AccordionContainer } from './styled'
import {
  SubHeader } from '../styled'
import { getArrayMidIndex } from '../../../utilities'

const CoursePolicies = ({ haveDynamicData, datoCoursePolicies }) => {
  const coursePolicies = getCoursePolicies({
    courseName: config.courseName, haveDynamicData, datoCoursePolicies
  }).filter(policy => policy !== undefined)

  const [isOpenValues, setIsOpenValues] = useState(
    new Array(coursePolicies.length).fill(false)
  )

  const getPolicies = (startIndex, endIndex) => {
    return coursePolicies.slice(startIndex, endIndex)
      .map((policy, index) => {
        const { title, content } = policy
        return (
          <Accordion
            key={title}
            title={title}
            content={content}
            setIsOpenValues={setIsOpenValues}
            isOpenValues={isOpenValues}
            index={startIndex + index}
          />
        )
      })
  }

  const policiesMidIndex = getArrayMidIndex(coursePolicies)
  const itemsPerColumn = policiesMidIndex + 1

  const wideScreen = window.matchMedia('(min-width: 768px)')
  const [maxItemsPerColumn, setMaxItemsPerColumn] = useState(
    wideScreen.matches ? itemsPerColumn : coursePolicies.length
  )
  wideScreen.addListener(media => {
    setMaxItemsPerColumn(media.matches ? itemsPerColumn : coursePolicies.length)
  })

  if (!coursePolicies?.length) return null

  return (
    <>
      <SubHeader>course policies</SubHeader>
      <div className='d-flex flex-row'>
        <AccordionContainer>
          {getPolicies(0, maxItemsPerColumn)}
        </AccordionContainer>
        {coursePolicies.length > maxItemsPerColumn &&
        <AccordionContainer marginLeft='24px'>
          {getPolicies(maxItemsPerColumn, coursePolicies.length)}
        </AccordionContainer>}
      </div>
    </>
  )
}

export default CoursePolicies
