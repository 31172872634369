import React, { useEffect, useContext } from 'react'
import StudentDataContex from '../Context/Context'
import { CORRECT, INCORRECT } from '../../Constants/result'
import { Nav, NavItem } from './styles'
import {
  NEW_COMPLETED_ICON,
  NEW_INCORRECT_ICON,
  SELECTED_CIRCLE_ICON,
  UNSELECTED_CIRCLE_ICON,
  POPUP_QUESTION_ICON,
  LAST_QUESTION_ICON,
  SELECTED_INCORRECT_ICON,
  SELECTED_CORRECT_ICON
} from '../../Constants/progressBarIconSource'

function Navigation ({
  questionSet,
  activeQuestionIndex,
  currentProblemSetResponse
}) {
  const {
    trials = [],
    popResponse
  } = currentProblemSetResponse || {}

  const answerArray = constructAnswersArray(
    { questionSet,
      trials,
      popResponse }
  )

  const studentContext = useContext(StudentDataContex)
  const { updateContext } = studentContext

  useEffect(() => {
    updateContext({
      isPracExerciseSetPassed: (
        answerArray[answerArray?.length - 1] === CORRECT ||
        answerArray[answerArray?.length - 1] === true)
    })
    // eslint-disable-next-line
  }, [currentProblemSetResponse])

  function getIconsSrc (questionInfo) {
    const { question, index, answerArray } = questionInfo
    if (!question) return

    const isLastQuestion = index === questionSet.length - 1

    const isAnswerCorrect = answerArray[index] === CORRECT ||
     answerArray[index] === true

    const isAnswerIncorrect = answerArray[index] === INCORRECT ||
     answerArray[index] === false

    if (index === activeQuestionIndex) {
      if (isAnswerCorrect) return SELECTED_CORRECT_ICON
      if (isAnswerIncorrect) return SELECTED_INCORRECT_ICON
      return SELECTED_CIRCLE_ICON
    }

    if (isAnswerCorrect) return NEW_COMPLETED_ICON
    if (isAnswerIncorrect) return NEW_INCORRECT_ICON
    if (question.isPopQuestion) return POPUP_QUESTION_ICON
    if (isLastQuestion) return LAST_QUESTION_ICON
    return UNSELECTED_CIRCLE_ICON
  }

  return (
    <Nav>
      {questionSet?.map((question, index) => {
        return <NavItem
          key={index}
          selected={index === activeQuestionIndex}
          isPopQuestion={question.isPopQuestion}
          isChallengeQuestion={index === questionSet?.length - 1}
          src={getIconsSrc({ question, index, answerArray })}
        />
      })}
    </Nav>
  )
}

function constructAnswersArray (questionData) {
  const { questionSet, popResponse, trials = [] } = questionData || {}
  if (!questionSet) return []
  let i = 0
  const answerArray = []

  questionSet.forEach(question => {
    if (question.isPopQuestion) {
      return answerArray.push(popResponse?.result)
    }
    answerArray.push(trials[i]?.result)
    i++
  })
  return answerArray
}

Navigation.displayName = 'Navigation'
export default Navigation
