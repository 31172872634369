import React, { useState, useEffect, useContext } from 'react'
import { Collapse } from 'reactstrap'
import config from '../../config'
import Context from '../Context/Context'
import VideoItem from './VideoItem'
import util, { capitalizeFirstLetter } from '../../utilities'
import { SectionListExerciseItemStyle, ToggleIcon } from '../SectionListExcerciseItem/styled'
import { Time, VideosWrapper } from './styled'
import { getTimeFromSeconds } from '../../utilities/dateTimeUtils'
import {
  getVideosRemainingTime,
  getKalturaVideosRemainingTime,
  getTotalSectionVideosTime,
  getVideosTime,
  areAllKalturaEmbedCodesValid
} from '../../utilities/sectionUtils'

import LockedMarker from '../LockedMarker/LockedMarker'
import { isOutlierAccount } from '../../utilities/userUtils'
import { Auth0Context } from '../Auth0Provider/Auth0Provider'
import InstructorInfoModal from '../InstructorInfoModal/InstructorInfoModal'
import CustomTooltip from '../Tooltip'
import { TooltipIcon } from '../Tooltip/styled'
import { getLectureCompleteKey, getLectureVideoProgressKey } from '../../utilities/contextKeys'
import { isOrientationChapter } from '../../utilities/chapterUtils'

const MultipleVideos = props => {
  const {
    chapterType,
    sectionUUID,
    isLastItem,
    lockReason,
    isPracticeDrawer,
    multipleVideos,
    hasSubsectionDrawer,
    excercise: {
      field_tooltip: tooltipText, type, question_set_uuid: questionSetUUID
    } = {}
  } = props
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const [remainingTimes, setRemainingTimes] = useState({})
  const [totalRemainingTime, setTotalRemainingTime] = useState(-1)
  const lectureVideoProgressKey = getLectureVideoProgressKey(chapterType)
  const lectureCompletekey = getLectureCompleteKey(chapterType)
  const {
    studentData: {
      [lectureVideoProgressKey]: lectureVideoProgress,
      [lectureCompletekey]: lectureComplete
    },
    isCohortEnded,
    isCohortEndedForStudent
  } = useContext(Context)
  const { user: { email } } = useContext(Auth0Context)
  const fieldTooltip = tooltipText ||
    'Video lessons from our lecturers. Class is always in session!'

  const hasKalturaVideo = isOutlierAccount(email) || config.isPreviewCourse ||
    areAllKalturaEmbedCodesValid(multipleVideos, true)
  const getRemainingTimes = hasKalturaVideo
    ? getKalturaVideosRemainingTime
    : getVideosRemainingTime
  const { isCalculusCourse } = config
  const isChapterOrientation = isOrientationChapter({ type: chapterType })
  const isCalculus = isCalculusCourse()

  useEffect(() => {
    const videoRemainingTimes = isCohortEndedForStudent
      ? getVideosTime(multipleVideos, hasKalturaVideo)
      : getRemainingTimes(multipleVideos, lectureVideoProgress, lectureComplete)
    const totalTime = Object.values(videoRemainingTimes)
      .reduce((a, b) => a + b, 0)
    setTotalRemainingTime(totalTime)
    setRemainingTimes(videoRemainingTimes)
    // eslint-disable-next-line
  }, [lectureVideoProgress, lectureComplete])

  useEffect(() => {
    const { get } = config.sectionItemAccordionState(type, sectionUUID)
    const storedValue = get()
    if (storedValue) return setIsAccordionOpen(storedValue === 'true')
    // if videos completed collapse the accordion
    if (isCalculus) setIsAccordionOpen(!Object.keys(lectureComplete).includes(sectionUUID))
    else setIsAccordionOpen(!!totalRemainingTime)
    // eslint-disable-next-line
  }, [totalRemainingTime])

  const handleToggle = () => {
    const { set } = config.sectionItemAccordionState(type, sectionUUID)
    set(!isAccordionOpen)
    setIsAccordionOpen(!isAccordionOpen)
  }

  const totalVideosTime = getTotalSectionVideosTime(multipleVideos)
  const isLocked = !!lockReason
  const allVideosComplete = isCalculus ? Object.keys(lectureComplete).includes(sectionUUID) : totalRemainingTime === 0
  const instructors = multipleVideos?.map(video => video.instructor)

  if (isPracticeDrawer || hasSubsectionDrawer) {
    return (
      <VideosWrapper isPracticeDrawer>
        {
          multipleVideos.map(
            (video, index) => {
              const { embedCode, kalturaEmbedCode } = video
              const videoEmbedCode = hasKalturaVideo
                ? kalturaEmbedCode
                : embedCode
              return <VideoItem
                key={videoEmbedCode}
                video={video}
                index={index}
                isLocked={isLocked}
                sectionUUID={sectionUUID}
                remainingTime={remainingTimes[videoEmbedCode]}
                hasSubsectionDrawer={hasSubsectionDrawer}
              />
            }
          )
        }
      </VideosWrapper>
    )
  }

  return (
    <>
      <SectionListExerciseItemStyle
        isLastItem={isLastItem}
        className={`section-panel-active d-flex
          justify-content-between align-items-center`}
        data-cy='sectionListExerciseItem'
        onClick={handleToggle}
        onKeyPress={(e) => util.handleKeyPress(e, handleToggle)}
        tabIndex='0'
      >
        <div>
          <div className='main-panels--left'>
            <h3 className='panel-title d-inline-block section-title'
              style={{ color: allVideosComplete && '#888' }}>
              {capitalizeFirstLetter(type)}s
            </h3>

            {fieldTooltip &&
              <CustomTooltip
                id='lecture-tooltip'
                text={fieldTooltip}
              >
                <TooltipIcon>?</TooltipIcon>
              </CustomTooltip>
            }
          </div>
          {(isCalculus && !isChapterOrientation)
            ? (() => {
              return allVideosComplete
                ? <Time>Completed</Time>
                : <Time>
                  1 video required
                </Time>
            })()
            : (() => {
              if (isCohortEnded) {
                return <Time data-cy='totalTime'>
                  {getTimeFromSeconds(totalRemainingTime)} mins
                </Time>
              }
              return allVideosComplete
                ? <Time>Completed</Time>
                : <Time>
                  {getTimeFromSeconds(totalRemainingTime)} mins
                  {(totalVideosTime !== totalRemainingTime) && ' remaining'}
                </Time>
            })()
          }
        </div>

        <div className='main-panels--right d-flex align-items-center'>
          {isLocked && <LockedMarker />}
          <ToggleIcon
            isAccordionOpen={isAccordionOpen}
            src={'images/icons/icon-angle-arrow-light.svg'}
            isLocked={isLocked}
            data-cy='toggleButton'
          />
        </div>
      </SectionListExerciseItemStyle>
      <Collapse isOpen={isAccordionOpen}>
        <VideosWrapper data-cy='videosWrapper'>
          {
            multipleVideos.map(
              (v, i) => {
                const { embedCode, kalturaEmbedCode } = v
                const videoEmbedCode = hasKalturaVideo
                  ? kalturaEmbedCode
                  : embedCode
                return <VideoItem
                  key={videoEmbedCode}
                  video={v}
                  index={i}
                  chapterType={chapterType}
                  isLocked={isLocked}
                  sectionUUID={sectionUUID}
                  remainingTime={remainingTimes[videoEmbedCode]}
                  questionSetUUID={questionSetUUID}
                />
              }
            )
          }
          {isCalculus && !isChapterOrientation &&
            <InstructorInfoModal instructors={instructors} />}
        </VideosWrapper>
      </Collapse>
    </>
  )
}
export default MultipleVideos
