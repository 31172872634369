import React from 'react'
import SectionlistExcerciseItem from '../SectionListExcerciseItem/SectionListExcerciseItem'
import MultipleVideos from './MultipleVideos'
import { getMultipleVideos } from '../../utilities/sectionUtils'

const LecturesExerciseItem = props => {
  const multipleVideos = props?.excercise?.videos ||
    getMultipleVideos(props.excerciseList)

  if (!multipleVideos) return <SectionlistExcerciseItem {...props} />

  return <MultipleVideos multipleVideos={multipleVideos} {...props} />
}

export default LecturesExerciseItem
