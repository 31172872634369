import styled from 'styled-components'

export const Container = styled.div`
  margin: 25px;
  margin-top: ${({ marginTop }) => marginTop || '29px'};
`

export const Title = styled.h5`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  display: inline-block;
  margin: 0;
  line-height: 19px;
`

export const TitleIcon = styled.img`
  margin-right: 13px;
`

export const IntroText = styled.div`
  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }
`

export const IntroHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 25px;
`

export const CustomButton = styled.button`
  width: 152px;
  height: 48px;
  margin-top: 8px;
  background: #5FC4B8;
`
