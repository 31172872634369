import React from 'react'
import {
  getTimezoneShort,
  secondsToFormattedDateTimeShort
} from '../../utilities/dateTimeUtils'
import {
  SubmissionHeading,
  PreviewTextMessage
} from './styles'

const AssignmentPreviewSection = ({ unlockTime }) => {
  const formattedUnlockTime = secondsToFormattedDateTimeShort(
    unlockTime,
    'short'
  )
  const timezone = getTimezoneShort(unlockTime)

  return (
    <div>
      <SubmissionHeading>Submit Assignment</SubmissionHeading>
      <PreviewTextMessage>
        {`Assignment submission will become available on ${formattedUnlockTime} ${timezone}.`}
      </PreviewTextMessage>
    </div>
  )
}

export default AssignmentPreviewSection
