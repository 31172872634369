import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import api from '../../api'
import LectureNotesAndExamples from './LectureNotesAndExamples'
import JDoodleCompiler from '../JDoodleCompiler/JDoodleCompiler'
import Modal from '../Modals/ConfirmationModal/Modal'
import { NotesAndEditorSection, Wrapper } from './styles'

const NotesAndEditor = ({
  lectureVideos,
  sectionBreadcrumbArr,
  activeNavTab,
  onTabClick
}) => {
  const [exampleCodes, setExampleCodes] = useState([])
  const [errorFetch, setErrorFetch] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [starterCode, setStarterCode] = useState()
  const [selectedExampleCodeIndex, setSelectedExampleCodeIndex] = useState()
  const [shouldOpenEditor, setShouldOpenEditor] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const editorCode = useRef()
  const replacementCodeIndex = useRef()

  const modalText = {
    text1: 'Do you want to replace the current code in the editor?',
    text2: 'We recommending exporting the work you have done before loading another code file.'
  }

  useEffect(() => {
    (async () => {
      const { lecturevideos } = lectureVideos
      const assetUrls = lecturevideos[0].codingExamples?.map(example => example?.file?.url)

      setIsFetching(true)

      try {
        const exampleCodes = await Promise.all(assetUrls.map(url => (
          api.fetchAsset(encodeURIComponent(url))
        )))

        setExampleCodes(exampleCodes)
      } catch {
        setErrorFetch(true)
      }

      setIsFetching(false)
    })()
    // eslint-disable-next-line
  }, [lectureVideos])

  useEffect(() => {
    if (shouldOpenEditor) return

    const exampleCode = exampleCodes[selectedExampleCodeIndex]

    setStarterCode(getEditorCode(exampleCode))
    editorCode.current = exampleCode

    setShouldOpenEditor(true)
    return onTabClick(2)
    // eslint-disable-next-line
  }, [shouldOpenEditor])

  const onExampleCodeClick = (index) => {
    const { current: code } = editorCode
    const isExampleCode = exampleCodes?.some(exampleCode => (
      exampleCode === code ||
      exampleCode === code?.concat('\n')
    ))

    if (code && !isExampleCode) {
      replacementCodeIndex.current = index
      return setShowModal(true)
    }

    setSelectedExampleCodeIndex(index)
    setShouldOpenEditor(false)
  }

  const getEditorCode = (code) => ({
    value: {
      schema: 'dast',
      document: {
        type: 'root',
        children: [
          {
            code,
            type: 'code',
            language: 'java'
          }
        ]
      }
    }
  }
  )

  const handleConfirm = () => {
    setShowModal(false)
    setSelectedExampleCodeIndex(replacementCodeIndex.current)
    setShouldOpenEditor(false)
  }

  const handleDecline = () => {
    setShowModal(false)
    onTabClick(2)
  }

  const getSelectedExampleFileName = () => {
    if (
      !selectedExampleCodeIndex &&
      selectedExampleCodeIndex !== 0
    ) return ''

    const { lecturevideos = [] } = lectureVideos || {}

    const { title } = lecturevideos?.[0]?.codingExamples
      ?.[selectedExampleCodeIndex] || {}

    return title || ''
  }

  return (
    <NotesAndEditorSection
      show={activeNavTab !== 0}
      isEditorTab={activeNavTab === 2}
      fileName={getSelectedExampleFileName()}
      activeNavTab={activeNavTab}
    >
      {showModal &&
        <Modal
          show={showModal}
          handleClose={() => setShowModal(false)}
          handleConfirm={handleConfirm}
          handleDecline={handleDecline}
          {...modalText}
        />
      }
      <LectureNotesAndExamples
        lectureVideos={lectureVideos}
        sectionBreadcrumbArr={sectionBreadcrumbArr}
        errorFetch={errorFetch}
        isFetching={isFetching}
        onTabClick={onTabClick}
        onExampleCodeClick={onExampleCodeClick}
        activeNavTab={activeNavTab}
      />
      <Wrapper
        isActiveTab={activeNavTab === 2}
        show={activeNavTab === 2}
        isEditorTab
      >
        {shouldOpenEditor && (
          <JDoodleCompiler
            data-testid='jdoodle-compiler'
            starterCode={starterCode}
            saveEditorContent={(message) => {
              editorCode.current = message
            }}
          />
        )}
      </Wrapper>
    </NotesAndEditorSection>
  )
}

NotesAndEditor.propTypes = {
  lectureVideos: PropTypes.object,
  sectionBreadcrumbArr: PropTypes.array,
  activeNavTab: PropTypes.number,
  onTabClick: PropTypes.func
}

NotesAndEditor.displayName = 'NotesAndEditorSection'
export default NotesAndEditor
