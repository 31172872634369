import styled from 'styled-components'

export const CustomCheckBox = styled.label`
  display: flex;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  margin: 0px;
  margin-bottom: ${({ mb }) => mb ? `${mb}px` : '16px'};
  align-items: flex-start;
  ${props => props.disabled && 'opacity: 0.5'};

  p {
    margin: 0px;
    margin-top: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  input {
    position: absolute;
    top: 1.5px;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    position: relative;
    padding: 5px;
    display: block;
    margin-right: 8px;
    font-size: unset!important;
    height: 16px!important;
    width: 16px!important;
    background-color: transparent;
    border: 1px solid #5fc4b8;
    box-sizing: border-box;
    border-radius: 3px;
  }

  span:after {
    content: '';
    position: absolute;
    display: ${props => props.checked ? 'block' : 'none'};
    left: 4.5px;
    top: 1.3px;
    width: 5px;
    height: 10px;
    border: solid #5fc4b8;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
