import classNames from 'classnames'
import debounce from 'lodash/debounce'
import React, { Component } from 'react'
import LoadingSpinner from '../LoadingSpinner/LoadingAnimation'
import './Editor.css'
import { asyncCheckStudentAnswer } from './utils'

const LAST_TD_TITLE = 'Nudge up'

export default class MathType extends Component {
  constructor (props) {
    super(props)
    this.state = {
      editorReady: false,
      editorError: false,
      checkInputTimer: null
    }
    this.editor = null
    this.containerRef = React.createRef()
    this.prevMathML = null
    // To check and set error state via timeout when waited too long
    this.editorInitAt = 0
    this.editorInitTimeoutId = 0
    this.editorObserver = null
  }

  componentDidMount () {
    const { containerRef: { current } } = this
    // Watch for element content change to detect all changes from the editor
    const config = { childList: true, subtree: true }

    const callback = () => {
      clearTimeout(this.state.checkInputTimer)

      const newTimer = setTimeout(() => {
        this.updateReadyState()
        this.onChange()
      }, 500)

      this.setState({ checkInputTimer: newTimer })
    }

    this.editorObserver = new MutationObserver(callback)
    this.editorObserver.observe(current, config)
    this.loadMathType()
  }

  componentWillUnmount () {
    this.editorObserver.disconnect()
    this.unloadMathType()
  }

  // PUBLIC METHODS:

  getMathML = () => {
    const { editor } = this
    if (!editor) return ''
    return editor.getMathML()
  }

  setMathML = mathML => {
    const { editor } = this
    if (!editor) return

    mathML = mathML?.toString() || '<math />'
    const isMathMLInput = mathML.startsWith('<math')
    if (!isMathMLInput) {
      mathML = `<math xmlns="http://www.w3.org/1998/Math/MathML"><mtext>${mathML}</mtext></math>`
    }

    editor.setMathML(mathML)
  }

  asyncCheckAnswer = async (answer) => {
    const { editor } = this
    if (!editor) return false

    const {
      questionConfiguration,
      additionalConfigurations
    } = this.props
    const studentAnswer = answer || editor.getMathML()

    const questionResult = await asyncCheckStudentAnswer({
      questionConfiguration, studentAnswer, additionalConfigurations
    })

    return questionResult

    // DISABLE HANDWRITTEN CONVERSION
    // It accepts incorrect answers, but we keep it here
    //    for reference of possible reuse in the future
    // const promises = [
    //   asyncCheckStudentAnswer(questionConfiguration, studentAnswer),
    //   this.asyncGetMathMLFromHand().then(handMathML =>
    //     asyncCheckStudentAnswer(questionConfiguration, handMathML))
    // ]
    // // Promise.race will try to get the first result
    // // If it's incorrect it will wait for both using Promise.all
    // return Promise.race(promises).then(r => r ||
    //   Promise.all(promises).then(r => r[0] || r[1]))
  }

  // PRIVATE METHODS:

  loadMathType = () => {
    this.editor = window.com.wiris.jsEditor.JsEditor.newInstance({
      language: 'en',
      // Fix bug for the math character pi
      // https://github.com/outlier-org/calculus-static/pull/1434#pullrequestreview-378450863
      toolbar: `<toolbar ref="general">
        <tab ref="general">
          <item ref="&#960;" before="numberPi" />
          <removeItem ref="numberPi" />
        </tab>
        <tab ref="symbols">
          <item ref="&#960;" before="numberPi" />
          <removeItem ref="numberPi" />
          <item ref="&#8245;" before="aposApos" />
          <removeItem ref="aposApos" />
        </tab>
      </toolbar>`,
      // Same with public/quizzes/resources/quizzes.js
      // Also add some more keywords for arc: https://github.com/outlier-org/calculus-static/pull/1586#pullrequestreview-399482997
      reservedWords: [
        'exp', 'log', 'ln', 'min', 'max', 'sign',
        'sin', 'cos', 'tan', 'cot', 'cotan',
        'asin', 'acos', 'atan', 'acot', 'acotan',
        'arcsin', 'arccos', 'arctan', 'arccot', 'arccotan',
        'sinh', 'cosh', 'tanh', 'coth', 'cotanh',
        'asinh', 'acosh', 'atanh', 'acoth', 'acotanh',
        'arcsinh', 'arccosh', 'arctanh', 'arccoth', 'arccotanh',
        'sec', 'sen', 'cosec', 'csc',
        'asec', 'asen', 'acosec', 'acsc',
        'arcsec', 'arcsen', 'arccosec', 'arccsc',
        'sech', 'senh', 'cosech', 'csch',
        'asech', 'asenh', 'acosech', 'acsch',
        'arcsech', 'arcsenh', 'arccosech', 'arccsch'
      ].join(', '),
      forceReservedWords: true,
      // https://docs.wiris.com/en/mathtype/mathtype_web/features/autoformat
      autoformat: true
    })
    const { editor, containerRef: { current } } = this
    editor.insertInto(current)
    this.editorInitAt = Date.now()
    this.editorInitTimeoutId = setTimeout(this.updateReadyState, 10000)
  }

  unloadMathType = () => {
    const { editor, containerRef: { current } } = this
    editor.close()
    current.innerHTML = ''
    this.clearEditorInitTimeout()
  }

  clearEditorInitTimeout = () => {
    if (this.editorInitTimeoutId) clearTimeout(this.editorInitTimeoutId)
    this.editorInitAt = 0
    this.editorInitTimeoutId = 0
  }

  reloadMathType = () => {
    this.setState({ editorReady: false, editorError: false })
    const mathML = this.getMathML()
    this.unloadMathType()
    this.loadMathType()
    this.setMathML(mathML)
  }

  onReloadMathTypeClick = e => {
    e.preventDefault()
    this.reloadMathType()
  }

  checkButtons = (td) => {
    const extraButtons = ['Leftwards double arrow',
      'Rightwards double arrow', 'Left right double arrow']
    return extraButtons.includes(td.childNodes[0].title)
  }

  getToolbarElement = () => {
    const { containerRef: { current } } = this

    const toolBarSectionNodes = current
      .getElementsByClassName('wrs_multipleRowPanel')
    this.renderToolButtons(toolBarSectionNodes)
  }

  renderToolButtons = (toolBarSectionNodes) => {
    const toolBarSectionArray = [...toolBarSectionNodes]
    toolBarSectionArray.forEach(toolBarSection => {
      const allToolbarSectionTd = toolBarSection.querySelectorAll('td')
      const toolBarSectionItems = [...toolBarSection.childNodes]
      let isEnd = false

      toolBarSectionItems.forEach(toolBarSectionItem => {
        if (toolBarSectionItem.tagName !== 'TABLE') {
          return toolBarSection.appendChild(toolBarSectionItem)
        }

        const table = document.createElement('table')
        table.className = 'wrs_layoutFor2Rows spacing'
        const tbody = document.createElement('tbody')
        const newFirstRow = document.createElement('tr')
        const newSecondRow = document.createElement('tr')
        const toolbarItem = [...toolBarSectionItem.getElementsByTagName('td')]

        toolbarItem.forEach((td, i) => {
          if (td.childNodes[0].title === LAST_TD_TITLE) {
            isEnd = true
          }

          if (isEnd || this.checkButtons(td)) return

          if (allToolbarSectionTd.length < 21 ||
            i < (toolbarItem.length / 2).toFixed()) {
            return newFirstRow.appendChild(td)
          }

          newSecondRow.appendChild(td)
        })

        tbody.appendChild(newFirstRow)
        tbody.appendChild(newSecondRow)
        table.appendChild(tbody)
        toolBarSection.appendChild(table)
        toolBarSectionItem.remove()
      })
    })
  }

  updateReadyState = debounce(() => {
    const { editorInitTimeoutId, editorInitAt, containerRef: { current } } = this
    if (!editorInitTimeoutId || !editorInitAt || !current) return

    const td = current.querySelector('.wrs_multipleRowPanel:nth-child(1) td')
    if (td && (td.clientWidth || td.offsetWidth)) {
      this.getToolbarElement()
      this.setState({ editorReady: true })
      this.clearEditorInitTimeout()
    } else if (Date.now() - editorInitAt > 10000) {
      // If it's too long (10 seconds) has passed since editor init
      //    we should show an error to let the user refresh the browser
      this.setState({ editorError: true })
      this.clearEditorInitTimeout()
    }
  }, 100, { maxWait: 300 })

  onChange = () => {
    const { editor, prevMathML, props: { onChange } } = this
    if (!editor || !onChange) return

    const mathML = editor.getMathML()
    const nextMathML = editor.isFormulaEmpty(mathML) ? '' : mathML
    this.prevMathML = nextMathML

    if (nextMathML !== prevMathML) onChange(nextMathML)
  }

  asyncGetMathMLFromHand = () => {
    const { editor } = this
    const hand = window.com.wiris.js.JsHand.newInstance({ readOnly: true })
    hand.setStrokes(editor.editorModel.getHandStrokes())

    const intervalAt = Date.now()
    return new Promise(resolve => {
      const intervalId = setInterval(() => {
        const mathML = hand.getMathML()
        const empty = /^<math[^>]*><\/math>$/.test(mathML) ||
          editor.isFormulaEmpty(mathML)
        if (!empty || Date.now() - intervalAt > 3000) {
          resolve(mathML)
          clearInterval(intervalId)
        }
      }, 300)
    })
  }

  render () {
    const { editorReady, editorError } = this.state
    return <>
      {editorError && <div className='MathType-Error'>
        {'Failed to load math editor input. '}
        <a href='#reload-math-type' onClick={this.onReloadMathTypeClick}>Retry</a>
      </div>}
      {!editorError && !editorReady && <LoadingSpinner />}
      <div
        className={classNames('MathType', {
          ready: editorReady,
          error: editorError
        })}
        ref={this.containerRef}
      />
    </>
  }
}
