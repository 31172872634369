import styled from 'styled-components'

export const PolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 13px;
  padding-right: 20px;
  margin-bottom: 12px;

  div:first-child {
    cursor: pointer;
  }
`

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: ${props => props.marginLeft || '0px'}
`

export const Text = styled.p`
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 0;
`
export const Title = styled(Text)`
  font-weight: 400;  
`

export const Content = styled(Text)`
  margin-top: 11px;
  font-weight: 300;  
`
