import styled, { createGlobalStyle } from 'styled-components'
import media from '../../../mediaQueries'
import { Button as BootstrapButton } from 'react-bootstrap'

export const ModalContainer = styled.div`
 width: 517px;
 position: fixed;
 top: 40%;
 left: 50%;
 transform: translate(-50%, -50%);
 text-align: left;
 padding: 24px;
 background-color: #25272B;
 border-radius: 5px;
 font-size: 16px;
 z-index: 100;
 button {
  margin-right: 10px;
  width: auto;
 }
 h1 {
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
 }
`

export const GlobalStyle = createGlobalStyle`
  .modal-dialog {
    max-width: none;
  }

  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-body {
    padding: 24px;
    & > p {
      margin-bottom: 18px;
      font-weight: 400;
    }
  }

  .modal-content {
    width: 455px;
    background-color: #25272b;
    display: flex;
    align-items: flex-start;
    margin: auto;
    padding: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    ${media.mobile`
      width: 327px;
    `}
  }
`

export const ExitModalHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 24px;

  & > p {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    flex: 1;
    margin-bottom: 0;
  }

  & > img {
    cursor: pointer;
    width: 14px;
    height: 14px;
    margin-left: auto;
  }
`

export const HorizontalLine = styled.hr`
  width: 100%;
  background: #C4C4C4;
  opacity: 0.1;
  border: 1px solid #FFFFFF;
  margin: 0;
`

export const ButtonsContainer = styled.div`
  margin-top: 24px;
  display: flex;

  ${media.mobile`
    flex-direction: column;

    & > button:first-child {
      margin-bottom: 10px;
    }
  `}

`
const buttonStyle = `
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const PrimaryButton = styled(BootstrapButton)`
  ${buttonStyle}
  background-color: #5FC4B8;
  color: #161618;
  border: none;
  margin-right: 16px;
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || '30px'};
  padding: ${({ height }) => height === 'auto' ? '16px 44px' : '0 44px'};

  ${media.tablet`
    width: 100%;
  `}

  &:active {
    background-color: #B8FFF74D !important;
    color: #161618 !important;
    border: 0 none !important;
  }

  &:focus {
    outline: 0;
    background-color: #5FC4B8;
    color: #161618;
    border: none;
    box-shadow: unset;
  }

  &:hover {
    background-color: #B8FFF7;
    color: #161618;
    border: none;
  }

`

export const SecondaryButton = styled(BootstrapButton)`
  ${buttonStyle}
  background-color: transparent;
  color: #5FC4B8;
  border: 3px solid #5FC4B8;
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || '30px'};
  padding: ${({ height }) => height === 'auto' ? '16px 44px' : '0 44px'};

  ${media.tablet`
    width: 100%;
  `}

  &:active {
    background-color: #1D1D1F !important;
    color: #B8FFF74D !important;
    border: 3px solid #B8FFF74D !important;
  }

  &:focus {
    outline: 0;
    color: #5FC4B8 !important;
    border: 3px solid #5FC4B8 !important;
    box-shadow: unset;
  }

  &:not(:disabled):hover {
    color: #161618 !important;
    border: 3px solid #B8FFF7 !important;
    background: #B8FFF7 !important;
  }

`
