import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .btn {
    width: 150px;
    margin: 6px 4px;
    height: 40px;
    padding: 0px 8px;
    ${mediaqueries.small`
      flex-grow: 1 !important;
    `}
  }
  .btn-secondary:disabled {
    background-color: #161618;
    border-color: #77b1af;
  }

  ${({ isRedesign }) => isRedesign && `
    justify-content: flex-start;
    margin-top: 48px;

    .btn-secondary:disabled {
      background-color: #161618;
      border-color: #77b1af;
      color: #5FC4B8;
    }

    .btn-primary {
      background-color: #5FC4B8;
    }
    
    .btn-secondary {
      margin-left: 0px;
    }

    .btn {
      width: 64px;
      border: #5FC4B8 1px solid;
    }
  `}

  ${mediaqueries.mobile`
    ${({ isRedesign }) => isRedesign && `
      .btn {
        flex-grow: 1 !important;
        max-width: 157px !important;  

      :nth-child(2) {
          margin-left: 6.5px !important;
          margin-right: 0px !important;
        }  
      }      
    `}
  `}

`

export const ShowSolutionDiv = styled.div`
    margin-top:0px;
`
export const CardContent = styled.div`
  ${mediaqueries.mobile`
    h1, h3 {
      font-size: 24px;
      letter-spacing: none;
      line-height: 27px;
    }
    iframe{
      max-width: 100%;
    }
    overflow-x: scroll;
  `}
  ${isALRedesign => isALRedesign && `
  #card-lesson-text {
    h1,h2 {
      font-size: 32px;
      margin: 0 0 24px !important;
      text-transform: initial;
      line-height: 29px;
      font-weight: 300;
    }
    h3,h4 {
      font-size: 24px;
      margin: 0 0 24px !important;
      font-weight: 300;
      text-transform: initial;
    }
    em {
      margin-right: 4px;
    }
  }
    .rtecenter {
      margin-top: 0px !important;
    }
    .paragraph {
      margin-bottom: 24px;
    }
  `}
  ${({ isTitleUpdates }) => isTitleUpdates && `
      h1 {
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        text-transform: none;
      }
  `}
`

export const LessonWrapper = styled.div`
  ${({ isALRedesign }) => isALRedesign && `
    background: #121212 !important;
    border-radius: 0px 0px 5px 5px !important;
    padding 24px !important;
  `}
`

export const IllustrationWrapper = styled.div`
  ${({ isALRedesign }) => isALRedesign && `
    padding-top: 0px !important;
    .col-12 {
      margin-bottom: 0px !important;
    }
  `}
`

export const AdaptionStatementWrapper = styled.div`
  ${({ isALRedesign }) => isALRedesign && `
    text-align: left !important;
  `}
`

export const LessonHeader = styled.div`
  height: 70px;
  background: #121212;
  border-radius: 5px 5px 0px 0px;
  margin-bottom: 1px;
  display: flex;
  padding: 0px 24px;
  align-items: center;
  justify-content: space-between;
`

export const LessonHeaderText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
`
export const PinWrapper = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #98A4AE;
  cursor: pointer;
`
