import styled from 'styled-components'

export const CourseDetailWrap = styled.div`
  padding: 24px;
`

export const Divider = styled.div`
  opacity: 0.1;
  border: 1px solid #ffffff;
  margin-bottom: 24px;
`

export const MessageCard = styled.div`
  background-color: ${({ backgroundColor }) => (backgroundColor || '#1d1d1f')};
  border-radius: 5px;
  padding: ${({ padding }) => (padding || '16px')};
  display: flex;
  align-items: center;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`};
  width: 100%;
  h4 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    margin-bottom: 0px;
    padding-right: 16px;
  }
  p, li {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.2px;
    color: ${({ color }) => (color || '#B1BFC5')};
    margin-bottom: 0px;
    padding-right: 16px;
    span {
      color: #969BFF;
    }
  }
  ul {
    padding-left: 25px;
    margin-bottom: 0;
  }
  `

export const WorkingAhead = styled.p`
  p:nth-child(3) {
    font-weight: 700;
    margin-top: 6px;
  }
`

export const ExtensionDeadlineLink = styled.div`
  font-weight: 700;
  font-size: 14px;
  margin-top: 8px;
  a {
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
`

export const ExtensionLinkArrowIcon = styled.span`
  display: inline-block;
  width: 14px;
  height: 9px;
  margin-left: 13px;
  background-color: #ffffff;
  mask: ${({ src }) => `url(${src}) center/cover no-repeat;`};
  mask-size: contain;
`

export const ActivityListWrap = styled.div``

export const SectionHeader = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  opacity: ${({ completed }) => (
    completed ? 0 : 1
  )};
  transition: opacity 3s, height 5s;
  h5 {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ warningColor }) => (warningColor || '#ffffff')};
    margin: 0px;
    max-width: 50%;
  }
  span {
    width: 2px;
    height: 2px;
    background: #98a4ae;
    border-radius: 50%;
    margin: 0px 8px;
  }
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ warningColor }) => (warningColor || '#b1bfc5')};
    margin: 0px;
    margin-right: 8px;
  }
`

export const ActivityList = styled.ul`
  padding: 0px;
  padding-left: 40px;
  list-style-type: circle;
`

export const ActivityListItem = styled.li`
  margin-bottom: 8px;
  opacity: ${({ active }) => (active ? 1 : 0.2)};
  cursor: pointer;
  margin-bottom: ${({ completed }) => (completed ? '-52px' : '8px')};
  opacity: ${({ active, completed }) => (
    completed ? 0 : active ? 1 : 0.2
  )};
  transition: opacity 3s, margin 4s;
  position: relative;
  ${({ isTermBreak }) => isTermBreak && `
    list-style-type: none;
    margin-left: -40px;
  `}
  &::marker {
    color: ${({ assessmentColor, warningColor }) => (
    assessmentColor || warningColor || '#98a4ae')};
    line-height: 10px;
    content: ${({ completed }) => (completed ? "' '" : 'unset')}
  }
  h5 {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    margin-bottom: 4px;
  }
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: ${({ isTermBreak }) => isTermBreak ? '16px' : '14px'};
    line-height: 17px;
    color: ${({ isTermBreak }) => isTermBreak ? '#ffffff' : '#98a4ae'};
    margin: 0px;
    margin-top: ${({ textAnimation }) => (textAnimation ? '-18px' : '0px')};
    opacity: ${({ textAnimation }) => (textAnimation ? '0' : '1')};
    transition: opacity 3s, margin 4s;
  }
  h4{
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #98a4ae;
    margin: 0px;
    margin-bottom: ${({ textAnimation }) => (!textAnimation ? '-18px' : '0px')};
    opacity: ${({ textAnimation }) => (textAnimation ? '1' : '0')};
    transition: opacity 3s, margin 4s;
  }
  span{
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${({ assessmentColor }) => (assessmentColor || '#ffffff')};
    padding-left: 8px;
  }
  .checkMark {
    display: ${({ completed }) => (completed ? 'block' : 'none')};
    &.draw:after {
      animation-duration: 1200ms;
      animation-timing-function: ease;
      animation-name: checkMark;
      transform: scaleX(-1) rotate(135deg);
    }
    &:after {
      opacity: 1;
      height: 17px;
      width: 10px ;
      transform-origin: left top;
      border-right: 3px solid #fff;
      border-top: 3px solid #fff;
      content: '';
      left: -25px;
      top: 13px;
      position: absolute;
    }
  }
  @keyframes checkMark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: 5px;
      opacity: 1;
    }
    40% {
      height: 17px;
      width: 10px;
      opacity: 1;
    }
    100% {
      height: 17px;
      width: 10px;
      opacity: 1;
    }
  }
`
export const MoreButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  padding: 0px;
  outline: none !important;
  margin-bottom: 20px;
  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0px;
    margin-left: 8px;
  }
`

export const DropdownWrap = styled.div`
  background: #35383E;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 50%);
  border-radius: 5px;
  position: absolute;
  width: 314px;
  left: -41px;
  top: 26px;
  z-index: 9999999;
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: opacity 0.5s;
  display: ${({ show }) => (show ? 'block' : 'none')};
`

export const DropdownItem = styled.div`
  padding: 10px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  &:last-child{
    border: none;
  }
`

export const Overlay = styled.div`
  position: fixed;
  right: 0px;
  top: 68px;
  height: calc(100vh - 68px);
  background-color: #25272B;
  z-index: 999;
  width: 375px;
  display: ${({ show }) => (show ? 'block' : 'none')};
  opacity: 0.4;
`

export const CollapseWrapper = styled.div`
  margin-top: 16px;
  ${({ open }) => open && `
    background-color: #33373E;
    padding: 16px;
    border-radius: 5px;
  `};
`

export const CollapseToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
  ${({ open }) => !open && `
    background-color: #33373E;
    padding: 16px;
    height: 50px;
    border-radius: 5px;
  `};
`

export const CollapseText = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin: 0px;
  margin-left: 8px;
`

export const WhiteLink = styled.a`
  text-decoration: underline !important;
  color: white !important;
`

export const ContentWrapper = styled.div`
  flex: 1;
`

export const StyledP = styled.p`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  margin-top: 16px;
  margin-bottom: 0px;
`
