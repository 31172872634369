import React from 'react'
import classNames from 'classnames'

export default function CourseButton (props) {
  const {
    id,
    name,
    value,
    style,
    className,
    onClick,
    disabled,
    dataCypress,
    dataUuid,
    dataTestId,
    title,
    onMouseEnter,
    onMouseLeave,
    children
  } = props

  return (
    <button
      id={id}
      name={name}
      value={value}
      style={style}
      className={typeof className === 'string' ? className : classNames({
        btn: true,
        ...className
      })}
      title={title}
      onClick={onClick}
      disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      data-uuid={dataUuid}
      data-testid={dataTestId}
      data-cy={dataCypress}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  )
}
