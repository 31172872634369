import React from 'react'
import {
  FAQContainer,
  FAQText
} from './styled'

export default function FAQ () {
  return (
    <FAQContainer data-cy='faqContainer'>
      <FAQText>
        <b>
          More Questions?
        </b>
        {' '}
        Check out the
        {' '}
        <a href='https://outlierorg.zendesk.com/hc/en-us'>
          Outlier Help Center
        </a>
        {' '}
        for information about transfer credits, study tips, and more.
      </FAQText>
    </FAQContainer>
  )
}
