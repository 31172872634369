import React, { useEffect, useState, useRef } from 'react'
import CourseButton from '../CourseButton/CourseButton'
import {
  NextOverlay,
  DetailText,
  Title,
  AutoPlayText
} from './styles/next'
import { CloseButton } from './styles'
import { getVideoByID } from '../../utilities/sectionUtils'
import { emitter } from '../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'

const COUNT_SECONDS = 10

const NextLectureOverlay = props => {
  const [counter, setCounter] = useState(COUNT_SECONDS)
  const [videoTitle, setVideoTitle] = useState('')
  const intervalID = useRef(null)

  useEffect(() => {
    // sets the next video title
    const { nextChildUUID, multipleVideos } = props
    const nextVideo = getVideoByID(nextChildUUID, multipleVideos)
    if (!nextVideo) return
    setVideoTitle(nextVideo.title)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    intervalID.current = setInterval(
      () => setCounter(prevCounter => prevCounter - 1), 1000
    )
    return () => clearInterval(intervalID.current)
  }, [])

  useEffect(() => {
    if (counter !== 0) return
    moveToNextLecture()
    return clearInterval(intervalID.current)
    // eslint-disable-next-line
  }, [counter])

  const moveToNextLecture = () => {
    const { sectionUUID, nextChildUUID } = props
    emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/${nextChildUUID}`)
  }

  const { onClose } = props
  return (
    <NextOverlay>
      {onClose && <CloseButton onClick={onClose} />}
      <DetailText>UP NEXT</DetailText>
      <Title>
        {videoTitle}
      </Title>
      <AutoPlayText>
        Playing in {counter} Seconds
      </AutoPlayText>
      <CourseButton
        onClick={moveToNextLecture}
        className='btn btn-primary'
        style={{ width: '150px' }}
      >
        Continue
      </CourseButton>
    </NextOverlay>
  )
}

export default NextLectureOverlay
