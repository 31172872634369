import React from 'react'
import config from '../../config'
import {
  isCohortEnded,
  shouldShowCurrentGrade,
  shouldShowFinalGrade
} from '../../utilities/courseUtils'
import { checkFinalSubmission, getGradeText } from './utils/courseHomeUtils'
import { GradeContainer, GradeText, Link, PendingGradeText } from './styles'
import GradeTooltip from './GradeTooltip'

const GradeMessage = ({
  cohort,
  currentGrade,
  projectedGrade,
  courseData,
  isAuditor,
  studentData
}) => {
  const {
    latestCohort,
    cohortExamDates,
    cohortData
  } = cohort

  const { name: cohortName } = cohortData || {}
  const { courseEndDate, cohortEndDate } = cohortExamDates || {}
  const {
    dateStart,
    cohortName: latestCohortName,
    finalGradeDate,
    hideStudentGradeDisplay
  } = latestCohort || {}
  const isGGUCohort = cohortName?.toLowerCase().includes('ggu')
  const cohortNameIncludesJan1123 = latestCohortName?.includes('1/11/23')
  const cohortNameIncludesMay0823 = latestCohortName?.includes('5/8/23')
  const isJan1123GGUCohort = ((dateStart && dateStart === '2023-01-11') ||
        cohortNameIncludesJan1123) && isGGUCohort
  const isMay0823GGUCohort = ((dateStart && dateStart === '2023-05-08') ||
        cohortNameIncludesMay0823) && isGGUCohort
  const showFinalGrade = shouldShowFinalGrade(courseEndDate, cohortEndDate)
  const isFinalSubmitted = checkFinalSubmission(courseData, studentData, latestCohort)
  const isStudentCohortEnded = isCohortEnded(
    courseEndDate,
    cohortEndDate
  )
  const showGGUGradePendingMessage = config.hasInTimeGradeFlag &&
      isGGUCohort &&
      (isFinalSubmitted || isStudentCohortEnded) &&
      !showFinalGrade

  if (showGGUGradePendingMessage || (isMay0823GGUCohort && showFinalGrade)) {
    return (
      <GradeContainer>
        <PendingGradeText style={{ fontsize: '15px' }}>
          Your Instructor is calculating your final grade, which will be
          available soon in the <Link href='https://portal.ggu.edu/' target='_blank' rel='noopener noreferrer'>myGGU student portal.</Link>
        </PendingGradeText>
      </GradeContainer>
    )
  }
  if (hideStudentGradeDisplay && config.hasInTimeGradeFlag) {
    if (isGGUCohort) {
      return (
        <GradeContainer>
            Please {' '}
          <a
            href='#/resources?instructor'
            target='_blank'
            rel='noopener noreferrer'
          >
              contact your instructor
          </a>
          {' '} for your grade.
        </GradeContainer>
      )
    } else {
      return null
    }
  }

  if (isJan1123GGUCohort && showFinalGrade) {
    return (
      <GradeContainer>
            Log into the {' '}
        <a href='https://portal.ggu.edu/' target='_blank' rel='noopener noreferrer'>
            myGGU student portal
        </a>
        {' '} to view your final grade.
      </GradeContainer>
    )
  } else {
    if (isGGUCohort) return null
    const [courseTitleGradeText, showTealBar] = getGradeText({
      isAuditor,
      cohort,
      currentGrade,
      projectedGrade
    })
    const showCurrentGrade = config.hasInTimeGradeFlag &&
            shouldShowCurrentGrade(
              courseEndDate,
              cohortEndDate
            )
    const showTooltip = showCurrentGrade && !isAuditor

    return (
      <GradeContainer showTealBar={showTealBar}>
        {
          showCurrentGrade
            ? `Current Grade: ` : `Final Grade: `
        }
        <GradeText>
          {courseTitleGradeText}
        </GradeText>
        {showTooltip && (
          <GradeTooltip
            finalGradeDate={finalGradeDate}
          />
        )}
      </GradeContainer>
    )
  }
}

export default GradeMessage
