import styled from 'styled-components'
import { Overlay, OverlayText } from './index'

export const Title = styled(OverlayText)`
    font-size: 14px;
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #C1CFD9;
    margin-bottom: 12px;
`

export const Description = styled(OverlayText)`
    font-size: 18px;
    font-weight: normal;
    line-height: 22px;
    margin-bottom: 12px;
    color: var(--white);
`

export const LastOverlay = styled(Overlay)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const ButtonsWrapper = styled.div`
  display: flex;
`
