export const ACTIVE_LEARNING_COMPLETE = 'active-learning-complete'
export const LECTURE_VIDEO_STARTED = 'lecture-started'
export const LECTURE_COMPLETE = 'lecture-complete'
export const LECTURE_VIDEO_PROGRESS = 'lecture-video-progress'
export const SECTION_VIDEO_PROGRESS = 'section-video-progress'
export const PRACTICE_EXERCISES_COMPLETE = 'practice-exercises-complete'
export const GUESSWORK_COMPLETE = 'guesswork-complete'
export const EXAM_COMPLETE = 'exam-complete'
export const EXAM_SECTION = 'exam-section'
export const EXAM_PERCENTAGE = 'exam-percentage'
export const QUIZ_COMPLETE = 'quiz-complete'
export const QUIZ_SECTION = 'quiz-section'
export const SECTION_PROGRESS = 'sectionProgress'
export const MINIMUM_SECTION_PROGRESS = 'minimumSectionProgress'
export const LAST_ACTIVE_LEARNING_UUID = 'last-active-learning-uuid'
export const QUIZ_PERCENTAGE = 'quiz-percentage'
export const STUDENT_ANSWERS = 'studentAnswers'
export const RATINGS = 'ratings'
export const LAST_GUESSWORK_UUID = 'last-guesswork-uuid'
export const LAST_QUIZ_UUID = 'last-quiz-uuid'
export const LAST_EXAM_UUID = 'last-exam-uuid'
export const TERMS_AGREEMENT = 'terms-agreement'
export const DEGREE_REGISTRATION_INCOMPLETE = 'degree-registration-incomplete'
export const EMAIL_VERIFIED = 'email-verified'
export const READINGS_PROGRESS = 'reading-progress'
export const READINGS_COMPLETE = 'reading-complete'
export const CONCEPT_MAP_PROGRESS = 'concept-map-progress'
export const CONCEPT_MAP_COMPLETE = 'concept-map-complete'
export const ASSIGNMENT_PROGRESS = 'assignment-progress'
export const PRACTICE_TERM_COMPLETE = 'practice-term-complete'
export const ASSIGNMENT_FILE_METADATA = 'assignment-file-metadata'
export const ORIENTATION_ACTIVE_LEARNING_COMPLETE = 'orientation-active-learning-complete'
export const ORIENTATION_LAST_ACTIVE_LEARNING_UUID = 'orientation-last-active-learning-uuid'
export const ORIENTATION_LECTURE_COMPLETE = 'orientation-lecture-complete'
export const ORIENTATION_LECTURE_VIDEO_PROGRESS = 'orientation-lecture-video-progress'
export const ORIENTATION_STUDENT_ANSWERS = 'orientation-student-answers'
export const ORIENTATION_SECTION_PROGRESS = 'orientation-section-progress'
export const REVIEW_LECTURE_VIDEO_PROGRESS = 'review-lecture-video-progress'
export const REVIEW_LECTURE_COMPLETE = 'review-lecture-complete'
export const REVIEW_SECTION_PROGRESS = 'review-section-progress'
export const REVIEW_ACTIVE_LEARNING_COMPLETE = 'review-active-learning-complete'
export const REVIEW_LAST_ACTIVE_LEARNING_UUID = 'review-last-active-learning-uuid'
export const REVIEW_STUDENT_ANSWERS = 'review-student-answers'

export const LAST_MOBILE_LOGIN = 'last-mobile-login'
export const CHECKLIST_INTRO_VIEWED = 'checklist-intro-viewed'
export const FREEZE_TABLE_VIEWED = 'freeze-table-viewed'

export const EXAM_LOCK_STATUS = 'exam-locked'
export const EXAM_STATUS = 'exam-status'
