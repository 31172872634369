import styled from 'styled-components'
import media from '../../../mediaQueries'

export const MsgText = styled.span`
  visibility: hidden;
  font-size: 16px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  width: 255px;
  background-color: #25272B;
  color: #ffffff;
  text-align: left;
  padding: 12px;
  border-radius: 5px;
  left: ${props => (props.left) * (2 / 3)}%;
  bottom: 40px;
  position: absolute;
  z-index: 1;
  ${media.tablet`
    left: ${(props => props.left > 55 ? '55%' : props.left + '%')};
  `}
  ${media.mobile`
    left: ${(props => props.left > 37 ? '37%' : props.left + '%')};
  `}
  ${media.small`
    left: ${(props => props.left > 30 ? '30%' : props.left + '%')};
  `}
`
export const ProgressWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 8px;
  margin-bottom: 8px;
  margin-top: -6px;
  &:hover ${MsgText} {
      visibility: visible;
    }
`
export const Container = styled.div`
  height: 3px;
  width: 100%;
  margin-bottom: ${({ noMargin }) => noMargin ? '' : '8px'};
  position: relative;
`
export const BaseBox = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  transition: width 1s ease-in-out;
`
export const Background = styled(BaseBox)`
  background: #5E636B;
  width: 100%;
`
export const Progress = styled(BaseBox)`
  background: ${({ color }) => color};
  width: ${({ percent }) => percent}%;
`
export const Marker = styled.div`
  height: 36px;
  width: 2px;
  background-color: ${({ color }) => color};
  position: absolute;
  top: 0;
  left: ${(props => props.left ? props.left + '%' : '0%')};
  border-radius: 2px;
  transform: translate(-50%, calc(-50% + 2px))
`
export const ProgressBarDot = styled.div`
  position: absolute;
  left: ${({ left }) => left}%;
  height: 8px;
  width: 8px;
  background-color: ${({ filled }) => filled};
  border: 2px solid ${({ borderColor }) => borderColor};
  border-radius: 50%;
  box-sizing: border-box;
  transform: translate(-50%, -25%);
  z-index: 1;
  ${props => !props.visible && ({
    display: 'none'
  })}
`
