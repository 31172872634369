import React, { Component } from 'react'
import Checkbox from '../FormComponent/Checkbox'
import QuestionComponent from '../QuestionComponent/QuestionComponent'
import CourseButton from '../CourseButton/CourseButton'
import { emitter } from '../Emitter/Emitter'
import Context from '../Context/Context'
import QuestionNextButton from '../QuestionComponent/QuestionNextButton'
import StepsSolutionModal from '../StepsSolutionModal/StepsSolutionModal'
import {
  ACTIVITY_TYPES
} from '../../Constants/sectionType'
import {
  ON_STUDENT_ANSWER
} from '../../Constants/emitterKeys'
import { findAnswer, isMultiAnswerSingleString } from '../../utilities/questions'
import ShowFeedback from '../ShowFeedBack/ShowFeedBack'
import isEqual from 'lodash/isEqual'
import {
  PaginationWrapper,
  QuestionCard,
  LessonText
} from './styles'
import { CORRECT, INCORRECT } from '../../Constants/result'
import JDoodleCompiler from '../JDoodleCompiler/JDoodleCompiler'

class MultipleChoiceQuestionGuesswork extends Component {
  constructor (props) {
    super(props)

    this.toggleCheckbox = this.toggleCheckbox.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.CheckSolution = this.CheckSolution.bind(this)
    this.createCheckbox = this.createCheckbox.bind(this)
    this.createCheckboxes = this.createCheckboxes.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.setModalContent = this.setModalContent.bind(this)
    this.popTriesResultIfExam = this.popTriesResultIfExam.bind(this)

    this.state = {
      result: '',
      tryAttempt: 0,
      findAns: null,
      isNextDisabled: true,
      isDisplayNext: true,
      solution: {},
      isDisplayCheckOrSubmit: true,
      displayColor: false,
      correctIncorrectClass: '',
      selectedOption: '',
      systemAnswer: '',
      isCorrect: '',
      triesPerQuestion: this.props.question.tries_per_question
        ? parseInt(this.props.question.tries_per_question)
        : 1,
      triesResult: [],
      isAnsweringDisabled: false,
      studentAnswerRes: {},
      modalContent: ''
    }
  }

  componentWillMount () {
    this.selectedCheckboxes = new Set()
  }

  setModalContent (modalContent) {
    this.setState({ modalContent })
  }

  componentDidUpdate (prevProps, prevState) {
    const { isDisplayNext } = this.state

    const {
      incorrectAnsIndex
    } = this.props
    this.lastSectionQuiz = this.getLastSectionQuiz()
    this.lastSectionExam = this.getLastSectionExam()

    const {
      question: {
        answer: validAnswer,
        Question_uuid: questionUUID
      }
    } = this.props
    const { studentData: { studentAnswers } } = this.context
    const validAnswerFromDato = isMultiAnswerSingleString(validAnswer)

    let fAns = findAnswer(questionUUID, studentAnswers)
    const currentAnswer = fAns
    if (!fAns?.answer && fAns?.correct === undefined && !fAns?.tries) fAns = null
    if (fAns?.answer) fAns = [fAns.answer, fAns.correct, fAns.tries]

    if (fAns && (prevProps.question.Question_uuid !==
      this.props.question.Question_uuid || !isDisplayNext)) {
      if (fAns?.length) {
        incorrectAnsIndex(fAns[0])
        const formattedStudentAnswer = typeof fAns[0] === 'number'
          ? [fAns[0] + 1]
          : Array.isArray(fAns[0])
            ? fAns[0].map(ans => ans + 1)
            : []

        this.setState({
          findAns: typeof fAns[0] === 'number' ? [fAns[0]] : fAns[0],
          result: fAns[1] ? CORRECT : INCORRECT,
          correctIncorrectClass: isEqual(
            formattedStudentAnswer.sort(),
            validAnswerFromDato.map(Number).sort()
          )
            ? 'ans-correct'
            : 'incorrect ans-incorrect',
          isNextDisabled: false,
          systemAnswer: this.props.question.answer,
          isDisplayNext: true,
          isDisplayCheckOrSubmit: false,
          displayColor: true,
          triesPerQuestion: this.props.question.tries_per_question
            ? parseInt(this.props.question.tries_per_question)
            : 1,
          triesResult: fAns[2] || [],
          studentAnswerRes: currentAnswer,
          selectedOption: currentAnswer?.answer,
          selectedValues: currentAnswer?.answer,
          isAnsweringDisabled: true
        })
      }
    } else if (this.lastSectionQuiz !== this.initialLastSectionQuiz ||
      this.lastSectionExam !== this.initialLastSectionExam) {
      this.initialLastSectionQuiz = this.lastSectionQuiz
      this.initialLastSectionExam = this.lastSectionExam
      window.location.reload()
    } else if (prevProps.question.Question_uuid !==
      this.props.question.Question_uuid && !fAns) {
      this.selectedCheckboxes.clear()
      this.setState({
        findAns: '',
        result: '',
        selectedValues: '',
        selectedOption: '',
        studentAnswerRes: fAns || {},
        isNextDisabled: true,
        tryAttempt: 0,
        correctIncorrectClass: '',
        systemAnswer: this.props.question.answer,
        isDisplayNext: false,
        isDisplayCheckOrSubmit: true,
        displayColor: false,
        triesPerQuestion: this.props.question.tries_per_question
          ? parseInt(this.props.question.tries_per_question)
          : 1,
        triesResult: fAns ? fAns[2] : [],
        isAnsweringDisabled: false
      })
    }
  }

  toggleCheckbox (index) {
    /* ------- Clear TIme out on Reveal Answer Click ----- */
    clearTimeout(this.timeOutFuncID)
    /* ------- Clear TIme out on Reveal Answer Click ----- */

    if (this.selectedCheckboxes.has(index)) {
      this.selectedCheckboxes.delete(index)
    } else if (this.props.question.only_allow_one &&
       this.props.question.only_allow_one === true &&
        (this.selectedCheckboxes.size === 0 ||
           this.selectedCheckboxes.size === 1) &&
            typeof (index) !== 'undefined') {
      this.selectedCheckboxes.clear()
      this.selectedCheckboxes.add(index)
    } else if (!this.props.question.only_allow_one &&
       typeof (index) !== 'undefined') {
      this.selectedCheckboxes.add(index)
    }

    this.setState({
      findAns: Array.from(this.selectedCheckboxes),
      isNextDisabled: false,
      correctIncorrectClass: '',
      selectedOption: Array.from(this.selectedCheckboxes)
    })
  }

  componentDidMount () {
    const {
      question: {
        Question_uuid: questionUUID
      }
    } = this.props
    const {
      studentData: { studentAnswers }
    } = this.context
    let fAns = findAnswer(questionUUID, studentAnswers)
    if (!fAns?.answer && fAns?.correct === undefined && !fAns?.tries) fAns = null
    if (fAns?.answer) fAns = [fAns.answer, fAns.correct, fAns.tries]

    this.setState({ systemAnswer: this.props.question.answer,
      isDisplayNext: false
    })

    this.initialLastSectionQuiz = this.getLastSectionQuiz()
    this.lastSectionQuiz = this.initialLastSectionQuiz
    this.initialLastSectionExam = this.getLastSectionExam()
    this.lastSectionExam = this.initialLastSectionExam
    if (fAns?.length) {
      const {
        incorrectAnsIndex,
        question: { answer: validAnswerFromDato }
      } = this.props
      incorrectAnsIndex(fAns[0])
      const formattedStudentAnswer = typeof fAns[0] === 'number'
        ? [fAns[0] + 1]
        : Array.isArray(fAns[0])
          ? fAns[0].map(ans => ans + 1)
          : []
      this.setState({
        findAns: fAns[0],
        result: fAns[1] ? CORRECT : INCORRECT,
        correctIncorrectClass:
          isEqual(
            formattedStudentAnswer.sort(),
            validAnswerFromDato.map(Number).sort()
          )
            ? 'ans-correct'
            : 'incorrect ans-incorrect',
        isNextDisabled: false,
        isDisplayNext: true,
        isDisplayCheckOrSubmit: false,
        displayColor: true,
        triesPerQuestion: this.props.question.tries_per_question
          ? parseInt(this.props.question.tries_per_question)
          : 1,
        triesResult: fAns[2],
        isAnsweringDisabled: true
      })
    }
  }

  getLastSectionQuiz () {
    const { studentData: { 'quiz-section': quizSection } } = this.context
    return Object.keys(quizSection).pop()
  }

  getLastSectionExam () {
    const { studentData: { 'exam-section': examSection } } = this.context
    return Object.keys(examSection).pop()
  }

  getCorrectAnswer = () => {
    const { answer, options } = this.props.question
    const answersArray = isMultiAnswerSingleString(answer)
    return options
      .filter((option, index) => answersArray.includes(`${index + 1}`))
      .join(' ')
  }

  handleFormSubmit () {
    /* ------- Clear TIme out on Reveal Answer Click ----- */
    clearTimeout(this.timeOutFuncID)
    /* ------- Clear TIme out on Reveal Answer Click ----- */
    this.CheckSolution()

    const { setGuessworkCorrectAnswer } = this.props
    setGuessworkCorrectAnswer(this.getCorrectAnswer())
  }

  CheckSolution () {
    const {
      type_of_question: typeOfQuestion,
      incorrectAnsIndex
    } = this.props

    const answers = Array.from(this.selectedCheckboxes)
    const correctAnswer = isMultiAnswerSingleString(this.state.systemAnswer)
    const correctAnswerDec = correctAnswer.map(num => num - 1)
    let ansLog = ''
    const ansOptions = this.props.question.options || []
    const indexForTextToShow = answers.filter(item => {
      return correctAnswerDec.indexOf(item) === -1
    })
    answers.forEach(num => {
      num = String(num)
      if (ansOptions[num]) {
        ansLog = ansLog + ansOptions[num]
      }
    })
    this.setState({
      selectedValues: answers
    })

    const finalTryAttempt = this.state.tryAttempt + 1
    this.popTriesResultIfExam()
    this.setState({ displayColor: true })
    const tryEl = document.getElementById('tries-' + (finalTryAttempt - 1))
    if (JSON.stringify(answers.sort()) ===
        JSON.stringify(correctAnswerDec.sort())) {
      this.state.triesResult.push(true)
      // change context data using event
      const testRes = {}
      testRes.uuid = this.props.question.Question_uuid
      testRes.answer = answers
      testRes.correct = true
      testRes.tries = this.state.triesResult
      testRes.type = ACTIVITY_TYPES[typeOfQuestion]
      this.saveResponses(testRes)
      if (this.state.result !== CORRECT) {
        window.outlierLog('Correct Answer', ansLog)
      }

      /** Change the color of tries remaining */
      if (tryEl) tryEl.className = 'radio__badge radio__badge-correct'

      // change context data
      this.setState({
        result: CORRECT,
        tryAttempt: 0,
        isNextDisabled: false,
        correctIncorrectClass: 'ans-correct',
        isCorrect: 'true',
        isDisplayNext: true,
        isDisplayCheckOrSubmit: false,
        isAnsweringDisabled: true
      })
    } else {
      if (finalTryAttempt === this.state.triesPerQuestion) {
        const isOptionUnselect = !(answers?.length)
        this.state.triesResult.push(false)
        // change context data using event
        const testRes = {}
        testRes.uuid = this.props.question.Question_uuid
        testRes.answer = answers
        testRes.correct = false
        testRes.tries = this.state.triesResult
        testRes.type = ACTIVITY_TYPES[typeOfQuestion]
        this.saveResponses(testRes)
        if (this.state.result !== INCORRECT) {
          window.outlierLog('Incorrect Answer', ansLog)
        }
        incorrectAnsIndex(indexForTextToShow)
        this.setState({
          isDisplayNext: true,
          isDisplayCheckOrSubmit: false,
          isNextDisabled: isOptionUnselect,
          result: isOptionUnselect ? '' : INCORRECT,
          correctIncorrectClass: 'incorrect ans-incorrect',
          isCorrect: 'false',
          isAnsweringDisabled: true
        })
      } else {
        this.state.triesResult.push(false)
        incorrectAnsIndex(indexForTextToShow)
        this.setState({
          result: INCORRECT,
          tryAttempt: finalTryAttempt,
          isNextDisabled: true,
          correctIncorrectClass: 'incorrect ans-incorrect',
          isCorrect: 'false',
          isAnsweringDisabled: false
        })

        /* ---------- Added for Clearing Selected Option ----------- */
        this.timeOutFuncID = setTimeout(() => {
          this.selectedCheckboxes.clear()
          this.toggleCheckbox()
          this.setState({
            result: '',
            selectedValues: '',
            correctIncorrectClass: '',
            isNextDisabled: true,
            displayColor: false,
            isAnsweringDisabled: false
          })
        }, 1000)
        /* ---------- Added for Clearing Selected Option ----------- */
      }
      /** Change the color of tries remaining */
      if (tryEl) tryEl.className = 'radio__badge radio__badge-incorrect'
    }
  }

  saveResponses = testRes => {
    const unSelectObject = {
      uuid: testRes.uuid
    }

    const isOptionUnselect = !(testRes?.answer?.length)
    const answerObject = isOptionUnselect ? unSelectObject : testRes
    emitter.emit(ON_STUDENT_ANSWER, { ...answerObject, isExam: false })
    this.setState({ studentAnswerRes: answerObject })

    this.props.updateOnCheck(testRes)
  }

  /*  For exams, users are allowed to change the answer they previously selected.
  This function ensures that triesResult always has one value in the case of exams. */
  popTriesResultIfExam () {
    const { triesResult } = this.state
    const examTriesResultIsEmpty = !triesResult ||
    triesResult.length === 0
    if (examTriesResultIsEmpty) return
    triesResult.pop()
  }

  createCheckbox (label, index) {
    const { result, isAnsweringDisabled } = this.state
    const curLoopInd = (index + 1).toString()
    let cbCorrectState = ''
    /* ---- CheckBox Partial True False Condition ---- */
    if (this.state.displayColor && this.state.displayColor === true &&
       this.state.findAns && this.state.systemAnswer.indexOf(curLoopInd) > -1 &&
       this.state.findAns.length >= this.state.systemAnswer.length) {
      cbCorrectState = 'true'
    } else if (this.state.displayColor && this.state.displayColor === true &&
               this.state.systemAnswer.indexOf(curLoopInd) === -1) {
      cbCorrectState = 'false'
    } else {
      cbCorrectState = 'null'
    }

    const {
      question: { options, Question_uuid: questionUUID },
      type_of_question: sectionType
    } = this.props
    const { isSidePanelOpen } = this.context

    return <Checkbox
      label={label}
      handleCheckboxChange={this.toggleCheckbox}
      key={index}
      value={index}
      isAnsweringDisabled={isAnsweringDisabled}
      isLastIndex={options.length - 1 === index && (result)}
      dataCypress='MCQButton'
      checked={!!(this.state.findAns && this.state.findAns.indexOf(index) > -1)}
      fAnswer={this.state.findAns}
      sysAnswer={this.state.systemAnswer}
      questionUUID={questionUUID}
      isSidePanelOpen={isSidePanelOpen}
      isCorrect={cbCorrectState}
      disabled={isAnsweringDisabled}
      correctIncorrect={'ans-correct-partial'}
      type_of_question={sectionType}
    />
  }

  createCheckboxes (items) {
    return items.map(this.createCheckbox)
  }

  // Should return false if the action was not allowed.
  handleKeyPress (type) {
    const {
      isNextDisabled,
      isDisplayNext,
      isDisplayCheckOrSubmit,
      studentAnswerRes
    } = this.state

    // Typically from pressing the left arrow (not allowed here)
    if (type && type === 'previous') return false

    // Everything else is interpreted as next or advance forward
    if (!isNextDisabled) {
      if (isDisplayNext) {
        this.props.onNextButtonClick(studentAnswerRes)
        return true
      } else if (isDisplayCheckOrSubmit) {
        this.handleFormSubmit()
        return true
      }
    }

    // Unrecognized, default is don't allow it.
    return false
  }

  render () {
    const {
      studentAnswerRes, modalContent, result,
      isAnsweringDisabled, selectedOption
    } = this.state
    const {
      question, type_of_question: typeOfQuestion,
      correctProps, incorrectProps, showfinish,
      isFirstQuestion, structuredText,
      showPrevious } = this.props
    const { isStudentProgressBusy } = this.context
    const options = this.createCheckboxes(question.options)
    const checkOrSubmit = 'Submit'
    const {
      jdoodleEditor,
      starterCode,
      Question_uuid: questionUUID
    } = question

    const studentAnswer = (
      Array.isArray(selectedOption) ? selectedOption : []
    ).map(option => question?.options[option]).join('')

    return (
      <React.Fragment>
        <StepsSolutionModal modalContent={modalContent} setModalContent={this.setModalContent} />
        <QuestionCard
          className='active__learning-block component__backdrop mb-2'
        >
          {structuredText}
          {
            this.props.lesson_text
              ? <React.Fragment>
                <div className='pb-3 font__md'>
                  <LessonText>
                    {this.props.lesson_text}
                  </LessonText>
                </div>
                <hr className='active__learning-hr' />
              </React.Fragment>
              : null
          }

          <div className='row align-items-center pt-4'>
            {this.props.display_illustration}
            {/* --- Added Desmos Here --- */}
            {this.props.desmosgraph}
            {
              this.props.question_text
                ? <div className='col que-text'>
                  <div className='font__md'>
                    <LessonText>
                      {this.props.question_text}
                    </LessonText>
                  </div>
                </div>
                : null
            }
          </div>
          {this.props.gMath}
          {jdoodleEditor &&
            <div className='mb-2'>
              <JDoodleCompiler
                key={questionUUID}
                questionUUID={questionUUID}
                starterCode={starterCode}
                isPartOfQuestion
              />
            </div>}
          <div>
            <div className='active__learning-options mx-auto row no-gutters'
              id='multiple_checkbox_auto'>
              {options}
            </div>
          </div>
          {result &&
          <ShowFeedback
            studentAnswer={studentAnswer}
            guessWorkCorrectAnswer={this.getCorrectAnswer()}
            sectionType={typeOfQuestion}
            result={result}
            correctProps={correctProps}
            incorrectProps={incorrectProps} />}
        </QuestionCard>

        <PaginationWrapper
          isAnsweringDisabled={isAnsweringDisabled}
          isFirstQuestion={isFirstQuestion}
          showfinish={showfinish}
          showPrevious={showPrevious}
        >
          {
            isStudentProgressBusy ? (
              <CourseButton
                className={{
                  'btn-primary': true,
                  'mx-1': true,
                  check_answer: true
                }}
                disabled
              >
                <i className='fa fa-spinner fa-spin fa-margin' /> <span>Saving</span>
              </CourseButton>
            ) : (
              <CourseButton
                className={{
                  'btn-primary': true,
                  'mx-1': true,
                  check_answer: true,
                  'd-block': !!this.state.isDisplayCheckOrSubmit,
                  'd-none': !this.state.isDisplayCheckOrSubmit
                }}
                onClick={this.handleFormSubmit}
                dataCypress='checkOrSubmitButton'
                disabled={this.state.isNextDisabled}
              >
                {checkOrSubmit}
              </CourseButton>
            )
          }

          {this.state.isDisplayNext && !isStudentProgressBusy && !showfinish &&
            <QuestionNextButton {...this.props}
              isNextDisabled={this.state.isNextDisabled}
              studentAnswerRes={studentAnswerRes}
            />}
        </PaginationWrapper>

      </React.Fragment>
    )
  }
}

MultipleChoiceQuestionGuesswork.contextType = Context
export default QuestionComponent(MultipleChoiceQuestionGuesswork)
