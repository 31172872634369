
import React, { Component } from 'react'
import Radiobutton from '../FormComponent/Radiobutton'
import ShowSolution from '../ShowSolution/ShowSolution'
import QuestionComponent from '../QuestionComponent/QuestionComponent'
import { emitter } from '../Emitter/Emitter'
import Context from '../Context/Context'
import QuestionNextButton from '../QuestionComponent/QuestionNextButton'
import QuestionPreviousButton from '../QuestionComponent/QuestionPreviousButton'
import ReviewNextButton from '../QuestionComponent/ReviewNextButton'
import StepsSolutionModal from '../StepsSolutionModal/StepsSolutionModal'
import CourseButton from '../CourseButton/CourseButton'
import {
  QUIZ, EXAM, GUESSWORK, RETAKE, ACTIVITY_TYPES
} from '../../Constants/sectionType'
import { ON_SECTION_DATA_PROGRESS, ON_STUDENT_ANSWER, ON_SUBMIT_ANSWER } from '../../Constants/emitterKeys'
import ReviewWorkButton from '../QuestionComponent/ReviewWorkButton'
import {
  saveAnswersToLocalStorage,
  isExamRetakeType
} from '../../utilities/examUtils'
import { findAnswer, getQuestionTitle } from '../../utilities/questions'
import { getExplanation } from '../../utilities/courseUtils'
import {
  PaginationWrapper, TextWrapper,
  PinWrapper, PinText, PinButtonWrapper, PinButton,
  QuestionIconWrapper, Icon, QuestionText, QuestionCard, LessonText
} from './styles'
import {
  NEW_GUESSWORK_ICON,
  NEW_COMPLETED_ICON,
  NEW_INCORRECT_ICON,
  PIN_ICON,
  PINNED_ICON_WHITE,
  NEW_EMPTY_ICON
} from '../../Constants/progressBarIconSource'
import { EXAM_STATUSES, PIN_TEXT, PINNED_TEXT } from '../../Constants/index'
import ShowFeedback from '../ShowFeedBack/ShowFeedBack'
import ProctorioExamResources
  from '../ProctorioExamResources/ProctorioExamResources'
import { CORRECT, INCORRECT } from '../../Constants/result'
import {
  isPracticeExam,
  trackStudentEvent
} from '../../utilities/chapterUtils'
import JDoodleCompiler from '../JDoodleCompiler/JDoodleCompiler'
import { EXAM_STATUS } from '../../Constants/studentContext'

class TrueFalseQuestionGuesswork extends Component {
  constructor (props) {
    super(props)
    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.onShowSolution = this.onShowSolution.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.CheckSolution = this.CheckSolution.bind(this)
    this.createRadiobutton = this.createRadiobutton.bind(this)
    this.createRadiobuttons = this.createRadiobuttons.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleRadioClick = this.handleRadioClick.bind(this)
    this.handleShowSolution = this.handleShowSolution.bind(this)
    this.setModalContent = this.setModalContent.bind(this)
    this.isExam = this.isExam.bind(this)
    this.isQuiz = this.isQuiz.bind(this)

    const { review_mode: reviewMode,
      type_of_question: sectionType } = props

    this.state = {
      selectedOption: '',
      try_attempt: 0,
      isNextDisabled: true,
      correctIncorrectClass: '',
      displayNext: 'none',
      isExam: this.isExam(),
      isQuiz: this.isQuiz(),
      displayCheck: 'block',
      isPinned: false,
      showSolution: false,
      solution: {},
      tries_per_question: this.getMaxTriesAllowedPerQuestion(),
      tries_result: [],
      isAnsweringDisabled: false,
      studentAnswerRes: {},
      isPendingResponseAnswer: !reviewMode && [QUIZ, EXAM].includes(sectionType),
      modalContent: ''
    }
  }

  handleOptionChange (index) {
    const { isAssessmentQuizExam } = this.context
    /* ------- Clear TIme out on Reveal Answer Click ----- */
    clearTimeout(this.timeOutFuncID)
    /* ------- Clear TIme out on Reveal Answer Click ----- */
    this.setState({
      selectedOption: index,
      isNextDisabled: false,
      correctIncorrectClass: isAssessmentQuizExam
        ? 'ans-correct-partial-assesment'
        : 'ans-correct-partial'
    }, () => {
      const { isExam, isQuiz } = this.state
      if (!(isExam || isQuiz)) return
      this.handleFormSubmit()
    })
  }

  componentDidUpdate (prevProps) {
    const { displayNext, isExam, isQuiz, isAnsweringDisabled } = this.state
    const {
      studentData: { studentAnswers }, isAssessmentQuizExam
    } = this.context
    const enableButtonsForExam = (isExam || isQuiz) &&
      (displayNext === 'none' || isAnsweringDisabled)
    if (enableButtonsForExam) {
      this.setState({
        displayNext: 'block',
        isAnsweringDisabled: (isExam || isQuiz)
          ? false
          : isAnsweringDisabled
      })
    }
    const {
      review_mode: reviewMode,
      question: { Question_uuid: questionUUID },
      type_of_question: sectionType
    } = this.props
    const {
      review_mode: previousReviewMode,
      question: { Question_uuid: previousQuestionUUID }
    } = prevProps
    if (previousQuestionUUID !== questionUUID) {
      const { studentData: { pinnedQuestions } } = this.context
      this.setState({ isPinned: pinnedQuestions.includes(questionUUID) })
    }
    const studentAnswered = findAnswer(questionUUID, studentAnswers)
    this.lastSectionQuiz = this.getLastSectionQuiz()
    this.lastSectionExam = this.getLastSectionExam()
    const isReviewModeNotMatched = reviewMode !== previousReviewMode
    const isQuestionUUIDNotMatched = previousQuestionUUID !== questionUUID

    if (isReviewModeNotMatched || (isQuestionUUIDNotMatched && reviewMode)) {
      const isPendingResponseAnswer = !reviewMode && [QUIZ, EXAM].includes(sectionType)
      setTimeout(() => {
        this.onShowSolution()
        this.setState({
          show_solution: true,
          isPendingResponseAnswer,
          correctIncorrectClass: isPendingResponseAnswer
            ? isAssessmentQuizExam
              ? 'pending-response-assessment'
              : 'pending-response' : studentAnswered &&
              studentAnswered.correct === true
              ? 'ans-correct' : 'incorrect ans-incorrect'
        })
      }, isReviewModeNotMatched ? 1000 : 500)
    }

    if (isQuestionUUIDNotMatched || (displayNext !== 'block' && studentAnswered)) {
      if (!studentAnswered?.answer) this.setState({ try_attempt: 0 })
      this.setState({ result: '' })
      this.updateStateOnStudentAnswer()
    } else if (this.lastSectionQuiz !== this.initialLastSectionQuiz ||
      this.lastSectionExam !== this.initialLastSectionExam) {
      this.initialLastSectionQuiz = this.lastSectionQuiz
      this.initialLastSectionExam = this.lastSectionExam
      window.location.reload()
    }
  }

  isExam () {
    const { type_of_question: typeOfQuestion } = this.props
    return typeOfQuestion === EXAM
  }

  isQuiz () {
    const { type_of_question: typeOfQuestion } = this.props
    return typeOfQuestion === QUIZ
  }

  componentDidMount () {
    this.updateStateOnStudentAnswer()
    this.handleShowSolution()
    const {
      type_of_question: questionType,
      question: { Question_uuid: questionUUID },
      activeChildrenIndex,
      completeScreenData,
      activeSectionUUID,
      isFirstQuestion
    } = this.props
    const { reviewMode, studentData: { studentAnswers, pinnedQuestions },
      cohortData: { name: cohort, duration } } = this.context
    if (!pinnedQuestions.length) {
      studentAnswers
        .forEach(studentAnswer => studentAnswer?.questionActivity?.pinned &&
            pinnedQuestions.push(studentAnswer.uuid))
    }
    this.setState({ isPinned: pinnedQuestions.includes(questionUUID) })
    const isQuestionTypeExam = questionType === EXAM
    const isTrackEvent = isQuestionTypeExam && isFirstQuestion && !reviewMode
    if (!isTrackEvent) return

    trackStudentEvent({
      cohort,
      duration,
      questionType,
      activeChildrenIndex,
      completeScreenData
    })
    emitter.emit(
      ON_SECTION_DATA_PROGRESS,
      {
        key: EXAM_STATUS,
        sectionUUID: activeSectionUUID,
        value: EXAM_STATUSES.IN_PROGRESS
      }
    )
  }

  setModalContent (modalContent) {
    this.setState({ modalContent })
  }

  updateStateOnStudentAnswer = () => {
    const { isPendingResponseAnswer, isExam } = this.state
    const { question: { Question_uuid: questionUUID } } = this.props
    const {
      studentData: { studentAnswers }, isAssessmentQuizExam
    } = this.context
    const studentAnswered = findAnswer(questionUUID, studentAnswers)
    this.initialLastSectionQuiz = this.getLastSectionQuiz()
    this.lastSectionQuiz = this.initialLastSectionQuiz
    this.initialLastSectionExam = this.getLastSectionExam()
    this.lastSectionExam = this.initialLastSectionExam
    let correctIncorrectClass = ''
    if (studentAnswered) {
      correctIncorrectClass = isPendingResponseAnswer
        ? isAssessmentQuizExam
          ? 'pending-response-assessment'
          : 'pending-response' : studentAnswered &&
          studentAnswered.correct === true
          ? 'ans-correct' : 'incorrect ans-incorrect'
    }
    const result = studentAnswered?.correct ? CORRECT : INCORRECT
    const isStudentAnswer = studentAnswered?.answer !== undefined
    this.setState({
      selectedOption: studentAnswered ? studentAnswered.answer : '',
      isNextDisabled: !studentAnswered,
      correctIncorrectClass,
      result: isStudentAnswer ? result : '',
      displayNext: studentAnswered ? 'block' : 'none',
      displayCheck: studentAnswered ? 'none' : 'block',
      tries_per_question: this.getMaxTriesAllowedPerQuestion(),
      tries_result: isStudentAnswer ? studentAnswered.tries : [],
      isAnsweringDisabled: isExam ? false : !!isStudentAnswer
    })
  }

  getLastSectionQuiz () {
    const { studentData: { 'quiz-section': quizSection } } = this.context
    return Object.keys(quizSection).pop()
  }

  getLastSectionExam () {
    const { studentData: { 'exam-section': examSection } } = this.context
    return Object.keys(examSection).pop()
  }

  handleShowSolution () {
    const { review_mode: reviewMode } = this.props
    if (!reviewMode) return

    setTimeout(() => {
      this.onShowSolution()
      this.setState({ show_solution: true })
    }, 500)
  }

  onShowSolution () {
    const {
      question: {
        options: answerChoices,
        question_view_lesson: questionViewLesson,
        answer: [correctAnswerIndex],
        Question_uuid: questionUUID
      },
      review_mode: reviewMode
    } = this.props

    if (!correctAnswerIndex) return

    const { selectedOption: studentSelectedOption } = this.state
    const [option1Text, option2Text] = answerChoices
    const correctAnswerValue = Number(correctAnswerIndex) === 1
      ? option1Text
      : option2Text

    let selectedAnswer = ''
    if (typeof studentSelectedOption === 'number') {
      selectedAnswer = (studentSelectedOption === 0 ? option1Text : option2Text)
    }

    const studentAnswerClassName = correctAnswerValue === selectedAnswer
      ? 'radio__badge-correct'
      : 'radio__badge-incorrect'

    this.setState({
      show_solution: true,
      solution: {
        questionUUID,
        correctAnswer: answerChoices[correctAnswerIndex - 1],
        studentAnswer: selectedAnswer,
        studentAnswerClass: studentAnswerClassName,
        view_lesson: (reviewMode && questionViewLesson !== null)
          ? questionViewLesson
          : null
      }
    })
  }

  getCorrectAnswer = () => {
    const {
      options: answerChoices,
      answer: [correctAnswerIndex]
    } = this.props.question
    if (!correctAnswerIndex) return ''
    return answerChoices[correctAnswerIndex - 1]
  }

  handleFormSubmit () {
    /* ------- Clear TIme out on Reveal Answer Click ----- */
    clearTimeout(this.timeOutFuncID)
    /* ------- Clear TIme out on Reveal Answer Click ----- */
    this.CheckSolution()
    const { setGuessworkCorrectAnswer } = this.props
    setGuessworkCorrectAnswer(this.getCorrectAnswer())
  }

  CheckSolution () {
    const {
      selectedOption: ansSelectedByStudent,
      result: stateResult,
      try_attempt: noOfTimeAttempted,
      isPendingResponseAnswer,
      tries_result: previousTriesResult,
      tries_per_question: maxTriesAllowedPerQuestion,
      isQuiz,
      isExam
    } = this.state
    const {
      completeScreenData,
      question: {
        Question_uuid: questionUUID,
        answer: correctAnswerIndex
      },
      review_mode: reviewMode,
      updateOnCheck,
      localStorageKey,
      currentQuestionSet,
      type_of_question: typeOfQuestion
    } = this.props
    const { isAssessmentQuizExam, examRetake, cohortData } = this.context
    const correctAnswer = parseInt(correctAnswerIndex[0]) - 1
    const selectedAnswerValue = ansSelectedByStudent !== ''
      ? (ansSelectedByStudent === 0 ? 'True' : 'False')
      : ''
    const isCorrectAns =
      JSON.stringify(ansSelectedByStudent) === JSON.stringify(correctAnswer)
    const className = isCorrectAns ? 'ans-correct' : 'incorrect ans-incorrect'
    const result = isCorrectAns ? CORRECT : INCORRECT
    const windowLog = () => {
      if (stateResult !== result && correctAnswerIndex[0]) {
        const text = isCorrectAns ? 'Correct Answer' : 'Incorrect Answer'
        window.outlierLog(text, selectedAnswerValue)
      }
    }
    const examTriesResultHasValue = isExam && previousTriesResult && previousTriesResult.length > 0
    if (examTriesResultHasValue) previousTriesResult.pop()

    const currentAttempt = noOfTimeAttempted + 1
    const elementOnDOM = 'tries-' + (currentAttempt - 1)
    const questionAttemptElement = document.getElementById(elementOnDOM)
    const areTriesMatched = currentAttempt === maxTriesAllowedPerQuestion

    if (!areTriesMatched && !isCorrectAns) {
      /* ---------- Added for Clearing Selected Option ----------- */

      this.timeOutFuncID = setTimeout(() => {
        if (!isExam) {
          this.setState({
            result: '',
            selectedOption: '',
            correctIncorrectClass: '',
            isNextDisabled: true,
            isAnsweringDisabled: false
          })
        }
      }, 1000)
      /* ---------- Added for Clearing Selected Option ----------- */
    } else {
      const isRetakeType = isExamRetakeType({
        examRetake,
        cohortId: cohortData?.cohortID,
        chapter: completeScreenData?.chapter
      }) && isExam
      // change context data using event
      const studentAnswerObject = {
        uuid: questionUUID,
        answer: ansSelectedByStudent,
        type: ACTIVITY_TYPES[isRetakeType ? RETAKE : typeOfQuestion],
        correct: isCorrectAns,
        tries: [...previousTriesResult, isCorrectAns]
      }

      if (!reviewMode) {
        emitter.emit(ON_STUDENT_ANSWER, { ...studentAnswerObject, isExam })
        if (isExam || isQuiz) {
          const { studentData: { studentAnswers } } = this.context
          saveAnswersToLocalStorage(localStorageKey, currentQuestionSet, studentAnswers)
        } else updateOnCheck(studentAnswerObject)
      }
      windowLog()
      // change context data
      this.setState({
        displayNext: 'block',
        displayCheck: 'none',
        studentAnswerRes: studentAnswerObject
      })
    }
    this.setState({
      result,
      try_attempt: isCorrectAns ? 0 : currentAttempt,
      isNextDisabled: isCorrectAns || isExam ? false : !areTriesMatched,
      correctIncorrectClass: isPendingResponseAnswer
        ? isAssessmentQuizExam
          ? 'pending-response-assessment'
          : 'pending-response' : className,
      isAnsweringDisabled: isExam ? false : isCorrectAns ? true : !!areTriesMatched,
      tries_result: [...previousTriesResult, isCorrectAns]
    })

    /** Change the color of tries remaining */
    if (questionAttemptElement) {
      const ansClass = isCorrectAns ? 'correct' : 'incorrect'
      questionAttemptElement.className =
          `radio__badge radio__badge-${ansClass}`
    } else {
      // change context data
      windowLog()
      this.setState({
        result,
        correctIncorrectClass: isPendingResponseAnswer
          ? isAssessmentQuizExam
            ? 'pending-response-assessment'
            : 'pending-response' : className
      })
    }
  }

  createRadiobutton (label, index) {
    const {
      question: { options, Question_uuid: questionUUID },
      type_of_question: sectionType, review_mode: reviewMode
    } = this.props
    const {
      show_solution: showSolution, result,
      selectedOption, correctIncorrectClass, isAnsweringDisabled, solution
    } = this.state
    const { isAssessmentQuizExam, isSidePanelOpen } = this.context
    const optionsLength = options.length ? options.length - 1 : 1
    const isGuesswork = sectionType === GUESSWORK
    const isDisabled = reviewMode ||
    (!isAssessmentQuizExam && isAnsweringDisabled)
    return (
      <Radiobutton
        label={label}
        isLastIndex={optionsLength === index && (showSolution || result)}
        key={index}
        reviewMode={reviewMode}
        isAssessmentQuizExam={isAssessmentQuizExam}
        isAnsweringDisabled={!isAssessmentQuizExam && isAnsweringDisabled}
        questionUUID={questionUUID}
        isSidePanelOpen={isSidePanelOpen}
        typeOfQuestion={sectionType}
        solutionData={solution}
        handleCheckboxChange={this.handleOptionChange}
        value={index}
        dataCypress='TrueFalseButton'
        isRadioChecked={selectedOption === index}
        correctIncorrect={isGuesswork ? 'ans-correct-partial'
          : correctIncorrectClass}
        disabled={isDisabled}
        selectedOption={selectedOption !== ''}
      />
    )
  }

  createRadiobuttons (items) {
    return (
      items.map(this.createRadiobutton)
    )
  }

  // Should return false if the action was not allowed.
  handleKeyPress (type) {
    // Typically from pressing the left arrow (not allowed here)
    if (type && type === 'previous') return false

    // Everything else is interpreted as next or advance forward
    const {
      isNextDisabled,
      displayNext,
      displayCheck,
      studentAnswerRes: studentAnswerResponse
    } = this.state
    const {
      review_mode: reviewMode,
      onReviewClickNext,
      onNextButtonClick
    } = this.props

    const nextCall = reviewMode
      ? onReviewClickNext
      : !isNextDisabled &&
      (displayNext === 'block'
        ? () => onNextButtonClick(studentAnswerResponse)
        : displayCheck === 'block' && this.handleFormSubmit)

    if (nextCall) nextCall()

    // ture if recognized and if Unrecognized, default is don't allow it.
    return !!nextCall
  }

  handleRadioClick (event) {
    const { target } = event
    // Blur the element so the arrow keys do not change the answer. Only click
    // should change the answer.
    target.blur()
  }

  getMaxTriesAllowedPerQuestion () {
    const {
      type_of_question: sectionType,
      question: { tries_per_question: maxTriesAllowedPerQuestion }
    } = this.props
    const TRIES_GUESSWORK = 1
    const TRIES_OTHER = 3
    const triesPerQuestion = sectionType === GUESSWORK
      ? TRIES_GUESSWORK : TRIES_OTHER

    return maxTriesAllowedPerQuestion
      ? parseInt(maxTriesAllowedPerQuestion) : triesPerQuestion
  }

  onPinClick (currentQuestionUUID) {
    const {
      completeScreenData,
      review_mode: reviewMode,
      type_of_question: typeOfQuestion
    } = this.props

    const {
      studentData,
      updateContext,
      cohortData,
      examRetake
    } = this.context
    const { pinnedQuestions, studentAnswers } = studentData
    const { isPinned, isExam } = this.state
    const currentIndex = pinnedQuestions.indexOf(currentQuestionUUID)
    const isRetakeType = isExamRetakeType({
      examRetake,
      cohortId: cohortData?.cohortID,
      chapter: completeScreenData?.chapter
    }) && isExam
    const fAnswer = findAnswer(currentQuestionUUID, studentAnswers)
    // condition to check answer exists in review mode before pinning
    const isAttemptedQuestion = typeof (fAnswer?.correct) === 'boolean'
    const answer = {
      uuid: currentQuestionUUID,
      type: isRetakeType
        ? ACTIVITY_TYPES[RETAKE]
        : typeOfQuestion.toLocaleLowerCase(),
      ...(reviewMode && isAttemptedQuestion && {
        answer: fAnswer.answer,
        correct: fAnswer.correct,
        tries: fAnswer.tries
      }),
      questionActivity: { pinned: false }
    }
    if (isPinned) {
      pinnedQuestions.splice(currentIndex, 1)
      emitter.emit(ON_SUBMIT_ANSWER, answer)
    } else {
      answer.questionActivity.pinned = true
      pinnedQuestions.push(currentQuestionUUID)
      emitter.emit(ON_SUBMIT_ANSWER, answer)
    }
    pinnedQuestions.push(currentQuestionUUID)
    studentData.pinnedQuestions = pinnedQuestions
    updateContext({ studentData })
    this.setState({ isPinned: !isPinned })
  }

  render () {
    const {
      isStudentProgressBusy,
      cohortData: { duration, name },
      isAssessmentQuizExam
    } = this.context
    const {
      question,
      type_of_question: sectionType,
      lesson_text: lessonText,
      structuredText,
      display_illustration: displayIllustration,
      desmosgraph: desmosGraph,
      gMath,
      question_text: questionText,
      review_mode: reviewMode,
      filterMessage,
      correctProps,
      incorrectProps,
      showfinish,
      isFirstQuestion,
      activeSectionUUID,
      currentQuestionUUID,
      currentQuestionSet
    } = this.props
    const {
      displayCheck,
      isNextDisabled,
      result,
      displayNext,
      isExam,
      isQuiz,
      show_solution: showSolution,
      solution,
      isPinned,
      studentAnswerRes,
      selectedOption,
      isAnsweringDisabled,
      modalContent
    } = this.state
    const isExamOrQuiz = isExam || isQuiz
    const {
      general_explanation: generalExplanation,
      specific_explanation: specificExplanation,
      examResources,
      resourceIcons,
      options
    } = question
    const radioOptions = options.length
      ? this.createRadiobuttons(options)
      : this.createRadiobuttons(['', ''])
    const isGuessworkOrQuiz = [GUESSWORK, QUIZ].includes(sectionType)
    const checkOrSubmit = isGuessworkOrQuiz ? 'Submit' : 'Check'
    const {
      Question_uuid: questionUUID,
      jdoodleEditor,
      starterCode
    } = question
    const questionIndex = currentQuestionSet?.findIndex(
      (q) => q.Question_uuid === questionUUID
    )
    const { questionUUID: solutionQuestionUUID } = solution
    const [firstQuestion] = currentQuestionSet || {}
    const { Question_uuid: firstQuestionUUID } = firstQuestion || {}
    const isLastQuestionOfOngoingExam = isExam && !reviewMode && showfinish
    const isLastQuestionAndNotAssessment = isLastQuestionOfOngoingExam && !isAssessmentQuizExam
    return (
      <>
        <StepsSolutionModal modalContent={modalContent} setModalContent={this.setModalContent} />
        {isAssessmentQuizExam && <TextWrapper>
          <QuestionIconWrapper>
            <span>
              <Icon
                src={result
                  ? (reviewMode
                    ? (result === 'Correct'
                      ? NEW_COMPLETED_ICON : NEW_INCORRECT_ICON)
                    : NEW_GUESSWORK_ICON)
                  : reviewMode ? NEW_INCORRECT_ICON : NEW_EMPTY_ICON}
                alt='Feedback' />
            </span>
            <QuestionText>
              {getQuestionTitle(question, questionIndex, sectionType)}
            </QuestionText>
          </QuestionIconWrapper>
          <PinWrapper onClick={() => this.onPinClick(currentQuestionUUID)}>
            <PinText>{isPinned ? PINNED_TEXT : PIN_TEXT}</PinText>
            <PinButtonWrapper>
              <PinButton
                src={isPinned ? PINNED_ICON_WHITE : PIN_ICON}
              />
            </PinButtonWrapper>
          </PinWrapper>
        </TextWrapper> }
        <QuestionCard
          className='active__learning-block component__backdrop mb-2'
          isAssessmentQuizExam={isAssessmentQuizExam}
        >
          {structuredText}
          { lessonText && <>
            <div className='pb-3 font__md'>{lessonText}</div>
            <hr className='active__learning-hr' />
          </>}
          <div className='row align-items-start pt-4'>
            {displayIllustration}
            {/* --- Added Desmos Here --- */}
            {desmosGraph}
            {questionText && <div className='col que-text'>
              <LessonText>{questionText}</LessonText>
            </div>}
          </div>
          {gMath}
          {jdoodleEditor &&
            <div className='mb-2'>
              <JDoodleCompiler
                key={questionUUID}
                questionUUID={questionUUID}
                starterCode={starterCode}
                isPartOfQuestion
              />
            </div>}
          <div className=' d-flex'>
            <div
              className='active__learning-options mx-auto row no-gutters'
              id='multiple_radio_auto'
              onClick={this.handleRadioClick}
            >
              {radioOptions}
            </div>
          </div>
          {isExamOrQuiz && <ProctorioExamResources
            reviewMode={reviewMode}
            examResources={examResources}
            resourceIcons={resourceIcons}
          />}
          {showSolution && questionUUID === solutionQuestionUUID &&
          <ShowSolution solutionData={solution}
            filterMessage={filterMessage} correctProps={correctProps}
            incorrectProps={incorrectProps} isExamReviewMode={reviewMode && sectionType === EXAM}
            explanation={getExplanation(specificExplanation, generalExplanation)}
            isExamOrQuizReviewMode={reviewMode && (isExam || isQuiz)}
          />}
          {!isExam && !isQuiz && !showSolution && result &&
          <ShowFeedback
            studentAnswer={question?.options[selectedOption]}
            guessWorkCorrectAnswer={this.getCorrectAnswer()}
            sectionType={sectionType}
            result={result}
            correctProps={correctProps}
            incorrectProps={incorrectProps} />}
        </QuestionCard>
        <PaginationWrapper
          isAssessmentQuizExam={isAssessmentQuizExam}
          isAnsweringDisabled={isAnsweringDisabled}
          reviewMode={reviewMode}
          showfinish={showfinish}
        >
          {isLastQuestionAndNotAssessment &&
           !isPracticeExam(activeSectionUUID) &&
           <ReviewWorkButton
             isDisabled={isNextDisabled}
             updateCurrentQuestionUUId={this.props.updateCurrentQuestionUUId}
             setFilteredQuestionInd={this.props.setFilteredQuestionInd}
             activeSectionUUID={activeSectionUUID}
             firstQuestionUUID={firstQuestionUUID} />}
          {!reviewMode && (isStudentProgressBusy && !isAssessmentQuizExam
            ? <CourseButton
              className={{
                'btn-primary': true,
                'mx-1': true,
                check_answer: true
              }}
              disabled
            >
              <i className='fa fa-spinner fa-spin fa-margin' /> <span>Saving</span>
            </CourseButton>
            : !isExam && !isQuiz &&
              <CourseButton
                style={{ display: displayCheck }}
                className={{
                  'btn-primary': true,
                  'mx-1': true,
                  check_answer: true
                }}
                dataCypress='checkOrSubmitButton'
                onClick={this.handleFormSubmit}
                disabled={isNextDisabled}
              >
                {checkOrSubmit}
              </CourseButton>
          )}

          {displayNext === 'block' && !isAssessmentQuizExam &&
          !reviewMode && !isStudentProgressBusy && !showfinish &&
          <QuestionNextButton
            {...this.props}
            isNextDisabled={isNextDisabled}
            studentAnswerRes={studentAnswerRes}
            duration={duration}
            cohort={name}
          />}
          {isAssessmentQuizExam && !isFirstQuestion &&
            <QuestionPreviousButton {...this.props}
              isAssessmentQuizExam={isAssessmentQuizExam} />}
          {!reviewMode && !showfinish &&
            isAssessmentQuizExam &&
            <QuestionNextButton {...this.props}
              studentAnswerRes={studentAnswerRes}
              isAssessmentQuizExam={isAssessmentQuizExam}
              isNextDisabled={isNextDisabled}
            />}
          {reviewMode && <ReviewNextButton {...this.props} />}
        </PaginationWrapper>

      </>
    )
  }
}

TrueFalseQuestionGuesswork.contextType = Context
export default QuestionComponent(TrueFalseQuestionGuesswork)
