import React, { useContext, useEffect, useRef, useState } from 'react'
import Context from '../Context/Context'
import CourseButton from '../CourseButton/CourseButton'
import { emitter } from '../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'
import {
  LastOverlay, Title
} from './styles/last'
import {
  DetailText,
  AutoPlayText
} from './styles/next'
import {
  getLastActiveLearningUUIDKey
} from '../../utilities/contextKeys'
import { CloseButton } from './styles'
import { getChapterOfASection } from '../../utilities/sectionUtils'
import config from '../../config'
import { getSectionIndex } from '../../utilities/problemBankUtils'
import { ORIENTATION } from '../../Constants/chapterType'

const LectureOverlay = props => {
  const [counter, setCounter] = useState(10)
  const intervalID = useRef(null)
  const {
    currentChapter: { type: chapterType },
    activeCourse,
    courseData: { chapters, instructor: instructors },
    course: { currentInstructor },
    ...rest
  } = useContext(Context)
  const { name: courseName } = activeCourse || {}
  const lastActiveLearningUUIDKey = getLastActiveLearningUUIDKey(chapterType)
  const {
    studentData: { [lastActiveLearningUUIDKey]: lastActiveLearningUUID }
  } = rest
  const {
    sectionUUID: currentSectionUUID, nextChildUUID, multipleVideos, hasActiveLearning
  } = props

  const {
    isPracticeDrawer, nextActivityType = ''
  } = multipleVideos?.[0] || {}

  useEffect(() => {
    if (hasActiveLearning) {
      intervalID.current = setInterval(
        () => setCounter(prevCounter => prevCounter - 1), 1000
      )
    }
    return () => clearInterval(intervalID.current)
  }, [hasActiveLearning])

  useEffect(() => {
    if (counter !== 0) return
    handleNext()
    return clearInterval(intervalID.current)
    // eslint-disable-next-line
  }, [counter])

  const getOverlayTitle = () => {
    const { chapterNumber, sections, type: chapterType } = getChapterOfASection(
      currentSectionUUID,
      chapters
    )
    const currentSection = sections?.find(
      ({ section_uuid: sectionUUID }) => sectionUUID === currentSectionUUID
    )
    const activeSectionNumber = getSectionIndex(sections, currentSection) + 1
    const sectionNumber = `${chapterNumber}.${activeSectionNumber}`
    if (chapterType === ORIENTATION) {
      return `${courseName} | ${ORIENTATION} | Active Learning`
    }
    if (config.course?.hasActiveLearningTheme) {
      const { theme_name: currentActiveLearningTheme } = instructors?.find(
        instructor => instructor.instructor_uuid === currentInstructor
      ) || {}
      const themeText = currentActiveLearningTheme ? `- ${currentActiveLearningTheme} Theme` : ''
      return `${courseName} | ${sectionNumber} | Active Learning ${themeText}`
    }
    if (isPracticeDrawer) {
      return `${courseName} | ${sectionNumber} | ${nextActivityType}`
    }
    return `${courseName} | ${sectionNumber} | Active Learning`
  }

  const handleNext = () => {
    const lastSectionALCard = lastActiveLearningUUID[currentSectionUUID]
    let childURL = nextChildUUID
    // redirect to the last card when there is a last card and
    // when there is no or one theme(which is of type string not object).
    if (
      lastSectionALCard && typeof lastSectionALCard === 'string'
    ) childURL = lastSectionALCard
    const url = `/${currentSectionUUID}/${childURL}`
    emitter.emit(ON_NAVIGATE_TO, url)
  }

  const handleBack = () => {
    const { sectionUUID } = props
    const url = `/${sectionUUID}`
    emitter.emit(ON_NAVIGATE_TO, url)
  }

  const getSectionHomeButton = (mr = 0) => (
    <CourseButton
      onClick={handleBack}
      className='btn btn-secondary completed-btn'
      style={{ width: '170px', marginRight: `${mr}px` }}
    >
      Section Home
    </CourseButton>
  )

  const { onClose } = props
  return (
    <LastOverlay>
      {onClose && <CloseButton onClick={onClose} />}
      {!hasActiveLearning && <Title>Lecture Series Completed</Title>}
      {hasActiveLearning && (
        <>
          <DetailText>UP NEXT</DetailText>
          <Title>{getOverlayTitle()}</Title>
          <AutoPlayText>Redirecting in {counter} second</AutoPlayText>
          <CourseButton
            onClick={handleNext}
            className='btn btn-primary completed-btn'
            style={{ width: '170px' }}
          >
            Continue
          </CourseButton>
        </>
      )}
      {!hasActiveLearning && getSectionHomeButton()}
    </LastOverlay>
  )
}

export default LectureOverlay
