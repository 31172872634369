import styled, { createGlobalStyle } from 'styled-components'
import mediaqueries from '../../../mediaQueries'
import { Button as BootstrapButton } from 'react-bootstrap'

export const GlobalStyle = createGlobalStyle`
  .modal-backdrop.show {
    opacity: 0.5;
  }

  .modal-dialog {
    max-width: none;
  }
  
  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-body {
    padding: 24px;
    & > p {
      margin-bottom: 24px;
    }
  }

  .modal-content {
    background-color: #25272B;
    width: 600px;
    display: flex;
    align-items: flex-start;
    margin: auto;
    padding: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    ${mediaqueries.tablet`
      width: 525px;
    `}
  
    ${mediaqueries.mobile`
      width: 324px;
    `}
  
    ${mediaqueries.small`
      width: 250px;
    `}
  }
`

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 24px;

  & > p {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    flex: 1;
    margin-bottom: 0;
  }

  & > img {
    cursor: pointer;
    width: 14px;
    height: 14px;
  }
`

export const Separator = styled.hr`
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  border: 0 none;
  margin: 0;
`

export const OptionsLink = styled.a`
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #5FC4B8 !important;

  & img {
    margin-top: -3px;
    margin-left: 13px;
  }
`

export const ButtonsContainer = styled.div`
  margin-top: 24px;
  display: flex;

  ${mediaqueries.tablet`
    flex-direction: column;

    & > button:first-child {
      margin-bottom: 10px;
    }
  `}
`

const buttonStyle = `
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-right: 16px;
`

export const PrimaryButton = styled(BootstrapButton)`
  ${buttonStyle}
  background-color: #5FC4B8;
  color: #161618;
  border: none;
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || '40px'};
  padding: ${({ height }) => height === 'auto' ? '16px 24px' : '0 24px'};

  ${mediaqueries.tablet`
    width: 100%;
  `}

  &:active {
    background-color: #5FC4B8 !important;
    color: #161618 !important;
    border: 0 none !important;
  }

  &:focus {
    outline: 0;
    background-color: #5FC4B8;
    color: #161618;
    border: none;
    box-shadow: unset;
  }

  &:hover {
    background-color: #5FC4B8;
    color: #161618;
    border: none;
  }

  &:disabled {
    color: #212529;
    background-color: #78BCB8;
    border-color: #78BCB8;
    pointer-events: none;
  }
`

export const SecondaryButton = styled(BootstrapButton)`
  ${buttonStyle}
  background-color: #1D1D1F;
  color: #77B1AF;
  border: 3px solid #77B1AF;
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || '40px'};

  ${mediaqueries.tablet`
    width: 100%;
  `}

  &:active {
    background-color: #1D1D1F !important;
    color: #77B1AF !important;
    border: 3px solid #77B1AF !important;
  }

  &:focus {
    outline: 0;
    background-color: #1D1D1F;
    color: #77B1AF;
    border: 3px solid #77B1AF;
    box-shadow: unset;
  }

  &:hover {
    color: #77B1AF;
    background-color: #1D1D1F;
    border: 3px solid #77B1AF;
  }

  &:disabled {
    background-color: #161618;
    border-color: #77b1af;
    color: #77b1af;
    pointer-events: none;
  }
`
