import React from 'react'
import { DatoLinkContainer } from './styled'

const DatoLink = ({
  link,
  isPracticeExerciseRedesign
}) => {
  return (
    <>
      <DatoLinkContainer
        isPracticeExerciseRedesign={isPracticeExerciseRedesign}
      >
        <span>You can edit this card in Dato.</span>
        <a id='dato-link' href={link} target='_blank' rel='noopener noreferrer'>
          open dato
        </a>
      </DatoLinkContainer>
    </>
  )
}

export default DatoLink
