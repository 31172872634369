export const gguUsersnapKeys = {
  'collegealgebra.plus': '2375eecf-e9f9-4ed1-8dcc-da494672e847',
  'calculus.plus': 'b8b374f9-aefb-46f9-a916-a2a134da0578',
  'collegewriting-i.plus': 'a9a56d36-a81f-49c4-b875-c2b13ce47026',
  'collegewriting-ii.plus': '902505f9-b245-4929-8e39-d072b44b3177',
  'professionalcommunication.plus': 'd18e3a75-1d09-423f-ad6d-21f6177c4f2e',
  'computerscience-i.plus': '28388791-126b-4ceb-b830-f9ffb221f98b',
  'astronomy.plus': '668ac68f-cfaa-413b-97aa-c42bfef63fd0',
  'business.plus': 'e63d914e-4bd3-44f8-a3e8-342330c5b163',
  'financialaccounting.plus': 'd06ba1aa-60e1-4a4b-bcf7-da4379ea3a27',
  'macroeconomics.plus': '84f69c69-0461-4bb2-ae69-31e70a31b2ea',
  'microeconomics.plus': '2cc4cbb8-1ac1-4311-b065-4cf78b28936a',
  'philosophy.plus': '11d744a5-7e19-408e-a00d-82fa48944a8a',
  'psychology.plus': '8db17ecf-d29c-4c5c-96b1-08a559034aab',
  'sociology.plus': '5cd4d6a6-15dd-4f6f-ac4b-86c1fd4d0113',
  'statistics.plus': '4879ca4b-b146-4a51-9196-22dd53c37dbb',
  'precalculus.plus': '932620aa-fe44-4ba5-b152-edd107106688',
  'principlesofeconomics.plus': 'd5e7079c-e731-4c5c-bbf2-e560db5a6822'
}
