import React, { memo, useState, useEffect } from 'react'
import qs from 'qs'
import api from '../../api'
import LoadingSpinner from '../LoadingSpinner/LoadingAnimation'
import { OffsetContainer, JDoodleContainer, GlobalStyle } from './styles'
import config from '../../config'
import { render } from 'datocms-structured-text-to-plain-text'
import {
  JDOODLE_COMPILER_SCRIPT,
  JDOODLE_COMPILER_KEY,
  DEFAULT_THEME,
  THEMES
} from '../../Constants/jdoodle'
import { formatCode } from '../../utilities/jdoodleUtils'

function JDoodleStandAloneCompiler () {
  const [isAssetLoading, setIsAssetLoading] = useState(true)
  const [fetchedCode, setFechedCode] = useState()

  const getAssetSrcFromUrl = () => {
    const { assetSrc } = qs.parse(window.location.search.replace('?', ''))
    return assetSrc
  }

  useEffect(() => {
    const assetSrc = getAssetSrcFromUrl()
    if (!assetSrc) { return setIsAssetLoading(false) }

    (async () => {
      const resource = await api.fetchAsset(encodeURIComponent(assetSrc))
      setFechedCode(resource)
      setIsAssetLoading(false)
    })()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isAssetLoading) return

    const existingScript = document.querySelector('#jdoodleCompiler')
    if (existingScript) existingScript.parentNode.removeChild(existingScript)

    const jDoodleScript = document.createElement('script')
    jDoodleScript.src = JDOODLE_COMPILER_SCRIPT
    jDoodleScript.type = JDOODLE_COMPILER_KEY
    jDoodleScript.id = 'jdoodleCompiler'
    document.body.append(jDoodleScript)

    // eslint-disable-next-line
  }, [isAssetLoading])

  const theme = config.jDoodleTheme
  const isValidTheme = THEMES.includes(theme) && theme

  if (isAssetLoading) return <LoadingSpinner />

  return (
    <OffsetContainer isStandAloneCompiler id='offset-container'>
      <GlobalStyle />
      <JDoodleContainer
        id='jdoodle-compiler-container'
        data-theme={isValidTheme || DEFAULT_THEME}
        data-pym-src='https://outlier.jdoodle.com/embed/simple'
        data-language='java'
        data-version-index='4'
        data-libs='mavenlib1, mavenlib2'
      >
        {render(formatCode(fetchedCode))}
      </JDoodleContainer>
    </OffsetContainer>
  )
}

JDoodleStandAloneCompiler.displayName = 'JDoodleStandAloneCompiler'

export default memo(JDoodleStandAloneCompiler)
