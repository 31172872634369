import React, { useState, useEffect, useRef, useCallback, useContext } from 'react'
import { UploadingInput, FileUploaderContainer,
  FileUploadLabel, FilePreview, Tooltip, AssignmentFileName
} from './styles'
import DownloadLink from '../AssignmentPage/DownloadLink'
import Context from '../Context/Context'

const syncStates = {
  NOT_SAVED: 'NOT_SAVED',
  SAVING: 'SAVING',
  SAVED: 'SAVED'
}

export default ({
  onFileSelect,
  assignmentTitle,
  allowSpreadsheetUpload,
  isDisabled = false,
  setActiveInput,
  uploadedContent,
  onUnsupportedFormat,
  onEmpty,
  hasStarterFiles,
  isCodingAssignment,
  isVideoLoading,
  isResubmitPage,
  setTempFile,
  multiPartUpload,
  isMultiPartUploading,
  file: { name, type } = {},
  index,
  uploadProgress }) => {
  const context = useContext(Context)
  const [file, setFile] = useState(undefined)
  const syncState = context.fileUploadAssignmentState
  const [showLink, setShowLink] = useState(false)
  const fileUploadRef = useRef(null)
  const fileUploadContianerRef = useRef(null)

  const setSyncState = state => {
    context.updateContext({ fileUploadAssignmentState: state })
  }

  useEffect(() => {
    if (!uploadedContent) return
    setFile(uploadedContent)
    setShowLink(true)
    setActiveInput('FILEUPLOAD')
    // eslint-disable-next-line
  }, [uploadedContent])

  const preventDefault = useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()
  }, [])

  const drop = useCallback((e) => {
    preventDefault(e)

    const dt = e.dataTransfer
    const file = dt.files[0]

    handleFileUpload(file)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isDisabled) return

    const fileUploadContainerNode = fileUploadContianerRef.current

    fileUploadContainerNode.addEventListener('dragenter', preventDefault, false)
    fileUploadContainerNode.addEventListener('dragover', preventDefault, false)
    fileUploadContainerNode.addEventListener('drop', drop, false)

    return () => {
      fileUploadContainerNode.removeEventListener('dragenter', preventDefault)
      fileUploadContainerNode.removeEventListener('dragover', preventDefault)
      fileUploadContainerNode.removeEventListener('drop', drop)
    }
    // eslint-disable-next-line
  }, [preventDefault, drop, isDisabled])

  const handleFileUpload = async (fileObj) => {
    if (isMultiPartUploading) return
    const fileExtention = fileObj?.name?.match(/\.[0-9a-z]+$/i)?.[0]

    const allowedExtentions = type?.length
      ? type
      : isCodingAssignment || hasStarterFiles
        ? ['.java', '.zip']
        : ['.doc', '.docx', '.rtf', '.txt', '.pdf', '.odt'].concat(
          allowSpreadsheetUpload ? ['.xls', '.xlsx'] : []
        )

    if (fileObj.size > 1000000000) {
      alert('Maximum file size: 1GB')
      return
    }

    if (fileObj.size > 20000000 && type?.[0] !== '.mp4') {
      alert('Maximum file size: 20MB')
      return
    }

    if (!allowedExtentions.includes(fileExtention)) {
      onUnsupportedFormat(fileExtention)
      return
    }

    setFile(fileObj)
    setShowLink(false)
    setTempFile(preVal => ({
      ...preVal,
      [index]: fileObj
    }))
    if (!fileObj || isResubmitPage || multiPartUpload) return
    setSyncState(syncStates.SAVING)
    const fileUploaded = await onFileSelect(fileObj)
    if (!fileUploaded) return removeFile()

    setSyncState(syncStates.SAVED)
  }

  const removeFile = () => {
    setFile(undefined)
    onEmpty()
    setSyncState(syncStates.NOT_SAVED)
  }

  const getAllowedFileTypes = () => {
    if (type?.length) return type[0]
    return isCodingAssignment || hasStarterFiles
      ? '.java, .zip'
      : '.doc, .docx, .rtf, .txt, .pdf, .odt' +
      (allowSpreadsheetUpload ? ', .xls, .xlsx' : '')
  }

  const getUploadProgress = () => {
    if (!isMultiPartUploading) return null
    if (!uploadProgress) return 'Waiting....'
    if (uploadProgress === '100%') return 'Uploaded'
    return `Uploading.... ${uploadProgress}`
  }

  return (
    <>
      <FileUploadLabel isMultiPartUploading={isMultiPartUploading}>
        {index === 0 &&
        <div>
          File Upload
          <Tooltip allowSpreadsheetUpload={allowSpreadsheetUpload}>
            <img
              src='images/icons/icon-file-upload.svg'
              width='16'
              height='16'
              alt='file-upload'
            />
            <span>
              <p>{multiPartUpload ? 'Max size for videos: 1GB' : 'Maximum file size: 20MB'}</p>
              <p>{multiPartUpload && 'Max size for texts: 20MB'}</p>
              {!multiPartUpload && <p>Acceptable file types: {getAllowedFileTypes()}</p>}
            </span>
          </Tooltip>
        </div>}
        {isDisabled && (<p>*To submit a file upload, remove text from text box below.</p>)}
        {!isDisabled && (
          <p data-testid='save-text'>
            <span>{getUploadProgress()}</span>
            <span>{syncState === syncStates.SAVED && 'Draft Saved'}</span>
            <span>{syncState === syncStates.SAVING && 'Saving....'}</span>
          </p>
        )}
      </FileUploadLabel>
      <FileUploaderContainer ref={fileUploadContianerRef} hasFile={file} isDisabled={isDisabled}>
        {file
          ? <FilePreview>
            <p data-testid='file-preview-text'>
              <img
                src={showLink
                  ? 'images/icons/icon-upload-file-name-link.svg#file_name'
                  : 'images/icons/icon-upload-file-name.svg#file_name'}
                width='15'
                height='15'
                fill='#1F6059'
                alt='upload-file-name'
              />
              {showLink ? <DownloadLink
                fileName={file.name || assignmentTitle.split(' ').join('')}
                label={`${(isResubmitPage && multiPartUpload)
                  ? 'View Uploaded File'
                  : (file.name || 'View Uploaded File')}`}
                link={file.url} /> : file.name}
            </p>
            {!isMultiPartUploading &&
              <p id='remove' onClick={removeFile}>
                <img
                  src='images/icons/icon-file-upload-remove.svg'
                  width='15'
                  height='15'
                  alt='file-upload-remove'
                />
                <span>Remove</span>
              </p>
            }
          </FilePreview>
          : isVideoLoading &&
            <p data-testid='upload-text'>
              Loading uploaded file...
            </p>
        }
        {!isMultiPartUploading &&
          <p data-testid='upload-text'>
            {name &&
              <AssignmentFileName>
                {name}
              </AssignmentFileName>
            }
            <span
              onClick={() => {
                fileUploadRef.current.click()
              }}
            >
              {file ? 'Replace file' : 'Upload a file'}
            </span> or drag and drop here.</p>
        }
        <UploadingInput type='file'
          multiple={false}
          value={''}
          data-testid='upload-input'
          accept={getAllowedFileTypes()}
          onChange={evt => {
            setActiveInput('FILEUPLOAD')
            handleFileUpload(evt.target.files[0])
          }}
          ref={fileUploadRef}
          disabled={isDisabled}
        />
        <div id='overlay' />
      </FileUploaderContainer>
    </>
  )
}
