import styled from 'styled-components'

export const Paragraph = styled.p`
  max-height: ${props => (props.paragraphHeight)};
  overflow: hidden;
  background-image: ${props => props.collapse &&
   'linear-gradient(to top,#25232363,#FFFFFF);'};
  background-clip: ${(props) => props.collapse && 'text'};
  -webkit-background-clip: ${(props) => props.collapse && 'text'};
  -webkit-text-fill-color: ${(props) => props.collapse && 'transparent'};
  transition: ${props => (props.collapse ? 'all 0.1s' : 'all 1s')};
  `

export const ReadMoreWrapper = styled.span`
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  position: relative;
`

export const Caret = styled.span`
  width: 9px;
  height: 9px;
  border: 1px solid #FFFFFF;
  border-top-width: 0;
  border-right-width: 0;
  margin-bottom: ${props => props.collapse && '3px'};
  margin-right: 12.5px;
  transform: ${props => (props.collapse ? 'rotate(-45deg)'
    : 'rotate(135deg)')};
`
