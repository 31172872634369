import React, { Component } from 'react'
import Context from '../Context/Context'
import util, { capitalizeFirstLetter } from '../../utilities'
import { emitter } from '../Emitter/Emitter'
import {
  ACTIVE_LEARNING,
  PRACTICE_EXERCISES,
  PROBLEM_BANK,
  QUIZ,
  GUESSWORK,
  LECTURE,
  DISCUSSION,
  PRACTICE_TERMS
} from '../../Constants/sectionType'
import SECTION_TOOLTIP, { STUDIO_TOOLTIP } from '../../Constants/sectionTooltip'
import SectionHomepagePracticeButton from
  '../SectionHomepagePracticeButton/SectionHomepagePracticeButton'
import SectionHomepageGuessworkButton from
  '../SectionHomepageGuessworkButton/SectionHomepageGuessworkButton'
import SectionHomepageLectureButton from
  '../SectionHomepageLectureButton/SectionHomepageLectureButton'
import SectionHomepageActiveLearningButton from
  '../SectionHomepageActiveLearningButton/SectionHomepageActiveLearningButton'
import {
  PRACTICE_EXERCISES_COMPLETE,
  QUIZ_COMPLETE,
  GUESSWORK_COMPLETE,
  PRACTICE_TERM_COMPLETE,
  QUIZ_SECTION
} from '../../Constants/studentContext'
import groupBy from 'lodash/groupBy'
import config from '../../config'

import {
  ON_NAVIGATE_TO
} from '../../Constants/emitterKeys'

import './css/SectionListExcerciseItem.css'
import {
  Dot,
  SectionProgressText,
  SectionListExerciseItemStyle,
  SectionListQuizScoreStyle,
  SectionFirstLineWrapper,
  Link,
  ExternalLinkIcon,
  ToggleIcon
} from './styled'
import PracticeSetSectionItem from './PracticeSetSectionItem'
import LockedMarker from '../LockedMarker/LockedMarker'
import QuizSectionItem from './QuizSectionItem'
import ActiveLearningSectionItem from './ActiveLearningSectionItem'
import CustomTooltip from '../Tooltip'
import { TooltipIcon } from '../Tooltip/styled'
import {
  getActiveLearningCompleteKey,
  getLectureCompleteKey,
  getStudentAnswersKey
} from '../../utilities/contextKeys'
import { getActiveLearningLockText } from '../../utilities/sectionUtils'
import { shouldHideActiveLearningStatus } from '../../utilities/chapterUtils'
import { getPlural } from '../../utilities/textUtils'
import { getYellowdigUrl } from '../../utilities/courseUtils'

class SectionListExcerciseItem extends Component {
  constructor (props) {
    super(props)
    this.getButtonClass = this.getButtonClass.bind(this)
    this.getSectionCompleteKey = this.getSectionCompleteKey.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.showTooltip = this.showTooltip.bind(this)
    this.hideTooltip = this.hideTooltip.bind(this)
    this.getFieldTooltip = this.getFieldTooltip.bind(this)
    this.displayQuizkPercentage = this.displayQuizkPercentage.bind(this)
    this.initializeExerciseData = this.initializeExerciseData.bind(this)
    this.sectionRef = React.createRef()
    this.state = {
      showTooltip: false,
      exercise: false,
      questionSets: [],
      questionSetLock: false,
      isAccordionOpen: true
    }
  };

  static getDerivedStateFromProps () {
    return null
  }

  componentDidMount () {
    const { lockReason, sectionUUID, excercise } = this.props
    this.scrollToSection()
    this.initializeExerciseData()
    const { get } = config.sectionItemAccordionState(excercise?.type || excercise[0]?.type, sectionUUID)
    const storedValue = get()

    this.setState({
      ...(storedValue && { isAccordionOpen: storedValue === 'true' }),
      questionSetLock: !!lockReason
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      excercise: { question_set_uuid: questionSetUuid },
      lockReason,
      sectionUUID
    } = this.props
    const {
      excercise: { question_set_uuid: prevQuestionSetUuid },
      lockReason: prevLockReason, excercise
    } = prevProps

    if (questionSetUuid !== prevQuestionSetUuid ||
        lockReason !== prevLockReason) {
      this.setState({ questionSetLock: !!lockReason })
    }

    const {
      exercise,
      questionSets
    } = this.state
    if (exercise?.type === PRACTICE_EXERCISES && !questionSets.length) {
      this.setState({ questionSets: this.getPracticeQuestionSets(exercise) })
    }

    const { get } = config.sectionItemAccordionState(excercise?.type || excercise[0]?.type, sectionUUID)
    const storedValue = get()

    const isCompleted = this.isExerciseComplete()
    if (!storedValue && isCompleted && prevState?.isAccordionOpen) {
      return this.setState({ isAccordionOpen: false })
    }
  }

  scrollToSection = () => {
    const hasScrollHash = window?.location?.hash?.includes('?quiz')
    setTimeout(() => {
      if (hasScrollHash) {
        this?.sectionRef?.current &&
          this.sectionRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
      }
    }, 1000)
  }

  isExerciseComplete () {
    const { studentData } = this.context
    const { sectionUUID, excercise } = this.props

    let type = excercise.type &&
      capitalizeFirstLetter(excercise.type)

    if (excercise[0]) {
      if (excercise[0].type === 'Practice Terms') {
      } else if (excercise[0].type === QUIZ || excercise[0].type === PRACTICE_EXERCISES) {
        type = excercise[0].type &&
          capitalizeFirstLetter(excercise[0].type)
      }
    }

    const completeKey = this.getSectionCompleteKey(type)
    return completeKey && studentData[completeKey][sectionUUID]
  }

  showTooltip () {
    const { sectionUUID } = this.props
    const { studentData } = this.context
    if (studentData[PRACTICE_EXERCISES_COMPLETE][sectionUUID]) return
    this.setState({ showTooltip: true })
  }

  hideTooltip () {
    this.setState({ showTooltip: false })
  }

  displayQuizkPercentage (quizPer, find) {
    const {
      isCohortEndedForStudent
    } = this.context
    if (quizPer) {
      if (!(find in quizPer) || isCohortEndedForStudent) return
      return <SectionListQuizScoreStyle
        className='text-primary mr-2'
        data-cy='quizSectionScore'
      >
        {'SCORE: ' + Math.round(Number(quizPer[find])) + '%'}
      </SectionListQuizScoreStyle>
    } else {
      return <span className='mr-2' />
    }
  }

  getSectionCompleteKey (sectionType) {
    if (!sectionType) return
    const { currentChapter: { type } } = this.context
    const sectionKeys = {
      [LECTURE]: getLectureCompleteKey(type),
      [ACTIVE_LEARNING]: getActiveLearningCompleteKey(type),
      [PRACTICE_EXERCISES]: PRACTICE_EXERCISES_COMPLETE,
      [PRACTICE_TERMS]: PRACTICE_TERM_COMPLETE,
      [QUIZ]: QUIZ_COMPLETE,
      [GUESSWORK]: GUESSWORK_COMPLETE
    }
    return sectionKeys[sectionType]
  }

  getButtonClass () {
    const {
      isCohortEndedForStudent
    } = this.context
    const { exercise } = this.state
    let buttonClass = ''
    if (isCohortEndedForStudent || !exercise) return buttonClass
    const { sectionUUID } = this.props
    const { studentData } = this.context
    let type = exercise.type &&
      capitalizeFirstLetter(exercise.type)

    if (exercise[0]) {
      if (exercise[0].type === 'Practice Terms') {
        buttonClass = 'secondary'
      } else if (exercise[0].type === QUIZ) {
        type = exercise[0].type &&
          capitalizeFirstLetter(exercise[0].type)
      }
    }
    const sectionCompleteKey =
      this.getSectionCompleteKey(type)
    if (sectionCompleteKey &&
        studentData[sectionCompleteKey][sectionUUID]) {
      buttonClass = 'secondary'
    }
    return buttonClass
  }

  handleClick () {
    const { questionSetLock, exercise } = this.state
    const { sectionUUID } = this.props
    if (questionSetLock) return
    emitter.emit(ON_NAVIGATE_TO, '/' + sectionUUID +
        '/' + exercise.question_set_uuid)
    window.outlierLog('Section start',
      capitalizeFirstLetter(exercise.type))
  }

  getStudioTooltip () {
    const { exercise } = this.state
    const type = exercise[0]?.type || exercise.type
    return STUDIO_TOOLTIP[type] || this.getFieldTooltip()
  }

  getFieldTooltip () {
    const { exercise } = this.state
    let fieldTooltip = ''
    if ((exercise[0] && exercise[0].type === QUIZ) ||
      (exercise.type && exercise.type === QUIZ)) {
      fieldTooltip = exercise[0].field_tooltip || SECTION_TOOLTIP[QUIZ]
    } else if (exercise) {
      fieldTooltip = exercise.field_tooltip || SECTION_TOOLTIP[exercise.type]
    } else if ((exercise && exercise.type === PRACTICE_EXERCISES)) {
      fieldTooltip = exercise[0].field_tooltip || SECTION_TOOLTIP[PRACTICE_EXERCISES]
    }
    return fieldTooltip
  }

  initializeExerciseData () {
    const { excercise } = this.props
    if (!excercise) return
    const exercise = Array.isArray(excercise)
      ? [...excercise] : { ...excercise }

    if ((excercise[0] && excercise[0].type === QUIZ) ||
      (excercise.type && excercise.type === QUIZ)) {
      exercise.type = QUIZ
      exercise.question_set_uuid = 'quizzes'
    }

    if (excercise[0] && (excercise[0].type === PRACTICE_EXERCISES ||
      excercise[0].type === PROBLEM_BANK)) {
      exercise.type = PRACTICE_EXERCISES
      exercise.question_set_uuid = 'practice_exercises'
    }

    this.setState({
      exercise
    })
  }

  handleToggle = () => {
    const { isAccordionOpen } = this.state
    const { sectionUUID, excercise } = this.props
    const { set } = config.sectionItemAccordionState(excercise?.type || excercise[0]?.type, sectionUUID)
    set(!isAccordionOpen)
    this.setState({ isAccordionOpen: !isAccordionOpen })
  }

  isPracticeSetCompleted = (questionUUID) => {
    const { studentAnswers } = this.context.studentData
    const studentAnswer = studentAnswers.find(answer =>
      answer.uuid === questionUUID && answer.isPracticeCompleted)
    return !!studentAnswer
  }

  getPracticeQuestionSets (exercise) {
    const { Question: question } = exercise

    const questionSets = Object.values(question ||
      (({ type, question_set_uuid: questionSetUUID, ...o }) => o)(exercise))

    for (let i = 0; i < questionSets.length; i++) {
      const {
        question_set_uuid: problemBankQuestionUUID,
        Question_uuid: questionUUID
      } = questionSets[i]
      questionSets[i].isCompleted =
        this.isPracticeSetCompleted(problemBankQuestionUUID || questionUUID)
    }

    return questionSets
  }

  handleDiscussionClick = async (e) => {
    e.preventDefault()
    const cohortData = this.context.cohortData
    const launchUrl = await getYellowdigUrl(cohortData)
    if (!launchUrl) return
    window.open(launchUrl, '_blank', 'noopener,noreferrer')
  }

  render () {
    const {
      exercise,
      questionSetLock,
      showTooltip
    } = this.state
    const {
      lockReason,
      sectionUUID,
      isLastItem,
      completeScreenData
    } = this.props
    const {
      isStudioCohort,
      studentData,
      currentChapter: { type: chapterType }
    } = this.context
    const studentAnswersKey = getStudentAnswersKey(chapterType)
    const buttonClass = this.getButtonClass()
    const fieldTooltip = isStudioCohort ? this.getStudioTooltip() : this.getFieldTooltip()
    const tooltip = lockReason || ''

    if (!exercise || (exercise && !exercise.type)) return null
    const { Question: question, type } = exercise
    const isGuesswork = type === GUESSWORK
    const isLecture = capitalizeFirstLetter(type) === LECTURE
    const isQuiz = type === QUIZ
    const isActiveLearning = type === ACTIVE_LEARNING
    const isDiscussion = type === DISCUSSION
    const isPracticeItem = type === PRACTICE_TERMS
    const isPracticeSets = type === PRACTICE_EXERCISES

    if (isLecture) {
      const { lecturevideos: lectureVideos } = exercise
      if (!lectureVideos || !lectureVideos.length) return null
    }

    const isExerciseCompleted = buttonClass === 'secondary'

    const renderLeftPanelFirstLine = () => {
      const capitalizedType = capitalizeFirstLetter(type)
      const title = isQuiz ? 'Quizzes' : isDiscussion ? 'Discussion Board' : capitalizedType

      return (<>
        <h3
          className='panel-title d-inline-block section-title'
        >
          {title}
        </h3>
        {fieldTooltip &&
          <CustomTooltip
            id='section-list-item-tooltip'
            data-testid='section-list-item'
            text={fieldTooltip}
          >
            <TooltipIcon>?</TooltipIcon>
          </CustomTooltip>
        }
      </>)
    }

    const getGuessWorkQuestions = () => (
      isExerciseCompleted ? 'Completed' : `${question.length} ${getPlural('question', question.length)}`)
    const getPracticeExcerciesQuestions = () => {
      const { isActiveLearningOpened } = this.props
      const { questionSets } = this.state
      const remaining = questionSets.filter(q => !q.isCompleted).length

      if (!isActiveLearningOpened) return getActiveLearningLockText()

      switch (remaining) {
        case questionSets.length:
          return `${questionSets.length} ${getPlural('set', questionSets.length)}`
        case 0:
          return 'Completed'
        default: return `${remaining} ${getPlural('set', remaining)} remaining`
      }
    }

    const getQuizCountAndScore = () => {
      const { isStudioCohort } = this.context
      const quizSets = exercise.filter(e => e.type)
      const quizCount = quizSets.length
      const completedQuizSections = Object.keys(studentData[QUIZ_SECTION])

      // Fetch the completed quiz count
      const completedQuiz = quizSets
        .filter(q => completedQuizSections.includes(q.question_set_uuid))

      const completedQuizCount = completedQuiz.length

      const attemptLeft = quizCount - completedQuizCount
      const attemptText = `${attemptLeft} ${getPlural('attempt', attemptLeft)} remaining`

      let quizMaxScore = -1
      if (completedQuizCount > 0) {
        for (let i = 0; i < completedQuizCount; i++) {
          // Fetch the score of completed quiz
          const tempScore = studentData[QUIZ_SECTION][completedQuiz[i]['question_set_uuid']]
          // Compare and update the max score
          if (tempScore > quizMaxScore) quizMaxScore = tempScore
        }
      }

      const scoreText = (!isStudioCohort && quizMaxScore > -1)
        ? `Score: ${quizMaxScore}%`
        : ''

      return (
        <>
          {`${scoreText} `}
          {scoreText && <Dot />}
          {` ${attemptText}`}
        </>
      )
    }

    const getActiveLearningStatus = (props) => {
      const { studentData: { studentAnswers }, currentChapter: { type } } = this.context
      const hideActiveLearningStatus = shouldHideActiveLearningStatus({ type })

      if (hideActiveLearningStatus) return ''

      const { excercise = {} } = this.props
      const studentAnswersList = studentAnswers.map(studentAnswer => studentAnswer.uuid)
      const questionList = excercise.Question ?? []
      const questionsByTheme = groupBy(questionList, answers => answers.instructor?.['theme_name'] || '')
      const unAnsweredQuestionList =
      questionList
        .filter(question => !studentAnswersList.includes(question.Question_uuid))
      const unAnsweredQuestions =
      groupBy(unAnsweredQuestionList, unAnsweredQuestion => unAnsweredQuestion
        .instructor?.['theme_name'] || '')
      if (config.isCalculusCourse()) {
        const totalQuestionsByTheme = Object.keys(questionsByTheme).length
        const totalUnAnsweredQuestionsByTheme = Object.keys(unAnsweredQuestions).length
        return totalUnAnsweredQuestionsByTheme < totalQuestionsByTheme
          ? 'Completed' : '1 Theme Required'
      }

      const totalCards = questionList.length
      const remainingCards = unAnsweredQuestionList.length

      if (remainingCards === 0) return 'Completed'
      if (remainingCards === totalCards) return `${totalCards} ${getPlural('card', totalCards)}`
      return `${remainingCards} ${getPlural('card', remainingCards)} remaining`
    }

    const getPracticeTermStatus = () => {
      const sectionCompleteKey = this.getSectionCompleteKey(type)
      if (sectionCompleteKey && studentData[sectionCompleteKey][sectionUUID]) {
        return 'Completed'
      }
      return 'Unlimited practice'
    }

    const getLeftPanelSecondLineText = () => {
      const { isActiveLearningOpened } = this.props
      switch (type) {
        case GUESSWORK: return getGuessWorkQuestions()
        case PRACTICE_TERMS: return !isActiveLearningOpened
          ? getActiveLearningLockText()
          : getPracticeTermStatus()
        case PRACTICE_EXERCISES: return getPracticeExcerciesQuestions()
        case QUIZ: return !isActiveLearningOpened
          ? getActiveLearningLockText()
          : getQuizCountAndScore()
        case ACTIVE_LEARNING: return getActiveLearningStatus()
        default: return ''
      }
    }

    const renderLeftPanelSecondLine = () => (
      <SectionProgressText data-cy='LeftPanelSecondLine'>
        {getLeftPanelSecondLineText()}
      </SectionProgressText>
    )

    const { isAccordionOpen, questionSets } = this.state

    return (
      <>
        <SectionListExerciseItemStyle
          isLastItem={isLastItem}
          className={`section-panel-active d-flex
          justify-content-between align-items-center`}
          data-cy='sectionListExerciseItem'
          onClick={this.handleToggle}
          onKeyPress={(e) => util.handleKeyPress(e, this.handleToggle)}
          tabIndex='0'
          ref={isQuiz && this.sectionRef}
        >
          <div className='main-panels--left'>
            <SectionFirstLineWrapper>
              {renderLeftPanelFirstLine()}
            </SectionFirstLineWrapper>
            {renderLeftPanelSecondLine()}
          </div>

          <div className='main-panels--right'>
            <span
              style={{ display: showTooltip
                ? 'inline-block' : 'none' }}
              className='tooltiip'>{tooltip}
            </span>
            {isPracticeSets &&
              <div className='main-panels--right d-flex align-items-center'>
                {questionSetLock && <LockedMarker />}
                <ToggleIcon
                  isAccordionOpen={isAccordionOpen}
                  src={'images/icons/icon-angle-arrow-light.svg'}
                  isLocked={questionSetLock}
                  data-testid='toggleButton'
                  data-cy='toggleButton'
                />
              </div>
            }
            {isPracticeItem &&
            <SectionHomepagePracticeButton
              isCompleted={buttonClass === 'secondary'}
              isUnlocked={!questionSetLock}
              exercise={exercise}
              sectionUUID={sectionUUID}
            />}
            {isGuesswork &&
            <SectionHomepageGuessworkButton
              isCompleted={buttonClass === 'secondary'}
              exercise={exercise}
              sectionUUID={sectionUUID}
            />}
            {isLecture &&
            <SectionHomepageLectureButton
              isCompleted={buttonClass === 'secondary'}
              isUnlocked={!questionSetLock}
              exercise={exercise}
              sectionUUID={sectionUUID}
            />}
            {isQuiz &&
              <div className='main-panels--right d-flex align-items-center'>
                {questionSetLock && <LockedMarker />}
                <ToggleIcon
                  isAccordionOpen={isAccordionOpen}
                  src={'images/icons/Caret.svg'}
                  onClick={this.handleToggle}
                  isLocked={questionSetLock}
                  data-cy='toggleButton'
                />
              </div>
            }
            {isActiveLearning && (!config.isCalculusCourse()
              ? <SectionHomepageActiveLearningButton
                isCompleted={buttonClass === 'secondary'}
                isUnlocked={!questionSetLock}
                exercise={exercise}
                completeScreenData={completeScreenData}
                sectionUUID={sectionUUID}
              /> : <div className='main-panels--right d-flex align-items-center'>
                {questionSetLock && <LockedMarker />}
                <ToggleIcon
                  isAccordionOpen={isAccordionOpen}
                  src={'images/icons/Caret.svg'}
                  onClick={this.handleToggle}
                  isLocked={questionSetLock}
                  data-cy='toggleButton'
                />
              </div>)}
            {isDiscussion && (
              <Link
                href='#'
                data-testid='discussionLink'
                onClick={this.handleDiscussionClick}
              >
                join discussion
                <ExternalLinkIcon />
              </Link>
            )}
          </div>
        </SectionListExerciseItemStyle>
        {isPracticeSets &&
          <PracticeSetSectionItem
            exercice={questionSets}
            isAccordionOpen={isAccordionOpen}
            sectionUUID={sectionUUID}
            questionSetLock={questionSetLock} />}
        {isQuiz &&
          <QuizSectionItem
            {...this.props}
            activeSectionUUID={sectionUUID}
            excerciseList={exercise.filter(e => e.type)}
            isAccordionOpen={isAccordionOpen}
          />}
        {isActiveLearning && config.isCalculusCourse() &&
          <ActiveLearningSectionItem
            {...this.props}
            activeSectionUUID={sectionUUID}
            isAccordionOpen={isAccordionOpen}
            questionSetLock={questionSetLock}
            answerUUIDs={studentData[studentAnswersKey].map(st => st.uuid)}
            incorrectAnswerUUIDs={studentData[studentAnswersKey]
              .filter(st => !st.correct)
              .map(st => st.uuid)}
          />
        }
      </>
    )
  }
}

SectionListExcerciseItem.contextType = Context
export default SectionListExcerciseItem
