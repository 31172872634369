import styled from 'styled-components'

export const TextEditorContainer = styled.div`
  width: 100% !important;
  position: relative;
  margin-top: 24px;

  #text-editor-overlay {
    position: absolute;
    width: 100%;
    height: 225px;
    top: 29px;
    right: 0;
    border-radius: 5px;
    bottom: 0;
    background: rgb(22 22 22 / 70%);
    z-index: 100;
    ${props => !props.isDisabled && `
    display: none;
  `}}
`
export const TextEditorLabel = styled.div`
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: right;
  }
`
export const SyncState = styled(TextEditorLabel)`
  position: absolute;
  top: 0;
  z-index: 100;
  color: white;
  right: 11px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
`

export const WordCount = styled(TextEditorLabel)`
  position: absolute;
  color: white;
  right: 0px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  margin-top: 4px;
`

export const Editor = styled.textarea`
  width: 100%;
  height: 225px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.3);
  border-color:rgba(255, 255, 255, 0.3) ;
  color: white;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 1px;
  text-align: left;
  padding: 30px 5px 14px 21px;
  &:focus {
    outline: none;
  }
`
