import React from 'react'
import {
  GradingScaleFigure,
  GradingScaleItemWrapper,
  GradingScaleSymbol
} from './styled'

const GradingScaleItem = ({ scale }) => {
  const { symbol, range } = scale

  return (
    <GradingScaleItemWrapper data-cy='gradeScaleItem'>
      <GradingScaleSymbol>{symbol}</GradingScaleSymbol>
      <GradingScaleFigure>{range}%</GradingScaleFigure>
    </GradingScaleItemWrapper>
  )
}

export default GradingScaleItem
