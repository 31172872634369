import React, { useState } from 'react'
import { invert } from 'lodash'
import Select from 'react-select'
import { SERVERS } from '../../Constants/serverSwitch'
import { NavBarItemSelect } from './style'
import { selectInputStyles } from './styled'

function ServerSwitch () {
  const { Production } = SERVERS
  const currentServer = localStorage.getItem('apiHost') || Production
  const [server, setServer] = useState({
    label: invert(SERVERS)[currentServer] || 'Production',
    value: currentServer
  })

  const switchServer = (selectedServer) => {
    setServer(selectedServer)
    localStorage.setItem('apiHost', selectedServer.value)
    window.location.reload()
  }

  return (
    <NavBarItemSelect>
      <Select
        data-testid='select-input'
        options={Object.keys(SERVERS).map(server => ({ label: server, value: SERVERS[server] }))}
        value={server}
        onChange={selectedServer => switchServer(selectedServer)}
        styles={selectInputStyles}
      />
    </NavBarItemSelect>
  )
}

export default ServerSwitch
