export const GRADING_SCALE = {
  main: [
    {
      symbol: 'A',
      range: '100-92.5'
    },
    {
      symbol: 'A-',
      range: '92.49-89.5'
    },
    {
      symbol: 'B+',
      range: '89.49-86.5'
    },
    {
      symbol: 'B',
      range: '86.49-82.5'
    },
    {
      symbol: 'B-',
      range: '82.49-79.5'
    },
    {
      symbol: 'C+',
      range: '79.49-76.5'
    },
    {
      symbol: 'C',
      range: '76.49-72.5'
    },
    {
      symbol: 'C-',
      range: '72.49-69.5'
    },
    {
      symbol: 'D',
      range: '69.49-59.5'
    },
    {
      symbol: 'F',
      range: '59.49-0'
    }
  ],
  // FP means florida poly
  FP: [
    {
      symbol: 'A',
      range: '100-90'
    },
    {
      symbol: 'A-',
      range: '89.99-87'
    },
    {
      symbol: 'B+',
      range: '86.99-84'
    },
    {
      symbol: 'B',
      range: '83.99-80'
    },
    {
      symbol: 'B-',
      range: '79.99-77'
    },
    {
      symbol: 'C+',
      range: '76.99-74'
    },
    {
      symbol: 'C',
      range: '73.99-70'
    },
    {
      symbol: 'C-',
      range: '69.99-67'
    },
    {
      symbol: 'D+',
      range: '66.99-64'
    },
    {
      symbol: 'D',
      range: '63.99-60'
    },
    {
      symbol: 'D-',
      range: '59.99-57'
    },
    {
      symbol: 'F',
      range: '56.99-0'
    }
  ]
}
