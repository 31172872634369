import styled from 'styled-components'
import mediaqueries, { BREAKPOINTS } from '../../../mediaQueries'

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .btn {
    width: 150px;
    margin: 6px 8px;
    height: 40px;
    padding: 0px 8px;
  }

  .btn-secondary:disabled {
    background-color: #161618;
    border-color: #77b1af;
    color: #77b1af;
  }

  ${({ isAssessmentQuizExam, showfinish, reviewMode, isALRedesign }) =>
    (isAssessmentQuizExam || isALRedesign) && `
     margin-top: ${isALRedesign ? '42px' : '43px'};
     justify-content: flex-start;

    .btn-secondary:disabled {
      background-color: #161618;
      border-color: #77b1af;
      color: #5FC4B8;
    }

    ${isALRedesign && `
      .btn {
        padding: 0px 6px !important;
      }
      .btn-secondary {
        margin-left: 0px;
      }
    `}

    .btn-primary {
      background-color: #5FC4B8;
    }

    .btn {
      width: ${(!reviewMode && showfinish) ? '150px' : '64px'};
      border: #5FC4B8 1px solid;
      height: 48px !important;
      width: auto !important;
      border-sizing: border-box;
      padding: 16px 24px !important;
      line-height: 14px;
    }

    .btn-primary.check_answer{
      line-height: 16px;
      padding: 16px 32px !important;
    }
  `}

  ${mediaqueries.mobile`

    ${({ isALRedesign }) => !isALRedesign && `
      .btn {
        width: 100%;
      }
    `}

    ${({ isAssessmentQuizExam, reviewMode, isFirstQuestion, showfinish }) =>
    isAssessmentQuizExam && `
        .btn {
          width: ${reviewMode ? '157px' : '100%'};

          :first-child {
            margin-left: 0px !important;
            margin-right: ${reviewMode ? '12px !important' : '0px'};
          }

          :nth-child(2) {
            margin-left: 0px !important;
            margin-right: 0px !important;
          }
        }
      `}
  `}

  @media (max-width: ${BREAKPOINTS.mobile - 1}px) {

    .btn {
        width: 100%;
      }

    ${({ isALRedesign }) => isALRedesign && `
      .btn {
        flex-grow: 1 !important;
        max-width: 157px !important;

        :first-child {
          margin-left: 0px !important;
          margin-right: 12px !important;
        }

        :nth-child(2) {
          margin-left: 0px !important;
          margin-right: 0px !important;
        }
      }
    `}
  }
`

export const TextWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 48px;
  display: flex;
  height: 19px;
  justify-content: space-between;
  ${({ isALRedesign }) => isALRedesign && `
    align-items: center;
    height: 70px;
    padding: 0px 24px;
    margin-top: 0px;
    margin-bottom: 1px;
    border-radius: 5px 5px 0px 0px;
    background: #121212;
  `}
`

export const Icon = styled.img`
  width: inherit;
  height: inherit;
  vertical-align: middle;
  border-style: none;
  margin-bottom: 18px;
`
export const QuestionIconWrapper = styled.div`
display: inline-flex;
`

export const QuestionText = styled.span`
  line-height: 19.2px;
  font-size: 16px;
  margin-left: ${({ isALRedesign }) => !isALRedesign ? '12px' : '0px'};
`

export const PinWrapper = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    ${({ isALRedesign }) => isALRedesign && 'align-items: center;'};
`
export const PinText = styled.span`
    max-width: 93px;
    font-size: 16px;
    line-height: 19.2px;
    color: #98A4AE;
    text-align: right;
`
export const PinButtonWrapper = styled.span`
  ${({ isAssessmentQuizExam }) => isAssessmentQuizExam && `
    width: 36px;
    height: 36px;
    margin-top: -6px;
  `}

  ${({ isALRedesign }) => isALRedesign && `
    margin-top: -2px;
  `}
`
export const PinButton = styled.img`
    margin-left: 12px;
    margin-bottom: 2px;
    width: 14px;
    height: 14px;
`

export const QuestionCard = styled.div`
  ${props => props.isAssessmentQuizExam && 'padding: 5px !important;'}
  ${props => props.isAssessmentQuizExam && props.reviewMode && 'background-color: rgba(0,0,0,0)'};
  ${({ isALRedesign }) => isALRedesign && `
    background-color: #121212;
    padding: 24px !important;
    border-radius: 0px 0px 5px 5px !important;
  `}
`

export const LessonText = styled.div`
  ${props => props.isAssessmentQuizExam && `
    table:nth-of-type(1) {
      width: 490px !important;
    }
  `}
  @media (max-width: ${BREAKPOINTS.desktop - 1}px) {
    ${props => props.isAssessmentQuizExam && props.isSidePanelOpen && `
      table:nth-of-type(1) {
        width: 0px !important;
      }
    `}
  }
  @media (max-width: ${BREAKPOINTS.mobile - 1}px) {
    ${props => props.isAssessmentQuizExam && `
      table:nth-of-type(1) {
        width: 0px !important;
      }
    `}
  }
  ${({ isALRedesign }) => isALRedesign && `
    padding: 0px !important;
    .paragraph{
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 24px !important;
    }
  `}
`

export const MathEditor = styled.div``

export const QuestionTextAL = styled.div`
  ${({ isALRedesign, isSidePanelOpen }) => isALRedesign && `
    padding-top: 0px !important;

    .paragraph{
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 24px !important;
    }

    .mb-5{
      margin-bottom: 0px !important;

      p {
        margin-bottom: 24px !important;
      }

      @media (max-width: ${BREAKPOINTS.desktop - 1}px) {
        ${isSidePanelOpen && `
          p {
            padding: 4px !important;
          }
        `}
      }
    }
  `}
`
export const AdaptationWrapper = styled.div`
  ${({ isALRedesign }) => isALRedesign && `
    text-align: left !important;
    .paragraph {
      margin-top: 42px !important;
      line-height: 17px !important;
    }
  `}
`
