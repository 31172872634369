export const MIDTERM_1_KEY = 'midterm1key'
export const MIDTERM_2_KEY = 'midterm2key'
export const EXAM_3_KEY = 'exam3key'
export const EXAM_4_KEY = 'exam4key'
export const EXAM_5_KEY = 'exam5key'
export const FINAL_EXAM_KEY = 'finalexamkey'

export const MIDTERM_1_KEY_OVERRIDE = 'midterm1KeyOverride'
export const MIDTERM_2_KEY_OVERRIDE = 'midterm2KeyOverride'
export const EXAM_3_KEY_OVERRIDE = 'exam3KeyOverride'
export const EXAM_4_KEY_OVERRIDE = 'exam4KeyOverride'
export const EXAM_5_KEY_OVERRIDE = 'exam5KeyOverride'
export const FINAL_EXAM_KEY_OVERRIDE = 'finalExamKeyOverride'

export const EXAM_KEY_FIELDS = [
  MIDTERM_1_KEY,
  MIDTERM_2_KEY,
  EXAM_3_KEY,
  EXAM_4_KEY,
  EXAM_5_KEY,
  FINAL_EXAM_KEY
]

export const EXAM_OVERRIDE_FIELDS = [
  MIDTERM_1_KEY_OVERRIDE,
  MIDTERM_2_KEY_OVERRIDE,
  EXAM_3_KEY_OVERRIDE,
  EXAM_4_KEY_OVERRIDE,
  EXAM_5_KEY_OVERRIDE,
  FINAL_EXAM_KEY_OVERRIDE
]
