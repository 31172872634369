import { getLatestCohort } from './courseUtils'
import { formatHoursToTime } from './dateTimeUtils'

export function getSessionsArray (sessionsObject) {
  if (!sessionsObject || typeof sessionsObject !== 'object') return []
  const sessionsArray = Object.entries(sessionsObject)
    .map(entry => ({ id: entry?.[0], ...entry?.[1] }))
    .sort((a, b) => new Date(a?.start) - new Date(b?.start))
  if (!sessionsArray?.[0]?.start || !sessionsArray?.[0]?.end) return []

  const currentTime = new Date().getTime()

  sessionsArray.forEach(session => {
    const sessionAttendance = session?.isAttended !== undefined
      ? session?.isAttended
      : currentTime > session?.end ? false : undefined
    session.isAttended = sessionAttendance
  })

  return sessionsArray
}

export const getActiveSession = (sessionsArray = []) => {
  const currentDate = new Date()
  const activeSession = sessionsArray?.find(session => {
    const sessionStart = new Date(session?.start)
    const sessionEnd = new Date(session?.end)
    return sessionStart <= currentDate && currentDate < sessionEnd
  })

  return activeSession
}

export const getUpcomingSession = (sessionsArray = [], initDate) => {
  const date = initDate || new Date()
  const upcomingSession = sessionsArray?.find(session => {
    const sessionStart = new Date(session?.start)
    return sessionStart > date
  })

  return upcomingSession
}

export const getUpcomingActiveSession = (sessionsArray = [], date) => {
  const currentDate = date || new Date()
  const upcomingActiveSession = sessionsArray?.find(session => {
    const sessionStart = new Date(session?.start)
    const sessionEnd = new Date(session?.end)
    return currentDate < sessionStart || currentDate < sessionEnd
  })

  return upcomingActiveSession
}

export function getRecentPastSessions (sessionsArray = [], noOfSessions, date) {
  const currentDate = date || new Date()
  const pastSessions = sessionsArray?.filter(session => {
    const sessionEnd = new Date(session?.end)
    return currentDate > sessionEnd
  })

  const recentPastSessions = pastSessions?.slice(-noOfSessions)
  return recentPastSessions
}

export function checkIfBlocksMissed (studyBlocks = [], metadata = {}) {
  const studyBlocksLatestUpdate = parseInt(metadata?.updatedAt || metadata?.createdAt)
  const isBlocksMissed = studyBlocks?.every(block => {
    const isAfterLatestUpdate = block?.end > studyBlocksLatestUpdate
    return !block?.isAttended && isAfterLatestUpdate
  })

  return isBlocksMissed
}

export function getBlockTime (block = {}) {
  const blockKeys = Object.keys(block)
  if (!blockKeys?.includes('start') || !blockKeys?.includes('end')) return ''

  const startTime = formatHoursToTime(block?.start).toLocaleLowerCase()
  const endTime = formatHoursToTime(block?.end).toLocaleLowerCase()

  const startTimeSuffix = startTime.split(' ')[1]
  const endTimeSuffix = endTime.split(' ')[1]

  if (startTimeSuffix === endTimeSuffix) {
    return `${startTime?.split(' ')[0]} - ${endTime}`
  }

  return `${startTime} - ${endTime}`
}

export function getLatestEnrolledCourse (courses = [], latestSBUpdate) {
  const latestEnrolledCourse = courses?.find(course => {
    const latestCohort = getLatestCohort(course)
    const isAfterLatestUpdate =
      new Date(latestCohort?.dateStart) > new Date(parseInt(latestSBUpdate))
    return isAfterLatestUpdate
  })

  return latestEnrolledCourse
}

export function getLatestScheduledCourse (courses = [], latestSBUpdate) {
  const latestScheduledCourses = courses?.filter(course => {
    const latestCohort = getLatestCohort(course)
    const isBeforeLatestUpdate =
      new Date(latestCohort?.dateStart) < new Date(parseInt(latestSBUpdate))
    return isBeforeLatestUpdate
  })

  const orderedCourses = latestScheduledCourses?.sort((a, b) => {
    const cohortAEndTime = getLatestCohort(a)?.cohortEndTime
    const cohortBEndTime = getLatestCohort(b)?.cohortEndTime
    return new Date(cohortBEndTime) - new Date(cohortAEndTime)
  })

  return orderedCourses?.[0]
}

export function getRecommendedHours (courses = []) {
  const recommendedHours = courses?.reduce((acc, course) => {
    const latestCohort = getLatestCohort(course)
    const courseDuration = latestCohort?.duration
    if (courseDuration >= 14) {
      return acc + 10
    } else if (courseDuration < 14) {
      return acc + 20
    }
    return acc
  }, 0)

  return recommendedHours
}
