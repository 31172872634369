import React, { useContext } from 'react'
import Context from '../Context/Context'

import config from '../../config'

import {
  CrossIcon,
  Icon,
  Link,
  RightSection,
  StatusText,
  Wrapper
} from './styled'

const MobileAppConnectionStatus = props => {
  const {
    // This will override wrapper style
    containerStyles = {}
  } = props

  const context = useContext(Context)

  const {
    lastMobileLogin,
    showMobilePromotion,
    updateContext
  } = context

  const renderMobileIcon = () => (
    <Icon
      src='/images/icons/phone.svg'
    />
  )

  const renderCrossIcon = () => (
    <CrossIcon
      onClick={() => updateContext({ showMobilePromotion: false })}
      src='/images/icons/modal-close.svg'
    />
  )

  const appLink = 'https://www.outlier.org/'

  const renderPromotionText = () => {
    return (
      <>
        {`Try the math entry feature on the `}
        <Link
          target='_blank'
          href={appLink}
        >
          Outlier App.
        </Link>
      </>
    )
  }

  // This component is guarded by a feature flag
  const isFeatureFlagSet = config.isMobileFlag
  if (!isFeatureFlagSet) return null

  const isMobilePromotionVisible = showMobilePromotion && !lastMobileLogin
  if (!isMobilePromotionVisible) return null

  return (
    <Wrapper
      data-testid='mobileAppConnectionStatus'
      style={containerStyles}
    >
      {renderMobileIcon()}
      <StatusText>
        {renderPromotionText()}
      </StatusText>
      <RightSection>
        {renderCrossIcon()}
      </RightSection>
    </Wrapper>
  )
}

MobileAppConnectionStatus.displayName = 'MobileAppConnectionStatus'
export default MobileAppConnectionStatus
