import React from 'react'
import { ModalContainer } from './styles/Modal'

export default function Modal ({ children }) {
  return (
    <ModalContainer>
      {children}
    </ModalContainer>
  )
}
