import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { OffsetContainer, JDoodleContainer } from './styles'
import config from '../../config'
import { render } from 'datocms-structured-text-to-plain-text'
import {
  JDOODLE_COMPILER_SCRIPT,
  JDOODLE_COMPILER_KEY,
  DEFAULT_THEME,
  THEMES
} from '../../Constants/jdoodle'
import { isGGUCourse } from '../../utilities/courseUtils'

function JdoodleQuizExam ({
  questionUUID,
  starterCode
}) {
  useEffect(() => {
    const existingScript = document.querySelector('#jdoodleCompiler')
    if (existingScript) existingScript.parentNode.removeChild(existingScript)

    const jDoodleScript = document.createElement('script')
    jDoodleScript.src = JDOODLE_COMPILER_SCRIPT
    jDoodleScript.type = JDOODLE_COMPILER_KEY
    jDoodleScript.id = 'jdoodleCompiler'
    document.body.append(jDoodleScript)
    // eslint-disable-next-line
  }, [questionUUID])

  const theme = config.jDoodleTheme
  const isValidTheme = THEMES.includes(theme) && theme

  return (
    <OffsetContainer>
      <JDoodleContainer
        id='jdoodle-compiler-container'
        data-theme={isValidTheme || DEFAULT_THEME}
        data-code-editor-only={!isGGUCourse()}
        data-pym-src='https://outlier.jdoodle.com/embed/simple'
        data-language='java'
        data-version-index='4'
        data-libs='mavenlib1, mavenlib2'
      >
        {render(starterCode)}
      </JDoodleContainer>
    </OffsetContainer>
  )
}

JdoodleQuizExam.propTypes = {
  questionUUID: PropTypes.string,
  starterCode: PropTypes.object
}

JdoodleQuizExam.displayName = 'JdoodleQuizExam'

export default memo(JdoodleQuizExam)
