import debounce from 'lodash/debounce'
import api from '../api'
import { emitter } from '../Components/Emitter/Emitter'
import {
  RESULT_FETCH_INTERVAL,
  RESULT_FETCH_STOP_STATES
} from '../Constants/codegrade'
import { ON_STUDENT_ANSWER } from '../Constants/emitterKeys'
import { CODING_QUESTION } from '../Constants/questionType'

const cache = {}
const retries = {
  submitUserCode: { retryCount: 0, maxRetries: 1 },
  getAutotestDetails: { retryCount: 0, maxRetries: 1 },
  getSubmissionResult: { retryCount: 0, maxRetries: 1 }
}
const CODEGRADE_ACCESS_TOKEN = 'codegradeAcessToken'

export const refreshAccessToken = async () => {
  const response = await api.loginToCodegrade()
  cache[CODEGRADE_ACCESS_TOKEN] = response?.['access_token'] || null
}

export const getCodegradeToken = async () => {
  const cachedToken = cache[CODEGRADE_ACCESS_TOKEN]
  if (cachedToken) return cachedToken

  await refreshAccessToken()
  return cache[CODEGRADE_ACCESS_TOKEN]
}

export const getFilename = code => {
  const stringAfterClass = code?.match(/public class (.*)/)?.[1] || ''
  return stringAfterClass.split('{')?.[0].trim() || 'jdoodle'
}

export const handleResponse = async (response, originalFunction, params) => {
  if (!retries[originalFunction.name]) {
    retries[originalFunction.name] = { retryCount: 0, maxRetries: 1 }
  }
  const currentRetry = retries[originalFunction.name]

  if (response?.response?.data?.error !== 'You need to be logged in to do this.') {
    return response
  }

  if (currentRetry.retryCount >= currentRetry.maxRetries) {
    retries[originalFunction.name].retryCount = 0
    return response
  }

  await refreshAccessToken()

  retries[originalFunction.name].retryCount = ++currentRetry.retryCount

  const newResponse = await originalFunction(params)
  return newResponse
}

export const submitUserCode = async params => {
  const { assignmentId, code } = params || {}
  const filename = getFilename(code)
  const formData = new FormData()
  formData.append(
    'assignment',
    new Blob([code], { type: 'text/x-java-source' }),
    `${filename}.java`
  )
  const response = await api.submitUserCode(
    assignmentId, formData, cache[CODEGRADE_ACCESS_TOKEN]
  )
  const recheckResponse = await handleResponse(response, submitUserCode, params)
  return recheckResponse
}

export const submitCodingAssignment = async params => {
  const { assignmentId, file } = params || {}
  const formData = new FormData()
  formData.append('assignment', file)

  const response = await api.submitUserCode(
    assignmentId, formData, cache[CODEGRADE_ACCESS_TOKEN]
  )

  const recheckResponse = await handleResponse(response, submitCodingAssignment, params)
  return recheckResponse
}

export const getAutotestDetails = async params => {
  const response = await api.getAutotestDetails(
    params?.assignmentId, cache[CODEGRADE_ACCESS_TOKEN]
  )
  const recheckResponse = await handleResponse(response, getAutotestDetails, params)
  return recheckResponse
}

export const getSubmissionResult = async params => {
  const response = await api.getSubmissionResult(
    params, cache[CODEGRADE_ACCESS_TOKEN]
  )
  const recheckResponse = await handleResponse(response, getSubmissionResult, params)
  const isAutotestComplete = RESULT_FETCH_STOP_STATES.includes(recheckResponse?.state)
  const isError = !!recheckResponse?.code
  if (isAutotestComplete || isError) return recheckResponse

  const newResult = await getSubmissionResult(params)
  return newResult
}

export const debouncedSubmissionResult = debounce(
  async (params, resolve, reject) => {
    const response = await api.getSubmissionResult(
      params, cache[CODEGRADE_ACCESS_TOKEN]
    )

    const recheckResponse = await handleResponse(response, getPracticeResult, params)
    const isAutotestComplete = RESULT_FETCH_STOP_STATES.includes(recheckResponse?.state)
    const isError = !!recheckResponse?.code

    if (isError) {
      reject(response)
      return false
    }

    if (isAutotestComplete) {
      resolve(recheckResponse)
      return false
    }

    const newResult = await getPracticeResult(params)
    resolve(newResult)
    return false
  }, RESULT_FETCH_INTERVAL
)

export const getPracticeResult = async params => {
  return new Promise((resolve, reject) => {
    debouncedSubmissionResult(params, resolve, reject)
  })
}

export const getCompilationRubricId = response => {
  if (!response) return

  const compilationRubric = (response.rubric_result?.rubrics || [])
    .find(rubric => rubric.header === 'Compile')
  return compilationRubric?.id
}

export const getCompilationStepId = (autotestDetails, rubricId) => {
  if (!autotestDetails) return

  const compilationSet = (autotestDetails.sets || [])
    .find(set => set?.suites?.[0]?.['rubric_row']?.id === rubricId)
  if (!compilationSet) return

  return compilationSet.suites?.[0]?.steps?.[0]?.id
}

export const getTotalScore = stepResults => {
  if (!stepResults?.length) return

  const totalWeight = stepResults.reduce((acc, step) => {
    return acc + step?.['auto_test_step']?.weight || 0
  }, 0)
  return totalWeight
}

export const getScoreDetails = (
  submissionResponse,
  autotestDetails,
  result
) => {
  if (!submissionResponse || !autotestDetails || !result) return {}

  const compilationRubricId = getCompilationRubricId(submissionResponse)
  const compilationStepId = getCompilationStepId(autotestDetails, compilationRubricId)

  const { points_achieved: totalScore, step_results: stepResults } = result
  const totalWeight = getTotalScore(stepResults)
  const questionResult = typeof totalScore === 'undefined' || typeof totalWeight === 'undefined'
    ? false : totalScore === totalWeight

  const compilationResult = (stepResults || []).find(step => {
    return step?.['auto_test_step']?.id === compilationStepId
  })
  if (!compilationResult) return { totalScore, questionResult }

  return {
    totalScore,
    totalWeight,
    compilationScore: compilationResult['achieved_points'] || 0,
    questionResult
  }
}

export const getSubmissionsAndAutotests = async answers => {
  if (!answers?.length) return []

  const submissions = await Promise.all(answers
    .map(async studentAnswer => {
      const { answer: code, questionType, codegradeAssignmentId } = studentAnswer
      if (questionType !== CODING_QUESTION || !code) return studentAnswer

      const [submissionResponse, autotestResponse] = await Promise.all([
        submitUserCode({ assignmentId: codegradeAssignmentId, code }),
        getAutotestDetails({ assignmentId: codegradeAssignmentId })
      ])

      const { id: submissionId } = submissionResponse || {}
      const { id: autotestId, runs } = autotestResponse || {}
      const { id: runId } = runs?.[0] || {}

      return {
        ...studentAnswer,
        submissionResponse,
        autotestResponse,
        submissionId,
        autotestId,
        runId
      }
    }))

  return submissions || []
}

export const getSubmissionGrades = async (submissions) => {
  if (!submissions?.length) return []

  const results = await Promise.all(
    submissions.map(async submission => {
      const {
        uuid,
        type,
        answer,
        questionType,
        submissionId,
        autotestId,
        runId,
        submissionResponse,
        autotestResponse
      } = submission
      if (questionType !== CODING_QUESTION) {
        emitter.emit(ON_STUDENT_ANSWER, submission)
        return submission
      }

      if (!submissionId || !autotestId || !runId) {
        const studentAnswer = {
          uuid,
          type: type?.toLowerCase(),
          answer,
          correct: false,
          meta: {
            submissionId,
            autotestId,
            runId,
            totalScore: undefined,
            totalWeight: undefined,
            compilationScore: undefined
          },
          tries: [false]
        }

        emitter.emit(ON_STUDENT_ANSWER, studentAnswer)

        return studentAnswer
      }

      const submissionResult = await getSubmissionResult({
        submissionId,
        autotestId,
        runId
      })
      const scoreDetails = getScoreDetails(
        submissionResponse, autotestResponse, submissionResult
      )
      const {
        questionResult, totalScore, totalWeight, compilationScore
      } = scoreDetails || {}

      const meta = {
        submissionId,
        autotestId,
        runId,
        totalScore,
        totalWeight,
        compilationScore
      }

      const studentAnswer = {
        uuid,
        type: type?.toLowerCase(),
        answer,
        correct: questionResult,
        meta,
        tries: [questionResult]
      }

      emitter.emit(ON_STUDENT_ANSWER, studentAnswer)

      return studentAnswer
    })
  )

  return results || {}
}

export const getCodingQuestionsResults = async (answers) => {
  if (!answers?.length) return []

  const submissions = await getSubmissionsAndAutotests(answers || [])
  const results = await getSubmissionGrades(submissions || [])
  return results || []
}

export const getSuiteSteps = ({ submissionSteps, autotestSteps }) => {
  return autotestSteps?.reduce((steps, step) => {
    // do not include step if weight is 0
    if (!step?.weight) return steps

    /* find autotest step's corresponding step from codegrade result api
    response; it contains the points achieved in each step */
    const submissionStep = submissionSteps.find(
      submissionStep => submissionStep?.['auto_test_step']?.id === step?.id
    )
    if (!submissionStep) return steps

    const {
      achieved_points: achievedPoints,
      auto_test_step: autotestStep
    } = submissionStep
    const { name, weight } = autotestStep || {}

    steps.push({ name, weight, achievedPoints })
    return steps
  }, []) || []
}

/**
 * @param {array} submissionSteps step_results field from codegrade
 * result api response
 * @param {array} autotestSet sets field from codegrade autotest api response
 * @returns array of objects containing headers, sub headers and points
 * scored in each suite of the autotest
 */
export const getStepResults = ({ submissionSteps, autotestSets }) => {
  if (!autotestSets?.length) return []

  const stepResults = autotestSets.reduce((results, autotestSet) => {
    (autotestSet?.suites || []).forEach(suite => {
      const {
        rubric_row: rubricRow,
        steps: autotestSteps
      } = suite || {}

      const steps = getSuiteSteps({ submissionSteps, autotestSteps })

      // calculate aggregrate score of all the steps under one suite
      const stepTotalScore = steps.reduce((total, step) => total + step.achievedPoints, 0)
      const stepTotalWeight = steps.reduce((total, step) => total + step.weight, 0)
      const stepScore = (stepTotalScore / stepTotalWeight) * 100

      results.push({
        header: rubricRow?.header || '',
        steps,
        stepScore
      })
    })
    return results
  }, [])

  return stepResults
}

export const getCodingAssignmentResults = async params => {
  const {
    assignmentUUID,
    codegradeAssignmentId,
    submissionResponse
  } = params || {}
  if (!assignmentUUID || !codegradeAssignmentId || !submissionResponse) return
  await getCodegradeToken()
  const autotestResponse = await getAutotestDetails(
    { assignmentId: codegradeAssignmentId }
  )

  const { id: submissionId } = submissionResponse || {}
  const { id: autotestId, runs, sets } = autotestResponse || {}
  const { id: runId } = runs?.[0] || {}

  let studentAnswer
  if (!submissionId || !autotestId || !runId) {
    studentAnswer = {
      uuid: assignmentUUID,
      type: 'assignment',
      answer: '',
      correct: false,
      meta: {
        submissionId,
        autotestId,
        runId,
        totalScore: undefined,
        totalWeight: undefined,
        compilationScore: undefined,
        step_results: []
      },
      tries: [false]
    }
  } else {
    const submissionResult = await getPracticeResult({
      submissionId,
      autotestId,
      runId
    })
    const scoreDetails = getScoreDetails(
      submissionResponse, autotestResponse, submissionResult
    )
    const {
      questionResult, totalScore, totalWeight, compilationScore
    } = scoreDetails || {}

    const stepResults = getStepResults({
      submissionSteps: submissionResult?.['step_results'], autotestSets: sets
    })
    studentAnswer = {
      uuid: assignmentUUID,
      type: 'assignment',
      answer: '',
      correct: !!questionResult,
      meta: {
        submissionId,
        autotestId,
        runId,
        totalScore,
        totalWeight,
        compilationScore,
        step_results: stepResults
      }
    }
  }
  return studentAnswer
}
