import React, { Component } from 'react'
import $ from 'jquery'
import config from '../../config'
import { emitter } from '../Emitter/Emitter'
import Context from '../Context/Context'
import {
  ON_LECTURE_VIDEO_PROGRESS,
  ON_MINIMUM_SECTION_PROGRESS,
  ON_SECTION_DATA_PROGRESS,
  ON_TRACK_STUDENT_EVENT,
  ON_SECTION_PROGRESS
} from '../../Constants/emitterKeys'
import {
  getLectureCompleteKey,
  getLectureVideoProgressKey
} from '../../utilities/contextKeys'
import TranscriptPlugin from './TranscriptPlugin'
import LastLectureOverlay from './LastLectureOverlay'
import NextLectureOverlay from './NextLectureOverlay'
import './styles/index.css'
import api from '../../api'
import { getBrowserNameAndVersion } from '../../utilities/mobileDeviceUtils'
import { areAllSiblingKalturaVideosCompleted } from '../../utilities/sectionUtils'
import KalturaPlayer from './KalturaPlayer'
import KalturaV7Player from './KalturaV7Player'
import { TranscriptPluginWrapper } from './styles'
import { MULTIPLE } from '../../Constants/frequency'
import {
  getCurrentInstructorDetails,
  getSubLectureNumber,
  shouldSaveMinimumSectionProgress
} from '../../utilities/chapterUtils'
import { getSecondsFromTime } from '../../utilities/dateTimeUtils'
import { LECTURE_STARTED, LECTURE_WATCHED } from '../../Constants/eventTypes'
import { KALTURA_VIDEO_PROGRESS_TRACKER } from '../../Constants'
import { LECTURE_VIDEO_STARTED } from '../../Constants/studentContext'
import { getPercentageAchieved } from '../../utilities'

class KalturaEmbed extends Component {
  constructor (props) {
    super(props)
    this.handleNavigateAway = this.handleNavigateAway.bind(this)
    this.saveSectionVideosComplete = this.saveSectionVideosComplete.bind(this)
    this.handlePlayerTimeChange = this.handlePlayerTimeChange.bind(this)
    this.handlePlayerPlayEnd = this.handlePlayerPlayEnd.bind(this)
    this.handleOverlayClose = this.handleOverlayClose.bind(this)
    this.handleBufferStart = this.handleBufferStart.bind(this)
    this.transcriptObserver = null
    this.transcriptRef = React.createRef()

    this.state = {
      showOverLay: false,
      videoDuration: 0,
      currentPlayerTime: 0,
      percentagesAchieved: []
    }
  }

  componentDidMount () {
    const { isCalculusCourse } = config
    const isCalculus = isCalculusCourse()
    const { hashedId, lectureVideos, multipleVideos } = this.props

    const video = isCalculus
      ? lectureVideos.find(video => video.kaltura_embed_code === hashedId)
      : multipleVideos.find(video => video.kalturaEmbedCode === hashedId)

    this.setState({ videoDuration: getSecondsFromTime(video?.duration) })

    window.addEventListener('beforeunload', this.handleNavigateAway)
    this.restoreLastProgress()

    if (config.course.useKalturaV7) return

    const { transcriptRef: { current } } = this
    // Listen when transcript widget injected from kaltura
    this.transcriptObserver = new MutationObserver(() => {
      const transcriptDisplay = getComputedStyle(current)
        .getPropertyValue('display') === 'block'
      this.modifyInstructorHeight(true, transcriptDisplay)
      this.modifyTranscriptWidget()
    })

    this.transcriptObserver.observe(current, {
      childList: true, attributeFilter: ['style']
    })
  }

  componentWillUnmount () {
    window.removeEventListener('beforeunload', this.handleNavigateAway)
    const { hashedId, watchedVideo } = this.props
    const { currentPlayerTime } = this.state

    emitter.emit(ON_LECTURE_VIDEO_PROGRESS, {
      sectionUUID: watchedVideo,
      [hashedId]: currentPlayerTime
    })

    if (config.course.useKalturaV7) return

    this.transcriptObserver.disconnect()
  }

  modifyInstructorHeight = (isTranscriptOpened = true, transcriptDisplay = true) => {
    /*
    `isTranscriptOpened` is effected upon click of Hide/Show Transcript.
    `transcriptDisplay` depends on the display handled by Kaltura.
    */
    const height = transcriptDisplay
      ? `calc(100% - ${isTranscriptOpened ? '271px' : '54px'})` : '100%'

    $('.instructor-slide').css('height', height)
  }

  modifyTranscriptWidget = () => {
    // display transcript widget on search icon click
    const onSearchIconClick = () => {
      $('.transcript-box').css('display', 'block')
      $('.transcript-menu-item.toggleTranscriptBodyWrapper')
        .removeClass('close')
        .addClass('open')
    }
    const onToggleTranscript = ({ target: { parentElement: { className } } }) => {
      const isTranscriptOpened = className === 'transcript-body-open'
      this.modifyInstructorHeight(isTranscriptOpened)
    }

    const transcriptMenu = '.transcript-menu-item'
    $(transcriptMenu).on('click', onToggleTranscript)
    // make search icon tabbable
    const serachIconSelector = '.transcript-menu-item.searchWrapper span'
    if (!serachIconSelector) return

    $(serachIconSelector).attr('tabindex', 0)
    $(serachIconSelector).on('click', onSearchIconClick)
    $(serachIconSelector).keydown(e => {
      if (e.which === 13) onSearchIconClick() // is enter key
    })
  }

  restoreLastProgress () {
    const { currentChapter: { type } } = this.context
    const { get, remove } = config.videoProgressOnNavigate(type)
    const progress = get()
    if (!progress) return
    remove()
    emitter.emit(ON_LECTURE_VIDEO_PROGRESS, progress)
  }

  saveSectionVideosComplete () {
    const { currentChapter: { type } } = this.context
    const { watchedVideo, multipleVideos } = this.props
    const lectureCompleteKey = getLectureCompleteKey(type)
    const saveMinimumProgress = shouldSaveMinimumSectionProgress({ type })

    emitter.emit(
      ON_SECTION_DATA_PROGRESS,
      {
        key: lectureCompleteKey,
        sectionUUID: watchedVideo,
        value: true
      }
    )

    const isAllOptionalVideos =
      multipleVideos.every(multipleVideo => multipleVideo.optionalVideo)
    if (isAllOptionalVideos) return

    emitter.emit(ON_SECTION_PROGRESS, watchedVideo)
    saveMinimumProgress && emitter.emit(ON_MINIMUM_SECTION_PROGRESS, watchedVideo)
  }

  savePartialLectureProgress (currentPlayerTime) {
    const { videoDuration, percentagesAchieved } = this?.state || {}
    const { hashedId, watchedVideo } = this?.props || {}

    if (!hashedId || !watchedVideo || !currentPlayerTime) return

    const targetedPercentages = [25, 50, 75]
    const percentageAchieved = getPercentageAchieved(
      targetedPercentages, videoDuration, currentPlayerTime, 5)
    const isWithinRange = !!percentageAchieved
    const isAlreadySaved = percentagesAchieved?.includes(percentageAchieved)

    if (!isWithinRange || isAlreadySaved) return

    this.setState(prevState => ({
      percentagesAchieved: [...prevState.percentagesAchieved, percentageAchieved]
    }))

    emitter.emit(ON_LECTURE_VIDEO_PROGRESS, {
      sectionUUID: watchedVideo,
      [hashedId]: currentPlayerTime
    })
  }

  handleNavigateAway () {
    const { hashedId } = this.props
    const { currentChapter: { type } } = this.context
    const { currentPlayerTime } = this.state
    const browser = getBrowserNameAndVersion(navigator.userAgent)
    const { isCohortEndedForStudent } = this.context
    if (parseInt(browser[1]) > 80 && !isCohortEndedForStudent) {
      const lectureVideoProgressKey = getLectureVideoProgressKey(type)
      return api.setLectureProgressOnNavigate(
        lectureVideoProgressKey,
        { [hashedId]: currentPlayerTime }
      )
    }

    config.videoProgressOnNavigate(type).set(
      { [hashedId]: currentPlayerTime }
    )
  }

  handlePlayerPlayEnd () {
    const {
      studentData,
      currentChapter: { type },
      updateContext,
      cohortData: { duration, name: cohort }
    } = this.context

    const {
      hashedId,
      watchedVideo,
      multipleVideos,
      lectureVideos,
      breadcrumbArr,
      nextChildUUID,
      currentInstructorID,
      hasActiveLearning
    } = this.props
    const { isCalculusCourse } = config
    const isCalculus = isCalculusCourse()
    const lectureCompletekey = getLectureCompleteKey(type)
    const saveMinimumProgress = shouldSaveMinimumSectionProgress({ type })
    const video = isCalculus
      ? lectureVideos.find(video => video.kaltura_embed_code === hashedId)
      : multipleVideos.find(video => video.kalturaEmbedCode === hashedId)
    this.setState({
      showOverLay: false,
      currentPlayerTime: getSecondsFromTime(video.duration)
    })

    updateContext({ showFinish: true, nextChildUUID, hasActiveLearning })
    const { name } = getCurrentInstructorDetails({
      currentInstructorID,
      lectureVideos
    })

    const subLectureNumber = getSubLectureNumber({
      lectureVideos,
      multipleVideos
    })

    const eventData = {
      properties: {
        course: breadcrumbArr[0][0],
        cohort_length: duration,
        cohort,
        sub_chapter: breadcrumbArr[2][0],
        chapter: breadcrumbArr[1][0],
        sublecture_number: subLectureNumber,
        instructor: name,
        time_stamp: Date.now()
      },
      event: LECTURE_WATCHED,
      frequency: MULTIPLE
    }

    emitter.emit(ON_TRACK_STUDENT_EVENT, eventData)

    if (isCalculus) {
      const siblingLectures = lectureVideos.filter(
        video => video.kalturaEmbedCode !== hashedId
      )
      const completedLectures = studentData[lectureCompletekey]
      const siblingLecturesCompleted = siblingLectures.every(lecture =>
        completedLectures[lecture.kalturaEmbedCode])
      emitter.emit(
        ON_SECTION_DATA_PROGRESS,
        {
          key: lectureCompletekey,
          sectionUUID: hashedId,
          value: true
        }
      )
      const { watchedVideo } = this.props
      emitter.emit(
        ON_SECTION_DATA_PROGRESS,
        {
          key: lectureCompletekey,
          sectionUUID: watchedVideo,
          value: true
        }
      )
      siblingLecturesCompleted && emitter.emit(ON_SECTION_PROGRESS, watchedVideo)
      saveMinimumProgress && emitter.emit(ON_MINIMUM_SECTION_PROGRESS, watchedVideo)
      return
    }

    emitter.emit(
      ON_SECTION_DATA_PROGRESS,
      {
        key: lectureCompletekey,
        sectionUUID: hashedId,
        value: true
      }
    )

    const siblingVideosCompleted = areAllSiblingKalturaVideosCompleted(
      multipleVideos, hashedId, studentData[lectureCompletekey]
    )
    return siblingVideosCompleted &&
      !studentData[lectureCompletekey][watchedVideo] &&
      this.saveSectionVideosComplete()
  }

  handleBufferStart () {
    const { currentPlayerTime } = this.state

    if (currentPlayerTime !== 0) return

    const {
      hashedId,
      watchedVideo,
      breadcrumbArr,
      currentInstructorID,
      lectureVideos,
      multipleVideos
    } = this.props
    const { cohortData: { duration, name: cohort } } = this.context
    const { name } = getCurrentInstructorDetails({
      currentInstructorID,
      lectureVideos
    })

    const subLectureNumber = getSubLectureNumber({
      lectureVideos,
      multipleVideos
    })

    const eventData = {
      properties: {
        course: breadcrumbArr[0][0],
        cohort_length: duration,
        cohort,
        sub_chapter: breadcrumbArr[2][0],
        chapter: breadcrumbArr[1][0],
        sublecture_number: subLectureNumber,
        instructor: name,
        time_stamp: new Date().getTime()
      },
      event: LECTURE_STARTED,
      frequency: MULTIPLE
    }

    emitter.emit(ON_TRACK_STUDENT_EVENT, eventData)
    emitter.emit(
      ON_SECTION_DATA_PROGRESS,
      {
        key: LECTURE_VIDEO_STARTED,
        sectionUUID: watchedVideo,
        value: hashedId
      }
    )
  }

  handlePlayerTimeChange (currentPlayerTime) {
    this.savePartialLectureProgress(currentPlayerTime)
    this.setState({ currentPlayerTime })
  }

  handleOverlayClose () {
    this.setState({ showOverLay: false })
  }

  renderLectureOverlay () {
    const {
      nextChildUUID,
      watchedVideo,
      hasActiveLearning,
      multipleVideos,
      isLastVideo
    } = this.props
    const { showOverLay } = this.state

    if (!showOverLay) return null
    if (isLastVideo === false) {
      return (
        <NextLectureOverlay
          sectionUUID={watchedVideo}
          nextChildUUID={nextChildUUID}
          onClose={this.handleOverlayClose}
          multipleVideos={multipleVideos}
        />
      )
    }
    return (
      <LastLectureOverlay
        sectionUUID={watchedVideo}
        nextChildUUID={nextChildUUID}
        multipleVideos={multipleVideos}
        hasActiveLearning={hasActiveLearning}
        onClose={this.handleOverlayClose}
      />
    )
  }

  render () {
    const { currentPlayerTime } = this.state
    const { studentData, currentChapter: { type } } = this.context
    const lectureVideoProgressKey = getLectureVideoProgressKey(type)
    const lectureCompleteKey = getLectureCompleteKey(type)
    const { hashedId, watchedVideo, isActive, shouldSplitLecturePage } = this.props
    const isVideoCompleted = studentData[lectureCompleteKey][hashedId]
    const initialProgress = isVideoCompleted
      ? 0 : studentData[lectureVideoProgressKey][hashedId]
    const { useKalturaV7 } = config.course
    return (
      <div className='position-relative'>
        <div className='embed-responsive embed-responsive-16by9'>
          {
            useKalturaV7
              ? (
                <KalturaV7Player
                  hashedId={hashedId}
                  isActive={isActive}
                  isLectureVideo={!!watchedVideo}
                  initialProgress={initialProgress}
                  onPlayEnd={this.handlePlayerPlayEnd}
                  onBuffer={this.handleBufferStart}
                  onTimeChange={this.handlePlayerTimeChange}
                />
              ) : (
                <KalturaPlayer
                  hashedId={hashedId}
                  isActive={isActive}
                  isLectureVideo={!!watchedVideo}
                  initialProgress={initialProgress}
                  onPlayEnd={this.handlePlayerPlayEnd}
                  onBuffer={this.handleBufferStart}
                  onTimeChange={this.handlePlayerTimeChange}
                />
              )
          }
          {this.renderLectureOverlay()}
          <div
            id={KALTURA_VIDEO_PROGRESS_TRACKER}
            kalturaid={hashedId}
            progress={currentPlayerTime}
          />
        </div>
        {useKalturaV7 ? null
          : (
            shouldSplitLecturePage ? (
              <TranscriptPluginWrapper
                id='transcript-player-plugin'
                ref={this.transcriptRef}
              />
            ) : (
              <TranscriptPlugin
                ref={this.transcriptRef}
              />
            )
          )}
      </div>
    )
  }
}

KalturaEmbed.contextType = Context
export default KalturaEmbed
