import React from 'react'
import InstructorVideoList from '../InstructorVideoList/InstructorVideoList'
import { emitter } from '../Emitter/Emitter'
import config from '../../config'
import './css/course-schedule.css'
import { ON_GO_TO_COURSEHOME } from '../../Constants/emitterKeys'

export default function CourseInfo ({ course, setUUID }) {
  if (!course) return null
  const handleBack = () => {
    emitter.emit(ON_GO_TO_COURSEHOME)
  }

  return (
    <section className='section course-home'>
      <div className='container position-relative'>
        <div className='position-absolute d-flex flex-row-reverse back-to-home'>
          <span className='d-flex justify-content-between align-items-center'>
            <svg
              className='icon-angle-arrow rotate-180 mr-2'
              width='7'
              height='13'
              fill='#3B5D5B'
            >
              <use xlinkHref='./images/icons/icon-angle-arrow.svg#icon-angle-arrow' />
            </svg>
            <span
              className='pointer linkHover'
              data-uuid={setUUID}
              onClick={handleBack}
            >
              Course Home
            </span>
          </span>
        </div>
        <div className='section-main__text d-flex align-items-center'>
          <div className='section-intro__icon pr-4'>
            <img
              className='sigma-icon-lg'
              src={config.courseLogo}
              alt='Sigma'
            />
          </div>
          <div className='col-8'>
            <h1 className='mt-2'>{course.title}</h1>
            <p className='text-justify'>{course.desc}</p>
          </div>
        </div>
        <div className='col-12 text-center'>
          <h3>Course Instructors</h3>
        </div>
        <hr />
        <div className='row'>
          <InstructorVideoList instructor={course.instructor} />
        </div>

        <div className='course-schedule'>
          <div className='row'>
            <div className='col-12 text-center'>
              <a href={`${config.courseName}.syllabus.pdf`}>
                Course Syllabus
              </a>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </section>
  )
}
