import React, { useContext, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import DownloadMenu from './DownloadMenu'
import FullWindow from './adobe/FullWindow'
import {
  ConceptMapHeading,
  ReadingContainer,
  ReadingDetails,
  ReadingTitle,
  StyledAnswerText,
  StyledAnswerDownloadText,
  Author
} from './styled'
import { emitter } from '../Emitter/Emitter'
import {
  ON_MINIMUM_SECTION_PROGRESS,
  ON_SECTION_DATA_PROGRESS,
  ON_SECTION_PROGRESS
} from '../../Constants/emitterKeys'
import Context from '../Context/Context'
import { downloadFile } from '../../utilities/file'
import {
  getReadingCompleteKey,
  getReadingProgressKey
} from '../../utilities/contextKeys'
import Infographic from './Infographic/Infographic'

export default function Readings (props) {
  const { activeSectionUUID, reading, completeScreenData } = props
  const {
    allReadings,
    readingUUID,
    answerKeyPdf,
    isConceptMap,
    title,
    infographic,
    author,
    pdf,
    mobi,
    epub
  } = reading

  const readingProgressKey = getReadingProgressKey(!!isConceptMap)
  const readingCompleteKey = getReadingCompleteKey(!!isConceptMap)

  const {
    studentData: {
      [readingProgressKey]: readingsProgress,
      [readingCompleteKey]: readingsComplete
    },
    updateContext
  } = useContext(Context)

  useEffect(() => {
    if (readingsComplete && activeSectionUUID) {
      const isReadingsComplete =
        !!readingsComplete[activeSectionUUID] && !!readingsProgress[readingUUID]
      if (isReadingsComplete) {
        updateContext({ reviewMode: true })
      }
    }
    if (updateContext && activeSectionUUID) {
      updateContext({
        currentQuestionSet: { ...reading, type: 'Readings' },
        showFinish: true
      })
    }
    // eslint-disable-next-line
  }, [readingsComplete, activeSectionUUID, completeScreenData])

  const callBack = () => {
    const isReadingsComplete = !!readingsComplete[activeSectionUUID] || true
    if (isReadingsComplete) return

    saveReadingProgress()
    saveSectionProgress()
  }

  const saveReadingProgress = () => {
    emitter.emit(ON_SECTION_DATA_PROGRESS, {
      key: readingProgressKey,
      value: true,
      sectionUUID: readingUUID
    })
  }

  const saveSectionProgress = () => {
    if (!isAllReadingsComplete()) return
    emitter.emit(ON_SECTION_DATA_PROGRESS, {
      key: readingCompleteKey,
      value: true,
      sectionUUID: activeSectionUUID
    })
    if (isAllOptionalReadings()) return
    emitter.emit(ON_SECTION_PROGRESS, activeSectionUUID)
    emitter.emit(ON_MINIMUM_SECTION_PROGRESS, activeSectionUUID)
  }

  const isAllReadingsComplete = () => {
    const isComplete = allReadings.every(reading => {
      const { question_set_uuid: readingUUID } = reading
      return !!readingsProgress[readingUUID]
    })

    return isComplete
  }

  const isAllOptionalReadings = () => {
    return allReadings.every(reading => reading.optionalReading)
  }

  const getHeadingText = () => {
    if (isConceptMap) {
      return (
        <ConceptMapHeading>
          <StyledAnswerText>
          After completing the worksheet below, view
            <StyledAnswerDownloadText onClick={() => downloadFile(answerKeyPdf)}>
            the answer key
            </StyledAnswerDownloadText>
          .
          </StyledAnswerText>
        </ConceptMapHeading>
      )
    }
  }

  const hasDownloadableFile = !!(pdf || mobi || epub)

  return (
    <Container>
      {getHeadingText()}
      <ReadingContainer>
        <Col className='p-0'>
          <ReadingDetails>
            <Col xs='12' sm='12' md='auto' lg='auto'
              style={{ paddingRight: '14px' }}>
              <ReadingTitle header>{title}</ReadingTitle>
            </Col>
            <Col xs='12' sm='12' md='auto' lg='auto'>
              <Author header>{author}</Author>
            </Col>
            {
              hasDownloadableFile && (
                <Col
                  xs='12'
                  sm='12'
                  md
                  lg
                  className='text-xs-left text-sm-left text-md-right text-lg-right'>
                  <DownloadMenu pdf={pdf} mobi={mobi} epub={epub} />
                </Col>
              )
            }
          </ReadingDetails>
          <Row>
            <Col>
              {
                infographic
                  ? <Infographic
                    title={title}
                    infographicEmbed={infographic}
                    progressCallback={callBack}
                  />
                  : <FullWindow
                    callBack={callBack}
                    pdf={pdf}
                    title={title}
                    readingUUID={readingUUID}
                  />
              }
            </Col>
          </Row>
        </Col>
      </ReadingContainer>
    </Container>
  )
}
