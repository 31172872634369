import React, { useMemo, memo } from 'react'
import PropTypes from 'prop-types'
import { StyledIframe, StyledInfographicWrapper } from './styled'
import { getSrcUrl } from '../../../utilities'

const Infographic = memo(({ title, infographicEmbed, progressCallback }) => {
  const infographicUrl = useMemo(() => {
    return getSrcUrl(infographicEmbed)
  }, [infographicEmbed])

  const handleOnLoad = () => {
    progressCallback()
  }

  return (
    <StyledInfographicWrapper>
      <StyledIframe
        title={`Infographic-${title}`}
        src={infographicUrl}
        frameBorder={0}
        scrolling='yes'
        onLoad={handleOnLoad}
      />
    </StyledInfographicWrapper>
  )
})

Infographic.propTypes = {
  title: PropTypes.string,
  infographicEmbed: PropTypes.string,
  progressCallback: PropTypes.func
}

Infographic.displayName = 'Infographic'

export default Infographic
