import React, { useEffect, useState, useRef, useContext } from 'react'
import * as typeformEmbed from '@typeform/embed'
import './css/typeForm.css'
import CourseButton from '../CourseButton/CourseButton'
import { Auth0Context } from '../Auth0Provider/Auth0Provider'
import Context from '../Context/Context'

const Typeform = ({
  formId,
  sendAttemptId,
  renderHeaderContent,
  afterSubmissionText,
  showContentAfterSubmission = true,
  buttonText,
  headerText,
  scrollToFooterAfterSubmission,
  formStyle,
  handleSubmit
}) => {
  const { studentId } = useContext(Auth0Context)
  const { latestCohort } = useContext(Context)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [isError, setIsError] = useState(false)
  const formRef = useRef(null)
  const footerRef = useRef(null)

  useEffect(() => {
    if (scrollToFooterAfterSubmission && isFormSubmitted && footerRef.current) {
      footerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [scrollToFooterAfterSubmission, isFormSubmitted])

  useEffect(() => {
    if (!formRef.current) return
    const embedForm = async () => {
      if (!studentId) setIsError(true)
      if (studentId) {
        const encodedStudentId = encodeURIComponent(studentId)
        typeformEmbed.makeWidget(
          formRef.current,
          `https://form.typeform.com/to/${formId}?student_id=${encodedStudentId}${sendAttemptId ? `&attempt_id=${latestCohort?.attemptID}` : ''}`,
          {
            transferableUrlParameters: [
              'student_id',
              ...(sendAttemptId ? ['attempt_id'] : [])
            ],
            onSubmit: () => {
              if (handleSubmit) handleSubmit()
              setIsFormSubmitted(true)
            }
          }
        )
      }
    }

    embedForm()
  }, [formRef, formId, sendAttemptId, studentId, latestCohort, handleSubmit])

  const handleOnNextClick = () => window.location.reload()

  if (isError) {
    return (
      <p className='typeform-error'>
        The form didn't load correctly.
        Please refresh the page and try again.
        If the problem persists, reach out to us at
        <a href='mailto:success@outlier.org'> success@outlier.org.</a>
      </p>
    )
  }

  return (
    <>
      {headerText
        ? <h2 className='registration-page__header'>{headerText}</h2>
        : null}
      {renderHeaderContent?.()}
      <div id='embedded-typeform' ref={formRef} style={formStyle} />
      {
        showContentAfterSubmission && isFormSubmitted && (
          <div id='footer' ref={footerRef}>
            <div id='footer-content'>
              {afterSubmissionText ? (
                <p id='footer-text'>
                  {afterSubmissionText}
                </p>
              ) : null}
              <CourseButton
                className={{ 'btn-primary': true }}
                style={{ minWidth: '119px', lineHeight: '22px' }}
                onClick={handleOnNextClick}
              >
                {buttonText || 'Next'}
              </CourseButton>
            </div>
          </div>
        )
      }
    </>
  )
}

export default Typeform
