import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const GraphNote = styled.p`
  content: '';
  min-width: 100%;
  height: 41px;
  padding: 12px;
  background-color: ${props => !props.isAssessmentQuizExam && '#000000'};
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  ${props => props.noteMargin && `margin-bottom: ${props.noteMargin}!important;`}
  ::before {
    content: 'Use cursor to interact with graph';
    ${mediaqueries.tablet`
        content: 'Use two fingers to interact with graph';
    `}
  }
`
