import React, { Component } from 'react'
import { emitter } from '../Emitter/Emitter'
import debounce from 'lodash/debounce'
import { NEXT_ICON } from '../../Constants/progressBarIconSource'
import CourseButton from '../CourseButton/CourseButton'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'
import { QUIZ } from '../../Constants/sectionType'
import { endProctorioSession } from '../../utilities/examUtils'

class ReviewNextButton extends Component {
  constructor (props) {
    super(props)
    this.finishReviewQuestionSet = this.finishReviewQuestionSet.bind(this)
    this.handleDebounce = this.handleDebounce.bind(this)
    this.onNextClicked = null
    this.state = {
    }
  }

  componentDidMount () {
    this.handleDebounce()
  }

  componentWillUnmount () {
    if (!this.onNextClicked) return
    this.onNextClicked = null
  }

  finishReviewQuestionSet () {
    console.log(this.props)
    if (this.props.type_of_question === QUIZ) {
      emitter.emit(ON_NAVIGATE_TO, '/' + this.props.activeSectionUUID)
    } else {
      emitter.emit(ON_NAVIGATE_TO, '/')
      endProctorioSession()
    }
  }

  handleDebounce () {
    const { onReviewClickNext } = this.props

    this.onNextClicked = debounce(() => onReviewClickNext(),
      1000,
      {
        maxWait: 1000,
        trailing: true,
        leading: false
      })
  }

  render () {
    const { showfinish, studentData: { isAssessmentQuizExam } } = this.props

    return (
      <CourseButton
        className={{
          'btn-primary': true,
          'mx-1': true
        }}
        dataCypress={showfinish ? 'finishButton' : 'nextButton'}
        onClick={showfinish ? this.finishReviewQuestionSet : this.onNextClicked}
        title={showfinish ? 'Finish' : 'Go to Next Question'}
      >
        {showfinish
          ? (isAssessmentQuizExam ? 'Exit' : 'Finish')
          : (isAssessmentQuizExam
            ? <img src={NEXT_ICON} alt='Next' />
            : 'Next')}
      </CourseButton>
    )
  }
}

export default ReviewNextButton
