import React from 'react'
import TooltipTextModal from '../TooltipTextModal/TooltipTextModal'

class TooltipTextModalContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      tooltipText: '',
      clickedText: ''
    }
  }

  componentDidMount () {
    document.addEventListener('click', this.handleClickForTooltipModal, false)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleClickForTooltipModal, false)
  }

  handleClickForTooltipModal = (e) => {
    const tooltipText = e.target.getAttribute('data-tooltip')
    const clickedText = e.target.innerText
    if (tooltipText && clickedText) {
      this.setState({ tooltipText, clickedText })
    }
  }

  resetText = () => {
    this.setState({ tooltipText: '', clickedText: '' })
  }

  render () {
    const { tooltipText, clickedText } = this.state
    return (
      <>
        {this.props.children}
        <TooltipTextModal tooltipText={tooltipText} clickedText={clickedText} resetText={this.resetText} />
      </>
    )
  }
}

TooltipTextModalContainer.displayName = 'TooltipTextModalContainer'
export default TooltipTextModalContainer
