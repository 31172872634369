import React, { useContext } from 'react'
import config from '../../../config'
import Header from '../Header'
import OrientationVideo from './OrientationVideo'
import VideoChapters from './VideoChapters'
import RequiredTools from './RequiredTools'
import HowItWorks from './HowItWorks'
import FAQ from '../FAQ'
import { Wrapper, Container, GlobalStyle } from '../styled'
import {
  CALCULUS_CHAPTERS,
  EVERGREEN_CHAPTERS,
  KALTURA_HIGHSCHOOL_COURSES_ID
} from '../../../Constants/orientationVideo'
import ScrollToTop from '../../ScrollToTop'
import Context from '../../Context/Context'
import { isHighSchoolCourse } from '../../../utilities/courseUtils'

const Orientation = () => {
  const {
    activeCourse
  } = useContext(Context)

  const { isCalculusCourse } = config
  const isCalculus = isCalculusCourse()
  const isHighSchool = isHighSchoolCourse(activeCourse)
  const chapters = isCalculus ? CALCULUS_CHAPTERS : EVERGREEN_CHAPTERS

  const videoId = isHighSchool
    ? KALTURA_HIGHSCHOOL_COURSES_ID
    : config.course.kalturaOrientationVideoId

  return (
    <>
      <GlobalStyle />
      <ScrollToTop />
      <Wrapper>
        <Container>
          <Header
            title='Orientation'
            breadcrumb={{
              text: 'Course Toolkit',
              link: 'resources'
            }}
          />
          <OrientationVideo
            videoId={videoId}
          />
          <VideoChapters
            chapters={chapters}
          />
          <RequiredTools />
          <HowItWorks />
          <FAQ />
        </Container>
      </Wrapper>
    </>
  )
}

export default Orientation
