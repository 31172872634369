import React from 'react'
import { StyledSuccessLink } from './styles'

const StudentSuccessLink = ({ showProfCertGuardianPermission }) => {
  if (showProfCertGuardianPermission) {
    return (
      <StyledSuccessLink target='_blank' href='https://help.outlier.org/hc/en-us/requests/new'>
        Student Success
      </StyledSuccessLink>
    )
  }

  return <span className='success'> success@outlier.org</span>
}

export default StudentSuccessLink
