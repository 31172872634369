import React, { Component } from 'react'
import MathJax from '../MathJax/MathJax'
import classNames from 'classnames'
import QuestionComponent from '../QuestionComponent/QuestionComponent'
import FeedbackToast from '../FeedbackToast/FeedbackToast'
import CourseButton from '../CourseButton/CourseButton'
import ShowSolution from '../ShowSolution/ShowSolution'
import { emitter } from '../Emitter/Emitter'
import Context from '../Context/Context'
import QuestionNextButton from '../QuestionComponent/QuestionNextButton'
import QuestionPreviousButton from '../QuestionComponent/QuestionPreviousButton'
import { replaceBlanksWithInputElem } from './TextBlanksQuestionShared'
import { ACTIVE_LEARNING,
  ACTIVITY_TYPES } from '../../Constants/sectionType'
import {
  ON_STUDENT_ANSWER, ON_SUBMIT_ANSWER
} from '../../Constants/emitterKeys'
import { findAnswer as findAnswerUtil } from '../../utilities/questions'
import { getExplanation } from '../../utilities/courseUtils'
import { PaginationWrapper, PinText, PinButton, PinWrapper, TextWrapper,
  QuestionText, PinButtonWrapper, TextBlankWrapper, LessonTextAL,
  LessonSmallTextAL, ExplanationWrapper, AdaptationWrapper }
  from './styles'
import { PINNED_ICON_WHITE, PIN_ICON }
  from '../../Constants/progressBarIconSource'
import ShowFeedback from '../ShowFeedBack/ShowFeedBack'
import { PINNED_TEXT, PIN_TEXT } from '../../Constants'
import { INCORRECT, CORRECT } from '../../Constants/result'
import { getStudentAnswersKey } from '../../utilities/contextKeys'
import { debounce, escapeRegExp } from 'lodash'
import JDoodleCompiler from '../JDoodleCompiler/JDoodleCompiler'

const ANS_VALUE_INDEX = 0
const ANS_TRUEORFALSE_INDEX = 1

class TextBlanksQuestion extends Component {
  constructor (props) {
    super(props)
    this.fillTheBlanks = this.fillTheBlanks.bind(this)
    this.callListenerFunc = this.callListenerFunc.bind(this)
    this.onShowSolution = this.onShowSolution.bind(this)
    this.showResult = this.showResult.bind(this)
    this.checkSolution = this.checkSolution.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)

    this.state = {
      question: '',
      tryAttempt: 0,
      answers: null,
      showAttempt: false,
      isPinned: false,
      shouldShowSolution: false,
      isNextDisabled: true,
      currentAns: [],
      showToast: false,
      studentAnswerRes: {}
    }
  }

  fillTheBlanks (answers, isCorrect) {
    const blank = document.getElementsByName('blank[]')
    const {
      question: {
        answer,
        fitbCaseSensitive
      }
    } = this.props
    const currentAns = []
    if (Array.isArray(answers)) {
      answers = answers.map(String)
      if (blank.length > 0) {
        blank.forEach((element, key) => {
          if (Array.isArray(answer) && answer[key] && answers[key]) {
            element.value = answers[key]
            const isSameAns = fitbCaseSensitive
              ? answer[key] === answers[key]
              : answer[key].toLowerCase() === answers[key].toLowerCase()
            const isTextMatched = fitbCaseSensitive
              ? escapeRegExp(answer[key]).match(escapeRegExp(answers[key]))
              : escapeRegExp(answer[key].toLowerCase())
                .match(escapeRegExp(answers[key].toLowerCase()))
            element.style.color = isCorrect || isSameAns ? '#78BCB8'
              : isTextMatched ? '#FFF' : '#E1774F'
            element.style.borderBottom = isTextMatched || isCorrect
              ? '1px solid #78BCB8' : '1px solid #E1774F'
            currentAns.push(answers[key])
          }
        })
      }
    }
    this.setState({ currentAns })
  }

  componentDidMount () {
    const { studentData: { pinnedQuestions } } = this.context
    const { question: { Question_uuid: questionUUID } } = this.props
    this.setState(
      { isPinned: pinnedQuestions.includes(questionUUID) }
    )
  }

  callListenerFunc () {
    const {
      question: {
        Question_uuid: questionUUID
      }
    } = this.props
    const studentAnswers = this.getStudentAnswers()

    let findAnswer = findAnswerUtil(questionUUID, studentAnswers)
    if (findAnswer) findAnswer = [findAnswer.answer, findAnswer.correct]

    const ANS_VALUE_INDEX = 0
    const ANS_TRUEORFALSE_INDEX = 1

    if (findAnswer) {
      this.fillTheBlanks(findAnswer[ANS_VALUE_INDEX],
        findAnswer[ANS_TRUEORFALSE_INDEX])
      this.setState({
        isNextDisabled: findAnswer[ANS_TRUEORFALSE_INDEX] !== true
      })
    }

    document.getElementsByName('blank[]').forEach((e, index) => {
      const isEventAdded = e.getAttribute('focusin') ||
      e.getAttribute('focusout')
      if (!isEventAdded) {
        e.addEventListener('focusin', (event) => {
          const userReply = e.value.trim().toLowerCase()
          if (userReply) return

          e.style.borderBottom = '1px solid #fff'
        })
        e.addEventListener('focusout', (event) => {
          const userReply = e.value.trim().toLowerCase()
          if (userReply) return

          e.style.borderBottom = '1px solid #78BCB8'
        })
      }
      e.addEventListener('keyup', () => {
        const {
          question: {
            Question_uuid: questionUUID,
            answer: answerArr,
            text_blank_stuck_trigger: textBlankStuckTrigger,
            fitbCaseSensitive
          },
          saveProgress
        } = this.props
        const { currentAns, result, isPinned } = this.state
        const chkAns = fitbCaseSensitive
          ? answerArr[index] : answerArr[index].toLowerCase()
        const userReply = fitbCaseSensitive
          ? e.value.trim() : e.value.trim().toLowerCase()
        const matchedText =
          chkAns.match(new RegExp(escapeRegExp(userReply) + '(.*)'))

        this.setState({ showToast: false })
        if (matchedText && matchedText.length && matchedText.index === 0) {
          e.style.color = chkAns !== userReply ? '#FFF' : '#78BCB8'
          e.style.borderBottom = '1px solid #fff'

          currentAns[index] = !matchedText[1] ? matchedText.input : userReply
          const isSameArr = answerArr.length === currentAns.length &&
          answerArr.every((element, index) =>
            fitbCaseSensitive
              ? element === userReply
              : element.toLowerCase() === currentAns[index].toLowerCase()
          )

          const testRes = {
            uuid: questionUUID,
            answer: currentAns,
            type: ACTIVITY_TYPES[ACTIVE_LEARNING],
            questionActivity: { pinned: isPinned },
            correct: isSameArr
          }
          if (isSameArr) {
            if (result !== CORRECT) {
              window.outlierLog('Correct Answer', chkAns)
            }
            this.setState({
              result: CORRECT,
              isNextDisabled: false,
              tryAttempt: 0,
              studentAnswerRes: testRes
            })
            this.setState({ showToast: true })
            debounce(() => saveProgress(testRes), 500)
          } else {
            this.setState({
              result: '',
              isNextDisabled: true,
              studentAnswerRes: testRes
            })
          }
          emitter.emit(ON_STUDENT_ANSWER, testRes)
        } else {
          currentAns[index] = userReply
          if (result !== INCORRECT && chkAns) {
            window.outlierLog('Incorrect Answer', chkAns)
          }
          const testRes = {
            uuid: questionUUID,
            answer: currentAns,
            questionActivity: { pinned: isPinned },
            correct: false
          }
          const { tryAttempt } = this.state
          this.setState({
            result: INCORRECT,
            isNextDisabled: true,
            tryAttempt: tryAttempt + 1,
            studentAnswerRes: testRes
          })
          emitter.emit(ON_STUDENT_ANSWER, testRes)
          e.style.color = '#E1774F'
          e.style.borderBottom = '1px solid #E1774F'

          /* --- Added for Displaying Stuck Dialouge --- */
          if (tryAttempt >= textBlankStuckTrigger) {
            this.setState({
              showAttempt: true,
              result: INCORRECT
            })
          }
          /* --- Added for Displaying Stuck Dialouge End--- */
          this.setState({ showToast: tryAttempt + 1 >= 3 })

          saveProgress(testRes)
        }
      })
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      question: {
        Question_uuid: questionUUID,
        answer: questionAnswer
      },
      isRevealed
    } = this.props
    const { studentData: { pinnedQuestions } } = this.context
    const {
      question: {
        Question_uuid: prevQuestionUUID
      },
      isRevealed: prevIsRevealed
    } = prevProps
    const studentAnswers = this.getStudentAnswers()

    let fAnswer = findAnswerUtil(questionUUID, studentAnswers)
    if (fAnswer) fAnswer = [fAnswer.answer, fAnswer.correct]
    if (prevQuestionUUID !== questionUUID) {
      this.setState({ isPinned: pinnedQuestions.includes(questionUUID) })
    }

    if (prevIsRevealed !== isRevealed && isRevealed) {
      this.fillTheBlanks(questionAnswer, true)
      this.setState({ result: '' })
      this.checkSolution()
    } else if (prevQuestionUUID !== questionUUID) {
      this.fillTheBlanks(...fAnswer
        ? [fAnswer[ANS_VALUE_INDEX], fAnswer[ANS_TRUEORFALSE_INDEX]]
        : ['', false])
      this.setState({
        isNextDisabled: !fAnswer || fAnswer[ANS_TRUEORFALSE_INDEX] !== true,
        result: '',
        tryAttempt: 0
      })
    }
  }

  getStudentAnswers = () => {
    const {
      currentChapter: { type },
      studentData
    } = this.context
    const studentAnswersKey = getStudentAnswersKey(type)
    return studentData[studentAnswersKey]
  }

  onShowSolution () {
    const {
      question: { answer: correctAnswer }
    } = this.props
    const {
      answers
    } = this.state

    if (!Array.isArray(correctAnswer)) return

    const answer = correctAnswer.join(',')
    const selectedValues = answers ? answers.join(' ,') : ''
    this.setState({
      showSolution: true,
      showAttempt: false,
      solution: {
        answer,
        selectedValues
      }
    })
  }

  showResult () {
    this.checkSolution()
  }

  onPinClick (currentQuestionUUID) {
    const {
      studentData,
      updateContext
    } = this.context
    const { pinnedQuestions } = studentData
    const { isPinned } = this.state
    const currentQuestionIndex = pinnedQuestions.indexOf(currentQuestionUUID)
    const studentAnswers = this.getStudentAnswers()
    const fAnswer = findAnswerUtil(currentQuestionUUID, studentAnswers)
    const answer = {
      uuid: currentQuestionUUID,
      type: ACTIVITY_TYPES[ACTIVE_LEARNING],
      questionActivity: { pinned: false },
      answer: fAnswer?.answer || '',
      correct: fAnswer?.correct || false
    }
    if (isPinned) {
      pinnedQuestions.splice(currentQuestionIndex, 1)
    } else {
      answer.questionActivity.pinned = true
      pinnedQuestions.push(currentQuestionUUID)
    }
    emitter.emit(ON_SUBMIT_ANSWER, answer)
    studentData.pinnedQuestions = pinnedQuestions
    updateContext({ studentData })
    this.setState({ isPinned: !isPinned })
  }

  checkSolution () {
    const blankElementList = document.getElementsByName('blank[]')
    const answers = []
    blankElementList.forEach(element => {
      answers.push(element.value.trim().replace(/\s+/g, ' '))
    })
    this.setState({ answers, showToast: false })

    const {
      question: {
        answer: correctAnswer,
        Question_uuid: questionUUID,
        fitbCaseSensitive
      },
      make_reveal_false: makeRevealFalse,
      saveProgress
    } = this.props
    const testRes = {
      uuid: questionUUID,
      questionActivity: { pinned: this.state.isPinned },
      answer: answers
    }

    let finalResult = true

    const results = correctAnswer.map((answer, index) => {
      const isCorrectAnswer = answer && answers[index] && (
        fitbCaseSensitive
          ? answer === answers[index]
          : answer.toLowerCase() === answers[index].toLowerCase()
      )
      if (isCorrectAnswer) {
        this.setState({
          tryAttempt: 0,
          isNextDisabled: false
        })
        return CORRECT
      } else {
        finalResult = false
        const { tryAttempt } = this.state
        this.setState({
          tryAttempt: tryAttempt + 1,
          isNextDisabled: true
        })
        return INCORRECT
      }
    })

    testRes.correct = finalResult
    this.setState({ studentAnswerRes: testRes })
    emitter.emit(ON_STUDENT_ANSWER, testRes)

    if (results.indexOf(INCORRECT) !== -1) {
      const { tryAttempt } = this.state
      const finalTryAttempt = tryAttempt + 1
      this.setState({
        result: INCORRECT,
        tryAttempt: finalTryAttempt,
        showAttempt: true,
        showSolution: false,
        isNextDisabled: true
      })
    } else {
      this.setState({
        result: CORRECT,
        tryAttempt: 0
      })
    }
    makeRevealFalse()
    saveProgress(testRes)
  }

  // Should return false if the action was not allowed.
  // Component uses text entry, so we must ignore arrow keys which are used to
  // move around within that input field.
  handleKeyPress (type) {
    // Typically from pressing the left arrow, allow it and ignore it.
    if (type && type === 'previous') return true

    // Typically from pressing the right arrow, allow it and ignore it.
    if (type && type === 'advance') return true

    const { onNextButtonClick } = this.props
    // Everything else is interpreted as next or advance forward
    const { isNextDisabled, studentAnswerRes } = this.state
    if (isNextDisabled) return false
    onNextButtonClick(studentAnswerRes)
    return true
  }

  render () {
    const {
      answers,
      studentAnswerRes,
      shouldShowSolution,
      isNextDisabled,
      showSolution,
      solution,
      showAttempt,
      isPinned,
      tryAttempt,
      showToast,
      result
    } = this.state
    const {
      question: {
        dato_card_type: datoCardType,
        Question_uuid: questionUUID,
        feedback,
        general_explanation: generalExplanation,
        specific_explanation: specificExplanation,
        adaptationStatement,
        jdoodleEditor,
        starterCode,
        answer
      },
      question_text: questionText,
      lesson_text: lessonText,
      structuredText,
      display_illustration: displayIllustration,
      desmosgraph,
      gMath,
      displayReveal,
      correctProps,
      currentQuestionUUID,
      filteredActvQuestInd,
      incorrectProps
    } = this.props
    const { isLastQuestionAnswered } = this.context
    const replacedTextBlankQ =
      replaceBlanksWithInputElem(questionText, answer)
    const queTextClass = classNames({
      'que-text': false,
      'active-que-text': false,
      col: true
    })

    return (
      <>
        <>
          {showToast && result &&
            <FeedbackToast
              showToast={showToast}
              isCorrect={result === CORRECT}
            />}
          <TextWrapper isALRedesign >
            <QuestionText isALRedesign >
              Card {filteredActvQuestInd + 1}
            </QuestionText>
            <PinWrapper isALRedesign
              onClick={() => this.onPinClick(currentQuestionUUID)}>
              <PinText>{isPinned ? PINNED_TEXT : PIN_TEXT}</PinText>
              <PinButtonWrapper>
                <PinButton
                  isALRedesign
                  src={isPinned ? PINNED_ICON_WHITE : PIN_ICON}
                  data-testid='pinButton'
                />
              </PinButtonWrapper>
            </PinWrapper>
          </TextWrapper>
        </>
        <TextBlankWrapper
          isALRedesign
        >
          {structuredText}
          {
            lessonText &&
            <>
              <LessonTextAL
                className='font__md' isALRedesign >
                { lessonText }
              </LessonTextAL>
            </>
          }
          <ExplanationWrapper className='row align-items-start pt-3'>
            {displayIllustration}
            {/* --- Added Desmos Here --- */}
            {desmosgraph}
            {
              replacedTextBlankQ &&
              <div className={queTextClass}>
                <LessonSmallTextAL
                  className='pb-3 font__md'
                  isALRedesign >
                  <MathJax
                    math={replacedTextBlankQ}
                    onMathJaxDidRender={this.callListenerFunc}
                    datoCardType={datoCardType}
                  />
                </LessonSmallTextAL>
              </div>
            }
          </ExplanationWrapper>
          {gMath}
          {jdoodleEditor &&
            <div className='mt-2'>
              <JDoodleCompiler
                key={questionUUID}
                questionUUID={questionUUID}
                isALRedesign
                starterCode={starterCode}
              />
            </div>}
          {
            showSolution &&
            <ShowSolution solutionData={solution}
              explanation={getExplanation(specificExplanation, generalExplanation)}
              toggleQuestionSolution={displayReveal} />
          }
          {!showSolution && result &&
          <ShowFeedback
            showStuck={showAttempt && tryAttempt >= 3}
            studentAnswer={studentAnswerRes?.answer.join(' ')}
            sectionType={ACTIVE_LEARNING} result={result}
            correctProps={correctProps}
            incorrectProps={incorrectProps}
            isALRedesign
            showSolutionHandler={displayReveal}
          />}
          {answers && feedback && <div
            dangerouslySetInnerHTML={{ __html: feedback }}
          />}
        </TextBlankWrapper>
        <PaginationWrapper isALRedesign >
          <QuestionPreviousButton
            {...this.props}
            isALRedesign
          />
          {
            shouldShowSolution &&
              <CourseButton
                className={{
                  'btn-primary': true,
                  'mx-1': true
                }}
                onClick={this.onShowSolution}
              >
                Show Solution
              </CourseButton>
          }
          {!isLastQuestionAnswered &&
            <QuestionNextButton {...this.props}
              isNextDisabled={isNextDisabled}
              isALRedesign
              studentAnswerRes={studentAnswerRes}
            />}
        </PaginationWrapper>
        {
          adaptationStatement &&
            <AdaptationWrapper
              isALRedesign
              className='active__learning-pagination
                adaptation bottom-fixed text-center'>
              <div className='adaptation-statement'>
                <MathJax
                  math={adaptationStatement}
                  datoCardType={datoCardType}
                />
              </div>
            </AdaptationWrapper>
        }
      </>
    )
  }
}

TextBlanksQuestion.contextType = Context
export default QuestionComponent(TextBlanksQuestion)
