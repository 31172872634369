import styled, { keyframes } from 'styled-components'
import mediaqueries from '../../../mediaQueries'

const translationRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(500px);
  }

  75% {
      transform: translateX(-20px);

  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`
const translationLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-500px);
  }

  75% {
      transform: translateX(20px);

  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`

export const InstructorBioText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5FC4B8;
  padding-bottom: 24px;
  cursor: pointer;
  margin-top: 24px;

  &:disabled {
    color: #b8fff7;
    opacity: 0.3;
  }
  &:not(:disabled) {
    &:active {
      color: #b8fff7;
      opacity: 0.3;
    }
    &:hover {
      color: #b8fff7;
    }
  }
`

export const BackdropModal = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${props => props.show ? 1 : 0};
  z-index: ${props => props.show ? 10 : -1};
  transition: all 0.5s ease-in-out;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const WrapperModal = styled.div`
  background-color: #161618;
  width: 500px;
  height: 500px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease-in-out;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${mediaqueries.mobile`width: 351px`}
`

export const ModalHeader = styled.div`
  height: 70px;
  background-color: #25272B;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
`

export const CloseIcon = styled.img`
  height: 12px;
  width: 12px;
  fill: white;
  cursor: pointer;
`

export const Title = styled.div`
  font-size: 18px;
  line-height: 22px;
`
export const ModalBody = styled.div`
  padding: 24px;
  overflow: hidden;
`

export const InstructorAvatar = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 60px;
`

export const InstructorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  animation: ${translationLeft} .5s ease-in-out;
  animation-fill-mode: both;
`
export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  animation: ${translationRight} .5s ease-in-out;
  animation-fill-mode: backwards;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const InstructorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
`

export const InstructorInfo = styled.div`
  margin-left: 12px;
`

export const InstructorName = styled.div`
  font-size: 18px !important;
  line-height: 22px !important;
  margin-bottom: 6px;
  color: #FFFFFF !important
`
export const InstructorTheme = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #B1BFC5;
  margin-bottom: 12px;
`

export const InstructorBio = styled.div`
  font-size: 14px!important;
  line-height: 17px!important;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5FC4B8!important;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:disabled {
    color: #5fc4b8;
    opacity: 0.3;
  }
  &:not(:disabled) {
    &:active {
      opacity: 0.3;
    }
    &:hover {
      filter: brightness(1.5);
    }
  }

  img {
    transform: rotate(180deg);
    margin-left: 12px;
    width: 6px!important;
    height: 12px!important;
  }
`

export const Back = styled.div`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5FC4B8;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:disabled {
    color: #5fc4b8;
    opacity: 0.3;
  }
  &:not(:disabled) {
    &:active {
      opacity: 0.3;
    }
    &:hover {
      filter: brightness(1.5);
    }
  }

  img {
    margin-right: 12px;
    width: 6px;
    height: 12px;
  }
`

export const InstructorHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;

  img {
    width: 120px;
    height: 120px;
    border-radius: 120px;
    margin-bottom: 24px;
  }
`
export const Bio = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 25px;
`

export const MoreInstructors = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 24px;
`

export const MoreInstructorsText = styled.div`
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #B1BFC5;
`

export const MoreInstructorItem = styled.div`
  display: flex;
  font-size: 18px;
  line-height: 22px;
  margin-top: 24px;

  img {
    width: 48px;
    height: 48px;
    border-radius: 48px;
  }
`
