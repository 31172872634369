import styled from 'styled-components'
import mediaqueries, { BREAKPOINTS } from '../../../mediaQueries'

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: ${({ isAssessmentQuizExam, isALRedesign }) => (isALRedesign ||
    isAssessmentQuizExam) ? 'flex-start' : 'center'};
  ${({ isALRedesign }) => isALRedesign && 'margin-top: 42px;'}
  flex-wrap: wrap;
  
  .btn {
    width: ${({ isAssessmentQuizExam, isALRedesign }) =>
    (isAssessmentQuizExam || isALRedesign) ? '64px' : '150px'};
    margin: 6px 8px !important;
    height: ${({ isAssessmentQuizExam, isALRedesign }) =>
    (isAssessmentQuizExam || isALRedesign) ? '40px' : '47px'};
    border: #5fc4b8 1px solid;
    padding: 0px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    ${mediaqueries.mobile`
      ${({ isALRedesign }) => !isALRedesign && `
        flex-grow: 1 !important;
        :first-child {
          width: 100%;
        }
      `}
    `}
  }
  ${({ isALRedesign }) => isALRedesign && `
    ${isALRedesign && `
      .btn-primary:disabled {
        background-color: #161618;
        border-color: #77b1af;
        color: #5FC4B8;
        opacity: 50%;
      }

      .btn-primary {
        margin: 6px 4px !important;
      }

      .btn-secondary {
        margin-left: 0px !important;
      }
    `}
  `}
  .btn-secondary:disabled {
    background-color: #161618;
    border-color: #77b1af;
    color: #77b1af;
  }
  ${({ isPreviousButton }) =>
    isPreviousButton &&
    `.btn:first-child {
        width: 64px !important;
        }`}

  @media (max-width: ${BREAKPOINTS.mobile - 1}px) {
    .btn {
      width: 100% !important;
    }

    ${({ isALRedesign }) => isALRedesign && `
      .btn {
        flex-grow: 1 !important;
        max-width: 157px !important;

        :first-child {
          margin-left: 0px !important;
          margin-right: 12px !important;
        }

        :nth-child(2) {
          margin-left: 0px !important;
          margin-right: 0px !important;
        }  
      }      
    `}
  }
`
export const QuestionIconWrapper = styled.div`
  display: inline-flex;
`
export const QuestionCard = styled.div`
  ${props => props.isAssessmentQuizExam && `
    background-color: rgba(0, 0, 0, 0);
    padding: 0px !important;
  `}
`

export const TextWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 48px;
  display: flex;
  height: 19px;
  justify-content: space-between;
  ${({ isALRedesign }) => isALRedesign && `
    height: 70px;
    background: #121212;
    border-radius: 5px 5px 0px 0px;
    margin-bottom: 1px;
    padding: 25px 24px;
    margin-top: 0px;
  `}
`
export const TextBlankWrapper = styled.div`
  ${({ isALRedesign }) => isALRedesign && `
    padding: 24px !important;
    background: #121212;
    margin-bottom: 0px;
    border-radius: 0px 0px 5px 5px;
  `}
`
export const LessonTextAL = styled.div` 
  ${({ isALRedesign }) => isALRedesign && `
    padding-bottom: 0px !important;
    h3 {
      font-style: normal;
      font-weight: 300;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 24px !important;
    }
    .paragraph {
      font-weight: 300 !important;
      font-size: 18px !important;
      line-height: 22px !important;
      margin-bottom: 24px !important;
    }
  `}     
`
export const LessonSmallTextAL = styled.div`
  ${({ isALRedesign }) => isALRedesign && `
    padding: 0px !important;
    font-weight: 300 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    .paragraph {
      margin-bottom: 24px;
    }
    .paragraph:last-child {
      margin-bottom: 0px !important;
    }
  `}
`
export const Icon = styled.img`
  width: inherit;
  height: inherit;
  vertical-align: middle;
  border-style: none;
  margin-bottom: 18px;
`
export const QuestionText = styled.span`
  line-height: 19.2px;
  font-size: 16px;
  margin-left: 12px;
  ${props => props.isALRedesign && `
    font-style: normal;
    font-weight: 400;
    text-align: center;
    line-height: 19px;
    margin-left: 0px;
    color: #FFFFFF;
  `}
`
export const ExplanationWrapper = styled.div`
  padding-top:0px !important;
`
export const AdaptationWrapper = styled.div`
  ${({ isALRedesign }) => isALRedesign && `
    text-align: left !important;
    .paragraph {
      margin-top: 42px !important;
      line-height: 17px !important;
    }
  `}
`
export const PinWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  ${props => props.isALRedesign && 'align-items: center;'}
  justify-content: flex-end;
`
export const PinText = styled.span`
  max-width: 93px;
  font-size: 16px;
  line-height: 19.2px;
  color: #98a4ae;
  text-align: right;
`
export const PinButtonWrapper = styled.span`
  margin-top: -2px;
`
export const PinButton = styled.img`
  margin-left: 12px;
  ${({ isALRedesign }) => !isALRedesign && 'margin-top: 3px;'}
  width: 14px;
  height: 14px;
`
