import React, { useContext } from 'react'
import StudentDataContex from '../Context/Context'
import { emitter } from '../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'
import { getProblemSetInfo } from '../../utilities/problemBankUtils'
import Exercises from './Exercises'
import {
  PageWrapper,
  ContentWrapper,
  BreadCrumb,
  SectionTitle,
  ExerciseTitle,
  NextButton,
  OverrideStyles
} from './styles'
import LoadingSpinner from '../LoadingSpinner/LoadingAnimation'

export default function PracticeExerciseIntro ({
  QuestionSetdata,
  breadCrumb,
  activeSectionUUID,
  isProblemBank,
  nextChildUUID,
  questionSet,
  problemBank: sectionProblemBank,
  currentProblemSetResponse,
  courseSections,
  shouldOverrideStyles
}) {
  const {
    intro_text: introText,
    currentProblemSetUUID,
    problemBank
  } = QuestionSetdata || {}

  const {
    isQuestionSetLoading,
    modalData: {
      isCompletionModalOpen,
      nextQuestionSetUUID
    } = {}
  } = useContext(StudentDataContex)

  function handleClick () {
    emitter.emit(ON_NAVIGATE_TO, `/${activeSectionUUID}/${nextChildUUID}`)
  }

  const { set = '', title = '' } = getProblemSetInfo(
    problemBank,
    currentProblemSetUUID
  ) || {}
  const breadCrumbName = breadCrumb?.split(' > ')[2]

  const fallBackIntroTxt = `
  The Practice Problem sets are here to help you hone your skills.
  The Pop Problem helps you revisit past problem types and 
  keep those skills fresh throughout the course.
  Correctly answer the Challenge Problem to prove you’ve 
  mastered that problem type.
  If you miss the Challenge Problem, you can start again!`

  return (
    <PageWrapper
      isQuestionSetLoading={isQuestionSetLoading}
      isCompletionModalOpen={isCompletionModalOpen}
      isTwoModalBtns={!!nextQuestionSetUUID}
    >
      {shouldOverrideStyles && <OverrideStyles />}
      <BreadCrumb
        data-testid='breadCrumb'
        onClick={() => emitter.emit(ON_NAVIGATE_TO, `/${activeSectionUUID}`)}
      >
        <span />{breadCrumbName}
      </BreadCrumb>
      {isQuestionSetLoading
        ? <LoadingSpinner />
        : (
          <ContentWrapper
            isProblemBank={isProblemBank}
          >
            {isProblemBank
              ? <Exercises
                questionSet={questionSet} //  current-question-set
                problemBank={sectionProblemBank}
                currentProblemSetResponse={currentProblemSetResponse}
                courseSections={courseSections}
              />
              : <>
                <SectionTitle data-testid='section-title'>{set}</SectionTitle>
                <ExerciseTitle data-testid='exercise-title'>{title}</ExerciseTitle>
                <p>{introText?.length ? introText : fallBackIntroTxt}</p>
                <NextButton onClick={handleClick} />
              </>}
          </ContentWrapper>
        )}
    </PageWrapper>
  )
}
