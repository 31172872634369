import React from 'react'
import DOMPurify from 'dompurify'
import { SubHeader } from '../styled'
import { getLearningOutcomes } from '../Utils'
import { LEARNING_OUTCOMES } from '../Utils/LEARNING_OUTCOMES'
import {
  LearningOutcomeItem,
  LearningOutcomesSubTitle,
  LearningOutcomesWrapper
} from './styled'

const LearningOutcomesSection = ({
  courseName, haveDynamicData, datoLearningOutcomes
}) => {
  const currentCourseLearningOutcomes = haveDynamicData
    ? getLearningOutcomes(datoLearningOutcomes)
    : LEARNING_OUTCOMES[courseName]
  if (!currentCourseLearningOutcomes?.length) return <></>

  const learningOutcomesMidPosition = Math.ceil(
    currentCourseLearningOutcomes.length / 2
  )

  return (
    <>
      <SubHeader>Learning Outcomes</SubHeader>
      <LearningOutcomesSubTitle>
        By the end of the course, you’ll be able to:
      </LearningOutcomesSubTitle>
      <LearningOutcomesWrapper>
        <ul>
          {currentCourseLearningOutcomes
            .slice(0, learningOutcomesMidPosition)
            .map(learningOutcome => (
              <LearningOutcomeItem
                key={learningOutcome}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(learningOutcome)
                }}
              />
            ))}
        </ul>
        <ul>
          {currentCourseLearningOutcomes
            .slice(learningOutcomesMidPosition)
            .map(learningOutcome => (
              <LearningOutcomeItem
                key={learningOutcome}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(learningOutcome)
                }}
              />
            ))}
        </ul>
      </LearningOutcomesWrapper>
    </>
  )
}

export default LearningOutcomesSection
