import styled from 'styled-components'
import media from '../../../mediaQueries'

export const InstructorNameWrapper = styled.p`
  text-align: center;
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0.4rem;
  ${media.tablet`
    text-align: left;
    margin-top: 21px;
  `}
`
export const LinkWrapper = styled.span`    
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: #5FC4B8;
  cursor: pointer;
  margin-left: 14px;
  &:disabled {
    color: #5fc4b8;
    opacity: 0.3;
  }
  &:not(:disabled) {
    &:active {
      color: #b8fff7;
      opacity: 0.3;
    }
    &:hover {
      color: #b8fff7;
    }
  }
  ${media.tablet`
    display:block;
    margin-left: 0px;
    margin-top: 12px;
  `}
`
export const Hr = styled.hr`
  display: none;
  ${media.tablet`
    margin-top: 1.2rem;
    display:block;
    width: 100%;
    background: #343a40;
  `}
`

export const Title = styled.span`
  font-weight: 300;
  font-size: 18px;
  margin-left: 9px;
  ${media.tablet`
    display: block;
    margin-left: 0px;
  `} 
`
