import { GUESSWORK, EXAM, QUIZ, ACTIVE_LEARNING } from '../Constants/sectionType'
import {
  ON_SET_LAST_GUESSWORK_UUID,
  ON_SET_LAST_EXAM_UUID,
  ON_SET_LAST_QUIZ_UUID,
  ON_SET_LAST_ACTIVE_LEARNING_UUID
} from '../Constants/emitterKeys'

export default sectionTypeToEmitterKey

function sectionTypeToEmitterKey (sectionType) {
  const MAPPING = {
    [GUESSWORK]: ON_SET_LAST_GUESSWORK_UUID,
    [EXAM]: ON_SET_LAST_EXAM_UUID,
    [QUIZ]: ON_SET_LAST_QUIZ_UUID,
    [ACTIVE_LEARNING]: ON_SET_LAST_ACTIVE_LEARNING_UUID
  }

  return MAPPING[sectionType]
}
