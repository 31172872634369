import React, { useState } from 'react'
import CheckIcon from './CheckIcon'
import CloseIcon from './CloseIcon'
import {
  ExtensionWrapper,
  ExtensionToastHeader,
  ExtensionToastHeaderData,
  ExtensionToastInfo,
  HeaderText
} from './styled'

function ExtensionToast () {
  const extensionToast = localStorage.getItem('extensionToast')
  const [showToast, setShowToast] = useState(extensionToast)

  const removeToast = () => {
    setShowToast(null)
    localStorage.removeItem('extensionToast')
  }

  if (!showToast || !extensionToast) return null
  const {
    assessmentTitle,
    courseTitle,
    extendedDeadline,
    timezone
  } = JSON.parse(extensionToast)
  return (
    <ExtensionWrapper data-testid='extension-toast'>
      <ExtensionToastHeader>
        <ExtensionToastHeaderData>
          <CheckIcon />
          <HeaderText>
            Deadline extended
          </HeaderText>
        </ExtensionToastHeaderData>
        <CloseIcon
          handleClick={removeToast}
        />
      </ExtensionToastHeader>
      <ExtensionToastInfo>
        The deadline for {courseTitle} {assessmentTitle} has been extended to {extendedDeadline} {timezone}.
      </ExtensionToastInfo>
    </ExtensionWrapper>
  )
}

export default ExtensionToast
