import React from 'react'
import './css/loading-animation.css'

export default function LoadingSpinner () {
  return (
    <div className='spinner'>
      <div className='bounce1' />
      <div className='bounce2' />
      <div className='bounce3' />
    </div>
  )
}
