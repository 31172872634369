import React, { Component } from 'react'
import QuizSectionHomepageButton from '../QuizSectionHomepageButton/QuizSectionHomepageButton'

import {
  ActivityWrapper,
  Left,
  Content,
  Title,
  Time,
  Right
} from './styles'

class QuizSectionListExcerciseItem extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showLockedTooltip: false
    }

    this.showLockedTooltip = this.showLockedTooltip.bind(this)
    this.hideLockedTooltip = this.hideLockedTooltip.bind(this)
  }

  showLockedTooltip () {
    this.setState({
      showLockedTooltip: true
    })
  }

  hideLockedTooltip () {
    this.setState({
      showLockedTooltip: false
    })
  }

  getToolTip () {
    if (!this.state.showLockedTooltip) return null

    if (!this.props.lockedTooltipText) return null

    return <span className='tooltiip'>{this.props.lockedTooltipText}</span>
  }

  render () {
    const {
      title,
      questionCount,
      isAuditor,
      isStudioCohort,
      sectionUuid,
      questionSetUuid,
      isCompleted,
      renderLockedStatus,
      score: propsScore,
      isUnlocked
    } = this.props
    let score = null

    if (isCompleted && !isAuditor && !isStudioCohort) {
      score = `Score: ${propsScore}%`
    }

    const questionSetLock = false
    const QUESTION_COUNT = `${questionCount} questions`

    return (
      <ActivityWrapper isLocked={questionSetLock} key={title}>
        <Left className='d-flex align-items-baseline'>
          <Content>
            <Title data-testid='quizTitle'>
              {title}
            </Title>
            <Time data-testid='questionProgress'>{score ?? QUESTION_COUNT}</Time>
          </Content>
        </Left>
        <Right>
          <QuizSectionHomepageButton
            sectionUuid={sectionUuid}
            questionSetUuid={questionSetUuid}
            isCompleted={isCompleted}
            isUnlocked={isUnlocked}
            showLockedTooltip={this.showLockedTooltip}
            hideLockedTooltip={this.hideLockedTooltip}
            renderLockedStatus={renderLockedStatus}
          />
        </Right>
      </ActivityWrapper>
    )
  }
}

export default QuizSectionListExcerciseItem
