import React from 'react'
import {
  getTimezoneShort,
  secondsToFormattedDateTimeShort,
  weeksToSeconds
} from '../../utilities/dateTimeUtils'
import { PreviewTextMessage } from './styles'

const GradePending = ({ lockTime }) => {
  const resultDate = secondsToFormattedDateTimeShort(
    lockTime + weeksToSeconds(2),
    'short'
  )
  const timezone = getTimezoneShort(lockTime)

  return (
    <div>
      <PreviewTextMessage>
        {`Hold tight! Grades are released on ${resultDate} ${timezone}.`}
      </PreviewTextMessage>
    </div>
  )
}

GradePending.displayName = 'GradePending'

export default GradePending
