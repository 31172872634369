import React, { Component } from 'react'
import Context from '../Context/Context'
import SectionListExcerciseItem from
  '../SectionListExcerciseItem/SectionListExcerciseItem'
import LecturesExerciseItem from '../LecturesExerciseItem/LecturesExerciseItem'
import ReadingsExerciseList from '../Readings/ReadingsExerciseList'
import {
  hasActiveLearningOpened,
  isOutlierOrVipAccount,
  getSectionExerciseLockText,
  getExerciseListArray
} from '../../utilities/sectionUtils'
import {
  secondsSinceEpoch
} from '../../utilities/dateTimeUtils'
import config from '../../config'
import CalculusLectureItem from '../CalculusLecturesExerciseItem/CalculusLectureItem'
import PracticeExerciseItem from '../PracticeExerciseItem/PracticeExerciseItem'
import SubsectionDrawer from '../SubsectionDrawer/SubsectionDrawer'
import {
  PROBLEM_BANK,
  QUIZ,
  PRACTICE_EXERCISES,
  DISCUSSION,
  PRACTICE_TERMS
} from '../../Constants/sectionType'
import { LAST_ACTIVE_LEARNING_UUID } from '../../Constants/studentContext'
import { isArray } from 'lodash'

class SectionExcerciseList extends Component {
  constructor (props) {
    super(props)
    this.getLockReason = this.getLockReason.bind(this)
    this.fetchExerciseList = this.fetchExerciseList.bind(this)
    this.state = {
      isLoading: true,
      exerciseListArr: [],
      lockReasons: undefined,
      currentDate: secondsSinceEpoch()
    }

    this._isMounted = false
  }

  hasSectionActiveLearning = () => {
    const { excerciseList } = this.props
    return !!excerciseList?.['active_learning']
  }

  getLockReason (exercise) {
    const isProblemBankOrQuiz = isArray(exercise)
    const type = isProblemBankOrQuiz ? exercise[0].type : exercise.type
    const {
      sectionUUID, finalExamLockDate, guessworkSectionUUID
    } = this.props
    const {
      studentData: { [LAST_ACTIVE_LEARNING_UUID]: activeLearningOpened },
      isAdmin,
      isStudioCohort,
      isVIPGradedContent
    } = this.context
    const isActiveLearningOpened =
    !this.hasSectionActiveLearning() ||
    hasActiveLearningOpened(activeLearningOpened, sectionUUID) ||
    config.course.unlockExercises ||
    isOutlierOrVipAccount({
      isAdmin,
      isStudioCohort,
      isVIPGradedContent
    })
    const activities = [PROBLEM_BANK, PRACTICE_EXERCISES, QUIZ, PRACTICE_TERMS, DISCUSSION]
    const isActivityLocked = !isActiveLearningOpened && activities.includes(type)
    if (isActivityLocked) return true
    const {
      studentData,
      isNoAssessments,
      isCohortEnded,
      isAuditor,
      isVIP } = this.context
    const { currentDate } = this.state
    return getSectionExerciseLockText(
      exercise,
      {
        sectionUUID,
        studentProgress: studentData,
        finalExamLockDate,
        currentDate,
        isCohortEnded,
        isAuditor,
        isStudioCohort,
        isAdmin,
        isVIP,
        isVIPGradedContent,
        guessworkSectionUUID,
        isNoAssessments
      }
    )
  }

  fetchExerciseList () {
    const { excerciseList: exerciseList } = this.props
    const {
      isCohortEndedForStudent
    } = this.context
    if (!exerciseList) return
    const exerciseListArr = getExerciseListArray(exerciseList)
    const lockReasons = exerciseListArr.map(
      (exercise) => this.getLockReason(exercise))
    if (!this._isMounted) return
    this.setState({
      exerciseListArr,
      lockReasons: isCohortEndedForStudent ? [] : lockReasons,
      isLoading: false
    })
  }

  componentDidMount () {
    this._isMounted = true
    this.fetchExerciseList()
  }

  componentDidUpdate (prevProps) {
    const {
      excerciseList: exerciseList,
      sectionUUID,
      finalExamLockDate
    } = this.props
    const {
      excerciseList: prevExerciseList,
      sectionUUID: prevSectionUUID,
      finalExamLockDate: prevFinalExamLockDate
    } = prevProps
    if (exerciseList === prevExerciseList &&
        sectionUUID === prevSectionUUID &&
        finalExamLockDate === prevFinalExamLockDate) return

    this.fetchExerciseList()
  }

  getComponentByType = exerciseType => {
    const {
      completeScreenData: { chapter: { type } }
    } = this.props
    if (exerciseType === 'lecture') {
      const { isCalculusCourse } = config
      const isCalculus = isCalculusCourse()

      if (isCalculus && type === 'chapter') return CalculusLectureItem
      return LecturesExerciseItem
    }
    if (exerciseType === 'readings') return ReadingsExerciseList
    if (exerciseType === 'practice') return PracticeExerciseItem
    if (exerciseType === 'subsection') return SubsectionDrawer
    return SectionListExcerciseItem
  }

  render () {
    const { exerciseListArr, lockReasons } = this.state
    const {
      studentData: { [LAST_ACTIVE_LEARNING_UUID]: activeLearningOpened },
      isAdmin,
      isStudioCohort,
      isVIPGradedContent
    } = this.context
    const {
      completeScreenData: { chapter: { type } },
      sectionUUID
    } = this.props
    const isActiveLearningOpened =
    !this.hasSectionActiveLearning() ||
    hasActiveLearningOpened(activeLearningOpened, sectionUUID) ||
    config.course.unlockExercises ||
    isOutlierOrVipAccount({
      isAdmin,
      isStudioCohort,
      isVIPGradedContent
    })

    if (!exerciseListArr.length) return <div id='loadingSections'>Loading...</div>
    const sectionItemsLengthIndex = exerciseListArr.length - 1
    return exerciseListArr.map((exercise, index) => {
      const isLastItem = (index === sectionItemsLengthIndex)
      const exerciseType = exercise?.type?.toLowerCase()
      const Comp = this.getComponentByType(exerciseType)
      return <Comp
        key={index}
        chapterType={type}
        excercise={exercise}
        lockReason={lockReasons[index]}
        isLastItem={isLastItem}
        isActiveLearningOpened={isActiveLearningOpened}
        {...this.props}
      />
    })
  }
}

SectionExcerciseList.displayName = 'SectionExcerciseList'
SectionExcerciseList.contextType = Context
export default SectionExcerciseList
