const grades = {
  '317eaeaa': [{ grade: 'C', courseName: 'Calculus I' }],
  79968171: [{ grade: 'C', courseName: 'Calculus I' }],
  '81b14f88': [{ grade: 'F', courseName: 'Calculus I' }],
  '780c7ec3': [{ grade: 'C+', courseName: 'Calculus I' }],
  32620919: [{ grade: 'F', courseName: 'Calculus I' }],
  b8200ce8: [{ grade: 'F', courseName: 'Calculus I' }],
  '86755c43': [{ grade: 'D', courseName: 'Calculus I' }],
  sckesv9xc800010as6b4mufg5g: [{ grade: 'A', courseName: 'Calculus I' }],
  scker575wy00040as6coc03i1r: [{ grade: 'A', courseName: 'Calculus I' }],
  scketfj1k800040as6gpuc7de0: [{ grade: 'F', courseName: 'Calculus I' }],
  sckc0lggva00tc4goecyy28qc2: [{ grade: 'D', courseName: 'Calculus I' }],
  sckebnmxwu00000as67bd67izi: [{ grade: 'A', courseName: 'Calculus I' }],
  scket4kr2c00000as685mxddwp: [{ grade: 'C', courseName: 'Calculus I' }],
  sckesvvmo100020as62ni69lfc: [{ grade: 'F', courseName: 'Calculus I' }],
  sckekholvl00010bs6ahfuhhxr: [{ grade: 'A-', courseName: 'Calculus I' }],
  sckerzjur300050as6f8fl3jxw: [{ grade: 'B', courseName: 'Calculus I' }],
  '0d4e9c42': [{ grade: 'B-', courseName: 'Calculus I' }],
  '5f5bd923': [{ grade: 'B+', courseName: 'Calculus I' }],
  '07a99537': [{ grade: 'A', courseName: 'Calculus I' }],
  '68c7de3d': [{ grade: 'B', courseName: 'Calculus I' }],
  '63f664ae': [{ grade: 'B', courseName: 'Calculus I' }],
  '4243e9cd': [{ grade: 'C+', courseName: 'Calculus I' }],
  '6d666c77': [{ grade: 'F', courseName: 'Calculus I' }],
  e5f6b4df: [{ grade: 'B+', courseName: 'Calculus I' }],
  a8bd7626: [{ grade: 'F', courseName: 'Calculus I' }],
  '62a7094b': [{ grade: 'D', courseName: 'Calculus I' }],
  '724d7525': [{ grade: 'F', courseName: 'Calculus I' }],
  dc87baf4: [{ grade: 'A-', courseName: 'Calculus I' }],
  '327d6f35': [{ grade: 'F', courseName: 'Calculus I' }],
  '5542a37a': [{ grade: 'F', courseName: 'Calculus I' }],

  '9d59fd2a': [{ grade: 'A', courseName: 'Calculus I' }],
  a0202f9b: [{ grade: 'A', courseName: 'Calculus I' }],
  '2751957d': [{ grade: 'A', courseName: 'Calculus I' }],
  '8a905a66': [{ grade: 'A', courseName: 'Calculus I' }],
  '433e5010': [{ grade: 'A', courseName: 'Calculus I' }],
  '9475649c': [{ grade: 'A-', courseName: 'Calculus I' }],
  ad559763: [{ grade: 'A-', courseName: 'Calculus I' }],
  '3fa44d3c': [{ grade: 'B', courseName: 'Calculus I' }],
  '3e6af98c': [{ grade: 'B', courseName: 'Calculus I' }],
  '1d07be59': [{ grade: 'B', courseName: 'Calculus I' }],
  f0dccd67: [{ grade: 'B-', courseName: 'Calculus I' }],
  '70c6ab75': [{ grade: 'B+', courseName: 'Calculus I' }],
  '0251cfe6': [{ grade: 'B+', courseName: 'Calculus I' }],
  be7a483d: [{ grade: 'B+', courseName: 'Calculus I' }],
  ed040ec6: [{ grade: 'C', courseName: 'Calculus I' }],
  '8d9a2c18': [{ grade: 'C-', courseName: 'Calculus I' }],
  '044d11ec': [{ grade: 'C-', courseName: 'Calculus I' }],
  b2c7f780: [{ grade: 'C+', courseName: 'Calculus I' }],
  a67ced97: [{ grade: 'C+', courseName: 'Calculus I' }],
  e89f8e26: [{ grade: 'D', courseName: 'Calculus I' }],
  bb2e4ff9: [{ grade: 'F', courseName: 'Calculus I' }],
  '98b81eac': [{ grade: 'F', courseName: 'Calculus I' }],
  '2816b7b3': [{ grade: 'F', courseName: 'Calculus I' }],
  c3078682: [{ grade: 'F', courseName: 'Calculus I' }],
  e75a538c: [{ grade: 'F', courseName: 'Calculus I' }],
  '66dafb25': [{ grade: 'F', courseName: 'Calculus I' }],
  '255eaede': [{ grade: 'F', courseName: 'Calculus I' }],
  32188883: [{ grade: 'F', courseName: 'Calculus I' }],
  '49baba29': [{ grade: 'F', courseName: 'Calculus I' }],
  '6003848e': [{ grade: 'F', courseName: 'Calculus I' }],
  '1ebf4f00': [{ grade: 'F', courseName: 'Calculus I' }],
  e95626de: [{ grade: 'F', courseName: 'Calculus I' }],
  c543ec5c: [{ grade: 'F', courseName: 'Calculus I' }],
  '3d7cb3b0': [{ grade: 'F', courseName: 'Calculus I' }],
  '181534d9': [{ grade: 'F', courseName: 'Calculus I' }],
  '92d768d6': [{ grade: 'F', courseName: 'Calculus I' }],
  b36484d8: [{ grade: 'C', courseName: 'Calculus I' }],
  e09b2dfb: [{ grade: 'A', courseName: 'Calculus I' }],
  ea8082b9: [{ grade: 'F', courseName: 'Calculus I' }],
  '1a69d348': [{ grade: 'F', courseName: 'Calculus I' }],
  def4de5f: [{ grade: 'F', courseName: 'Calculus I' }],
  d516899c: [{ grade: 'A', courseName: 'Calculus I' }],
  cbd24ad7: [{ grade: 'C+', courseName: 'Calculus I' }],
  e6d71aa4: [{ grade: 'B', courseName: 'Calculus I' }],
  '803c46f3': [{ grade: 'Audit', courseName: 'Calculus I' }],
  '637758ad': [{ grade: 'B', courseName: 'Calculus I' }],
  '67aa342f': [{ grade: 'A-', courseName: 'Calculus I' }],
  '47f6653d': [{ grade: 'F', courseName: 'Calculus I' }],
  '6116bc1c': [{ grade: 'F', courseName: 'Calculus I' }],
  ba8830a5: [{ grade: 'F', courseName: 'Calculus I' }],
  '395505da': [{ grade: 'F', courseName: 'Calculus I' }],
  '4c432a05': [{ grade: 'F', courseName: 'Calculus I' }],
  '01bc41a9': [{ grade: 'F', courseName: 'Calculus I' }],
  e99f4f1c: [{ grade: 'F', courseName: 'Calculus I' }],
  e72fde7b: [{ grade: 'C', courseName: 'Calculus I' }],
  '1f2b07a7': [{ grade: 'F', courseName: 'Calculus I' }],
  '6eca9394': [{ grade: 'F', courseName: 'Calculus I' }],
  d7be1fba: [{ grade: 'D', courseName: 'Calculus I' }],
  f09d7ad4: [{ grade: 'F', courseName: 'Calculus I' }],
  '1cf52dc6': [{ grade: 'F', courseName: 'Calculus I' }],
  '324df140': [{ grade: 'F', courseName: 'Calculus I' }],
  '587060bc': [{ grade: 'F', courseName: 'Calculus I' }],
  '93e5526b': [{ grade: 'F', courseName: 'Calculus I' }],
  '3e1ccea4': [{ grade: 'F', courseName: 'Calculus I' }],
  '0036fabe': [{ grade: 'F', courseName: 'Calculus I' }],
  '06ce4925': [{ grade: 'A', courseName: 'Calculus I' }],
  '8758d1c9': [{ grade: 'F', courseName: 'Calculus I' }],
  '142ecdd2': [{ grade: 'F', courseName: 'Calculus I' }],
  '8c673bfb': [{ grade: 'B+', courseName: 'Calculus I' }],
  '5b64cfab': [{ grade: 'F', courseName: 'Calculus I' }],
  e84114b5: [{ grade: 'F', courseName: 'Calculus I' }],
  '161425d2': [{ grade: 'B', courseName: 'Calculus I' }],
  '1b1a4cc9': [{ grade: 'D', courseName: 'Calculus I' }],
  '60a1b2a5': [{ grade: 'F', courseName: 'Calculus I' }],
  f5a388ba: [{ grade: 'F', courseName: 'Calculus I' }],
  '6cd14972': [{ grade: 'A', courseName: 'Calculus I' }],
  e86751eb: [{ grade: 'D', courseName: 'Calculus I' }],
  31551163: [{ grade: 'F', courseName: 'Calculus I' }],
  '03bf6b99': [{ grade: 'F', courseName: 'Calculus I' }],
  '04fbda5b': [{ grade: 'F', courseName: 'Calculus I' }],
  '5b9c142e': [{ grade: 'F', courseName: 'Calculus I' }],
  '290d7d22': [{ grade: 'F', courseName: 'Calculus I' }],
  '71a82c35': [{ grade: 'F', courseName: 'Calculus I' }],
  '5a38105d': [{ grade: 'F', courseName: 'Calculus I' }],
  '68a2b3b6': [{ grade: 'D', courseName: 'Calculus I' }],
  '02cd6f18': [{ grade: 'C', courseName: 'Calculus I' }],
  '5b0bc8c2': [{ grade: 'A', courseName: 'Calculus I' }],
  a7a9f7b5: [{ grade: 'A', courseName: 'Calculus I' }],
  d6e3a1e4: [{ grade: 'C', courseName: 'Calculus I' }],
  '843cc9f2': [{ grade: 'A', courseName: 'Calculus I' }],
  '7a3fd214': [{ grade: 'B', courseName: 'Calculus I' }],
  '225ee613': [{ grade: 'A', courseName: 'Calculus I' }],
  b05733e5: [{ grade: 'F', courseName: 'Calculus I' }],
  c3e955ea: [{ grade: 'F', courseName: 'Calculus I' }],
  '52fd5488': [{ grade: 'B', courseName: 'Calculus I' }],
  '9151d10d': [{ grade: 'F', courseName: 'Calculus I' }],
  f995d415: [{ grade: 'F', courseName: 'Calculus I' }],
  '8116b4f5': [{ grade: 'F', courseName: 'Calculus I' }],
  '3b456e7e': [{ grade: 'C', courseName: 'Calculus I' }],
  '080842ab': [{ grade: 'C+', courseName: 'Calculus I' }],
  '2458dc43': [{ grade: 'A', courseName: 'Calculus I' }],
  '3a7b0b2e': [{ grade: 'F', courseName: 'Calculus I' }],
  '2f52ac5e': [{ grade: 'F', courseName: 'Calculus I' }],
  f8f6409e: [{ grade: 'F', courseName: 'Calculus I' }],
  '885c9763': [{ grade: 'F', courseName: 'Calculus I' }],
  '7bacdc93': [{ grade: 'C+', courseName: 'Calculus I' }],
  '12e5db24': [{ grade: 'A-', courseName: 'Calculus I' }],
  '3947868f': [{ grade: 'A-', courseName: 'Calculus I' }],
  c9cf7d87: [{ grade: 'F', courseName: 'Calculus I' }],
  a9841ac4: [{ grade: 'A', courseName: 'Calculus I' }],
  b3344089: [{ grade: 'B+', courseName: 'Calculus I' }],
  fd79a37c: [{ grade: 'A', courseName: 'Calculus I' }],
  e2c0b291: [{ grade: 'F', courseName: 'Calculus I' }],
  af6b23c1: [{ grade: 'F', courseName: 'Calculus I' }],
  '22d41068': [{ grade: 'F', courseName: 'Calculus I' }],
  c50055d1: [{ grade: 'F', courseName: 'Calculus I' }],
  d99795a4: [{ grade: 'F', courseName: 'Calculus I' }],
  '705bcf05': [{ grade: 'F', courseName: 'Calculus I' }],
  f66128ae: [{ grade: 'B+', courseName: 'Calculus I' }],
  '5b5fb424': [{ grade: 'F', courseName: 'Calculus I' }],
  '24a45d49': [{ grade: 'F', courseName: 'Calculus I' }],
  '84e51ee0': [{ grade: 'C+', courseName: 'Calculus I' }],
  '8ad5ee42': [{ grade: 'F', courseName: 'Calculus I' }],

  // Calculus 11/4/20 - 14 weeks
  f7a39125: [{ grade: 'B', courseName: 'Calculus I' }],
  e895d290: [{ grade: 'B+', courseName: 'Calculus I' }],
  '7f036f77': [{ grade: 'F', courseName: 'Calculus I' }],
  '316c15fc': [{ grade: 'B', courseName: 'Calculus I' }],
  '3161b9f1': [{ grade: 'F', courseName: 'Calculus I' }],
  '2be8f4c2': [{ grade: 'F', courseName: 'Calculus I' }],
  '2aada31d': [{ grade: 'F', courseName: 'Calculus I' }],
  '3845fc7b': [{ grade: 'A-', courseName: 'Calculus I' }],
  '7b1afd68': [{ grade: 'D', courseName: 'Calculus I' }],
  91631886: [{ grade: 'B', courseName: 'Calculus I' }, { grade: 'C', courseName: 'Introduction to Psychology' }],
  '3106a40e': [{ grade: 'B-', courseName: 'Calculus I' }],
  '7934d7ce': [{ grade: 'A', courseName: 'Calculus I' }],
  '86b60c0a': [{ grade: 'F', courseName: 'Calculus I' }],
  f15c9485: [{ grade: 'F', courseName: 'Calculus I' }],
  '6c207b63': [{ grade: 'F', courseName: 'Calculus I' }],
  '9a06b77c': [{ grade: 'A-', courseName: 'Calculus I' }],
  e2b44482: [{ grade: 'B', courseName: 'Calculus I' }],
  '4efeec38': [{ grade: 'F', courseName: 'Calculus I' }],
  ea8c0b73: [{ grade: 'F', courseName: 'Calculus I' }],
  bbf2ce36: [{ grade: 'F', courseName: 'Calculus I' }],
  '5bab588d': [{ grade: 'F', courseName: 'Calculus I' }],
  '570e6446': [{ grade: 'F', courseName: 'Calculus I' }],
  f4301bdd: [{ grade: 'A', courseName: 'Calculus I' }],

  // Calculus 1/6/21 - 7 weeks
  a5511dfc: [{ grade: 'B', courseName: 'Calculus I' }],
  a47c6e66: [{ grade: 'B+', courseName: 'Calculus I' }],
  '406627e7': [{ grade: 'A', courseName: 'Calculus I' }],
  '1187a1c7': [{ grade: 'F', courseName: 'Calculus I' }],
  '0297f1ff': [{ grade: 'D', courseName: 'Calculus I' }],
  '5e8398bd': [{ grade: 'B-', courseName: 'Calculus I' }],
  '82de3e6c': [{ grade: 'C-', courseName: 'Calculus I' }],

  fe979a5e: [{ grade: 'A', courseName: 'Introduction to Psychology' }],
  '3cf15024': [{ grade: 'A', courseName: 'Introduction to Psychology' }],
  feef32c3: [{ grade: 'A', courseName: 'Introduction to Psychology' }],
  d1aa7a28: [{ grade: 'A', courseName: 'Introduction to Psychology' }],
  f73ef940: [{ grade: 'A', courseName: 'Introduction to Psychology' }],
  b47ae379: [{ grade: 'A', courseName: 'Introduction to Psychology' }],
  '89981db2': [{ grade: 'A', courseName: 'Introduction to Psychology' }],
  '2efc76a6': [{ grade: 'A', courseName: 'Introduction to Psychology' }],
  e4eb67b0: [{ grade: 'A-', courseName: 'Introduction to Psychology' }],
  '82655c86': [{ grade: 'A-', courseName: 'Introduction to Psychology' }],
  '3daa744f': [{ grade: 'A-', courseName: 'Introduction to Psychology' }],
  '6c9eb279': [{ grade: 'A-', courseName: 'Introduction to Psychology' }],
  a2125cd7: [{ grade: 'A-', courseName: 'Introduction to Psychology' }],
  '612c63c1': [{ grade: 'B', courseName: 'Introduction to Psychology' }],
  '003715f7': [{ grade: 'B', courseName: 'Introduction to Psychology' }],
  '2cad6196': [{ grade: 'B', courseName: 'Introduction to Psychology' }],
  '98b8cbf9': [{ grade: 'B', courseName: 'Introduction to Psychology' }],
  daebdf39: [{ grade: 'B-', courseName: 'Introduction to Psychology' }],
  '72828de7': [{ grade: 'B+', courseName: 'Introduction to Psychology' }, { grade: 'F', courseName: 'Calculus I' }],
  '6e068d56': [{ grade: 'B+', courseName: 'Introduction to Psychology' }],
  b91a4962: [{ grade: 'B+', courseName: 'Introduction to Psychology' }],
  '7255d6b0': [{ grade: 'B+', courseName: 'Introduction to Psychology' }],
  '19b6474e': [{ grade: 'C', courseName: 'Introduction to Psychology' }],
  '8a0572b6': [{ grade: 'C', courseName: 'Introduction to Psychology' }],
  e86acaaa: [{ grade: 'C', courseName: 'Introduction to Psychology' }],
  ccfd2314: [{ grade: 'C-', courseName: 'Introduction to Psychology' }, { grade: 'Withdraw', courseName: 'Calculus I' }],
  '10e560ac': [{ grade: 'C-', courseName: 'Introduction to Psychology' }],
  f0f3cbe3: [{ grade: 'C-', courseName: 'Introduction to Psychology' }],
  '6591d145': [{ grade: 'C+', courseName: 'Introduction to Psychology' }],
  '9c9db63b': [{ grade: 'D', courseName: 'Introduction to Psychology' }],
  f664da03: [{ grade: 'F', courseName: 'Introduction to Psychology' }, { grade: 'Withdraw', courseName: 'Calculus I' }],
  '95b4d616': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '38a62c22': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '71e2abe9': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  ec0c3a51: [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '9d86f1e6': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '5652385f': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  f07b5b7d: [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '2fdc9ff3': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '0e727ab4': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '56545a3b': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '0aa03a81': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '6053024e': [{ grade: 'Withdraw', courseName: 'Introduction to Psychology' }],
  '44b4d8f8': [{ grade: 'C', courseName: 'Introduction to Psychology' }],
  '1336f228': [{ grade: 'A-', courseName: 'Introduction to Psychology' }],
  '1e28903c': [{ grade: 'A-', courseName: 'Introduction to Psychology' }, { grade: 'C', courseName: 'Calculus I' }],
  '133ce62c': [{ grade: 'D', courseName: 'Introduction to Psychology' }],
  '9a5f82c1': [{ grade: 'A-', courseName: 'Introduction to Psychology' }],
  c07b3dbd: [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  e0fc4bad: [{ grade: 'C', courseName: 'Introduction to Psychology' }],
  '89b24efc': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '83a817c0': [{ grade: 'B', courseName: 'Introduction to Psychology' }],
  a8636a33: [{ grade: 'B+', courseName: 'Introduction to Psychology' }],
  '4e64d876': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '7f32014b': [{ grade: 'D', courseName: 'Introduction to Psychology' }],
  b10ab970: [{ grade: 'D', courseName: 'Introduction to Psychology' }],
  '504630ab': [{ grade: 'A', courseName: 'Introduction to Psychology' }],
  '5664946c': [{ grade: 'B', courseName: 'Introduction to Psychology' }],
  '179d2916': [{ grade: 'A', courseName: 'Introduction to Psychology' }],
  '7001ea7c': [{ grade: 'B', courseName: 'Introduction to Psychology' }],
  '7d0c1d5c': [{ grade: 'A-', courseName: 'Introduction to Psychology' }],
  ca9da04d: [{ grade: 'A', courseName: 'Introduction to Psychology' }],
  '22abd90d': [{ grade: 'C-', courseName: 'Introduction to Psychology' }],
  '6ef7fc22': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '0b95a9ea': [{ grade: 'C', courseName: 'Introduction to Psychology' }],
  ba9c6592: [{ grade: 'A', courseName: 'Introduction to Psychology' }],
  f4140660: [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  cc71fafa: [{ grade: 'A', courseName: 'Introduction to Psychology' }],
  f1561ac8: [{ grade: 'A-', courseName: 'Introduction to Psychology' }],
  '4f5c2149': [{ grade: 'C-', courseName: 'Introduction to Psychology' }],
  '391e0756': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '70ca1b39': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '6e0aa26a': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  f17981ad: [{ grade: 'A-', courseName: 'Introduction to Psychology' }],
  fc5a4c72: [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '1fe6399b': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '52262bc9': [{ grade: 'B-', courseName: 'Introduction to Psychology' }],
  c5f73e1c: [{ grade: 'C+', courseName: 'Introduction to Psychology' }],
  e92355dc: [{ grade: 'A', courseName: 'Introduction to Psychology' }],
  e192fa04: [{ grade: 'A-', courseName: 'Introduction to Psychology' }],
  '955e1eda': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '4c588285': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  fb326b1f: [{ grade: 'B+', courseName: 'Introduction to Psychology' }],
  '811f5836': [{ grade: 'B+', courseName: 'Introduction to Psychology' }],

  // Psychology 11/4/20 - 14 weeks
  '153606e3': [{ grade: 'C-', courseName: 'Introduction to Psychology' }],
  '7cd10658': [{ grade: 'A', courseName: 'Introduction to Psychology' }],
  '2df567ca': [{ grade: 'C+', courseName: 'Introduction to Psychology' }],
  '70a47cbb': [{ grade: 'A-', courseName: 'Introduction to Psychology' }],
  '7f155b55': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  c2563abd: [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '9ac43213': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '00894120': [{ grade: 'F', courseName: 'Introduction to Psychology' }],
  '532029b7': [{ grade: 'F', courseName: 'Introduction to Psychology' }],

  f632cf09: [{ grade: 'B+', courseName: 'Introduction to Psychology' }],
  d88641ce: [{ grade: 'D', courseName: 'Introduction to Psychology' }],
  '5e9812c2': [{ grade: 'A', courseName: 'Introduction to Psychology' }],

  ckgb58w1300rt0bs698mbf934: [{ grade: 'B', courseName: 'Introduction to Psychology' }],
  ckgb58w1400u80bs627zg2xzf: [{ grade: 'B-', courseName: 'Introduction to Psychology' }],
  ckgb58w1100p30bs632ni79qk: [{ grade: 'B', courseName: 'Introduction to Psychology' }],
  ckgb58w1800zm0bs687qt0cyu: [{ grade: 'B+', courseName: 'Introduction to Psychology' }],
  ckgb58w1400tv0bs69rzw7ldp: [{ grade: 'A', courseName: 'Introduction to Psychology' }]
}

export default grades

// private util to merge the grade object
// when we need to add new grades, please add them at the bottom
// because the grade with same course will be removed as the logic of this util
/* const mergeGrade = (courseName, gradeObj) => {
  Object.entries(gradeObj).forEach(([id, grade]) => {
    const arr = (grades[id] || []).filter(item => item.courseName !== courseName)
    arr.push({ grade, courseName })
    grades[id] = arr
  })
} */

// psychology data from Kenny on 21st August 2020

// calculus data from Kenny on 21st August 2020

// psychology data from Kenny on 01st July 2020
/* mergeGrade('Introduction to Psychology', {
  '89dc93ee': 'A',
  '380715c6': 'A',
  '8faf56d1': 'A',
  '171b7ce9': 'A',
  '57b3792a': 'A',
  cce550f6: 'A',
  '4f4f35f8': 'A',
  eb4b8cb8: 'A',
  '8675422e': 'A',
  cab0b50c: 'A',
  b5ebc2f2: 'A',
  b0ba0cb6: 'A-',
  '485b4112': 'A-',
  '4b8f2d31': 'A-',
  '1c6925fb': 'B',
  ed0cf268: 'B',
  a21e1050: 'B',
  53921808: 'B',
  '46251d8d': 'B',
  '571b9cb5': 'B-',
  '1a94b93e': 'B-',
  c86e2817: 'B+',
  c2669282: 'B+',
  '7504e0fd': 'B+',
  a792ee3e: 'B+',
  f5b343d8: 'B+',
  '3e39ef1f': 'C-',
  b0ebc38b: 'C+',
  '7eb8da01': 'F',
  83589998: 'F',
  '4d10c5c2': 'W',
  '5717c739': 'W',
  ef3f734f: 'W',
  '9938049b': 'W',
  bbdab6a1: 'W',
  c47fdc3d: 'W',
  '66f05d16': 'W'
}) */

// calculus data from Kenny on 01st July 2020
/* mergeGrade('Calculus I', {
  cacc0e1e: 'A',
  '475a22e2': 'A',
  c7f5c5d8: 'A',
  a1649eba: 'A',
  '9c58261c': 'A',
  '2c3a1e05': 'A',
  f37769b8: 'A',
  '16549d8b': 'A',
  '616c5a67': 'A',
  a5dadc0f: 'A',
  '0ffe6106': 'A',
  '334bfde5': 'A',
  '20305ae1': 'A',
  '6a2be0bf': 'A-',
  ad0c53f5: 'A-',
  '552416d7': 'A-',
  bd5cfeef: 'A-',
  '74414fe7': 'A-',
  '75deaa2f': 'A-',
  '94f00731': 'A-',
  '821ba2d6': 'B',
  '0367b5b5': 'B',
  fc0b04a5: 'B',
  '5f4870f0': 'B',
  '535938cb': 'B',
  '86129e4b': 'B',
  '6df52087': 'B',
  b0ba0cb6: 'B-',
  c86e2817: 'B-',
  dff9de3d: 'B-',
  '6b271bbe': 'B-',
  '28d94d52': 'B-',
  be22b5c4: 'B-',
  a6e88fe1: 'B-',
  '198fc78d': 'B-',
  '2555519f': 'B-',
  '35bc866c': 'B+',
  '044fe0b1': 'B+',
  c9628a30: 'B+',
  76791957: 'B+',
  '1d5f76c7': 'B+',
  '7df585dd': 'B+',
  d72e64b9: 'C',
  '256da9ba': 'C-',
  '901c4aba': 'C-',
  '90ef9a2f': 'C+',
  '68d457e8': 'C+',
  '404d1a77': 'D',
  eeea2195: 'D',
  '28a10725': 'F',
  f995d415: 'F',
  d167e98c: 'F',
  '8805d563': 'F',
  c929bffe: 'F',
  b46f70f1: 'F',
  '3e286ca4': 'F',
  '55aefd36': 'F',
  '6ece9c13': 'F',
  '5e51aa56': 'F',
  e7597252: 'F',
  f45a4ab1: 'F',
  d14bf288: 'F',
  '530a8f8d': 'F',
  e63ad8a8: 'F',
  a49a0160: 'F',
  fb498579: 'F',
  '30b7aadb': 'F',
  e9a7e79e: 'F',
  d660ddf6: 'F',
  a57ec169: 'F',
  '6d10eedd': 'F',
  '32991a19': 'F',
  cc7af0cd: 'F',
  '934dd25d': 'W',
  f70f8bad: 'W',
  '7c6ca2d8': 'W',
  '85774a22': 'W',
  '9dfd8a26': 'W',
  '1acd496f': 'W',
  '05c215c3': 'W',
  '4d6e790a': 'W',
  e46669c0: 'W',
  '6700a4f5': 'W',
  c643a043: 'W',
  b9d15dec: 'W',
  a7a236af: 'W',
  '7c641e9f': 'W',
  '21c3fcf0': 'W',
  f76a7871: 'W',
  '33a6d534': 'W',
  '9fcb23dc': 'W',
  eb546b61: 'W',
  '65bc96b8': 'W',
  e56d44c2: 'W',
  df1d75e5: 'W',
  cc1543d4: 'W',
  '3ee2eb78': 'W',
  b3e13abc: 'W',
  '5333f87a': 'W',
  '7fc24056': 'W',
  '60ea41c6': 'W'
}) */
