import React from 'react'
import './css/no-course-component.css'
import { COURSE_NOT_OPEN, FAILURE, NO_ACTIVE_COURSE, NOT_ENROLLED, UNDER_13 } from './../../Constants/noCourseType'

const NotEnrolledComponent = () => (
  <span className='py-2 my-0 col no-course-text'>
    Looks like you're not enrolled in this course. You can visit <a href='https://outlier.org'>Outlier.org</a> to sign up for a class.
    <br /><br />
    If you believe you should have access, please double check that you're signing up with the same email address you used to purchase the course, or email us at <a href='mailto:success@outlier.org'>success@outlier.org</a> if there has been an error.
  </span>
)

const FailureComponent = () => (
  <span className='py-2 my-0 col' style={{ fontSize: '18px' }}>
    Something went wrong! Unable to access the site data
  </span>
)

const NoActiveCourseComponent = () => (
  <p className='py-2 my-0 col' style={{ fontSize: '18px' }}>
    You do not have an active course
  </p>
)

const StudentUnder13 = () => (
  <span className='py-2 my-0 col' style={{ fontSize: '18px' }}>
    Our records show that you are under 13, which is below the minimum age required to access our site.
    Our student success team will be reaching out to you soon regarding next steps. If you think you are
    receiving this message by mistake, please reach out to <a href='mailto:success@outlier.org'>success@outlier.org</a>
  </span>
)

const CourseNotOpen = ({ courseStartDate }) => (
  <span className='py-2 my-0 col' style={{ fontSize: '18px' }}>
    This course will open on <strong>{courseStartDate}</strong>. Check back then
    to get started on your course work!
  </span>
)

function renderComponent (noCourseType, courseStartDate) {
  switch (noCourseType) {
    case NOT_ENROLLED:
      return <NotEnrolledComponent />
    case FAILURE:
      return <FailureComponent />
    case NO_ACTIVE_COURSE:
      return <NoActiveCourseComponent />
    case UNDER_13:
      return <StudentUnder13 />
    case COURSE_NOT_OPEN:
      return <CourseNotOpen courseStartDate={courseStartDate} />
    default:
      throw new Error('Invalid NoCourseComponent')
  }
}

function NoCourseComponent (props) {
  return (
    <div className='container'>
      <div className='noCourse'>
        <div className='row text-center '>
          { renderComponent(props.noCourseType, props?.courseStartDate) }
        </div>
      </div>
    </div>
  )
}

export default NoCourseComponent
