import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Toast from '../../Toast'
import {
  getSessionsArray, getRecentPastSessions, checkIfBlocksMissed
} from '../../../utilities/studyBlocks'
import { STUDY_BLOCKS_URL } from '../../../Constants'

function EditStudyBlocks ({ studyBlocks, metadata }) {
  const [show, setShow] = useState(false)
  const remove = () => setShow(false)

  useEffect(() => {
    const savedStudyBlocks = getSessionsArray(studyBlocks)
    const recentPast3Blocks = getRecentPastSessions(savedStudyBlocks, 3)
    const isRecentBlocksMissed = checkIfBlocksMissed(recentPast3Blocks, metadata)

    const shouldShowWarning = isRecentBlocksMissed && recentPast3Blocks?.length === 3
    setShow(shouldShowWarning)
  }, [studyBlocks, metadata])

  const title = 'Adjust your study blocks'
  const toastWrapperStyle = { margin: '88px 64px 0', width: '448px' }
  const getBody = () => (
    <p>
      Looks like you missed three study blocks. That’s alright! You can{' '}
      <a
        href={STUDY_BLOCKS_URL}
        rel='noopener noreferrer'
        onClick={remove}
      >
        adjust your study blocks
      </a>{' '}
      to a time that better suits your schedule.
    </p>
  )

  return (
    <Toast
      show={show}
      remove={remove}
      title={title}
      getBody={getBody}
      headerIcon='images/icons/open-book.svg'
      toastWrapperStyle={toastWrapperStyle}
    />
  )
}

EditStudyBlocks.propTypes = {
  studyBlocks: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired
}

EditStudyBlocks.displayName = 'EditStudyBlocks'
export default EditStudyBlocks
