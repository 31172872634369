import styled from 'styled-components'
import media from '../../../mediaQueries'

export const DeadlineWarning = styled.div`
  grid-row: 2/3;
  grid-column: 1/3;
  background-color: ${(({ show24HrDeadlineWarning }) =>
    show24HrDeadlineWarning ? '#351915' : '#342215')};
  padding: 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  ${media.mobile`
    grid-column: 1/2;
  `}
`

export const DeadlineMessageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.mobile`
    flex-direction: column;
    align-items: flex-start;
  `}
`

export const DeadlineMessage = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  ${media.mobile`
    margin-bottom: 8px;
  `}
`

export const DeadlineExtendedMessage = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${(({ show24HrDeadlineWarning }) =>
    show24HrDeadlineWarning ? '#F2765A' : '#FFB800')};
`

export const DeadlineExtensionLink = styled.a`
  display: flex;
  align-items: center;
  div {
    margin-right: 13px;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: #FFFFFF;
  }
`

export const SmallCalendarIcon = styled.div`
  margin-right: 16px;
  ${media.mobile`
    display: none;
  `}
`

export const LargeCalendarIcon = styled.div`
  margin-left: auto;
  display: none;
  ${media.mobile`
    display: block;
  `}
`
