import React, { Component } from 'react'
import {
  ProgressButton,
  ButtonWrapper,
  PinnedIcon,
  CardTitle,
  GridPinnedIcon
} from './style'
import { stripHtml } from '../../utilities'
import {
  COMPLETED_ICON,
  NEW_COMPLETED_ICON,
  NEW_INCORRECT_ICON,
  NEW_EMPTY_ICON,
  NEW_ACTIVE_ICON,
  NEW_GUESSWORK_ICON,
  NEW_GUESSWORK_WHITE_ICON,
  INCORRECT_ICON,
  ACTIVE_ICON,
  PINNED_ICON,
  PINNED_ICON_GRID,
  EMPTY_ICON,
  GUESSWORK_ICON
} from '../../Constants/progressBarIconSource'
import { GUESSWORK } from '../../Constants/sectionType'

class Button extends Component {
  render () {
    const {
      sectionType,
      btnClass,
      value,
      doNavigate,
      QuestionSetdata,
      isActiveLearning,
      isAssessmentQuizExam,
      label,
      isPinned,
      isTouchDevice,
      dataCypress,
      currentQuestionUUID
    } = this.props
    const { uuid } = label
    const isQuizExamOrALUpdates = isAssessmentQuizExam || isActiveLearning

    let icon, className
    if (sectionType === GUESSWORK) {
      if (btnClass.includes('guesswork')) {
        icon = GUESSWORK_ICON
        className = 'guesswork'
      } else if (btnClass.includes('active')) {
        icon = ACTIVE_ICON
        className = 'active'
      } else {
        icon = EMPTY_ICON
        className = 'empty'
      }
      return <ProgressButton
        src={icon}
        className={className}
        data-cy={dataCypress}
        isTouchDevice={isTouchDevice}
        onClick={() => doNavigate && doNavigate(uuid)}
      />
    }
    if (btnClass.includes('response-submitted')) {
      icon = isQuizExamOrALUpdates ? NEW_GUESSWORK_ICON : GUESSWORK_ICON
      className = 'response-submitted'
    } else if (btnClass.includes('completed')) {
      icon = isQuizExamOrALUpdates ? NEW_COMPLETED_ICON : COMPLETED_ICON
      className = 'completed'
    } else if (btnClass.includes('incorrect')) {
      icon = isQuizExamOrALUpdates ? NEW_INCORRECT_ICON : INCORRECT_ICON
      className = 'incorrect'
    } else if (btnClass.includes('active')) {
      icon = isQuizExamOrALUpdates ? NEW_ACTIVE_ICON : ACTIVE_ICON
      className = 'active'
    } else {
      icon = isQuizExamOrALUpdates ? NEW_EMPTY_ICON : EMPTY_ICON
      className = 'empty'
    }
    const isNewActiveIcon = icon === NEW_ACTIVE_ICON
    const isCurrentQuestion = currentQuestionUUID === uuid
    const isGuessWorkIconAndCurrentQuestion =
     icon === NEW_GUESSWORK_ICON && isCurrentQuestion
    const currentIcon = isNewActiveIcon ? NEW_EMPTY_ICON
      : (isGuessWorkIconAndCurrentQuestion ? NEW_GUESSWORK_WHITE_ICON : icon)
    const { questions } = QuestionSetdata || {}
    const {
      lesson_text: lessonText,
      question_text: questionText
    } = questions?.[value] || QuestionSetdata?.[value] || {}

    return (
      <>
        <ButtonWrapper
          isPinned={isPinned}
          isActiveLearning={isActiveLearning}
          onClick={() => isQuizExamOrALUpdates && doNavigate && doNavigate(uuid)}
        >
          <ProgressButton
            src={currentIcon}
            className={isNewActiveIcon ? 'empty' : className}
            isAssessmentQuizExam={isQuizExamOrALUpdates}
            isActiveLearning={isActiveLearning}
            data-cy={dataCypress}
            isTouchDevice={isTouchDevice}
            onClick={() => doNavigate && doNavigate(uuid)}
          />
          {isCurrentQuestion && isQuizExamOrALUpdates && <ProgressButton
            src={NEW_ACTIVE_ICON}
            isAssessmentQuizExam={isQuizExamOrALUpdates}
            isActiveLearning={isActiveLearning}
            isActiveIcon
            className='active'
            data-cy={dataCypress}
            isTouchDevice={isTouchDevice}
            onClick={() => doNavigate && doNavigate(uuid)}
          />
          }
          <span className={`number ${isCurrentQuestion ? 'bold' : ''}`}>
            {value + 1}
          </span>
          {isPinned && <>
            <PinnedIcon
              className='listPinnedIcon'
              src={PINNED_ICON}
            />
            <GridPinnedIcon
              className='girdPinnedIcon'
              src={PINNED_ICON_GRID}
              isCurrentQuestion={isCurrentQuestion}
            />
          </>}
        </ButtonWrapper>
        {isActiveLearning &&
          <CardTitle className='card-title'>
            {stripHtml(lessonText || questionText)}
          </CardTitle>
        }
      </>
    )
  }
}

export default Button
