import React from 'react'
import styled from 'styled-components'
import CourseButton from '../CourseButton/CourseButton'
import { emitter } from '../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'

export default ReviewWorkButton

const ReviewText = styled.p`
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1px;
    font-family: "Lato", sans-serif;
    font-weight: normal;
    margin-right: 10px;
    text-align: center;
`

function ReviewWorkButton (props) {
  const startReview = () => {
    const {
      activeSectionUUID,
      firstQuestionUUID,
      updateCurrentQuestionUUId,
      setFilteredQuestionInd
    } = props
    emitter.emit(ON_NAVIGATE_TO, '/' + activeSectionUUID + '/' + firstQuestionUUID)
    if (updateCurrentQuestionUUId) updateCurrentQuestionUUId(firstQuestionUUID)
    if (setFilteredQuestionInd) setFilteredQuestionInd(firstQuestionUUID)
  }
  const { isDisabled } = props
  return (
    <>
      {!isDisabled && <ReviewText>
        Don’t forget to review your work before submitting the exam!
      </ReviewText>}
      <CourseButton className={{ 'btn-secondary': true, 'mr-2': true }}
        title='Review Responses' disabled={isDisabled} onClick={startReview}
        dataCypress='reviewWorkButton'
        data-testid='review-button'
      >
          Review Work
      </CourseButton>
    </>
  )
}
