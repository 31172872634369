import React from 'react'
import { useStudentDataContext } from '../Components/Context/Context'

const withStudentContext = Component => {
  const WithStudentContext = props => {
    const context = useStudentDataContext()

    return (
      <Component {...props} studentData={{ ...context }} />
    )
  }

  return WithStudentContext
}

export default withStudentContext
