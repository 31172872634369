import React, { useState, useEffect } from 'react'
import { CustomCheckBox } from './styled'

const CustomCheckbox = ({
  label,
  onChange,
  disabled,
  defalutValue,
  mb
}) => {
  const [isChecked, setIschecked] = useState(false)
  useEffect(() => {
    if (!onChange) return
    onChange(isChecked)
    // eslint-disable-next-line
  }, [isChecked])

  useEffect(() => {
    setIschecked(!!defalutValue)
    // eslint-disable-next-line
  }, [defalutValue])

  return (
    <CustomCheckBox
      mb={mb}
      checked={isChecked}
      disabled={disabled}
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <p>{label}</p>
      <input
        type='checkbox'
        disabled={disabled}
        checked={isChecked}
        onChange={() => setIschecked(preVal => !preVal)}
      />
    </CustomCheckBox>
  )
}

export default CustomCheckbox
