import React from 'react'
import PropTypes from 'prop-types'
import ModalComponent from '../ModalComponent/ModalComponent'
import Button from '../CourseButton/CourseButton'
import {
  ModalContent,
  ModalCrossIcon,
  ModalCrossIconSpan,
  ModalHeader,
  ModalTitleSpan,
  ModalWrapper
} from './styled'

function AlertModal ({
  buttonStyles = {},
  closeButtonText,
  closeButtonClickHandler,
  children,
  headerShow = false,
  show = true,
  title = '',
  wrapperStyles = {},
  wrapperWidth
}) {
  return (
    <ModalComponent
      show={show}
      hideClose
      wrapperWidth={wrapperWidth}
      wrapperStyles={wrapperStyles}
    >
      {headerShow &&
        <ModalHeader>
          <ModalTitleSpan>
            {title}
          </ModalTitleSpan>
          <ModalCrossIconSpan onClick={closeButtonClickHandler}>
            <ModalCrossIcon
              alt='icon'
              src='/images/icons/icon-cross-white.svg'
            />
          </ModalCrossIconSpan>
        </ModalHeader>
      }
      <ModalWrapper>
        <ModalContent>
          {children}
        </ModalContent>
        {closeButtonText && <div className='justify-content-center'>
          <Button
            className={{
              'btn-custom': true,
              'btn-primary': true
            }}
            style={buttonStyles}
            onClick={() => {
              closeButtonClickHandler()
            }}
          >
            {closeButtonText}
          </Button>
        </div>}
      </ModalWrapper>
    </ModalComponent>
  )
}

AlertModal.propTypes = {
  closeButtonText: PropTypes.string.isRequired,
  closeButtonClickHandler: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired
}

export default AlertModal
