import React from 'react'
import config from '../../../config'
import {
  GradeChartCircleWrapper,
  GradeCircleSubTitle,
  GradeCircleTextContainer,
  GradeCircleTitle,
  GradeChartCircle,
  InnerGradeCircle
} from './styled'

const getGradeChartColors = (chartNumber) => {
  const colors = ['#b1bfc5', '#4c4c52', '#3b3b40', '#303033', '#444', '#595959']
  const arrayLength = colors.length
  // Reshuffle colors based on chartNumber, For example:
  // If chartNumber is 2, bring the last two colors to the front
  const newColors = [
    ...colors.slice(-chartNumber),
    ...colors.slice(0, arrayLength - chartNumber)
  ]

  return newColors
}

const GradeChartItem = props => {
  const {
    chartNumber,
    percentage,
    title,
    subTitle,
    degrees,
    newPhilosophyGrading,
    finalEssay
  } = props

  const isBusinessCourse = ['business', 'business.plus'].includes(config.courseName)
  const isBusinessPlan = isBusinessCourse && title === 'Assignments'

  return (
    <GradeChartCircleWrapper data-cy='gradingChartItem'>
      <GradeChartCircle
        degrees={degrees}
        colors={getGradeChartColors(chartNumber)}
      >
        <InnerGradeCircle>{percentage}%</InnerGradeCircle>
      </GradeChartCircle>
      <GradeCircleTextContainer>
        <GradeCircleTitle>
          {isBusinessPlan ? 'Business Plan' : title}
        </GradeCircleTitle>
        {subTitle && (
          <GradeCircleSubTitle>
            {finalEssay && `Final Essay: ${finalEssay}%`}
            {finalEssay && <br />}
            {finalEssay && `Others: `}
            {!Number.isInteger(subTitle) && '~'}
            {!isBusinessPlan && (
              <React.Fragment>
                {subTitle}% {newPhilosophyGrading ? '' : 'each'}
              </React.Fragment>
            )}
          </GradeCircleSubTitle>
        )}
      </GradeCircleTextContainer>
    </GradeChartCircleWrapper>
  )
}

export default GradeChartItem
