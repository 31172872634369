/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/
import appConfig from '../../../config'

class ViewSDKClient {
  constructor () {
    this.addScript()
    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve()
      } else {
        document.addEventListener('adobe_dc_view_sdk.ready', () => {
          resolve()
        })
      }
    })
    this.adobeDCView = undefined
  }

  addScript () {
    const script = document.createElement('script')
    script.src = 'https://documentservices.adobe.com/view-sdk/viewer.js'
    document.body.appendChild(script)
  }

  ready () {
    return this.readyPromise
  }

  previewFile (divId, fileConfig, viewerConfig) {
    const config = {
      clientId: appConfig.embedPdfClientId
    }
    if (divId) config.divId = divId
    this.adobeDCView = new window.AdobeDC.View(config)
    const { pdf, title, readingUUID } = fileConfig
    const previewFilePromise = this.adobeDCView.previewFile({
      content: {
        location: {
          url: pdf
        }
      },
      metaData: {
        fileName: title + '.pdf',
        id: readingUUID
      }
    }, viewerConfig)

    return previewFilePromise
  }
}

export default ViewSDKClient
