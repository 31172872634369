import styled, { css, createGlobalStyle } from 'styled-components'
import mediaqueries, { BREAKPOINTS } from '../../../mediaQueries'

export const OverrideCSSProperties = createGlobalStyle`
  .Page-layout-main-wrapper{
    padding-bottom: 0px!important;
    min-height: calc(100vh - 112px);

    ${mediaqueries.giant`
      ${props => props.activeNavTab === 1 && 'height: 790px;'}
      ${props => props.activeNavTab === 2 && 'height: 870px;'}
    `}

    ${mediaqueries.mobile`
      margin-top: -14px;
      'height: 900px;'
    `}
  }
  .transcriptInterface {
    display: flex!important;
    flex-direction: column!important;
    padding-bottom: 28px!important;
    background-color: black!important;
  }
  .transcript-menu {
    order: 2!important;
    display: flex;
    flex-flow: row nowrap;
    padding-left: 24px!important;
    background-color: black!important;
  }
  .transcript-body {
    background-color: black!important;
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: gray;
      border-radius: 4px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .transcript-box{
    padding: 24px!important;
    background-color: transparent!important;
  }
  .toggleTranscriptBodyWrapper {
    display: none;
  }
  .downloadWrapper {
    margin-left: auto;
    a {
      mask: none!important;
      background-color: transparent !important;
      background-image: url(/images/icons/icon-download.svg)!important;
      background-position: center;
      background-size: cover;
      width: 24px!important;
      height: 24px!important;
    }
  }
  .printWrapper{
    margin-right: 4px;
    a {
      mask: none!important;
      background-color: transparent !important;
      background-image: url(/images/icons/icon-printer.svg)!important;
      background-position: center;
      background-size: cover;
      width: 24px!important;
      height: 24px!important;
    }
  }

  .wrapper-modal {
    width: 500px;
    height: 500px;

    .body-modal {
      margin: 24px;
    }
  }

  .backdrop-modal.show{
    overflow: hidden!important;
  }
`
export const LectureContainer = styled.div`
  width: 100%;
  min-width: 370px;

  ${props => props.shouldSplitLecturePage && css`
    display: flex;
    flex-flow: row wrap;
  `}

  ${props => !props.shouldSplitLecturePage && css`
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: ${BREAKPOINTS.giant}px) {
      width: 1140px !important;
    }
    ${mediaqueries.giant`width: 960px !important`}
    ${mediaqueries.tablet`
      width: 720px !important;
      display: flex;
      flex-direction: column-reverse;
    `}
    ${mediaqueries.mobile`width: 100% !important;`}
  `}

  ${mediaqueries.giant`
    justify-content: center;
  `}
`
export const VideoTranscriptContainer = styled.div`
  width: ${props => props.shouldSplitLecturePage ? '64.4%' : '100%'};
  
  ${props => props.isKalturaV7 && css`
    ${mediaqueries.giant`
      min-height: auto;
    `}
    min-height: calc(100vh - 111px);
    display: table;
    background-color: black;
  `}
  
  ${mediaqueries.giant`
    justify-content: center;
    width: 100%;
    padding: 0px 64px;
    opacity: ${props => props.activeNavTab !== 0 ? 0 : 1};
  `}

  ${mediaqueries.desktop`
    padding: 0px;
  `}

  ${mediaqueries.mobile`
    padding-bottom: 48px;
  `}
`
export const NotesAndEditorSection = styled.div`
  width: 35.6%;
  min-height: calc(100vh - 111px);
  ${props => props.activeNavTab === 2 && `min-height: 780px;`}
  background-color: rgba(22, 22, 24, 1);
  border-left: 1px solid #98A4AE;
  position: relative;

  ${mediaqueries.giant`
    ${props => props.show ? `
      position: absolute;
      top: 111px;
      left: 0;
      width: 100%;
      min-height: calc(100% - 160px);
      border-left: none;
      z-index: 10;
    ` : `display: none;`}
  `}

  ${mediaqueries.mobile`
    top: 49px;
    min-height: calc(100% - 145px);
  `}

  #offset-container {
    width: 100%!important;
    margin-left: 0px!important;
  }

  #jdoodle-compiler-container {
    position: relative;
    width: 100%;
    height: auto!important;

    iframe {
      width: calc(100% + 100px);
      margin-left: -50px;
      height: auto!important;
      min-height: 750px!important;

      ${mediaqueries.giant`
        width: calc(100% + 70px);
        margin-left: -35px;
      `}

      ${mediaqueries.mobile`
        height: auto!important;
      `}
    }

    ::before {
      position: absolute;
      content: '';
      display: none;
      width: 100%;
      height: 100%;
      border: 1px solid #5B6067;
      border-radius: 5px;
      pointer-events: none;

      ${mediaqueries.giant`display: block;`}
    }

    ::after {
      position: absolute;
      content: '${props => props.fileName && props.fileName}';
      white-space: nowrap; 
      width: 280px; 
      overflow: hidden;
      text-overflow: ellipsis;
      top: 17px;
      left: 25px;
      height: 24px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1px;
      color: #FFFFFF;

      ${mediaqueries.giant`
        width: 353px;
      `}

      @media(max-width: 611px) {
        width: 200px;
      }

      @media(max-width: 400px) {
        width: 150px;
      }
    }
  }
`
export const LectureInfo = styled.div`
  margin-top: -24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  .backdrop-modal {
    overflow: hidden;
  }
  .wrapper-modal{
    width: 500px;
    ${mediaqueries.mobile`
      width: 351px;
      top: 0;
      bottom: 0;
      margin: auto;
    `}
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 0;  
      background: transparent;  
    }
  }
  .body-modal {
    margin: 24px;
    p {
      margin-bottom: 12px;
    }
  }
  ${mediaqueries.tablet`
    text-align: left;
    display: flex;
    align-items: end;
    text-align: left;
    margin-top: 20px;  
  `}
  div[data-cy=InstructorBioModal] {
    ${props => props.isCalculus && 'background-color: transparent'};
  }
`

export const LectureVideoContainer = styled.div`
  overflow: hidden;
  overflow-y: hidden;
  > div {
    padding: 0px;
    ${mediaqueries.tablet`top: -95px;`} 
  }
`
export const NavigationSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #161618;
  position: relative;

  ${mediaqueries.mobile`
    position: absolute;
    bottom: 48px;
    left: 0px;
    z-index: 11;
    order: 3;

    ::before {
      content: '';
      height: 1500px;
      width: 100%;
      top: -1500px;
      position: absolute;
    }
  `}
`
export const BreadCrumb = styled.div`
  display: flex;
  margin-left: 64px;
  color: #5FC4B8;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;

  span {
    display: inline-block;
    width: 9px;
    height: 9px;
    border: 2px solid #5fc4b8;
    border-top-width: 0;
    border-right-width: 0;
    margin-right: 12px;
    transform: rotate(45deg);
  }

  ${mediaqueries.tablet`
    margin-left: 32px;
  `}

  ${mediaqueries.mobile`
    max-width: 215px;
    top: 48px;

    span { width: 15px; }
  `}
`
export const TabsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 35.6%;

  & > div:first-child {
    display: none;

    div {
      background-image: url(/images/icons/icon-media-inactive.svg);
    }
  }

  & > div:nth-child(2) {
    border-left: 1px solid #98A4AE;

    div {
      background-image: url(/images/icons/icon-notes.svg);
    }
  }

  & > div:nth-child(3) {
    div {
      background-image: url(/images/icons/icon-code.svg);
    }
  }

  ${mediaqueries.giant`
    width: auto!important;

    & > div:first-child {
      display: flex;

      div {
        ${props => props.activeNavTab === 0 ? `
          background-image: url(/images/icons/icon-media-active.svg);
          height: 22px;
        ` : `background-image: url(/images/icons/icon-media-inactive.svg);`}
      }
    }

    & > div:nth-child(2) {
      border-left: none;

      div {
        ${props => props.activeNavTab === 1 ? `
          background-image: url(/images/icons/icon-notes-active.svg);
          height: 22px;
        ` : `background-image: url(/images/icons/icon-notes-inactive.svg);`}
      }
    }

    & > div:nth-child(3) {
      margin-right: 64px!important;
      div {
        ${props => props.activeNavTab === 2 ? `
          background-image: url(/images/icons/icon-code-active.svg);
          height: 22px;
        ` : `background-image: url(/images/icons/icon-code-inactive.svg);`}
      }
    }
  `}

  ${mediaqueries.tablet`
    & > div:nth-child(3) {
      margin-right: 32px!important;
    }
  `}
`
export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 49px;
  width: 50%;
  text-align: center;
  height: 48px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  background: #25272B;

  ${props => (props.active ||
    (props.index === 1 && props.activeNavTab === 0)
  ) && 'background: transparent;'};
  cursor: pointer;

  ${mediaqueries.giant`
    align-items: center;
    margin-right: 32px;
    padding: 0px;
    background: transparent!important;
  `}
`
export const TabText = styled.span`
  ${mediaqueries.giant`display: none;`}
`
export const Icon = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 9px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  ${mediaqueries.giant`
    margin-right: 0px;
  `}
`
export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #161618;
  padding: 20px 22px;
  z-index: ${props => props.show ? 5 : 0};

  ${mediaqueries.giant`
    padding: ${props => props.isEditorTab ? '32px 64px' : '32px 67px'};
    ${props => !props.isActiveTab && 'opacity: 0;'}
  `}

  ${mediaqueries.tablet`
    padding: ${props => props.isEditorTab ? '32px 27px' : '32px 44px'};
  `}

  ${mediaqueries.mobile`
    padding: 26px 24px;
  `}
`
export const LectureSection = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid #25272B;
`
export const LectureTitle = styled.h1`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 48px;
  color: #FFFFFF;
  margin: 0px;
`
export const Instructor = styled.span`
  display: inline-block;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5FC4B8;
  margin-top: 16px;
  padding: 0px;
  cursor: pointer;

  &:hover {
    color: #B8FFF7;
  }

  &:active {
    color: #FFFFFF!important;
  }
`
export const Instructions = styled.p`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #CCD4D8;
  margin-top: 14px;
  padding: 0px;
`
export const NotesWrapper = styled.div`
  margin-top: 16px;
`
export const Note = styled(Instructor)`
  display: flex;
  align-items: center;
  margin-top: 12px;

  ::before {
    content: '';
    display: inline-block;
    width: 13.15px;
    height: 16px;
    margin-right: 8px;
    background-image: url(/images/icons/icon-file-green.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
`
export const ExamplesSection = styled.div`
  margin-top: 32px;
`
export const ExamplesTitle = styled.h2`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 0px;
  margin-bottom: 16px;
`
export const ExampleFiles = styled(Note)`
  ::before { display: none; }

  ${props => props.disabled && css`
      color: rgba(95,196,184,0.4);
      &:hover {
        color: rgba(95,196,184,0.4)!important;
      }

      &:active {
        color: rgba(95,196,184,0.4)!important;
      }
  `}
`
