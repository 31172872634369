import styled from 'styled-components'
import media from '../../../mediaQueries'

export const LinksHeader = styled.div`
  margin-top: 48px;
  font-weight: 300;
  font-size: 24px;
  line-height: 28.8px;
`

export const LinksSection = styled.div`
  margin-top: 24px;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(${props => Math.ceil(props.count / 2)}, auto);
  grid-gap: 12px;

  ${media.tablet`
  grid-template-rows: repeat(${props => props.count}, auto);
  `}
`

export const LinksSectionItemContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0A2A2D;
  border-radius: 5px;
  padding: 12px;

  &:hover {
    background-color: #0f3e43;
    text-decoration: none;

  }
  &:active {
    background-color: #0f3e43;
    opacity: 0.3;
  }
  &.disabled {
    background-color: #0a2a2d;
    opacity: 0.3;
  }
`

export const LinkTitle = styled.div`
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 16.8px;
`

export const LinkText = styled.div`
  margin-top: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  color: #B1BFC5;

  ${media.tablet`
    display: none;
  `}
`

export const Caret = styled.span`
  width: 8px;
  height: 8px;
  border: 2px solid #FFFFFF;
  border-left-width: 0;
  border-bottom-width: 0;
  transform: rotate(45deg);
`
