import React, { Component } from 'react'
import wolframAlphaLogo from './wolframAlphaLogo.png'
import CourseButton from '../CourseButton/CourseButton'

import api from '../../api'
import config from '../../config'
import { emitter } from '../Emitter/Emitter'

import './css/StepByStepSolution.css'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'

class StepByStepSolution extends Component {
  constructor (props) {
    super(props)
    this.state = {
      query: '',
      queryURI: '',
      steps: null,
      isLoadingResults: false,
      isQuestionChanged: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.keyHandling = this.keyHandling.bind(this)

    emitter.on(ON_NAVIGATE_TO, () => {
      this.setState({ isQuestionChanged: true, query: '' })
    })
  }

  // Prevents the ENTER key (form submission) event from being propogated up
  // to parent components.
  keyHandling (e) {
    if (e.keyCode !== 13) return
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    e.preventDefault()
  }

  handleChange (event) {
    this.setState({ query: event.target.value })
  }

  handleSubmit (event) {
    const { query } = this.state
    event.preventDefault()
    if (query === '') {
      return this.setState({ steps: null })
    } else {
      this.setState({ isLoadingResults: true })
    }
    this.setState({ isQuestionChanged: false })

    const queryURI = encodeURIComponent(query)
    api.getStepByStepAnswer(queryURI)
      .then((steps) => {
        this.setState({ steps, queryURI, isLoadingResults: false })
      })
  }

  buildStepsSection () {
    const { isLoadingResults, query } = this.state
    const { steps } = this.state
    let stepElems

    if (steps === null && !isLoadingResults) return null

    if (isLoadingResults) {
      stepElems = 'Loading...'
    } else if (steps === undefined) {
      stepElems = 'No step-by-step solution found'
    } else if (!steps.length) {
      stepElems = <p>Uh oh! Looks like this one is a hard one. No step-by-step
       solution is available at this time. For more help, please
       contact <a href='mailto:success@outlier.org' rel='noopener noreferrer'>
       success@outlier.org</a></p>
      window.outlierLog('blankWAResponse', query)
    } else {
      stepElems =
      (<div>
        {steps.map((step, index) =>
          <div key={index}>
            <img className='solutionImg' src={step.img} alt={`step ${index + 1}`} />
          </div>)}
      </div>)
    }

    const { isQuestionChanged, queryURI } = this.state
    const stepsContainer = (
      <div className='stepsContainer answer'>
        {stepElems}
      </div>
    )
    const wolframURL =
      `${config.wolframBaseURL}/input/?i=${queryURI}`

    return (
      <>{
        !isQuestionChanged && <div>
          {stepsContainer}
          <div>
            <a href={wolframURL} target='_blank' rel='noopener noreferrer'>
                Computed by Wolfram|Alpha
            </a>
          </div>
          <img
            className='wolframAlphaLogo'
            src={wolframAlphaLogo}
            alt='Wolfram|Alpha logo'
          />
        </div>
      }
      </>
    )
  }

  render () {
    const stepsSection = this.buildStepsSection()
    const { query } = this.state

    return (
      <div className='stepByStepWrapper'>
        <div className='answer-title text-left'>Step-by-Step Solution</div>
        <div className='stepByStepText text-left'>Enter the problem here for a step-by-step walkthrough:</div>
        <form className='stepByStep' onSubmit={this.handleSubmit}>
          <input
            id='step-by-step-input'
            placeholder='Enter problem here for solution'
            value={query}
            onChange={this.handleChange}
            onKeyUp={this.keyHandling}
          />
          <CourseButton
            className={{
              'btn-primary': true,
              stepInputBtn: true
            }}
            onClick={this.handleSubmit}
          >
            =
          </CourseButton>
        </form>
        <div className='linkWrapper'>
          <a href={config.wolframAlphaLink} target='_blank' rel='noopener noreferrer'>
            How to enter problem for solution
          </a>
        </div>
        {stepsSection}
      </div>
    )
  }
}

export default StepByStepSolution
