import { useEffect, useState } from 'react'

const useOverridableState = (initializerCallback) => {
  const [state, setState] = useState(initializerCallback())

  useEffect(() => {
    setState(initializerCallback())
  }, [initializerCallback])

  return [state, setState]
}

export default useOverridableState
