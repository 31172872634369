import React from 'react'
import PropTypes from 'prop-types'
import { PracticeSubText } from './styled'

const PracticeExerciseItemText = props => {
  const {
    numberOfCompleted,
    numberOfActivities
  } = props

  const getUnAttemptedText = numberOfActivities => {
    return `${numberOfActivities} part${numberOfActivities > 1 ? 's' : ''}`
  }

  const isAllAttempted = numberOfCompleted === numberOfActivities
  if (
    isAllAttempted
  ) return <PracticeSubText>Completed</PracticeSubText>

  const isAllUnAttempted = numberOfCompleted === 0
  if (isAllUnAttempted) {
    return <PracticeSubText>{getUnAttemptedText(numberOfActivities)}</PracticeSubText>
  }

  const numberOfUnAttempted = numberOfActivities - numberOfCompleted
  return <PracticeSubText>{getUnAttemptedText(numberOfUnAttempted)} remaining</PracticeSubText>
}

PracticeExerciseItemText.propTypes = {
  numberOfCompleted: PropTypes.number.isRequired,
  numberOfActivities: PropTypes.number.isRequired
}

export default PracticeExerciseItemText
