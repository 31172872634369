import React, { useState, useEffect, useContext } from 'react'
import {
  AssignmentContainer,
  ExternalLink,
  ExternalLinkText,
  AssignmentDescription,
  BreakLine,
  RubricIcon,
  JSTorIcon,
  AssignmentSection,
  AssignmentFileLink,
  StarterFile
} from './styles'
import api from '../../api'
import {
  ASSIGNMENT_PROGRESS,
  ASSIGNMENT_FILE_METADATA } from '../../Constants/studentContext'
import {
  dateToSecondsSinceEpoch
} from '../../utilities/dateTimeUtils'
import Context from '../Context/Context'
import AssignmentSubmissionPage from './AssignmentSubmissionPage'
import AssignmentReviewPage from './AssignmentReviewPage'
import LoadingAnimation from '../LoadingSpinner/LoadingAnimation'
import AssignmentPreviewSection from './AssignmentPreviewSection'
import { downloadFile } from '../../utilities/file'
import CodingReviewPage from './CodingReviewPage'
import GradePending from './GradePending'
import { ASSIGNMENT_PAGE_LAYOUTS } from '../../Constants'
import { getAssignmentStatus } from '../../utilities/chapterUtils'
import { syncStates } from '../../Constants/textEditor'

const { SUBMIT, RESUBMIT, REVIEW, PREVIEW, GRADE_PENDING } = ASSIGNMENT_PAGE_LAYOUTS

const AssignmentPage = ({ data }) => {
  const {
    assignmentUUID,
    title,
    cohortID,
    lockTime,
    unlockTime,
    multiPartUpload,
    affirmationStatements,
    multiPartUploadfiles,
    hideTextEntryField,
    hideFileUploadField,
    codegradeAssignmentId,
    allowSpreadsheetUpload,
    answerKeyFile,
    type: assignmentType
  } = data

  const context = useContext(Context)
  const {
    courseID,
    isVIP,
    textEntryAssignmentState,
    fileUploadAssignmentState,
    studentData: {
      studentAnswers,
      [ASSIGNMENT_PROGRESS]: assignmentProgress,
      [ASSIGNMENT_FILE_METADATA]: assignmentFileMetadata
    }
  } = context
  const progressData = assignmentProgress && assignmentProgress[assignmentUUID]
  const fileMetadata = assignmentFileMetadata && assignmentFileMetadata[assignmentUUID]
  const { originalFileName } = fileMetadata || {}
  const unlockTimeInSeconds = dateToSecondsSinceEpoch(new Date(unlockTime))
  const lockTimeInSeconds = dateToSecondsSinceEpoch(new Date(lockTime))

  const [layout, setLayout] = useState(null)
  const [file, setFile] = useState(null)
  const [text, setText] = useState('')
  const isCodingAssignment = assignmentType === 'coding_assignment'
  const [assignment, setAssignment] = useState(null)
  const [content, setContent] = useState({})
  const [loading, setLoading] = useState(false)
  const [videoUrl, setVideoUrl] = useState(null)
  const [videoUrlIndex, setVideoUrlIndex] = useState(0)
  const [isVideoLoading, setIsVideoLoading] = useState(false)
  const isSubmitDisabled = !file && !text.trim().length
  const hasStarterFiles = data.starterFiles?.length > 0
  const isSavingAssignment = [
    textEntryAssignmentState,
    fileUploadAssignmentState
  ].includes(syncStates.SAVING)
  const showResubmitPage = layout === RESUBMIT

  useEffect(() => {
    if (context.updateContext && assignmentUUID) {
      context.updateContext({ currentQuestionSet: data })
    }
    const getAssignment = async () => {
      try {
        setLoading(true)
        let responses
        if (multiPartUpload) {
          const promises = multiPartUploadfiles.map((file, index) => {
            return api.getWritingAssignment({
              assignmentUUID,
              cohortID,
              fileName: `file${index}`,
              gcsVideo: true,
              multiPartAssignment: true
            })
          })

          responses = await Promise.all(promises)
        } else {
          const response = await api.getWritingAssignment({
            assignmentUUID,
            cohortID,
            gcsVideo: true
          })
          responses = [response]
        }
        setLoading(false)

        const isError = responses.some(response => {
          const { data, error } = response
          return error || !data || data.error
        })

        if (isError) return setAssignment(null)

        setAssignment(responses)
      } catch (error) {
        console.error(error.message)
      }
    }

    if (isCodingAssignment || !progressData) return
    getAssignment()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (context.updateContext &&
      layout &&
      layout !== RESUBMIT &&
      !multiPartUpload
    ) {
      context.updateContext({
        showFinish: !isSavingAssignment && !isSubmitDisabled,
        isAssessmentQuizExam: true
      })
      return () => {
        context.updateContext({ showFinish: false })
      }
    }
  // eslint-disable-next-line
  }, [isSubmitDisabled, isSavingAssignment])

  useEffect(() => {
    if (context.updateContext) {
      context.updateContext({ reviewMode: layout === REVIEW })
    }
  // eslint-disable-next-line
  }, [layout])

  const readTextContent = (data, isFile) => {
    const blob = new Blob([data], { type: 'text/plain' })
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      const { result } = reader
      if (!result) return setContent({ 0: { text: '' } })
      try {
        if (isFile) {
          setContent({ 0: { text: result } })
          setText(result)
        } else {
          const text = JSON.parse(result)
          setContent({ 0: { text: text?.assignmentHTML } })
          setText(text?.assignmentHTML)
        }
      } catch (error) {
        console.log('Error when parsing text json: ', error.message)
        setContent({ 0: { text: '' } })
      }
    })
    reader.readAsText(blob)
  }

  const readFileContent = (data, fileType, index) => {
    const blob = new Blob([data], { type: fileType })
    const objectURL = URL.createObjectURL(blob)
    setContent(preVal => ({
      ...preVal,
      [index]: {
        url: objectURL,
        name: originalFileName
      }
    }))
    setFile(preVal => ({
      ...preVal,
      [index]: objectURL
    }))
  }

  useEffect(() => {
    const pageStatus = isVIP ? null : getAssignmentStatus({
      lockTime,
      unlockTime,
      assignment,
      isCodingAssignment,
      status: progressData?.status
    })
    setLayout(pageStatus)

    if (isCodingAssignment || !assignment) return
    assignment.forEach((response, index) => {
      const { data, headers: { 'content-type': fileType } = {} } = response
      if (!fileType) return
      if (fileType.includes('application/json')) {
        const text = Buffer.from(data).toString('utf8')
        const { type, url } = JSON.parse(text)
        if (type === 'video') {
          setVideoUrl(url)
          setVideoUrlIndex(index)
          return
        }
        readTextContent(data, fileType === 'text/plain')
      } else if (fileType === 'text/plain') {
        layout === REVIEW ? readTextContent(data, fileType === 'text/plain')
          : readFileContent(data, fileType, index)
      } else {
        readFileContent(data, fileType, index)
      }
    })
    // eslint-disable-next-line
  }, [assignment])

  useEffect(() => {
    if (!videoUrl) return
    const downloadVideo = async () => {
      try {
        setIsVideoLoading(true)
        const response = await api.getVideoFromGCS(videoUrl)
        const objectURL = URL.createObjectURL(response.data)

        setContent(preVal => ({
          ...preVal,
          [videoUrlIndex]: {
            url: objectURL,
            name: originalFileName
          }
        }))
        setFile(preVal => ({
          ...preVal,
          [videoUrlIndex]: objectURL
        }))
        setIsVideoLoading(false)
      } catch (error) {
        console.error(error.message)
      }
    }
    downloadVideo()
    // eslint-disable-next-line
  }, [videoUrl])

  const renderPageContent = () => {
    if (!layout) return null

    if (layout === SUBMIT || showResubmitPage) {
      return <AssignmentSubmissionPage
        assignmentUUID={assignmentUUID}
        codegradeAssignmentId={codegradeAssignmentId}
        isCodingAssignment={isCodingAssignment}
        cohortID={cohortID}
        multiPartUpload={multiPartUpload}
        affirmationStatements={affirmationStatements}
        multiPartUploadfiles={multiPartUploadfiles}
        lockTimeInSeconds={lockTimeInSeconds}
        hideTextEntryField={hideTextEntryField}
        hideFileUploadField={hideFileUploadField}
        title={title}
        isSubmitDisabled={isSubmitDisabled}
        setLoading={setLoading}
        isVideoLoading={isVideoLoading}
        file={file}
        setFile={setFile}
        text={text}
        setText={setText}
        content={content}
        setContent={setContent}
        hasStarterFiles={hasStarterFiles}
        layout={layout}
        allowSpreadsheetUpload={allowSpreadsheetUpload}
        assignmentType={assignmentType}
      />
    }

    if (layout === REVIEW) {
      if (isCodingAssignment) {
        const studentAnswer = studentAnswers.find(
          answer => answer?.uuid === assignmentUUID
        )
        return (
          <CodingReviewPage
            assignmentUUID={assignmentUUID}
            lockTimeInSeconds={lockTimeInSeconds}
            progressData={progressData}
            studentAnswer={studentAnswer}
          />
        )
      }

      return (
        <AssignmentReviewPage
          assignmentUUID={assignmentUUID}
          assignment={assignment?.[0]}
          content={content?.[0] || {}}
          lockTimeInSeconds={lockTimeInSeconds}
          progressData={progressData}
          title={title}
          courseID={courseID}
          cohortID={cohortID}
          fileMetadata={fileMetadata}
          hasStarterFiles={hasStarterFiles}
          answerKeyFile={answerKeyFile}
          hideTextEntryField={hideTextEntryField}
        />
      )
    }
    if (layout === PREVIEW) {
      return <AssignmentPreviewSection
        unlockTime={unlockTimeInSeconds}
      />
    }
    if (layout === GRADE_PENDING) {
      return <GradePending lockTime={lockTimeInSeconds} />
    }
  }

  if (loading) {
    return <LoadingAnimation />
  }

  const isUngradedAssignment = hideTextEntryField && hideFileUploadField

  return (
    <AssignmentSection>
      <AssignmentContainer className='container'>
        <div className='row'>
          <AssignmentDescription
            dangerouslySetInnerHTML={{ __html: data.assignmentDetails }} />
          {hasStarterFiles && (
            <StarterFile>
              <p>STARTER FILE(S):</p>
              {data.starterFiles.map((starterFile, index) => (
                <AssignmentFileLink key={index} onClick={() => downloadFile(starterFile.url)}>
                  {starterFile.title}
                </AssignmentFileLink>
              ))}
            </StarterFile>
          )}
          {!isUngradedAssignment && (
            <div>
              {data.rubric && (
                <ExternalLink href={data.rubric} target='_blank'>
                  <RubricIcon />
                  <ExternalLinkText>
                VIEW RUBRIC
                  </ExternalLinkText>
                </ExternalLink>
              )}
              {data.jstorLink && (
                <ExternalLink href='https://www.jstor.org/' target='_blank'>
                  <JSTorIcon />
                  <ExternalLinkText>
                VISIT DIGITAL LIBRARY (JSTOR)
                  </ExternalLinkText>
                </ExternalLink>
              )}
              {!isVIP && <BreakLine />}
              {renderPageContent()}
            </div>
          )}
        </div>
      </AssignmentContainer>
    </AssignmentSection>
  )
}

export default AssignmentPage
