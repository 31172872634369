import styled, { css, createGlobalStyle } from 'styled-components'

export const OverrideCSSProperties = createGlobalStyle`
  .wrapper-modal {
    width: 500px;
    height: ${({ height }) => height || '228'}px!important;
    top: 267px;
    background: #25272B;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
    height: 228px;

    .btn-close-modal {
      display: none;
    }

    .body-modal {
      margin: 0px;
    }
  }

  .backdrop-modal {
    overflow: hidden!important;
    height: 100vh!important;
    width: 100vw!important;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 455px;
  padding: 24px 29px 24px 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`
export const ModalHeaderTitle = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
`
export const ModalCloseIcon = styled.div`
  position: absolute;
  top: 19px;
  right: -15px;
  background-image: url(/images/close-icon.svg);
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  width: 14px;
  height: 14px;
  margin-top: 6px;
  cursor: pointer;
`
export const ModalBody = styled.div`
  position: relative;
  width: 455px;
  padding: 24px;
  padding-bottom: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin: 0px 0px 12px 0px;
  }

  p:last-child {
    font-size: 16px;
    line-height: 24px;
  }
`
export const ModalActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 0px 24px 24px 24px;
`
export const PrimaryButton = styled.button`
  display: block;
  padding: 16px 24px;
  height: 48px;
  margin-left: ${props => props.ml ? props.ml : '0'}px;
  background: #5FC4B8;
  color: black;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  border-radius: 5px!important;
  outline: none!important;
  opacity: ${props => props.disabled ? 0.3 : 1};

  :active {
    ${props => !props.disabled && css`
      background: white;
    `}
  }
`
export const SecondaryButton = styled(PrimaryButton)`
  background: transparent;
  color: #5FC4B8;
  border: none;
  border: solid 1px #5FC4B8;
  border-radius: 5px!important;
  outline: none!important;
  opacity: ${props => props.disabled ? 0.3 : 1};

  :active {
    ${props => !props.disabled && css`
      background: white;
      color: black;
    `}
  }
`
