import React, { useEffect, useState } from 'react'
import MathJax from '../MathJax/MathJax'
import StepByStepSolution from '../StepByStepSolution/StepByStepSolution'
import { formatQuestionExplanation } from '../../utilities/questions'
import { getExplanation } from '../../utilities/courseUtils'
import { getCorrectAnswer } from '../FreeformEquationQuestion/utils/wiris'
import { cleanupPopupForDisplaying, replaceEntities } from '../MathType/utils'
import {
  CorrectCode,
  AnswerExplanationWrapper,
  TitleWrapper,
  CorrectAnswer,
  ExplanationWrapper,
  CustomStepByStepSolnStyles
} from './styles'
import config from '../../config'
import {
  CODING_QUESTION,
  MULTIPLE_CHOICE,
  TEXT_BLANK,
  TRUE_FALSE
} from '../../Constants/questionType'

function AnswerExplanation ({
  generalExplanation,
  specificExplanation,
  questionType,
  correctCodeFile,
  isPopup,
  options,
  isCorrectAnswer,
  answer
}) {
  const isCodingQuestion = questionType === CODING_QUESTION
  const isTextBlank = questionType === TEXT_BLANK
  const isOptionType = questionType === MULTIPLE_CHOICE || questionType === TRUE_FALSE
  const [correctCode, setCorrectCode] = useState('')

  useEffect(() => {
    if (!correctCodeFile) return setCorrectCode('')

    const getCorrectCode = async () => {
      await fetch(correctCodeFile)
        .then(result => {
          result.text().then(content => {
            setCorrectCode(content)
          }
          )
        })
    }

    getCorrectCode()
  }, [correctCodeFile])

  function getMultipleChoiceAnswers () {
    if (!options) return null
    return (
      <>
        {options?.map((option, index) => {
          if (!answer?.includes((index + 1).toString())) return null
          return <MathJax
            data-testid={`correct-answer-${index}`}
            key={index} math={option}
          />
        })}
      </>
    )
  }

  function getTextBlankAnswer () {
    return answer?.toString().replace(',', ', ')
  }

  const correctAnswer = isPopup
    ? cleanupPopupForDisplaying(getCorrectAnswer())
    : getCorrectAnswer()

  const explanation = getExplanation(specificExplanation, generalExplanation)
  const { showStepByStepSolution } = config.course
  return (
    <AnswerExplanationWrapper>
      {isCorrectAnswer
        ? null
        : (
          <>
            {isOptionType && !explanation
              ? null
              : <TitleWrapper>Answer</TitleWrapper>
            }
            <CorrectAnswer>
              {isCodingQuestion && correctCode &&
                <CorrectCode>
                  {correctCode}
                </CorrectCode>
              }
              <span>
                {!isCodingQuestion && isOptionType
                  ? explanation && getMultipleChoiceAnswers()
                  : isTextBlank ? getTextBlankAnswer()
                    : <MathJax
                      data-testid='correct-answer'
                      math={
                        formatQuestionExplanation(
                          replaceEntities(correctAnswer)
                        )}
                    />}
              </span>
            </CorrectAnswer>
          </>
        )}
      {explanation &&
        <>
          <TitleWrapper>Explanation</TitleWrapper>
          <ExplanationWrapper>
            <MathJax
              math={formatQuestionExplanation(explanation)}
              data-testid='explanation'
            />
          </ExplanationWrapper>
        </>
      }
      {showStepByStepSolution && !(isOptionType || isTextBlank) &&
        <>
          <CustomStepByStepSolnStyles />
          <StepByStepSolution />
        </>}
    </AnswerExplanationWrapper>
  )
}

AnswerExplanation.displayName = 'AnswerExplanation'
export default AnswerExplanation
