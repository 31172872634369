import styled from 'styled-components'

export const CalendarListContainer = styled.div`
  position: absolute;
  top: 26px;
  border-radius: 5px;
  width: 327px;
  background: #35383E;
  z-index: 10;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`
export const CalendarListText = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-transform: none;
  padding: 11px 16px;
  color: #FFFFFF;
`
export const BorderLine = styled.span`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`
