import React from 'react'
import MultipleVideos from '../LecturesExerciseItem/MultipleVideos'

const CalculusLectureItem = props => {
  const getVideos = props.excerciseList?.lecture?.lecturevideos
  getVideos.forEach(video => {
    video.displayTitle = video.instructor.name
    video.kalturaEmbedCode = video.kaltura_embed_code
  })

  return <MultipleVideos multipleVideos={getVideos} {...props} />
}

export default CalculusLectureItem
