import React, { Component } from 'react'
import isEqual from 'lodash/isEqual'
import QuestionSetProgress from '../QuestionSetProgress/QuestionSetProgress'
import QuestionWrapper from '../QuestionWrapper/QuestionWrapper'
import Context from '../Context/Context'
import config from '../../config'
import LoadingSpinner from '../LoadingSpinner/LoadingAnimation'
import ExamKeysPage from '../ExamKeysPage'
import { emitter } from '../Emitter/Emitter'
// import { TabPane,Row} from 'reactstrap';
import { TabPane } from 'reactstrap'
import {
  hasBlankAnswers,
  getAnswerForExamQuestions,
  doesLastCardExistInQuestionSet
} from '../../utilities/sectionUtils'
import {
  QUIZ, EXAM, ACTIVE_LEARNING, GUESSWORK, PRACTICE_EXERCISES,
  ACTIVITY_TYPES
} from '../../Constants/sectionType'
import {
  ON_SECTION_PROGRESS,
  ON_IS_LAST_SECTION, ON_NAVIGATE_TO, ON_SKIP_SECTION,
  ON_SECTION_DATA_PROGRESS, ON_SUBMIT_ANSWER, ON_MINIMUM_SECTION_PROGRESS,
  ON_EXAM_COMPLETE,
  ON_TRACK_STUDENT_EVENT
} from '../../Constants/emitterKeys'
import {
  GUESSWORK_COMPLETE, EXAM_COMPLETE, ACTIVE_LEARNING_COMPLETE,
  LAST_QUIZ_UUID,
  REVIEW_ACTIVE_LEARNING_COMPLETE,
  ORIENTATION_ACTIVE_LEARNING_COMPLETE
} from '../../Constants/studentContext'
import {
  FREE_FORM,
  CARD } from '../../Constants/questionType'
import sectionTypeToLastContextKey from '../../utilities/sectionTypeToLastContextKey'
import sectionTypeToEmitterKey from '../../utilities/sectionTypeToEmitterKeys'
import { getNumberOfQuestions, getProblemSetQuestions } from '../../utilities/problemBankUtils'
import {
  getLocalStorageKey,
  updateStudentContext,
  canUseLocalStorageResponses,
  isExamRetakeType,
  getExamKeyFieldNames,
  shouldByPassExamKeysPage
} from '../../utilities/examUtils'
import groupBy from 'lodash/groupBy'
import {
  DESMOS_SCRIPT_LINK,
  MATHJAX_SCRIPT_LINK
} from '../../Constants'
import { configureQuizzes } from '../FreeformEquationQuestion/utils/configure-quizzes'
import { MULTIPLE } from '../../Constants/frequency'
import {
  getActiveLearningCompleteKey,
  getLastActiveLearningUUIDKey,
  getStudentAnswersKey
} from '../../utilities/contextKeys'
import { ACTIVE_LEARNING_COMPLETED } from '../../Constants/eventTypes'
import {
  isMidExam,
  isPracticeExam,
  shouldSaveMinimumSectionProgress
} from '../../utilities/chapterUtils'
import { checkIs39WeekCohort } from '../../utilities/courseUtils'

export function fillQuestions (questionSet) {
  return questionSet.map(question => ({
    uuid: question.Question_uuid,
    question: question.Question_uuid,
    answer: null,
    correct: null
  }))
}

class InstructorQuestionSet extends Component {
  constructor (props) {
    super(props)
    this.updateCurrentQuestionUUId = this.updateCurrentQuestionUUId.bind(this)
    this.filteredquestionSetFun = this.filteredquestionSetFun.bind(this)
    this.onClickNext = this.onClickNext.bind(this)
    this.onReviewClickNext = this.onReviewClickNext.bind(this)
    this.onClickPrevious = this.onClickPrevious.bind(this)
    this.setFilteredQuestionInd = this.setFilteredQuestionInd.bind(this)
    this.enableFinish = this.enableFinish.bind(this)
    this.enablePrevious = this.enablePrevious.bind(this)
    this.finishQuestionSet = this.finishQuestionSet.bind(this)
    this.updateOnCheck = this.updateOnCheck.bind(this)
    this.getStudentContextKeyBySectionType =
      this.getStudentContextKeyBySectionType.bind(this)
    this.renderQuestionWrapper = this.renderQuestionWrapper.bind(this)
    this.showFinishButton = this.showFinishButton.bind(this)
    this.checkPrevProps = this.checkPrevProps.bind(this)
    this.getLastCardUUID = this.getLastCardUUID.bind(this)
    this.getUpdatedCardForStore = this.getUpdatedCardForStore.bind(this)

    const {
      currentQuestion: { type },
      problemBank,
      currentProblemSetUUID
    } = this.props
    this.state = {
      isMathJaxLoading: true,
      isDesmosLoading: false,
      isWirisLoading: false,
      currentQuestionUUID: '',
      isProblemBank: type === 'question_problem_bank',
      question: '',
      questionAttempt: [],
      filteredquestionSet: [],
      filteredActvQuestInd: 0,
      showfinish: false,
      showPrevious: false,
      localStorageKey: '',
      isInitialRender: false,
      slideBubbleToLatest: false,
      shouldByPassExamKeys: false
    }

    if (!problemBank) return
    const questions = problemBank.find(problemSet =>
      problemSet.question_set_uuid === currentProblemSetUUID)
    this.numberOfQuestions = getNumberOfQuestions(questions?.Question)
  }

  componentDidMount () {
    this.loadMathJax()
    this.loadDesmosScript()

    const {
      currentQuestion,
      questionSet,
      sectionUUID,
      type_of_question: sectionType,
      review_mode: reviewMode,
      activeSectionUUID
    } = this.props
    const {
      isProblemBank,
      questionAttempt
    } = this.state
    const {
      overrideKeys,
      isActiveLearningButtonClicked,
      updateContext,
      studentData: { pinnedQuestions },
      cohortData,
      courseData
    } = this.context

    const getExamKeysOverride = async () => {
      const { overrideName } = getExamKeyFieldNames(courseData, activeSectionUUID)
      const shouldByPassKeysPage = shouldByPassExamKeysPage({
        cohortData, overrideFieldName: overrideName, overrideKeys
      })
      this.setState({ shouldByPassExamKeys: shouldByPassKeysPage })
    }
    const { officialCourseName, duration } = cohortData
    const is39WeekCohort = checkIs39WeekCohort(officialCourseName, duration)
    if (is39WeekCohort) getExamKeysOverride()

    this.setFilteredQuestionInd(
      reviewMode ? questionSet[0].Question_uuid : currentQuestion.uuid
    )

    const studentAnswers = this.getStudentAnswers()
    const savedCardUUID = this.getLastCardUUID(
      sectionType, sectionUUID, currentQuestion.practiceUUID
    )

    if (!pinnedQuestions.length) {
      studentAnswers
        .forEach(studentAnswer => studentAnswer?.questionActivity?.pinned &&
          pinnedQuestions.push(studentAnswer.uuid))
    }

    const currentQuestionUUID = isProblemBank ? this.getProblemSetNextUUID()
      : currentQuestion.uuid

    const lastCardUUID = doesLastCardExistInQuestionSet(savedCardUUID, questionSet)
      ? savedCardUUID : currentQuestionUUID

    const obj = questionSet.find(obj =>
      obj.Question_uuid === currentQuestionUUID
    )
    if (questionAttempt.indexOf(currentQuestionUUID) === -1) {
      questionAttempt.push(currentQuestionUUID)
      this.setState({ questionAttempt })
    }

    this.setState({ currentQuestionUUID, question: obj })
    this.updateCurrentQuestionUUId(
      reviewMode ? questionSet[0].Question_uuid
        : (isActiveLearningButtonClicked ? lastCardUUID ||
           currentQuestionUUID : currentQuestionUUID)
    )
    if (isProblemBank) return this.setFilteredQuestionInd(currentQuestionUUID)
    if ((sectionType === EXAM || sectionType === QUIZ) &&
      !reviewMode) this.getLocalStorageResponses()
    updateContext({ currentQuestionSet: currentQuestion })
    if (!lastCardUUID || !isActiveLearningButtonClicked) return
    this.setFilteredQuestionInd(lastCardUUID)
    this.setState({ slideBubbleToLatest: true })
    updateContext({ isActiveLearningButtonClicked: false })
  }

  getStudentAnswers = () => {
    const {
      currentChapter: { type },
      studentData
    } = this.context
    const studentAnswersKey = getStudentAnswersKey(type)
    return studentData[studentAnswersKey]
  }

  // retrives stored last card based on sectionUUID and sectionType
  getLastCardUUID (sectionType, sectionUUID, practiceUUID) {
    const {
      studentData,
      course: { currentInstructor },
      currentChapter: { type: chapterType }
    } = this.context
    const contextKey = sectionTypeToLastContextKey(sectionType, chapterType)
    if (!contextKey) return
    const sectionLastCard = studentData[contextKey][sectionUUID]
    if (
      [GUESSWORK, EXAM].includes(sectionType)
    ) return sectionLastCard
    if (sectionType === QUIZ) {
      return sectionLastCard && sectionLastCard[practiceUUID]
    }
    if (sectionType === ACTIVE_LEARNING) {
      // active learning comes here
      const { Instructors } = this.props
      if (Instructors.length <= 1) return sectionLastCard
      return sectionLastCard && sectionLastCard[currentInstructor]
    }
  }

  updateCurrentQuestionUUId (currentQuestionUUID) {
    const { sectionUUID, questionSet, type_of_question: sectionType,
      currentQuestion: { practiceUUID }, completeScreenData } = this.props
    const { currentQuestionUUID: stateCurrentQuestionUUID } = this.state
    const { navigateToActiveIndex, examRetake, cohortData } = this.context
    let obj = questionSet.find(obj =>
      obj.Question_uuid === currentQuestionUUID
    )
    const question = obj
    const isExam = sectionType === EXAM
    const isRetake = isExam && isExamRetakeType({
      examRetake,
      cohortId: cohortData.cohortID,
      chapter: completeScreenData?.chapter
    })
    const retakeAllowedAndNotQuestion = isRetake && !question
    if ((stateCurrentQuestionUUID ||
        !navigateToActiveIndex ||
        currentQuestionUUID) &&
      (currentQuestionUUID !== stateCurrentQuestionUUID)) {
      const emitterKey = sectionTypeToEmitterKey(sectionType)
      emitterKey && !isPracticeExam(sectionUUID) &&
        emitter.emit(emitterKey,
          { [sectionUUID]: this.getUpdatedCardForStore(
            sectionType,
            {
              sectionUUID,
              practiceUUID,
              currentQuestionUUID: retakeAllowedAndNotQuestion
                ? questionSet[0].Question_uuid : currentQuestionUUID
            })
          })
      this.setState({ currentQuestionUUID })
    }

    if (retakeAllowedAndNotQuestion) {
      obj = questionSet[0]
      this.setState({ currentQuestionUUID: obj.Question_uuid })
    }
    this.setState({ question: obj })
    this.loadWirisScript(obj)
  }

  loadMathJax = () => {
    if (window.MathJax || !this.state.isMathJaxLoading) {
      return this.setState({ isMathJaxLoading: false })
    }

    const mathJaxConfigScript = document.createElement('script')
    mathJaxConfigScript.type = 'text/x-mathjax-config'
    const mathJaxConfig =
      `MathJax.Hub.Config({
        jax: ['input/TeX', 'input/MathML', 'input/AsciiMath', 'output/SVG'],
        menuSettings: {
          explorer: true
        },
        explorer: {
          speech: true,
          background: 'yellow',
          foreground: 'white'
        },
        showMathMenu: false,
        styles: {
          '#MathJax_Message': {
            display: 'none'
          }
        }
      });
      MathJax.Hub.processSectionDelay = 0;
      MathJax.Hub.processUpdateDelay = 0;
      MathJax.Hub.Register.StartupHook('MathML Jax Ready', function () {
        var PARSE = MathJax.InputJax.MathML.Parse;
        PARSE.Augment({
          _AddChildren: PARSE.prototype.AddChildren,
          AddChildren: function (mml, node) {
            this._AddChildren(mml, node);
            if (mml.type === "mrow" && (mml.open || mml.close)) {
              if (mml.open && !mml.data[0].stretchy) delete mml.open;
              if (mml.close && !mml.data[mml.data.length-1].stretchy) delete mml.close;
            }
          }
        });
      });
      `
    mathJaxConfigScript[(window.opera ? 'innerHTML' : 'text')] = mathJaxConfig
    document.body.appendChild(mathJaxConfigScript)

    const mathJaxScript = document.createElement('script')
    mathJaxScript.id = 'mathJaxScript'
    mathJaxScript.src = MATHJAX_SCRIPT_LINK
    mathJaxScript.onload = () => {
      this.setState({ isMathJaxLoading: false })
    }
    document.body.appendChild(mathJaxScript)
  }

  getLocalStorageResponses = () => {
    const {
      currentQuestion,
      type_of_question: sectionType,
      questionSet
    } = this.props
    const isQuiz = sectionType === QUIZ
    const isExam = sectionType === EXAM
    const {
      user: { email: userEmail },
      studentData: { isProgressEmpty }
    } = this.context
    const isAssessmentQuizExam = (isQuiz || isExam)
    const { breadcrumbArr: [[keyTitle = '']] } = currentQuestion || {}
    const { examUnlockDate, examLockDate } = currentQuestion
    const localStorageKey = getLocalStorageKey(userEmail, config.courseName, keyTitle)
    this.setState({ localStorageKey })
    const useLocalStorageResponses = canUseLocalStorageResponses({
      context: this.context,
      localStorageKey,
      examLockDates: { examUnlockDate, examLockDate },
      isQuiz
    })

    if (isAssessmentQuizExam && isProgressEmpty) {
      window.localStorage.removeItem(localStorageKey)
      return
    }
    useLocalStorageResponses && updateStudentContext(this.context, localStorageKey, questionSet)
  }

  loadWirisScript = question => {
    const { question_type: questionType } = question || {}
    if (questionType !== FREE_FORM) return

    const wirisLoaded = this.isWirisLoaded()
    if (wirisLoaded || this.state.isWirisLoading) return

    this.setState({ isWirisLoading: true })
    const script = document.createElement('script')
    script.id = 'wirisScript'
    script.src = 'https://www.wiris.net/demo/editor/editor'
    script.onload = () => this.onWirisLoad()
    document.body.appendChild(script)
  }

  onWirisLoad = () => {
    const quizzesScript = document.createElement('script')
    quizzesScript.id = 'wirisQuizzes'
    quizzesScript.src = '/quizzes/resources/quizzes.js'
    quizzesScript.onload = () => this.onWirisQuizzesLoad()
    document.body.appendChild(quizzesScript)
  }

  onWirisQuizzesLoad = () => {
    configureQuizzes()
    this.setState({ isWirisLoading: false })
  }

  loadDesmosScript = () => {
    if (window.Desmos) return

    const { questionSet } = this.props
    const hasDesmosQuestion = questionSet.some(question => !!question.desmos_graph)
    if (!hasDesmosQuestion) return

    this.setState({ isDesmosLoading: true })
    this.script = document.createElement('script')
    this.script.id = 'desmosScript'
    this.script.src = DESMOS_SCRIPT_LINK
    this.script.onload = () => {
      this.setState({ isDesmosLoading: false })
    }
    document.body.appendChild(this.script)
  }

  getUpdatedCardForStore (sectionType, questionDetail) {
    if (
      [GUESSWORK, EXAM].includes(sectionType)
    ) return questionDetail.currentQuestionUUID
    if (sectionType === QUIZ) {
      const { studentData } = this.context
      const lastCardSection = studentData[LAST_QUIZ_UUID][questionDetail.sectionUUID]
      return {
        ...(lastCardSection instanceof Object ? lastCardSection : {}),
        [questionDetail.practiceUUID]: questionDetail.currentQuestionUUID
      }
    }
    if (sectionType === ACTIVE_LEARNING) {
      // active learning
      const { Instructors } = this.props
      const {
        currentChapter: { type }
      } = this.context
      const lastActiveLearningUUIDKey = getLastActiveLearningUUIDKey(type)
      // if one theme or no theme, the schema is
      // { sectionUUID: questionUUID }
      if (Instructors.length <= 1) return questionDetail.currentQuestionUUID
      const { studentData, course: { currentInstructor } } = this.context
      const lastALCardSection = studentData[lastActiveLearningUUIDKey][questionDetail.sectionUUID]
      // currentInstructor is the ID for the current theme selected
      return {
        ...(lastALCardSection instanceof Object ? lastALCardSection : {}),
        [currentInstructor]: questionDetail.currentQuestionUUID
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      questionSet: previousQuestionSet,
      currentInstructor: prevInstructor,
      currentQuestion: {
        uuid: prevQuestionUUID
      }
    } = prevProps
    const { currentQuestionUUID: prevCurrentQuestionUUID } = prevState
    const {
      type_of_question: sectionType,
      review_mode: reviewMode
    } = this.props
    const { fetchLocalProgress } = this.context
    const questionSetUpdated =
      previousQuestionSet?.[0]?.Question_uuid !== // eslint-disable-line
      this.props.questionSet?.[0]?.Question_uuid // eslint-disable-line

    if ((sectionType === EXAM || sectionType === QUIZ) &&
        !reviewMode && (fetchLocalProgress || questionSetUpdated)) {
      this.getLocalStorageResponses()
    }
    this.checkPrevProps({ prevInstructor, prevQuestionUUID, previousQuestionSet })
    this.showFinishButton(prevCurrentQuestionUUID)
  }

  showFinishButton (prevCurrentQuestionUUID) {
    const { currentQuestionUUID } = this.state
    if (prevCurrentQuestionUUID !== currentQuestionUUID) this.enableFinish()
  }

  checkPrevProps (prevProps) {
    const {
      previousQuestionSet,
      prevInstructor,
      prevQuestionUUID } = prevProps
    const {
      completeScreenData,
      questionSet: currentQuestionSet,
      currentInstructor,
      activeChildrenIndex,
      type_of_question: typeOfQuestion,
      currentQuestion: {
        uuid: currentQuestionUUID
      }
    } = this.props
    const { examRetake, cohortData } = this.context
    const isExam = typeOfQuestion === EXAM
    const isRetake = isExam && isExamRetakeType({
      examRetake,
      cohortId: cohortData.cohortID,
      chapter: completeScreenData?.chapter
    })
    const { Question_uuid: uuid } = currentQuestionSet[activeChildrenIndex] || {}
    const questionId = isRetake
      ? currentQuestionSet[activeChildrenIndex + 1] ? uuid : ''
      : currentQuestionUUID
    if (prevInstructor !== currentInstructor) {
      this.setState({
        filteredActvQuestInd: 0,
        showfinish: false,
        currentQuestionUUID: questionId || ''
      })
    }

    const newQuestionSet = !isEqual(previousQuestionSet, currentQuestionSet)
    if (prevQuestionUUID !== currentQuestionUUID || newQuestionSet) {
      newQuestionSet && this.loadDesmosScript()
      const { filteredActvQuestInd, isProblemBank } = this.state
      const { questionSet, type_of_question: sectionType } = this.props
      const isActiveLearning = sectionType === ACTIVE_LEARNING
      if (!isActiveLearning &&
        filteredActvQuestInd === questionSet.length - 1) return

      const questionUUID = isProblemBank ? this.getProblemSetNextUUID() || currentQuestionUUID
        : questionId
      this.setFilteredQuestionInd(questionUUID)
      this.updateCurrentQuestionUUId(questionUUID)
      this.setState({ currentQuestionUUID: questionUUID || '' })
    }
  }

  getProblemSetNextUUID = () => {
    const { isInitialRender } = this.state
    const { currentProblemSet, currentProblemSetResponse } = this.context
    if (!currentProblemSetResponse || !currentProblemSet) return
    const hasPopQuestion = currentProblemSet.length === this.numberOfQuestions + 1
    const { trials, hasPopupQuestionAttempted, isPracticeCompleted } = currentProblemSetResponse
    const trialsLength = (trials && trials.length) || 0
    let questionIndex = this.state.filteredActvQuestInd
    if (!isPracticeCompleted && !isInitialRender) {
      if (hasPopQuestion && hasPopupQuestionAttempted) {
        questionIndex = (trialsLength + 1) % currentProblemSet.length
      } else {
        questionIndex = trialsLength % this.numberOfQuestions
      }
      this.setState({ isInitialRender: true })
    }
    return currentProblemSet[questionIndex] && currentProblemSet[questionIndex].Question_uuid
  }

  filteredquestionSetFun () {
    const {
      type_of_question: sectionType,
      currentInstructor,
      questionSet
    } = this.props

    if (currentInstructor && (sectionType !== GUESSWORK &&
      sectionType !== QUIZ && sectionType !== EXAM &&
      sectionType !== PRACTICE_EXERCISES)) {
      const filteredQuestionSet = questionSet.filter(el => {
        return el.instructor.instructor_uuid === currentInstructor
      })
      return filteredQuestionSet
    } else {
      return questionSet
    }
  }

  updateOnCheck (studentAnswerRes) {
    const { type_of_question: sectionType } = this.props
    if (!studentAnswerRes) return
    const { isProblemBank } = this.state
    if (sectionType !== QUIZ && sectionType !== EXAM && !isProblemBank) return
    emitter.emit(ON_SUBMIT_ANSWER, {
      ...studentAnswerRes,
      type: ACTIVITY_TYPES[sectionType]
    })
  }

  onClickNext (studentAnswerRes) {
    const { type_of_question: sectionType } = this.props
    const {
      filteredActvQuestInd,
      question: {
        question_type: questionType
      }
    } = this.state
    const filteredQuest = this.filteredquestionSetFun()
    if (filteredActvQuestInd < filteredQuest.length - 1) {
      this.updateCurrentQuestionUUId(
        filteredQuest[filteredActvQuestInd + 1].Question_uuid
      )
      this.setState({
        filteredActvQuestInd: filteredActvQuestInd + 1
      })
      if (studentAnswerRes &&
          ((sectionType === ACTIVE_LEARNING && questionType !== CARD) ||
            sectionType === GUESSWORK)) {
        emitter.emit(ON_SUBMIT_ANSWER, {
          ...studentAnswerRes,
          type: ACTIVITY_TYPES[sectionType]
        })
      }
      window.outlierLog('Click Next', 'Question')
    }

    this.resetProblemBankQuestionSet()
    this.enablePrevious()
    return this.enableFinish()
  }

  resetProblemBankQuestionSet = async () => {
    const { isProblemBank, filteredActvQuestInd } = this.state
    if (!isProblemBank ||
      filteredActvQuestInd < this.props.questionSet.length - 1) return
    const { problemBank, currentProblemSetUUID, activeSectionUUID,
      currentQuestion: { data: { courseSections } } } = this.props
    const currentProblemSet = await getProblemSetQuestions(this.context,
      { problemBank, currentProblemSetUUID },
      { activeSectionUUID, courseSections })
    if (!currentProblemSet || currentProblemSet.length === 0) {
      emitter.emit(ON_NAVIGATE_TO, `/${activeSectionUUID}/practice_exercises`)
      return
    }
    const nextQuestionUUID = currentProblemSet[0].Question_uuid
    this.setState({ filteredActvQuestInd: 0, showPrevious: false })
    this.updateCurrentQuestionUUId(nextQuestionUUID)
    emitter.emit(ON_NAVIGATE_TO, isProblemBank
      ? `/${activeSectionUUID}/${currentProblemSetUUID}_${nextQuestionUUID}`
      : `/${activeSectionUUID}/${nextQuestionUUID}`)
  }

  onReviewClickNext () {
    const { filteredActvQuestInd } = this.state
    const filteredQuest = this.filteredquestionSetFun()

    if (filteredActvQuestInd < filteredQuest.length - 1) {
      this.updateCurrentQuestionUUId(
        filteredQuest[filteredActvQuestInd + 1].Question_uuid
      )
      this.setState({ filteredActvQuestInd: filteredActvQuestInd + 1 })
      window.outlierLog('Click Next', 'Question')
    }
    this.enablePrevious()
    return this.enableFinish()
  }

  onClickPrevious () {
    const { filteredActvQuestInd } = this.state
    const filteredQuest = this.filteredquestionSetFun()

    if ((filteredActvQuestInd - 1) === 0) this.setState({ showPrevious: false })
    if (filteredActvQuestInd <= filteredQuest.length - 1) {
      this.updateCurrentQuestionUUId(
        filteredQuest[filteredActvQuestInd - 1].Question_uuid
      )
      this.setState({ filteredActvQuestInd: filteredActvQuestInd - 1 })

      window.outlierLog('Click Previous Question', 'Question')
    }
  }

  setFilteredQuestionInd (uuid) {
    const { isProblemBank } = this.state
    const filteredQuestionSet = [...this.filteredquestionSetFun()]

    filteredQuestionSet.forEach((question, index) => {
      const { Question_uuid: questionUUID } = question
      if (questionUUID === uuid) {
        this.setShowPrevious(index > 0)
        this.setState({
          filteredActvQuestInd: index,
          showfinish: !isProblemBank && index + 1 === filteredQuestionSet.length
        })
        filteredQuestionSet.length = 0
      }
      return undefined
    })
  }

  enableFinish () {
    const { filteredActvQuestInd, isProblemBank } = this.state
    const filteredQuest = this.filteredquestionSetFun()
    const showfinish =
      !isProblemBank && filteredActvQuestInd + 1 === filteredQuest.length
    this.setShowFinishContext(showfinish)
    return this.setState({ showfinish })
  }

  setShowPrevious (showPrevious) {
    this.setState({ showPrevious })
  }

  enablePrevious () {
    this.setShowPrevious(true)
  }

  componentWillUnmount () {
    const { examLockEvent, updateContext } = this.context
    examLockEvent && examLockEvent.close()
    updateContext({ showFinish: false, currentQuestionSet: undefined, examLockEvent: null })
  }

  getStudentContextKeyBySectionType () {
    const {
      currentChapter: { type }
    } = this.context
    const activeLearningCompleteKey = getActiveLearningCompleteKey(type)
    const {
      type_of_question: sectionType
    } = this.props
    if (sectionType === GUESSWORK) {
      return GUESSWORK_COMPLETE
    } else if (sectionType === EXAM) {
      return EXAM_COMPLETE
    } else if (sectionType === ACTIVE_LEARNING) {
      return activeLearningCompleteKey
    }
    return ''
  }

  finishQuestionSet (studentAnswerRes) {
    const { question_type: questionType } = this.state.question
    const {
      type_of_question: sectionType,
      questionSet,
      sectionUUID,
      completeScreenData,
      section_ending_uuid: sectionEndingUUID
    } = this.props
    const { examRetake, cohortData } = this.context
    const { chapter, chapter: { title = '' } = {} } = completeScreenData || {}
    const isExam = sectionType === EXAM
    const isRetakeAllowed = isExamRetakeType({
      examRetake,
      cohortId: cohortData.cohortID,
      chapter
    })
    let uuid = sectionUUID

    if (isExam && isRetakeAllowed) {
      const { data: { versionUUID } = {} } = completeScreenData || {}
      uuid = versionUUID
    }

    if (isPracticeExam(sectionUUID)) {
      this.finishPracticeExam()
      return
    }

    if (studentAnswerRes &&
        ((sectionType === ACTIVE_LEARNING && questionType !== CARD) ||
          sectionType === GUESSWORK)) {
      emitter.emit(ON_SUBMIT_ANSWER, {
        ...studentAnswerRes,
        type: ACTIVITY_TYPES[sectionType]
      })
    }

    if (sectionType === QUIZ || sectionType === EXAM) {
      const { studentAnswers } = this.context.studentData
      const blankAnswers = hasBlankAnswers(studentAnswers, questionSet)
      questionSet[0] && this.updateCurrentQuestionUUId(questionSet[0].Question_uuid)
      if (blankAnswers) {
        return emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/${sectionEndingUUID}`)
      } else {
        const examAnswerList = getAnswerForExamQuestions(studentAnswers, questionSet)
        emitter.emit(ON_EXAM_COMPLETE, { examAnswerList })
      }
    }

    const studentContextKey = this.getStudentContextKeyBySectionType()
    const isActiveLearningComplete = [
      ACTIVE_LEARNING_COMPLETE,
      REVIEW_ACTIVE_LEARNING_COMPLETE,
      ORIENTATION_ACTIVE_LEARNING_COMPLETE
    ].includes(studentContextKey)

    // Calculate percentage per seciton
    if (studentContextKey && !isActiveLearningComplete) {
      emitter.emit(
        ON_SECTION_DATA_PROGRESS,
        {
          key: studentContextKey,
          sectionUUID: uuid,
          value: true,
          title,
          isMidExam: isMidExam(chapter)
        }
      )
    }
    if (sectionUUID && !isActiveLearningComplete &&
      sectionType !== QUIZ) {
      emitter.emit(ON_SECTION_PROGRESS, uuid)
      emitter.emit(ON_MINIMUM_SECTION_PROGRESS, uuid)
    } else if (sectionUUID && isActiveLearningComplete) {
      const {
        currentChapter: { type },
        studentData,
        cohortData: { duration, name: cohort },
        currentActiveLearningTheme
      } = this.context

      const saveMinimumSectionProgress = shouldSaveMinimumSectionProgress({ type })
      const studentAnswersKey = getStudentAnswersKey(type)
      const studentAnswers = studentData[studentAnswersKey]
      const studentAnswersList = studentAnswers.map(studentAnswer => studentAnswer.uuid)
      const questionList = questionSet
      const unAnsweredQuestionList =
      questionList.filter(question => !studentAnswersList.includes(question.Question_uuid))
      const unAnsweredQuestions = groupBy(unAnsweredQuestionList,
        unAnsweredQuestion => unAnsweredQuestion.instructor?.['theme_name'] || '')
      const breadcrumbArr = completeScreenData.breadcrumbArr
      const unAnsweredCount =
      unAnsweredQuestions[currentActiveLearningTheme]?.length
      const hasUnAnsweredQuestions = !!Object.keys(unAnsweredQuestions).length
      emitter.emit(
        ON_SECTION_DATA_PROGRESS,
        {
          key: studentContextKey,
          sectionUUID,
          value: !hasUnAnsweredQuestions,
          title,
          isMidExam: isMidExam(chapter)
        }
      )
      if (!unAnsweredCount) {
        saveMinimumSectionProgress && emitter.emit(ON_MINIMUM_SECTION_PROGRESS, sectionUUID)
      }
      if (!hasUnAnsweredQuestions) {
        emitter.emit(ON_SECTION_PROGRESS, sectionUUID)
      }
      const eventData = {
        properties: {
          course: breadcrumbArr[0][0],
          cohort_length: duration,
          cohort,
          sub_chapter: breadcrumbArr[2][0],
          chapter: breadcrumbArr[1][0],
          time_stamp: new Date().getTime(),
          ...(currentActiveLearningTheme) &&
          { theme_name: currentActiveLearningTheme }
        },
        event: ACTIVE_LEARNING_COMPLETED,
        frequency: MULTIPLE
      }
      emitter.emit(ON_TRACK_STUDENT_EVENT, eventData)
    }
    if (!sectionEndingUUID) {
      if (sectionType !== QUIZ) {
        return emitter.emit(ON_SKIP_SECTION, sectionUUID)
      }
      return emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/quizzes`)
    }
    emitter.emit(ON_IS_LAST_SECTION)
    emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/${sectionEndingUUID}`)
  }

  finishPracticeExam = () => {
    const { studentAnswers } = this.context.studentData
    const {
      questionSet,
      sectionUUID,
      completeScreenData,
      section_ending_uuid: sectionEndingUUID
    } = this.props

    const { chapter, chapter: { title = '' } = {} } = completeScreenData || {}
    const examAnswerList = getAnswerForExamQuestions(studentAnswers, questionSet)

    emitter.emit(ON_EXAM_COMPLETE, { examAnswerList })
    emitter.emit(
      ON_SECTION_DATA_PROGRESS,
      {
        key: EXAM_COMPLETE,
        sectionUUID,
        value: true,
        title,
        isMidExam: isMidExam(chapter)
      }
    )
    emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/${sectionEndingUUID}`)
  }

  renderQuestionWrapper (filteredQuestionSet, isViewLesson) {
    const {
      question,
      currentQuestionUUID,
      showfinish,
      showPrevious } = this.state
    if (!question) {
      return
    }

    // Remove LSEP symbols
    question.options = question.options?.map(option => (
      option && option.replace(/\u2028/g, '')
    ))
    question.text_when_incorrect = question.text_when_incorrect?.map(data => (
      data.replace(/\u2028/g, '')
    ))
    question.text_when_correct = question.text_when_correct?.replace(/\u2028/g, '')

    const answerOptions = question.answer.length === 1
      ? question.answer[0].split(',')
      : question.answer

    const { question_type: questionType } = question
    const wirisLoaded = this.isWirisLoaded()

    const loadQuestionComponent = questionType !== FREE_FORM || wirisLoaded

    if (!loadQuestionComponent) return

    const {
      type_of_question: sectionType,
      questionSet,
      currentProblemSetUUID,
      problemBank,
      section_ending_uuid: sectionEndingUUID, activeSectionUUID,
      currentQuestion: { practiceUUID: currentQuestionPracticeUUID },
      review_mode: reviewMode,
      view_lesson: viewLesson,
      activeChildrenIndex,
      completeScreenData,
      currentQuestion: { data: { courseSections } = {} } = {}
    } = this.props

    const isQuizOrExam = sectionType === QUIZ || sectionType === EXAM
    question.only_allow_one = question.only_allow_one ||
      (answerOptions.length === 1 && !isQuizOrExam)

    const { localStorageKey, filteredActvQuestInd } = this.state
    const { currentProblemSetResponse, isAssessmentQuizExam } = this.context
    return <QuestionWrapper
      activeChildrenIndex={activeChildrenIndex}
      completeScreenData={completeScreenData}
      question={question}
      questionSetUUID={currentQuestionPracticeUUID}
      currentProblemSetUUID={currentProblemSetUUID}
      currentProblemSetResponse={currentProblemSetResponse}
      filteredActvQuestInd={filteredActvQuestInd}
      problemBank={problemBank}
      onFinishClick={this.finishQuestionSet}
      onNextButtonClick={this.onClickNext}
      onReviewClickNext={this.onReviewClickNext}
      currentQuestionUUID={currentQuestionUUID}
      showfinish={showfinish}
      updateCurrentQuestionUUId={this.updateCurrentQuestionUUId}
      setFilteredQuestionInd={this.setFilteredQuestionInd}
      type_of_question={sectionType}
      activeSectionUUID={activeSectionUUID}
      updateOnCheck={this.updateOnCheck}
      review_mode={sectionType !== PRACTICE_EXERCISES ? reviewMode : undefined}
      view_lesson={sectionType !== PRACTICE_EXERCISES ? viewLesson : undefined}
      onPreviousButtonClick={sectionType === PRACTICE_EXERCISES ||
        isAssessmentQuizExam || filteredQuestionSet ? this.onClickPrevious
        : undefined}
      showPrevious={sectionType === PRACTICE_EXERCISES ||
        isAssessmentQuizExam || filteredQuestionSet ? showPrevious : undefined}
      isFirstQuestion={!showPrevious}
      practiceUUID={sectionType === PRACTICE_EXERCISES ||
        filteredQuestionSet ? currentQuestionPracticeUUID : undefined}
      currentQuestionSet={filteredQuestionSet || questionSet}
      section_ending_uuid={isViewLesson ? viewLesson : sectionEndingUUID}
      localStorageKey={localStorageKey}
      breadCrumb={this.props.breadCrumb}
      questionSet={questionSet}
      courseSections={courseSections}
    />
  }

  getActiveIndex (questionSet) {
    const { navigateToActiveIndex } = this.context
    const { filteredActvQuestInd, currentQuestionUUID } = this.state
    if (navigateToActiveIndex || filteredActvQuestInd) return filteredActvQuestInd
    return questionSet.findIndex(item => {
      return item.Question_uuid === currentQuestionUUID
    })
  }

  isWirisLoaded = () => {
    return !!(window.com && window.com.wiris && window.com.wiris.quizzes)
  }

  setShowFinishContext (showFinish) {
    if (showFinish) {
      this.context.updateContext({ showFinish })
    }
  }

  render () {
    const {
      questionSet,
      currentInstructor,
      activeSectionUUID,
      type_of_question: sectionType,
      review_mode: reviewMode,
      setDisableAIViolationModal
    } = this.props
    const {
      courseData,
      isStudentProgressBusy,
      course,
      currentProblemSetResponse,
      isALRedesign,
      cohortData,
      examsUnlockedWithKeys
    } = this.context
    const {
      isMathJaxLoading,
      isDesmosLoading,
      currentQuestionUUID,
      isProblemBank,
      question: {
        desmos_graph: desmosGraph,
        question_type: questionType
      } = {},
      shouldByPassExamKeys
    } = this.state

    const {
      relationship: { fields: { liveProctoring } = {} } = {}
    } = cohortData || {}

    const showLoadingSpinner = isStudentProgressBusy && ![
      PRACTICE_EXERCISES,
      QUIZ,
      ACTIVE_LEARNING,
      EXAM
    ].includes(sectionType)

    const isExam = sectionType === EXAM

    const waitForDesmos = desmosGraph && !window.Desmos
    if (isMathJaxLoading || isDesmosLoading || waitForDesmos) return null

    const wirisLoaded = this.isWirisLoaded()
    const loadQuestionComponent = questionType !== FREE_FORM || wirisLoaded

    if (!loadQuestionComponent) return null

    const sectionDisplay = !showLoadingSpinner ? 'display-block'
      : 'display-none'
    if (sectionType && (sectionType === GUESSWORK || sectionType === QUIZ ||
      isExam || sectionType === PRACTICE_EXERCISES)) {
      // studentAnswersToQuestions will have array of objects from following funcion
      const studentAnswersToQuestions = fillQuestions(questionSet)

      const isPracticeExercise = sectionType === PRACTICE_EXERCISES
      const isPracExerciseRedesign = isPracticeExercise && isProblemBank

      const isLiveProctoring = liveProctoring
      const { examKeyName } = getExamKeyFieldNames(courseData, activeSectionUUID)

      const showExamKeysPage = isLiveProctoring &&
        isExam &&
        !reviewMode &&
        !examsUnlockedWithKeys?.[activeSectionUUID] &&
        !shouldByPassExamKeys

      return (
        <TabPane>
          {showExamKeysPage
            ? <ExamKeysPage
              examUUID={activeSectionUUID}
              examKeyName={examKeyName}
              cohortId={cohortData.cohortID}
              setDisableAIViolationModal={setDisableAIViolationModal}
            />
            : <>
              {showLoadingSpinner && <LoadingSpinner />}
              {!isPracExerciseRedesign &&
              <QuestionSetProgress
                numberOfQuestions={this.numberOfQuestions}
                testResults={studentAnswersToQuestions}
                context={this.context}
                currentQuestion={questionSet}
                currentProblemSetResponse={currentProblemSetResponse}
                updateQuestionUUId={this.updateCurrentQuestionUUId}
                activeQuestionInd={this.getActiveIndex(questionSet)}
                activeSectionUUID={activeSectionUUID}
                setFilteredQuestionInd={this.setFilteredQuestionInd}
                currentQuestionUUID={currentQuestionUUID}
                currentInstructor=''
                type_of_question={sectionType}
                slideBubbleToLatest
                reviewMode={reviewMode}
              />}
              <div className={sectionDisplay}>
                {this.renderQuestionWrapper()}
              </div>
            </>}
        </TabPane>
      )
    } else {
      let filteredQuestionSet = []
      const isCourseWithoutTheme = !config.course?.hasActiveLearningTheme
      if (isCourseWithoutTheme) {
        filteredQuestionSet = questionSet
      } else {
        filteredQuestionSet = questionSet.filter(el => {
          return el.instructor.instructor_uuid === course['currentInstructor']
        })
      }
      // studentAnswersToQuestions will have array of objects from following funcion
      const studentAnswersToQuestions = fillQuestions(filteredQuestionSet)

      return (
        <TabPane tabId={currentInstructor}>
          <>
            {showLoadingSpinner && <LoadingSpinner />}
            {sectionType && sectionType !== PRACTICE_EXERCISES &&
            <QuestionSetProgress
              testResults={studentAnswersToQuestions}
              context={this.context}
              currentQuestion={filteredQuestionSet}
              updateQuestionUUId={this.updateCurrentQuestionUUId}
              activeQuestionInd={this.getActiveIndex(filteredQuestionSet)}
              activeSectionUUID={activeSectionUUID}
              setFilteredQuestionInd={this.setFilteredQuestionInd}
              currentQuestionUUID={currentQuestionUUID}
              currentInstructor={currentInstructor || ''}
              type_of_question={sectionType}
              slideBubbleToLatest
              reviewMode={reviewMode}
              {...(isALRedesign && {
                showSidePanel: true
              })}
            />
            }
            <div className={sectionDisplay}>
              {this.renderQuestionWrapper(filteredQuestionSet, true)}
            </div>
          </>
        </TabPane>
      )
    }
  }
}

InstructorQuestionSet.contextType = Context
export default InstructorQuestionSet
