import { stringifyJSON } from './examUtils'

const jdoodleCodeKey = 'jdoodleSavedCode'

export const saveCodeToLocalStorage = code => {
  const { set } = localStorageJdoodleCode(jdoodleCodeKey)
  set(code)
}

export const getCodeFromLocalStorage = questionUUID => {
  const { get, remove } = localStorageJdoodleCode(jdoodleCodeKey)
  try {
    const savedCode = JSON.parse(get() || '{}')
    return savedCode[questionUUID] || null
  } catch (e) {
    remove()
    return null
  }
}

export const removeCodeFromLocalStorage = questionUUID => {
  const { get, set, remove } = localStorageJdoodleCode(jdoodleCodeKey)
  const savedCode = JSON.parse(get() || '{}')
  delete savedCode[questionUUID]
  remove()
  savedCode && Object.keys(savedCode)?.length && set(savedCode)
}

export const localStorageJdoodleCode = key => {
  const get = () => {
    return localStorage.getItem(key)
  }

  const set = code => {
    if (!code) return

    const savedCode = {
      ...JSON.parse(get() || '{}'),
      ...code
    }
    const objectToStore = stringifyJSON(savedCode)
    if (!objectToStore) return

    localStorage.setItem(key, objectToStore)
  }

  const remove = () => {
    localStorage.removeItem(key)
  }

  return { get, set, remove }
}

export const formatCode = (code) => {
  return {
    value: {
      schema: 'dast',
      document: {
        type: 'root',
        children: [
          {
            code,
            type: 'code',
            language: 'java'
          }
        ]
      }
    }
  }
}
