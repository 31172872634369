import React from 'react'

function CheckIcon () {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12 19C10.1435 19 8.36301 18.2625 7.05025 16.9497C5.7375 15.637 5 13.8565 5 12C5 10.1435 5.7375 8.36301 7.05025 7.05025C8.36301 5.7375 10.1435 5 12 5C13.8565 5 15.637 5.7375 16.9497 7.05025C18.2625 8.36301 19 10.1435 19 12C19 13.8565 18.2625 15.637 16.9497 16.9497C15.637 18.2625 13.8565 19 12 19ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20Z' fill='#98A4AE' />
      <path d='M14.9699 8.96997C14.9627 8.97688 14.956 8.98423 14.9499 8.99198L11.4769 13.417L9.38385 11.323C9.24168 11.1905 9.05363 11.1184 8.85933 11.1218C8.66503 11.1252 8.47964 11.2039 8.34223 11.3414C8.20482 11.4788 8.1261 11.6642 8.12268 11.8585C8.11925 12.0528 8.19137 12.2408 8.32385 12.383L10.9699 15.03C11.0411 15.1011 11.126 15.1572 11.2194 15.1948C11.3129 15.2325 11.4129 15.2509 11.5136 15.249C11.6143 15.2472 11.7136 15.225 11.8055 15.184C11.8975 15.1429 11.9803 15.0837 12.0489 15.01L16.0409 10.02C16.1768 9.87731 16.2511 9.68687 16.2477 9.48984C16.2444 9.29282 16.1636 9.10502 16.0229 8.96707C15.8822 8.82912 15.6928 8.75208 15.4958 8.75263C15.2987 8.75317 15.1098 8.83125 14.9699 8.96997Z' fill='#98A4AE' />
    </svg>
  )
}

export default CheckIcon
