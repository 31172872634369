import React, { useContext, useState, useEffect } from 'react'
import Context from '../../Context/Context'
import {
  InstructorBioModalContainer,
  InstructorHeader,
  InstructorHeaderWrapper,
  SliderCounter,
  InstructorsContainer,
  GlobalInstructorBioStyle,
  StyledSlider,
  InstructorsGridWrapper,
  InstructorsWrapper
} from './styled'
import SingleInstructor from './SingleInstructor'
import shuffle from 'lodash/shuffle'
import InstructorBioModal from '../../InstructorBioModal/InstructorBioModal'
import ShowOrHideAll from '../ShowOrHideAll'

const Instructors = ({ setIsLecturersLoading }) => {
  const [showInstructorBio, setShowInstructorBio] = useState(false)
  const [randomizedInstructors, setRandomizedInstructors] = useState([])
  const [bioInstructor, setBioInstructor] = useState({})
  const [showAllInstructors, setShowAllInstructors] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(1)
  const { courseData: { instructor: courseInstructors } } = useContext(Context)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    if (!courseInstructors) return

    setRandomizedInstructors(
      shuffle(
        [...courseInstructors].filter(
          courseInstructor => courseInstructor.image
        )
      )
    )
  }, [courseInstructors])

  useEffect(() => {
    const updateDimensions = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', updateDimensions)

    return () => window.removeEventListener('resize', updateDimensions)
  }, [screenWidth])

  const numberOfInstructorsShowingForViewAll = screenWidth > 992 ? 4 : 3
  const showOrHideAllText = `${showAllInstructors ? '' : 'All '}Lecturers`

  const handleCloseInstructorBio = () => {
    setShowInstructorBio(false)
    setBioInstructor({})
  }
  const getInstructor = name =>
    courseInstructors.find(instructor => name === instructor.name)

  const getAllInstructors = () => {
    if (!randomizedInstructors) return

    return randomizedInstructors.map(instructor => {
      const { name, image } = instructor
      if (!image) return null

      return (
        <SingleInstructor
          setShowInstructorBio={setShowInstructorBio}
          setBioInstructor={setBioInstructor}
          getInstructor={getInstructor}
          instructor={instructor}
          key={`${image}-${name}`}
        />
      )
    })
  }

  const countOfAllInstructors = randomizedInstructors.length
  const carouselSettings = {
    centerMode: true,
    centerPadding: 0,
    slidesToShow: 1,
    infinite: true,
    slidesToScroll: 1,
    // This is based on react-slick props, for more info:
    // https://react-slick.neostack.com/docs/api#beforeChange
    beforeChange: (current, next) => setCurrentSlide(next + 1),
    variableWidth: true
  }

  const { name } = bioInstructor
  setIsLecturersLoading && setIsLecturersLoading(false)

  return (
    <InstructorsWrapper className='instructor-wrapper'>
      {
        courseInstructors?.length ? (
          <>
            <InstructorHeaderWrapper>
              <InstructorHeader>Lecturers</InstructorHeader>
              <SliderCounter>
                {currentSlide}/{countOfAllInstructors}
              </SliderCounter>
            </InstructorHeaderWrapper>
            {randomizedInstructors && randomizedInstructors.length && (
              <StyledSlider {...carouselSettings}>{getAllInstructors()}</StyledSlider>
            )}
            <InstructorsGridWrapper data-cy='instructorWrapper'>
              <InstructorsContainer>
                {showAllInstructors
                  ? getAllInstructors()
                  : getAllInstructors().slice(
                    0,
                    numberOfInstructorsShowingForViewAll
                  )}
              </InstructorsContainer>
              {countOfAllInstructors > numberOfInstructorsShowingForViewAll && (
                <ShowOrHideAll
                  text={showOrHideAllText}
                  showAll={showAllInstructors}
                  setShowAll={setShowAllInstructors}
                  paddingTop='22'
                />
              )}
            </InstructorsGridWrapper>

            {showInstructorBio && (
              <InstructorBioModalContainer>
                <GlobalInstructorBioStyle />
                <InstructorBioModal
                  show={showInstructorBio}
                  handleClose={handleCloseInstructorBio}
                  headerContent={name}
                  content={bioInstructor}
                  showInstitution
                />
              </InstructorBioModalContainer>
            )}
          </>
        ) : null
      }
    </InstructorsWrapper>
  )
}

export default Instructors
