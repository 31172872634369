import React, { useEffect, useRef, useContext } from 'react'
import * as typeformEmbed from '@typeform/embed'
import Context from '../Context/Context'
import { emitter } from '../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'
import api from '../../api'
import {
  addDaysToDateInSecs,
  getTimezoneShort,
  secondsToFormattedDateTimeShort
} from '../../utilities/dateTimeUtils'
import { COURSE_FEATURE_KEY, GGU_CONTACT_INSTRUCTOR_PATH } from '../../Constants'
import {
  Description,
  DeadlineSection,
  OriginalDeadline,
  RequestedExtensionDeadline,
  TypeFormContainer
} from './styled'

function RequestablePage (props) {
  const {
    assessmentID,
    assessmentTitle,
    cohortID,
    courseTitle,
    lockDate
  } = props

  const formRef = useRef(null)
  const {
    studentId,
    updateContext,
    cohortData: { isGGUCohort },
    courseFeatures: {
      [COURSE_FEATURE_KEY]: extendedIds = []
    } = {},
    setCourseFeatures
  } = useContext(Context)
  const extensionDays = isGGUCohort ? 3 : 1

  const originalDeadline =
    secondsToFormattedDateTimeShort(lockDate).replace(', ', ' at ')
  const extendedDeadline =
    secondsToFormattedDateTimeShort(
      addDaysToDateInSecs(lockDate, extensionDays))
      .replace(', ', ' at ')
  const timezone = getTimezoneShort(lockDate)

  const formId = isGGUCohort ? 'pu6YkyQ1' : 'UdmLLcS1'

  useEffect(() => {
    if (!formRef.current) return
    const embedForm = async () => {
      const encodedStudentId = encodeURIComponent(studentId)
      typeformEmbed.makeWidget(
        formRef.current,
        `https://form.typeform.com/to/${formId}?student_id=${encodedStudentId}&cohort_id=${cohortID}&assessment_id=${assessmentID}`,
        {
          transferableUrlParameters: ['student_id', 'cohort_id', 'assessment_id'],
          onSubmit: async () => {
            const idList = [...extendedIds, assessmentID]
            setCourseFeatures(COURSE_FEATURE_KEY, idList)
            const response = await api.setCourseFeatures(COURSE_FEATURE_KEY, idList)

            if (response.error) return

            emitter.emit(ON_NAVIGATE_TO, '/')
            localStorage.setItem('extensionToast', JSON.stringify({
              assessmentTitle,
              courseTitle,
              extendedDeadline,
              assessmentID,
              timezone
            }))
            window.location.reload()
          }
        }
      )
    }

    embedForm()
  }, [
    formId,
    extendedIds,
    setCourseFeatures,
    updateContext,
    formRef,
    studentId,
    cohortID,
    courseTitle,
    assessmentID,
    assessmentTitle,
    extendedDeadline,
    timezone
  ])

  const contactText = () => {
    return isGGUCohort ? (
      <a
        href={GGU_CONTACT_INSTRUCTOR_PATH}
        target='_blank'
        rel='noopener noreferrer'
        style={{ textDecoration: 'underline' }}
      >
        contact your instructor
      </a>
    ) : (
      <>
        contact the student success team directly at{' '}
        <a href='mailto:success@outlier.org'>success@outlier.org</a>
      </>
    )
  }

  return (
    <>
      <Description>
        Below is the deadline extension request form that you must complete
        to be granted an extension. Any student may request a {extensionDays * 24}-hour
        extension on any given exam. There is no penalty for requesting an extension and
        all request reasons are valid. If you need an additional extension
        due to extenuating circumstances, {contactText()}.
      </Description>
      <DeadlineSection>
        <OriginalDeadline>
          Original deadline: {originalDeadline} {timezone}
        </OriginalDeadline>
        <RequestedExtensionDeadline>
          Extended deadline: {extendedDeadline} {timezone}
        </RequestedExtensionDeadline>
      </DeadlineSection>
      <TypeFormContainer ref={formRef} />
    </>
  )
}

RequestablePage.displayName = 'RequestablePage'
export default RequestablePage
