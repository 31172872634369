import React from 'react'
import PropTypes from 'prop-types'
import { StyledSpinner, StyledWrapper } from './Loader.styled'

const modeTypes = {
  card: 'rgba(0, 0, 0, 0.5)',
  placeholder: 'transparent'
}

const sizeMapping = {
  small: 20,
  medium: 40,
  large: 60
}

const Loader = ({
  mode = 'card',
  size = 'medium',
  color,
  animation = 'border',
  position,
  marginTop,
  marginLeft
}) => {
  const background = modeTypes[mode]

  return (
    <StyledWrapper
      bg={background}
      position={position}
      marginTop={marginTop}
      marginLeft={marginLeft}
    >
      <StyledSpinner
        _size={sizeMapping[size]}
        color={color}
        animation={animation}
      />
    </StyledWrapper>
  )
}

Loader.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  mode: PropTypes.oneOf(['card', 'placeholder']),
  color: PropTypes.string,
  animation: PropTypes.oneOf(['border', 'grow'])
}

export default Loader
