import styled from 'styled-components'

// Heading 1
export const PageTitle = styled.h1`
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 48px;
`

export const ContentTitle = styled.h3`
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
