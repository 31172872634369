import React, { useEffect, useRef } from 'react'
import api from '../../api'
import config from '../../config'

const KalturaV7Player = ({
  hashedId,
  isActive,
  initialProgress,
  onTimeChange,
  onBuffer,
  onPlayEnd,
  isLectureVideo
}) => {
  const playerId = `kaltura_player_${hashedId}`
  const player = useRef(null)

  const parseKs = (xmlString) => {
    const parser = new DOMParser()
    const data = parser.parseFromString(xmlString, 'text/xml')
    return data.getElementsByTagName('result')[0].childNodes[0].nodeValue
  }

  useEffect(() => {
    if (!player.current) return
    player.current.pause()

    // eslint-disable-next-line
  }, [isActive])

  useEffect(() => {
    const setupPlayer = async () => {
      const { PARTNER_ID, UICONF_ID } = config.kalturaV7Setting
      const kalturaSession = config.isPreviewCourse ? ''
        : parseKs(await api.generateKalturaSession())

      try {
        const videoConfig = {
          targetId: playerId,
          provider: {
            partnerId: PARTNER_ID,
            uiConfId: UICONF_ID,
            ks: kalturaSession
          }
        }

        const kalturaPlayer = window.KalturaPlayer.setup(videoConfig)
        kalturaPlayer.loadMedia({ entryId: hashedId })
        kalturaPlayer.ready().then(() => {
          handleV7PlayerReady()
        })
        player.current = kalturaPlayer
      } catch (e) {
        console.error(e.message)
      }
    }

    setupPlayer()

    return () => {
      if (!player.current) return
      player.current.destroy()
    }

    // eslint-disable-next-line
  }, [])

  const handleV7PlaybackRateChange = event => {
    const kalturaPlayer = player.current
    const newPlaybackRate = event.target.playbackRate

    localStorage.setItem('lectureVideoPlaybackRate', newPlaybackRate)
    kalturaPlayer.playbackRate = newPlaybackRate

    const kalturaPlayers = window.KalturaPlayer.getPlayers()
    if (!kalturaPlayers) return

    (Object.keys(kalturaPlayers) || [])
      .forEach((playerId, index, allPlayers) => {
        const currentPlayer = allPlayers[playerId]
        currentPlayer.playbackRate = newPlaybackRate
      })
  }

  const handleTimeChange = event => {
    if (!onTimeChange || !event.target) return
    onTimeChange(event.target.currentTime)
  }

  const handleBufferStart = () => {
    onBuffer && onBuffer()
  }

  const handlePlayEnd = () => {
    onPlayEnd && onPlayEnd()
    window.outlierLog('End', 'Video')
  }

  const handleV7PlayerReady = () => {
    const kalturaPlayer = player.current
    if (!kalturaPlayer) return

    const playbackRate = localStorage.getItem('lectureVideoPlaybackRate')
    if (playbackRate) kalturaPlayer.playbackRate = playbackRate
    if (initialProgress) kalturaPlayer.currentTime = initialProgress

    kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.PLAY, () => {
      window.outlierLog('Play', 'Video')
    })
    kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.PLAYING, handleBufferStart)
    kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.PAUSE, () => {
      window.outlierLog('Pause', 'Video')
    })
    kalturaPlayer.addEventListener(
      kalturaPlayer.Event.Core.RATE_CHANGE, handleV7PlaybackRateChange
    )

    if (!isLectureVideo) return
    kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.SEEKED, handleTimeChange)
    kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.TIME_UPDATE, handleTimeChange)
    kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.ENDED, handlePlayEnd)
  }

  return (
    <div id={playerId} className='embed-responsive-item kaltura-player' />
  )
}

KalturaV7Player.displayName = 'KalturaV7Player'

export default KalturaV7Player
