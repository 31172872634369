import React, { Component } from 'react'
import { TabContent } from 'reactstrap'
import Context from '../Context/Context'
import InstructorList from '../InstructorList/InstructorList'
import LectureVideo from '../LectureVideo/LectureVideo'
import NotesAndEditor from './NotesAndEditor'
import Tabs from './Tabs'
import config from '../../config'
import { emitter } from '../Emitter/Emitter'
import './css/lecture-video.css'
import { ON_INSTRUCTOR_SWITCH, ON_NAVIGATE_TO } from '../../Constants/emitterKeys'
import CourseMaterialDownload from '../CourseMaterialDownload/CourseMaterialDownload'
import {
  OverrideCSSProperties,
  LectureContainer,
  LectureInfo,
  LectureVideoContainer,
  NavigationSection,
  VideoTranscriptContainer,
  BreadCrumb
} from './styles'
import { isOrientationChapter } from '../../utilities/chapterUtils'

class Lecture extends Component {
  constructor (props) {
    super(props)
    this.setSectionLectureVideos = this.setSectionLectureVideos.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleClick = this.toggle.bind(this)
    this.onTabClick = this.onTabClick.bind(this)

    this.state = {
      activeTab: 0,
      currentInstructor: null,
      activeNavTab: 0,
      lectureVideos: []
    }
  }

  setSectionLectureVideos () {
    const { LectureVideos, sectionTitle, multipleVideos } = this.props

    if (!LectureVideos) return
    const { lecturevideos } = LectureVideos
    if (!lecturevideos) return
    let lectureVideos =
      lecturevideos.filter(lecture => lecture.title === sectionTitle)
    if (lectureVideos.length === 0) lectureVideos = lecturevideos
    multipleVideos && emitter.emit(ON_INSTRUCTOR_SWITCH,
      lectureVideos[0].instructor.instructor_uuid)
    this.setState({ lectureVideos })
  }

  componentDidMount () {
    if (this.props.shouldSplitLecturePage) {
      window.scrollTo(0, 0)
    }

    this.setSectionLectureVideos()
    const { course, updateContext } = this.context
    this.setState({
      activeTab: course['currentInstructor'] || 0,
      currentInstructor: course['currentInstructor'] || null
    })
    if (this.props?.LectureVideos && updateContext) {
      updateContext({
        currentQuestionSet: {
          ...this.props?.LectureVideos,
          type: 'lecture',
          multipleVideos: this.props?.multipleVideos
        }
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState &&
      this.context?.course?.currentInstructor &&
      prevState.currentInstructor !== this.context.course?.currentInstructor) {
      const { course } = this.context
      this.setState({
        activeTab: course['currentInstructor'] || 0,
        currentInstructor: course['currentInstructor'] || null
      })
    }
  }

  toggle (tab) {
    const { activeTab } = this.state
    if (activeTab) this.setState({ showInstructorList: false })
    if (activeTab !== tab) {
      this.setState({ activeTab: tab, currentInstructor: tab })
      emitter.emit(ON_INSTRUCTOR_SWITCH, tab)
    }
  }

  onTabClick (index) {
    this.setState({ activeNavTab: index })
  }

  getCourseDownload = () => {
    const { activeTab, lectureVideos } = this.state
    const video = lectureVideos.find(
      ({ instructor }) => instructor.instructor_uuid === activeTab
    )

    if (!lectureVideos.length) return
    // set course download to first instructor, if there is no instructor selected
    if (!video) return lectureVideos[0].course_download

    return video.course_download
  }

  render () {
    const {
      LectureVideos,
      sectionCompleteUUID,
      nextChildUUID,
      hasActiveLearning,
      multipleVideos,
      breadcrumbArr,
      isLastVideo,
      shouldSplitLecturePage,
      sectionTitle,
      sectionUUID: {
        breadcrumbArr: sectionBreadcrumbArr
      } = {}
    } = this.props
    const {
      lectureVideos,
      activeTab,
      currentInstructor,
      activeNavTab
    } = this.state
    if (!activeTab && lectureVideos.length) {
      const {
        instructor: {
          instructor_uuid: instructorUuid
        }
      } = lectureVideos[0]
      if (instructorUuid) this.toggle(instructorUuid)
    }
    if (!LectureVideos || !lectureVideos.length) return (<div data-testid='loader'>Loading...</div>)

    const { currentChapter: { type: chapterType } } = this.context
    const isChapterOrientation = isOrientationChapter({ type: chapterType })

    const courseDownload = this.getCourseDownload()
    const { isCalculusCourse, course: { useKalturaV7 } } = config
    const isCalculus = isCalculusCourse()

    return (
      <>
        <LectureContainer shouldSplitLecturePage={shouldSplitLecturePage} >
          {shouldSplitLecturePage
            ? <>
              <OverrideCSSProperties
                activeNavTab={activeNavTab}
              />
              <NavigationSection>
                <BreadCrumb onClick={() => {
                  emitter.emit(ON_NAVIGATE_TO, `/${sectionCompleteUUID}`)
                }}>
                  <span /> {sectionTitle}
                </BreadCrumb>
                <Tabs
                  activeNavTab={activeNavTab}
                  onTabClick={this.onTabClick}
                />
              </NavigationSection>
            </>
            : <LectureInfo isCalculus={isCalculus}>
              <InstructorList
                Instructors={lectureVideos}
                onInstructorSwitch={this.toggle}
                currentInstructor={currentInstructor}
                isChapterOrientation={isChapterOrientation}
              />
              <CourseMaterialDownload
                course_download={courseDownload}
              />
            </LectureInfo>
          }
          <VideoTranscriptContainer
            shouldSplitLecturePage={shouldSplitLecturePage}
            activeNavTab={activeNavTab}
            isKalturaV7={useKalturaV7}
          >
            <div className='position-relative'
              style={useKalturaV7
                ? { display: 'table-cell', verticalAlign: 'middle' } : undefined}>
              <LectureVideoContainer>
                <TabContent activeTab={activeTab}>
                  <LectureVideo
                    LectureVideos={lectureVideos}
                    breadcrumbArr={breadcrumbArr}
                    sectionUUID={sectionCompleteUUID}
                    nextChildUUID={nextChildUUID}
                    hasActiveLearning={hasActiveLearning}
                    isLastVideo={isLastVideo}
                    multipleVideos={multipleVideos}
                    activeTab={activeTab}
                    shouldSplitLecturePage={shouldSplitLecturePage}
                  />
                </TabContent>
              </LectureVideoContainer>
            </div>
          </VideoTranscriptContainer>
          {shouldSplitLecturePage && (
            <NotesAndEditor
              lectureVideos={LectureVideos}
              sectionBreadcrumbArr={sectionBreadcrumbArr}
              activeNavTab={activeNavTab}
              onTabClick={this.onTabClick}
            />
          )}
        </LectureContainer>
      </>
    )
  }
}

Lecture.contextType = Context
export default Lecture
