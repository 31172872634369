import styled, { css, createGlobalStyle } from 'styled-components'
import media from '../../../mediaQueries'

export const GlobalStyle = createGlobalStyle`
  #projected-grade-tooltip > .tooltip-inner {
    background-color: #25272B !important;
    max-width: 255px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5) !important;
    text-align: left;
    border-radius: 0px !important;
    padding: 8px 12px !important;
  }

  #projected-grade-tooltip > .tooltip.right .tooltip-arrow {
    top:50%;
    left:0;
    margin-top:-5px;
    border-top:5px solid transparent;
    border-bottom:5px solid transparent;
    border-right:5px solid #000
  }

  .tooltip.show {
    opacity: 1;
  }

  .section-intro__text {
    width: 100%;
  }
`

const resourceGradeSharedStyle = css`
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 17px;
  text-transform: uppercase;
`

export const ViewResourcesLink = styled.a`
  ${resourceGradeSharedStyle}
  position: relative;
  color: #5FC4B8 !important;
  display: flex;
  align-items: center;
  margin-right: 24px;

  &:disabled {
    color: #5fc4b8;
    opacity: 0.3;
  }
  &:not(:disabled) {
    &:active {
      color: #b8fff7;
      opacity: 0.3;
    }
    &:hover {
      filter: brightness(1.5);
    }
  }

  ${media.mobile`
    margin-bottom: 24px;
  `}
`

export const CourseHomeContainer = styled.div`
  width: 840px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`

export const CourseHomeHeader = styled.div`
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
`

export const CourseTitleContainer = styled.div`
  width: 100%;
  margin-top: 9px;
  margin-bottom: 48px;
  padding-bottom: 35px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  display: flex;
  justify-content: space-between;

  > h1 {
    margin-top: 0;
  }

  .info-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    row-gap: 6px;

    .info-date {
      width: fit-content;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #C1CFD9;
    }

    > a {
      margin-right: 0px;
    }
  }

  ${media.mobile`
    margin-top: 0;
    padding: 0 12px;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 12px;

    border-bottom: unset;

    .info-section {
      width: 100%;
      align-items: flex-start;
      padding-bottom: 35px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);

      > a {
        margin-bottom: 0;
      }
    }
  `}
`
export const CourseTitleGradeContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const GradeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  align-self: flex-start;
  background-color: #1D1D1F;
  font-size: 16px;
  color: #CCD4D8;
  ${props => props.showTealBar && `border-left: 3px solid #5FC4B8`};
  border-radius: 2px 5px 5px 2px;
`
export const GradeText = styled.span`
  color: white;
`

export const PendingGradeText = styled.span`
  font-size: 15px
`

export const CourseTitle = styled.h1`
  margin-top: 0;
  font-weight: 300;
  margin-bottom: ${({ marginBottom }) => marginBottom ? '24' : '0'}px;
  ${media.mobile`
    font-size: 32px;
  `}

  ${({ isTitleUpdates }) => (isTitleUpdates) && `
      font-size: 32px;
      font-weight: 300;
      font-style: normal;
      text-transform: none;
      line-height: 48px;
  `}
`

export const CourseWork = styled.h2`
  margin: 43px 0 8px;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
`

export const ResourceWrapper = styled.div`
  margin-bottom: 24px;
`

export const ResourceContent = styled.div`
  ${media.mobile`
    flex-direction: column;
    align-items: flex-start !important;
  `}
`

export const Dot = styled.div`
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: #B1BFC5;
  margin-right: 12px;
  ${media.mobile`
    display: none;
  `}
`

export const ProjectedGradeWrapper = styled.span`
  ${resourceGradeSharedStyle}
  color: #B1BFC5;
  margin-right: 12px;
`

export const ProjectedGradePercent = styled.span`
  color: var(--white);
`

export const TooltipIcon = styled.span`
  width: 16px;
  height: 16px;
  background-color: ${props => props.color ? props.color : 'var(--white)'};
  mask: url(/images/icons/icon-info.svg);
`

export const TooltipText = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--white);
  margin-right: 5px;
`

export const GradeToolTipText = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--white);
  white-space: pre-line;
`

export const Link = styled.a`
  display: inline;
  color: #5FC4B8;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
`

export const ShowHideText = styled.span`
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 60px;
  margin-top: 12px;
  ${media.small`
    margin-bottom: 65px;
  `}
  ${media.mobile`
    margin-bottom: 66px;
  `}
  ${media.tablet`
    margin-bottom: 63px;
  `}
`
export const ChapterToggleIcon = styled.span`
  margin-bottom: 1px;
  margin-right: 12px;
  cursor: pointer;
  mask: url(/images/icons/icon-angle-arrow-light.svg) no-repeat 50% 50%;
  width: 12px;
  height: 7px;
  background-color: white;
  ${props => !props.collapsed && ({
    transform: 'rotate(180deg)'
  })}
`
export const CardHeading = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
`
export const ProgressValue = styled.span`
  font-size: 16px;
  font-weight: 300;
  float: right;
  margin-top: 10px;
  line-height: 19px;
`
export const ImageStyler = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`
