import styled from 'styled-components'
import mediaQueries from '../../mediaQueries'

export const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #22292d;
  padding-bottom: 12px;
  padding-top: 12px;
  z-index: 1;
  ${mediaQueries.mobile`
    padding: 5px;
  `}
`

export const NeedsReviewMessage = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: white;
  font-weight: 400;
  text-align: center;
  margin: 0px;
  padding: 0px;
`

export const WarningIcon = styled.img`
  margin-right: 5px;
`
