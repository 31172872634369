import React from 'react'
import { getTimeAgoInMinsHoursAndDays } from '../../../utilities/dateTimeUtils'
import LoadingSpinner from '../../LoadingSpinner/LoadingAnimation'
import {
  ActionContainer,
  NotificationCourses,
  NotificationDetails,
  NotificationHeadline,
  NotificationText,
  NotificationTimeStamp
} from '../style'
import NotificationActionButtons from './NotificationActionButtons'

const NotificationList = ({ notifications, studentCourses }) => {
  if (!notifications?.length) return <LoadingSpinner />

  return (
    <>
      {notifications.map(notification => {
        const {
          id,
          headline,
          additionalDetails,
          actions,
          createdAt,
          courses
        } = notification
        const notificationCourses =
          studentCourses.length > 1 ? courses.join(' · ') : ''

        return (
          <NotificationDetails key={id}>
            <NotificationHeadline>{headline}</NotificationHeadline>
            <NotificationText>{additionalDetails}</NotificationText>
            <ActionContainer>
              <NotificationActionButtons actions={actions} />
            </ActionContainer>
            <NotificationCourses>{notificationCourses}</NotificationCourses>
            <NotificationTimeStamp>
              {getTimeAgoInMinsHoursAndDays(createdAt)}
            </NotificationTimeStamp>
          </NotificationDetails>
        )
      })}
    </>
  )
}

export default NotificationList
