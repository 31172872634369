/**
 * This function does not need tests because it is a well known implementation.
 * Source: https://stackoverflow.com/a/1527820/4892995
 * */
export const generateQuestionRandomSeed = (max, min) => {
  return Math.floor(Math.random() * (max - min)) + min
}

export const generateRandomSeedWithExclusion = (max, min, numbersToExclude) => {
  const randomNumber = generateQuestionRandomSeed(max, min)
  return numbersToExclude.includes(randomNumber)
    ? generateRandomSeedWithExclusion(max, min, numbersToExclude)
    : randomNumber
}

export const stripAnnotationTag = answer => {
  const annotationTagMatch = /<annotation\s*[^>]*>[^]*?<\/annotation>/
  while (annotationTagMatch.test(answer)) {
    answer = answer.replace(annotationTagMatch, '')
  }

  return answer
}

export const replaceLast = (query, replaceWith, answer) => {
  const lastIndex = answer.lastIndexOf(query)
  if (lastIndex === -1) return answer
  const beginString = answer.substring(0, lastIndex)
  const endString = answer.substring(lastIndex + query.length)

  return beginString + replaceWith + endString
}

export const areAllSiblingsCompleted = data => {
  const { questionSet, currentQuestionUUID, studentAnswers } = data
  const sibilingProblemSets = questionSet.filter(question => {
    const { Question_uuid: questionUUID } = question
    return questionUUID !== currentQuestionUUID
  })
  return sibilingProblemSets.every(question =>
    isQuestionCompleted(question.Question_uuid, studentAnswers))
}

export const isQuestionCompleted = (questionUUID, studentAnswers) => {
  return !!(studentAnswers.some(question => question.uuid ===
    questionUUID && question.isPracticeCompleted))
}
