import React, { useContext, useEffect, useRef, useState } from 'react'
import api from '../../../api'
import Context from '../../Context/Context'
import { getCohortInstructors, getCohortTeachingAssistants } from '../Utils'
import {
  InstructorsWrapper, InstructorContainer, InstructorImg, InstructorInfo, Caret,
  ReadMoreWrapper, BioContainer
} from '../styled'
import { getMobileDevice } from '../../../utilities/mobileDeviceUtils'
import { emitter } from '../../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../../Constants/emitterKeys'

const EmailLink = ({ email }) => email
  ? (
    <a href={`mailto:${email}`} className='link'>
      send them an email
    </a>
  ) : (
    'send them an email'
  )

const CalendlyLink = ({ calendlylink }) => calendlylink
  ? (
    <>
      {' '}or{' '}
      <a
        href={calendlylink}
        className='link'
        rel='noopener noreferrer'
        target='_blank'
      >
        schedule a call
      </a>.
    </>
  ) : '.'

const Instructor = ({ instructor }) => {
  const {
    instructorName, email, biography, headshotUrl, teachingAssistant, calendlylink
  } = instructor
  const imgUrl = headshotUrl

  const bioRef = useRef(null)
  const [isTextHidden, setIsTextHidden] = useState(false)
  const [isShowToggle, setIsShowToggle] = useState(false)

  useEffect(() => {
    const checkBioToggling = () => {
      const bioHeight = bioRef.current.offsetHeight
      const lineHeight = 24
      const linesNumber = (bioHeight / lineHeight) + 1
      const isMobile = getMobileDevice()?.length
      const threshold = isMobile ? 14 : 6
      const isShowToggle = linesNumber > threshold

      setIsTextHidden(isShowToggle)
      setIsShowToggle(isShowToggle)
    }

    checkBioToggling()
  }, [])

  const toggleHandler = () => {
    setIsTextHidden(!isTextHidden)
  }

  if (!instructorName) return null

  const instructorType = teachingAssistant ? 'teaching assistant' : 'instructor'

  return (
    <InstructorContainer>
      {imgUrl && <InstructorImg src={imgUrl} alt='instructor_image' />}

      <InstructorInfo>
        <h5 className='header'>{instructorName}</h5>

        <BioContainer hideText={isTextHidden} ref={bioRef}>
          {biography}
        </BioContainer>

        {isShowToggle && (
          <ReadMoreWrapper onClick={toggleHandler}>
            <Caret toggle={isTextHidden} />
            {isTextHidden ? 'read more' : 'read less'}
          </ReadMoreWrapper>
        )}

        <h5 className='header margin-top'>
          Contact your {instructorType}
        </h5>
        <p>
          The best way to contact your {instructorType} is in your course’s online community
          but if you need to discuss something privately, you can {' '}
          <EmailLink email={email} />
          <CalendlyLink calendlylink={calendlylink} />
        </p>
      </InstructorInfo>
    </InstructorContainer>
  )
}

function Instructors (props) {
  const {
    cohortData: { cohortID }
  } = useContext(Context)
  const scrollRef = useRef()
  const [instructors, setInstructors] = useState()
  const [teachingAssistants, setTeachingAssistants] = useState()

  useEffect(() => {
    const getInstructors = async () => {
      const cohortInfo = await api.getCohortInfo(cohortID)
      const instructors = getCohortInstructors(cohortInfo)
      const teachingAssistants = getCohortTeachingAssistants(cohortInfo)

      setInstructors(instructors)
      setTeachingAssistants(teachingAssistants)
    }

    getInstructors()
  }, [cohortID])

  const scrollToSection = () => {
    const {
      location: { hash }
    } = window
    const hasScrollHash = hash.includes('?instructor')
    if (hasScrollHash) {
      emitter.emit(ON_NAVIGATE_TO, '/resources')
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }

  useEffect(() => {
    if (window && scrollRef?.current) {
      scrollToSection()
    }
  // eslint-disable-next-line
  }, [window?.location?.hash, scrollRef?.current])

  if (!instructors?.length && !teachingAssistants?.length) return null

  const isInstructorsPlural = instructors?.length > 1
  const isTeachingAssistantsPlural = teachingAssistants?.length > 1

  return (
    <div ref={scrollRef}>
      {!!instructors?.length && (
        <InstructorsWrapper>
          <h4 className='title'>
            Meet your instructor{isInstructorsPlural && 's'}
          </h4>

          {instructors.map((instructor = {}) => (
            <Instructor
              key={instructor?.instructorName}
              instructor={instructor}
            />
          ))}
        </InstructorsWrapper>
      )}

      {!!teachingAssistants?.length && (
        <InstructorsWrapper>
          <h4 className='title'>
            Meet your teaching assistant{isTeachingAssistantsPlural && 's'}
          </h4>

          {teachingAssistants.map((teachingAssistant = {}) => (
            <Instructor
              key={teachingAssistant?.instructorName}
              instructor={teachingAssistant}
            />
          ))}
        </InstructorsWrapper>
      )}
    </div>
  )
}

export default Instructors
