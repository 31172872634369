import React, { Component } from 'react'
import PracticeExercisesButton from '../PracticeExercisesButton/PracticeExercisesButton'
import { capitalizeFirstLetter } from '../../utilities'
import { ProblemSetItemContainer } from './styles'

class ProblemSetListItem extends Component {
  render () {
    const { question, sectionUUID } = this.props
    if (!question) return
    const {
      title, question_set_uuid: problemBankQuestionUUID,
      Question_uuid: questionUUID
    } = question
    const fistLetterCapitalizedTitle = capitalizeFirstLetter(title)
    return (
      <>
        <ProblemSetItemContainer
          data-cy='problemSetListItem'
          className={
            'section-panel ' +
          'section-panel-active d-flex ' +
          'justify-content-between ' +
          'align-items-center'
          }
        >
          <div className='main-panels--left'>
            <h3 className='panel-title d-inline-block'>
              {fistLetterCapitalizedTitle}
            </h3>
          </div>
          <div className='main-panels--right'>
            <PracticeExercisesButton
              sectionUUID={sectionUUID}
              questionUUID={problemBankQuestionUUID || questionUUID}
            />
          </div>
        </ProblemSetItemContainer>
      </>
    )
  }
}

export default ProblemSetListItem
