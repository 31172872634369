import { PRACTICE_EXAM } from '../../Constants/examType'

const QUESTION2_TABLE = `<table class="table-bordered mb-3">
<tr>
  <th></th>
  <th>Desmos Scientific</th>
  <th>Desmos Graphing</th>
  <th>Handheld Calculator</th>
  <th>RStudio.cloud</th>
</tr>
<tr>
  <td>Astronomy</td>
  <td>Permitted</td>
  <td>N/A</td>
  <td>Permitted</td>
  <td>N/A</td>
</tr>
<tr>
  <td>Calculus I</td>
  <td>Permitted</td>
  <td>Permitted</td>
  <td>Permitted</td>
  <td>N/A</td>
</tr>
<tr>
  <td>Microeconomics</td>
  <td>N/A</td>
  <td>N/A</td>
  <td>N/A</td>
  <td>N/A</td>
</tr>
<tr>
  <td>Philosophy</td>
  <td>N/A</td>
  <td>N/A</td>
  <td>N/A</td>
  <td>N/A</td>
</tr>
<tr>
  <td>Psychology</td>
  <td>N/A</td>
  <td>N/A</td>
  <td>N/A</td>
  <td>N/A</td>
</tr>
<tr>
  <td>Statistics</td>
  <td>Permitted</td>
  <td>Permitted</td>
  <td>Permitted</td>
  <td>Permitted</td>
</tr>
<tr>
  <td>Macroeconomics</td>
  <td>N/A</td>
  <td>N/A</td>
  <td>N/A</td>
  <td>N/A</td>
</tr>
<tr>
  <td>Precalculus</td>
  <td>Permitted</td>
  <td>N/A</td>
  <td>N/A</td>
  <td>N/A</td>
</tr>
<tr>
  <td>Financial Accounting</td>
  <td>N/A</td>
  <td>N/A</td>
  <td>Permitted</td>
  <td>N/A</td>
</tr>
</table>`

export const getPracticeExamContent = () => {
  return ProctorioPracticeExamContent
}

export const ProctorioPracticeExamContent = {
  chapter_uuid: 'ckxh4deha00063f63mpvo1cce',
  desc: '',
  exams: { Question: [{
    question_text: '<p>I must start the Exam at least ≥2 hours prior to 9AM Pacific Time on the date of the exam window\'s closure as indicated in the syllabus.</p>',
    Question_uuid: 'ckxhhxe0200073f63rel2alup',
    answer: ['1'],
    configuration: null,
    desmos_graph: null,
    options: ['<p>True</p>', '<p>False</p>'],
    question_type: 'True False',
    title: 'Question 1',
    tries_per_question: '1'
  }, {
    question_text: '<p>All exams are closed-book. I am not permitted to access any unauthorized materials including, but not limited to: Outlier course materials, pre-written notes, textbooks, smart devices, and other non-authorized websites such as Google search on the exam. I understand that I am permitted to use the following:</p>' + QUESTION2_TABLE,
    Question_uuid: 'ckxhik4gq000b3f63cemauqko',
    answer: ['1'],
    configuration: null,
    desmos_graph: null,
    options: ['<p>True</p>', '<p>False</p>'],
    question_type: 'True False',
    title: 'Question 2',
    tries_per_question: '1'
  }, {
    question_text: '<p>If I encounter technical difficulties during the Exam, I can reach out to Proctorio support using the live chat function in the Proctorio Tools menu that pops up when you start the Exam.</p>',
    Question_uuid: 'ckxhhxwu500093f6374lq7357',
    answer: ['1'],
    configuration: null,
    desmos_graph: null,
    options: ['<p>True</p>', '<p>False</p>'],
    question_type: 'True False',
    title: 'Question 3',
    tries_per_question: '1'
  }, {
    question_text: '<p>I will be prepared to show a copy of my photo identification card that clearly displays a) my full name and b) a recent photograph of myself at the start of the Exam.</p>',
    Question_uuid: 'ckxhhy3yc000a3f6337p6d2p8',
    answer: ['1'],
    configuration: null,
    desmos_graph: null,
    options: ['<p>True</p>', '<p>False</p>'],
    question_type: 'True False',
    title: 'Question 4',
    tries_per_question: '1'
  }] },
  title: PRACTICE_EXAM,
  type: 'exam'
}
