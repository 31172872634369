import React, { Component } from 'react'
import ReactDom from 'react-dom'
import { render } from 'datocms-structured-text-to-html-string'
import MathJax from '../MathJax/MathJax'
import AttributedImage from '../AttributedImage/AttributedImage'
import Context from '../Context/Context'
import config from '../../config'
import DesmosgraphComponent from '../Desmonsgraph/DesmosgraphComponent'
import TableColumnRowFreezeComponent from './TableColumnRowFreeze/TableColumnRowFreezeComponent'
import './css/active-learning.css'
import {
  PRACTICE_EXERCISES,
  ACTIVE_LEARNING,
  EXAM,
  QUIZ
} from '../../Constants/sectionType'
import {
  TEXT_BLANK,
  CARD,
  AL_TEXT_REVEAL_CARD
} from '../../Constants/questionType'
import { emitter } from '../Emitter/Emitter'
import {
  ON_REVIEW_MODE,
  ON_TRACK_STUDENT_EVENT
} from '../../Constants/emitterKeys'
import { COURSERA_LOGIN } from '../../Constants/eventTypes'
import { MULTIPLE } from '../../Constants/frequency'
import { CORRECT, INCORRECT } from '../../Constants/result'
import {
  getGMExpressionsStartAndGoal,
  getIllustrationAlt,
  highlightedText,
  getHyperlinkedTitle,
  filterOutStructuredTextBlockType,
  filterStructuredTextBlockImages,
  isEmptyStructuredTextValue,
  canFreezeTable
} from '../../utilities/questions'
import GMath from '../GMath/GMath'
import Frame from 'react-frame-component'
import { formatBoldTags, replaceBlockquoteDoubleQuotation } from '../../utilities'
import { setDatoUrl } from '../../utilities/dato'
import { StyledStructuredText } from './styles'
import { getLocalStorageKey } from '../../utilities/examUtils'
import {
  appendQueryParamsToLink,
  copyWidthToFirstRowCells,
  formatChapterLinkTags,
  getGGUCourseLessonText,
  getTableColumnRowSpanStatus, removeLinksFromOtherSections,
  removeTableWidthProperty,
  replaceInternalLinks
} from '../../utilities/domUtils'
import { is39WHSCourse } from '../../utilities/courseUtils'
import { handleCourseraProgramMembership } from './utils'

export default function QuestionComponent (WrappedComponent, selectData) {
  // ...and returns another component...
  const questionComponent = class extends Component {
    constructor (props) {
      super(props)
      this.keyPressRef = React.createRef()
      this.filterMessage = this.filterMessage.bind(this)
      this.textWhenCorrect = this.textWhenCorrect.bind(this)
      this.textWhenIncorrect = this.textWhenIncorrect.bind(this)
      this.questionText = this.questionText.bind(this)
      this.lessonText = this.lessonText.bind(this)
      this.questionTitle = this.questionTitle.bind(this)
      this.displayReveal = this.displayReveal.bind(this)
      this.makeRevealFalse = this.makeRevealFalse.bind(this)
      this.makeSolutionFalse = this.makeSolutionFalse.bind(this)
      this.incorrectAnsIndex = this.incorrectAnsIndex.bind(this)
      this.displayIllustration = this.displayIllustration.bind(this)
      this.showDesmosGraph = this.showDesmosGraph.bind(this)
      this.displayExitLink = this.displayExitLink.bind(this)
      this.keyHandling = this.keyHandling.bind(this)
      this.isPendingResponse = this.isPendingResponse.bind(this)
      this.showGMath = this.showGMath.bind(this)
      this.getIconConfig = this.getIconConfig.bind(this)
      this.deriveIncorrectTextFromOptions = this.deriveIncorrectTextFromOptions.bind(this)
      this.getIncorrectTextForMCQs = this.getIncorrectTextForMCQs.bind(this)

      const { question } = props
      this.state = {
        result: selectData,
        isRevealClicked: false,
        GMathHeight: '400px',
        question: { ...question, lesson_text: this.getLessonText(question) },
        incorrectAnsIndexSt: [],
        isSolutionClicked: false,
        reviewMode: false,
        flash: false,
        guessworkCorrectAnswer: '',
        displayExitLink: false
      }
    }

    keyHandling (e) {
      // Enter key, left arrow, right arrow.
      if (e.keyCode !== 13 && e.keyCode !== 37 && e.keyCode !== 39) return

      e.preventDefault()
      if (!this.keyPressRef.current) return null

      // ENTER key (default) means advance forward: (Check or Next button).
      // Left arrow means go back (Previous button).
      // Right arrow means advance forward: (Check or Next button).
      // Arrow keys should be ignored for anything with a text type input, which
      // is determined and handled by each individual component.
      let type = 'ENTER'
      if (e.keyCode === 37) type = 'previous'
      if (e.keyCode === 39) type = 'advance'

      const allowed = this.keyPressRef.current.handleKeyPress(type)

      if (!allowed) this.flash()
    }

    handleCourseraLinkClick = async (e) => {
      const { host, nodeName, href } = e.target || {}
      if (nodeName.toLowerCase() === 'a' && host === 'www.coursera.org') {
        e.preventDefault()
        const eventData = {
          event: COURSERA_LOGIN,
          properties: {
            timestamp: Date.now()
          },
          frequency: MULTIPLE
        }
        emitter.emit(ON_TRACK_STUDENT_EVENT, eventData)

        await handleCourseraProgramMembership(e)
        window.open(href, '_blank')
      }
    }

    componentWillUnmount () {
      this.resetFreezeTooltips()
      window.removeEventListener('keyup', this.keyHandling)
      window.removeEventListener('resize', this.renderFreezeRowAndColumnButtons)
      window.removeEventListener('click', this.handleCourseraLinkClick)

      const { activeLearningDatoLink, updateContext } = this.context
      if (!activeLearningDatoLink) return
      updateContext({ activeLearningDatoLink: '' })
    }

    componentDidMount () {
      const {
        review_mode: reviewMode,
        question,
        type_of_question: exerciseType,
        question: {
          id: datoRecordId,
          question_type: questionType,
          dato_card_type: cardType
        } = {}
      } = this.props

      const { user: { email: userEmail }, currentQuestionSet } = this.context

      emitter.emit(ON_REVIEW_MODE, !!reviewMode)
      if (reviewMode) {
        const isQuizOrExam = exerciseType === QUIZ || exerciseType === EXAM
        if (isQuizOrExam) {
          const { breadcrumbArr: [[quizTitle = '']] } = currentQuestionSet || {}
          const localStorageKey = getLocalStorageKey(
            userEmail,
            config.courseName,
            quizTitle
          )
          localStorage.removeItem(localStorageKey)
        }
        this.setState({ reviewMode })
      }
      const { Question_uuid: questionUUID } = question
      const { studentData: { studentAnswers }, updateContext } = this.context
      const highlighted = highlightedText(questionUUID, studentAnswers)
      this.setState({ questionHighlightText: highlighted })

      setDatoUrl({
        datoRecordId,
        cardType,
        questionType
      }, updateContext)

      this.resetFreezeTooltips()
      this.renderFreezeRowAndColumnButtons()
      window.addEventListener('keyup', this.keyHandling)
      window.addEventListener('resize', this.renderFreezeRowAndColumnButtons)
      window.addEventListener('click', this.handleCourseraLinkClick)
    }

    componentDidUpdate (prevProps) {
      const {
        question,
        question: {
          id: datoRecordId,
          question_type: questionType,
          dato_card_type: cardType
        } = {}
      } = this.props

      if (prevProps.question !== question) {
        const { Question_uuid: questionUUID } = question
        const { studentData: { studentAnswers }, updateContext } = this.context
        const highlighted = highlightedText(questionUUID, studentAnswers)
        this.setState({ questionHighlightText: highlighted })

        this.resetFreezeTooltips()

        setDatoUrl({
          datoRecordId,
          cardType,
          questionType
        }, updateContext)

        this.setState({
          question: { ...question, lesson_text: this.getLessonText(question) },
          isRevealClicked: false,
          isSolutionClicked: false
        })
      }
    }

    getLessonText (question) {
      const { lesson_text: lessonText } = question
      return lessonText || ''
    }

    filterMessage (textToFilter) {
      const { activeLearningUUID } = this.props
      const selectedThemes = this.context.studentData['selected-themes']
      const selFilterVal = selectedThemes
        ? selectedThemes[activeLearningUUID] : ''
      if (selFilterVal !== '') {
        const splitBy = '[/theme]'
        const findBy = '[theme: ' + selFilterVal + ']'
        const filteredArray = textToFilter.split(splitBy)
        let filteredText = ''
        filteredArray.forEach(function (entry) {
          if (entry.indexOf(findBy) !== -1) {
            filteredText = entry.split(findBy)[1]
          }
          if (!filteredText) filteredText = textToFilter
        })
        return filteredText
      } else {
        return textToFilter
      }
    }

    textWhenCorrect () {
      const { question: {
        text_when_correct: textWhenCorrect
      } = {} } = this.props
      const { title, text } = this.getTitleAndText(textWhenCorrect, true)
      const iconConfig = this.getIconConfig(true)
      return { correctProps: { title, text, iconConfig } }
    }

    getTitleAndText (displayText, isCorrect) {
      const isPendingResponse = this.isPendingResponse()
      const { type_of_question: sectionType } = this.props
      const isPracticeExercises = sectionType === PRACTICE_EXERCISES
      const isActiveLearning = sectionType === ACTIVE_LEARNING
      const title = isCorrect ? isPendingResponse ? 'Response Submitted' : CORRECT
        : isPracticeExercises || isActiveLearning ? 'Try again'
          : isPendingResponse ? 'Response Submitted' : INCORRECT
      const text = isPendingResponse ? '' : displayText
      return { title, text }
    }

    getIconConfig (isCorrect) {
      const isPendingResponse = this.isPendingResponse()
      return {
        bgColor: isPendingResponse ? 'pending-response' : isCorrect ? 'bg-primary' : 'bg-orange',
        width: isCorrect ? isPendingResponse ? '54%' : '52%' : '54%',
        height: isCorrect ? isPendingResponse ? '54%' : '38%' : '54%',
        viewBox: isPendingResponse ? '0 0 408 408' : isCorrect ? '0 0 52 38' : '0 0 54 54',
        xlinkHref: isPendingResponse
          ? '/images/icons/response-submitted.svg#response-submitted'
          : isCorrect ? '/images/icons/icon-radio-checkmark.svg#icon-radio-checkmark'
            : '/images/icons/icon-cross.svg#icon-cross'
      }
    }

    resetFreezeTooltips = () => {
      const tableScrollableButtonsWrapper = document.querySelectorAll('.tableScrollableButtonsWrapper')
      const freezeTooltipOverlay = document.querySelectorAll('.tooltip-overlay-card')
      tableScrollableButtonsWrapper.forEach((element) => {
        element.remove()
      })

      freezeTooltipOverlay.forEach((element) => {
        element.remove()
      })
    }

    renderFreezeRowAndColumnButtons = () => {
      const {
        freezeTableViewed,
        user: { email: userEmail },
        isAdmin,
        updateContext
      } = this.context

      const updateFreezeTableViewed = () => {
        updateContext({
          freezeTableViewed: true
        })
      }

      setTimeout(() => {
        const tableButtonsWrapper = document.querySelectorAll('.tableScrollableButtonsWrapper')
        if (!tableButtonsWrapper?.length) return

        const { currentQuestionUUID } = this.props

        tableButtonsWrapper.forEach((element, index) => {
          const tableWrapper = element.parentElement.children[1]
          const hasHorizontalScroll = tableWrapper?.scrollWidth > tableWrapper?.clientWidth
          const hasVerticalScroll = tableWrapper?.scrollHeight > tableWrapper?.clientHeight

          const table = tableWrapper.querySelector('table')

          const {
            firstColumnSpanSingleRow,
            firstRowSpanSingleColumn
          } = getTableColumnRowSpanStatus(table)

          ReactDom.render(
            <TableColumnRowFreezeComponent
              key={currentQuestionUUID}
              disableColumnFreeze={!hasHorizontalScroll || !firstColumnSpanSingleRow}
              disableRowFreeze={!hasVerticalScroll || !firstRowSpanSingleColumn}
              tableElement={table}
              freezeTableViewed={freezeTableViewed || index !== 0}
              updateFreezeTableViewed={updateFreezeTableViewed}
              isAdmin={isAdmin}
              userEmail={userEmail}
            />,
            element
          )
        })
      }, 200)
    }

    isPendingResponse () {
      const { type_of_question: sectionType, review_mode: reviewMode } = this.props
      return !reviewMode && sectionType === EXAM
    }

    textWhenIncorrect () {
      const incorrectStr = this.getIncorrectTextForMCQs()
      const { title, text } = this.getTitleAndText(incorrectStr, false)
      const iconConfig = this.getIconConfig(false)
      return { incorrectProps: { title, text, iconConfig } }
    }

    getIncorrectTextForMCQs () {
      const { question: {
        text_when_incorrect: textWhenIncorrect,
        options
      } = {} } = this.state
      const { incorrectAnsIndexSt } = this.state
      if (!textWhenIncorrect || textWhenIncorrect.length === 0) return ''
      if (!incorrectAnsIndexSt ||
        incorrectAnsIndexSt.length === 0 ||
        textWhenIncorrect.length === 1) return textWhenIncorrect[0]
      if (textWhenIncorrect.length === options.length) {
        let incorrectStr = ''
        const newIncorrectAnsIndexSt = !Array.isArray(incorrectAnsIndexSt)
          ? [incorrectAnsIndexSt]
          : incorrectAnsIndexSt

        newIncorrectAnsIndexSt.forEach(function (entry) {
          if (textWhenIncorrect[entry] && !incorrectStr.includes(textWhenIncorrect[entry])) {
            incorrectStr += textWhenIncorrect[entry]
          }
        })
        return incorrectStr
      }
      return this.deriveIncorrectTextFromOptions()
    }

    /* Could not find a scenario when textWhenIncorrect.length !== options.length
    and this function will be called. Hence retaining it as it is */
    deriveIncorrectTextFromOptions () {
      const { question: {
        text_when_incorrect: textWhenIncorrect,
        options,
        answer
      } = {}, incorrectAnsIndexSt } = this.state
      const correctAnswerIndices = answer.map(num => num - 1)
      let incorrectStr = ''
      let i = -1
      let qIncorrectText = Array(options.length).fill('')
      qIncorrectText = qIncorrectText.map((value, index) => {
        return correctAnswerIndices.includes(index) ? '' : textWhenIncorrect[++i] || ''
      })
      incorrectAnsIndexSt.forEach((entry) => {
        if (qIncorrectText[entry]) incorrectStr += qIncorrectText[entry]
      })
      return incorrectStr
    }

    questionText () {
      const {
        question_type: questionType,
        question_text: questionText,
        datoCardType
      } = this.state.question
      const { questionHighlightText } = this.state
      const { isAssessmentQuizExam } = this.context
      if (!questionText) return ''
      if (questionType !== TEXT_BLANK) {
        let whiteQuestionText = questionText &&
          questionText.replace(/<span style="font-size:11pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Arial"><span style="color:#000000"><span style="font-weight:400">/g,
            '<span style="font-size:18px; font-variant:normal; white-space:pre-wrap"><span style="font-family:Lato"><span style="color:#ffffff"><span style="font-weight:300">')
        whiteQuestionText = copyWidthToFirstRowCells(whiteQuestionText)
        whiteQuestionText = removeTableWidthProperty(whiteQuestionText)
        whiteQuestionText = appendQueryParamsToLink({
          htmlString: whiteQuestionText,
          queryParams: { authProvider: 'ggu-outlier' }
        })
        const highlightedQuestionDiv = questionHighlightText?.questionText && <div
          className={`font__md question_text ${isAssessmentQuizExam && `selection`}`}
          id='question_text'
          dangerouslySetInnerHTML={{
            __html: questionHighlightText.questionText
          }}
        />
        const showAnchorInline = questionType === AL_TEXT_REVEAL_CARD
        const mathJaxClassName = this.getMathJaxClassName(this.props.currentQuestionUUID)
        const MathJaxDiv = <div
          id='question_text'
          className={`question_text ${isAssessmentQuizExam && `selection`} ${
            showAnchorInline ? 'question-text-anchor-inline' : ''}`
          }
        >
          <MathJax
            className={mathJaxClassName}
            math={this.filterMessage(formatBoldTags(whiteQuestionText))}
            datoCardType={datoCardType}
          />
        </div>

        return highlightedQuestionDiv || MathJaxDiv
      }

      let filteredQuestionText = this.filterMessage(formatBoldTags(questionText))
      filteredQuestionText = copyWidthToFirstRowCells(filteredQuestionText)
      filteredQuestionText = removeTableWidthProperty(filteredQuestionText)
      return filteredQuestionText
    }

    structuredText = () => {
      const {
        question: {
          structuredText,
          lesson_text: lessonText,
          question_type: questionType,
          question_text: questionText
        }
      } = this.state
      const isCardType = questionType === CARD

      const blockImages = filterStructuredTextBlockImages(structuredText?.blocks)
      const filteredStructuredValue = filterOutStructuredTextBlockType(structuredText?.value)
      const isStructuredTextEmpty = isEmptyStructuredTextValue(filteredStructuredValue)

      if (isStructuredTextEmpty && !blockImages?.length) return null
      const showRuler = lessonText || questionText

      return (
        <>
          {
            !isStructuredTextEmpty && <StyledStructuredText
              dangerouslySetInnerHTML={{
                __html: render(filteredStructuredValue)
              }}
            />
          }
          {
            blockImages?.map(({ image }) => (
              <div className={isCardType
                ? 'col-12 mb-5' : 'col-12 mb-5 col-lg-6'}>
                <div width='100%'>
                  <AttributedImage alt={image.alt} src={image.url} title={image.title} />
                </div>
              </div>
            ))
          }
          {showRuler && <hr className='active__learning-hr' />}
        </>
      )
    }

    lessonText () {
      const {
        currentQuestionUUID,
        currentQuestionSet,
        activeSectionUUID
      } = this.props
      const {
        question_type: questionType,
        dato_card_type: datoCardType,
        lesson_text: lessonText
      } = this.state.question
      const { questionHighlightText } = this.state
      const {
        isAssessmentQuizExam,
        studentData,
        isAdmin,
        isVIP,
        isVIPGradedContent
      } = this.context
      const isCardType = questionType === CARD
      let text = lessonText
      const regex = /&quot;/
      const quoteLength = (lessonText.match(new RegExp(regex, 'g')))?.length
      for (let index = 0; index < quoteLength; index++) {
        const isEven = index % 2 === 0
        if (isEven) {
          text = text.replace(regex, '“')
        } else {
          text = text.replace(regex, '”')
        }
      }

      if (config.course.showRegularQuotationInsideDoubleQuoteElement) {
        text = replaceBlockquoteDoubleQuotation(text)
      }

      text = copyWidthToFirstRowCells(text)
      text = removeTableWidthProperty(text)
      text = appendQueryParamsToLink({
        htmlString: text,
        queryParams: { authProvider: 'ggu-outlier' }
      })
      if (is39WHSCourse()) {
        text = replaceInternalLinks(text)
        text = removeLinksFromOtherSections(text, activeSectionUUID)
      }

      const newLessonText = isCardType
        ? `<div id='card-lesson-text'>${text}</div>`
        : text
      const highlightedLessonDiv = questionHighlightText?.lessonText && <div
        className={`font__md lesson_text ${isAssessmentQuizExam && `selection`}`}
        id='lesson_text'
        dangerouslySetInnerHTML={{
          __html: questionHighlightText.lessonText
        }}
      />

      const lessonTextWithBoldTags = formatBoldTags(newLessonText)
      const courseLessonText = getGGUCourseLessonText({
        lessonText: lessonTextWithBoldTags,
        questions: currentQuestionSet,
        sectionUUID: activeSectionUUID
      })

      const formattedLessonText =
        isAdmin || isVIP || isVIPGradedContent
          ? courseLessonText
          : formatChapterLinkTags(
            courseLessonText,
            studentData['guesswork-complete']
          )

      const mathJaxClassName = this.getMathJaxClassName(currentQuestionUUID)
      const mathJaxDiv = lessonText && <div
        id='lesson_text'
        className={`lesson_text ${isAssessmentQuizExam && `selection`}`}
      >
        <MathJax
          className={mathJaxClassName}
          math={this.filterMessage(formattedLessonText)}
          datoCardType={datoCardType}
        />
      </div>

      return highlightedLessonDiv || (lessonText ? mathJaxDiv : '')
    }

    getMathJaxClassName (currentQuestionUUID) {
      const { isAdmin, isSavvasAndHasAdminAccess } = this.context
      return (isAdmin || isSavvasAndHasAdminAccess)
        ? currentQuestionUUID
        : `${currentQuestionUUID} prevent-select`
    }

    questionTitle () {
      const { title } = this.state.question
      return title ? (<div>{title}</div>) : ''
    }

    displayReveal () {
      this.setState({
        isRevealClicked: true
      })
      window.outlierLog('Reveal Answer', '')
    }

    makeRevealFalse () {
      this.setState({
        isRevealClicked: false
      })
    }

    makeSolutionFalse () {
      this.setState({
        isSolutionClicked: false
      })
    }

    incorrectAnsIndex (indexArr) {
      this.setState({ incorrectAnsIndexSt: indexArr })
    }

    setGuessworkCorrectAnswer = guessworkCorrectAnswer => {
      this.setState({ guessworkCorrectAnswer })
    }

    displayIllustration () {
      const {
        illustration,
        illustrationProps,
        question_type: questionType
      } = this.state.question
      if (!illustration || !illustrationProps) return
      const { fields } = illustrationProps
      const illustrationStr = typeof illustration === 'object'
        ? illustration.url?.toString() : illustration.toString()
      if (!illustrationStr) return

      const illustrationAlt = getIllustrationAlt(this.state.question)
      const illustrationTitle = this.getIllustrationTitle()
      const illustrationTitleStr =
        illustrationTitle ? getHyperlinkedTitle(illustrationTitle, fields) : ''
      return (
        <div className={questionType === CARD
          ? 'col-12 mb-5' : 'col-12 mb-5 col-lg-6'}>
          <div width='100%'>
            <AttributedImage alt={illustrationAlt} src={illustrationStr} title={illustrationTitleStr} />
          </div>
        </div>
      )
    }

    getIllustrationTitle = () => {
      const {
        question: {
          illustration_title: illustrationTitle,
          illustrationProps
        }
      } = this.state

      if (!illustrationProps) return illustrationTitle
      const { title } = illustrationProps

      return title || illustrationTitle
    }

    showDesmosGraph () {
      const { type_of_question: sectionType, problemBank } = this.props
      const {
        desmos_graph: desmosGraph,
        illustration,
        question_type: questionType
      } = this.state.question
      if (!desmosGraph || (sectionType === PRACTICE_EXERCISES && !problemBank)) return null
      return (
        <DesmosgraphComponent
          expression={desmosGraph}
          illustration={illustration}
          questionType={questionType}
        />
      )
    }

    showGMath () {
      const {
        GMathHeight,
        question: {
          gmStartingExpressionGoal,
          gmMatchCommutedGoals,
          gmGoalsMatchAnyOfTheExpressions
        }
      } = this.state
      const styles = {
        width: '100%',
        height: GMathHeight,
        border: 'none'
      }
      const { type_of_question: sectionType, problemBank } = this.props

      const expressionGoal = getGMExpressionsStartAndGoal(gmStartingExpressionGoal)
      const setGMathHeight = (GMathHeight) => {
        this.setState({ GMathHeight })
      }

      if (!expressionGoal || (sectionType === PRACTICE_EXERCISES && !problemBank)) return null

      return (
        <Frame
          style={styles}
        >
          <GMath
            eqs={expressionGoal}
            setGMathHeight={setGMathHeight}
            matchCommuted={gmMatchCommutedGoals}
            matchAnyEq={gmGoalsMatchAnyOfTheExpressions}
          />
        </Frame>
      )
    }

    displayExitLink (val) {
      const { displayExitLink } = this.state
      if (displayExitLink === val) return
      return this.setState({
        displayExitLink: val
      })
    }

    flash () {
      this.setState({ flash: true })
      setTimeout(() => this.setState({ flash: false }), 50)
    }

    responseSubmittedSVG () {
      return (<svg className='response-submitted-popup' viewBox='0 0 408 408'>
        <use
          xlinkHref='/images/icons/response-submitted.svg#response-submitted' />
      </svg>
      )
    }

    render () {
      const {
        localStorageKey
      } = this.props
      const {
        flash,
        isRevealClicked,
        isSolutionClicked,
        question: {
          dato_card_type: datoCardType
        },
        reviewMode
      } = this.state
      const flashClass = flash ? 'flash-start' : 'flash-end'
      const { correctProps } = this.textWhenCorrect()
      const { incorrectProps } = this.textWhenIncorrect()
      const questionText = this.questionText()
      const questionTitle = this.questionTitle()
      const lessonText = this.lessonText()
      const structuredText = this.structuredText()
      const displayIllustration = this.displayIllustration()
      const desmosGraph = this.showDesmosGraph()
      const gMath = this.showGMath()

      const shouldFreezeTable = canFreezeTable(datoCardType)
      if (shouldFreezeTable) {
        this.renderFreezeRowAndColumnButtons()
      }

      return (
        <section className={`section active-learning ${flashClass}`}>
          <WrappedComponent {...this.props}
            correctProps={correctProps}
            incorrectProps={incorrectProps}
            question_text={questionText}
            lesson_text={lessonText}
            structuredText={structuredText}
            question_title={questionTitle}
            display_illustration={displayIllustration}
            isRevealed={isRevealClicked}
            make_reveal_false={this.makeRevealFalse}
            isSolution={isSolutionClicked}
            gMath={gMath}
            displayReveal={this.displayReveal}
            make_solution_false={this.makeSolutionFalse}
            incorrectAnsIndex={this.incorrectAnsIndex}
            desmosgraph={desmosGraph}
            review_mode={reviewMode}
            displayExitLink={this.displayExitLink}
            filterMessage={this.filterMessage}
            setGuessworkCorrectAnswer={this.setGuessworkCorrectAnswer}
            ref={this.keyPressRef}
            localStorageKey={localStorageKey}
          />
        </section>

      )
    }
  }
  questionComponent.contextType = Context
  return questionComponent
}
