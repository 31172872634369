import {
  FREE_FORM,
  MULTIPLE_CHOICE,
  TEXT_BLANK,
  TRUE_FALSE
} from '../Constants/questionType'
import { EXAM_KEY_FIELDS, EXAM_OVERRIDE_FIELDS } from '../Constants/examKeys'
import { secondsSinceEpoch } from './dateTimeUtils'
import history from './history'
import { getAnswerForExamQuestions } from './sectionUtils'
import { PROCTORIO, EXAM_TAKE, EXAM_END } from '../Constants/proctorio'
import config from '../config'
import {
  cleanupMathMLForChecking,
  getCorrectAnswerTestEnv
} from '../Components/MathType/utils'
import { EXAM_PERCENTAGE } from '../Constants/studentContext'
import { isExamRetakeType as _isExamRetakeType } from './chapterUtils'

export {
  getUnlockedExams,
  getLocalStorageKey,
  localStorageStudentResponse,
  isExamUnlocked,
  updateStudentContext,
  canUseLocalStorageResponses,
  getResponseFeedback,
  parseJSON,
  stringifyJSON,
  formatTag,
  getProctorioExamTag,
  getProctorioExamTake,
  startProctorioRecord,
  endProctorioSession,
  storeAuthToken,
  formatAnswer,
  isExamRetakeType,
  saveAnswersToLocalStorage,
  isExamDone,
  allowLiveProctoring,
  getExamKeyFieldNames,
  shouldByPassExamKeysPage
}

function getLocalStorageKey (userEmail, courseName, title) {
  if (!userEmail || !title) return ''
  return `${userEmail}_${courseName}_${title.toLowerCase().replace(/ /g, '_')}_answers`
}

function localStorageStudentResponse (key) {
  const getResponses = () => {
    const { answers } = parseJSON(key)
    return answers
  }

  const getDate = () => {
    const { date } = parseJSON(key)
    return date
  }

  const get = () => {
    return localStorage.getItem(key)
  }

  const set = answers => {
    const objectToStore = stringifyJSON({ date: secondsSinceEpoch(), answers })
    console.info(`SET localStorage key: ${key}`, objectToStore)
    objectToStore && localStorage.setItem(key, objectToStore)
  }

  const remove = () => {
    localStorage.removeItem(key)
  }

  return { getResponses, getDate, get, set, remove }
}

function parseJSON (key) {
  const { get, remove } = localStorageStudentResponse(key)
  try {
    const storedValue = get()
    console.info(`GET localStorage key: ${key}`, storedValue)
    return JSON.parse(storedValue || '{}')
  } catch (e) {
    remove()
    return {}
  }
}

function stringifyJSON (value) {
  try {
    return JSON.stringify(value)
  } catch (e) {
    return null
  }
}

/**
 * @param {String} localStorageKey
 * @param @param {Array} questionSet - exam questions
 * @param {Array} studentAnswers
 * @description This function is needed because when a JSON parse fails, the corresponding
 * localStorage value is deleted. So student answers are fetched from context, and are saved to
 * localStorage along with the newly added response
 */
function saveAnswersToLocalStorage (localStorageKey, questionSet, studentAnswers) {
  if (!localStorageKey) return
  const examAnswers = getAnswerForExamQuestions(studentAnswers, questionSet)
  const examAnswersforLocalStorage = examAnswers.map(element => {
    const { answer, correct, uuid } = element
    const isFreeFormAnswer = typeof answer === 'string' && answer.includes('<math')

    return {
      [uuid]: isFreeFormAnswer ? { answer, correct } : answer
    }
  })
  const { set } = localStorageStudentResponse(localStorageKey)
  set(examAnswersforLocalStorage)
}

function isExamRetakeType ({
  cohortId,
  examRetake,
  chapter
}) {
  return _isExamRetakeType({
    cohortId,
    examRetake,
    chapter
  })
}

function isExamUnlocked (localStorageKey, examDates) {
  if (!examDates) return false
  const { examLockDate, examUnlockDate } = examDates
  if (!examLockDate || !examUnlockDate) return false
  const { getDate } = localStorageStudentResponse(localStorageKey)
  const localStorageExamDate = getDate()
  if (!localStorageExamDate) return false
  return localStorageExamDate > examUnlockDate && localStorageExamDate < examLockDate
}

/**
 * @param {Context} context - student data context
 * @param {String} localStorageKey
 * @param {Array} questionSet - exam questions
 * @description - If localStorage has data, update studentAnswers in Context with
 * localStorage values.
 */
function updateStudentContext (context, localStorageKey, questionSet) {
  const { studentData, updateContext } = context || {}
  const { studentAnswers } = studentData || {}
  const { getResponses } = localStorageStudentResponse(localStorageKey)
  const localStorageAnswers = getResponses()
  if (!localStorageAnswers) return
  const examAnswers = getAnswerForExamQuestions(studentAnswers, questionSet)
  let questionUUID
  let answer
  let studentAnswer
  let responseFeedback
  localStorageAnswers.forEach(storedAnswer => {
    questionUUID = Object.keys(storedAnswer)[0]
    const savedResponse = storedAnswer[questionUUID]

    const hasFeedback = typeof savedResponse === 'object' && 'correct' in savedResponse
    answer = hasFeedback ? savedResponse.answer : savedResponse

    const currentQuestion = questionSet.find(question => {
      return question.Question_uuid === questionUUID
    })
    answer = formatAnswer(answer, currentQuestion)

    studentAnswer = examAnswers.find(answer => {
      return answer.uuid === questionUUID
    })

    responseFeedback = hasFeedback ? savedResponse.correct
      : getResponseFeedback(questionSet, questionUUID, answer)

    const answerObject = { uuid: questionUUID, answer, correct: responseFeedback, tries: [responseFeedback] }
    if (studentAnswer) {
      const studentAnswerIndex = studentAnswers
        .findIndex(answer => answer.uuid === studentAnswer.uuid)
      if (studentAnswers[studentAnswerIndex]) {
        studentAnswers[studentAnswerIndex] = {
          ...studentAnswer,
          ...answerObject
        }
      }
    } else studentAnswers.push(answerObject)
  })
  updateContext({ studentData })
}

/**
 * @param {Context} context - student data context
 * @param {String} localStorageKey
 * @param {Object} examLockDates - examLockDate and examUnlockDate
 * @returns {Boolean}
 * @description returns true if user is Admin or VIP or isQuiz or if date in localStorage is between
 * exam lock and unlock dates. If false, deletes the localStorage key and its stored values
 */
function canUseLocalStorageResponses ({ context, localStorageKey, examLockDates, isQuiz }) {
  const {
    isAdmin,
    isVIP,
    isVIPGradedContent
  } = context
  const examUnlocked = isExamUnlocked(localStorageKey, examLockDates || {})
  const useLocalStorageResponses = (
    examUnlocked || isAdmin || isVIP || isVIPGradedContent || isQuiz
  )
  if (useLocalStorageResponses) return true
  return false
}

/**
 * @param {Array} questionSet - exam questions
 * @param {String} currentQuestionUUID
 * @param {Object} studentAnswer
 * @returns {Boolean}
 * @description returns true if student answer is same as answer field of question,
 * returns false if wrong.
 */
function getResponseFeedback (questionSet, currentQuestionUUID, studentAnswer) {
  const currentQuestion = questionSet.find(question => {
    return question.Question_uuid === currentQuestionUUID
  })
  const {
    answer,
    question_type: questionType,
    configuration
  } = currentQuestion || {}
  let correctAnswer
  // eslint-disable-next-line
  switch (questionType) {
    case MULTIPLE_CHOICE:
      correctAnswer = answer.map(index => parseInt(index) - 1)
      return stringifyJSON(correctAnswer.sort()) === stringifyJSON(studentAnswer.sort())
    case TRUE_FALSE:
      correctAnswer = parseInt(answer[0]) - 1
      return stringifyJSON(correctAnswer) === stringifyJSON(studentAnswer)
    case FREE_FORM:
      const correctAnswerText = getCorrectAnswerTestEnv(configuration)

      correctAnswer = cleanupMathMLForChecking(
        correctAnswerText?.toLowerCase()
      )
      const cleanedStudentAnswer = cleanupMathMLForChecking(
        studentAnswer?.trim()?.toLowerCase()
      )

      return correctAnswer === cleanedStudentAnswer
    case TEXT_BLANK:
      let result = true
      answer.forEach((answer, index) => {
        if (answer && studentAnswer[index] &&
           answer.toLowerCase() === studentAnswer[index].toLowerCase()) return
        result = false
      })
      return result
  }
}

function formatAnswer (answer, question) {
  if (!question) return answer

  const { question_type: questionType } = question
  const isQuestionAnswerArrayType = [
    MULTIPLE_CHOICE
  ].includes(questionType)

  const shouldConvertToArray = isQuestionAnswerArrayType && !Array.isArray(answer)
  if (shouldConvertToArray) return [answer]

  return answer
}

function formatTag (str) {
  return str.replace(/\s+/g, '')
}

function getProctorioExamTag (cohortName, chapter) {
  const {
    chapter_uuid: uuid
  } = chapter

  return `Outlier${formatTag(cohortName)}${formatTag(uuid)}`
}

function getProctorioExamTake (params) {
  const { courseBaseUrl, examUUID, firstQuestionUUID } = params
  let flags = `${PROCTORIO}=true`
  flags = `${flags}&flag-studio=true`
  return `${courseBaseUrl}/?${flags}#/${examUUID}/${firstQuestionUUID}`
}

function emitProctorioStateChange (state) {
  return window.top.postMessage(
    ['exam_state_change', state],
    'https://getproctorio.com'
  )
}

function startProctorioRecord () {
  if (!config.insideProctorio) return
  return emitProctorioStateChange(EXAM_TAKE)
}

// remove stored token and end proctorio record
function endProctorioSession () {
  const { remove } = config.authToken()
  remove()

  endProctorioRecord()
}

function endProctorioRecord () {
  if (!config.insideProctorio) return

  const queryParams = new URLSearchParams(window.location.search)
  if (!queryParams.has(PROCTORIO)) return emitProctorioStateChange(EXAM_END)

  queryParams.delete(PROCTORIO)
  history.replace({
    search: queryParams.toString()
  })

  emitProctorioStateChange(EXAM_END)
}

function storeAuthToken (token) {
  const { set } = config.authToken()
  set(token)
}

function isExamDone (context, examUuid) {
  const { studentData } = context || {}
  const examPercentage = studentData[EXAM_PERCENTAGE]
  return examUuid in examPercentage
}

function allowLiveProctoring (cohortData) {
  if (!cohortData) return false
  const {
    is39WeekCohort,
    relationship: { fields } = {}
  } = cohortData

  if (!fields) return false

  return is39WeekCohort && fields.liveProctoring
}

function getExamKeyFieldNames (courseData, examUUID) {
  if (!courseData || !examUUID) return {}

  const { chapters } = courseData
  const exams = chapters?.filter(chapter => chapter.type === 'exam')

  if (!exams?.length) return {}

  const noOfExams = exams.length
  const noOfexamKeyFields = EXAM_KEY_FIELDS.length
  const examIndex = exams.findIndex(exam => exam.chapter_uuid === examUUID)

  const keys = exams.map((_, index) => {
    const lastExamIndex = noOfExams - 1
    const accessIndex = index === lastExamIndex ? noOfexamKeyFields - 1 : index

    return {
      examKeyName: EXAM_KEY_FIELDS[accessIndex],
      overrideName: EXAM_OVERRIDE_FIELDS[accessIndex]
    }
  })

  return keys[examIndex] || {}
}

function shouldByPassExamKeysPage (params) {
  const { cohortData, overrideFieldName, overrideKeys } = params || {}
  if (!cohortData || !overrideFieldName || !overrideKeys) return false
  const { relationship: { id: relationshipId = {} } = {} } = cohortData
  const overrideList = overrideKeys[overrideFieldName]
  return !!overrideList?.some(id => id === relationshipId)
}

/**
 * @param {Object} examLockStatus - exam lock status object from student progress
 * @returns {Object} - unlocked exams object with exam uuid as key
 * @description - Within examLockStatus:
 * value for exam uuid is undefined till the exam is unlocked from partners dashboard
 * value for exam uuid is set to false if exam is unlocked from partners dashboard
 * value for exam uuid is set to true if exam is locked from partners dashboard
*/

function getUnlockedExams (examLockStatus) {
  if (!examLockStatus) return {}

  const unlockedExams = Object.keys(examLockStatus)
    .reduce((unlockedExamsAcc, examUUID) => {
      const isExamUnlocked = examLockStatus[examUUID] === false
      if (isExamUnlocked) {
        unlockedExamsAcc[examUUID] = isExamUnlocked
        return unlockedExamsAcc
      }
      return unlockedExamsAcc
    }, {})

  return unlockedExams
}
