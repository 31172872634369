import styled from 'styled-components'

const SubsectionDrawerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 21px 12px 22px 12px;
  border-top: 2px solid #262a2c;
  overflow: hidden;
  cursor: pointer;
  background-color: #1a1e22;
  ${props => props.isLastItem && `
    border-bottom: 2px solid #262a2c;
  `}
`

const TagNameText = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  .tag-name {
    font-size: calc(12px + var(--font-increase-px));
    line-height: calc(1.125rem + var(--font-increase-rem));
    letter-spacing: 0.05rem;
    margin: 0;
  }
  span {
    margin-top: 3px;
  }
`

const DrawerWrapper = styled.div`
  padding: 0px 12px;
  background-color: #0F0F0F;
  min-height: ${props => props.minHeight ? `${props.minHeight}px` : 'auto'};
`
const ToggleIcon = styled.img`
  cursor: pointer;
  transform: ${props => props.isAccordionOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: all 0.5s ease-in-out;
  background-color: white;
  mask: url(/images/icons/icon-angle-arrow-light.svg) no-repeat 50% 50%;
  width: 12px;
  height: 7px;
  margin - left: ${props => props.isLocked ? '15px' : '0px'};
`

export {
  SubsectionDrawerItem,
  TagNameText,
  DrawerWrapper,
  ToggleIcon
}
