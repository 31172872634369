import React from 'react'
import PropTypes from 'prop-types'
import { StyledText } from '../styled'

const ConceptMapText = props => {
  const {
    isCompleted,
    isLoading
  } = props

  const getNumberOfPagesText = () => {
    const { numberOfPages } = props
    return `${numberOfPages} Page${numberOfPages !== 1 ? 's' : ''}`
  }

  if (
    isCompleted
  ) return <StyledText>Completed</StyledText>

  if (
    isLoading
  ) return <StyledText><i className='fa fa-spinner fa-spin' /></StyledText>

  return (
    <StyledText>
      {getNumberOfPagesText()}
    </StyledText>
  )
}

ConceptMapText.propTypes = {
  isCompleted: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  numberOfPages: PropTypes.number
}

ConceptMapText.displayName = 'ConceptMapText'

export default ConceptMapText
