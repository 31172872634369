import styled from 'styled-components'
import media from '../../../mediaQueries'

export const ScheduleItemWrapper = styled.div`
  background: #1d1d1f;
  border-radius: 5px;
  position: relative;
  min-height: 70px;
  width: 100%;
  padding: 24px;
  margin-bottom: 12px;
  ${props => !props.textLink && { position: 'relative' }};
`

export const ActiveMarker = styled.div`
  position: absolute;
  width: 3px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background: #5fc4b8;
  border-radius: 2px 0px 0px 2px;
`
export const ScheduleItemBox = styled.div`
  display: grid;
  grid-template-columns: ${({ isExam }) =>
    isExam
      ? '149px calc(100% - 149px - 15%) 15%'
      : '149px calc(100% - 149px - 30%) 30%'};

  ${media.desktop`
    grid-template-columns: ${props =>
    props.textLink ? '35% 65%' : '35% 60% 5%'};
  `}

  ${media.mobile`
    grid-template-columns: 100%;
  `}
`

export const ScheduleDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 149px calc(100% - 149px - 5%) 5%;
  margin-top: 12px;

  ${media.desktop`
    grid-template-columns: 100%;
  `}
`

export const HiddenSpan = styled.span`
  display: none;

  ${media.desktop`
    display: ${props => (props.textLink ? 'block' : 'none')};
  `}
`

export const ScheduleDetails = styled.span`
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 15px;
  ${media.desktop`
    margin-left: 0;
  `}
`

export const ScheduleDate = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  height: 100%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #98a4ae;

  ${media.mobile`
    margin-bottom: 6px;
  `}
`

export const NoBreak = styled.span`
  word-break: keep-all;
`

export const ScheduleTitle = styled.span`
  font-weight: bold;
  font-size: 18px;
  margin-left: 15px;
  line-height: 22px;
  color: #ffffff;
  ${media.desktop`
    margin-left: 0;
  `}
`

export const ScheduleTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ScheduleLastProgressTitle = styled.span`
  font-size: 16px;
  margin-left: 15px;
  color: #ffffff;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  margin-top: 6px;
  ${media.desktop`
    margin-left: 0;
  `}
`

export const ScheduleLastProgressLink = styled.span`
  font-weight: 400;
  color: #5FC4B8;
  cursor: pointer;
`

export const TextLink = styled(ScheduleDate)`
  position: relative;
  top: ${({ top }) => top || '3px'};
  color: #5FC4B8 !important;
  display: flex;
  align-items: center;

  span {
    display: flex;

    &:active {
      opacity: 0.3;
    }
    &:hover {
      filter: brightness(1.5);
    }
  }

  &.schedule-section-link {
    &:active {
      color: #b8fff7;
      opacity: 0.3;
    }
    &:hover {
      color: #b8fff7 !important;
    }
  }

  ${({ hasPointer }) => (hasPointer && 'cursor: pointer')};
`

export const RightMargin = styled.span`
  margin-right: 18px;
`

export const RightAlign = styled.span`
  justify-content: right;
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;

  ${media.desktop`
    margin-left: ${props => (props.textLink ? 0 : 'auto')};
    margin-top:  ${props => (props.textLink ? '6px' : 0)};
  `}

  ${media.mobile`
    margin-top:  ${props => (props.textLink ? '7px' : 0)};
    ${props => !props.textLink && {
    position: 'absolute',
    right: '30px',
    top: '42px' }};
  `}

  ${media.small`
    margin-top:  ${props => (props.textLink ? '6px' : 0)};
  `}
`

export const DownArrow = styled.span`
  width: 6px;
  height: 12px;
  transform: ${props =>
    props.isIconClicked ? 'rotate(90deg)' : 'rotate(270deg)'};
  background-color: #ffffff;
  mask: url(../images/icons/icon-angle-left-arrow.svg);
  margin-left: 27px;

  ${media.desktop`
    ${props => props.textLink && {
    position: 'absolute',
    right: '17px',
    top: '42px' }};
  `}

  ${media.mobile`
    ${props => props.textLink && { right: '30px' }};
  `}
`

export const SyllabusChangeNote = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 24px;
  margin-top: -2px;

  span {
    color: #5fc4b8;
    text-decoration: underline;
    cursor: pointer;

    &:disabled {
      color: #b8fff7;
      opacity: 0.3;
    }
    &:not(:disabled) {
      &:active {
        color: #b8fff7;
        opacity: 0.3;
      }
      &:hover {
        color: #b8fff7;
      }
    }
  }
`

export const ArchivedScheduleNote = styled.div`
  margin-top: 24px;
  margin-bottom: 12px;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;

  span {
    color: #4da096;
    cursor: pointer;
    text-decoration: underline;

    &:disabled {
      color: #b8fff7;
      opacity: 0.3;
    }
    &:not(:disabled) {
      &:active {
        color: #b8fff7;
        opacity: 0.3;
      }
      &:hover {
        color: #b8fff7;
      }
    }
  }
`
