import debounce from 'lodash/debounce'
import React, { Component } from 'react'
import { asyncCheckStudentAnswer } from './utils'
import '../FreeformEquationQuestion/css/free-form.css'

// Implement small inline text field for MathType
// https://github.com/outlier-org/calculus-static/pull/1434#pullrequestreview-378450863

export default class TextField extends Component {
  constructor (props) {
    super(props)
    this.inputRef = React.createRef()
  }

  componentDidMount () {
    this.onChange()
  }

  componentDidUpdate (prevProps) {
    const { quizzesEditor } = this.props
    const isQuestionChanged = quizzesEditor !== prevProps.quizzesEditor

    isQuestionChanged && this.onChange()
  }

  // PUBLIC METHODS:

  getMathML = () => {
    const { inputRef: { current } } = this
    if (!current) return ''

    return current.value
  }

  setMathML = mathML => {
    const { inputRef: { current } } = this
    if (!current) return

    current.value = mathML
  }

  asyncCheckAnswer = async () => {
    const { inputRef: { current } } = this
    if (!current) return false

    const { questionConfiguration, additionalConfigurations } = this.props
    return asyncCheckStudentAnswer({
      questionConfiguration, studentAnswer: current.value, additionalConfigurations
    })
  }

  // PRIVATE METHODS:

  onChange = debounce(() => {
    const { inputRef: { current }, props: { onChange } } = this
    if (!current || !onChange) return

    onChange(current.value)
  }, 100, { maxWait: 300 })

  render () {
    return (
      <>
        <p className='plainTextInputHeading'>Type your answer here:</p>
        <input ref={this.inputRef} type='text'
          className='wirisembeddedtextinput' onChange={this.onChange}
          onFocus={this.onChange} onBlur={this.onChange} />
      </>
    )
  }
}
