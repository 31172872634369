import React, { useState } from 'react'
import PropTypes from 'prop-types'
import InstructorBioModal from '../InstructorBioModal/InstructorBioModal'
import {
  Wrapper,
  LectureSection,
  LectureTitle,
  Instructor,
  Instructions,
  NotesWrapper,
  Note,
  ExamplesSection,
  ExamplesTitle,
  ExampleFiles
} from './styles'

const LectureNotesAndExamples = ({
  lectureVideos = {},
  sectionBreadcrumbArr = [],
  errorFetch,
  isFetching,
  onExampleCodeClick,
  activeNavTab
}) => {
  const {
    course_download: courseDownload,
    codingExamples,
    instructor: { name } = {},
    instructor
  } = lectureVideos.lecturevideos?.[0] || {}

  const [showModal, setShowModal] = useState(false)

  const onNotesClick = (note) => {
    const fileUrl = note?.file
    if (!fileUrl) return

    if (fileUrl.includes('.java')) {
      return window.open(
        `${window.origin}/?assetSrc=${encodeURIComponent(fileUrl)}#/java-compiler`,
        '_blank')
    }

    window.open(note?.file, '_blank')
  }

  return (
    <Wrapper
      isActiveTab={activeNavTab === 1}
      show={activeNavTab !== 2}
    >
      {showModal &&
        <InstructorBioModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          headerContent='Meet your instructor'
          content={instructor}
        />
      }
      <LectureSection>
        <LectureTitle>{sectionBreadcrumbArr[0]?.[0]}</LectureTitle>
        <Instructor onClick={() => setShowModal(true)}>{name}</Instructor>
        {courseDownload?.length ? <>
          <Instructions>Fill in these guided notes as you follow along with the key terms and concepts in the lecture.</Instructions>
          <NotesWrapper>
            {courseDownload?.map((note, index) => (
              <Note
                key={index}
                onClick={() => onNotesClick(note)}
              >
                {note.title}
              </Note>
            ))}
          </NotesWrapper>
        </> : null}
      </LectureSection>
      {codingExamples?.length ? (
        <ExamplesSection>
          <ExamplesTitle>Examples</ExamplesTitle>
          <Instructions>Click on the following examples to code along with the instructor. Then, return to the guided notes.</Instructions>
          {codingExamples.map((resource, index) => (
            <ExampleFiles
              onClick={() => onExampleCodeClick(index)}
              disabled={isFetching || errorFetch}
            >
              {resource.title}
            </ExampleFiles>
          ))}
        </ExamplesSection>
      ) : null}
    </Wrapper>
  )
}

LectureNotesAndExamples.propTypes = {
  lectureVideos: PropTypes.object,
  sectionBreadcrumbArr: PropTypes.array,
  errorFetch: PropTypes.bool,
  isFetching: PropTypes.bool,
  onExampleCodeClick: PropTypes.func
}

LectureNotesAndExamples.displayName = 'LectureNotesAndExamples'
export default LectureNotesAndExamples
