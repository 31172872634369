import React, { useEffect, useState, useRef, useContext } from 'react'
import StudentDataContex from '../Context/Context'
import MathType from '../MathType/MathType'
import OptionsComponent from './OptionsComponent'
import AnswerExplanation from './AnswerExplanation'
import CodingQuestion from './CodingQuestion'
import FreeformAuthoringDisplay from '../FreeformEquationQuestion/FreeformAuthoringDisplay'
import ToastComponents from './ToastComponents'
import RequestErrorToast from './RequestErrorToast'
import {
  CODING_QUESTION,
  FREE_FORM,
  MULTIPLE_CHOICE,
  TRUE_FALSE,
  TEXT_BLANK
} from '../../Constants/questionType'
import { CORRECT } from '../../Constants/result'
import { configureQuizzes } from '../FreeformEquationQuestion/utils/configure-quizzes'
import { display } from '../FreeformEquationQuestion/utils/wiris'
import { isEmptyOrWhiteSpace, cleanupMathMLForText, isPopupEditor } from '../MathType/utils'
import { saveQuestionSetResult, getProblemSetQuestions } from '../../utilities/problemBankUtils'
import {
  PREVIOUS_ICON,
  NEXT_ICON,
  NEXT_ICON_DISABLED
} from '../../Constants/progressBarIconSource'
import {
  CompilationResult,
  TitleWrapper,
  AnswersWrapper,
  ButtonsWrapper,
  AuthoringWrapper,
  CheckButton,
  ArrowButtons,
  NextButtonV2,
  ArrowButtunsWrapper,
  MathTypeWrapper,
  ShowAnswer
} from './styles'
import {
  submitUserCode,
  getAutotestDetails,
  getPracticeResult,
  getScoreDetails,
  getCodegradeToken
} from '../../utilities/codegradeUtils'
import MathJax from '../MathJax/MathJax'
import { isArray } from 'lodash'

function AnswerComponent ({
  activeQuestionIndex = 0,
  questionSet = [],
  setActiveQuestionIndex,
  problemBank,
  courseSections,
  currentProblemSetResponse
}) {
  const currentQuestion = questionSet[activeQuestionIndex] || {}
  const {
    jdoodleEditor,
    codegradeAssignmentId,
    Question_uuid: questionUUID,
    sectionUUID: activeSectionUUID,
    isPopQuestion,
    question_type: questionType,
    correctCodeFile,
    questionSetUUID,
    options,
    configuration,
    additionalConfigurations,
    answer,
    text_when_correct: textWhenCorrect,
    text_when_incorrect: textWhenIncorrect,
    general_explanation: generalExplanation,
    specific_explanation: specificExplanation
  } = currentQuestion

  const [shouldRenderPlugin, setShouldRenderPlugin] = useState(false)
  const [isWirisLoaded, setIsWirisLoaded] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState({})
  const [mathTypeEntry, setMathTypeEntry] = useState('')
  const [hasStudentAnswered, setHasStudentAnswered] = useState(false)
  const [areAllAnswersCorrect, setAreAllAnswersCorrect] = useState(false)
  const [isFreeFormAnswerCorrect, setIsFreeFormAnswerCorrect] = useState(false)
  const [isAnswerChecking, setIsAnswerChecking] = useState(false)
  const [hasStudentGivenUp, setHasStudentGivenUp] = useState(false)
  const [retrySelectedOptions, setRetrySelectedOptions] = useState({})
  const [showToastComponent, setShowToastComponent] = useState(false)
  const [quizzesEditor, setQuizzesEditor] = useState()
  const [shouldRecordAnswer, setShouldRecordAnswer] = useState(false)
  const [isRequestError, setIsRequestError] = useState(false)
  const [showExplanation, setShowExplanation] = useState(false)
  const [codeAnswer, setCodeAnswer] = useState('')

  const studentContext = useContext(StudentDataContex)
  const {
    updateContext,
    studentData: { studentAnswers } = {},
    modalData,
    shouldExerciseSetReload
  } = studentContext
  const isCodingQuestion = questionType === CODING_QUESTION
  const nonPopQuestionsIndex = questionSet
    ?.filter(question => !question.isPopQuestion)
    ?.findIndex(question => question.Question_uuid === questionUUID)
  const currentQuestionResponse = isPopQuestion
    ? currentProblemSetResponse?.popResponse
    : currentProblemSetResponse?.trials?.[nonPopQuestionsIndex]
  const [codingResponse, setCodingResponse] = useState(
    isCodingQuestion ? currentQuestionResponse || null : null
  )

  const mathTypeRef = useRef()
  const shouldAutoChangeQuestion = useRef(true)
  const textBlankInputs = useRef()

  const {
    trials = [],
    popResponse
  } = currentProblemSetResponse || {}

  const {
    answer: savedAnswer = '',
    isAnswerRevealed,
    result
  } = isPopQuestion
    ? popResponse || {}
    : trials[nonPopQuestionsIndex] || {}

  const hasStudentAttemptedQuestion = isPopQuestion
    ? !!popResponse
    : !!trials[nonPopQuestionsIndex]

  useEffect(() => {
    if (!shouldAutoChangeQuestion.current) return
    const nonPopQuestionsAnswered = trials?.length || 0
    const popQuestionsAnswered = popResponse ? 1 : 0
    const totalQuestionsAnswered = nonPopQuestionsAnswered + popQuestionsAnswered
    const index = totalQuestionsAnswered >= questionSet.length
      ? questionSet.length - 1
      : totalQuestionsAnswered

    setActiveQuestionIndex(index)
    setShouldRenderPlugin(true)
    setShowToastComponent(false)
    // eslint-disable-next-line
  }, [currentProblemSetResponse, savedAnswer])

  useEffect(() => {
    if (!shouldExerciseSetReload) return
    setActiveQuestionIndex(0)
    setShouldRenderPlugin(true)
    getProblemSetQuestions(studentContext,
      { problemBank, currentProblemSetUUID: questionSetUUID },
      { activeSectionUUID, courseSections })
    updateContext({
      shouldExerciseSetReload: false,
      modalData: {
        isCompletionModalOpen: false
      }
    })
    // eslint-disable-next-line
  }, [shouldExerciseSetReload])

  useEffect(() => {
    setUpInputListeners()
    populateTextBlankAnswers(savedAnswer)
    setCodingResponse(null)

    if (questionType === FREE_FORM) {
      setSelectedOptions({})
    } else {
      if (isCodingQuestion) {
        setCodingResponse(currentQuestionResponse || null)
      } else {
        const selectedOptions = {}
        const recordedAnswer = savedAnswer || []
        if (questionType === TEXT_BLANK) {
          isArray(recordedAnswer) && recordedAnswer.forEach((answer, index) => {
            selectedOptions[index] = answer
          })
        } else {
          recordedAnswer.forEach(answer => {
            selectedOptions[String(answer * 1 + 1)] = true
          })
        }

        setSelectedOptions(selectedOptions)
      }
    }
    setRetrySelectedOptions({})
    setHasStudentGivenUp(isAnswerRevealed || false)
    setHasStudentAnswered(hasStudentAttemptedQuestion)
    setShowExplanation(isAnswerRevealed || result === 'Correct')
    setIsFreeFormAnswerCorrect(
      questionType === FREE_FORM &&
      (result === CORRECT || result === true)
    )
    setMathTypeEntry('')
    updateContext({
      modalData: {
        ...modalData,
        nextQuestionSetUUID: getNextExerciseSetUUID(),
        sectionUUID: activeSectionUUID
      }
    })

    return () => {
      removeInputListeners()
      updateContext({
        modalData: { isCompletionModalOpen: false }
      })
    }
    // eslint-disable-next-line
  }, [activeQuestionIndex, currentProblemSetResponse])

  useEffect(() => {
    if (isCodingQuestion) {
      const fetchCodegradeToken = async () => {
        await getCodegradeToken()
      }

      fetchCodegradeToken()
      return
    }

    if (questionType !== FREE_FORM) return
    if (window?.com?.wiris?.quizzes) {
      setIsWirisLoaded(true)
      return
    }
    loadWirisScript()
    // eslint-disable-next-line
  }, [activeQuestionIndex])

  useEffect(() => {
    if (!isWirisLoaded) return
    initializeWiris()
      .catch(err => console.log(err))
    // eslint-disable-next-line
  }, [
    questionUUID,
    configuration,
    isWirisLoaded,
    savedAnswer])

  useEffect(() => {
    setAreAllAnswersCorrect(
      areAllStudentAnswersCorrect()
    )
    // eslint-disable-next-line
  }, [selectedOptions])

  useEffect(() => {
    if (!shouldRecordAnswer) return

    const responseData = getStudentResponseData()
    saveQuestionSetResult(responseData, studentContext)
    setShouldRecordAnswer(false)
    const isCorrectAnswer = responseData?.questionResult || responseData?.result === 'Correct'
    setShowExplanation(hasStudentGivenUp || isCorrectAnswer)

    // eslint-disable-next-line
  }, [shouldRecordAnswer])

  useEffect(() => {
    const contextResponse = isPopQuestion
      ? currentProblemSetResponse?.popResponse
      : currentProblemSetResponse?.trials?.[nonPopQuestionsIndex]
    setCodingResponse({
      ...contextResponse || {},
      answer: codeAnswer
    })
    // eslint-disable-next-line
  }, [currentProblemSetResponse, codeAnswer])

  function loadWirisScript () {
    const isScriptLoaded = document.getElementById('wirisScript')
    if (isScriptLoaded) return
    const script = document.createElement('script')
    script.id = 'wirisScript'
    script.src = 'https://www.wiris.net/demo/editor/editor'
    script.onload = () => {
      onWirisLoad()
    }
    document.body.appendChild(script)
  }

  async function initializeWiris () {
    if (!window?.com?.wiris?.quizzes?.api) return
    const contentJSON = {
      text: '',
      feedback: '',
      question: configuration || '',
      gievnAnswer: ''
    }
    const tabDeliveryElem = document.getElementById('tabdelivery')
    if (tabDeliveryElem) {
      tabDeliveryElem.className = ''
    }

    await display(contentJSON, (editor) => {
      setQuizzesEditor(editor || questionUUID)
      if (!mathTypeRef.current) return

      if (!shouldAutoChangeQuestion.current) {
        return mathTypeRef.current.setMathML(savedAnswer || '')
      }

      const isLastQuestion = activeQuestionIndex === questionSet.length - 1

      if (isLastQuestion) {
        return mathTypeRef.current.setMathML(savedAnswer || '')
      }

      return mathTypeRef.current.setMathML('')
    })
  }

  const checkCodeSubmission = async () => {
    const code = codingResponse?.answer
    if (!code) return

    setIsRequestError(false)
    setIsAnswerChecking(true)
    const [submissionResponse, autotestResponse] = await Promise.all([
      submitUserCode({ assignmentId: codegradeAssignmentId, code }),
      getAutotestDetails({ assignmentId: codegradeAssignmentId })
    ])
    const { id: submissionId } = submissionResponse || {}
    const { id: autotestId, runs } = autotestResponse || {}
    const { id: runId } = runs?.[0] || {}

    if (!submissionId || !runId || !autotestId) {
      setIsAnswerChecking(false)
      setHasStudentAnswered(true)
      return
    }
    let submissionResult
    try {
      submissionResult = await getPracticeResult({
        submissionId,
        autotestId,
        runId
      })
    } catch {
      setIsRequestError(true)
      setIsAnswerChecking(false)
      setHasStudentAnswered(false)
      return
    }
    setShouldRenderPlugin(false)
    setIsAnswerChecking(false)

    const scoreDetails = getScoreDetails(
      submissionResponse, autotestResponse, submissionResult
    )
    setCodingResponse({
      ...codingResponse,
      ...scoreDetails,
      result: scoreDetails?.questionResult ? 'Correct' : 'Incorrect',
      submissionId,
      autotestId,
      runId
    })
    setHasStudentAnswered(true)
    setShouldRenderPlugin(true)
  }

  async function onCheckClick () {
    shouldAutoChangeQuestion.current = false
    setHasStudentAnswered(true)

    if (activeQuestionIndex === questionSet.length - 1) {
      updateContext({
        modalData: {
          isCompletionModalOpen: true
        }
      })
    }

    if (isCodingQuestion) {
      await checkCodeSubmission()
      setShouldRecordAnswer(true)
      setShowToastComponent(true)
      return
    }

    if (questionType === FREE_FORM) {
      const { current: editor } = mathTypeRef
      setIsAnswerChecking(true)
      const isAnswerCorrect = await editor.asyncCheckAnswer()
      setIsFreeFormAnswerCorrect(isAnswerCorrect)
      setIsAnswerChecking(false)
      setShouldRecordAnswer(true)
      setShowToastComponent(true)
      return
    }

    if (hasStudentAnswered) {
      if (questionType === TEXT_BLANK) {
        setSelectedOptions(preVal => ({ ...preVal }))
        setShouldRecordAnswer(true)
        setShowToastComponent(true)
        return
      }
      setSelectedOptions(retrySelectedOptions)
      setRetrySelectedOptions({})
    }
    setShouldRecordAnswer(true)
    setShowToastComponent(true)
  }

  function onWirisLoad () {
    const script = document.getElementById('wirisQuizzes')
    if (script) return

    const quizzesScript = document.createElement('script')
    quizzesScript.id = 'wirisQuizzes'
    quizzesScript.src = '/quizzes/resources/quizzes.js'
    quizzesScript.onload = () => {
      setIsWirisLoaded(true)
      configureQuizzes()
    }

    document.body.appendChild(quizzesScript)
  }

  function onOptionSelect (optionType, optObj) {
    if (optionType === 'radio') {
      return setSelectedOptions({
        [optObj.index]: optObj.value
      })
    }

    return setSelectedOptions({
      ...selectedOptions,
      [optObj.index]: optObj.value
    })
  }

  function onRetrySelect (optionType, optObj) {
    if (optionType === 'radio') {
      return setRetrySelectedOptions({
        [optObj.index]: optObj.value
      })
    }

    return setRetrySelectedOptions({
      ...retrySelectedOptions,
      [optObj.index]: optObj.value
    })
  }

  const areSavedTextBlankAnswersCorrect = (answers) => {
    return isArray(answers) && answers.every((savedAnswer, index) => {
      return savedAnswer === answer[index]
    })
  }

  const isCodingResponseCorrect = isPopQuestion
    ? codingResponse?.result
    : codingResponse?.result === CORRECT

  function shouldCheckBtnEnable () {
    if (hasStudentAnswered) {
      if (questionType === TEXT_BLANK) {
        const answers = savedAnswer || []
        return !areSavedTextBlankAnswersCorrect(answers) &&
        !hasStudentGivenUp
      }

      if (areAllAnswersCorrect ||
          hasStudentGivenUp ||
          isAnswerChecking) return false

      if (questionType === FREE_FORM) {
        return !isFreeFormAnswerCorrect
      }

      if (isCodingQuestion) return !isCodingResponseCorrect

      return Object
        .values(retrySelectedOptions)
        .some(option => option)
    }

    if (isCodingQuestion) return !!codingResponse?.answer

    const isValidEntry = !!mathTypeEntry?.length && !isEmptyOrWhiteSpace(mathTypeEntry)

    return Object
      .values(selectedOptions)
      .some(option => option) ||
      isValidEntry
  }

  function onNextClick () {
    removeInputListeners()
    setShowToastComponent(false)
    if (questionSet.length - 1 !== activeQuestionIndex) {
      setActiveQuestionIndex(activeQuestionIndex + 1)
    }
  }

  function onPreviousClick () {
    removeInputListeners()
    shouldAutoChangeQuestion.current = false
    setShowToastComponent(false)
    if (activeQuestionIndex !== 0) {
      setActiveQuestionIndex(activeQuestionIndex - 1)
    }
  }

  function onChange (answer) {
    const MathMlAnswer = cleanupMathMLForText(answer)
    setMathTypeEntry(MathMlAnswer)
  }

  function areAllStudentAnswersCorrect () {
    if (questionType === FREE_FORM || isCodingQuestion) return false

    if (questionType === TEXT_BLANK) {
      return answer?.every((answer, index) => {
        return selectedOptions[index] === answer
      })
    }

    const trueOptions = Object
      .values(selectedOptions)
      .filter(option => option)

    return answer?.every(answer => selectedOptions[answer]) &&
     trueOptions.length === answer.length
  }

  function shouldNextBtnEnable () {
    return hasStudentAnswered &&
      activeQuestionIndex !== questionSet.length - 1 &&
      !isAnswerChecking
  }

  function shouldShowAnswerShow () {
    if (questionType === TEXT_BLANK) {
      const answers = savedAnswer || []
      return hasStudentAnswered &&
      !areSavedTextBlankAnswersCorrect(answers) &&
      !hasStudentGivenUp
    }

    return hasStudentAnswered &&
      !areAllAnswersCorrect &&
      !isAnswerChecking &&
      !isFreeFormAnswerCorrect &&
      !hasStudentGivenUp &&
      !isCodingResponseCorrect
  }

  function getNextExerciseSetUUID () {
    return problemBank?.find(set => {
      return !studentAnswers.some(answer => {
        return answer.uuid === set.question_set_uuid &&
          answer.isPracticeCompleted
      })
    })?.['question_set_uuid']
  }

  function getStudentResponseData () {
    let responseData = {
      activeSectionUUID,
      problemBank,
      currentQuestionSet: questionSet,
      isAnswerRevealed: hasStudentGivenUp,
      questionSetUUID,
      questionUUID
    }

    if (questionType === FREE_FORM) {
      responseData.answer = mathTypeRef.current?.getMathML()
      responseData.questionResult = isFreeFormAnswerCorrect
    } else if (isCodingQuestion) {
      responseData = {
        ...responseData,
        ...codingResponse,
        isAnswerRevealed: hasStudentGivenUp
      }
    } else if (questionType !== TEXT_BLANK) {
      const answerIndexes = Object.keys(selectedOptions)
      const answers = answerIndexes
        ?.filter(answerIndex => selectedOptions[answerIndex])
        .map(index => index * 1 - 1)
      responseData.answer = answers
      responseData.questionResult = areAllStudentAnswersCorrect()
    } else {
      responseData.questionResult = areAllStudentAnswersCorrect()
      responseData.answer = Object.values(selectedOptions)
    }
    return responseData
  }

  function populateTextBlankAnswers (savedAnswer = []) {
    if (questionType !== TEXT_BLANK || !savedAnswer?.length) return
    const { current: inputs } = textBlankInputs

    inputs.forEach((input, index) => {
      input.value = savedAnswer[index] || ''
      const isCorrect = savedAnswer[index] === answer[index]
      updateInputsTextColor(input, isCorrect ? '#78BCB8' : '#E1774F')
    })
  }

  function setUpInputListeners () {
    if (questionType !== TEXT_BLANK) return

    const inputs = document.getElementsByName('blank[]')
    if (!inputs?.length) return

    textBlankInputs.current = inputs
    inputs.forEach((input, index) => {
      input.addEventListener('input', (e) => {
        inputChangeHandler(e, index)
      })
    })
  }

  function removeInputListeners () {
    if (questionType !== TEXT_BLANK) return

    const { current: inputs } = textBlankInputs
    if (!inputs?.length) return

    inputs.forEach((input, index) => {
      input.removeEventListener('input', (e) => {
        inputChangeHandler(e, index)
      })
    })
  }

  function inputChangeHandler (e, index) {
    setSelectedOptions(preVal => ({
      ...preVal,
      [index]: e.target.value
    }))
  }

  function updateInputsTextColor (element, color) {
    if (!element) return
    element.style['-webkit-text-fill-color'] = color
    element.style.color = color
    element.style.borderBottom = `1px solid ${color}`
  }

  const optionsType = answer?.length > 1 ? 'checkbox' : 'radio'
  const nextBtnImgSrc = shouldNextBtnEnable() ? NEXT_ICON : NEXT_ICON_DISABLED
  const shouldOptionsShow = questionType === MULTIPLE_CHOICE ||
    questionType === TRUE_FALSE
  const isCorrectAnswer = areAllAnswersCorrect || isFreeFormAnswerCorrect || isCodingResponseCorrect

  function onCodeChange (code) {
    setCodeAnswer(code)
  }

  return (
    <AnswersWrapper isTextBlank={questionType === TEXT_BLANK}>
      {(!isRequestError && showToastComponent && !isAnswerChecking) &&
        <ToastComponents
          areAllAnswersCorrect={areAllAnswersCorrect}
          isFreeFormAnswerCorrect={isFreeFormAnswerCorrect}
          isCodingResponseCorrect={isCodingResponseCorrect}
          setShowToastComponent={setShowToastComponent}
        />
      }
      {isRequestError && (
        <RequestErrorToast
          isRequestError={isRequestError}
          error='Network error, try again'
        />
      )}
      <AuthoringWrapper>
        <FreeformAuthoringDisplay />
      </AuthoringWrapper>
      {shouldOptionsShow &&
       options?.map((option, index) => (
         <OptionsComponent
           key={index}
           labelText={option}
           type={optionsType}
           index={index + 1}
           onOptionSelect={onOptionSelect}
           onRetrySelect={onRetrySelect}
           checked={
             hasStudentAnswered
               ? retrySelectedOptions[index + 1]
               : selectedOptions[index + 1]
           }
           hasStudentAnswered={hasStudentAnswered}
           hasStudentGivenUp={hasStudentGivenUp}
           selectedOptions={selectedOptions}
           retrySelectedOptions={retrySelectedOptions}
           answer={answer}
           areAllAnswersCorrect={areAllAnswersCorrect}
           activeQuestionIndex={activeQuestionIndex}
         />
       ))}
      {(questionType === FREE_FORM && isWirisLoaded) &&
        <>
          <MathTypeWrapper>
            <div className='mathTypeWrapper'>
              <MathType
                ref={mathTypeRef}
                questionConfiguration={configuration}
                additionalConfigurations={additionalConfigurations}
                quizzesEditor={quizzesEditor}
                onChange={onChange}
              />
            </div>
          </MathTypeWrapper>
        </>
      }
      {shouldRenderPlugin && isCodingQuestion && jdoodleEditor && (
        <CodingQuestion
          key={questionUUID}
          question={currentQuestion}
          sectionType='Practice Exercises'
          codingResponse={currentQuestionResponse || {}}
          onCodeChange={(code) => onCodeChange(code)}
        />
      )}
      {shouldShowAnswerShow() &&
        <ShowAnswer
          onClick={() => {
            setHasStudentGivenUp(true)
            setShouldRecordAnswer(true)
            setShowExplanation(true)
          }}
        >
          show answer
        </ShowAnswer>
      }
      {showExplanation &&
        <AnswerExplanation
          generalExplanation={generalExplanation}
          specificExplanation={specificExplanation}
          isPopup={isPopupEditor(configuration)}
          options={options}
          answer={answer}
          isCorrectAnswer={isCorrectAnswer}
          questionType={questionType}
          correctCodeFile={correctCodeFile?.file?.url}
        />
      }
      {codingResponse?.result !== undefined && (
        <CompilationResult data-testid='coding-feedback'>
          <TitleWrapper>FEEDBACK</TitleWrapper>
          <MathJax
            className='result'
            math={isCodingResponseCorrect
              ? textWhenCorrect
              : textWhenIncorrect?.[0] || ''
            }
          />
        </CompilationResult>
      )}
      {codingResponse?.compilationScore >= 0 && (
        <CompilationResult data-testid='coding-compilation-result'>
          <TitleWrapper>COMPILATION</TitleWrapper>
          <p className='result'>
            {codingResponse?.compilationScore === 1
              ? 'Code compiled successfully.'
              : 'Code did not compile'
            }
          </p>
        </CompilationResult>
      )}
      <ButtonsWrapper>
        <CheckButton
          data-testid='check-answer-button'
          onClick={onCheckClick}
          disabled={!shouldCheckBtnEnable()}
          active={shouldCheckBtnEnable()}
          primary={!hasStudentAnswered}
        >
          {
            isAnswerChecking
              ? (
                <>
                  <i className='fa fa-spinner fa-spin fa-margin' />
                  {isCodingQuestion && 'Compiling'}
                </>
              )
              : 'Check'
          }
        </CheckButton>
        <ArrowButtunsWrapper>
          {activeQuestionIndex !== 0 &&
            <ArrowButtons
              data-testid='previous-question-button'
              src={PREVIOUS_ICON}
              active
              onClick={onPreviousClick}
            />
          }
          <NextButtonV2
            data-testid='next-question-button'
            src={nextBtnImgSrc}
            fullWidth={activeQuestionIndex === 0}
            onClick={onNextClick}
            disabled={!shouldNextBtnEnable()}
            active={shouldNextBtnEnable()}
          />
        </ArrowButtunsWrapper>
      </ButtonsWrapper>
    </AnswersWrapper>)
}

AnswerComponent.displayName = 'AnswerComponent'
export default AnswerComponent
