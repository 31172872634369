import React, { Component } from 'react'
import { isPracticeExam } from '../../utilities/chapterUtils'
import {
  StartButton,
  PreviewButton,
  ButtonWrapper,
  ReviewButton
} from './styled'

class ChapterListProgress extends Component {
  constructor (props) {
    super(props)
    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.getButtonText = this.getButtonText.bind(this)
    this.state = {
      isExamButtonClicked: false
    }
  }

  getButtonText () {
    const {
      chapter_uuid: chapterUUID,
      isPreview,
      isAssignment,
      isVIP,
      isRetakeAllowed,
      isRetakeComplete,
      isChapterComplete,
      isFutureLockTime,
      isDrafted
    } = this.props

    if (isAssignment && isVIP) return 'View'
    if (isAssignment && isDrafted && !isChapterComplete) return 'Continue'
    let buttonText = 'Start'
    if (isChapterComplete) {
      if (!isPracticeExam(chapterUUID)) {
        buttonText = isFutureLockTime ? 'Resubmit' : 'Review'
      }
      if (isRetakeAllowed) buttonText = 'Start'
      if (isRetakeComplete) buttonText = 'Review'
    }
    if (isPreview) buttonText = 'Preview'
    return buttonText
  }

  handleButtonClick (buttonText) {
    const { navigateToChapter } = this.props
    this.setState({
      isExamButtonClicked: true
    }, () => {
      navigateToChapter(() => {
        this.setState({
          isExamButtonClicked: false
        })
      }, buttonText)
    })
  }

  buildButton () {
    const { isExamButtonClicked } = this.state
    const {
      isDisabled
    } = this.props

    if (
      (!this.props.isPracticeExamOpen &&
      isPracticeExam(this.props.chapter_uuid))
    ) {
      return null
    }

    const buttonText = this.getButtonText()

    const buttonValue = isExamButtonClicked && !this.props.showErrorOnMobile
      ? <i className='fa fa-spinner fa-spin' />
      : buttonText

    if (buttonText === 'Start') {
      return <StartButton
        disabled={isDisabled}
        className='btn-primary'
        dataCypress='examButton'
        data-testid='btn-start'
        onClick={() => this.handleButtonClick(buttonText)}
      >
        {buttonValue}
      </StartButton>
    }
    if (buttonText === 'Continue') {
      return <StartButton
        disabled={isDisabled}
        className='btn-primary'
        dataCypress='examButton'
        data-testid='btn-start'
        onClick={() => this.handleButtonClick(buttonText)}
      >
        {buttonValue}
      </StartButton>
    }
    if (buttonText === 'Review' || buttonText === 'Resubmit') {
      return <ReviewButton
        className='btn-secondary'
        dataCypress='examButton'
        data-testid='btn-review'
        onClick={() => this.handleButtonClick()}
      >
        {buttonValue}
      </ReviewButton>
    }
    if (buttonText === 'Preview' || buttonText === 'View') {
      return <PreviewButton
        className='btn-secondary'
        dataCypress='examButton'
        data-testid={`btn-${buttonText.toLowerCase()}`}
        onClick={() => this.handleButtonClick()}
      >
        {buttonValue}
      </PreviewButton>
    }
  }

  render () {
    const { lockReason, isPreview, isRetakeAllowed } = this.props
    if (lockReason && !isPreview && !isRetakeAllowed) return null

    const button = this.buildButton()

    // If there is no button, we don't need the enclosing divs either.
    if (!button) return null

    return (
      <ButtonWrapper>
        {button}
      </ButtonWrapper>
    )
  }
}
export default ChapterListProgress
