/*
This function is used to make the bottom modals stick to the bottom
without overlapping the footer
*/
export function getModalCorrectPosition () {
  const footerHeight = 48
  const isScrolledToBottomOfPage =
        (window.innerHeight + window.scrollY + footerHeight) >=
        document.body.offsetHeight
  let currentDistanceToBottom = document.body.offsetHeight -
            (window.innerHeight + window.scrollY + footerHeight)
  currentDistanceToBottom = Math.round(Math.abs(currentDistanceToBottom))
  return [isScrolledToBottomOfPage, currentDistanceToBottom]
}
