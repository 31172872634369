import styled from 'styled-components'
import { BREAKPOINTS } from '../../../mediaQueries'

export const SidePanelContainer = styled.div` 
    position: absolute;
    width: 264px;
    height: calc(${props => (props.isOutlierUser && !props.QuizIntroPage
    ? props.sidePanelHeight
    : props.sidePanelHeight + 64) +
            ((props.isLastQuestion)
              ? -88
              : -0)}px - 129px - 48px ); // - Headers - Footer
    right: 0;
    top: ${props => props.QuizIntroPage ? '64px'
    : (props.reviewMode ? '-90px' : '0px')};
    ${({ isALRedesign, QuizIntroPage }) => isALRedesign && !QuizIntroPage && `
      top: -68px
    `};
  @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
    position: ${props => props.isSidePanelOpen ? 'fixed' : ''};
    top: ${({ selectedIcon, reviewMode, QuizIntroPage }) => selectedIcon === 0 && `
      ${reviewMode
    ? '-74px'
    : QuizIntroPage ? '65px' : '16px'
}
    `};
    ${({ isALRedesign, QuizIntroPage, selectedIcon }) => selectedIcon === 0 &&
      isALRedesign && `
        top: ${QuizIntroPage ? '64px' : '-66px'}`
};

    ${({ selectedIcon, sidePanelHeight }) => selectedIcon !== 0 && `
      top: 0px;
      width: 100%;
      z-index: 1052;
      height: ${sidePanelHeight}px;
      overflow-y: auto;
    `}

    height: ${props => props.QuizIntroPage && props.selectedIcon === 0
    ? '90vh' : '100vh'};
  }
  @media (max-width: ${BREAKPOINTS.mobile}px) {
    top: 0px;
    ${({ isSidePanelOpen }) => isSidePanelOpen && `
      position: fixed;
    `}    
    ${({ selectedIcon, reviewMode, QuizIntroPage }) => selectedIcon === 0 && `
      top: ${reviewMode
    ? '-100px'
    : QuizIntroPage ? '65px' : '16px'};
    `}


    ${({ isALRedesign, QuizIntroPage, selectedIcon }) => !selectedIcon &&
      isALRedesign && `
        top: ${QuizIntroPage ? '50px' : '-80px'}`
};
  }
`

export const SidePanelWrapper = styled.div`
  position: relative;
  top:0;
  height: calc(100% - 10px);
  border-top: 1px solid #161618;
  background: #101011;
  padding: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1040;

  ${({ isLastQuestion }) => isLastQuestion && `
    padding-bottom: 6rem;
  `};

  div {
    width:240px;
    height: 48px;
    margin-bottom: 12px;
    padding: 14px 38px 15px 12px;
    font-size: 16px;
    cursor: pointer;
    @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
      ${({ selectedIcon }) => selectedIcon !== 0 && `
        width: 100%;
      `}
    }
    span {
      width: 162px;
      display: inline-block;
      vertical-align: top;
      line-height: 19px;
    }
    .girdPinnedIcon {
      display: none;
    }
    span.bold {
      font-weight: 700;
    }
    ${({ isALRedesign }) => isALRedesign
    ? `
        padding: 7px 38px 15px 12px;
        span:before {
          content: 'Card ';
        }
      `
    : `
        span:before {
          content: 'Question ';
        }
    `}
  }
  @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
    ${({ selectedIcon }) => selectedIcon !== 0 && `
      border-top: 0px;
    `}
    padding-bottom:3rem;
  }
`

export const SidePanelHead = styled.div`
  height: 65px;
  width: 264px;
  display: flex;
  justify-content: flex-end;
  padding: 0px 24px 15px 0px;

  ${({ selectedIcon }) => selectedIcon !== 0 && `
    width: 100%;
    background: #101011;
  `}

  @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
    ${({ selectedIcon }) => selectedIcon !== 0 && `
      height: 84px;
      background: #000000;
    `}
  }
`

export const SelectedView = styled.span`
  height: 16px;
  width: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 25px;
  margin-left: 32px;
  cursor: pointer;

  @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
    top: ${props => props.selectedIcon !== 0 ? '44px' : '29px'};
  }
`
export const TextWrapper = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-top: 15px;
  height: 36px;
  width: 54px;
  text-align: left;
  vertical-align: middle;
  line-height: 36px;
  position: absolute;
  left: 12px;
  background: #101011;
  border-radius: 5px;
  
  ${({ selectedIcon }) => selectedIcon === 0 ? `
    text-align: center;
  ` : `
    left: 23px;
    line-height: 16.8px;
    margin-top: 24px;
    height: 17px;
    width: 77px;
  `}

  @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
    margin-top: 43px;
    left: 24px;

    ${({ selectedIcon }) => selectedIcon === 0 ? `
      margin-top: 18px;
      left: 93px;
    ` : `
      width: 168px;
      background: #000000;
    `}
  }
`
export const FullScreenIcon = styled(SelectedView)`
  visibility: visible;
  width: 16px;

  @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
    visibility: hidden;
    width: 0px;
    margin-left: 0px;
  }
`

export const CrossIcon = styled(SelectedView)`
  visibility: hidden;
  width: 0px;
  margin-left: 0px;

  @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
    visibility: visible;
    width: 16px;
    margin-left: 32px;
  }
`
