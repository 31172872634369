import { useState, useEffect } from 'react'

export default function useModal () {
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeClick, false)

    return () => {
      document.removeEventListener('keydown', handleEscapeClick, false)
    }
  }, [])

  const handleEscapeClick = (event) => {
    if (event.keyCode === 27) {
      setIsModalVisible(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }
  const hideModal = () => {
    setIsModalVisible(false)
  }

  return { isModalVisible, showModal, hideModal }
}
