import React, { useEffect, useContext, useState } from 'react'
import StudentDataContex from '../Context/Context'
import ConfettiCanvasComponent from '../ConfettiCanvasComponent/ConfettiCanvasComponent'
import { emitter } from '../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'
import {
  CORRECT_CROWN_ICON,
  INCORRECT_CROWN_ICON
} from '../../Constants/progressBarIconSource'
import {
  Wrapper,
  ButtonsWrapper,
  PrimaryButton,
  SecondaryButton,
  Message,
  RedoButton,
  Icon,
  ConfettiWrapper
} from './styles'
import {
  getModalCorrectPosition
} from '../../utilities/modalUtils'
import { PRACTICE_EXERCISES } from '../../Constants/sectionType'
import { PRACTICE_EXERCISES_COMPLETE } from '../../Constants/studentContext'
import { transformActivityType } from '../../utilities/sectionUtils'

const CompletionModal = () => {
  const [id, setId] = useState()
  const [showConfetti, setShowConfetti] = useState(false)
  const [scrolledToBottom, setScrolledToBottom] = useState(false)
  const [distanceToBottom, setDistanceToBottom] = useState(0)
  const studentContext = useContext(StudentDataContex)
  const {
    modalData = {},
    updateContext,
    currentQuestionSet,
    studentData,
    isPracExerciseSetPassed,
    nextActivity,
    currentProblemSetResponse
  } = studentContext || {}

  const {
    nextQuestionSetUUID,
    sectionUUID
  } = modalData
  const { randomizePracticeQuestions } = currentProblemSetResponse || {}
  const isPracticeExercise = currentQuestionSet?.type === PRACTICE_EXERCISES
  const getNextActivity = () => {
    const CurrentSectionUUID = window?.location?.hash.split('/')[1] || sectionUUID
    const isCompletedSection =
      completeSectionUUIDForPracticeExercises(studentData, CurrentSectionUUID)
    if (isCompletedSection) {
      return nextActivity?.uuid
    }
    return false
  }

  const completeSectionUUIDForPracticeExercises = (
    studentProgress,
    sectionUUID
  ) => {
    const exerciseHasPracticeExercises = studentProgress?.[PRACTICE_EXERCISES_COMPLETE]
    if (exerciseHasPracticeExercises) {
      const isPracticeExercisesCompleted =
        studentProgress?.[PRACTICE_EXERCISES_COMPLETE]?.[sectionUUID]
      if (isPracticeExercisesCompleted) {
        return true
      }
    }
    return false
  }
  const nextActivityUUID = getNextActivity()
  useEffect(() => {
    clearTimeout(id)

    const timeOutId = setTimeout(() => {
      setShowConfetti(true)
    }, 500)

    setId(timeOutId)

    return () => {
      clearTimeout(id)
    }

    // eslint-disable-next-line
  }, [nextQuestionSetUUID, sectionUUID, isPracExerciseSetPassed])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  })

  const handleScroll = () => {
    const [scrolledToBottom, currentDistanceToBottom] = getModalCorrectPosition()
    setScrolledToBottom(scrolledToBottom)
    setDistanceToBottom(currentDistanceToBottom)
  }

  function onClick () {
    if (nextActivityUUID) {
      emitter.emit(ON_NAVIGATE_TO,
        `/${sectionUUID}/${nextActivityUUID}`
      )
      return updateContext({
        modalData: {
          isCompletionModalOpen: false
        }
      })
    } else if (isPracExerciseSetPassed || !randomizePracticeQuestions) {
      emitter.emit(ON_NAVIGATE_TO,
        `/${sectionUUID}${nextQuestionSetUUID
          ? '/' + nextQuestionSetUUID : ''}`
      )
      return updateContext({ modalData: {
        isCompletionModalOpen: false
      } })
    }
    resetSetAndCloseModal()
  }

  function resetSetAndCloseModal () {
    updateContext({
      shouldExerciseSetReload: true,
      modalData: { isCompletionModalOpen: false }
    })
  }

  const icon = isPracExerciseSetPassed ? CORRECT_CROWN_ICON : INCORRECT_CROWN_ICON
  const SET_COMPLETE = 'Set complete! Continue to the next one.'
  const SET_FAILED = 'You did not pass the Challenge.'
  const SET_NOT_CORRECT = `You didn’t get the challenge question correct this time.${
    randomizePracticeQuestions ? '' : 'Try again in the next set!'
  }`
  const SET_COMPLETE_QUIZ = `Practice Problems complete! Continue to ${transformActivityType(nextActivity?.type)}.`
  const confettiProps = {
    height: '100%',
    colors: [
      ['#5fc4b8', '#5fc4b8'],
      ['white', 'white']
    ],
    duration: 0.01,
    paperCount: 50,
    ribbonCount: 8,
    ribbonParticleCount: 3,
    ribbonParticleThick: 2
  }
  const isQuiz = modalData?.type === 'quiz'

  if (isQuiz) {
    return (<Wrapper
      isQuiz={isQuiz}
      isOnlyBtn={!nextQuestionSetUUID}
    >
      <Message>
        <p>{modalData.text}</p>
      </Message>
      <ButtonsWrapper
        isQuiz={isQuiz}
        isOnlyBtn={!nextQuestionSetUUID}
      >
        { modalData.buttons.primary && <PrimaryButton
          isOnlyBtn={!nextQuestionSetUUID}
          isQuiz={isQuiz}
          onClick={modalData.buttons.primary.onClick}
        >
          {modalData.buttons.primary.text}
        </PrimaryButton>}
        {modalData.buttons?.secondary && (
          <SecondaryButton
            onClick={modalData.buttons.secondary.onClick}
          >
            {modalData.buttons.secondary.text}
          </SecondaryButton>
        )}
      </ButtonsWrapper>
    </Wrapper>)
  }

  if (!sectionUUID) return null

  return (
    <Wrapper
      isOnlyBtn={!nextQuestionSetUUID}
      scrolledToBottom={scrolledToBottom}
      distanceToBottom={distanceToBottom}
    >
      {isPracExerciseSetPassed && showConfetti && !isPracticeExercise &&
      <ConfettiWrapper>
        <ConfettiCanvasComponent {...confettiProps} />
      </ConfettiWrapper>}
      <Message isPracticeExercise={isPracticeExercise}>
        {!isPracticeExercise && <Icon data-testid='icon' src={icon} />}
        {isPracExerciseSetPassed && !isPracticeExercise &&
        <RedoButton
          onClick={resetSetAndCloseModal}
        >
          Redo set
        </RedoButton>
        }
        <p>
          {isPracExerciseSetPassed && !nextActivityUUID
            ? SET_COMPLETE
            : isPracticeExercise && !nextActivityUUID
              ? SET_NOT_CORRECT
              : nextActivityUUID
                ? SET_COMPLETE_QUIZ
                : SET_FAILED}
        </p>
      </Message>
      <ButtonsWrapper
        isOnlyBtn={!nextQuestionSetUUID}
      >
        <PrimaryButton
          isOnlyBtn={!nextQuestionSetUUID}
          onClick={onClick}
        >
          {isPracExerciseSetPassed && !nextActivityUUID
            ? isPracticeExercise
              ? 'Go to next'
              : nextQuestionSetUUID
                ? `NEXT SET` : `EXIT`
            : isPracticeExercise && !nextActivityUUID && randomizePracticeQuestions
              ? 'Retry'
              : nextActivityUUID || !randomizePracticeQuestions
                ? 'Continue' : 'TRY AGAIN'}
        </PrimaryButton>
        {nextQuestionSetUUID && !isPracticeExercise && (
          <SecondaryButton
            onClick={() => {
              emitter.emit(ON_NAVIGATE_TO, '/' + sectionUUID)
              updateContext({ modalData: { isCompletionModalOpen: false } })
            }}
          >
            EXIT
          </SecondaryButton>
        )}
        {((isPracExerciseSetPassed || nextActivityUUID) && randomizePracticeQuestions) &&
          <SecondaryButton
            onClick={resetSetAndCloseModal}
          >
            Redo set
          </SecondaryButton>
        }
      </ButtonsWrapper>
    </Wrapper>
  )
}

CompletionModal.displayName = 'CompletionModal'
export default CompletionModal
