
import React, { Component } from 'react'
import MathJax from '../MathJax/MathJax'
import {
  EYE_OPEN_ICON,
  NEW_INCORRECT_QUESTION_ICON,
  NEW_INCORRECT_ATTEMPTED_QUESTION_ICON,
  NEW_CORRECT_UNATTEMPTED_QUESTION_ICON,
  NEW_COMPLETED_ICON,
  EYE_CLOSED_ICON
} from '../../Constants/progressBarIconSource'
import { TRUE_FALSE } from '../../Constants/questionType'
import {
  OptionsWrapper,
  Label,
  Input,
  TextWrapper,
  IconWrapper,
  CorrectAnswerText,
  Icon
} from './style'

class Radiobutton extends Component {
  constructor (props) {
    super(props)
    this.changeRadioButtonChange = this.changeRadioButtonChange.bind(this)
    this.state = {
      isEyeOpen: false,
      isDisabledBox: false
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { questionUUID } = this.props
    if (prevProps.questionUUID !== questionUUID) {
      this.setState({ isEyeOpen: false, isDisabledBox: false })
    }
  }

  changeRadioButtonChange () {
    const { handleCheckboxChange, value } = this.props
    handleCheckboxChange(value)
  }

  getIcon () {
    const {
      label, isRadioChecked, correctIncorrect, reviewMode, solutionData
    } = this.props
    const { isEyeOpen } = this.state
    const isCurrentCorrect = correctIncorrect === 'ans-correct'

    if (!reviewMode) {
      return isEyeOpen ? EYE_OPEN_ICON : EYE_CLOSED_ICON
    }

    if (isRadioChecked) {
      return isCurrentCorrect
        ? NEW_COMPLETED_ICON
        : NEW_INCORRECT_ATTEMPTED_QUESTION_ICON
    }

    return !isCurrentCorrect && solutionData.correctAnswer === label
      ? NEW_CORRECT_UNATTEMPTED_QUESTION_ICON
      : NEW_INCORRECT_QUESTION_ICON
  }

  render () {
    const {
      label, value, isRadioChecked, correctIncorrect, disabled, dataCypress,
      isLastIndex, isAnsweringDisabled, typeOfQuestion, isAssessmentQuizExam,
      isSidePanelOpen, reviewMode, solutionData, isALRedesign
    } = this.props
    const { isEyeOpen, isDisabledBox } = this.state
    let inctOptions = ''
    let radioDisabled = false
    const isCurrrentCorrect = correctIncorrect === 'ans-correct'
    const toggleIconSrc = this.getIcon()
    if (correctIncorrect && correctIncorrect === 'incorrect ans-incorrect' && isRadioChecked) {
      inctOptions = 'incorrect-options'
    }
    if (disabled) {
      radioDisabled = true
    }
    const answerCorrect = correctIncorrect === 'ans-correct'
    return (
      <OptionsWrapper
        data-cy={dataCypress}
        isLastIndex={isLastIndex}
        isAssessmentQuizExam={isAssessmentQuizExam}
        isSidePanelOpen={isSidePanelOpen}
        isALRedesign={isALRedesign}
      >
        <Input
          type='radio'
          value={value}
          checked={isRadioChecked}
          disabled={radioDisabled || (isEyeOpen && isDisabledBox)}
          isAssessmentQuizExam={isAssessmentQuizExam}
          isALRedesign={isALRedesign}
          onChange={() => { if (!radioDisabled) this.changeRadioButtonChange() }}
        />
        <Label
          className={`${!isALRedesign && inctOptions} ${(isRadioChecked &&
            !(isAssessmentQuizExam && reviewMode))
            ? !isALRedesign && correctIncorrect
            : ''}
          `}
          isIncorrectOption={!!inctOptions}
          isAssessmentQuizExam={isAssessmentQuizExam}
          isEyeOpen={isEyeOpen}
          typeOfQuestion={typeOfQuestion}
          reviewMode={reviewMode}
          checked={isRadioChecked}
          isCorrect={isCurrrentCorrect}
          isAnsweringDisabled={isAnsweringDisabled}
          isCurrentOptionCorrect={solutionData?.correctAnswer === label}
          type={TRUE_FALSE}
          isALRedesign={isALRedesign}
          correctIncorrect={correctIncorrect}
        >
          {isRadioChecked && (isALRedesign || answerCorrect) &&
          !isAssessmentQuizExam
            ? <IconWrapper
              isSidePanelOpen={isSidePanelOpen}
              isALRedesign={isALRedesign}>
              {(isALRedesign && answerCorrect)
                ? <Icon src={NEW_COMPLETED_ICON} alt='Correct' />
                : isALRedesign && correctIncorrect &&
                  <Icon
                    src={NEW_INCORRECT_ATTEMPTED_QUESTION_ICON}
                    alt='InCorrect' />}
              {!isALRedesign &&
                <Icon src='images/icons/correct-answer.png'
                  alt='Correct' />
              }
            </IconWrapper>
            : (!disabled || reviewMode) &&
            (isAssessmentQuizExam || isALRedesign) &&
            <IconWrapper
              isALRedesign={isALRedesign}
              isSidePanelOpen={isSidePanelOpen}
              isAssessmentQuizExam={isAssessmentQuizExam}>
              <Icon
                src={toggleIconSrc}
                alt='Incorrect'
                onClick={() => {
                  this.setState({
                    isEyeOpen: !isEyeOpen,
                    isDisabledBox: !isDisabledBox
                  })
                }} />
            </IconWrapper>}
          <TextWrapper
            isAssessmentQuizExam={isAssessmentQuizExam}
            isALRedesign={isALRedesign}
            isEyeOpen={isEyeOpen}
          >
            {isRadioChecked && correctIncorrect && isALRedesign && (
              <CorrectAnswerText isALRedesign={isALRedesign}>
                {isCurrrentCorrect ? 'Correct Answer' : 'Incorrect Answer'}
              </CorrectAnswerText>
            )}
            {<MathJax math={label} />}
          </TextWrapper>
        </Label>
      </OptionsWrapper>
    )
  }
}

export default Radiobutton
