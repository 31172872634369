/** DOCS https://github.com/pubkey/broadcast-channel */
import { BroadcastChannel } from 'broadcast-channel'

export {
  postMessage,
  onMessage
}
/** Add TabID for each tab */
localStorage.tabID = localStorage.tabID || 0
const tabID = parseInt(localStorage.tabID) + 1
localStorage.tabID = tabID

const channel = new BroadcastChannel('tabs')

function postMessage (message) {
  channel.postMessage({ tabID, message })
}

function onMessage (callback) {
  channel.onmessage = payload => {
    if (payload.tabID !== tabID) callback(payload.message)
  }
}
