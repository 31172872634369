import React, { useEffect, useState } from 'react'
import {
  ToastComponentsWrapper,
  ToastComponent,
  BBConfetti
} from './styles'

function ToastComponents ({
  setShowToastComponent,
  areAllAnswersCorrect,
  isCodingResponseCorrect,
  isFreeFormAnswerCorrect
}) {
  const [isToastVisible, setIsToastVisible] = useState(false)

  useEffect(() => {
    const id1 = setTimeout(() => {
      setIsToastVisible(true)
    }, '10')

    const id2 = setTimeout(() => {
      setIsToastVisible(false)
      setShowToastComponent(false)
    }, '2500')

    return () => {
      clearTimeout(id1)
      clearTimeout(id2)
    }
  }, [setShowToastComponent])

  const isCorrect = areAllAnswersCorrect || isFreeFormAnswerCorrect || isCodingResponseCorrect
  return (
    <ToastComponentsWrapper
      showToast={isToastVisible}
    >
      <ToastComponent>
        <p>{isCorrect
          ? 'Correct!'
          : 'Good try! Give it another go'}
        </p>
        {(isCorrect) && <BBConfetti />}
      </ToastComponent>
    </ToastComponentsWrapper>
  )
}

ToastComponents.displayName = 'ToastComponents'
export default ToastComponents
