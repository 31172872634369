import React, { Component } from 'react'
import { createHashHistory } from 'history'
import Context from '../Context/Context'

export const history = createHashHistory({ hashType: 'slash' })
export const location = history.location
class HistoryManager extends Component {
  constructor (props) {
    super(props)
    this.state = { activePath: location.pathname }
  }

  location = history.listen((location, action) => {
    this.setState({
      activePath: location.pathname
    })
  })

  componentDidMount () {
    const { redirectURI } = this.context
    if (redirectURI) {
      this.setState({ activePath: redirectURI })
    }
  }

  render () {
    return (
      <React.Fragment>
        {React.cloneElement(this.props.children,
          {
            activePath: this.state.activePath,
            context: this.context
          })
        }
      </React.Fragment>
    )
  }
}

HistoryManager.contextType = Context
HistoryManager.displayName = 'HistoryManager'
export default HistoryManager
