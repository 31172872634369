import { useEffect } from 'react'

// this is used to ensure a page starts from the top on initial mount
// it's forced to scroll to the top of the page on mount
const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return null
}

export default ScrollToTop
