import React from 'react'
import { ReactComponent as ExtendDeadlineIcon } from './icon-extend-deadline.svg'
import DeadlineCalendar from './DeadlineCalendar'
import {
  DeadlineMessageContainer,
  DeadlineMessage,
  DeadlineExtendedMessage,
  DeadlineWarning,
  DeadlineExtensionLink,
  SmallCalendarIcon,
  LargeCalendarIcon
} from './styled'

function DeadlineWarningComponent ({
  assignmentUUID,
  isExtendedDeadline,
  show24HrDeadlineWarning
}) {
  const extensionLink = `#/extension-request/${assignmentUUID}`
  return (
    <DeadlineWarning
      show24HrDeadlineWarning={show24HrDeadlineWarning}
      data-testid='deadline-warning'
    >
      <SmallCalendarIcon>
        <DeadlineCalendar
          show24HrDeadlineWarning={show24HrDeadlineWarning}
          size={24}
        />
      </SmallCalendarIcon>
      <DeadlineMessageContainer>
        <DeadlineMessage>
          Closing Soon!
        </DeadlineMessage>
        {!isExtendedDeadline &&
          <DeadlineExtensionLink href={extensionLink}>
            <div>EXTEND DEADLINE</div>
            <ExtendDeadlineIcon />
          </DeadlineExtensionLink>}
        {isExtendedDeadline &&
          <DeadlineExtendedMessage
            show24HrDeadlineWarning={show24HrDeadlineWarning}
          >
            Deadline extended
          </DeadlineExtendedMessage>}
      </DeadlineMessageContainer>
      <LargeCalendarIcon>
        <DeadlineCalendar
          show24HrDeadlineWarning={show24HrDeadlineWarning}
          size={32}
        />
      </LargeCalendarIcon>
    </DeadlineWarning>
  )
}

export default DeadlineWarningComponent
