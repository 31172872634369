import React, { useState, useContext, useEffect } from 'react'
import { Collapse } from 'reactstrap'
import LockedMarker from '../LockedMarker/LockedMarker'
import { VideosWrapper } from '../LecturesExerciseItem/styled'
import { ReadingsSubText, ReadingsSubTextDiv } from './styled'
import { capitalizeFirstLetter } from '../../utilities'
import config from '../../config'
import ReadingItem from './ReadingItem'
import { SectionListExerciseItemStyle, ToggleIcon } from
  '../SectionListExcerciseItem/styled'
import { READINGS_PROGRESS, READINGS_COMPLETE } from
  '../../Constants/studentContext'
import Context from '../Context/Context'
import localStorageUtil from '../../utilities/localStorage'
import CustomTooltip from '../Tooltip'
import { TooltipIcon } from '../Tooltip/styled'

export default function ReadingsExerciseList (props) {
  const { isLastItem, lockReason, excercise, sectionUUID } = props
  const { type, readings } = excercise

  const { studentData: {
    [READINGS_PROGRESS]: readingsProgress,
    [READINGS_COMPLETE]: readingsComplete }
  } = useContext(Context)

  const readingsCount = readings?.length
  const openedReadingsCount = readings.filter(reading => {
    const { question_set_uuid: readingUUID } = reading
    return !!readingsProgress[readingUUID]
  })?.length
  const unopenedReadingsCount = readingsCount - openedReadingsCount

  const isReadingsComplete = openedReadingsCount > 0 &&
    !!readingsComplete[sectionUUID]
  const isLocked = !!lockReason
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  useEffect(() => {
    /* Use localstorage value if it's available */
    const { get } = readingsAccordionState()
    const accordionState = get()
    if (accordionState) return setIsAccordionOpen(accordionState === 'true')
    /* Accordion is closed if
      - readings section is locked
      - readings section is unlocked and all readings are opened
      Accordion is open if section is unlocked and some readings are unopened
    */
    setIsAccordionOpen(!isLocked && !isReadingsComplete)
    // eslint-disable-next-line
  }, [])

  const readingsAccordionState = () => {
    return localStorageUtil.stateFactory(`readings-accordion-${sectionUUID}`)
  }

  const toggleAccordion = () => {
    const { set } = readingsAccordionState()
    set(!isAccordionOpen)
    setIsAccordionOpen(!isAccordionOpen)
  }
  const getReadingsSubtitle = (unopenedReadingsCount, readingsCount) => {
    if (unopenedReadingsCount === 0) return 'Completed'
    if (unopenedReadingsCount === readingsCount) {
      return `${unopenedReadingsCount} reading${readingsCount > 1 ? 's' : ''}`
    }
    return `${unopenedReadingsCount} reading${unopenedReadingsCount > 1 ? 's' : ''} remaining`
  }

  const { course: { readingsLabel, readingsTooltipText } } = config

  const [{ field_tooltip: tooltip }] = readings
  const fieldTooltip = tooltip || readingsTooltipText

  return (
    <>
      <SectionListExerciseItemStyle
        isLastItem={isLastItem}
        className={`section-panel-active d-flex
      justify-content-between align-items-center`}
        data-cy='sectionListExerciseItem'
        onClick={toggleAccordion}
      >
        <div>
          <div className='main-panels--left'>
            <h3 className='panel-title d-inline-block section-title'>
              {readingsLabel || capitalizeFirstLetter(type)}
            </h3>
            <CustomTooltip
              id='reading-tooltip'
              text={fieldTooltip}
            >
              <TooltipIcon>?</TooltipIcon>
            </CustomTooltip>
          </div>
          <ReadingsSubTextDiv>
            <ReadingsSubText>
              {getReadingsSubtitle(unopenedReadingsCount, readingsCount)}
            </ReadingsSubText>
          </ReadingsSubTextDiv>
        </div>

        <div className='main-panels--right d-flex align-items-center'>
          {isLocked && <LockedMarker />}
          <ToggleIcon
            isAccordionOpen={isAccordionOpen}
            src={'images/icons/icon-angle-arrow-light.svg'}
            isLocked={isLocked}
          />
        </div>
      </SectionListExerciseItemStyle>
      <Collapse isOpen={isAccordionOpen}>
        <VideosWrapper>
          {
            readings.map((reading, index) => {
              return <ReadingItem key={reading['question_set_uuid']} {...reading}
                isLocked={isLocked} index={index}
                sectionUUID={sectionUUID} />
            })
          }
        </VideosWrapper>
      </Collapse>
    </>
  )
}
