export const ACTIVE_ICON = '/images/icons/icon-progress-active.svg'
export const COMPLETED_ICON = '/images/icons/icon-progress-correct.svg'
export const NEW_ACTIVE_ICON = '/images/icons/icon-new-progress-active.svg'
export const NEW_COMPLETED_ICON = '/images/icons/icon-new-progress-correct.svg'
export const NEW_INCORRECT_ICON = '/images/icons/icon-new-progress-incorrect.svg'
export const NEW_INCORRECT_QUESTION_ICON = '/images/icons/icon-new-progress-incorrect-question.svg'
export const NEW_INCORRECT_ATTEMPTED_QUESTION_ICON = '/images/icons/icon-new-progress-incorrect-attempted-question.svg'
export const NEW_CORRECT_UNATTEMPTED_QUESTION_ICON = '/images/icons/icon-new-progress-correct-unattempted-question.svg'
export const NEW_EMPTY_ICON = '/images/icons/icon-new-progress-empty.svg'
export const NEW_GUESSWORK_ICON = '/images/icons/icon-new-progress-guesswork.svg'
export const NEW_GUESSWORK_WHITE_ICON = '/images/icons/icon-new-progress-white-guesswork.svg'
export const PINNED_ICON = '/images/icons/icon-pinned.svg'
export const PINNED_ICON_WHITE = '/images/icons/icon-pinned-white.svg'
export const PIN_ICON = '/images/icons/icon-pin.svg'
export const PINNED_ICON_GRID = '/images/icons/icon-pinned-grid.svg'
export const INCORRECT_ICON = '/images/icons/icon-progress-incorrect.svg'
export const EMPTY_ICON = '/images/icons/icon-progress-empty.svg'
export const NEXT_ICON = '/images/icons/icon-next.svg'
export const NEXT_ICON_DISABLED = '/images/icons/icon-next-disabled.svg'
export const NEXT_ICON_ENABLED = '/images/icons/icon-next-enabled.svg'
export const PREVIOUS_ICON = '/images/icons/icon-previous.svg'
export const EYE_OPEN_ICON = '/images/icons/icon-eye-opened.svg'
export const EYE_CLOSED_ICON = '/images/icons/icon-eye-closed.svg'
export const GUESSWORK_ICON = '/images/icons/icon-progress-guesswork.svg'
export const POPUP_ICON = '/images/icons/icon-progress-pop.svg'
export const POPUP_ACTIVE_ICON = '/images/icons/icon-progress-pop-active.svg'
export const POPUP_INCORRECT_ICON = '/images/icons/icon-progress-pop-incorrect.svg'
export const POPUP_COMPLETED_ICON = '/images/icons/icon-progress-pop-correct.svg'
export const CHALLENGE_ICON = '/images/icons/icon-progress-challenge.svg'
export const PRACTICE_NEXT_ICON_DISABLED = '/images/icons/icon-prac-next-disabled.svg'
export const SELECTED_CIRCLE_ICON = '/images/icons/icon-selected-question-incomplete.svg'
export const UNSELECTED_CIRCLE_ICON = '/images/icons/icon-unselected-question-incomplete.svg'
export const LAST_QUESTION_ICON = '/images/icons/icon-challenge-question.svg'
export const POPUP_QUESTION_ICON = '/images/icons/icon-pop-question.svg'
export const SELECTED_CORRECT_ICON = '/images/icons/icon-correct-selected-focused.svg'
export const SELECTED_INCORRECT_ICON = '/images/icons/icon-incorrect-selected-focused.svg'
export const INCORRECT_CROWN_ICON = '/images/icons/icon-incorrect-crown.svg'
export const CORRECT_CROWN_ICON = '/images/icons/icon-correct-crown.svg'
