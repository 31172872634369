import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  div.content {
    margin-top: 141px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;

    span.error {
      color: #E1774F;
      margin-bottom: 6px;
    }

    div.buttons-container {
      display: flex;
    }

    button {
      width: 140px;
      line-height: 17px;
      height: 40px;
      margin: 0px 12px 0 0;
    }

    &.center {
      margin-top: 246px;
      align-items: center;
      max-width: 772px;

      h2 {
        font-size: 24px;
        line-height: 32px;
        color: #e7e7e7;
        font-family: sans-serif;
      }

      p {
        text-align: center;
        font-size: 20px;
        line-height: 28px;
        color: #e7e7e7b3;
        font-family: sans-serif;
      }
    }
  }
`

export const Header = styled.h2`
  font-size: 36px;
  line-height: 43px;
  margin-bottom: ${props => props.marginBottom || '12px'};
  font-weight: 300;
`

export const Content = styled.p`
  font-size: 18px;
  line-height: 22px;
  margin-bottom: ${props => props.marginBottom || '16px'};

  span {
    font-weight: 700;

    &.success {
      color: #5FC4B8;
    }
  }
`

export const Label = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 6px;
  margin-top: 16px;
`

export const Input = styled.input`
  margin-bottom: 24px;
  width: 100%;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  border: ${props => props.hasError
    ? '1px solid #E1774F' : '1px solid rgba(255, 255, 255, 0.3)'};
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  padding: 0px 10px;
  &:focus {
    outline: none;
    border: 1px solid #5fc4b8;
  }
`

export const StyledSuccessLink = styled.a`
  color: #5FC4B8;
  text-decoration: underline;
  font-weight: 700;
  display: inline;
`
