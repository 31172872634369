import { QUESTION, QUESTIONS, ACTIVE_LEARNING_CARD } from '../Constants/dato'
import config from '../config'

export const setDatoUrl = (cardData, updateContext) => {
  const {
    datoRecordId,
    questionType,
    cardType
  } = cardData

  const type = cardType || questionType
  const model = QUESTIONS.includes(type) ? QUESTION : ACTIVE_LEARNING_CARD
  updateContext({
    activeLearningDatoLink: config.getDatoEditingUrl(datoRecordId, model)
  })
}
