import styled from 'styled-components'
import media from '../../../mediaQueries'

export const VideoWrapper = styled.div`
  margin-top: 48px;
  margin-bottom: 12px;
`

export const VideoChapterTable = styled.table`
  margin-top: 12px;
  font-size: 18px;
  line-height: 22px;
  border-collapse: separate;
  border-spacing: 0px 12px;

  td:nth-child(1) {
    color: #B1BFC5;
    padding-right: 12px;
    vertical-align: text-top;
  }
`

export const VideoChapterToggle = styled.div`
  cursor: pointer;
  display: flex;
  padding-top: 12px;
  line-height: 17px;
`

export const VideoChapterToggleIcon = styled.span`
  margin-top: 8px;
  margin-right: 12px;
  mask: url(/images/icons/icon-angle-arrow-light.svg) no-repeat 50% 50%;
  transform: ${props => props.showChapters ? 'rotate(180deg)' : 'rotate(0deg)'};
  width: 12px;
  height: 7px;
  background-color: white;
`

export const VideoChapterToggleText = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
`

export const RequiredToolsHeader = styled.div`
  margin-top: 48px;
  font-size: 24px;
  font-weight: 300;
  line-height: 29px;
`

export const RequiredToolsSection = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 12px;

  ${media.tablet`
    grid-template-columns: auto;
  `}
`

export const RequiredToolsSectionItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  box-sizing: border-box;
  padding: 12px;

  color: #fff;
  background: #161618;
  background-clip: padding-box; /* !importanté */
  border: solid 1px transparent; /* !importanté */
  border-radius: 5px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`

export const RequiredToolsSectionHeader = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
`

export const RequiredToolsSectionDescription = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  margin-top: 12px;
`

export const RequiredToolsSectionLink = styled.a`
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-transform: uppercase;
  text-decoration: none;
  color: #5FC4B8;
  letter-spacing: 1px;
  margin-top: 12px;

  &:disabled {
    color: #b8fff7;
    opacity: 0.3;
  }
  &:not(:disabled) {
    &:active {
      color: #b8fff7;
      opacity: 0.3;
    }
    &:hover {
      color: #b8fff7;
      text-decoration: none;
    }
  }
`

export const HowItWorksHeader = styled.div`
  margin-top: 48px;
  font-size: 24px;
  font-weight: 300;
  line-height: 29px;
`

export const HowItWorksSection = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 12px;

  ${media.giant`
    grid-template-columns: auto auto;
  `}

  ${media.tablet`
    grid-template-columns: auto;
  `}
`

export const HowItWorksSectionItemHeader = styled.div`
  display: flex;
`

export const HowItWorksSectionItemHeaderImage = styled.img`
  margin-right: 12px;
`

export const HowItWorksSectionItemHeaderText = styled.div`
  font-size: 18px;
  line-height: 22px;
`

export const HowItWorksSectionItemDescription = styled.div`
  margin-top: 13px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
`
