const extractUUIDs = (uuid) => {
  if (!uuid?.length) return

  const firstSlashRemovedUUID = uuid.substr(1)
  const UUIDs = firstSlashRemovedUUID.split('/')
  const [parentUUID, childUUID, extraUUID] = UUIDs

  return {
    parentUUID,
    childUUID,
    extraUUID
  }
}

const isInvalidParentUUID = (navigation, id) => {
  return navigation.every((arr) => arr.uuid !== id)
}

const isInvalidExtraUUID = (id) => {
  const extraUUIDs = ['questions', 'practice', 'Quizlet']

  return id && !extraUUIDs.includes(id)
}

const isInvalidChildUUID = (navigation, id, isExamRetakeType) => {
  const screensHavingChildren = navigation.filter(elem =>
    elem.children && elem.children !== null
  )

  return !(screensHavingChildren
    .some(arr => arr.children
      .some(ch => {
        if (!isExamRetakeType) return ch.uuid === id

        const { examVersions } = ch || {}
        if (!examVersions?.length) return false

        const isVersionUUID = examVersions[0].Question
          .some(question => question.Question_uuid === id)
        return isVersionUUID
      }))
  )
}

/**
 * @param {Array} navigation
 * @param {Number} currentScreenIndex
 * @param {Number} currentChildIndex
 * @description Problem bank questions are not added to the children of navigation objects,
 * as they are random question sets chosen when the problem set is opened. Hence checking if
 * childUUID is valid should be skipped for problem bank questions.
 */
const isProblemBankQuestion = ({
  navigation, uuid, currentScreenIndex, currentChildIndex }) => {
  const splitUUID = uuid && uuid.split('_')
  if (splitUUID && splitUUID.length === 2) return true
  const currentScreen = navigation[currentScreenIndex]
  if (!currentScreen || !currentScreen.children) return false
  const child = currentScreen.children[currentChildIndex]
  return child?.type === 'question_problem_bank'
}

export {
  extractUUIDs,
  isInvalidParentUUID,
  isInvalidChildUUID,
  isProblemBankQuestion,
  isInvalidExtraUUID
}
