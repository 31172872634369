import Cookies from 'js-cookie'
import util, { getCourseNameFromHostname } from './utilities'
import localStorageUtil from './utilities/localStorage'
import {
  STUDENT_PROGRESS,
  RECOMMENDED_PROGRESS,
  LINKS_ONLY,
  RECORD_ROOM_START,
  NOTIFICATION
} from './Constants/flagKeys'
import { DATO_EDITING_URL, DATO_MODEL_UUIDS, QUESTION } from './Constants/dato'
import {
  OUTLIER_DOMAIN,
  OUTLIER_AUTH_TOKEN,
  FIRST_EDITION_SUBDOMAIN,
  COURSE_NAME_DOMAIN_REGEX
} from './Constants'
import { CourseFactory } from './configuration'
import { gguUsersnapKeys } from './courseConfig'
import { getLectureVideoProgressKey } from './utilities/contextKeys'
import { skooliTutoringCourses } from './Constants/courseToolkit'
import { PITTS } from './Constants/creditGrantingInstitutions'

const qs = require('qs')
const COURSE_ID = getCourseId()
const COURSE_NAME = courseIdToName(COURSE_ID)

const redirectBetaToFirstEdition = () => {
  const [, courseName, betaSubdomain] = COURSE_NAME_DOMAIN_REGEX.exec(window.location.hostname) || []
  if (!betaSubdomain) return

  const isBeta = isBetaCourse(courseName, true)
  window.location.replace(
    window.location.href.replace(betaSubdomain, isBeta ? `.${FIRST_EDITION_SUBDOMAIN}` : '')
  )
}

redirectBetaToFirstEdition()

const { REACT_APP_DOMAIN } = process.env
export const PRODUCTION_API_HOST = 'https://api.outlier.org'
export const COURSE_IDS = getCourseIds()

const gguPittsTutoringStartDate = 1686726000 // Jun-14-2023 00:00:00 PDT
const course = CourseFactory(COURSE_ID)

export default {
  hasInTimeGradeFlag: hasInTimeGrade(),
  getUserAssignedCourses,
  redirectBetaToFirstEdition,
  course,
  jDoodleTheme: setJDoodleThemeUsingQueryParams(),
  setJDoodleThemeUsingQueryParams,
  courseId: COURSE_ID,
  courseIds: getCourseIds(),
  courseName: COURSE_NAME,
  courseLogo: getCourseLogo(),
  courseImage: getCourseImage,
  courseIdToName,
  courseBaseUrl: getCourseBaseUrl(),
  transferCreditsLogo: 'images/icon-transfer-credits.png',
  wolframBaseURL: '//www.wolframalpha.com',
  auth0BaseURL: `https://${REACT_APP_DOMAIN}`,
  isStudent: isStudent,
  getApiHost,
  datoApiHost: getDatoApiHost(),
  isTitleUpdates: isTitleUpdates(),
  getDatoApiHost,
  flags: getFlags(),
  getFlags,
  storedToken: getStoredToken(),
  getStoredToken,
  insideProctorio: isInsideProctorio(),
  isInsideProctorio,
  proctorioExamSettings: getProctorioExamSettings(COURSE_ID),
  getProctorioExamSettings,
  authToken,
  getIsPreviewCourse: isPreviewCourse,
  isPreviewCourse: isPreviewCourse(COURSE_ID),
  isStudioCohort: isStudioCohort(),
  isMobileFlag: isMobileFlag(),
  termsUpdatedDate: '2021-04-28',
  repeatGuardianPermissionCutOffDate: '2023-08-17',
  checkIfPreview: isPreviewCourse,
  videoProgressOnNavigate,
  sectionItemAccordionState,
  kalturaSetting: getKalturaSetting(),
  kalturaV7Setting: getKalturaV7Setting(),
  projectedGradeReferenceDate: 1614931200, // Mar-05-2021 00:00:00 PST
  philosophyStephensCollegeStudioCohortId: 'recSjIx74JkzUUqKM', // Philosophy - 1/10/22
  collegeWritingIUpdatedAssignmentReleaseDate: 1667980800, // Nov-09-2022 00:00:00 PST
  skooliUpdateDate: 1666767600, // Oct-26-2022 00:00:00 PDT
  ggu1_11_23StartDate: 1673424000, // Jan-11-2023 00:00:00 PST
  ggu_pitts_6_14_23StartDate: gguPittsTutoringStartDate,
  hideQuizForAuditorsCutOffDate: 1693724400, // Sep 3, 2023 00:00:00 PDT
  isBetaCourse,
  lectureSidePaneText: getLectureSidePaneText(COURSE_ID),
  getLectureSidePaneText,
  getPracticeExamDescription,
  practiceExamDescription: getPracticeExamDescription(COURSE_ID),
  getUsersnapAPIKey,
  wolframAlphaLink: getWolframAlphaLink(COURSE_ID),
  getWolframAlphaLink,
  getAdobeApiKeyByDomain,
  embedPdfClientId: getAdobeApiKeyByDomain(),
  studentEmailOverride: getStudentEmailOverride(),
  isCourseWithSingleMidtermExam,
  getDatoEditingUrl,
  isStudentProgress: isStudentProgress(getFlags()),
  isRecommendedProgress: isRecommendedProgress(getFlags()),
  hasNotificationFlag: hasNotificationFlag(getFlags()),
  hasUPittBlockerFlag: hasUPittBlockerFlag(),
  hasWhatsNextUpdateFlag: hasWhatsNextUpdateFlag(),
  hasAnnouncementsFlag,
  hasPinnedActiveLearningFlag,
  isLesson0Flag: hasLesson0Flag,
  isPwa: isPwa(),
  isCalculusCourse,
  isCSCourse,
  isSpecialTopicsCourse,
  isCollegeSuccessCourse,
  getLogoInfo,
  getCourseIdFromUrl,
  getConvertFlowUrl,
  getCourseBaseUrlById,
  isContentCourse: isContentCourse(COURSE_NAME),
  isStatisticsCourse: isStatisticsCourse(COURSE_NAME),
  hasUseAttempt: hasUseAttemptFlag(),
  freeCourseIds: getFreeCourseIds(),
  isTestCourse,
  shouldUseSkooliTutoringUsernsap,
  hasCodeGradeBreakdownFlag: hasCodeGradeBreakdownFlag(),
  isOutlierEngineer
}

// 39 week extended courses
const extendedCourseNames = [
  'business.ext',
  'sociology.ext',
  'principlesofeconomics.ext',
  'computerscience-i.ext',
  'calculus.ext',
  'psychology.ext',
  'astronomy.ext',
  'microeconomics.ext',
  'macroeconomics.ext',
  'statistics.ext',
  'collegealgebra.ext',
  'precalculus.ext',
  'financialaccounting.ext',
  'professionalcommunication.ext'
]

function getFreeCourseIds () {
  return ['ckyp0xzra00003e5zdb9d8mta']
}

function getUserAssignedCourses () {
  const flags = getFlags()
  return !!flags.fetchAssignedCourses
}

function getDatoApiHost () {
  return localStorage.datoApiHost || PRODUCTION_API_HOST
}

function getStudentEmailOverride () {
  return localStorage.studentEmailOverride
}

function getCourseId () {
  setCourseNameUsingQueryParams()
  return (
    getCourseIdFromLocalStorage() ||
    process.env.REACT_APP_COURSE_ID ||
    getCourseIdFromUrl()
  )
}

function getApiHost () {
  let apiHostFromLocalStorage = localStorage.apiHost
  const apiHostFromURL =
    window.location.search && qs.parse(window.location.search.slice(1)).apiHost

  if (!apiHostFromLocalStorage && !apiHostFromURL) {
    return 'https://api.outlier.org'
  }

  if (apiHostFromURL) {
    localStorage.apiHost = apiHostFromURL
    apiHostFromLocalStorage = localStorage.apiHost
  }

  return apiHostFromLocalStorage
}

function isStudent () {
  let isStudentFromLocalStorage = localStorage.isStudent
  const isStudentFromURL = window.location.search &&
  qs.parse(window.location.search.slice(1)).isStudent

  if (!isStudentFromLocalStorage && !isStudentFromURL) {
    return false
  }

  if (isStudentFromURL) {
    localStorage.isStudent = isStudentFromURL
    isStudentFromLocalStorage = localStorage.isStudent
  }

  return (
    (isStudentFromLocalStorage &&
      isStudentFromLocalStorage.toLowerCase() === 'true') ||
    (isStudentFromURL && isStudentFromURL.toLowerCase() === 'true')
  )
}

function getCourseBaseUrl () {
  // Im not sure if we need this code here, we can always remove it if we don't want it
  // Reason I put it is because it makes it easier to test locally
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:3000'
  }
  return window.location.origin
}

function getCourseIdFromUrl (courseIds = getCourseIds()) {
  const { hostname } = window.location
  const courseName = getCourseNameFromHostname(hostname)

  const courseId = courseIds[courseName]

  return courseId || courseIds.calculus
}

function getCourseLogo () {
  return {
    test: 'images/icons/icon-sigma.png',
    calculus: 'images/icons/icon-sigma.png',
    'preview.calculus': 'images/icons/icon-sigma.png',
    'preview.principlesofeconomics': 'images/icons/icon-sigma.png',
    psychology: 'images/psych.png',
    'preview.financialaccounting': 'images/icons/icon-outlier.png',
    'preview.sociology': 'images/icons/precalculus.png',
    'preview.collegealgebra': 'images/icons/precalculus.png',
    'preview.psychology': 'images/psych.png',
    'welcome.psychology': 'images/psych.png',
    principlesofeconomics: 'images/icons/icon-sigma.png',
    'y.calculus': 'images/icons/icon-sigma.png',
    statistics: 'images/icons/icon-outlier.png',
    'preview.computerscience-i': 'images/icons/icon-outlier.png',
    'computerscience-i': 'images/icons/icon-outlier.png',
    'physics-i': 'images/icons/icon-outlier.png',
    'calculus-ii': 'images/icons/icon-outlier.png',
    professionalcommunication: 'images/icons/icon-outlier.png',
    'preview.professionalcommunication': 'images/icons/icon-outlier.png',
    managerialaccounting: 'images/icons/icon-outlier.png',
    'preview.statistics': 'images/icons/icon-outlier.png',
    precalculus: 'images/icons/precalculus.png',
    collegealgebra: 'images/icons/precalculus.png',
    'preview.precalculus': 'images/icons/precalculus.png',
    collegesuccess: 'images/icons/icon-outlier.png',
    macroeconomics: 'images/icons/icon-outlier.png',
    'preview.macroeconomics': 'images/icons/icon-outlier.png',
    'collegewriting-i': 'images/icons/icon-outlier.png',
    'collegewriting-ii': 'images/icons/icon-outlier.png',
    'preview.collegewriting-i': 'images/icons/icon-outlier.png',
    'preview.collegewriting-ii': 'images/icons/icon-outlier.png',
    business: 'images/icons/icon-outlier.png',
    'preview.business': 'images/icons/icon-outlier.png',
    barnardhighlights: 'images/icons/icon-outlier.png',
    'preview.microeconomics': 'images/icons/icon-outlier.png',
    financialaccounting: 'images/icons/icon-outlier.png',
    sociology: 'images/icons/icon-outlier.png',
    philosophy: 'images/icons/icon-sigma.png',
    'preview.philosophy': 'images/icons/icon-sigma.png',
    'preview-marketing.philosophy': 'images/icons/icon-sigma.png',
    microeconomics: 'images/icons/icon-sigma.png',
    astronomy: 'images/icons/astronomy.svg',
    'preview.astronomy': 'images/icons/astronomy.svg'
  }[COURSE_NAME]
}

function getCourseIdFromLocalStorage () {
  return getCourseIds()[localStorage.courseName]
}

function courseIdToName (id) {
  const coureNamesByIds = {
    ...invert(getCourseIds()),
    cl8bpkss900003b68lww2m8az: 'collegewriting-i.plus',
    ckdf2158p00003g5milp6mvj8: 'astronomy'
  }
  return coureNamesByIds[id]
}

function getCourseImage (coursId) {
  const courseName = courseIdToName(coursId)?.replace('.plus', '')
  return {
    test: 'images/calculus.png',
    calculus: 'images/calculus.png',
    'preview.calculus': 'images/calculus.png',
    'preview.financialaccounting': 'images/calculus.png',
    'preview.sociology': 'images/calculus.png',
    'preview.collegealgebra': 'images/precalculus.png',
    psychology: 'images/psychology.png',
    professionalcommunication: 'images/default.jpg',
    'preview.professionalcommunication': 'images/default.jpg',
    'computerscience-i': 'images/calculus.png',
    'physics-i': 'images/calculus.png',
    'calculus-ii': 'images/calculus.png',
    'preview.computerscience-i': 'images/default.png',
    managerialaccounting: 'images/calculus.png',
    'preview.psychology': 'images/psychology.png',
    'welcome.psychology': 'images/psychology.png',
    'y.calculus': 'images/calculus.png',
    statistics: 'images/calculus.png',
    'preview.statistics': 'images/calculus.png',
    precalculus: 'images/precalculus.png',
    collegealgebra: 'images/precalculus.png',
    'preview.precalculus': 'images/precalculus.png',
    barnardhighlights: 'images/calculus.png',
    collegesuccess: 'images/default.jpg',
    macroeconomics: 'images/calculus.png',
    'preview.macroeconomics': 'images/calculus.png',
    'preview.microeconomics': 'images/calculus.png',
    financialaccounting: 'images/calculus.png',
    sociology: 'images/calculus.png',
    'collegewriting-i': 'images/calculus.png',
    'collegewriting-ii': 'images/calculus.png',
    'preview.collegewriting-i': 'images/calculus.png',
    'preview.collegewriting-ii': 'images/calculus.png',
    business: 'images/calculus.png',
    'preview.business': 'images/calculus.png',
    philosophy: 'images/calculus.png',
    'preview.philosophy': 'images/calculus.png',
    'preview-marketing.philosophy': 'images/calculus.png',
    principlesofeconomics: 'images/calculus.png',
    'preview.principlesofeconomics': 'images/calculus.png',
    microeconomics: 'images/calculus.png',
    astronomy: 'images/astronomy.png',
    'preview.astronomy': 'images/astronomy.png',
    content: 'images/calculus.png'
  }[courseName]
}

function getCourseIds () {
  return {
    test: 'test-course',
    calculus: 'b227c462-332c-40e0-8735-ea3af6f11661',
    'preview.calculus': 'ckp8vzbw600003h5vzfuhfdcr',
    psychology: '1e2f466d-049a-41e7-af53-74afbfa9d87b',
    'preview.psychology': 'ckppte45z00003g5vsapg3kuh',
    'welcome.psychology': 'cl4g36qd500003b663c8n4nc1',
    'y.calculus': 'ea88ffd3-5c59-49d5-89b4-b9f009dde9ac',
    statistics: 'ckdampe3b00003g5m6l85b1s5',
    'preview.statistics': 'ckppugm1s00003g5vzj24w075',
    philosophy: 'ckgqsu5lf00003h5lzot6as6x',
    'collegewriting-i': 'cku4ayye700003g5vsb6jgmhb',
    'collegewriting-ii': 'cl31r155p00003f63wxa1sq4z',
    'preview.collegewriting-ii': 'cllbfko3o00003b699vaj6q0o',
    'computerscience-i': 'cl0sd8la200003e62p8cxq2ey',
    'physics-i': 'cl0h6maex00003e625n0dtois',
    'calculus-ii': 'cl9pxqbj300003b685ixsl873',
    'preview.computerscience-i': 'cl1fv7i7h00003e62vuzo788m',
    managerialaccounting: 'cl0h7sg9f00003e620m2mlma8',
    'preview.collegewriting-i': 'cku73ehlx00003g5vpm96sq24',
    business: 'cku4ayarl00003g5v8zya64ih',
    professionalcommunication: 'cl8929un900003b68j8k16w6a',
    'preview.professionalcommunication': 'cllbfcvu900003b694wnrai7e',
    'preview.business': 'cl2upq5jf00003g63mrfli9gh',
    collegealgebra: 'ckw6tiqy100003e5xexynd0dz',
    'preview.philosophy': 'ckppvs4og00003g5vt00g596a',
    'preview-marketing.philosophy': 'ckrz50bj500003h5v6n9qyzcy',
    microeconomics: 'ckiawlgvw00003h5o8c6u6rog',
    'microeconomics.ext': 'clzbrh8hn00003b6f7yjprec7',
    'preview.microeconomics': 'ckppvyng500003g5vnn5vt6fj',
    precalculus: 'ckp356yiy00003h5vpz3fbu1h',
    'preview.precalculus': 'cku73ipcg00003g5vb5x06hjb',
    principlesofeconomics: 'ckxyytpau00003f5xhe3sf4xj',
    'preview.principlesofeconomics': 'cl1l0bnk200003e627p3biscw',
    macroeconomics: 'ckp3587wu00003h5v6jfbv37v',
    'macroeconomics.ext': 'clzbr1j2400003b6er0vculdk',
    'preview.macroeconomics': 'cku73hcje00003g5vqjlpojpt',
    financialaccounting: 'ckp359ieh00003h5vm158kdt0',
    collegesuccess: 'ckyp0xzra00003e5zdb9d8mta',
    sociology: 'ckp3592gx00003h5vsd5ijmp9',
    astronomy: 'ckzmyc33d00003e5z5ju9w077',
    'astronomy.ext': 'clzbmp7cm00003b6evkcd0srr',
    barnardhighlights: 'cktt8s66100003g5v8872yin9',
    'preview.financialaccounting': 'cky8w5w0s00003e5xalquzhzv',
    'preview.sociology': 'cky8w79k100003e5xdwhztphl',
    'preview.collegealgebra': 'cky8w87y000003e5xy67h8pvz',
    'preview.astronomy': 'ckppvq9q200003g5vhcz1utxu',
    content: 'cl2grnv7z00003f6302c1ccbf',
    'calculus.plus': 'cl8chwjku00003b68m0uulv59',
    'computerscience-i.plus': 'cla70y6pd00003b68xl5o929f',
    'collegealgebra.plus': 'cl8cc3qps00003b68vf2xvbx2',
    'collegewriting-i.plus': 'clpx286t000013b6buj1q8rgq',
    'astronomy.plus': 'cl9ipiyvr00003b68940h4yf7',
    'business.plus': 'cl8chrori00003b68iatqg9ds',
    'financialaccounting.plus': 'cl8cicil200003b686pscg7nt',
    'macroeconomics.plus': 'cl8cifhox00003b683k6qy7gk',
    'microeconomics.plus': 'cl8cieub000003b68hturuo2t',
    'philosophy.plus': 'cl8cig0e700003b68mgbqnry2',
    'psychology.plus': 'cl8chvsoy00003b68m0t56gfv',
    'sociology.plus': 'cl8cib1gy00003b682ct750ba',
    'statistics.plus': 'cl8ci9dna00003b684igglvbn',
    'precalculus.plus': 'cl8d5dbhz00003b689avjvmsv',
    'principlesofeconomics.plus': 'cl8cigudr00003b68gdw3vcxn',
    'professionalcommunication.plus': 'clg6ywvo000003b676omcaa9t',
    'collegewriting-ii.plus': 'clg6yvuyc00003b67eoyh7lz1',
    'business.ext': 'clix6ozhf00003b67f9cfxryn',
    'sociology.ext': 'clixipgk800003b6727vle7hi',
    'principlesofeconomics.ext': 'clixiovxz00003b671j9lggjw',
    'computerscience-i.ext': 'clixingmb00003b67f9nlibtx',
    'calculus.ext': 'clj65g8fi00003b670xricu75',
    'psychology.ext': 'clixio9dp00003b67fimpf4h2',
    'statistics.ext': 'clix6t3q600003b67l08i0ljb',
    'collegealgebra.ext': 'clix6s4i500003b67roqr1l1p',
    'precalculus.ext': 'clix6q93y00003b67mbuz6lm4',
    'financialaccounting.ext': 'clix6mm3e00003b67x74owz2b',
    'professionalcommunication.ext': 'clixiqdxw00003b67c8r80fb3',
    'itsupport.cert': 'cljst951400003b69tq4jnltv',
    'projectmanagement.cert': 'clieyyjpp00003b69h8dnhmtu',
    'dataanalytics-i.cert': 'clgb29uhc00003b67wtuupjqy',
    'digitalmarketing-i.cert': 'clgcltmgf00003b675y2hecxi',
    'dataanalytics-ii.cert': 'cloipjvx900003b6c9irc9j7d',
    'digitalmarketing-ii.cert': 'cloipl0py00003b6cxbtn9t2s',
    'salesoperations.cert': 'clgclvp9o00003b675vmu4250',
    'preview.itsupport.cert': 'clnvyvst600003b6cio2wp081',
    'preview.projectmanagement.cert': 'clnvy1z0o00003b6cefrtk1ew',
    'preview.dataanalytics-i.cert': 'clnw1z4oa00003b6cc3gh7mvp',
    'preview.digitalmarketing-i.cert': 'clnvyey4d00003b6c95fkbnbc',
    'preview.salesoperations.cert': 'clnvyjk8y00003b6crzqx4fbo'
  }
}

function getProctorioExamSettings () {
  const flags = getFlags()

  let setting = 'recordvideo,recordaudio,recordscreen,recordwebtraffic,verifyvideo,verifyaudio,verifydesktop,verifyidauto,verifysignature,clipboard,closetabs,onescreen,print,downloads,rightclick,agentreentry'

  if (flags[LINKS_ONLY]) {
    setting += ',linksonly'
  }

  if (flags[RECORD_ROOM_START]) {
    setting += ',recordroomstart'
  }

  return setting
}

function getLectureSidePaneText (courseId) {
  const { psychology, 'psychology.plus': psychologyPlus, astronomy,
    'astronomy.plus': astronomyPlus, statistics, 'statistics.plus': statisticsPlus,
    philosophy, 'philosophy.plus': philosophyPlus, microeconomics,
    'microeconomics.plus': microeconomicsPlus
  } = getCourseIds()

  if (
    [
      psychology, astronomy, statistics, philosophy, microeconomics,
      psychologyPlus, astronomyPlus, statisticsPlus, philosophyPlus, microeconomicsPlus
    ].includes(courseId)
  ) return 'Your Instructor'
  return 'Swap Instructors'
}

function getPracticeExamDescription (courseId) {
  if (
    courseId == null || isCourseWithSingleMidtermExam(courseId)
  ) return 'Test your Proctorio setup in preparation for your midterm.'
  return 'Test your Proctorio setup in preparation for your first midterm.'
}

function shouldUseSkooliTutoringUsernsap ({
  courseName,
  isGGUCohort,
  cohortStartDate,
  creditGrantingInstitution
}) {
  const isFinancialAccounting =
    ['financialaccounting', 'financialaccounting.plus'].includes(
      courseName
    )

  if (!isFinancialAccounting) return true

  const is14Jun23OrLaterCohort =
    cohortStartDate >= gguPittsTutoringStartDate

  if (!is14Jun23OrLaterCohort) return false

  const isPittsCreditInstitution = creditGrantingInstitution === PITTS
  return isGGUCohort || isPittsCreditInstitution
}

function getUsersnapAPIKey ({
  usersnapMenuOverride,
  courseId = COURSE_ID,
  isSkooliUpdate,
  isStudioCohort = false,
  isGGUCohort,
  cohortStartDate,
  creditGrantingInstitution,
  tutoring
}) {
  if (usersnapMenuOverride) return usersnapMenuOverride
  if (isStudioCohort) return '8a599f04-a4da-4e32-962e-4e63c38dca2f'

  const courseName = courseIdToName(courseId)

  if (courseName in gguUsersnapKeys) return gguUsersnapKeys[courseName]

  const {
    precalculus,
    'precalculus.ext': precalculusExt,
    calculus,
    'calculus.ext': calculusExt,
    collegealgebra,
    'collegealgebra.ext': collegealgebraExt,
    statistics,
    'statistics.ext': statisticsExt,
    'collegewriting-i': collegeWritingI,
    'collegewriting-ii': collegeWritingII,
    'y.calculus': calculusFlorida,
    financialaccounting,
    'financialaccounting.ext': financialaccountingExt,
    philosophy
  } = getCourseIds()

  const useSkooliTutoringForCourse = shouldUseSkooliTutoringUsernsap({
    courseName,
    isGGUCohort,
    cohortStartDate,
    creditGrantingInstitution
  })

  const useSkooliTutoring =
    isSkooliUpdate &&
    skooliTutoringCourses.includes(courseName) &&
    useSkooliTutoringForCourse

  if (useSkooliTutoring) {
    if (courseId === calculus) return 'f3febb26-3053-4ca6-8e65-32fc93809b00'
    if (courseId === precalculus) return 'bbbbd7f8-15fc-410a-a46a-aa826d18cd20'
    if (courseId === collegealgebra) return 'db538539-3509-4860-9ce0-252de5e7df32'
    if (courseId === statistics) return '85f606f7-3700-49c8-8462-d14fbcad5f1a'
    if (courseId === collegeWritingI) return 'c0b7335c-755e-403a-a44f-8285f222ffa0'
    if (courseId === collegeWritingII) return 'a5400498-984e-4e54-8575-a229293fe52b'
    if (courseId === philosophy) return 'fd8f3195-c78b-4a62-8a65-dd7a27a95d90'
    if (courseId === financialaccounting) return '9e1e210b-70c7-4bbd-a08f-8710f00f521c'
    if (courseId === calculusExt) return '9a7813af-2004-4bfa-9786-075f2d4d3f80'
    if (courseId === statisticsExt) return '20e568d9-036e-4dfc-bfc6-c61d92c5e4ee'
    if (courseId === collegealgebraExt) return '0ef0cea5-0437-4513-9b7e-e2c75338bfa5'
    if (courseId === precalculusExt) return '2b0da372-437b-466d-b0ec-50448145529c'
    if (courseId === financialaccountingExt) return 'b0172c81-56fc-4bc6-b0cb-f53f123b6b8a'
  }

  const hasTutoring = [
    precalculus,
    calculus,
    collegealgebra,
    statistics,
    collegeWritingI,
    calculusFlorida
  ].includes(courseId)

  if (hasTutoring || tutoring) return '5379b22b-f6a3-42be-ae8c-89b9e18453a1'
  return '39f91528-799b-4663-bb5f-4f17d4f510f6'
}

function getWolframAlphaLink (courseId) {
  const { statistics, 'statistics.plus': statisticsPlus } = getCourseIds()
  const generalURL = 'https://www.wolframalpha.com/examples/pro-features/step-by-step-solutions/'
  if ([statistics, statisticsPlus].includes(courseId)) return generalURL
  return `${generalURL}step-by-step-calculus/`
}

function getAdobeApiKeyByDomain () {
  const domainKeys = {
    [`${OUTLIER_DOMAIN}`]: '6f9c4c9223154178b10190faddbfc729',
    localhost: '3b034b55109847f2884489f01d202f49'
  }

  const isLocalhost = window.location.hostname === 'localhost'
  return domainKeys[isLocalhost ? 'localhost' : OUTLIER_DOMAIN]
}

function getLogoInfo ({ courseName, isStudioCohort = false }) {
  const isBeta = isBetaCourse(courseName)

  const betaLogo = {
    logoSrc: 'images/outlier-beta.svg'
  }
  const defaultLogo = {
    logoSrc: 'images/new-outlier-logo.svg',
    className: 'outlier-logo'
  }
  const studioBetaLogo = {
    logoSrc: 'images/outlier-studio-beta.svg'
  }
  const studioLogo = {
    logoSrc: 'images/outlier-studio.svg'
  }

  if (isBeta) {
    return isStudioCohort ? studioBetaLogo : betaLogo
  } else {
    return isStudioCohort ? studioLogo : defaultLogo
  }
}

function isBetaCourse (courseName, ignoreTestCourse) {
  const courses = [
    ...(ignoreTestCourse ? [] : ['test']),
    'calculus',
    'psychology',
    'y.calculus',
    'astronomy',
    'projectmanagement.cert',
    'dataanalytics-i.cert',
    'digitalmarketing-i.cert',
    'dataanalytics-ii.cert',
    'digitalmarketing-ii.cert',
    'salesoperations.cert',
    'itsupport.cert',
    'calculus.plus',
    'psychology.plus',
    'astronomy.plus',
    ...extendedCourseNames
  ]

  return !courses.includes(courseName)
}

function setJDoodleThemeUsingQueryParams () {
  const compilerTheme = window.location.search &&
  qs.parse(window.location.search.slice(1)).compilerTheme
  return compilerTheme?.replace('/', '')
}

function setCourseNameUsingQueryParams () {
  const courseName = window.location.search &&
  qs.parse(window.location.search.slice(1)).courseName

  if (courseName) localStorage.courseName = courseName
}

function invert (obj) {
  const inverted = {}
  Object.keys(obj).forEach(function (k) {
    const v = obj[k]
    inverted[v] = k
  })
  return inverted
}

function getStoredToken () {
  const { get } = authToken()

  return isInsideProctorio() && get()
}

function isInsideProctorio () {
  const { proctorio } = qs.parse(window.location.search.slice(1))
  return proctorio === 'true'
}

function authToken () {
  const KEY = OUTLIER_AUTH_TOKEN

  function get () {
    return Cookies.get(KEY)
  }

  function set (token) {
    Cookies.set(KEY, token, { expires: 1, sameSite: 'None', secure: true })
  }

  function remove () {
    Cookies.remove(KEY)
  }

  return { get, set, remove }
}

function getFlags () {
  const query = qs.parse(window.location.search?.slice(1))
  Object.entries(query).forEach(([k, v]) => {
    delete query[k]
    if (k.indexOf('flag-') !== 0) return
    query[k.replace('flag-', '')] = v
  })
  return util.parseBooleanObject(query)
}

function videoProgressOnNavigate (chapterType) {
  const KEY = getLectureVideoProgressKey(chapterType)

  function get () {
    const storedValue = localStorage.getItem(KEY)
    return storedValue ? JSON.parse(storedValue) : undefined
  }

  function set (progress) {
    let lectureProgress = JSON.parse(localStorage.getItem(KEY) || '{}')
    lectureProgress = { ...lectureProgress, ...progress }
    localStorage.setItem(KEY,
      JSON.stringify({ ...lectureProgress }))
  }

  function remove () {
    localStorage.removeItem(KEY)
  }

  return { get, set, remove }
}

function sectionItemAccordionState (sectionType, sectionIdentifier) {
  const KEY = `${sectionType}-accordion-${sectionIdentifier}`
  return localStorageUtil.stateFactory(KEY)
}

function getKalturaSetting () {
  // Kaltura account identification number
  const PARTNER_ID = 2654411
  // The player instance ID created in the KMC (Kaltura Management Console)
  // It holds the player configuration.
  const UICONF_ID = 45079531

  return {
    PARTNER_ID,
    UICONF_ID,
    scriptUrl: `https://cdnapisec.kaltura.com/p/${PARTNER_ID}/sp/${PARTNER_ID}00/embedIframeJs/uiconf_id/${UICONF_ID}/partner_id/${PARTNER_ID}`
  }
}

function getKalturaV7Setting () {
  const PARTNER_ID = 2654411
  const UICONF_ID = 52485092

  return {
    PARTNER_ID,
    UICONF_ID,
    scriptUrl: `https://cdnapisec.kaltura.com/p/${PARTNER_ID}/embedPlaykitJs/uiconf_id/${UICONF_ID}`
  }
}

/**
 * @param {string} courseId the current course id
 * @returns {boolean} if course is a preview one or if there preview query param
 */

function isPreviewCourse (courseId) {
  const previewCoursesIds = {
    cllbfcvu900003b694wnrai7e: 'preview.professionalcommunication',
    cllbfko3o00003b699vaj6q0o: 'preview.collegewriting-ii',
    ckppvyng500003g5vnn5vt6fj: 'preview.microeconomics',
    cl1fv7i7h00003e62vuzo788m: 'preview.computerscience-i',
    cku73hcje00003g5vqjlpojpt: 'preview.macroeconomics',
    ckppvs4og00003g5vt00g596a: 'preview.philosophy',
    cl1l0bnk200003e627p3biscw: 'preview.principlesofeconomics',
    ckrz50bj500003h5v6n9qyzcy: 'preview-marketing.philosophy',
    ckppte45z00003g5vsapg3kuh: 'preview.psychology',
    cl4g36qd500003b663c8n4nc1: 'welcome.psychology',
    ckppugm1s00003g5vzj24w075: 'preview.statistics',
    cku73ipcg00003g5vb5x06hjb: 'preview.precalculus',
    cl2upq5jf00003g63mrfli9gh: 'preview.business',
    ckppvq9q200003g5vhcz1utxu: 'preview.astronomy',
    cktt8s66100003g5v8872yin9: 'barnardhighlights',
    cky8w5w0s00003e5xalquzhzv: 'preview.financialaccounting',
    cky8w79k100003e5xdwhztphl: 'preview.sociology',
    cky8w87y000003e5xy67h8pvz: 'preview.collegealgebra',
    cku73ehlx00003g5vpm96sq24: 'preview.collegewriting-i',
    ckp8vzbw600003h5vzfuhfdcr: 'preview.calculus',
    clnvyvst600003b6cio2wp081: 'preview.itsupport.cert',
    clnvy1z0o00003b6cefrtk1ew: 'preview.projectmanagement.cert',
    clnw1z4oa00003b6cc3gh7mvp: 'preview.dataanalytics-i.cert',
    clnvyey4d00003b6c95fkbnbc: 'preview.digitalmarketing-i.cert',
    clnvyjk8y00003b6crzqx4fbo: 'preview.salesoperations.cert'
  }

  const isPreviewCourse = previewCoursesIds[courseId]

  return !!isPreviewCourse
}

function hasCodeGradeBreakdownFlag () {
  const flags = getFlags()
  return !!flags.codeGradeBreakdown
}

function hasWhatsNextUpdateFlag () {
  const flags = getFlags()
  return !!flags.whatsNextUpdate
}

function hasUseAttemptFlag () {
  const flags = getFlags()
  return !!flags.hasUseAttempt
}

function hasLesson0Flag () {
  const flags = getFlags()
  return !!flags.lesson0
}

function isStudioCohort () {
  const flags = getFlags()
  return !!flags.studio
}

function isMobileFlag () {
  const flags = getFlags()
  return !!flags.mobile
}

function isTitleUpdates () {
  const flags = getFlags()
  return !!flags.titleUpdates
}

function isStudentProgress (flags) {
  const studentProgress = flags[STUDENT_PROGRESS]
  return !!studentProgress
}

function isRecommendedProgress (flags) {
  const recommendedProgress = flags[RECOMMENDED_PROGRESS]
  return !!recommendedProgress
}

function hasNotificationFlag (flags) {
  const notificationFlag = flags[NOTIFICATION]
  return !!notificationFlag
}

function hasUPittBlockerFlag () {
  const flags = getFlags()
  return !!flags.UPittBlocker
}

function hasAnnouncementsFlag () {
  const flags = getFlags()
  return !!flags.announcements
}

function hasPinnedActiveLearningFlag () {
  const flags = getFlags()
  return !!flags.pinnedActiveLearning
}

function isCourseWithSingleMidtermExam (courseId) {
  if (course.isSingleMidtermExam) return true

  // This is the old way of checking if a course has a single midterm exam
  const { philosophy, 'philosophy.plus': philosophyPlus } = getCourseIds()
  return [philosophy, philosophyPlus].includes(courseId)
}

function getDatoEditingUrl (recordId, model = QUESTION) {
  if (!recordId) return null
  const modelId = DATO_MODEL_UUIDS[model]

  if (!modelId) return null
  return DATO_EDITING_URL({ modelId, recordId })
}

function isSpecialTopicsCourse (courseName = COURSE_NAME) {
  return [
    'business',
    'business.plus',
    'business.ext',
    'macroeconomics',
    'macroeconomics.plus',
    'psychology',
    'psychology.plus',
    'psychology.ext'
  ].includes(courseName)
}

function isCalculusCourse (courseName = COURSE_NAME) {
  const isCalculusCourse = [
    'calculus', 'y.calculus', 'preview.calculus', 'calculus.plus', 'calculus.ext'
  ].includes(courseName)
  return isCalculusCourse
}

function isCollegeSuccessCourse (courseName = COURSE_NAME) {
  const isCollegeSuccessCourse = [
    'collegesuccess',
    'collegesuccess-pwa'
  ].includes(courseName)
  return isCollegeSuccessCourse
}

function isCSCourse (courseId = COURSE_ID) {
  const {
    'computerscience-i': cs,
    'computerscience-i.plus': csPlus,
    'computerscience-i.ext': csExt
  } = getCourseIds()
  return [cs, csPlus, csExt].includes(courseId)
}

function isPwa () {
  const isPwaEnabled = window.matchMedia('(display-mode: standalone)').matches
  return isPwaEnabled
}

function getConvertFlowUrl (websiteId) {
  return `https://app.convertflow.co/websites/${websiteId}/contacts/create/`
}

function isContentCourse (courseName = COURSE_NAME) {
  return [
    'content'
  ].includes(courseName)
}

function getCourseBaseUrlById (courseId) {
  const courseName = courseIdToName(courseId)
  const betaUrl = `https://${courseName}.${FIRST_EDITION_SUBDOMAIN}.outlier.org`
  const defaultUrl = `https://${courseName}.outlier.org`
  const defaultCourses = [
    'calculus',
    'psychology',
    'y.calculus',
    'astronomy',
    ...extendedCourseNames,
    'itsupport.cert',
    'projectmanagement.cert',
    'dataanalytics-i.cert',
    'digitalmarketing-i.cert',
    'dataanalytics-ii.cert',
    'digitalmarketing-ii.cert',
    'salesoperations.cert'
  ]
  return defaultCourses.includes(courseName) || courseName?.includes('.plus')
    ? defaultUrl : betaUrl
}

function isStatisticsCourse (courseName = COURSE_NAME) {
  return [
    'statistics'
  ].includes(courseName)
}

function hasInTimeGrade () {
  const flags = getFlags()
  const inTimeGrade = flags.inTimeGrade
  return !!inTimeGrade
}

function isTestCourse () {
  const { test } = getCourseIds()
  return test === COURSE_ID
}

function isOutlierEngineer (email) {
  if (!email) return false
  return email?.endsWith('@engineering.outlier.org')
}
