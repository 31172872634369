import React, { Component } from 'react'
import QuestionNextButton from '../QuestionComponent/QuestionNextButton'
import QuestionComponent from '../QuestionComponent/QuestionComponent'
import JDoodleCompiler from '../JDoodleCompiler/JDoodleCompiler'
import QuestionPreviousButton from '../QuestionComponent/QuestionPreviousButton'
import { emitter } from '../Emitter/Emitter'
import { ON_SUBMIT_CARD_QUESTION } from '../../Constants/emitterKeys'
import { ACTIVITY_TYPES } from '../../Constants/sectionType'
import MathJax from '../MathJax/MathJax'
import Context from '../Context/Context'
import {
  CardContent,
  LessonHeader,
  LessonHeaderText,
  LessonWrapper,
  PaginationWrapper,
  PinWrapper,
  IllustrationWrapper,
  AdaptionStatementWrapper
} from './styles'
import config from '../../config'
import { PINNED_ICON_WHITE, PIN_ICON } from '../../Constants/progressBarIconSource'
import { PinButton, PinButtonWrapper, PinText } from '../FreeformEquationQuestion/styles'
import { PINNED_TEXT, PIN_TEXT } from '../../Constants'

export class CardQuestion extends Component { /* Exported for test purposes only */
  constructor (props) {
    super(props)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.markCheck = this.markCheck.bind(this)

    this.state = {
      isPinned: false
    }
  }

  componentDidMount () {
    const { studentData: { pinnedQuestions } } = this.context
    const { Question_uuid: questionUuid } = this.props.question

    this.markCheck()
    this.setState(
      { isPinned: pinnedQuestions.includes(questionUuid) }
    )
  }

  componentDidUpdate (prevProps) {
    const { Question_uuid: questionUuid } = this.props.question
    const { Question_uuid: prevQuestionUuid } = prevProps.question
    if (prevQuestionUuid !== questionUuid) {
      this.markCheck()
      const { studentData: { pinnedQuestions } } = this.context
      this.setState(
        { isPinned: pinnedQuestions.includes(questionUuid) }
      )
    }
  }

  onPinClick (currentQuestionUUID) {
    const {
      type_of_question: typeOfQuestion
    } = this.props

    const {
      studentData,
      updateContext
    } = this.context
    const { pinnedQuestions } = studentData
    const { isPinned } = this.state
    const currentIndex = pinnedQuestions.indexOf(currentQuestionUUID)
    const answer = {
      questionUuid: currentQuestionUUID,
      type: ACTIVITY_TYPES[typeOfQuestion],
      questionActivity: { pinned: false }
    }
    if (isPinned) {
      pinnedQuestions.splice(currentIndex, 1)
    } else {
      answer.questionActivity.pinned = true
      pinnedQuestions.push(currentQuestionUUID)
    }
    emitter.emit(ON_SUBMIT_CARD_QUESTION, answer)
    studentData.pinnedQuestions = pinnedQuestions
    updateContext({ studentData })
    this.setState({ isPinned: !isPinned })
  }

  markCheck () {
    const { question: { Question_uuid: questionUuid },
      type_of_question: typeOfQuestion } = this.props
    emitter.emit(ON_SUBMIT_CARD_QUESTION, {
      questionUuid,
      type: ACTIVITY_TYPES[typeOfQuestion]
    })
  }

  // Should return false if the action was not allowed.
  handleKeyPress (type) {
    const {
      showPrevious,
      onNextButtonClick,
      onPreviousButtonClick
    } = this.props
    // Typically from pressing the left arrow
    if (type && type === 'previous') {
      if (!showPrevious) return false
      onPreviousButtonClick()
      return true
    }

    // Everything else is interpreted as next or advance forward
    onNextButtonClick()
    return true
  }

  render () {
    const {
      lesson_text: lessonText,
      structuredText,
      display_illustration: displayIllustration,
      desmosgraph: desmosGraph,
      filteredActvQuestInd,
      gMath,
      question: {
        adaptationStatement,
        jdoodleEditor,
        starterCode,
        Question_uuid: questionUuid
      }
    } = this.props
    const { isPinned } = this.state
    const { isTitleUpdates } = config
    const { isLastQuestionAnswered } = this.context

    return (
      <>
        <LessonHeader>
          <LessonHeaderText tabIndex={0}>Card {filteredActvQuestInd + 1}</LessonHeaderText>
          <PinWrapper onClick={() => this.onPinClick(questionUuid)} tabIndex={0}>
            <PinText>{isPinned ? PINNED_TEXT : PIN_TEXT}</PinText>
            <PinButtonWrapper>
              <PinButton
                src={isPinned ? PINNED_ICON_WHITE : PIN_ICON}
                data-testid='pinButton'
              />
            </PinButtonWrapper>
          </PinWrapper>
        </LessonHeader>
        <LessonWrapper
          isALRedesign
          className='active__learning-block component__backdrop mb-2'
          data-cy='activeLearningCard'
        >
          {structuredText}
          {lessonText &&
            <CardContent
              isTitleUpdates={isTitleUpdates}
              isALRedesign
              className='font__md'
              data-testid='card-content'
            >
              {lessonText}
            </CardContent>
          }
          {
            (displayIllustration || desmosGraph) &&
              <IllustrationWrapper
                isALRedesign
                className='row align-items-start pt-4 justify-content-center'>
                {displayIllustration}
                {desmosGraph}
              </IllustrationWrapper>
          }
          {gMath}
          {jdoodleEditor &&
            <div className='mt-2'>
              <JDoodleCompiler
                key={questionUuid}
                questionUUID={questionUuid}
                isALRedesign
                starterCode={starterCode}
              />
            </div>}
        </LessonWrapper >
        <PaginationWrapper
          isRedesign
        >
          <QuestionPreviousButton
            isAssessmentQuizExam
            {...this.props}
          />
          {!isLastQuestionAnswered &&
            <QuestionNextButton
              {...this.props}
              isNextDisabled={false}
              isAssessmentQuizExam
            />}
        </PaginationWrapper>
        {
          adaptationStatement &&
            <AdaptionStatementWrapper
              isALRedesign
              className='active__learning-pagination adaptation bottom-fixed text-center'
              data-testid='adaptation-statement'
            >
              <div className='adaptation-statement'>
                <MathJax math={adaptationStatement} />
              </div>
            </AdaptionStatementWrapper>
        }
      </>
    )
  }
}

CardQuestion.contextType = Context
export default QuestionComponent(CardQuestion)
