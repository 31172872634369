import React, { Component } from 'react'
import Slider from 'react-slick'
import Button from '../FormComponent/Button'
import ButtonPractice from '../FormComponent/ButtonPractice'
import { emitter } from '../Emitter/Emitter'
import SidePanel from './SidePanel'
import './css/slick-custom.css'
import {
  GUESSWORK,
  EXAM,
  ACTIVE_LEARNING,
  PRACTICE_EXERCISES,
  ACTIVITY_TYPES,
  QUIZ } from '../../Constants/sectionType'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'
import { MULTIPLE_CHOICE, TEXT_BLANK } from '../../Constants/questionType'
import isEqual from 'lodash/isEqual'
import { CORRECT } from '../../Constants/result'
import { getStudentAnswersKey } from '../../utilities/contextKeys'
import { isPracticeExam } from '../../utilities/chapterUtils'
import { escapeRegExp } from 'lodash'
import { isExamRetakeType } from '../../utilities/examUtils'
import config from '../../config'
import SidePanelWithPin from './SidePanelWithPin'

class QuestionSetProgress extends Component {
  constructor (props) {
    super(props)
    this.buttonClass = this.buttonClass.bind(this)
    this.createButton = this.createButton.bind(this)
    this.createButtons = this.createButtons.bind(this)
    this.navigatorEmitter = this.navigatorEmitter.bind(this)
    this.checkPrevProps = this.checkPrevProps.bind(this)

    const { type_of_question: sectionType } = this.props
    this.state = {
      currentQuestionUUID: '',
      showSlider: false,
      isMobile: window.innerWidth <= 375 || window.innerWidth <= 576,
      isTouchDevice: 'ontouchstart' in document.documentElement && window.innerWidth <= 768,
      isProblemBank: sectionType === PRACTICE_EXERCISES
    }
    this.onNavigate = this.onNavigate.bind(this)
    this.doSlideGoTO = this.doSlideGoTO.bind(this)
  }

  componentWillMount () {
    const { currentQuestionUUID } = this.props
    this.setState({
      currentQuestionUUID
    })
    window.addEventListener('resize', () => {
      this.setState({
        isMobile: window.innerWidth <= 375 || window.innerWidth <= 576
      })
    })
  }

  componentDidMount () {
    const {
      context: { updateContext },
      type_of_question: typeOfQuestion } = this.props
    const isAssessmentQuizExam =
    (typeOfQuestion === QUIZ || typeOfQuestion === EXAM)
    updateContext({ isAssessmentQuizExam })
    const isALRedesign = typeOfQuestion === ACTIVE_LEARNING
    updateContext({ isALRedesign })
  }

  componentDidUpdate (prevProps) {
    const { isMobile, isTouchDevice } = this.state
    const slidesToShow = isMobile || isTouchDevice ? 5 : 10
    const { testResult } = this.props
    const {
      testResult: prevTestResult,
      activeQuestionInd: prevActiveQuestionIndex,
      currentQuestion: prevQuestions,
      currentQuestionUUID: prevQuestionUUID,
      type_of_question: prevTypeOfQuestion
    } = prevProps
    this.checkPrevProps({
      prevActiveQuestionIndex,
      prevQuestions,
      prevQuestionUUID,
      prevTypeOfQuestion
    })
    if (!isEqual(testResult, prevTestResult)) {
      if (testResult && testResult.length > slidesToShow) {
        this.setState({ showSlider: true })
      } else {
        this.setState({ showSlider: false })
      }
    }
  }

  checkPrevProps (prevProps) {
    const {
      slideBubbleToLatest,
      activeQuestionInd,
      activeSectionUUID,
      currentQuestion: questions,
      type_of_question: currentTypeOfQuestion
    } = this.props

    const {
      prevActiveQuestionIndex,
      prevQuestions,
      prevQuestionUUID,
      prevTypeOfQuestion
    } = prevProps

    const { isProblemBank, isMobile, isTouchDevice } = this.state
    const currentQuestion = activeQuestionInd >= 0 &&
      questions[activeQuestionInd]
    const currentQuestionUUID = currentQuestion &&
      currentQuestion.Question_uuid
    if (!currentQuestionUUID) return

    if (slideBubbleToLatest) {
      // if the last viewed Question is being passed then slide the bubble to latest
      const NToSlide = isMobile || isTouchDevice ? 5 : 10
      this.doSlideGoTO(activeQuestionInd - activeQuestionInd % NToSlide)
    }
    if (prevActiveQuestionIndex !== activeQuestionInd) {
      /* ------- emit commented as it was redirecting back URL after finishing last question ------------ */
      const shouldNavigate = prevTypeOfQuestion === currentTypeOfQuestion ||
        prevQuestionUUID !== currentQuestionUUID
      if (shouldNavigate) {
        this.navigatorEmitter(activeSectionUUID, isProblemBank
          ? `${currentQuestion.questionSetUUID}_${currentQuestionUUID}`
          : currentQuestionUUID)
        this.setState({ currentQuestionUUID })
      }

      if (prevActiveQuestionIndex < activeQuestionInd) {
        if (activeQuestionInd >= 10 && activeQuestionInd <= 200 &&
          activeQuestionInd % 10 === 0) this.doSlideGoTO(activeQuestionInd)
      } else if (prevActiveQuestionIndex > activeQuestionInd) {
        if (activeQuestionInd >= 9 && activeQuestionInd <= 199 &&
          activeQuestionInd % 10 === 9) if (this.slider) this.slider.slickPrev(activeQuestionInd)
      }
    }

    const prevFirstQuestionUUID = prevQuestions[0].Question_uuid
    const firstQuestion = questions[0]
    const firstQuestionUUID = firstQuestion.Question_uuid
    if (prevFirstQuestionUUID !== firstQuestionUUID) {
      this.navigatorEmitter(activeSectionUUID, isProblemBank
        ? `${firstQuestion.questionSetUUID}_${firstQuestionUUID}`
        : firstQuestionUUID)
      this.setState({ currentQuestionUUID: firstQuestionUUID })
    } else if (prevFirstQuestionUUID === firstQuestionUUID &&
      !prevQuestionUUID) {
      this.setState({ currentQuestionUUID })
    }
  }

  navigatorEmitter (activeSectionUUID, UUID) {
    emitter.emit(ON_NAVIGATE_TO, '/' + activeSectionUUID + '/' + UUID)
  }

  onNavigate (uuid) {
    const { isProblemBank } = this.state
    if (isProblemBank) return
    const {
      activeSectionUUID,
      setFilteredQuestionInd,
      updateQuestionUUId
    } = this.props
    this.setState({ currentQuestionUUID: uuid })
    this.navigatorEmitter(activeSectionUUID, uuid)
    setFilteredQuestionInd(uuid)
    if (updateQuestionUUId) updateQuestionUUId(uuid)
    document.activeElement.blur()
  }

  doSlideGoTO (index) {
    if (this.slider) this.slider.slickGoTo(index)
  }

  getProblemBankTestResults = (label, index, items) => {
    const { activeQuestionInd, numberOfQuestions } = this.props
    const questionSetResponse = this.getQuestionSetResponse()
    const { trials, hasPopupQuestionAttempted, popResult } = questionSetResponse || {}
    const popIndex = Math.floor(parseInt(numberOfQuestions) / 2)
    const hasPopQuestion = items.length === parseInt(numberOfQuestions) + 1
    const isPopQuestion = hasPopQuestion && popIndex === index
    const isChallengeQuestion = index === items.length - 1
    const trialsIndex = hasPopQuestion ? index < popIndex ? index : index - 1 : index
    const trialsClass = trials && trials[trialsIndex]
      ? trials[trialsIndex] === CORRECT || trials[trialsIndex].result === CORRECT
        ? 'completed'
        : 'incorrect'
      : (activeQuestionInd === index)
        ? 'active'
        : 'empty'
    const popUpButtonState = hasPopupQuestionAttempted
      ? popResult
        ? 'completed'
        : 'incorrect'
      : (activeQuestionInd === index)
        ? 'active'
        : 'empty'
    const buttonState = isPopQuestion ? popUpButtonState : trialsClass
    const buttonType = isPopQuestion && ['empty', 'active'].includes(buttonState)
      ? 'bubble_border'
      : isChallengeQuestion
        ? 'bubble_star'
        : ''

    return `${buttonType} ${buttonState}`
  }

  getQuestionSetResponse = () => {
    const {
      currentQuestion: questionSet,
      currentProblemSetResponse
    } = this.props
    const studentAnswers = this.getStudentAnswers()
    const { questionSetUUID } = questionSet && questionSet[0]
    return currentProblemSetResponse || studentAnswers.find(answer => answer.uuid === questionSetUUID)
  }

  buttonClass (label, index, items) {
    const {
      context: {
        isCohortEndedForStudent,
        examRetake,
        cohortData,
        currentChapter: chapter
      }
    } = this.props

    const isRetakeAllowed = isExamRetakeType({
      examRetake,
      cohortId: cohortData?.cohortID,
      chapter
    })

    const { currentQuestionUUID, isProblemBank } = this.state
    if (isCohortEndedForStudent && !isRetakeAllowed) {
      return (label.uuid === currentQuestionUUID) ? ' active' : 'empty'
    }
    if (isProblemBank) return this.getProblemBankTestResults(label, index, items)

    const studentAnswers = this.getStudentAnswers()
    const {
      type_of_question: sectionType,
      reviewMode,
      activeSectionUUID,
      currentQuestion
    } = this.props
    let btnClass
    const foundAnswer = studentAnswers.find(
      answer => answer.uuid === label.uuid
    )
    const isExamPractice = isPracticeExam(activeSectionUUID)
    const isPendingResponseAnswer = !reviewMode && !isExamPractice &&
      [QUIZ, EXAM].includes(sectionType)
    const {
      question_type: questionType,
      answer,
      fitbCaseSensitive
    } = currentQuestion[index]
    /*
        For the pin feature, we're using the same foundAnswer object, so added
        a check for the answer key, but in case of Active Learning, few answers have
        empty string, that's why added it's check separately. Also added check for
        true false questions because first answer is selected, the value is zero
      */
    if (foundAnswer?.answer || foundAnswer?.answer === 0 ||
      foundAnswer?.type === ACTIVITY_TYPES[ACTIVE_LEARNING]) {
      const { correct, answer: studentAnswer } = foundAnswer
      const isCurrentQuestion = label.uuid === currentQuestionUUID
      const iconClass = correct ? 'completed' : 'incorrect'
      btnClass = correct === undefined
        ? isCurrentQuestion ? 'active' : 'empty'
        : iconClass
      if (questionType === MULTIPLE_CHOICE) {
        const formattedStudentAnswer = typeof studentAnswer === 'number'
          ? [(studentAnswer + 1).toString()]
          : studentAnswer && Array.isArray(studentAnswer)
            ? studentAnswer.map(ans => (ans + 1).toString())
            : []
        const isCorrectMultiChoice = isEqual(
          formattedStudentAnswer.sort(),
          answer.sort()
        )
        const isCurrentQuestion = label.uuid === currentQuestionUUID
        const iconClass = isCorrectMultiChoice ? 'completed multi-choice'
          : 'incorrect multi-choice'
        btnClass = correct === undefined
          ? isCurrentQuestion ? 'active' : 'empty'
          : iconClass
      }
      if (sectionType === GUESSWORK) btnClass = 'guesswork'
      if (isPendingResponseAnswer) btnClass = 'response-submitted'
      if (sectionType === ACTIVE_LEARNING && questionType === TEXT_BLANK) {
        const answerArr = []
        answer.forEach((_element, key) => {
          let studentAns = studentAnswer[key]
          const currentAns = answer[key]
          if (currentAns && studentAns) {
            studentAns = studentAns.toString()
            const matchText = currentAns.match(
              new RegExp(escapeRegExp(studentAns) + '(.*)')
            )
            const isAnswerCorrect = fitbCaseSensitive
              ? currentAns === studentAns
              : currentAns.toLowerCase() === studentAns.toLowerCase()
            const isAnswerMatched = isAnswerCorrect ||
              (matchText && matchText.length && matchText.index === 0)
            answerArr.push(isAnswerMatched || false)
          }
        })
        if (!correct) {
          btnClass = answerArr.indexOf(false) !== -1 ? 'incorrect'
            : label.uuid === currentQuestionUUID ? 'active' : 'empty'
        }
      }
    } else {
      if (reviewMode) btnClass = 'incorrect'
      else if (label.uuid === currentQuestionUUID) btnClass = 'active'
      else btnClass = 'empty'
    }
    return btnClass
  }

  getStudentAnswers = () => {
    const {
      context: {
        currentChapter: { type },
        studentData
      }
    } = this.props
    const studentAnswersKey = getStudentAnswersKey(type)
    return studentData[studentAnswersKey]
  }

  createButton (label, index, items) {
    const {
      type_of_question: sectionType,
      context,
      currentQuestion: QuestionSetdata
    } = this.props
    const { isProblemBank, isTouchDevice, currentQuestionUUID } = this.state
    const { studentData: { pinnedQuestions } } = context

    const isAssessmentQuizExam =
     (sectionType === QUIZ || sectionType === EXAM)
    const isActiveLearning = sectionType === ACTIVE_LEARNING
    const isQuizExamOrAL = isAssessmentQuizExam || isActiveLearning
    const isPinned = { isPinned: pinnedQuestions?.includes(label.uuid) }
    return isProblemBank
      ? (<ButtonPractice
        keyId={index}
        btnClass={this.buttonClass(label, index, items)}
      />)
      : (<Button
        key={label.uuid}
        value={index}
        label={label}
        sectionType={sectionType}
        isAssessmentQuizExam={isAssessmentQuizExam}
        QuestionSetdata={QuestionSetdata}
        isActiveLearning={isActiveLearning}
        dataCypress='progressBar'
        btnClass={this.buttonClass(label, index, items)}
        doNavigate={this.onNavigate}
        isTouchDevice={isTouchDevice}
        currentQuestionUUID={currentQuestionUUID}
        {...(isQuizExamOrAL && isPinned)}
      />)
  }

  createButtons (items) {
    return items.map(this.createButton)
  }

  componentWillUnmount () {
    const { context: { updateContext } } = this.props

    updateContext({ navigateToActiveIndex: false, examsUnlockedWithKeys: {} })
    window.removeEventListener('resize', () => {
      this.setState({
        windowWidth: window.innerWidth
      })
    })
  }

  render () {
    const {
      testResults,
      showSidePanel,
      type_of_question: sectionType,
      reviewMode,
      context: {
        updateContext,
        sidePanelOpenState
      },
      activeQuestionInd
    } = this.props
    const {
      isMobile,
      isTouchDevice,
      showSlider
    } = this.state
    const questionsInfo = Array.from(Object.keys(testResults), k => testResults[k])
    const progressBubbleButtons = this.createButtons(questionsInfo)

    const settings = {
      autoplay: false,
      dots: false,
      slidesToShow: (isMobile || isTouchDevice) ? 5 : 10,
      slidesToScroll: (isMobile || isTouchDevice) ? 5 : (activeQuestionInd + 10 <= progressBubbleButtons.length) ? 10 : 5,
      infinite: false,
      // Disables arrow key navigation in the bubble list slider.
      accessibility: false
    }
    const isAssessmentQuizExam =
     (sectionType === QUIZ || sectionType === EXAM)
    const isNonReviewAndExam = !reviewMode && sectionType === EXAM
    const isALRedesign = sectionType === ACTIVE_LEARNING
    const isRedesignUpdates = isAssessmentQuizExam || isALRedesign
    const enableSidePanel = (isALRedesign && showSidePanel) || isAssessmentQuizExam
    return (
      <React.Fragment>
        {!isRedesignUpdates
          ? <div
            id={(isTouchDevice || isMobile) ? 'touch-layout' : ''}
            className='tab-content'>
            <div className='active__learning-progress'>
              <div className={`active__learning-progress-track
              guess__work-progress-track align-items-start`
              }>
                { !showSlider &&
              progressBubbleButtons}
                {
                  showSlider &&
                  <section className='slider'>
                    <Slider
                      ref={slider => (this.slider = slider)}
                      {...settings}>
                      {progressBubbleButtons}
                    </Slider>
                  </section>
                }
              </div>
            </div>
          </div> : enableSidePanel && (config.hasPinnedActiveLearningFlag()
          ? <SidePanelWithPin
            progressBubbleButtons={progressBubbleButtons}
            sidePanelOpenState={sidePanelOpenState}
            isNonReviewAndExam={isNonReviewAndExam}
            updateContext={updateContext}
            isALRedesign={isALRedesign}
            reviewMode={reviewMode}
            isLastQuestion={this.props.context.isLastQuestionAnswered}
            sidePanelOpen={this.props.context.isSidePanelOpen}
          />
          : <SidePanel
            progressBubbleButtons={progressBubbleButtons}
            sidePanelOpenState={sidePanelOpenState}
            isNonReviewAndExam={isNonReviewAndExam}
            updateContext={updateContext}
            isALRedesign={isALRedesign}
            reviewMode={reviewMode}
            isLastQuestion={this.props.context.isLastQuestionAnswered}
            sidePanelOpen={this.props.context.isSidePanelOpen}
          />)
        }
      </React.Fragment>
    )
  }
}

export default QuestionSetProgress
