import styled from 'styled-components'

export const ModalWrapper = styled.div`
  font-size: 16px;
  line-height: 26px;
  min-height: 203px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ModalContent = styled.div`
  margin-bottom: 24px;
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledHeaderText = styled.p`
  font-weight: 400;
`

export const StyledList = styled.ol`
  font-weight: 300;
`

export const StyledAnchor = styled.a`
  color: #78bcb8 !important;
  &:hover {
    text-decoration: none;
  }
`
