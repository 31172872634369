import React, { useState, useContext, useEffect } from 'react'
import Context from '../Context/Context'
import api from '../../api'
import CustomInput from '../CustomInput'
import {
  OverrideStyles,
  PageWrapper,
  LockIcon,
  Title,
  KeysInputWrapper,
  Button
} from './styled'
import { EXAM_LOCK_STATUS } from '../../Constants/studentContext'

const ExamKeysPage = ({ examUUID, examKeyName, cohortId, setDisableAIViolationModal }) => {
  const { updateContext } = useContext(Context)
  const [value, setValue] = useState('')
  const [isVerifying, setIsVerifying] = useState(false)
  const [invalidKey, setInvalidKey] = useState(false)

  useEffect(() => {
    setDisableAIViolationModal(true)
    // eslint-disable-next-line
  }, [])

  const handleButtonClick = async () => {
    setIsVerifying(true)
    const { isValidExamKey } = await api.verifyExamKey(
      cohortId,
      { [examKeyName]: value }
    )
    setIsVerifying(false)
    if (!isValidExamKey) return setInvalidKey(true)

    setInvalidKey(false)

    await api.setStudentSectionProgress(
      EXAM_LOCK_STATUS,
      { [examUUID]: false }
    )
    setDisableAIViolationModal(false)
    updateContext({
      examsUnlockedWithKeys: { [examUUID]: true }
    })
  }

  return (
    <>
      <OverrideStyles />
      <PageWrapper>
        <LockIcon
          src='images/icons/icon-lock-with-key-hole.svg'
          alt='lock-key-icon'
        />
        <Title>Exam Locked</Title>
        <div className='content'>
          <p className='description'>
            Please wait for your instructor to unlock the exam.
          </p>
          <KeysInputWrapper>
            <CustomInput
              showClear
              warningIconSrc='images/icons/icon-round-warning.svg'
              placeholder='Enter exam key'
              warningText='Invalid key. Please check for typos.'
              footerText=''
              onChange={(value) => {
                setValue(value)
                setInvalidKey(false)
              }}
              width='100%'
              error={invalidKey}
              handleEnterKeyPress={handleButtonClick}
              disabled={isVerifying}
            />
          </KeysInputWrapper>
          <Button
            disabled={!value || isVerifying}
            onClick={handleButtonClick}
          >
            {isVerifying ? <i className='fa fa-spinner fa-spin' /> : 'Unlock Exam'}
          </Button>
        </div>
      </PageWrapper>
    </>
  )
}

ExamKeysPage.displayName = 'ExamKeysPage'
export default ExamKeysPage
