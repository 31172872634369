import React, { useCallback } from 'react'
import PermissionRequested from './PermissionRequested'
import PermissionRequired from './PermissionRequired'
import AwaitingPermission from './AwaitingPermission'
import PermissionGranted from './PermissionGranted'
import { useStudentDataContext } from '../Context/Context'
import useOverridableState from
  '../../Hooks/overridableStateHook/overridableStateHook'
import {
  PERMISSION_AWAITED,
  PERMISSION_GRANTED,
  PERMISSION_REQUESTED,
  PERMISSION_REQUIRED
} from './permissionStates'

const GuardianPermission = ({
  initialState
}) => {
  const {
    guardianEmail
  } = useStudentDataContext()

  const [permissionState, setPermissionState] = useOverridableState(
    useCallback(
      () => initialState || PERMISSION_REQUIRED,
      [initialState]
    )
  )
  const [email, setEmail] = useOverridableState(
    useCallback(() => guardianEmail || '', [guardianEmail])
  )

  if (permissionState === PERMISSION_REQUIRED) {
    return <PermissionRequired
      email={email}
      setEmail={setEmail}
      setPermissionState={setPermissionState}
    />
  }

  if (permissionState === PERMISSION_REQUESTED) {
    return <PermissionRequested
      guardianEmail={email}
      setEmail={setEmail}
      setPermissionState={setPermissionState}
    />
  }

  if (permissionState === PERMISSION_AWAITED) {
    return <AwaitingPermission
      guardianEmail={email}
      setEmail={setEmail}
      setPermissionState={setPermissionState}
    />
  }

  if (permissionState === PERMISSION_GRANTED) {
    return <PermissionGranted />
  }

  return null
}

export default GuardianPermission
