import React from 'react'
import DOMPurify from 'dompurify'
import { GradingCriteriaText } from './styled'
import { getExplanationArticles, getGradeExplanationText } from '../Utils'
import config from '../../../config'

const GradeCriteriaText = ({ haveDynamicData, datoGradeExplanationText }) => {
  const gradeExplanationText = getGradeExplanationText(datoGradeExplanationText)
  const shouldHideText = haveDynamicData && !gradeExplanationText?.length
  if (shouldHideText) {
    return null
  }

  const explanationArticles = getExplanationArticles(gradeExplanationText)
  if (haveDynamicData && explanationArticles?.length) {
    return (
      <GradingCriteriaText>
        {explanationArticles.map(article => (
          <article>
            {article.map(paragraph => (
              <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(paragraph) }} />
            ))}
          </article>
        ))}
      </GradingCriteriaText>
    )
  }
  const customGradingFirstParagraphText =
      config.course.customGradingFirstParagraphText
  const isCustomGradingText = customGradingFirstParagraphText !== ''

  if (isCustomGradingText) {
    return (
      <GradingCriteriaText>
        <article>
          <p>
            {customGradingFirstParagraphText}
          </p>
          <p>
              You will be able to attempt three (3) versions of each chapter’s
              quiz. All three versions will be made available to you
              at the end of each chapter. You are required to attempt and
              complete at least one version of each quiz. Failing to
              do so will result in a grade of 0% for that chapter. If you
              complete more than one quiz for a particular chapter, only
              the highest grade achieved will be counted for that chapter.
          </p>
        </article>
        <article>
          <p>
              As such, you are encouraged to use these multiple quiz attempts as
              opportunities to identify areas that are in need
              of further practice. This diligent effort will allow you to
              improve their understanding, mastery of the material, and
              grade in the course.
          </p>
          <p>
              An official grade will appear on the University of Pittsburgh
              transcript for any student who continues their enrollment beyond
              the
              official drop date for the course.
          </p>
        </article>
      </GradingCriteriaText>
    )
  }

  return (
    <GradingCriteriaText>
      <article>
        <p>
          At the end of each chapter, you will be required to complete at least
          one version of each chapter’s quiz to assess your mastery of the
          material.
        </p>
        <p>
          Failing to do so will result in a grade of 0% for that chapter. If you
          complete more than one quiz for a chapter, only the highest grade
          achieved will be counted for that chapter.
        </p>
        <p>
          You will be able to attempt five versions of each chapter’s quiz:
          Three will be made available at the end of each chapter, one will
          become available when the midterm covering the material of that
          chapter is presented, and one last version will become available when
          the final exam is presented.
        </p>
      </article>
      <article>
        <p>
          For chapters with materials that are only covered on the final, two
          additional versions of those chapters’ quizzes will become available
          when the final is presented.
        </p>
        <p>
          As such, you are encouraged to use these multiple quiz attempts as
          opportunities to identify areas that are in need of further practice.
          This diligent effort will allow you to improve your understanding,
          mastery of the material, and grade in the course.
        </p>
        <p>
          An official grade will appear on the University of Pittsburgh
          transcript for any student who continues their enrollment beyond the
          official drop date for the course.
        </p>
      </article>
    </GradingCriteriaText>
  )
}

export default GradeCriteriaText
