import React, { useState, useEffect } from 'react'
import ModalComponent from '../ModalComponent/ModalComponent'
import {
  ContentWrapper,
  ReadBio,
  ArrowWrapper,
  NameWrapper,
  Input,
  ThemeWrapper
} from './styles'

function ChangeInstructorModal (props) {
  const {
    handleClose,
    show,
    headerContent,
    calculusInstructorsList,
    onChangeInstructor,
    currentInstructorID,
    showReadBioModal,
    footerShow
  } = props

  const [radioButtonChange, setRadioButtonChange] = useState(currentInstructorID)
  const [instructorName, setInstructorName] = useState()
  const footerPrefix = currentInstructorID === radioButtonChange ? 'Keep' : 'Set'

  useEffect(() => {
    setInstructor(currentInstructorID)
    // eslint-disable-next-line
  }, [])

  function setInstructor (currentInstructorID) {
    setRadioButtonChange(currentInstructorID)
    const { instructor } = calculusInstructorsList?.find(({ instructor }) =>
      instructor.instructor_uuid === currentInstructorID)
    const name = instructor?.name.split(',')[0]
    setInstructorName(name)
  }

  return (
    <ModalComponent
      show={show}
      handleClose={handleClose}
      hideClose
      title={headerContent}
      headerShow
      footerShow={footerShow}
      cypress='changeInstructorModal'
      radioButtonChange={radioButtonChange}
      onChangeInstructor={onChangeInstructor}
      footerText={`${footerPrefix} ${instructorName} as your Lecturer?`}
      disableHideOnClick
    >
      {calculusInstructorsList?.map(({ instructor: {
        instructor_uuid: instructorUuid,
        theme_name: themeName,
        name
      } }) => (
        <ContentWrapper key={instructorUuid}>
          <span
            className='d-flex'
            data-cy='changeInstructorContent'
          >
            <span className='position-relative'>
              <Input
                value={instructorUuid}
                type='radio'
                name='selectInstructor'
                data-cy={name}
                defaultChecked={radioButtonChange === instructorUuid}
                onClick={(e) => setInstructor(e.target.value)}
              />
            </span>
            <span>
              <NameWrapper>{name}</NameWrapper>
              <ThemeWrapper>{themeName}</ThemeWrapper>
            </span>
          </span>
          <span data-cy='ReadBio' className='d-flex'>
            <ReadBio
              onClick={() => showReadBioModal(instructorUuid)}
              data-cy='readBio'
            >
              READ BIO
            </ReadBio>
            <ArrowWrapper>
              <img src='images/Caret.png' alt='icon' />
            </ArrowWrapper>
          </span>
        </ContentWrapper>
      ))
      }
    </ModalComponent>
  )
}

export default ChangeInstructorModal
