import styled from 'styled-components'

export const ExtensionWrapper = styled.div`
  position: absolute;
  z-index: 1111;
  top: 0;
  right: 0;
  margin: 88px 24px 0;
  padding: 24px;
  background-color: #25272B;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  max-width: 400px;
`

export const ExtensionToastHeader = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
`

export const ExtensionToastHeaderData = styled.div`
  display: flex;
`

export const HeaderText = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-left: 8px;
`

export const ExtensionToastInfo = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`
