import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import JDoodleCompiler from '../JDoodleCompiler/JDoodleCompiler'

const CodingQuestion = ({
  question,
  codingResponse,
  onCodeChange,
  sectionType
}) => {
  const {
    Question_uuid: questionUUID,
    starterCode,
    codegradeAssignmentId
  } = question || {}

  const savedAnswer = codingResponse?.answer
    ? {
      value: {
        schema: 'dast',
        document: {
          type: 'root',
          children: [
            {
              code: codingResponse.answer,
              type: 'code',
              language: 'java'
            }
          ]
        }
      }
    } : null

  return (
    <>
      <JDoodleCompiler
        key={questionUUID}
        questionUUID={questionUUID}
        starterCode={savedAnswer || starterCode}
        sectionType={sectionType}
        codegradeAssignmentId={codegradeAssignmentId}
        saveEditorContent={useCallback(
          message => onCodeChange(message),
          // eslint-disable-next-line
          [questionUUID]
        )}
      />
    </>
  )
}

CodingQuestion.propTypes = {
  question: PropTypes.object,
  sectionType: PropTypes.string,
  onCodeChange: PropTypes.func
}

CodingQuestion.displayName = 'CodingQuestionProblemBank'

export default CodingQuestion
