import { getStudentAnswersKey } from './contextKeys'

export const isAnswersLessThan70Per = (
  studentData,
  questionSet,
  instructorUuid,
  chapterType
) => {
  const studentAnswersKey = getStudentAnswersKey(chapterType)
  const answerUUIDs = studentData[studentAnswersKey]
    .map(st => st.uuid)
  const currInstructorQuestionSet = questionSet
    .filter(question => question.instructor.instructor_uuid === instructorUuid)
  const unansweredQuestions = currInstructorQuestionSet
    .filter(question => !answerUUIDs.includes(question.Question_uuid))
  const questionCount = currInstructorQuestionSet.length
  const unAnsweredCount = unansweredQuestions?.length ?? 0
  const answerCount = questionCount - unAnsweredCount
  return Math.round(answerCount / questionCount * 100) < 70
}
