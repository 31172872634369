import React from 'react'
import ProblemSetList from '../ProblemSetList/ProblemSetList'

export default function ProblemSet (props) {
  const { questionSet } = props
  if (!questionSet) return <div>loading...</div>

  const { activeSectionUUID } = props
  return (
    <section className='section section-home'>
      <div className='container'>
        <div className='container-sm'>
          <div className='section-panel__wrapper'>
            <ProblemSetList questionList={questionSet}
              sectionUUID={activeSectionUUID} />
          </div>
        </div>
      </div>
    </section>
  )
}
