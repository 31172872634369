import React, { useState } from 'react'
import { ButtonDropdown } from 'reactstrap'
import {
  DownloadToggle,
  DownloadDropdownMenu,
  DownloadIcon,
  DownloadDropdownItem } from './styled'
import { downloadFile } from '../../utilities/file'

export default function DownloadMenu (props) {
  const { pdf, mobi, epub } = props
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <ButtonDropdown isOpen={isOpen} toggle={toggle}>
      <DownloadToggle color='primary'>
        <DownloadIcon src='/images/icons/icon-download.svg' alt='PDF' />
        Download
      </DownloadToggle>
      <DownloadDropdownMenu>
        <DownloadDropdownItem onClick={() => downloadFile(pdf)}>
          <DownloadIcon pdf src='/images/icons/icon-pdf.svg' alt='PDF' />
          PDF</DownloadDropdownItem>
        {mobi && <DownloadDropdownItem onClick={() => downloadFile(mobi)}>
          <DownloadIcon mobi src='/images/icons/icon-mobi.svg' alt='MOBI (Amazon Devices)' />
          MOBI (Amazon Devices)
        </DownloadDropdownItem>}
        {epub && <DownloadDropdownItem onClick={() => downloadFile(epub)}>
          <DownloadIcon epub src='/images/icons/icon-epub.svg' alt='EPUB' />
          EPUB</DownloadDropdownItem>}
      </DownloadDropdownMenu>
    </ButtonDropdown>
  )
}
