export default {
  stateFactory
}

function stateFactory (KEY) {
  function get () {
    return localStorage.getItem(KEY)
  }

  function set (value) {
    if (typeof value !== 'boolean') return
    return localStorage.setItem(KEY, value)
  }

  function toggle () {
    const state = get() === 'true'
    return set(!state)
  }

  function clear () {
    return localStorage.removeItem(KEY)
  }

  return { get, set, toggle, clear }
}
