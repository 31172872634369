import React, { useContext } from 'react'
import config from '../../../config'
import {
  HowItWorksHeader,
  HowItWorksSection,
  HowItWorksSectionItemHeader,
  HowItWorksSectionItemHeaderImage,
  HowItWorksSectionItemHeaderText,
  HowItWorksSectionItemDescription
} from './styled'
import Context from '../../Context/Context'
import { isHighSchoolCourse } from '../../../utilities/courseUtils'

const { isCalculusCourse } = config
const isCalculus = isCalculusCourse()

const content = (isHighSchoolPartnerCourse, isCalculus) => [
  {
    image: 'images/icons/icon-tv.svg',
    header: isHighSchoolPartnerCourse ? 'Learn from Lectures and Active Learning' : 'Watch the Lectures',
    description: isHighSchoolPartnerCourse
      ? 'Learn from engaging lecture videos with top educators and from active learning, our interactive textbook. These resources are not graded.'
      : isCalculus
        ? 'Switch between educators during the course by selecting from the menu on the left. For tricky topics, you may want to try another educator if something doesn’t click the first time through.'
        : 'Learn from top educators who are experts in each of their respective topics.'
  },
  {
    image: 'images/icons/icon-book.svg',
    header: 'Practice',
    description: isHighSchoolPartnerCourse
      ? 'Reinforce your learning with practice tools such as vocabulary terms and sample problems. Practice is not graded, so keep going until you feel confident enough to move on.'
      : 'All active learning is provided for your practice and does not count toward your grade. Keep practicing till you feel ready to move on.'
  },
  {
    image: 'images/icons/icon-chat.svg',
    header: isHighSchoolPartnerCourse ? 'Talk to Classmates' : 'Learn From Others',
    description: isHighSchoolPartnerCourse
      ? 'Ask and answer questions with your fellow students in the academic discussion community to earn the participation portion of your grade.'
      : 'Ask and answer questions from your fellow students in the Outlier community discussion to earn the participation portion of your grade.'
  },
  {
    image: 'images/icons/icon-checklist.svg',
    header: 'Test Your Knowledge',
    description: isHighSchoolPartnerCourse
      ? 'When you’ve mastered each section, take a quiz to test your mastery. Only your highest quiz score for each section will be counted toward your grade, so if you don’t get a score you like the first time, review the material and try again!'
      : 'When you’ve mastered each section, take a quiz to test your mastery. Only your highest quiz score for each section will be counted toward your grade, so if you don’t get a score you like the first time, try, try again (up to 5 times).'
  },
  {
    image: 'images/icons/icon-check.svg',
    header: isHighSchoolPartnerCourse ? 'Complete the Exams and/or Assignments' : 'Complete the Exams',
    description: isHighSchoolPartnerCourse
      ? 'Your course includes graded exams, graded assignments, or both. Your facilitator will tell you when to take each exam. You may only attempt each exam and submit each assignment once, so be sure to prepare before working on these assessments.'
      : 'There are 2 midterms and a final exam. You can take an exam at any time during each open exam period. Please keep in mind that you may only attempt each exam once, so be sure to keep practicing and be fully prepared when you begin your exam.'
  },
  {
    image: 'images/icons/icon-pen.svg',
    header: 'Help Us Improve',
    description: 'Tell us what you love and what you don’t. Our mission is to create the best educational experiences in the world. To do that, we need your feedback, so don’t be shy! Use the button in the bottom right corner of the site to submit your feedback.'
  }
]

function HowItWorksSectionItem (props) {
  const { image, header, description } = props
  return (
    <div>
      <HowItWorksSectionItemHeader>
        <HowItWorksSectionItemHeaderImage src={image} />
        <HowItWorksSectionItemHeaderText>
          {header}
        </HowItWorksSectionItemHeaderText>
      </HowItWorksSectionItemHeader>
      <HowItWorksSectionItemDescription>
        {description}
      </HowItWorksSectionItemDescription>
    </div>
  )
}

export default function HowItWorks () {
  const { activeCourse } = useContext(Context)

  const isHighSchoolPartnerCourse = isHighSchoolCourse(activeCourse)
  const contentToDisplay = content(isHighSchoolPartnerCourse, isCalculus)

  return (
    <div>
      <HowItWorksHeader>
        How It Works
      </HowItWorksHeader>
      <HowItWorksSection>
        {contentToDisplay.map(item => (
          <HowItWorksSectionItem
            key={`${item.image}-${item.header}`}
            {...item}
          />
        ))}
      </HowItWorksSection>
    </div>
  )
}
