import styled from 'styled-components'

export {
  Content,
  Left,
  Right,
  Time,
  Title
} from '../../LecturesExerciseItem/styled'

export const QuizItemContainer = styled.div`
  padding: 36px 12px;
`

export const ActivityWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${props => props.isLocked ? 'center' : 'flex-start'};
  padding: 24px 0px;
  p {
    margin: 0px;
  }
`
