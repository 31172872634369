import React from 'react'
import DOMPurify from 'dompurify'
import { Collapse } from 'reactstrap'
import { ToggleIcon } from '../../SectionListExcerciseItem/styled'
import {
  PolicyContainer,
  Content,
  Title } from './styled'

const Accordion = ({
  title,
  content,
  isOpenValues,
  setIsOpenValues,
  index
}) => {
  return (
    <PolicyContainer data-cy='policyContainer'>
      <div
        className='d-flex justify-content-between align-items-center'
        onClick={() => {
          setIsOpenValues(isOpenValues.map(
            (value, position) => {
              if (position !== index) return false
              return !value
            }))
        }}
      >
        <Title>{title}</Title>
        <ToggleIcon
          data-cy='policyToggleBtn'
          isAccordionOpen={isOpenValues[index]} />
      </div>
      <Collapse isOpen={isOpenValues[index]}>
        <Content
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
        />
      </Collapse>
    </PolicyContainer>
  )
}

export default Accordion
