import React, { useContext, useEffect, useRef } from 'react'
import config from '../../../config'
import { PHILOSOPHY } from '../../../Constants/courseNames'
import Context from '../../Context/Context'
import { SubHeader } from '../styled'
import {
  getDynamicGradingCriteria,
  getGradingCriteria,
  getPercentageInDegrees,
  getSubTitle
} from '../Utils'
import { CS_GRADING_CRITERIA, CWII_GRADING_CRITERIA, PROFCOMM_GRADING_CRITERIA } from '../Constants'
import GradeChartItem from './GradeChartItem'
import { GradeCriteriaChartWrapper, GradeDetailsText } from './styled'
import { emitter } from '../../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../../Constants/emitterKeys'

function getGradeChartItemProps ({
  gradingDetails, haveDynamicData, isPhilosophy
}) {
  const isProfessionalCommunication = [
    'professionalcommunication',
    'professionalcommunication.plus'
  ].includes(config.courseName)

  if (haveDynamicData) {
    return gradingDetails.sort((a, b) => a.percentage < b.percentage ? 1 : -1)
  }

  const {
    quizzes,
    participation,
    midterms,
    finalExams,
    finalEssay,
    allWritingAssignments,
    otherWritingAssignments,
    writingAssignmentsCount,
    preWritingAssignments,
    apexAssignments,
    scaffoldingAssignments,
    unit1Assignments,
    unit2Assignments,
    unit3Assignments,
    cloneReadingEssay,
    lensEssay,
    multiSourceResearchEssay,
    midtermCount,
    capstone,
    capstoneCount
  } = gradingDetails

  const midTermSubTitle = getSubTitle(midterms / midtermCount)
  const capstoneSubTitle = getSubTitle(capstone / capstoneCount)
  const writingAssignmentSubTitle =
    allWritingAssignments &&
    getSubTitle(otherWritingAssignments / writingAssignmentsCount)

  const isSociology = [
    'sociology',
    'sociology-plus'
  ].includes(config.courseName)

  const isCollegeWritingI = [
    'collegewriting-i',
    'collegewriting-i.plus'
  ].includes(config.courseName)

  const isCollegeWritingII = [
    'collegewriting-ii',
    'collegewriting-ii.plus'
  ].includes(config.courseName)

  const isCSCourse = config.isCSCourse()

  const gradeChartItemProps = [
    {
      title: 'Quizzes',
      percentage: quizzes
    },
    {
      title: 'Participation',
      percentage: participation
    }
  ]

  if (isCSCourse) {
    gradeChartItemProps.push({
      title: 'Capstone Assignments',
      percentage: capstone,
      subTitle: capstoneSubTitle
    })
  }

  if (preWritingAssignments) {
    gradeChartItemProps.push({
      title: 'Pre-writing Assignments',
      percentage: preWritingAssignments
    })
  }

  if (apexAssignments) {
    gradeChartItemProps.push({
      title: 'Apex Assignments',
      percentage: apexAssignments
    })
  }

  if (scaffoldingAssignments) {
    gradeChartItemProps.push({
      title: 'Scaffolding Assignments',
      percentage: scaffoldingAssignments
    })
  }

  if (unit1Assignments) {
    gradeChartItemProps.push({
      title: 'Unit 1 Assignments',
      percentage: unit1Assignments
    })
  }

  if (unit2Assignments) {
    gradeChartItemProps.push({
      title: 'Unit 2 Assignments',
      percentage: unit2Assignments
    })
  }

  if (unit3Assignments) {
    gradeChartItemProps.push({
      title: 'Unit 3 Assignments',
      percentage: unit3Assignments
    })
  }

  if (cloneReadingEssay) {
    gradeChartItemProps.push({
      title: 'Close Reading Essay',
      percentage: cloneReadingEssay
    })
  }

  if (lensEssay) {
    gradeChartItemProps.push({
      title: 'Lens Essay',
      percentage: lensEssay
    })
  }

  if (multiSourceResearchEssay) {
    gradeChartItemProps.push({
      title: 'Multi-source Research Essay',
      percentage: multiSourceResearchEssay
    })
  }

  if (finalExams) {
    gradeChartItemProps.push({
      title: 'Final Exam',
      percentage: finalExams
    })
  }
  if (allWritingAssignments) {
    gradeChartItemProps.push({
      title: 'Assignments',
      finalEssay,
      percentage: allWritingAssignments,
      subTitle: isPhilosophy
        ? otherWritingAssignments
        : isSociology
          ? ''
          : writingAssignmentSubTitle
    })
  }

  if (!(isCollegeWritingI || isCollegeWritingII || isProfessionalCommunication)) {
    gradeChartItemProps.push({
      title: `Midterm${midtermCount > 1 ? 's' : ''}`,
      subTitle: midtermCount > 1 && midTermSubTitle,
      percentage: midterms
    })
  }

  return gradeChartItemProps
    .sort((a, b) => a.percentage < b.percentage ? 1 : -1)
}

function getDegrees (gradeChartItemProps) {
  const percentagesList = gradeChartItemProps.map(props => props.percentage)
  const degreesList = percentagesList.map(getPercentageInDegrees)

  return degreesList
}

const GradeCriteriaChart = ({ subHeader }) => {
  const scrollRef = useRef()

  useEffect(() => {
    if (config.hasInTimeGradeFlag && window && scrollRef?.current) {
      scrollToSection()
    }
    // eslint-disable-next-line
  }, [window?.location?.hash, scrollRef?.current])
  const {
    courseData: {
      gradeCalculations
    },
    cohortData: {
      officialCourseName,
      is39WeekCohort,
      name: cohortName
    }
  } = useContext(Context)
  const isPhilosophy = officialCourseName === PHILOSOPHY
  const isGGUCohort = cohortName?.toLowerCase().includes('ggu')

  const isCScourse = config.isCSCourse()
  const isCollegeWritingII = [
    'collegewriting-ii',
    'collegewriting-ii.plus'
  ].includes(config.courseName)

  const isProfessionalCommunication = [
    'professionalcommunication',
    'professionalcommunication.plus'
  ].includes(config.courseName)

  const getGradingDetails = () => {
    switch (true) {
      case isGGUCohort:
      case is39WeekCohort:
        return getDynamicGradingCriteria(gradeCalculations)
      case isProfessionalCommunication:
        return PROFCOMM_GRADING_CRITERIA
      case isCScourse:
        return CS_GRADING_CRITERIA
      case isCollegeWritingII:
        return CWII_GRADING_CRITERIA
      default:
        return getGradingCriteria(
          officialCourseName
        )
    }
  }
  const scrollToSection = () => {
    const {
      location: { hash }
    } = window
    const hasScrollHash = hash.includes('?gradingCriteria')
    if (hasScrollHash) {
      emitter.emit(ON_NAVIGATE_TO, '/resources')
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }

  const gradingDetails = getGradingDetails()

  if (!gradingDetails) return null

  const haveDynamicData = isGGUCohort || is39WeekCohort
  const gradeChartItemProps = getGradeChartItemProps({
    gradingDetails,
    haveDynamicData,
    isPhilosophy
  })

  const degrees = getDegrees(gradeChartItemProps)

  return (
    <>
      {subHeader && <SubHeader>{subHeader}</SubHeader>}
      <GradeDetailsText>Your grade will be comprised of:</GradeDetailsText>
      <GradeCriteriaChartWrapper ref={scrollRef}>
        { gradeChartItemProps.map((props, index) => <GradeChartItem
          key={props.title}
          chartNumber={index}
          degrees={degrees}
          newPhilosophyGrading={isPhilosophy}
          {...props}
        />)}
      </GradeCriteriaChartWrapper>
    </>
  )
}

export default GradeCriteriaChart
