export const ON_NAVIGATE_TO = 'navigate-to'
export const ON_NAVIGATE_TO_EXAM = 'navigate-to-exam'
export const ON_SKIP_SECTION = 'skip-section'
export const ON_GO_TO_COURSEHOME = 'go_to_courseHome'
export const ON_SET_LAST_ACTIVE_LEARNING_UUID = 'set-last-active-learning-uuid'
export const ON_PRACTICE_EXERCISES_COMPLETE = 'practice-exercises-complete'
export const ON_QUIZ_COMPLETE = 'quiz-complete'
export const ON_EXAM_COMPLETE = 'exam-complete'
export const ON_LECTURE_COMPLETE = 'lecture-complete'
export const ON_SET_CURRENT_CHAPTER = 'set-current-chapter'
export const ON_REVIEW_MODE = 'review-mode'
export const ON_STUDENT_ANSWER = 'student-answer'
export const ON_INSTRUCTOR_SWITCH = 'instructor-switch'
export const ON_QUIZ_SECTION = 'quiz-section'
export const ON_EXAM_SECTION = 'exam-section'
export const ON_SUBMIT_CARD_QUESTION = 'submit-card-question'
export const ON_IS_LAST_SECTION = 'is-last-section'
export const ON_SECTION_PROGRESS = 'section-progress'
export const ON_MINIMUM_SECTION_PROGRESS = 'on-minimum-section-progress'
export const ON_GUESSWORK_COMPLETE = 'guesswork-complete'
export const ON_ACTIVE_LEARNING_COMPLETE = 'active-learning-complete'
export const ON_SECTION_DATA_PROGRESS = 'section-data-progress'
export const ON_SUBMIT_ANSWER = 'submit-answer'
export const ON_SET_LAST_GUESSWORK_UUID = 'set-last-guesswork-uuid'
export const ON_SET_LAST_EXAM_UUID = 'set-last-exam-uuid'
export const ON_SET_LAST_QUIZ_UUID = 'set-last-quiz-uuid'
export const ON_LECTURE_VIDEO_PROGRESS = 'lecture-video-progress'
export const ON_PRACTICE_TERM_COMPLETE = 'practice-term-complete'
export const ON_TRACK_STUDENT_EVENT = 'on-track-student-event'
export const ON_TRACK_NPS_EVENT = 'on-track-nps-event'
export const ON_GET_STUDENT_COURSES = 'on-get-student-courses'
export const ON_MIDTERM_EXAM_COMPLETE = 'midterm-complete'
