export const skooliTutoringCourses = [
  'calculus',
  'calculus.plus',
  'calculus.ext',
  'precalculus',
  'precalculus.plus',
  'precalculus.ext',
  'collegealgebra',
  'collegealgebra.plus',
  'collegealgebra.ext',
  'statistics',
  'statistics.plus',
  'statistics.ext',
  'collegewriting-i',
  'collegewriting-i.plus',
  'collegewriting-ii',
  'collegewriting-ii.plus',
  'financialaccounting',
  'financialaccounting.plus',
  'financialaccounting.ext',
  'philosophy',
  'philosophy.plus'
]
