import api from '../api'
import config from '../config'
import { FREE_FORM } from '../Constants/questionType'
import { findAnswer } from '../utilities/questions'

export async function myScriptHandler () {
  if (!config.isMobileFlag) return

  const {
    review_mode: reviewMode,
    question: { question_type: questionType, Question_uuid: questionUUID, title }
  } = this.props
  const { studentData: { studentAnswers } } = this.context
  const fAns = findAnswer(questionUUID, studentAnswers)

  const isUnAnsweredMathEditor = questionType === FREE_FORM && !reviewMode && !fAns
  if (!isUnAnsweredMathEditor) return

  const registerCurrentQuestion = await api.setMyScriptData(questionUUID, { meta: { title } })
  const { data: { timestamp } = {} } = await api.getMyScriptData() || {}
  this.setState({ myScriptTimestamp: timestamp })

  if (registerCurrentQuestion?.status !== 200) return

  this.intervalId = setInterval(async () => {
    const currentTime = Math.floor((new Date()).getTime())
    const { myScriptTimestamp } = this.state
    const {
      data: {
        answer: mobileAnswer,
        timestamp: mobileAnswerTimestamp
      } = {}
    } = await api.getMyScriptData() || {}

    const isNewAnswerSubmitted = mobileAnswerTimestamp > myScriptTimestamp
    // Recent answer which was submitted within 5 seconds
    const isNewAnswerRecent = (currentTime - mobileAnswerTimestamp) < 5000

    const shouldUpdateAnswer = isNewAnswerSubmitted &&
      mobileAnswer &&
      isNewAnswerRecent

    if (!shouldUpdateAnswer) return

    const { current: mathType } = this.mathTypeRef
    mathType && mathType.setMathML(mobileAnswer)
  }, 5000)
}
