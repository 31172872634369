function Android () {
  return navigator.userAgent.match(/Android/i)
}

function BlackBerry () {
  return navigator.userAgent.match(/BlackBerry/i)
}

function iOS () {
  return navigator.userAgent.match(/iPhone|iPad|iPod/i)
}

function Opera () {
  return navigator.userAgent.match(/Opera Mini/i)
}

function Windows () {
  return navigator.userAgent.match(/IEMobile/i)
}

export function getBrowserNameAndVersion (userAgent) {
  let temp
  let M = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(M[1])) {
    temp = /\brv[ :]+(\d+)/g.exec(userAgent) || []
    return 'IE ' + (temp[1] || '')
  }
  if (M[1] === 'Chrome') {
    temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/)
    if (temp != null) return temp.slice(1).join(' ').replace('OPR', 'Opera')
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((temp = userAgent.match(/version\/(\d+)/i)) != null) M.splice(1, 1, temp[1])
  return M
}

export function getMobileDevice () {
  return (Android() || BlackBerry() || iOS() || Opera() || Windows())
}
