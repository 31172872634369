import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const Wrapper = styled.div`
  position: fixed;
  z-index: 10000;
  bottom: ${props => (props.scrolledToBottom
    ? `${props.distanceToBottom}px`
    : 0
  )};
  width:  100%;
  min-width: 375px;
  min-height: 88px;
  padding-right: 24px;
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
  align-items: center;
  background: #25272B;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  
  button {
    cursor: pointer;
    outline: none;
  }

  ${mediaqueries.tablet`
    flex-flow: ${props => props.isOnlyBtn ? 'row nowrap' : 'column nowrap'};
    align-items: center;
    justify-content: ${props => props.isOnlyBtn ? 'end' : 'center'};
    ${isQuiz => isQuiz && `flex-direction: column !important;`}
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 0;
  `}

  ${mediaqueries.mobile`
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  `}
`
export const Message = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;

  p {
    padding: 0px;
    margin: 0px;
    margin-right: 12px;
    ${props => props.isPracticeExercise && 
      `font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    `};
  }
`
export const RedoButton = styled.button`
  z-index: 10;
  width: 74px;
  height: 17px;
  padding: 0;
  margin-right: 24px;
  border: none;
  color: #5fc4b8;
  background-color: transparent;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
`
export const Icon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  display: inline-block;
  background-image: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: fit-content;
  position: relative;

  ${mediaqueries.tablet`
    ${props => !props.isOnlyBtn && 'width: 100%'};
    padding-right: 24px;
    padding-left: ${props => props.isOnlyBtn ? '0px' : '24px'};
  `}

  ${mediaqueries.mobile`
   ${isQuiz => !isQuiz && 'width: 100%;'}
    padding-left: 24px;
  `}
`
export const PrimaryButton = styled.button`
  display: block;
  width: 157px;
  height: 40px;
  margin-right: 12px;
  border-radius: 5px;
  border: none;
  background-color: #5fc4b8;
  color: black;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  z-index: 10;

  ${mediaqueries.tablet`
    margin-top: ${props => props.isQuiz ? '24px' :
      props.isOnlyBtn ? '0px' : '24px'};
    width: ${props => (props.isQuiz || props.isOnlyBtn) ? '157px' : '50%'};
  `}
  ${mediaqueries.mobile`
    width: ${props => props.isQuiz ? '157px' : props.isOnlyBtn ? '100%' : '50%'};
    margin-right: ${props => props.isQuiz ? '12px' : props.isOnlyBtn ? '0px' : '12px'};
    margin-top: 24px;
  `}
`
export const SecondaryButton = styled(PrimaryButton)`
  margin-right: 0px;
  border: 1px solid #5fc4b8;
  background-color: transparent;
  border-radius: 5px;
  color: #5fc4b8;
`
export const ConfettiWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  top: -250px;
  left: 40%;
  background-color: transparent;
  width: 100%;
  height: 300px;
  overflow-y: hidden;
  overflow-x: clip;

  ${mediaqueries.tablet`
    left: 0%;
    top: -215px;
  `}
`
