import styled from 'styled-components'
import { BREAKPOINTS } from '../../../mediaQueries'

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 261px;
  min-height: 98px;
  left: 186px;
  top: 52px;
  background: #25272b;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  @media (min-width: ${BREAKPOINTS.tablet}px) {
    position: absolute;
  }
`

export const ModalWrapper = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-left: 24px;
`

export const ModalHeader = styled.div`
  font-family: 'Lato';
  font-weight: 700;
  font-size: 16px;
  line-height: 19.2px;
  width: inherit;
  padding: 24px 0px 12px 24px;
  display: flex;
  justify-content: space-between;
`

export const ModalCrossIconSpan = styled.span`
  width: 14px;
  left: calc(50% - 14px / 2 + 99.5px);
  top: 0%;
  bottom: 26.32%;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 147px;
  cursor: pointer;
`

export const ModalCrossIcon = styled.img`
  height: 14px;
  width: 14px;
`

export const ModalContent = styled.div`
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`
