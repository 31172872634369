import { getChapterLockDates } from '../../../utilities/chapterUtils'
import { getCohortExamDateSecondsSinceEpoch } from '../../../utilities/courseUtils'

export const getExamExtensionLockDates = ({
  chapter,
  cohortData,
  examRetake,
  originalCohortDeadlines
}) => {
  const { cohortExamDates } = cohortData
  const {
    midTerm1EndTime,
    midTerm2EndTime,
    exam3EndTime,
    exam4EndTime,
    exam5EndTime,
    finalExamEndTime
  } = originalCohortDeadlines

  const originalCohortExamDates = {
    ...cohortExamDates,
    midTerm1EndDate: getCohortExamDateSecondsSinceEpoch(midTerm1EndTime),
    midTerm2EndDate: getCohortExamDateSecondsSinceEpoch(midTerm2EndTime),
    exam3EndDate: getCohortExamDateSecondsSinceEpoch(exam3EndTime),
    exam4EndDate: getCohortExamDateSecondsSinceEpoch(exam4EndTime),
    exam5EndDate: getCohortExamDateSecondsSinceEpoch(exam5EndTime),
    courseEndDate: getCohortExamDateSecondsSinceEpoch(finalExamEndTime)
  }

  const originalCohortData = {
    ...cohortData,
    cohortExamDates: originalCohortExamDates
  }

  const { lockDate } = getChapterLockDates(
    { chapter, cohortData, examRetake })

  const { lockDate: originalLockDate } = getChapterLockDates(
    { chapter, cohortData: originalCohortData, examRetake })

  return {
    lockDate,
    originalLockDate
  }
}

export const getAssignmentExtensionLockDates = ({
  assessmentID,
  cohortData,
  originalCohortDeadlines
}) => {
  const { cohortMilestones } = cohortData

  const assignmentData = cohortMilestones.find(assignment =>
    assignment.datoAssignmentUUID === assessmentID)
  const assignmentLockTime =
    getCohortExamDateSecondsSinceEpoch(assignmentData?.lockTime)

  const {
    cohortMilestones: originalCohortMilestones
  } = originalCohortDeadlines
  const originalAssignmentData = originalCohortMilestones.find(assignment =>
    assignment.id === assignmentData?.id)
  const originalAssignmentLockTime =
    getCohortExamDateSecondsSinceEpoch(originalAssignmentData?.lockTime)

  return {
    lockDate: assignmentLockTime,
    originalLockDate: originalAssignmentLockTime
  }
}
