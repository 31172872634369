import React, { useContext, useEffect, useMemo } from 'react'
import Header from '../Header'
import { Wrapper, Container, GlobalStyle } from '../styled'
import GradingScale from '../GradingScale'
import GradeCriteriaChart from '../GradeCriteriaChart'
import FAQ from '../FAQ'
import {
  GradingScaleWrapper,
  InstructorsWrapper,
  ScheduleSectionWrapper
} from './styled'
import GradeCriteriaText from './GradeCriteriaText'
import Instructors from '../Instructors'
import Context from '../../Context/Context'
import CourseAndMaterialSection from './CourseDescAndMaterialSection'
import CoursePolicies from '../CoursePolicies/CoursePolicies'
import LearningOutcomesSection from './LearningOutcomesSection'
import ScheduleSection from '../ScheduleSection'
import ScrollToTop from '../../ScrollToTop'
import { emitter } from '../../Emitter/Emitter'
import { ON_TRACK_STUDENT_EVENT } from '../../../Constants/emitterKeys'
import { MULTIPLE } from '../../../Constants/frequency'
import { SYLLABUS_VIEWED } from '../../../Constants/eventTypes'
import config from '../../../config'
import {
  getCourseSyllabusUrl
} from '../../../utilities/courseUtils'

const Syllabus = () => {
  const isCollegeSuccess = config.isCollegeSuccessCourse()
  const {
    cohortData: {
      officialCourseName,
      cohortID,
      duration,
      name: cohort,
      is39WeekCohort,
      isGGUCohort,
      startDate
    },
    courseData: {
      desc: courseDescription,
      syllabus,
      toolkitSyllabus,
      gradeExplanationText,
      learningOutcomes,
      coursePolicies,
      courseMaterialsOverview
    }
  } = useContext(Context)

  useEffect(() => {
    const eventData = {
      event: SYLLABUS_VIEWED,
      properties: {
        course: officialCourseName,
        cohort_length: duration,
        cohort,
        time_stamp: new Date().getTime()
      },
      frequency: MULTIPLE
    }

    emitter.emit(ON_TRACK_STUDENT_EVENT, eventData)
    // eslint-disable-next-line
  }, [cohortID])

  const syllabusLink = useMemo(() => {
    const syllabusLink = getCourseSyllabusUrl({
      syllabus,
      toolkitSyllabus,
      isGGUCohort,
      cohortDuration: duration,
      cohortStartDate: startDate
    })
    return syllabusLink
    // eslint-disable-next-line
  }, [duration, isGGUCohort, syllabus, toolkitSyllabus])

  const haveDynamicData = isGGUCohort || is39WeekCohort

  return (
    <>
      <GlobalStyle />
      <ScrollToTop />
      <Wrapper>
        <Container>
          <Header
            title='Syllabus'
            breadcrumb={{
              text: 'Course Toolkit',
              link: 'resources'
            }}
          />
          <CourseAndMaterialSection
            haveDynamicData={haveDynamicData}
            syllabusDownloadLink={syllabusLink}
            courseDescription={courseDescription}
            datoCourseMaterials={courseMaterialsOverview}
          />
          <LearningOutcomesSection
            haveDynamicData={haveDynamicData}
            courseName={officialCourseName}
            datoLearningOutcomes={learningOutcomes}
          />
          <CoursePolicies
            haveDynamicData={haveDynamicData}
            datoCoursePolicies={coursePolicies}
          />
          {!isCollegeSuccess && (
            <>
              <GradingScaleWrapper>
                <GradingScale subHeader='grading scale' />
              </GradingScaleWrapper>
              <GradeCriteriaChart subHeader='Grading Criteria' />
              <GradeCriteriaText
                haveDynamicData={haveDynamicData}
                datoGradeExplanationText={gradeExplanationText}
              />
            </>
          )}
          <InstructorsWrapper>
            <Instructors />
          </InstructorsWrapper>
          {!isCollegeSuccess && <ScheduleSectionWrapper>
            <ScheduleSection subHeader='Full Schedule' isSyllabusPage />
          </ScheduleSectionWrapper>}
          <FAQ />
        </Container>
      </Wrapper>
    </>
  )
}

export default Syllabus
