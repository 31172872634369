import React from 'react'
import Editor from './Editor'
import TextField from './TextField'
import Quizzes from './Quizzes'
import { isPopupEditor, isInlineEditor } from './utils'

export default React.forwardRef((props, ref) => {
  const { questionConfiguration: q } = props
  let Comp = TextField
  if (isPopupEditor(q)) {
    Comp = Quizzes
  } else if (isInlineEditor(q)) {
    Comp = Editor
  }
  return <Comp ref={ref} {...props} />
})
