import React, { Component } from 'react'
import Context from '../Context/Context'
import { emitter } from '../Emitter/Emitter'
import { ON_SET_CURRENT_CHAPTER } from '../../Constants/emitterKeys'
import { DEFAULT_DIFFICULTY_WARNING_LEVEL_1 } from '../../Constants/sectionTooltip'
import {
  ProgressMeter,
  ScoreWrapper,
  SectionScore,
  ScoreContainer,
  SectionTitle,
  SectionListItemWrapper
} from './styled'
import { history } from '../HistoryManager/HistoryManager'
import config from '../../config'
import CustomTooltip from '../Tooltip'
import util from '../../utilities'
import { getSectionProgressKey } from '../../utilities/contextKeys'
import { shouldLockSection } from '../../utilities/sectionUtils'

const DIFFICULTY_LEVELS = [1, 2]

class SectionListItem extends Component {
  displayQuizkPercentage (quizPer, find, progress) {
    if (!(find in quizPer)) return

    return <ScoreWrapper>Score: {Math.round(Number(quizPer[find])) + '%'}</ScoreWrapper>
  }

  displaySectionProgress (quizPer, sectionUUID, sectionProgress) {
    const { isStudentProgress } = config
    const quizExists = sectionUUID in quizPer
    if (!isStudentProgress && (quizExists || sectionProgress)) {
      // if a student has completed at least one quiz, set the progress bar for that section to be completely filled,
      if (quizExists) return { width: '100%' }
      return { width: Math.round(Number(sectionProgress)) + '%' }
    } else if (isStudentProgress && sectionProgress) {
      return { width: Math.round(Number(sectionProgress)) + '%' }
    } else {
      return { width: '0%' }
    }
  }

  handleClick = (e) => {
    const isLockedSection = shouldLockSection({
      ...this.context,
      ...this.props
    })
    if (isLockedSection) return

    emitter.emit(ON_SET_CURRENT_CHAPTER, this.props.chapter_uuid)
    history.push('/' + this.props.sectionItem.section_uuid)
  }

  render () {
    const {
      sectionItem,
      sectionListLoopId,
      chapterLoopId,
      chapterType,
      hideSectionNumber
    } = this.props
    const { difficultyLevel, warningText } = sectionItem
    const warningAtLevel1 = warningText || DEFAULT_DIFFICULTY_WARNING_LEVEL_1
    const warning = (difficultyLevel === 1) ? warningAtLevel1 : warningText
    const isLocked = shouldLockSection({
      ...this.context,
      ...this.props
    })
    const { isStudentProgress } = config
    const sectionProgressKey = getSectionProgressKey(chapterType)
    const {
      isStudioCohort,
      isAuditor,
      studentData,
      studentData: { minimumSectionProgress }
    } = this.context
    const { course: { use0BasedIndexing } } = config
    const sectionProgress = studentData[sectionProgressKey]
    let chapterIndex = use0BasedIndexing ? chapterLoopId - 1 : chapterLoopId
    chapterIndex = chapterIndex === undefined ? '' : chapterIndex
    const sectionIndex = use0BasedIndexing
      ? sectionListLoopId
      : sectionListLoopId + 1
    const title = hideSectionNumber ? sectionItem.title
      : `${chapterIndex.toString()}.${sectionIndex} ${sectionItem.title}`

    const isQuizScroreVisible = !isStudioCohort && !isAuditor
    return (
      <Context.Consumer>
        {(context) => (
          <React.Fragment>
            <SectionListItemWrapper
              className={`section-panel__list pointer ${isLocked ? 'disabled' : ''}`}
              tabIndex='0'
              onKeyPress={(e) => util.handleKeyPress(e, this.handleClick)}
              onClick={(e) => this.handleClick(e)}
            >
              <SectionTitle className='section-panel__list-text' data-testid='title'>
                {title}
              </SectionTitle>
              <SectionScore className='text-right section-panel__list-score'>
                <ScoreContainer>
                  {!context.isCohortEndedForStudent &&
                    <ProgressMeter className='progress-meter'>
                      <span
                        className='progress-meter__track bg-primary'
                        style={this.displaySectionProgress(
                          context.studentData['quiz-percentage'],
                          sectionItem.section_uuid,
                          isStudentProgress
                            ? minimumSectionProgress[sectionItem.section_uuid]
                            : sectionProgress[sectionItem.section_uuid])}
                      />
                    </ProgressMeter>
                  }
                  {isQuizScroreVisible && this.displayQuizkPercentage(
                    context.studentData['quiz-percentage'],
                    sectionItem.section_uuid,
                    isStudentProgress
                      ? minimumSectionProgress[sectionItem.section_uuid]
                      : sectionProgress[sectionItem.section_uuid])}
                </ScoreContainer>
                {
                  DIFFICULTY_LEVELS.includes(difficultyLevel) &&
                  <CustomTooltip
                    id='difficulty-tooltip'
                    placement='top'
                    text={warning}
                  >
                    <img
                      src='/images/icons/difficulty-icon.svg'
                      alt={`difficulty ${difficultyLevel}`}
                    />
                  </CustomTooltip>
                }
              </SectionScore>
            </SectionListItemWrapper>
          </React.Fragment>
        )}
      </Context.Consumer>
    )
  }
}

SectionListItem.contextType = Context
SectionListItem.displayName = 'SectionListItem'
export default SectionListItem
