import React, { useState, useEffect } from 'react'

import {
  ToastComponentsWrapper,
  ToastComponent,
  BBConfetti
} from './styled'

const FeedbackToast = ({
  showToast,
  delay = 5000,
  isCorrect
}) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const showTimeout = setTimeout(() => { setShow(true) }, 10)
    const hideTimeout = setTimeout(() => { setShow(false) }, delay)

    return () => {
      clearTimeout(showTimeout)
      clearTimeout(hideTimeout)
    }
  }, [showToast, delay])

  return (
    <ToastComponentsWrapper showToast={show}>
      <ToastComponent>
        <p>{isCorrect ? 'Correct!' : 'Good Try! Give it Another Go'}</p>
        {isCorrect && <BBConfetti />}
      </ToastComponent>
    </ToastComponentsWrapper>
  )
}

FeedbackToast.displayName = 'FeedbackToast'
export default FeedbackToast
