import React, { Component } from 'react'
import debounce from 'lodash/debounce'
import CourseButton from '../CourseButton/CourseButton'
import {
  NEXT_ICON,
  NEXT_ICON_DISABLED,
  NEXT_ICON_ENABLED
} from '../../Constants/progressBarIconSource'
import { Icon } from './styles'
import {
  isPracticeExam,
  trackStudentEvent
} from '../../utilities/chapterUtils'
import { FREE_FORM } from '../../Constants/questionType'

class QuestionNextButton extends Component {
  constructor (props) {
    super(props)
    this.handleNextButton = this.handleNextButton.bind(this)
    this.handleFinishButton = this.handleFinishButton.bind(this)
    this.onNextClicked = null
  }

  componentDidMount () {
    this.onNextClicked = debounce(() => this.handleNextButton(),
      1000,
      {
        maxWait: 1000,
        leading: true,
        trailing: false
      })
  }

  componentWillUnmount () {
    if (!this.onNextClicked) return
    this.onNextClicked = null
  }

  handleNextButton () {
    const {
      studentAnswerRes,
      setGuessworkCorrectAnswer,
      onNextButtonClick
    } = this.props

    setGuessworkCorrectAnswer('')
    onNextButtonClick(studentAnswerRes)

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  async handleFinishButton () {
    const {
      activeSectionUUID,
      studentAnswerRes,
      activeChildrenIndex,
      completeScreenData,
      duration,
      cohort,
      type_of_question: questionType,
      onFinishClick
    } = this.props

    if (isPracticeExam(activeSectionUUID)) {
      trackStudentEvent({
        questionType,
        duration,
        cohort,
        activeChildrenIndex,
        completeScreenData,
        isPracticeExamLastQuestion: true
      })
    }

    onFinishClick(studentAnswerRes)
  }

  render () {
    const {
      isLastQuestion,
      showfinish,
      isNextDisabled,
      onFinishClick,
      isNextBtnPrimary = true,
      showConfetti,
      isLoading = false,
      isAssessmentQuizExam,
      isALRedesign,
      question: { question_type: questionType },
      isEditorDisabled,
      isPractice
    } = this.props
    const isEditorMode = questionType === FREE_FORM && !isNextDisabled &&
    !isEditorDisabled && !isALRedesign

    const isPrimary = (isAssessmentQuizExam
      ? questionType === FREE_FORM
        ? isEditorDisabled
        : !isNextDisabled : isNextBtnPrimary) || isEditorMode

    const isALRedesignEditor =
        isEditorMode && isALRedesign && isNextDisabled && !isAssessmentQuizExam

    return (
      <CourseButton
        className={{
          'btn-primary': isPrimary,
          'btn-secondary': !isPrimary,
          'trigger-next': true
        }}
        onClick={showfinish ? this.handleFinishButton : e => this.onNextClicked(e)}
        disabled={isNextDisabled || isLoading}
        dataCypress={showfinish ? 'finishButton' : 'nextButton'}
        title={!(isLastQuestion && isPractice) && (showfinish
          ? (onFinishClick ? 'Finish.' : 'Finish')
          : (!isAssessmentQuizExam && isNextDisabled
            ? 'You can move to the next question once your answer is correct.'
            : 'Go to Next Question'))}
      >
        {isLoading
          ? <div><i className='fa fa-spinner fa-spin fa-margin' />
            <span>Loading</span></div>
          : (isLastQuestion && isPractice)
            ? (showConfetti ? 'Redo Set' : 'Try Again')
            : (showfinish ? 'Finish' : (isAssessmentQuizExam || isALRedesign)
              ? <Icon
                src={
                  isALRedesignEditor
                    ? NEXT_ICON_DISABLED
                    : !isNextDisabled ? NEXT_ICON : NEXT_ICON_ENABLED}
                alt='Next' />
              : 'Next')
        }

      </CourseButton>
    )
  }
}

export default QuestionNextButton
