import React, { useEffect, useRef, useState } from 'react'
import * as typeformEmbed from '@typeform/embed'
import qs from 'qs'
import '../../TypeForm/css/typeForm.css'
import {
  Container,
  Header,
  Content
} from '../styles'
import CourseButton from '../../CourseButton/CourseButton'
import { emitter } from '../../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../../Constants/emitterKeys'
import { StyledFooter } from './ProfCertGuardianPermissionForm.styled'
import {
  GUARDIAN_PERMISSION_PATH,
  PROF_CERT_GUARDIAN_PERMISSION_GRANTED
} from '../../../Constants'
import StudentSuccessLink from '../StudentSuccessLink'

const ProfCertGuardianPermissionForm = () => {
  const params = qs.parse(window.location.search.slice(1))
  const {
    requestDate,
    firstName: encodedFirstName,
    studentId,
    guardianTOSDate
  } = params

  const firstName = decodeURIComponent(encodedFirstName)
  const [isError, setIsError] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const formRef = useRef(null)
  const footerRef = useRef(null)

  let permissionGrantedInfo

  try {
    const storedValue = localStorage.getItem(PROF_CERT_GUARDIAN_PERMISSION_GRANTED)
    permissionGrantedInfo = storedValue ? JSON.parse(storedValue) : []
    if (!Array.isArray(permissionGrantedInfo)) permissionGrantedInfo = []
  } catch (error) {
    permissionGrantedInfo = []
    console.error('Error when getting PROF_CERT_GUARDIAN_PERMISSION_GRANTED: ', error.message)
  }

  const alreadyApproved = permissionGrantedInfo.some(info => {
    const {
      studentId: savedStudentId,
      requestDate: savedRequestDate
    } = info
    return studentId === savedStudentId && requestDate === savedRequestDate
  })

  useEffect(() => {
    if (isFormSubmitted && footerRef.current) {
      footerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isFormSubmitted])

  useEffect(() => {
    if (alreadyApproved || !formRef.current) return

    const embedForm = async () => {
      try {
        typeformEmbed.makeWidget(
          formRef.current,
          `https://form.typeform.com/to/SqWW7f7Y?student_id=${studentId}`,
          {
            transferableUrlParameters: [
              'student_id'
            ],
            onSubmit: () => {
              try {
                permissionGrantedInfo.push({ studentId, requestDate })
                localStorage.setItem(
                  PROF_CERT_GUARDIAN_PERMISSION_GRANTED,
                  JSON.stringify(permissionGrantedInfo)
                )
                setIsFormSubmitted(true)
              } catch (error) {
                console.error(
                  'Error when setting PROF_CERT_GUARDIAN_PERMISSION_GRANTED: ',
                  error.message
                )
              }
            }
          }
        )
      } catch (error) {
        localStorage.removeItem(PROF_CERT_GUARDIAN_PERMISSION_GRANTED)
        setIsError(error.message)
      }
    }

    embedForm()
    // eslint-disable-next-line
  }, [formRef])

  const handleOnNextClick = () => {
    emitter.emit(ON_NAVIGATE_TO, GUARDIAN_PERMISSION_PATH)
  }

  // Automatically move to the next page if the parent has already approved
  // the ggu certifications form but not the U18 permission from
  useEffect(() => {
    if (alreadyApproved && !isFormSubmitted && !guardianTOSDate) {
      handleOnNextClick()
    }
  }, [alreadyApproved, guardianTOSDate, isFormSubmitted])

  const doesBothGGUCertificationAndU18PermissionApproved = (
    alreadyApproved && !isFormSubmitted && guardianTOSDate
  )
  if (doesBothGGUCertificationAndU18PermissionApproved) {
    return (
      <Container>
        <div className='content center'>
          <Header>Permission already granted</Header>
          <Content>
            You've already granted your permission to enroll {firstName}.
            If you've any questions or if we can help you with anything else,
            please reach out to <StudentSuccessLink showProfCertGuardianPermission />
          </Content>
        </div>
      </Container>
    )
  }

  if (isError) {
    return (
      <p className='typeform-error'>
        The form didn't load correctly.
        Please refresh the page and try again.
        If the problem persists, reach out to us at
        <a href='mailto:success@outlier.org'> success@outlier.org.</a>
      </p>
    )
  }

  if (alreadyApproved && !isFormSubmitted) {
    return null
  }

  return (
    <div>
      <div id='embedded-typeform' ref={formRef} />
      {isFormSubmitted && <StyledFooter ref={footerRef}>
        <CourseButton
          className={{ 'btn-primary': true }}
          style={{ minWidth: '119px', lineHeight: '22px' }}
          onClick={handleOnNextClick}
        >
          I understand
        </CourseButton>
      </StyledFooter>}
    </div>
  )
}

export default ProfCertGuardianPermissionForm
