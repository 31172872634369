import React, { useContext } from 'react'
import CourseButton from '../CourseButton/CourseButton'
import LockedMarker from '../LockedMarker/LockedMarker'
import Context from '../Context/Context'
import { capitalizeFirstLetter } from '../../utilities'
import { emitter } from '../Emitter/Emitter'
import {
  ON_NAVIGATE_TO
} from '../../Constants/emitterKeys'
import { trackStudentEvent } from '../../utilities/chapterUtils'
import { ReadingButtonWrapper } from '../Readings/styled'

export default function SectionHomepageActiveLearningButton ({
  exercise,
  isCompleted,
  isUnlocked,
  completeScreenData,
  sectionUUID
}) {
  const {
    studentData,
    updateContext,
    isCohortEndedForStudent,
    cohortData: { name: cohort, duration }
  } = useContext(Context)
  if (!isUnlocked) return <LockedMarker />
  const { type, question_set_uuid: questionSetUuid } = exercise
  const captilalizedType = capitalizeFirstLetter(type)
  const handleClick = () => {
    emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/${questionSetUuid}`)
    window.outlierLog('Section start', captilalizedType)
    const questionType = exercise.type
    const isStartState = getButtonText() === 'Start'

    isStartState && trackStudentEvent({
      cohort,
      duration,
      questionType,
      completeScreenData
    })
    updateContext({ isActiveLearningButtonClicked: true })
  }
  const getButtonText = () => {
    if (isCohortEndedForStudent) return 'View'
    if (isCompleted) return 'Review'
    if (isContinue) return 'Continue'
    return 'Start'
  }

  const { studentAnswers } = studentData
  const { Question: questions } = exercise
  let isContinue = false
  if (questions && Array.isArray(questions)) {
    questions.forEach((question) => {
      const { Question_uuid: questionUuid } = question
      const isAnswerExist = studentAnswers.find(answer => answer.uuid === questionUuid)
      if (!isAnswerExist) return
      isContinue = true
    })
  }
  return (
    <ReadingButtonWrapper>
      <CourseButton
        className={{
          'btn-primary': !isCompleted && !isCohortEndedForStudent,
          'btn-secondary': !!isCompleted || isCohortEndedForStudent
        }}
        onClick={handleClick}
        dataUuid={sectionUUID + '/' + questionSetUuid}
      >
        {getButtonText()}
      </CourseButton>
    </ReadingButtonWrapper>
  )
}
