import styled from 'styled-components'

export const SectionListExerciseItemStyle = styled.div`
  cursor: pointer;
  position: relative;
  padding: 21px 12px 22px 12px;
    border-top: 2px solid #262a2c;
    ${props => props.isLastItem && `
        border-bottom: 2px solid #262a2c;
    `}
    overflow: hidden;
    background-color: #1a1e22;

    .section-panel__btn {
        text-align: right;
    }

    .badge {
        background: rgba(255,255,255,0.228);
        width: 20.13px;
        height: 20.13px;
        border-radius: 100%;
        margin-left: 11px;
        margin-right: 11px;
        display: inline-flex;
        align-items: center;
    }

    h3 {
        margin-bottom: 0px;
    }

    h3.panel-title {
        display: block;
    }

    .section-title.panel-title {
        text-transform: none;
    }
`

export const SectionListQuizScoreStyle = styled.span`
  font-size: 18px;
`

export const SectionProgressText = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #b1bfc5;
`

export const ActivitiesWrapper = styled.div`
  padding: ${({ isPracticeDrawer }) => isPracticeDrawer ? 0 : '0px 12px'};
  background-color: #0F0F0F;
`

export const ActivityWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${props => props.isLocked ? 'center' : 'flex-start'};
  padding: ${props => props.padding ?? '20px 0px'};
  p {
    margin: 0px;
  }
`

export const ToggleIcon = styled.img`
  cursor: pointer;
  transform: ${props => props.isAccordionOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: all 0.5s ease-in-out;
  background-color: white;
  mask: url(/images/icons/icon-angle-arrow-light.svg) no-repeat 50% 50%;
  width: 12px;
  height: 7px;
  margin-left: ${props => props.isLocked ? '15px' : '0px'};
`

export const SectionFirstLineWrapper = styled.div`
    line-height: 22px !important;
`

export const Dot = styled.span`
  background-color: #b1bfc5;
  width: 2px;
  height: 2px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 4px;
  text-align: center;
  border-radius: 2px;
`

export const Link = styled.a`
  display: flex;
  color: #5FC4B8 !important;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none !important;

  &:hover, &:active {
    color: #B8FFF7 !important;

    span {
      background-color: #B8FFF7;
    }
  }

  &:active {
    opacity: 0.3;
  }
`

const Icon = styled.span`
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-color: #5FC4B8;
`

export const ExternalLinkIcon = styled(Icon)`
  mask: url(/images/icons/box-arrow-up-right.svg) no-repeat 50% 50%;
  margin-left: 8px;
`
