import React, { Component } from 'react'
import $ from 'jquery'

import ShowSolution from '../ShowSolution/ShowSolution'
import QuestionComponent from '../QuestionComponent/QuestionComponent'
import { emitter } from '../Emitter/Emitter'
import Context from '../Context/Context'
import QuestionNextButton from '../QuestionComponent/QuestionNextButton'
import ReviewNextButton from '../QuestionComponent/ReviewNextButton'
import FreeformAuthoringDisplay from './FreeformAuthoringDisplay'
import CourseButton from '../CourseButton/CourseButton'
import MathType from '../MathType/MathType'
import StepsSolutionModal from '../StepsSolutionModal/StepsSolutionModal'
import MobileAppConnectionStatus from '../MobileAppConnectionStatus/MobileAppConnectionStatus'

import { display, getCorrectAnswer } from './utils/wiris'
import { isPopupEditor, cleanupPopupForDisplaying, isEmptyOrWhiteSpace } from '../MathType/utils'
import {
  ACTIVITY_TYPES
} from '../../Constants/sectionType'
import {
  ON_STUDENT_ANSWER,
  ON_SUBMIT_ANSWER
} from '../../Constants/emitterKeys'
import { findAnswer } from '../../utilities/questions'
import { getExplanation } from '../../utilities/courseUtils'
import { myScriptHandler } from '../../utilities/mobileAppUtils'
import ShowFeedback from '../ShowFeedBack/ShowFeedBack'
import {
  PaginationWrapper,
  QuestionCard,
  LessonText,
  MathEditor
} from './styles'
import { CORRECT, INCORRECT } from '../../Constants/result'
import api from '../../api'
import { BREAKPOINTS } from '../../mediaQueries'
import { debounce } from 'lodash'
import JDoodleCompiler from '../JDoodleCompiler/JDoodleCompiler'

class FreeformEquationQuestionGuesswork extends Component {
  constructor (props) {
    super(props)
    this.onShowSolution = this.onShowSolution.bind(this)
    this.showResult = this.showResult.bind(this)
    this.initializeWiris = this.initializeWiris.bind(this)
    this.onEditorChange = this.onEditorChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.getTriesPerQuestion = this.getTriesPerQuestion.bind(this)
    this.setModalContent = this.setModalContent.bind(this)
    this.showResultDebounced = debounce(this.showResult, 400)

    this.state = {
      tryAttempt: 0,
      result: '',
      solution: {},
      isNextDisabled: true,
      isEditorDisabled: false,
      isNextLoading: false,
      displayNext: 'none',
      displayCheck: 'block',
      triesPerQuestion: this.getTriesPerQuestion(),
      triesResult: [],
      quizzesEditor: null,
      studentAnswerRes: {},
      modalContent: '',
      isPinned: false,
      myScriptTimestamp: null
    }

    this.prevAnswer = null
    this.mathTypeRef = React.createRef()
    this.wrapperRef = React.createRef()
    this.myScriptHandler = myScriptHandler.bind(this)
    this.intervalId = null
  }

  async componentDidMount () {
    this.myScriptHandler()
    this.initialLastSectionQuiz = this.getLastSectionQuiz()
    this.lastSectionQuiz = this.initialLastSectionQuiz

    const filterQuestion = this.getFilteredQuestions()
    const {
      question: { Question_uuid: questionUUID }
    } = this.props
    if (filterQuestion && filterQuestion.Question_uuid !== questionUUID) return

    const {
      studentData: { studentAnswers, pinnedQuestions },
      reviewMode
    } = this.context
    if (!pinnedQuestions.length) {
      studentAnswers
        .forEach(studentAnswer => studentAnswer?.questionActivity?.pinned &&
          pinnedQuestions.push(studentAnswer.uuid))
    }
    this.setState({ isPinned: pinnedQuestions.includes(questionUUID) })
    const fAns = findAnswer(questionUUID, studentAnswers)
    const ans = fAns ? fAns.answer : ''
    if (fAns) this.setState({ result: '' }, () => this.setQuestionStates(fAns))

    await this.initializeWiris()

    if (reviewMode) this.onShowSolution()

    const { current: mathType } = this.mathTypeRef
    mathType && mathType.setMathML(ans)
  }

  async componentDidUpdate (prevProps, prevState) {
    this.lastSectionQuiz = this.getLastSectionQuiz()

    const {
      question: { Question_uuid: prevQuestionUUID },
      review_mode: prevReviewMode
    } = prevProps
    const {
      question: { Question_uuid: questionUUID },
      review_mode: reviewMode
    } = this.props
    const { current: mathType } = this.mathTypeRef
    const { studentData: { studentAnswers } } = this.context

    const fAns = findAnswer(questionUUID, studentAnswers)
    const ans = fAns?.answer || ''

    const updateEditorValue = () =>
      (questionUUID !== prevQuestionUUID || ans || reviewMode) &&
        mathType.setMathML(ans)

    updateEditorValue()

    if (prevQuestionUUID !== questionUUID) {
      await this.initializeWiris()
      clearInterval(this.intervalId)
      this.myScriptHandler()
    }

    if (reviewMode && (!prevReviewMode || prevQuestionUUID !== questionUUID)) {
      await this.onShowSolution()
    }

    if (fAns && (prevQuestionUUID !== questionUUID)) {
      this.setQuestionStates(fAns)
    } else if (this.lastSectionQuiz !== this.initialLastSectionQuiz) {
      window.location.reload()
    } else if (prevQuestionUUID !== questionUUID && !fAns) {
      this.setQuestionStates(fAns)
    }
    // Need to call this again to set after initialize wiris
    // The fields in wiris.js could be replaced while initializing
    updateEditorValue()

    const { isEditorDisabled } = this.state
    $('.wrs_focusElement, #studentAnswer input').attr('disabled', isEditorDisabled)
    if (prevQuestionUUID !== questionUUID) {
      const { studentData: { pinnedQuestions } } = this.context
      this.setState({ isPinned: pinnedQuestions.includes(questionUUID) })
    }
  }

  async componentWillUnmount () {
    clearInterval(this.intervalId)
    await api.setMyScriptData('nodata')
  }

  getLastSectionQuiz () {
    const { studentData: { 'quiz-section': quizSection } } = this.context
    return Object.keys(quizSection).sort().pop()
  }

  getFilteredQuestions () {
    const { currentQuestionSet, activeSectionUUID, question } = this.props
    const { studentData, course, navigateToActiveIndex } = this.context
    if (!navigateToActiveIndex) return question
    const lastActiveQuestionUUID = studentData['last-active-learning-uuid']
    const isActiveSectionUUID = lastActiveQuestionUUID[activeSectionUUID]
    const filterQuestion = isActiveSectionUUID &&
    currentQuestionSet.find(q => q.Question_uuid ===
      lastActiveQuestionUUID[activeSectionUUID][course['currentInstructor']])
    return filterQuestion
  }

  setQuestionStates (fAns) {
    const result = fAns?.correct ? CORRECT : INCORRECT
    this.setState({
      isNextDisabled: !fAns,
      result: fAns ? result : '',
      tryAttempt: 0,
      displayNext: fAns ? 'block' : 'none',
      displayCheck: fAns ? 'none' : 'block',
      triesPerQuestion: this.getTriesPerQuestion(),
      triesResult: fAns?.answer ? fAns.tries : []
    })
  }

  getTriesPerQuestion () {
    const {
      question: { tries_per_question: triesPerQuestion }
    } = this.props
    return triesPerQuestion
      ? parseInt(triesPerQuestion)
      : 1
  }

  async initializeWiris () {
    const {
      question: { Question_uuid: questionUUID, configuration }
    } = this.props
    const tabDeliveryElem = document.getElementById('tabdelivery')
    if (tabDeliveryElem) {
      tabDeliveryElem.className = ''
    }
    const { studentData: { studentAnswers } } = this.context

    const fAns = findAnswer(questionUUID, studentAnswers)
    const contentJSON = {
      text: '',
      feedback: '',
      question: configuration,
      gievnAnswer: fAns?.answer || ''
    }
    await display(contentJSON, (editor) => {
      this.setState({ quizzesEditor: editor || questionUUID })
    })
    if (window.innerWidth > BREAKPOINTS.mobile) {
      $('.wrs_focusElement').focus()
    }
  }

  onShowSolution () {
    const {
      review_mode: reviewMode,
      question: {
        Question_uuid: questionUUID,
        question_view_lesson: questionViewLessson, configuration }
    } = this.props

    const correctAnswer = getCorrectAnswer()
    const isPopup = isPopupEditor(configuration)
    const { studentData: { studentAnswers } } = this.context

    const fAns = findAnswer(questionUUID, studentAnswers)
    const studentAnswer = fAns?.answer || ''
    const questionResult = !!fAns?.correct

    this.setState({
      solution: {
        questionUUID,
        correctAnswer: isPopup
          ? cleanupPopupForDisplaying(correctAnswer) : correctAnswer,
        studentAnswer: isPopup
          ? cleanupPopupForDisplaying(studentAnswer) : studentAnswer,
        studentAnswerClass: questionResult
          ? 'radio__badge-correct' : 'radio__badge-incorrect',
        view_lesson: reviewMode
          ? questionViewLessson !== null
            ? questionViewLessson : null
          : null
      },
      showSolution: true
    })
  }

  getCorrectAnswer = () => {
    const { configuration } = this.props.question
    const correctAnswer = getCorrectAnswer()
    const isPopup = isPopupEditor(configuration)
    return isPopup ? cleanupPopupForDisplaying(correctAnswer) : correctAnswer
  }

  async showResult () {
    const {
      tryAttempt,
      triesResult,
      triesPerQuestion,
      isNextLoading
    } = this.state
    const { current: mathType } = this.mathTypeRef
    const notValidAnswer = isEmptyOrWhiteSpace(mathType.getMathML())

    if (notValidAnswer) {
      isNextLoading && this.setState({ isNextLoading: false })
      return
    }

    this.setState({ isNextDisabled: true })
    window.outlierLog('Check Answer', mathType.getMathML())

    const questionResult = await mathType.asyncCheckAnswer()
    const { setGuessworkCorrectAnswer } = this.props
    setGuessworkCorrectAnswer(this.getCorrectAnswer())
    var finalTryAttempt = tryAttempt + 1
    const tryEl = document.getElementById('tries-' + (finalTryAttempt - 1))
    if (questionResult) {
      if (tryEl) tryEl.className = 'radio__badge radio__badge-correct'
      this.setQuestionResult(true)
    } else {
      if (finalTryAttempt === triesPerQuestion) {
        this.setQuestionResult(false)
      } else {
        this.setState({
          result: '',
          tryAttempt: finalTryAttempt,
          isNextDisabled: true
        })
        triesResult.push(false)
        mathType.setMathML('')
      }
      if (tryEl) tryEl.className = 'radio__badge radio__badge-incorrect'
    }
  }

  async setQuestionResult (questionResult) {
    const {
      updateOnCheck,
      question: { Question_uuid: questionUUID },
      type_of_question: typeOfQuestion,
      review_mode: reviewMode
    } = this.props
    const { triesResult, tryAttempt } = this.state
    const { current: mathType } = this.mathTypeRef
    const reChecQuestionResult = await mathType.asyncCheckAnswer()
    if (reChecQuestionResult !== questionResult) this.showResult()
    const answerString = mathType.getMathML()

    triesResult.push(questionResult)

    const testResult = {}
    testResult.uuid = questionUUID
    testResult.answer = answerString
    testResult.correct = questionResult
    testResult.tries = triesResult
    testResult.type = ACTIVITY_TYPES[typeOfQuestion]

    this.setState({
      result: questionResult ? CORRECT : INCORRECT,
      isEditorDisabled: true,
      tryAttempt: questionResult ? 0 : tryAttempt + 1,
      isNextDisabled: false,
      displayCheck: 'none',
      displayNext: 'block'
    })
    if (!reviewMode) {
      emitter.emit(ON_STUDENT_ANSWER, { ...testResult, isExam: false })
      this.setState({ studentAnswerRes: testResult })
      updateOnCheck(testResult)
    }
    window.outlierLog(
      questionResult ? 'Correct Answer' : 'Incorrect Answer', answerString
    )
  }

  // Should return false if the action was not allowed.
  // Component uses Wiris entry, so we must ignore arrow keys which are used to
  // move around within that input field.
  handleKeyPress (type) {
    const {
      isNextDisabled,
      displayNext,
      displayCheck,
      studentAnswerRes
    } = this.state
    const {
      onNextButtonClick,
      onReviewClickNext,
      review_mode: reviewMode
    } = this.props
    // Typically from pressing the left arrow, allow it and ignore it.
    if (type && type === 'previous') return true

    // Typically from pressing the right arrow, allow it and ignore it.
    if (type && type === 'advance') return true

    // Everything else is interpreted as next or advance forward
    if (reviewMode) {
      onReviewClickNext()
      return true
    } else {
      if (!isNextDisabled) {
        if (displayNext === 'block') {
          onNextButtonClick(studentAnswerRes)
          return true
        } else if (displayCheck === 'block') {
          this.showResult()
          return true
        }
      }
    }

    // Unrecognized, default is don't allow it.
    return false
  }

  setModalContent (modalContent) {
    this.setState({ modalContent })
  }

  onEditorChange (answer) {
    if (answer === this.prevAnswer) return
    this.prevAnswer = answer

    const {
      question: { Question_uuid: questionUUID },
      review_mode: reviewMode
    } = this.props
    const { studentData: { studentAnswers } } = this.context
    const fAns = findAnswer(questionUUID, studentAnswers)
    const isEditorDisabled = reviewMode || (!!(fAns && fAns.answer))

    this.setState({
      isEditorDisabled,
      isNextDisabled: !isEditorDisabled && !answer,
      displayNext: isEditorDisabled ? 'block' : 'none',
      displayCheck: isEditorDisabled ? 'none' : 'block'
    })
  }

  onPinClick (currentQuestionUUID) {
    const {
      review_mode: reviewMode,
      type_of_question: typeOfQuestion
    } = this.props

    const {
      studentData,
      updateContext
    } = this.context
    const { pinnedQuestions, studentAnswers } = studentData
    const { isPinned } = this.state
    const currentIndex = pinnedQuestions.indexOf(currentQuestionUUID)
    const fAnswer = findAnswer(currentQuestionUUID, studentAnswers)
    // condition to check answer exists in review mode before pinning
    const isAttemptedQuestion = typeof (fAnswer?.correct) === 'boolean'
    const answer = {
      uuid: currentQuestionUUID,
      type: typeOfQuestion.toLocaleLowerCase(),
      ...(reviewMode && isAttemptedQuestion && {
        answer: fAnswer.answer,
        correct: fAnswer.correct,
        tries: fAnswer.tries
      }),
      questionActivity: { pinned: false }
    }
    if (isPinned) {
      pinnedQuestions.splice(currentIndex, 1)
      emitter.emit(ON_SUBMIT_ANSWER, answer)
    } else {
      answer.questionActivity.pinned = true
      pinnedQuestions.push(currentQuestionUUID)
      emitter.emit(ON_SUBMIT_ANSWER, answer)
    }
    studentData.pinnedQuestions = pinnedQuestions
    updateContext({ studentData })
    this.setState({ isPinned: !isPinned })
  }

  render () {
    const { question } = this.props
    if (!question) return <div className='text-center'>Loading...</div>

    const {
      question: {
        general_explanation: generalExplanation,
        specific_explanation: specificExplanation,
        additionalConfigurations,
        configuration
      },
      type_of_question: typeOfQuestion,
      lesson_text: lessonText,
      structuredText,
      question_text: questionText,
      display_illustration: displayIllustration,
      review_mode: reviewMode,
      desmosgraph,
      gMath,
      showPrevious,
      filterMessage,
      correctProps,
      showfinish,
      isFirstQuestion,
      incorrectProps
    } = this.props
    const {
      quizzesEditor,
      displayCheck,
      isNextDisabled,
      isEditorDisabled,
      displayNext,
      showSolution,
      solution,
      result,
      studentAnswerRes,
      isNextLoading,
      modalContent
    } = this.state
    const {
      isStudentProgressBusy, isSidePanelOpen
    } = this.context
    const {
      Question_uuid: questionUUID,
      jdoodleEditor,
      starterCode
    } = question
    const { questionUUID: solutionQuestionUUID } = solution
    return (
      <React.Fragment>
        <StepsSolutionModal modalContent={modalContent} setModalContent={this.setModalContent} />
        <FreeformAuthoringDisplay />
        <div id='delivery' style={{ display: 'none' }}>
          <QuestionCard
            id='questiontext'
            className='active__learning-block component__backdrop mb-2'
            reviewMode={reviewMode}
          >
            {structuredText}
            {lessonText ? (
              <React.Fragment>
                <LessonText
                  className='pb-3 font__md'
                  isSidePanelOpen={isSidePanelOpen}
                >
                  {lessonText}
                </LessonText>
                <hr className='active__learning-hr' />
              </React.Fragment>
            ) : null}
            <div className='row align-items-start pt-4'>
              {displayIllustration}
              {/* --- Added Desmos Here --- */}
              {desmosgraph}
              {questionText ? (
                <div className='col que-text'>
                  <div className='font__md'>{questionText}</div>
                </div>
              ) : null}
            </div>
            {gMath}
            {jdoodleEditor &&
            <div className='mb-2'>
              <JDoodleCompiler
                key={questionUUID}
                questionUUID={questionUUID}
                starterCode={starterCode}
                isPartOfQuestion
              />
            </div>}
            <div>
              <MathEditor
                className='mx-auto row no-gutters position-relative'
                isSidePanelOpen={isSidePanelOpen}
              >
                <MathType ref={this.mathTypeRef} onChange={this.onEditorChange}
                  questionConfiguration={configuration} quizzesEditor={quizzesEditor}
                  additionalConfigurations={additionalConfigurations}
                />
                {isEditorDisabled && (
                  <div
                    id='studentAnswerDisabled'
                    className='studentAnswerOverlay'
                  />
                )}
              </MathEditor>
              <MobileAppConnectionStatus />
            </div>
            {showSolution && questionUUID === solutionQuestionUUID && (
              <ShowSolution solutionData={solution}
                filterMessage={filterMessage} correctProps={correctProps}
                incorrectProps={incorrectProps}
                explanation={getExplanation(specificExplanation, generalExplanation)}
              />
            )}
            {!showSolution && result &&
            quizzesEditor &&
            <ShowFeedback
              guessWorkCorrectAnswer={this.getCorrectAnswer()}
              sectionType={typeOfQuestion}
              result={result}
              studentAnswer={studentAnswerRes?.answer}
              correctProps={correctProps}
              incorrectProps={incorrectProps} />}
          </QuestionCard>
          <PaginationWrapper
            isFirstQuestion={isFirstQuestion}
            showPrevious={showPrevious}
            reviewMode={reviewMode}
            showfinish={showfinish}
          >
            {(!reviewMode) && (
              <CourseButton
                id='test'
                name='test'
                {
                ...(!isStudentProgressBusy && {
                  style: { display: displayCheck }
                })
                }
                className={{
                  'btn-primary': true,
                  'btn-secondary': false,
                  'mx-1': true,
                  check_answer: true
                }}
                onClick={!isStudentProgressBusy ? this.showResult : undefined}
                disabled={isStudentProgressBusy ? true : isNextDisabled}
                dataCypress='checkOrSubmitButton'
              >
                {isStudentProgressBusy
                  ? <div><i className='fa fa-spinner fa-spin fa-margin' /> <span>Saving</span></div>
                  : <span>Submit</span> }
              </CourseButton>
            )}
            {(displayNext === 'block') &&
              !reviewMode && !isStudentProgressBusy && !showfinish && (
              <QuestionNextButton
                {...this.props}
                isLoading={isNextLoading}
                isNextDisabled={isNextDisabled}
                studentAnswerRes={studentAnswerRes}
              />
            )}
            {reviewMode && (
              <ReviewNextButton {...this.props} />
            )}

            <strong style={{ display: 'none' }}>
              <span id='feedback' />
            </strong>
          </PaginationWrapper>

          {/* <!-- The custom feedback will be inserted here in place of the empty span element. --> */}
          <div id='customfeedback' style={{ display: 'none' }}>
            <span />
          </div>
          {/* <!-- The answer feedback component will be inserted here in place of the empty span element. --> */}
          <div id='answerfeedback' style={{ display: 'none' }}>
            <span />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

FreeformEquationQuestionGuesswork.contextType = Context
FreeformEquationQuestionGuesswork.displayName = 'FreeformEquationQuestionGuesswork'
export default QuestionComponent(FreeformEquationQuestionGuesswork)
