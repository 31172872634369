import styled from 'styled-components'

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;

  div {
    position: relative;
  }

  div img {
    position: absolute;
    top: calc(50% - 8px);
    width: 16px;
    height: 16px;
    right: 16px;
    cursor: pointer;
  }
`
export const InputWarningText = styled.span`
  display: inline-block;
  margin-bottom: 8px;
  color: #FF8064;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`
export const Input = styled.input`
  width: 100%;
  max-width: ${props => props.width ? `${props.width}px` : '400px'};
  max-height: ${props => props.height ? `${props.height}px` : '56px'};
  border-radius: 5px;
  outline: none!important;
  background:  #0D0D0F;
  padding: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #FFF;

  border: 1px solid ${props => props.error ? '#FF8064!important' : '#5B6067'};

  ::placeholder {
    color: #5B6067!important;
  }

  :hover:not(:disabled) {
    border: 1px solid #5FC4B8;
  }

  :focus:not(:disabled) {
    border: 1px solid #FFF;
  }

  :disabled {
    filter: highlight(0.5);
    color: #5B6067!important;
  }
`
export const InputFooterText = styled.span`
  display: inline-block;
  margin-top: 8px;
  color: #98A4AE;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`
