import React, { Component } from 'react'
import CourseButton from '../CourseButton/CourseButton'
import { PREVIOUS_ICON } from '../../Constants/progressBarIconSource'
import { Icon } from './styles'

class QuestionPreviousButton extends Component {
  render () {
    const {
      onPreviousButtonClick,
      isAssessmentQuizExam,
      isALRedesign,
      isLoading = false
    } = this.props
    return (
      <React.Fragment>
        {this.props.showPrevious &&
        <CourseButton
          className={{
            'btn-secondary': true
          }}
          data-testid='btn-previous'
          onClick={onPreviousButtonClick}
          disabled={isLoading}
          dataCypress='previousButton'
          title='Go to Previous Question'
        >
          {(isAssessmentQuizExam || isALRedesign)
            ? <Icon src={PREVIOUS_ICON} alt='Previos' />
            : 'Previous'}
        </CourseButton>}
      </React.Fragment>
    )
  }
}

export default QuestionPreviousButton
