import styled from 'styled-components'

export const StyledPartWrapper = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
  margin-bottom: 48px;
  margin-top: 48px;
`

export const SpecialTopic = styled.span`
  font-size: 24px;
  font-weight: 300;
  padding-top: 29px;
  padding-bottom: 48px;
`
