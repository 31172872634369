import React, { useState, useEffect, useContext, useMemo, useRef } from 'react'
import { uniq } from 'lodash'
import Context from '../Context/Context'
import BreadCrumbComponent from '../BreadCrumbComponent/BreadCrumbComponent'
import Announcement from './Announcement'
import { announcements as mockAnnouncements } from './mockData'
import {
  ContentWrapper,
  Header,
  Title,
  CheckAllIcon,
  MarkAllAsRead,
  AnnouncementsContainer
} from './styles'

const AnnouncementsPage = () => {
  const [seenAnnouncements, setSeenAnnouncements] = useState([])
  const {
    seenAnnouncementsFromContext,
    selectedAnnouncementId,
    updateContext
  } = useContext(Context)
  const headerRef = useRef(null)

  useEffect(() => {
    updateContext({
      isAnnouncements: true
    })

    return () => {
      updateContext({
        isAnnouncements: false,
        selectedAnnouncementId: null
      })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleNotificationSelect()
    // eslint-disable-next-line
  }, [selectedAnnouncementId])

  useEffect(() => {
    setSeenAnnouncements(preVal => (
      uniq([
        ...(seenAnnouncementsFromContext || []),
        ...preVal
      ]))
    )
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    updateContext({
      seenAnnouncementsFromContext: uniq(
        [
          ...(seenAnnouncementsFromContext || []),
          ...seenAnnouncements]
      )
    })
    // eslint-disable-next-line
  }, [seenAnnouncements])

  const handleNotificationSelect = () => {
    if (!selectedAnnouncementId) return
    setSeenAnnouncements(preVal => uniq([...preVal, selectedAnnouncementId]))
    const announcement = document.getElementById(`announcement-${selectedAnnouncementId}`)
    if (!announcement) return
    window.scrollTo({
      top: announcement.offsetTop - headerRef.current.offsetHeight,
      behavior: 'smooth'
    })
  }

  const announcements = useMemo(() => {
    return mockAnnouncements.map(announcement => {
      if (seenAnnouncements.includes(announcement.id)) {
        return { ...announcement, seen: true }
      }
      return announcement
    })
  }, [seenAnnouncements])

  const shouldMarkAllDisable = useMemo(() => {
    return announcements.every(announcement => announcement.seen)
  }, [announcements])

  const onMarkAllAsRead = () => {
    if (shouldMarkAllDisable) return
    setSeenAnnouncements(mockAnnouncements.map(
      announcement => announcement.id
    ))
  }

  return (
    <>
      <BreadCrumbComponent
        isAnnouncements
        className='announcements-breadcrumb'
      />
      <ContentWrapper>
        <Header ref={headerRef}>
          <Title>Announcements</Title>
          <MarkAllAsRead
            data-testid='mark-all-as-read'
            onClick={onMarkAllAsRead}
            disabled={shouldMarkAllDisable}
          >
            <CheckAllIcon />
            <span>Mark all as read</span>
          </MarkAllAsRead>
        </Header>
        <AnnouncementsContainer>
          {announcements.map((announcement, index) => (
            <Announcement
              key={index}
              announcement={announcement}
              setSeenAnnouncements={setSeenAnnouncements}
              selectedAnnouncementId={selectedAnnouncementId}
            />
          ))}
        </AnnouncementsContainer>
      </ContentWrapper>
    </>
  )
}

AnnouncementsPage.displayName = 'Announcements'
export default AnnouncementsPage
