import React from 'react'
import { NotificationAction } from '../style'

function NotificationActionButtons ({ actions }) {
  if (!actions?.length) return null

  return (
    <>
      {actions.map(action => (
        <NotificationAction
          key={`${action.actionText}-${action.websiteUrl}`}
        >
          {action.actionText}
        </NotificationAction>
      ))}
    </>
  )
}

export default NotificationActionButtons
