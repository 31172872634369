import styled from 'styled-components'

export const ToastComponentsWrapper = styled.div`
  position: fixed;
  top: ${props => props.showToast ? '76px' : '-76px'};
  opacity: ${props => props.showToast ? '1' : '0'};
  left: 0;
  width: 100%;
  height: fit-content;
  z-index: 8;
  transition: all 0.3s ease-in-out;
`
export const ToastComponent = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  margin: 0 auto;
  background: #25272B;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 24px 12px;

  p {
    margin: 0px;
    padding: 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #FFFFFF;
  }
`
export const BBConfetti = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(/images/icons/icon-bb-confetti.svg);
`
