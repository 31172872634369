import React, { useContext } from 'react'
import { Collapse } from 'reactstrap'
import groupBy from 'lodash/groupBy'
import { emitter } from '../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'
import CourseButton from '../CourseButton/CourseButton'
import LockedMarker from '../LockedMarker/LockedMarker'
import Context from '../Context/Context'
import { ReadingButtonWrapper } from '../Readings/styled'

import { ActivitiesWrapper, ActivityWrapper } from './styled'
import {
  Content,
  Left,
  Right,
  Time,
  Title
} from '../LecturesExerciseItem/styled'
import {
  getChapterActiveLearningThemeName,
  trackStudentEvent
} from '../../utilities/chapterUtils'
import { getPlural } from '../../utilities/textUtils'
import { ACTIVE_LEARNING } from '../../Constants/sectionType'

const ActiveLearningSectionItem = props => {
  const {
    answerUUIDs = [],
    excercise = {},
    questionSetLock,
    incorrectAnswerUUIDs
  } = props

  const questionList = excercise.Question ?? []
  const context = useContext(Context)
  const unansweredQuestionList = questionList.filter(q => !answerUUIDs.includes(q.Question_uuid))
  const allQuestions = groupBy(questionList, x => x.instructor?.['theme_name'] || '')
  const unansweredQuestions = groupBy(unansweredQuestionList, x => x.instructor?.['theme_name'] || '')
  const {
    currentChapter: { type: chapterType },
    cohortData: { name: cohort, duration }
  } = context

  const handleClick = (id, themeName, buttonText) => {
    const {
      sectionUUID,
      excercise: { type: questionType, question_set_uuid: questionSetUuid },
      completeScreenData
    } = props
    context.updateContext({
      currentActiveLearningTheme: themeName,
      isActiveLearningButtonClicked: true
    })
    localStorage.setItem('last_viewed_theme', themeName)
    const isStartState = buttonText === 'Start'
    const isActiveLearning = questionType === ACTIVE_LEARNING

    isStartState && trackStudentEvent({
      cohort,
      duration,
      questionType,
      completeScreenData,
      themeName
    })
    if (isActiveLearning) {
      return emitter.emit(ON_NAVIGATE_TO,
        `/${sectionUUID}/${questionSetUuid}`)
    }
    emitter.emit(ON_NAVIGATE_TO, '/' + sectionUUID + '/' + id)
  }

  const getButtonMeta = ({ questionCount, answerCount, hasIncorrectAnswer }) => {
    let buttonText = 'Start'
    let buttonClass = 'btn btn-primary'

    if (answerCount > 0) buttonText = 'Continue'
    if (questionCount === answerCount && !hasIncorrectAnswer) {
      buttonText = 'Review'
      buttonClass = 'btn btn-secondary'
    }

    return ({ buttonText, buttonClass })
  }

  const getTargetQuestionId = (unAnsweredCount, themeName) =>
    (unAnsweredCount
      ? unansweredQuestions
      : allQuestions
    )[themeName][0]['Question_uuid']

  const getStatusText = (unAnsweredCount, answerCount) =>
    unAnsweredCount
      ? `${unAnsweredCount} ${getPlural('card', unAnsweredCount)}${answerCount > 0 ? ' remaining' : ''}`
      : 'Completed'

  return (
    <>
      <Collapse isOpen={props.isAccordionOpen}>
        <ActivitiesWrapper>
          {Object.keys(allQuestions).map((themeName, index) => {
            const hasIncorrectAnswer = incorrectAnswerUUIDs.some(uuid =>
              allQuestions[themeName].some(q => q.Question_uuid === uuid)
            )
            const questionCount = allQuestions[themeName].length
            const unAnsweredCount = unansweredQuestions[themeName]?.length ?? 0
            const answerCount = questionCount - unAnsweredCount
            const {
              buttonText,
              buttonClass
            } = getButtonMeta({ questionCount, answerCount, hasIncorrectAnswer })

            const questionId = getTargetQuestionId(unAnsweredCount, themeName)
            const statusText = getStatusText(unAnsweredCount, answerCount)
            const _themeName = getChapterActiveLearningThemeName({ type: chapterType }, themeName)

            return (
              <ActivityWrapper key={index} padding={'24px 0px'}>
                <Left>
                  <Content>
                    <Title>
                      {_themeName}
                    </Title>
                    <Time data-testid='questionProgress'>{statusText}</Time>
                  </Content>
                </Left>
                <Right>
                  {questionSetLock
                    ? <LockedMarker />
                    : <ReadingButtonWrapper>
                      <CourseButton
                        className={buttonClass}
                        onClick={() => handleClick(questionId, themeName, buttonText)}
                        style={{ width: '120px' }}
                        dataCypress={themeName}
                      >
                        {buttonText}
                      </CourseButton>
                    </ReadingButtonWrapper>
                  }
                </Right>
              </ActivityWrapper>
            )
          })}
        </ActivitiesWrapper>
      </Collapse>
    </>
  )
}

export default ActiveLearningSectionItem
