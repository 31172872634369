import React, { Component } from 'react'
import ModalComponent from '../ModalComponent/ModalComponent'

class MobileNotificationModal extends Component {
  constructor (props) {
    super(props)
    this.setModalShow = this.setModalShow.bind(this)
    this.state = {
      modalShow: false
    }
  }

  componentDidMount () {
    let isMobileNotificationEnabled =
      localStorage.getItem('isMobileNotificationEnabled')
    if (!isMobileNotificationEnabled) {
      localStorage.setItem('isMobileNotificationEnabled', true)
      isMobileNotificationEnabled = 'true'
    }
    this.setState({ modalShow: isMobileNotificationEnabled === 'true' })
  }

  setModalShow (modalShow) {
    this.setState({ modalShow })
    localStorage.setItem('isMobileNotificationEnabled', modalShow)
  }

  render () {
    const { modalShow } = this.state
    return (
      <>
        <ModalComponent
          className='mobile-modal'
          show={modalShow}
          handleClose={() => this.setModalShow(false)}
        >
          <span className='p-3'>
            For the best educational experience,
            please open Outlier.org on a desktop or laptop.
          </span>
        </ModalComponent>
      </>
    )
  }
}
export default MobileNotificationModal
