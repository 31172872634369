import React from 'react'
import DOMPurify from 'dompurify'
import { SubHeader } from '../styled'
import { getCourseMaterialsTextFromDato } from '../Utils'
import {
  CourseDescAndMaterialText,
  CourseDescAndMaterialWrapper,
  DownloadSyllabusButton
} from './styled'

const CourseAndMaterialSection = ({
  haveDynamicData,
  courseDescription,
  syllabusDownloadLink,
  datoCourseMaterials
}) => {
  const courseMaterialsText = haveDynamicData
    ? getCourseMaterialsTextFromDato(datoCourseMaterials)
    : `
      This is an online course. All course materials (including lecture
      videos, interactive textbook, and practice exercises) and additional
      tools are provided here on this course site.
    `

  return (
    <CourseDescAndMaterialWrapper>
      <div>
        <SubHeader>Course Description</SubHeader>
        <CourseDescAndMaterialText>
          {courseDescription}
        </CourseDescAndMaterialText>
      </div>
      <div>
        <SubHeader>Course Materials</SubHeader>
        <CourseDescAndMaterialText
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(courseMaterialsText)
          }}
        />
        {syllabusDownloadLink && (
          <DownloadSyllabusButton
            onClick={() => window.open(syllabusDownloadLink, '_blank')}
          >
            download syllabus
          </DownloadSyllabusButton>
        )}
      </div>
    </CourseDescAndMaterialWrapper>
  )
}

export default CourseAndMaterialSection
