import { cloneDeep } from 'lodash'

import { createSectionList } from '../CourseActivities/CourseActivitiesUtils'
import { getLatestCohort } from '../../../utilities/courseUtils'
import { GREY, YELLOW, RED, YELLOW_MESSAGE_CARD } from '../checklistColors'
import { ASSIGNMENT, EXAM } from '../../../Constants/sectionType'

export const getAssessments = (course) => {
  const { activeAssessments } = course
  const hasActiveAssignment = activeAssessments.some(
    ({ type }) => type === ASSIGNMENT
  )
  const hasActiveExam = activeAssessments.some(({ type }) => type === EXAM)
  const assessments = []
  if (hasActiveAssignment) {
    const activeAssignments = activeAssessments.filter(
      ({ type }) => type === ASSIGNMENT
    )
    const {
      assessmentWarningColor,
      backgroundColor
    } = getAssessmentWarningColor(activeAssignments)
    let cardTitle = 'The assignment is open!'
    let endDateTime = `Closes: ${activeAssignments[0].endDateTime}`
    let cardDescription = ''
    let endDateTimeAssessments = []
    let extendDeadlineLink = activeAssignments[0].extendDeadlineLink
    if (activeAssignments[0].finalAssignment) {
      cardTitle = 'The essay is open!'
      if (assessmentWarningColor === RED ||
        assessmentWarningColor === YELLOW_MESSAGE_CARD) {
        cardTitle = 'Essay closing soon!'
      }
    } else if (assessmentWarningColor === RED ||
      assessmentWarningColor === YELLOW_MESSAGE_CARD) {
      cardTitle = 'Assignment closing soon!'
    }
    if (activeAssignments.length > 1) {
      cardTitle = `${activeAssignments.length} Assignments are open!`
      endDateTime = ''
      cardDescription = 'Closing dates:'
      endDateTimeAssessments = activeAssignments
      extendDeadlineLink = ''
    }
    assessments.push({
      cardTitle,
      cardDescription,
      endDateTime,
      endDateTimeAssessments,
      assessmentWarningColor,
      backgroundColor,
      extendDeadlineLink
    })
  }

  if (hasActiveExam) {
    const activeExams = activeAssessments.filter(({ type }) => type === EXAM)
    const {
      assessmentWarningColor,
      backgroundColor
    } = getAssessmentWarningColor(activeExams)
    let cardTitle = 'The exam is open!'
    let endDateTime = `Closes: ${activeExams[0].endDateTime}`
    let cardDescription =
      activeExams[0].coverTitle && `Covers: ${activeExams[0].coverTitle}`
    let endDateTimeAssessments = []
    let extendDeadlineLink = activeExams[0].extendDeadlineLink
    if (assessmentWarningColor === RED ||
      assessmentWarningColor === YELLOW_MESSAGE_CARD) {
      cardTitle = 'Exam closing soon!'
    }
    if (activeExams.length > 1) {
      cardTitle = `${activeExams.length} Exams are open!`
      cardDescription = 'Closing dates:'
      endDateTime = ''
      endDateTimeAssessments = activeExams
      extendDeadlineLink = ''
    }
    assessments.push({
      cardTitle,
      cardDescription,
      endDateTime,
      endDateTimeAssessments,
      assessmentWarningColor,
      backgroundColor,
      extendDeadlineLink
    })
  }

  return assessments
}

const getAssessmentWarningColor = (activeAssessments) => {
  const hasActiveAssessmentRedColor = activeAssessments.some(
    ({ assessmentColor }) => assessmentColor === RED
  )
  if (hasActiveAssessmentRedColor) {
    return { assessmentWarningColor: RED, backgroundColor: '#2E141C' }
  }

  const hasActiveAssessmentYellowColor = activeAssessments.some(
    ({ assessmentColor }) => assessmentColor === YELLOW
  )
  if (hasActiveAssessmentYellowColor) {
    return { assessmentWarningColor: YELLOW_MESSAGE_CARD, backgroundColor: '#342215' }
  }

  const hasActiveAssessmentGreyColor = activeAssessments.some(
    ({ assessmentColor }) => assessmentColor === GREY
  )
  if (hasActiveAssessmentGreyColor) {
    return { assessmentWarningColor: GREY, backgroundColor: '#1D1D1F' }
  }

  return {}
}

export const getFinalExamNoteText = (courseId, isCaughtUp) => {
  const finalNoteKey = `${courseId}_displayFinalNote`
  const displayFinalNote = localStorage.getItem(finalNoteKey)
  if (displayFinalNote === 'false') return null
  let finalNoteTitle =
      'You’ve completed the final, but there’s still a little work that needs finishing.'
  let finalNoteDescription
  if (isCaughtUp) {
    finalNoteTitle =
        'You’ve completed the final and all of your coursework!'
    finalNoteDescription = `
     Pro-tip: If you're feeling frisky before the
            course officially ends, you may still go back
            and retake quizzes to try for perfect scores (if
            you don't have those already). Remember, you can take each quiz up to 5 times, and only
            your best score counts!`
  }
  localStorage.setItem(finalNoteKey, true)
  return [finalNoteTitle, finalNoteDescription]
}

export const getDetailHeaderNote = ({
  course,
  hasCohortStarted,
  previousSection,
  scheduleSection1,
  studentData,
  isIntensive,
  context
}) => {
  const section1AvailableEarlyNote =
    'We’ve made Section 1.1 available to you early, if you want to get ahead!'
  const completedSection1Note =
    'You’ve completed Section 1.1 early and are ahead of the game!'
  const section1ProgressEarlyNote =
    'You’ve made progress in Section 1.1 early and are ahead of the game!'
  const courseLiveNote = 'The course is now live!'

  const latestCohort = getLatestCohort(course)
  const cohortName = latestCohort?.name
  const cohortID = latestCohort?.id

  const section1AvailableEarlyNoteKey =
    `section1AvailableEarlyNote-${cohortName}-${cohortID}`
  const courseLiveNoteKey =
    `courseLiveNote-${cohortName}-${cohortID}`
  const section1IsCompletedNoteKey =
    `section1IsCompletedNote-${cohortName}-${cohortID}`
  const section1ProgressEarlyNoteKey =
    `section1ProgressEarlyNote-${cohortName}-${cohortID}`

  const allSectionsData = course?.allSectionsData
  const chapters = course?.chapters
  const hasPreviousSection = previousSection?.length > 0

  const section1AvailableEarly = localStorage.getItem(section1AvailableEarlyNoteKey)
  const section1IsCompleted = localStorage.getItem(section1IsCompletedNoteKey)
  const section1ProgressEarly = localStorage.getItem(section1ProgressEarlyNoteKey)
  const isCourseLive = localStorage.getItem(courseLiveNoteKey)

  let activitiesCompleted
  let hasProgressed
  if (!hasPreviousSection &&
    (!hasCohortStarted || !section1IsCompleted || !section1ProgressEarly)) {
    const scheduleSection = cloneDeep(scheduleSection1)
    if (scheduleSection) {
      scheduleSection.schedules.splice(1)
    }
    const section1 = createSectionList({
      sectionsData: allSectionsData,
      dynamicCohortSchedule: scheduleSection,
      context,
      chapters,
      filteredSchedules: course?.courseResourcesSchedule,
      isIntensive,
      studentData
    })
    activitiesCompleted = section1?.every((section) => section?.isCompleted)
    hasProgressed = section1?.find((section) => section?.isCompleted)
  }

  if (!hasCohortStarted) {
    if (!section1IsCompleted && activitiesCompleted) {
      localStorage.setItem(section1IsCompletedNoteKey, true)
      return completedSection1Note
    }
    if (!section1AvailableEarly) {
      localStorage.setItem(section1AvailableEarlyNoteKey, true)
      return section1AvailableEarlyNote
    }
    return null
  }
  if (!section1IsCompleted) {
    localStorage.setItem(section1IsCompletedNoteKey, true)
    localStorage.setItem(section1ProgressEarlyNoteKey, true)
    localStorage.setItem(courseLiveNoteKey, true)
    if (!hasPreviousSection) {
      if (section1AvailableEarly) {
        if (activitiesCompleted) {
          return completedSection1Note
        }
        if (!section1ProgressEarly && hasProgressed) {
          return section1ProgressEarlyNote
        }
      }
      return courseLiveNote
    }
    return null
  }
  if (!hasPreviousSection && !isCourseLive) {
    localStorage.setItem(courseLiveNoteKey, true)
    return courseLiveNote
  }
}
