export const flexibleComparisonOptions = {
  unify_leading_negatives: true,
  sortCommutativeTerms: true,
  removeOptionalBracketsOneLevel: true
}

export const strictComparisonOptions = {
  unify_leading_negatives: true,
  sortCommutativeTerms: false,
  removeOptionalBracketsOneLevel: false
}

export const canvasSmallToolbarSettings = {
  auto_resize_on_scroll: false,
  use_toolbar: true,
  undo_btn: true,
  redo_btn: true,
  new_sheet_btn: false,
  font_size_btns: true,
  formula_btn: false,
  formula_panel: true,
  help_btn: false,
  help_logo_btn: true,
  fullscreen_toolbar_btn: true,
  fullscreen_btn: false,
  transform_btn: false,
  keypad_btn: false,
  scrub_btn: false,
  draw_btn: false,
  erase_btn: false,
  arrange_btn: false,
  reset_btn: true,
  save_btn: false,
  load_btn: false,
  settings_btn: false,
  share_btn: false,
  insert_btn: false,
  use_hold_menu: false,
  display_labels: false,
  btn_size: 'xs',
  ask_confirmation_on_closing: false,
  // for auto-height adjustments, this is still needed for fullscreen mode
  vertical_scroll: true
}
