import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledWrapper = styled.div`
  ${({ position, marginTop, marginLeft }) => position === 'relative'
    ? `position: relative;
      margin-top: ${marginTop || '0px'};
      margin-left: ${marginLeft || '0px'};
      `
    : position === 'static'
      ? 'position: static;'
      : `position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        text-align: center;
        transform: translate(-50%, -50%);`
}
  z-index: 9999;
  background: ${props => props.bg};
  display: flex;
  justify-content: center;
  align-items: center;

`

export const StyledSpinner = styled(Spinner)`
  height: ${props => props._size}px;
  width: ${props => props._size}px;
  color: ${props => props.color || '#5FC4B8'};
`
