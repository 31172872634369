export const MULTIPLE_CHOICE = 'Multiple Choice'
export const TRUE_FALSE = 'True False'
export const TEXT_BLANK = 'Text Blanks'
export const FREE_FORM = 'Free Form'
export const CODING_QUESTION = 'Coding Question'
export const CARD = 'Card'
export const AL_MULTIPLE_CHOICE = 'MultipleChoiceCardRecord'
export const AL_TEXT_REVEAL_CARD = 'TextRevealCardRecord'
export const AL_MULTIPLE_CHOICE_WITH_MATH = 'MultipleChoiceCardWithMathRecord'
export const AL_TRUE_FALSE = 'TrueFalseCardRecord'
export const AL_TRUE_FALSE_WITH_MATH = 'TrueFalseCardWithMathRecord'
export const AL_CARD = 'NonInteractiveCardRecord'
export const AL_CARD_WITH_MATH = 'NonInteractiveCardWithMathRecord'
export const AL_TEXT_BLANKS = 'FillInTheBlanksCardRecord'
