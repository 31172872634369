import React, { useContext } from 'react'
import Context from '../Context/Context'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'
import {
  getAssignmentExtensionLockDates,
  getExamExtensionLockDates
} from './utils'
import { emitter } from '../Emitter/Emitter'
import { EXAM } from '../../Constants/chapterType'
import UnrequestablePage from './UnrequestablePage'
import RequestablePage from './RequestablePage'
import {
  Header,
  AssessmentInfoContainer,
  CourseTitle,
  Dot,
  AssessmentTitle
} from './styled'

function ExtensionRequestPage (props) {
  const {
    cohortData,
    courseTitle,
    assessments
  } = props

  const {
    originalCohortDeadlines,
    examRetake,
    latestCohort,
    cohortData: contextCohortData
  } = useContext(Context)
  cohortData.cohortID = contextCohortData.cohortID

  const { cohortID } = cohortData

  const {
    relationship: { fields: { liveProctoring } = {} } = {}
  } = latestCohort || {}

  const assessmentID = window.location.hash.split('/')[2]

  const chapter = assessments.find(
    assessment => assessment.chapter_uuid === assessmentID)

  const hideExtensionRequestPage = !chapter ||
    (chapter?.type === EXAM && liveProctoring)

  if (hideExtensionRequestPage) {
    emitter.emit(ON_NAVIGATE_TO, '/')
    return null
  }

  const {
    lockDate,
    originalLockDate
  } = (chapter.type === 'WritingAssignmentChapterRecord')
    ? getAssignmentExtensionLockDates(
      { assessmentID, cohortData, originalCohortDeadlines })
    : getExamExtensionLockDates(
      { chapter, cohortData, examRetake, originalCohortDeadlines })

  return (
    <section className='section course-home'>
      <div className='container'>
        <div className='container-sm'>
          <Header>Extension Request</Header>
          <AssessmentInfoContainer>
            <CourseTitle>{courseTitle}</CourseTitle>
            <Dot />
            <AssessmentTitle>{chapter.title}</AssessmentTitle>
          </AssessmentInfoContainer>
          { lockDate && originalLockDate && (originalLockDate !== lockDate) &&
            <UnrequestablePage
              lockDate={lockDate}
              originalLockDate={originalLockDate}
            />}
          { lockDate && originalLockDate && (originalLockDate === lockDate) &&
            <RequestablePage
              assessmentID={assessmentID}
              assessmentTitle={chapter.title}
              cohortID={cohortID}
              courseTitle={courseTitle}
              lockDate={lockDate}
            />}
        </div>
      </div>
    </section>
  )
}

ExtensionRequestPage.displayName = 'ExtensionRequestPage'
export default ExtensionRequestPage
