import styled from 'styled-components'
import media from '../../../mediaQueries'

export const GradingScaleContainer = styled.div`
    display: grid;
    margin-top: 12px;
    grid-template-columns: auto auto auto auto auto;
    ${media.giant`
        grid-template-columns: auto auto auto auto;
    `}
    ${media.desktop`
        grid-template-columns: auto auto auto;
    `}
    ${media.tablet`
        grid-template-columns: auto auto;
    `}
    ${media.small`
        grid-template-columns: auto;
    `}
    grid-gap: 12px;
`

export const GradingScaleItemWrapper = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 5px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
`

export const GradingScaleSymbol = styled.div`
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  color: #ffffff;
`

export const GradingScaleFigure = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
`
