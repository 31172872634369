import React, { Component } from 'react'
import ModalComponent from '../ModalComponent/ModalComponent'
import './css/TermAgreementModal.css'
import api from '../../api'
import { TERMS_AGREEMENT } from '../../Constants/studentContext'
import Context from '../Context/Context'

class TermAgreementModal extends Component {
  constructor (props) {
    super(props)
    this.setModalShow = this.setModalShow.bind(this)
    this.state = {
      modalShow: true,
      isChecked: false
    }
  }

  async setModalShow (modalShow) {
    const data = await api.setStudentData([TERMS_AGREEMENT],
      { [TERMS_AGREEMENT]: true })
    if (data) {
      this.setState({ modalShow })
      this.context.updateContext({
        dateTOS: new Date().toString(),
        termsAgreement: true
      })
    }
  }

  render () {
    const { modalShow, isChecked } = this.state
    const termsOfUseUrl = `https://dashboard.outlier.org/#/terms-of-use`
    const privacyPolicyUrl = `https://dashboard.outlier.org/#/privacy-policy`
    const homepageUrl = `https://www.outlier.org/`
    const termsOfUseLink = <a href={termsOfUseUrl} target='_blank'
      rel='noopener noreferrer'> Terms of Service </a>
    const privacyPolicyLink = <a href={privacyPolicyUrl} target='_blank'
      rel='noopener noreferrer'> Privacy Policy </a>
    const homePageLink = <a href={homepageUrl} target='_blank'
      rel='noopener noreferrer'>outlier.org</a>

    return (
      <>
        <ModalComponent
          className='team-agreement-modal p-4'
          show={modalShow}
          hideClose
          wrapperWidth={640}
        >
          <h5 className='p-5'>Terms Agreement</h5>
          <div className='d-flex flex-row pb-4 pr-5 pl-5
            agreement-text-container'>
            <div>
              <input
                type='checkbox'
                value={isChecked}
                onChange={() => this.setState({ isChecked: !isChecked })}
                className='pointer terms-checkbox mr-3'
              />
            </div>
            <div>
              <span>
              By checking this box you acknowledge that you have read and
              agree to our {termsOfUseLink} and {privacyPolicyLink}.
              </span>
              <div>
                <span>
                  Please note that these differ from the Terms of Service and Privacy Policy of our homepage, {homePageLink}.
                </span>
              </div>
            </div>
          </div>
          <button
            className='ml-5 mb-5 btn btn-primary'
            disabled={!isChecked}
            onClick={() => this.setModalShow(false)}
          >
            Continue
          </button>
        </ModalComponent>
      </>
    )
  }
}

TermAgreementModal.contextType = Context
export default TermAgreementModal
