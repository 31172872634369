import React, { useContext, useEffect, useState } from 'react'
import Context from '../Context/Context'
import config from '../../config'
import _ from 'lodash'
import BurstConfetti from '../BurstConfetti/BurstConfetti'
import { getCourseSections } from '../../utilities/sectionUtils'
import { getDynamicSchedules } from '../ResourcesSection/Utils'
import {
  getLastRecommendedSectionId,
  getFormattedSchedule,
  getRecommendedProgress,
  getProgressBarColor,
  getTooltipRangeText,
  getTooltipRecommededText,
  getTooltipText
} from '../../utilities/courseUtils'
import {
  Background,
  Container,
  Marker,
  MsgText,
  Progress, ProgressBarDot,
  ProgressWrapper
} from './styles'
import { secondsSinceEpoch } from '../../utilities/dateTimeUtils'

const ProgressTimeLine = ({ course }) => {
  const {
    courseData: { chapters },
    cohortData: { courseResourcesSchedule },
    isCohortEnded, cohortStartDate,
    studentData: { minimumSectionProgress }
  } = useContext(Context)

  const [courseProgress, setCourseProgress] = useState(0)

  useEffect(() => {
    if (courseResourcesSchedule !== undefined) {
      calculateCourseProgress()
    }
  })

  const calculateMarkerPosition = () => {
    if (isCohortEnded) return 100
    const cohortSchedules = getFormattedSchedule(courseResourcesSchedule)
    if (!cohortSchedules.length) return

    let lastWeekDay = cohortSchedules[cohortSchedules.length - 1]

    let currentWeek = getDynamicSchedules(cohortSchedules)[0]
    if (!currentWeek) currentWeek = lastWeekDay

    currentWeek = currentWeek.week

    lastWeekDay = lastWeekDay.week
    const markerPosition = (100 / lastWeekDay) * currentWeek
    return Math.round(markerPosition)
  }

  const calculateCourseProgress = () => {
    const courseSections = getCourseSections(course)

    if (!courseResourcesSchedule) return

    const cohortSchedules = getFormattedSchedule(courseResourcesSchedule)
    const cohortSchedulesLength = cohortSchedules.length
    if (!cohortSchedulesLength) return

    let lastSection = getDynamicSchedules(courseResourcesSchedule)[0]
    if (!lastSection || isCohortEnded) {
      lastSection = courseResourcesSchedule[courseResourcesSchedule.length - 1]
    }

    const lastRecommendedSection =
      getLastRecommendedSectionId(lastSection, chapters)
    const indexOfLastRecommendedSection = courseSections
      .indexOf(lastRecommendedSection)
    const allRecommendedSections = courseSections
      .slice(0, indexOfLastRecommendedSection + 1)
    const commonSectionsProgress = []
    Object.keys(minimumSectionProgress)
      .forEach((key) => {
        if (allRecommendedSections.indexOf(key) !== -1) {
          commonSectionsProgress.push(minimumSectionProgress[key])
        }
      })
    if (commonSectionsProgress.length) {
      let progress = _.sum(commonSectionsProgress) / courseSections.length
      progress = Math.round(progress)
      setCourseProgress(progress)
    }
  }

  const getProgressNotchColor = () => {
    if (!courseResourcesSchedule) return
    const { values: { currentValueRP } } = getRecommendedProgress(isCohortEnded, courseResourcesSchedule)
    const isCompleted = courseProgress >= currentValueRP && isCohortEnded
    return isCompleted ? '#FFFFFF'
      : courseProgress >= currentValueRP ? '#969BFF' : '#FFFFFF'
  }

  const getDotColor = (leftPosition, currentValueRP, lastWeekValueRP) => {
    let { color, borderColor } = { color: '#161618', borderColor: '#5E636B' }
    if (markerPosition === 0 || courseProgress === 0) return { color, borderColor }
    if (leftPosition <= courseProgress) {
      color = borderColor = getProgressBarColor(courseProgress,
        isCohortEnded,
        courseResourcesSchedule)
      return { color, borderColor }
    }
    if (leftPosition > courseProgress && leftPosition < lastWeekValueRP) {
      return { color, borderColor: '#F2765A' }
    }
    if (leftPosition > courseProgress && leftPosition < markerPosition) {
      return { color, borderColor: '#FFB800' }
    }

    return { color, borderColor }
  }

  if (!courseResourcesSchedule) return null
  const formattedSchedules = getFormattedSchedule(courseResourcesSchedule)
  const cohortSchedulesLength = formattedSchedules.length

  if (cohortSchedulesLength === 0) return null
  const { currentRP, lastWeekRP, lastTwoWeeksRP,
    values: { currentValueRP, lastWeekValueRP, lastTwoWeeksValueRP }
  } = getRecommendedProgress(isCohortEnded, courseResourcesSchedule)

  const currentDate = secondsSinceEpoch()
  const isCohortStart = currentDate > cohortStartDate

  const { isRecommendedProgress } = config
  const markerPosition = calculateMarkerPosition()
  const notchColor = getProgressNotchColor()
  const isSchedule = markerPosition !== undefined
  const isProgressGreaterOrEqual = courseProgress >= markerPosition
  const isGreaterThanOneWeek = courseProgress > lastWeekValueRP
  const isGreaterThanTwoWeeks = courseProgress > lastTwoWeeksValueRP
  const spaceBetweenDots = 100 / cohortSchedulesLength
  const progressColor = getProgressBarColor(courseProgress,
    isCohortEnded,
    courseResourcesSchedule)
  const tooltipTextInfo = {
    cohortStartDate,
    isCohortStart,
    isCohortEnded,
    courseProgress,
    currentValueRP,
    lastWeekValueRP,
    lastTwoWeeksValueRP
  }
  return (<ProgressWrapper>
    <Container data-cy='progressBar' data-testid='progressBar'>
      {isProgressGreaterOrEqual &&
        <BurstConfetti left={courseProgress} />}
      <Background />
      <Progress percent={courseProgress} color={progressColor} />
      {formattedSchedules && formattedSchedules.map((schedule, key) => {
        const leftPosition = spaceBetweenDots * (key + 1)
        const leftPositionRounded = Math.round(leftPosition)
        const isVisible = Math.round(leftPosition) !== markerPosition
        const { color, borderColor } = getDotColor(leftPositionRounded, currentValueRP, lastWeekValueRP)
        return <ProgressBarDot
          visible={isVisible}
          filled={color}
          borderColor={borderColor}
          left={leftPosition}
          key={key} />
      })}
      {isRecommendedProgress && isSchedule &&
        <Marker left={markerPosition}
          data-testid='progressMarker'
          color={notchColor} />}
      {isSchedule && <MsgText left={0}>
        {getTooltipText(tooltipTextInfo)}
        {!isProgressGreaterOrEqual && !isGreaterThanOneWeek &&
          !isGreaterThanTwoWeeks &&
          <span className='recText' style={{ color: '#F2765A' }}>
            {getTooltipRangeText(lastTwoWeeksRP, formattedSchedules[0])}
          </span>}
        {!isProgressGreaterOrEqual && !isGreaterThanOneWeek &&
          <span className='recText' style={{ color: '#FFB800' }}>
            {getTooltipRecommededText(lastWeekRP)}
          </span>}
        {!isProgressGreaterOrEqual &&
        <span className='recText'>
          {getTooltipRecommededText(currentRP)}
        </span>}
      </MsgText>}
    </Container>
  </ProgressWrapper>)
}
export default ProgressTimeLine
