import React, { Component } from 'react'
import Checkbox from '../FormComponent/Checkbox'
import ShowSolution from '../ShowSolution/ShowSolution'
import QuestionComponent from '../QuestionComponent/QuestionComponent'
import CourseButton from '../CourseButton/CourseButton'
import { emitter } from '../Emitter/Emitter'
import Context from '../Context/Context'
import QuestionNextButton from '../QuestionComponent/QuestionNextButton'
import QuestionPreviousButton from '../QuestionComponent/QuestionPreviousButton'
import ReviewWorkButton from '../QuestionComponent/ReviewWorkButton'
import ReviewNextButton from '../QuestionComponent/ReviewNextButton'
import StepsSolutionModal from '../StepsSolutionModal/StepsSolutionModal'
import {
  EXAM,
  QUIZ,
  RETAKE,
  ACTIVITY_TYPES
} from '../../Constants/sectionType'
import {
  ON_SECTION_DATA_PROGRESS,
  ON_STUDENT_ANSWER,
  ON_SUBMIT_ANSWER
} from '../../Constants/emitterKeys'
import {
  saveAnswersToLocalStorage,
  isExamRetakeType
} from '../../utilities/examUtils'
import { findAnswer, getQuestionTitle, isMultiAnswerSingleString } from '../../utilities/questions'
import { getExplanation } from '../../utilities/courseUtils'
import ShowFeedback from '../ShowFeedBack/ShowFeedBack'
import ProctorioExamResources
  from '../ProctorioExamResources/ProctorioExamResources'
import isEqual from 'lodash/isEqual'
import {
  PaginationWrapper,
  TextWrapper,
  Icon,
  QuestionText,
  QuestionCard,
  PinWrapper,
  PinText,
  PinButtonWrapper,
  PinButton,
  LessonText,
  QuestionIconWrapper
} from './styles'
import {
  NEW_GUESSWORK_ICON,
  NEW_EMPTY_ICON,
  NEW_COMPLETED_ICON,
  NEW_INCORRECT_ICON,
  PIN_ICON,
  PINNED_ICON_WHITE
} from '../../Constants/progressBarIconSource'
import { EXAM_STATUSES, PIN_TEXT, PINNED_TEXT } from '../../Constants/index'
import { CORRECT, INCORRECT } from '../../Constants/result'
import { trackStudentEvent } from '../../utilities/chapterUtils'
import JDoodleCompiler from '../JDoodleCompiler/JDoodleCompiler'
import { EXAM_STATUS } from '../../Constants/studentContext'

class MultipleChoiceQuestionQuizExam extends Component {
  constructor (props) {
    super(props)

    this.onShowSolution = this.onShowSolution.bind(this)
    this.toggleCheckbox = this.toggleCheckbox.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.CheckSolution = this.CheckSolution.bind(this)
    this.createCheckbox = this.createCheckbox.bind(this)
    this.createCheckboxes = this.createCheckboxes.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleShowSolution = this.handleShowSolution.bind(this)
    this.setModalContent = this.setModalContent.bind(this)
    this.isExam = this.isExam.bind(this)
    this.isQuiz = this.isQuiz.bind(this)
    this.popTriesResultIfExam = this.popTriesResultIfExam.bind(this)

    this.state = {
      result: '',
      tryAttempt: 0,
      findAns: null,
      isNextDisabled: true,
      isDisplayNext: true,
      isExam: this.isExam(),
      isQuiz: this.isQuiz(),
      showSolution: false,
      solution: {},
      isDisplayCheckOrSubmit: true,
      displayColor: false,
      correctIncorrectClass: '',
      selectedOption: '',
      systemAnswer: '',
      isCorrect: '',
      triesPerQuestion: this.props.question.tries_per_question
        ? parseInt(this.props.question.tries_per_question)
        : 3,
      triesResult: [],
      isAnsweringDisabled: false,
      studentAnswerRes: {},
      isPinned: false,
      modalContent: ''
    }
  }

  isExam () {
    const { type_of_question: typeOfQuestion } = this.props
    return typeOfQuestion === EXAM
  }

  isQuiz () {
    const { type_of_question: typeOfQuestion } = this.props
    return typeOfQuestion === QUIZ
  }

  onShowSolution (questionId) {
    const { question, question: { question_view_lesson: questionViewLessson }
    } = this.props
    const { answer, Question_uuid: questionUUID } = question
    let studentAnswerClass = 'radio__badge-incorrect'

    if (Array.isArray(answer)) {
      let correctAnswer = ''
      let studentAnswer = ''
      question.options.forEach((option, index) => {
        const questionIndex = (index + 1).toString()
        if (answer.includes(questionIndex)) {
          correctAnswer += option + ' '
        }
      })
      if (Array.isArray(this.state.findAns)) {
        this.state.findAns.forEach(selectedOpt => {
          studentAnswer += question.options[selectedOpt] + ' '
        })
      }
      if (this.state.correctIncorrectClass === 'ans-correct') {
        studentAnswerClass = 'radio__badge-correct'
      }
      this.setState({
        showSolution: true,
        solution: {
          questionUUID,
          correctAnswer,
          studentAnswer,
          studentAnswerClass,
          view_lesson: questionViewLessson
        }
      })
    }
  }

  componentWillMount () {
    this.selectedCheckboxes = new Set()
  }

  setModalContent (modalContent) {
    this.setState({ modalContent })
  }

  componentDidUpdate (prevProps, prevState) {
    const { isDisplayNext, isExam, isQuiz } = this.state
    if (isExam && isExam !== isDisplayNext) this.setState({ isDisplayNext: isExam })
    if (isQuiz && (isQuiz !== isDisplayNext)) {
      this.setState({ isDisplayNext: isQuiz })
    }
    const {
      review_mode: reviewMode,
      type_of_question: typeOfQuestion,
      currentQuestionUUID,
      incorrectAnsIndex
    } = this.props
    this.lastSectionQuiz = this.getLastSectionQuiz()
    this.lastSectionExam = this.getLastSectionExam()
    const isPendingResponseAnswer =
      !reviewMode && [QUIZ, EXAM].includes(typeOfQuestion)
    if (this.props.review_mode !== prevProps.review_mode) {
      this.onShowSolution()
      this.setState({
        showSolution: true
      })
    }
    const {
      question: {
        answer: validAnswer,
        Question_uuid: questionUUID
      }
    } = this.props
    const { studentData: { studentAnswers } } = this.context
    const validAnswerFromDato = isMultiAnswerSingleString(validAnswer)

    let fAns = findAnswer(questionUUID, studentAnswers)
    const currentAnswer = fAns
    if (!fAns?.answer && fAns?.correct === undefined && !fAns?.tries) fAns = null
    if (fAns?.answer) fAns = [fAns.answer, fAns.correct, fAns.tries]

    if (fAns && (prevProps.question.Question_uuid !==
      this.props.question.Question_uuid || !isDisplayNext)) {
      if (this.props.review_mode) {
        setTimeout(() => {
          this.onShowSolution()
          this.setState({
            showSolution: true
          })
        }, 500)
      }

      if (fAns?.length) {
        incorrectAnsIndex(fAns[0])
        const formattedStudentAnswer = typeof fAns[0] === 'number'
          ? [fAns[0] + 1]
          : Array.isArray(fAns[0])
            ? fAns[0].map(ans => ans + 1)
            : []
        if (isExam || isQuiz) {
          this.selectedCheckboxes = new Set(
            typeof fAns[0] === 'number' ? [fAns[0]] : fAns[0]
          )
        }
        this.setState({
          findAns: typeof fAns[0] === 'number' ? [fAns[0]] : fAns[0],
          result: fAns[1] ? CORRECT : INCORRECT,
          correctIncorrectClass: isPendingResponseAnswer
            ? ''
            : isEqual(
              formattedStudentAnswer.sort(),
              validAnswerFromDato.map(Number).sort()
            )
              ? 'ans-correct'
              : 'incorrect ans-incorrect',
          isNextDisabled: false,
          systemAnswer: this.props.question.answer,
          isDisplayNext: true,
          isDisplayCheckOrSubmit: false,
          displayColor: true,
          triesPerQuestion: this.props.question.tries_per_question
            ? parseInt(this.props.question.tries_per_question)
            : 3,
          triesResult: fAns[2] || [],
          studentAnswerRes: currentAnswer,
          selectedOption: currentAnswer?.answer,
          selectedValues: currentAnswer?.answer,
          isAnsweringDisabled: !(isExam || isQuiz)
        })
      }
    } else if (this.lastSectionQuiz !== this.initialLastSectionQuiz ||
      this.lastSectionExam !== this.initialLastSectionExam) {
      this.initialLastSectionQuiz = this.lastSectionQuiz
      this.initialLastSectionExam = this.lastSectionExam
      window.location.reload()
    } else if (prevProps.question.Question_uuid !==
      this.props.question.Question_uuid && !fAns) {
      if (this.props.review_mode) {
        setTimeout(() => {
          this.onShowSolution()
          this.setState({
            showSolution: true
          })
        }, 500)
      }
      this.selectedCheckboxes.clear()
      this.setState({
        findAns: '',
        result: '',
        selectedValues: '',
        selectedOption: '',
        studentAnswerRes: fAns || {},
        isNextDisabled: true,
        tryAttempt: 0,
        correctIncorrectClass: '',
        systemAnswer: this.props.question.answer,
        isDisplayNext: isExam,
        isDisplayCheckOrSubmit: true,
        displayColor: false,
        triesPerQuestion: this.props.question.tries_per_question
          ? parseInt(this.props.question.tries_per_question)
          : 3,
        triesResult: fAns ? fAns[2] : [],
        isAnsweringDisabled: false
      })
    }
    if (prevProps.question.Question_uuid !==
      this.props.question.Question_uuid) {
      const { studentData: { pinnedQuestions } } = this.context
      this.setState(
        { isPinned: pinnedQuestions.includes(currentQuestionUUID) }
      )
    }
  }

  toggleCheckbox (index) {
    /* ------- Clear TIme out on Reveal Answer Click ----- */
    clearTimeout(this.timeOutFuncID)
    /* ------- Clear TIme out on Reveal Answer Click ----- */

    if (this.selectedCheckboxes.has(index)) {
      this.selectedCheckboxes.delete(index)
    } else if (this.props.question.only_allow_one &&
       this.props.question.only_allow_one === true &&
        (this.selectedCheckboxes.size === 0 ||
           this.selectedCheckboxes.size === 1) &&
            typeof (index) !== 'undefined') {
      this.selectedCheckboxes.clear()
      this.selectedCheckboxes.add(index)
    } else if (!this.props.question.only_allow_one &&
       typeof (index) !== 'undefined') {
      this.selectedCheckboxes.add(index)
    }
    const { isExam, isAnsweringDisabled, isQuiz } = this.state

    this.setState({
      findAns: Array.from(this.selectedCheckboxes),
      isNextDisabled: false,
      correctIncorrectClass: '',
      selectedOption: Array.from(this.selectedCheckboxes),
      isAnsweringDisabled: (isExam || isQuiz)
        ? false : isAnsweringDisabled
    })
    if (!(isExam || isQuiz)) return
    this.handleFormSubmit()
  }

  componentDidMount () {
    const {
      activeSectionUUID,
      type_of_question: questionType,
      isFirstQuestion,
      activeChildrenIndex,
      completeScreenData,
      question: {
        Question_uuid: questionUUID
      }
    } = this.props
    const {
      studentData: { studentAnswers, pinnedQuestions },
      cohortData: { duration, name: cohort },
      reviewMode
    } = this.context
    if (!pinnedQuestions.length) {
      studentAnswers
        .forEach(studentAnswer => studentAnswer?.questionActivity?.pinned &&
          pinnedQuestions.push(studentAnswer.uuid))
    }
    this.setState({ isPinned: pinnedQuestions.includes(questionUUID) })
    let fAns = findAnswer(questionUUID, studentAnswers)
    if (!fAns?.answer && fAns?.correct === undefined && !fAns?.tries) fAns = null
    if (fAns?.answer) fAns = [fAns.answer, fAns.correct, fAns.tries]

    const { isAnsweringDisabled, isExam, isQuiz } = this.state
    this.setState({ systemAnswer: this.props.question.answer,
      isDisplayNext: isExam,
      isAnsweringDisabled: (isExam || isQuiz)
        ? false : isAnsweringDisabled
    })

    this.initialLastSectionQuiz = this.getLastSectionQuiz()
    this.lastSectionQuiz = this.initialLastSectionQuiz
    this.initialLastSectionExam = this.getLastSectionExam()
    this.lastSectionExam = this.initialLastSectionExam
    if (fAns?.length) {
      const {
        incorrectAnsIndex,
        question: { answer: validAnswerFromDato }
      } = this.props
      incorrectAnsIndex(fAns[0])
      const formattedStudentAnswer = typeof fAns[0] === 'number'
        ? [fAns[0] + 1]
        : Array.isArray(fAns[0])
          ? fAns[0].map(ans => ans + 1)
          : []
      this.setState({
        findAns: fAns[0],
        result: fAns[1] ? CORRECT : INCORRECT,
        correctIncorrectClass:
          isEqual(
            formattedStudentAnswer.sort(),
            validAnswerFromDato.map(Number).sort()
          )
            ? 'ans-correct'
            : 'incorrect ans-incorrect',
        isNextDisabled: false,
        isDisplayNext: true,
        isDisplayCheckOrSubmit: false,
        displayColor: true,
        triesPerQuestion: this.props.question.tries_per_question
          ? parseInt(this.props.question.tries_per_question)
          : 3,
        triesResult: fAns[2],
        isAnsweringDisabled: !(isExam || isQuiz)
      })
    }
    this.handleShowSolution()
    const isQuestionTypeExam = questionType === EXAM
    const isTrackEvent = isQuestionTypeExam && isFirstQuestion && !reviewMode
    if (!isTrackEvent) return

    trackStudentEvent({
      questionType,
      cohort,
      duration,
      activeChildrenIndex,
      completeScreenData
    })
    emitter.emit(
      ON_SECTION_DATA_PROGRESS,
      {
        key: EXAM_STATUS,
        sectionUUID: activeSectionUUID,
        value: EXAM_STATUSES.IN_PROGRESS
      }
    )
  }

  getLastSectionQuiz () {
    const { studentData: { 'quiz-section': quizSection } } = this.context
    return Object.keys(quizSection).pop()
  }

  getLastSectionExam () {
    const { studentData: { 'exam-section': examSection } } = this.context
    return Object.keys(examSection).pop()
  }

  handleShowSolution () {
    const { review_mode: reviewMode } = this.props
    if (!reviewMode) return

    setTimeout(() => {
      this.onShowSolution()
      this.setState({ show_solution: true })
    }, 500)
  }

  getCorrectAnswer = () => {
    const { answer, options } = this.props.question
    const answersArray = isMultiAnswerSingleString(answer)
    return options
      .filter((option, index) => answersArray.includes(`${index + 1}`))
      .join(' ')
  }

  handleFormSubmit () {
    /* ------- Clear TIme out on Reveal Answer Click ----- */
    clearTimeout(this.timeOutFuncID)
    /* ------- Clear TIme out on Reveal Answer Click ----- */
    this.CheckSolution()

    const { setGuessworkCorrectAnswer } = this.props
    setGuessworkCorrectAnswer(this.getCorrectAnswer())
  }

  CheckSolution () {
    const {
      review_mode: reviewMode,
      type_of_question: typeOfQuestion,
      incorrectAnsIndex
    } = this.props
    const isPendingResponseAnswer =
      !reviewMode && [QUIZ, EXAM].includes(typeOfQuestion)
    const { isExam, isQuiz } = this.state
    const answers = Array.from(this.selectedCheckboxes)
    const correctAnswer = isMultiAnswerSingleString(this.state.systemAnswer)
    const correctAnswerDec = correctAnswer.map(num => num - 1)
    let ansLog = ''
    const ansOptions = this.props.question.options || []
    const indexForTextToShow = answers.filter(item => {
      return correctAnswerDec.indexOf(item) === -1
    })
    answers.forEach(num => {
      num = String(num)
      if (ansOptions[num]) {
        ansLog = ansLog + ansOptions[num]
      }
    })
    this.setState({
      selectedValues: answers
    })

    const finalTryAttempt = this.state.tryAttempt + 1
    this.popTriesResultIfExam()
    this.setState({ displayColor: true })
    const tryEl = document.getElementById('tries-' + (finalTryAttempt - 1))
    if (JSON.stringify(answers.sort()) ===
        JSON.stringify(correctAnswerDec.sort())) {
      this.state.triesResult.push(true)
      // change context data using event
      const testRes = {}
      testRes.uuid = this.props.question.Question_uuid
      testRes.answer = answers
      testRes.correct = true
      testRes.tries = this.state.triesResult
      testRes.type = ACTIVITY_TYPES[typeOfQuestion]
      this.saveResponses(testRes)
      if (this.state.result !== CORRECT) {
        window.outlierLog('Correct Answer', ansLog)
      }

      /** Change the color of tries remaining */
      if (tryEl) tryEl.className = 'radio__badge radio__badge-correct'

      // change context data
      this.setState({
        result: CORRECT,
        tryAttempt: 0,
        isNextDisabled: false,
        correctIncorrectClass: 'ans-correct',
        isCorrect: 'true',
        isDisplayNext: true,
        isDisplayCheckOrSubmit: false,
        isAnsweringDisabled: !(isExam || isQuiz)
      })
    } else {
      if (finalTryAttempt === this.state.triesPerQuestion) {
        const isOptionUnselect = !(answers?.length)
        this.state.triesResult.push(false)
        // change context data using event
        const testRes = {}
        testRes.uuid = this.props.question.Question_uuid
        testRes.answer = answers
        testRes.correct = false
        testRes.tries = this.state.triesResult
        testRes.type = ACTIVITY_TYPES[typeOfQuestion]
        this.saveResponses(testRes)
        if (this.state.result !== INCORRECT) {
          window.outlierLog('Incorrect Answer', ansLog)
        }
        incorrectAnsIndex(indexForTextToShow)
        this.setState({
          isDisplayNext: true,
          isDisplayCheckOrSubmit: false,
          isNextDisabled: isOptionUnselect,
          result: isOptionUnselect ? '' : INCORRECT,
          correctIncorrectClass: isPendingResponseAnswer
            ? ''
            : 'incorrect ans-incorrect',
          isCorrect: 'false',
          isAnsweringDisabled: !(isExam || isQuiz)
        })
      } else {
        this.state.triesResult.push(false)
        incorrectAnsIndex(indexForTextToShow)
        this.setState({
          result: INCORRECT,
          tryAttempt: finalTryAttempt,
          isNextDisabled: true,
          correctIncorrectClass: isPendingResponseAnswer
            ? ''
            : 'incorrect ans-incorrect',
          isCorrect: 'false',
          isAnsweringDisabled: false
        })

        /* ---------- Added for Clearing Selected Option ----------- */
        this.timeOutFuncID = setTimeout(() => {
          this.selectedCheckboxes.clear()
          this.toggleCheckbox()
          this.setState({
            result: '',
            selectedValues: '',
            correctIncorrectClass: '',
            isNextDisabled: true,
            displayColor: false,
            isAnsweringDisabled: false
          })
        }, 1000)
        /* ---------- Added for Clearing Selected Option ----------- */
      }
      /** Change the color of tries remaining */
      if (tryEl) tryEl.className = 'radio__badge radio__badge-incorrect'
    }
  }

  saveResponses = testRes => {
    const { review_mode: reviewMode, completeScreenData } = this.props
    const {
      cohortData,
      studentData: { pinnedQuestions },
      isAssessmentQuizExam,
      examRetake
    } = this.context
    const { isExam, isQuiz } = this.state
    const isRetakeType = isExamRetakeType({
      examRetake,
      cohortId: cohortData.cohortID,
      chapter: completeScreenData?.chapter
    }) && isExam
    if (isRetakeType) testRes.type = ACTIVITY_TYPES[RETAKE]

    if (reviewMode) return

    if (isAssessmentQuizExam && pinnedQuestions.includes(testRes.uuid)) {
      testRes.questionActivity = {
        pinned: true
      }
    }
    const unSelectObject = {
      uuid: testRes.uuid,
      questionActivity: {
        pinned: testRes?.questionActivity?.pinned
      }
    }

    const isOptionUnselect = !(testRes?.answer?.length)
    const answerObject = isOptionUnselect ? unSelectObject : testRes
    emitter.emit(ON_STUDENT_ANSWER, { ...answerObject, isExam })
    this.setState({ studentAnswerRes: answerObject })
    if (isExam || isQuiz) {
      const { studentData: { studentAnswers } } = this.context
      const { localStorageKey, currentQuestionSet } = this.props
      saveAnswersToLocalStorage(localStorageKey, currentQuestionSet, studentAnswers)
      return
    }
    this.props.updateOnCheck(testRes)
  }

  /*  For exams, users are allowed to change the answer they previously selected.
  This function ensures that triesResult always has one value in the case of exams. */
  popTriesResultIfExam () {
    const { isExam, triesResult, isQuiz } = this.state
    const examTriesResultIsEmpty = !isExam || !isQuiz || !triesResult ||
    triesResult.length === 0
    if (examTriesResultIsEmpty) return
    triesResult.pop()
  }

  createCheckbox (label, index) {
    const { showSolution, result, isAnsweringDisabled } = this.state
    const curLoopInd = (index + 1).toString()
    let cbCorrectState = ''
    /* ---- CheckBox Partial True False Condition ---- */
    if (this.state.displayColor && this.state.displayColor === true &&
       this.state.findAns && this.state.systemAnswer.indexOf(curLoopInd) > -1 &&
       this.state.findAns.length >= this.state.systemAnswer.length) {
      cbCorrectState = 'true'
    } else if (this.state.displayColor && this.state.displayColor === true &&
               this.state.systemAnswer.indexOf(curLoopInd) === -1) {
      cbCorrectState = 'false'
    } else {
      cbCorrectState = 'null'
    }

    const {
      review_mode: reviewMode,
      question: { options, Question_uuid: questionUUID },
      type_of_question: sectionType
    } = this.props
    const { isAssessmentQuizExam, isSidePanelOpen } = this.context
    const isDisabled = reviewMode ||
    (!isAssessmentQuizExam && isAnsweringDisabled)
    return <Checkbox
      label={label}
      handleCheckboxChange={this.toggleCheckbox}
      key={index}
      value={index}
      isAnsweringDisabled={!isAssessmentQuizExam && this.state.isAnsweringDisabled}
      isAssessmentQuizExam={isAssessmentQuizExam}
      isLastIndex={options.length - 1 === index && (showSolution || result)}
      dataCypress='MCQButton'
      checked={!!(this.state.findAns && this.state.findAns.indexOf(index) > -1)}
      fAnswer={this.state.findAns}
      sysAnswer={this.state.systemAnswer}
      questionUUID={questionUUID}
      isSidePanelOpen={isSidePanelOpen}
      isCorrect={cbCorrectState}
      disabled={isDisabled}
      correctIncorrect={this.state.correctIncorrectClass}
      reviewMode={reviewMode}
      type_of_question={sectionType}
    />
  }

  createCheckboxes (items) {
    return items.map(this.createCheckbox)
  }

  // Should return false if the action was not allowed.
  handleKeyPress (type) {
    const {
      isNextDisabled,
      isDisplayNext,
      isDisplayCheckOrSubmit,
      studentAnswerRes
    } = this.state

    // Typically from pressing the left arrow (not allowed here)
    if (type && type === 'previous') return false

    // Everything else is interpreted as next or advance forward
    if (this.props.review_mode) {
      this.props.onReviewClickNext()
      return true
    } else {
      if (!isNextDisabled) {
        if (isDisplayNext) {
          this.props.onNextButtonClick(studentAnswerRes)
          return true
        } else if (isDisplayCheckOrSubmit) {
          this.handleFormSubmit()
          return true
        }
      }
    }

    // Unrecognized, default is don't allow it.
    return false
  }

  onPinClick (currentQuestionUUID) {
    const {
      completeScreenData,
      review_mode: reviewMode,
      type_of_question: typeOfQuestion
    } = this.props

    const {
      studentData,
      updateContext,
      cohortData,
      examRetake
    } = this.context
    const { pinnedQuestions, studentAnswers } = studentData
    const { isPinned, isExam } = this.state
    const currentIndex = pinnedQuestions.indexOf(currentQuestionUUID)
    const isRetakeType = isExamRetakeType({
      examRetake,
      cohortId: cohortData.cohortID,
      chapter: completeScreenData?.chapter
    }) && isExam
    const fAnswer = findAnswer(currentQuestionUUID, studentAnswers)
    // condition to check answer exists in review mode before pinning
    const isAttemptedQuestion = typeof (fAnswer?.correct) === 'boolean'
    const answer = {
      uuid: currentQuestionUUID,
      type: isRetakeType
        ? ACTIVITY_TYPES[RETAKE]
        : typeOfQuestion.toLocaleLowerCase(),
      ...(reviewMode && isAttemptedQuestion && {
        answer: fAnswer.answer,
        correct: fAnswer.correct,
        tries: fAnswer.tries
      }),
      questionActivity: { pinned: false }
    }
    if (isPinned) {
      pinnedQuestions.splice(currentIndex, 1)
      emitter.emit(ON_SUBMIT_ANSWER, answer)
    } else {
      answer.questionActivity.pinned = true
      pinnedQuestions.push(currentQuestionUUID)
      emitter.emit(ON_SUBMIT_ANSWER, answer)
    }
    studentData.pinnedQuestions = pinnedQuestions
    updateContext({ studentData })
    this.setState({ isPinned: !isPinned })
  }

  render () {
    const {
      studentAnswerRes, modalContent, solution, showSolution, result, isQuiz,
      isNextDisabled, isExam, isAnsweringDisabled, selectedOption, isPinned
    } = this.state
    const isExamOrQuiz = isExam || isQuiz
    const {
      question, type_of_question: typeOfQuestion, filterMessage,
      correctProps, incorrectProps, review_mode: reviewMode, showfinish,
      currentQuestionSet, isFirstQuestion, activeSectionUUID, structuredText,
      showPrevious, currentQuestionUUID } = this.props
    const { isStudentProgressBusy, isAssessmentQuizExam } = this.context
    const options = this.createCheckboxes(question.options)
    const checkOrSubmit = isQuiz ? 'Submit' : 'Check'
    const {
      general_explanation: generalExplanation,
      specific_explanation: specificExplanation,
      jdoodleEditor,
      starterCode,
      examResources,
      resourceIcons,
      Question_uuid: questionUUID
    } = question
    const questionIndex = currentQuestionSet?.findIndex(
      (q) => q.Question_uuid === questionUUID
    )
    const { questionUUID: solutionQuestionUUID } = solution
    const [firstQuestion] = currentQuestionSet || {}
    const { Question_uuid: firstQuestionUUID } = firstQuestion || {}
    const isLastQuestionOfOngoingExam = isExam && !reviewMode && showfinish
    const isLastQuestionAndNotAssessment = isLastQuestionOfOngoingExam && !isAssessmentQuizExam
    const studentAnswer = (
      Array.isArray(selectedOption) ? selectedOption : []
    ).map(option => question?.options[option]).join('')

    return (
      <React.Fragment>
        <StepsSolutionModal modalContent={modalContent} setModalContent={this.setModalContent} />
        {isAssessmentQuizExam && <TextWrapper>
          <QuestionIconWrapper>
            <span>
              <Icon
                src={result
                  ? (reviewMode
                    ? (result === 'Correct'
                      ? NEW_COMPLETED_ICON : NEW_INCORRECT_ICON)
                    : NEW_GUESSWORK_ICON)
                  : reviewMode ? NEW_INCORRECT_ICON : NEW_EMPTY_ICON}
                alt='Feedback'
              />
            </span>
            <QuestionText>
              {getQuestionTitle(question, questionIndex, typeOfQuestion)}
            </QuestionText>
          </QuestionIconWrapper>
          <PinWrapper onClick={() => this.onPinClick(currentQuestionUUID)}>
            <PinText>{isPinned ? PINNED_TEXT : PIN_TEXT}</PinText>
            <PinButtonWrapper>
              <PinButton
                src={isPinned ? PINNED_ICON_WHITE : PIN_ICON}
              />
            </PinButtonWrapper>
          </PinWrapper>
        </TextWrapper>}
        <QuestionCard
          className='active__learning-block component__backdrop mb-2'
          isAssessmentQuizExam={isAssessmentQuizExam}
        >
          {structuredText}
          {
            this.props.lesson_text
              ? <React.Fragment>
                <div className='pb-3 font__md'>
                  <LessonText>
                    {this.props.lesson_text}
                  </LessonText>
                </div>
                <hr className='active__learning-hr' />
              </React.Fragment>
              : null
          }

          <div className='row align-items-center pt-4'>
            {this.props.display_illustration}
            {/* --- Added Desmos Here --- */}
            {this.props.desmosgraph}
            {
              this.props.question_text
                ? <div className='col que-text'>
                  <div className='font__md'>
                    <LessonText>
                      {this.props.question_text}
                    </LessonText>
                  </div>
                </div>
                : null
            }
          </div>
          {this.props.gMath}
          {jdoodleEditor &&
            <div className='mb-2'>
              <JDoodleCompiler
                key={questionUUID}
                questionUUID={questionUUID}
                starterCode={starterCode}
                isPartOfQuestion
              />
            </div>}
          <div>
            <div className='active__learning-options mx-auto row no-gutters'
              id='multiple_checkbox_auto'>
              {options}
            </div>
          </div>
          {isExamOrQuiz && <ProctorioExamResources
            reviewMode={reviewMode}
            examResources={examResources}
            resourceIcons={resourceIcons}
          />}
          {showSolution && questionUUID === solutionQuestionUUID &&
          <ShowSolution solutionData={solution}
            filterMessage={filterMessage} correctProps={correctProps}
            incorrectProps={incorrectProps} isExamReviewMode={reviewMode && isExam}
            explanation={getExplanation(specificExplanation, generalExplanation)}
            isAssessmentQuizExam={isAssessmentQuizExam}
            isExamOrQuizReviewMode={reviewMode && (isExam || isQuiz)}
          />}
          {!isExam && !isQuiz && !showSolution && result &&
          <ShowFeedback
            studentAnswer={studentAnswer}
            guessWorkCorrectAnswer={this.getCorrectAnswer()}
            sectionType={typeOfQuestion}
            result={result}
            correctProps={correctProps}
            incorrectProps={incorrectProps} />}
        </QuestionCard>

        <PaginationWrapper
          isAssessmentQuizExam={isAssessmentQuizExam}
          isAnsweringDisabled={isAnsweringDisabled}
          isLastQuestionOfOngoingExam={isLastQuestionOfOngoingExam}
          isFirstQuestion={isFirstQuestion}
          showfinish={showfinish}
          showPrevious={showPrevious}
          reviewMode={reviewMode}
        >
          {isLastQuestionAndNotAssessment &&
          <ReviewWorkButton
            isDisabled={isNextDisabled}
            updateCurrentQuestionUUId={this.props.updateCurrentQuestionUUId}
            setFilteredQuestionInd={this.props.setFilteredQuestionInd}
            activeSectionUUID={activeSectionUUID}
            firstQuestionUUID={firstQuestionUUID} />}

          {!this.props.review_mode && !isAssessmentQuizExam
            ? isStudentProgressBusy ? (
              <CourseButton
                className={{
                  'btn-primary': true,
                  'mx-1': true,
                  check_answer: true
                }}
                disabled
              >
                <i className='fa fa-spinner fa-spin fa-margin' /> <span>Saving</span>
              </CourseButton>
            ) : (
              !(isExam || isQuiz) &&
                <CourseButton
                  className={{
                    'btn-primary': true,
                    'mx-1': true,
                    check_answer: true,
                    'd-block': !!this.state.isDisplayCheckOrSubmit,
                    'd-none': !this.state.isDisplayCheckOrSubmit
                  }}
                  onClick={this.handleFormSubmit}
                  dataCypress='checkOrSubmitButton'
                  disabled={this.state.isNextDisabled}
                >
                  {checkOrSubmit}
                </CourseButton>
            )
            : null
          }

          {this.state.isDisplayNext && !isAssessmentQuizExam &&
            !this.props.review_mode && !isStudentProgressBusy && !showfinish &&
            <QuestionNextButton {...this.props}
              isNextDisabled={this.state.isNextDisabled}
              studentAnswerRes={studentAnswerRes}
            />}
          {isAssessmentQuizExam && !isFirstQuestion &&
            <QuestionPreviousButton {...this.props}
              isAssessmentQuizExam={isAssessmentQuizExam} />}
          {!this.props.review_mode && !showfinish &&
            isAssessmentQuizExam &&
            <QuestionNextButton {...this.props}
              studentAnswerRes={studentAnswerRes}
              isAssessmentQuizExam={isAssessmentQuizExam}
              isNextDisabled={isNextDisabled}
            />}
          {(this.props.review_mode) && <ReviewNextButton {...this.props} />}
        </PaginationWrapper>

      </React.Fragment>
    )
  }
}

MultipleChoiceQuestionQuizExam.contextType = Context
export default QuestionComponent(MultipleChoiceQuestionQuizExam)
