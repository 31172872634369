import React, { Component } from 'react'
import Checkbox from '../FormComponent/Checkbox'
import QuestionComponent from '../QuestionComponent/QuestionComponent'
import { emitter } from '../Emitter/Emitter'
import Context from '../Context/Context'
import QuestionNextButton from '../QuestionComponent/QuestionNextButton'
import QuestionPreviousButton from '../QuestionComponent/QuestionPreviousButton'
import FeedbackToast from '../FeedbackToast/FeedbackToast'
import classNames from 'classnames'
import {
  ACTIVITY_TYPES } from '../../Constants/sectionType'
import {
  ON_STUDENT_ANSWER,
  ON_SUBMIT_ANSWER
} from '../../Constants/emitterKeys'
import MathJax from '../MathJax/MathJax'
import { findAnswer } from '../../utilities/questions'
import {
  PaginationWrapper,
  TextWrapper,
  QuestionText,
  QuestionCard,
  PinWrapper,
  PinText,
  PinButtonWrapper,
  PinButton,
  LessonText,
  AdaptationStatement,
  QuestionTextAL,
  FadeIn
} from './styles'
import {
  PIN_ICON,
  PINNED_ICON_WHITE
} from '../../Constants/progressBarIconSource'
import { PIN_TEXT, PINNED_TEXT } from '../../Constants/index'
import ShowFeedback from '../ShowFeedBack/ShowFeedBack'
import { CORRECT, INCORRECT } from '../../Constants/result'
import { getStudentAnswersKey } from '../../utilities/contextKeys'
import JDoodleCompiler from '../JDoodleCompiler/JDoodleCompiler'
import QuestionCheckButton from '../QuestionComponent/QuestionCheckButton'

class MultipleChoiceQuestion extends Component {
  constructor (props) {
    super(props)
    this.toggleCheckbox = this.toggleCheckbox.bind(this)
    this.checkSolution = this.checkSolution.bind(this)
    this.createCheckbox = this.createCheckbox.bind(this)
    this.createCheckboxes = this.createCheckboxes.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.checkPrevProps = this.checkPrevProps.bind(this)
    this.revealAnswer = this.revealAnswer.bind(this)
    this.setQuestionProgress = this.setQuestionProgress.bind(this)

    this.state = {
      result: '',
      isCheckButton: true,
      tryAttempt: 0,
      findAns: null,
      showAttempt: false,
      shouldShowSolution: false,
      isNextDisabled: true,
      correctIncorrectClass: 'ans-correct-partial',
      selectedOption: '',
      isCorrect: false,
      checkAnswer: false,
      studentAnswerRes: {},
      isPinned: false,
      showToast: false
    }
    this.findAns = null
  }

  componentWillMount () {
    this.selectedCheckboxes = new Set()
  }

  componentDidUpdate (prevProps) {
    const {
      isRevealed: prevIsRevealed,
      question: {
        Question_uuid: prevQuestionUUID
      }
    } = prevProps
    const { currentQuestionUUID } = this.props
    const { question: { Question_uuid: questionUUID } } = this.props
    this.checkPrevProps({ prevIsRevealed, prevQuestionUUID })
    if (prevQuestionUUID !== questionUUID) {
      const { studentData: { pinnedQuestions } } = this.context
      this.setState(
        { isPinned: pinnedQuestions.includes(currentQuestionUUID) }
      )
      this.selectedCheckboxes.clear()
    }
  }

  checkPrevProps (prevProps) {
    const { prevIsRevealed, prevQuestionUUID } = prevProps
    const {
      isRevealed,
      type_of_question: sectionType,
      question: {
        Question_uuid: questionUUID
      },
      make_reveal_false: makeRevealFalse
    } = this.props

    if (prevIsRevealed !== isRevealed && isRevealed) {
      this.revealAnswer({ sectionType, makeRevealFalse })
    } else if (prevQuestionUUID !== questionUUID) {
      this.setQuestionProgress()
    }
  }

  revealAnswer (currentProps) {
    const { makeRevealFalse } = currentProps
    this.selectedCheckboxes.clear()
    const { question: { answer: systemAnswer } } = this.props
    const findAns = systemAnswer.map((num) => num - 1)
    if (findAns && findAns.length) {
      findAns.forEach((val) => {
        this.toggleCheckbox(val)
      })
    }
    this.setState({
      findAns,
      selectedValues: findAns
    }, () => {
      this.checkSolution()
      this.setState({ showToast: false })
      makeRevealFalse()
    })
  }

  setQuestionProgress () {
    const {
      question: {
        Question_uuid: questionUUID
      }
    } = this.props

    const studentAnswers = this.getStudentAnswers()

    const savedAnswer = findAnswer(questionUUID, studentAnswers)
    if (!savedAnswer) {
      return this.setState({
        result: '',
        isCheckButton: true,
        tryAttempt: 0,
        findAns: null,
        showAttempt: false,
        isNextDisabled: true,
        correctIncorrectClass: 'ans-correct-partial',
        selectedOption: '',
        isCorrect: false,
        checkAnswer: false,
        studentAnswerRes: {},
        showToast: false
      })
    }

    const { answer: findAns, correct } = savedAnswer
    this.selectedCheckboxes = new Set(findAns)

    this.setState({
      checkAnswer: false,
      showToast: false,
      findAns,
      result: '',
      correctIncorrectClass: (correct
        ? 'ans-correct' : 'incorrect ans-incorrect'),
      isNextDisabled: !correct
    }, this.checkSolution)
  }

  toggleCheckbox (index) {
    this.setState({ showToast: false })
    const { make_reveal_false: makeRevealFalse } = this.props
    /* ------- Clear TIme out on Reveal Answer Click ----- */
    clearTimeout(this.timeOutFuncID)
    /* ------- Clear TIme out on Reveal Answer Click ----- */
    const {
      question: {
        answer: systemAnswer,
        only_allow_one: onlyAllowOne
      },
      isRevealed
    } = this.props
    const { size } = this.selectedCheckboxes
    const { findAns } = this.state

    if (this.selectedCheckboxes.has(index)) {
      this.selectedCheckboxes.delete(index)
    } else if (onlyAllowOne && onlyAllowOne === true &&
      (size === 0 || size === 1) && typeof (index) !== 'undefined'
    ) {
      this.selectedCheckboxes.clear()
      this.selectedCheckboxes.add(index)
      !isRevealed && this.checkSolution()
    } else if (!onlyAllowOne && typeof (index) !== 'undefined') {
      this.selectedCheckboxes.add(index)
    }
    this.setState({
      checkAnswer: false,
      findAns: Array.from(this.selectedCheckboxes),
      isNextDisabled: true,
      selectedOption: Array.from(this.selectedCheckboxes)
    })
    const currentLoopIndex = (index + 1).toString()
    if (findAns && systemAnswer.indexOf(currentLoopIndex) > -1 &&
      findAns.length !== systemAnswer.length
    ) {
      this.setState({ result: '' })
    }
    setTimeout(() => {
      this.setState({ showToast: true })
    }, 200)
    makeRevealFalse()
  }

  componentDidMount () {
    const { question: { Question_uuid: questionUUID } } = this.props
    const {
      studentData: { pinnedQuestions }
    } = this.context
    this.setState({ isPinned: pinnedQuestions.includes(questionUUID) })
    const studentAnswers = this.getStudentAnswers()

    const savedAnswer = findAnswer(questionUUID, studentAnswers)
    if (!savedAnswer) return

    const { answer: findAns, correct } = savedAnswer
    this.selectedCheckboxes = new Set(findAns)
    this.setState({
      checkAnswer: false,
      findAns,
      result: '',
      correctIncorrectClass: (correct
        ? 'ans-correct' : 'incorrect ans-incorrect'),
      isNextDisabled: !correct
    }, this.checkSolution)
  }

  getStudentAnswers = () => {
    const {
      currentChapter: { type },
      studentData
    } = this.context
    const studentAnswersKey = getStudentAnswersKey(type)
    return studentData[studentAnswersKey]
  }

  getSavedAnswer = () => (
    Array.from(this.selectedCheckboxes).length
      ? Array.from(this.selectedCheckboxes)
      : Array.isArray(this.state.findAns)
        ? this.state.findAns : []
  )

  checkSolution () {
    const { question: { answer: systemAnswer } } = this.props
    const answers = this.getSavedAnswer()
    const correctAnswerDec = systemAnswer.map(num => num - 1)
    const correctAnsSorted = correctAnswerDec.sort()
    const answerSorted = answers.sort()
    const answerMatched = JSON.stringify(answerSorted) === JSON.stringify(correctAnsSorted)
    answerMatched ? this.changeContextData(true)
      : this.onUnmatchedAnswer(correctAnswerDec)
  }

  getAnswerLog () {
    const { question: { options } } = this.props
    const answers = Array.from(this.selectedCheckboxes)
    let answerLog = ''
    const ansOptions = options || []

    answers.forEach(function (num) {
      num = String(num)
      if (!ansOptions[num]) return
      answerLog += ansOptions[num]
    })
    this.setState({ selectedValues: answers })
    return answerLog
  }

  changeContextData (isCorrect) {
    const { result } = this.state
    const answerLog = this.getAnswerLog()
    const correctIncorrectClass = isCorrect ? 'ans-correct'
      : 'incorrect ans-incorrect'
    const newResult = isCorrect ? CORRECT : INCORRECT
    if (result !== newResult) {
      window.outlierLog(`${newResult} Answer`, answerLog)
    }
    this.setState({
      result: newResult,
      isNextDisabled: !isCorrect,
      isCheckButton: isCorrect,
      correctIncorrectClass,
      isCorrect,
      ...(!isCorrect && {
        showAttempt: true
      })
    }, this.onCheckAnswer)
  }

  onUnmatchedAnswer (correctAnswerDec) {
    const { incorrectAnsIndex } = this.props
    const answers = Array.from(this.selectedCheckboxes)
    const indexArr = answers.filter(item => {
      return correctAnswerDec.indexOf(item) === -1
    })

    incorrectAnsIndex(indexArr)
    this.changeContextData(false)
  }

  getCheckboxCorrectState = (index, systemAnswer) => {
    const currentLoopIndex = (index + 1).toString()
    const answerIndex = systemAnswer.indexOf(currentLoopIndex)
    if (answerIndex === -1) return 'false'
    return 'true'
  }

  createCheckbox (label, index) {
    const {
      checkAnswer,
      isCorrect,
      correctIncorrectClass,
      findAns
    } = this.state
    const {
      reviewMode,
      question,
      question: {
        answer: systemAnswer,
        multipleChoiceHideFeedbackUi
      },
      type_of_question: sectionType
    } = this.props
    const hideCorrectIndicator = multipleChoiceHideFeedbackUi && question.options.length === 1
    const { isSidePanelOpen } = this.context
    const isDisabled = checkAnswer && isCorrect
    const cbCorrectState = checkAnswer
      ? this.getCheckboxCorrectState(index, systemAnswer)
      : 'null'
    const checked =
      !!(findAns && Array.isArray(findAns) && findAns.indexOf(index) > -1)
    return <Checkbox
      label={label}
      disabled={isDisabled}
      handleCheckboxChange={this.toggleCheckbox}
      isLastIndex={false}
      key={index}
      value={index}
      isALRedesign
      dataCypress='MCQButton'
      checked={checked}
      fAnswer={findAns}
      sysAnswer={systemAnswer}
      isSidePanelOpen={isSidePanelOpen}
      isCorrect={cbCorrectState}
      correctIncorrect={correctIncorrectClass}
      reviewMode={reviewMode}
      type_of_question={sectionType}
      hideCorrectIndicator={hideCorrectIndicator}
    />
  }

  createCheckboxes (items) {
    return items.map(this.createCheckbox)
  }

  // Should return false if the action was not allowed.
  handleKeyPress (type) {
    const { showPrevious, onPreviousButtonClick,
      onNextButtonClick } = this.props
    const { isNextDisabled, studentAnswerRes } = this.state

    // Typically from pressing the left arrow
    if (type && type === 'previous') {
      if (!showPrevious) return false
      onPreviousButtonClick()
      return true
    }

    // Everything else is interpreted as next or advance forward
    if (isNextDisabled) return false
    onNextButtonClick(studentAnswerRes)
    return true
  }

  onPinClick (currentQuestionUUID) {
    const {
      type_of_question: typeOfQuestion
    } = this.props
    const {
      studentData,
      updateContext
    } = this.context
    const studentAnswers = this.getStudentAnswers()
    const findAns = findAnswer(currentQuestionUUID, studentAnswers)
    const { pinnedQuestions } = studentData
    const { isPinned } = this.state
    const currentIndex = pinnedQuestions.indexOf(currentQuestionUUID)
    const answer = {
      uuid: currentQuestionUUID,
      type: ACTIVITY_TYPES[typeOfQuestion],
      questionActivity: { pinned: false },
      ...(findAns?.correct && {
        answer: findAns.answer,
        correct: findAns.correct
      })
    }
    if (isPinned) {
      pinnedQuestions.splice(currentIndex, 1)
    } else {
      answer.questionActivity.pinned = true
      pinnedQuestions.push(currentQuestionUUID)
    }
    emitter.emit(ON_SUBMIT_ANSWER, answer)
    studentData.pinnedQuestions = pinnedQuestions
    updateContext({ studentData })
    this.setState({ isPinned: !isPinned })
  }

  onCheckAnswer = () => {
    const {
      checkAnswer,
      isCorrect,
      isPinned: pinned,
      tryAttempt
    } = this.state
    const {
      question: { Question_uuid: questionUUID },
      type_of_question: typeOfQuestion,
      saveProgress
    } = this.props
    if (!checkAnswer) {
      // change context data using event
      const answers = this.getSavedAnswer()
      const testRes = {
        uuid: questionUUID,
        answer: answers,
        type: ACTIVITY_TYPES[typeOfQuestion],
        questionActivity: { pinned },
        correct: isCorrect
      }
      emitter.emit(ON_STUDENT_ANSWER, testRes)
      saveProgress(testRes)
      return this.setState({
        checkAnswer: true,
        isCheckButton: isCorrect,
        tryAttempt: isCorrect ? 0 : tryAttempt + 1,
        studentAnswerRes: testRes
      })
    }
    this.selectedCheckboxes.clear()
    this.setState({
      checkAnswer: false,
      isCheckButton: true,
      findAns: null
    })
  }

  render () {
    const {
      studentAnswerRes,
      isCorrect,
      isCheckButton,
      tryAttempt,
      showAttempt,
      checkAnswer,
      result,
      isPinned,
      showToast,
      selectedOption
    } = this.state
    const {
      question,
      question: {
        answer: systemAnswer,
        jdoodleEditor,
        starterCode,
        text_when_stuck: textWhenStuck,
        only_allow_one: onlyAllowOne,
        adaptationStatement,
        multipleChoiceHideFeedbackUi
      },
      type_of_question: sectionType,
      lesson_text: lessonText,
      structuredText,
      display_illustration: displayIllustration,
      question_text: questionText,
      desmosgraph,
      gMath,
      displayReveal,
      correctProps,
      filteredActvQuestInd,
      incorrectProps
    } = this.props
    const { Question_uuid: questionUUID } = question
    const currentQuestionUUID = questionUUID
    const { isLastQuestionAnswered } = this.context
    const options = this.createCheckboxes(question.options)
    const queTextClass = classNames({
      'que-text': false,
      'active-que-text': false,
      col: true
    })
    const isAnswerCorrectOrGreaterAttempt = isCorrect || tryAttempt >= 3
    const isSelectedEqualToAnswer = selectedOption?.length === systemAnswer?.length
    const showCheckButton = !onlyAllowOne
    const hideCorrectIndicator = multipleChoiceHideFeedbackUi && question.options.length === 1
    const hideFeedbackButton = hideCorrectIndicator && (isCorrect || checkAnswer)

    return (
      <>
        <>
          {!hideCorrectIndicator && showToast &&
          isAnswerCorrectOrGreaterAttempt && isSelectedEqualToAnswer &&
          checkAnswer &&
          <FeedbackToast
            showToast={showToast}
            isCorrect={isCorrect}
          />}
          <TextWrapper isALRedesign >
            <QuestionText isALRedesign >
            Card {filteredActvQuestInd + 1}
            </QuestionText>
            <PinWrapper
              isALRedesign
              onClick={() => this.onPinClick(currentQuestionUUID)}
            >
              <PinText>{isPinned ? PINNED_TEXT : PIN_TEXT}</PinText>
              <PinButtonWrapper
                isALRedesign
              >
                <PinButton
                  src={isPinned ? PINNED_ICON_WHITE : PIN_ICON}
                  data-testid='pinButton'
                />
              </PinButtonWrapper>
            </PinWrapper>
          </TextWrapper>
        </>
        <QuestionCard
          className='active__learning-block component__backdrop mb-2'
          isALRedesign
        >
          {structuredText}
          {
            !!lessonText &&
              <>
                <LessonText
                  className='font__md'
                  isALRedesign>
                  <div className='font__md'>
                    {lessonText}
                  </div>
                </LessonText>
              </>
          }

          <QuestionTextAL
            className='row align-items-center pt-4'
            isALRedesign
          >
            {displayIllustration}
            {/* --- Added Desmos Here --- */}
            {desmosgraph}
            {
              !!questionText &&
                <div className={queTextClass}>
                  <div className='font__md'>
                    <div>{questionText}</div>
                  </div>
                </div>
            }
          </QuestionTextAL>
          {gMath}
          {jdoodleEditor &&
            <div className='mb-2'>
              <JDoodleCompiler
                key={questionUUID}
                questionUUID={questionUUID}
                isALRedesign
                starterCode={starterCode}
              />
            </div>}
          {!hideFeedbackButton && (
            <div>
              <div
                className='active__learning-options mx-auto row no-gutters'
                id='multiple_checkbox_auto'
              >
                {options}
              </div>
            </div>
          )}
          <FadeIn
            showFeedback={isCorrect || result}
            hideCorrectIndicator={hideCorrectIndicator}
          >
            {result &&
              checkAnswer && (
              <ShowFeedback
                isALRedesign
                textWhenStuck={textWhenStuck}
                showStuck={showAttempt && tryAttempt >= 3}
                studentAnswer={question?.options[studentAnswerRes?.answer]}
                sectionType={sectionType}
                result={result}
                correctProps={correctProps}
                incorrectProps={incorrectProps}
                showSolutionHandler={displayReveal}
                hideCorrectIndicator={hideCorrectIndicator}
              />
            )}
          </FadeIn>
        </QuestionCard>
        <PaginationWrapper isALRedesign >
          {showCheckButton &&
          <QuestionCheckButton
            handleCheck={this.checkSolution}
            isDisabled={checkAnswer && isCorrect}
            tryAgain={!isCheckButton}
          />
          }
          <QuestionPreviousButton {...this.props}
            isALRedesign />
          {!isLastQuestionAnswered &&
            <QuestionNextButton
              {...this.props}
              isALRedesign
              isNextDisabled={!checkAnswer || !isCorrect}
              studentAnswerRes={studentAnswerRes}
            />}
        </PaginationWrapper>
        {
          adaptationStatement &&
            <AdaptationStatement
              isALRedesign
              className='active__learning-pagination adaptation
                bottom-fixed text-center'>
              <div className='adaptation-statement'>
                <MathJax math={adaptationStatement} />
              </div>
            </AdaptationStatement>
        }
      </>
    )
  }
}

MultipleChoiceQuestion.contextType = Context
export default QuestionComponent(MultipleChoiceQuestion)
