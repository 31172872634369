export const CALCULUS = 'Calculus I'
export const CALCULUS_FP = 'Calculus I - FP'
export const CALCULUS_EXT = 'Calculus I - EXT'
export const CALCULUS_GGU = 'Calculus I - GGU'
export const CALCULUS_COURSES = [CALCULUS, CALCULUS_EXT, CALCULUS_GGU, CALCULUS_FP]
export const PSYCHOLOGY = 'Introduction to Psychology'
export const PSYCHOLOGY_FP = 'Introduction to Psychology - FP'
export const ASTRONOMY = 'Introduction to Astronomy'
export const STATISTICS = 'Introduction to Statistics'
export const PHILOSOPHY = 'Introduction to Philosophy'
export const MICROECONOMICS = 'Introduction to Microeconomics'
export const FINANCIAL_ACCOUNTING = 'Introduction to Financial Accounting'
export const SOCIOLOGY = 'Introduction to Sociology'
export const PRECALCULUS = 'Precalculus'
export const COLLEGE_WRITING_I = 'College Writing I'
export const PRINCIPLES_OF_ECONOMICS = 'Principles of Economics'
export const COLLEGE_ALGEBRA = 'College Algebra'
export const BUSINESS = 'Introduction to Business'
export const VIP = 'VIP'

// exclude these courses from being alerted to Success team on browser focus change
export const EXCLUDED_COURSES = [
  'Intro to Astronomy',
  'Calculus I',
  'College Algebra',
  'Precalculus',
  'Intro to Statistics'
]
