export const getStudentSemester = semesters => {
  if (!semesters?.length) return null

  return semesters.sort((a, b) => {
    return new Date(b.openEnrollment) - new Date(a.openEnrollment)
  })?.[0] || null
}

export const getCurrentAndFutureSemesters = (
  allGGUSemesters, studentSemesterOpenEnrollmentDate
) => {
  if (!allGGUSemesters?.length || !studentSemesterOpenEnrollmentDate) return null

  return allGGUSemesters.filter(semester => {
    return new Date(semester?.openEnrollment) >= studentSemesterOpenEnrollmentDate
  })
}

export const getCurrentGGUSemester = params => {
  const { allGGUSemesters, studentAssignedSemesters } = params || {}
  if (!studentAssignedSemesters?.length || !allGGUSemesters?.length) return null

  const studentSemester = getStudentSemester(studentAssignedSemesters)
  if (!studentSemester) return null

  const {
    semesterStart: studentSemesterStart,
    openEnrollment: studentSemesterOpenEnrollment
  } = studentSemester
  const studentSemesterStartDate = new Date(studentSemesterStart)
  const studentSemesterOpenEnrollmentDate = new Date(studentSemesterOpenEnrollment)

  const now = new Date()
  if (now < studentSemesterStartDate) return studentSemester

  const eligibleSemesters = getCurrentAndFutureSemesters(
    allGGUSemesters, studentSemesterOpenEnrollmentDate
  )
  if (!eligibleSemesters?.length) return null

  const sortedSemesters = eligibleSemesters.sort((a, b) => {
    return new Date(a?.openEnrollment) - new Date(b?.openEnrollment)
  })

  const currentSemester = sortedSemesters.find((semester, index) => {
    const { openEnrollment, closeEnrollment } = semester || {}
    const openEnrollmentDate = new Date(openEnrollment)
    const closeEnrollmentDate = new Date(closeEnrollment)

    if (now >= openEnrollmentDate && now <= closeEnrollmentDate) return true

    const nextSemesterOpenEnrollment = sortedSemesters[index + 1]?.openEnrollment
    if (now > closeEnrollmentDate &&
      (!nextSemesterOpenEnrollment || now <= new Date(nextSemesterOpenEnrollment))) return true

    return false
  })

  return currentSemester || null
}

export const getCurrentProspect = prospects => prospects?.[0] || null
