import React, { useContext, useState } from 'react'
import { Widget } from '@typeform/embed-react'
import MobileDetect from 'mobile-detect'
import DeviceUUID from 'device-uuid'
import { Auth0Context } from '../Auth0Provider/Auth0Provider'
import api from '../../api'
import { PWA_ONBOARDING } from '../../Constants/typeformId'
import { getDeviceType, getOperatingSystem } from '../../utilities/deviceUtils'
import { emitter } from '../Emitter/Emitter'
import { ON_TRACK_STUDENT_EVENT } from '../../Constants/emitterKeys'
import { MULTIPLE, ONCE } from '../../Constants/frequency'
import './css/OnboardingPwaForm.css'
import { getIpAddress, getUTMParametersFromUrl } from '../../utilities'
import {
  COLLEGE_SUCCESS_WELCOME_PAGE_ID,
  pageIdToPageNameMapping,
  pageIdToEventNameMapping,
  trackAllowedPageIds
} from '../../Constants/pwaOnboarding'

const deviceUUID = new DeviceUUID.DeviceUUID()

const getEventProperties = async ({ courseName, pageId, studentId }) => {
  const pageNumber = pageIdToPageNameMapping[pageId]

  const deviceId = deviceUUID.get()
  const { platform } = deviceUUID.parse()

  const ipAddress = await getIpAddress()
  const deviceFamily = new MobileDetect(window.navigator.userAgent)
  const url = window.location.href

  return {
    ...(pageNumber ? { page_name: pageNumber } : {}),
    course: courseName,
    certificate: '',
    product: '',
    employer: '',
    url,
    time_stamp: new Date().getTime(),
    ip_address: ipAddress,
    device_family: deviceFamily.ua,
    device_id: deviceId,
    device_type: getDeviceType(),
    os: getOperatingSystem(),
    platform,
    event_id: pageId,
    session_id: '',
    user_id: studentId
  }
}

const getEventData = async ({ courseName, pageId, studentId }) => {
  const eventName = pageIdToEventNameMapping[pageId]
  const properties = await getEventProperties({ courseName, pageId, studentId })

  const eventData = {
    properties,
    event: eventName,
    frequency: MULTIPLE
  }

  return eventData
}

const OnboardingPwaForm = ({ props }) => {
  const { studentId } = useContext(Auth0Context)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const { courseData, user, cohortData, dateTOS } = props

  const handleTrackPage = async ({ ref: pageId }) => {
    const shouldTrackPage = trackAllowedPageIds.includes(pageId)
    if (!shouldTrackPage) return

    const eventData = await getEventData({
      courseName: courseData.title,
      pageId,
      studentId
    })

    try {
      emitter.emit(ON_TRACK_STUDENT_EVENT, eventData)
    } catch (e) {
      console.error(e)
    }
  }

  const handleReady = async () => {
    handleTrackPage({ ref: COLLEGE_SUCCESS_WELCOME_PAGE_ID })
  }

  const questionSubmitHandler = async (response) => {
    const { responseId } = response
    const { url, ...sharedProperties } = await getEventProperties({
      courseName: courseData.title,
      studentId
    })

    const studentJourneySubmittedProperties = { url }

    const enrollmentCompletedProperties = {
      audit: 'No',
      name: cohortData.name,
      product_id: '',
      quantity: '',
      sku: '',
      acValue: '',
      course_length: cohortData.duration,
      start_date: new Date(dateTOS).getTime(),
      price: 0,
      college_success_start_date: cohortData.startDate,
      college_success_enrollment: 'true',
      college_success_optin: cohortData.endDate
    }

    const pwaEventsBody = {
      formId: PWA_ONBOARDING,
      responseId,
      studentJourneySubmittedProperties,
      enrollmentCompletedProperties,
      sharedProperties: {
        ...sharedProperties,
        ...getUTMParametersFromUrl()
      },
      frequency: ONCE
    }

    try {
      await Promise.all([
        api.updateStudentData({ pwaOnboard: true }),
        api.submitCollegeSuccessPWAEvents(pwaEventsBody)
      ])

      setFormSubmitted(true)
      window.location.reload()
    } catch (e) {
      console.log('error while updating student data :', e)
    }
  }

  return (
    <>
      {formSubmitted
        ? <div className='pwa-form'>
          <div className='pwa-form-content'>
            <p className='pwa-form-text'>
              Registration complete! Redirecting to College Success course home.
            </p>
          </div>
        </div>
        : <Widget id={PWA_ONBOARDING} style={{ width: '100%', height: '100vh' }}
          onSubmit={questionSubmitHandler}
          onReady={handleReady}
          onQuestionChanged={handleTrackPage}
          data-testid='onBoardTypeform'
          hidden={{
            student_email: user.email,
            student_name: user.name
          }}
        />
      }
    </>
  )
}

export default OnboardingPwaForm
