export const getUniqueInstructorsFromQuestionSet = questionSet => {
  const instructors = []
  questionSet.forEach(question => {
    const {
      instructor,
      instructor: { instructor_uuid: instructorUUID }
    } = question

    if (Array.isArray(instructor)) return
    const found = instructors.some(i => i.instructor_uuid === instructorUUID)
    if (!found) instructors.push(instructor)
  })

  return instructors
}
