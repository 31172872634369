import React, { Component } from 'react'
import ChapterList from '../ChapterList/ChapterList'
import './css/course-home.css'
import Context from '../Context/Context'
import {
  getLastProgressedChapterUUID,
  removeQuizzesFromChapters
} from '../../utilities/chapterUtils'
import { Collapse } from 'reactstrap'
import {
  getSecondsFromTime, getTimeFromSeconds
} from '../../utilities/dateTimeUtils'
import { PRACTICE_TERM_COMPLETE,
  ACTIVE_LEARNING_COMPLETE, LECTURE_COMPLETE,
  LECTURE_VIDEO_PROGRESS } from '../../Constants/studentContext'
import { PITTS } from '../../Constants/creditGrantingInstitutions'
import { PROBLEM_BANK, ACTIVE_LEARNING,
  ACTIVITY_TYPES } from '../../Constants/sectionType'
import {
  CourseHomeHeader,
  CourseTitle,
  ViewResourcesLink,
  GlobalStyle,
  ShowHideText,
  ChapterToggleIcon,
  CardHeading,
  ProgressValue,
  CourseHomeContainer,
  CourseTitleContainer,
  CourseWork,
  ImageStyler,
  CourseTitleGradeContainer
} from './styles'
import {
  getCohortStartEndDateShort,
  getIsVIPCohort,
  isInProgressBeforeCutOffDate,
  getProjectedFinalGradeText
} from '../../utilities/courseUtils'
import api from '../../api'
import config from '../../config'
import { emitter } from '../Emitter/Emitter'
import { ON_NAVIGATE_TO } from './../../Constants/emitterKeys'
import {
  VIEW_TOOLKIT_LABEL
} from '../../Constants'
import { hasCourseProgress } from '../../utilities/studentProgressUtils'
import ProgressTimeLine from '../ProgressTimeLine/ProgressTimeLine'
import ScheduleSection from '../ResourcesSection/ScheduleSection'
import {
  AUDIT,
  AUDIT_TRANSCRIPT
} from '../../Constants/studentStatus'
import ReadMoreToggler from '../ReadMoreToggler/ReadMoreToggler'
import GradeMessage from './GradeMessage'

class CourseHome extends Component {
  state = {
    isPittsCreditInstitution: false,
    gradeText: '',
    chapterSectionsData: null,
    open: false,
    showCalendarDropdown: false,
    projectedGrade: '',
    currentGrade: ''
  }

  componentDidMount () {
    if (config.isPreviewCourse) return
    this.fetchPittsburghCohortStatus()
    this.fetchProjectedGrade()
    this.fetchSectionCountData()
    this.setFirstLoginDate()
    if (config.hasInTimeGradeFlag) { this.fetchCurrentGrade() }
  }

  setFirstLoginDate = async () => {
    const { isAdmin, latestCohort, updateContext, studentData } = this.context
    const { attemptID, dateFirstLogin } = latestCohort || {}

    const progressMade = hasCourseProgress(studentData)
    const shouldNotSendFirstLoginDate = !!(
      isAdmin || progressMade || dateFirstLogin || !attemptID
    )
    if (shouldNotSendFirstLoginDate) return

    const updatedRecord = await api.setFirstLoginDate(attemptID)
    if (!updatedRecord) return

    const {
      dateFirstLogin: updatedFirstDateLogin
    } = updatedRecord

    const updatedLatestCohort = {
      ...latestCohort,
      dateFirstLogin: updatedFirstDateLogin
    }

    updateContext({
      latestCohort: updatedLatestCohort
    })
  }

  fetchSectionCountData = async () => {
    const sectionsData = await api.getSectionCountData(config.courseId)
    let chapterSectionsData = sectionsData

    const { isAuditor, latestCohort, activeCourse } = this.context
    if (isAuditor) {
      const { dateStart, finalExamEndTime, cohortEndTime, duration } = latestCohort || {}
      const isCohortInProgress = isInProgressBeforeCutOffDate({
        dateStart,
        finalExamEndTime,
        cohortEndTime,
        duration,
        officialCourseName: activeCourse?.name
      })
      if (!isCohortInProgress) {
        chapterSectionsData = removeQuizzesFromChapters(sectionsData)
      }
    }

    this.setState({ chapterSectionsData })
  }

  fetchPittsburghCohortStatus = () => {
    const {
      cohortData: { creditGrantingInstitution }
    } = this.context
    const isPittsCreditInstitution = creditGrantingInstitution === PITTS

    this.setState({ isPittsCreditInstitution })
  }

  fetchProjectedFinalGradeText = () => {
    const { cohortData: { startDate } } = this.context

    const {
      cohortExamDates
    } = this.props
    const {
      cohortEndDate,
      courseEndDate
    } = cohortExamDates || {}

    const gradeText = getProjectedFinalGradeText({
      cohortStartDate: startDate,
      cohortEndDate,
      courseEndDate
    })

    return gradeText
  }

  fetchProjectedGrade = async () => {
    const STUDENT_STATUS_MAPPING = {
      [AUDIT]: 'Audit',
      [AUDIT_TRANSCRIPT]: 'Audit',
      Withdraw: 'W'
    }
    const {
      cohortData: { cohortID, studentStatus },
      user: { email }
    } = this.context

    const gradeText = this.fetchProjectedFinalGradeText()
    if (!gradeText) return

    this.setState({ gradeText })

    const mappedStatus = STUDENT_STATUS_MAPPING[studentStatus]
    if (mappedStatus) return this.setState({ projectedGrade: mappedStatus })

    const projectedGrade = await api.getProjectedGrade(
      config.courseId,
      cohortID,
      email
    )

    this.setState({ projectedGrade })
  }

  fetchCurrentGrade = async () => {
    const {
      cohortData: { name: cohortName },
      user: { email }
    } = this.context

    const grade = await api.getStudentCurrentGrade(
      config.courseId,
      cohortName,
      email
    )
    const currentGrade = Object.values(grade)[0]
    this.setState({ currentGrade })
  }

  calculateSectionActivitiesCount = () => {
    const { chapterSectionsData } = this.state
    let activeLearningCount = 0
    let guessWorkCount = 0
    let practiceTermCount = 0
    let videoDuration = 0
    const isCalculus = config.course.isCalculus
    chapterSectionsData &&
    chapterSectionsData.chapters.forEach(
      chapter => chapter.sections && chapter.sections.forEach(
        section => {
          section.guessWorkCount && guessWorkCount++
          if (isCalculus) {
            practiceTermCount += section.practiceExerciseCount
            section.videoDuration &&
           videoDuration++
            section.activeLearningCount &&
           activeLearningCount++
          } else {
            section.practiceTermCount &&
            practiceTermCount++
            videoDuration += getSecondsFromTime(section.videoDuration)
            activeLearningCount += section.activeLearningCount
          }
        }
      )
    )
    if (!isCalculus) {
      videoDuration = getTimeFromSeconds(videoDuration, true) || '00:00'
    }
    return {
      activeLearningCount,
      guessWorkCount,
      practiceTermCount,
      videoDuration
    }
  }

  calculateSectionActivitiesProgressCount = () => {
    const { studentData } = this.context
    let guessWorkProgressCount = 0
    let practiceTermProgressCount = 0
    let lectureProgressDuration = 0
    let activityLearningProgressCount = 0

    guessWorkProgressCount =
     Object.keys(studentData['guesswork-complete']).length
    const isCalculus = config.course.isCalculus

    if (isCalculus) {
      practiceTermProgressCount = Object.values(studentData['studentAnswers'])
        .filter(answer => answer.type === ACTIVITY_TYPES[PROBLEM_BANK] &&
           answer.isPracticeCompleted).length
      lectureProgressDuration = Object.keys(studentData[LECTURE_COMPLETE])
        .filter(key => key.length === 10).length
      activityLearningProgressCount =
      Object.keys(studentData[ACTIVE_LEARNING_COMPLETE]).length
    } else {
      practiceTermProgressCount =
      Object.keys(studentData[PRACTICE_TERM_COMPLETE]).length
      lectureProgressDuration = getTimeFromSeconds(
        Object.values(studentData[LECTURE_VIDEO_PROGRESS]
        )
          .reduce((a, b) => a + b, 0), true) || '00:00'
      activityLearningProgressCount =
      Object.values(studentData['studentAnswers'])
        .filter(answer => answer.type === ACTIVITY_TYPES[ACTIVE_LEARNING]).length
    }

    return {
      guessWorkProgressCount,
      practiceTermProgressCount,
      lectureProgressDuration,
      activityLearningProgressCount
    }
  }

  getProgressBarAndCards = () => {
    const { open } = this.state
    const {
      activeLearningCount,
      guessWorkCount,
      practiceTermCount,
      videoDuration
    } = this.calculateSectionActivitiesCount()
    const {
      guessWorkProgressCount,
      practiceTermProgressCount,
      lectureProgressDuration,
      activityLearningProgressCount
    } = this.calculateSectionActivitiesProgressCount()
    const isCalculus = config.course.isCalculus
    return (<>
      <Collapse
        className='section-panel__collapse'
        isOpen={open}
        data-cy='progressCardsSection'
        data-testid='progressCardsSection'
      >
        <div className='row'>
          <div className='col-sm padding-right'>
            <div className='cards '>
              <CardHeading>Guesswork</CardHeading>
              <ProgressValue>{guessWorkProgressCount}/{guessWorkCount}</ProgressValue></div>
            <div className='cards'>
              <CardHeading>
                {isCalculus ? 'Lecture Videos' : 'Lecture Time'}
              </CardHeading>
              <ProgressValue>{lectureProgressDuration}/{videoDuration}</ProgressValue>
            </div>
          </div>
          <div className='col-sm padding-left'>
            <div className='cards'>
              <CardHeading>{isCalculus
                ? 'Active Learning Chapters' : 'Active Learning Cards'}
              </CardHeading>
              <ProgressValue>
                {activityLearningProgressCount}/{activeLearningCount}
              </ProgressValue></div>
            <div className='cards'>
              <CardHeading>
                {isCalculus ? 'Practice Sets' : 'Practice Terms'}
              </CardHeading>
              <ProgressValue>
                {practiceTermProgressCount}/{practiceTermCount}
              </ProgressValue>
            </div>
          </div>
        </div>
      </Collapse>
      <ChapterToggleIcon
        collapsed={!open} onClick={() => { this.setState({ open: !open }) }} />
      <ShowHideText onClick={() => { this.setState({ open: !open }) }}>
        {open ? 'HIDE STATS' : 'SHOW STATS'}
      </ShowHideText></>)
  }

  handleViewResourcesClick = () => {
    emitter.emit(ON_NAVIGATE_TO, '/resources')
  }

  render () {
    var {
      course,
      cohortModifier,
      cohortSpecialDays,
      cohortCourseInfoUrl,
      cohortMilestones,
      cohortExamDates
    } = this.props
    const { studentData, isAuditor } = this.context
    const lastProgressedChapterUUID = getLastProgressedChapterUUID(course.chapters, studentData['sectionProgress'])
    const {
      isStudioCohort,
      cohortData,
      latestCohort,
      isAdmin,
      courseData
    } = this.context
    const { name: cohortName } = cohortData
    const { isStudentProgress, isCollegeSuccessCourse } = config

    const {
      isPittsCreditInstitution,
      projectedGrade
    } = this.state

    const { isContentCourse, isTitleUpdates, isPreviewCourse } = config
    const isAdminOrVipCohort = isAdmin || getIsVIPCohort(cohortName)
    const isGGUCohort = cohortName?.toLowerCase().includes('ggu')

    const showDefaultHomePage = !config.isPreviewCourse
    const showResources = !config.isPreviewCourse && !isStudioCohort && !isContentCourse
    const showSchedule = showResources && !isCollegeSuccessCourse()
    const showCoursework = !course?.courseUnits?.length && !config.isPreviewCourse

    const shouldShowToolkit = isPittsCreditInstitution || isGGUCohort
    const currentGrade = this.state.currentGrade
    const cohort = {
      latestCohort,
      cohortExamDates,
      cohortData
    }

    return (
      <React.Fragment>
        <GlobalStyle />
        <section className='section course-home'>
          <div className='container'>
            <CourseHomeContainer>
              <CourseHomeHeader
                className='section-main__text course-home__intro d-flex align-items-center'
              >
                <div className='section-intro__text' data-cy='CourseHeader' >
                  {showDefaultHomePage &&
                  <CourseTitleContainer>
                    <CourseTitleGradeContainer>
                      <CourseTitle isTitleUpdates>
                        { course.title }
                      </CourseTitle>
                      <GradeMessage
                        cohort={cohort}
                        currentGrade={currentGrade}
                        projectedGrade={projectedGrade}
                        courseData={courseData}
                        isAuditor={isAuditor}
                        studentData={studentData}
                      />
                    </CourseTitleGradeContainer>

                    <div className='info-section'>
                      {!isAdminOrVipCohort && (
                        <div className='info-date'>
                          {getCohortStartEndDateShort(latestCohort)}
                        </div>
                      )}

                      {showResources && (
                        !shouldShowToolkit ? (
                          cohortCourseInfoUrl && (
                            <ViewResourcesLink
                              target='_blank'
                              data-testid='externalResource'
                              rel='noopener noreferrer'
                              href={cohortCourseInfoUrl}
                            >
                              <ImageStyler
                                src='images/icons/folder-icon.png'
                                alt='Toolkit Icon'
                              />
                              {VIEW_TOOLKIT_LABEL}
                            </ViewResourcesLink>
                          )
                        ) : (
                          <ViewResourcesLink
                            data-testid='internalResource'
                            role='button'
                            onClick={this.handleViewResourcesClick}
                          >
                            <ImageStyler
                              src='images/icons/folder-icon.png'
                              alt='Toolkit Icon'
                            />
                            {VIEW_TOOLKIT_LABEL}
                          </ViewResourcesLink>
                        )
                      )}
                    </div>
                  </CourseTitleContainer>
                  }

                  {isPreviewCourse &&
                  <>
                    <CourseTitle
                      marginBottom
                      isTitleUpdates={isTitleUpdates}
                    >
                      { course.title }
                    </CourseTitle>

                    <ReadMoreToggler>
                      {course.desc}
                    </ReadMoreToggler>
                  </>
                  }

                  {showSchedule && (
                    <ScheduleSection subHeader='schedule' />
                  )}

                  {showCoursework && (
                    <CourseWork>Coursework</CourseWork>
                  )}
                </div>

              </CourseHomeHeader>

              {isStudentProgress && <ProgressTimeLine course={course} />}
              {isStudentProgress && this.getProgressBarAndCards()}
              <div className='section-panel__wrapper mt-3'>
                <ChapterList
                  lastProgressedChapterUUID={lastProgressedChapterUUID}
                  chapters={course.chapters} key={course.course_uuid}
                  courseUnits={course.courseUnits}
                  courseUnlockDate={this.props.courseUnlockDate}
                  cohortModifier={cohortModifier}
                  cohortSpecialDays={cohortSpecialDays}
                  cohortExamDates={cohortExamDates}
                  cohortMilestones={cohortMilestones}
                  cohortData={this.props.cohortData}
                />
              </div>
            </CourseHomeContainer>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

CourseHome.contextType = Context
CourseHome.displayName = 'CourseHome'
export default CourseHome
