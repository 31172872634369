import styled from 'styled-components'

export const StyledInfographicWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const StyledIframe = styled.iframe`
  height: 90vh;
  width: 80%;
  border: 0;
`
