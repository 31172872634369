import React, { Component } from 'react'
import { debounce } from 'lodash'
import MultipleChoiceQuestion from
  '../MultipleChoiceQuestion/MultipleChoiceQuestion.jsx'
import TextBlanksQuestion from '../TextBlanksQuestion/TextBlanksQuestion'
import TrueFalseQuestion from '../TrueFalseQuestion/TrueFalseQuestion'
import FreeformEquationQuestion from
  '../FreeformEquationQuestion/FreeformEquationQuestion'
import MultipleChoiceQuestionGuesswork from
  '../MultipleChoiceQuestion/MultipleChoiceQuestionGuesswork'
import MultipleChoiceQuestionQuizExam from
  '../MultipleChoiceQuestion/MultipleChoiceQuestionQuizExam'
import TextBlanksQuestionGuesswork from
  '../TextBlanksQuestion/TextBlanksQuestionGuesswork'
import TrueFalseQuestionGuesswork from
  '../TrueFalseQuestion/TrueFalseQuestionGuesswork'
import FreeformEquationQuestionGuesswork from
  '../FreeformEquationQuestion/FreeformEquationQuestionGuesswork'
import PracticeExerciseIntro from '../PracticeExercise/PracticeExerciseIntro.jsx'
import CardQuestion from '../CardQuestion/CardQuestion'
import TextRevealCard from '../TextRevealCard/index.jsx'
import {
  ACTIVE_LEARNING,
  PRACTICE_EXERCISES,
  QUIZ,
  EXAM,
  GUESSWORK,
  ACTIVITY_TYPES
} from '../../Constants/sectionType'
import { emitter } from '../Emitter/Emitter'
import { ON_NAVIGATE_TO, ON_SECTION_DATA_PROGRESS, ON_SUBMIT_ANSWER } from '../../Constants/emitterKeys'
import {
  CODING_QUESTION,
  MULTIPLE_CHOICE,
  TRUE_FALSE,
  TEXT_BLANK,
  FREE_FORM,
  AL_TEXT_REVEAL_CARD,
  CARD
} from '../../Constants/questionType'
import { QUIZ_TEXT } from '../../Constants/alertText'
import withStudentContext from '../../Hoc/withStudentContext'
import config from '../../config'
import { endProctorioSession } from '../../utilities/examUtils.js'
import CodingQuestionQuizExam from '../CodingQuestion/CodingQuestionQuizExam'
import FreeformEquationQuestionQuizExam from
  '../FreeformEquationQuestion/FreeformEquationQuestionQuizExam'
import { PRACTICE_EXAM } from '../../Constants/examType'
import { checkExamLockedStatus } from '../../utilities/examLock.js'
import { EXAM_STATUS } from '../../Constants/studentContext.js'
import { EXAM_STATUSES } from '../../Constants/index.js'
import {
  AssessmentTitle,
  ContainerAL,
  ContainerPractice,
  MainWrapperAL,
  MainWrapperPractice
} from './styles'

class QuestionWrapper extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isReload: false
    }

    this.reload = this.reload.bind(this)
    this.saveProgress = debounce(this.saveProgress.bind(this), 300)
  }

  handleExamLock = () => {
    const {
      activeSectionUUID: examUUID,
      studentData: { examsUnlockedWithKeys, updateContext }
    } = this.props

    updateContext({
      examsUnlockedWithKeys: {
        ...examsUnlockedWithKeys,
        [examUUID]: false
      },
      showFinish: false
    })
    emitter.emit(
      ON_SECTION_DATA_PROGRESS,
      {
        key: EXAM_STATUS,
        sectionUUID: examUUID,
        value: EXAM_STATUSES.INCOMPLETE
      }
    )

    updateContext({ examLockEvent: null })
  }

  async componentDidMount () {
    const {
      review_mode: reviewMode,
      studentData: {
        navigateToActiveIndex,
        updateContext,
        examLockEvent: contextExamLockEvent,
        studentId
      },
      currentQuestionSet,
      type_of_question: typeOfQuestion,
      activeSectionUUID
    } = this.props

    if (typeOfQuestion === EXAM && !reviewMode && !contextExamLockEvent) {
      const examLockEvent = await checkExamLockedStatus({
        studentId,
        examUUID: activeSectionUUID,
        handleExamLock: this.handleExamLock
      })
      updateContext({ examLockEvent })
    }

    if (!navigateToActiveIndex) {
      const isAssessmentQuizOrExam =
      (typeOfQuestion === QUIZ || typeOfQuestion === EXAM)
      updateContext({ navigateToActiveIndex: isAssessmentQuizOrExam })
    }
    if (typeOfQuestion === PRACTICE_EXERCISES) {
      updateContext({
        currentQuestionSet: {
          data: currentQuestionSet,
          type: typeOfQuestion
        }
      })
    }
    this.onRefreshListener()
  }

  onRefreshListener () {
    const { review_mode: reviewMode, type_of_question: typeOfQuestion } = this.props
    if (reviewMode) return
    if (typeOfQuestion !== QUIZ && typeOfQuestion !== EXAM) return null
    window.onbeforeunload = function () {
      return 'Change you made may not be saved'
    }

    if (typeOfQuestion === QUIZ) return null
    window.onunload = function () {
      endProctorioSession()
    }
  }

  async componentWillUnmount () {
    const { isSubmittingAnswers, updateContext } = this.props.studentData || {}
    if (isSubmittingAnswers) {
      updateContext({ isSubmittingAnswers: false })
      window.onbeforeunload = null
      window.onunload = null
      return
    }
    const {
      type_of_question: typeOfQuestion
    } = this.props
    const isQuiz = typeOfQuestion === QUIZ
    const {
      questionSetUUID,
      studentData: { setAnswersAndFinish },
      activeSectionUUID,
      currentQuestionSet,
      question: { Question_uuid: id },
      section_ending_uuid: setionEndingUUID,
      review_mode: reviewMode,
      localStorageKey
    } = this.props
    const hash = window.location.hash
    // checks wheather user back from quiz
    const isBack = hash.split('/').length < 3
    let isUUIDExist = !isBack && hash.split('/')[2]
    // checks wheather id is in questions or contains end_section
    isUUIDExist = !isBack && (currentQuestionSet
      .find(question => question.Question_uuid === isUUIDExist) ||
        isUUIDExist.includes('end_section'))
    const showConfirmationModal = isQuiz &&
      !reviewMode && (isBack || hash === '#/' || !isUUIDExist)
    const result = showConfirmationModal && window.confirm(QUIZ_TEXT)
    if (result) {
      await setAnswersAndFinish({
        activeSectionUUID,
        questionSet: {
          questionSetUUID,
          questions: currentQuestionSet,
          first_quiz_question: id,
          section_ending_uuid: setionEndingUUID
        },
        localStorageKey,
        isQuiz
      })
      setTimeout(() => {
        emitter.emit(ON_NAVIGATE_TO, activeSectionUUID)
      }, 100)
    } else {
      showConfirmationModal &&
        emitter.emit(ON_NAVIGATE_TO, `/${activeSectionUUID}/${id}`)
    }
    window.onbeforeunload = null
    window.onunload = null
  }

  getAssessmentTitle () {
    const {
      question: { cmsTitle },
      type_of_question: questionType
    } = this.props

    if (questionType === QUIZ) {
      const {
        activeSectionUUID,
        studentData: {
          currentChapter: { uuid: chapterUUID },
          courseData: { chapters }
        }
      } = this.props
      const { course: { use0BasedIndexing } } = config
      const chapter = chapters?.find(
        chapter => chapter.chapter_uuid === chapterUUID
      )
      const index = chapter?.sections?.findIndex(
        ({ section_uuid: sectionUUID }) => sectionUUID === activeSectionUUID
      )
      const chapterIndex = use0BasedIndexing ? index : index + 1
      const chapterNumber = use0BasedIndexing
        ? chapter?.chapterNumber - 1 : chapter?.chapterNumber
      const quizNumber = this.getQuizNumber(cmsTitle)
      const quizText = quizNumber ? ` ${QUIZ} ${quizNumber}` : ''
      const quizTitle = `${chapterNumber}.${chapterIndex}${quizText}`
      return quizTitle
    }

    if (questionType === EXAM) {
      const {
        completeScreenData: {
          chapter: { title }
        }
      } = this.props
      if (title === PRACTICE_EXAM) { return 'Set up your exam' }
      return title
    }
    return ''
  }

  getQuizNumber (cmsTitle) {
    if (!cmsTitle.includes(QUIZ)) return
    let quizNumber = cmsTitle.match(new RegExp(QUIZ + '\\s(\\d+)'))?.[1]
    quizNumber = parseInt(quizNumber)
    return isNaN(quizNumber) ? 0 : quizNumber
  }

  getNonPopupQuestion (QuestionComponentType) {
    const {
      studentData: {
        isAssessmentQuizExam,
        isSidePanelOpen,
        courseDisplayName
      },
      type_of_question: sectionType
    } = this.props

    const isGuessworkSection = sectionType === GUESSWORK

    return (
      <MainWrapperAL
        isSidePanelOpen={isGuessworkSection ? false : isSidePanelOpen}>
        <div className='active__learning-main-mid'>
          <ContainerAL
            isAssessmentQuizExam={isAssessmentQuizExam}
            isSidePanelOpen={isGuessworkSection ? false : isSidePanelOpen}>
            <>
              {isAssessmentQuizExam && (
                <AssessmentTitle>
                  <span>{courseDisplayName}</span>
                  <span>.</span>
                  <span data-testid='assessment-title'>
                    {this.getAssessmentTitle()}
                  </span>
                </AssessmentTitle>
              )}
              <QuestionComponentType reload={this.reload} {...this.props} />
            </>
          </ContainerAL>
        </div>
      </MainWrapperAL>
    )
  }

  getActiveLearningQuestion (QuestionComponentType) {
    const {
      studentData: {
        isSidePanelOpen
      }
    } = this.props

    return (
      <MainWrapperAL
        isSidePanelOpen={isSidePanelOpen}>
        <div className='active__learning-main-mid'>
          <ContainerAL
            isSidePanelOpen={isSidePanelOpen}
          >
            <QuestionComponentType
              reload={this.reload}
              {...this.props}
              saveProgress={this.saveProgress}
            />
          </ContainerAL>
        </div>
      </MainWrapperAL>
    )
  }

  getPracticeQuestion () {
    const {
      studentData: {
        isSidePanelOpen
      }
    } = this.props

    return (
      <MainWrapperPractice>
        <div className='active__learning-main-mid'>
          <ContainerPractice
            isSidePanelOpen={isSidePanelOpen}>
            <PracticeExerciseIntro
              isProblemBank
              shouldOverrideStyles
              {...this.props}
            />
          </ContainerPractice>
        </div>
      </MainWrapperPractice>
    )
  }

  reload () {
    this.setState({ isReload: true }, () => {
      this.setState({ isReload: false })
    })
  }

  saveProgress (studentAnswerRes) {
    const {
      question: { question_type: questionType },
      type_of_question: sectionType
    } = this.props

    if (
      studentAnswerRes &&
      ((sectionType === ACTIVE_LEARNING && questionType !== CARD) ||
        sectionType === GUESSWORK)
    ) {
      emitter.emit(ON_SUBMIT_ANSWER, {
        ...studentAnswerRes,
        type: ACTIVITY_TYPES[sectionType]
      })
    }
  }

  render () {
    const {
      question: { question_type: questionType } = {},
      problemBank,
      type_of_question: sectionType
    } = this.props
    const { isReload } = this.state

    if (isReload) return null

    const examType = [QUIZ, EXAM]

    if (examType.includes(sectionType)) {
      switch (questionType) {
        case MULTIPLE_CHOICE:
          return this.getNonPopupQuestion(MultipleChoiceQuestionQuizExam)
        case TRUE_FALSE:
          return this.getNonPopupQuestion(TrueFalseQuestionGuesswork)
        case TEXT_BLANK:
          return this.getNonPopupQuestion(TextBlanksQuestionGuesswork)
        case FREE_FORM:
          return this.getNonPopupQuestion(FreeformEquationQuestionQuizExam)
        case CODING_QUESTION:
          return this.getNonPopupQuestion(CodingQuestionQuizExam)
        default:
          break
      }
    } else if (sectionType === GUESSWORK) {
      switch (questionType) {
        case MULTIPLE_CHOICE:
          return this.getNonPopupQuestion(MultipleChoiceQuestionGuesswork)
        case TRUE_FALSE:
          return this.getNonPopupQuestion(TrueFalseQuestionGuesswork)
        case TEXT_BLANK:
          return this.getNonPopupQuestion(TextBlanksQuestionGuesswork)
        case FREE_FORM:
          return this.getNonPopupQuestion(FreeformEquationQuestionGuesswork)
        default:
          break
      }
    } else if (sectionType === ACTIVE_LEARNING) {
      switch (questionType) {
        case MULTIPLE_CHOICE:
          return this.getActiveLearningQuestion(MultipleChoiceQuestion)
        case AL_TEXT_REVEAL_CARD:
          return this.getActiveLearningQuestion(TextRevealCard)
        case TRUE_FALSE:
          return this.getActiveLearningQuestion(TrueFalseQuestion)
        case TEXT_BLANK:
          return this.getActiveLearningQuestion(TextBlanksQuestion)
        case FREE_FORM:
          return this.getActiveLearningQuestion(FreeformEquationQuestion)
        default:
          break
      }
    } else if (sectionType === PRACTICE_EXERCISES && problemBank) {
      return this.getPracticeQuestion()
    }

    return questionType === CARD && this.getNonPopupQuestion(CardQuestion)
  }
}

export default withStudentContext(QuestionWrapper)
