import styled from 'styled-components'
import mediaqueries, { BREAKPOINTS } from '../../../mediaQueries'

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${({ isALRedesign }) => isALRedesign && `
    margin-top: 42px;
  `}

  .btn {
    width: 150px;
    margin: 6px 0px !important;
    margin-right: 8px !important;
    height: 40px;
    padding: 0px 8px;
  }
  .btn-secondary:disabled {
    background-color: #161618;
    border-color: #77b1af;
    color: #77b1af;
  }

  ${({ isAssessmentQuizExam, isAnsweringDisabled, isALRedesign }) =>
    (isAssessmentQuizExam || isALRedesign) && `
      justify-content: flex-start;
      margin-top: ${isAnsweringDisabled ? '43px' : ''};
      ${isALRedesign && `
        .btn-primary:disabled {
          background-color: #161618;
          border-color: #77b1af;
          color: #5FC4B8;
          opacity: 50%;
        }
      `}

    .btn-secondary:disabled {
      background-color: #161618;
      border-color: #77b1af;
      color: #5FC4B8;
    }

    .btn-primary {
      background-color: #5FC4B8;
    }

    .btn {
      width: 64px;
      border: #5FC4B8 1px solid;
    }
  `}

  ${mediaqueries.mobile`
     ${({ isALRedesign }) => !isALRedesign && `
      .btn {
        width: 100%;
      }
    `}
    ${({ isAssessmentQuizExam, reviewMode }) => isAssessmentQuizExam && `
      .btn {
        width: ${reviewMode ? '157px' : '100%'};

        :first-child {
          margin-left: 0px !important;
          margin-right: ${reviewMode ? '12px !important' : '0px'};
        }

        :nth-child(2) {
          margin-left: 0px !important;
          margin-right: 0px !important;
        }
      }
    `}
  `}

  @media (max-width: ${BREAKPOINTS.mobile - 1}px) {
    .btn {
      width: 100%;
    }

    ${({ isALRedesign }) => isALRedesign && `
      .btn {
        flex-grow: 1 !important;
        max-width: 157px !important;

        :first-child {
          margin-left: 0px !important;
          margin-right: 12px !important;
        }

        :nth-child(2) {
          margin-left: 0px !important;
          margin-right: 0px !important;
        }
      }
    `}
  }
`

export const TextWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 48px;
  display: flex;
  height: 19px;
  justify-content: space-between;
  ${({ isALRedesign }) => isALRedesign && `
    height: 70px;
    background: #121212;
    border-radius: 5px 5px 0px 0px;
    margin-bottom: 1px;
    padding: 25px 24px;
    margin-top: 0px;
   `}
`
export const TrueFalseFirstSection = styled.div`
  ${({ isALRedesign }) => isALRedesign && `
    padding: 24px 24px 0px 24px;
    background: #121212;
    margin-bottom: 0px;
    border-radius: 0px;
  `}
`
export const TrueFalseSecondSection = styled.div`
  ${({ isALRedesign }) => isALRedesign && `
    padding: 0px 24px 12px 24px;
    background: #121212;
    border-radius: 0px 0px 5px 5px;
  `}
`

export const Icon = styled.img`
  width: inherit;
  height: inherit;
  vertical-align: middle;
  border-style: none;
  margin-bottom: 18px;
`

export const QuestionIconWrapper = styled.div`
display: inline-flex;
`

export const QuestionText = styled.span`
  line-height: 19.2px;
  font-size: 16px;
  margin-left: 12px;
  ${props => props.isALRedesign && `
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-left: 0px;
    color: #FFFFFF;
  `}

`

export const QuestionCard = styled.div`
  ${props => props.isAssessmentQuizExam && `
    background-color: rgba(0, 0, 0, 0);
    padding: 0px !important;
  `}
`

export const PinWrapper = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    ${props => props.isALRedesign && `align-items: center`}
`
export const PinText = styled.span`
    max-width: 93px;
    font-size: 16px;
    line-height: 19.2px;
    color: #98A4AE;
    text-align: right;
`
export const PinButtonWrapper = styled.span`
  ${({ isAssessmentQuizExam }) => isAssessmentQuizExam && `
    width: 36px;
    height: 36px;
    margin-top: -6px;
  `}
  ${({ isALRedesign }) => isALRedesign && `
    margin-top: -2px;
  `}
`
export const PinButton = styled.img`
    margin-left: 12px;
    margin-bottom: 2px;
    width: 14px;
    height: 14px;
`
export const LessonText = styled.div`
  ${mediaqueries.mobile`
    img {
      max-width: 100%;
    }
  `}
`
export const LessonTextAL = styled.div`
  ${({ isALRedesign }) => isALRedesign && `
    padding-bottom: 0px !important;
    h3{
      font-size: 24px !important;
      line-height: 29px;
      font-weight: 300;
      margin-bottom: 24px !important;
    }
    .paragraph {
      font-size: 18px !important;a
      line-height: 22px;
      font-weight: 300;
    }
  `}
`
export const AdaptationStatement = styled.div`
  ${({ isALRedesign }) => isALRedesign && `
    text-align: left !important;
    .paragraph {
      margin-top: 42px !important;
      line-height: 17px !important;
    }
  `}
`
export const LessonSmallTextAL = styled.div`
  ${({ isALRedesign }) => isALRedesign && `
    font-size: 18px !important;
    line-height: 22px;
    .paragraph {
      margin-bottom: 24px !important;
    }
  `}
`
