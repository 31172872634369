import React from 'react'
import {
  Container,
  Header,
  Content
} from './styles'
import CourseButton from '../CourseButton/CourseButton'
import { useStudentDataContext } from '../Context/Context'
import StudentSuccessLink from './StudentSuccessLink'
import { PERMISSION_REQUIRED } from './permissionStates'

const AwaitingPermission = ({
  guardianEmail,
  setEmail,
  setPermissionState
}) => {
  const { showProfCertGuardianPermission } = useStudentDataContext()

  const resendEmail = () => {
    setEmail('')
    setPermissionState(PERMISSION_REQUIRED)
  }

  return (
    <Container>
      <div className='content'>
        <Header marginBottom='20px'>Awaiting permission</Header>
        <Content>
          It looks like your parent/guardian has not agreed to {
            showProfCertGuardianPermission
              ? 'the terms for Golden Gate University yet.'
              : 'our Terms of Service and Privacy Policy yet.'
          } We reached out to them at <span>{guardianEmail}</span> with
          instructions on how to agree to these for you.
        </Content>
        <Content marginBottom='24px'>
          You can resend the email to them below or reach out to {' '}
          <StudentSuccessLink
            showProfCertGuardianPermission={showProfCertGuardianPermission}
          />{' '}if you need additional help.
        </Content>
        <CourseButton
          className='btn btn-primary'
          onClick={resendEmail}
        >
            resend
        </CourseButton>
      </div>
    </Container>
  )
}

export default AwaitingPermission
