import React, { useState, useEffect, forwardRef } from 'react'
import { useStudentDataContext } from '../Context/Context'
import { emitter } from '../Emitter/Emitter'
import { ON_SUBMIT_ANSWER } from '../../Constants/emitterKeys'
import { ACTIVE_LEARNING, ACTIVITY_TYPES } from '../../Constants/sectionType'
import MathJax from '../MathJax/MathJax'
import QuestionComponent from '../QuestionComponent/QuestionComponent'
import {
  TextWrapper,
  QuestionText,
  PinWrapper,
  PinText,
  PinButtonWrapper,
  PinButton,
  CardWrapper, QuestionWrapper, RevealButton, RevealedTextWrapper,
  LessonTextAL,
  QuestionTextAL,
  PaginationWrapper,
  AdaptationStatementWrapper
} from './style'
import JDoodleCompiler from '../JDoodleCompiler/JDoodleCompiler'
import QuestionNextButton from '../QuestionComponent/QuestionNextButton'
import QuestionPreviousButton from '../QuestionComponent/QuestionPreviousButton'
import { PINNED_TEXT, PIN_TEXT } from '../../Constants'
import { PINNED_ICON_WHITE, PIN_ICON } from '../../Constants/progressBarIconSource'
import { findAnswer } from '../../utilities/questions'
import { getStudentAnswersKey } from '../../utilities/contextKeys'

function TextRevealCard (props) {
  const {
    innerRef,
    question,
    lesson_text: lessonText,
    structuredText,
    question_text: questionText,
    display_illustration: displayIllustration,
    showPrevious,
    filteredActvQuestInd,
    onNextButtonClick,
    onPreviousButtonClick
  } = props

  const {
    Question_uuid: questionUuid,
    revealTextButton,
    jdoodleEditor,
    starterCode,
    revealedText,
    adaptationStatement
  } = question

  const {
    updateContext,
    currentChapter: { type },
    studentData = {}
  } = useStudentDataContext()

  const { studentAnswers = [], pinnedQuestions } = studentData

  const isCardChecked =
    !!studentAnswers.find(answer => answer.uuid === questionUuid && answer.correct)

  const [isRevealText, setIsRevealText] = useState(false)
  const [pinned, setPinned] = useState(false)

  useEffect(() => {
    setIsRevealText(isCardChecked)
    setPinned(pinnedQuestions.includes(questionUuid))
  // eslint-disable-next-line
  }, [questionUuid])

  const handleRevealButton = () => {
    if (isRevealText || isCardChecked) return
    setIsRevealText(true)
    markChecked()
  }

  const markChecked = () => {
    const answer = {
      uuid: questionUuid,
      answer: '',
      correct: true,
      type: ACTIVITY_TYPES[ACTIVE_LEARNING]
    }

    studentAnswers.push(answer)
    updateContext({
      studentData
    })

    emitter.emit(ON_SUBMIT_ANSWER, answer)
  }

  const getStudentAnswers = () => {
    const studentAnswersKey = getStudentAnswersKey(type)
    return studentData[studentAnswersKey]
  }

  const onPinClick = (currentQuestionuuID) => {
    const index = pinnedQuestions.indexOf(currentQuestionuuID)
    const studentAnswers = getStudentAnswers()
    const fAnswer = findAnswer(currentQuestionuuID, studentAnswers)
    const answer = {
      type: ACTIVITY_TYPES[ACTIVE_LEARNING],
      uuid: currentQuestionuuID,
      questionActivity: { pinned: false },
      answer: '',
      ...(fAnswer?.correct && { correct: fAnswer.correct })
    }
    if (pinned) {
      pinnedQuestions.splice(index, 1)
    } else {
      pinnedQuestions.push(currentQuestionuuID)
      answer.questionActivity.pinned = true
    }
    emitter.emit(ON_SUBMIT_ANSWER, answer)
    studentData.pinnedQuestions = pinnedQuestions
    updateContext({ studentData })
    setPinned(!pinned)
  }

  // Should return false if the action was not allowed.
  const handleKeyPress = (type = '') => {
    // Typically from pressing the left arrow
    if (type === 'previous') {
      if (!showPrevious) return false
      onPreviousButtonClick()
      return true
    }

    if (!isCardChecked) return false

    // Everything else is interpreted as next or advance forward
    onNextButtonClick()
    return true
  }

  innerRef.current = {
    handleKeyPress
  }

  return (
    <>
      <TextWrapper>
        <QuestionText>
          Card {filteredActvQuestInd + 1}
        </QuestionText>
        <PinWrapper
          onClick={() => onPinClick(questionUuid)}
        >
          <PinText>{pinned ? PINNED_TEXT : PIN_TEXT}</PinText>
          <PinButtonWrapper>
            <PinButton
              src={pinned ? PINNED_ICON_WHITE : PIN_ICON}
              data-testid='pinButton'
            />
          </PinButtonWrapper>
        </PinWrapper>
      </TextWrapper>
      <CardWrapper>
        {structuredText}
        {lessonText &&
          <>
            <LessonTextAL
              className='font__md'
              style={{ paddingBottom: '1rem' }}
              data-testid='reveal-card-lesson-text'
            >
              {lessonText}
            </LessonTextAL>
          </>
        }

        <QuestionWrapper>
          {displayIllustration}

          {questionText &&
            <QuestionTextAL
              className='font__md'
              data-testid='reveal-card-question-text'
            >
              {questionText}
            </QuestionTextAL>
          }
        </QuestionWrapper>
        {jdoodleEditor &&
          <div className='mb-2'>
            <JDoodleCompiler
              key={questionUuid}
              questionUUID={questionUuid}
              isALRedesign
              starterCode={starterCode}
            />
          </div>}

        <RevealButton
          textRevealed={isRevealText}
          onClick={handleRevealButton}
          data-testid='reveal-button'
        >
          <MathJax math={revealTextButton} />
        </RevealButton>

        {isRevealText && (
          <RevealedTextWrapper
            data-testid='revealed-text'
          >
            <MathJax math={revealedText} />
          </RevealedTextWrapper>
        )}
      </CardWrapper>

      <PaginationWrapper>
        <QuestionPreviousButton isALRedesign {...props} />

        <QuestionNextButton
          {...props}
          isALRedesign
          isNextDisabled={!isRevealText}
        />
      </PaginationWrapper>

      {adaptationStatement &&
        <AdaptationStatementWrapper
          data-testid='text-reveal-adaptation'
        >
          <MathJax math={adaptationStatement} />
        </AdaptationStatementWrapper>
      }
    </>
  )
}

const TextRevealCardWithRef = forwardRef(
  (props, ref) => (<TextRevealCard innerRef={ref} {...props} />)
)

export default QuestionComponent(TextRevealCardWithRef)
