import React, { useEffect } from 'react'
import ReactCanvasConfetti from 'react-canvas-confetti'

export default function BurstConfetti (props) {
  let animationInstance = null
  const canvasStyles = {
    position: 'absolute',
    pointerEvents: 'none',
    width: props.width ?? '150px',
    height: props.height ?? '130px',
    bottom: '0',
    borderRadius: '32px 32px 0px 0px',
    left: `${props.left}%`
  }

  const makeShot = (options) => {
    animationInstance &&
          animationInstance({
            ...options,
            origin: { x: 0.1, y: 1 },
            particleCount: props.particleCount ?? 30,
            colors: ['#5fc4b8', '#ffffff'],
            shapes: ['square'],
            scalar: 0.1
          })
  }

  const fire = () => {
    makeShot({
      spread: 120,
      startVelocity: 25
    })
  }

  const getInstance = (instance) => {
    animationInstance = instance
  }

  useEffect(() => {
    fire()
    // eslint-disable-next-line
  }, [animationInstance])

  return (
    <>
      <ReactCanvasConfetti
        refConfetti={getInstance}
        style={canvasStyles}
      />
    </>
  )
}
