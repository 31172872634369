import styled from 'styled-components'

export const FileUploaderContainer = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  border: 1px dashed rgba(255, 255, 255, 0.3);
  border-radius: 5px;

  > P {
    color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.2px;
    text-align: center;
    padding: 10px 0 13px 0;

    span {
      color: #5FC4B8;
      cursor: ${props => props.isDisabled ? 'auto' : 'pointer'};
    }
  }
    height: 39px;
  ${props => props.hasFile && `
    height: 76px;
  `}

  #overlay {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgb(22 22 22 / 70%);
    border-radius: 5px;
    z-index: 100;
    ${props => !props.isDisabled && `
    display: none;
  `}
  }
`
export const FileUploadLabel = styled.label`
 font-size: 16px;
 font-weight: 400;
 line-height: 16.8px;
 margin-bottom: 12px;
 ${props => props.isMultiPartUploading && `margin-top: 8px`};
 width: 100%;
 display: flex;
 justify-content: space-between;

 > P {
  padding: 0;
  margin: 0;
  ${props => props.isMultiPartUploading && `margin-left: auto`};
 }

 img {
   margin-left: 5px;
 }
`

export const AssignmentFileName = styled.span`
  color: #FFFFFF!important;
  cursor: auto!important;
  text-transform: uppercase;

  :after {
    display: inline-block;
    margin-right: 5px;
    content: ':';
  }
`
export const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  > span {
    visibility: hidden;
    width: 183.59px;
    height: ${props => props.allowSpreadsheetUpload ? '100px' : '75.9px'};
    background: rgba(79, 79, 79, 0.8);
    color: rgba(255, 255, 255, 1);
    text-align: center;
    padding: 8px;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: -184%;
    left: 482%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;

    > p {
      &:first-child {
        margin-bottom: 5px;
      }

      font-weight: 700;
      font-size: 14px;
      line-height: 16.8px;
    }
  }

  &:hover {

    > span {
      visibility: visible;
      opacity: 1;
    }
  }
`

export const FilePreview = styled.div`
  background: #FFFFFF;
  border-radius: 5px;
  margin: 12px 11px 0 12px;
  height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 7px;

  p {
    color: #000000;
    text-align: left;
    display: flex;
    align-content: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.8px;
    padding: 0;
    margin:0;
    img {
      align-self: center;
    }
  }

  #remove {
    cursor: pointer;

    span {
      color: #980000;
    }
  }
`

export const UploadingInput = styled.input`
  &::-webkit-file-upload-button {
    display: none;
  }

  &:focus{
    outline: none;
  }


  background-color: white;
  width: 100%;
  height: 39px;
  text-align: center;
  background: transparent;
  position: absolute;
  color: transparent;
  bottom: 0;
  visibility: hidden;
  left: 0;
`
