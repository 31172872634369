import React, { memo } from 'react'
import {
  GlobalStyle,
  StyledArrowWrapper,
  StyledTooltip,
  StyledTriangle
} from './TooltipOverlayCard.styled'
import { OverlayTrigger } from 'react-bootstrap'
import StandardOverlayCard from '../../Cards/Overlay/StandardOverlayCard'

const TooltipOverlayCard = memo(({
  title,
  headerIcon,
  renderOverlayContent,
  buttons,
  placement = 'bottom-start',
  arrowPlacement = 'start',
  show,
  isLoading,
  children
}) => {
  return (
    <>
      <GlobalStyle />
      <OverlayTrigger
        show={show}
        placement={placement}
        overlay={
          <StyledTooltip maxwidth={455} className='tooltip-overlay-card'>
            <StyledArrowWrapper placement={arrowPlacement}>
              <StyledTriangle />
            </StyledArrowWrapper>
            <StandardOverlayCard
              title={title}
              buttons={buttons}
              headerIcon={headerIcon}
              isLoading={isLoading}
            >
              {renderOverlayContent?.()}
            </StandardOverlayCard>
          </StyledTooltip>
        }
      >
        {children}
      </OverlayTrigger>
    </>
  )
})

export default memo(TooltipOverlayCard)
