import React, { useState, useEffect } from 'react'
import { emitter } from '../../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../../Constants/emitterKeys'
import {
  VideoWrapper,
  Left,
  Right,
  Content
} from '../../LecturesExerciseItem/styled'
import ConceptMapText from './ConceptMapText'
import { ConceptMapTitle } from './styled'
import { getUrl } from '../../../utilities/sectionUtils'
import FullWindow from '../../Readings/adobe/FullWindow'
import PracticeExerciseItemButton from '../PracticeExerciseItemButton'
import { findNumberOfPages } from '../../../utilities/file'

const ConceptMapItem = props => {
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [getPagesFromAdobe, setGetPagesFromAdobe] = useState(false)
  const [loadingPagesCount, setLoadingPagesCount] = useState(true)
  const {
    isLocked,
    sectionUUID,
    isCompleted,
    isCohortEnded,
    uuid: readingUUID,
    pdfFile
  } = props
  const pdfUrl = getUrl(pdfFile)

  useEffect(() => {
    const fetchNumberOfPages = async () => {
      try {
        const count = await findNumberOfPages(pdfUrl)
        if (!count) return setGetPagesFromAdobe(true)

        setNumberOfPages(count)
        setLoadingPagesCount(false)
      } catch (error) {
        console.error(error.message)
      }
    }

    fetchNumberOfPages()
  }, [pdfUrl])

  const handleClick = () => {
    emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/${readingUUID}`)
  }

  return (
    <VideoWrapper isLocked={isLocked}>
      <Left className='d-flex'>
        <Content>
          <div className='p-0 d-flex flex-wrap'>
            <ConceptMapTitle className='mr-2'>Concept Map</ConceptMapTitle>
          </div>
          <div>
            <ConceptMapText
              isCompleted={isCompleted}
              numberOfPages={numberOfPages}
              isLoading={loadingPagesCount}
            />
          </div>
        </Content>
      </Left>
      <Right>
        <PracticeExerciseItemButton
          isConceptMap
          isLocked={isLocked}
          isCohortEnded={isCohortEnded}
          handleClick={handleClick}
          isCompleted={isCompleted}
        />
      </Right>
      {getPagesFromAdobe && <FullWindow
        getPagesInCb
        callBack={pages => {
          setNumberOfPages(pages)
          setLoadingPagesCount(false)
        }}
        pdf={pdfUrl}
        title='title'
        readingUUID={readingUUID} />}
    </VideoWrapper>
  )
}

ConceptMapItem.displayName = 'ConceptMapItem'

export default ConceptMapItem
