import React, { useEffect, useRef, useState } from 'react'
import * as typeformEmbed from '@typeform/embed'
import qs from 'qs'
import '../TypeForm/css/typeForm.css'
import {
  Container,
  Header,
  Content
} from './styles'
import { PERMISSION_GRANTED_KEY } from '../../Constants'
import { isLatestTOSDate } from '../../utilities/userUtils'

const GuardianPermissionForm = () => {
  const params = qs.parse(window.location.search.slice(1))
  const {
    requestDate,
    firstName: encodedFirstName,
    lastName,
    studentId,
    guardianTOSDate
  } = params
  const firstName = decodeURIComponent(encodedFirstName)
  const [isError, setIsError] = useState(false)
  const formRef = useRef(null)
  let permissionGrantedInfo

  try {
    const storedValue = localStorage.getItem(PERMISSION_GRANTED_KEY)
    permissionGrantedInfo = storedValue ? JSON.parse(storedValue) : []
    if (!Array.isArray(permissionGrantedInfo)) permissionGrantedInfo = []
  } catch (error) {
    permissionGrantedInfo = []
    console.error('Error when getting PERMISSION_GRANTED_KEY: ', error.message)
  }

  const hasAlreadyGrantedPermission = permissionGrantedInfo.some(info => {
    const {
      studentId: savedStudentId,
      requestDate: savedRequestDate
    } = info
    return studentId === savedStudentId && requestDate === savedRequestDate
  })

  const isLatestGuardianTOSDate = isLatestTOSDate(guardianTOSDate)
  const alreadyApproved = isLatestGuardianTOSDate || hasAlreadyGrantedPermission

  useEffect(() => {
    if (alreadyApproved || !formRef.current) return

    const embedForm = async () => {
      try {
        typeformEmbed.makeWidget(
          formRef.current,
          `https://form.typeform.com/to/zBCNfu6p?firstname=${encodedFirstName}&lastname=${lastName}&student_id=${studentId}&course_url=${window.location.origin}`,
          {
            transferableUrlParameters: [
              'firstName',
              'lastName',
              'student_id',
              'course_url'
            ],
            onSubmit: () => {
              try {
                permissionGrantedInfo.push({ studentId, requestDate })
                localStorage.setItem(
                  PERMISSION_GRANTED_KEY,
                  JSON.stringify(permissionGrantedInfo)
                )
              } catch (error) {
                console.error('Error when setting PERMISSION_GRANTED_KEY: ', error.message)
              }
            }
          }
        )
      } catch (error) {
        localStorage.removeItem(PERMISSION_GRANTED_KEY)
        setIsError(error.message)
      }
    }

    embedForm()
    // eslint-disable-next-line
  }, [formRef])

  if (alreadyApproved) {
    return (
      <Container>
        <div className='content center'>
          <Header>Permission already granted</Header>
          <Content>
            You've already granted your permission to enroll {firstName}.
            If you've any questions or if we can help you with anything else,
            please reach out to <a className='success'
              href='mailto:success@outlier.org'>success@outlier.org.</a>
          </Content>
        </div>
      </Container>
    )
  }

  if (isError) {
    return (
      <p className='typeform-error'>
        The form didn't load correctly.
        Please refresh the page and try again.
        If the problem persists, reach out to us at
        <a href='mailto:success@outlier.org'> success@outlier.org.</a>
      </p>
    )
  }

  return <div id='embedded-typeform' ref={formRef} />
}

export default GuardianPermissionForm
