import React, { useContext } from 'react'
import Context from '../Context/Context'
import CourseButton from '../CourseButton/CourseButton'
import LockedMarker from '../LockedMarker/LockedMarker'
import { capitalizeFirstLetter } from '../../utilities'
import { emitter } from '../Emitter/Emitter'
import {
  ON_NAVIGATE_TO
} from '../../Constants/emitterKeys'

export default function SectionHomepageLectureButton ({
  exercise,
  isCompleted,
  isUnlocked,
  sectionUUID
}) {
  const {
    isCohortEndedForStudent
  } = useContext(Context)
  if (!isUnlocked) return <LockedMarker />
  const { type, question_set_uuid: questionSetUuid } = exercise
  const captilalizedType = capitalizeFirstLetter(type)
  const handleClick = () => {
    emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/${questionSetUuid}`)
    window.outlierLog('Section start', captilalizedType)
  }

  return <CourseButton
    data-cy='sectionLectureButton'
    className={{
      'btn-primary': !isCompleted && !isCohortEndedForStudent,
      'btn-secondary': !!isCompleted || isCohortEndedForStudent
    }}
    onClick={handleClick}
    dataUuid={sectionUUID + '/' + questionSetUuid}
  >
    {isCohortEndedForStudent ? 'View' : isCompleted ? 'Replay' : 'Play'}
  </CourseButton>
}
