import styled from 'styled-components'

export const Header = styled.div`
  padding-top: 32px;
  font-weight: 300;
  font-size: 36px;
  line-height: 43px;
`

export const AssessmentInfoContainer = styled.div`
  padding: 14px 0 36px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255,255,255,0.3);
`

export const CourseTitle = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
`

export const Dot = styled.div`
  margin: 6px;
  background: #98A4AE;
  width: 2px;
  height: 2px;
`

export const AssessmentTitle = styled.div`
  color: #B1BFC5;
  font-size: 16px;
  line-height: 19px;
`

export const Description = styled.div`
  margin-top: 36px;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;

  a {
    font-weight: 400;
  }
`

export const DeadlineSection = styled.div`
  margin-top: 24px;
  font-size: 18px;
  line-height: 22px;
`

export const OriginalDeadline = styled.div`
  font-weight: 300;
`

export const UnrequestedExtensionDeadline = styled.div`
  font-weight: 400;
`

export const RequestedExtensionDeadline = styled.div`
  font-weight: 700;
  margin-top: 12px;
`

export const TypeFormContainer = styled.div`
  height: 600px;
`
