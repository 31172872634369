import React, { useContext } from 'react'
import Context from '../../Context/Context'
import { SubHeader } from '../styled'
import { getGradingScale } from '../Utils'
import GradingScaleItem from './GradingScaleItem'
import { GradingScaleContainer } from './styled'

const GradingScale = ({ subHeader }) => {
  const {
    courseData: {
      gradeScales
    },
    cohortData
  } = useContext(Context)

  const gradingScale = getGradingScale({ gradeScales, cohortData })

  if (!gradingScale?.length) return null

  return (
    <>
      {subHeader && <SubHeader>{subHeader}</SubHeader>}
      <GradingScaleContainer>
        {gradingScale.map(scale => (
          <GradingScaleItem scale={scale} key={scale.symbol} />
        ))}
      </GradingScaleContainer>
    </>
  )
}

export default GradingScale
