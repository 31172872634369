import React, { useMemo, useEffect, useState, useRef } from 'react'
import {
  Wrapper,
  DisplayText,
  ButtonWrap,
  NextButton,
  NextSetButton,
  LoaderWrap,
  Message,
  CheckBox
} from './styles'
import { emitter } from '../Emitter/Emitter'
import {
  ON_EXAM_COMPLETE,
  ON_SECTION_DATA_PROGRESS,
  ON_IS_LAST_SECTION,
  ON_TRACK_STUDENT_EVENT,
  ON_SECTION_PROGRESS,
  ON_MINIMUM_SECTION_PROGRESS,
  ON_SUBMIT_ANSWER,
  ON_NAVIGATE_TO,
  ON_INSTRUCTOR_SWITCH,
  ON_GUESSWORK_COMPLETE
} from '../../Constants/emitterKeys'
import {
  CONCEPT_MAP_COMPLETE,
  CONCEPT_MAP_PROGRESS,
  EXAM_COMPLETE,
  LECTURE_COMPLETE,
  ORIENTATION_LECTURE_COMPLETE,
  REVIEW_LECTURE_COMPLETE,
  PRACTICE_TERM_COMPLETE,
  QUIZ_SECTION,
  READINGS_COMPLETE,
  READINGS_PROGRESS,
  EXAM_STATUS
} from '../../Constants/studentContext'
import {
  EXAM,
  ACTIVE_LEARNING,
  QUIZ,
  GUESSWORK,
  READINGS,
  CONCEPT_MAP,
  LECTURE,
  DISCUSSION
} from '../../Constants/sectionType'
import {
  getAnswerForExamQuestions,
  getUnansweredQuestions,
  transformActivityType
} from '../../utilities/sectionUtils'
import { isLastQuestionInSet } from '../../utilities/questions'
import config from '../../config'
import BurstConfetti from '../BurstConfetti/BurstConfetti'
import Modal from '../Modals/Alert/Modal'
import {
  getActiveLearningCompleteKey,
  getStudentAnswersKey
} from '../../utilities/contextKeys'
import { isMidExam, shouldSaveMinimumSectionProgress } from '../../utilities/chapterUtils'
import { ACTIVE_LEARNING_COMPLETED } from '../../Constants/eventTypes'
import { MULTIPLE } from '../../Constants/frequency'
import { groupBy, size } from 'lodash'
import {
  getModalCorrectPosition
} from '../../utilities/modalUtils'
import { getLatestProgress, hasProgress } from '../../utilities/studentProgressUtils'
import { getLocalStorageKey, isExamRetakeType } from '../../utilities/examUtils'
import {
  getCodegradeToken,
  getSubmissionGrades,
  getSubmissionsAndAutotests
} from '../../utilities/codegradeUtils'
import { setAsyncTimeout } from '../../utilities'
import LoadingSpinner from '../LoadingSpinner/LoadingAnimation'
import { CODING_QUESTION } from '../../Constants/questionType'
import { CODING_ASSIGNMENT_PATH, EXAM_STATUSES, PRACTICE_TERM_PATH, WRITING_ASSIGNMENT_PATH } from '../../Constants'
import api from '../../api'

const LastQuestionConfirmationModal = ({ props }) => {
  const [burstConfetti, setBurstConfetti] = useState(false)
  const [scrolledToBottom, setScrolledToBottom] = useState(false)
  const [distanceToBottom, setDistanceToBottom] = useState(0)
  const [markCompleted, setMarkCompleted] = useState(false)
  const [counter, setCounter] = useState(10)
  const [isStayHere, setIsStayHere] = useState(false)
  const intervalID = useRef(null)
  const [modal, setModal] = useState({
    show: false,
    content: ''
  })
  const [isSavingAnswers, setIsSavingAnswers] = useState(false)
  const {
    currentQuestionSet,
    studentData,
    updateContext,
    context: { course: { currentInstructor, instructors },
      isALRedesign, currentChapter: { type, title },
      cohortData: { duration, name: cohort, courseDisplayName },
      submitAssignment,
      currentActiveLearningTheme,
      examRetake,
      cohortData,
      currentChapter,
      hasActiveLearning,
      isModalSubmitDisabled,
      isMultiPartUploadingModal,
      isResubmitModal,
      isMultiPartUploading
    },
    isLastQuestionAnswered,
    nextActivity
  } = props
  const { quest_type: questType, type: questionType, question_set_uuid: questionSetUuid } = currentQuestionSet || {}
  const isCurrentCalculusCourse = config.isCalculusCourse()
  const isActiveLearning = questType === ACTIVE_LEARNING
  const isQuiz = questType === QUIZ
  const isExam = questType === EXAM
  const isGuesswork = questType === GUESSWORK
  const isReading = questionType === READINGS
  const isLecture = questionType === LECTURE?.toLowerCase()
  const isAssignment = [WRITING_ASSIGNMENT_PATH, CODING_ASSIGNMENT_PATH]
    .includes(questionType)
  const isPracticeTerm = [PRACTICE_TERM_PATH].includes(questionType)
  const isMarkCompleted = markCompleted
  const studentAnswersQuestions = studentData?.studentAnswers?.length || 0
  const currInstructorQuestionSet = currentQuestionSet?.data
    ?.filter(question => question.instructor?.['instructor_uuid'] === currentInstructor)
  const instructor = instructors?.length && instructors.find(instructor =>
    instructor.instructor_uuid === currentInstructor)
  const questionData = isCurrentCalculusCourse && isActiveLearning
    ? currInstructorQuestionSet : currentQuestionSet?.data
  const questIds = questionData?.map(quest => quest.Question_uuid)
  const studentAnswersKey = getStudentAnswersKey(type)
  const studentChapterAnswers = studentData[studentAnswersKey]
  const studentAnswers = studentChapterAnswers?.filter(({ answer, uuid }) =>
    typeof answer !== 'undefined' && questIds?.indexOf(uuid) > -1)
  const unAnsweredQuestions = useMemo(
    () =>
      getUnansweredQuestions(
        studentAnswers,
        questionData || []
      ),
    // eslint-disable-next-line
    [studentAnswers, questionData, studentAnswersQuestions]
  )
  const answeredIncorrectly = useMemo(() => (
    studentAnswers.reduce((acc, curr) => curr.correct ? acc : acc + 1, 0)
  ), [studentAnswers])
  const isAllAnsweredCorrectly = answeredIncorrectly === 0
  const [sectionUUID = '', currentQuestionId = ''] = props?.activePath?.split('/').filter(path => !!path) || []
  const unAnsweredCount = unAnsweredQuestions?.length
  const isLastQuestion = useMemo(
    () =>
      isLastQuestionInSet(questionData || [], currentQuestionId, unAnsweredCount),
    [questionData, currentQuestionId, unAnsweredCount]
  )

  useEffect(() => {
    const isLastAnswered = isLastQuestion
      ? true
      : isLastQuestionAnswered && false
    updateContext({ isLastQuestionAnswered: isLastAnswered })
    // eslint-disable-next-line
  }, [isLastQuestion, isLastQuestionAnswered]);

  useEffect(() => {
    const completionModalisVisible = unAnsweredCount === 0
    if (completionModalisVisible) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  })

  const filterVideosByProgress = ({ videos, embedCodeField, studentData }) => {
    if (!videos?.length) return videos
    const progressKeysToCheck = [
      LECTURE_COMPLETE, ORIENTATION_LECTURE_COMPLETE, REVIEW_LECTURE_COMPLETE
    ]
    return videos.filter(video => {
      const { [embedCodeField]: kalturaEmbedCode } = video || {}
      return !progressKeysToCheck.some(key => studentData?.[key]?.[kalturaEmbedCode])
    })
  }

  const getInCompletedVideos = () => {
    const multipleVideos = currentQuestionSet?.multipleVideos
    const { studentData } = props?.context || {}
    const inCompletedMultipleVideos = filterVideosByProgress({
      videos: multipleVideos, studentData, embedCodeField: 'kalturaEmbedCode'
    })
    if (multipleVideos?.length) return inCompletedMultipleVideos

    const allVideos = currentQuestionSet?.lecturevideos
    const inCompletedVideos = filterVideosByProgress({
      videos: allVideos, studentData, embedCodeField: 'kaltura_embed_code'
    })
    return inCompletedVideos
  }

  const isLecturesCompleted = !getInCompletedVideos()?.length

  const getInCompletedReadings = () => {
    const isConceptMap = currentQuestionSet?.title === CONCEPT_MAP
    const progressKey = isConceptMap ? CONCEPT_MAP_PROGRESS : READINGS_PROGRESS
    const progress = studentData?.[progressKey] || {}
    const allReadings = currentQuestionSet?.allReadings || []
    const incompleteReadings = []
    for (let i = 0; i < allReadings.length; i++) {
      if (!progress[allReadings[i].question_set_uuid]) {
        incompleteReadings.push(allReadings[i].question_set_uuid)
      }
    }
    return incompleteReadings
  }
  const isReadingsCompleted = !getInCompletedReadings().length

  useEffect(() => {
    if (isLecture && counter > 0 && !isStayHere) {
      intervalID.current = setInterval(
        () => setCounter(prevCounter => prevCounter - 1), 1000
      )
    } else if (counter <= 0 && !isStayHere && !isLecturesCompleted) {
      submitLecture()
    }
    return () => clearInterval(intervalID.current)
    // eslint-disable-next-line
  }, [isLecture, counter, isStayHere, isLecturesCompleted])

  const handleScroll = () => {
    const [scrolledToBottom, currentDistanceToBottom] = getModalCorrectPosition()
    setScrolledToBottom(scrolledToBottom)
    setDistanceToBottom(currentDistanceToBottom)
  }

  const activeLearningStudentAnswers = studentChapterAnswers?.filter(({ answer, uuid }) =>
    typeof answer !== 'undefined' && currentQuestionSet?.data?.map(quest => quest.Question_uuid)?.indexOf(uuid) > -1)
  const activeLearningUnAnsweredQuestions = useMemo(
    () =>
      getUnansweredQuestions(
        activeLearningStudentAnswers,
        currentQuestionSet?.data || []
      ),
    // eslint-disable-next-line
    [activeLearningStudentAnswers, currentQuestionSet?.data, studentAnswersQuestions]
  )
  const groupedActiveLearningSets = groupBy(activeLearningUnAnsweredQuestions,
    question => question.instructor?.['theme_name'] || '')
  const activeLearningSets = Object.keys(groupedActiveLearningSets || '{}')
  if (!isPracticeTerm && !isAssignment && !isReading && !isLecture && !isLastQuestion) return null
  const isMoreActiveLearning =
    activeLearningSets?.length > 1 || activeLearningSets?.filter(set => set !== instructor?.['theme_name'])?.length !== 0

  const showNextSetButton = unAnsweredCount === 0 && isActiveLearning && isAllAnsweredCorrectly &&
    isCurrentCalculusCourse && isMoreActiveLearning
  const showSectionHomeButton = isActiveLearning && unAnsweredCount === 0 && isAllAnsweredCorrectly
  const showSubmitButton = !isActiveLearning && unAnsweredCount === 0
  const questionText = unAnsweredCount > 1 ? 'questions' : 'question'
  const hasNoActiveLearningAfterVideos =
    isLecture && !hasActiveLearning && !isStayHere && isLecturesCompleted
  const getDisplayText = () => {
    const { type: activityType } = nextActivity || {}
    if (isGuesswork && unAnsweredCount > 0) {
      return 'You haven’t answered all of the questions yet.'
    } else if (isActiveLearning && unAnsweredCount > 0) {
      return `You reached the end of the activity but have ${unAnsweredCount} ${unAnsweredCount > 1 ? 'cards' : 'card'} left to complete.`
    } else if (unAnsweredCount > 0) {
      return `You reached the end of the ${questType.toLowerCase()} but have ${unAnsweredCount} ${questionText} left to complete.`
    } else {
      if (isActiveLearning) {
        return isAllAnsweredCorrectly
          ? showNextSetButton ? 'Chapter complete! Continue to the next one.'
            : `Active Learning complete! Continue to ${transformActivityType(activityType)}.`
          : `You reached the end of the ${questType.toLowerCase()} but have ${answeredIncorrectly} ${questionText} answered incorrectly.`
      } else if (isQuiz || isExam) {
        return 'You answered all of the questions! Ready to submit?'
      } else if (isMultiPartUploading) {
        return 'Uploading submissions...'
      } else if (isResubmitModal) {
        return 'Ready to resubmit your assignment?'
      } else if (isAssignment) {
        return 'Ready to submit your assignment?'
      } else if ((isPracticeTerm || isReading) && !isMarkCompleted) {
        return 'Mark activity as complete.'
      } else if (isReading && isMarkCompleted && !isReadingsCompleted) {
        return 'Readings complete! Continue to the next reading.'
      } else if (isReading && isMarkCompleted && isReadingsCompleted) {
        return `Reading complete! Continue to ${transformActivityType(activityType)}.`
      } else if (isPracticeTerm) {
        return `Practice Terms complete! Continue to ${transformActivityType(activityType)}.`
      } else if (isGuesswork) {
        return `Guesswork complete! Continue to ${transformActivityType(activityType)}.`
      } else if (hasNoActiveLearningAfterVideos) {
        return 'Lectures complete! Let’s move on to the next section.'
      } else if (isLecture && !isStayHere && isLecturesCompleted && hasActiveLearning) {
        return `Lectures complete! Continue to ${transformActivityType(activityType)}.`
      } else if (isLecture && !isStayHere) {
        return `Lecture complete! The next one will play in ${counter} seconds...`
      } else if (isLecture && isStayHere) {
        return `Lecture complete!`
      } else {
        return 'You have answered all of the questions!'
      }
    }
  }

  const submitPracticeTerm = () => {
    const { courseData, currentChapter: currChapter } = props.context
    const { uuid } = nextActivity || {}
    const CurrentSectionUUID = window?.location?.hash.split('/')[1]
    const currentChapter = courseData?.chapters?.findIndex(
      chapter => chapter?.['chapter_uuid'] === currChapter?.uuid)
    const currentSection = courseData?.chapters[currentChapter]
    const currentSectionIndex = currentSection?.sections?.findIndex((section) =>
      section?.['section_uuid'] === CurrentSectionUUID
    )
    const nextSectionUUID = currentSection?.sections[currentSectionIndex + 1]?.['section_uuid']
    const sectionUUID = currentSection?.sections[currentSectionIndex]?.['section_uuid']
    updateContext({ showFinish: false })
    if (uuid) {
      emitter.emit(ON_NAVIGATE_TO, '/' + sectionUUID + '/' + uuid)
    } else {
      emitter.emit(ON_NAVIGATE_TO, '/' + nextSectionUUID)
    }
  }

  const submitGuesswork = () => {
    const key = ON_GUESSWORK_COMPLETE
    emitter.emit(
      ON_SECTION_DATA_PROGRESS,
      { key: key, sectionUUID, value: true, title, isMidExam: isMidExam(currentChapter) }
    )
    const lastAnswer = studentAnswers.find((studentAnswers) =>
      studentAnswers.uuid === currentQuestionId)
    if (lastAnswer && lastAnswer.answer !== '') {
      emitter.emit(ON_SUBMIT_ANSWER, lastAnswer)
    }
    emitter.emit(ON_MINIMUM_SECTION_PROGRESS, sectionUUID)
    emitter.emit(ON_SECTION_PROGRESS, sectionUUID)
    const CurrentSectionUUID = window?.location?.hash.split('/')[1]
    const { uuid } = nextActivity
    emitter.emit(ON_NAVIGATE_TO, '/' + CurrentSectionUUID + '/' + uuid)
    updateContext({ showFinish: false })
  }

  const submitReading = () => {
    const CurrentSectionUUID = window?.location?.hash.split('/')[1]
    if (isReadingsCompleted) {
      const { uuid } = nextActivity
      emitter.emit(ON_NAVIGATE_TO, '/' + CurrentSectionUUID + '/' + uuid)
    } else {
      emitter.emit(ON_NAVIGATE_TO, '/' + CurrentSectionUUID + '/' + getInCompletedReadings()[0])
      window.location.reload()
    }

    updateContext({ showFinish: false })
  }
  const submitLecture = () => {
    const { course, nextChildUUID, courseData, currentChapter: currChapter } = props?.context
    const CurrentSectionUUID = window?.location?.hash.split('/')[1]
    const inCompletedVideos = getInCompletedVideos()
    const currentInstructor = course?.currentInstructor
    const nextVideoUUID = inCompletedVideos?.[0]?.id || questionSetUuid
    const nextVideo = inCompletedVideos?.find(
      (video) => video?.instructor?.['instructor_uuid'] !== currentInstructor
    )
    const { instructor_uuid: instructorUUID } = nextVideo?.instructor || {}
    if (hasNoActiveLearningAfterVideos) {
      const currentChapter = courseData?.chapters?.findIndex(
        chapter => chapter?.['chapter_uuid'] === currChapter?.uuid)
      const currentSection = courseData?.chapters[currentChapter]
      const currentSectionIndex =
        currentSection?.sections?.findIndex((section) =>
          section?.['section_uuid'] === CurrentSectionUUID
        )
      const nextSectionUUID =
        currentSection?.sections[currentSectionIndex + 1]?.['section_uuid']
      emitter.emit(ON_NAVIGATE_TO, '/' + nextSectionUUID)
    } else if (isLecturesCompleted) {
      emitter.emit(ON_NAVIGATE_TO, '/' + CurrentSectionUUID + '/' + nextChildUUID)
    } else {
      emitter.emit(ON_INSTRUCTOR_SWITCH, instructorUUID)
      emitter.emit(ON_NAVIGATE_TO, '/' + CurrentSectionUUID + '/' + nextVideoUUID)
    }
    updateContext({ showFinish: false })
  }
  const onNextSet = async () => {
    updateContext({ isLastQuestionAnswered: false })

    if (isAssignment) {
      submitAssignment()
      return
    }
    if (isPracticeTerm) {
      submitPracticeTerm()
      return
    }
    if (isGuesswork) {
      submitGuesswork()
      return
    }
    if (isReading) {
      submitReading()
      return
    }
    if (isLecture) {
      submitLecture()
      return
    }
    const isOnline = await api.checkServerStatus()
    if (!isOnline) {
      setModal({
        show: true,
        content: <>
          <h1>Network Error</h1>
          <p>
            Unable to submit quiz/exam at this time. Please try to submit again.
          </p>
          <NextSetButton className='btn btn-primary' onClick={() => {
            setModal({ ...modal, show: false })
          }} >OK</NextSetButton>
        </>
      })
      return
    }

    const {
      currentQuestionSet: { quest_type: questionType, practiceUUID }
    } = props
    const isExam = questionType === EXAM

    const latestProgress = !config.isPreviewCourse
      ? await getLatestProgress() : null
    latestProgress && props.context.setStudentProgress(latestProgress)
    const isAlreadyComplete = hasProgress({
      uuid: isExam ? sectionUUID : practiceUUID,
      progressKey: isExam ? EXAM_COMPLETE : QUIZ_SECTION,
      studentProgress: latestProgress
    })
    const isRetakeAllowed = isExamRetakeType({
      examRetake, chapter: currentChapter, cohortId: cohortData?.cohortID
    })
    const isRetakeComplete = latestProgress?.[EXAM_COMPLETE]?.[currentQuestionSet?.practiceUUID]
    const considerExamComplete = isRetakeAllowed ? isRetakeComplete : isAlreadyComplete
    if (considerExamComplete) {
      const {
        context: { user: { email } },
        currentQuestionSet: { breadcrumbArr: [[keyTitle = '']] }
      } = props
      const localStorageKey = getLocalStorageKey(
        email,
        config.courseName,
        keyTitle
      )
      localStorage.removeItem(localStorageKey)
      emitter.emit(
        ON_NAVIGATE_TO,
        `/${sectionUUID}/${currentQuestionSet.section_ending_uuid}`
      )
      return
    }

    const examAnswers = getAnswerForExamQuestions(
      studentAnswers,
      questionData
    )

    setIsSavingAnswers(true)
    updateContext({ isSubmittingAnswers: true })
    const hasCodingQuestion = questionData.some(question => {
      return question.question_type === CODING_QUESTION
    })
    if (hasCodingQuestion) await getCodegradeToken()
    const submissions = await getSubmissionsAndAutotests(examAnswers || [])
    if (!submissions?.length) return setIsSavingAnswers(false)

    await setAsyncTimeout(async () => {
      const examAnswerList = await getSubmissionGrades(submissions || [])
      setIsSavingAnswers(false)
      emitter.emit(ON_EXAM_COMPLETE, { examAnswerList })
      if (isExam) {
        emitter.emit(
          ON_SECTION_DATA_PROGRESS,
          {
            key: EXAM_COMPLETE,
            sectionUUID,
            value: true,
            title,
            isMidExam: isMidExam(currentChapter)
          }
        )
        emitter.emit(
          ON_SECTION_DATA_PROGRESS,
          {
            key: EXAM_STATUS,
            sectionUUID,
            value: EXAM_STATUSES.COMPLETE
          }
        )
        const { examLockEvent } = props.context
        examLockEvent && examLockEvent.close()
        updateContext({ examLockEvent: null })
      }
      emitter.emit(ON_IS_LAST_SECTION)
      emitter.emit(
        ON_NAVIGATE_TO,
        `/${sectionUUID}/${currentQuestionSet.section_ending_uuid}`
      )
    }, hasCodingQuestion ? 30000 : 0)
  }

  const onChangeSet = () => {
    setBurstConfetti(true)
    setTimeout(async () => {
      setBurstConfetti(false)
      finishQuestionSet()
      const nextSetQuestionUUID = Object.values(groupedActiveLearningSets || '{}')?.[0]?.[0]?.['Question_uuid']
      await emitter.emit(
        ON_NAVIGATE_TO,
        `/${sectionUUID}`
      )
      emitter.emit(
        ON_NAVIGATE_TO,
        `/${sectionUUID}/${nextSetQuestionUUID}`
      )
    }, 1000)
  }

  const onNavigateToSectionHome = () => {
    const { courseData, currentChapter: currChapter } = props.context
    const CurrentSectionUUID = window?.location?.hash.split('/')[1]
    const currentChapter = courseData?.chapters?.findIndex(
      chapter => chapter?.['chapter_uuid'] === currChapter?.uuid
    )
    const currentSection = courseData?.chapters[currentChapter]
    const currentSectionIndex = currentSection?.sections?.findIndex(
      section => section?.['section_uuid'] === CurrentSectionUUID
    )
    const nextSectionUUID = currentSection?.sections[currentSectionIndex + 1]?.['section_uuid']
    const { type: activityType, uuid } = nextActivity || {}

    const navigateTo = (path) => {
      setBurstConfetti(true)
      setTimeout(() => {
        finishQuestionSet()
        emitter.emit(ON_NAVIGATE_TO, path)
      }, 1000)
    }

    if (activityType === DISCUSSION) {
      navigateTo(`/${sectionUUID}`)
    } else if (uuid) {
      navigateTo(`/${sectionUUID}/${uuid}`)
    } else {
      navigateTo(`/${nextSectionUUID}`)
    }
  }

  const getSubChapterTitle = () => {
    const { courseData, currentChapter: currChapter } = props.context
    const currentSectionUUID = window?.location?.hash.split('/')[1]
    const currentChapter = courseData?.chapters?.findIndex(
      chapter => chapter?.['chapter_uuid'] === currChapter?.uuid)
    const currentSection = courseData?.chapters[currentChapter]
    const currentSectionIndex = currentSection?.sections?.findIndex((section) =>
      section?.['section_uuid'] === currentSectionUUID
    )

    return currentSection?.sections[currentSectionIndex]?.title
  }

  const getButtonText = () => {
    if (isMultiPartUploading) {
      return <i className='fa fa-spinner fa-spin' />
    }
    if (isResubmitModal) return 'Resubmit'
    if (isQuiz) return 'Submit Quiz'
    if (isExam) return 'Submit Exam'
    if (isAssignment) return 'Submit'
    if (isGuesswork || (isLecture && isLecturesCompleted)) return 'Continue'
    if (isLecture) return 'Play next'
    return `Submit ${questType}`
  }

  const finishQuestionSet = () => {
    let answers = studentAnswers
    const studentContextKey = getActiveLearningCompleteKey(type)
    const saveMinimumSectionProgress = shouldSaveMinimumSectionProgress({ type })
    const lastAnswer = studentAnswers.find((studentAnswers) =>
      studentAnswers.uuid === currentQuestionId)
    if (lastAnswer && lastAnswer.answer !== '') {
      emitter.emit(ON_SUBMIT_ANSWER, lastAnswer)
    }
    // Since calculus courses have active learning questions grouped on the basis of theme. answers variable
    // would only have answers for the current theme, adding a check for calculus courses to find all the
    // answers for the section to check if the section is complete or not.
    if (isCurrentCalculusCourse) {
      const questIds = currentQuestionSet?.data.map(quest => quest.Question_uuid)
      answers = studentChapterAnswers.filter(({ answer, uuid }) =>
        typeof answer !== 'undefined' && questIds.indexOf(uuid) > -1)
    }
    const studentAnswersList =
      answers.map(studentAnswer => studentAnswer.uuid)
    const questionList = currentQuestionSet?.data
    const unAnsweredQuestions =
      questionList.filter(question => !studentAnswersList.includes(question.Question_uuid))
    const unAnsweredQuestionsList = groupBy(unAnsweredQuestions,
      unAnsweredQuestion => unAnsweredQuestion.instructor?.['theme_name'] || '')
    const hasUnAnsweredQuestions = !!size(unAnsweredQuestionsList)
    emitter.emit(
      ON_SECTION_DATA_PROGRESS,
      {
        key: studentContextKey,
        sectionUUID,
        value: !hasUnAnsweredQuestions,
        title,
        isMidExam: isMidExam(currentChapter)
      }
    )
    if (!unAnsweredCount) {
      saveMinimumSectionProgress && emitter.emit(ON_MINIMUM_SECTION_PROGRESS, sectionUUID)
    }
    if (!hasUnAnsweredQuestions) {
      emitter.emit(ON_SECTION_PROGRESS, sectionUUID)
    }
    const subChapterTitle = getSubChapterTitle()
    const eventData = {
      properties: {
        course: courseDisplayName,
        cohort_length: duration,
        cohort,
        chapter: title,
        sub_chapter: subChapterTitle || '',
        time_stamp: new Date().getTime(),
        ...(currentActiveLearningTheme) &&
        { theme_name: currentActiveLearningTheme }
      },
      event: ACTIVE_LEARNING_COMPLETED,
      frequency: MULTIPLE
    }
    emitter.emit(ON_TRACK_STUDENT_EVENT, eventData)
    emitter.emit(ON_IS_LAST_SECTION)
  }

  const onMarkReadingComplete = () => {
    const isConceptMap = currentQuestionSet?.title === CONCEPT_MAP
    const key = isConceptMap ? CONCEPT_MAP_COMPLETE : READINGS_COMPLETE
    const progressKey = isConceptMap ? CONCEPT_MAP_PROGRESS : READINGS_PROGRESS
    emitter.emit(
      ON_SECTION_DATA_PROGRESS,
      { key: key, sectionUUID, value: true, title, isMidExam: isMidExam(currentChapter) }
    )
    emitter.emit(
      ON_SECTION_DATA_PROGRESS,
      {
        key: progressKey,
        sectionUUID: currentQuestionId,
        value: true,
        title,
        isMidExam: isMidExam(currentChapter)
      }
    )
    emitter.emit(ON_MINIMUM_SECTION_PROGRESS, sectionUUID)
    emitter.emit(ON_SECTION_PROGRESS, sectionUUID)
    setMarkCompleted(true)
  }

  const onMarkComplete = () => {
    if (isReading) {
      onMarkReadingComplete()
      return
    }
    const key = PRACTICE_TERM_COMPLETE
    const value = true
    emitter.emit(ON_SECTION_DATA_PROGRESS, {
      key,
      value,
      title,
      sectionUUID
    })
    emitter.emit(ON_SECTION_PROGRESS, sectionUUID)
    emitter.emit(ON_MINIMUM_SECTION_PROGRESS, sectionUUID)
    setTimeout(() => {
      setMarkCompleted(true)
    }, 400)
  }

  const savingQuizExamAnswers = [QUIZ, EXAM].includes(questType) && isSavingAnswers
  if (savingQuizExamAnswers) {
    const isCSCourse = config.isCSCourse()
    return (
      <LoaderWrap data-testid='loader-wrap'>
        {
          isCSCourse && <Message>
            <p>Compiling code submissions</p>
          </Message>
        }
        <LoadingSpinner />
      </LoaderWrap>)
  }
  if (isPracticeTerm || isReading) {
    return (
      <Wrapper
        unAnsweredCount={unAnsweredCount > 0}
        scrolledToBottom={scrolledToBottom}
        distanceToBottom={distanceToBottom}
        isPracticeTerm={!isMarkCompleted}
        isFinish={unAnsweredCount === 0}
      >
        {isMarkCompleted
          ? <>
            <div>
              <DisplayText unAnsweredCount={unAnsweredCount > 0} isALRedesign={isALRedesign}>
                {getDisplayText()}
              </DisplayText>
            </div>
            <ButtonWrap
              unAnsweredCount={unAnsweredCount > 0}
              isALRedesign={isALRedesign}>
              <NextButton
                isALRedesign={isALRedesign}
                onClick={onNextSet}
                className='btn-primary'>
                Continue
              </NextButton>
            </ButtonWrap>
          </>
          : <div>
            <CheckBox>
              <label>
                <input
                  type='checkbox'
                  onClick={onMarkComplete}
                  data-testid='checkbox'
                  tabIndex='0'
                />
                <span />
                <DisplayText
                  unAnsweredCount={unAnsweredCount > 0}
                  isALRedesign={isALRedesign}
                >
                  {getDisplayText()}
                </DisplayText>
              </label>
            </CheckBox>
          </div>
        }
      </Wrapper>
    )
  }
  return (
    <>
      <Wrapper
        unAnsweredCount={unAnsweredCount > 0 && !isGuesswork}
        scrolledToBottom={scrolledToBottom}
        isActiveLearning={isActiveLearning}
        isFinish={unAnsweredCount === 0}
        distanceToBottom={distanceToBottom}>
        <div>
          <DisplayText unAnsweredCount={unAnsweredCount > 0} isALRedesign={isALRedesign}>
            {getDisplayText()}
          </DisplayText>
        </div>
        <ButtonWrap
          unAnsweredCount={unAnsweredCount > 0}
          isALRedesign={isALRedesign}>
          {showNextSetButton &&
            <NextSetButton className={isActiveLearning ? 'btn-primary' : 'btn-secondary'} onClick={onChangeSet}>
              {isActiveLearning ? 'Go to next' : 'Next Set'}
            </NextSetButton>}
          {burstConfetti &&
            <BurstConfetti
              height={400}
              width={300}
              particleCount={80}
            />}
          {showSubmitButton ? (
            <NextButton
              isALRedesign={isALRedesign}
              disabled={isMultiPartUploading || isModalSubmitDisabled}
              onClick={() => {
                if (isMultiPartUploadingModal || isResubmitModal) {
                  return updateContext({ submitAssignments: true })
                }
                onNextSet()
              }}
              className='btn-primary'>
              {getButtonText()}
            </NextButton>
          ) : null}
          {isResubmitModal &&
            <NextSetButton
              className='btn-secondary'
              onClick={() => updateContext({ cancelResubmission: true })}
              disabled={isMultiPartUploading}
            >
              Cancel
            </NextSetButton>}
          {isLecture && !isStayHere && !isLecturesCompleted &&
            <NextSetButton
              isLecture={isLecture}
              className={'btn-secondary'}
              onClick={() => setIsStayHere(true)}
            >
              Stay here
            </NextSetButton>}
          {showSectionHomeButton && !showNextSetButton ? (
            <NextButton
              isALRedesign={isALRedesign}
              onClick={onNavigateToSectionHome}
              className='btn-primary'>
              Continue
            </NextButton>
          ) : null}
        </ButtonWrap>
      </Wrapper>
      {modal?.show && (
        <Modal>
          {modal.content}
        </Modal>
      )}
    </>
  )
}
export default LastQuestionConfirmationModal
