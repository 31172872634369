import React, { useContext, useEffect, useMemo } from 'react'
import Context from '../Context/Context'
import { BannerContainer, NeedsReviewMessage, WarningIcon } from './styles'
import { getNeedsReviewingAttempts } from '../../utilities/courseUtils'
import api from '../../api'
import config from '../../config'
import { NEEDS_REVIEWING_DEGREE } from '../../Constants/studentStatus'

const Warning = <WarningIcon
  src='images/icons/warning-icon.svg'
  alt='warning-needs-review'
/>

const NeedsReviewBanner = () => {
  const { studentAttempts, updateContext } = useContext(Context)

  useEffect(() => {
    const fetchStudentAttempts = async () => {
      if (studentAttempts?.length) return

      const attempts = await api.getStudentAttempts()
      updateContext({ studentAttempts: attempts })
    }

    if (config.isPreviewCourse) return
    fetchStudentAttempts()

    // eslint-disable-next-line
  }, [])

  const needsReviewingAttempts = useMemo(() => {
    if (!studentAttempts?.length) return

    const duplicateAttempts = getNeedsReviewingAttempts(studentAttempts)
    return duplicateAttempts

    // eslint-disable-next-line
  }, [studentAttempts])

  if (!needsReviewingAttempts?.length) return null

  const degreeAttempts = needsReviewingAttempts.filter(attempt => {
    return attempt?.fields?.studentStatus?.fields?.Name === NEEDS_REVIEWING_DEGREE
  })
  const hasNeedsReviewingDegree = !!degreeAttempts?.length

  const courseName = hasNeedsReviewingDegree
    ? degreeAttempts.length === 1
      ? degreeAttempts?.[0].fields?.course?.fields?.displayName
      : ''
    : needsReviewingAttempts.length === 1
      ? needsReviewingAttempts[0].fields?.course?.fields?.displayName
      : ''

  return (
    <BannerContainer>
      {hasNeedsReviewingDegree
        ? (
          <NeedsReviewMessage>
            {Warning}
            You have purchased {courseName || 'multiple courses'} outside of your degree program. Please <a
              href='https://help.outlier.org/hc/en-us/requests/new'
              rel='noopener noreferrer'
              target='_blank'
            >
              speak with an Advisor
            </a> to discuss your options.
          </NeedsReviewMessage>
        ) : (
          <NeedsReviewMessage>
            {Warning}
            {courseName
              ? `You have attempted to enroll in the same ${courseName} cohort twice.`
              : 'You have attempted to enroll in the same cohort twice for multiple courses.'
            } Please contact <a
              href='https://help.outlier.org/hc/en-us/requests/new'
              rel='noopener noreferrer'
              target='_blank'
            >
              Student Success
            </a> to resolve this issue.
          </NeedsReviewMessage>
        )
      }
    </BannerContainer>
  )
}

NeedsReviewBanner.displayName = 'NeedsReviewBanner'

export default NeedsReviewBanner
