import React, { Component } from 'react'
// import { Button } from 'reactstrap'
import { Collapse } from 'reactstrap'
import SectionList from '../SectionList/SectionList'
import Context from '../Context/Context'
import {
  isReviewChapter,
  shouldHideSectionNumber,
  shouldHideChapterNumber
} from '../../utilities/chapterUtils'
import config from '../../config'
import {
  ChapterContentWrapper,
  ChapterDescription,
  ChapterTitle,
  ChapterToggleIcon,
  ChapterWrapper,
  ChapterInfoWrapper,
  ChapterHeader,
  SectionListWrapper,
  LightningIcon,
  CourseLockTextWrapper,
  CourseLockText
} from './styled'
import { history } from '../HistoryManager/HistoryManager'
import camelCase from 'lodash/camelCase'
import util from '../../utilities'
const { course } = config

class ChapterListItem extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: props.isOpen || (course.expandChapterListCollapse) || false,
      showErrorOnMobile: false
    }
    this.chapterRef = React.createRef()
    this.toggleCollapse = this.toggleCollapse.bind(this)
  }

  componentDidMount () {
    this.scrollToSection()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ open: this.props.isOpen })
    }
  }

  scrollToSection = () => {
    const {
      location: { hash }
    } = history
    const {
      chapter: { title }
    } = this.props
    const hasScrollHash = hash.slice(1) === camelCase(title)
    if (hasScrollHash) {
      this.chapterRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }

  toggleCollapse () {
    this.setState({ open: !this.state.open })
  }

  getChapterTitle = () => {
    const { chapter } = this.props
    const { hasWhatsNextUpdateFlag, isSpecialTopicsCourse,
      course: { use0BasedIndexing } } = config
    if (hasWhatsNextUpdateFlag && isSpecialTopicsCourse()) {
      const isSpecialTopic = chapter.title.includes('Special Topic')
      if (isSpecialTopic) return chapter.title
    }

    if (isReviewChapter(chapter)) return `Review: ${chapter.title}`
    if (shouldHideChapterNumber(chapter)) return chapter.title

    let chapterIndex = use0BasedIndexing
      ? chapter.chapterNumber - 1
      : chapter.chapterNumber
    chapterIndex = chapterIndex === undefined ? '' : chapterIndex
    return `Chapter ${chapterIndex.toString()} : ${chapter.title}`
  }

  stopPropagation = (event) => {
    event.stopPropagation()
  }

  render () {
    const {
      chapter,
      courseLockText
    } = this.props
    const {
      open
    } = this.state
    const hideDescription = { display: open ? 'inline-block' : 'none' }
    const title = this.getChapterTitle()
    const hideSectionNumber = shouldHideSectionNumber(chapter)
    return (
      <ChapterWrapper key={chapter.chapter_uuid} ref={this.chapterRef}>
        <ChapterInfoWrapper>
          <ChapterHeader
            onKeyPress={(e) => util.handleKeyPress(e, this.toggleCollapse)}
            onClick={this.toggleCollapse}
            tabIndex='0'
          >
            <ChapterContentWrapper className='main-panels--left w-75'>
              <ChapterTitle>
                {title}
              </ChapterTitle>
              <ChapterDescription
                onClick={this.stopPropagation}
                style={hideDescription}
              >
                { chapter.desc }
              </ChapterDescription>
              {courseLockText && chapter.chapterNumber === 1 &&
                <CourseLockTextWrapper show={open}>
                  <LightningIcon />
                  <CourseLockText>
                    {courseLockText}
                  </CourseLockText>
                </CourseLockTextWrapper>
              }
            </ChapterContentWrapper>
            <ChapterToggleIcon data-cy='chapterToggleBtn' collapsed={!open} />
          </ChapterHeader>
          <Collapse
            className='section-panel__collapse'
            id={chapter.chapter_uuid}
            isOpen={this.state.open}
            data-cy='sectionPanel'
          >
            <SectionListWrapper>
              <SectionList
                data-testid='section-list'
                sections={chapter.sections}
                key={chapter.chapter_uuid}
                chapter_uuid={chapter.chapter_uuid}
                chapterType={chapter.type}
                chapterLoopId={chapter.chapterNumber}
                lockReason={this.props.lockReason}
                hideSectionNumber={hideSectionNumber}
              />
            </SectionListWrapper>
          </Collapse>
        </ChapterInfoWrapper>
      </ChapterWrapper>
    )
  }
}

ChapterListItem.contextType = Context
export default ChapterListItem
