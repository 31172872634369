export const findNumberOfPages = async pdfUrl => {
  try {
    const response = await fetch(pdfUrl)

    const result = await response.text()
    const pages = result.match(/\/Type[\s]*\/Page[^s]/g)
    const count = pages?.length

    return count
  } catch (error) {
    throw new Error(`Error when fetching pdf: ${error.message}`)
  }
}

export const downloadFile = async (fileUrl, data) => {
  let response
  let responseBlob

  try {
    if (fileUrl) {
      response = await fetch(fileUrl)
      responseBlob = await response.blob()
    }

    if (data) {
      responseBlob = data.blob
    }

    const url = window.URL.createObjectURL(responseBlob)

    const link = document.createElement('a')
    link.href = url
    link.download = fileUrl
      ? fileUrl.split('/').pop()
      : data.fileName
    link.click()
  } catch (e) {
    console.error(`Error when fetching file: ${e.message}`)
  }
}
