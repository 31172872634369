import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const Wrapper = styled.div`
  background: #25272b;
  display: flex;
  justify-content: flex-end;
  padding: 24px 0 24px 40px;
  padding-right: ${props => (props.isPracticeTerm ? '60px' : '0')};
  font-size: 16px;
  align-items: center;
  position: ${props => (props.isActiveLearning && props.unAnsweredCount ? 'absolute' : props.unAnsweredCount ? 'relative' : 'fixed')};
  margin-bottom: ${props => (props.unAnsweredCount ? '-70px' : '0')};
  bottom: ${props => (props.isActiveLearning && props.unAnsweredCount ? '7rem'
    : props.scrolledToBottom ? `${props.distanceToBottom}px` : props.unAnsweredCount
      ? 'unset'
      : props.isFinish ? 0 : props.scrolledToBottom
        ? `${props.distanceToBottom}px`
        : 0
  )};
  width: 100%;
  z-index: 9999;
  text-align: center;
  line-height: 19px;
  height: 88px;
  ${mediaqueries.tablet`
    height: 100px;
    ${isALRedesign => isALRedesign && 'padding: 24px 0 36px;'}
  `}
  ${mediaqueries.mobile`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: ${props => (props.isActiveLearning && props.unAnsweredCount && '7.5rem')};
    padding: 24px 0 ${props => (props.unAnsweredCount ? '24px' : '36px')} 0;
    height: ${props => (props.unAnsweredCount || props.isPracticeTerm ? '100px' : '143px')};
    position: ${props => ((props.isActiveLearning && props.unAnsweredCount) || !props.unAnsweredCount) && 'absolute'};
    z-index: 1;
`}
`
export const DisplayText = styled.p`
  margin: ${props => (props.unAnsweredCount ? '0 auto' : 0)};
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  ${mediaqueries.tablet`
  text-align: center;
  ${isALRedesign => !isALRedesign && 'padding: 0 24px;'}
`}
`

export const ButtonWrap = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  ${isALRedesign => isALRedesign && `
    display: flex;
    align-items: center;
  `}
  ${mediaqueries.mobile`
  ${isALRedesign => !isALRedesign && `width: 100%;`}
  margin: ${props => (props.unAnsweredCount ? '0px' : '24px')} 0 0 0;
  padding: 0 25px;
`}
`

export const NextButton = styled.button`
  position: relative;
  width: ${isALRedesign => isALRedesign ? '157px' : '150px'};
  height: 40px;
  ${mediaqueries.mobile`
    ${isALRedesign => !isALRedesign && `width: 100%;`}
`}
`

export const NextSetButton = styled(NextButton)`
  margin-right: ${isLecture => !isLecture && '12px'};
  width: ${isLecture => isLecture ? '130px !important' : '157px !important'};
  margin-left: ${isLecture => isLecture && '12px'}
`

export const LearningButtons = styled.div`
  ${mediaqueries.mobile`
    margin-top:24px;
    display: flex;
    justify-content: space-between;
    button {
      width: 47%;
    }
`}
`

export const CheckBox = styled.div`
  label {
    display: block;
    position: relative;
    padding-left: 0px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  span {
    position: absolute;
    top: 2px;
    left: -22px;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border-radius: 3px;
    border: 1px solid #5FC4B8;
  }
  label:hover input ~ span {
    background-color: transparent;
  }
  label input:checked ~ span {
    background-color: #5FC4B8;
  }
  label input:focus ~ span {
    border-color: #ffffff;
  }
  span:after {
    content: "";
    position: absolute;
    display: none;
  }
  label input:checked ~ span:after {
    display: block;
  }
  label span:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

export const LoaderWrap = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 9999;
  background: rgba(0,0,0,1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Message = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  color: #FFFFFF;
  margin-top: -5rem;

  p {
    padding: 0px;
    margin: 0px;
    margin-right: 0px;
  }
`
