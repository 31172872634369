import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import * as serviceWorker from './serviceWorker'
import App from './App'

import NavigationComponent from './Components/NavigationComponent/NavigationComponent'
import HistoryManager from './Components/HistoryManager/HistoryManager'
import TooltipTextModalContainer from './Components/TooltipTextModalContainer/TooltipTextModalContainer'
import { Auth0Provider } from './Components/Auth0Provider/Auth0Provider'
import ContextProvider from './Components/Context/ContextProvider'
import PageLayout from './Components/PageLayout/PageLayout'
import ErrorBoundary from './Components/ErrorBoundary'

(function () {
  const link = document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = process.env.PUBLIC_URL + 'images/icons/favicon.jpg'
  document.getElementsByTagName('head')[0].appendChild(link)
})()

ReactDOM.render(
  <ErrorBoundary>
    <Auth0Provider>
      <ContextProvider>
        <TooltipTextModalContainer>
          <HistoryManager>
            <PageLayout>
              <NavigationComponent>
                <App />
              </NavigationComponent>
            </PageLayout>
          </HistoryManager>
        </TooltipTextModalContainer>
      </ContextProvider>
    </Auth0Provider>
  </ErrorBoundary>,
  document.getElementById('root')
)

serviceWorker.register()
