import styled, { createGlobalStyle } from 'styled-components'
import media, { BREAKPOINTS } from '../../mediaQueries'

export const GlobalStyle = createGlobalStyle`
  #header-mb {
    display: none;
  }
`

export const Wrapper = styled.div`
`

export const SubHeader = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  text-transform: capitalize;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`

export const Container = styled.div`
  max-width: 1074px;
  margin: 0 auto;
  @media (max-width: ${BREAKPOINTS.giant + 80}px) {
    max-width: 994px;
  }
  @media (max-width: ${BREAKPOINTS.giant - 1}px) {
    max-width: 786px;
  }
  @media (max-width: ${BREAKPOINTS.desktop - 1}px) {
    max-width: 562px;
  }
  @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
    max-width: 550px;
  }

  ${media.mobile`
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 29px;
  `}
`

export const FAQContainer = styled.div`

  display: flex;
  justify-content: center;

  margin-top: 48px;

  position: relative;
  box-sizing: border-box;
  padding: 24px 12px;

  color: #fff;
  background: #161618;
  background-clip: padding-box;
  border: solid 1px transparent;
  border-radius: 5px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`

export const FAQText = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  text-align: center;

  b {
    font-weight: 400;
  }

  a {
    display: unset;
    text-decoration: underline;
    font-weight: 400;

    &:disabled {
      color: #b8fff7;
      opacity: 0.3;
    }
    &:not(:disabled) {
      &:active {
        color: #b8fff7;
        opacity: 0.3;
      }
      &:hover {
        color: #b8fff7;
      }
    }
  }
`

// Instructors

export const InstructorsWrapper = styled.div`
  margin: 48px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  > .title {
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 0;
  }
`

export const InstructorContainer = styled.div`
  height: fit-content;
  padding: 32px;
  border-radius: 5px;
  background-color: #0D0D0F;

  display: flex;
  gap: 48px;
  flex-direction: row;

  ${media.mobile`
    flex-direction: column;
    gap: 32px;
  `}
`

export const InstructorImg = styled.img`
  width: 260px;
  height: auto;
  min-width: 260px;
  max-height: 300px;
  object-fit: cover;
  background: radial-gradient(
    84.32% 84.32% at 53.47% 39.83%,
    rgba(22, 22, 24, 0) 36.46%,
    rgba(0, 0, 0, 0.25) 100%
  );

  ${media.tablet`
    min-width: 160px;
    max-height: 180px;
  `}

  ${media.mobile`
    width: 100%;
    max-height: 360px;
  `}
`

export const InstructorInfo = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;

  font-size: 18px;
  line-height: 24px;
  font-weight: 300;

  > .header {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 24px;
  }

  > .margin-top {
    margin-top: 24px;
  }

  > p {
    margin-bottom: 0;
  }

  .link {
    color: #5FC4B8;
    text-decoration: underline;

    :hover {
      color: #b8fff7;
    }
  }
`

export const BioContainer = styled.p`
  overflow: hidden;

  ${({ hideText }) => hideText && `
    height: ${6 * 24}px;
  `}

  ${media.mobile`
    ${({ hideText }) => hideText && `
      height: ${14 * 24}px;
    `}
  `}
`

export const ReadMoreWrapper = styled.span`
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  width: fit-content;
  color: #5FC4B8;

  :hover {
    color: #b8fff7;
  }
`

export const Caret = styled.span`
  width: 9px;
  height: 9px;
  border: 1px solid #5FC4B8;
  border-top-width: 0;
  border-right-width: 0;

  ${props => !props.toggle && `
    top: 3px;
    position: relative;
  `};
  margin-bottom: ${props => props.toggle && '3px'};
  margin-right: 12.5px;
  transform: ${props => (
    props.toggle ? 'rotate(-45deg)' : 'rotate(135deg)'
  )};

`
