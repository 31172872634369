import React, { useEffect, useMemo, useState } from 'react'
import api from '../../../api'
import config from '../../../config'
import { ON_NAVIGATE_TO } from '../../../Constants/emitterKeys'
import { PRACTICE_EXAM } from '../../../Constants/examType'
import { secondsSinceEpoch } from '../../../utilities/dateTimeUtils'
import { endProctorioSession, formatTag } from '../../../utilities/examUtils'
import CompletionPage from '..'
import { useStudentDataContext } from '../../Context/Context'
import CourseButton from '../../CourseButton/CourseButton'
import { emitter } from '../../Emitter/Emitter'
import LoadingSpinner from '../../LoadingSpinner/LoadingAnimation'

const UNLOCKED_TEXT = 'Practice Exam Complete! You’re now ready to take the exam.'
const LOCKED_TEXT = 'Practice Exam Complete! Let’s keep reviewing…'

const PracticeExamCompletionPage = () => {
  const [isTrackingProctorio, setIsTrackingProctorio] = useState(false)
  const { firstExamDates, isStudentProgressBusy } = useStudentDataContext()

  useEffect(() => {
    // end proctorio session on page refresh and on unmount
    window.onbeforeunload = () => endPracticeProctorioExam()
    return () => {
      endPracticeProctorioExam()
      window.onbeforeunload = null
    }
  }, [])

  useEffect(() => {
    trackProctorioExam()
  }, [])

  const endPracticeProctorioExam = () => {
    if (!config.insideProctorio) return
    endProctorioSession()
  }

  const trackProctorioExam = async () => {
    if (!config.insideProctorio) return

    const tag = formatTag(PRACTICE_EXAM)

    setIsTrackingProctorio(true)
    await api.trackProctorioExams(tag)
    setIsTrackingProctorio(false)
  }

  const getDescription = () => {
    if (!firstExamDates) return UNLOCKED_TEXT

    const { lockDate } = firstExamDates
    const isLocked = secondsSinceEpoch() >= lockDate
    if (isLocked) return LOCKED_TEXT

    return UNLOCKED_TEXT
  }

  const handleButtonClick = () => {
    emitter.emit(ON_NAVIGATE_TO, '/')
  }

  const description = useMemo(() => {
    return getDescription()
    // eslint-disable-next-line
  }, [firstExamDates])

  if (isStudentProgressBusy) return <LoadingSpinner />

  return (
    <CompletionPage
      imagePath='https://www.datocms-assets.com/25569/1595023195-albook.png'
      content={
        <p>{description}</p>
      }
      footer={
        <CourseButton
          className='btn btn-primary w-100'
          onClick={handleButtonClick}
          disabled={isTrackingProctorio}
        >
          Course Home
        </CourseButton>
      }
    />
  )
}

PracticeExamCompletionPage.displayName = 'PracticeExamCompletionPage'

export default PracticeExamCompletionPage
