import styled from 'styled-components'

export const EditorWrapper = styled.div`
  .quill {

    .ql-container {
      height: auto;
      width: auto;
      border: none;
      font-family: Lato;
      .ql-editor{
        padding: 0px;
        a {
          color: #78bcb8;
          text-decoration: none;
          background-color: transparent;
        }
        blockquote {
          border-radius: 0px;
        }
      } 
    }
  }
`
