import timeZoneList from './timeZonesList.json'
import { isNumber } from 'lodash'
export {
  convertDateToSecondsSinceEpoch,
  secondsSinceEpoch,
  getDateStringWithYear,
  getDifferenceOfDaysWithCurrentDate,
  dateToSecondsSinceEpoch,
  secondsToFormattedDateShort,
  secondsToFormattedTimeShort,
  secondsToFormattedDateTimeShort,
  weeksToSeconds,
  daysToSeconds,
  changeTimezone,
  diffDays,
  addDaysToDate,
  addDaysToDateInSecs,
  subtractDaysFromDateInSecs,
  getDateWithoutYear,
  getSecondsFromTime,
  getTimeFromSeconds,
  getNextWeekdayDate,
  getNextWeekdayDateInSecs,
  checkCohortDate,
  getElevenFiftyNinePmInSecs,
  getTimeAgoInMinsHoursAndDays,
  getTwelveAmInSecs,
  getDateMidNight,
  getTimezoneShort,
  secondsToFormattedDay
}

// undefined means use the user's locale settings
const USER_LOCALE = process.env.NODE_ENV === 'test' ? 'en-US' : undefined

// Returns the current date/time in seconds.
// Does not need tests because that would require mocking Date.now and is not
// worth the effort since this method is already well known. Instead see:
// https://stackoverflow.com/questions/3830244/get-current-date-time-in-seconds
function secondsSinceEpoch () {
  return Math.floor(Date.now() / 1000)
}

// Given a date, it returns that date/time in seconds.
// Does not need tests because that would require mocking Date.now and is not
// worth the effort since this method is already well known. Instead see:
// https://stackoverflow.com/questions/3830244/get-current-date-time-in-seconds
function dateToSecondsSinceEpoch (date) {
  return Math.floor(date.getTime() / 1000)
}

// Extracted from an existing component. Might not be the perfect abstraction
// yet and clearly doesn't support locales other then 'en-us'. Putting all the
// date formatting in this module will help us eventually determine the best
// abstraction so this is a good first step.
function secondsToFormattedDateShort (seconds, dayType = '2-digit') {
  const convDate = new Date(seconds * 1000)
  return convDate.toLocaleString('en-us', { month: 'short', day: dayType })
}

function getDateStringWithYear (dateStringISO) {
  if (!dateStringISO) return null

  const date = new Date(dateStringISO)
  if (date.toString() === 'Invalid Date') return null

  return date.toLocaleDateString(
    'en-US',
    { year: 'numeric', day: 'numeric', month: 'short' }
  )
}

function secondsToFormattedDay (seconds, weekDayType = 'short') {
  if (!seconds) return
  const convDate = new Date(seconds * 1000)
  return convDate.toLocaleString('en-us', { weekday: weekDayType })
}

// Given a time in seconds since epoch, it returns a short time, formatted
// according to the user's locale.
function secondsToFormattedTimeShort (seconds) {
  const convDate = new Date(seconds * 1000)
  // 'numeric' means a leading zero will be dropped. '2-digit' means the leading
  // zero will be preserved.
  const options = {
    timeStyle: 'short',
    hour: 'numeric',
    minute: '2-digit'
  }
  // undefined for the first parameter means use the user's locale settings.
  return convDate.toLocaleTimeString(undefined, options)
}

// Given a time in seconds since epoch, it returns a short date time, formatted
// according to the user's locale.
function secondsToFormattedDateTimeShort (seconds, monthStyle = 'long') {
  const convDate = new Date(seconds * 1000)
  // 'numeric' means a leading zero will be dropped. '2-digit' means the leading
  // zero will be preserved.
  const options = {
    month: monthStyle,
    day: 'numeric',
    hour: 'numeric',
    hour12: true,
    minute: '2-digit'
  }
  // undefined for the first parameter means use the user's locale settings.
  return convDate.toLocaleString(USER_LOCALE, options)
}

function getTimezoneShort (seconds = 1) {
  if (!seconds) seconds = 1
  if (!isNumber(seconds)) {
    seconds = convertDateToSecondsSinceEpoch(seconds)
  }
  const date = new Date(seconds * 1000)
  const dateString = date.toLocaleString(USER_LOCALE, {
    timeZoneName: 'long', hour12: true
  })
  const timeZoneStartIndex = dateString.search(/( AM | PM | am | pm)/)
  const timezoneLong = dateString.substring(timeZoneStartIndex + 4)
  const timezoneShort = timeZoneList.find(timeZone => timeZone.Name === timezoneLong)
  const abbreviation = timezoneShort?.Abbreviation
  const abbreviationsLength = timeZoneList
    .filter(timeZone => timeZone.Abbreviation === abbreviation).length
  const timeZone = abbreviationsLength > 1 ? timezoneLong : abbreviation
  return timeZone || timezoneLong.split(' ').map(word => word[0]).join('')
}

export function getDateStringWithShortMonth (value) {
  const date = new Date(value || ' ')
  if (date.toString() === 'Invalid Date') return
  return date
    .toLocaleString('en-US', { month: 'short', day: 'numeric' })
}

/**
 * @param {date} date in seconds
 * @param {number} dayOfTheWeek between 1 and 7
 * {Mon: 1, Tue: 2, Wed: 3, Thurs: 4, Fri: 5, Sat: 6, Sun: 7}
 * @returns {number} date of the next specified day if short is false
 * or old month is equal to new month
 * @returns {string} date of next specified day (e.g 'Jan 10') if short is true
 * or old month is not equal to new month
 */
function getNextWeekdayDate (date, dayOfTheWeek, short = false) {
  const newDate = new Date(date * 1000)
  const currentDateMonth = newDate.getMonth()
  newDate.setDate(
    newDate.getDate() + ((dayOfTheWeek - 1 - newDate.getDay() + 7) % 7) + 1
  )
  const newDateMonth = newDate.getMonth()
  const isAnotherMonth = currentDateMonth !== newDateMonth

  if (short || isAnotherMonth) {
    return newDate.toLocaleString('en-us', { month: 'short', day: 'numeric' })
  }

  return newDate.getDate()
}

/**
 * @param {date} date in seconds
 * @param {number} dayOfTheWeek between 1 and 7
 * {Mon: 1, Tue: 2, Wed: 3, Thurs: 4, Fri: 5, Sat: 6, Sun: 7}
 * @returns {number} date of the next specified day in seconds
 */
function getNextWeekdayDateInSecs (date, dayOfTheWeek) {
  const newDate = new Date(date * 1000)
  newDate.setDate(
    newDate.getDate() + ((dayOfTheWeek - 1 - newDate.getDay() + 7) % 7) + 1
  )

  return Math.round(newDate / 1000)
}

/**
 * @param {number} dateInSeconds
 * @param {number} days
 * @returns {number} date of the next specified day if
 * old month is equal to new month
 * @returns {string} date of next specified day (e.g 'Jan 10') if
 * old month is not equal to new month or short param is set to true
 */
function addDaysToDate (dateInSeconds, days, short = false) {
  const newDate = new Date(dateInSeconds * 1000)
  const currentDateMonth = newDate.getMonth()
  newDate.setDate(newDate.getDate() + days)

  const newDateMonth = newDate.getMonth()
  const isAnotherMonth = currentDateMonth !== newDateMonth

  if (isAnotherMonth || short) {
    return newDate.toLocaleString('en-us', { month: 'short', day: 'numeric' })
  }

  return newDate.getDate()
}

function addDaysToDateInSecs (dateInSeconds, days) {
  // one day is 86_400 seconds —> 24 hr * 60 mins * 60 secs
  const daysInSecs = days * 86_400

  return dateInSeconds + daysInSecs
}

function subtractDaysFromDateInSecs (dateInSeconds, days) {
  // one day is 86_400 seconds —> 24 hr * 60 mins * 60 secs
  const daysInSecs = days * 86_400

  return dateInSeconds - daysInSecs
}

function getDateWithoutYear (dateInSeconds) {
  const newDate = new Date(dateInSeconds * 1000)

  return newDate.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    hour12: true
  })
}

function weeksToSeconds (weeks) {
  // Days, hours, minutes, seconds
  return parseFloat(weeks) * 7 * 24 * 60 * 60
}

function daysToSeconds (days) {
  return parseFloat(days) * 24 * 60 * 60
}

// Changes the underlying date object to a new time zone while preserving the
// date and the time. For example, if a UTC date of 2019-Sep-24 1am is
// given and the IANATimeZone is 'America/Los_Angeles', it will return a date
// object that is 2019-Sep-24 1am for Los Angeles.
function changeTimezone (date, IANATimezone) {
  // Create a new date in the requested timezone.
  var invdate = new Date(date.toLocaleString('en-US', {
    timeZone: IANATimezone
  }))

  // Get the difference between the given date and the new timezone.
  var diff = date.getTime() - invdate.getTime()

  // Calculate a new date adjusted using the difference.
  return new Date(date.getTime() + diff)
}

// Returns the difference in days between two dates
function diffDays (startDate, endDate, isRounded = true) {
  const date1 = new Date(startDate * 1000)
  const date2 = new Date(endDate * 1000)
  let diff = (date1.getTime() - date2.getTime()) / 1000
  diff /= (60 * 60 * 24)
  return Math.abs(isRounded ? Math.round(diff) : diff)
}

// Returns seconds from HH:mmm:ss or mm:ss or ss format
function getSecondsFromTime (time) {
  if (!time) return
  const units = time.split(':')
  let [seconds, multiplier] = [0, 1]
  while (units.length > 0) {
    seconds += multiplier * parseInt(units.pop(), 10)
    multiplier *= 60
  }

  return seconds
}

// Returns HH:mm:ss or mm:ss from seconds
function getTimeFromSeconds (seconds, hoursMinsFormat = false) {
  if (!seconds) return
  // Hours, minutes and seconds
  const hrs = ~~(seconds / 3600)
  const mins = ~~((seconds % 3600) / 60)
  const secs = ~~seconds % 60

  // Output like '1:01' or '4:03:59'
  let time = ''
  if (hoursMinsFormat) {
    return `${(hrs < 10 ? '0' : '')}${hrs}:${(mins < 10 ? '0' : '')}${mins}`
  }
  if (hrs > 0) {
    time += `${hrs}:${(mins < 10 ? '0' : '')}`
  }
  time += `${mins}:${(secs < 10 ? '0' : '')}`
  time += secs

  return time
}

function getElevenFiftyNinePmInSecs (dateInSecs) {
  if (!dateInSecs) return

  const newDate = new Date(dateInSecs * 1000)
  return Math.round(newDate.setHours(23, 59, 59, 59) / 1000)
}

function getTwelveAmInSecs (dateInSecs) {
  if (!dateInSecs) return

  const newDate = new Date(dateInSecs * 1000)
  return Math.round(newDate.setHours(0, 0, 0, 0) / 1000)
}

function getTimeAgoInMinsHoursAndDays (timeInMilliseconds) {
  if (!timeInMilliseconds) return

  const intervals = [
    { label: 'd', seconds: 86_400 },
    { label: 'hr', seconds: 3_600 },
    { label: 'm', seconds: 60 }
  ]

  const seconds = Math.floor((Date.now() - timeInMilliseconds) / 1000)
  if (seconds === 0) return `${seconds}m ago`

  const interval = intervals.find(i => i.seconds <= seconds)
  const count = Math.floor(seconds / interval.seconds)
  const { label } = interval

  return `${count}${label}${count > 1 && label === 'hr' ? 's' : ''} ago`
}

function getDateMidNight (dateString) {
  const date = new Date(dateString)
  date.setHours(0, 0, 0, 0)

  return date
}

function getDifferenceOfDaysWithCurrentDate (date) {
  if (!date) return ''
  return diffDays(secondsSinceEpoch(), date)
}

function convertDateToSecondsSinceEpoch (date) {
  return date ? dateToSecondsSinceEpoch(new Date(date)) : undefined
}

function checkCohortDate (currentCohortDate, date) {
  // checks wheather current cohort date is greater or equals to date
  if (!currentCohortDate) return false

  const dateString = new Date(date)
  return currentCohortDate >= dateToSecondsSinceEpoch(dateString)
}

/**
 * @param {Number} hour - hour in 24 hour format
 * @returns {String} - time in 12 hour format
 * e.g 13 => 1:00 PM , e.g 12.5 => 12:30 PM ,
 * e.g 0 => 12:00 AM , e.g 24 => 12:00 AM
*/
export function formatHoursToTime (hour) {
  if (!hour || hour < 0) return ''

  const date = new Date()
  const wholeHour = Math.floor(hour)
  const minutes = Math.round((hour - wholeHour) * 60)
  date.setHours(wholeHour)
  date.setMinutes(minutes)
  date.setSeconds(0)

  return date.toLocaleTimeString(
    'en-US', { hour: 'numeric', minute: '2-digit', hour12: true }
  )
}

/**
 * @param {String} date in the form of a string.
 * @returns {number} - The hours extracted from the Date object.
 */
export function getHoursFromDate (initDate) {
  if (new Date(initDate).toString() === 'Invalid Date') return

  const date = new Date(initDate)
  const dateHours = date.getHours()
  const dateMinutes = date.getMinutes()
  const minutesFraction = Number((dateMinutes / 60).toFixed(2))

  return dateHours + minutesFraction
}

/**
 * @param {String | Date} startValue a valid date or a date string
 * @param {String | Date} endValue a valid date or a date string
 * @returns the start and end date string in US format
 * e.g same year > start: 2022/01/10, end: 2022/5/10 -> Jan 10 - May 10, 2022
 * e.g diff year > start: 2021/11/10, end: 2022/2/10 -> Nov 10, 2021 - Feb 10, 2022
 */
export function getStartEndDateUSFormat (startValue, endValue) {
  const startDate = new Date(startValue)
  const endDate = new Date(endValue)

  if (
    startDate.toString() === 'Invalid Date' ||
    endDate.toString() === 'Invalid Date'
  ) return

  const startYear = startDate.getFullYear()
  const endYear = endDate.getFullYear()
  const isSameYear = startYear === endYear

  const formattedStartDate = getDateStringWithShortMonth(startValue)
  const formattedEndDAte = getDateStringWithShortMonth(endValue)

  if (isSameYear) {
    return `${formattedStartDate} - ${formattedEndDAte}, ${startYear}`
  }

  return `${formattedStartDate}, ${startYear} - ${formattedEndDAte}, ${endYear}`
}
