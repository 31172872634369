import React, { Component } from 'react'
import './QuizletComponent.css'
class QuizletComponent extends Component {
  constructor () {
    super()
    this.clearLog = this.clearLog.bind(this)
  }

  componentDidMount () {
    const { data: { sectionUUID }, context } = this.props
    if (context?.updateContext && sectionUUID) {
      context.updateContext({
        currentQuestionSet: this.props.data,
        showFinish: true
      })
    }
  }

  clearLog () {
    console.clear()
  }

  render () {
    const { data: { url } } = this.props
    return (
      <div className='quizlet-wrapper'>
        <iframe
          title='Practice Term'
          className='quizlet-iframe'
          src={url}
          onLoad={this.clearLog}
          onChange={this.clearLog}
        />
      </div>
    )
  }
}

export default QuizletComponent
