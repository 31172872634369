import styled from 'styled-components'

export const ModalWrapper = styled.div`
  font-size: 16px;
  line-height: 26px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & button {
    width: 150px;
  }
`

export const ModalHeader = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 24px;
  border-bottom: 1px solid #3C3D40;
  display: flex;
  justify-content: space-between;
  gap: 12px;
`

export const ModalTitleSpan = styled.span`
  word-break: break-word;
`

export const ModalCrossIconSpan = styled.span`
  line-height: 24px;
  cursor: pointer;
`

export const ModalCrossIcon = styled.img`
  height: 14px;
  width: 14px;
`

export const ModalContent = styled.div`
  margin-bottom: 24px;
`
