import styled, { createGlobalStyle } from 'styled-components'
import Slider from 'react-slick'
import media, { BREAKPOINTS } from '../../../mediaQueries'

export const GlobalInstructorBioStyle = createGlobalStyle`
  body, html {
    overflow: hidden;
  }
`

export const InstructorItemWrapper = styled.div`
  width: 100%;
  height: 304px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 49.78%, #000000 100%),
    url(${props => props.image}) no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  position: relative;
`

export const InstructorsWrapper = styled.div`
  margin-top: 46.24px;
  margin-bottom: 48px;
`

export const InstructorDetailsWrapper = styled.div`
  position: absolute;
  bottom: 25.35px;
  left: 13.08px;
  right: 13.08px;
`

export const InstructorsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  ${media.desktop`
    grid-template-columns: auto auto auto;
  `}
  grid-gap: 10px;
`

export const InstructorsGridWrapper = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`

export const InstructorHeader = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 20.57px;
`

export const InstructorHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SliderCounter = styled.p`
  display: none;
  @media (max-width: ${BREAKPOINTS.tablet - 1}px) { 
    display: block;
    font-size: 16px;
    line-height: 19px;
    color: #b1bfc5;
    font-weight: normal;
  }
`

export const InstructorTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  color: #ffffff;
`

export const InstructorTopics = styled.div`
  margin-top: 6px;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #b1bfc5;
`

export const InstructorReadBioBtn = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5fc4b8;
  margin-top: 12px;
  cursor: pointer;

  &:disabled {
    color: #b8fff7;
    opacity: 0.3;
  }
  &:not(:disabled) {
    &:active {
      color: #b8fff7;
      opacity: 0.3;
    }
    &:hover {
      color: #b8fff7;
    }
  }
`

export const InstructorBioModalContainer = styled.div`
  .wrapper-modal {
    max-width: 500px;
    ${media.mobile`max-width: 351px;`}
    background: #1d1d1f;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    border-radius: 5px;

    .modal-header {
      border: none;
      background: #25272b;
      border-radius: 5px 5px 0px 0px;
    }

    .body-modal {
      height: 430px;
      max-height: 430px;
      overflow: scroll;
      padding: 24px;

      ::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    }
  }
`

export const StyledSlider = styled(Slider)`
  display: none;

  .slick-arrow {
    top: calc(50% - 17.39px);
    width: 11.22px;
    height: 17.39px;
    transform-style: preserve-3d;

    &:before {
      height: 200px;
      width: 36px;
      background-color: rgba(0, 0, 0, 0.75);
      border-radius: 3px;
      display: block;
      content: '';
      right: -12px;
      position: absolute;
      transform: translateZ(-1px);
    }
  }

  .slick-next:before {
    bottom: -91px;
  }

  .slick-prev:before {
    top: -91px;
  }

  @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
    width: calc(100vw - 24px);
    display: block;

    .slick-slide {
      padding: 0 4.875px;
      min-width: 262px;
      opacity: 0.5;
      pointer-events: none;
    }

    .slick-center {
      opacity: 1;
      pointer-events: auto;
    }

    .slick-next {
      right: 22.78px;
    }

    .slick-prev {
      left: 22.78px;
      z-index: 1;
    }
  }

  ${media.small`
    .slick-slide {
      min-width: 230px;
    }
  `}
`
