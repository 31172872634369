export const getOperatingSystem = () => {
  let operatingSystem = 'Not known'
  if (navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS' }
  if (navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS' }
  if (navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS' }
  if (navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS' }

  return operatingSystem
}

export const getDeviceType = () => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}
