import React, { useState } from 'react'
import {
  VideoChapterTable,
  VideoChapterToggle, VideoChapterToggleIcon, VideoChapterToggleText } from './styled'

export default function VideoChapters (props) {
  const { chapters } = props
  const [showChapters, setShowChapters] = useState(false)
  function toggleChapters () {
    setShowChapters(showChapters => !showChapters)
  }
  const showHideText = (showChapters ? 'Hide' : 'Show') + ' Video Chapters'
  return (
    <div>
      {showChapters &&
        <VideoChapterTable data-cy='videoChaptersContainer'>
          { chapters.map((item) =>
            <tr>
              <td>{item.time}</td>
              <td>{item.chapter}</td>
            </tr>
          )}
        </VideoChapterTable>
      }
      <VideoChapterToggle data-cy='videoChapterToggle' onClick={toggleChapters}>
        <VideoChapterToggleIcon
          showChapters={showChapters}
        />
        <VideoChapterToggleText>{showHideText}</VideoChapterToggleText>
      </VideoChapterToggle>
    </div>
  )
}
