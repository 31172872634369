import React, { useEffect } from 'react'
import api from '../../api'
import LoadingSpinner from '../LoadingSpinner/LoadingAnimation'
import { emitter } from '../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'
import { useStudentDataContext } from '../Context/Context'

const StudyGuide = () => {
  const { courseData } = useStudentDataContext()

  const navigateToHome = () => emitter.emit(ON_NAVIGATE_TO, '/')

  useEffect(() => {
    async function fetchData () {
      const { chapters } = courseData
      const [, query] = window.location.href.split('#')[1].split('?')
      const examId = new URLSearchParams(query).get('chapter_uuid')
      if (chapters) {
        const { examStudyGuide } =
          chapters.find(({ chapter_uuid: id }) => examId === id) || {}
        if (examStudyGuide) {
          const { file: { url } = {} } = examStudyGuide
          if (!url) return navigateToHome()
          api.incrementStudyGuideClicks(examId)
          window.open(url, '_self')
          return
        }
      }
      navigateToHome()
    }
    fetchData()
    // eslint-disable-next-line
  }, [courseData])

  return <LoadingSpinner />
}

StudyGuide.displayName = 'StudyGuide'
export default StudyGuide
