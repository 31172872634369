import styled from 'styled-components'

export const ShowSolutionWrapper = styled.div`
background-color: ${props => props.isExamOrQuizReviewMode &&
 `rgba(0,0,0,0)`}!important;
`

export const CorrectAnswerContainer = styled.div`
  display: flex;
  gap: 4px;
  cursor: pointer;
  align-items: center;
`
