import React, { Component } from 'react'
import './css/modal-component.css'
import { ModalHeader, Footer } from './styles'
import Button from '../CourseButton/CourseButton'

class ModalComponent extends Component {
  constructor (props) {
    super(props)
    this.handleEscapeClick = this.handleEscapeClick.bind(this)
    this.onSelectInstructor = this.onSelectInstructor.bind(this)
  }

  componentDidMount () {
    document.addEventListener('keydown', this.handleEscapeClick, false)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.handleEscapeClick, false)
  }

  handleEscapeClick (event) {
    const { handleClose } = this.props
    if (event.keyCode === 27 && handleClose) {
      handleClose()
    }
  }

  onSelectInstructor () {
    this.props.onChangeInstructor(this.props.radioButtonChange)
  }

  render () {
    const {
      show,
      handleClose,
      children,
      wrapperStyles = {},
      wrapperWidth,
      hideClose,
      headerBgColor,
      showHeaderBorder,
      title,
      footerShow,
      cypress,
      footerText,
      disableHideOnClick,
      headerShow
    } = this.props
    const width = wrapperWidth ? { maxWidth: wrapperWidth } : {}
    const wrapperStyle = { ...wrapperStyles, ...width }
    const isTitleBack = title === 'BACK'

    return (
      <div
        data-cy={cypress}
        // When the `show` class is not present, the div is not visible.
        className={`backdrop-modal ${show ? 'show' : ''}`}
        {...(!disableHideOnClick && { onClick: handleClose })}
      >
        <div className='wrapper-modal' style={wrapperStyle}>
          {!hideClose && <div className='btn-close-modal' onClick={handleClose}>
            <i className='fa fa-times' />
          </div>}
          <div>
            {headerShow &&
              <ModalHeader
                className='modal-header'
                headerBgColor={headerBgColor}
                showHeaderBorder={showHeaderBorder}
              >
                <span
                  className={isTitleBack ? 'back' : ''}
                  {...isTitleBack && { onClick: handleClose }}
                >
                  {isTitleBack && <img src='images/Caret.png' alt='icon' />}
                  {title}
                </span>
                <i className='fa fa-times' onClick={handleClose} />
              </ModalHeader>
            }
            <div className='body-modal'>{children}</div>
          </div>
          {footerShow &&
            <Footer>
              <p>{footerText}</p>
              <Button
                className={{
                  'btn-custom': true,
                  'btn-primary': true
                }}
                onClick={this.onSelectInstructor}
                dataCypress={'changeInstructorSelectBtn'}
              >
                SELECT
              </Button>
            </Footer>
          }
        </div>
      </div>
    )
  }
}

export default ModalComponent
