import styled, { createGlobalStyle } from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const OverrideCSSProperties = createGlobalStyle`
  .transcript-body {
    min-height: 277px!important;
    height: ${props => props.transcriptHeight}px!important;

    ${mediaqueries.mobile`
      height: 470px!important;
    `}
  }
`
export const Overlay = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.65);
`

export const OverlayText = styled.p`
    font-family: "Lato", sans-serif;
    font-weight: normal;
`

export const Description = styled(OverlayText)`
    font-size: 18pt;
    margin-bottom: 30px;
`

export const CloseButton = styled.span`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    mask: url(/images/icons/icon-plus.svg) no-repeat 50% 50%;
    width: 16px;
    height: 16px;
    background-color: #737880;
    transform: rotate(45deg);
`

export const TranscriptPluginWrapper = styled.div`
  .transcript-menu, .transcript-box {
    background-color: #161618;
  }

  .transcript-menu {
    padding-top: 11px;
  }

  .transcript-body {
    background-color: #0E0E0F;
    border-radius: 0px;
    color: var(--white);
    font-size: 18px;

    span.transcription-time-part.active {
      background-color: #55789D;
    }
  }

  .transcript-menu-item.toggleTranscriptBodyWrapper {
    a {
      color: #5fc4b8 !important;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      text-decoration: none !important;

      &:active {
        opacity: 0.3 !important;
      }
      &:hover {
        filter: brightness(1.5) !important;
      }
    }

    &:hover {
      color: unset;
      opacity: unset;
    }
  }

  .transcript-menu-item.searchWrapper {
    span {
      background-image: url(/images/icons/icon-search.svg);
      border-right: 0px;
      width: 16px;
      cursor: pointer;
    }

    input { 
      background-color: #0E0E0F;
      padding-left: 55px;
      color: var(--white);

      &:focus {
        outline: none !important;
      }

      &:before {
        width: 1px;
        content: '';
        border-right: 1px solid var(--white);
      }
    }
  }

  .transcript-menu-item.downloadWrapper {
    a {
      mask: url(/images/icons/icon-download-light.svg);
      background-color: var(--white);
      background-image: none;
    }
  }

  .transcript-menu-item.printWrapper {
    a {
      mask: url(/images/icons/icon-print.svg);
      background-color: var(--white);
      background-image: none;
    }
  }

  .transcription-time-part {
    span {
      white-space: pre-wrap;
    }
    span.highlight {
      background: #adad00 !important;
    }
  }
`
