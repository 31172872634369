import styled from 'styled-components'
import { Overlay, OverlayText } from './index'

export const NextOverlay = styled(Overlay)`
    justify-content: center;
    align-items: center;    
`

export const Title = styled(OverlayText)`
    font-size: 18px;
    line-height: 22px;
    color: var(--white);
    margin-bottom: 33px;
    text-align: center;
`

export const DetailText = styled(OverlayText)`
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1px;
    color: #C1CFD9;
    text-transform: uppercase;
    margin-bottom: 12px;
`

export const AutoPlayText = styled(OverlayText)`
    font-size: 16px;
    line-height: 19px;
    color: var(--white);
    margin-bottom: 12px;
`
