import React from 'react'
import ModalComponent from '../ModalComponent/ModalComponent'
import { ContentWrapper, Institution, Avatar, Image } from './styles'
import config from '../../config'

function InstructorBioModal (props) {
  const {
    handleClose,
    show,
    content,
    headerContent,
    footerShow,
    onChangeInstructor,
    showInstitution,
    currentInstructorID
  } = props

  const {
    image,
    name: instructorName,
    theme_name: themeName,
    bio,
    institution,
    instructor_uuid: instructorUuid
  } = content || {}

  const { isCalculusCourse } = config
  const isCalculus = isCalculusCourse()
  const footerPrefix = currentInstructorID === instructorUuid ? 'Keep' : 'Set'

  return (
    <ModalComponent
      show={show}
      handleClose={handleClose}
      hideClose
      title={headerContent}
      headerShow
      cypress='InstructorBioModal'
      footerShow={footerShow}
      footerText={`${footerPrefix} ${instructorName?.split(',')[0]} as your Lecturer?`}
      disableHideOnClick
      onChangeInstructor={onChangeInstructor}
      radioButtonChange={instructorUuid}
    >
      <ContentWrapper>
        {showInstitution
          ? <Institution>{institution}</Institution>
          : isCalculus &&
          <Avatar>
            <Image src={image} alt='icon' />
            <span>{instructorName}</span>
            <p>{themeName}</p>
          </Avatar>
        }
        <span dangerouslySetInnerHTML={{ __html: bio }} />
      </ContentWrapper>
    </ModalComponent>
  )
}

export default InstructorBioModal
