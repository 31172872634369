import {
  CARD, MULTIPLE_CHOICE, TRUE_FALSE, TEXT_BLANK, FREE_FORM
} from './questionType'

export const ACTIVE_LEARNING_CARD = 'alCard'
export const QUESTION = 'question'

export const DATO_MODEL_UUIDS = {
  [ACTIVE_LEARNING_CARD]: '586696',
  [QUESTION]: '222282'
}

export const QUESTIONS = [
  CARD,
  MULTIPLE_CHOICE,
  TRUE_FALSE,
  TEXT_BLANK,
  FREE_FORM
]

export const DATO_EDITING_URL = ({ modelId, recordId }) => `https://outlier-org.admin.datocms.com/editor/item_types/${modelId}/items/${recordId}/edit`
