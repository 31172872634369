import config from '../../../config'
import { validate } from 'datocms-structured-text-utils'
import { render } from 'datocms-structured-text-to-html-string'
import { FIRST_EDITION_SUBDOMAIN } from '../../../Constants'

const CALCULUS = 'calculus'
const CALCULUS_FP = 'y.calculus'
const CALCULUS_PLUS = 'calculus.plus'
const PSYCHOLOGY = 'psychology'
const PSYCHOLOGY_FP = 'y.psychology'
const PSYCHOLOGY_PLUS = 'psychology.plus'
const STATISTICS = 'statistics'
const STATISTICS_PLUS = 'statistics.plus'
const ASTRONOMY = 'astronomy'
const ASTRONOMY_PLUS = 'astronomy.plus'
const PHILOSOPHY = 'philosophy'
const PHILOSOPHY_PLUS = 'philosophy.plus'
const MICROECONOMICS = 'microeconomics'
const MICROECONOMICS_PLUS = 'microeconomics.plus'
const FINANCIAL_ACCOUNTING = 'financialaccounting'

const isCollegeSuccess = courseName =>
  config.isCollegeSuccessCourse(courseName)
const isCollegeSuccessAndFloridaPoly = courseName =>
  isFloridaPolyCourse(courseName) || config.isCollegeSuccessCourse(courseName)

const isPracticeExercises = courseName => {
  return [
    PSYCHOLOGY,
    PHILOSOPHY_PLUS,
    PHILOSOPHY,
    PHILOSOPHY_PLUS,
    MICROECONOMICS,
    MICROECONOMICS_PLUS
  ].includes(courseName)
}

const isFloridaPolyCourse = courseName => {
  return [CALCULUS_FP, PSYCHOLOGY_FP].includes(courseName)
}

const getAttendance = courseName => {
  if (isCollegeSuccess(courseName)) return

  const defaultAssessments = 'quizzes, midterm exams, and a final exam'
  const cumulativeFinalExam = 'quizzes, midterm exams, and a cumulative final exam'
  const assessments = new Map([
    [CALCULUS, defaultAssessments],
    [CALCULUS_PLUS, defaultAssessments],
    [CALCULUS_FP, defaultAssessments],
    [PSYCHOLOGY, defaultAssessments],
    [PSYCHOLOGY_FP, defaultAssessments],
    [PSYCHOLOGY_PLUS, defaultAssessments],
    [STATISTICS, cumulativeFinalExam],
    [STATISTICS_PLUS, cumulativeFinalExam],
    [ASTRONOMY, cumulativeFinalExam],
    [ASTRONOMY_PLUS, cumulativeFinalExam],
    [PHILOSOPHY, 'quizzes, a midterm, and a final essay'],
    [PHILOSOPHY_PLUS, 'quizzes, a midterm, and a final essay'],
    [MICROECONOMICS, defaultAssessments],
    [MICROECONOMICS_PLUS, defaultAssessments]
  ])

  return {
    title: 'Attendance & Attention to Course Materials',
    content: `As an online course, attendance is asynchronous and is tracked by students viewing the required lectures, engaging with interactive online materials, and completing ${
      isPracticeExercises(courseName)
        ? 'practice exercises' : 'problem sets'}. Each student will also have the option to spend as much time as needed learning and practicing with additional materials and practice questions; the level of their understanding will be demonstrated by their performance on a set of mastery assessments in the form of ${assessments.get(courseName)}.`
  }
}

const getAccessToPlatform = courseName => {
  if (isCollegeSuccessAndFloridaPoly(courseName)) return

  const domain = !config.isBetaCourse(courseName) || courseName.includes('.plus')
    ? courseName : `${courseName}.${FIRST_EDITION_SUBDOMAIN}`
  return {
    title: 'Access to Online Course Platform',
    content: `All course materials will be accessed through the course website ${domain}.outlier.org. Course progress, lectures, active learning, ${
      isPracticeExercises(courseName)
        ? 'practice exercises' : 'problem sets'}, and quizzes will be tracked and saved once you log in. Please reference your Course Homepage to view your grades and academic progress, and the Course Info page for details and instructions on the Outlier course features.`
  }
}

const getLateWork = courseName => {
  if (isCollegeSuccess(courseName)) return

  return {
    title: 'Assessment Completion & Late Work',
    content: getLateWorkTextByCourse(courseName)
  }
}

function getLateWorkTextByCourse (courseName) {
  const isCollegeWritingI = ['collegewriting-i', 'collegewriting-i.plus'].includes(courseName)
  const isPhilosophy = ['philosophy', 'philosophy.plus'].includes(courseName)

  if (isPhilosophy) {
    return 'Writing assignments and mastery assessments (the Midterm Exam, and Final Essay) must be completed by their due dates (as indicated on Outlier.org).  Failure to complete an assessment by the indicated due date will result in a grade of 0 for that assessment. For assessments offering multiple opportunities for completion (only quizzes in this course), only the highest grade of any completed instance of that assessment will count towards the student’s final grade.  Late work will not be accepted.'
  }

  if (isCollegeWritingI) {
    return 'Writing assignments must be completed by their due dates (as indicated on Outlier.org). Failure to complete an assignment by the indicated due date will result in a grade of 0 for that assignment. For assessments offering multiple opportunities for completion (only quizzes in this course), only the highest grade of any completed instance of that assessment will count towards the student’s final grade. Late work will not be accepted.'
  }

  return `Mastery assessments (${isFloridaPolyCourse(courseName) ? '' : 'quizzes, '}midterm exams, and the final exam) must be completed by their due dates (as indicated on Outlier.org).  Failure to complete an assessment by the indicated due date will result in a grade of 0 for that assessment. For assessments offering multiple opportunities for completion (only quizzes in this course), only the highest grade of any completed instance of that assessment will count towards the student’s final grade.  Late work will not be accepted.`
}

const getCalculators = courseName => {
  if ([
    PSYCHOLOGY,
    PSYCHOLOGY_FP,
    PSYCHOLOGY_PLUS,
    PHILOSOPHY,
    PHILOSOPHY_PLUS,
    MICROECONOMICS,
    MICROECONOMICS_PLUS
  ].includes(courseName) ||
    isCollegeSuccess(courseName)) return

  const calculatorsContent = new Map([
    [CALCULUS, 'Students are allowed to use calculators for all assignments. We suggest Desmos, available on both desktop and as an app for Android and iOS. Note that smartphones cannot be used during your Midterm Exams or Final Exam and that, if you choose to use Desmos during an exam, it must be in a separate tab in the same browser window that the student is using to complete the exam.'],
    [CALCULUS_PLUS, 'Students are allowed to use calculators for all assignments. We suggest Desmos, available on both desktop and as an app for Android and iOS. Note that smartphones cannot be used during your Midterm Exams or Final Exam and that, if you choose to use Desmos during an exam, it must be in a separate tab in the same browser window that the student is using to complete the exam.'],
    [STATISTICS, 'Students are allowed to use calculators for all assignments. We suggest the Desmos smartphone or web app. RStudio will be used for statistical analysis.'],
    [STATISTICS_PLUS, 'Students are allowed to use calculators for all assignments. We suggest the Desmos smartphone or web app. RStudio will be used for statistical analysis.'],
    [ASTRONOMY, 'Students are allowed to use calculators for all assignments.'],
    [ASTRONOMY_PLUS, 'Students are allowed to use calculators for all assignments.'],
    [FINANCIAL_ACCOUNTING, 'Students are allowed to use calculators for all assignments. We suggest Desmos, available on both desktop and as an app for Android and iOS. Note that smartphones cannot be used during your Midterm Exams or Final Exam and that, if you choose to use Desmos during an exam, it must be in a separate tab in the same browser window that the student is using to complete the exam.'],
    [CALCULUS_FP, 'Students are allowed to use calculators for all assignments.  We suggest the Desmos app on your smartphone (available for Android and iOS).']
  ])

  return {
    title: 'Calculators',
    content: calculatorsContent.get(courseName)
  }
}

const getExams = courseName => {
  if (isCollegeSuccessAndFloridaPoly(courseName)) return

  return {
    title: 'Exams',
    content: `In order to maintain high standards of academic integrity, we use online technology to proctor exams. You will be asked to confirm your identity with a photo ID and you will be required to grant access to your computer, including its webcam, screen, and microphone, for the duration of the exam. Each exam can be taken anytime during the exam open period but must be completed in a single contiguous sitting. Times and dates below are all based on Pacific Standard Time. Exams will be available from the open date at 9:00 am PT through the closing date at 9:00 am PT.`
  }
}

const getExamMaterials = courseName => {
  if (isCollegeSuccess(courseName)) return

  const defaultContent = 'Students may not use any kind of notes, websites, books, or any other type of aid on course exams.'
  const examMaterialsContent = new Map([
    [CALCULUS, 'Students are allowed to use a graphing calculator on exams, including Desmos. If using Desmos, it must be used in a separate tab in the same browser window that the student is using to complete the exam. Students may not use any other kind of notes, websites, books, or any other type of aid on course exams.'],
    [CALCULUS_PLUS, 'Students are allowed to use a graphing calculator on exams, including Desmos. If using Desmos, it must be used in a separate tab in the same browser window that the student is using to complete the exam. Students may not use any other kind of notes, websites, books, or any other type of aid on course exams.'],
    [CALCULUS_FP, 'Students are allowed to use a graphing calculator on exams, such the Desmos app.  They may not use any other kind of notes, websites, books, or any other type of aid on course exams.'],
    [STATISTICS, 'Students are allowed to use a graphing calculator, such the Desmos app, and the statistical analysis tool RStudio.cloud on exams. They may not use any other kind of notes, websites, books, or any other type of aid on course exams.'],
    [STATISTICS_PLUS, 'Students are allowed to use a graphing calculator, such the Desmos app, and the statistical analysis tool RStudio.cloud on exams. They may not use any other kind of notes, websites, books, or any other type of aid on course exams.'],
    [ASTRONOMY, 'Students are allowed to use a graphing calculator on exams. They may not use any other kind of notes, websites, books, or any other type of aid on course exams.'],
    [ASTRONOMY_PLUS, 'Students are allowed to use a graphing calculator on exams. They may not use any other kind of notes, websites, books, or any other type of aid on course exams.'],
    [FINANCIAL_ACCOUNTING, 'Students are allowed to use a scientific calculator on exams, including Desmos. If using Desmos, it must be used in a separate tab in the same browser window that the student is using to complete the exam. Students may not use any other kind of notes, websites, books, or any other type of aid on course exams.'],
    [PSYCHOLOGY, defaultContent],
    [PSYCHOLOGY_PLUS, defaultContent],
    [PSYCHOLOGY_FP, defaultContent],
    [PHILOSOPHY, defaultContent],
    [PHILOSOPHY_PLUS, defaultContent],
    [MICROECONOMICS, defaultContent],
    [MICROECONOMICS_PLUS, defaultContent]
  ])

  return {
    title: 'Exam Materials',
    content: examMaterialsContent.get(courseName)
  }
}

const getAcademicIntegrity = courseName => {
  if (isCollegeSuccessAndFloridaPoly(courseName)) return

  return {
    title: 'Academic Integrity',
    content: `Students in this course will be expected to comply with the University of Pittsburgh's Policy on Academic Integrity. Any student suspected of violating this obligation for any reason during the semester will be required to participate in the procedural process, initiated at the Outlier.org instructor level, as outlined in the University Guidelines on Academic Integrity.`
  }
}

const getCommunications = courseName => {
  if (isFloridaPolyCourse(courseName)) return

  return {
    title: 'Course Questions or Comments',
    content: `Most of your learning will happen within the Outlier course. Use the button in the bottom right corner to submit feedback or if you have a question(s) about any of the content in the lectures, active learning, or ${
      isPracticeExercises(courseName)
        ? 'practice exercises' : 'problem sets'}. You can also reach a Success Advisor by sending an email to success@outlier.org or by engaging on the community discussion. You can expect a response within 2 to 3 hours during Outlier.org business hours.`
  }
}

const getParticipation = courseName => {
  if (isCollegeSuccessAndFloridaPoly(courseName)) return

  return {
    title: 'Class Participation',
    content: `Join Outlier’s community discussion and participate in weekly conversations with the course facilitators and your peers to earn the discussion group portion of your grade. Participation is counted as sharing an interesting article you read that pertains to the chapters you’re working on, or asking a question of your fellow classmates to try to solve a difficult problem. You can also answer your peers’ questions by starting a thread under their post. Derogatory and sarcastic comments and jokes that marginalize anyone are fundamentally unacceptable, especially in the classroom. Offensive language—or language that could be construed as offensive—should be avoided.`
  }
}

const getAdministrativeDrops = courseName => {
  if (isCollegeSuccessAndFloridaPoly(courseName)) return

  return {
    title: 'Administrative Drops',
    content: 'Students who do not meaningfully participate by the time 20% of the term has passed will be administratively dropped from the course. For 15-week terms, the 20% mark is the third week of the course. Meaningful participation includes regularly logging into the course, completing quizzes, engaging in the community discussion, and responding to Student Success outreach.'
  }
}

const getWithdrawals = courseName => {
  if (isCollegeSuccessAndFloridaPoly(courseName)) return

  return {
    title: 'Withdrawals',
    content: 'If a student decides to exit from a course AFTER the drop deadline and BEFORE the withdrawal deadline, it is defined as a withdrawal. This is different from having dropped the course, as a grade of W will appear on your transcript. Withdrawals do not affect your GPA, but they do count towards the number of credits attempted. Students will still have access to review the lectures and active learning, but progress in the course will not be saved. Students who Withdraw are not eligible for a refund.'
  }
}

const getDisabilityAccommodation = courseName => {
  if (isCollegeSuccessAndFloridaPoly(courseName)) return

  return {
    title: 'Disability Accommodations',
    content: 'If you have a request for reasonable disability accommodations, contact success@outlier.org. Please include specifics regarding the accommodation you are seeking. Additionally, if you have a disability accommodations letter from a school you are currently attending, please submit it as well. Outlier is committed to ensuring that learners with accessibility needs have equal opportunity to succeed in our courses.'
  }
}

const getReligiousObservances = courseName => {
  if (isCollegeSuccessAndFloridaPoly(courseName)) return

  return {
    title: 'Religious observances',
    content: `Outlier will make a reasonable attempt to accommodate student needs in the case of serious incompatibility between a student's religious creed and a scheduled test or examination. Students should make such requests via success@outlier.org during the first two weeks of an academic term.`
  }
}

const getTransferCredit = courseName => {
  if (isCollegeSuccessAndFloridaPoly(courseName)) return

  return {
    title: 'Transfer Credit',
    content: 'University of Pittsburgh grades and credits earned in this course appear on an official University of Pittsburgh transcript, and the course credits are likely to be eligible for transfer to other colleges and universities. Students are encouraged to contact potential colleges and universities in advance to ensure their credits would be accepted. If students decide to attend any University of Pittsburgh campuses, the University of Pittsburgh grade earned in the course will count toward the student grade point average at the University. At the University of Pittsburgh, the grade from this course supersedes any equivalent AP credit.'
  }
}

export const getCoursePolicies = ({
  courseName, haveDynamicData, datoCoursePolicies
}) => {
  if (haveDynamicData) {
    return getCoursePoliciesFromDato(datoCoursePolicies)
  }

  return [
    getAttendance(courseName),
    getAccessToPlatform(courseName),
    getLateWork(courseName),
    getCalculators(courseName),
    getExams(courseName),
    getExamMaterials(courseName),
    getAcademicIntegrity(courseName),
    getCommunications(courseName),
    getParticipation(courseName),
    getAdministrativeDrops(courseName),
    getWithdrawals(courseName),
    getDisabilityAccommodation(courseName),
    getReligiousObservances(courseName),
    getTransferCredit(courseName)
  ]
}

/**
 * @param {Object} datoCoursePolicies containing the raw data coming from Dato
 * @returns {Array} coursePolicies containing the data to be used directly.
 */
export const getCoursePoliciesFromDato = (datoCoursePolicies = []) => {
  const rawCoursePolicies = datoCoursePolicies?.[0]?.coursePolicies
  if (!rawCoursePolicies?.length) return []

  const coursePolicies = rawCoursePolicies?.map((policy = {}) => {
    const policyText = policy?.policyText?.value
    const isValidStructure = validate(policyText).valid

    return {
      title: policy?.policyTitle?.trim(),
      content: isValidStructure && render(policyText)
    }
  })?.filter(policy => policy?.title && policy?.content)

  return coursePolicies
}
