import styled from 'styled-components'
import media from '../../../mediaQueries'
import { ContentTitle } from '../../styles/text'

export const AssignmentContainer = styled.div`
  margin-right: 24px;
`

export const AssignmentDescription = styled.p`
  font-size: 16px !important;
  line-height: 19px !important;
  margin-bottom: 12px !important;
  display: block !important;
  font-weight: 400;
  color: #D6E7E7;
  p {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    color: inherit;
  }
`

export const ChapterAssignmentWrapper = styled.div`
  background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(46, 46, 47, 0));
  padding: 1px;
  border-radius: 5px;
`

export const ChapterAssignment = styled.div`
  background: #161618;
  border-radius: 5px;
  padding: 24px 12px 32px;
  position: relative;
  display: grid;
  grid-template-columns: auto 132px;
  row-gap: 24px;
  overflow: visible;

  ${media.mobile`
    grid-template-columns: auto;
  `}
`
export const AssignmentTitle = styled(ContentTitle)`
  margin-bottom: 12px;
`

export const SupportingText = styled.p`
  font-size: 16px !important;
  font-weight: 400;
  color: ${(({ showDeadlineWarning, show24HrDeadlineWarning }) =>
    show24HrDeadlineWarning
      ? '#F2765A'
      : showDeadlineWarning
        ? '#FFB800'
        : '#D6E7E7')};
  line-height: 19px !important;
  margin-bottom: 0px;
  display: flex;
  margin-top: ${isFutureUnlockTime => isFutureUnlockTime ? '20px' : '0px'}
`

export const TealText = styled.span`
  color: #5fc4b8;
  cursor: pointer;
  margin-left: 4px;
  margin-right: 45px;
`
