import React, { PureComponent } from 'react'
import api from '../../api'
import config from '../../config'

class KalturaPlayer extends PureComponent {
  constructor (props) {
    super(props)
    this.playerId = `kaltura_player_${props.hashedId}`
    this.handlePlayerReady = this.handlePlayerReady.bind(this)
    this.handleMediaReady = this.handleMediaReady.bind(this)
    this.handlePlaybackRateChange = this.handlePlaybackRateChange.bind(this)
    this.handleTimeChange = this.handleTimeChange.bind(this)
    this.handlePlayEnd = this.handlePlayEnd.bind(this)
  }

  parseKs (xmlString) {
    const parser = new DOMParser()
    const data = parser.parseFromString(xmlString, 'text/xml')
    return data.getElementsByTagName('result')[0].childNodes[0].nodeValue
  }

  async componentDidMount () {
    const { PARTNER_ID, UICONF_ID } = config.kalturaSetting
    const { hashedId } = this.props
    // get playbackRate from localStorage and apply it to the player
    const playbackRate = localStorage.getItem('lectureVideoPlaybackRate')

    const kalturaSession = config.isPreviewCourse ? ''
      : this.parseKs(await api.generateKalturaSession())

    window.kWidget && window.kWidget.embed({
      targetId: this.playerId,
      wid: `_${PARTNER_ID}`,
      uiconf_id: UICONF_ID,
      flashvars: {
        playbackRateSelector: {
          defaultSpeed: playbackRate || 1
        },
        ks: kalturaSession
      },
      entry_id: hashedId,
      readyCallback: this.handlePlayerReady
    })
  }

  componentDidUpdate (prevProps) {
    const { isActive } = this.props
    if (!prevProps.isActive || isActive) return
    const kdp = document.getElementById(this.playerId)
    if (!kdp?.sendNotification) return
    kdp.sendNotification('doPause')
  }

  componentWillUnmount () {
    window.kWidget && window.kWidget.destroy(this.playerId)
  }

  handleMediaReady () {
    const { initialProgress } = this.props
    const kdp = document.getElementById(this.playerId)
    // Initial Video position when loaded
    initialProgress && kdp.sendNotification('doSeek', initialProgress)
  }

  handlePlaybackRateChange (rate) {
    const kdp = document.getElementById(this.playerId)
    if (kdp.currentPlaybackRate === rate) return

    localStorage.setItem('lectureVideoPlaybackRate', rate)
    kdp.currentPlaybackRate = rate

    // apply the playbackRate to other kaltura players
    const kalturaPlayers = document.getElementsByClassName('kaltura-player')
    Array.from(kalturaPlayers).forEach((kalturaPlayer) => {
      if (kalturaPlayer.currentPlaybackRate === rate) return
      kalturaPlayer.currentPlaybackRate = rate
      kalturaPlayer.sendNotification('playbackRateChangeSpeed', rate)
    })
  }

  handleTimeChange (sec) {
    const { onTimeChange } = this.props
    onTimeChange && onTimeChange(sec)
  }

  handleBufferStart = () => {
    const { onBuffer } = this.props
    onBuffer && onBuffer()
  }

  handlePlayEnd () {
    const { onPlayEnd } = this.props
    onPlayEnd && onPlayEnd()
    window.outlierLog('End', 'Video')
  }

  handlePlayerReady () {
    const { isLectureVideo } = this.props
    const kdp = document.getElementById(this.playerId)

    const playbackRate = localStorage.getItem('lectureVideoPlaybackRate')
    kdp.currentPlaybackRate = playbackRate

    kdp.kBind('mediaReady', this.handleMediaReady)
    kdp.kBind('playerPlayed', () => {
      window.outlierLog('Play', 'Video')
    })
    kdp.kBind('bufferStartEvent', this.handleBufferStart)
    kdp.kBind('playerPaused', () => {
      window.outlierLog('Pause', 'Video')
    })
    // when the user change playbackRate, store it to localStorage
    kdp.kBind('updatedPlaybackRate', this.handlePlaybackRateChange)

    if (!isLectureVideo) return
    // when the user clicks on process bar, it records the current time
    kdp.kBind('seeked', this.handleTimeChange)
    // while lecture video is playing, it records the current time
    kdp.kBind('playerUpdatePlayhead', this.handleTimeChange)
    // event when the player has reached the end of video
    kdp.kBind('playerPlayEnd', this.handlePlayEnd)
  }

  render () {
    return (
      <div
        id={this.playerId}
        className='embed-responsive-item kaltura-player'
      />
    )
  }
}

export default KalturaPlayer
