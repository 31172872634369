import React, { Component } from 'react'
import './css/show-feedback.css'
import Context from '../Context/Context'
import MathJax from '../MathJax/MathJax'
import { EXAM, GUESSWORK } from '../../Constants/sectionType'
import { INCORRECT } from '../../Constants/result'
import { FeedbackWrapper } from './styled'
class ShowFeedback extends Component {
  getTextAndTitle () {
    const {
      result,
      correctProps,
      incorrectProps
    } = this.props
    if (correctProps || incorrectProps) return this.props[result.toLowerCase() + 'Props']
    return { title: result }
  }

  filterMessage (textToFilter) {
    const { activeLearningUUID } = this.props
    const selectedThemes = this.context.studentData['selected-themes']
    const selFilterVal = selectedThemes
      ? selectedThemes[activeLearningUUID] : ''
    if (selFilterVal !== '') {
      const splitBy = '[/theme]'
      const findBy = '[theme: ' + selFilterVal + ']'
      const filteredArray = textToFilter.split(splitBy)
      let filteredText = ''
      filteredArray.forEach(function (entry) {
        if (entry.indexOf(findBy) !== -1) {
          filteredText = entry.split(findBy)[1]
        }
        if (!filteredText) filteredText = textToFilter
      })
      return filteredText
    } else {
      return textToFilter
    }
  }

  render () {
    const {
      result,
      sectionType,
      studentAnswer,
      showSolutionHandler,
      guessWorkCorrectAnswer,
      showStuck,
      isALRedesign,
      textWhenStuck,
      hideCorrectIndicator
    } = this.props

    if (sectionType === EXAM) return null

    const text = this.getTextAndTitle()?.text
    const canShowSolution = ((sectionType === 'Active Learning') && showStuck) ||
      (sectionType === 'Practice Exercises' && result === INCORRECT)
    const isHintText = (text || (showStuck && textWhenStuck))
    return (
      <FeedbackWrapper
        isALRedesign={isALRedesign}
        className={`lesson-list component__backdrop px-0 ${
          hideCorrectIndicator ? 'pt-0 pb-4 ml-0' : 'py-4'
        } revealed-answer-wrapper-feedback`}
      >

        {sectionType === GUESSWORK
          ? (
            <span>
              <h3
                data-cy='checkOrSubmitResponse'
                className='active__learning-block-title'>
                  Good guess!
              </h3>
              {result === INCORRECT ? <>
                <div className={'show-feedback-answer-title text-left'}>YOUR ANSWER</div>
                <span className='mx-auto img-fluid revealed-answer-box'>
                  <MathJax math={studentAnswer} />
                </span>
                <div className={'show-feedback-answer-title text-left'}>CORRECT ANSWER</div>
                <span className='mx-auto img-fluid revealed-answer-box'>
                  <MathJax data-testid='correctAnswer' math={guessWorkCorrectAnswer} />
                </span>
              </> : <>
                <span className='mx-auto img-fluid revealed-answer-box correct-answer'>You guessed correctly.</span>
              </>}
            </span>
          ) : !hideCorrectIndicator && (
            <>
              <div className={`d-flex align-items-center ${canShowSolution && !isHintText ? 'mb-0' : 'mb-4'} correct-heading`}>
                <span className={`radio__badge radio__badge-xs radio__badge-${result.toLowerCase()}`}
                  data-cy='studentAnswerCheckMark' />
                <span
                  data-cy='checkOrSubmitResponse'
                  className={'answer-status'}>
                  {this.getTextAndTitle()?.title}
                </span>
                {(canShowSolution) &&
                  <span data-testid='showSolutionButton' className={'show-solution'} onClick={showSolutionHandler}>
                    <span>{'SHOW SOLUTION'}</span></span>}
              </div>
            </>)
        }
        {hideCorrectIndicator && (
          <span className='explanation-heading'>Explanation</span>
        )}
        {isHintText &&
          <div style={{ fontSize: '18px', lineHeight: '21.6px' }}>
            <MathJax data-testid='feedBackText' math={this.filterMessage(text || textWhenStuck)} />
          </div>
        }
      </FeedbackWrapper>
    )
  }
}

ShowFeedback.contextType = Context
export default ShowFeedback
