import styled from 'styled-components'
import media from '../../../mediaQueries'
import { PageTitle } from '../../styles/text'

export const Wrapper = styled.div`
  margin-bottom: 46.24px;
`

export const BreadcrumbWrapper = styled.div`
  margin: 24px 0;
  cursor: pointer;
  width: fit-content;

  &:disabled {
    color: #5fc4b8;
    opacity: 0.3;
  }
  &:not(:disabled) {
    &:active {
      color: #b8fff7;
      opacity: 0.3;
    }
    &:hover {
      filter: brightness(1.5);
    }
  }

  ${media.mobile`
    margin-bottom: 18px;
  `}

  ${media.small`
    margin-bottom: 32px;
  `}
`

export const BackArrow = styled.span`
  background-color: #5FC4B8;
  width: 6px;
  height: 12px;
  mask: url(../images/icons/icon-angle-left-arrow.svg);
`

export const BreadcrumbText = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5FC4B8;
  margin-left: 12px;
`

export const ContentWrapper = styled.div`
  padding-bottom: 46px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  ${media.mobile`
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    padding-bottom: 48px;
  `}
`

export const Title = styled(PageTitle)`
  margin: 0;

  ${media.mobile`
    margin-bottom: 12px;
    margin-top: 0px;
  `}
`

export const Right = styled.div`
  ${media.mobile`
    align-items: flex-start !important;
  `}
`

export const HeaderText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #B1BFC5;
`

export const CourseName = styled(HeaderText)`
  margin-bottom: 4px;

  ${media.mobile`
    margin-bottom: 6px;
  `}
`
