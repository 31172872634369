import React, { useState, useRef } from 'react'
import {
  InstructorBioText,
  BackdropModal,
  WrapperModal,
  ModalHeader,
  CloseIcon,
  Title,
  ModalBody,
  InstructorAvatar,
  InstructorsWrapper,
  InstructorWrapper,
  InstructorInfo,
  InstructorName,
  InstructorTheme,
  InstructorBio,
  Back,
  InstructorHeader,
  Bio,
  MoreInstructors,
  MoreInstructorItem,
  MoreInstructorsText,
  DescriptionWrapper
} from './styled'
import useModal from './ModalHook'

function InstructorInfos (instructor = {}, setInstructor) {
  const { name, image, theme_name: theme } = instructor
  return (
    <InstructorWrapper key={name}>
      <InstructorAvatar alt={name} src={image} />
      <InstructorInfo>
        <InstructorName>{name}</InstructorName>
        <InstructorTheme>{theme}</InstructorTheme>
        <InstructorBio onClick={() => setInstructor(instructor)}>
          read bio
          <img
            src='images/icons/icon-back-symbol.svg'
            alt={`instructor-${name}`}
          />
        </InstructorBio>
      </InstructorInfo>
    </InstructorWrapper>
  )
}

function InstructorInfoModal ({ instructors }) {
  const { isModalVisible, showModal, hideModal } = useModal()
  const bioRef = useRef()
  const [{ instructor_uuid: selectedUUID, name, image, bio, theme_name: theme }, setInstructor] = useState({})

  const filterInstructors = () => {
    return instructors?.filter(
      ({ instructor_uuid: instructorUUID }) => selectedUUID !== instructorUUID
    )
  }
  return (
    <>
      <InstructorBioText data-testid='readInstructor' onClick={showModal}>
        Read lecturer bios
      </InstructorBioText>
      {instructors?.length && (
        <BackdropModal show={isModalVisible} data-testid='BackdropModal'>
          <WrapperModal>
            <ModalHeader ref={bioRef}>
              {selectedUUID ? (
                <Back onClick={() => setInstructor({})}>
                  <img src='images/icons/icon-back-symbol.svg' alt='back' />
                  back
                </Back>
              ) : (
                <Title>Meet your lecturers</Title>
              )}
              <CloseIcon
                src='images/icons/icon-close-dropdown-menu.svg'
                onClick={hideModal}
                alt={'close'}
              />
            </ModalHeader>
            <ModalBody>
              {selectedUUID ? (
                <DescriptionWrapper>
                  <InstructorHeader>
                    <img
                      src={image}
                      alt='selectedImage'
                    />
                    <InstructorName>{name}</InstructorName>
                    <InstructorTheme>
                      {theme}
                    </InstructorTheme>
                  </InstructorHeader>
                  <Bio
                    dangerouslySetInnerHTML={{
                      __html: bio
                    }}
                  />
                  <MoreInstructors>
                    <MoreInstructorsText>More Lecturers</MoreInstructorsText>
                    {filterInstructors().map(instructor => {
                      const {
                        name,
                        image,
                        instructor_uuid: instructorUUID
                      } = instructor
                      return (
                        <MoreInstructorItem>
                          <img src={image} alt={instructorUUID} />
                          <InstructorInfo>
                            <InstructorName>{name}</InstructorName>
                            <InstructorBio
                              onClick={() => {
                                setInstructor(instructor)
                                bioRef.current.scrollIntoView({
                                  behavior: 'smooth'
                                })
                              }}
                            >
                              read bio
                              <img
                                src='images/icons/icon-back-symbol.svg'
                                alt='instructor-selected'
                              />
                            </InstructorBio>
                          </InstructorInfo>
                        </MoreInstructorItem>
                      )
                    })}
                  </MoreInstructors>
                </DescriptionWrapper>
              ) : (
                <InstructorsWrapper>
                  {instructors?.map(instructor =>
                    InstructorInfos(instructor, setInstructor)
                  )}
                </InstructorsWrapper>
              )}
            </ModalBody>
          </WrapperModal>
        </BackdropModal>
      )}
    </>
  )
}

export default InstructorInfoModal
