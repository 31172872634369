import {
  getAutotestDetails, getCodegradeToken,
  getPracticeResult
} from '../../utilities/codegradeUtils'
import React, { useEffect, useState } from 'react'
import LoadingSpinner from '../LoadingSpinner/LoadingAnimation'
import {
  CodingAnswersScoreBreakdownWrapper,
  DisplayedScoreDetailsWrapper,
  GroupHeader, GroupWrapper,
  StepText, TotalScore,
  TotalScoreContainer, TotalScorePercent
} from './styles'
import { ToggleIcon } from '../SectionListExcerciseItem/styled'
import { Collapse } from 'reactstrap'
import {
  calculateScoreDetails
} from './codingAnswerUtil'

const CodingAnswerScoreBreakdown = ({ codegradeAssignmentId, studentAnswerMeta }) => {
  const [submissionResult, setSubmissionResult] = useState(null)
  const [autoTestDetails, setAutoTestDetails] = useState(null)
  const [isOpen, setIsOpen] = useState(true)
  const { autotestId = null, runId = null, submissionId = null } =
    studentAnswerMeta || {}

  useEffect(() => {
    const getSubmissionResult = async () => {
      if (!codegradeAssignmentId) return

      await getCodegradeToken()
      const autoTestDetails = await getAutotestDetails(
        { assignmentId: (codegradeAssignmentId) }
      )

      if (!autoTestDetails?.sets) return

      const submissionResult = await getPracticeResult({
        autotestId,
        runId,
        submissionId
      })

      setAutoTestDetails(autoTestDetails)
      setSubmissionResult(submissionResult)
    }
    getSubmissionResult()
  }, [codegradeAssignmentId, autotestId, runId, submissionId])

  const getDisplayedScoreDetails = (groupedStepsByRubricHeader) => (
    groupedStepsByRubricHeader.map((group, groupIndex) => (
      <div key={groupIndex}>
        <GroupHeader>
          <span>{group.header}</span>
          <span>{group.scoreInPercentage}</span>
        </GroupHeader>
        {group.results.map((step, stepIndex) => (
          <GroupWrapper key={stepIndex}>
            <StepText>
              <span>{step.auto_test_step.name}</span>
              <span>{step.achieved_points} / {step.auto_test_step.weight}</span>
            </StepText>
            {step?.auto_test_step?.data?.inputs?.map((input, inputIndex) => (
              <StepText key={inputIndex}>
                <span>{input.name}</span>
                <span>{input.achievedPoints} / {input.weight}</span>
              </StepText>
            ))}
          </GroupWrapper>
        ))}
      </div>
    ))
  )

  if (!submissionResult || !autoTestDetails) {
    return (
      <DisplayedScoreDetailsWrapper>
        <LoadingSpinner />
      </DisplayedScoreDetailsWrapper>
    )
  }

  if (submissionResult?.step_results && autoTestDetails?.sets) {
    const {
      groupedStepsByRubricHeader,
      totalAchievedPoints,
      totalWeight
    } = calculateScoreDetails(submissionResult, autoTestDetails)

    const totalPercentage = (totalWeight > 0
      ? Math.round((totalAchievedPoints / totalWeight) * 100)
      : 0) + '%'

    const updatedGroupedStepsByRubricHeader =
        groupedStepsByRubricHeader.map((group, groupIndex) => {
          const scoreInPercentage = (group.totalWeight > 0
            ? Math.round((group.totalAchievedPoints / group.totalWeight) * 100)
            : 0) + '%'

          return {
            ...group,
            scoreInPercentage
          }
        })

    return (
      <CodingAnswersScoreBreakdownWrapper>
        <TotalScoreContainer onClick={() => setIsOpen(!isOpen)}>
          <TotalScore>score</TotalScore>
          <TotalScorePercent>{totalPercentage}</TotalScorePercent>
          <ToggleIcon
            isAccordionOpen={isOpen}
          />
        </TotalScoreContainer>
        <Collapse isOpen={isOpen}>
          <DisplayedScoreDetailsWrapper>
            {getDisplayedScoreDetails(updatedGroupedStepsByRubricHeader)}
          </DisplayedScoreDetailsWrapper>
        </Collapse>
      </CodingAnswersScoreBreakdownWrapper>
    )
  }
  return null
}
CodingAnswerScoreBreakdown.displayName = 'CodingAnswerScoreBreakdown'
export default CodingAnswerScoreBreakdown
