import styled from 'styled-components'
import {
  Row,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col } from 'reactstrap'

export const PageTitle = styled(Col)`
    font-size: 24px;
    line-height: 29px;
    font-weight: 300;
    font-style: normal;
    height: 28px;
`
export const StyledAnswerText = styled(Col)`
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
`

export const StyledAnswerDownloadText = styled.span`
  color: #5FC4B8;
  border-bottom: 1px solid #5FC4B8;
  cursor: pointer;
  margin-left: 3px;
`

export const ReadingsSubText = styled.div`
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: #B1BFC5;
    margin-top: ${props => props.mt}px;
    ${props => props.dot && `
    -webkit-text-stroke-width: medium;
    line-height: 12px;
    `}
`
export const ReadingsSubTextDiv = styled.div`
display: flex;
margin-top: 11px;
`
export const ConceptMapHeading = styled(Row)`
    margin-bottom: 36px;
`
export const ReadingTitle = styled.span`
    font-size: ${props => props.header ? '18px' : '16px'};
    line-height: ${props => props.header ? '26px' : '19px'};
    font-weight: 700;
`
export const Author = styled.span`
    font-size: ${props => props.header ? '18px' : '16px'};
    line-height: ${props => props.header ? '22px' : '19px'};
    font-weight: ${props => props.header ? '300' : '400'};
`
export const ReadingContainer = styled(Row)`
    background-color: #000000;
    padding: 25px 36px 36px 36px;
    margin-bottom: 40px;
    @media (max-width: 767px) {
        padding: 14px 24px 24px 24px;
    }
`

export const ReadingDetails = styled(Row)`
    margin-bottom: 27px;
    @media (max-width: 767px) {
        margin-bottom: 5px;
    }
    & .col-12:first-child {
        @media (max-width: 767px) {
            margin-bottom: -7px;
        }
    }
    & .col-12:nth-child(2) {
        @media (max-width: 767px) {
            margin-bottom: 8px;
        }
    }
`

export const DownloadToggle = styled(DropdownToggle)`
    border: 0 none;
    background-color: transparent;
    color: #5FC4B8;
    padding-right: 0;
    padding-left: 0;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1px;
    font-style: normal;
    font-weight: bold;
    &:hover {
        background-color: transparent;
    }
    & img {
        margin-right: 12px;
        margin-top: -4px;
    }
`

export const DownloadDropdownMenu = styled(DropdownMenu)`
    width: auto;
    &:before {
        display: none;
    }
`

export const DownloadDropdownItem = styled(DropdownItem)`
    padding-top: 8.5px;
    padding-bottom: 8.5px;
    padding-left: 16px;
    font-size: 18px;
    line-height: 22px;
    font-style: normal;
    font-weight: normal;
`

export const DownloadIcon = styled.img`
  margin-right: 12px;
  margin-left: ${props => props.pdf ? '-2px' : 0};
  margin-top: -2px;
  width: ${props => {
    const { pdf, mobi, epub } = props

    if (pdf || mobi) {
      return 21
    } else if (epub) {
      return 19
    } else {
      return 18
    }
  }}px;
`

export const PdfContainer = styled.div`
    height: 645px;
    @media (max-width: 767px) {
        height: 595px;
    }
`
export const ReadingButtonWrapper = styled.div`
    .btn-secondary {
        color: #5FC4B8;
        border: #5FC4B8 1px solid;
    }
    .btn-primary {
        background-color: #5FC4B8;
        border: #5FC4B8 3px solid;
    }
`
