import api from '../api'

const getTimeUntilReady = () => {
  try {
    if (!window.performance) return

    const navigationEntries = performance.getEntriesByType('navigation')

    if (navigationEntries && navigationEntries.length) {
      const fetchStart = navigationEntries[0].fetchStart || 0
      return Math.round(performance.now() - fetchStart)
    }

    // Deprecated feature used to help site load in safari
    // https://developer.mozilla.org/en-US/docs/Web/API/PerformanceTiming
    const fetchStart = performance.timing.fetchStart || 0
    return Math.round(Date.now() - fetchStart)
  } catch (error) {
    console.error('Error in getTimeUntilReady: ', error.message)
    return null
  }
}

const logMetric = metric => {
  if (process.env.NODE_ENV === 'development') {
    return console.info('timeUntilReady metric: ', metric)
  }

  api.sendMetrics(metric)
}

export {
  getTimeUntilReady,
  logMetric
}
