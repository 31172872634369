import React, { Component } from 'react'
import MathJax from '../MathJax/MathJax'
import QuestionComponent from '../QuestionComponent/QuestionComponent'
import CourseButton from '../CourseButton/CourseButton'
import ShowSolution from '../ShowSolution/ShowSolution'
import { emitter } from '../Emitter/Emitter'
import Context from '../Context/Context'
import QuestionPreviousButton from
  '../QuestionComponent/QuestionPreviousButton'
import QuestionNextButton from '../QuestionComponent/QuestionNextButton'
import ReviewNextButton from '../QuestionComponent/ReviewNextButton'
import { TextWrapper, Icon, QuestionText, PinWrapper,
  PinText,
  PinButton,
  PaginationWrapper,
  QuestionCard,
  QuestionIconWrapper } from './styles'
import {
  NEW_GUESSWORK_ICON,
  NEW_EMPTY_ICON,
  PIN_ICON,
  PINNED_ICON_WHITE
} from '../../Constants/progressBarIconSource'
import { EXAM_STATUSES, PIN_TEXT, PINNED_TEXT } from '../../Constants/index'
import { replaceBlanksWithInputElem } from './TextBlanksQuestionShared'
import {
  GUESSWORK,
  QUIZ,
  EXAM,
  RETAKE,
  ACTIVITY_TYPES
} from '../../Constants/sectionType'
import {
  ON_SECTION_DATA_PROGRESS,
  ON_STUDENT_ANSWER,
  ON_SUBMIT_ANSWER
} from '../../Constants/emitterKeys'
import { getExplanation } from '../../utilities/courseUtils'
import { findAnswer as findAnswerUtil, getQuestionTitle } from '../../utilities/questions'
import ShowFeedback from '../ShowFeedBack/ShowFeedBack'
import { INCORRECT, CORRECT } from '../../Constants/result'
import { trackStudentEvent } from '../../utilities/chapterUtils'
import isEqual from 'lodash/isEqual'
import {
  saveAnswersToLocalStorage,
  isExamRetakeType
} from '../../utilities/examUtils'
import ProctorioExamResources from '../ProctorioExamResources/ProctorioExamResources'
import JDoodleCompiler from '../JDoodleCompiler/JDoodleCompiler'
import { EXAM_STATUS } from '../../Constants/studentContext'

class TextBlanksQuestionGuesswork extends Component {
  constructor (props) {
    super(props)
    this.getTriesPerQuestion = this.getTriesPerQuestion.bind(this)
    this.fillTheBlanks = this.fillTheBlanks.bind(this)
    this.callListenerFunc = this.callListenerFunc.bind(this)
    this.onShowSolution = this.onShowSolution.bind(this)
    this.showResult = this.showResult.bind(this)
    this.CheckSolution = this.CheckSolution.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)

    this.state = {
      question: '',
      try_attempt: 0,
      answers: null,
      isNextDisabled: true,
      displayNext: 'none',
      displayCheck: 'flex',
      isQuiz: this.isQuiz(),
      showSolution: false,
      isPinned: false,
      solution: {},
      tries_per_question: this.getTriesPerQuestion(),
      tries_result: [],
      studentAnswerClassName: 'radio__badge-incorrect',
      studentAnswerRes: {}
    }
  }

  isQuiz () {
    const { type_of_question: typeOfQuestion } = this.props
    return typeOfQuestion === QUIZ
  }

  fillTheBlanks (answers, isCorrect) {
    const blank = document.getElementsByName('blank[]')
    const { review_mode: reviewMode, type_of_question: typeOfQuestion } = this.props
    const isPendingResponseAnswer = !reviewMode &&
    [QUIZ, EXAM].includes(typeOfQuestion)
    if (!answers || !blank.length) return
    const colorBlank = isPendingResponseAnswer ? '#FFF'
      : isCorrect ? '#78BCB8' : '#E1774F'
    const borderColor = isPendingResponseAnswer ? '#78BCB8'
      : isCorrect ? '#FFF' : '#E1774F'
    blank.forEach((element, key) => {
      const isEventAdded = element.getAttribute('focusin') ||
      element.getAttribute('focusout')
      if (!isEventAdded) {
        element.addEventListener('focusin', (event) => {
          const userReply = answers[key]
          if (userReply) return

          element.style.borderBottom = '1px solid #fff'
        })
        element.addEventListener('focusout', (event) => {
          const userReply = answers[key]
          if (userReply) return

          element.style.borderBottom = '1px solid #78BCB8'
        })
      }
      element.value = answers[key]
      element.style.color = colorBlank
      element.style.borderBottom = `1px solid ${borderColor}`
      reviewMode && (element.disabled = 'disabled')
    })
  }

  callListenerFunc () {
    const {
      type_of_question: typeOfQuestion,
      review_mode: reviewMode,
      question: {
        Question_uuid: questionUUID
      }
    } = this.props
    const { studentData: { studentAnswers } } = this.context
    const { isQuiz } = this.state

    let findAnswer = findAnswerUtil(questionUUID, studentAnswers)
    if (findAnswer) findAnswer = [findAnswer.answer, findAnswer.correct]

    const isExam = typeOfQuestion === EXAM
    const isActiveExam = isExam && !reviewMode

    if (findAnswer) {
      const result = findAnswer[1] ? CORRECT : INCORRECT
      this.fillTheBlanks(findAnswer[0], findAnswer[1])
      this.setState({
        result,
        isNextDisabled: false,
        displayNext: 'block',
        displayCheck: isActiveExam ? 'flex' : 'none',
        tries_per_question: this.getTriesPerQuestion(),
        tries_result: findAnswer[2] || [],
        studentAnswerClassName: findAnswer[1] === true
          ? 'radio__badge-correct' : 'radio__badge-incorrect'
      })
    } else if (reviewMode) {
      const blank = document.getElementsByName('blank[]')
      blank.forEach((element) => {
        element.style.color = '#E1774F'
        element.style.borderBottom = `1px solid #E1774F`
        element.disabled = 'disabled'
      })
    }

    setTimeout(() => {
      document.getElementsByName('blank[]').forEach((e, index) => {
        e.addEventListener('focus', () => {
          clearTimeout(this.timeOutFuncID)
        })
        e.addEventListener('change', () => {
          /* ------- Clear TIme out on Reveal Answer Click ----- */
          clearTimeout(this.timeOutFuncID)
          /* ------- Clear TIme out on Reveal Answer Click ----- */
          if (isQuiz && !reviewMode) this.showResult()
        })
        e.addEventListener('keyup', () => {
          if (isActiveExam) return
          this.setState({
            isNextDisabled: false
          })
        })
      })
    }, 1000)
  }

  componentDidMount () {
    this.callListenerFunc()
    const {
      type_of_question: questionType,
      activeSectionUUID,
      activeChildrenIndex,
      completeScreenData,
      question: { Question_uuid: questionUUID },
      isFirstQuestion
    } = this.props
    const {
      reviewMode,
      studentData: { studentAnswers, pinnedQuestions },
      cohortData: { name: cohort, duration }
    } = this.context
    if (!pinnedQuestions.length) {
      studentAnswers
        .forEach(studentAnswer => studentAnswer?.questionActivity?.pinned &&
          pinnedQuestions.push(studentAnswer.uuid))
    }
    this.setState({ isPinned: pinnedQuestions.includes(questionUUID) })
    const isQuestionTypeExam = questionType === EXAM
    const isTrackEvent = isQuestionTypeExam && isFirstQuestion && !reviewMode
    if (!isTrackEvent) return

    trackStudentEvent({
      cohort,
      duration,
      questionType,
      activeChildrenIndex,
      completeScreenData
    })
    emitter.emit(
      ON_SECTION_DATA_PROGRESS,
      {
        key: EXAM_STATUS,
        sectionUUID: activeSectionUUID,
        value: EXAM_STATUSES.IN_PROGRESS
      }
    )
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      question: {
        Question_uuid: questionUUID
      },
      currentQuestionUUID,
      review_mode: reviewMode
    } = this.props
    const {
      question: {
        Question_uuid: prevQuestionUUID
      },
      review_mode: prevReviewMode
    } = prevProps

    if (reviewMode !== prevReviewMode) {
      this.callListenerFunc()
      setTimeout(() => {
        this.onShowSolution()
        this.setState({
          show_solution: true
        })
      }, 1000)
    }

    if (prevQuestionUUID === questionUUID) return
    const { studentData: { studentAnswers } } = this.context

    let fAns = findAnswerUtil(questionUUID, studentAnswers)
    if (fAns) fAns = [fAns.answer, fAns.correct]

    setTimeout(() => {
      fAns && this.fillTheBlanks(fAns[0], fAns[1])
      if (!reviewMode) return
      this.onShowSolution()
      this.setState({
        show_solution: true
      })
    }, 1000)

    if (!fAns) {
      this.callListenerFunc()
      this.fillTheBlanks('', false)
    }
    const result = fAns && (fAns[1] ? CORRECT : INCORRECT)
    const state = {
      isNextDisabled: !fAns,
      result: fAns ? result : '',
      try_attempt: 0,
      displayNext: fAns ? 'block' : 'none',
      displayCheck: fAns ? 'none' : 'flex',
      tries_per_question: this.getTriesPerQuestion(),
      tries_result: fAns ? fAns[2] : []
    }
    fAns && (state.studentAnswerClassName = fAns[1]
      ? 'radio__badge-correct' : 'radio__badge-incorrect')
    this.setState(state)
    if (prevQuestionUUID !== questionUUID) {
      const { studentData: { pinnedQuestions } } = this.context
      this.setState(
        { isPinned: pinnedQuestions.includes(currentQuestionUUID) }
      )
    }
  }

  onShowSolution (questionId) {
    const {
      question: {
        answer,
        quesition_view_lesson: questionViewLesson,
        Question_uuid: questionUUID
      },
      review_mode: reviewMode
    } = this.props

    if (!Array.isArray(answer)) return

    const correctAnswer = answer.join(',')
    const {
      correctIncorrectClass,
      studentAnswerClassName
    } = this.state

    let studentAnswer = ''
    document.getElementsByName('blank[]').forEach(element => {
      studentAnswer += `${element.value.trim()},`
    })
    studentAnswer = studentAnswer.substring(0, studentAnswer.length - 1)

    if (correctIncorrectClass === 'ans-correct') {
      this.setState({ studentAnswerClassName: 'radio__badge-correct' })
    }
    this.setState({
      show_solution: true,
      show_attempt: false,
      solution: {
        questionUUID,
        correctAnswer,
        studentAnswer,
        studentAnswerClass: studentAnswerClassName,
        view_lesson: reviewMode && questionViewLesson !== null ? questionViewLesson : null
      }
    })
  }

  showResult () {
    /* ------- Clear TIme out on Reveal Answer Click ----- */
    clearTimeout(this.timeOutFuncID)
    /* ------- Clear TIme out on Reveal Answer Click ----- */
    this.CheckSolution()

    const {
      question: {
        answer: correctAnswer
      },
      setGuessworkCorrectAnswer
    } = this.props

    setGuessworkCorrectAnswer(correctAnswer.join(','))
  }

  CheckSolution () {
    const blankElementList = document.getElementsByName('blank[]')
    const { answers: prevAnswers } = this.state
    const answers = []
    const {
      studentData: { studentAnswers },
      cohortData,
      examRetake
    } = this.context
    const { localStorageKey, currentQuestionSet } = this.props

    blankElementList.forEach(element => {
      answers.push(element.value.trim().replace(/\s+/g, ' '))
    })
    const isAnswersEqual = isEqual(answers, prevAnswers)

    if (isAnswersEqual) return

    this.setState({ answers })

    const {
      completeScreenData,
      question: {
        answer: correctAnswer,
        Question_uuid: questionUUID,
        fitbCaseSensitive
      },
      review_mode: reviewMode,
      type_of_question: typeOfQuestion,
      updateOnCheck
    } = this.props
    let finalResult = true

    const isPendingResponseAnswer = !reviewMode &&
      [QUIZ, EXAM].includes(typeOfQuestion)

    const results = correctAnswer.map((answer, index) => {
      const isCorrectAnswer = answer && answers[index] && (
        fitbCaseSensitive
          ? answer === answers[index]
          : answer.toLowerCase() === answers[index].toLowerCase()
      )
      if (isCorrectAnswer) {
        return CORRECT
      } else {
        blankElementList.forEach((element, key) => {
          if (key !== index || (isPendingResponseAnswer)) return
          element.style.color = '#E1774F'
          element.style.borderBottom = '1px solid #E1774F'
        })

        finalResult = false
        return INCORRECT
      }
    })
    const hasIncorrectResult = results.indexOf(INCORRECT) !== -1

    const {
      tries_result: triesResult,
      try_attempt: tryAttempt,
      isQuiz,
      tries_per_question: triesPerQuestion
    } = this.state
    const isExam = typeOfQuestion === EXAM
    const isRetakeType = isExamRetakeType({
      examRetake,
      cohortId: cohortData?.cohortID,
      chapter: completeScreenData?.chapter
    }) && isExam
    const testRes = {
      uuid: questionUUID,
      answer: answers,
      correct: finalResult,
      type: ACTIVITY_TYPES[isRetakeType ? RETAKE : EXAM],
      tries: triesResult
    }
    const answerString = Array.isArray(answers)
      ? answers.join(', ') : answers
    const finalTryAttempt = tryAttempt + 1
    const tryEl = document.getElementById('tries-' + (finalTryAttempt - 1))
    if (hasIncorrectResult) {
      triesResult.push(false)
      /* --- Added for Displaying Stuck Dialouge --- */
      if (finalTryAttempt === triesPerQuestion) {
        if (!reviewMode) {
          emitter.emit(ON_STUDENT_ANSWER, testRes)
          if (isQuiz || isExam) {
            saveAnswersToLocalStorage(localStorageKey, currentQuestionSet, studentAnswers)
            this.setState({ isNextDisabled: false, displayNext: 'block' })
            return
          }

          updateOnCheck(testRes)
          this.setState({ studentAnswerRes: testRes })
        }

        window.outlierLog('IncorrectAnswer', answerString)
        // change context data
        this.setState({
          result: INCORRECT,
          try_attempt: finalTryAttempt,
          isNextDisabled: false,
          correctIncorrectClass: 'incorrect ans-incorrect',
          displayNext: 'block',
          displayCheck: 'none'
        })
      } else {
        /* --- Added for Displaying Stuck Dialouge End--- */
        this.setState({
          result: INCORRECT,
          try_attempt: finalTryAttempt,
          isNextDisabled: true,
          correctIncorrectClass: 'incorrect ans-incorrect'
        })
        window.outlierLog('IncorrectAnswer', answerString)
        /* ---------- Added for Clearing answer ----------- */
        this.timeOutFuncID = setTimeout(() => {
          blankElementList.forEach(e => {
            e.value = ''
            e.style.color = ''
            e.style.borderBottom = ''
          })
          this.setState({
            result: '',
            isNextDisabled: true,
            correctIncorrectClass: ''
          })
        }, 1000)
        /* ---------- Added for Clearing answer End ----------- */
      }
      /** Change the color of tries remaining */
      if (tryEl) tryEl.className = 'radio__badge radio__badge-incorrect'
    } else {
      triesResult.push(true)
      if (!reviewMode) {
        emitter.emit(ON_STUDENT_ANSWER, testRes)
        if (isQuiz || isExam) {
          saveAnswersToLocalStorage(localStorageKey, currentQuestionSet, studentAnswers)
          this.setState({ isNextDisabled: false, displayNext: 'block' })
          return
        }

        updateOnCheck(testRes)
        this.setState({ studentAnswerRes: testRes })
      }
      window.outlierLog('CorrectAnswer', answerString)
      /** Change the color of tries remaining */
      if (tryEl) tryEl.className = 'radio__badge radio__badge-correct'
      this.setState({
        result: CORRECT,
        try_attempt: 0,
        isNextDisabled: false,
        correctIncorrectClass: 'ans-correct',
        displayNext: 'block',
        displayCheck: 'none'
      })
    }
  }

  // Should return false if the action was not allowed.
  // Component uses text entry, so we must ignore arrow keys which are used to
  // move around within that input field.
  handleKeyPress (type) {
    const {
      isNextDisabled,
      displayNext,
      displayCheck,
      studentAnswerRes
    } = this.state

    // Typically from pressing the left arrow, allow it and ignore it.
    if (type && type === 'previous') return true

    // Typically from pressing the right arrow, allow it and ignore it.
    if (type && type === 'advance') return true

    // Everything else is interpreted as next or advance forward
    if (this.props.review_mode) {
      this.props.onReviewClickNext()
      return true
    } else {
      if (!isNextDisabled) {
        if (displayNext === 'block') {
          this.props.onNextButtonClick(studentAnswerRes)
          return true
        } else if (displayCheck === 'flex') {
          this.showResult()
          return true
        }
      }
    }

    // Unrecognized, default is don't allow it.
    return false
  }

  getTriesPerQuestion () {
    const {
      question: {
        tries_per_question: triesPerQuestion
      },
      type_of_question: typeOfQuestion
    } = this.props
    const TRIES_GUESSWORK = 1
    const TRIES_OTHER = 3

    return triesPerQuestion
      ? parseInt(triesPerQuestion)
      : (typeOfQuestion === GUESSWORK) ? TRIES_GUESSWORK : TRIES_OTHER
  }

  getCorrectAnswer () {
    const { question: { answer } } = this.props
    return answer.join(', ')
  }

  onPinClick (currentQuestionUUID) {
    const {
      completeScreenData,
      review_mode: reviewMode,
      type_of_question: typeOfQuestion
    } = this.props

    const {
      studentData,
      updateContext,
      cohortData,
      examRetake
    } = this.context
    const { pinnedQuestions, studentAnswers } = studentData
    const { isPinned, isExam } = this.state
    const currentIndex = pinnedQuestions.indexOf(currentQuestionUUID)
    const isRetakeType = isExamRetakeType({
      examRetake,
      cohortId: cohortData?.cohortID,
      chapter: completeScreenData?.chapter
    }) && isExam
    const fAnswer = findAnswerUtil(currentQuestionUUID, studentAnswers)
    // condition to check answer exists in review mode before pinning
    const isAttemptedQuestion = typeof (fAnswer?.correct) === 'boolean'
    const answer = {
      uuid: currentQuestionUUID,
      type: isRetakeType
        ? ACTIVITY_TYPES[RETAKE]
        : typeOfQuestion.toLocaleLowerCase(),
      ...(reviewMode && isAttemptedQuestion && {
        answer: fAnswer.answer,
        correct: fAnswer.correct,
        tries: fAnswer.tries
      }),
      questionActivity: { pinned: false }
    }
    if (isPinned) {
      pinnedQuestions.splice(currentIndex, 1)
      emitter.emit(ON_SUBMIT_ANSWER, answer)
    } else {
      answer.questionActivity.pinned = true
      pinnedQuestions.push(currentQuestionUUID)
      emitter.emit(ON_SUBMIT_ANSWER, answer)
    }
    studentData.pinnedQuestions = pinnedQuestions
    updateContext({ studentData })
    this.setState({ isPinned: !isPinned })
  }

  render () {
    const {
      studentAnswerRes,
      result,
      show_solution: showSolution,
      displayNext,
      isQuiz,
      isNextDisabled,
      displayCheck,
      solution,
      isPinned,
      answers: studentAnswers
    } = this.state
    const { isStudentProgressBusy, isAssessmentQuizExam } = this.context
    const {
      question,
      type_of_question: typeOfQuestion,
      question_text: questionText,
      lesson_text: lessonText,
      structuredText,
      review_mode: reviewMode,
      filterMessage,
      correctProps,
      showfinish,
      isFirstQuestion,
      currentQuestionUUID,
      currentQuestionSet,
      incorrectProps
    } = this.props
    const {
      general_explanation: generalExplanation,
      specific_explanation: specificExplanation,
      answer: answers
    } = question
    const isExam = typeOfQuestion === EXAM
    const isExamOrQuiz = isExam || isQuiz
    const replacedTextBlankQ =
      replaceBlanksWithInputElem(questionText, answers)
    // var { question } = this.props

    const {
      Question_uuid: questionUUID,
      jdoodleEditor,
      starterCode,
      examResources,
      dato_card_type: datoCardType,
      resourceIcons
    } = question

    const questionIndex = currentQuestionSet?.findIndex(
      (q) => q.Question_uuid === questionUUID
    )
    const { questionUUID: solutionQuestionUUID } = solution
    const isTextBlankEmpty = isAssessmentQuizExam && !isFirstQuestion
    const isQuestionSubmitted = displayCheck === 'flex' || isStudentProgressBusy

    const answerString = Array.isArray(studentAnswers) ? studentAnswers.join(', ') : studentAnswers
    return (
      <>
        {isAssessmentQuizExam && <TextWrapper>
          <QuestionIconWrapper>
            <span>
              <Icon
                src={result ? NEW_GUESSWORK_ICON : NEW_EMPTY_ICON}
                alt='Feedback'
              />
            </span>
            <QuestionText>
              {getQuestionTitle(question, questionIndex, typeOfQuestion)}
            </QuestionText>
          </QuestionIconWrapper>
          <PinWrapper onClick={() => this.onPinClick(currentQuestionUUID)}>
            <PinText>{isPinned ? PINNED_TEXT : PIN_TEXT}</PinText>
            <PinButton
              src={isPinned ? PINNED_ICON_WHITE : PIN_ICON}
              data-testid='pinButton'
            />
          </PinWrapper>
        </TextWrapper>}
        <QuestionCard
          className='active__learning-block component__backdrop mb-2'
          isAssessmentQuizExam={isAssessmentQuizExam}
        >
          {structuredText}
          {
            lessonText && <>
              <div className='pb-3 font__md'>{lessonText}</div>
              <hr className='active__learning-hr' />
            </>
          }
          <div className='row align-items-start pt-3'>
            {this.props.display_illustration}
            {/* --- Added Desmos Here --- */}
            {this.props.desmosgraph}
            {
              replacedTextBlankQ &&
                <div className='col que-text'>
                  <div className='pb-3 font__md'>
                    <MathJax
                      isExam={isExam}
                      className={isExam ? 'prevent-select' : ''}
                      math={replacedTextBlankQ}
                      onMathJaxDidRender={this.callListenerFunc}
                      datoCardType={datoCardType}
                    />
                  </div>
                </div>
            }
          </div>
          {this.props.gMath}
          {jdoodleEditor &&
            <div className='mb-2'>
              <JDoodleCompiler
                key={questionUUID}
                questionUUID={questionUUID}
                starterCode={starterCode}
                isPartOfQuestion
              />
            </div>}
          {isExamOrQuiz && <ProctorioExamResources
            reviewMode={reviewMode}
            examResources={examResources}
            resourceIcons={resourceIcons}
          />}
          {showSolution && questionUUID === solutionQuestionUUID &&
          <ShowSolution solutionData={solution}
            filterMessage={filterMessage} correctProps={correctProps}
            incorrectProps={incorrectProps} isExamReviewMode={reviewMode && isExam}
            explanation={getExplanation(specificExplanation, generalExplanation)}
            isExamOrQuizReviewMode={reviewMode && (isExam || isQuiz)} /> }
          {!isQuiz && !showSolution && result &&
          <ShowFeedback
            guessWorkCorrectAnswer={this.getCorrectAnswer()}
            sectionType={typeOfQuestion}
            studentAnswer={answerString}
            result={result}
            correctProps={correctProps}
            incorrectProps={incorrectProps} />}
        </QuestionCard>

        <div className='active__learning-pagination text-center'>
          <hr />
          <PaginationWrapper
            isAssessmentQuizExam={isAssessmentQuizExam}
            isPreviousButton={isTextBlankEmpty}
            className={`justify-content-end ${
              isQuestionSubmitted && isTextBlankEmpty
                ? 'questions-btn-wrapper'
                : isQuestionSubmitted
                  ? 'questions-btn-wrapper--reverse'
                  : 'questions-btn-wrapper'
            }`}
          >
            {(!reviewMode && !isQuiz)
              ? (isStudentProgressBusy
                ? <CourseButton
                  className={{
                    'btn-primary': true,
                    'mx-1': true,
                    'px-5': true,
                    check_answer: true
                  }}
                  disabled
                >
                  <i className='fa fa-spinner fa-spin fa-margin' /> <span>Saving</span>
                </CourseButton>
                : <CourseButton
                  style={{ display: displayCheck }}
                  className={{
                    'btn-primary': true,
                    'mx-1': true,
                    'px-5': true,
                    'd-flex': displayCheck === 'flex',
                    'd-none': displayCheck === 'none',
                    'questions-btn': displayCheck === 'flex',
                    check_answer: true
                  }}
                  data-testid='checkOrSubmit'
                  onClick={this.showResult}
                >
                  Submit
                </CourseButton>
              )
              : null
            }
            {isTextBlankEmpty &&
            <QuestionPreviousButton {...this.props}
              isAssessmentQuizExam={isAssessmentQuizExam} />}
            {(displayNext === 'block' || isQuiz || isExam) &&
            !reviewMode && !isStudentProgressBusy &&
            !showfinish && isAssessmentQuizExam &&
            <QuestionNextButton {...this.props}
              isNextDisabled={isNextDisabled}
              isAssessmentQuizExam={isAssessmentQuizExam}
              studentAnswerRes={studentAnswerRes}
            />}
            {(displayNext === 'block' || isQuiz || !showfinish) &&
            !reviewMode && !isStudentProgressBusy &&
            !isAssessmentQuizExam &&
            <QuestionNextButton {...this.props}
              isNextDisabled={isNextDisabled}
              studentAnswerRes={studentAnswerRes}
            />}
            {reviewMode && <ReviewNextButton {...this.props} />}
          </PaginationWrapper>
          {!isAssessmentQuizExam && <hr />}
        </div>

      </>
    )
  }
}

TextBlanksQuestionGuesswork.contextType = Context
export default QuestionComponent(TextBlanksQuestionGuesswork)
