import React, { useState, useEffect } from 'react'
import MathJax from '../MathJax/MathJax'
import {
  NEW_CORRECT_UNATTEMPTED_QUESTION_ICON,
  NEW_COMPLETED_ICON,
  NEW_INCORRECT_ICON,
  EYE_OPEN_ICON,
  EYE_CLOSED_ICON,
  NEW_INCORRECT_QUESTION_ICON
} from '../../Constants/progressBarIconSource'
import {
  Option,
  OptionWrapper,
  CorrectAnswerWrapper,
  IncorrectAnswerWrapper,
  UnselectedCorrectAnswerWrapper,
  UnselectedIncorrectAnswerWrapper,
  CircleComponent
} from './styles'

export default function OptionsComponent ({
  labelText,
  type,
  index,
  onOptionSelect,
  checked,
  hasStudentAnswered,
  selectedOptions = {},
  areAllAnswersCorrect,
  onRetrySelect,
  hasStudentGivenUp,
  answer,
  activeQuestionIndex
}) {
  const [isHidden, setIsHidden] = useState(false)

  useEffect(() => {
    setIsHidden(false)
  }, [activeQuestionIndex])

  function getOptionComponents (type) {
    switch (type) {
      case 'correct-answer':
        return OptionComponent(
          CorrectAnswerWrapper,
          {
            optionTitle: 'correct answer',
            name: 'correct-option',
            imgSrc: NEW_COMPLETED_ICON
          })
      case 'incorrect-answer':
        return OptionComponent(
          IncorrectAnswerWrapper,
          {
            optionTitle: 'your answer',
            name: 'incorrect-option',
            imgSrc: NEW_INCORRECT_ICON
          }
        )
      case 'unselected-correct-answer':
        return OptionComponent(
          UnselectedCorrectAnswerWrapper,
          {
            optionTitle: 'correct answer',
            name: 'correct-option',
            imgSrc: NEW_CORRECT_UNATTEMPTED_QUESTION_ICON
          }
        )
      case 'unselected-incorrect-answer':
        return OptionComponent(
          UnselectedIncorrectAnswerWrapper,
          {
            name: 'incorrect-option',
            imgSrc: NEW_INCORRECT_QUESTION_ICON
          }
        )
      default:
        return OptionComponent(
          OptionWrapper,
          {
            name: 'option',
            setSelectedOption: hasStudentAnswered ? onRetrySelect : onOptionSelect,
            imgSrc: isHidden ? EYE_OPEN_ICON : EYE_CLOSED_ICON
          })
    }
  }

  function OptionComponent (wrapper, compProperties) {
    const { optionTitle, name, setSelectedOption, imgSrc } = compProperties

    function onClick (e) {
      if (!setSelectedOption || isHidden) return
      setSelectedOption(type, {
        index,
        value: e.target.checked
      })
    }

    const shouldHide = isHidden &&
      !hasStudentGivenUp &&
      !(hasStudentAnswered && areAllAnswersCorrect)

    const Wrapper = shouldHide ? UnselectedIncorrectAnswerWrapper : wrapper

    return (
      <Option>
        <Wrapper
          htmlFor={`option-${index}`}
          checked={checked !== undefined ? checked : false}
          isHidden={isHidden}
        >
          {optionTitle && !shouldHide && <p>{optionTitle}</p>}
          <MathJax math={labelText} />
          {!shouldHide &&
            <input
              id={`option-${index}`}
              type={type}
              name={name}
              checked={checked !== undefined ? checked : false}
              onChange={(e) => onClick(e)}
            />
          }
        </Wrapper>
        <CircleComponent
          data-testid='circle-component'
          src={imgSrc}
          onClick={() => {
            toggleOption(name, setSelectedOption)
          }}
        />
      </Option>
    )
  }

  function switchComponents () {
    const isOptionAnswer = answer?.indexOf(index.toString()) !== -1

    if (hasStudentGivenUp) {
      if (isOptionAnswer) {
        return getOptionComponents(
          selectedOptions[index]
            ? 'correct-answer'
            : 'unselected-correct-answer'
        )
      }

      return getOptionComponents(
        selectedOptions[index]
          ? 'incorrect-answer'
          : 'unselected-incorrect-answer'
      )
    }

    if (hasStudentAnswered) {
      if (areAllAnswersCorrect) {
        return getOptionComponents(
          selectedOptions[index]
            ? 'correct-answer'
            : 'unselected-incorrect-answer'
        )
      }

      if (type === 'checkbox') {
        return getOptionComponents('option')
      }

      return getOptionComponents(
        selectedOptions[index]
          ? 'incorrect-answer'
          : 'option'
      )
    }

    return getOptionComponents('option')
  }

  function toggleOption (name, setSelectedOption) {
    if (name !== 'option') return
    setIsHidden(!isHidden)
    setSelectedOption(type, {
      index,
      value: false
    })
  }

  return switchComponents()
}
