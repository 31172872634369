import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Context from '../Context/Context'
import { ON_SECTION_DATA_PROGRESS, ON_STUDENT_ANSWER, ON_SUBMIT_ANSWER } from '../../Constants/emitterKeys'
import {
  isExamRetakeType,
  saveAnswersToLocalStorage
} from '../../utilities/examUtils'
import { emitter } from '../Emitter/Emitter'
import QuestionComponent from '../QuestionComponent/QuestionComponent'
import {
  PaginationWrapper,
  TextWrapper,
  Icon,
  QuestionText,
  QuestionCard,
  PinWrapper,
  PinText,
  PinButtonWrapper,
  PinButton,
  LessonText,
  QuestionIconWrapper
} from './styles'
import {
  NEW_COMPLETED_ICON,
  NEW_EMPTY_ICON,
  NEW_GUESSWORK_ICON,
  NEW_INCORRECT_ICON,
  PINNED_ICON_WHITE,
  PIN_ICON
} from '../../Constants/progressBarIconSource'
import { EXAM_STATUSES, PINNED_TEXT, PIN_TEXT } from '../../Constants'
import { findAnswer, getQuestionTitle } from '../../utilities/questions'
import { ACTIVITY_TYPES, EXAM, RETAKE } from '../../Constants/sectionType'
import ProctorioExamResources from '../ProctorioExamResources/ProctorioExamResources'
import QuestionPreviousButton from '../QuestionComponent/QuestionPreviousButton'
import QuestionNextButton from '../QuestionComponent/QuestionNextButton'
import ReviewNextButton from '../QuestionComponent/ReviewNextButton'
import { getCodegradeToken } from '../../utilities/codegradeUtils'
import ShowSolution from '../ShowSolution/ShowSolution'
import { getExplanation } from '../../utilities/courseUtils'
import JdoodleQuizExam from './JdoodleQuizExam'
import { EXAM_STATUS } from '../../Constants/studentContext'

const CodingQuestionQuizExam = props => {
  const {
    activeSectionUUID,
    structuredText,
    display_illustration: displayIllustration,
    lesson_text: lessonText,
    question_text: questionText,
    gMath,
    desmosgraph,
    showfinish,
    review_mode: reviewMode,
    isFirstQuestion,
    showPrevious,
    question,
    type_of_question: sectionType,
    localStorageKey,
    correctProps,
    incorrectProps,
    filterMessage,
    currentQuestionSet
  } = props
  const context = useContext(Context)
  const [isNextDisabled, setIsNextDisabled] = useState(true)
  const [correctCode, setCorrectCode] = useState('')

  const {
    Question_uuid: questionUUID,
    general_explanation: generalExplanation,
    specific_explanation: specificExplanation,
    examResources,
    resourceIcons,
    starterCode,
    correctCodeFile,
    question_view_lesson: questionViewLessson,
    codegradeAssignmentId
  } = question || {}
  // eslint-disable-next-line
  const isExam = useMemo(() => sectionType === EXAM, [questionUUID])
  const isLastQuestionOfOngoingExam = useMemo(() => {
    return isExam && !reviewMode && showfinish
    // eslint-disable-next-line
  }, [questionUUID])
  const [currentStudentAnswer, setCurrentStudentAnswer] = useState(starterCode)
  const { studentAnswers } = context?.studentData || {}
  const studentAnswer = useMemo(() => {
    return studentAnswers.find(answer => answer?.uuid === questionUUID) || {}
  }, [questionUUID, studentAnswers])
  const {
    questionActivity,
    correct: isCodeCorrect
  } = studentAnswer || {}
  const [isPinned, setIsPinned] = useState(!!questionActivity?.pinned)

  useEffect(() => {
    if (!isExam || !isFirstQuestion || reviewMode) return

    emitter.emit(
      ON_SECTION_DATA_PROGRESS,
      {
        key: EXAM_STATUS,
        sectionUUID: activeSectionUUID,
        value: EXAM_STATUSES.IN_PROGRESS
      }
    )
  }, [isFirstQuestion, reviewMode, isExam, activeSectionUUID])

  useEffect(() => {
    if (!reviewMode || !correctCodeFile?.file?.url) return setCorrectCode('')

    const getCorrectCode = async () => {
      const correctCodeContent = await fetch(correctCodeFile.file.url)
      const content = await correctCodeContent.text()
      setCorrectCode(content)
    }

    getCorrectCode()
  }, [reviewMode, correctCodeFile])

  const saveEditorContent = useCallback(message => {
    emitter.emit(ON_STUDENT_ANSWER, {
      ...(studentAnswer ? { ...studentAnswer } : {}),
      uuid: questionUUID,
      answer: message,
      type: sectionType.toLowerCase()
    })
    saveAnswersToLocalStorage(
      localStorageKey,
      currentQuestionSet,
      studentAnswers
    )
    setIsNextDisabled(false)
    // eslint-disable-next-line
  }, [questionUUID])

  useEffect(() => {
    const messageHandler = event => {
      if (event.origin === 'https://outlier.jdoodle.com' && event.data.script) {
        const message = event.data.script
        saveEditorContent(message)
      }
    }

    window.addEventListener('message', messageHandler)

    return () => {
      window.removeEventListener('message', messageHandler)
    }

    // eslint-disable-next-line
  }, [questionUUID])

  const fetchCodegradeToken = async () => {
    await getCodegradeToken()
  }

  useEffect(() => {
    setIsNextDisabled(true)
    setIsPinned(!!studentAnswer?.questionActivity?.pinned)
    setCurrentStudentAnswer(studentAnswer?.answer
      ? {
        value: {
          schema: 'dast',
          document: {
            type: 'root',
            children: [
              {
                code: studentAnswer?.answer,
                type: 'code',
                language: 'java'
              }
            ]
          }
        }
      } : starterCode)

    fetchCodegradeToken()

    // eslint-disable-next-line
  }, [questionUUID])

  const onPinClick = () => {
    const {
      studentData,
      updateContext,
      cohortData,
      examRetake
    } = context
    const { pinnedQuestions, studentAnswers } = studentData
    const currentIndex = pinnedQuestions.indexOf(questionUUID)
    const isRetakeType = isExamRetakeType({
      examRetake,
      cohortId: cohortData?.cohortID,
      chapter: props.completeScreenData?.chapter
    }) && isExam
    const fAnswer = findAnswer(questionUUID, studentAnswers)
    // condition to check answer exists in review mode before pinning
    const isAttemptedQuestion = typeof (fAnswer?.correct) === 'boolean'
    const answer = {
      ...(fAnswer ? { ...fAnswer } : {}),
      uuid: questionUUID,
      type: isRetakeType
        ? ACTIVITY_TYPES[RETAKE]
        : sectionType.toLocaleLowerCase(),
      ...(reviewMode && isAttemptedQuestion && {
        answer: fAnswer.answer,
        correct: fAnswer.correct,
        tries: fAnswer.tries
      }),
      questionActivity: { pinned: false }
    }
    if (isPinned) {
      pinnedQuestions.splice(currentIndex, 1)
      answer.questionActivity.pinned = false
    } else {
      answer.questionActivity.pinned = true
      pinnedQuestions.push(questionUUID)
    }
    emitter.emit(ON_STUDENT_ANSWER, answer)
    emitter.emit(ON_SUBMIT_ANSWER, answer)
    studentData.pinnedQuestions = pinnedQuestions
    updateContext({ studentData })
    setIsPinned(!isPinned)
  }

  const questionIndex = useMemo(() => {
    return currentQuestionSet?.findIndex(
      (q) => q.Question_uuid === questionUUID
    )
  }, [currentQuestionSet, questionUUID])

  return (
    <>
      <TextWrapper>
        <QuestionIconWrapper>
          <span>
            <Icon
              src={studentAnswer
                ? (reviewMode
                  ? (isCodeCorrect ? NEW_COMPLETED_ICON : NEW_INCORRECT_ICON)
                  : NEW_GUESSWORK_ICON)
                : reviewMode ? NEW_INCORRECT_ICON : NEW_EMPTY_ICON}
              alt='Feedback'
            />
          </span>
          <QuestionText>
            {getQuestionTitle(question, questionIndex, sectionType)}
          </QuestionText>
        </QuestionIconWrapper>
        <PinWrapper onClick={onPinClick}>
          <PinText>{isPinned ? PINNED_TEXT : PIN_TEXT}</PinText>
          <PinButtonWrapper>
            <PinButton
              src={isPinned ? PINNED_ICON_WHITE : PIN_ICON}
            />
          </PinButtonWrapper>
        </PinWrapper>
      </TextWrapper>
      <QuestionCard className='active__learning-block component__backdrop mb-2'>
        {structuredText}
        {
          lessonText
            ? <React.Fragment>
              <div className='pb-3 font__md'>
                <LessonText>
                  {lessonText}
                </LessonText>
              </div>
              <hr className='active__learning-hr' />
            </React.Fragment>
            : null
        }

        <div className='row align-items-center pt-4'>
          {displayIllustration}
          {desmosgraph}
          {
            questionText
              ? <div className='col que-text'>
                <div className='font__md'>
                  <LessonText>
                    {questionText}
                  </LessonText>
                </div>
              </div>
              : null
          }
        </div>
        {gMath}
        <JdoodleQuizExam
          key={questionUUID}
          questionUUID={questionUUID}
          starterCode={currentStudentAnswer}
        />
        <ProctorioExamResources
          reviewMode={reviewMode}
          examResources={examResources}
          resourceIcons={resourceIcons}
        />
        {reviewMode &&
          <ShowSolution
            solutionData={{
              questionUUID,
              correctAnswer: correctCode,
              studentAnswer: studentAnswer?.answer,
              studentAnswerClass: isCodeCorrect ? 'radio__badge-correct' : 'radio__badge-incorrect',
              view_lesson: questionViewLessson
            }}
            compilationScore={studentAnswer?.meta?.compilationScore}
            filterMessage={filterMessage}
            correctProps={correctProps}
            incorrectProps={incorrectProps}
            explanation={getExplanation(specificExplanation, generalExplanation)}
            isAssessmentQuizExam
            isCodingQuestion
            isExamOrQuizReviewMode={reviewMode}
            codegradeAssignmentId={codegradeAssignmentId}
            studentAnswerMeta={studentAnswer?.meta}
          />}
      </QuestionCard>
      <PaginationWrapper
        isLastQuestionOfOngoingExam={isLastQuestionOfOngoingExam}
        isFirstQuestion={isFirstQuestion}
        showfinish={showfinish}
        showPrevious={showPrevious}
        reviewMode={reviewMode}
      >
        {!isFirstQuestion &&
        <QuestionPreviousButton {...props} isAssessmentQuizExam />}
        {!reviewMode && !showfinish &&
        <QuestionNextButton
          {...props}
          studentAnswerRes={studentAnswer}
          isAssessmentQuizExam
          isNextDisabled={isNextDisabled}
        />}
        {reviewMode && <ReviewNextButton {...props} />}
      </PaginationWrapper>
    </>
  )
}

CodingQuestionQuizExam.displayName = 'CodingQuestionQuizExam'

export default QuestionComponent(CodingQuestionQuizExam)
