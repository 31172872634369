import { ON_NAVIGATE_TO } from '../../../Constants/emitterKeys'
import { SET_UP_YOUR_EXAM } from '../../../Constants/examType'
import { SECTION, ESSAY, EXAM, COURSERA_ACTIVITY, BREAK } from '../../../Constants/scheduleType'
import { INTENSIVE_COHORT_DURATION } from '../../../Constants/cohortDuration'
import { GGU_ORIENTATION_LINK } from '../../../Constants'
import {
  addDaysToDate,
  addDaysToDateInSecs,
  getNextWeekdayDate,
  getNextWeekdayDateInSecs,
  dateToSecondsSinceEpoch,
  secondsToFormattedDateShort,
  getTwelveAmInSecs,
  getElevenFiftyNinePmInSecs,
  secondsSinceEpoch
} from '../../../utilities/dateTimeUtils'
import { emitter } from '../../Emitter/Emitter'
import flatten from 'lodash/flatten'
import mergeWith from 'lodash/mergeWith'
import inRange from 'lodash/inRange'
import camelCase from 'lodash/camelCase'
import { ICalendar } from 'datebook'
import { capitalizeFirstLetter } from '../../../utilities'
import config from '../../../config'
import { validate } from 'datocms-structured-text-utils'
import { render } from 'datocms-structured-text-to-html-string'
import { render as renderNodes } from 'datocms-structured-text-to-dom-nodes'
import isEmpty from 'lodash/isEmpty'
import api from '../../../api'
import { getExamDatesFromCohortExamDates } from '../../../utilities/chapterUtils'
import { GRADING_CRITERIA } from './GRADING_CRITERIA'
import { GRADING_SCALE } from '../../../Constants/gradingScale'
import { isNewGoogleDataAnalyticsICohort } from '../../../utilities/courseUtils'

export const getFinalDropOrWithdrawalDate = date => {
  if (!date) return ''

  const midNightDate = new Date(date + 'T00:00:00')

  return dateToSecondsSinceEpoch(midNightDate)
}

export const todayAtTwelveAmInSecs = new Date().setHours(0, 0, 0, 0) / 1000

export const customMerge = (string1, string2) => {
  if (typeof string1 !== 'string' || typeof string2 !== 'string') return
  if (string1 === string2 || (string1.includes('|') && string2.includes('|'))) {
    return string1
  }

  return string1 + ', ' + string2
}

export const getActiveSchedules = schedules => {
  if (!schedules) return []

  return schedules.filter(schedule =>
    inRange(
      todayAtTwelveAmInSecs,
      schedule.startDateInSecs,
      schedule.endDateInSecs + 1
    )
  ).length
}

export const getDynamicSchedules = schedules => {
  if (!schedules) return []

  const currentTimeInSecs = secondsSinceEpoch()

  return schedules.filter(
    schedule =>
      inRange(
        currentTimeInSecs,
        schedule.startDateInSecs,
        schedule.endDateInSecs + 1
      ) || schedule.startDateInSecs >= currentTimeInSecs
  )
}

export const getCourseResourcesSchedule = ({
  startDate,
  duration,
  fetchedSchedule,
  finalDropDate,
  finalWithdrawalDate,
  finalGradeDate,
  cohortMilestones,
  cohortSpecialDays,
  cohortExamDates,
  courseData,
  isAuditor,
  isGGUCohort,
  liveProctoring
}) => {
  const {
    chapters,
    course_uuid: courseId,
    title: courseName
  } = courseData || {}

  const { syllabusData } = fetchedSchedule || {}
  const dropAndWithdrawalDates = {
    finalWithdrawalDate: getFinalDropOrWithdrawalDate(finalWithdrawalDate),
    finalDropDate: getFinalDropOrWithdrawalDate(finalDropDate)
  }
  const currentFinalGradeDate = finalGradeDate || ''
  const formattedFinalGradeDate = currentFinalGradeDate
    .replace(/\//g, '-')
    .toLocaleString('en-us', { month: 'short', day: '2-digit' })
  const dates = { cohortExamDates, startDate, dropAndWithdrawalDates, formattedFinalGradeDate }
  const isIntensive = duration <= INTENSIVE_COHORT_DURATION
  const cohortDetails = {
    cohortMilestones,
    isIntensive,
    cohortSpecialDays,
    chapters
  }
  let formattedFetchedSchedule = getFormattedSchedule({
    fetchedSchedule: syllabusData,
    dates,
    cohortDetails,
    isAuditor,
    isGGUCohort,
    courseName,
    liveProctoring
  })
  if (isAuditor) {
    const lastDayWithdrawText = 'Last day to withdraw with a grade of W'
    formattedFetchedSchedule = formattedFetchedSchedule
      .filter(schedule => !schedule.title.includes(lastDayWithdrawText))
  }
  const isNewGDAICohort = isNewGoogleDataAnalyticsICohort(courseId, startDate * 1000)

  return formattedFetchedSchedule
    .filter(schedule => !isNewGDAICohort || schedule.assignmentType !== EXAM)
}

export const getFormattedTermBreaks = params => {
  const { isIntensive, termBreaks, cohortStartDate } = params || {}
  if (!termBreaks?.length) return []

  const formattedBreaks = termBreaks.reduce((breaks, currentBreak) => {
    const { title, week, day } = currentBreak || {}
    const dates = isIntensive
      ? getIntensiveDates({ day, cohortStartDate })
      : getStandardDates({ week, cohortStartDate })
    const {
      startDate,
      startDateInSecs,
      endDate,
      endDateInSecs,
      isActive
    } = dates || {}

    const previousBreak = breaks[breaks.length - 1]
    const isConsecutiveBreak = previousBreak?.week === week - 1 ||
        previousBreak?.day === day - 1
    if (isConsecutiveBreak) {
      previousBreak.endDate = endDate
      previousBreak.endDateInSecs = endDateInSecs
      if (!isNaN(week)) previousBreak.week = week
      else if (!isNaN(day)) previousBreak.day = day
      breaks[breaks.length - 1] = previousBreak
      return breaks
    }

    breaks.push({
      ...currentBreak,
      startDate,
      startDateInSecs,
      endDate,
      endDateInSecs,
      title,
      textLink: {
        text: isActive && 'Open Checklist',
        handleClick: (updateContext) => {
          updateContext({ openChecklist: true })
        }
      }
    })
    return breaks
  }, [])

  return formattedBreaks
}

export const getFormattedSchedule = ({
  fetchedSchedule,
  dates,
  cohortDetails,
  isAuditor,
  isGGUCohort,
  courseName,
  liveProctoring
}) => {
  if (!fetchedSchedule?.length) return []
  const {
    isIntensive,
    cohortSpecialDays,
    cohortMilestones,
    chapters
  } = cohortDetails

  const { cohortExamDates, startDate, dropAndWithdrawalDates, formattedFinalGradeDate } = dates
  const twelveAmOnStartDateInSecs = getTwelveAmInSecs(startDate)
  const { midTerm1StartDate } = cohortExamDates
  let intensiveIndex = 0
  const allSectionSchedules = fetchedSchedule.map((schedule, index) => {
    const intensiveStartDate = addDaysToDateInSecs(
      twelveAmOnStartDateInSecs,
      intensiveIndex
    )
    const isSaturday = new Date(intensiveStartDate * 1000).getDay() === 6
    const isSunday = new Date(intensiveStartDate * 1000).getDay() === 0
    if (isSunday) {
      intensiveIndex += 2
    } else if (isSaturday) {
      intensiveIndex += 3
    } else { intensiveIndex += 1 }

    return schedule.sections.map(s => {
      const dayData = { day: intensiveIndex }
      const weekData = { week: index + 1 }
      const scheduleDayOrWeek = isIntensive ? dayData : weekData
      return {
        ...s,
        ...scheduleDayOrWeek,
        schedules: schedule.sections
      }
    })
  })

  const examSchedule = flatten(allSectionSchedules).filter(
    a => a.assignmentType === EXAM
  )

  const essaySchedule = flatten(allSectionSchedules).filter(
    a => a.assignmentType === ESSAY
  )
  const formattedEssaySchedule = getFormattedEssaySchedule(
    essaySchedule,
    cohortMilestones,
    chapters
  )
  const sectionSchedules = allSectionSchedules
    .map(schedule => schedule.filter(s => s.assignmentType === SECTION))
    .filter(array => array.length)

  const sectionSchedule = sectionSchedules.flatMap((schedule, index) => {
    let newSchedule = {}
    const sections = schedule.filter(s => s.assignmentType === SECTION)
    const lengthOfSections = sections.length

    sections.forEach((sch, index) => {
      // combine all sections objects with their values into one
      newSchedule = mergeWith(newSchedule, sch, customMerge)
    })
    if (lengthOfSections > 1) {
      newSchedule.title = `Sections ${schedule[0].title
        .split('|')[1]
        .trim()} - ${sections[lengthOfSections - 1].title.split('|')[1].trim()}`
    } else {
      newSchedule.title = `Section ${schedule[0].title.split('|')[1].trim()}`
    }

    return newSchedule
  })
  const formattedSpecialDays = getFormattedSpecialDays(cohortSpecialDays)

  const formattedSectionSchedule = isIntensive
    ? getIntensiveSchedule(sectionSchedule, twelveAmOnStartDateInSecs)
    : getSectionSchedule(sectionSchedule, twelveAmOnStartDateInSecs)

  const courseraActivitiesSchedule = flatten(allSectionSchedules).filter(
    a => a.assignmentType === COURSERA_ACTIVITY
  )
  const formattedActivitiesSchedule = getCourseraActivitiesSchedule({
    isIntensive, courseraActivitiesSchedule, cohortStartDate: twelveAmOnStartDateInSecs
  })

  const formattedExamSchedule = getExamSchedule(examSchedule, cohortExamDates)
  const practiceExam = getPracticeExam(midTerm1StartDate)
  const formattedDropAndWithdrawalSchedule = getDropAndWithdrawalSchedule(
    dropAndWithdrawalDates, courseName
  )
  const finalGradeDateInSeconds =
      dateToSecondsSinceEpoch(new Date(formattedFinalGradeDate))
  const formattedFinalGradeSchedule = getFinalGradeSchedule(finalGradeDateInSeconds)
  const firstSchedule = {
    startDate: secondsToFormattedDateShort(startDate, 'numeric'),
    startDateInSecs: startDate,
    title: 'Semester Begins',
    endDate: getNextWeekdayDate(startDate, 7),
    endDateInSecs: getElevenFiftyNinePmInSecs(
      getNextWeekdayDateInSecs(startDate, 7)
    ),
    textLink: {
      text: 'Watch the Orientation',
      handleClick: () => isGGUCohort
        ? window.location.replace(GGU_ORIENTATION_LINK)
        : emitter.emit(ON_NAVIGATE_TO, '/resources/orientation')
    }
  }

  const termBreaks = flatten(allSectionSchedules).filter(
    a => a.assignmentType === BREAK
  )
  const formattedTermBreaks = getFormattedTermBreaks({
    isIntensive, termBreaks, cohortStartDate: twelveAmOnStartDateInSecs
  })

  const formattedSchedule = [
    ...formattedTermBreaks,
    ...formattedSectionSchedule,
    ...formattedActivitiesSchedule,
    ...formattedSpecialDays,
    ...formattedDropAndWithdrawalSchedule,
    ...((isAuditor || isGGUCohort) ? [] : formattedFinalGradeSchedule),
    ...formattedEssaySchedule,
    ...(isAuditor ? [] : formattedExamSchedule),
    ...(isAuditor || liveProctoring ? [] : practiceExam)
  ].sort((a, b) => a.startDateInSecs - b.startDateInSecs)

  return [firstSchedule, ...formattedSchedule].filter(
    schedule => !isEmpty(schedule)
  )
}

export const getCohortInstructors = (cohortInfo) => {
  const allInstructors = cohortInfo?.fields?.instructors
  if (!allInstructors?.length) return []

  const instructors = allInstructors?.filter(
    (instructor = {}) => !instructor?.fields?.teachingAssistant
  )

  const mappedInstructors = instructors?.map((instructor = {}) => ({
    id: instructor?.id,
    ...instructor?.fields
  }))

  return mappedInstructors
}

export const getCohortTeachingAssistants = (cohortInfo) => {
  const allInstructors = cohortInfo?.fields?.instructors
  if (!allInstructors?.length) return []

  const teachingAssistants = allInstructors?.filter(
    (instructor = {}) => instructor?.fields?.teachingAssistant
  )

  const mappedTeachingAssistants = teachingAssistants?.map((teachingAssistant = {}) => ({
    id: teachingAssistant?.id,
    ...teachingAssistant?.fields
  }))

  return mappedTeachingAssistants
}

export const getFormattedSpecialDays = specialDays => {
  if (!specialDays?.length) return []

  return specialDays.map(specialDay => {
    const {
      startDate: startDateInSecs,
      endDate: endDateInSecs,
      name: title,
      numberOfSpecialDays
    } = specialDay

    return {
      startDateInSecs: getTwelveAmInSecs(startDateInSecs),
      startDate: secondsToFormattedDateShort(startDateInSecs, 'numeric'),
      endDate: endDateInSecs
        ? addDaysToDate(startDateInSecs, numberOfSpecialDays)
        : null,
      endDateInSecs:
        endDateInSecs || getElevenFiftyNinePmInSecs(startDateInSecs),
      textLink: ' ',
      title
    }
  })
}

export const getFormattedEssaySchedule = (
  essays,
  cohortMilestones,
  chapters
) => {
  if (!essays?.length || !cohortMilestones?.length) return []

  return essays.map(essay => {
    const updatedTitle = essay.title.split('|')[1].trim()
    const currentEssay = cohortMilestones.find(({ assignmentList }) => {
      if (!assignmentList) return false
      return assignmentList.map(list => list.name).includes(essay.title)
    })
    if (!currentEssay) return {}

    const { chapter_uuid: chapterUuid } = chapters?.find(
      chapter => currentEssay.datoAssignmentUUID === chapter.chapter_uuid
    ) || {}

    const { lockTime, unlockTime, description, finalAssignment } = currentEssay
    const essayStartDateInSecs = dateToSecondsSinceEpoch(new Date(unlockTime))
    const essayEndDateInSecs = dateToSecondsSinceEpoch(new Date(lockTime))

    const isActive = inRange(
      todayAtTwelveAmInSecs,
      essayStartDateInSecs,
      essayEndDateInSecs + 1
    )

    return {
      ...essay,
      description,
      finalAssignment,
      startDate: secondsToFormattedDateShort(essayStartDateInSecs, 'numeric'),
      startDateInSecs: essayStartDateInSecs,
      endDate: secondsToFormattedDateShort(essayEndDateInSecs, 'numeric'),
      endDateInSecs: essayEndDateInSecs,
      title: updatedTitle,
      chapterUuid: chapterUuid,
      textLink: {
        text: isActive && 'View Assignment',
        handleClick: () =>
          emitter.emit(ON_NAVIGATE_TO, `/${chapterUuid}/writing_assignment`)
      }
    }
  })
}

const getDropAndWithdrawalSchedule = (dates, courseName) => {
  const dropAndWithdrawalSchedule = []
  const { finalWithdrawalDate, finalDropDate } = dates
  if (finalWithdrawalDate) {
    dropAndWithdrawalSchedule.push({
      title: 'Last day to withdraw with a grade of W',
      assignmentType: 'dropAndWithdrawal',
      startDateInSecs: getTwelveAmInSecs(finalWithdrawalDate),
      endDateInSecs: getElevenFiftyNinePmInSecs(finalWithdrawalDate),
      startDate: secondsToFormattedDateShort(finalWithdrawalDate, 'numeric'),
      textLink: {
        text: 'Contact Outlier',
        handleClick: () =>
          (window.location.href = 'https://dashboard.outlier.org/#/contact')
      }
    })
  }
  if (finalDropDate) {
    dropAndWithdrawalSchedule.push({
      title: 'Last day to drop or audit',
      assignmentType: 'dropAndWithdrawal',
      startDateInSecs: getTwelveAmInSecs(finalDropDate),
      endDateInSecs: getElevenFiftyNinePmInSecs(finalDropDate),
      startDate: secondsToFormattedDateShort(finalDropDate, 'numeric'),
      textLink: {
        text: 'Check Eligibility',
        handleClick: () =>
          (window.location.href = `https://dashboard.outlier.org/#/account/manage-courses/${courseName}`)
      }
    })
  }

  return dropAndWithdrawalSchedule
}

const getFinalGradeSchedule = finalGradeDate => {
  return [{
    title: 'Final Grade released',
    startDateInSecs: finalGradeDate,
    startDate: secondsToFormattedDateShort(finalGradeDate, 'numeric'),
    assignmentType: 'finalGrade'
  }]
}

export const getExamHashLink = title => {
  if (!title) return ''

  switch (title) {
    case 'Midterm':
      return 'midtermExam'
    case 'Midterm 1':
      return 'midtermExam1'
    case 'Midterm 2':
      return 'midtermExam2'
    default:
      return camelCase(title)
  }
}

export const getExamSchedule = (examSchedule, cohortExamDates) => {
  return examSchedule.map(examSchedule => {
    const { title } = examSchedule

    const updatedTitle = title.split('|')?.pop()?.trim()
    const hashLink = getExamHashLink(updatedTitle)
    const {
      start: examStartDate,
      stop: examEndDate
    } = getCurrentExamDate(examSchedule, cohortExamDates)

    if (!examStartDate || !examEndDate) return null

    const isActive = inRange(
      todayAtTwelveAmInSecs,
      examStartDate,
      examEndDate + 1
    )

    return {
      ...examSchedule,
      title: updatedTitle,
      startDate: secondsToFormattedDateShort(examStartDate),
      startDateInSecs: examStartDate,
      endDate: secondsToFormattedDateShort(examEndDate),
      endDateInSecs: examEndDate,
      textLink: {
        text: isActive && 'start exam',
        handleClick: () => emitter.emit(ON_NAVIGATE_TO, `/#${hashLink}`)
      }
    }
  }).filter(Boolean)
}

export const getIntensiveSchedule = (sectionSchedule, startDate) => {
  if (!sectionSchedule || !sectionSchedule.length || !startDate) return []
  let sectionStartDate = startDate

  return sectionSchedule.map((currSchedule, index) => {
    const { title: currentTitle, day = 0 } = currSchedule
    sectionStartDate = addDaysToDateInSecs(startDate, day - 1)
    let endDate = null
    let endDateInSecs = getElevenFiftyNinePmInSecs(sectionStartDate)
    const nextSchedule = sectionSchedule[index + 1] || {}
    const previousSchedule = sectionSchedule[index - 1] || {}
    const { title: nextTitle } = nextSchedule
    const { title: previousTitle } = previousSchedule
    const isSameSection = currentTitle === nextTitle
    if (isSameSection) {
      endDate = addDaysToDate(sectionStartDate, 1)
      endDateInSecs = addDaysToDateInSecs(sectionStartDate, 1)
    }
    if (previousTitle === currentTitle && !isSameSection) return null

    const formattedSchedule = {
      ...currSchedule,
      startDate: secondsToFormattedDateShort(sectionStartDate, 'numeric'),
      startDateInSecs: sectionStartDate,
      endDate,
      endDateInSecs
    }

    return formattedSchedule
  }).filter(Boolean)
}

export const getSectionStartDate = (week, dates) => {
  if (!week || !dates) return

  const isWeekOne = week === 1
  const isWeekTwo = week === 2

  const { firstMondayAfterStartDate, sectionStartDate } = dates
  if (isWeekTwo) return firstMondayAfterStartDate
  else if (isWeekOne) return sectionStartDate

  const days = (week - 2) * 7
  return addDaysToDateInSecs(firstMondayAfterStartDate, days)
}

export const getSectionSchedule = (sectionSchedule, startDate) => {
  let sectionStartDate = startDate
  const nextSundayDate = getNextWeekdayDate(startDate, 7)
  const nextSundayDateInSecs = getElevenFiftyNinePmInSecs(
    getNextWeekdayDateInSecs(startDate, 7)
  )

  // if the start date is a Sunday, add a day to the start date
  // considering the end of the first week to be the following Sunday
  // and get the first Monday after the start date to be the first day of the second week
  const isSunday = new Date(startDate * 1000).getDay() === 0
  const modifiedStartDate = isSunday ? addDaysToDateInSecs(startDate, 1) : startDate
  const firstMondayAfterStartDate = getNextWeekdayDateInSecs(modifiedStartDate, 1)

  return [...sectionSchedule].map(currSchedule => {
    const { week } = currSchedule
    const isWeekOne = week === 1
    const dates = { firstMondayAfterStartDate, sectionStartDate }
    sectionStartDate = getSectionStartDate(week, dates)

    const formattedSchedule = {
      ...currSchedule,
      startDate: secondsToFormattedDateShort(sectionStartDate, 'numeric'),
      startDateInSecs: sectionStartDate,
      endDate: isWeekOne ? nextSundayDate : addDaysToDate(sectionStartDate, 6),
      endDateInSecs: isWeekOne
        ? nextSundayDateInSecs
        : getElevenFiftyNinePmInSecs(addDaysToDateInSecs(sectionStartDate, 6))
    }

    return formattedSchedule
  })
}

export const getIntensiveDates = params => {
  const { day, cohortStartDate } = params || {}
  const activityStartDate = addDaysToDateInSecs(cohortStartDate, day - 1)

  const endDate = addDaysToDate(activityStartDate, 1)
  const endDateInSecs = getElevenFiftyNinePmInSecs(activityStartDate)
  const isActive = inRange(todayAtTwelveAmInSecs, activityStartDate, endDateInSecs + 1)

  return {
    startDate: secondsToFormattedDateShort(activityStartDate, 'numeric'),
    startDateInSecs: activityStartDate,
    endDate,
    endDateInSecs,
    isActive
  }
}

export const getStandardDates = params => {
  const { week, cohortStartDate } = params || {}
  const firstMondayAfterStartDate = getNextWeekdayDateInSecs(cohortStartDate, 1)
  const activityStartDate = getSectionStartDate(
    week, { firstMondayAfterStartDate, sectionStartDate: cohortStartDate }
  )

  const isWeekOne = week === 1
  const nextSundayDate = getNextWeekdayDate(cohortStartDate, 7)
  const nextSundayDateInSecs = getElevenFiftyNinePmInSecs(
    getNextWeekdayDateInSecs(cohortStartDate, 7)
  )
  const endDate = isWeekOne ? nextSundayDate : addDaysToDate(activityStartDate, 6)
  const endDateInSecs = isWeekOne
    ? nextSundayDateInSecs
    : getElevenFiftyNinePmInSecs(addDaysToDateInSecs(activityStartDate, 6))
  const isActive = inRange(todayAtTwelveAmInSecs, activityStartDate, endDateInSecs + 1)

  return {
    startDate: secondsToFormattedDateShort(activityStartDate, 'numeric'),
    startDateInSecs: activityStartDate,
    endDate,
    endDateInSecs,
    isActive
  }
}

export const getCourseraActivitiesSchedule = params => {
  const { isIntensive, courseraActivitiesSchedule, cohortStartDate } = params || {}
  if (!courseraActivitiesSchedule?.length || !cohortStartDate) return []

  return courseraActivitiesSchedule.map(activity => {
    const {
      title, week, day = 0, courseraAssignmentId
    } = activity || {}
    const {
      isActive, startDate, startDateInSecs, endDate, endDateInSecs
    } = isIntensive
      ? getIntensiveDates({ day, cohortStartDate })
      : getStandardDates({ week, cohortStartDate })

    const updatedTitle = title?.split('|')?.[2]?.trim() || ''
    const activityType = updatedTitle.split(':')?.[0]?.trim() || ''
    return {
      ...activity,
      startDate,
      startDateInSecs,
      endDate,
      endDateInSecs,
      title: `Coursera ${updatedTitle}`,
      textLink: {
        text: isActive && `View ${activityType}`,
        handleClick: async () => {
          const result = await api.getCourseraActivityLink(title, courseraAssignmentId)
          const { deeplinkUrl } = result || {}
          if (!deeplinkUrl) {
            console.error('No deeplinkUrl found: ', result)
            return
          }
          window.open(deeplinkUrl, '_blank')
        }
      }
    }
  })
}

export const getCurrentExamDate = (examSchedule, cohortExamDates) => {
  const {
    title,
    assignmentType,
    examNumber,
    isFinalExam
  } = examSchedule

  const exam = {
    title,
    type: assignmentType,
    examNumber,
    isFinalExam
  }

  const {
    startDate,
    endDate
  } = getExamDatesFromCohortExamDates({
    exam,
    cohortExamDates
  })

  return {
    start: startDate,
    stop: endDate
  }
}

export const getPracticeExam = midTerm1StartDate => {
  if (!midTerm1StartDate) return []
  // subtract a week (7 days) from midTerm1StartDate in seconds
  // one day is 86_400 seconds —> 24 hr * 60 mins * 60 secs
  const practiceExamStartDate = midTerm1StartDate - 86_400 * 7

  const isPracticeExamOpen = practiceExamStartDate <= secondsSinceEpoch()

  return [
    {
      title: SET_UP_YOUR_EXAM,
      assignmentType: EXAM,
      startDateInSecs: getTwelveAmInSecs(practiceExamStartDate),
      startDate: secondsToFormattedDateShort(practiceExamStartDate, 'numeric'),
      endDate: addDaysToDate(practiceExamStartDate, 6),
      endDateInSecs: addDaysToDateInSecs(practiceExamStartDate, 6),
      textLink: {
        text: isPracticeExamOpen && 'start exam',
        handleClick: () => emitter.emit(ON_NAVIGATE_TO, '/')
      }
    }
  ]
}

// https://datebook.dev/docs/icalendar.html
export const getCalendarEvents = schedule => {
  if (!schedule?.length) return null
  const courseName = capitalizeFirstLetter(config.courseName)
  const calendarSchedule = schedule
    .map(sch => {
      if (sch.title === 'Practice Exam') return null

      return {
        ...sch,
        title: `[${courseName}] ${sch.title}`
      }
    })
    .filter(Boolean)

  const calendarConfig = {
    title: `${courseName} Course begins`,
    description: calendarSchedule[0]?.description,
    start: new Date(calendarSchedule[0].startDateInSecs * 1000),
    // add 1000ms to round up to 12 am the next day
    end: new Date(
      getElevenFiftyNinePmInSecs(calendarSchedule[0].startDateInSecs) * 1000 +
        1000
    )
  }
  const calendar = new ICalendar(calendarConfig)

  calendarSchedule.slice(1).forEach(sch => {
    const event = new ICalendar({
      title: sch.title,
      description: sch.description,
      start: new Date(sch.startDateInSecs * 1000),
      end: sch.endDate ? new Date(sch?.endDateInSecs * 1000) : null
    })

    calendar.addEvent(event)
  })

  return calendar
}

// convert percentage to degrees based on a total of 360
export const getPercentageInDegrees = value => {
  return (value / 100) * 360
}

export const getSubTitle = value => {
  return Math.floor(value * 100) / 100
}

export const isNewCollegeWritingIReleased = cohortStartDate => {
  return cohortStartDate >= config.collegeWritingIUpdatedAssignmentReleaseDate
}

export const getGradingCriteria = (courseName) => {
  return GRADING_CRITERIA[courseName]
}

/**
 * @param {Array} gradeCalculations an array that contains the grading criteria
 * @returns {Array} that have the grading criteria mapped to title and percentage
 *  returns undefined in case of an the argument does not have the data needed
 *  as the component that uses the function relies on that for early return.
 */
export const getDynamicGradingCriteria = (gradeCalculations = []) => {
  const gradeCalculationActivity =
    gradeCalculations?.[0]?.gradeCalculationActivityPercentage
  if (!gradeCalculationActivity?.length) return

  const gradingCriteria = gradeCalculationActivity.map((grade = {}) => {
    const category = grade?.gradeCriteriaActivityCategory || ''
    return {
      title: category,
      percentage: grade?.gradeCriteriaActivityPercentage
    }
  })

  return gradingCriteria
}

/**
 * @param {Object} contains the data coming from Dato
 * @returns {Array} that contains the text directly.
 */
export const getGradeExplanationText = (datoGradeExplanationText) => {
  const gradingExplanationText = datoGradeExplanationText?.gradingExplanationText?.value
  const isValidStructure = validate(gradingExplanationText).valid
  if (!isValidStructure) return []

  const explanationTextNodes = renderNodes(gradingExplanationText)

  const explanationTextArr = explanationTextNodes?.map(
    node => node?.innerHTML?.trim()
  ).filter(item => !!item)

  return explanationTextArr
}

/**
 * @param {Array} textArray containing text elements
 * @returns {Array} of two array dividing the text array into two
 *  each one containing half or the original array for even numbers
 *  and +1 in the first array for odd number of elements.
 */
export const getExplanationArticles = (textArray = []) => {
  if (!textArray?.length) return []

  const isEvenArray = textArray.length % 2 === 0
  const midArrayIndex = isEvenArray
    ? Math.floor((textArray.length - 1) / 2)
    : Math.floor(textArray.length / 2)

  return [
    textArray.slice(0, midArrayIndex + 1),
    textArray.slice(midArrayIndex + 1)
  ]
}

/**
 * @param {Object} gradeScales containing the data to be parsed
 * @param {Object} cohortData containing cohort information
 * @returns static grading scale for non-GGU cohorts and
 *  mapped grading scale data to be used in the UI for GGU cohorts
 */
export const getGradingScale = ({ gradeScales = {}, cohortData = {} }) => {
  const {
    officialCourseName, isGGUCohort, is39WeekCohort
  } = cohortData || {}
  const isFloridaPolyCohort = officialCourseName?.includes('- FP')

  const haveDynamicData = isGGUCohort || is39WeekCohort
  if (!haveDynamicData) {
    return GRADING_SCALE[isFloridaPolyCohort ? 'FP' : 'main']
  }

  const gradeScaleSetup = gradeScales?.gradeScaleSetup
  if (!gradeScaleSetup?.length) return

  const mappedGradeScales = gradeScaleSetup.map((grade = {}) => {
    const {
      letterGrade, letterGradeUpperBoundary, letterGradeLowerBoundary
    } = grade
    return {
      symbol: letterGrade,
      range: `${letterGradeUpperBoundary}-${letterGradeLowerBoundary}`
    }
  })

  return mappedGradeScales
}

/**
 * @param {Object} learningOutcomesRaw containing the raw data coming from Dato
 * @returns {Array} learningOutcomes containing the data to used directly
 */
export const getLearningOutcomes = (datoLearningOutcomes = {}) => {
  const isValidStructure = validate(datoLearningOutcomes?.value).valid
  if (!isValidStructure) return []

  const nodes = renderNodes(datoLearningOutcomes?.value)
  const children = nodes?.length > 1
    ? nodes
    : Array.from(nodes?.[0]?.childNodes || [])

  const learningOutcomes = children?.map((item) => {
    return item?.innerHTML
  }).filter(item => !!item)

  return learningOutcomes
}

/**
* @param {Object} datoCourseMaterials containing the raw data coming from Dato
* @returns {String} Course Materials Text or undefined if not available
*/
export const getCourseMaterialsTextFromDato = (datoCourseMaterials = {}) => {
  const courseMaterialsText = datoCourseMaterials?.courseMaterialsText?.value
  const isValidStructure = courseMaterialsText && validate(courseMaterialsText).valid
  if (!isValidStructure) return

  return render(courseMaterialsText)
}

export const shouldHideGrading = ({
  courseData = {}, cohortData = {}, isCollegeSuccessCourse = false
}) => {
  const { gradeScales = {}, gradeCalculations = [] } = courseData || {}
  const { isGGUCohort, is39WeekCohort } = cohortData || {}

  const haveDynamicData = isGGUCohort || is39WeekCohort

  if (isCollegeSuccessCourse) return true
  if (!haveDynamicData) return false

  const gradingScale = getGradingScale({ gradeScales, cohortData })
  const gradingCriteria = getDynamicGradingCriteria(gradeCalculations)

  const hasGradingData = !!gradingScale?.length || !!gradingCriteria?.length

  return !hasGradingData
}
