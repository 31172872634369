import React from 'react'
import CourseButton from '../CourseButton/CourseButton'

function QuestionCheckButton (props) {
  const {
    handleCheck,
    isDisabled,
    tryAgain
  } = props
  return (
    <CourseButton
      className={{
        'btn-primary': !isDisabled,
        'btn-secondary': isDisabled,
        'text-btn': true
      }}
      disabled={isDisabled}
      onClick={handleCheck}
    >
      { tryAgain ? 'Try Again' : 'Check'}
    </CourseButton>
  )
}

export default QuestionCheckButton
