import React, { Component } from 'react'
import ModalComponent from '../ModalComponent/ModalComponent'
import { StyledImageTitle } from './styled'

class AttributedImage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalShow: false
    }
    this.setModalShow = this.setModalShow.bind(this)
  }

  setModalShow (modalShow) {
    this.setState({ modalShow })
  }

  render () {
    const { alt = '', src, title, img_style: imgStyle } = this.props
    const { modalShow } = this.state
    return (
      <>
        <div className='image__attr' style={{ imgStyle }}>
          <img
            onClick={() => this.setModalShow(true)}
            src={src}
            alt={alt}
            style={{ imgStyle }}
            width={!imgStyle ? '100%' : ''}
          />
          {title && <StyledImageTitle
            dangerouslySetInnerHTML={{ __html: title }}
          />}
        </div>
        <ModalComponent show={modalShow} handleClose={() => this.setModalShow(false)}>
          <img src={src} alt={alt} className='img-modal' />
        </ModalComponent>
      </>
    )
  }
}
export default AttributedImage
