import React, { Component } from 'react'
import SectionExcerciseList from '../SectionExcerciseList/SectionExcerciseList'
import CourseMaterialUri from '../CourseMaterialUri/CourseMaterialUri'
import Context from '../Context/Context'
import AlertModal from '../AlertModal/AlertModal'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'
import { DEFAULT_DIFFICULTY_WARNING_LEVEL_1 } from '../../Constants/sectionTooltip'
import { emitter } from '../Emitter/Emitter'
import {
  Container,
  SectionContainer,
  SectionTitle,
  WarningContainer,
  WarningImgContainer,
  WarningTextContainer
} from './style'
import ReadMoreToggler from '../ReadMoreToggler/ReadMoreToggler'

class SectionLanding extends Component {
  render () {
    const { SectionSetdata } = this.props
    if (!SectionSetdata) return <div>loading...</div>

    const {
      SectionSetdata: { course_material: courseMaterial,
        warningText, difficultyLevel,
        section_title: sectionTitle, section_defination: sectionDefination,
        section_exe: sectionTypes, section_uuid: sectionUUID,
        work_in_progress: workInProgress, guessworkSectionUUID
      },
      finalExamUnlockDate,
      chapterExamUnlockDate,
      completeScreenData,
      finalExamLockDate
    } = this.props
    const warningAtLevel1 = warningText || DEFAULT_DIFFICULTY_WARNING_LEVEL_1
    const warning = (difficultyLevel === 1) ? warningAtLevel1 : warningText
    return (
      <section className='section section-home'>
        {workInProgress &&
          <AlertModal
            closeButtonText={'COURSE HOME'}
            closeButtonClickHandler={() => emitter.emit(ON_NAVIGATE_TO, '/')}
          >
            Sorry for the dust! This section is still under construction and will be available to you soon. In the meantime, please continue working on the other sections.
          </AlertModal>
        }
        <Container className='container'>
          <SectionContainer>
            <div className='section-main__text'>
              <SectionTitle>
                {sectionTitle}
              </SectionTitle>
              <ReadMoreToggler>
                {sectionDefination}
              </ReadMoreToggler>
              {warning &&
              <WarningContainer>
                <WarningImgContainer
                  src='/images/icons/difficulty-icon.svg'
                  alt={`difficulty ${difficultyLevel}`}
                />
                <WarningTextContainer>
                  {warning}
                </WarningTextContainer>
              </WarningContainer>}
            </div>
            <div className='section-panel__wrapper'>
              <SectionExcerciseList
                excerciseList={sectionTypes}
                sectionUUID={sectionUUID}
                completeScreenData={completeScreenData}
                finalExamLockDate={finalExamLockDate}
                guessworkSectionUUID={guessworkSectionUUID}
                chapterExamUnlockDate={chapterExamUnlockDate}
                finalExamUnlockDate={finalExamUnlockDate}
              />
            </div>
          </SectionContainer>
          <CourseMaterialUri course_material={courseMaterial} />
        </Container>
      </section>
    )
  }
}

SectionLanding.contextType = Context
export default SectionLanding
