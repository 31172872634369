import React, { useEffect, useState } from 'react'
import ModalComponent from '../ModalComponent/ModalComponent'
import Button from '../CourseButton/CourseButton'
import {
  ModalWrapper,
  ModalContent,
  StyledHeaderText,
  StyledList,
  StyledAnchor,
  ModalFooter
} from './styled'
import api from '../../api'
import { getPopupType, isAmazonStudent } from '../../utilities/voucher'
import { DASHBOARD_VOUCHER_URL, POPUP_TYPE } from '../../Constants/voucher'
import withStudentContext from '../../Hoc/withStudentContext'

const VoucherUploadModal = props => {
  const { studentData: { showVoucherPopup, studentCourses } } = props
  const [popupType, setPopupType] = useState(null)

  useEffect(() => {
    const isStudentAmazon = isAmazonStudent(studentCourses)
    const shouldNotCallAmazonEndpoint = !showVoucherPopup ||
      !isStudentAmazon

    if (shouldNotCallAmazonEndpoint) return

    fetchVouchers()
  }, [showVoucherPopup, studentCourses])

  const fetchVouchers = async () => {
    const vouchers = await api.getAmazonVouchers()
    const type = getPopupType(vouchers)

    if (!type) return null

    setPopupType(type)
  }

  const wrapperStyles = {
    backgroundColor: '#25272B',
    borderRadius: '15px',
    top: '35%'
  }

  const buttonStyles = {
    height: '55px',
    width: '150px',
    fontSize: '13px',
    whiteSpace: 'normal'
  }

  const closeModal = () => {
    const { studentData: { updateContext } } = props
    setPopupType(null)
    updateContext({ showVoucherPopup: false })
  }

  const handleRedirect = () => {
    window.open(DASHBOARD_VOUCHER_URL, '_blank')
  }

  const shouldNotRenderModal = !popupType || !showVoucherPopup
  if (shouldNotRenderModal) return null

  const isRemovableType = popupType === POPUP_TYPE.REMOVABLE

  const amazonCareerChoiceLink = (
    <StyledAnchor target='_blank' href='https://www.amazoncareerchoice.com/home'>
      Amazon Career Choice Dashboard
    </StyledAnchor>
  )

  return (
    <ModalComponent
      show={!!popupType}
      hideClose
      wrapperStyles={wrapperStyles}
      wrapperWidth={'700px'}
    >
      <ModalWrapper>
        <ModalContent>
          <>
            <StyledHeaderText>
                Action Required: Upload Your Amazon Career Choice Tuition Voucher for College Pathway!
            </StyledHeaderText>
            <p>
              Amazon Career Choice needs to approve your enrollment. Before your course starts:
            </p>
            <StyledList type='number'>
              <li>
                Go to {amazonCareerChoiceLink}{' '}
                to generate a unique voucher and fill it out.
              </li>
              <li>
                Once you have filled out the voucher, submit it{' '}
                <StyledAnchor onClick={handleRedirect}>here</StyledAnchor>.
              </li>
            </StyledList>
            <p>We look forward to seeing you in class!</p>
            <p>
              Note: If you do not submit your voucher before your course starts,
              we will have to drop you from the program.
            </p>
          </>
        </ModalContent>
        <ModalFooter>
          {
            isRemovableType && (
              <Button
                className={{
                  'btn-custom': true,
                  'btn-secondary': true
                }}
                style={{
                  ...buttonStyles,
                  marginRight: '15px'
                }}
                onClick={closeModal}
              >
                Dismiss
              </Button>
            )
          }
          <Button
            className={{
              'btn-custom': true,
              'btn-primary': true
            }}
            style={buttonStyles}
            onClick={handleRedirect}
          >
            Submit Voucher
          </Button>
        </ModalFooter>
      </ModalWrapper>
    </ModalComponent>
  )
}

export default withStudentContext(VoucherUploadModal)
