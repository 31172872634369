import React from 'react'
import SectionListItem from '../SectionListItem/SectionListItem'

const SectionList = props => {
  const {
    sections,
    chapter_uuid: chapterUUID,
    chapterType,
    chapterLoopId,
    hideSectionNumber,
    lockReason
  } = props

  return sections.map((section, id) => {
    return (
      <SectionListItem
        sectionItem={section}
        key={section.section_uuid}
        chapter_uuid={chapterUUID}
        chapterType={chapterType}
        chapterLoopId={chapterLoopId}
        sectionListLoopId={id}
        lockReason={lockReason}
        hideSectionNumber={hideSectionNumber}
      />
    )
  })
}

export default React.memo(SectionList)
