import React from 'react'

export default function CourseMaterialUri (props) {
  const { course_material: courseMaterial } = props
  if (!courseMaterial) return null
  return (
    <div className='container-sm'>
      <div className='section-main__text'>
        <h1>Course Material Links</h1>
      </div>
      <div className='section-panel__wrapper'>
        {courseMaterial.map(({ uri }, i) => (
          <div key={i} className='section-panel__list row align-items-center'>
            <div className='col-7 section-panel__list-text'>
              <a
                className='text-primary'
                href={uri}
                target='_blank'
                rel='noopener noreferrer'
              >
                {window
                  // Get the file name from the pathname
                  .decodeURI(uri.split('/').pop())
                  // Remove the file extension
                  .split('.')
                  .slice(0, -1)
                  .join('.')
                  // Replace underscores with spaces
                  .replace(/_/g, ' ')}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
