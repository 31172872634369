import React from 'react'
import PropTypes from 'prop-types'
import CourseButton from '../../CourseButton/CourseButton'
import LockedMarker from '../../LockedMarker/LockedMarker'

const PracticeExerciseItemButton = props => {
  const {
    isLocked,
    isCompleted,
    isCohortEnded,
    isConceptMap,
    handleClick
  } = props

  const buttonStyle = { minWidth: '119px', lineHeight: '22px' }

  if (isLocked) return <LockedMarker />

  const getButtonInfo = () => {
    if (
      isCohortEnded
    ) return getInfoObject(getCohortEndText(isConceptMap), 'secondary')
    if (isCompleted) return getInfoObject('Review', 'secondary')
    return getInfoObject('Start', 'primary')
  }

  const getCohortEndText = isConceptMap => isConceptMap
    ? 'View' : 'Practice'

  const getInfoObject = (text, className) => {
    return { text, className }
  }

  const { text, className } = getButtonInfo()

  return (
    <CourseButton
      onClick={handleClick}
      style={buttonStyle}
      className={{ [`btn-${className}`]: true }}
    >
      {text}
    </CourseButton>
  )
}

PracticeExerciseItemButton.propTypes = {
  isConceptMap: PropTypes.bool,
  isLocked: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isCohortEnded: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired
}

PracticeExerciseItemButton.displayName = 'PracticeExerciseItemButton'

export default PracticeExerciseItemButton
