import styled from 'styled-components'
import media from '../../../mediaQueries'
import { SectionTitle } from '../../SectionListItem/styled'
import { ContentTitle } from '../../styles/text'

const LIGHT_DARK = '#1D1D1F'

export const ChapterTitle = styled(ContentTitle)`
    margin-bottom: ${props => props.mb ? props.mb : 0}px;
    cursor: pointer;
    flex-grow: 1;
    margin-right: 18px;
`

export const ChapterDescription = styled(SectionTitle)`
  margin-top: 36px;
  margin-bottom: 9px;
  -webkit-font-smoothing: unset !important;
  color: #D6E7E7;
`

export const ChapterHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ChapterInfoWrapper = styled.div`
  background: #161618;
  border-radius: 5px;
  padding: 24px 12px 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  ${media.mobile`
    flex-direction: column;
    align-items: flex-start;
    justify-content- flex-start;
  `}
`

export const ChapterWrapper = styled.div`
  background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(46, 46, 47, 0));
  padding: 1px;
  border-radius: 5px;
`

export const ChapterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`

export const ChapterToggleIcon = styled.span`
  margin-top: 14px;
  margin-right: 6px;
  cursor: pointer;
  mask: url(/images/icons/icon-angle-arrow-light.svg) no-repeat 50% 50%;
  width: 12px;
  height: 7px;
  background-color: white;
  transition: all ease-in 0.4s;

  ${props => !props.collapsed && ({
    transform: 'rotate(180deg)'
  })}
`

export const ChapterExamContentWrapper = styled.div`
  p {
    margin-bottom: 12px !important;
    -webkit-font-smoothing: unset !important;
    color: #D6E7E7;
    font-weight: normal;
  }
`

export const ChapterContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.mobile`width: 100% !important;`}
`

export const SectionListWrapper = styled.div`
  width: 100% !important;
`

export const CourseLockTextWrapper = styled.div`
  display:  ${props => props.show ? 'flex' : 'none'};
`

export const CourseLockText = styled.p`
  font-weight: normal;
  font-size: 16px !important;
  line-height: 19px !important;
`

export const LightningIcon = styled.span`
  mask: url(/images/icons/icon-lightning.svg) no-repeat 50% 50%;
  width: 16px;
  height: 16px;
  background-color: white;
  margin-right: 10px;
  margin-top: 5px;
`
export const ErrorMessage = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #E1774F;
  line-height: 19px;
  margin-bottom: 8px;
  margin-left: 10px;
`

export const ExamStudyGuideButton = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  margin-left: 12px;
  margin-bottom: 12px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5FC4B8;
  &:hover {
    text-decoration: underline;
  }
  ${media.mobile`
    margin-left: 12px;
    margin-bottom: 12px;
  `}
`
export const StudyGuideWrapper = styled.div`
  display: flex !important;
  align-items: baseline;
  ${media.mobile`
    flex-direction: ${(({ column }) => column ? 'column' : 'row')};
    display: flex !important;
  `}
`

export const StudyGuideUnlockText = styled.div`
  font-size: 16px;
  font-weight: normal;
  margin-left: 12px;
  line-height: 19px;
  color: #FFFFFF;
  ${media.mobile`
    margin-left: 0px;
    margin-bottom: 12px;
  `}
`

export const StudioCohortSignWrapper = styled.div`
  width: 100%;
  padding: 16px;
  margin-top: 12px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: ${LIGHT_DARK};

  ${media.mobile`
    order: 1;
    margin-bottom: 24px;
  `}
`

export const StudioCohortText = styled.p`
  margin-left: 16px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
`

export const ButtonWrapper = styled.div`
  direction: rtl;
  ${media.mobile`
    direction: ltr;
  `}
`

export const StartButton = styled.button`
  border: 1px solid #5FC4B8;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #5FC4B8;
  width: 100%;
  height: 49px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #161618;
  padding-left: 24px;
  padding-right: 24px;
`

export const ReviewButton = styled(StartButton)`
  background-color: #161618;
  color: #5FC4B8;
`

export const PreviewButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  width: 117px;
  height: 49px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  background: #5FC4B8;
  border-radius: 5px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #161618;
`
