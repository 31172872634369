import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const ScoreWrapper = styled.span`
    margin-right: 13px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #FFFFFF;
`

export const SectionListItemWrapper = styled.div`
  margin-bottom: 12px;
  background-color: #0A2A2D;
  padding: 12px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${mediaqueries.mobile`
    flex-direction: column;
    align-items: stretch;
  `}

  &:hover,&:focus { 
    background-color: #0f3e43;
  }

  &:active {
    background-color: #0f3e43;
    opacity: 0.3;
  }

  &.disabled {
    background-color: #0a2a2d;
    opacity: 0.3;
  }
`

export const SectionTitle = styled.p`
  font-size: 16px !important;
  line-height: 19px !important;
  color: #FFF;
  font-weight: normal;
`

export const SectionScore = styled.div`
  display: flex;
  flex-direction: row-reverse;
  ${mediaqueries.mobile`
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
  `}

  img {
    margin-right: 13px;
  }
`

export const ScoreContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  ${mediaqueries.mobile`
    flex-direction: row;
`}
`

export const ProgressMeter = styled.span`
  margin-right: 13px;
  width: 51px;
  height: 15px;
  border: 1px solid #1e3b41;
  border-radius: 6px;
  overflow: hidden;
`
