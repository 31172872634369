import React from 'react'
import PropTypes from 'prop-types'
import { TabsWrapper, Tab, Icon, TabText } from './styles'

const Tabs = ({ activeNavTab, onTabClick }) => {
  const tabs = [
    { name: '' },
    { name: 'Lecture Notes' },
    { name: 'Editor' }
  ]

  return (
    <TabsWrapper activeNavTab={activeNavTab}>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          active={activeNavTab === index}
          onClick={() => onTabClick(index)}
          activeNavTab={activeNavTab}
          index={index}
        >
          <Icon />
          <TabText>{tab.name}</TabText>
        </Tab>
      ))}
    </TabsWrapper>
  )
}

Tabs.propTypes = {
  activeNavTab: PropTypes.number.isRequired,
  onTabClick: PropTypes.func.isRequired
}

Tabs.displayName = 'Tabs'
export default Tabs
