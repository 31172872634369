import React, { useEffect, useRef } from 'react'
import {
  BoxContainer,
  GoBackLink,
  Heading,
  Button,
  Container, GoBackIcon, OverrideStyles
} from '../styled'
import PropTypes from 'prop-types'

const WarningPage = ({
  showAIWarning,
  setShowAIWarning
}) => {
  const startExamButtonRef = useRef(null)

  useEffect(() => {
    if (showAIWarning) {
      startExamButtonRef.current.focus()
    }
  }, [showAIWarning])

  if (!showAIWarning) return null
  const handleGoBack = () => {
    window.location.href = '/'
  }

  const handleStartExamClick = () => {
    setShowAIWarning(false)
  }

  const handleKeyDown = (e) => {
    const { key } = e
    if (key === 'Enter') handleStartExamClick()
    if (['Escape', 'Backspace', 'ArrowLeft'].includes(key)) handleGoBack()
  }

  return (
    <>
      <OverrideStyles />
      <Container>
        <GoBackLink
          onClick={handleGoBack}
          handleKeyDown={handleKeyDown}
          aria-label='Course home'
          tabIndex={0}
        >
          <GoBackIcon />course home
        </GoBackLink>
        <BoxContainer>
          <Heading>Before you begin...</Heading>
          <p>
          Remember, keep it honest! Once you start the exam, you are not
          permitted to click away.
          </p>
          <p>
          You should have everything put away except for a blank sheet of paper
          and pen/pencil. Using unauthorized materials like notes, websites,
          search engines, or smart devices is <i>never</i> allowed.
          </p>
          <p>Your success comes from your own hard work. You got this!</p>
          <Button
            ref={startExamButtonRef}
            onClick={handleStartExamClick}
            onKeyDown={handleKeyDown}
            aria-label='Start Exam'
            tabIndex={1}
          >
          Start Exam
          </Button>
        </BoxContainer>
      </Container>
    </>
  )
}

WarningPage.propTypes = {
  showAIWarning: PropTypes.bool.isRequired,
  setShowAIWarning: PropTypes.func.isRequired
}

export default WarningPage
