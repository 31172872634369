import React, { useContext } from 'react'
import {
  LAST_GUESSWORK_UUID,
  LAST_EXAM_UUID,
  LAST_QUIZ_UUID,
  LECTURE_VIDEO_PROGRESS,
  LECTURE_COMPLETE,
  READINGS_PROGRESS,
  READINGS_COMPLETE,
  ASSIGNMENT_PROGRESS,
  PRACTICE_TERM_COMPLETE,
  CONCEPT_MAP_PROGRESS,
  CONCEPT_MAP_COMPLETE,
  ORIENTATION_ACTIVE_LEARNING_COMPLETE,
  ORIENTATION_LAST_ACTIVE_LEARNING_UUID,
  ORIENTATION_LECTURE_COMPLETE,
  ORIENTATION_LECTURE_VIDEO_PROGRESS,
  ORIENTATION_SECTION_PROGRESS,
  REVIEW_LECTURE_VIDEO_PROGRESS,
  REVIEW_LECTURE_COMPLETE,
  REVIEW_SECTION_PROGRESS,
  ORIENTATION_STUDENT_ANSWERS,
  LAST_ACTIVE_LEARNING_UUID,
  REVIEW_ACTIVE_LEARNING_COMPLETE,
  REVIEW_LAST_ACTIVE_LEARNING_UUID,
  REVIEW_STUDENT_ANSWERS,
  SECTION_VIDEO_PROGRESS
} from '../../Constants/studentContext'
import { syncStates } from '../../Constants/textEditor'

export const StudentData = {

  course: {
    instructors: {

    },
    currentInstructor: null
  },
  courseID: '',
  currentChapter: {},
  currentSection: {},
  currentProblemSet: [],
  currentProblemSetResponse: {},
  reviewMode: false,
  studentData: {
    'active-learning-complete': {

    },
    'practice-exercises-complete': {

    },
    'guesswork-complete': {

    },
    [PRACTICE_TERM_COMPLETE]: {

    },
    [LECTURE_VIDEO_PROGRESS]: {

    },
    [SECTION_VIDEO_PROGRESS]: {

    },
    [LECTURE_COMPLETE]: {

    },
    'quiz-complete': {

    },
    'quiz-section': {

    },
    'quiz-percentage': {

    },
    studentAnswers: [

    ],
    sectionProgress: {

    },
    minimumSectionProgress: {

    },
    [ASSIGNMENT_PROGRESS]: {

    },
    'exam-section': {

    },
    'exam-complete': {

    },
    'exam-percentage': {

    },
    ratings: {

    },
    [READINGS_PROGRESS]: {},
    [READINGS_COMPLETE]: {},
    [LAST_GUESSWORK_UUID]: {},
    [LAST_EXAM_UUID]: {},
    [LAST_QUIZ_UUID]: {},
    [CONCEPT_MAP_PROGRESS]: {},
    [CONCEPT_MAP_COMPLETE]: {},
    [LAST_ACTIVE_LEARNING_UUID]: {},
    [ORIENTATION_ACTIVE_LEARNING_COMPLETE]: {},
    [ORIENTATION_LAST_ACTIVE_LEARNING_UUID]: {},
    [ORIENTATION_LECTURE_COMPLETE]: {},
    [ORIENTATION_LECTURE_VIDEO_PROGRESS]: {},
    [ORIENTATION_SECTION_PROGRESS]: {},
    [ORIENTATION_STUDENT_ANSWERS]: [],
    [REVIEW_LECTURE_COMPLETE]: {},
    [REVIEW_LECTURE_VIDEO_PROGRESS]: {},
    [REVIEW_SECTION_PROGRESS]: {},
    [REVIEW_ACTIVE_LEARNING_COMPLETE]: {},
    [REVIEW_LAST_ACTIVE_LEARNING_UUID]: {},
    [REVIEW_STUDENT_ANSWERS]: [],
    'pretest-complete': {

    },
    'pretest-section': [

    ],
    'pretest-percentage': [

    ],
    pinnedQuestions: []
  },
  extensions: [],
  currentInstructor: {},
  audioConfig: {},
  sectionTotalQuestionSet: {},
  isStudentProgressBusy: false,
  isStudentProgressLoaded: false,
  isCourseDataLoading: false,
  isVIP: undefined,
  isActiveLearningButtonClicked: false,
  isVIPGradedContent: false,
  isAuditor: false,
  isStudioCohort: false,
  isAdmin: false,
  fetchLocalProgress: false,
  isInitialProgress: false,
  isContentUser: false,
  showVoucherPopup: true,
  isNoAssessments: false,
  activeLearningDatoLink: '',
  // To avoid showing the TOS modal for a split of a second before ContextProvider loads
  termsAgreement: true,
  needsGuardianPermission: false,
  lastMobileLogin: undefined,
  showMobilePromotion: true,
  currentActiveLearningTheme: '',
  registerStatus: true,
  noAccessToCourse: false,
  firstExamDates: {},
  latestCohort: {},
  cohortData: {
    name: '',
    startDate: '',
    cohortSpecialDays: undefined,
    endDate: '',
    duration: '',
    cohortID: '',
    courseResourcesSchedule: [],
    creditGrantingInstitution: '',
    calendarEvents: [],
    calendarKey: '',
    finalDropDate: undefined,
    finalWithdrawalDate: undefined,
    cohortMilestones: [],
    cohortExamDates: {
      midTerm1StartDate: '',
      midTerm1EndDate: '',
      midTerm2StartDate: '',
      midTerm2EndDate: '',
      finalExamStartDate: '',
      assignmentStartDate: undefined,
      assignmentEndDate: undefined,
      courseEndDate: ''
    },
    officialCourseName: ''
  },
  originalCohortDeadlines: {},
  areAllCoursesProfCert: false,
  activeCourse: undefined,
  studentCourses: [],
  isPITStudent: undefined,
  isActiveGGUStudent: false,
  zoomConsent: false,
  id: undefined,
  upittStudentData: null,
  isProfessionalCertificateCourse: false,
  showProfCertGuardianPermission: false,
  guardianEmail: null,
  textEntryAssignmentState: syncStates.NOT_SAVED,
  fileUploadAssignmentState: syncStates.NOT_SAVED
}
const StudentDataContex = React.createContext(StudentData) // passing initial value
export const useStudentDataContext = () => useContext(StudentDataContex)

export default StudentDataContex
