import React from 'react'
import ConfettiCanvas from 'react-confetti-canvas'
import {
  CONFETTI_CANVAS_COLORS,
  CONFETTI_CANVAS_RIBBON_COUNT,
  CONFETTI_CANVAS_DURATION
} from '../../Constants/index'

function ConfettiCanvasComponent ({
  height,
  colors,
  duration,
  paperCount,
  ribbonCount,
  ribbonParticleCount,
  ribbonParticleThick
}) {
  return (
    <ConfettiCanvas
      height={height}
      duration={duration || CONFETTI_CANVAS_DURATION}
      ribbonCount={ribbonCount || CONFETTI_CANVAS_RIBBON_COUNT}
      colors={colors || CONFETTI_CANVAS_COLORS}
      paperCount={paperCount}
      ribbonParticleCount={ribbonParticleCount}
      ribbonParticleThick={ribbonParticleThick}
    />
  )
}

export default ConfettiCanvasComponent
