import {
  COLLEGE_SUCCESS_SCREEN2_VIEWED,
  COLLEGE_SUCCESS_SCREEN3_VIEWED,
  COLLEGE_SUCCESS_SCREEN4_VIEWED,
  COLLEGE_SUCCESS_GOAL_QUESTION_VIEWED,
  COLLEGE_SUCCESS_ONBOARDING_STARTED
} from './eventTypes'

export const COLLEGE_SUCCESS_WELCOME_PAGE_ID = '7eb08013-e8c3-42e8-9f96-89b58b7f8c48'
const COLLEGE_SUCCESS_SCREEN2_PAGE_ID = '6e8e9879-b984-41bc-8b81-f5d3dc8dee3b'
const COLLEGE_SUCCESS_SCREEN3_PAGE_ID = 'a991f63b-eb20-4e41-8247-0e3e804bf2be'
const COLLEGE_SUCCESS_SCREEN4_PAGE_ID = 'e666d0b4-af8d-45bd-ba7d-12b897452db8'
const COLLEGE_SUCCESS_GOAL_QUESTION_PAGE_ID = 'd546cb0f-d0be-409e-88b1-a242e81fd5cd'

export const pageIdToPageNameMapping = {
  [COLLEGE_SUCCESS_WELCOME_PAGE_ID]: 1,
  [COLLEGE_SUCCESS_SCREEN2_PAGE_ID]: 2,
  [COLLEGE_SUCCESS_SCREEN3_PAGE_ID]: 3,
  [COLLEGE_SUCCESS_SCREEN4_PAGE_ID]: 4,
  [COLLEGE_SUCCESS_GOAL_QUESTION_PAGE_ID]: 5
}

export const pageIdToEventNameMapping = {
  [COLLEGE_SUCCESS_WELCOME_PAGE_ID]: COLLEGE_SUCCESS_ONBOARDING_STARTED,
  [COLLEGE_SUCCESS_SCREEN2_PAGE_ID]: COLLEGE_SUCCESS_SCREEN2_VIEWED,
  [COLLEGE_SUCCESS_SCREEN3_PAGE_ID]: COLLEGE_SUCCESS_SCREEN3_VIEWED,
  [COLLEGE_SUCCESS_SCREEN4_PAGE_ID]: COLLEGE_SUCCESS_SCREEN4_VIEWED,
  [COLLEGE_SUCCESS_GOAL_QUESTION_PAGE_ID]: COLLEGE_SUCCESS_GOAL_QUESTION_VIEWED
}

export const trackAllowedPageIds = [
  COLLEGE_SUCCESS_WELCOME_PAGE_ID,
  COLLEGE_SUCCESS_SCREEN2_PAGE_ID,
  COLLEGE_SUCCESS_SCREEN3_PAGE_ID,
  COLLEGE_SUCCESS_SCREEN4_PAGE_ID,
  COLLEGE_SUCCESS_GOAL_QUESTION_PAGE_ID
]
