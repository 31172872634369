import React, { useContext } from 'react'
import CourseButton from '../CourseButton/CourseButton'
import Context from '../Context/Context'
import CompletedMarker from '../CompletedMarker/CompletedMarker'
import { capitalizeFirstLetter } from '../../utilities'
import { emitter } from '../Emitter/Emitter'
import {
  ON_NAVIGATE_TO
} from '../../Constants/emitterKeys'

export default function SectionHomepageGuessworkButton ({
  exercise,
  isCompleted,
  sectionUUID
}) {
  const { type, question_set_uuid: questionSetUuid } = exercise
  const captilalizedType = capitalizeFirstLetter(type)
  const handleClick = () => {
    emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/${questionSetUuid}`)
    window.outlierLog('Section start', captilalizedType)
  }
  const {
    isCohortEndedForStudent
  } = useContext(Context)

  return isCompleted
    ? <CompletedMarker />
    : (
      <CourseButton
        className={{
          'btn-primary': !isCompleted && !isCohortEndedForStudent,
          'btn-secondary': !!isCompleted || isCohortEndedForStudent
        }}
        onClick={handleClick}
        dataUuid={sectionUUID + '/' + questionSetUuid}
      >
        {isCohortEndedForStudent ? 'View' : 'Start'}
      </CourseButton>
    )
}
