import React, { useRef } from 'react'
import {
  Modal,
  ModalContent,
  ModalCrossIcon,
  ModalCrossIconSpan,
  ModalHeader,
  ModalWrapper
} from './styled'
import { useDetectOutsideRefEvent } from '../Checklist/useDetectOutsideRefEvent'

const ExamListItemModal = ({ showModal, setShowModal, section }) => {
  const modalRef = useRef(null)
  const isReasonOther = section.reason.match(/Other/gi)
  const body = isReasonOther ? section.note : section.reason

  useDetectOutsideRefEvent(modalRef, () => {
    setShowModal()
  })

  if (showModal) {
    return (
      <Modal
        ref={modalRef}
        data-testid='change-reason-modal'
      >
        <ModalHeader>
          Reason
          <ModalCrossIconSpan
            data-testid='change-reason-modal-close'
            onClick={() => {
              setShowModal()
            }}
          >
            <ModalCrossIcon
              alt='icon'
              src='/images/icons/icon-cross-white.svg'
            />
          </ModalCrossIconSpan>
        </ModalHeader>
        <ModalWrapper>
          <ModalContent>{body}</ModalContent>
        </ModalWrapper>
      </Modal>
    )
  }

  return null
}

export default ExamListItemModal
