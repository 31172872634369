import React, { useState, useCallback } from 'react'
import {
  AnnouncementWrapper,
  AnnouncementDate,
  CardContainer,
  Card,
  CardHeader,
  CardTitle,
  CardSeenIcon,
  CardBody,
  Link,
  CollapseButton,
  CardFooter,
  FooterText,
  Dot
} from './styles'

const Announcement = React.memo(({
  announcement = {},
  setSeenAnnouncements,
  selectedAnnouncementId
}) => {
  const { id, title, date, seen, body, link, course, name } = announcement
  const [isOpen, setIsOpen] = useState(false)

  const handleSeenIconClick = useCallback(() => {
    if (seen) return
    setSeenAnnouncements((preVal) => [...preVal, id])
  }, [seen, id, setSeenAnnouncements])

  const handleCollapseButtonClick = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen)
  }, [])

  const getBodyText = useCallback(() => {
    const notificationLength = body?.length
    if (notificationLength <= 283) return body
    return isOpen ? body : `${body?.slice(0, 280)}...`
  }, [isOpen, body])

  return (
    <AnnouncementWrapper id={`announcement-${id}`}>
      <AnnouncementDate
        data-testid='announcement-date'
      >
        {date}
      </AnnouncementDate>
      <CardContainer>
        <Card isHighlightened={id === selectedAnnouncementId}>
          <CardHeader>
            <CardTitle data-testid='card-title'>{title}</CardTitle>
            <CardSeenIcon
              alt='card-seen-icon'
              data-testid='card-seen-icon'
              isSeen={seen}
              onClick={handleSeenIconClick}
            />
          </CardHeader>
          <CardBody data-testid='card-body'>
            {getBodyText()}
            {link && (
              <Link href={link?.href} target='_blank'>
                {link?.text}
              </Link>
            )}
            {body?.length > 283 && (
              <CollapseButton
                data-testid='collapse-button'
                isOpen={isOpen}
                onClick={handleCollapseButtonClick}
              >
                <img alt='caret-icon' />
                {isOpen ? 'Read Less' : 'Read More'}
              </CollapseButton>
            )}
          </CardBody>
          <CardFooter>
            <FooterText data-testid='card-footer-course'>{course}</FooterText>
            <Dot hideOnMobile />
            <FooterText data-testid='card-footer-name'>{name}</FooterText>
          </CardFooter>
        </Card>
      </CardContainer>
    </AnnouncementWrapper>
  )
})

Announcement.displayName = 'Announcement'
export default Announcement
