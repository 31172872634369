import React, { useEffect, useState, useRef, useContext } from 'react'
import Context from '../Context/Context'
import { ASSIGNMENT_PROGRESS } from '../../Constants/studentContext'
import {
  secondsSinceEpoch,
  getTimezoneShort,
  dateToSecondsSinceEpoch,
  weeksToSeconds,
  secondsToFormattedDateTimeShort
} from '../../utilities/dateTimeUtils'
import { emitter } from '../Emitter/Emitter'
import ChapterListProgress from '../ChapterListItem/ChapterListProgress'
import {
  ON_NAVIGATE_TO,
  ON_TRACK_STUDENT_EVENT
} from '../../Constants/emitterKeys'
import { history } from '../HistoryManager/HistoryManager'
import camelCase from 'lodash/camelCase'
import DeadlineWarningComponent
  from '../DeadlineWarningComponent/DeadlineWarningComponent'
import ExamListItemModal from '../ExamListItemModal/ExamListItemModal'
import {
  AssignmentContainer,
  AssignmentDescription,
  AssignmentTitle,
  ChapterAssignmentWrapper,
  ChapterAssignment,
  TealText,
  SupportingText
} from './styled'
import { getAssignmentExtensionLockDates } from '../ExtensionRequestPage/utils'
import { getIsVIPCohort } from '../../utilities/courseUtils'
import { ASSIGNMENT_PATH_NAME } from '../../Constants'
import { CODING_ASSIGNMENT } from '../../Constants/chapterType'
import { WRITING_ASSIGNMENT_STARTED_GGU } from '../../Constants/eventTypes'
import { MULTIPLE } from '../../Constants/frequency'

const AssignmentListItem = ({
  chapter,
  cohortData,
  deadlineData,
  lockReason
}) => {
  const {
    chapter_uuid: assignmentUUID,
    type: chapterType,
    hideFileUploadField,
    hideTextEntryField
  } = chapter
  const pathname = ASSIGNMENT_PATH_NAME[chapterType]
  const currentTime = secondsSinceEpoch()

  let { lockTime, unlockTime } = deadlineData || {}
  lockTime = lockTime && dateToSecondsSinceEpoch(new Date(lockTime))
  unlockTime = unlockTime && dateToSecondsSinceEpoch(new Date(unlockTime))
  const {
    adjustedAssignmentGradeData,
    isAdmin,
    isVIP,
    isVIPGradedContent,
    originalCohortDeadlines,
    studentData: {
      [ASSIGNMENT_PROGRESS]: assignmentProgress
    },
    cohortData: {
      name: cohortName,
      officialCourseName,
      isGGUCohort,
      gguTerm
    }
  } = useContext(Context)
  const { lockDate, originalLockDate } = getAssignmentExtensionLockDates({ assessmentID: assignmentUUID, cohortData, originalCohortDeadlines })
  const isExtendedDeadline = lockDate !== originalLockDate
  const { [assignmentUUID]: { grade: assignmentGrade, status: assignmentStatus
  } = {} } = assignmentProgress || {}

  const [subText, setSubText] = useState(null)
  const [closingTimeText, setClosingTimeText] = useState(null)
  const [isLocked, setIsLocked] = useState(false)
  const [completionPercent, setCompletionPercent] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const unlockAssignment = isAdmin || isVIPGradedContent
  const [navURL, setNavURL] = useState(unlockAssignment
    ? `/${assignmentUUID}/${pathname}` : null)
  const assignmentRef = useRef()
  const isAdminAndVipCohort = isAdmin && getIsVIPCohort(cohortName)
  const showAssignmentStatus = !isAdminAndVipCohort && !isVIP
  const adjustedSection = adjustedAssignmentGradeData?.sort(
    (a, b) => b?.timestamp - a?.timestamp)?.find(chapter =>
      assignmentUUID === chapter.assignmentUuid)

  const isOptionalAssignment = hideFileUploadField && hideTextEntryField

  useEffect(() => {
    const scrollToSection = () => {
      const {
        location: { hash }
      } = history
      const { title } = chapter
      const hasScrollHash = hash.slice(1) === camelCase(title)
      if (hasScrollHash) {
        assignmentRef.current.scrollIntoView({
          behavior: 'smooth'
        })
      }
    }
    scrollToSection()
  }, [chapter])

  useEffect(() => {
    const setSubTextAndLockStatus = () => {
      const formattedLockTime = secondsToFormattedDateTimeShort(
        lockTime,
        'short'
      ).replace(', ', ' at ')
      const formattedUnlockTime = secondsToFormattedDateTimeShort(
        unlockTime,
        'short'
      )
      const timezone = getTimezoneShort(lockTime)
      const hasNotSubmittedAssignment =
        assignmentStatus === 'draft' || !assignmentStatus
      const hasSubmittedAssignment = assignmentStatus === 'submitted'
      const isFutureUnlockTime = currentTime < unlockTime
      const isPastUnlockTime = currentTime > unlockTime
      const isFutureLockTime = currentTime < lockTime
      const isPastLockTime = currentTime > lockTime
      const twoWeeksAfterLockTime = lockTime + weeksToSeconds(2)
      const isWithinTwoWeeksAfterLockTime = isPastLockTime &&
        currentTime < twoWeeksAfterLockTime
      const isCodingAssignment = chapter?.type === CODING_ASSIGNMENT
      const isAssignmentOpen =
        (!unlockTime && isFutureLockTime) ||
        (isPastUnlockTime && isFutureLockTime)
      if (lockReason) {
        return setSubText(lockReason)
      } else if (isFutureUnlockTime) {
        setIsLocked(!unlockAssignment && true)
        unlockAssignment &&
          setCompletionPercent(assignmentGrade ? 100 : null)
        setNavURL(`/${assignmentUUID}/${pathname}`)
        setSubText(
          `Assignment submission will become available on ${formattedUnlockTime} ${timezone}.`
        )
        setClosingTimeText(
          `Closes: ${formattedLockTime} ${timezone}`
        )
      } else if (isWithinTwoWeeksAfterLockTime && isCodingAssignment) {
        setIsLocked(false)
        setCompletionPercent(100)
        setNavURL(`/${assignmentUUID}/${pathname}`)
        setSubText('Score: Pending')
      } else if (isPastLockTime && hasNotSubmittedAssignment) {
        setIsLocked(false)
        setCompletionPercent(100)
        setNavURL(`/${assignmentUUID}/${pathname}`)
        setSubText(`Score: ${assignmentGrade || 0}%`)
      } else if (isPastLockTime && hasSubmittedAssignment) {
        setIsLocked(false)
        setNavURL(`/${assignmentUUID}/${pathname}`)
        setCompletionPercent(100)
        setSubText(
          `Score: ${isNaN(assignmentGrade) ? 'Pending' : assignmentGrade + '%'}`
        )
      } else if (isAssignmentOpen) {
        setIsLocked(false)
        hasSubmittedAssignment && setCompletionPercent(100)
        setNavURL(`/${assignmentUUID}/${pathname}`)
        hasSubmittedAssignment && setSubText(
          `Score: ${isCodingAssignment
            ? 'Pending'
            : isNaN(assignmentGrade) ? 'Pending' : assignmentGrade + '%'}`
        )
        !hasSubmittedAssignment && setClosingTimeText(
          `Closes: ${formattedLockTime} ${timezone}`
        )
      }
    }
    setSubTextAndLockStatus()
    // eslint-disable-next-line
  }, [])

  const handleNavigation = (_restoreButtonState, buttonText) => {
    const isStart = buttonText?.toLowerCase() === 'start'

    if (!navURL) return
    emitter.emit(ON_NAVIGATE_TO, navURL)

    if (isStart && isGGUCohort) {
      const eventData = {
        event: WRITING_ASSIGNMENT_STARTED_GGU,
        properties: {
          course: officialCourseName,
          cohort: cohortName,
          term: gguTerm,
          assignment_name: chapter.title,
          timestamp: Date.now()
        },
        frequency: MULTIPLE
      }

      emitter.emit(ON_TRACK_STUDENT_EVENT, eventData)
    }
  }

  const isFutureLockTime = currentTime < lockTime
  const secondsBeforeLock = lockTime - currentTime
  const showDeadlineWarning =
    secondsBeforeLock > 0 && secondsBeforeLock < 2 * 24 * 60 * 60
  const show24HrDeadlineWarning =
    showDeadlineWarning && secondsBeforeLock < 24 * 60 * 60
  const isFutureUnlockTime = currentTime < unlockTime
  const isDrafted = assignmentStatus === 'draft'

  return (
    <div key={chapter.chapter_uuid} ref={assignmentRef}>
      <ChapterAssignmentWrapper>
        <ChapterAssignment>
          <AssignmentContainer>
            <AssignmentTitle>{chapter.title}</AssignmentTitle>
            <AssignmentDescription
              dangerouslySetInnerHTML={{ __html: chapter.description }} />
            {showAssignmentStatus && subText &&
            <div>
              {(!adjustedSection || subText === 'Score: Pending')
                ? <SupportingText>{subText}</SupportingText>
                : <SupportingText>
                  Adjusted Score:
                  <TealText
                    data-testid='teal-text'
                    onClick={() => setShowModal(!showModal)}
                  >
                    {Math.round(adjustedSection.to)}%
                  </TealText>
                </SupportingText>}
              {adjustedSection && showModal && <ExamListItemModal
                section={adjustedSection}
                showModal={showModal}
                setShowModal={() => setShowModal(!showModal)}
              />}
            </div>}
            {!isOptionalAssignment && showAssignmentStatus && closingTimeText &&
              <SupportingText
                showDeadlineWarning={showDeadlineWarning}
                show24HrDeadlineWarning={show24HrDeadlineWarning}
                isFutureUnlockTime={isFutureUnlockTime}
              >
                {closingTimeText}
              </SupportingText>}
          </AssignmentContainer>
          <ChapterListProgress
            chapter_uuid={chapter.chapter_uuid}
            isDrafted={isDrafted}
            isChapterComplete={completionPercent === 100}
            isPreview={isLocked}
            isAssignment
            isVIP={isVIP}
            navigateToChapter={handleNavigation}
            lockReason={lockReason}
            isFutureLockTime={isFutureLockTime}
          />
          {showAssignmentStatus && showDeadlineWarning && (completionPercent !== 100) &&
            <DeadlineWarningComponent
              assignmentUUID={assignmentUUID}
              isExtendedDeadline={isExtendedDeadline}
              show24HrDeadlineWarning={show24HrDeadlineWarning}
            />}
        </ChapterAssignment>
      </ChapterAssignmentWrapper>
    </div>
  )
}

AssignmentListItem.displayName = 'AssignmentListItem'
export default AssignmentListItem
