import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { emitter } from '../Emitter/Emitter'
import LectureItemTime from '../LectureItemTime/LectureItemTime'
import LectureItemButton from '../LectureItemButton/LectureItemButton'
import { ON_INSTRUCTOR_SWITCH, ON_NAVIGATE_TO } from '../../Constants/emitterKeys'
import {
  VideoWrapper,
  Left,
  Right,
  Counter,
  Content,
  Title,
  SubTitle
} from './styled'
import { getSecondsFromTime } from '../../utilities/dateTimeUtils'
import config from '../../config'
import Context from '../Context/Context'
import { isOrientationChapter } from '../../utilities/chapterUtils'

const VideoItem = props => {
  const {
    index,
    chapterType,
    remainingTime,
    isLocked,
    sectionUUID,
    hasSubsectionDrawer,
    video: {
      id, uuid, displayTitle, duration, description, instructor, optionalVideo,
      isPracticeDrawer
    }
  } = props

  const { updateContext } = useContext(Context)
  const { isCalculusCourse } = config
  const isCalculus = isCalculusCourse()
  const isChapterOrientation = isOrientationChapter({ type: chapterType })
  const showIndex = !(isCalculus || isPracticeDrawer) && !hasSubsectionDrawer

  const handleClick = () => {
    if (isCalculus && !isChapterOrientation) {
      const {
        instructor_uuid: instructorUUID,
        theme_name: themeName
      } = instructor
      updateContext({ currentActiveLearningTheme: themeName })
      localStorage.setItem('last_viewed_theme', themeName)
      emitter.emit(ON_INSTRUCTOR_SWITCH, instructorUUID)
      emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/${props?.questionSetUUID}`)
    } else {
      const videoId = id || uuid
      emitter.emit(ON_NAVIGATE_TO, `/${sectionUUID}/${videoId}`)
    }
  }

  const durationInSecond = getSecondsFromTime(duration)
  return (
    <VideoWrapper isLocked={isLocked}>
      <Left className='d-flex'>
        {showIndex && <Counter>{index + 1}.</Counter>}
        <Content>
          <Title>{displayTitle}</Title>
          <LectureItemTime
            duration={durationInSecond}
            remainingTime={remainingTime}
            optionalVideo={optionalVideo}
          />
          {description && <SubTitle>{description}</SubTitle>}
        </Content>
      </Left>
      <Right>
        <LectureItemButton
          isLocked={isLocked}
          handleClick={handleClick}
          duration={durationInSecond}
          remainingTime={remainingTime}
        />
      </Right>
    </VideoWrapper>
  )
}

VideoItem.propTypes = {
  index: PropTypes.number.isRequired,
  remainingTime: PropTypes.number,
  sectionUUID: PropTypes.string.isRequired,
  video: PropTypes.shape({
    displayTitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired
  }),
  isLocked: PropTypes.bool.isRequired
}

export default VideoItem
