import React from 'react'

function ListView ({ progressBubbleButtons }) {
  return (
    <>
      {progressBubbleButtons}
    </>
  )
}

export default ListView
