import React, { useContext, useEffect, useState, useCallback } from 'react'
import {
  RequiredToolsHeader,
  RequiredToolsSection,
  RequiredToolsSectionItemContainer,
  RequiredToolsSectionHeader,
  RequiredToolsSectionDescription,
  RequiredToolsSectionLink
} from './styled'
import Context from '../../Context/Context'
import {
  getYellowdigUrl,
  getValidDiscussionLinkType,
  isHighSchoolCourse
} from '../../../utilities/courseUtils'

const content = (isHighSchoolPartnerCourse) => [
  {
    header: 'Wifi Connection',
    description: 'Exams require a steady internet speed with a download and upload speed of at least 2 Mbps.',
    link: 'https://www.speedtest.net/',
    linkDescription: 'Test Internet Speed'
  },
  {
    header: 'Proctorio',
    description: isHighSchoolPartnerCourse
      ? 'For remote students, a laptop or desktop computer with a webcam and microphone is required for taking exams.'
      : 'A laptop or desktop computer with a webcam and microphone is required for taking exams.',
    link: 'https://proctorio.com/system-requirements',
    linkDescription: 'View Requirements'
  },
  {
    header: 'Google Chrome',
    description: 'This powerful browser, with pop-up blocker enabled, will give you the best experience.',
    link: 'https://www.google.com/chrome/',
    linkDescription: 'Get Google Chrome'
  }
]

function RequiredToolsSectionItem ({ header, description, link, linkDescription }) {
  return (
    <RequiredToolsSectionItemContainer>
      <RequiredToolsSectionHeader>
        {header}
      </RequiredToolsSectionHeader>
      <RequiredToolsSectionDescription>
        {description}
      </RequiredToolsSectionDescription>
      <RequiredToolsSectionLink href={link}>
        {linkDescription}
      </RequiredToolsSectionLink>
    </RequiredToolsSectionItemContainer>
  )
}

export default function RequiredTools() {
  const {
    studentCourses,
    cohortData,
    activeCourse
  } = useContext(Context)

  const isHighSchoolPartnerCourse = isHighSchoolCourse(activeCourse)
  const contentToDisplay = content(isHighSchoolPartnerCourse)

  const [tools, setTools] = useState(contentToDisplay)

  const configureDiscussionLinks = useCallback(async () => {
    const validDiscussionLinkType = getValidDiscussionLinkType(studentCourses)
    const yellowdigLaunchUrl = await getYellowdigUrl(cohortData)

    if (!isHighSchoolPartnerCourse && !yellowdigLaunchUrl) return

    const discussion = {
      header: 'Discussion Community',
      description: isHighSchoolPartnerCourse
        ? 'This communication platform is where course discussions will take place for participation credit.'
        : 'This communication platform is where course discussions and support will take place.',
      linkDescription: 'Connect to Discussion Community',
      link: yellowdigLaunchUrl
    }

    if (isHighSchoolPartnerCourse && !validDiscussionLinkType) {
      delete discussion.link
      delete discussion.linkDescription
    }

    setTools(prevTools => {
      if (prevTools.some(tool => tool.header === 'Discussion Community')) {
        return prevTools
      }
      return [...prevTools, discussion]
    })
  }, [studentCourses, cohortData, isHighSchoolPartnerCourse])

  useEffect(() => {
    if (!studentCourses?.length) return
    configureDiscussionLinks()
  }, [studentCourses, isHighSchoolPartnerCourse, configureDiscussionLinks])

  useEffect(() => {
    setTools(content(isHighSchoolPartnerCourse))
  }, [isHighSchoolPartnerCourse])

  return (
    <div>
      <RequiredToolsHeader>Required Tools</RequiredToolsHeader>
      <RequiredToolsSection>
        {tools.map(item => (
          <RequiredToolsSectionItem key={`${item.header}-${item.linkDescription}`} {...item} />
        ))}
      </RequiredToolsSection>
    </div>
  )
}
