export const CS_GRADING_CRITERIA = {
  quizzes: 35,
  midterms: 20,
  finalExams: 25,
  capstone: 15,
  participation: 5,
  midtermCount: 2,
  capstoneCount: 3
}

export const CWII_GRADING_CRITERIA = {
  quizzes: 8,
  preWritingAssignments: 22,
  apexAssignments: 50,
  scaffoldingAssignments: 15,
  participation: 5
}

export const PROFCOMM_GRADING_CRITERIA = {
  quizzes: 7,
  unit1Assignments: 23,
  unit2Assignments: 23,
  unit3Assignments: 32,
  participation: 15
}
