import isEqual from 'lodash/isEqual'
import convert from 'xml-js'
import { getCorrectAnswer, executeService } from '../FreeformEquationQuestion/utils/wiris'
import { stripAnnotationTag } from
  '../FreeformEquationQuestion/utils/helpers'
import config from '../../config'

const cache = {}

export const asyncCheckStudentAnswer = async ({
  questionConfiguration, studentAnswer, additionalConfigurations = []
}) => {
  const configurations = getQuestionConfigurations(
    questionConfiguration, additionalConfigurations
  )
  const key = `${configurations.join('&')}&studentAnswer=${studentAnswer}`

  if (typeof cache[key] === 'boolean') {
    return cache[key]
  }

  const correctAnswer = process.env.NODE_ENV === 'test'
    ? getCorrectAnswerTestEnv(questionConfiguration)
    : getCorrectAnswer()

  if (correctAnswer.indexOf('<mtext>') > 0 || studentAnswer.indexOf('<mtext>') > 0 ||
    studentAnswer.indexOf('<math') < 0) {
    const d1 = document.createElement('div')
    const d2 = document.createElement('div')
    d1.innerHTML = cleanupMathMLForText(correctAnswer)
    d2.innerHTML = cleanupMathMLForText(studentAnswer)
    if (d1.innerText === d2.innerText) return true
  }

  const results = await Promise.all(
    configurations.map(
      configuration => checkStudentAnswer(configuration, studentAnswer)
    )
  )

  cache[key] = results.includes(true)
  return cache[key]
}

export const checkStudentAnswer = async (configuration, studentAnswer) => {
  const builder = window.com.wiris.quizzes.api.QuizzesBuilder.getInstance()
  const question = builder.readQuestion(configuration)
  const instance = builder.newQuestionInstance(question)

  const uibilder = builder.getQuizzesUIBuilder()
  const authoringField = uibilder.newAuthoringField(question, null, 0)
  authoringField.setFieldType('studio')
  authoringField.showVariablesTab(true)
  authoringField.showGradingFunction(true)

  const correctAnswer = process.env.NODE_ENV === 'test'
    ? getCorrectAnswerTestEnv(configuration)
    : authoringField.value
  const MATHML = 'MathML'
  const endIndex = correctAnswer?.indexOf(MATHML) + MATHML.length
  const lowerCorrectAnswer = config.isStatisticsCourse ? correctAnswer
    : correctAnswer?.slice(0, endIndex) + correctAnswer?.slice(endIndex)?.toLowerCase()
  const lowerStudentAnswer = config.isStatisticsCourse ? studentAnswer
    : studentAnswer?.slice(0, endIndex) + studentAnswer?.slice(endIndex)?.toLowerCase()
  question.setCorrectAnswer(0, lowerCorrectAnswer)
  instance.setStudentAnswer(0, lowerStudentAnswer)

  const service = builder.getQuizzesService()
  const request = builder.newFeedbackRequest('', question, instance)
  const response = await executeService(service, request)
  instance.update(response)

  return instance.getAnswerGrade(0, 0, question) >= 0.99
}

export const isEmptyOrWhiteSpace = s => !s || /^\s+$/.test(s) ||
  /^\s*<math[^>]*\/>\s*$/.test(s) ||
  /^\s*<math[^>]*>(<mo>(&#(xA0)|(160);)+<\/mo>)*<\/math>\s*$/.test(s)

const inlineEditor = '<data name="inputField">inlineEditor</data>'
const popupEditor = '<data name="inputField">popupEditor</data>'
const textField = '<data name="inputField">textField</data>'
export const isInlineEditor = q => q && q.indexOf(inlineEditor) >= 0
export const isPopupEditor = q => q && q.indexOf(popupEditor) >= 0
export const isTextField = q => q && q.indexOf(textField) >= 0

// This util was built when Wiris changed their api
// After they fixed the issue, we still want to keep this util as a reference
export const cleanupMathMLForChecking = a => {
  if (a.indexOf('<math') < 0) {
    // Wrap non MathML answer
    a = `<math xmlns="http://www.w3.org/1998/Math/MathML"><mtext>${a}</mtext></math>`
  }
  a = a
    // Replace hex to dec
    // .replace(/&#x[^;]+;/g, m => '&#' +
    //   hex2dec.hexToDec(m.replace('&#x', '').replace(';', '')) + ';')
    // Split special characters
    .replace(/<mo>([^<&]+)&#/g, '<mo>$1</mo><mo>&#')
    // Remove spaces
    .replace(/<mo>&#160;<\/mo>/g, '')
    // Replace mfenced
    .replace(/<mo>\(<\/mo>/g, '<mfenced>')
    .replace(/<mo>\)<\/mo>/g, '</mfenced>')
    // Remove invisible time character
    .replace(/<mo>&#8290;<\/mo>/g, '')
    // Remove No-break space character
    .replace(/<mo>&#xA0;<\/mo>/g, '')
    // Remove math variant
    .replace(/\s*mathvariant="[^"]+"/g, '')
    // Remove mrow outside of mfrac
    .replace(/(<math[^>]+>)(<mrow>)+/g, '$1')
    .replace(/(<\/mrow>)+(<\/math>)/g, '$2')
    .replace(/(<mrow>)+<mfrac>/g, '<mfrac>')
    .replace(/<\/mfrac>(<\/mrow>)+/g, '</mfrac>')
  if (a.indexOf('<mfrac>') < 0) {
    a = a.replace(/<mrow>/g, '').replace(/<\/mrow>/g, '')
  }
  return a
}

export const cleanupMathMLForText = a =>
  a.replace(/<mfenced>/g, '<mo>(</mo>').replace(/<\/mfenced>/g, '<mo>)</mo>') // Replace mfenced

export const cleanupPopup = v =>
  v.replace(/<semantics><mrow><\/mrow><annotation encoding="text\/plain"><\/annotation><\/semantics>/g, '')

export const cleanupPopupForDisplaying = v =>
  v?.replace(/<mspace\s*linebreak="newline"\s*\/>/g, `<mspace width="20px"/></math><math xmlns="http://www.w3.org/1998/Math/MathML">`)

export const injectDOM = dom => {
  const div = document.createElement('div')
  div.style.display = 'none'
  div.appendChild(dom)
  document.body.appendChild(div)
  return () => document.body.removeChild(div)
}

export const getCorrectAnswerTestEnv = questionConfiguration => {
  const m = /<!\[CDATA\[(.+?)]]>/.exec(questionConfiguration)

  if (m) return m[1]

  const [, answer = ''] = /<correctAnswer>(.*)<\/correctAnswer>/
    .exec(questionConfiguration) || []

  return answer.trim()
}

export const replaceEntities = (text) => {
  if (!text) return text
  if (isEmptyMath(text)) return ''

  const matchedTags = text.match(/<math/g)
  const isMultiplePopEditorAnswers = matchedTags ? matchedTags.length > 1 : false
  text = isMultiplePopEditorAnswers ? `<root>${text}</root>` : text
  return parseXMLToString(text)
}

export const isEmptyMath = text => {
  return /^<math\s*\/>$/.test(text)
}

/**
 * @description This function parses an xml string and encodes
 * special characters to valid xml values. For example, '<' symbol
 * in annotation tag is modified to '&lt;'
 */
export const parseXMLToString = text => {
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(text, 'text/xml')
  if (!xmlDoc || !xmlDoc.childNodes || !xmlDoc.childNodes.length) return text
  const isError = !xmlDoc.childNodes[0].outerHTML ||
    xmlDoc.childNodes[0].outerHTML.includes('parsererror')
  return isError ? stripAnnotationTag(text)
    : xmlDoc.childNodes[0].outerHTML
}

export const getHighlightedAnswer = (solutionData, isExamOrQuizReviewMode) => {
  const {
    studentAnswer, correctAnswer, studentAnswerClass
  } = solutionData
  const isIncorrectClass = studentAnswerClass &&
    studentAnswerClass?.includes('incorrect')

  if (!(isIncorrectClass && studentAnswer &&
    isExamOrQuizReviewMode)) {
    return studentAnswer
  }
  // Check if the answer is not math equation.
  if (
    isIncorrectClass &&
    isExamOrQuizReviewMode &&
    (studentAnswer.search(/<math/g) === -1 || correctAnswer.search(/<math/g) === -1)
  ) {
    return `<mtext class="error-text">${studentAnswer}</mtext>`
  }

  const formattedStudentAnswer = replaceEntities(studentAnswer)
  const formattedCorrectAnswer = replaceEntities(correctAnswer)

  const hasEmptyAnswer = !formattedStudentAnswer || !formattedCorrectAnswer
  if (hasEmptyAnswer) return ''

  return mapAnswer(formattedStudentAnswer, formattedCorrectAnswer)
}

export const mapAnswer = (formattedStudentAnswer, formattedCorrectAnswer) => {
  const studentAnswerAst = parseXml(formattedStudentAnswer)
  const correctAnswerAst = parseXml(formattedCorrectAnswer)

  if (!studentAnswerAst || !correctAnswerAst) { return '' }
  // when the given answer is shorter than correct answer
  if (studentAnswerAst?.elements?.length < correctAnswerAst?.elements?.length) {
    studentAnswerAst.attributes = { ...studentAnswerAst?.attributes, class: 'error-text' }
    return stringifyXml(studentAnswerAst)
  }

  if (!correctAnswerAst.elements || !studentAnswerAst.elements) {
    studentAnswerAst.attributes.class = 'error-text'
    return stringifyXml(studentAnswerAst)
  }

  studentAnswerAst.elements = studentAnswerAst.elements.map((studentAnsChild, index) => {
    const correctAnsChild = correctAnswerAst?.elements[index]
    return mapChildren(studentAnsChild, correctAnsChild)
  })
  // convert to html string again.
  return stringifyXml(studentAnswerAst)
}

export const mapChildren = (studentAnsChild, correctAnsChild = {}) => {
  if (studentAnsChild?.elements?.length === 1 && studentAnsChild.elements[0].type === 'text') {
    const isMatched = isContentMatched(studentAnsChild, correctAnsChild)
    isMatched
      ? studentAnsChild.attributes = { class: 'match-text' }
      : studentAnsChild.attributes = { class: 'error-text' }
    return studentAnsChild
  }

  if (studentAnsChild?.elements?.length >= 1) {
    if (studentAnsChild.name !== correctAnsChild.name) {
      studentAnsChild.attributes = { ...studentAnsChild?.attributes, class: 'error-text' }
    }

    if (studentAnsChild.name === 'mfenced' && correctAnsChild.name === 'mfenced') {
      const isSameAttrinbute = matchAttributes(studentAnsChild.attributes, correctAnsChild.attributes)
      if (!isSameAttrinbute) {
        studentAnsChild.attributes = { ...studentAnsChild?.attributes, class: 'error-text' }
      }
    }

    studentAnsChild.elements = studentAnsChild.elements.map((studentElem, index) => {
      const correctElem = correctAnsChild?.elements && correctAnsChild?.elements[index]
      return mapChildren(studentElem, correctElem)
    })
  }
  return studentAnsChild
}

export const isContentMatched = (studentElement, correctElement = {}) => {
  const studentElem = studentElement.elements[0]
  const correctElem = correctElement.elements && correctElement.elements[0]
  if (studentElem?.text === correctElem?.text &&
    studentElement?.name === correctElement?.name
  ) {
    return true
  }
  return false
}

export const matchAttributes = (studentAnsAttributes = {}, correctAnsAttributes = {}) => {
  return isEqual(studentAnsAttributes, correctAnsAttributes)
}

export const stringifyXml = (studentAns) => {
  return convert.js2xml({ elements: [studentAns] })
}

export const parseXml = (formattedAnswer) => {
  try {
    const parsed = convert.xml2js(formattedAnswer)
    if (parsed?.elements && parsed?.elements[0]) {
      return parsed.elements[0]
    }
    return parsed
  } catch (e) {
    console.log('Error in xml2js conversion: ', e)
    return null
  }
}

/**
 * Returns the max length of characters from the answers
 * @param {string} generalAnswer student or correct answer which consists of 2 answers
 * eg. h(x)=1 D=5 first answer is h(x)=1, and second answer is D=5.
 */
export const getMultipleAnswerMaxLength = generalAnswer => {
  if (!generalAnswer) return 0
  const mathRegex = /<math xmlns="http:\/\/www\.w3\.org\/1998\/Math\/MathML">((.|\n)*?)<\/math>/g
  const [firstAnswer = '', secondAnswer = ''] = (generalAnswer.match(mathRegex) || [])
    .map((e) => e.replace(mathRegex, '$1').trim())

  return Math.max(getMathMLLength(firstAnswer), getMathMLLength(secondAnswer))
}

/**
 * It's is not a general function, it only counts identifiers, operators and numbers.
 * @param {string} mathml MathML
 * @returns {number} sum of length of identifier, operator and number characters.
 */
export const getMathMLLength = (mathml) => {
  mathml = cleanupMathMLForText(mathml)
  const identifierLength = getTagContentLength('mi', mathml)
  const operatorLength = getTagContentLength('mo', mathml)
  const numberLength = getTagContentLength('mn', mathml)

  return identifierLength + operatorLength + numberLength
}

/**
 * @param {string} tag tag name
 * @param {string} mathml MathML
 * @returns {number} total number of tag content in a MathML.
 */
export const getTagContentLength = (tag, mathml) => {
  // change space hex to  ' '
  mathml = mathml.replace(/<mo>&#xA0;<\/mo>/g, '<mo> </mo>')
  const tagRegex = new RegExp(`<${tag}>((.|\n)*?)</${tag}>`, 'g')
  return (mathml.match(tagRegex) || [])
    .map((e) => e.replace(tagRegex, '$1').length)
    .reduce((a, b) => a + b, 0)
}

export function getQuestionConfigurations (
  questionConfiguration, additionalConfigurations = []
) {
  const additionalConfigs = additionalConfigurations
    .map(config => config?.configuration)

  const configurations = [questionConfiguration, ...additionalConfigs]
    .filter(config => config !== undefined)

  return configurations
}
