import { emitter } from '../Components/Emitter/Emitter'
import { ON_NAVIGATE_TO, ON_PRACTICE_EXERCISES_COMPLETE, ON_STUDENT_ANSWER, ON_SUBMIT_ANSWER,
  ON_SECTION_DATA_PROGRESS, ON_SECTION_PROGRESS,
  ON_TRACK_STUDENT_EVENT,
  ON_MINIMUM_SECTION_PROGRESS
} from '../Constants/emitterKeys'
import { generateQuestionRandomSeed, generateRandomSeedWithExclusion } from
  '../Components/FreeformEquationQuestion/utils/helpers'
import { PROBLEM_BANK, ACTIVITY_TYPES } from '../Constants/sectionType'
import config from '../config'
import {
  addGeneralExplanationToEachQuestion
} from '../Components/NavigationComponent/Utils/loadSection'
import { INCORRECT, CORRECT } from '../Constants/result'
import { COURSE_STARTED } from '../Constants/eventTypes'
import { ONCE } from '../Constants/frequency'
import { removeCodeFromLocalStorage } from './jdoodleUtils'
import { loadSectionData } from './sectionUtils'

export {
  getProblemSetQuestions,
  saveQuestionSetResult,
  getIfChallengeQuestion,
  markSectionAsComplete,
  areAllSiblingsCompleted,
  isQuestionSetCompleted,
  endPractice,
  getProblemSetInfo,
  getProblemSetIndex,
  getSectionIndex,
  getCurrentProblemSetResponse,
  getQuestionRandomSeed,
  getSeedByQuestionId,
  getFirstSectionPopQuestion,
  getOtherSectionsPopQuestion,
  getQuestionIndex,
  getQuestionByUUID,
  getQuestion,
  getNumberOfQuestions
}

let popQuestionForNextSet = null
let forceQuestionForNextSet = null
let numbersToExclude = []

/**
 * @param {Context} context - student data context
 * @param {Object} questionSetData - object containing entire problem bank for the section
 * and current problem set UUID
 * @param {Object} sectionData - object containing all sections of the course
 * and current section UUID
 * @returns {Array} - generated array of questions to be used as current problem set
 * @description - this function selects 4 questions from a random position in the current
 * problem set; gets a pop question from the previous section and inserts it in the
 * middle of the generated problem set for practice exercises with randomization enabled and
 * if randomization is disabled pop questions are positioned at the end of the problem set.
 * Then it updates the context with an initial response for the problem set. It holds the
 * properties questionSetUUID, hasPopupQuestionAttempted, popResult, questionRandomSeed...
 */
async function getProblemSetQuestions (context, questionSetData, sectionData) {
  const {
    updateContext,
    studentData,
    isInitialProgress,
    cohortData: { officialCourseName, name: cohort, duration }
  } = context
  const {
    problemBank,
    currentProblemSetUUID,
    forcePopQuestionUUID,
    forceQuestionUUID = forceQuestionForNextSet } = questionSetData
  const currentProblemSet = problemBank.find(problemSet =>
    problemSet.question_set_uuid === currentProblemSetUUID)
  if (!currentProblemSet) return
  updateContext({ isQuestionSetLoading: true })
  const {
    question_set_uuid: questionSetUUID,
    Question: questions,
    randomizePracticeQuestions } = currentProblemSet
  const currentProblemSetResponse = getCurrentProblemSetResponse(studentData?.studentAnswers,
    questionSetUUID)
  let numberOfQuestions = getNumberOfQuestions(questions)
  if (!randomizePracticeQuestions && questions?.length <= 9) {
    numberOfQuestions = (questions[0] && parseInt(questions[0].practice_tries)) ?? 4
  }

  const {
    trials,
    questionRandomSeed: savedQuestionRandomSeed,
    popQuestionRandomSeed, hasPopupQuestionAttempted
  } = currentProblemSetResponse || {}

  const questionRandomSeed = (forceQuestionUUID && !trials)
    ? getSeedByQuestionId(currentProblemSetResponse,
      { questions, forceQuestionUUID })
    : trials
      ? savedQuestionRandomSeed
      : randomizePracticeQuestions
        ? getQuestionRandomSeed(currentProblemSetResponse, questions)
        : 0
  let selectedQuestions = questions.slice(questionRandomSeed,
    questionRandomSeed + numberOfQuestions)
  if (selectedQuestions.length < numberOfQuestions) {
    selectedQuestions.push(...questions.slice(
      0, numberOfQuestions - selectedQuestions.length))
  }

  let popQuestionIndex

  if (config.course.allowPopQuestions) {
    const {
      popQuestion,
      popIndex = popQuestionRandomSeed
    } = popQuestionForNextSet || await getPopQuestion(
      { currentProblemSet,
        problemBank,
        forcePopQuestionUUID,
        currentProblemSetResponse
      }, sectionData) || {}

    const trialsLength = trials?.length || 0

    forceNextQuestion({ selectedQuestions, forceQuestionUUID, trialsLength })

    popQuestionForNextSet = forcePopQuestionUUID &&
    hasPopupQuestionAttempted && trialsLength >= 2
      ? { popQuestion, popIndex } : null

    // postion pop questions at the end if randomize practice questions
    // is disabled and position at the middle if enabled
    let popPosition = numberOfQuestions
    if (randomizePracticeQuestions) {
      popPosition = Math.floor(numberOfQuestions / 2)
    }
    popQuestion && selectedQuestions.splice(popPosition, 0, { ...popQuestion, isPopQuestion: true })
    popQuestionIndex = popIndex
  }

  selectedQuestions = selectedQuestions.map((question, index) => {
    question.questionSetUUID = currentProblemSetUUID
    question.problemSetTitle = currentProblemSet.title // added to show correct breadcrumb for pop question
    question.sectionUUID = sectionData?.activeSectionUUID
    return question
  })
  if (currentProblemSetResponse && !currentProblemSetResponse.trials) {
    delete currentProblemSetResponse.hasPopupQuestionAttempted
    delete currentProblemSetResponse.popResult
  }
  if (isInitialProgress) {
    const eventData = {
      properties: {
        course: officialCourseName,
        cohort_length: duration,
        cohort,
        time_stamp: new Date().getTime()
      },
      event: COURSE_STARTED,
      frequency: ONCE
    }

    emitter.emit(ON_TRACK_STUDENT_EVENT, eventData)
    updateContext({
      isInitialProgress: false
    })
  }
  updateContextData(updateContext, studentData, { questionRandomSeed,
    popQuestionRandomSeed: popQuestionIndex,
    questionSetUUID,
    currentProblemSetResponse,
    randomizePracticeQuestions,
    currentProblemSet: selectedQuestions,
    isQuestionSetLoading: false })

  selectedQuestions?.length && selectedQuestions.forEach(question => {
    removeCodeFromLocalStorage(question?.['Question_uuid'])
  })
  return selectedQuestions
}

function forceNextQuestion ({
  selectedQuestions, forceQuestionUUID, trialsLength
}) {
  if (forceQuestionForNextSet || !forceQuestionUUID) {
    forceQuestionForNextSet = null
    return
  }

  const forceQuestionIndex = selectedQuestions
    .findIndex(question => question.Question_uuid === forceQuestionUUID)

  forceQuestionForNextSet = forceQuestionIndex === -1 || trialsLength > forceQuestionIndex
    ? forceQuestionUUID : null
}

function getSeedByQuestionId (studentAnswer, questionSetData) {
  const {
    questions,
    forceQuestionUUID } = questionSetData

  const questionIndex = questions.findIndex(question => {
    return question.Question_uuid === forceQuestionUUID
  })

  if (questionIndex === -1) {
    return getQuestionRandomSeed(studentAnswer, questions)
  }

  const offset = studentAnswer && studentAnswer.trials
    ? studentAnswer.trials.length : 0

  const seed = questionIndex - offset
  return seed > 0 ? seed : (questions.length + seed)
}

function getCurrentProblemSetResponse (studentAnswers, questionUUID) {
  return studentAnswers.find(
    answer => answer.uuid === questionUUID
  )
}

function getNumberOfQuestions (questions) {
  if (questions?.length <= 9) {
    const hasPopQuestion = questions.some(question => !!question.isPopQuestion)
    return hasPopQuestion ? questions.length - 1 : questions.length
  }

  return questions[0] && parseInt(questions[0].practice_tries)
}

/**
 * @param {Object} currentProblemSetResponse - problem set response holding questionSetUUID,
 * hasPopupQuestionAttempted, popResult, questionRandomSeed, isPracticeCompleted
 * @param {Array} questions
 * @returns {Number} random number
 * @description
 * 1. If currentProblemSetResponse does not have a questionRandomSeed value,
 * generate a new random seed.
 * 2. Else, generate a random seed which is exclusive of the questions
 * in the previous 2 problem sets, and also 2 questions before the
 * first question of each set
 */
function getQuestionRandomSeed (currentProblemSetResponse, questions) {
  if (!questions || !questions.length) return 0

  const numberOfQuestions = getNumberOfQuestions(questions)
  const questionsCount = questions.length
  const max = questionsCount - 1
  if (!currentProblemSetResponse) {
    numbersToExclude = []
    return generateQuestionRandomSeed(max, 0)
  }

  let questionIndex = (questionsCount +
    currentProblemSetResponse.questionRandomSeed - 2) % questionsCount
  const isTwoRoundsCompleted = numbersToExclude.length >= (numberOfQuestions * 2) + 4

  if (isTwoRoundsCompleted) {
    numbersToExclude.splice(0, numberOfQuestions + 2)
  }
  numbersToExclude.push(...(
    new Array(numberOfQuestions + 2)
      .fill(0)
      .map(() => questionIndex++ % questionsCount))
  )

  const allIndecesExcluded = questions.every((q, index) =>
    numbersToExclude.includes(index)
  )
  if (allIndecesExcluded) {
    numbersToExclude.splice(0, numberOfQuestions + 2)
  }

  return generateRandomSeedWithExclusion(max, 0, numbersToExclude)
}

/**
 * @param {Object} questionSetData - object containing entire problem bank for the section and
 * current problem set UUID
 * @param {Object} sectionData - object containing all sections of the course and current
 * section UUID
 * @returns {Question Object}
 * @description - For the first section of a course, there is no previous section to select
 * pop questions. So, first problem set of first section has no pop question. Subsequent problem
 * sets of the first section choose one question from previous problem set as pop question.
 * Problem sets in subsequent sections choose pop question from corresponding problem sets of
 * previous section
 */
async function getPopQuestion (questionSetData, sectionData) {
  const {
    currentProblemSet,
    problemBank,
    forcePopQuestionUUID,
    currentProblemSetResponse
  } = questionSetData
  const { courseSections, activeSectionUUID } = sectionData
  const currentSectionIndex = getSectionIndex(courseSections, activeSectionUUID)
  const { question_set_uuid: currentProblemSetUUID } = currentProblemSet || {}
  const currentProblemSetIndex = getProblemSetIndex(problemBank, currentProblemSetUUID)
  const isFirstSection = currentSectionIndex === 0
  if (isFirstSection && currentProblemSetIndex <= 0) return null

  if (isFirstSection) {
    return getFirstSectionPopQuestion({
      problemBank,
      currentProblemSetIndex,
      forcePopQuestionUUID,
      currentProblemSetResponse
    })
  }

  return getOtherSectionsPopQuestion(
    { courseSections, currentSectionIndex },
    {
      currentProblemSetIndex,
      forcePopQuestionUUID,
      currentProblemSetResponse
    })
}

function getSectionIndex (courseSections, activeSectionUUID) {
  return courseSections && courseSections.indexOf(activeSectionUUID)
}

function getProblemSetIndex (problemBank, currentProblemSetUUID) {
  const currentProblemSetUUIDs = problemBank && problemBank.map(problemSet => problemSet.question_set_uuid)
  const currentProblemSetIndex = currentProblemSetUUIDs &&
    currentProblemSetUUIDs.indexOf(currentProblemSetUUID)
  return currentProblemSetIndex
}

/**
 * @description - select a random question from previous problem set as pop question
 */
function getFirstSectionPopQuestion ({
  problemBank,
  currentProblemSetIndex,
  forcePopQuestionUUID,
  currentProblemSetResponse
}) {
  const problemSet = problemBank[currentProblemSetIndex - 1]

  const question = getQuestion({
    currentProblemSetResponse,
    forcePopQuestionUUID,
    problemSet
  })

  if (question) return question

  const problemSetLength = problemSet && problemSet.Question && problemSet.Question.length
  const popIndex = generateQuestionRandomSeed(problemSetLength, 0)
  return problemSet && {
    popQuestion: problemSet.Question[popIndex],
    popIndex
  }
}

/**
 * @param {Array} courseSections - array of all the section UUIDs of a course
 * @param {Number} currentSectionIndex
 * @param {Number} currentProblemSetIndex
 * @param {Question Object}
 * @description - fetch json data of previous section from Dato; select a random question
 * as pop question from the corresponding problem set of the previous section's problem bank
 */
async function getOtherSectionsPopQuestion (
  { courseSections, currentSectionIndex },
  {
    currentProblemSetIndex,
    forcePopQuestionUUID,
    currentProblemSetResponse
  }) {
  const previousSectionUUID = courseSections[currentSectionIndex - 1]
  const previousSectionData = await loadSectionData({
    activeCourseUUID: config.courseId, uuid: previousSectionUUID
  })
  if (!previousSectionData) return null
  const { section_exe: {
    practice_exercises: previousSectionProblemBank,
    practiceDrawer
  } = {} } = previousSectionData
  const problemSetsFromPracticeDrawer = !previousSectionProblemBank &&
    practiceDrawer?.filter(content => {
      return content.type === PROBLEM_BANK
    })
  const problemSetForPopQuestion = getProblemSetForPopQuestion(
    currentProblemSetIndex, previousSectionProblemBank || problemSetsFromPracticeDrawer)
  if (!problemSetForPopQuestion) return null

  const question = getQuestion({
    currentProblemSetResponse,
    forcePopQuestionUUID,
    problemSet: problemSetForPopQuestion
  })

  if (question) return question

  const popIndex = generateQuestionRandomSeed(problemSetForPopQuestion.length, 0)
  return { popQuestion: problemSetForPopQuestion[popIndex], popIndex }
}

function getQuestionByUUID (questionSet, questionUUID) {
  if (!questionUUID) return
  const question = questionSet.find(problem =>
    problem.Question_uuid === questionUUID)
  const questionIndex = questionSet.findIndex(problem =>
    problem.Question_uuid === questionUUID)

  return { question, questionIndex }
}

function getProblemSetForPopQuestion (currentProblemSetIndex, previousSectionProblemBank = []) {
  if (!previousSectionProblemBank?.length) return
  const problemSet = previousSectionProblemBank[currentProblemSetIndex] ||
    previousSectionProblemBank[previousSectionProblemBank.length - 1]
  if (!problemSet) return
  const {
    Question: questions,
    question,
    general_explanation: generalExplanation
  } = problemSet
  addGeneralExplanationToEachQuestion(questions || question, generalExplanation)
  return questions || question
}

/**
 * @param {Function} updateContext
 * @param {Object} studentData - student data object from student data context which contains
 * student answers for problem sets
 * @param {Object} questionSetData - problem set related fields
 * @description - updates student context with latest problem set array and its initial response
 * If response is already present, its random seed value is updated
 */
function updateContextData (updateContext, studentData, questionSetData) {
  const {
    popQuestionRandomSeed,
    questionRandomSeed,
    questionSetUUID,
    currentProblemSetResponse,
    currentProblemSet,
    isQuestionSetLoading,
    randomizePracticeQuestions
  } = questionSetData
  const { studentAnswers } = studentData
  let studentAnswer = {}
  if (currentProblemSetResponse) {
    const contextProblemSetIndex = studentAnswers.indexOf(currentProblemSetResponse)
    if (contextProblemSetIndex > -1) {
      studentAnswers[contextProblemSetIndex].questionRandomSeed = questionRandomSeed
      studentAnswers[contextProblemSetIndex].popQuestionRandomSeed = popQuestionRandomSeed
      studentAnswers[contextProblemSetIndex].randomizePracticeQuestions = randomizePracticeQuestions
      studentAnswer = studentAnswers[contextProblemSetIndex]
    }
  } else {
    studentAnswer = { uuid: questionSetUUID,
      hasPopupQuestionAttempted: false,
      popResult: false,
      popQuestionRandomSeed,
      questionRandomSeed,
      randomizePracticeQuestions }
    studentAnswers.push(studentAnswer)
  }

  updateContext({
    currentProblemSet,
    currentProblemSetResponse: studentAnswer,
    studentData,
    isQuestionSetLoading })
}

function getQuestionIndex (currentQuestionSet, questionUUID) {
  const questionUUIDs = currentQuestionSet.map(question => question.Question_uuid)
  return questionUUIDs.indexOf(questionUUID)
}

/**
 * @param {Object} result - problem set details and current result details
 * @param {Context} context - student data context
 * @description - save response for current problem set; if challenge question, check if
 * sibling problem sets are complete and mark practice exercises as complete for this section
 */
function saveQuestionSetResult (result, context) {
  const { questionUUID, currentQuestionSet, problemBank, activeSectionUUID,
    questionSetUUID, questionResult, answer, isAnswerRevealed } = result
  const { studentData: { studentAnswers } = {} } = context
  const currentProblemSetResponse = getCurrentProblemSetResponse(studentAnswers, questionSetUUID)
  const { isPracticeCompleted: savedPracticeCompleted, trials: studentAnswerTrials,
    hasPopupQuestionAttempted: savedHasPopupQuestionAttempted,
    popResult: savedPopResult, questionRandomSeed, popResponse: savedPopResponse,
    popQuestionRandomSeed,
    randomizePracticeQuestions
  } = currentProblemSetResponse || {}
  const { updateContext, currentProblemSetResponse: contextProblemSetResponse } = context
  const { trials: contextTrials, popResult: contextPopResult,
    hasPopupQuestionAttempted: contextHasPopupQuestionAttempted,
    questionRandomSeed: contextQuestionRandomSeed,
    popQuestionRandomSeed: contextPopQuestionRandomSeed,
    popResponse: contextPopResponse,
    randomizePracticeQuestions: contextRandomizePracticeExercises
  } = contextProblemSetResponse
  const withoutPopQuestionSet = currentQuestionSet.filter(question => !question.isPopQuestion)
  const trials = getTrials({ savedPracticeCompleted, contextTrials, studentAnswerTrials },
    { currentQuestionSet, questionUUID, withoutPopQuestionSet })
  const currentQuestion = currentQuestionSet.find(question => question.Question_uuid === questionUUID)
  const currentQuestionIndex = getQuestionIndex(withoutPopQuestionSet, questionUUID)
  const isPopQuestion = currentQuestion && currentQuestion.isPopQuestion
  const {
    totalScore, totalWeight, compilationScore, submissionId, autotestId, runId
  } = result
  const codingQuestionTrial = submissionId
    ? { totalScore, totalWeight, compilationScore, submissionId, autotestId, runId }
    : null
  if (!isPopQuestion) {
    trials[currentQuestionIndex] = {
      ...(codingQuestionTrial ? { ...codingQuestionTrial } : {}),
      result: questionResult ? CORRECT : INCORRECT,
      isAnswerRevealed,
      answer
    }
  }

  const popTrial = {
    ...(codingQuestionTrial ? { ...codingQuestionTrial } : {}),
    result: !!questionResult,
    isAnswerRevealed,
    answer
  }
  const hasPopupQuestionAttempted = isPopQuestion ? true : savedPracticeCompleted
    ? contextHasPopupQuestionAttempted : savedHasPopupQuestionAttempted
  const popResult = isPopQuestion ? questionResult : savedPracticeCompleted
    ? contextPopResult : savedPopResult
  const isProblemSetAttempted = trials.length === withoutPopQuestionSet.length
  const popResponse = isPopQuestion ? popTrial : (savedPracticeCompleted ||
   isProblemSetAttempted) ? contextPopResponse : savedPopResponse
  const {
    answer: challengeAnswer,
    result: challengeResult
  } = trials[trials.length - 1]
  const otherQuestionResult = {
    uuid: questionSetUUID,
    hasPopupQuestionAttempted,
    isPracticeCompleted: savedPracticeCompleted,
    popResult,
    trials,
    popResponse,
    popQuestionRandomSeed: popQuestionRandomSeed || contextPopQuestionRandomSeed,
    questionRandomSeed: questionRandomSeed || contextQuestionRandomSeed
  }
  const isPracticeCompleted =
    questionResult || savedPracticeCompleted || !contextRandomizePracticeExercises
  const challengeQuestionResult = {
    uuid: questionSetUUID,
    answer: challengeAnswer,
    correct: challengeResult === CORRECT,
    isPracticeCompleted,
    ...!isPracticeCompleted && { hasPopupQuestionAttempted, popResult },
    questionRandomSeed }

  const isChallengeQuestion = getIfChallengeQuestion(questionUUID, currentQuestionSet)
  // set practice exercises as complete if randomization is disabled even if
  // the questions are not correctly answered
  const isChallengeSuccessful =
    isChallengeQuestion &&
    (questionResult || !contextRandomizePracticeExercises)
  const problemSetResult = isChallengeQuestion || savedPracticeCompleted ||
   isProblemSetAttempted ? challengeQuestionResult : otherQuestionResult
  updateContext({
    currentProblemSetResponse: {
      ...otherQuestionResult,
      randomizePracticeQuestions:
        randomizePracticeQuestions || contextRandomizePracticeExercises
    } })
  const resultWithType = {
    ...problemSetResult,
    type: ACTIVITY_TYPES[PROBLEM_BANK]
  }
  emitter.emit(ON_STUDENT_ANSWER, resultWithType)
  // As per calculus practice exercises logic, ON_STUDENT_ANSWER triggers ON_SUBMIT_ANSWER
  // if problemSetResult has trials; check studentAnswer() in NavigationComponent.
  // Hence emit ON_SUBMIT_ANSWER only if trials is not available
  !problemSetResult.trials && emitter.emit(ON_SUBMIT_ANSWER, resultWithType)
  if (!isChallengeSuccessful) return
  markSectionAsComplete(activeSectionUUID, { questionSets: problemBank,
    currentSetUUID: questionSetUUID,
    studentAnswers })
}

/**
 * @param {Object} trialProperties
 * @param {Object} questionProperties
 * @returns {Array}
 * @description - If problem set is already completed, the response stored in studentAnswers object
 * does not have trials array; so we have to use the trials array from currentProblemSetResponse
 * in student context. Skip trials array manipulation if current question is pop question;
 * for other questions, if same question is attempted again pop previous trials value
 */
function getTrials (trialProperties, questionProperties) {
  const { savedPracticeCompleted, contextTrials, studentAnswerTrials } = trialProperties
  const { currentQuestionSet, questionUUID, withoutPopQuestionSet } = questionProperties
  const isChallengeQuestion = getIfChallengeQuestion(questionUUID, currentQuestionSet)
  const savedTrials = isChallengeQuestion || savedPracticeCompleted ||
    contextTrials?.length === withoutPopQuestionSet.length
    ? contextTrials : studentAnswerTrials
  return savedTrials || []
}

function getIfChallengeQuestion (currentQuestionUUID, currentQuestionSet) {
  let isChallengeQuestion = false
  currentQuestionSet.forEach((question, index) => {
    const { Question_uuid: questionUUID } = question
    isChallengeQuestion = currentQuestionUUID === questionUUID && index === currentQuestionSet.length - 1
  })
  return isChallengeQuestion
}

function markSectionAsComplete (activeSectionUUID, data) {
  if (!areAllSiblingsCompleted(data)) return
  emitter.emit(
    ON_SECTION_DATA_PROGRESS,
    {
      key: ON_PRACTICE_EXERCISES_COMPLETE,
      sectionUUID: activeSectionUUID,
      value: true
    }
  )
  emitter.emit(ON_SECTION_PROGRESS, activeSectionUUID)
  emitter.emit(ON_MINIMUM_SECTION_PROGRESS, activeSectionUUID)
}

function areAllSiblingsCompleted (data) {
  const { questionSets, currentSetUUID, studentAnswers } = data
  const sibilingProblemSets = questionSets.filter(questionSet => {
    const { question_set_uuid: questionSetUUID } = questionSet
    return questionSetUUID !== currentSetUUID
  })
  return sibilingProblemSets.every(questionSet =>
    isQuestionSetCompleted(questionSet.question_set_uuid, studentAnswers))
}

function isQuestionSetCompleted (questionSetUUID, studentAnswers) {
  return !!(studentAnswers.some(answer => answer.uuid ===
    questionSetUUID && answer.isPracticeCompleted))
}

function getQuestion ({
  currentProblemSetResponse,
  forcePopQuestionUUID,
  problemSet
}) {
  const questionProblemSet = problemSet.Question
    ? problemSet.Question : problemSet
  const popQuestionIndex = currentProblemSetResponse?.popQuestionRandomSeed
  const {
    question,
    questionIndex
  } = forcePopQuestionUUID
    ? getQuestionByUUID(questionProblemSet, forcePopQuestionUUID)
    : popQuestionIndex ? { question: questionProblemSet[popQuestionIndex] } : {}

  if (!question) return null

  return { popQuestion: question, popIndex: questionIndex }
}

function endPractice (activeSectionUUID) {
  emitter.emit(ON_NAVIGATE_TO, '/' + activeSectionUUID)
}

function getProblemSetInfo (problemBank, problemSetUUID) {
  if (!problemBank?.length || !problemSetUUID) return

  const title = problemBank.find(problem => {
    return problem.question_set_uuid === problemSetUUID
  })?.title

  const splittedTitle = title?.split(': ')
  if (!splittedTitle?.length) return

  return {
    set: splittedTitle[0],
    title: splittedTitle[1]
  }
}
