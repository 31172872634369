import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Toast from '../../Toast'
import { STUDY_BLOCKS_URL } from '../../../Constants'
import { PrimaryButton } from '../../GlobalInstructorSwitch/style'
import {
  getActiveCourses, getLatestCohort
} from '../../../utilities/courseUtils'
import {
  getLatestEnrolledCourse, getLatestScheduledCourse, getRecommendedHours
} from '../../../utilities/studyBlocks'
import { getStartEndDateUSFormat } from '../../../utilities/dateTimeUtils'

function AdjustStudyBlocks ({ studentCourses, metadata }) {
  const [latestCourses, setLatestCourses] = useState({ scheduled: {}, enrolled: {} })
  const [show, setShow] = useState(false)
  const remove = () => setShow(false)

  const onAdjustClick = () => {
    window.location.href = STUDY_BLOCKS_URL
  }

  useEffect(() => {
    const activeCourses = getActiveCourses(studentCourses)
    const latestStudyBlocksUpdate = metadata?.updatedAt || metadata?.createdAt
    const latestEnrolledCourse = getLatestEnrolledCourse(
      activeCourses, latestStudyBlocksUpdate)
    const latestScheduledCourse = getLatestScheduledCourse(
      activeCourses, latestStudyBlocksUpdate)

    setShow(!!latestEnrolledCourse && !!latestScheduledCourse)
    setLatestCourses({
      scheduled: latestScheduledCourse, enrolled: latestEnrolledCourse
    })
  }, [studentCourses, metadata])

  const title = 'Overlapping course schedules'
  const toastWrapperStyle = { margin: '92px 64px 0', width: '448px' }
  const getBody = () => {
    const { scheduled = {}, enrolled = {} } = latestCourses
    const { name: scheduledName = '' } = scheduled
    const { name: enrolledName = '' } = enrolled
    const scheduledEndTime = getLatestCohort(scheduled)?.cohortEndTime
    const enrolledStartTime = getLatestCohort(enrolled)?.dateStart
    const overlapTime = getStartEndDateUSFormat(enrolledStartTime, scheduledEndTime)
    const newRecommendedHours = getRecommendedHours(getActiveCourses(studentCourses))

    return (
      <>
        <div>
          <p style={{ marginBottom: '6px' }}>
            Hey there! Your new course {enrolledName} overlaps with courses you’ve
            already scheduled: {scheduledName}.
          </p>
          <p>
            The overlap runs between {overlapTime},
            making your new commitment {newRecommendedHours}hrs/wk.
          </p>
        </div>
        <PrimaryButton
          height='fit-content'
          mr='0'
          onClick={onAdjustClick}
        >
          adjust study blocks
        </PrimaryButton>
      </>
    )
  }

  return (
    <Toast
      hasBreak
      show={show}
      remove={remove}
      title={title}
      getBody={getBody}
      toastWrapperStyle={toastWrapperStyle}
    />
  )
}

AdjustStudyBlocks.propTypes = {
  studentCourses: PropTypes.arrayOf(PropTypes.object).isRequired,
  metadata: PropTypes.object.isRequired
}

AdjustStudyBlocks.displayName = 'AdjustStudyBlocks'
export default AdjustStudyBlocks
