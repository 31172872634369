import React, { Component } from 'react'
import Desmos from '../QuestionComponent/js/desmos'
import withStudentContext from '../../Hoc/withStudentContext'
import { GraphNote } from './styled'
import { CARD } from '../../Constants/questionType'

class DesmosgraphComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.calculator = null
  }

  componentDidMount () {
    if (this.props.expression) {
      this.calculator = Desmos(JSON.parse(this.props.expression))
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.expression !== this.props.expression) {
      this.destroyDesmos()
      document.getElementById('calculator').innerHTML = ''
      this.calculator = Desmos(JSON.parse(this.props.expression))
    }
  }

  componentWillUnmount () {
    this.destroyDesmos()
  }

  destroyDesmos () {
    if (!this.calculator) return
    this.calculator.destroy()
  }

  render () {
    const {
      questionType,
      illustration,
      studentData: { isAssessmentQuizExam },
      noteMargin
    } = this.props
    const fulWidthDesmos = questionType === CARD || !!illustration

    return (
      <React.Fragment>
        <div className={`col-12${fulWidthDesmos ? '' : ' col-md-6'} mb-5`}>
          <div id='calculator' style={{ height: '330px', width: '100%' }} />
          <GraphNote
            isAssessmentQuizExam={isAssessmentQuizExam}
            noteMargin={noteMargin}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default withStudentContext(DesmosgraphComponent)
