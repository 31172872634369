import React, { Component } from 'react'
import ProblemSetListItem from '../ProblemSetListItem/ProblemSetListItem'

class ProblemSetList extends Component {
  render () {
    const { questionList } = this.props
    if (questionList) {
      var questionListarr = Array.from(Object.keys(questionList), k => questionList[k])
      return questionListarr.map((question, index) => (
        <ProblemSetListItem question={question} key={index} {...this.props} />
      ))
    } else {
      return (<div>Loading...</div>)
    }
  }
}

export default ProblemSetList
