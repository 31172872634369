import { PRACTICE_EXERCISES } from '../../../Constants/sectionType'

// Keep a reference to the calculator so we can destroy it from other util methods.
let calculator = null
// The edited question text.
var questionText
// Question object for the question definition.
var questionObject
// QuestionInstance for the question attempt.
var instanceObject
// Authoring field Wiris Quizzes web component.
export var authoringField
// Answer field Wiris Quizzes web component.
export var answerField

export const display = async (content, onEditorReady) => {
  // Get Wiris Quizzes builders.
  if (!window.com) return
  var builder = window.com.wiris.quizzes.api.QuizzesBuilder.getInstance()
  var uibuilder = builder.getQuizzesUIBuilder()

  // Load question configuration
  questionObject = builder.readQuestion(content.question)
  // Build authoring web component. It will update questionObject.
  authoringField = uibuilder.newAuthoringField(questionObject, null, 0)
  // Configure authoring field to be a Wiris Quizzes Studio.
  authoringField.setFieldType('studio')
  authoringField.showVariablesTab(true)
  authoringField.showGradingFunction(true)
  // Insert into page.
  var authoringContainer = document.getElementById('correctAnswer')

  if (authoringContainer) {
    authoringContainer.replaceChild(
      authoringField.getElement(),
      authoringContainer.firstChild
    )
  }
  // Set feedback text
  window.feedbacktext = content.feedback
  var retQuestion = ''
  retQuestion = await deliver(content, onEditorReady)
  return retQuestion
}

export const extractVariables = response => {
  const rawVariables = response.results[0].variables
  const vars = {}
  for (const v of rawVariables) {
    vars[v.name] = parseFloat(v.content)
  }
  return vars
}

export const expressionMapper = variables => {
  return template => {
    let expression = template
    const keys = Object.keys(variables)
    for (const key of keys) {
      const name = '#' + key
      expression = expression.split(name).join(variables[key])
    }
    return expression
  }
}

export const destroyDesmos = () => {
  // Clean up existing calculator instance
  if (!calculator) return
  calculator.destroy()
  calculator = null
}

export const initializeDesmos = (response, content) => {
  var config = JSON.parse(content.desmosConfig)
  var variables = extractVariables(response)
  var expressions = content.expressionTemplates.map(expressionMapper(variables))

  var count = config.state.expressions.list.length
  if (expressions.length < count) count = expressions.length
  for (let i = 0; i < count; i++) {
    config.state.expressions.list[i].latex = expressions[i]
  }
  destroyDesmos()
  if (document.getElementById('calculator')) {
    document.getElementById('calculator').innerHTML = ''
  }
  desmos(config)
}

export const desmos = expr => {
  var elt = document.getElementById('calculator')

  if (elt) {
    calculator = window.Desmos.GraphingCalculator(elt)

    calculator.updateSettings(expr.settings)
    calculator.setState(expr.state)
    calculator.updateSettings({
      settingsMenu: false,
      zoomButtons: false,
      keypad: false,
      expressions: false,
      backgroundColor: '#4f515a'
    })
  }
}

export const executeService = async (quizzesService, request) => {
  let errorMessage
  // Suggestion by Wiris, implement a call retry when WirisQuizzes throws a
  // runtime error and avoid losing some answers.
  const callRetryTimes = 3
  for (let i = 1; i <= callRetryTimes; i++) {
    try {
      return await executeQuizzesService(quizzesService, request)
    } catch (error) {
      errorMessage = error
      console.error(
        `Error in WirisQuizzes executeService, failed retrying ${i} times: `,
        errorMessage)
    }
  }
  return errorMessage
}

const executeQuizzesService = (quizzesService, request) => {
  return new Promise(function (resolve, reject) {
    console.info('wiris request', JSON.stringify(request || ''))
    try {
      quizzesService.executeAsync(request, {
        onResponse: function (res) {
          console.info('wiris response', JSON.stringify(res || ''))
          resolve(res)
        }
      })
    } catch (e) {
      reject(e)
    }
  })
}

export const deliver = async (content, onEditorReady) => {
  if (!window.com) return
  const tabdelivery = document.getElementById('tabdelivery')
  if (tabdelivery && tabdelivery.className !== 'tabactive') {
    // Save question text.
    questionText = content.text

    // Change tab from authoring to delivery.
    document.getElementById('authoring').style.display = 'none'
    document.getElementById('tabauthoring').className = ''
    const delivery = document.getElementById('delivery')
    if (delivery) {
      delivery.style.display = 'block'
    }
    document.getElementById('tabdelivery').className = 'tabactive'
    document.getElementById('tabindicator').className = 'deliveryindicator'

    // Get Wiris Quizzes builders.
    var builder = window.com.wiris.quizzes.api.QuizzesBuilder.getInstance()
    var uibuilder = builder.getQuizzesUIBuilder()

    // Create QuestionInstance for this question attempt.
    instanceObject = builder.newQuestionInstance(questionObject)
    // Set the platform user identifier -here "123"
    // as example- to allow the use of user_id parameter in algorithm.
    instanceObject.setParameter(
      window.com.wiris.quizzes.api.QuizzesConstants.PARAMETER_USER_ID,
      '123'
    )

    /** append questionRandomSeed to
     * the instance object to generate the same variables
     * DOCS: http://www.wiris.com/quizzes/docs/generic/run/js/level3.html
     */
    if (content.questionRandomSeed) {
      instanceObject.userData.randomSeed = content.questionRandomSeed
    }
    // Get the value of variables using the Wiris Quizzes service.
    var request = builder.newVariablesRequest(
      questionText + authoringField.getValue(),
      questionObject,
      instanceObject
    )
    var service = builder.getQuizzesService()
    const response = await executeService(service, request)
    // Store variables into QuestionInstance object.
    if (content.desmosConfig && content.type === PRACTICE_EXERCISES) {
      initializeDesmos(response, content)
    }

    instanceObject.update(response)

    // Build student's answer field. We need to rebuild it every time because
    // it depends on question configuration.
    answerField = uibuilder.newAnswerField(questionObject, instanceObject, 0)

    // Synchronize the answer field with the integration
    // interface using a QuizzesFieldListener.
    // This is useful when the field is a MathType,
    // since there is a time lapse between the
    // user handwriting and when the value is ready.
    answerField.addQuizzesFieldListener({
      contentChangeStarted: function () {
        document.getElementById('test').disabled = false // set to false from true
      }
    })

    // If we need to get the MathType interface
    // http://www.wiris.net/demo/formula/docs/api/index.html?com/wiris/formula/editor/EditorInterface.html
    // for a more complex integration of MathType we must use the
    // asynchronous version of the function.
    // answerField.getEditorAsync({onGetEditor: function(editor) {
    // Use MathType
    // }});
    // Put the answer field into the document.
    // Wait for the Wiris inline editor to be ready and run the provided
    // callback.
    // See http://www.wiris.com/quizzes/demo/generic/doc/com/wiris/quizzes/api/ui/AnswerField.html
    if (typeof onEditorReady === 'function') {
      const quizConsts = window.com.wiris.quizzes.api.QuizzesConstants
      const isMathEditor =
        answerField.getFieldType() ===
        quizConsts.ANSWER_FIELD_TYPE_INLINE_EDITOR
      if (isMathEditor) {
        answerField.getEditorAsync({
          onGetEditor: editor => {
            onEditorReady(editor)
          }
        })
        // If it is not an inline math editor, we don't need to wait.
      } else {
        onEditorReady(null)
      }
    }

    var answerContainer = document.getElementById('studentAnswer')

    if (answerContainer) {
      answerContainer.replaceChild(
        answerField.getElement(),
        answerContainer.firstChild
      )
    }
  }
  if (content.gievnAnswer) {
    setTimeout(() => {
      answerField.setValue(content.gievnAnswer)
    }, 50)
  }

  // Return dynamic question to save purpose
  if (questionText) {
    return instanceObject.expandVariables(questionText)
  } else {
    return 'noQuestion'
  }
}

export const back = () => {
  if (document.getElementById('tabauthoring').className !== 'tabactive') {
    // Change tab from delivery to authoring.
    const elStudentAnswer = document.getElementById('studentAnswer')
    document.getElementById('delivery').style.display = 'none'
    document.getElementById('tabdelivery').className = ''
    document.getElementById('authoring').style.display = 'block'
    document.getElementById('tabauthoring').className = 'tabactive'
    document.getElementById('tabindicator').className = 'authoringindicator'
    // Clear deliver page.
    document.getElementById('feedback').innerHTML = 'Click to check the answer.'
    document.getElementById('answerfeedback').innerHTML = '<span></span>'
    if (elStudentAnswer) elStudentAnswer.innerHTML = '<span></span>'
    document.getElementById('customfeedback').innerHTML = '<span></span>'
  }
}

// Because the answerField is a variable and can be changed when call display
//   we need to get it dynamically to make sure it's the current field
export const getAnswerField = () => answerField
export const getCorrectAnswer = isPracticeExecise =>
  isPracticeExecise ? instanceObject?.expandVariablesMathML(authoringField?.getValue())
    : authoringField?.getValue()
