import React from 'react'
import PropTypes from 'prop-types'

const CompletionPage = props => {
  const {
    imagePath,
    content,
    footer
  } = props

  return (
    <section className='section guess-work quiz-page'>
      <div className='guess__work-main'>
        <div className='guess__work-main-mid'>
          <div className='container-fluid'>
            <div className='guess__work-block mb-1'>
              <div
                width='441'
                height='213'
                style={{ position: 'relative', textAlign: 'center' }}
              >
                <img
                  className='img-fluid'
                  src={imagePath}
                  alt='Interstitial Intro'
                />
              </div>
              <div className='component__backdrop backdrop__bottom-rounded'>
                <div data-testid='completion-content'>{content}</div>
                <div className='text-center'>
                  <div data-testid='completion-footer'>{footer}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

CompletionPage.propTypes = {
  imagePath: PropTypes.string,
  content: PropTypes.element,
  footer: PropTypes.element
}

CompletionPage.displayName = 'CompletionPage'

export default CompletionPage
