import React, { useState } from 'react'
import {
  LinksSectionItemContainer,
  LinkTitle,
  LinkText,
  Caret
} from '../styled'
import Loader from '../../../Loader/Loader'
import { StyledLinkItemWrapper } from './LinkItem.styled'

const LinksSectionItem = ({ item }) => {
  const [isLoading, setIsLoading] = useState(false)

  if (!item) return null

  const { title, text, link, onClick } = item

  const handleClick = async (e) => {
    e.preventDefault()

    if (link) return window.open(link.url, link.target || '_self')

    setIsLoading(true)
    await onClick()
    setIsLoading(false)
  }

  return (
    <StyledLinkItemWrapper>
      <LinksSectionItemContainer key={title} onClick={handleClick}>
        <div>
          <LinkTitle>
            {title}
          </LinkTitle>
          <LinkText>
            {text}
          </LinkText>
        </div>
        <Caret />
      </LinksSectionItemContainer>
      {isLoading && <Loader />}
    </StyledLinkItemWrapper>
  )
}

export default LinksSectionItem
