import styled from 'styled-components'
import media, { BREAKPOINTS } from '../../../mediaQueries'

export const MainWrapperAL = styled.div`
  margin-top: 40px;
  margin-bottom: 30px;
  position: relative;

  ${({ isSidePanelOpen }) => isSidePanelOpen && 'width: calc(100% - 264px);'}
`

export const MainWrapperPractice = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
`

export const AssessmentTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  align-items: center;

  & > span:nth-child(2) {
    padding-bottom: 8px;
    color: #98A4AE;
  }
`

export const ContainerPractice = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%!important;
  .active__learning-options{
    display: grid;
  }
  #multiple_radio_auto{
    width: 100%;
  }

  ${media.tablet`
    width: 562px !important;
  `}

  @media (max-width: ${BREAKPOINTS.mobile - 1}px) {
    width: '100% !important;
  }
`

export const ContainerAL = styled.div`
  margin-left: auto;
  margin-right: auto;
  ${({ isAssessmentQuizExam }) =>
    isAssessmentQuizExam ? 'width: 500px'
      : 'width: 560px'
};
  .active__learning-options{
    display: grid;
  }
  #multiple_radio_auto{
    width: 100%;
  }

  @media (max-width: ${BREAKPOINTS.desktop - 1}px) {
    ${({ isSidePanelOpen }) => isSidePanelOpen &&
      'width: min(100% - 96px) !important;'}
  }

  ${media.tablet` width: ${({ isAssessmentQuizExam, isSidePanelOpen }) => {
    if (isAssessmentQuizExam) return '500px;'
    if (isSidePanelOpen) return 'min(100% - 96px) !important;'
    return '528px !important;'
  }}
  `}
  
  @media (max-width: ${BREAKPOINTS.mobile - 1}px) {
    width: 327px !important;
  }
`
