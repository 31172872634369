import styled from 'styled-components'
import { PageTitle } from '../../styles/text'

export const Container = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  @media (min-width: 576px)
   {
    max-width: unset;
  }
`

export const SectionContainer = styled.div`
  width: 715px;
  margin: auto;

  @media (max-width: 1199px) {
    width: 715px !important;
  }

  @media (max-width: 991px) {
    width: 562px !important;
  }

  @media (max-width: 767px) {
    width: 552px !important;
  }

  @media (max-width: 575px) {
    width: 100% !important;
  }
`

export const WarningContainer = styled.div`
  display: flex;
  margin: 36px 0 26px;
`

export const WarningImgContainer = styled.img`
  margin-top: 2px;
  margin-bottom: auto;
`

export const WarningTextContainer = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin-left: 8px;
`
export const SectionTitle = styled(PageTitle)`
  text-transform: none;
  margin: 0;
`
