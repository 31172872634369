import React, { Component } from 'react'
import HeaderComponent from '../StudentHeader/StudentHeaderComponent'
import FooterComponent from '../StudentFooter/StudentFooterComponent'
import MobileNotificationModal
  from '../MobileNotificationModal/MobileNotificationModal'
import { getMobileDevice } from '../../utilities/mobileDeviceUtils'
import LastQuestionModal from '../LastQuestionModal'
import ExtensionToast from '../ExtensionToast/ExtensionToast'
import { MainWrapper } from './styles'
import config from '../../config'
import { getQueryParameters } from '../../utilities'
import { isGuardianPermissionPath } from '../GuardianPermission/GuardianPermission.utils'

class PageLayout extends Component {
  componentDidMount () {
    const queryParams = getQueryParameters()
    if (queryParams?.hideHeaderFooter !== 'true') return

    const root = document.querySelector('#root')
    if (!root) return
    root.style.minHeight = 'auto'
  }

  render () {
    const {
      activePath,
      context,
      children
    } = this.props
    const isMobileNotificationEnabled = getMobileDevice()
    const isGuardianPermissionForm = isGuardianPermissionPath(activePath)
    const isZoomConsentForm = activePath === '/zoom-consent'
    const isJDoodlePage = activePath === '/java-compiler'
    const queryParams = getQueryParameters()
    const hideHeaderFooter = queryParams?.hideHeaderFooter === 'true'
    const isLesson0Flag = config.isLesson0Flag()

    const showFullPage = isZoomConsentForm

    return (
      <React.Fragment>
        {(!isLesson0Flag || !hideHeaderFooter) && !isJDoodlePage && <HeaderComponent activePath={activePath} />}
        {isMobileNotificationEnabled && <MobileNotificationModal />}
        <MainWrapper isFullPage={showFullPage} className='Page-layout-main-wrapper'>
          {React.cloneElement(children,
            {
              isJDoodlePage,
              activePath,
              context
            })
          }
          <LastQuestionModal props={{ ...this.props }} />
          <ExtensionToast />
        </MainWrapper>
        {!isGuardianPermissionForm && !isJDoodlePage && (
          <FooterComponent
            context={context}
            activePath={activePath}
            onlyConvertflow={isLesson0Flag && hideHeaderFooter}
          />
        )}
      </React.Fragment>
    )
  }
}

PageLayout.displayName = 'PageLayout'
export default PageLayout
