import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Collapse } from 'reactstrap'
import Context from '../Context/Context'
import {
  DrawerWrapper,
  SubsectionDrawerItem,
  TagNameText,
  ToggleIcon
} from './styled'
import MultipleVideos from '../LecturesExerciseItem/MultipleVideos'
import PracticeExerciseItemText from '../PracticeExerciseItem/PracticeExerciseItemText'
import { LECTURE } from '../../Constants/sectionType'
import { LECTURE_COMPLETE } from '../../Constants/studentContext'
import { capitalizeFirstLetter } from '../../utilities'
import config from '../../config'

const SubsectionDrawer = (props) => {
  const [numberOfCompletedActivities, setNumberOfCompletedActivities] = useState(-1)
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  const {
    sectionUUID,
    isLastItem,
    lockReason,
    excercise
  } = props

  const {
    isCohortEndedForStudent,
    studentData
  } = useContext(Context)

  const lectureComplete = studentData[LECTURE_COMPLETE]

  const handleToggle = () => {
    const { tag } = excercise
    const { set } = config.sectionItemAccordionState(tag, sectionUUID)
    set(!isAccordionOpen)
    setIsAccordionOpen(!isAccordionOpen)
  }

  const isActivityCompleted = useCallback((activity) => {
    const { type } = activity

    if (type === LECTURE) {
      const kalturaEmbedCode = activity?.multipleVideos?.[0]?.kalturaEmbedCode
      return !!lectureComplete[kalturaEmbedCode]
    } else {
      return false
    }
  }, [lectureComplete])

  const getNumberOfCompletedActivities = useCallback(() => {
    const { activities } = excercise

    return activities.filter(activity => isActivityCompleted(activity)).length
  }, [excercise, isActivityCompleted])

  useEffect(() => {
    const completedActivitiesCount = getNumberOfCompletedActivities()
    setNumberOfCompletedActivities(completedActivitiesCount)
  }, [getNumberOfCompletedActivities])

  useEffect(() => {
    const { activities, tag } = excercise

    const { get } = config.sectionItemAccordionState(tag, sectionUUID)
    const storedState = get()
    if (storedState) return setIsAccordionOpen(storedState === 'true')

    // if all activities completed, collapse the accordion.
    const isAllCompleted = activities.length === numberOfCompletedActivities
    setIsAccordionOpen(!isAllCompleted)
  }, [excercise, numberOfCompletedActivities, sectionUUID])

  const { tag, activities } = excercise
  const numberOfActivities = activities.length
  const isLocked = !!lockReason

  return (
    <>
      <SubsectionDrawerItem
        isLastItem={isLastItem}
        tabIndex='0'
        onClick={handleToggle}
      >
        <TagNameText>
          <h3 className='tag-name'>
            {capitalizeFirstLetter(tag)}
          </h3>
          <PracticeExerciseItemText
            numberOfCompleted={numberOfCompletedActivities}
            numberOfActivities={numberOfActivities}
          />
        </TagNameText>
        <ToggleIcon
          isAccordionOpen={isAccordionOpen}
          src={'images/icons/icon-angle-arrow-light.svg'}
          isLocked={isLocked}
        />
      </SubsectionDrawerItem>
      <Collapse isOpen={isAccordionOpen}>
        <DrawerWrapper
          data-testid='activity-items'
          minHeight={activities.length * 90}
        >
          {
            activities.map(activity => {
              const { type } = activity
              const isLecture = type === LECTURE

              if (isLecture) {
                return <MultipleVideos
                  isLocked={isLocked}
                  isCohortEnded={isCohortEndedForStudent}
                  isCompleted={isActivityCompleted(activity)}
                  sectionUUID={sectionUUID}
                  hasSubsectionDrawer
                  {...activity}
                />
              } else {
                return null
              }
            })
          }
        </DrawerWrapper>
      </Collapse>
    </>
  )
}

export default SubsectionDrawer
