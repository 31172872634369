import React, { Component } from 'react'
import md5 from 'md5'
import filter from 'lodash/filter'

import { emitter } from '../Emitter/Emitter'
import { history } from '../HistoryManager/HistoryManager'
import api from '../../api'
import { Auth0Context } from '../Auth0Provider/Auth0Provider'

import {
  getFinalExamDates,
  getChapterExamDates,
  practiceExamReleased as practiceExamReleasedFunction,
  isOrientationChapter,
  getVersionQuestions,
  isPracticeExam,
  isReviewChapter,
  getChapterLockDates
} from '../../utilities/chapterUtils'
import {
  getQuestionType
} from '../../utilities/questions'
import { isExamRetakeType, allowLiveProctoring, shouldByPassExamKeysPage, getExamKeyFieldNames } from '../../utilities/examUtils'
import {
  copyStudentProgress,
  getStudentProgressDiff,
  getNPSEvent,
  hasCourseExam,
  isGGUCourse,
  randomizeMultipleChoices
} from '../../utilities/courseUtils'
import {
  secondsSinceEpoch, secondsToFormattedDateShort
} from '../../utilities/dateTimeUtils'
import {
  getCourseSections,
  getSectionType,
  getSectionGuessworkUUID,
  getNumberOfSectionTypes,
  isContentGatingUnlocked,
  getTotalQuestionSetForSectionProgress,
  getRubric,
  loadSectionData,
  getSectionProgressCompleteKeys
} from '../../utilities/sectionUtils'

import {
  isGuessworkCompleteForSection
  , hasCourseProgress } from '../../utilities/studentProgressUtils'
import shouldUnlockQuiz from '../../utilities/quizLockLogic'

import BreadCrumbComponent from '../BreadCrumbComponent/BreadCrumbComponent'
import NoCourseComponent from '../NoCourseComponent/NoCourseComponent'
import LoadingSpinner from '../LoadingSpinner/LoadingAnimation'
import {
  saveSectionProgress
} from '../../utilities/studentProgress'
import {
  addExamResourcesInQuestions,
  loadEachSection,
  trimQuestionsAnswers
} from './Utils/loadSection'
import {
  extractUUIDs,
  isInvalidParentUUID,
  isInvalidExtraUUID,
  isProblemBankQuestion,
  isInvalidChildUUID
} from './Utils/uuidUtils'

import {
  EXAM_COMPLETE,
  EXAM_SECTION,
  EXAM_PERCENTAGE,
  QUIZ_SECTION,
  QUIZ_PERCENTAGE,
  SECTION_PROGRESS,
  MINIMUM_SECTION_PROGRESS,
  STUDENT_ANSWERS,
  LAST_GUESSWORK_UUID,
  LAST_EXAM_UUID,
  LAST_QUIZ_UUID,
  ASSIGNMENT_PROGRESS,
  ORIENTATION_LECTURE_COMPLETE,
  ORIENTATION_ACTIVE_LEARNING_COMPLETE,
  ORIENTATION_SECTION_PROGRESS,
  REVIEW_LECTURE_COMPLETE,
  REVIEW_SECTION_PROGRESS,
  SECTION_VIDEO_PROGRESS,
  REVIEW_ACTIVE_LEARNING_COMPLETE
} from '../../Constants/studentContext'

import {
  ON_GET_STUDENT_COURSES,
  ON_NAVIGATE_TO,
  ON_NAVIGATE_TO_EXAM,
  ON_SKIP_SECTION,
  ON_GO_TO_COURSEHOME,
  ON_SET_LAST_ACTIVE_LEARNING_UUID,
  ON_SET_CURRENT_CHAPTER,
  ON_REVIEW_MODE,
  ON_STUDENT_ANSWER,
  ON_INSTRUCTOR_SWITCH,
  ON_QUIZ_SECTION,
  ON_EXAM_SECTION,
  ON_SUBMIT_CARD_QUESTION,
  ON_MINIMUM_SECTION_PROGRESS,
  ON_SECTION_PROGRESS,
  ON_SECTION_DATA_PROGRESS,
  ON_SUBMIT_ANSWER,
  ON_EXAM_COMPLETE,
  ON_SET_LAST_GUESSWORK_UUID,
  ON_SET_LAST_EXAM_UUID,
  ON_SET_LAST_QUIZ_UUID,
  ON_LECTURE_VIDEO_PROGRESS,
  ON_TRACK_STUDENT_EVENT,
  ON_TRACK_NPS_EVENT,
  ON_MIDTERM_EXAM_COMPLETE
} from '../../Constants/emitterKeys'
import { QUIZ, EXAM, PRACTICE_EXERCISES } from '../../Constants/sectionType'

import { COURSE_NOT_OPEN, FAILURE, NO_ACTIVE_COURSE, NOT_ENROLLED, UNDER_13 } from './../../Constants/noCourseType'

import ExamNavigationModal from '../ExamNavigationModal/ExamNavigationModal'
import QuizNavigationModal from '../QuizNavigationModal/QuizNavigationModal'
import TermAgreementModal from '../TermAgreementModal/TermAgreementModal'
import TermsOfUse from '../TermsOfUse/TermsOfUse'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'
import RegistrationPage from '../RegistrationPage/RegistrationPage'
import AcademicIntegrityForm from '../AcademicIntegrityForm'
import { isAuthRequired } from '../../utilities'
import config from '../../config'
import { getPracticeExamContent } from './practiceExam'
import {
  isNewCollegeWritingIReleased
} from '../ResourcesSection/Utils'
import DatoLink from './DatoLink'
import {
  getTimeUntilReady,
  logMetric } from '../../utilities/performanceUtils'
import {
  getLastActiveLearningUUIDKey,
  getLectureVideoProgressKey,
  getStudentAnswersKey as getStudentAnswersKeyUtil
} from '../../utilities/contextKeys'
import GuardianPermission from '../GuardianPermission/GuardianPermission'
import GuardianPermissionForm from '../GuardianPermission/GuardianPermissionForm'
import {
  getGuardianPermissionKeys
} from '../GuardianPermission/GuardianPermission.utils'
import {
  COHORT_START_DATE_2023_03_22,
  GUARDIAN_PERMISSION_PATH,
  PERMISSION_REQUESTED_KEY,
  PROF_CERT_GUARDIAN_PERMISSION_PATH,
  PROF_CERT_GUARDIAN_PERMISSION_REQUESTED,
  STAT_SECTION_11DOT2UUID
} from '../../Constants'
import { COURSE_STARTED } from '../../Constants/eventTypes'
import { ONCE } from '../../Constants/frequency'
import $script from 'scriptjs'
import OnboardingPwaForm from '../OnboardingPwa/OnboardingPwaForm'
import { CODING_ASSIGNMENT } from '../../Constants/chapterType'
import ProfessionalCertificationTypeform from '../TypeForm/ProfessionalCertificationTypeform/ProfessionalCertificationTypeform'
import ZoomConsentForm from '../TypeForm/ZoomConsentForm'
import {
  PERMISSION_AWAITED,
  PERMISSION_GRANTED
} from '../GuardianPermission/permissionStates'
import ProfCertGuardianPermissionForm from
  '../GuardianPermission/ProfCertGuardianPermissionForm/ProfCertGuardianPermissionForm'
import { OFFER_NOT_ACCEPTED } from '../../Constants/prospectStatus'

const {
  isPreviewCourse,
  insideProctorio
} = config

export const SATISMETER_SCRIPT = 'https://app.satismeter.com/js'
export const SATISMETER_KEY = 'satismeter'
let updatingCount = 0

class NavigationComponent extends Component {
  constructor (props) {
    super(props)
    this.skipSection = this.skipSection.bind(this)
    this.navigateTo = this.navigateTo.bind(this)
    this.loadSection = this.loadSection.bind(this)
    this.goToCourseHome = this.goToCourseHome.bind(this)
    this.setLastCard = this.setLastCard.bind(this)
    this.setCurrentChapter = this.setCurrentChapter.bind(this)
    this.reviewMode = this.reviewMode.bind(this)
    this.sectionProgress = this.sectionProgress.bind(this)
    this.minimumSectionProgress = this.minimumSectionProgress.bind(this)
    this.studentAnswer = this.studentAnswer.bind(this)
    this.instructorSwitch = this.instructorSwitch.bind(this)
    this.updatePercentForSection = this.updatePercentForSection.bind(this)
    this.submitCardQueston = this.submitCardQueston.bind(this)
    this.sectionDataProgress = this.sectionDataProgress.bind(this)
    this.logEmitterToConsole = this.logEmitterToConsole.bind(this)
    this.submitAnswer = this.submitAnswer.bind(this)
    this.finishExam = this.finishExam.bind(this)
    this.sectionProgressWithUpdatingCount = this.sectionProgressWithUpdatingCount.bind(this)
    this.setCourseData = this.setCourseData.bind(this)
    this.loginIfNot = this.loginIfNot.bind(this)
    this.fetchStudentsCourseStatus = this.fetchStudentsCourseStatus.bind(this)
    this.persistLectureProgress = this.persistLectureProgress.bind(this)
    this.getChapterForNav = this.getChapterForNav.bind(this)
    this.handleExamNavigation = this.handleExamNavigation.bind(this)
    this.getExamChapterForNav = this.getExamChapterForNav.bind(this)
    this.routeHandling = this.routeHandling.bind(this)

    this.state = {
      navigation: [],
      currentScreen: 0,
      childIndex: -1,
      showLoader: true,
      showFailure: false,
      quizNavigationLocked: false,
      examNavigationLockedState: 0
    }

    emitter.on(ON_NAVIGATE_TO, e => {
      const currentLocation = window.location.hash.slice(1)
      if (e === currentLocation) return

      this.navigateTo(e)
    })
    emitter.on(ON_NAVIGATE_TO_EXAM, link => {
      this.navigateToExam(link)
    })

    emitter.on(ON_SKIP_SECTION, e => {
      this.skipSection()
    })
    emitter.on(ON_GO_TO_COURSEHOME, e => {
      this.goToCourseHome()
    })

    emitter.on(ON_SET_LAST_ACTIVE_LEARNING_UUID, e => {
      this.logEmitterToConsole(ON_SET_LAST_ACTIVE_LEARNING_UUID, e)
      this.setLastActiveLearningUUID(e)
    })

    emitter.on(ON_SET_LAST_GUESSWORK_UUID, (e) => {
      this.logEmitterToConsole(ON_SET_LAST_GUESSWORK_UUID, e)
      this.setLastCard(e, LAST_GUESSWORK_UUID)
    })

    emitter.on(ON_SET_LAST_EXAM_UUID, (e) => {
      this.logEmitterToConsole(ON_SET_LAST_EXAM_UUID, e)
      this.setLastCard(e, LAST_EXAM_UUID)
    })

    emitter.on(ON_SET_LAST_QUIZ_UUID, (e) => {
      this.logEmitterToConsole(ON_SET_LAST_QUIZ_UUID, e)
      this.setLastCard(e, LAST_QUIZ_UUID)
    })

    emitter.on(ON_SUBMIT_ANSWER, e => {
      this.logEmitterToConsole(ON_SUBMIT_ANSWER, e)
      this.submitAnswer(e)
    })

    emitter.on(ON_MIDTERM_EXAM_COMPLETE, e => {
      this.logEmitterToConsole(ON_MIDTERM_EXAM_COMPLETE, e)
      this.midtermCompletedEvent(e)
    })

    emitter.on(ON_EXAM_COMPLETE, e => {
      const { examAnswerList } = e
      this.finishExam(examAnswerList)
    })

    emitter.on(ON_LECTURE_VIDEO_PROGRESS, (eventData) => {
      this.persistLectureProgress(eventData)
    })

    emitter.on(ON_SET_CURRENT_CHAPTER, e => {
      this.logEmitterToConsole(ON_SET_CURRENT_CHAPTER, e)
      this.setCurrentChapter(e)
    })

    emitter.on(ON_REVIEW_MODE, e => {
      this.logEmitterToConsole(ON_REVIEW_MODE, e)
      this.reviewMode(e)
    })

    emitter.on(ON_SECTION_PROGRESS, e => {
      this.logEmitterToConsole(ON_SECTION_PROGRESS, e)
      this.sectionProgress(e)
    })

    emitter.on(ON_MINIMUM_SECTION_PROGRESS, e => {
      this.logEmitterToConsole(ON_MINIMUM_SECTION_PROGRESS, e)
      this.minimumSectionProgress(e)
    })

    emitter.on(ON_TRACK_STUDENT_EVENT, e => {
      this.logEmitterToConsole(ON_TRACK_STUDENT_EVENT, e)
      this.submitStudentTrackedEvent(e)
    })

    emitter.on(ON_TRACK_NPS_EVENT, e => {
      this.logEmitterToConsole(ON_TRACK_NPS_EVENT, e)
      this.submitNPSTrackedEvent(e)
    })

    emitter.on(ON_STUDENT_ANSWER, e => {
      const { isExam, ...studentAnswer } = e
      const { uuid, answer } = studentAnswer || {}
      this.logEmitterToConsole(ON_STUDENT_ANSWER, isExam ? { uuid, answer } : studentAnswer)
      this.studentAnswer(studentAnswer, isExam)
    })

    emitter.on(ON_INSTRUCTOR_SWITCH, e => {
      this.logEmitterToConsole(ON_INSTRUCTOR_SWITCH, e)
      this.instructorSwitch(e)
    })

    emitter.on(ON_QUIZ_SECTION, e => {
      this.logEmitterToConsole(ON_QUIZ_SECTION, e)
      this.updatePercentForSection(e, QUIZ_SECTION)
    })

    emitter.on(ON_EXAM_SECTION, e => {
      this.logEmitterToConsole(ON_EXAM_SECTION, e)
      this.updatePercentForSection(e, EXAM_SECTION)
    })

    emitter.on(ON_SUBMIT_CARD_QUESTION, e => {
      this.logEmitterToConsole(ON_SUBMIT_CARD_QUESTION, e)
      this.submitCardQueston(e)
    })

    emitter.on(ON_SECTION_DATA_PROGRESS, e => {
      this.logEmitterToConsole(ON_SECTION_DATA_PROGRESS, e)
      this.sectionDataProgress(e)
    })

    emitter.on(ON_GET_STUDENT_COURSES, () => {
      try {
        this.fetchStudentsCourseStatus()
      } catch (err) {
        console.error('Students Course Status Fetching Issue - Navigation', err)
        this.setState({ showFailure: true })
      }
    })
  }

  setLastActiveLearningUUID = card => {
    const {
      context: { currentChapter: { type } }
    } = this.props
    const lastActiveLearningUUIDKey = getLastActiveLearningUUIDKey(type)
    this.setLastCard(card, lastActiveLearningUUIDKey)
  }

  logEmitterToConsole (emitterKey, e) {
    console.info(`ON: ${emitterKey}`, e)
  }

  // Emitted event handlers follow. Note they make heavy use of `this.props.context`.
  // The following should be noted when using context.
  // 1. See the HistoryManager component for how this component gets that property.
  // 2. Always make a copy of context before modifying it: const newContext = { ...this.props.context }
  // 3. When you are finished making changes, make sure you update the context:
  //    this.props.context.updateContext(newContext)
  // 4. Emitted event handler functions should not be async if they update the
  //    the Context. We want one event to be handled at time to prevent race
  //    conditions in updating the Context.

  setLastCard (card, key) {
    const { context } = this.props
    let {
      isCohortEndedForStudent,
      currentChapter: chapter
    } = context
    const newContext = { ...context }
    const { studentData } = newContext
    const lastStoredCards = studentData[key]
    studentData[key] = !lastStoredCards ? card : {
      ...lastStoredCards,
      ...card
    }

    const isExam = chapter?.type === EXAM_SECTION
    if (isExam) {
      const { examRetake, cohortData, currentChapter: chapter } = context
      const isRetakeAllowed = isExamRetakeType({
        examRetake,
        cohortId: cohortData?.cohortID,
        chapter
      })
      isCohortEndedForStudent = isCohortEndedForStudent && !isRetakeAllowed
    }

    context.updateContext({ studentData })
    saveSectionProgress(card, {
      key,
      isCohortEndedForStudent,
      warningType: null
    })
  }

  trackCourseStartEvent () {
    const {
      context: {
        updateContext,
        cohortData: { officialCourseName, name: cohort, duration }
      }
    } = this.props

    const eventData = {
      properties: {
        course: officialCourseName,
        cohort_length: duration,
        cohort,
        time_stamp: new Date().getTime()
      },
      event: COURSE_STARTED,
      frequency: ONCE
    }

    emitter.emit(ON_TRACK_STUDENT_EVENT, eventData)
    updateContext({
      isInitialProgress: false
    })
  }

  midtermCompletedEvent = ({ examUUID }) => {
    const { courseId } = config
    try {
      api.addLogEvent(ON_MIDTERM_EXAM_COMPLETE, {
        event: ON_MIDTERM_EXAM_COMPLETE,
        data: {
          examUUID,
          courseId
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  trackNPSEvent () {
    const { context: { cohortData, studentId } } = this.props

    const event = getNPSEvent(cohortData)
    if (!event) return

    const eventData = {
      event,
      properties: {
        outlier_id: studentId,
        attempt_id: cohortData.attemptID,
        course_name: cohortData.officialCourseName,
        cohort_name: cohortData.name
      },
      frequency: ONCE
    }

    emitter.emit(ON_TRACK_NPS_EVENT, eventData)
  }

  async sectionProgressWithUpdatingCount (sectionData, warningType, key) {
    const { context: { updateContext, isCohortEndedForStudent } } = this.props
    if (isCohortEndedForStudent) {
      return updateContext({ isStudentProgressBusy: false })
    }
    updatingCount += 1
    try {
      await saveSectionProgress(sectionData, {
        key,
        isCohortEndedForStudent,
        warningType
      })
    } finally {
      updatingCount -= 1
    }
    updateContext({ isStudentProgressBusy: updatingCount !== 0 })
  }

  submitAnswer (studentAnswer) {
    if (!studentAnswer) return
    const { uuid } = studentAnswer
    if (!uuid) return

    const { context, context: { isInitialProgress } } = this.props
    const studentAnswersKey = this.getStudentAnswersKey()

    if (isInitialProgress) this.trackCourseStartEvent()

    context.updateContext({
      isStudentProgressBusy: true
    })

    const newStudentAnswer = { ...studentAnswer }
    delete newStudentAnswer.uuid
    const studentAnswerData = { [uuid]: { ...newStudentAnswer } }

    this.sectionProgressWithUpdatingCount(
      studentAnswerData,
      'confirm',
      studentAnswersKey
    )
  }

  finishExam (studentAnswers) {
    if (!studentAnswers) return
    const studentAnswerData = []
    const {
      context: {
        cohortData: { cohortID } = {},
        courseData: {
          course_uuid: activeCourseUUID
        },
        user
      }
    } = this.props

    const choiceOrdersForMultipleChoiceQuestions = JSON.parse(
      localStorage.getItem(`choiceOrders_${user.email}`)
    )?.[activeCourseUUID]?.[cohortID] || {}

    studentAnswers.forEach((answer, index) => {
      const { uuid } = answer
      const choiceOrder = choiceOrdersForMultipleChoiceQuestions[uuid]
      const newStudentAnswer = this.objectToKeyValue(answer, index)
      choiceOrder && (newStudentAnswer[uuid].choiceOrder = choiceOrder)
      studentAnswerData.push(newStudentAnswer)
    })

    const { context } = this.props
    context.updateContext({
      isStudentProgressBusy: true
    })

    this.sectionProgressWithUpdatingCount(
      studentAnswerData,
      'confirm',
      STUDENT_ANSWERS
    )
  }

  objectToKeyValue (answer, index) {
    const { uuid } = answer
    if (!uuid) return {}
    const newAnswer = { ...answer }
    delete newAnswer.uuid
    return { [uuid]: { ...newAnswer, questionIndex: index } }
  }

  persistLectureProgress ({ sectionUUID, ...lectureProgress }) {
    const { context } = this.props
    const {
      isCohortEndedForStudent,
      currentChapter: { type },
      isInitialProgress
    } = context
    const lectureVideoProgressKey = getLectureVideoProgressKey(type)
    const newContext = { ...context }
    const { studentData } = newContext

    if (isInitialProgress) this.trackCourseStartEvent()

    const lastVideoProgress = studentData[lectureVideoProgressKey]
    studentData[lectureVideoProgressKey] = { ...lastVideoProgress, ...lectureProgress }

    const section = this.getCurrentSectionData()
    const { isCalculusCourse } = config
    const isCalculus = isCalculusCourse()
    const currentSectionEmbedCodes = isCalculus
      ? section?.lecture?.lecturevideos.map(video => video.kalturaEmbedCode)
      : section?.['multi_lecture_videos']?.videos.map(video => video.kalturaEmbedCode)

    const currentSectionVideoProgress =
      Object.keys(studentData[lectureVideoProgressKey] || {})
        .filter(kalturaEmbedCode =>
          currentSectionEmbedCodes?.includes(kalturaEmbedCode))
        .reduce((obj, kalturaEmbedCode) => {
          return Object.assign(obj, {
            [kalturaEmbedCode]: studentData[lectureVideoProgressKey][kalturaEmbedCode]
          })
        }, {})

    studentData[SECTION_VIDEO_PROGRESS] = {
      [sectionUUID]: currentSectionVideoProgress
    }

    context.updateContext({ studentData })

    Object.entries(lectureProgress).forEach(([id, second]) => {
      saveSectionProgress({ [id]: second }, {
        key: lectureVideoProgressKey,
        isCohortEndedForStudent,
        warningType: null
      })
    })
    saveSectionProgress({ [sectionUUID]: currentSectionVideoProgress }, {
      key: SECTION_VIDEO_PROGRESS,
      isCohortEndedForStudent,
      warningType: null
    })
  }

  setCurrentChapter (e) {
    const { context, context: { updateContext } } = this.props
    const newContext = { ...context }
    newContext.currentSection['currentChapter'] = e
    const { currentSection } = newContext
    updateContext({ currentSection })
  }

  reviewMode (e) {
    const { context, context: { updateContext } } = this.props
    let { reviewMode } = { ...context }
    reviewMode = e
    updateContext({ reviewMode })
  }

  sectionProgress (sectionUUID) {
    const { context } = this.props
    const { isCohortEndedForStudent, currentChapter: { type } } = context
    const isOrientation = isOrientationChapter({ type })
    const isReview = isReviewChapter({ type })
    if (
      isOrientation
    ) return this.orientationSectionProgress(sectionUUID)
    if (isReview) return this.reviewSectionProgress(sectionUUID)

    const newContext = { ...context }
    const { studentData, sectionTotalQuestionSet } = newContext

    const sectionsToCheck = getSectionProgressCompleteKeys()
    const section = this.getCurrentSectionData()

    const totalQuestionSet = sectionTotalQuestionSet[sectionUUID]
    let total = getTotalQuestionSetForSectionProgress(section, totalQuestionSet)

    let totalComplete = 0

    sectionsToCheck.forEach(section => {
      if (!studentData[section][sectionUUID]) return
      if ([EXAM_COMPLETE, ASSIGNMENT_PROGRESS].includes(section)) {
        totalComplete = 1
        total = 1
      } else totalComplete++
    })

    const completedPerc =
      Math.round(totalComplete * 100 / total * 100) / 100
    studentData[SECTION_PROGRESS][sectionUUID] = completedPerc

    context.updateContext({ studentData })
    const sectionData = { [sectionUUID]: completedPerc }
    saveSectionProgress(sectionData, {
      key: SECTION_PROGRESS,
      isCohortEndedForStudent,
      warningType: 'confirm'
    })
  }

  orientationSectionProgress = sectionUUID => {
    const { context } = this.props
    const { isCohortEndedForStudent } = context

    const newContext = { ...context }
    const { studentData, sectionTotalQuestionSet } = newContext

    const sectionsToCheck = [
      ORIENTATION_LECTURE_COMPLETE,
      ORIENTATION_ACTIVE_LEARNING_COMPLETE
    ]

    const total = sectionTotalQuestionSet[sectionUUID]
    let totalComplete = 0

    sectionsToCheck.forEach(section => {
      if (!studentData[section][sectionUUID]) return
      totalComplete++
    })

    const completedPerc =
      Math.round(totalComplete * 100 / total * 100) / 100
    studentData[ORIENTATION_SECTION_PROGRESS][sectionUUID] = completedPerc

    context.updateContext({ studentData })
    const sectionData = { [sectionUUID]: completedPerc }
    saveSectionProgress(sectionData, {
      key: ORIENTATION_SECTION_PROGRESS,
      isCohortEndedForStudent,
      warningType: 'confirm'
    })
  }

  reviewSectionProgress = sectionUUID => {
    const { context } = this.props
    const { isCohortEndedForStudent } = context

    const newContext = { ...context }
    const { studentData, sectionTotalQuestionSet } = newContext

    const sectionsToCheck = [
      REVIEW_LECTURE_COMPLETE,
      REVIEW_ACTIVE_LEARNING_COMPLETE
    ]

    const total = sectionTotalQuestionSet[sectionUUID]
    let totalComplete = 0

    sectionsToCheck.forEach(section => {
      if (!studentData[section][sectionUUID]) return
      totalComplete++
    })

    const completedPerc =
      Math.round(totalComplete * 100 / total * 100) / 100
    studentData[REVIEW_SECTION_PROGRESS][sectionUUID] = completedPerc

    context.updateContext({ studentData })
    const sectionData = { [sectionUUID]: completedPerc }
    saveSectionProgress(sectionData, {
      key: REVIEW_SECTION_PROGRESS,
      isCohortEndedForStudent,
      warningType: 'confirm'
    })
  }

  minimumSectionProgress (sectionUUID) {
    const { context } = this.props
    const { isCohortEndedForStudent, studentData, sectionTotalQuestionSet } = context
    const { sectionProgress } = studentData || {}
    if (!sectionTotalQuestionSet) return

    const sectionsToCheck = getSectionProgressCompleteKeys()
    const section = this.getCurrentSectionData()

    const totalQuestionSet = sectionTotalQuestionSet[sectionUUID]
    let total = getTotalQuestionSetForSectionProgress(section, totalQuestionSet)

    let totalComplete = 0

    sectionsToCheck.forEach(section => {
      const sectionIds = studentData[section] ? Object.keys(studentData[section]) : []

      // if sectionId exists with false, it's counted in minimum progress
      if (!sectionIds.includes(sectionUUID)) return
      if ([EXAM_COMPLETE, ASSIGNMENT_PROGRESS].includes(section)) {
        totalComplete = 1
        total = 1
      } else totalComplete++
    })

    const completedPerc =
      Math.round(totalComplete * 100 / total * 100) / 100
    studentData[MINIMUM_SECTION_PROGRESS][sectionUUID] =
    sectionProgress[sectionUUID] || completedPerc

    context.updateContext({ studentData })
    const sectionData = {
      [sectionUUID]: sectionProgress[sectionUUID] || completedPerc
    }
    saveSectionProgress(sectionData, {
      key: MINIMUM_SECTION_PROGRESS,
      isCohortEndedForStudent,
      warningType: 'confirm'
    })
  }

  submitStudentTrackedEvent = (data) => {
    const { context: { cohortData: { cohortID } } } = this.props
    const { courseId } = config
    try {
      api.submitTrackedEvent({ data, courseId, cohortID })
    } catch (e) {
      console.error(e)
    }
  }

  submitNPSTrackedEvent = async (data) => {
    const { context: { cohortData: { cohortID } } } = this.props
    const { courseId } = config
    try {
      await api.submitTrackedEvent({ data, courseId, cohortID })
      $script(SATISMETER_SCRIPT, SATISMETER_KEY)
      $script.ready(SATISMETER_KEY, () => {
        this.loadSatismeter(data)
      })
    } catch (e) {
      console.error(e)
    }
  }

  loadSatismeter = () => {
    const {
      context: {
        cohortData,
        isContractorEmployee,
        user,
        studentId,
        isNotStudent
      }
    } = this.props

    const {
      attemptID,
      name: cohortName,
      officialCourseName,
      testAttempt
    } = cohortData

    const isOutlierStudent = !isContractorEmployee && !testAttempt && !isNotStudent

    window.satismeter({
      writeKey: process.env.REACT_APP_SATISMETER_WRITE_KEY,
      userId: md5(user.email),
      traits: {
        attempt_id: attemptID,
        course_name: officialCourseName,
        cohort_name: cohortName,
        outlier_id: studentId,
        student_email: user?.email,
        outlier_student: isOutlierStudent
      }
    })
  }

  submitCardQueston ({ questionUuid, type, questionActivity }) {
    const {
      context,
      context: {
        updateContext,
        isInitialProgress
      }
    } = this.props
    const studentAnswersKey = this.getStudentAnswersKey()
    const newContext = { ...context }
    const { studentData } = newContext
    const studentAnswers = studentData[studentAnswersKey]
    const answer = {
      uuid: questionUuid,
      answer: '',
      type,
      questionActivity,
      correct: true
    }
    if (!questionUuid || !studentAnswers) return
    const studentAnswer = studentAnswers.find(
      answer => answer.uuid === questionUuid
    )
    if (studentAnswer && !questionActivity) return
    if (isInitialProgress) this.trackCourseStartEvent()

    if (!studentAnswer) studentAnswers.push(answer)
    updateContext({
      studentData,
      isStudentProgressBusy: true
    })

    const { uuid } = answer
    const newStudentAnswer = { ...answer }
    delete newStudentAnswer.uuid

    const studentAnswerData = { [uuid]: { ...newStudentAnswer } }
    this.sectionProgressWithUpdatingCount(
      studentAnswerData,
      'confirm',
      studentAnswersKey
    )
  }

  studentAnswer (answer, isExam) {
    const { context, context: { updateContext } } = this.props
    const studentAnswersKey = this.getStudentAnswersKey()

    const newContext = { ...context }
    const beforeStudentData = copyStudentProgressContext(newContext)
    const { studentData } = newContext
    const studentAnswers = studentData[studentAnswersKey]
    const { uuid } = answer

    if (!uuid) return
    if (studentAnswers) {
      const studentAnswerIndex = studentAnswers.findIndex(
        answer => answer.uuid === uuid
      )
      if (studentAnswerIndex === -1) studentAnswers.push(answer)
      else studentAnswers[studentAnswerIndex] = answer
    }
    if (!isExam) logStudentProgressDiffContext(beforeStudentData, newContext)
    if ('trials' in answer) emitter.emit(ON_SUBMIT_ANSWER, answer)
    updateContext({ studentData })
  }

  getStudentAnswersKey = () => {
    const {
      context: {
        currentChapter: { type }
      }
    } = this.props
    return getStudentAnswersKeyUtil(type)
  }

  instructorSwitch (e) {
    if (e) {
      const { context, context: { updateContext } } = this.props
      const { course } = { ...context }
      course['currentInstructor'] = e
      updateContext({ course })
    }
  }

  updatePercentForSection (sectionData, sectionKey) {
    const {
      activeSectionUUID,
      sectionUUID,
      percentage
    } = sectionData

    const { context } = this.props
    const { isCohortEndedForStudent } = context
    const newContext = { ...context }
    const { studentData } = newContext
    const percentKey = sectionKey === QUIZ_SECTION
      ? QUIZ_PERCENTAGE : EXAM_PERCENTAGE

    const sectionKeyData = studentData[sectionKey]
    sectionKeyData[sectionUUID] = percentage
    studentData[sectionKey] = sectionKeyData

    const sectionPercentData = studentData[percentKey]
    let sectionPercentage = percentage
    if (activeSectionUUID in sectionPercentData) {
      sectionPercentage = sectionPercentData[activeSectionUUID]
      if (sectionPercentage < percentage) {
        sectionPercentage = percentage
      }
    }
    sectionPercentData[activeSectionUUID] = sectionPercentage
    studentData[percentKey] = sectionPercentData

    context.updateContext({ studentData })
    const progressData = {
      [activeSectionUUID]: {
        sectionPercentage,
        [sectionUUID]: percentage
      }
    }
    saveSectionProgress(progressData, {
      key: sectionKey,
      isCohortEndedForStudent,
      warningType: 'confirm'
    })
  }

  sectionDataProgress (sectionData) {
    const { key, sectionUUID, value, title, isMidExam } = sectionData
    const { context } = this.props
    const { isCohortEndedForStudent, isInitialProgress } = context
    const newContext = { ...context }
    const { studentData } = newContext

    if (isInitialProgress) this.trackCourseStartEvent()
    const beforeStudentData = copyStudentProgressContext(newContext)
    const studentSectionData = studentData[key]
    if (studentSectionData) {
      studentSectionData[sectionUUID] = value
      studentData[key] = studentSectionData
    }

    logStudentProgressDiffContext(beforeStudentData, newContext)

    context.updateContext({ studentData })

    const progressData = { [sectionUUID]: value }
    saveSectionProgress(progressData, {
      key,
      title,
      isMidExam,
      sectionUUID,
      isCohortEndedForStudent,
      warningType: null
    })
  }

  fetchStudentsCourseStatus () {
    this.setCourseData()

    this.trackNPSEvent()
  }

  routeHandling (navigation) {
    if (!navigation?.length) return

    const {
      activePath,
      context: {
        examRetake,
        cohortData,
        currentChapter,
        currentChapter: {
          title,
          type
        } } } = this.props
    const { currentScreen, childIndex } = this.state
    const {
      parentUUID,
      childUUID,
      extraUUID
    } = extractUUIDs(activePath)

    if (isInvalidExtraUUID(extraUUID)) {
      window.location.hash = `${parentUUID}/${childUUID}`
    }

    const shouldCheckChildUUID = !isProblemBankQuestion({
      navigation,
      uuid: childUUID,
      currentScreenIndex: this.state.currentScreen,
      currentChildIndex: this.state.childIndex })
    const isExam = type === 'exam'
    const isRetakeAllowed = isExam &&
        isExamRetakeType({
          examRetake,
          cohortId: cohortData.cohortID,
          chapter: currentChapter
        })
    if (
      shouldCheckChildUUID &&
        childUUID &&
        isInvalidChildUUID(navigation, childUUID, isRetakeAllowed)
    ) {
      const practiceExam = title === 'Practice Exam'
      if (isRetakeAllowed && !practiceExam) {
        const { data: { Question } } = navigation[currentScreen]
        const question = Question && Question[childIndex + 1]
        if (!question) return this.setState({ childIndex: childIndex + 1 })
      }
      window.location.hash = parentUUID
    }

    if (isInvalidParentUUID(navigation, parentUUID)) {
      window.location.hash = '/'
    }
  }

  setMinimumSectionProgressForRemaining () {
    const { context: {
      studentData: { minimumSectionProgress, sectionProgress }
    } } = this.props
    const minimumSectionProgressKeys = Object.keys(minimumSectionProgress)
    const sectionProgressKeys = Object.keys(sectionProgress)
    if (!minimumSectionProgressKeys.length && !sectionProgressKeys.length) { return }
    const unCommomElements = sectionProgressKeys
      .filter(element => !minimumSectionProgressKeys.includes(element))
    unCommomElements.forEach(key => {
      emitter.emit(ON_MINIMUM_SECTION_PROGRESS, key)
    })
  }

  componentDidUpdate (prevProps) {
    const {
      activePath,
      context: { studentData, isCourseDataLoading }
    } = this.props
    const {
      activePath: prevActivePath,
      context: {
        studentData: prevStudentData,
        isCourseDataLoading: prevIsCourseDataLoading
      }
    } = prevProps
    if (JSON.stringify(studentData) !== JSON.stringify(prevStudentData)) {
      this.setMinimumSectionProgressForRemaining()
    }
    if (prevActivePath !== activePath) {
      this.navigateToUuid(activePath)
      this.routeHandling(this.state.navigation)
    }
    if (isPreviewCourse && prevIsCourseDataLoading && !isCourseDataLoading) {
      return this.setCourseData()
    }
  }

  componentDidMount () {
    const { activePath, isJDoodlePage } = this.props
    const { course: { showJDoodleCompiler } } = config
    const isComputerScienceCourse =
      isJDoodlePage && showJDoodleCompiler
    if (!isAuthRequired(activePath) || isComputerScienceCourse) return
    setTimeout(() => {
      this.loginIfNot()
    }, 1000)
    this.getRetakes()
  }

  loginIfNot () {
    const { isAuthenticated, loginWithRedirect } = this.context
    const searchParams = new URLSearchParams(window.location.search)
    const emailParam = decodeURIComponent(searchParams.get('email') || '')
    !isAuthenticated && loginWithRedirect({
      redirect_uri: window.location.origin,
      login_hint: emailParam,
      appState: { targetUrl: window.location.hash }
    })
  }

  getChapterForNav ({ courseUUID, courseTitle }, chapter) {
    const {
      title: chapterTitle,
      chapter_uuid: chapterUUID
    } = chapter

    return {
      uuid: chapterUUID,
      type: chapter.type,
      data: chapter,
      breadcrumb: courseTitle + ' > ' + chapterTitle,
      breadcrumbArr: [
        [courseTitle, courseUUID],
        [chapterTitle, chapterUUID]
      ],
      children: null
    }
  }

  handleExamNavigation (navElement, cohortData) {
    const {
      context: {
        examRetake,
        isAdmin,
        isStudioCohort,
        isVIPGradedContent
      }
    } = this.props
    if (isAdmin || isVIPGradedContent || isStudioCohort) return
    const { uuid, chapter } = navElement
    const currentDate = secondsSinceEpoch()
    let examNavigationLockedState = 0
    if (isPracticeExam(uuid)) {
      const { cohortExamDates, cohortStartDate } = cohortData
      if (!cohortExamDates) return
      const { courseEndDate } = cohortExamDates
      if (cohortStartDate > currentDate) examNavigationLockedState = 1
      if (currentDate >= courseEndDate) examNavigationLockedState = 2
      if (currentDate > cohortStartDate &&
          currentDate < courseEndDate) return
    } else {
      const { context: { studentData } } = this.props
      const isExamAttempted = uuid in studentData[EXAM_COMPLETE]
      const { unlockDate, lockDate } = getChapterLockDates({ chapter, cohortData, examRetake })
      if (unlockDate > currentDate) examNavigationLockedState = 1
      if ((lockDate + 2 * 60 * 60) < currentDate &&
          !isExamAttempted) examNavigationLockedState = 2
    }
    this.setState({ examNavigationLockedState })
  }

  handleQuizLocking = (section, question) => {
    const {
      data: { section_exe: sectionExe, guessworkSectionUUID },
      chapterExamUnlockDate,
      finalExamUnlockDate,
      finalExamLockDate
    } = section
    const { quiz } = sectionExe

    const { practiceUUID } = question

    const {
      isAuditor,
      isStudioCohort,
      studentData,
      isAdmin,
      isVIPGradedContent,
      isNoAssessments,
      isContentGatingEnabled,
      isCohortEndedForStudent
    } = this.props.context
    const isContentUnlocked = isContentGatingUnlocked({
      section: section.data,
      studentData,
      isContentGatingEnabled
    })
    const isGuessworkComplete = !guessworkSectionUUID ||
      isGuessworkCompleteForSection(studentData, guessworkSectionUUID)

    const quizIndex = quiz.findIndex(q => q.question_set_uuid === practiceUUID)
    const shouldUnlock = shouldUnlockQuiz({
      isAuditor,
      isStudioCohort,
      isAdmin,
      isVIPGradedContent,
      isCohortEndedForStudent,
      quizNumber: quizIndex,
      isGuessworkComplete,
      chapterExamUnlockDate,
      finalExamUnlockDate,
      finalExamLockDate,
      isNoAssessments,
      isContentUnlocked,
      currentDate: secondsSinceEpoch()
    })

    return shouldUnlock
  }

  handleQuizNavigation = ({ section, questionId }) => {
    if (!section) return

    const { children } = section
    if (!children || !children.length) return

    const quizzes = children.filter(c => c.quest_type === QUIZ)
    if (!quizzes || !quizzes.length) return

    const question = quizzes.find(quiz => quiz.uuid === questionId)
    if (!question) return

    const shouldUnlock = this.handleQuizLocking(section, question)
    this.setState({ quizNavigationLocked: !shouldUnlock })
  }

  getAssignmentNav = ({ courseUUID, courseTitle }, chapter, cohortData,
    assignmentType = 'writing_assignment') => {
    const {
      chapter_uuid: chapterUUID,
      type,
      title,
      updateddisplaytitle,
      description,
      updateddescription,
      question_set_ending_text: questionSetEndingText,
      question_set_ending_image: questionSetEndingImage,
      assignmentDetails,
      updatedassignmentdetails,
      updatedassignmentdetails2,
      rubric,
      updatedrubric,
      updatedRubrics,
      jstorLink,
      starterFiles,
      multiPartUpload,
      affirmationStatements,
      fileName1,
      fileName2,
      fileName3,
      fileName4,
      fileName5,
      fileType1,
      fileType2,
      fileType3,
      fileType4,
      fileType5,
      codegradeAssignmentId,
      hideTextEntryField,
      hideFileUploadField,
      maxScore
    } = chapter

    const { cohortID, cohortMilestones, cohortStartDate } = cohortData
    if (!cohortMilestones) return

    const isPhilosophy = ['philosophy', 'philosophy.plus'].includes(config.courseName)

    const isCollegeWritingI = ['collegewriting-i', 'collegewriting-i.plus'].includes(config.courseName) ||
      courseUUID === config.courseIds.test
    const shouldUseCollegeWritingIUpdatedAssignment = isCollegeWritingI &&
      isNewCollegeWritingIReleased(cohortStartDate)

    let updateAssignmentDetails = assignmentDetails
    if (shouldUseCollegeWritingIUpdatedAssignment) {
      updateAssignmentDetails = updatedassignmentdetails2 || assignmentDetails
    } else if (isPhilosophy) {
      updateAssignmentDetails = updatedassignmentdetails || assignmentDetails
    }

    const assignmentMilestone = cohortMilestones.find(milestone =>
      milestone?.datoAssignmentUUID === chapterUUID)
    if (!assignmentMilestone) return

    const {
      name: airtableName,
      unlockTime,
      lockTime,
      description: airtableDescription
    } = assignmentMilestone

    const updatedTitle = isPhilosophy
      ? updateddisplaytitle || title
      : title

    const chapterObj = {
      uuid: chapterUUID,
      type,
      data: null,
      breadcrumb: courseTitle + ' > ' + updatedTitle,
      breadcrumbArr: [
        [courseTitle, courseUUID],
        [updatedTitle, chapterUUID]
      ],
      course_uuid: courseUUID,
      title: updatedTitle
    }

    const cohortRubric = getRubric({
      rubric,
      updatedRubric: updatedrubric,
      useUpdatedRubric: isPhilosophy,
      rubrics: updatedRubrics,
      cohortStartDate
    })

    const assignment = {
      uuid: assignmentType,
      type: assignmentType,
      assignmentUUID: chapterUUID,
      cohortID,
      description: isPhilosophy
        ? updateddescription || description
        : description,
      assignmentDetails: updateAssignmentDetails,
      title: updatedTitle,
      rubric: cohortRubric,
      starterFiles,
      codegradeAssignmentId,
      jstorLink,
      multiPartUpload,
      affirmationStatements,
      ...(multiPartUpload && {
        multiPartUploadfiles: [
          ...(fileName1 && fileType1 ? [{ name: fileName1, type: fileType1 }] : []),
          ...(fileName2 && fileType2 ? [{ name: fileName2, type: fileType2 }] : []),
          ...(fileName3 && fileType3 ? [{ name: fileName3, type: fileType3 }] : []),
          ...(fileName4 && fileType4 ? [{ name: fileName4, type: fileType4 }] : []),
          ...(fileName5 && fileType5 ? [{ name: fileName5, type: fileType5 }] : [])
        ]
      }),
      hideTextEntryField,
      hideFileUploadField,
      maxScore,
      unlockTime,
      lockTime,
      airtableName,
      airtableDescription,
      breadcrumb: updatedTitle,
      breadcrumbArr: [
        [updatedTitle, assignmentType]
      ]
    }

    const endContentIntertitialExam = {
      uuid: chapterUUID + '_end_section',
      type: 'end_content_intertitial_section',
      typeSection: 'end_content_intertitial_writing',
      data: {
        intro_text: questionSetEndingText,
        section_ending_uuid: chapterUUID + '_end_section',
        intro_image: questionSetEndingImage
      },
      breadcrumb: title,
      breadcrumbArr: [['Writing Assignment', chapterUUID + '_end_section']],
      sectionUUID: chapterUUID
    }

    chapterObj.children = [assignment, endContentIntertitialExam]
    return chapterObj
  }

  getExamChapterForNav ({ courseUUID, courseTitle }, chapter, cohortData) {
    const {
      context: {
        examRetake
      }
    } = this.props
    const examVersionQuestions = getVersionQuestions({
      cohortId: cohortData?.cohortID,
      chapter,
      examRetake
    })
    const {
      title: chapterTitle,
      exams: chapterExams,
      examResources,
      resourceIcons,
      examNumber,
      isFinalExam,
      chapter_uuid: chapterUUID,
      view_lesson: viewLesson,
      question_set_ending_text: questionSetEndingText,
      question_set_ending_image: questionSetEndingImage
    } = chapter

    chapterExams.Question = trimQuestionsAnswers(chapterExams.Question)

    chapterExams.Question = addExamResourcesInQuestions(
      chapterExams.Question, examResources, resourceIcons
    )

    const chapterObj = {
      uuid: chapterUUID,
      type: 'exam',
      chapter: {
        title: chapterTitle,
        type: 'exam',
        examNumber,
        isFinalExam,
        uuid: chapterUUID
      },
      data: examVersionQuestions || chapterExams,
      breadcrumb: courseTitle + ' > ' + chapterTitle,
      breadcrumbArr: [
        [courseTitle, courseUUID],
        [chapterTitle, chapterUUID]
      ],
      course_uuid: courseUUID,
      title: chapterTitle
    }

    const { unlockDate: examUnlockDate, lockDate: examLockDate } =
    getChapterLockDates({ chapter, cohortData, examRetake })

    const chapterExamsQuestion = examVersionQuestions?.Question ||
      chapterExams?.Question

    const childArray = chapterExamsQuestion.map((question, key) => {
      const {
        Question_uuid: questionUUID,
        title: questionTitle
      } = question
      return {
        uuid: questionUUID,
        chapterUUID,
        type: 'question_exam',
        quest_type: EXAM,
        typeSection: 'end_content_intertitial_exam',
        section_ending_uuid: chapterUUID + '_end_section',
        data: chapterExamsQuestion,
        breadcrumb: `Exam > ${questionTitle}`,
        practiceUUID: examVersionQuestions?.versionUUID || chapterUUID,
        breadcrumbArr: [
          [chapterTitle, chapterUUID],
          [questionTitle, questionUUID]
        ],
        examUnlockDate,
        examLockDate,
        view_lesson: viewLesson
      }
    })

    const endContentIntertitialExam = {
      uuid: chapterUUID + '_end_section',
      type: 'end_content_intertitial_section',
      typeSection: 'end_content_intertitial_exam',
      data: {
        intro_text: questionSetEndingText,
        section_ending_uuid: chapterUUID + '_end_section',
        intro_image: questionSetEndingImage,
        first_quiz_question: chapterExamsQuestion[0].Question_uuid,
        questions: chapterExamsQuestion
      },
      breadcrumb: chapterTitle,
      breadcrumbArr: [[EXAM, chapterUUID + '_end_section']],
      sectionUUID: chapterUUID
    }

    childArray.push(endContentIntertitialExam)

    chapterObj.children = childArray

    return chapterObj
  }

  getNavigationState () {
    const nav = []
    const { context } = this.props
    const {
      examRetake,
      courseData,
      cohortData: {
        cohortID,
        cohortModifier,
        cohortMilestones
      },
      latestCohort,
      cohortCourseInfoUrl,
      cohortExamDates,
      cohortSpecialDays,
      cohortStartDate,
      isStudioCohort
    } = context

    const {
      relationship: { fields: { liveProctoring } = {} } = {}
    } = latestCohort || {}

    const cohortData = {
      cohortID,
      cohortStartDate,
      cohortModifier,
      cohortSpecialDays,
      cohortMilestones,
      cohortExamDates
    }

    const {
      course_uuid: courseUUID,
      title: courseTitle,
      audio: audioConfig,
      chapters
    } = courseData

    const courseObj = {}
    courseObj.uuid = courseData.course_uuid
    courseObj.type = 'course'
    courseObj.data = courseData
    courseObj.breadcrumb = courseData.title
    courseObj.breadcrumbArr = [[courseData.title, courseData.course_uuid]]
    courseObj.children = null
    courseData.cohortID = cohortID
    courseObj.courseUnlockDate = cohortStartDate
    courseObj.cohortModifier = cohortModifier
    courseObj.cohortSpecialDays = cohortSpecialDays
    courseObj.cohortMilestones = cohortMilestones
    courseObj.cohortCourseInfoUrl = cohortCourseInfoUrl
    courseObj.cohortExamDates = (
      cohortExamDates || (isPreviewCourse ? {} : undefined)
    )
    courseObj.cohortData = cohortData
    nav.push(courseObj)

    /** Start : Course Info  */
    if (courseData) {
      const courseObj = {}
      courseObj.uuid = courseData.course_uuid + '_info'
      courseObj.type = 'course_info'
      courseObj.data = courseData
      courseObj.breadcrumb = `${courseData.title} > Info`
      courseObj.breadcrumbArr = [
        [courseData.title, courseData.course_uuid],
        [courseData.title, courseData.course_uuid + '_info'],
        ['Info', courseData.course_uuid + '_info']
      ]
      courseObj.children = null
      nav.push(courseObj)
    }
    /** End : Course Info  */

    // Used by quiz unlocking logic.
    const {
      unlockDate: finalExamUnlockDate,
      lockDate: finalExamLockDate
    } = getFinalExamDates(courseData.chapters, cohortData, examRetake)
    const midTerm1ExamDates = getChapterExamDates({
      chapterIndex: 0,
      chapters: courseData.chapters,
      examRetake,
      cohortData
    })
    // practiceExam is always open for studio cohorts
    const practiceExamOpenDate = isStudioCohort
      ? 0 // Jan 1, 1970
      : practiceExamReleasedFunction(midTerm1ExamDates.unlockDate)

    context.updateContext({
      audioConfig,
      firstExamDates: midTerm1ExamDates || {},
      finalExamLockDate
    })

    const isCohortStarted = cohortData.cohortStartDate < secondsSinceEpoch()
    // Set practice exam on 0 index if cohort is started
    // or if it is a studio cohort
    const courseHasExam = hasCourseExam(courseData.chapters)
    const shouldShowPracticeExam = chapters?.length && courseHasExam &&
      (isCohortStarted || isStudioCohort) && !liveProctoring

    if (shouldShowPracticeExam) {
      const practiceExam = getPracticeExamContent()
      chapters.splice(0, 0, { ...practiceExam, practiceExamOpenDate })
    }

    chapters.forEach((chapter, index) => {
      let chapterObj
      // eslint-disable-next-line
      switch (chapter.type) {
        case 'exam':
          chapterObj = this.getExamChapterForNav({ courseUUID, courseTitle },
            chapter, cohortData)
          nav.push(chapterObj)
          break
        case CODING_ASSIGNMENT:
          chapterObj = this.getAssignmentNav({ courseUUID, courseTitle },
            chapter, cohortData, 'coding_assignment')
          chapterObj && nav.push(chapterObj)
          break
        case 'WritingAssignmentChapterRecord':
          chapterObj = this.getAssignmentNav({ courseUUID, courseTitle },
            chapter, cohortData)
          chapterObj && nav.push(chapterObj)
          break
        case 'orientation':
        case 'review':
        case 'chapter':
          chapterObj = this.getChapterForNav(
            { courseUUID, courseTitle }, chapter)
          nav.push(chapterObj)

          // Used by quiz unlocking logic
          const { unlockDate: chapterExamUnlockDate } = getChapterExamDates({
            chapterIndex: index,
            chapters: courseData.chapters,
            cohortData,
            examRetake,
            courseId: courseUUID
          })

          chapter.sections.forEach((section) => {
            const {
              type,
              title,
              chapter_uuid: chapterUUID
            } = chapter
            var sectionObj = {}
            sectionObj.uuid = section.section_uuid
            sectionObj.type = 'section'
            sectionObj.chapter = {
              type,
              title,
              uuid: chapterUUID
            }
            sectionObj.data = section
            sectionObj.siblingSections = chapter.sections
            sectionObj.breadcrumb =
            chapterObj.breadcrumb + ' > ' + section.title
            sectionObj.breadcrumbArr = [
              chapterObj.breadcrumbArr[0],
              chapterObj.breadcrumbArr[1],
              [section.title, section.section_uuid]
            ]
            sectionObj.children = null
            // Next three items used for quiz unlocking logic
            sectionObj.chapterExamUnlockDate = chapterExamUnlockDate
            sectionObj.finalExamUnlockDate = finalExamUnlockDate
            sectionObj.finalExamLockDate = finalExamLockDate
            nav.push(sectionObj)
          })
      }
    })

    // check if browser is not chrome
    if (navigator.userAgent.indexOf('Chrome') === -1) {
      alert(
        'Outlier courses require the use of the Google Chrome browser on a laptop or desktop. Please switch to Chrome for the best experience.'
      )
    }

    const termsOfUse = {}
    termsOfUse.uuid = 'terms-of-use'
    termsOfUse.type = 'terms_of_use'
    nav.push(termsOfUse)

    const privacyPolicy = {}
    privacyPolicy.uuid = 'privacy-policy'
    privacyPolicy.type = 'privacy_policy'
    nav.push(privacyPolicy)

    const grades = {
      uuid: 'grades',
      type: 'grades'
    }
    nav.push(grades)

    const resources = {
      uuid: 'resources',
      type: 'resources',
      hideBreadcrumb: true,
      children: [
        {
          uuid: 'syllabus',
          type: 'syllabus'
        },
        {
          uuid: 'orientation',
          type: 'orientation'
        }
      ]
    }

    nav.push(resources)

    const extensionChildren = courseData.chapters
      .filter(chapter =>
        ['exam', 'WritingAssignmentChapterRecord'].includes(chapter.type)
      )
      .map(chapter => {
        return {
          ...chapter,
          breadcrumbArr: [[courseTitle, courseUUID]],
          uuid: chapter.chapter_uuid
        }
      })
    const extensionRequest = {
      uuid: 'extension-request',
      type: 'extension-request',
      breadcrumbArr: [[courseTitle, courseUUID]],
      courseTitle,
      cohortData,
      children: extensionChildren
    }
    nav.push(extensionRequest)

    const profCertGuardianPermission = {
      uuid: 'prof-cert-guardian-permission',
      type: 'prof-cert-guardian-permission'
    }
    nav.push(profCertGuardianPermission)

    const guardianPermission = {
      uuid: 'guardian-permission',
      type: 'guardian-permission'
    }
    nav.push(guardianPermission)

    const studyGuide = {
      uuid: 'study-guide',
      type: 'study_guide'
    }

    const javaCompiler = {
      uuid: 'java-compiler',
      type: 'java_compiler'
    }

    const announcements = {
      uuid: 'announcements',
      type: 'announcements'
    }

    const { course: { showJDoodleCompiler } } = config
    const hasAnnouncementsFlag = config.hasAnnouncementsFlag()

    console.log({ showJDoodleCompiler })

    if (showJDoodleCompiler) nav.push(javaCompiler)
    if (hasAnnouncementsFlag) nav.push(announcements)

    nav.push(studyGuide)

    return nav
  }

  setCourseData (shouldSendMetric = true) {
    const navigation = this.getNavigationState()

    this.setState({
      navigation,
      showLoader: false
    }, this.setURLPathContent)

    if (!shouldSendMetric) return

    const timeUntilReady = getTimeUntilReady()
    if (!timeUntilReady) return

    logMetric({
      courseId: config.courseId,
      timeUntilReady
    })
  }

  async setURLPathContent () {
    const {
      context: {
        cohortData: {
          cohortID,
          cohortModifier,
          cohortMilestones
        },
        cohortExamDates,
        cohortSpecialDays,
        cohortStartDate
      },
      context
    } = this.props

    const {
      navigation
    } = this.state

    const cohortData = {
      cohortID,
      cohortStartDate,
      cohortModifier,
      cohortSpecialDays,
      cohortMilestones,
      cohortExamDates
    }

    const path = window.location.hash.split('/')
    if (path.length <= 1) return

    const queryParams = path[1].split('?')
    const isWithQueryParams = queryParams.length > 1
    const navElement = isWithQueryParams ? queryParams[0] : path[1]
    const index = navigation.findIndex(element => element.uuid === navElement)

    if (index < 0) return this.routeHandling(navigation)

    const element = navigation[index]

    if (element.type === 'section') {
      await this.loadSection(navElement)
      this.updateCurrentChapter(element)
      this.updateSectionTotalQuestionSetContext(element)
    }

    let childIndex = -1

    if (!element) {
      return this.setState({
        currentScreen: index,
        showLoader: false
      })
    }

    if (element.type === 'exam') {
      this.updateCurrentChapter(element)
      this.handleExamNavigation(element, cohortData)
    }

    if (path.length > 2) {
      this.handleQuizNavigation({
        section: element,
        questionId: path[2]
      })

      const uuids = path[2].split('_')
      const hasProblemSetAndQuestionUUIDs = uuids && uuids.length === 2
      let problemSetUUID
      if (hasProblemSetAndQuestionUUIDs) problemSetUUID = uuids[0]

      element.children && element.children.forEach(
        (childrenData, childrenDataIndex) => {
          if (!hasProblemSetAndQuestionUUIDs &&
                  childrenData.uuid === path[2]) {
            // Need this condition for problem banks, because traditional
            // practice exercises too have "problem_set" but they
            // have path length > 3 and are handled in another if condition
            if (childrenData.type === 'problem_set') {
              const childData = childrenData.data &&
                    childrenData.data.length && childrenData.data[0]
              if (
                childData.type === 'Problem Bank'
              ) childIndex = childrenDataIndex
            } else childIndex = childrenDataIndex
          } else if (problemSetUUID) {
            // This condition is to set a childIndex value even if
            // a problemSetUUID_questionUUID is not present in the
            // children. It means the questionUUID could be from
            // the previous section (or previous problem set in the
            // case of first section) and was a pop question in this
            // problem set.
            if (childIndex === -1 &&
                  childrenData.uuid.startsWith(`${problemSetUUID}_`)) {
              childIndex = childrenDataIndex
            }
            if (childrenData.uuid === path[2]) childIndex = childrenDataIndex
          }
        }
      )

      // If path[2] is problemSetUUID_questionUUID and the
      // child at position specified by childIndex does not
      // have uuid same as questionUUID, we consider that as
      // a pop question from previous section
      if (problemSetUUID && childIndex !== -1) {
        const childData = element.children[childIndex]
        if (childData && childData.questionUUID !== uuids[1]) {
          childData.forcePopQuestionUUID = uuids[1]
        }
      }
    }

    // set tird params
    if (path.length > 3) {
      element.children && element.children.forEach(
        (childrenData, childrenDataIndex) => {
          if (
            path[3] &&
                path[3] === 'questions' &&
                childrenData.type === 'problem_set'
          ) childIndex = childrenDataIndex
          else if (
            path[3] &&
                path[3] === 'practice' &&
                childrenData.type ===
                'end_content_intertitial_practices'
          ) childIndex = childrenDataIndex
        }
      )
    }
    this.setState({
      currentScreen: index,
      showLoader: false,
      ...(childIndex > -1 && { childIndex })
    })
    const { examRetake } = context
    const isRetake = isExamRetakeType({
      examRetake,
      cohortId: cohortID,
      chapter: element.chapter
    })
    insideProctorio && isRetake && this.setState({ childIndex: 0 })
  }

  updateCurrentChapter = section => {
    const { chapter } = section
    const { context: { currentChapter, updateContext } } = this.props

    if (currentChapter.uuid === chapter.uuid) return

    updateContext({ currentChapter: chapter })
  }

  updateSectionTotalQuestionSetContext = section => {
    const { uuid: sectionUUID } = section
    const { context: { sectionTotalQuestionSet, updateContext } } = this.props
    if (sectionTotalQuestionSet[sectionUUID]) return

    const numOfSectionTypes = getNumberOfSectionTypes(section)
    const updatedSectionTotalQuestionSet = {
      ...sectionTotalQuestionSet,
      [sectionUUID]: numOfSectionTypes
    }

    updateContext({ sectionTotalQuestionSet: updatedSectionTotalQuestionSet })
  }

  async getRetakes () {
    try {
      const { context: { updateContext } } = this.props
      const examRetake = await api.getExamRetakes()
      updateContext({ examRetake })
    } catch (error) {
      console.error('Error:', error)
    }
  }

  skipSection () {
    const { currentScreen, navigation } = this.state
    for (
      let startInd = currentScreen + 1;
      startInd < navigation.length;
      startInd++
    ) {
      const screenObj = navigation[startInd]
      if (screenObj.type === 'section') {
        return this.navigateTo('/' + screenObj.uuid)
      }
    }
  }

  getCurrentSectionData = () => {
    const { currentScreen, navigation } = this.state

    if (!navigation?.length) return null
    const screenData = navigation[currentScreen]

    // eslint-disable-next-line
    const sectionData = screenData?.data?.section_exe
    if (!sectionData) return null

    return screenData.data.section_exe
  }

  navigateToExam (examLink) {
    const isPWA = window.matchMedia('(display-mode: standalone)').matches
    isPWA ? window.open(examLink, '_blank') : window.location.href = examLink
    return null
  }

  navigateTo (uuid) {
    history.push(uuid)
    return this.navigateToUuid(uuid)
  }

  navigateToUuid = async (uuid) => {
    const firstSlashRemovedUUID = uuid.substr(1)
    const UUIDs = firstSlashRemovedUUID.split('/')
    const [parentUUID, , extraUUID] = UUIDs
    let [, childUUID] = UUIDs
    const { navigation } = this.state
    const navigationIndex = navigation.findIndex(
      element => element.uuid === parentUUID)

    if (!parentUUID || navigationIndex === -1) {
      this.setState({
        currentScreen: 0,
        childIndex: -1
      })

      return
    }

    const extraUUIDs = ['questions', 'practice', 'Quizlet']
    if (extraUUID && extraUUIDs.includes(extraUUID)) {
      childUUID = childUUID + `/${extraUUID}`
    }

    const {
      context: {
        cohortCourseInfoUrl,
        cohortData: {
          cohortID,
          cohortModifier,
          cohortMilestones
        },
        cohortExamDates,
        cohortSpecialDays,
        cohortStartDate
      }
    } = this.props

    const cohortData = {
      cohortID,
      cohortStartDate,
      cohortSpecialDays,
      cohortMilestones,
      cohortCourseInfoUrl,
      cohortExamDates,
      cohortModifier
    }

    const isSingleUUID = UUIDs.length === 1
    const navElement = navigation[navigationIndex]
    const { type, children, chapter } = navElement

    if (isSingleUUID) {
      this.setState({
        currentScreen: navigationIndex,
        childIndex: -1
      })
    }
    if (type === 'section') {
      if (children === null) await this.loadSection(parentUUID)
      this.updateCurrentChapter(navElement)
      this.updateSectionTotalQuestionSetContext(navElement)
      if (UUIDs.length === 2) {
        this.handleQuizNavigation({
          section: navElement,
          questionId: UUIDs[1]
        })
      }
    } else if (type === 'exam') {
      this.updateCurrentChapter(navElement)
      this.handleExamNavigation(navElement, cohortData)
    }
    if (!isSingleUUID) {
      const { context: { examRetake, activeCourse } } = this.props
      const isRetakeAllowed = isExamRetakeType({
        examRetake,
        cohortId: activeCourse?.cohort?.id,
        chapter
      })

      const versionBQuestions = isRetakeAllowed &&
        navElement.data.Question
      const questions = navElement.children
      const isRetakeAndVersionB = isRetakeAllowed && versionBQuestions
      const updatedChildren = versionBQuestions || questions
      updatedChildren && updatedChildren.length &&
        updatedChildren.forEach((childElement, childElementIndex) => {
          const { Question_uuid: questionUUID, uuid } = childElement
          const isUUIDMatched = isRetakeAndVersionB
            ? questionUUID === childUUID
            : uuid === childUUID
          if (!isUUIDMatched) return

          this.setState({
            currentScreen: navigationIndex,
            childIndex: childElementIndex
          })
        })
    }
  }

  async loadSection (uuid) {
    this.setState({ showLoader: true })
    const {
      context: {
        isAuditor,
        activeCourse,
        latestCohort,
        studentData: {
          studentAnswers
        },
        user: {
          email
        },
        courseData: {
          course_uuid: activeCourseUUID
        },
        cohortData: {
          startDate
        } = {}
      }
    } = this.props
    const { id: cohortId } = latestCohort || {}
    const { navigation } = this.state
    try {
      const section = await loadSectionData({
        activeCourseUUID,
        uuid,
        isAuditor,
        latestCohort,
        officialCourseName: activeCourse?.name
      })

      if (!section) {
        throw new Error(`Navigation.loadSection: no data from api,
          activeCourseUUID=${activeCourseUUID} uuid=${uuid}`)
      }

      // add discussion link to section_exe
      if (section?.includeDiscussionLink && section?.section_exe) {
        section.section_exe.includeDiscussionLink = section.includeDiscussionLink
      }

      // delete guesswork for stat section 11.2 for cohorts before 2023-03-22
      if (
        config.isStatisticsCourse &&
        startDate < COHORT_START_DATE_2023_03_22 &&
        uuid === STAT_SECTION_11DOT2UUID &&
        // eslint-disable-next-line
        section?.section_exe?.guesswork
      ) { delete section.section_exe.guesswork }

      const [courseData] = navigation
      const { data: { chapters } } = courseData

      const guessworkUUID = getSectionGuessworkUUID(section, chapters)
      const quizzes = section?.section_exe?.quiz?.map(quiz => {
        const { Question: questions } = quiz
        const randomizeMultipleChoicesQuestions = randomizeMultipleChoices({ questions, courseData, email, cohortId, studentAnswers })
        return {
          ...quiz,
          Question: randomizeMultipleChoicesQuestions
        }
      })
      const sectionWithRandomizedQuizzes = {
        ...section,
        section_exe: {
          ...section.section_exe,
          quiz: quizzes
        }
      }
      this.loadSiblingSections(sectionWithRandomizedQuizzes, guessworkUUID)

      this.setState({ showLoader: false })
      this.routeHandling(navigation)
    } catch (err) {
      this.setState({ showFailure: true })
      console.error('Section Loading Issue - Navigation', err)
    }
  }

  loadSiblingSections = (section, guessworkSectionUUID) => {
    const { navigation } = this.state
    const { context: {
      courseData,
      courseMetadata,
      studentData: { studentAnswers }
    } } = this.props

    const courseSections = getCourseSections(courseData, false)
    const children = loadEachSection({
      courseMetadata,
      studentAnswers,
      section,
      courseSections
    })
    const { section_uuid: uuid } = section

    const {
      title,
      warningText,
      difficultyLevel,
      desc: description,
      section_exe: content,
      course_material: courseMaterial,
      work_in_progress: workInProgress
    } = section
    const data = {
      section_uuid: uuid,
      warningText,
      difficultyLevel,
      guessworkSectionUUID,
      section_title: title,
      section_defination: description,
      section_exe: content,
      course_material: courseMaterial,
      work_in_progress: workInProgress
    }

    const nav = navigation.find(n => n.uuid === uuid)
    nav.children = children
    nav.data = data
  }

  goToCourseHome () {
    const { navigation } = this.state

    this.navigateTo(navigation[0].uuid)
    window.outlierLog('Click on', 'Go to Course Home')
  }

  getRedirectUrlforUPittStudents () {
    let {
      context: {
        id,
        isPITStudent,
        upittStudentData,
        cohortData: { name = '' } = {}
      }
    } = this.props

    const isNotValidData = (isPITStudent === undefined ||
      !upittStudentData || name === '' ||
      upittStudentData === 'Error' || !id)

    if (isNotValidData) return

    const { courseId } = config
    const { courses = [] } = upittStudentData

    isPITStudent = 'yes agree'.includes(isPITStudent?.toLowerCase())
    if (!isPITStudent) return

    const isCourseForAudit = name.toLowerCase().includes('audit')
    const isStudentAllowed = filter(courses, { id: courseId }).length

    if (isCourseForAudit || isStudentAllowed) return

    return `https://outlierorg.typeform.com/to/gcHqLVMI#student_id=${id}&course_id=${courseId}`
  }

  hasCourseDwnldOrCodingExmpl (activeSection) {
    const { children } = activeSection
    const { activePath } = this.props

    if (!activeSection || !activePath?.length) return false

    const { childUUID } = extractUUIDs(activePath)
    const { data: { lecturevideos = [] } = {} } = children?.find(child => {
      return child.uuid === childUUID
    }) || {}

    const {
      course_download: courseDownload,
      codingExamples
    } = lecturevideos[0] || {}

    const hasJavaInCourseDownload = courseDownload?.some(resource => {
      return resource?.file?.includes('.java')
    })

    const hasJavaInCodingExamples = codingExamples?.some(resource => {
      return resource?.file?.url?.includes('.java')
    })

    return hasJavaInCourseDownload || hasJavaInCodingExamples
  }

  isExamKeysPageFunction = (screen, childIndex) => {
    const {
      context: {
        overrideKeys,
        cohortData = {},
        courseData,
        examsUnlockedWithKeys,
        studentData = {}
      }
    } = this.props

    const isExam =
      getSectionType(screen.children, childIndex) === EXAM ||
      getQuestionType(screen.children, childIndex) === EXAM

    if (!isExam) return false

    const { chapterUUID } = screen.children?.[childIndex]

    const sectionData = studentData[EXAM_SECTION]
    const isReviewMode = chapterUUID in sectionData
    const isExamUnlockedWithKey = examsUnlockedWithKeys?.[chapterUUID]
    const { overrideName } = getExamKeyFieldNames(courseData, chapterUUID)
    const shouldBypassExamKeys = shouldByPassExamKeysPage({
      cohortData, overrideFieldName: overrideName, overrideKeys
    })

    return allowLiveProctoring(cohortData) &&
      !isExamUnlockedWithKey &&
      !isReviewMode &&
      !shouldBypassExamKeys
  }

  render () {
    if (config.hasUPittBlockerFlag) {
      const typeFormUrl = this.getRedirectUrlforUPittStudents()
      if (typeFormUrl) window.location.href = typeFormUrl
    }

    const { navigation, currentScreen, childIndex, showLoader,
      showFailure
    } = this.state

    const { logout, studentData } = this.context
    const {
      context: {
        cohortData: { cohortID, isGGUCohort } = {},
        cohortStartDate,
        showGuardianPermission,
        showProfCertGuardianPermission,
        showTermsModal,
        dateOfBirth,
        registerStatus,
        noAccessToCourse,
        isStudioCohort,
        isPartnerCohort,
        cohortExamDates,
        activeLearningDatoLink,
        termsAgreement, isNotStudent, isTypeFormRegistered, isAdmin,
        isStudentProgressLoaded, hasAIF, isVIP, dateToRemoveAccess,
        isCourseDataLoading, pwaOnboard,
        under13, isVIPGradedContent,
        zoomConsent,
        isActiveGGUStudent,
        isProfessionalCertificateCourse,
        gguCertificationFormNotSubmitted,
        areAllCoursesProfCert,
        isProfCertRegistered,
        gguParentCertificationFormSubmitted,
        guardianTOSDate,
        isGGUStudent, prospects
      }
    } = this.props

    const currentProspect = prospects?.[0]
    const isGGUOfferNotAccepted = isGGUCohort &&
        currentProspect?.prospectStatus === OFFER_NOT_ACCEPTED

    const { isCollegeSuccessCourse, isPwa } = config
    const { context } = this.props
    const isCollegeSuccess = isCollegeSuccessCourse()
    const showOnboard = isCollegeSuccess && isPwa && pwaOnboard === false

    if (showOnboard) {
      return <OnboardingPwaForm props={context} />
    }
    const practiceExamQuestionHash = '#/ckxh4deha00063f63mpvo1cce/ckxhhxe0200073f63rel2alup'
    if (window.location.hash === practiceExamQuestionHash) {
      const { midTerm1StartDate } = cohortExamDates || {}
      const practiceExamOpenDate = practiceExamReleasedFunction(
        midTerm1StartDate)
      const isPracticeExamOpen = isStudioCohort || (secondsSinceEpoch() > practiceExamOpenDate)
      if (!isPracticeExamOpen) {
        window.location.hash = '#/'
      }
    }

    const { activePath, isJDoodlePage } = this.props
    const isTermsOfUse = activePath === '/terms-of-use'
    const isPrivacyPolicy = activePath === '/privacy-policy'
    const isRegistrationRoute = activePath === '/registration'
    const isProfCertGuardianPermissionForm =
      activePath === PROF_CERT_GUARDIAN_PERMISSION_PATH
    const isGuardianPermissionform = activePath === GUARDIAN_PERMISSION_PATH

    if (isTermsOfUse) return <TermsOfUse />
    if (isPrivacyPolicy) return <PrivacyPolicy />
    if (isProfCertGuardianPermissionForm) return <ProfCertGuardianPermissionForm />
    if (isGuardianPermissionform) return <GuardianPermissionForm />

    if (showFailure) {
      return <NoCourseComponent noCourseType={FAILURE} />
    }

    if (under13 && !isVIP && !isVIPGradedContent) {
      return <NoCourseComponent noCourseType={UNDER_13} />
    }

    let isDateExpired = false
    if (isVIP || isVIPGradedContent) {
      const [month, date, year] = new Date().toLocaleDateString('en-us').split('/')
      const dateString = `${year}-${('0' + month).slice(-2)}-${('0' + date).slice(-2)}`
      // lexicographical comparision will work for this format
      isDateExpired = dateString > dateToRemoveAccess
    }

    if (
      isDateExpired ||
      !registerStatus ||
      noAccessToCourse ||
      isGGUOfferNotAccepted
    ) {
      return <NoCourseComponent noCourseType={NOT_ENROLLED} />
    }

    const { course: {
      showRegistration,
      showAIF,
      showZoomConsentForm
    } = {} } = config

    // This is a generic check for showing forms
    const shouldShowForm = !isAdmin &&
      isNotStudent === false &&
      !isVIP &&
      !isStudioCohort &&
      !isVIPGradedContent

    const isStudentNotRegistered = areAllCoursesProfCert
      ? isProfCertRegistered === false : isTypeFormRegistered === false

    const shouldShowRegistrationForm = shouldShowForm &&
      isStudentNotRegistered &&
      !hasCourseProgress(studentData) &&
      showRegistration &&
      !(isActiveGGUStudent && isGGUCohort)

    if (!config.isPreviewCourse && !isAdmin) {
      if (isVIP || isVIPGradedContent) {
        if (!termsAgreement) return <TermAgreementModal />
      } else {
        if (!cohortID || (!dateOfBirth && !isCollegeSuccess && !isGGUStudent)) {
          return <LoadingSpinner />
        }

        const {
          guardianPermissionRequestedKey
        } = getGuardianPermissionKeys(showProfCertGuardianPermission)

        const guardianPermissionRequested = localStorage.getItem(
          guardianPermissionRequestedKey
        )

        const showPermissionPage = showProfCertGuardianPermission || showGuardianPermission
        if (showPermissionPage) {
          return (
            <GuardianPermission
              {...(guardianPermissionRequested
                ? { initialState: PERMISSION_AWAITED } : {})}
            />
          )
        }

        if (showTermsModal) return <TermAgreementModal />

        // If permission is already granted by the parent, and the state of
        // locally stored permission requested key is true, then show the
        // permission granted page
        const showProfCertGrantedPage = !!(localStorage.getItem(
          PROF_CERT_GUARDIAN_PERMISSION_REQUESTED
        ) && gguParentCertificationFormSubmitted)

        const showGrantedPage = !!(localStorage.getItem(
          PERMISSION_REQUESTED_KEY
        ) && guardianTOSDate)

        const showPermissionGrantedPage = showProfCertGrantedPage || showGrantedPage
        if (showPermissionGrantedPage) {
          return <GuardianPermission initialState={PERMISSION_GRANTED} />
        }
      }
    }

    if (shouldShowRegistrationForm) {
      window.location.hash = '/registration'
      return <RegistrationPage />
    }

    const shouldShowProfCertTypeForm = shouldShowForm &&
      isProfessionalCertificateCourse &&
      !isActiveGGUStudent &&
      gguCertificationFormNotSubmitted

    if (shouldShowProfCertTypeForm) {
      return <ProfessionalCertificationTypeform />
    }

    const partnerCohortCheck = isPartnerCohort || isTypeFormRegistered

    const shouldShowAIF = partnerCohortCheck && !isAdmin &&
      !isStudioCohort && !isGGUStudent &&
      isNotStudent === false && isVIP === false && hasAIF === false &&
      !isVIPGradedContent && showAIF

    const shouldShowGGUAIF = isGGUStudent && !currentProspect?.hasGGUAIF

    if (shouldShowAIF || shouldShowGGUAIF) return <AcademicIntegrityForm />

    const shouldShowZoomConsentForm =
      shouldShowForm && showZoomConsentForm && !zoomConsent

    if (shouldShowZoomConsentForm) {
      window.location.hash = '/zoom-consent'
      return <ZoomConsentForm />
    }

    if (
      isRegistrationRoute && (
        isTypeFormRegistered || isPartnerCohort
      )
    ) window.location.hash = '/'

    if (showLoader || !isStudentProgressLoaded || isCourseDataLoading) {
      return <LoadingSpinner />
    }

    if (!navigation.length) {
      return <NoCourseComponent noCourseType={NO_ACTIVE_COURSE} />
    }

    const hasCourseStarted = secondsSinceEpoch() > cohortStartDate
    const isCurrentCourseGGU = isGGUCourse() && isGGUCohort

    if (isActiveGGUStudent && isCurrentCourseGGU && !hasCourseStarted) {
      const courseStartDate = secondsToFormattedDateShort(cohortStartDate)
      return (
        <NoCourseComponent
          noCourseType={COURSE_NOT_OPEN}
          courseStartDate={courseStartDate}
        />
      )
    }

    const {
      quizNavigationLocked,
      examNavigationLockedState
    } = this.state

    if (quizNavigationLocked) {
      return <QuizNavigationModal />
    }
    if (examNavigationLockedState) {
      return <ExamNavigationModal
        examNavigationLockedState={examNavigationLockedState}
      />
    }

    const {
      children,
      context: {
        isAssessmentQuizExam,
        isAnnouncements,
        navigateToActiveIndex,
        reviewMode
      }
    } = this.props
    const screen = navigation[currentScreen]
    const showDatoLink = isAdmin && activeLearningDatoLink
    const wrapperMarginTop = showDatoLink
      ? '20px'
      : (screen.type === 'extension-request')
        ? '15px'
        : '24px'

    const isPracticeExerciseSet =
      getSectionType(screen.children, childIndex) === PRACTICE_EXERCISES ||
      getQuestionType(screen.children, childIndex) === PRACTICE_EXERCISES

    const hasCourseDwnldOrCodingExmpl = this.hasCourseDwnldOrCodingExmpl(screen)
    const shouldSplitLecturePage = hasCourseDwnldOrCodingExmpl && (
      config.isCSCourse() || config.isTestCourse())
    const isExamKeysPage = this.isExamKeysPageFunction(screen, childIndex)

    return (
      <>
        {showDatoLink &&
        <DatoLink
          link={activeLearningDatoLink}
          isPracticeExerciseRedesign={isPracticeExerciseSet}
        />
        }
        {!isPracticeExerciseSet &&
          !isAnnouncements &&
          !shouldSplitLecturePage &&
          (!isAssessmentQuizExam ||
            (isAssessmentQuizExam && (!navigateToActiveIndex || reviewMode))) &&
          !isJDoodlePage && !isExamKeysPage && (
          <BreadCrumbComponent
            screen={screen}
            childIndex={childIndex}
            wrapperMarginTop={wrapperMarginTop}
          />
        )}
        {
          React.cloneElement(children, {
            logout,
            screen: screen,
            activeChildrenIndex: childIndex,
            shouldSplitLecturePage
          })
        }
      </>
    )
  }
}

NavigationComponent.contextType = Auth0Context
NavigationComponent.displayName = 'NavigationComponent'
export default NavigationComponent

// Convenience function to make copying student data more concise.
function copyStudentProgressContext (context) {
  return copyStudentProgress(context.studentData)
}

// Convenience function to make logging student progress diffs more concise.
export const logStudentProgressDiffContext = (beforeStudentData, context) => {
  return getStudentProgressDiff(
    beforeStudentData,
    context.studentData
  )
}
