import {
  shouldShowCurrentGrade,
  shouldShowFinalGrade
} from '../../../utilities/courseUtils'
import config from '../../../config'
import { GRADE_NOT_APPLICABLE } from '../../../Constants/studentStatus'
import { FINAL_ESSAY } from '../../../Constants/sectionType'
import {
  ASSIGNMENT_PROGRESS,
  EXAM_COMPLETE
} from '../../../Constants/studentContext'

export const getGradeText = ({
  cohort, currentGrade, projectedGrade, isAuditor
}) => {
  if (isAuditor) return ['Audit', false]

  const {
    latestCohort,
    cohortExamDates
  } = cohort || {}
  const { finalGradeDate, studentStatus } = latestCohort || {}
  const { cohortEndDate, courseEndDate } = cohortExamDates || {}
  let text = 'N/A'
  let showTealBar = false

  const notApplicable = GRADE_NOT_APPLICABLE.includes(studentStatus)
  if (notApplicable) {
    text = 'Not Applicable'
    return [text, showTealBar]
  }

  const showFinalGrade = shouldShowFinalGrade(courseEndDate, cohortEndDate)
  const showCurrentGrade = shouldShowCurrentGrade(
    courseEndDate,
    cohortEndDate
  )
  if (showCurrentGrade && config.hasInTimeGradeFlag) {
    text = isNaN(currentGrade) ? currentGrade : `${currentGrade}%`
    return [text, showTealBar]
  }

  const finalGrade = projectedGrade
  if (showFinalGrade && (finalGrade || finalGrade === 0)) {
    text = `${finalGrade}%`
    showTealBar = true
    return [text, showTealBar]
  }

  if (finalGradeDate) {
    const formattedFinalGradeDate =
      new Date(finalGradeDate.replace(/\//g, '-'))
        .toLocaleString('en-us', { month: 'short', day: 'numeric' })
    text = `Available ${formattedFinalGradeDate}`
  }

  return [text, showTealBar]
}

export const checkFinalSubmission = (courseData, studentData, latestCohort) => {
  const { chapters } = courseData || {}
  const { milestones } = latestCohort || {}
  const finalGGUEssay = milestones?.find(milestone => milestone.finalAssignment)
  const finalEssay = chapters?.find(chapter => chapter.title === FINAL_ESSAY)
  const finalExam = chapters?.find(chapter => chapter.isFinalExam)
  const finalEssayUUID = finalGGUEssay?.datoAssignmentUUID ||
  // eslint-disable-next-line camelcase
      finalEssay?.chapter_uuid
  // eslint-disable-next-line camelcase
  const examUUID = finalExam?.chapter_uuid
  return finalEssayUUID
    ? !!studentData?.[ASSIGNMENT_PROGRESS]?.[finalEssayUUID]
    : !!studentData?.[EXAM_COMPLETE]?.[examUUID]
}
