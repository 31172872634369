import styled from 'styled-components'

export const ContentWrapper = styled.div`
  font-size: 18px;
  line-height: 21.6px;
  text-align: left;
  margin: 0px;
`

export const Institution = styled.p`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`

export const Avatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 11px;
  > span { 
    font-weight: 400;
    width: 452px;
    height: 22px;
    text-align: center;
    font-size: 18px;
  }
  p {
    margin-top: 6px;
    color: #B1BFC5;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
  }
`

export const Image = styled.img`
  width: 124px;
  height: 124px;
  margin-bottom: 20px;
  border-radius: 50% !important;
`
