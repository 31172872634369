import React from 'react'
import Modal from 'react-bootstrap/Modal'
import {
  GlobalStyle,
  ExitModalHeader,
  PrimaryButton,
  SecondaryButton,
  ButtonsContainer,
  HorizontalLine
} from './styles/Modal'
import {
  getTimezoneShort,
  secondsToFormattedDateTimeShort
} from '../../utilities/dateTimeUtils'

const CloseIcon = 'images/close-icon.svg'

const ExitModal = (props) => {
  const {
    show,
    handleLeave,
    handleStay,
    lockTimeInSeconds
  } = props

  const modalText = 'You’ve started your submission but haven’t clicked SUBMIT. Would you like to stay to submit your work?'
  const formattedLockTime = secondsToFormattedDateTimeShort(
    lockTimeInSeconds,
    'short'
  ).replace(', ', ' at ') || ''
  const timezone = getTimezoneShort(lockTimeInSeconds) || ''
  const reminderText = `the assignment closes on ${formattedLockTime} ${timezone}.`

  const closeModal = () => {
    handleStay()
  }

  const continueToLink = () => {
    handleLeave()
  }

  return (
    <Modal show={show} centered>
      <GlobalStyle />
      <ExitModalHeader>
        <p data-testid='modal-title'>Are you sure you want to leave?</p>
        <img
          data-testid='img-close'
          src={CloseIcon}
          alt='Close Exit Modal'
          onClick={closeModal}
        />
      </ExitModalHeader>
      <HorizontalLine />
      <Modal.Body>
        <p data-testid='modal-text'>
          {modalText}
        </p>
        <p data-testid='remider-text'>
          <strong>Reminder: </strong>{reminderText}
        </p>
        <ButtonsContainer>
          <PrimaryButton
            data-testid='btn-stay'
            onClick={closeModal}
            height='49px'
          >
            Stay
          </PrimaryButton>
          <SecondaryButton
            data-testid='btn-leave'
            onClick={continueToLink}
            height='49px'
          >
            Leave
          </SecondaryButton>
        </ButtonsContainer>
      </Modal.Body>
    </Modal>
  )
}

ExitModal.displayName = 'ExitModal'

export default ExitModal
