
import React, { Component } from 'react'
import CourseButton from '../CourseButton/CourseButton'
import { emitter } from '../Emitter/Emitter'
import { ON_NAVIGATE_TO, ON_REVIEW_MODE } from '../../Constants/emitterKeys'

class QuizSectionHomepageButton extends Component {
  constructor (props) {
    super(props)
    this.getButtonAttributes = this.getButtonAttributes.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  getButtonAttributes () {
    const {
      sectionUuid,
      questionSetUuid,
      isCompleted
    } = this.props

    const text = isCompleted ? 'Review' : 'Start'
    const className = {
      'btn-primary': !isCompleted,
      'btn-secondary': isCompleted
    }

    return {
      text,
      className,
      dataUuid: sectionUuid + '/' + questionSetUuid
    }
  }

  handleClick () {
    const { sectionUuid, questionSetUuid, isCompleted } = this.props
    emitter.emit(ON_NAVIGATE_TO, '/' + sectionUuid + '/' + questionSetUuid)
    emitter.emit(ON_REVIEW_MODE, isCompleted)
  }

  render () {
    const {
      isUnlocked,
      renderLockedStatus
    } = this.props

    if (!isUnlocked) return renderLockedStatus()

    const { text, className, dataUuid } = this.getButtonAttributes()

    return (
      <CourseButton
        className={className}
        onClick={this.handleClick}
        dataUuid={dataUuid}
        dataCypress='quizStartOrReviewButton'
        style={{ width: '120px' }}
      >
        {text}
      </CourseButton>
    )
  }
}

export default QuizSectionHomepageButton
