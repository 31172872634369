import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const DatoLinkContainer = styled.div`
  display: flex;
  font-family: Lato;
  width: 100%;
  background-color: #25272b;
  padding: 20px 0;
  align-items: baseline;
  justify-content: center;

  span {
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
    margin-right: 10px;
  }

  a {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    color: #5fc4b8;

    &:disabled {
      color: #b8fff7;
      opacity: 0.3;
    }
    &:not(:disabled) {
      &:active {
        color: #b8fff7;
        opacity: 0.3;
      }
      &:hover {
        color: #b8fff7;
        text-decoration: none;
      }
    }
  }

  ${mediaqueries.mobile`
    ${({ isPracticeExerciseRedesign }) => (
    isPracticeExerciseRedesign && `margin-top: 21px;`)}
  `}
`
