import React from 'react'
import Modal from 'react-bootstrap/Modal'
import {
  GlobalStyle,
  ExitModalHeader,
  PrimaryButton,
  SecondaryButton,
  ButtonsContainer
} from './style'
import config from '../../config'

const CloseIcon = 'images/close-icon.svg'

const ExitModal = (props) => {
  const {
    show,
    handleLeave,
    handleStay
  } = props

  const isCollegeSuccess = config.isCollegeSuccessCourse()
  const modalText = isCollegeSuccess
    ? 'Active Learning includes key concepts, resources, and exercises, some of which are not discussed in Lectures.'
    : 'Active Learning covers key concepts that will be tested in Quizzes and Exams, some of which are not discussed in Lectures.'

  const closeModal = () => {
    handleStay()
  }

  const continueToLink = () => {
    handleLeave()
  }

  return (
    <Modal show={show} centered>
      <GlobalStyle />
      <ExitModalHeader>
        <img
          data-testid='img-close'
          src={CloseIcon}
          alt='Close Exit Modal'
          onClick={closeModal}
        />
      </ExitModalHeader>
      <Modal.Body>
        <p data-testid='modal-title'>Are you sure you want to leave?</p>
        <p data-testid='modal-text'>
          {modalText}
        </p>
        <ButtonsContainer>
          <PrimaryButton
            data-testid='btn-stay'
            onClick={closeModal}
            height='49px'
          >
            Stay
          </PrimaryButton>
          <SecondaryButton
            data-testid='btn-leave'
            onClick={continueToLink}
            height='49px'
          >
            Leave
          </SecondaryButton>
        </ButtonsContainer>
      </Modal.Body>
    </Modal>
  )
}

ExitModal.displayName = 'ExitModal'

export default ExitModal
