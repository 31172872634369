import React, { Component } from 'react'
import ModalComponent from '../ModalComponent/ModalComponent'

class ExamNavigationModal extends Component {
  constructor (props) {
    super(props)
    this.setModalShow = this.setModalShow.bind(this)
    this.state = {
      modalShow: true
    }
  }

  setModalShow (modalShow) {
    this.setState({ modalShow })
    window.location.href = '/'
  }

  render () {
    const { examNavigationLockedState } = this.props
    const { modalShow } = this.state
    return (
      <>
        <ModalComponent
          className='exam-navigation-modal'
          show={modalShow}
          handleClose={() => this.setModalShow(false)}
        >
          <span className='p-3'>
            {examNavigationLockedState === 1
              ? 'The assessment you tried to access has not yet been unlocked.'
              : 'The period for taking this exam has closed. Please contact us at success@outlier.org to discuss possible options.'
            }
          </span>
        </ModalComponent>
      </>
    )
  }
}
export default ExamNavigationModal
