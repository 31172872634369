import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  ChecklistContainer,
  ChecklistWrapper,
  GlobalStyle,
  ChecklistBody
} from './styles'
import { useDetectOutsideRefEvent } from './useDetectOutsideRefEvent'
import LoadingAnimation from '../LoadingSpinner/LoadingAnimation'
import ChecklistHeader from './ChecklistHeader/ChecklistHeader'
import ChecklistIntro from '../Checklist/ChecklistIntro/ChecklistIntro'
import NoCourse from './NoCourse/NoCourse'
import CourseList from './CourseList/CourseList'
import CourseActivities from './CourseActivities/CourseActivities'
import { getLatestCohort } from '../../utilities/courseUtils'
import {
  dateToSecondsSinceEpoch,
  secondsSinceEpoch
} from '../../utilities/dateTimeUtils'
import api from '../../api'
import { CHECKLIST_INTRO_VIEWED } from '../../Constants/studentContext'

const Checklist = ({
  toggleCheckList,
  isOpen,
  isGGUCohort
}) => {
  // calling students courses api because studentCourses from context has only
  // the active course of the student. Checklist needs all the courses of the student.
  const [studentCourses, setStudentCourses] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [courseDetail, setCourseDetail] = useState({})
  const [studentData, setStudentData] = useState({})
  const [showChecklistIntro, setShowChecklistIntro] = useState(false)

  const fetchStudentCourses = async () => {
    if (studentCourses?.length) return

    setIsLoading(true)
    const [studentCoursesData, studentData] = await Promise.all([
      api.getStudentCourses(false, false, true),
      api.getStudentData()
    ])
    setStudentData(studentData)
    setIsLoading(false)
    setStudentCourses(studentCoursesData?.courses || [])
    setScreen(studentCoursesData?.courses?.length >= 1 ? 'courses' : 'activities')
  }
  const isMultiCourse = studentCourses.length > 1
  const [screen, setScreen] = useState(isMultiCourse ? 'courses' : 'activities')

  useEffect(() => {
    if (!isOpen) return
    fetchStudentCourses()

    // eslint-disable-next-line
  }, [isOpen])

  const drawerRef = useRef(null)
  useDetectOutsideRefEvent(drawerRef, () => {
    toggleCheckList(false)
  })

  const onGoBack = () => {
    if (courseDetail) {
      setCourseDetail({})
    }
  }

  const onCourseClick = useCallback((course) => {
    setCourseDetail(course)
  }, [])

  useEffect(() => {
    if (!studentData) return

    setShowChecklistIntro(!studentData[CHECKLIST_INTRO_VIEWED])
  }, [studentData])

  const renderChecklistBody = (hasCohortStarted, isGGUSelectedCohort) => {
    if (isLoading) return <LoadingAnimation />

    if (showChecklistIntro) {
      return <ChecklistIntro setViewChecklist={setViewChecklist} />
    }

    const noCourses = !studentCourses.length
    if (noCourses) return <NoCourse />

    if (courseDetail?.id) {
      return <CourseActivities
        isFromCourseDetail
        course={courseDetail}
        toggleCheckList={() => {
          toggleCheckList()
          setCourseDetail('')
        }}
        hasCohortStarted={hasCohortStarted}
        isOpen={isOpen}
        isGGUCohort={isGGUSelectedCohort}
      />
    }
    if (screen === 'courses') {
      return (
        <CourseList
          courses={studentCourses}
          isGGUCohort={isGGUCohort}
          onClick={onCourseClick}
          toggleCheckList={toggleCheckList}
        />
      )
    }

    return (
      <h6
        data-testid='checklistBody'
        style={{
          width: '100%',
          marginTop: '20px',
          textAlign: 'center'
        }}
      >
        Checklist Body
      </h6>
    )
  }

  const setViewChecklist = async () => {
    setIsLoading(true)
    try {
      const response = await api.setStudentData(
        CHECKLIST_INTRO_VIEWED, { [CHECKLIST_INTRO_VIEWED]: true }
      )
      if (response.data?.success) {
        setShowChecklistIntro(false)
      }
    } catch {} finally {
      setIsLoading(false)
    }
  }

  const latestCohort = getLatestCohort(courseDetail)
  const { dateStart, name } = latestCohort || {}
  const isGGUSelectedCohort = name?.toLowerCase().includes('ggu')
  const currentDate = secondsSinceEpoch()
  const hasCohortStarted =
    dateToSecondsSinceEpoch(new Date(dateStart)) <= currentDate

  const isCourseDetailSection = isMultiCourse && !!courseDetail.id && !courseDetail.singleCourse
  const progress = isCourseDetailSection ? courseDetail.progress : courseDetail.fullCourseProgress
  const { isCaughtUp } = courseDetail
  return (
    <>
      {isOpen && <GlobalStyle />}
      <ChecklistWrapper isOpen={isOpen}>
        <ChecklistContainer ref={drawerRef} isOpen={isOpen}>
          <ChecklistHeader
            setScreen={setScreen}
            removeProgressBar={showChecklistIntro}
            isCourseDetailSection={isCourseDetailSection}
            toggleSideDrawer={toggleCheckList}
            onClick={onGoBack}
            progress={hasCohortStarted ? progress : 0}
            isCaughtUp={isCaughtUp}
          />
          <ChecklistBody>
            {isOpen
              ? renderChecklistBody(hasCohortStarted, isGGUSelectedCohort)
              : null
            }
          </ChecklistBody>
        </ChecklistContainer>
      </ChecklistWrapper>
    </>
  )
}

Checklist.displayName = 'Checklist'
export default Checklist
