import styled from 'styled-components'

export const StyledText = styled.p`
    margin: 0px;
    font-size: 14px;
    margin-right: 10px;
    margin-top: 12px !important;
    color: #B1BFC5;
    font-weight: 400;
    line-height: 17px;
`
