import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const ContentWrapper = styled.div`
  font-size: 18px;
  line-height: 21.6px;
  text-align: left;
  display: flex;
  margin-bottom: 12px;

  span:last-child {
    &:disabled {
      color: #5fc4b8;
      opacity: 0.3;
    }
    &:not(:disabled) {
      &:active {
        color: #b8fff7;
        opacity: 0.3;
      }
      &:hover {
        filter: brightness(1.5);
      }
    }
  }
  
  ${mediaqueries.mobile`flex-direction: column;`}
  `

export const ThemeWrapper = styled.span`
  margin-bottom: 12px;
  height: 17px;
  width: 314px;
  line-height: 16.8px;
  font-weight: 400;
  font-size: 14px;
  margin-top: 6px;
`
export const NameWrapper = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 21.6px;
  width: 314px;
  height: 22px;
`

export const Input = styled.input`
  width: 20px;
  height: 20px;
  margin-right: 14px;
  cursor: pointer;
  background-color: #343a40;
  :after {
    content: "";
    border-radius: 50%;
    display: inline-block;
    border: 3px solid #77b1af;
    padding: 0px;
    margin-right: 14px;
    width: 20px;
    height: 20px;
    background-color: ${props => props.defaultChecked ? '#77b1af' : '#161618'};
  }
`

export const Footer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 16px;
  display: flex;
  border-top: 2px solid #343a40;
  justify-content: space-between;
  button {
    width: 160px;
    max-height: 40px;
  }
  p {
    font-size: 18px;
  }
`

export const ReadBio = styled.span`    
  font-size: 14px;
  line-height: 17px;
  color: #5FC4B8;
  cursor: pointer;
  width: 73px;
  height: 17px;
  margin-left: 12px;
  font-weight: 700;
  ${mediaqueries.mobile`
    margin-left: 33px;
    margin-bottom: 11px;
  `}
`

export const ArrowWrapper = styled.div`
  width: 12px;
  height: 6px;
  display: inline;
  margin-left: 13px;
  margin-top: -3px;
`
