import React, { useEffect, useMemo, useState } from 'react'
import CourseCard from '../CourseCard/CourseCard'
import api from '../../../api'
import {
  dateToSecondsSinceEpoch,
  secondsSinceEpoch
} from '../../../utilities/dateTimeUtils'
import {
  getCohortExamDates,
  getCohortExamDateSecondsSinceEpoch,
  getCohortSpecialDays,
  getCohortStartSecondsSinceEpoch,
  isInProgressBeforeCutOffDate,
  getLatestCohort
} from '../../../utilities/courseUtils'
import {
  calculateCourseProgress,
  getActiveAssessmentsLabelAndColor,
  getFormattedCourseList
} from './courseListUtils'
import {
  getCourseResourcesSchedule,
  getFinalDropOrWithdrawalDate
} from '../../ResourcesSection/Utils'
import LoadingAnimation from '../../LoadingSpinner/LoadingAnimation'
import NoCourse from '../NoCourse/NoCourse'
import {
  removeQuizzesFromChapters,
  removeQuizzesFromSection
} from '../../../utilities/chapterUtils'
import { isUserAuditor } from '../../../utilities/userUtils'
import config from './../../../config'

const CourseList = ({ courses, isGGUCohort, onClick, toggleCheckList }) => {
  const [courseList, setCourseList] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchCoursesAndCourseData()
    // eslint-disable-next-line
  }, [courses])

  /**
   * Fetches course data, syllabus details and all sections data for each course
   * and sets the courseList state with the data. If the course has no schedule, it is filtered out.
   */
  const fetchCoursesAndCourseData = async () => {
    setLoading(true)

    const coursesWithStudentProgressAndCourseData = await Promise.all(
      courses.map(course => getCourseAndCourseData(course))
    )
    const coursesWithSyllabuses = coursesWithStudentProgressAndCourseData.filter(Boolean)

    setCourseList(coursesWithSyllabuses)
    setLoading(false)
  }

  const getCourseAndCourseData = async course => {
    const {
      id: activeCourseUUID,
      cohort: { id: cohortId }
    } = course || {}
    // get course data, cohort schedule and all section data by course id
    const isPreviewCourse = config.getIsPreviewCourse(activeCourseUUID)
    const [
      datoCourseData,
      fetchedSchedule,
      datoSectionData,
      studentCourseProgress
    ] = await Promise.all([
      api.getCourseData(activeCourseUUID),
      api.getSyllabusDetails(cohortId),
      api.getAllSectionsData(activeCourseUUID),
      api.getStudentProgress(isPreviewCourse, activeCourseUUID)
    ])
    const { syllabusData } = fetchedSchedule || {}
    const hasSchedule =
      syllabusData?.find((schedule) => schedule.sections.length > 0)

    if (!hasSchedule) return null

    const latestCohort = getLatestCohort(course)
    const studentProgress = (() => {
      const studentAnswers = Array.isArray(studentCourseProgress?.studentAnswers)
        ? studentCourseProgress?.studentAnswers.reduce((acc, answer) => {
          acc[answer.uuid] = answer
          return acc
        }, {})
        : studentCourseProgress.studentAnswers
      return {
        ...studentCourseProgress,
        studentAnswers
      }
    })()

    let courseData = datoCourseData
    let allSectionsData = datoSectionData
    const isAuditor = isUserAuditor(latestCohort)
    if (isAuditor) {
      const { dateStart, finalExamEndTime, cohortEndTime, duration } = latestCohort || {}
      const isCohortInProgress = isInProgressBeforeCutOffDate({
        dateStart,
        finalExamEndTime,
        cohortEndTime,
        duration,
        officialCourseName: course?.name
      })
      if (!isCohortInProgress) {
        courseData = removeQuizzesFromChapters(datoCourseData)
        allSectionsData = datoSectionData?.map(section => {
          const newSectionData = removeQuizzesFromSection(section)
          return newSectionData
        }) || []
      }
    }

    const cohortSpecialDays = getCohortSpecialDays(latestCohort)
    const cohortExamDates = getCohortExamDates(latestCohort)
    const cohortStartDate = getCohortStartSecondsSinceEpoch(latestCohort)
    const currentDate = secondsSinceEpoch()
    const {
      duration,
      finalExamEndTime,
      cohortEndTime,
      finalDropDate,
      dateStart,
      finalWithdrawalDate,
      finalGradeDate,
      relationship: { fields: { liveProctoring } = {} } = {},
      milestones: cohortMilestones
    } = latestCohort || {}

    const isCohortEnded =
      getCohortExamDateSecondsSinceEpoch(cohortEndTime || finalExamEndTime) < currentDate
    const hasCohortStarted =
      dateToSecondsSinceEpoch(new Date(dateStart)) <= currentDate

    courseData.isLiveProctoring = !!liveProctoring

    const dropAndWithdrawalDates = {
      finalWithdrawalDate: getFinalDropOrWithdrawalDate(finalWithdrawalDate),
      finalDropDate: getFinalDropOrWithdrawalDate(finalDropDate)
    }

    const courseResourcesSchedule = getCourseResourcesSchedule({
      startDate: cohortStartDate,
      duration,
      fetchedSchedule,
      finalDropDate,
      finalWithdrawalDate,
      finalGradeDate,
      cohortMilestones,
      cohortSpecialDays,
      cohortExamDates,
      courseData,
      isAuditor,
      isGGUCohort,
      liveProctoring
    })

    const courseProgress = calculateCourseProgress({
      courseData,
      courseResourcesSchedule,
      studentProgress,
      isCohortEnded,
      allSectionsData,
      cohortExamDates,
      cohortStartDate,
      dropAndWithdrawalDates
    })

    return {
      ...course,
      courseResourcesSchedule,
      activityCount:
        hasCohortStarted && courseProgress?.activityCount,
      isAudit: isAuditor,
      isCohortEnded,
      hasCohortStarted,
      isCaughtUp: courseProgress?.isCaughtUp,
      isWorkingAhead: courseProgress?.isWorkingAhead,
      hasWorkedAheadUntilExam: courseProgress?.hasWorkedAheadUntilExam,
      hasAssessmentAfterTermBreak: courseProgress?.hasAssessmentAfterTermBreak,
      assessmentAfterTermBreakType: courseProgress?.assessmentAfterTermBreakType,
      workingAheadWeekToDisplay: courseProgress?.workingAheadWeekToDisplay,
      hasWorkedAheadAndNotCaughtUp: courseProgress?.hasWorkedAheadAndNotCaughtUp,
      weekProgress: courseProgress?.weekProgress,
      progress: courseProgress?.progress,
      chapters: courseData.chapters,
      activeAssessments:
        getActiveAssessmentsLabelAndColor(
          courseResourcesSchedule,
          courseData,
          studentProgress,
          activeCourseUUID
        ) || [],
      allSectionsData,
      key: course.id,
      studentData: studentProgress
    }
  }

  const formattedCourseList = useMemo(() => getFormattedCourseList(courseList), [courseList])

  useEffect(() => {
    if (formattedCourseList?.length === 1 && !loading) {
      onClick({ ...formattedCourseList[0], singleCourse: true })
    }
  }, [formattedCourseList, onClick, loading])

  if (loading) {
    return <LoadingAnimation />
  }

  if (!formattedCourseList?.length) return <NoCourse />

  return formattedCourseList?.map((node) => (
    <CourseCard {...node} onClick={() => onClick(node)} />
  ))
}

export default CourseList
