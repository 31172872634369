import React, { Component } from 'react'
import {
  Section, Heading, Date, Subtitle, Paragraph, ListItem,
  ContentList, MoveToSection
} from './style'

class PrivacyPolicy extends Component {
  constructor (props) {
    super(props)

    this.infoCollect = React.createRef()
    this.infoUse = React.createRef()
    this.infoShare = React.createRef()
    this.cookies = React.createRef()
    this.infoRetain = React.createRef()
    this.privacyRights = React.createRef()
    this.caResidents = React.createRef()
    this.caRights = React.createRef()
    this.neRights = React.createRef()
    this.childPrivacy = React.createRef()
    this.thirdParty = React.createRef()
    this.updatePolicy = React.createRef()
    this.contactPolicy = React.createRef()
    this.whereInformation = React.createRef()
    this.DNT = React.createRef()
  }

  scrollToRef = (ref) => window.scrollTo(0, this[ref].current.offsetTop)

  render () {
    return (
      <React.Fragment>
        <div className='container'>
          <Section>
            <Heading>PRIVACY POLICY</Heading>

            <Date>Last updated April 28, 2021</Date>

            <Paragraph>
              Thank you for choosing to be part of our community at Outlier.org Inc. (“Outlier,” the "Company", "we", "us", "our"). We are committed to protecting your personal information and your right to privacy. In this privacy policy, we seek to explain to you what we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If you have any questions or concerns about this privacy policy, or our practices with regards to your personal information, please contact us at <a href='mailto:info@outlier.org'>info@outlier.org</a>
.            </Paragraph>

            <Paragraph>
              When you visit our websites that deliver our courses to you (e.g., calculus.outlier.org) (together, the “Course Website”), and use our services related to them (the “Services”), you trust us with your personal information. Although you may have accessed the Course Website though our website <a href='https://outlier.org' target='_blank' rel='noopener noreferrer'>https://outlier.org</a> (the “Website”), they are different websites and different privacy policies apply to them. This privacy policy applies to all information we collect from you after you have enrolled in a course and/or when you visit our websites that deliver our courses to you. Information that we collected from you before you were enrolled in a course is subject to a separate privacy policy, available on our Website <a href='https://www.outlier.org/pages/privacy' target='_blank' rel='noopener noreferrer'>https://www.outlier.org/pages/privacy</a>.            </Paragraph>

            <Paragraph>
              Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.
            </Paragraph>

            <br />

            <Subtitle>TABLE OF CONTENTS</Subtitle>

            <ContentList>
              <ListItem isLink onClick={() => this.scrollToRef('infoCollect')}><u>WHAT INFORMATION DO WE COLLECT?</u></ListItem>
              <ListItem isLink onClick={() => this.scrollToRef('infoUse')}><u>HOW DO WE USE YOUR INFORMATION?</u></ListItem>
              <ListItem isLink onClick={() => this.scrollToRef('infoShare')}><u>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</u></ListItem>
              <ListItem isLink onClick={() => this.scrollToRef('cookies')}><u>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</u></ListItem>
              <ListItem isLink onClick={() => this.scrollToRef('infoRetain')}><u>HOW LONG DO WE KEEP YOUR INFORMATION?</u></ListItem>
              <ListItem isLink onClick={() => this.scrollToRef('privacyRights')}><u>HOW DO WE KEEP YOUR INFORMATION SAFE?</u></ListItem>
              <ListItem isLink onClick={() => this.scrollToRef('whereInformation')}><u>WHERE DO WE STORE YOUR INFORMATION?</u></ListItem>
              <ListItem isLink onClick={() => this.scrollToRef('caResidents')}><u>WHAT ARE YOUR PRIVACY RIGHTS?</u></ListItem>
              <ListItem isLink onClick={() => this.scrollToRef('caRights')}><u>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</u></ListItem>
              <ListItem isLink onClick={() => this.scrollToRef('neRights')}><u>DO NEVADA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</u></ListItem>
              <ListItem isLink onClick={() => this.scrollToRef('childPrivacy')}><u>CHILDREN’S PRIVACY</u></ListItem>
              <ListItem isLink onClick={() => this.scrollToRef('thirdParty')}><u>THIRD-PARTY WEBSITES AND CONTENT</u></ListItem>
              <ListItem isLink onClick={() => this.scrollToRef('DNT')}><u>DO-NOT-TRACK</u></ListItem>
              <ListItem isLink onClick={() => this.scrollToRef('updatePolicy')}><u>DO WE MAKE UPDATES TO THIS POLICY?</u></ListItem>
              <ListItem isLink onClick={() => this.scrollToRef('contactPolicy')}><u>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</u></ListItem>
            </ContentList>

            <br />

            <Subtitle ref={this.infoCollect}>1. WHAT INFORMATION DO WE COLLECT?</Subtitle>

            <Paragraph>
              <b><em>Personal information you disclose to us</em></b>
            </Paragraph>

            <Paragraph>
              <em>In Short: We collect personal information that you provide to us such as name, address, contact information, and log in information. We also collect directory information that our educational partners may share with us. That can include, but may not be limited to, your name, address, and contact information.</em>
            </Paragraph>

            <Paragraph>
              We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services or when you contact us.
            </Paragraph>

            <Paragraph>
              The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the products and features you use. The personal information we collect can include the following:
            </Paragraph>

            <Paragraph>
              <b>Personal Identifiers.</b> We may collect your name, address, Internet Protocol (IP) address, e-mail address, phone number and similar identifiers.
            </Paragraph>

            <Paragraph>
              <b>Information About You.</b> We may collect information related to your ethnicity, gender, date of birth, and country of citizenship.
            </Paragraph>

            <Paragraph>
              <b>Commercial Information.</b> We may collect information regarding your purchase or enrollment in our Services.
            </Paragraph>

            <Paragraph>
              <b>Credentials.</b> We may collect passwords, password hints, and similar security information used for authentication and account access.
            </Paragraph>

            <Paragraph>
              <b>Education Information.</b> We may collect information regarding your educational level, field(s) of study, schools you are interested in applying to, academic enrollment, academic performance, and responses to survey regarding readiness for online learning.
            </Paragraph>

            <Paragraph>
              <b>User Profiles.</b> We may combine the information you provide us and that we collect about you to create a user profile so that we can more effectively deliver our Services to you and manage our Course Website.
            </Paragraph>

            <Paragraph>
              <em><b>Information automatically collected</b></em>
            </Paragraph>

            <Paragraph>
              <em>In Short: Some information – such as IP address and/or browser and device characteristics – is collected automatically when you visit our Course Website.</em>
            </Paragraph>

            <Paragraph>
              We automatically collect certain information when you visit, use or navigate the Course Website such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services and other technical information. This information is used to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
            </Paragraph>

            <Paragraph>
              Like many businesses, we also collect information through cookies and similar technologies. For more information on our use of cookies, please see <MoveToSection onClick={() => this.scrollToRef('cookies')}>Section 4</MoveToSection> of this policy.
            </Paragraph>

            <Subtitle ref={this.infoUse}>2. HOW DO WE USE YOUR INFORMATION?</Subtitle>

            <Paragraph>In Short:  We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.</Paragraph>
            <Paragraph>We use the information we collect or receive to:</Paragraph>
            <ul type='square'>
              <ListItem>
                <b> Facilitate account creation and logon process.</b> If you sign up for a course, we use the information you provide us to complete that registration. Additionally, if you choose to link your account with us to a third-party account (such as your Shopify, Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate the account creation and logon process.
              </ListItem>

              <ListItem>
                <b> Deliver targeted advertising to you.</b> Once you enroll in a class, you will be opted out of all targeted or behavioral advertising. You can opt back in by sending a request to marketing@outlier.org. You may opt-in to the receipt of marketing emails and/or text messages when you enroll in a course. If you opt in to the receipt of these messages, we may use your personal information for marketing or promotional purposes, and may use your information to develop and display content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness
              </ListItem>

              <ListItem>
                If you request that we send you marketing and promotional communications, we and/or our third-party marketing partners may use the personal information you send us to provide you with that information. You can opt-out of our marketing emails at any time (see the <MoveToSection onClick={() => this.scrollToRef('caResidents')}>"WHAT ARE YOUR PRIVACY RIGHTS?" </MoveToSection> section of this policy below).
              </ListItem>

              <ListItem>
                <b>Administer the Services.</b> We may use your personal information to send you information about the Services and new features and/or information about changes to our terms, conditions, and policies. We may also use your information to allow your teachers or school or program administrators to review your course progress.
              </ListItem>

              <ListItem>
                <b>Fulfill and manage your orders.</b> We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services. This may include reporting grades to the educational institutions with whom we partner.
              </ListItem>

              <ListItem>
                <b>Request Feedback.</b> We may use your information to request feedback and to contact you about your use of our Services.
              </ListItem>

              <ListItem>
                <b> Protect our Services.</b> We may use your information as part of our efforts to keep our Services safe and secure (for example, for fraud monitoring and prevention) and to enforce our terms and conditions.
              </ListItem>

              <ListItem>
                <b>Enable user-to-user communications.</b> We may use your information in order to enable user-to-user communications with each user's consent.
              </ListItem>

              <ListItem>
                <b> Respond to legal requests and comply with our legal obligations.</b> We may use your information to comply with our legal obligations. For example, if we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.
              </ListItem>

              <ListItem>
                <b> Respond to user inquiries/offer support to users.</b> We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services.
              </ListItem>
            </ul>
            <Paragraph>
              Where we process your personal information with your consent, you may withdraw that consent at any time by emailing info@outlier.org. Where we require your personal information in order to enter into or perform a contract with you, should you decline to provide that information we will not be able to conclude or perform that contract.
            </Paragraph>

            <Subtitle ref={this.infoShare}>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</Subtitle>
            <Paragraph>In Short:  We only share information with your consent, to comply with laws, or to provide you with Services and relevant marketing.</Paragraph>

            <Paragraph>We may process or share data for the following purposes:</Paragraph>
            <ul type='square'>
              <ListItem>
                <b>Consent.</b> We may process or disclose your data if you have given us specific consent to use your personal information in a specific purpose.
              </ListItem>

              <ListItem>
                <b>Compliance with our <a href='#terms-of-use' target='_blank' rel='noopener noreferrer'>Terms of Use</a>.</b> We may process or disclose your personal information to ensure compliance with our Terms of Use.
              </ListItem>

              <ListItem>
                <b> Delivery of the Services.</b> We may process or disclose your information where we believe it is necessary to provide you with Services; investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities; or as evidence in litigation in which we are involved.
              </ListItem>

              <ListItem>
                <b>Legal Obligations.</b> We may process or disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
              </ListItem>

              <ListItem>
                <b>Schools, Universities and Educational Programs.</b> We may share your data with our partner colleges, universities, schools and educational programs so that those organizations can provide our users with academic credits for participating in and completing our Services and monitor student progress.
              </ListItem>

              <ListItem>
                <b>Vendors, Consultants and Other Third-Party Service Providers.</b> We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work in connection with providing you the Services or delivering information to you that you have requested. We share information with these third parties only where (a) the sharing of your personal information is necessary for them to carry out such support services and (b) those service providers agree to use any personal information they receive in accordance with our instructions.
              </ListItem>

              <ListItem>
                <b> Third-Party Analytics.</b> We use selected third parties to collect data about how you interact with the Course Website and our Services over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this privacy policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.
              </ListItem>

              <ListItem>
                <b>Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
              </ListItem>

              <ListItem>
                <b>Third-Party Advertisers.</b> Once you enroll in a class, you will automatically be opted out of all targeted or behavioral advertising. You can opt back in by sending a request to marketing@outlier.org. You may opt-in to the receipt of marketing emails and/or text messages when you enroll in a course. If you opt in to the receipt of these messages, we may use your personal information for marketing or promotional purposes, and may use your information to develop and display content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.
              </ListItem>
            </ul>

            <Subtitle ref={this.cookies}>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?  </Subtitle>
            <Paragraph>
              In Short:  Yes, we may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information and to track users across devices.
            </Paragraph>
            <Paragraph><i>What are cookies?</i></Paragraph>
            <Paragraph>
              A cookie is a piece of information contained in a text file that is stored in your internet browser or elsewhere on your hard drive. Cookies are transferred from our Course Website to your computer, phone or tablet and allows our Course Website to identify a user's device whenever that user returns to the Course Website. Cookies are used in order to make websites work more efficiently, improve the user experience, and to provide information to the owners of the site.
            </Paragraph>
            <Paragraph><i>Our use of cookies</i></Paragraph>
            <Paragraph>
              While our Services and Course Website do not create any of our own cookies, our Services and Course Website may contain certain third-party cookies. We may use cookies and other tracking technologies to collect and store your information:
            </Paragraph>
            <Paragraph><b>FullStory</b>. Certain of our Services use FullStory cookies. These cookies use information about you to recognize the same user over multiple use sessions and across our Services.</Paragraph>

            <Paragraph><b>Segment</b>. Certain of our Services use Segment tracking technologies. Segment cookies and login information are used to recognize users across devices.</Paragraph>

            <Paragraph><b>Usersnap</b>. Certain of our Services use Usersnap cookies. These cookies use information about you and enable our Services to recognize your browser. We also use these cookies to save your preferences for future visits.</Paragraph>

            <Paragraph><b>Google Analytics</b>. Our Course Website uses Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses cookies to help the Course Website analyze how users use the site. The information generated by the cookie about your use of the Course Website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the Course Website, compiling reports on Course Website activity for us and providing other services relating to your Course Website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google will not associate your IP address with any other data held by Google or by us.</Paragraph>

            <Paragraph><b>Wiris.</b> Certain of our Services use Wiris cookies, which use information about you to recognize users and save their preferences in connection with Wiris’ mathematical notation entry tool and CalcMe calculation engine.</Paragraph>

            <Paragraph><b>Auth0.</b> Certain of our Services use Auth0 cookies, which use information about you to recognize a particular user.</Paragraph>

            <Paragraph><b>MathJax.</b> Certain of our Services use MathJax cookies, which use information about you to remember your preferences.</Paragraph>

            <Paragraph><i>Cookie management</i></Paragraph>
            <Paragraph>You have the ability to accept or decline cookies. Most browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience our Services.</Paragraph>
            <Paragraph>You can use the methods described below to manage cookies. You must take these steps on each browser or device that you use. If you replace, change or upgrade your browser or device, or delete your cookies, you may need to use these opt-out tools again.</Paragraph>
            <ul>
              <ListItem className='btn-link pointer'>Google Chrome</ListItem>
              <ListItem className='btn-link pointer'>Apple Safari</ListItem>
              <ListItem className='btn-link pointer'>Mozilla Firefox</ListItem>
              <ListItem className='btn-link pointer'>Microsoft Edge</ListItem>
            </ul>
            <Paragraph>If the browser you use is not listed above, please refer to your browser’s help menu for information on how to manage cookies.</Paragraph>

            <Paragraph><b><em>Pixel use</em></b></Paragraph>
            <Paragraph>
              Pixels are small files automatically downloaded to your browser when you interact with certain parts of our Website or Services. We use pixels from several websites on which we advertise in order to track your interaction with our marketing, Website and Services. These pixels may automatically collect data about you as described above in Section 1, <b>Information automatically collected</b>.
            </Paragraph>

            <Subtitle ref={this.infoRetain}>5. HOW LONG DO WE KEEP YOUR INFORMATION?</Subtitle>
            <Paragraph><em>In Short:  We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.</em></Paragraph>
            <Paragraph>
              We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements).
            </Paragraph>
            <Paragraph>
              When we have no ongoing business need to process your personal information, we will either delete or deidentify it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
            </Paragraph>

            <Subtitle ref={this.privacyRights}>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</Subtitle>
            <Paragraph>We have implemented reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</Paragraph>

            <Subtitle ref={this.whereInformation}>7. WHERE DO WE STORE YOUR INFORMATION?</Subtitle>
            <Paragraph>
              The Services are intended for residents of the United States. However, if you are located outside of the United States, please be aware that information we collect through the Course Website and Services will be transferred to and processed in the United States. By using the Services, visiting our Course Website or providing us with any information, you consent to the transfer, processing, and storage of your information in the United States.
            </Paragraph>

            <Subtitle ref={this.caResidents}>8. WHAT ARE YOUR PRIVACY RIGHTS?</Subtitle>
            <Paragraph>
              <em>In Short:  You may review, change, or terminate your account at any time.</em>
            </Paragraph>
            <Paragraph><i><b>Account Information</b></i></Paragraph>
            <Paragraph>If you would at any time like to review or change the information in your account or terminate your account, you can:</Paragraph>
            <ul type='square'>
              <ListItem>Log into your account settings and update your user account.</ListItem>
              <ListItem>Contact us using the contact information provided.</ListItem>
              <ListItem>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</ListItem>
            </ul>

            <Paragraph><i><b>Additional rights over the data we collect from you</b></i></Paragraph>
            <Paragraph>In addition to accessing and changing your information through the methods described above, you may have the right to</Paragraph>
            <ul>
              <ListItem> request access to the personal information we collect from you;</ListItem>
              <ListItem> ask for a copy of that personal data in a commonly used and machine readable format;</ListItem>
              <ListItem> request that we delete your personal information;</ListItem>
              <ListItem> request that we rectify incorrect or incomplete data we hold about you; and</ListItem>
              <ListItem> object to, or request that we restrict, our processing of your data.</ListItem>
            </ul>

            <Paragraph>To exercise any of these rights concerning your personal information, please submit a request to info@outlier.org. We will respond to your request within 30 days.</Paragraph>
            <Subtitle><em>Opting out of email marketing</em></Subtitle>
            <Paragraph>
          As explained above, once you enroll in a class, you will automatically be opted out of all targeted or behavioral advertising. If you opt in to receive such advertising or have not yet enrolled in a course, however, you can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. We may still need to send you Service-related emails that are necessary for the administration and use of your account.
            </Paragraph>

            <Subtitle ref={this.caRights}>9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</Subtitle>
            <Paragraph><em>In Short:  Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</em></Paragraph>
            <Paragraph>At present, the California Consumer Privacy Act of 2018 does not apply to Outlier. If that were to change in the future, we will update this privacy policy, and attempt to notify you of that change.</Paragraph>
            <Paragraph>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</Paragraph>
            <Paragraph>If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from our systems.</Paragraph>
            <Subtitle ref={this.neRights}>10. DO NEVADA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</Subtitle>
            <Paragraph><em>In Short: Yes, if you are a resident of Nevada, you are granted specific rights regarding your ability to opt out of the sale of your information.</em></Paragraph>
            <Paragraph>We do not presently sell the personal information we collect to any third parties.  If we were to do so in the future, we will update this policy, and provide Nevada residents with the opportunity to opt-out of the sale of their personal information.</Paragraph>
            <Subtitle ref={this.childPrivacy}>11. CHILDREN’S PRIVACY</Subtitle>
            <Paragraph>The Course Website and Services are intended for users who are at least 13 years of age.  We do not knowingly accept, request, or solicit information from children or knowingly market to children. If we receive actual knowledge that anyone under the age of 13 has provided personal information to us without parental consent, we will delete that information.</Paragraph>
            <Subtitle ref={this.thirdParty}>12. THIRD-PARTY WEBSITES AND CONTENT</Subtitle>
            <Paragraph>
                The Course Website and Services may contain links to other websites ("Third-Party Websites") as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties ("Third-Party Content"). Such Third-Party Websites and Third-Party Content are not monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites or Third-Party Content. Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the Course Website or Services and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk. You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate from the Course Website or Services.
            </Paragraph>

            <Subtitle ref={this.DNT}>13. DO-NOT-TRACK</Subtitle>
            <Paragraph>
                     Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online.
            </Paragraph>
            <Subtitle ref={this.updatePolicy}>14. UPDATES TO THIS POLICY</Subtitle>
            <Paragraph>We may update this privacy policy from time to time. The updated version will be indicated by a “Last Updated” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we will attempt to notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are using and protecting your information.</Paragraph>
            <Subtitle ref={this.contactPolicy}>15. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</Subtitle>
            <Paragraph>If you have questions or comments about this policy, you may email us at <a href='mailto:info@outlier.org'>info@outlier.org</a> or by post to:</Paragraph>
            <Paragraph>
              Outlier.org Inc. <br />
              240 Kent Ave, Suite A3 <br />
              Brooklyn, NY 11249 <br />
              United States <br />
            </Paragraph>
          </Section>
        </div>
      </React.Fragment>
    )
  }
}
export default PrivacyPolicy
