import styled, { css, createGlobalStyle } from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const DisableBackgroundScrolling = createGlobalStyle`
  ${mediaqueries.mobile`
    html {
      overflow-y: hidden;
    }
  `}
`

export const ContentWrapper = styled.div`
  width: 730px;
  min-width: 350px;
  margin: 0 auto;
  margin-top: 24px;

  padding: 0 24px;

  ${mediaqueries.tablet`
    width: 100%;
  `}
`
export const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: end;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 24px;
  margin-bottom: 32px;

  ${mediaqueries.mobile`
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-bottom: 22px;
  `}
`
export const Title = styled.h1`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 48px;
  color: #FFFFFF;
  margin: 0;
  padding: 0;

  ${mediaqueries.mobile`
    margin-bottom: 16px;
  `}
`
export const CheckAllIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  content: url('/images/icons/check-all.svg');
`
export const MarkAllAsRead = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5FC4B8;
  cursor: pointer;
  user-select: none;

  ${props => props.disabled && css`
    opacity: 0.5;
    cursor: default;
  `}

  :hover {
    filter: brightness(1.5);
  }

  :active {
    opacity: 0.5;
  }
`
export const AnnouncementsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding-left: 26px;

  ${mediaqueries.mobile`
    padding-left: 0;
  `}
`
export const AnnouncementWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: start;
  margin-bottom: 17px;

  ${mediaqueries.mobile`
    flex-flow: column nowrap;
  `}
`
export const AnnouncementDate = styled.div`
  width: 96px;
  margin-right: 12px;
  padding-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #98A4AE;

  ${mediaqueries.mobile`
    margin-bottom: 8px;
  `}
`
export const CardContainer = styled.div`
  width: calc(100% - 108px);
  padding-bottom: 16px;
  border-bottom: 1px solid #25272B;

  ${mediaqueries.mobile`
    width: 100%;
  `}
`
export const Card = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 10px 16px 6px 8px;
  border-radius: 5px;

  ${props => props.isHighlightened && css`
    // change the background to #25272B for 5 seconds and fade it back to transparent
    animation: highlight 5s ease-in-out;
    @keyframes highlight {
      0% {
        background: #25272B;
      }
      80% {
        background: #25272B;
      }
      100% {
        background: transparent;
      }
    }
  `}
`
export const CardHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`
export const CardTitle = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
`
export const CardSeenIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-left: auto;
  cursor: pointer;

  ${props => props.isSeen
    ? css`
        content: url('/images/icons/icon-check-circle-filled.svg');
    `
    : css`
      content: url('/images/icons/icon-check-circle-unfilled.svg');
    `}
`
export const CardBody = styled.div`
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  opacity: 0.9;

  ${mediaqueries.mobile`
    margin-bottom: 16px;
  `}
`
export const Link = styled.a`
  display: block;
  margin: 0;
  margin-top: 12px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #5FC4B8!important;
  text-decoration: none!important;

  :hover {
    filter: brightness(1.5)!important;
  }
`
export const CollapseButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 12px;
  border: none;
  outline: none!important;
  background: none;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  color: #FFFFFF;

  :active {
    opacity: 0.5;
  }
  
  img {
    width: 12px;
    height: 6px;
    margin-right: 12px;
    transition: all 0.3s ease-in-out;
    content: url('/images/icons/caret.svg');

    ${props => props.isOpen && css`
      transform: rotate(180deg);
    `}
  }
`
export const CardFooter = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  ${mediaqueries.mobile`
    flex-flow: column nowrap;
    align-items: start;
  `}
`
export const FooterText = styled.h3`
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #98A4AE;
  margin: 0;
`
export const Dot = styled.div`
  display: inline-block;
  width: 2px;
  height: 2px;
  margin: 0 8px;
  border-radius: 50%;
  background: #98A4AE;

  ${mediaqueries.mobile`
    ${props => props.hideOnMobile && 'display: none;'}
  `}
`
export const NotificationWrapper = styled.div`
  position: relative;
`
export const NotificationsIcon = styled.img`
  display: block;
  width: 17.5px;
  height: 20px;
  margin-left: 15px;
  margin-right: 8px;
  transition: 0.5s;
  cursor: pointer;

  ${props => props.areAllNotificationsSeen
    ? css`
      content: url('/images/icons/icon-announcements.svg');
    `
    : css`
      content: url('/images/icons/icon-no-announcements.svg');
    `}
`
export const ModalWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 26px;
  width: 320px;
  min-width: 320px;
  min-width: 320px;
  ${props => props.showModal
    ? css`
      min-height: 575px;
      height: calc(100vh - 92px);
      max-height: 765px;
    `
    : css`
      height: 0px;
    `};
  transition: 0.3s;
  overflow: hidden;
  z-index: 10000;
`
export const NotificationModal = styled.div`
  position: absolute;
  top: -600px;
  width: 320px;
  min-width: 320px;
  min-height: 575px;
  height: calc(100vh - 92px);
  max-height: 765px;
  background: #25272B;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;

  ${props => props.showModal && css`
    opacity: 1;
    top: 0px;
    pointer-events: auto;
  `}

  ${mediaqueries.mobile`
    position: fixed;
    top: 0px;
    right: -100%;
    width: 100%;
    min-width: 320px;
    height: 100%;

    ${props => props.showModal && css`
      right: 0px;
      opacity: 1;
      pointer-events: auto;
    `}
  `}
`
export const ModalHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #5B6067;
  padding: 23px 24px 21px 24px;
`
export const ModalTitle = styled.h2`
  margin: 0px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #FFFFFF;
`
export const CloseIcon = styled.img`
  display: none;
  width: 13.5px;
  height: 13.5px;
  margin-left: auto;
  cursor: pointer;
  content: url('/images/close-icon.svg');

  ${mediaqueries.mobile`
    display: block;
  `}
`
export const ModalBody = styled.div`
  width: calc(100% - 5px);
  height: calc(100% - 109px);
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 9px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #5B6067;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`
export const NotificationCard = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  min-height: 169px;
  background: #232327;
  border: 1px solid #5B6067;
  border-radius: 5px;
  margin-bottom: 12px;
  padding: 12px 16px;
  transition: 0.3s;
  cursor: pointer;

  :hover {
    border-color: #C7F6F0;
  }
`
export const NotificationCardHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 8px;
`
export const NotificationCardTitle = styled.h2`
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
`
export const NotificationCardUnseenIcon = styled.div`
  width: 6px;
  height: 6px;
  background: #5FC4B8;
  border-radius: 50%;
`
export const NotificationCardBody = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #CCD4D8;
  margin-bottom: 8px;
`
export const NotificationCardFooter = styled.div`
  display: flex;
  flex-flow: column nowrap;
`
export const NotificationFooterText = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #98A4AE;
`
export const ModalFooter = styled.div`
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 49px;
  padding: 16px 24px;
  border-top: 1px solid #5B6067;
`
export const ViewAllLink = styled.div`
  font-weight: 700;
  display: flex;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5FC4B8!important;
  cursor: pointer;

  ::after {
    display: inline-block;
    width: 14px;
    height: 9px;
    margin-left: 9px;
    content: url('/images/icons/icon-teal-right-arrow.svg');
    color: #5FC4B8!important;
  }

  :hover {
    filter: brightness(1.5)
  }

  :active {
    opacity: 0.5;
  }
`
