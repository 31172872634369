import styled, { createGlobalStyle } from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const GlobalStyle = createGlobalStyle`
  #breadcrumb-wrapper {
    margin-top: 20px;
  }

  #breadcrumb-container {
    margin-bottom: ${props => (props.isCourseHome ? 0 : '3rem')};
  }

  #header-mb {
    display: none;
  }
`

export const Header = styled.header`
  transition: top 0.6s;
  position: fixed;
  width: 100%;
  z-index: ${({ isAssessment, isALRedesign }) =>
    (isAssessment || isALRedesign) && '1052'};
  top: ${props => (props.showHeader ? '0' : '-86px')};
  height: 64px;
  ${mediaqueries.mobile`
    height: 85px;
  `}
  .dropdown-menu a {
    color: #fff !important;
  }
`

export const HeaderPlacementContainer = styled.div`
  width: 100%;
  height: 64px;

  ${mediaqueries.mobile`height: 85px;`}
`

export const EmailContainer = styled.span`
  font-size: 15px;
  line-height: 18px;
  height: 18px;
  margin-right: 8px;
  ${mediaqueries.mobile`display: none !important;`}
`

export const DropDownMenu = styled.div`
  ${props =>
    props.dropDownOffset ? `right: -${props.dropDownOffset}px !important;` : ''}
  ${props => (props.maxHeight ? `max-height: ${props.maxHeight}px;` : '')}
  ${mediaqueries.mobile`
    position: fixed !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    top: -18px !important;
    max-height: 100vh;
  `}
`

export const CloseDropDownIcon = styled.img`
  cursor: pointer;
  display: none !important;
  ${mediaqueries.mobile`
    position: fixed;
    right: 17.5px;
    top: 17px;
    display: inline-block !important;
  `}
`

export const Nav = styled.nav`
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  padding: 0 24px;
`

export const BannerWrapper = styled.div`
  margin-top: 65px;
  padding: 11.95px 93px 13.66px 93px;
  background: #2C3339;
  min-height: 70px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  position: relative;
  ${mediaqueries.giant`padding: 10px 140px;`}
  ${mediaqueries.desktop`padding: 10px 145px;`}
  ${mediaqueries.tablet`padding: 15px 110px;`}
  ${mediaqueries.mobile`padding: 10px 35px;`}

  a {
    text-decoration: underline;
  }
`

export const CloseButton = styled.span`
  position: absolute;
  top: 29px;
  right: 24px;
  cursor: pointer;
  mask: url(/images/icons/icon-plus.svg) no-repeat 50% 50%;
  width: 12px;
  height: 12px;
  background-color: #ffffff;
  transform: rotate(45deg);
`

export const Bold = styled.div`
  font-weight: bold;
`

export const DropDownMenuWrapper = styled.div`
  margin-right: 0px;
  margin-left: 12px;
`

export const ChecklistIcon = styled.img`
  margin-right: 9px;
  margin-left: 19px;
  cursor: pointer;
  height: 20px;
  width: 18px;
  transition: 0.5s;
  &:hover {
    filter: opacity(0.5);
  }
`

export const NotificationHeader = styled.div`
  font-weight: bold;
  padding: 24px 12px 12px;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #c1cfd9 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`

export const NotificationDetails = styled.div`
  padding: 24px 12px 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  :last-child {
    border-bottom: none;
  }
`

export const NotificationHeadline = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 6px;
  font-weight: normal;
`

export const NotificationText = styled(NotificationHeadline)`
  margin-bottom: 12px;
  font-weight: 300;
`

export const NotificationAction = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5fc4b8;
  cursor: pointer;
  margin-bottom: 12px;
  margin-right: 24px;
`

export const NotificationCourses = styled.div`
  margin-bottom: 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #b1bfc5;
`

export const NotificationTimeStamp = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.3);
`

export const NotificationDetailsWrapper = styled.div`
  overflow-y: scroll;
  overscroll-behavior: contain;
  max-height: 394px;
  /* background styles to add top and bottom shadow on scroll 
  while hiding at the end of scroll */
  background: linear-gradient(#25272b 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), #25272b 70%) 0 100%,
    linear-gradient(180deg, #1b1818 0%, #25272b 100%),
    linear-gradient(0deg, #212121 0%, #25272b 100%) 0 100%;
  background: linear-gradient(#25272b 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), #25272b 70%) 0 100%,
    linear-gradient(180deg, #1b1818 0%, #25272b 100%),
    linear-gradient(0deg, #1c1b1b 0%, #25272b 100%) 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 50px, 100% 50px, 100% 10px, 100% 65px;
  background-attachment: local, local, scroll, scroll;

  ${mediaqueries.mobile`
    max-height: calc(100vh - 53px);
  `}

  ::-webkit-scrollbar {
    width: 3px;
    scroll-margin-top: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #3b3d40;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`

export const ActionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`
