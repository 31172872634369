import React, { useContext } from 'react'
import Context from '../Context/Context'
import indexOf from 'lodash/indexOf'
import {
  EXAM,
  QUIZ,
  ACTIVE_LEARNING,
  GUESSWORK,
  READINGS,
  LECTURE
} from '../../Constants/sectionType'
import LastQuestionConfirmationModal from './LastQuestionConfirmationModal'
import {
  CODING_ASSIGNMENT_PATH,
  PRACTICE_TERM_PATH,
  WRITING_ASSIGNMENT_PATH
} from '../../Constants'

const LastQuestionModal = ({ props }) => {
  const {
    reviewMode,
    showFinish,
    currentQuestionSet,
    studentData,
    updateContext,
    isLastQuestionAnswered,
    nextActivity
  } = useContext(Context)
  const { quest_type: questType, type } = currentQuestionSet || {}
  const isActiveLearning = questType === ACTIVE_LEARNING
  const typePaths = [
    QUIZ,
    EXAM,
    WRITING_ASSIGNMENT_PATH,
    CODING_ASSIGNMENT_PATH,
    PRACTICE_TERM_PATH,
    GUESSWORK,
    READINGS,
    LECTURE?.toLowerCase()
  ]
  const isQuizExamOrAL = isActiveLearning ||
    (indexOf(typePaths, questType || type) > -1)
  if ((!isQuizExamOrAL) || reviewMode || !showFinish) {
    return null
  }
  if (isQuizExamOrAL) {
    return (
      <LastQuestionConfirmationModal
        props={{
          ...props,
          studentData,
          updateContext,
          isLastQuestionAnswered,
          currentQuestionSet,
          nextActivity
        }}
      />
    )
  }
}

export default LastQuestionModal
